import React, { useState, useRef, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import * as Yup from "yup";

import { Toast } from "primereact/toast";

// import { Chip } from "primereact/chip";
import debounce from "lodash.debounce";
import { CallCenterService } from "../../../../services/callCenterService";
import {
	convertNullToString,
	convertObjNull,
} from "../../../../utils/UtilsFunction";
import {
	FeildDropdown,
	FieldInput,
	FieldMultiselect,
	FieldPassword,
} from "../../../../components/FieldInput";
import Loader from "../../../../components/Loader";
import { useGetAllHubQuery } from "../../../../services/query/queryApi";
const CreateCallCenterUser = (props) => {
	const { data = [] } = useGetAllHubQuery();
	// console.log(data);
	const callcenterService = new CallCenterService();

	// const [allHub, setAllHub] = useState([]);
	const toast = useRef(null);
	const [loading1, setLoading1] = useState(false);
	const [spokeList, setSpokeList] = useState([]);
	const [parentUserName, setparentUserName] = useState([]);

	const initialValues = {
		firstName: "",
		middleName: "",
		lastName: "",
		mobileNumber: "",
		email: "",
		userName: "",
		password: "",
		roleId: "", //number
		hubId: "", //number
		spokeId: [], //number
		parentUserName: "",
	};

	const validationSchema = Yup.object().shape({
		firstName: Yup.string()
			.required("First name cannot be empty.")
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid first name"
			),
		lastName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid last name"
			)
			.required("Last name cannot be empty."),
		// middleName: Yup.string().matches(
		// 	/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
		// 	"Please enter valid middle name"
		// ),
		email: Yup.string()
			.required("Email id cannot be empty.")
			.email("Invalid email id."),
		mobileNumber: Yup.string()
			.min(10, "Mobile No. should be 10 digit")
			.max(10, "Mobile No. should not be more than 10 digit")
			.matches(/^[0]?[6789]\d{9}$/, "Invalid Mobile No.")
			.required("Mobile no cannot be empty."),
		userName: Yup.string()
			.required("username Id cannot be empty.")
			.matches(/^\S+$/, "Invalid Id"),
		password: Yup.string().when("roleId", {
			is: () => props.type === "create",
			then: Yup.string().required("This field is required"),
		}),
		roleId: Yup.number().required("This field is required"),
		hubId: Yup.number().required("This field is required"),
		// spokeId: Yup.number().required("This field is required"),
		// hubId: Yup.number().when("roleId", {
		// 	is: (roleid) => roleid === props?.roles[0]?.id,
		// 	then: Yup.number().required("This field is required"),
		// }),

		// spokeId: Yup.number().when("roleId", {
		// 	is: (roleid) => roleid === props?.roles[1]?.id,
		// 	then: Yup.number().required("This field is required"),
		// 	otherwise: Yup.number().nullable(),
		// }),

		// role: Yup.object().test({
		// 	test: (role) => role.name !== null,
		// 	message: "Role is required",
		// }),
		// user: Yup.object().when("role", {
		// 	is: (role) =>
		// 		role.position !== 1 &&
		// 		role.position !== 50 &&
		// 		role.position !== 100,
		// 	then: Yup.object().test({
		// 		test: (role) => role.id !== null,
		// 		message: "Role is required",
		// 	}),
		// }),
		// areaList: Yup.array()
		// 	.of(Yup.number())
		// 	.when("role", {
		// 		is: (role) => role.position !== 50 && role.position !== 100,
		// 		then: Yup.array()
		// 			.of(Yup.number())
		// 			.min(1, "Field cannot be empty")
		// 			.required("Field cannot be empty"),
		// 	}),

		// user: Yup.object()

		// 	.when("role", {
		// is: (role) => role.name === "Area Sales Manager",
		// then: Yup.object().required("Zonal  Manager cannot be empty."),
		// 	})
		// 	.when("role", {
		// 		is: (role) => role.name === "Regional Manager",
		// 		then: Yup.object().required(
		// 			"Area Sales Manager cannot be empty."
		// 		),
		// 	})
		// 	.when("role", {
		// 		is: (role) => role.name === "Sales Executive",
		// 		then: Yup.object().required(
		// 			"Regional Manager cannot be empty."
		// 		),
		// 	}),
	});

	const handleSubmitForm = (data, action) => {
		const requestBody = convertObjNull(data);

		if (props.type === "create") {
			setLoading1(true);
			callcenterService
				.createCallCenterUser(requestBody)
				.then(() => {
					toast.current.show({
						severity: "success",
						summary: "Successful",
						detail: "User added Successfully",
						style: { color: "#000000" },
						life: 3000,
					});
					props.userDataTableValue();
					action.resetForm();
				})
				.then(
					debounce(() => {
						props.setMemberDialog(false);
					}, 1000)
				)
				.catch((e) => {
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => setLoading1(false));
		} else {
			setLoading1(true);
			callcenterService
				.updateCallCenterUser(requestBody)
				.then(() => {
					toast.current.show({
						severity: "success",
						summary: "Successful",
						detail: "User updated Successfully",
						style: { color: "#000000" },
						life: 3000,
					});
					props.userDataTableValue();
				})
				.then(
					debounce(() => {
						props.setMemberDialog(false);
					}, 1000)
				)
				.catch((e) => {
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => setLoading1(false));
		}
	};
	// const getAllHub = () => {
	// 	callcenterService.getAllHub().then((res) => {
	// 		setAllHub(res);
	// 	});
	// };
	const onRoleChanges = (e, setFieldValue) => {
		// setFieldValue("user", initialValues.user, true);
		// setFieldValue("areaList", initialValues.areaList, true);
		// setUserAreaList([]);
		setFieldValue("hubId", "", true);
		setFieldValue("spokeId", "", true);
		setFieldValue("parentUserName", "", true);
		setFieldValue("roleId", e.value, true);
	};
	const getSpokeList = (e) => {
		callcenterService.getSpokeListById(e).then((res) => setSpokeList(res));
	};
	const getParentUserName = (e) => {
		callcenterService.getUserByHubId(e).then((res) => {
			const data = res.map((item) => ({
				...item,
				name:
					(item.firstName ? item.firstName : "") +
					" " +
					(item.middleName ? item.middleName : "") +
					" " +
					(item.lastName ? item.lastName : ""),
			}));
			setparentUserName(data);
		});
	};
	const handleSpokeList = (e, setFieldValue) => {
		setFieldValue("hubId", e.value, true);
		setFieldValue("parentUserName", "", true);
		getParentUserName(e.value);
		getSpokeList(e.value);
	};
	useEffect(() => {
		Promise.allSettled([
			props?.data?.hubId && getSpokeList(props.data.hubId),
			props?.data?.hubId && getParentUserName(props.data.hubId),
		]);
		props?.data?.hubId && getSpokeList(props.data.hubId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.data]);
	return (
		<>
			{loading1 && <Loader />}
			<Toast ref={toast} position="top-left" />
			<div className="col-12 p-0">
				<div className="card modal-form-grid">
					<Formik
						initialValues={{
							...initialValues,
							...(props.data && convertNullToString(props.data)),
						}}
						enableReinitialize
						onSubmit={handleSubmitForm}
						validationSchema={validationSchema}
					>
						{({
							handleSubmit,
							setFieldValue,
							values,
							resetForm,
						}) => (
							<Form className="grid" onSubmit={handleSubmit}>
								<>
									<div className="field col-12 md:col-4">
										<Field
											header={"First Name"}
											name={"firstName"}
											component={FieldInput}
											star={"*"}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Middle Name"}
											name={"middleName"}
											component={FieldInput}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Last Name"}
											name={"lastName"}
											component={FieldInput}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-6">
										<Field
											header={"Email Id"}
											name={"email"}
											component={FieldInput}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-6">
										<Field
											header={"Mobile No"}
											name={"mobileNumber"}
											component={FieldInput}
											star={"*"}
										/>
									</div>

									<div className="field col-12 md:col-6">
										<Field
											header={"User Name"}
											name={"userName"}
											component={FieldInput}
											star={"*"}
										/>
									</div>
									{props.type === "create" && (
										<div className="field col-12 md:col-6">
											<Field
												header={"Password"}
												name={"password"}
												component={FieldPassword}
												star={"*"}
											/>
										</div>
									)}
								</>
								<div className="field col-12 md:col-6">
									<Field
										header={"Select Role"}
										name={"roleId"}
										component={FeildDropdown}
										filter
										star={"*"}
										onChange={(e) =>
											onRoleChanges(e, setFieldValue)
										}
										options={props.roles}
										optionLabel="name"
										optionValue={"id"}
									/>
								</div>

								{values.roleId === props?.roles[0]?.id ? (
									<div className="field col-12 md:col-6">
										<Field
											header={`Select Hub`}
											name={"hubId"}
											component={FeildDropdown}
											star={"*"}
											filter
											// onChange={(e) => {
											// 	handleSpokeList(
											// 		e,
											// 		setFieldValue
											// 	);
											// }}
											options={data}
											optionLabel="name"
											optionValue={"id"}
										/>
									</div>
								) : (
									values.roleId === props?.roles[1]?.id && (
										<>
											<div className="field col-12 md:col-6">
												<Field
													header={`Select Hub`}
													name={"hubId"}
													component={FeildDropdown}
													star={"*"}
													filter
													onChange={(e) => {
														handleSpokeList(
															e,
															setFieldValue
														);
													}}
													options={data}
													optionLabel="name"
													optionValue={"id"}
												/>
											</div>
											<div className="field col-12 md:col-6">
												<Field
													header={`Select Tasm`}
													name={"parentUserName"}
													component={FeildDropdown}
													star={"*"}
													filter
													options={parentUserName}
													optionLabel="name"
													optionValue={"userName"}
												/>
											</div>
											{values.hubId && (
												<div className="field col-12 md:col-6">
													<Field
														header={`Select Spoke`}
														name={"spokeId"}
														component={
															FieldMultiselect
														}
														star={"*"}
														filter
														options={spokeList}
														optionLabel="name"
														optionValue={"id"}
													/>
												</div>
											)}
										</>
									)
								)}
								<div className="col-12 pb-0">
									<div className="flex justify-content-end align-items-center w-full">
										<Button
											type="button"
											label="Reset"
											className="danger-btn mr-2"
											icon="pi pi-times"
											onClick={() => {
												resetForm();
											}}
										/>
										<Button
											type="submit"
											label="Save"
											className="primary-btn"
											icon="pi pi-save"
										/>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</>
	);
};

export default CreateCallCenterUser;
