import React, { useState, useEffect, useRef } from "react";

import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { LeadService } from "../../../../services/LeadService";
import moment from "moment";
import TableLoader from "../../../../components/TableLoader";
import Loader from "../../../../components/Loader";
import { Toast } from "primereact/toast";
const StatusUpdate = (props) => {
	const [status, setStatus] = useState("");
	const toast = useRef(null);
	const statusData = [
		// {
		// 	name: "New",
		// 	value: "NEW",
		// },
		{
			name: "Not Interested",
			value: "NOT_INTERESTED",
		},
		{
			name: "Not Eligible",
			value: "NOT_ELIGIBLE",
		},
		{
			name: "Follow Up",
			value: "FOLLOW_UP",
		},
	];
	const [stateHistory, setStateHistory] = useState([]);
	const [spinner, setSpinner] = useState(false);
	const [loader, setLoader] = useState(false);
	const leadService = new LeadService();
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="Status" />
				<Column header="Date" />
				<Column header="Updated By" />
				<Column header="Current State" />
			</Row>
		</ColumnGroup>
	);
	const template2 = {
		layout: "CurrentPageReport PrevPageLink NextPageLink",
	};
	const statusTemplate = (item) => {
		return item.active ? (
			<span className="status status-success">Active</span>
		) : (
			<span className="status status-danger">Inactive</span>
		);
	};
	const dateTemplate = (item) => {
		return moment(item.createdAt).format("Do MMM YYYY");
	};
	const getStatus = (id) => {
		setSpinner(true);
		leadService
			.getStatusHistory(id)
			.then((res) => {
				let temp = res.sort((a, b) => {
					const date1 = new Date(a.createdAt);
					const date2 = new Date(b.createdAt);
					return date2 - date1;
				});
				setStateHistory(temp);
				setSpinner(false);
			})
			.catch((e) => {
				setSpinner(false);
			});
	};

	useEffect(() => {
		getStatus(props.id);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const updateStatus = (e) => {
		setLoader(true);
		const payload = {
			leadId: e,
			status: status,
		};
		leadService
			.updateStatus(payload)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});
				props.event();
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoader(false);
				getStatus(props.id);
			});
	};
	const handleClick = () => {
		updateStatus(props.id);
		setLoader(true);
	};
	const itaration = {
		city: "",
		status: "",
		action: "",
		name: "",
	};
	const leadstatusTemplate = (item) => {
		switch (item.status) {
			case "FOLLOW_UP":
				return <span className="status status-warning">Follow Up</span>;

			case "NOT_INTERESTED":
				return (
					<span className="status status-danger">Not Interested</span>
				);

			case "NEW":
				return <span className="status status-primary">New</span>;

			case "NOT_ELIGIBLE":
				return (
					<span className="status status-danger-deep">
						Not Eligible
					</span>
				);

			case "CONVERTED":
				return <span className="status status-success">Converted</span>;
			case "INVALID":
				return (
					<span className="status bg-red-400 text-red-50">
						Invalid
					</span>
				);
			default:
				return "";
		}
	};
	return (
		<>
			<Toast ref={toast} />
			{loader && <Loader />}
			<div className="modal-form-grid">
				<Dropdown
					className="border-round-md mr-3"
					value={status}
					options={
						stateHistory.length
							? statusData.filter(
									(item) =>
										item.value !== stateHistory[0].status
							  )
							: statusData
					}
					optionLabel={"name"}
					onChange={(e) => setStatus(e.value)}
					width={300}
					placeholder={"Set status"}
				/>
				<Button
					disabled={status === "" ? true : false}
					label="Update"
					onClick={handleClick}
				/>

				<h3>Status History</h3>
				{spinner ? (
					<TableLoader
						headerGroup={headerGroup}
						itaration={itaration}
					/>
				) : (
					<DataTable
						paginator
						value={stateHistory}
						headerColumnGroup={headerGroup}
						responsiveLayout="scroll"
						breakpoint="960px"
						rows={5}
						paginatorTemplate={template2}
					>
						<Column body={leadstatusTemplate} />
						<Column body={dateTemplate} />
						<Column field="createdBy" />

						<Column body={statusTemplate} />
					</DataTable>
				)}
			</div>
		</>
	);
};

export default StatusUpdate;
