import { useEffect, useReducer, useRef } from "react";
import { dataReducer, fetchInitialState } from "../../components/fetchReducer";
import {
	searchInitialState,
	searchReducer,
} from "../../components/searchReducer";
import SearchReport from "./components/SearchReport";
import TableLoader from "../../components/TableLoader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Paginator from "../../components/Paginator";
import Config from "../../shared/config";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { ReportService } from "../../services/ReportService";
import { Toast } from "primereact/toast";
import moment from "moment";

const AgentReport = () => {
	const reportService = new ReportService();
	const [reportState, reportDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const toast = useRef(null);
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);

	const getAgentReport = () => {
		const body = {
			...searchState.searchValue,
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.sortByColumn &&
				searchState.sortType && {
					sorts: [
						searchState.sortByColumn + "," + searchState.sortType,
					],
				}),
		};
		console.log({ ...searchState.searchValue });
		const demo = Object.fromEntries(
			Object.entries(body).filter(([_, v]) => v !== "")
		);
		reportDispatch({ type: Config.FETCH_CONFIG.start });
		reportService
			.getAgentReportList(demo)
			.then((res) => {
				reportDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			})
			.finally(() => {
				reportDispatch({ type: Config.FETCH_CONFIG.stop });
			});
	};
	const getSortableColumn = (name, columnName) => {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	};
	function tblSort(columnName, sortType) {
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}
	const itaration = {
		agentName: "",
		email: "",
		mobile: "",
		rmUserName: "",
		seUserName: "",
		district: "",
		state: "",
		userName: "",
		status: "",
		createOn: "",
		updateOn: "",
	};
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />
				<Column header={getSortableColumn("Name", "agent.firstName")} />
				<Column
					header={getSortableColumn("Username", "agent.userName")}
				/>
				<Column header={"Mobile"} />
				<Column header={"Email ID"} />
				<Column header={getSortableColumn("RM/ASM", "rmUser")} />
				<Column header={getSortableColumn("TSE", "salesUser")} />
				<Column
					header={getSortableColumn("District", "district.name")}
				/>
				<Column header={getSortableColumn("Status", "wf.step")} />
				<Column header={"Created On"} />
				<Column header={"Updated On"} />
			</Row>
		</ColumnGroup>
	);
	useEffect(() => {
		let subscribe = true;
		if (subscribe) {
			getAgentReport();
		}
		return () => {
			subscribe = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);
	const nameTemplate = (e) => {
		return (
			(e.firstName ? e.firstName : "") +
			" " +
			(e.middleName ? e.middleName : "") +
			" " +
			(e.lastName ? e.lastName : "")
		);
	};
	const createOnFormat = (item) => {
		return (
			<>
				{item.createdOn &&
					moment(item.createdOn).format("Do MMM, YYYY HH:mm A")}
			</>
		);
	};

	const updateOnFormat = (item) => {
		return (
			<>
				{item.updateOn &&
					moment(item.updateOn).format("Do MMM, YYYY HH:mm A")}
			</>
		);
	};
	return (
		<>
			<SearchReport
				searchDispatch={searchDispatch}
				data={reportState.data.data && reportState.data.data}
			/>
			<Toast ref={toast} />
			{reportState.isLoading ? (
				<TableLoader itaration={itaration} headerGroup={headerGroup} />
			) : (
				<>
					<Paginator
						pageSize={reportState.data.pageSize}
						firstPage={reportState.data.firstPage}
						lastPage={reportState.data.lastPage}
						decrement={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.decrement,
							})
						}
						increment={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.increment,
							})
						}
						pagesizechange={(e) => {
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageSize,
								payload: e.target.value,
							});
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageNo,
								payload: 1,
							});
						}}
						totalElements={reportState.data.totalElements}
						pageNo={reportState.data.pageNo}
						totalPages={reportState.data.totalPages}
					/>
					<DataTable
						// value={elem.programListDtoList}
						headerColumnGroup={headerGroup}
						value={
							reportState.data.data &&
							reportState.data.data.map((item, index) => ({
								...item,
								index:
									reportState.data.pageSize *
										reportState.data.pageNo -
									reportState.data.pageSize +
									1 +
									index,
							}))
						}
						responsiveLayout="scroll"
					>
						<Column field="index" />
						<Column body={nameTemplate} />
						<Column field={"userName"} />
						<Column field={"mobileNumber"} />
						<Column field={"emailId"} />

						<Column field={"rmUserName"} />
						<Column field={"seUserName"} />

						<Column field={"districtName"} />
						<Column field={"status"} />
						<Column body={createOnFormat} />
						<Column body={updateOnFormat} />
					</DataTable>
					<Paginator
						pageSize={reportState.data.pageSize}
						firstPage={reportState.data.firstPage}
						lastPage={reportState.data.lastPage}
						decrement={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.decrement,
							})
						}
						increment={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.increment,
							})
						}
						pagesizechange={(e) => {
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageSize,
								payload: e.target.value,
							});
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageNo,
								payload: 1,
							});
						}}
						totalElements={reportState.data.totalElements}
						pageNo={reportState.data.pageNo}
						totalPages={reportState.data.totalPages}
					/>
				</>
			)}
		</>
	);
};

export default AgentReport;
