import React, { useRef, useState } from "react";
import Config from "../../../../shared/config";

import { Field, Form, Formik } from "formik";
import {
	FeildCalender,
	FeildDropdown,
	// FeildDropdown,
	FieldEditor,
	FieldInput,
	FieldMultiselect,
} from "../../../../components/FieldInput";
import { useGetProductDetailsQuery } from "../../../../services/query/queryApi";
import { Button } from "primereact/button";
import { InputNode } from "../../../../components/InputComponent";
import { FileUpload } from "primereact/fileupload";
import { S3 } from "aws-sdk";
import { Toast } from "primereact/toast";
import mapSeries from "async/mapSeries";
import { ProgressBar } from "primereact/progressbar";
import moment from "moment";
import { CampaignService } from "../../../../services/CampaignService";
import * as Yup from "yup";
import Loader from "../../../../components/Loader";
import { useEffect } from "react";
import { Image } from "primereact/image";
import { folder_path } from "../../../../shared/constant";
import { useNavigate } from "react-router-dom";
const CampaignDetails = (props) => {
	const navigate = useNavigate();
	const campaignService = new CampaignService();
	const toastTL = useRef(null);
	const [imgSpinner, setImgSpinner] = useState(false);
	const [programList, setProgramList] = useState([]);
	const [spinner, setSpinner] = useState(false);
	const { data = { data: [] } } = useGetProductDetailsQuery({
		searchRequest: [],
		pageNo: 1,
		pageSize: 10000,
		sorts: [],
	});
	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.matches(/^(\w+\s)*\w+$/, "Please enter valid name")
			.required("This Field is Required"),
		campaignModeDetailsDtoList: Yup.array()
			.of(Yup.object())
			// .min(1, "Select minimum one")
			.required("This Field is Required"),

		campaignProductDetailsDtos: Yup.array()
			.of(Yup.object())
			// .min(1, "Select minimum one")
			.required("This Field is Required"),
		campaignSubProductDetailsDtos: Yup.array()
			.of(Yup.object())
			// .min(1, "Select minimum one")
			.required("This Field is Required"),
		campaignProgramDetailsDtos: Yup.array().of(Yup.string()),
		startDate: Yup.date().required("This field is required"),
		endDate: Yup.date()
			.when("startDate", (startDate, value) => {
				return value.test({
					test: (endDate) =>
						!!new Date(startDate) &&
						new Date(endDate) > new Date(startDate),
					message: "Invalid date",
				});
			})
			.required("This field is required"),
		shortDescription: Yup.string().required("This Field is Required"),
		longDescription: Yup.string().required("This Field is Required"),
		campaignImages: Yup.array()
			.of(Yup.object())
			.min(1, "Select minimum one")
			.required("This Field is Required"),
	});
	const initialValues = {
		name: "",
		campaignModeDetailsDtoList: [],
		frequency: [],
		campaignProductDetailsDtos: [],
		campaignSubProductDetailsDtos: [],
		campaignProgramDetailsDtos: [],
		startDate: "",
		endDate: "",
		shortDescription: "",
		longDescription: "",
		campaignImages: [],
	};
	const getProgram = (e) => {
		campaignService
			.getProgramList(e)
			.then((xx) => {
				const newProgram = xx.flatMap((yy) => yy.programs);
				// console.log(newProgram);
				setProgramList(newProgram);
			})
			.catch((er) => {});
	};
	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});
	const progressBArTemplate = () => {
		return (
			imgSpinner && (
				<ProgressBar mode="indeterminate" style={{ height: "5px" }} />
			)
		);
	};
	const onUploadImage = (e, setFieldValue) => {
		const fileN = e.files;
		setImgSpinner(true);
		function uploadFile(file, callback) {
			const fileName = file.name;
			const fileNameArray = fileName.split(".");
			const fileExtension = fileNameArray[fileNameArray.length - 1];
			const newFileName = `${new Date().getTime()}.${fileExtension}`;
			const params = {
				Body: file,
				Bucket: Config.CAMPAIGN_BUCKET + "/" + folder_path,
				Key: newFileName,
				ContentType: file.type,
			};

			s3Clint.upload(params, (err, data) => {
				if (err) {
					callback(err);
				} else {
					// console.log(newFileName);
					callback(null, { imgUrl: newFileName });
				}
			});
		}

		mapSeries(fileN, uploadFile)
			.then((results) => {
				setFieldValue("campaignImages", results);
				e.options.clear();
				// console.log(results);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Image uploaded",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((err) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setImgSpinner(false));
	};
	useEffect(() => {
		props.id &&
			props.dataState.details.campaignSubProductDetailsDtos &&
			getProgram({
				subproductIds:
					props.dataState.details.campaignSubProductDetailsDtos.map(
						(elm) => elm.id
					),
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id, props.dataState.details]);
	const handleProductChange = (e, values, setFieldValue) => {
		setFieldValue("campaignProductDetailsDtos", e.value, true);
		const newData = values.campaignSubProductDetailsDtos.filter((elm) =>
			e.value.some((item) => item.id === elm.parentId)
		);
		setFieldValue("campaignSubProductDetailsDtos", newData, true);
		if (newData.length > 0) {
			const programData = newData.map((item) => item.id);
			getProgram({ subproductIds: programData });
		} else {
			setProgramList([]);
		}
	};

	const handleNext = () => {
		props.setStages({
			type: "stage",
			payload: {
				sequence: 2,

				status: "active",
			},
		});
		props.setStages({
			type: "stage",
			payload: {
				sequence: 1,

				status: "success",
			},
		});
		props.setStages({ type: "active", active: 2 });
	};
	const handleSubmit = (values) => {
		const { frequency, ...rest } = values;
		setSpinner(true);
		let requestBody = {
			...rest,
			...(frequency.length === 0
				? { frequency: null }
				: {
						frequency: frequency,
				  }),
			startDate: values.startDate
				? moment(values.startDate).format("YYYY-MM-DD")
				: null,
			endDate: values.endDate
				? moment(values.endDate).format("YYYY-MM-DD")
				: null,
			// campaignModeDetailsDtoList: values.campaignModeDetailsDtoList.map(
			// 	(item) => ({
			// 		campaignMode: item,
			// 	})
			// ),
			// campaignImages: values.campaignImages.map((item) => ({
			// 	imgUrl: item,
			// })),
			campaignProductDetailsDtos: values.campaignProductDetailsDtos.map(
				(item) => ({
					originId: item.id,
				})
			),
			campaignSubProductDetailsDtos:
				values.campaignSubProductDetailsDtos.map((item) => ({
					originId: item.id,
				})),
			campaignProgramDetailsDtos: values.campaignProgramDetailsDtos.map(
				(xx) => ({ programId: xx })
			),
		};
		if (props.id) {
			campaignService
				.campaignUpdate(requestBody)
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Successfull",
						detail: "Saved",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.catch()
				.finally(() => setSpinner(false));
		} else {
			campaignService
				.campaignCreate(requestBody)
				.then((res) => {
					props.setStages({
						type: "id",
						id: res,
					});
					props.setStages({
						type: "stage",
						payload: {
							sequence: 2,

							status: "active",
						},
					});
					props.setStages({
						type: "stage",
						payload: {
							sequence: 1,

							status: "success",
						},
					});
					props.setStages({ type: "active", active: 2 });
				})
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => {
					setSpinner(false);
				});
		}
	};
	const handleSubProductHange = (e, values, setFieldValue) => {
		setFieldValue("campaignSubProductDetailsDtos", e.value, true);
		setFieldValue("campaignProgramDetailsDtos", [], true);
		if (e.value.length === 0) {
			setProgramList([]);
		} else {
			const programData = e.value.map((item) => item.id);
			getProgram({ subproductIds: programData });
		}
	};

	// const handleCampaign = (e, setFieldValue, values) => {
	// 	setFieldValue("campaignModeDetailsDtoList", e, true);
	// 	setFieldValue("frequency", [], true);
	// 	console.log(values.campaignModeDetailsDtoList);
	// };
	return (
		<>
			<Toast ref={toastTL} />
			{spinner && <Loader />}
			<Formik
				initialValues={{
					...initialValues,
					...(props.id && props.dataState.details),
				}}
				enableReinitialize
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
			>
				{({ handleSubmit, setFieldValue, values, errors, touched }) => (
					<Form onSubmit={handleSubmit} className="grid form-grid">
						<div className="field col-12 md:col-3">
							<Field
								header={"Campaign Name"}
								name={"name"}
								component={FieldInput}
							/>
						</div>
						{/* {console.log(values.image)} */}
						<div className="field col-12 md:col-3">
							<Field
								header={"Campaign Mode"}
								name={"campaignModeDetailsDtoList"}
								component={FieldMultiselect}
								options={[
									{
										label: "Email",
										value: { campaignMode: "Email" },
									},
									{
										label: "Tele Calling",
										value: { campaignMode: "Tele_calling" },
									},
									{
										label: "SMS",
										value: { campaignMode: "SMS" },
									},
									{
										label: "MM screen",
										value: { campaignMode: "MM_screen" },
									},
									{
										label: "MH screen",
										value: { campaignMode: "MH_screen" },
									},
								]}
								// onChange={(e) =>
								// 	handleCampaign(
								// 		e.value,
								// 		setFieldValue,
								// 		values
								// 	)
								// }
							/>
						</div>

						{}
						<div className="field col-12 md:col-3">
							<Field
								header={"Frequency"}
								name={"frequency"}
								value={
									values.campaignModeDetailsDtoList.some(
										(item) =>
											item.campaignMode === "Tele_calling"
									)
										? ""
										: values.frequency
								}
								component={FeildDropdown}
								options={[
									{
										label: "Daily",
										value: "Daily",
									},
									{
										label: "Weekly",
										value: "Weekly",
									},

									{
										label: "Fort Nightly",
										value: "Fort_Nightly",
									},
									{
										label: "Monthly",
										value: "Monthly",
									},
								]}
								disabled={
									values.campaignModeDetailsDtoList.some(
										(item) =>
											item.campaignMode === "Tele_calling"
									)
										? true
										: false
								}
							/>
						</div>

						<div className="field col-12 md:col-3">
							<Field
								header={"Product"}
								name={"campaignProductDetailsDtos"}
								component={FieldMultiselect}
								optionLabel={"title"}
								options={data.data}
								onChange={(e) => {
									handleProductChange(
										e,
										values,
										setFieldValue
									);
								}}
							/>
						</div>
						{values.campaignProductDetailsDtos && (
							<div className="field col-12 md:col-3">
								<Field
									header={"Sub Products"}
									name={"campaignSubProductDetailsDtos"}
									component={FieldMultiselect}
									optionLabel={"title"}
									options={values.campaignProductDetailsDtos}
									optionGroupLabel="title"
									optionGroupChildren="subProducts"
									onChange={(e) => {
										handleSubProductHange(
											e,
											values,
											setFieldValue
										);
									}}
								/>
							</div>
						)}
						<div className="field col-12 md:col-3">
							<Field
								header={"Program"}
								name={"campaignProgramDetailsDtos"}
								component={FieldMultiselect}
								optionValue={"id"}
								options={programList}
								display={false}
								optionLabel={"name"}
							/>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								header={"Start Date"}
								name={"startDate"}
								component={FeildCalender}
							/>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								header={"End Date"}
								name={"endDate"}
								component={FeildCalender}
								minDate={new Date(values.startDate)}
							/>
						</div>
						<div className="field col-12 md:col-6">
							<Field
								header={"Short Description"}
								name={"shortDescription"}
								component={FieldInput}
							/>
						</div>

						<div className="field col-12 md:col-6">
							<Field
								header={"Long Description"}
								name={"longDescription"}
								component={FieldEditor}
							/>
						</div>
						<div className="col-12 flex gap-2 flex-wrap">
							{values.campaignImages.map((xxx) => (
								<>
									<Image
										key={xxx.id}
										src={`${Config.AGENT_BUCKET_ACCESS_URL}/campaign-docs/campaign-details/${folder_path}/${xxx.imgUrl}`}
										width={150}
										height={150}
										imageStyle={{ objectFit: "cover" }}
										alt={`${xxx.imgUrl}`}
									/>
								</>
							))}
						</div>
						<div className="field col-12 md:col-6">
							<InputNode header={"Upload Logo"}>
								<FileUpload
									name="campaignImages"
									customUpload
									multiple
									accept=".jpg,.png,.jpeg"
									uploadHandler={(e) =>
										onUploadImage(e, setFieldValue)
									}
									progressBarTemplate={progressBArTemplate}
									maxFileSize={1000000}
									emptyTemplate={
										<p className="m-0">
											Drag and drop files to here to
											upload.
										</p>
									}
								/>
								{errors.campaignImages &&
									touched.campaignImages && (
										<small className="p-error">
											{errors.campaignImages}
										</small>
									)}
							</InputNode>
						</div>
						<div className="flex col-12 justify-content-end align-items-center w-full gap-2 mt-4">
							<Button
								type="button"
								onClick={() => navigate("/masters/campaign")}
								className="danger-btn"
							>
								<i className="pi pi-angle-left ml-1"></i>
								Back
							</Button>
							<Button
								type="submit"
								label={props.id ? "Save" : "Save & Next"}
								icon={
									props.id
										? "pi pi-save"
										: "pi pi-angle-right"
								}
								disabled={
									props.stages.campaignId ? true : false
								}
							/>
							{(props.id || props.stages.campaignId) && (
								<Button
									label="Next"
									icon="pi pi-angle-right"
									onClick={handleNext}
								/>
							)}
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default CampaignDetails;
