import React, { useState, useEffect, useRef, useReducer } from "react";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { ProgressSpinner } from "primereact/progressspinner";
import { LoanApplicationService } from "../../../services/LoanApplication";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import Config from "../../../shared/config";
import { Field, Form, Formik } from "formik";
import { FieldInput } from "../../../components/FieldInput";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog"; // For <ConfirmDialog /> component
import { confirmDialog } from "primereact/confirmdialog"; // For confirmDialog method
import { produce } from "immer";

const ReasonCreate = (props) => {
	const message = useRef(null);
	const formReducer = produce((state, action) => {
		switch (action.type) {
			case "change":
				state.status = action.payload;
				break;
			case "create":
				state.create.reason = action.payload;
				break;
			case "update":
				state.update.reason = action.payload.reason;
				state.update.id = action.payload.id;

				break;

			default:
				return state;
		}
	});
	const [statusForm, setFrom] = useReducer(formReducer, {
		status: "create",
		create: { productId: props.id, reason: "" },
		update: {
			id: "",
			productId: props.id,
			reason: "",
		},
	});
	const [isLoading, setLoading] = useState(false);
	const loanApplicationService = new LoanApplicationService();
	const [reason, setAllReason] = useReducer(dataReducer, fetchInitialState);
	const getAllReason = () => {
		setAllReason({ type: Config.FETCH_CONFIG.start });
		loanApplicationService
			.getReason(props.id)
			.then((res) =>
				setAllReason({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				})
			)
			.catch((e) =>
				setAllReason({
					type: Config.FETCH_CONFIG.error,
					payload: e,
				})
			)
			.finally(() =>
				setAllReason({
					type: Config.FETCH_CONFIG.stop,
				})
			);
	};

	const handleEdit = (data) => {
		setFrom({ type: "change", payload: "update" });
		setFrom({
			type: "update",
			payload: { id: data.id, reason: data.reason },
		});
	};
	const actionTemplate = (item) => {
		return (
			<div className="flex gap-2 align-items-end justify-content-end">
				<Button
					className="p-button-text icon-btn primary-btn"
					icon="pi pi-pencil"
					tooltip="Edit"
					tooltipOptions={{ position: "bottom" }}
					onClick={() => handleEdit(item)}
				/>
				<Button
					className="p-button-text icon-btn p-button-danger"
					icon="pi pi-trash"
					tooltip="Delete"
					tooltipOptions={{ position: "bottom" }}
					onClick={() => confirm1(item.id)}
				/>
			</div>
		);
	};

	const handleFormSubmit = (value, fromikBag) => {
		if (statusForm.status === "create") {
			setLoading(true);
			loanApplicationService
				.createReason(value)
				.then((res) =>
					message.current.show({
						severity: "success",
						summary: "",
						detail: res,
						closable: false,
					})
				)
				.finally(() => {
					fromikBag.resetForm();
					setLoading(false);
					getAllReason();
				});
		}
		if (statusForm.status === "update") {
			setLoading(true);
			loanApplicationService
				.updateReason(value)
				.then((res) =>
					message.current.show({
						severity: "success",
						summary: "",
						detail: res,
						closable: false,
					})
				)
				.finally(() => {
					fromikBag.resetForm();
					setLoading(false);
					getAllReason();
					setFrom({ type: "change", payload: "create" });
				});
		}
	};
	const confirm1 = (id) => {
		confirmDialog({
			message: "Are you sure you want to proceed?",
			header: "Confirmation",
			icon: "pi pi-exclamation-triangle",
			accept: () => {
				loanApplicationService
					.deleteReason(id)
					.then((res) => {
						message.current.show({
							severity: "success",
							summary: "",
							detail: res,
							closable: false,
						});
					})
					.then(() => getAllReason());
			},
			reject: () => {},
		});
	};
	useEffect(() => {
		let ignore = false;
		if (!ignore) {
			getAllReason();
		}

		return () => {
			ignore = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="card modal-form-grid">
			<Messages className="w-full" ref={message} />
			{reason.isLoading ? (
				<div className="flex align-items-center justify-content-center py-5">
					<ProgressSpinner
						style={{
							width: "40px",
							height: "40px",
						}}
						strokeWidth="4"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				</div>
			) : (
				<>
					<Formik
						initialValues={
							statusForm.status === "create"
								? statusForm.create
								: statusForm.update
						}
						onSubmit={handleFormSubmit}
						enableReinitialize
					>
						{({ values, handleSubmit }) => (
							<Form
								onSubmit={handleSubmit}
								className="flex my-2 gap-2"
							>
								<div className="field flex-1">
									<Field
										name={"reason"}
										component={FieldInput}
										placeholder="Product Purpose"
									/>
								</div>
								{statusForm.status === "update" && (
									<div className="field flex gap-2">
										<Button
											type={"submit"}
											icon="pi pi-pencil"
											label="Update"
											loading={isLoading}
											tooltip="Update"
											tooltipOptions={{
												position: "bottom",
											}}
										/>

										<Button
											type="button"
											className="p-button-outlined p-button-danger"
											icon="pi pi-times"
											tooltip="Close"
											tooltipOptions={{
												position: "bottom",
											}}
											onClick={() => {
												setFrom({
													type: "change",
													payload: "create",
												});
											}}
										/>
									</div>
								)}
								{statusForm.status === "create" && (
									<div className="field">
										<Button
											type={"submit"}
											icon="pi pi-save"
											label="Save"
											loading={isLoading}
											tooltip="Create"
											tooltipOptions={{
												position: "bottom",
											}}
											disabled={
												values.reason ? false : true
											}
										/>
									</div>
								)}
							</Form>
						)}
					</Formik>
					<DataTable value={reason.data}>
						<Column
							header="#"
							headerStyle={{ width: "3em" }}
							body={(data, options) => options.rowIndex + 1}
						></Column>
						<Column field="reason" header="Reason" />
						<Column
							header="Action"
							headerClassName="text-right"
							body={actionTemplate}
						/>
					</DataTable>
				</>
			)}

			<ConfirmDialog />
		</div>
	);
};

export default ReasonCreate;
