import React from "react";
import Insurance from "./Insurance";
import { useLocation, useParams } from "react-router-dom";

const VendorHospicasEdit = () => {
	const urlData = useLocation();
	const { id } = useParams();
	return <Insurance propsId={id} urlData={urlData} />;
};

export default VendorHospicasEdit;
