import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";

import { SelectButton } from "primereact/selectbutton";
import { CountryAdminDashboardService } from "../../../../services/CountryAdminDashboard";

const AgentLeadDetails = (props) => {
	const [value1, setValue1] = useState(1);
	const leadOptions = [
		{ name: "Agent Lead", value: 1 },
		{ name: "Agent", value: 2 },
		{ name: "Application Lead", value: 4 },
		{ name: "Application", value: 3 },
	];
	const selector = useSelector((state) => state.persistData.auth.user);

	const [funnelData, setFunnelData] = useState([]);
	const [funnelData2, setFunnelData2] = useState([]);
	const [funnelData3, setFunnelData3] = useState([]);
	const [funnelData4, setFunnelData4] = useState([]);

	const [dropdown, setDropdown] = useState("");
	const dashboardService = new CountryAdminDashboardService();

	const getData = (e) => {
		Promise.all([
			dashboardService.agent_block({ userName: e }).then((res) => {
				const arr = Object.entries(res);
				const data = arr.map((elm, i) => (
					<div key={i.toString()} className="col-12 md:col-2">
						<div className="c-box-dw">
							<h3>
								{Number(elm[1])}
								{i !== 0 && (
									<sub>
										/{" "}
										{Number(arr[0][1])
											? (
													(Number(elm[1]) * 100) /
													Number(arr[0][1])
											  ).toFixed(2)
											: 0.0}{" "}
										%
									</sub>
								)}
							</h3>

							<h2>{elm[0]?.replaceAll("_", " ")}</h2>
						</div>
					</div>
				));
				setFunnelData(data);
			}),
			dashboardService.agent_block_2({ userName: e }).then((res) => {
				const arr = Object.entries(res);
				const data = arr.map((elm, i) => (
					<div key={i.toString()} className="col-12 md:col-2">
						<div className="c-box-dw">
							<h3>
								{Number(elm[1])}
								{i !== 0 && (
									<sub>
										/{" "}
										{Number(arr[0][1])
											? (
													(Number(elm[1]) * 100) /
													Number(arr[0][1])
											  ).toFixed(2)
											: 0.0}{" "}
										%
									</sub>
								)}
							</h3>

							<h2>{elm[0]?.replaceAll("_", " ")}</h2>
						</div>
					</div>
				));
				setFunnelData2(data);
			}),
			dashboardService.agent_block_3({ userName: e }).then((res) => {
				const arr = Object.entries(res);
				const data = arr.map((elm, i) => (
					<div key={i.toString()} className="col-12 md:col-2">
						<div className="c-box-dw">
							<h3>
								{Number(elm[1])}
								{i !== 0 && (
									<sub>
										/{" "}
										{Number(arr[0][1])
											? (
													(Number(elm[1]) * 100) /
													Number(arr[0][1])
											  ).toFixed(2)
											: 0.0}{" "}
										%
									</sub>
								)}
							</h3>

							<h2>{elm[0]?.replaceAll("_", " ")}</h2>
						</div>
					</div>
				));
				setFunnelData3(data);
			}),
			dashboardService
				.agent_block_4_application_lead({ userName: e })
				.then((res) => {
					const arr = Object.entries(res);
					const data = arr.map((elm, i) => (
						<div key={i.toString()} className="col-12 md:col-2">
							<div className="c-box-dw">
								<h3>
									{Number(elm[1])}
									{i !== 0 && (
										<sub>
											/{" "}
											{Number(arr[0][1])
												? (
														(Number(elm[1]) * 100) /
														Number(arr[0][1])
												  ).toFixed(2)
												: 0.0}{" "}
											%
										</sub>
									)}
								</h3>

								<h2>{elm[0]?.replaceAll("_", " ")}</h2>
							</div>
						</div>
					));
					setFunnelData4(data);
				}),
		]);
	};
	useEffect(() => {
		getData(null);
	}, []);
	return (
		<div className="c-agent-box-1 c-agent-lead-w">
			<div className="flex align-items-center md:pb-4 w-full justify-content-between flex-wrap">
				<SelectButton
					value={value1}
					options={leadOptions}
					optionLabel="name"
					onChange={(e) => setValue1(e.value)}
				/>
				{selector.role.position === 1030 ? null : (
					<div className="flex justify-content-between pb-2 c-w-full">
						<Dropdown
							className="border-round-md"
							filter
							options={props?.tsrmlist?.map((elm) => ({
								...elm,
								name:
									(elm?.firstName ? elm?.firstName : "") +
									" " +
									(elm?.middleName ? elm?.middleName : "") +
									" " +
									(elm?.lastName ? elm?.lastName : ""),
							}))}
							optionLabel="name"
							optionValue="userName"
							placeholder="Tsrm"
							value={dropdown}
							onChange={(e) => {
								getData(e.value);
								setDropdown(e.value);
							}}
						/>
						<Button
							icon={"pi pi-refresh"}
							onClick={() => {
								setDropdown("");
								getData(null);
							}}
							className="p-button-text c-btn-height"
						/>
					</div>
				)}
			</div>

			<div className="grid c-box-dw-grid">
				{value1 === 1 && funnelData}
				{value1 === 3 && funnelData3}
				{value1 === 2 && funnelData2}
				{value1 === 4 && funnelData4}

				{/* <div className="col-12 md:col-2">
					<div className="c-box-dw">
						<h3>529</h3>
						<h2>Total Agent leads </h2>
					</div>
				</div>
				<div className="col-12 md:col-2">
					<div className="c-box-dw">
						<h3>
							45/ <sub>20%</sub>
						</h3>
						<h2>Registered </h2>
					</div>
				</div>
				<div className="col-12 md:col-2">
					<div className="c-box-dw">
						<h3>100%</h3>
						<h2>Interested & Downloaded </h2>
					</div>
				</div>
				<div className="col-12 md:col-2">
					<div className="c-box-dw">
						<h3>100%</h3>
						<h2>Not Interested & No Response </h2>
					</div>
				</div>
				<div className="col-12 md:col-2">
					<div className="c-box-dw">
						<h3>100%</h3>
						<h2>Follow-UP In-process </h2>
					</div>
				</div> */}
				{/* <div className="col-12 md:col-2">
					<div className="c-box-dw">
						<h3>100%</h3>
						<h2>Total application Leads </h2>
					</div>
				</div>
				<div className="col-12 md:col-2">
					<div className="c-box-dw">
						<h3>100%</h3>
						<h2>Total Applications </h2>
					</div>
				</div>
				<div className="col-12 md:col-2">
					<div className="c-box-dw">
						<h3>100%</h3>
						<h2>Active Agents </h2>
					</div>
				</div>
				<div className="col-12 md:col-2">
					<div className="c-box-dw">
						<h3>100%</h3>
						<h2>In-Active Agents </h2>
					</div>
				</div>
				<div className="col-12 md:col-2">
					<div className="c-box-dw">
						<h3>100%</h3>
						<h2>Dormant Agents </h2>
					</div>
				</div> */}

				{/* <div className="col-12 md:col-2">
					<div className="" style={{ minHeight: 150 }}>
						<p className="c-kyc-hw">
							<small>Registered :</small>
						</p>
						<span>45 / 20%</span>
					</div>
				</div>
				<div className="col-12 md:col-2">
					<div className="" style={{ minHeight: 150 }}>
						<p className="c-kyc-hw">
							<small>KYC Pending :</small>
						</p>
						<span></span>
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default AgentLeadDetails;
