import { useEffect, useReducer, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import EmployeeCommission from "./EmployeeCommission";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import { ProgramService } from "../../../../services/ProgramService";
import Config from "../../../../shared/config";
import Loader from "../../../../components/Loader";
import moment from "moment/moment";
import debounce from "lodash.debounce";
const EditEmployeeCommission = () => {
	const { programId } = useParams();
	const [employeeState, employeeDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const [spinner, setSpinner] = useState(false);
	const programService = new ProgramService();
	const toast = useRef(null);
	const dialogReducer = (state, action) => {
		switch (action.type) {
			case "Id":
				return { ...state, id: action.payload };
			case "Dialog":
				return { ...state, dialog: action.payload };

			default:
				return state;
		}
	};
	const [dialogState, dialogDispatch] = useReducer(dialogReducer, {
		dialog: false,
		id: null,
	});
	const getAllCommissionData = (e) => {
		employeeDispatch({ type: Config.FETCH_CONFIG.start });
		programService
			.getAllCommissionById(e)
			.then((res) =>
				employeeDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				})
			)
			.catch((error) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				})
			)
			.finally(() =>
				employeeDispatch({
					type: Config.FETCH_CONFIG.stop,
				})
			);
	};

	useEffect(() => {
		let subscribe = true;
		if (subscribe) {
			getAllCommissionData(programId);
		}
		return () => (subscribe = false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFormSubmit = (value) => {
		setSpinner(true);
		const { effectiveStartDate, effectiveEndDate, ...rest } = value;
		const newData = {
			...rest,
			effectiveStartDate: moment(effectiveStartDate).format("YYYY-MM-DD"),
			effectiveEndDate: moment(effectiveEndDate).format("YYYY-MM-DD"),
		};
		programService
			.updateEmployeeCommission(newData)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					life: 3000,
				});
			})
			.then(debounce(() => getAllCommissionData(programId), 1000))
			.catch((err) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: err.message,
					life: 3000,
				});
			})
			.finally(() => {
				setSpinner(false);
			});
	};
	return (
		<>
			<Toast ref={toast} />
			{spinner && <Loader />}
			<Dialog
				header={"Edit Commission"}
				style={{ minWidth: "60%" }}
				visible={dialogState.dialog}
				onHide={() =>
					dialogDispatch({ type: "Dialog", payload: false })
				}
			>
				<EmployeeCommission
					initialValue={employeeState.data.find(
						(item) => item.id === dialogState.id
					)}
					handleFormSubmit={handleFormSubmit}
				/>
			</Dialog>

			{employeeState.isLoading ? (
				<Loader />
			) : (
				<>
					{employeeState.data && employeeState.data.length > 0 ? (
						employeeState.data.map((item, index) => (
							<Card
								key={item.id}
								style={{ marginBottom: "20px" }}
							>
								<div className="flex align-items-center justify-content-between">
									<h3 className="c-round-text">
										{index + 1}
									</h3>{" "}
									<Button
										icon={"pi pi-pencil "}
										onClick={() => {
											dialogDispatch({
												type: "Dialog",
												payload: true,
											});
											dialogDispatch({
												type: "Id",
												payload: item.id,
											});
										}}
										className="p-button-rounded"
										tooltip="Edit"
										tooltipOptions={{ position: "bottom" }}
									/>
								</div>

								<div className="grid mt-2">
									<div className="col-12 md:col-3">
										<div className="c-box-w">
											<i className="pi pi-user"></i>
											<h4 className="m-0">User</h4>
											<p>{item.userType}</p>
										</div>
									</div>
									<div className="col-12 md:col-3">
										<div className="c-box-w">
											<i className="pi pi-calendar"></i>
											<h4 className="m-0">Start Date</h4>
											<p>
												{moment(
													item.effectiveStartDate
												).format("Do MMM, YYYY")}
											</p>
										</div>
									</div>
									<div className="col-12 md:col-3">
										<div className="c-box-w">
											<i className="pi pi-calendar"></i>
											<h4 className="m-0">End Date</h4>
											<p>
												{moment(
													item.effectiveEndDate
												).format("Do MMM, YYYY")}
											</p>
										</div>
									</div>
									<div className="col-12 md:col-3">
										<div className="c-box-w">
											<i className="pi pi-chart-pie"></i>
											<h4 className="m-0">
												Commission type
											</h4>
											<p className="capitalize">
												{item.commissionType}
											</p>
										</div>
									</div>

									<DataTable
										className="col-12 py-3"
										value={item.agentCommissionDetails}
										scrollable
									>
										<Column
											field="minSaleAmount"
											header="Min Sell Amount"
										/>
										<Column
											field="maxSaleAmount"
											header="Max Sell Amount"
										/>
										<Column
											field="amount"
											header="Amount"
										/>
									</DataTable>
								</div>
							</Card>
						))
					) : (
						<h1>No Data</h1>
					)}
				</>
			)}
		</>
	);
};

export default EditEmployeeCommission;
