import { Button } from "primereact/button";
import error from "../assets/images/404.png";
import { useNavigate } from "react-router-dom";
const ErrorPage = () => {
	const navigate = useNavigate();
	return (
		<div className="error-wrapper">
			<div className="error-container">
				<img src={error} alt={error} />
				<h1 className="text-white mb-4">Page not found</h1>
				<Button
					className="p-button-outlined text-white"
					label="Go Home"
					icon={"pi pi-home"}
					onClick={() => navigate("/")}
				/>
			</div>
		</div>
	);
};

export default ErrorPage;
