import { useState, useReducer, useEffect, useRef, memo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Dropdown } from "primereact/dropdown";
import { UsersService } from "../../../../services/UsersService";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import TableLoader from "../../../../components/TableLoader";
import TableNoData from "../../../../components/TableNoData";
const DialogSelector = (props) => {
	const session = JSON.parse(sessionStorage.getItem("user"));

	const userservice = new UsersService();

	const toast = useRef(null);
	const [userData, setUserData] = useState([]);
	const reducer = (state, action) => {
		switch (action.type) {
			case "SELECTED":
				return { ...state, selected: action.payload };
			case "USER_NAME":
				return {
					...state,
					user: action.payload,
				};
			case "AGENT":
				return {
					...state,
					agent: action.payload,
				};
			case "SPINNER":
				return {
					...state,
					spinner: action.payload,
				};

			default:
				return state;
		}
	};
	const [state, dispatch] = useReducer(reducer, {
		selected: [],
		user: null,
		agent: [],
		spinner: false,
	});
	const handleSelect = (data) => {
		dispatch({ type: "SELECTED", payload: data });
	};
	const itaration = {
		profileType: "",
		firstName: "",
		pincode: "",
		mobileNumber: "",
		emailId: "",
		action: "",
	};
	const getUser = () => {
		userservice
			.getAgentProfile()
			.then((res) => {
				let tempUser = res.map((item) => {
					return {
						...item,
						name: `${item.firstName ? item.firstName : ""} ${
							item.middleName ? item.middleName : ""
						} ${item.lastName ? item.lastName : ""}`,
					};
				});
				setUserData(tempUser);
				dispatch({ type: "SPINNER", payload: false });
			})
			.catch((e) => dispatch({ type: "SPINNER", payload: false }));
	};
	useEffect(() => {
		dispatch({ type: "SPINNER", payload: true });

		getUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (val) => {
		dispatch({ type: "SPINNER", payload: true });
		dispatch({ type: "USER_NAME", payload: val });
		dispatch({ type: "SELECTED", payload: [] });
		userservice
			.getAgentById(val.userName)
			.then((res) => {
				dispatch({ type: "AGENT", payload: res });
			})
			.catch((e) => {
				toast.current.show([
					{
						severity: "error",
						summary: "Error",
						detail: "Error",
						life: 3000,
					},
				]);
			})
			.finally(() => dispatch({ type: "SPINNER", payload: false }));
	};

	const nameTemplate = ({ firstName, middleName, lastName }) => {
		return firstName + " " + middleName + " " + lastName;
	};
	const handleClick = () => {
		const newArray = state.selected.map((e) => e.profileId);
		const { userName } = state.user;
		const payload = JSON.stringify({
			userName: userName,
			profileId: newArray,
		});
		props.handelAgentAllocate(payload);
	};

	const selectAgent = () => {
		switch (session.role.name) {
			case "Zonal Manager":
				return "Area Sales Manager";
			case "Area Sales Manager":
				return "Regional Manager";
			case "Regional Manager":
				return "Sales Executive";

			default:
				return "User";
		}
	};
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column style={{ width: "3em" }} />
				<Column header={"Profile Type"} />
				<Column header={"Pin"} />
				<Column header={"Name"} />
				<Column header={"Mobile No"} />
				<Column header={"Email "} />
			</Row>
		</ColumnGroup>
	);
	return (
		<div className="modal-form-grid">
			<Toast ref={toast} />
			<div className="flex">
				{userData.length > 0 ? (
					<div className="mb-5">
						<p className="font-bold">Select {selectAgent()}</p>
						<Dropdown
							value={state.user}
							options={userData}
							onChange={(e) => {
								handleChange(e.value);
							}}
							placeholder={selectAgent()}
							optionLabel={"name"}
							className="border-round-md"
						/>
					</div>
				) : state.spinner ? (
					<ProgressSpinner strokeWidth="6" style={{ width: 30 }} />
				) : (
					<div className="text-center w-full">No data found!</div>
				)}
			</div>
			{state.spinner && state.user ? (
				<TableLoader itaration={itaration} headerGroup={headerGroup} />
			) : state.agent.length > 0 ? (
				<>
					<DataTable
						value={state.agent}
						selectionMode="checkbox"
						selection={state.selected}
						onSelectionChange={(e) => {
							handleSelect(e.value);
						}}
						dataKey="profileId"
						responsiveLayout="scroll"
						breakpoint="960px"
						headerColumnGroup={headerGroup}
					>
						<Column
							selectionMode="multiple"
							headerStyle={{ width: "3em" }}
						/>
						<Column field="profileType" />
						<Column field="pincode" />
						<Column body={nameTemplate} />
						<Column field="mobileNumber" />
						<Column field="emailId" />
					</DataTable>
					<div className="flex justify-content-end mt-6">
						<Button
							label="Cancel"
							className="p-button-danger mr-3"
							onClick={() =>
								dispatch({
									type: "SELECTED",
									payload: [],
								})
							}
						/>
						<Button
							label="Add"
							className="primary-btn"
							disabled={
								state.selected.length === 0 ? true : false
							}
							onClick={handleClick}
						/>
					</div>
				</>
			) : (
				state.user !== null &&
				state.agent.length === 0 &&
				!state.spinner && (
					<TableNoData headerColumnGroup={headerGroup} />
				)
			)}
		</div>
	);
};

export default memo(DialogSelector);
