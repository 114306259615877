import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import Config from "../../../../shared/config";
import {
	FeildCalender,
	FeildDropdown,
	FieldCheckBox,
	FieldInput,
} from "../../../../components/FieldInput";
import {
	useGetCityListQuery,
	useGetStateListQuery,
} from "../../../../services/query/queryApi";
import { SplitButton } from "primereact/splitbutton";
import { useSelector } from "react-redux";
import { CallCenterService } from "../../../../services/callCenterService";

const SearchAgentLead = (props) => {
	const { data: stateList = [] } = useGetStateListQuery();
	const { data: cityList = [] } = useGetCityListQuery();

	const callcenterService = new CallCenterService();

	const [userList, setUserList] = useState([]);
	const selector = useSelector(
		(state) => state.persistData?.auth?.user?.role?.position
	);
	const [open, setOpen] = useState(false);
	const initialValues = {
		agentLeadName: "",
		stateId: "", //number
		cityId: "", //number
		source: "",
		assignedTo: "",
		hiddenBy: "",
		includeHidden: "",
		onlyHidden: "",
		fromDate: "",
		toDate: "",
		currentStatus: "",
		mobileNumber: "",
		isCalled: false,
		lastCalledTo: "",
		lastCalledFrom: "",
	};

	const handleSearch = (value) => {
		let data = Object.fromEntries(
			Object.entries(value).filter(([_, v]) => v !== "")
		);
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: data,
		});
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};

	const items = [
		{
			label: "Archive",
			icon: "pi pi-arrow-down",
			command: () => {
				props.setVisible((e) => ({
					...e,
					confirm: true,
					id: props.selectedProducts.map((item) => item.id),
				}));
			},
		},
		{
			label: "Allocate",
			icon: "pi pi-sitemap",
			command: () => {
				props.setProfileDetailDialog((prev) => ({
					...prev,
					state: true,
					data: props.selectedProducts.map((item) => item.id),
				}));
			},
		},
		{
			label: "Add Note",
			icon: "pi pi-file-edit",
			command: () => {
				props.setNoteDetailDialog((prev) => ({
					...prev,
					state: true,
					id: props.selectedProducts.map((item) => item.id),
				}));
			},
		},
		// {
		// 	label: "Update Status",
		// 	icon: "pi pi-history",
		// 	command: () => {
		// 		props.setStatusDetailDialog((prev) => ({
		// 			...prev,
		// 			state: true,
		// 			id: props.selectedProducts.map((item) => item.id),
		// 		}));
		// 	},
		// },
		{
			label: "Set Reminder",
			icon: "pi pi-history",
			command: () => {
				props.setReminderDetailDialog((prev) => ({
					...prev,
					state: true,
					id: props.selectedProducts.map((item) => item.id),
				}));
			},
		},
		{
			label: "Whatsapp",
			icon: "pi pi-whatsapp",
			command: () => {
				props.setVisibleMessage((e) => ({
					...e,
					confirm: true,
					id: props.selectedProducts.map((item) => item.id),
					type: "WHATSAPP",
				}));
			},
		},
		{
			label: "Email",
			icon: "pi pi-envelope",
			command: () => {
				props.setVisibleMessage((e) => ({
					...e,
					confirm: true,
					id: props.selectedProducts.map((item) => item.id),
					type: "EMAIL",
				}));
			},
		},
	];
	const getTsrmBYHubSpoke = (val) => {
		callcenterService.getTsrmByHubIdspokeId(val).then((res) => {
			setUserList(res?.data);
		});
	};
	useEffect(() => {
		selector === 1000 &&
			getTsrmBYHubSpoke({
				pageNo: 1,
				pageSize: 1000000,
				hubId: null,
				spokeId: null,
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selector]);

	return (
		<>
			<div className="w-full py-3 flex align-items-center justify-content-between">
				<h3 className="m-0">All Agent Lead</h3>
				<div className="flex gap-2">
					<Button
						label={open ? "Close" : "Search"}
						icon={open ? "pi pi-times" : "pi pi-search"}
						className={
							open
								? "p-button-outlined p-button-danger"
								: "p-button-outlined"
						}
						aria-label="Cancel"
						onClick={() => setOpen((prev) => !prev)}
					/>
					<Button
						label="Reload"
						icon="pi pi-refresh"
						className="p-button-outlined"
						onClick={() => {
							props.searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							});
						}}
					/>
					{selector === 3000 && (
						<Button
							label="Bulk Upload"
							icon="pi pi-arrow-up"
							onClick={() => {
								props.setBulkDialogue(true);
							}}
						/>
					)}
					{props.selectedProducts.length > 0 && (
						<SplitButton
							icon={"pi pi-bars"}
							label="Action"
							model={items}
							// loading={spinner}
						/>
					)}
				</div>
			</div>

			{open && (
				<Formik
					initialValues={initialValues}
					enableReinitialize
					onSubmit={handleSearch}
					// validationSchema={validationSchema}
				>
					{({ handleSubmit, resetForm, values }) => (
						<Form
							className="background-secondary c-search-form-box border-round-md form-grid grid"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 md:col-3">
								<Field
									header={"From Date"}
									name={"fromDate"}
									component={FeildCalender}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"To Date"}
									name={"toDate"}
									component={FeildCalender}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Status"}
									name={"currentStatus"}
									component={FeildDropdown}
									options={Config.AGENTLEADSTATUSEARCHS}
								/>
							</div>
							{selector === 1000 && (
								<div className="field col-12 md:col-3">
									<Field
										header={"Assign to (TSRM)"}
										name={"assignedTo"}
										filter
										component={FeildDropdown}
										options={userList.map((elm) => ({
											...elm,
											name:
												(elm?.firstName
													? elm?.firstName
													: "") +
												" " +
												(elm?.middleName
													? elm?.middleName
													: "") +
												" " +
												(elm?.lastName
													? elm?.lastName
													: "") +
												" (" +
												elm?.userName +
												")",
										}))}
										optionLabel="name"
										optionValue={"userName"}
									/>
								</div>
							)}
							<div className="field col-12 md:col-3">
								<Field
									header={"Name"}
									name={"agentLeadName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Mobile"}
									name={"mobileNumber"}
									component={FieldInput}
									type={"number"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"City"}
									name={"cityId"}
									filter
									component={FeildDropdown}
									options={cityList}
									optionLabel={"name"}
									optionValue={"id"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"State"}
									name={"stateId"}
									filter
									component={FeildDropdown}
									options={stateList}
									optionLabel={"name"}
									optionValue={"id"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Last Call From Date"}
									name={"lastCalledFrom"}
									component={FeildCalender}
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									header={"Last Call From To Date"}
									name={"lastCalledTo"}
									component={FeildCalender}
								/>
							</div>
							<div className="field col-12 md:col-4 mt-auto mb-auto">
								<div className="flex flex-wrap gap-1 c-search-form-chackbox c-search-list-radio">
									<div className="flex align-items-center">
										{!values.includeHidden && (
											<Field
												header={"Archive only"}
												name={"onlyHidden"}
												component={FieldCheckBox}
											/>
										)}
									</div>
									<div className="flex align-items-center">
										{!values.onlyHidden && (
											<Field
												header={"Archive  included"}
												name={"includeHidden"}
												component={FieldCheckBox}
											/>
										)}
									</div>
									<div className="flex align-items-center">
										<Field
											header={"Called"}
											name={"isCalled"}
											component={FieldCheckBox}
										/>
									</div>
								</div>
							</div>

							<div className="col-12 md:col-2 flex align-items-end justify-content-end">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Search" />
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default SearchAgentLead;
