import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";

const TableLoader = (props) => {
	const lodarTblArray = Array.from({ length: 10 });
	const skeletonBody = () => {
		return <Skeleton height="1rem"></Skeleton>;
	};
	return (
		<DataTable
			headerColumnGroup={props.headerGroup}
			value={lodarTblArray}
			responsiveLayout="stack"
			breakpoint="960px"
		>
			{Object.keys(props.itaration).map((item) => (
				<Column key={item} body={skeletonBody} />
			))}
		</DataTable>
	);
};

export default TableLoader;
