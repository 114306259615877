import Config from "../shared/config";
import { produce } from "immer";
export const fetchInitialState = {
	data: [],
	isLoading: false,
	error: false,
	errorMessage: null,
	isFetched: false,
};
export const dataReducer = produce((state, action) => {
	switch (action.type) {
		case Config.FETCH_CONFIG.start:
			state.isLoading = true;
			break;
		case Config.FETCH_CONFIG.success:
			state.data = action.payload;
			break;
		case Config.FETCH_CONFIG.error:
			state.error = true;
			state.errorMessage = action.payload;
			break;
		case Config.FETCH_CONFIG.ini:
			state.data = [];
			state.error = false;
			state.errorMessage = null;
			state.isLoading = false;
			break;
		case Config.FETCH_CONFIG.stop:
			state.isLoading = false;
			break;
		case Config.FETCH_CONFIG.fetched:
			state.isFetched = true;
			break;

		default:
			return state;
	}
});

export const sortReducer = (state, action) => {
	switch (action.type) {
		case "SORTBYCOLUMN":
			return { ...state, sortByColumn: action.payload };
		case "SORTTYPE":
			return { ...state, sortType: action.payload };
		case "SEARCH":
			return { ...state, searchValue: action.payload };

		default:
			break;
	}
};
