import React, { useState, useRef, useReducer, useEffect } from "react";
import ProgramDialog from "./setUpDialog/ProgramDialog";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { Dialog } from "primereact/dialog";
import { ProgramService } from "../../../services/ProgramService";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import Config from "../../../shared/config";
import SearchProgram from "./components/SearchProgram";
import Paginator from "../../../components/Paginator";
import {
	searchInitialState,
	searchReducer,
} from "../../../components/searchReducer";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import TableLoader from "../../../components/TableLoader";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { InputSwitch } from "primereact/inputswitch";
import Loader from "../../../components/Loader";
import EmployeeCommission from "./components/EmployeeCommission";
import moment from "moment";
import MenuComponent from "../../../components/MenuComponent";
import ProgramEdit from "./programEdit/programEdit";
import ProgramCopy from "./progeamCopy/programCopy";
import { Field, Form, Formik } from "formik";
import { FeildDropdown } from "../../../components/FieldInput";
import {
	useGetDsaInstitutionDetailsQuery,
	useGetInstitutionDetailsQuery,
} from "../../../services/query/queryApi";
import { InstitutionService } from "../../../services/InstitutionService";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setEnable, setId } from "../../../store/reducer/ProgramReducer";

const ProgramList = () => {
	const [showDialog, setShowDialog] = useState(false);
	const [newProgram, setNewProgram] = useState(false);
	const toast = useRef(null);
	const dispatch = useDispatch();
	const { data: dsaInstitute = { data: [] } } =
		useGetDsaInstitutionDetailsQuery({
			pageNo: 1,
			pageSize: 5000,
		});
	const [productList, setProductList] = useState({
		institutionProduct: [],
	});
	const { data = [] } = useGetInstitutionDetailsQuery();
	const navigate = useNavigate();
	const institutionService = new InstitutionService();
	const programService = new ProgramService();
	const [spinner, setSpinner] = useState(false);
	const [programState, programDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const setDaialog = (e) => setShowDialog(e);
	const dialogReducer = (state, action) => {
		switch (action.type) {
			case "Id":
				return { ...state, id: action.payload };
			case "Dialog":
				return { ...state, dialog: action.payload };
			case "Type":
				return { ...state, type: action.payload };

			default:
				return state;
		}
	};
	const [dialogState, dialogDispatch] = useReducer(dialogReducer, {
		dialog: false,
		id: null,
	});
	const [editDialog, editDialogDispatch] = useReducer(dialogReducer, {
		dialog: false,
		id: null,
		type: "edit",
	});
	const [copyDialog, copyDialogDispatch] = useReducer(dialogReducer, {
		dialog: false,
		id: null,
		type: "copy",
	});
	const getAllProgramList = () => {
		const body = {
			...searchState.searchValue,
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.sortByColumn &&
				searchState.sortType && {
					sorts: [
						searchState.sortByColumn + "," + searchState.sortType,
					],
				}),
		};
		const demo = Object.fromEntries(
			Object.entries(body).filter(([_, v]) => v !== "")
		);
		programDispatch({ type: Config.FETCH_CONFIG.start });
		programService
			.getAllProgramList(demo)
			.then((res) => {
				programDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((e) => {})
			.finally(() => programDispatch({ type: Config.FETCH_CONFIG.stop }));
	};

	useEffect(() => {
		getAllProgramList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);

	const deleteProgram = (e) => {
		setSpinner(true);
		programService
			.deleteProgram(e)
			.then((res) =>
				toast.current.show({
					severity: "info",
					summary: "Info Message",
					detail: "Program Deleted",
					life: 3000,
				})
			)
			.catch((error) =>
				toast.current.show({
					severity: "error",
					summary: "Error Message",
					detail: "Unable to delete program",
					life: 3000,
				})
			)
			.finally(() => setSpinner(false));
	};
	const reject = () => {
		toast.current.show({
			severity: "warn",
			summary: "Rejected",
			detail: "You have rejected",
			life: 3000,
		});
	};

	const showConfirm = (data) => {
		confirmDialog({
			message: "Are you sure you want to proceed?",
			header: "Confirmation",
			icon: "pi pi-exclamation-triangle",
			accept: () => deleteProgram(data),
			reject: () => reject(),
		});
	};

	// const addCommission = (id) => {
	// 	dialogDispatch({
	// 		type: "Dialog",
	// 		payload: true,
	// 	});
	// 	dialogDispatch({
	// 		type: "Id",
	// 		payload: id,
	// 	});
	// };
	const handleSubProduct1 = (e, setFieldValue, dsaDetails) => {
		setFieldValue("institutionId", e.value, true);
		console.log(dsaDetails);
		setFieldValue("name", dsaDetails?.dsaName + "_" + e.value.bankName);
		setFieldValue(
			"productId",
			{
				id: null,
			},
			true
		);
		institutionService
			.getInstitutionWiseProductList(e.value?.id)
			.then((res) => setProductList(res))
			.catch((e) => {});
	};
	const handleSubProduct2 = (e, setFieldValue) => {
		setFieldValue("institutionId", e.value, true);
		setFieldValue("name", e.value.name);
		setFieldValue("productId", null, true);
		institutionService
			.getInstitutionWiseProductList(e.value?.id)
			.then((res) => setProductList(res))
			.catch((e) => {});
	};
	const actionTemplate = (item) => {
		const items = [
			{
				label: "Edit",
				icon: "pi pi-pencil",
				command: () => {
					// editDialogDispatch({ type: "Dialog", payload: true });
					// editDialogDispatch({ type: "Id", payload: item.programId });
					// editDialogDispatch({ type: "Type", payload: "edit" });
					dispatch(setId(null));
					dispatch(setEnable(0));
					navigate("/partners/program-management-create", {
						state: {
							programData: {
								institutionId: { id: item?.institutionId },
								productId: {
									subProductId: item?.subProductId,
									alias: item?.alias,
								},
							},
							type: "edit",
							programId: item.programId,
						},
					});
				},
			},
			{
				label: "Copy",
				icon: "pi pi-copy",
				command: () => {
					// dispatch(setId(null));
					// copyDialogDispatch({ type: "Dialog", payload: true });
					// copyDialogDispatch({ type: "Id", payload: item.programId });
					// copyDialogDispatch({ type: "Type", payload: "copy" });
					dispatch(setId(null));
					dispatch(setEnable(0));
					navigate("/partners/program-management-create", {
						state: {
							programData: {
								institutionId: { id: item?.institutionId },
								productId: {
									subProductId: item?.subProductId,
									alias: item?.alias,
								},
							},
							type: "copy",
							programId: item.programId,
						},
					});
				},
			},
			{
				label: "Delete",
				icon: "pi pi-trash",
				command: () => {
					showConfirm(item.programId);
				},
			},
		];

		return (
			// <div className="flex">
			// 	<Button
			// 		className="icon-btn primary-btn mr-2"
			// 		onClick={() =>
			// 			navigate(
			// 				`/partners/program-setup-management/edit/${item.programId}`
			// 			)
			// 		}
			// 		tooltip="Edit"
			// 		tooltipOptions={{ position: "bottom" }}
			// 	>
			// 		<MdModeEditOutline />
			// 	</Button>
			// 	<Button
			// 		className="icon-btn primary-btn mr-2"
			// 		onClick={() =>
			// 			navigate(
			// 				`/partners/program-setup-management/copy/${item.programId}`
			// 			)
			// 		}
			// 		tooltip="Copy Program"
			// 		tooltipOptions={{ position: "bottom" }}
			// 	>
			// 		<MdContentCopy />
			// 	</Button>
			// 	<Button
			// 		onClick={() => showConfirm(item.programId)}
			// 		className="icon-btn bg-red-800 text-yellow-50 mr-2"
			// 		tooltip="Delete"
			// 		tooltipOptions={{ position: "bottom" }}
			// 	>
			// 		{" "}
			// 		<MdDelete />
			// 	</Button>
			// 	<Button
			// 		icon={"pi pi-verified"}
			// 		// onClick={() => {
			// 		// 	setCommissionDialog(true);
			// 		// }}
			// 		onClick={() => addCommission(item.programId)}
			// 		className="icon-btn p-button-info text-yellow-50 mr-2"
			// 		tooltip="Add Commission"
			// 		tooltipOptions={{ position: "bottom" }}
			// 	/>
			// 	<Button
			// 		icon={"pi pi-user-edit"}
			// 		onClick={() =>
			// 			navigate(
			// 				`/partners/program-setup-management/commission/${item.programId}`
			// 			)
			// 		}
			// 		className="icon-btn p-button-success text-yellow-50 mr-2"
			// 		tooltip="View all Commission"
			// 		tooltipOptions={{ position: "left" }}
			// 	/>
			// </div>
			<MenuComponent items={items} />
		);
	};

	const itaration = {
		institutionId: "",
		productId: "",
		subproductId: "",
		name: "",
		status: "",
		action: "",
		action2: "",
	};
	const onEditClick = (item) => {
		let requestBody = {
			programId: item.programId,
			activeStatus: !item.activeStatus,
		};

		programService.statusUpdate(requestBody).then((res) => {
			if (res) {
				toast.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Program Status Updated Successfully",
					style: { color: "#000000" },
					life: 3000,
				});
				getAllProgramList();
				setSpinner(false);
			}
		});
	};
	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	function tblSort(columnName, sortType) {
		// setSortByColumn(columnName);
		// setSortType(sortType);

		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}
	const validationSchema = Yup.object().shape({
		selectType: Yup.string().nullable().required("This Field is required"),
		dsaDetails: Yup.object().nullable().required("This Field is required"),
		institutionId: Yup.object()
			.nullable()
			.required("This Field is required"),
		productId: Yup.object().nullable().required("This Field is required"),
	});
	const statusUpdateTemplate = (item) => {
		return (
			<InputSwitch
				checked={item.activeStatus}
				onChange={() => {
					onEditClick(item);
					setSpinner(true);
				}}
				className="switch_toggle"
				tooltip="Change Status"
			/>
		);
	};
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />
				<Column header={getSortableColumn("Program Name", "name")} />
				<Column
					header={getSortableColumn(
						"Institution Name",
						"institutionId"
					)}
				/>
				<Column
					header={getSortableColumn("Product Name", "productId")}
				/>
				<Column
					header={getSortableColumn(
						"Subproduct Name",
						"subProductId"
					)}
				/>
				<Column header={"Status"} />
				<Column header={"Actions"} />
			</Row>
		</ColumnGroup>
	);

	const addCommissionAgent = (value, action) => {
		const { effectiveStartDate, effectiveEndDate, ...rest } = value;
		let requestBody = {
			...rest,
			program: dialogState.id,
			effectiveStartDate: moment(effectiveStartDate).format("YYYY-MM-DD"),
			effectiveEndDate: moment(effectiveEndDate).format("YYYY-MM-DD"),
		};

		// console.log(requestBody);
		setSpinner(true);
		programService
			.addCommissionAgent(requestBody)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Successfull",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error Message",
					detail: e.message,
					life: 3000,
				});
			})
			.finally(() => {
				setSpinner(false);
				action.resetForm();
			});
	};
	const newData =
		programState.data.data &&
		programState.data.data.map((elm) => ({
			"Program Id": elm.programId,
			"Program Name": elm.name,
			"Sub Product Name": elm.subProductName,
			ProductName: elm.productName,
			"Line Of Business": elm.lob,
			"FI Name": elm.institutionName,

			"Active Status": elm.activeStatus,
		}));
	return (
		<>
			<Dialog
				header={"Edit"}
				style={{ width: "70%" }}
				visible={editDialog.dialog}
				onHide={() => {
					editDialogDispatch({ type: "Dialog", payload: false });
					editDialogDispatch({ type: "Id", payload: null });
				}}
			>
				<ProgramEdit programId={editDialog.id} type={editDialog.type} />
			</Dialog>
			<Dialog
				visible={copyDialog.dialog}
				header={"Copy"}
				style={{ width: "70%" }}
				onHide={() => {
					copyDialogDispatch({ type: "Dialog", payload: false });
					copyDialogDispatch({ type: "Id", payload: null });
				}}
			>
				<ProgramCopy programId={copyDialog.id} />
			</Dialog>
			<SearchProgram
				searchDispatch={searchDispatch}
				setNewProgram={setNewProgram}
				dialog={setShowDialog}
				newData={newData}
			/>
			{spinner && <Loader />}
			<Dialog
				draggable={false}
				visible={newProgram}
				style={{ width: "30%" }}
				header={"Select Institution"}
				onHide={() => setNewProgram(false)}
			>
				<Formik
					initialValues={{
						selectType: "",
						dsaDetails: { details: [] },
						institutionId: null,
						productId: null,
					}}
					onSubmit={(value) =>
						navigate("/partners/program-management-create", {
							state: {
								programData: value,
								type: "create",
								programId: null,
							},
						})
					}
					validationSchema={validationSchema}
				>
					{({ values, setFieldValue, handleSubmit }) => (
						<Form
							className="grid form-grid modal-form-grid mt-1"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 ">
								<Field
									name="selectType"
									component={FeildDropdown}
									options={[
										{ label: "Institution", value: "INS" },
										{ label: "DSA", value: "DSA" },
									]}
									header={"Select Type"}
									star={"*"}
									value={values.selectType}
									onChange={(e) => {
										setFieldValue(
											"selectType",
											e.value,
											true
										);
										setFieldValue(
											"dsaDetails",
											{ details: [] },
											true
										);

										setFieldValue(
											"institutionId",
											"",
											true
										);
									}}
								/>
							</div>
							{values.selectType === "DSA" ? (
								<>
									<div className="field col-12 ">
										<Field
											filter
											name="dsaDetails"
											component={FeildDropdown}
											options={dsaInstitute.data}
											optionLabel={"dsaName"}
											header={"Select DSA"}
											star={"*"}
											value={values.dsaDetails}
											onChange={(e) => {
												setFieldValue(
													"dsaDetails",
													e.value,
													true
												);

												setFieldValue(
													"institutionId",
													null,
													true
												);
											}}
										/>
									</div>
									<div className="field col-12 ">
										<Field
											name="institutionId"
											component={FeildDropdown}
											options={values.dsaDetails?.details.map(
												(item) => ({
													...item,
													name: item.bankName,
												})
											)}
											filter
											optionLabel={"bankName"}
											header={"Bank"}
											star={"*"}
											value={values.institutionId}
											onChange={(e) =>
												handleSubProduct1(
													e,
													setFieldValue,
													values.dsaDetails
												)
											}
										/>
									</div>
								</>
							) : (
								values.selectType === "INS" && (
									<div className="field col-12 ">
										<Field
											name="institutionId"
											component={FeildDropdown}
											options={data}
											filter
											optionLabel={"name"}
											header={"Bank"}
											star={"*"}
											value={values.institutionId}
											onChange={(e) =>
												handleSubProduct2(
													e,
													setFieldValue
												)
											}
										/>
									</div>
								)
							)}
							{values.selectType && (
								<div className="field col-12 ">
									<Field
										name="productId"
										filter
										component={FeildDropdown}
										options={productList.institutionProduct}
										optionLabel="subProduct"
										header={"Sub Product"}
										star={"*"}
									/>
								</div>
							)}
							<div className="col-12">
								<Button
									className="w-full"
									type="submit"
									label="Next"
								/>
							</div>
						</Form>
					)}
				</Formik>
			</Dialog>
			<Dialog
				header="Program Setup"
				visible={showDialog}
				onHide={() => setShowDialog(false)}
				style={{ width: "80%" }}
			>
				<ProgramDialog
					dialog={setDaialog}
					getAllProgramList={getAllProgramList}
				/>
			</Dialog>
			<Dialog
				header="Employee Commission"
				style={{ minWidth: "60%" }}
				visible={dialogState.dialog}
				onHide={() =>
					dialogDispatch({ type: "Dialog", payload: false })
				}
			>
				<EmployeeCommission handleFormSubmit={addCommissionAgent} />
			</Dialog>
			<Toast ref={toast} />
			<ConfirmDialog />
			{programState.isLoading ? (
				<TableLoader itaration={itaration} headerGroup={headerGroup} />
			) : (
				<>
					{spinner && <Loader />}

					{programState.data?.data?.length > 10 && (
						<Paginator
							pageSize={programState.data.pageSize}
							firstPage={programState.data.firstPage}
							lastPage={programState.data.lastPage}
							totalElements={programState.data.totalElements}
							decrement={() =>
								searchDispatch({
									type: Config.SEARCH_CONFIG.decrement,
								})
							}
							increment={() =>
								searchDispatch({
									type: Config.SEARCH_CONFIG.increment,
								})
							}
							pagesizechange={(e) => {
								// let newPageNo = Math.ceil(
								// 	(programState.data.pageSize *
								// 		programState.data.pageNo) /
								// 		e.target.value
								// );
								searchDispatch({
									type: Config.SEARCH_CONFIG.pageSize,
									payload: e.target.value,
								});
								searchDispatch({
									type: Config.SEARCH_CONFIG.pageNo,
									payload: 1,
								});
							}}
							pageNo={programState.data.pageNo}
							totalPages={programState.data.totalPages}
						/>
					)}

					<DataTable
						// value={elem.programListDtoList}
						headerColumnGroup={headerGroup}
						value={
							programState.data.data &&
							programState.data.data.map((item, index) => ({
								...item,
								index:
									programState.data.pageSize *
										programState.data.pageNo -
									programState.data.pageSize +
									1 +
									index,
							}))
						}
						responsiveLayout="scroll"
					>
						<Column field="index" />
						<Column field={"name"} />

						<Column field={"institutionName"} />
						<Column field={"productName"} />
						<Column field={"subProductName"} />
						<Column body={statusUpdateTemplate} />
						<Column body={actionTemplate} />
					</DataTable>
					{programState.data?.data?.length > 0 && (
						<Paginator
							pageSize={programState.data.pageSize}
							firstPage={programState.data.firstPage}
							lastPage={programState.data.lastPage}
							totalElements={programState.data.totalElements}
							decrement={() =>
								searchDispatch({
									type: Config.SEARCH_CONFIG.decrement,
								})
							}
							increment={() =>
								searchDispatch({
									type: Config.SEARCH_CONFIG.increment,
								})
							}
							pagesizechange={(e) => {
								// let newPageNo = Math.ceil(
								// 	(programState.data.pageSize *
								// 		programState.data.pageNo) /
								// 		e.target.value
								// );
								searchDispatch({
									type: Config.SEARCH_CONFIG.pageSize,
									payload: e.target.value,
								});
								searchDispatch({
									type: Config.SEARCH_CONFIG.pageNo,
									payload: 1,
								});
							}}
							pageNo={programState.data.pageNo}
							totalPages={programState.data.totalPages}
						/>
					)}
				</>
			)}
		</>
	);
};

export default ProgramList;
