import { Field, Form, Formik } from "formik";
import { FeildDropdown } from "../../../../components/FieldInput";
import { Button } from "primereact/button";
import { useGetAllHubQuery } from "../../../../services/query/queryApi";
import { CallCenterService } from "../../../../services/callCenterService";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
const AllocateAgentForm = (props) => {
	const { data = [] } = useGetAllHubQuery();
	const callcenterService = new CallCenterService();
	const selector = useSelector((state) => state.persistData.auth.user.role);

	const [spokeList, setSpokeList] = useState([]);
	const [userList, setUserList] = useState([]);
	const initialValues = {
		hubId: "",
		spokeId: "",
		userName: "",
	};
	const getSpokeList = (e) => {
		callcenterService.getSpokeListById(e).then((res) => setSpokeList(res));
	};
	const validationSchema = Yup.object().shape({
		userName: Yup.string().required("This field is required."),
	});
	const handleSpokeList = (e, setFieldValue) => {
		setFieldValue("userName", "", true);
		setFieldValue("spokeId", "", true);
		setFieldValue("hubId", e.value, true);
		Promise.allSettled([
			getTsrmBYHubSpoke({
				pageNo: 1,
				pageSize: 1000000,
				hubId: e.value,
				spokeId: null,
			}),
			getSpokeList(e.value),
		]);
	};
	const getTsrmBYHubSpoke = (val) => {
		callcenterService.getTsrmByHubIdspokeId(val).then((res) => {
			setUserList(res?.data);
		});
	};
	const handleUserList = (e, setFieldValue, hubId) => {
		setFieldValue("userName", "", true);
		setFieldValue("spokeId", e.value, true);
		getTsrmBYHubSpoke({
			pageNo: 1,
			pageSize: 1000000,
			hubId: hubId,
			spokeId: e.value,
		});
	};
	const getSpokeListByUser = () => {
		callcenterService
			.getUSerWiseSpokeList()
			.then((res) => setSpokeList(res));
	};
	useEffect(() => {
		Promise.allSettled([
			getTsrmBYHubSpoke({
				pageNo: 1,
				pageSize: 1000000,
				hubId: null,
				spokeId: null,
			}),
			selector.position === 1020 && getSpokeListByUser(),
		]);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selector]);
	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize
			onSubmit={props.handleSearch}
			validationSchema={validationSchema}
		>
			{({ handleSubmit, resetForm, setFieldValue, values }) => (
				<Form
					className="modal-form-grid border-round-md mt-2 p-4 form-grid grid"
					onSubmit={handleSubmit}
				>
					{selector.position === 1000 && (
						<div className="field col-12 md:col-3">
							<Field
								header={"Hub"}
								name={"hubId"}
								filter
								component={FeildDropdown}
								onChange={(e) => {
									handleSpokeList(e, setFieldValue);
								}}
								options={data}
								optionLabel="name"
								optionValue={"id"}
							/>
						</div>
					)}

					<div className="field col-12 md:col-3">
						<Field
							header={"Spoke"}
							name={"spokeId"}
							filter
							component={FeildDropdown}
							onChange={(e) => {
								handleUserList(e, setFieldValue, values.hubId);
							}}
							options={spokeList}
							optionLabel={"name"}
							optionValue={"id"}
						/>
					</div>

					<div className="field col-12 md:col-3">
						<Field
							header={"User"}
							name={"userName"}
							filter
							component={FeildDropdown}
							options={userList.map((elm) => ({
								...elm,
								name:
									(elm?.firstName ? elm?.firstName : "") +
									" " +
									(elm?.middleName ? elm?.middleName : "") +
									" " +
									(elm?.lastName ? elm?.lastName : ""),
							}))}
							optionLabel="name"
							optionValue={"userName"}
						/>
					</div>
					<div className="col-12 flex align-items-end justify-content-end">
						<Button
							type="button"
							label="Reset"
							className="p-button-danger mr-2"
							onClick={() => {
								resetForm();
								getTsrmBYHubSpoke({
									pageNo: 1,
									pageSize: 1000000,
									hubId: null,
									spokeId: null,
								});
							}}
						/>
						<Button type="submit" label="Allocate" />
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default AllocateAgentForm;
