import React, { useEffect, useReducer, useRef, useState } from "react";
import { Button } from "primereact/button";
import { SupportService } from "../../../services/SupportService";
import { Dialog } from "primereact/dialog";
import EditFAQDialoge from "./EditFAQDialoge";
import CreateFAQDialoge from "./CreateFAQDialoge";
import Config from "../../../shared/config";
import { produce } from "immer";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import { ListBox } from "primereact/listbox";
import AddDialoge from "./AddDialoge";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { NoDataBtn } from "../../../components/NoDataBtn";
import { ZoneService } from "../../../services/ZoneService";
const Faq = () => {
	const roleService = new ZoneService();
	const supportService = new SupportService();
	const [editDialog, setEditDialog] = useState(false);
	const [createDialog, setCreateDialog] = useState(false);
	const [editData, setEditData] = useState({});
	const toast = useRef(null);
	const [categoryList, setCategoryList] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const [roleState, roleDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const initialState = {
		value: null,
		dialog: false,
		type: "add",
	};
	const reducer = produce((state, action) => {
		switch (action.type) {
			case "value":
				state.value = action.payload;
				break;

			default:
				return state;
		}
	});
	const [faqState, faqDispatch] = useReducer(reducer, initialState);

	const getAllCategory = () => {
		setCategoryList({
			type: Config.FETCH_CONFIG.start,
		});
		supportService
			.getFAQCategory()
			.then((res) => {
				setCategoryList({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
				faqDispatch({ type: "value", payload: res[0] });
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				})
			)
			.finally(() => {
				setCategoryList({
					type: Config.FETCH_CONFIG.stop,
				});
				setCategoryList({
					type: Config.FETCH_CONFIG.fetched,
				});
			});
	};
	useEffect(() => {
		getAllCategory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		let subscribe = true;
		if (subscribe) {
			roleDispatch({ type: Config.FETCH_CONFIG.start });
			roleService
				.getRole()
				.then((res) =>
					roleDispatch({
						type: Config.FETCH_CONFIG.success,
						payload: res,
					})
				)
				.catch((err) => {})
				.finally(() =>
					roleDispatch({ type: Config.FETCH_CONFIG.stop })
				);
		}
		return () => {
			subscribe = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleCategorySelect = (e) => {
		faqState.value &&
			faqState.value.id !== e.id &&
			faqDispatch({ type: "value", payload: e });
	};

	const editCategory = (e) => {
		setEditDialog(true);
		setEditData(e);
	};

	const addCategory = () => {
		setCreateDialog(true);
	};

	const editDialogClose = (e) => {
		setEditDialog(e);
		getAllCategory();
	};

	const createDialogClose = (e) => {
		setCreateDialog(e);
		getAllCategory();
	};
	const itemTemplate = (item) => {
		return (
			<div className="flex justify-content-between align-items-center">
				<div className="flex align-items-center gap-2">
					<img
						className="border-circle w-2rem h-2rem"
						src={`${Config.AGENT_BUCKET_ACCESS_URL}/faq-docs/${item.logoUrl}`}
						alt={item.name}
						style={{ objectFit: "cover" }}
					/>
					<h5>{item.name}</h5>
				</div>

				<Button
					// label="Edit"
					icon={"pi pi-pencil"}
					className="p-button-text"
					onClick={() => editCategory(item)}
				/>
			</div>
		);
	};
	const handleClick = () => {
		addCategory();
	};
	return (
		<div className="grid">
			<Toast ref={toast} />
			<h3 className="col-12">FAQs</h3>
			<div className="col-12 md:col-3">
				{categoryList.isLoading ? (
					<>
						<Skeleton width="100%" className="mb-2" height="5rem" />
						<Skeleton width="100%" className="mb-2" height="5rem" />
						<Skeleton width="100%" className="mb-2" height="5rem" />
						<Skeleton width="100%" height="5rem" />
					</>
				) : (
					categoryList.data.length > 0 && (
						<ListBox
							options={categoryList.data}
							value={faqState.value}
							onChange={(e) => {
								handleCategorySelect(e.value);
							}}
							filter
							optionLabel="name"
							itemTemplate={itemTemplate}
							listStyle={{ maxHeight: "60vh" }}
						/>
					)
				)}

				<Button
					className="w-full my-2"
					label="Add FAQ Category"
					icon="pi pi-plus"
					onClick={() => addCategory()}
				/>
			</div>
			<div className="col-12 md:col-9">
				{faqState.value ? (
					<AddDialoge role={roleState.data} value={faqState.value} />
				) : (
					categoryList.isFetched &&
					categoryList.data.length === 0 && (
						<NoDataBtn
							header={"Add FAQ Category"}
							handleClick={handleClick}
						/>
					)
				)}
			</div>
			<Dialog
				header="Edit"
				visible={editDialog}
				onHide={() => setEditDialog(false)}
				breakpoints={{
					"960px": "95vw",
				}}
				style={{ width: "50%" }}
			>
				<EditFAQDialoge
					editData={editData}
					editDialogClose={editDialogClose}
				/>
			</Dialog>
			<Dialog
				header="Add"
				visible={createDialog}
				onHide={() => setCreateDialog(false)}
				breakpoints={{
					"960px": "95vw",
				}}
				style={{ width: "50%" }}
			>
				<CreateFAQDialoge createDialogClose={createDialogClose} />
			</Dialog>
		</div>
	);
};

export default Faq;
