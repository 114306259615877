import { useRef, useState } from "react";
import Config from "../../../../../shared/config";
import { Field, Form, Formik } from "formik";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import S3 from "aws-sdk/clients/s3";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { ProgramService } from "../../../../../services/ProgramService";
import { InputNode } from "../../../../../components/InputComponent";

import moment from "moment";

import {
	FeildCalender,
	FeildDropdown,
	FieldEditor,
	FieldInput,
} from "../../../../../components/FieldInput";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../../../../../store/reducer/ProgramReducer";
import Loader from "../../../../../components/Loader";
import debounce from "lodash.debounce";
const Offers = (props) => {
	const offerservice = new ProgramService();
	const dispatch = useDispatch();
	const [spinner, setSpinner] = useState(false);
	const toastTL = useRef(null);
	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});
	const selector = useSelector((state) => state.program);
	const initialValue = {
		programId: selector.programId,
		name: "",
		startDate: "",
		endDate: "",
		details: "",
		image: "",
		joiningFee: false,
		annualFee: "",
		preClosureCharge: false, //NPA
		processingFees: false, //isProcessingFeess
		rateOfInterest: "", //rateOfInterest

		// // Not present in field
		// preClosureChargePeriod: "",
		// minpreClosureChargeCharge: "",
		// maxpreClosureChargeCharge: "",
		// minProcessingFees: "",
		// maxProcessingFees: "",
		// minInterest: "",
		// maxInterest: "",
		// createdBy: "",
		// createdAt: "",
		// modifiedBy: "",
		// modifiedAt: "",
		// ispreClosureChargeCharge: "boolean",
		// // Not present in field
	};
	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.matches(/^(\w+\s)*\w+$/, "Please enter valid name")
			.required("This Field is required"),
		startDate: Yup.date().required("This Field is required"),
		endDate: Yup.date()
			.when("startDate", (startDate, value) => {
				return value.test({
					test: (endDate) =>
						!!startDate && new Date(endDate) > new Date(startDate),
					message: "Invalid date",
				});
			})
			.required("This Field is required"),
		details: Yup.string().required("This Field is required"),
		rateOfInterest: Yup.number()
			.typeError("Enter a valid number")
			.min(0, "Invalid number")
			.required("This Field is required"),
	});
	const handleFormSubmit = (value, onSubmitProps) => {
		setSpinner(true);
		const { endDate, startDate, ...rest } = value;
		const newObj = [
			{
				endDate: moment(endDate).format("YYYY-MM-DD"),
				startDate: moment(startDate).format("YYYY-MM-DD"),
				...rest,
			},
		];
		offerservice
			.offerCreate(newObj)
			.then((res) =>
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Program Created Successfully",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setSpinner(false);
				onSubmitProps.resetForm();
			})
			.finally(
				debounce(() => {
					props.dialog(false);
					dispatch(reset());
					props.getAllProgramList();
				})
			);
	};

	const onUploadfirst = (e, setFieldValue) => {
		//setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.INSTITUTION_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Contract document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});

				setFieldValue("image", newFileName);
			}
		});
	};

	return (
		<>
			{spinner && <Loader />}
			<Toast ref={toastTL} position="top-left" />
			<Formik
				initialValues={initialValue}
				onSubmit={handleFormSubmit}
				validationSchema={validationSchema}
			>
				{({
					handleSubmit,

					setFieldValue,
				}) => (
					<Form className="form-grid grid" onSubmit={handleSubmit}>
						<div className="field col-12 md:col-6">
							<Field
								component={FieldInput}
								name="name"
								header={"Offer Name"}
								star={"*"}
							/>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								component={FeildCalender}
								name="startDate"
								header={"Start Date"}
								star={"*"}
							/>
						</div>
						<div className="field col-12  md:col-3">
							<Field
								component={FeildCalender}
								name="endDate"
								header={"End Date"}
								star={"*"}
							/>
						</div>
						<div className="field col-12 md:col-6">
							<Field
								component={FieldEditor}
								name="details"
								header={"Offer Details"}
								star={"*"}
							/>
						</div>
						<div className="field col-12 md:col-6">
							<InputNode header={"Upload Image "}>
								<FileUpload
									name="image"
									customUpload
									accept=".png,.jpeg,.jpg"
									uploadHandler={(e) =>
										onUploadfirst(e, setFieldValue)
									}
									maxFileSize={1000000}
									emptyTemplate={
										<p className="m-0">
											Drag and drop files to here to
											upload.
										</p>
									}
								/>
							</InputNode>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								component={FeildDropdown}
								name={"processingFees"}
								options={Config.YES_NO}
								header={"Processing Fee"}
								star="*"
							/>
						</div>

						<div className="field col-12 md:col-3">
							<Field
								type="number"
								component={FieldInput}
								name={"rateOfInterest"}
								header={"Interest Rate"}
								star="*"
							/>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								component={FeildDropdown}
								name={"preClosureCharge"}
								options={Config.YES_NO}
								header={"Pre-closure"}
								star="*"
							/>
						</div>

						{selector.productType === "CC" && (
							<>
								<div className="field col-12 md:col-3">
									<Field
										component={FeildDropdown}
										name={"annualFee"}
										options={Config.YES_NO}
										header={"Annual Fee"}
										star="*"
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										component={FeildDropdown}
										name={"joiningFee"}
										options={Config.YES_NO}
										header={"Joining Fee"}
										star="*"
									/>
								</div>
							</>
						)}
						<div className="flex justify-content-end align-items-center w-full mt-4">
							<Button
								type="submit"
								label="Save"
								style={{ borderRadius: "6px" }}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default Offers;
