import React, { useState, useEffect, useRef, useReducer } from "react";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { Dialog } from "primereact/dialog";
import { BiEdit } from "react-icons/bi";
import Paginator from "../../../components/Paginator";
import AddUserDialog from "./AddUserDialog";
import { UsersService } from "../../../services/UsersService";
import EditUser from "./EditUser";
import { ProgressSpinner } from "primereact/progressspinner";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import Config from "../../../shared/config";
import {
	searchInitialState,
	searchReducer,
} from "../../../components/searchReducer";
import SearchComponent from "./components/SearchComponent";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import { FieldPassword } from "../../../components/FieldInput";
import * as Yup from "yup";
import { AgentService } from "../../../services/AgentService";
import moment from "moment";

import { ConfirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { confirmDialog } from "primereact/confirmdialog"; // To use confirmDialog method
import Loader from "../../../components/Loader";

const Users = () => {
	const userService = new UsersService();
	const agentService = new AgentService();
	const toastTL = useRef(null);
	const [spinner, setSpinner] = useState(false);
	const [members, setMembers] = useReducer(dataReducer, fetchInitialState);

	const [role, setRole] = useState([]);
	const [memberDialog, setMemberDialog] = useState(false);
	const [userEditDialog, setUserEditDialog] = useState(false);
	const [editData, setEditData] = useReducer(dataReducer, {
		data: {},
		isLoading: false,
		error: false,
		errorMessage: null,
		isFetched: false,
	});
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const [userPasswordDialog, setUserPasswordDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const [userName, setUserName] = useState("");
	const user = useSelector(
		(state) => state.persistData.auth.user.role.position
	);
	const lodarTblArray = Array.from({ length: 10 });
	const skeletonBody = () => {
		return <Skeleton height="1rem"></Skeleton>;
	};

	const validationSchema = Yup.object().shape({
		newPassword: Yup.string()
			.required("Password is required")
			.min(8, "Password must have at least 8 characters")
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
			),
		cnfNewPassword: Yup.string().oneOf(
			[Yup.ref("newPassword"), null],
			"Passwords must match"
		),
	});
	useEffect(() => {
		let ignore = false;
		if (!ignore) {
			userService
				.getRoles()
				.then((res) => {
					const newData = [...res];
					newData.sort((a, b) => {
						return a.position - b.position;
					});
					setRole(newData);
				})
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: e.name,
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});
				});
		}
		return () => {
			ignore = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const userDataTableValue = () => {
		const requestBody = {
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.searchValue !== "" && searchState.searchValue),
			...(searchState.sortByColumn && searchState.sortType
				? {
						sorts: [
							searchState.sortByColumn +
								"," +
								searchState.sortType,
						],
				  }
				: { sorts: [] }),
		};

		// if (searchValue !== "" && operation) {
		// 	requestBody.searchRequest = ;
		// }
		// if (searchState.sortByColumn && searchState.sortType) {
		// 	requestBody.sorts = [
		// 		,
		// 	];
		// }
		setMembers({ type: Config.FETCH_CONFIG.start });
		userService
			.getUser(requestBody)
			.then((res) => {
				setMembers({ type: Config.FETCH_CONFIG.success, payload: res });
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setMembers({ type: Config.FETCH_CONFIG.stop }));
	};

	useEffect(() => {
		userDataTableValue();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);

	/**
	 * Table Sort Function
	 */
	const addMemberClick = (item) => {
		setMemberDialog(true);
	};
	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	function tblSort(columnName, sortType) {
		// setSortByColumn(columnName);
		// setSortType(sortType);

		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}

	const increment = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const pagesizechange = (e) => {
		// const newPageNo = Math.ceil(
		// 	(members.data.pageSize * members.data.pageNumber) / e.target.value
		// );

		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};

	/**
	 *  Table Templates
	 */
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />
				<Column header={getSortableColumn("Name", "firstName")} />

				<Column header={getSortableColumn("Mobile", "mobileNumber")} />
				<Column header={getSortableColumn("User Name", "userName")} />
				<Column header={"User Status"} />
				<Column header={getSortableColumn("Created On", "createdOn")} />
				<Column header={getSortableColumn("Role", "role.position")} />
				<Column header={getSortableColumn("Gender", "gender")} />
				<Column
					header={getSortableColumn("Parent Id", "u.firstName")}
				/>
				<Column header="Employee Id" />
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const actionTemplate = (item) => {
		return (
			<div className="flex gap-2">
				<Button
					className="icon-btn primary-btn"
					tooltip="Edit User"
					tooltipOptions={{
						position: "bottom",
					}}
					onClick={() => handleEdit(item.userName)}
				>
					<BiEdit />
				</Button>
				{user === null && (
					<Button
						className="icon-btn success-btn"
						tooltip="User Password Change"
						tooltipOptions={{ position: "left" }}
						onClick={() => handelUserPasswordDialoge(item)}
					>
						<i className="pi pi-pencil"></i>
					</Button>
				)}
				{item?.isActive ? (
					<Button
						className="icon-btn primary-btn"
						tooltip="Disable User"
						tooltipOptions={{
							position: "bottom",
						}}
						onClick={() =>
							confirmDialog({
								message: "Are you sure you want to proceed?",
								header: "Confirmation",
								icon: "pi pi-exclamation-triangle",
								accept: () => handleDisable(item.userName),
								reject: () => {},
							})
						}
						icon={"pi pi-trash"}
					/>
				) : (
					<Button
						className="icon-btn primary-btn"
						tooltip="Enable User"
						tooltipOptions={{
							position: "left",
						}}
						onClick={() =>
							confirmDialog({
								message: "Are you sure you want to proceed?",
								header: "Confirmation",
								icon: "pi pi-exclamation-triangle",
								accept: () => handleEnable(item.userName),
								reject: () => {},
							})
						}
						icon={"pi pi-user"}
					/>
				)}
			</div>
		);
	};

	const handelUserPasswordDialoge = (e) => {
		setUserName(e.userName);
		setUserPasswordDialog(true);
	};

	const handleChangePassword = (e) => {
		setLoading(true);
		let requestBody = {
			newPassword: e.newPassword,
			userName: userName,
		};
		agentService
			.agentPasswordChange(requestBody)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: "Password Changed Successfully",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setLoading(false);
				setUserPasswordDialog(false);
			});
	};
	const roleColor = (item) => {
		switch (item.position) {
			case 1:
				return (
					<span className="status status-warning">
						{item.roleName}
					</span>
				);

			case 3:
				return (
					<span className="status status-danger">
						{item.roleName}
					</span>
				);

			case 2:
				return (
					<span className="status status-primary">
						{item.roleName}
					</span>
				);

			case 4:
				return (
					<span className="status status-danger-deep">
						{item.roleName}
					</span>
				);

			case 5:
				return (
					<span className="status status-success">
						{item.roleName}
					</span>
				);
			case 50:
				return (
					<span className="status status-success">
						{item.roleName}
					</span>
				);
			case 100:
				return (
					<span className="status bg-puple-100 text-puple-800">
						{item.roleName}
					</span>
				);
			case 5000:
				return (
					<span className="status bg-blue-100 text-blue-800">
						{item.roleName}
					</span>
				);
			default:
				return "";
		}
	};

	const handleEdit = (userName) => {
		setUserEditDialog(true);
		setEditData({
			type: Config.FETCH_CONFIG.start,
		});
		userService
			.getUserEdit(userName)
			.then((res) => {
				setEditData({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((e) =>
				setEditData({
					type: Config.FETCH_CONFIG.error,
					payload: e,
				})
			)
			.finally(() =>
				setEditData({
					type: Config.FETCH_CONFIG.stop,
				})
			);
	};
	const handleDisable = (id) => {
		setSpinner(true);
		userService
			.disableUser(id)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: "User disabled Successfully",
					style: { color: "#000000" },
					life: 3000,
				});
				setSpinner(false);
				userDataTableValue();
			})
			.catch((e) => {
				setSpinner(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				});
			});
	};
	const handleEnable = (id) => {
		setSpinner(true);
		userService
			.enableUser(id)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: "User Enabled Successfully",
					style: { color: "#000000" },
					life: 3000,
				});
				setSpinner(false);
				userDataTableValue();
			})
			.catch((e) => {
				setSpinner(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				});
			});
	};
	const editFormData = (e) => {
		const { role, user, ...rest } = e;
		const data = { ...rest };

		userService
			.getUserUpdate(data)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: "User Updated Successfully",
					style: { color: "#000000" },
					life: 3000,
				});
				setUserEditDialog(false);
				userDataTableValue();
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				});
			});

		// acton.resetForm();
	};
	const getName = (item) => {
		let fname = item.firstName ? item.firstName : "";
		let mname = item.middleName ? item.middleName : "";
		let lname = item.lastName ? item.lastName : "";
		let name = `${fname} ${mname} ${lname}`;
		return name;
	};

	const memberDialogClose = (e) => {
		setMemberDialog(e);
	};
	const newData =
		members.data.data &&
		members.data.data.map((elm) => ({
			Id: elm.id,
			"Employee Id": elm.employeeId,
			"Role Name": elm.roleName,
			// "Role Id": elm.roleId,
			Position: elm.position,
			Username: elm.userName,
			"User Type": elm.userType,
			Name:
				(elm.firstName ? elm.firstName : "") +
				" " +
				(elm.middleName ? elm.middleName : "") +
				" " +
				(elm.lastName ? elm.lastName : ""),
			"Email Id": elm.emailId,
			"Created On": elm.createdOn,
			Gender: elm.gender,
			"Mobile Number": elm.mobileNumber,
			"Parent Id": elm.parentId,
			"User Status": elm.isActive ? "Active" : "Disable",
		}));

	const getParentName = (item) => {
		let fname = item.parentIdFirstName ? item.parentIdFirstName : "";
		let mname = item.parentIdMiddleName ? item.parentIdMiddleName : "";
		let lname = item.parentIdLastName ? item.parentIdLastName : "";
		let userName = item.parentId ? `(${item.parentId})` : "";
		let parentName = `${fname} ${mname} ${lname} ${userName}`;
		return parentName;
	};
	const createdOnTemplate = (item) => {
		return item.createdOn
			? moment(item.createdOn).format("Do MMM, YYYY")
			: "";
	};
	const areaTemplate = (item) => {
		return (
			<>
				<Button
					className="p-button-text"
					tooltip={[
						"West bengal",
						"Bihar",
						"Uttar pradeshfvdsgszgfszdfesrf",
					].join(", ")}
					tooltipOptions={{ position: "bottom" }}
					style={{ color: "#333" }}
				>
					<p
						className="m-0  white-space-nowrap overflow-hidden text-overflow-ellipsis"
						style={{ width: "100px" }}
					>
						{[
							"West bengal",
							"Bihar",
							"Uttar pradeshfvdsgszgfszdfesrf",
						].join(", ")}
					</p>
				</Button>
			</>
		);
	};

	return (
		<>
			{spinner && <Loader />}
			<ConfirmDialog />
			<Toast ref={toastTL} position="top-left" />
			<SearchComponent
				addMemberClick={addMemberClick}
				searchDispatch={searchDispatch}
				role={role}
				newData={newData}
			/>
			<div className="grid">
				{members.isLoading ? (
					<div className="col-12">
						<DataTable
							headerColumnGroup={headerGroup}
							value={lodarTblArray}
							responsiveLayout="stack"
							breakpoint="960px"
						>
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
						</DataTable>
					</div>
				) : (
					<div className="col-12">
						<Paginator
							pageSize={searchState.pageSize}
							firstPage={members.data.firstPage}
							lastPage={members.data.lastPage}
							decrement={decrement}
							increment={increment}
							pagesizechange={pagesizechange}
							pageNo={members.data.pageNo}
							totalPages={members.data.totalPages}
							totalElements={members.data.totalElements}
						/>
						<DataTable
							headerColumnGroup={headerGroup}
							value={
								members.data.data &&
								members.data.data.map((item, index) => ({
									...item,
									index:
										members.data.pageSize *
											members.data.pageNo -
										members.data.pageSize +
										1 +
										index,
								}))
							}
							responsiveLayout="scroll"
							breakpoint="960px"
						>
							<Column field="index" />
							<Column body={getName} />

							<Column field="mobileNumber" />
							<Column field="userName" />
							<Column
								body={(item) =>
									item?.isActive ? (
										<span className="px-2 py-1 border-round text-green-700 bg-green-100">
											Active
										</span>
									) : (
										<span className="px-2 py-1 border-round text-red-700 bg-red-100">
											Disable
										</span>
									)
								}
							/>
							<Column body={createdOnTemplate} />
							<Column body={roleColor} />
							<Column field="gender" />
							<Column body={getParentName} />
							<Column field="employeeId" />
							<Column body={actionTemplate} />
						</DataTable>

						<Paginator
							pageSize={searchState.pageSize}
							firstPage={members.data.firstPage}
							lastPage={members.data.lastPage}
							decrement={decrement}
							increment={increment}
							pagesizechange={pagesizechange}
							pageNo={members.data.pageNo}
							totalPages={members.data.totalPages}
							totalElements={members.data.totalElements}
						/>
					</div>
				)}

				<Dialog
					header="Edit Users"
					visible={userEditDialog}
					style={{ width: "70vw" }}
					breakpoints={{ "960px": "75vw", "640px": "95vw" }}
					onHide={() => {
						setUserEditDialog(false);
						setEditData({
							type: Config.FETCH_CONFIG.ini,
						});
					}}
				>
					<div className="card modal-form-grid">
						{editData.isLoading ? (
							<div className="card flex justify-content-center">
								<ProgressSpinner
									style={{ width: "40px", height: "70px" }}
									strokeWidth="4"
								/>
							</div>
						) : (
							editData.data && (
								<EditUser
									roles={role}
									editData={editData.data}
									editFormData={editFormData}
									setUserEditDialog={setUserEditDialog}
								/>
							)
						)}
					</div>
				</Dialog>

				<Dialog
					header="Add Users"
					visible={memberDialog}
					style={{ width: "70vw" }}
					breakpoints={{ "960px": "75vw", "640px": "95vw" }}
					onHide={() => setMemberDialog(false)}
				>
					<AddUserDialog
						roles={role}
						setMemberDialog={setMemberDialog}
						userDataTableValue={userDataTableValue}
						memberDialogClose={memberDialogClose}
					/>
				</Dialog>
			</div>
			<div className="dialog-demo">
				<div className="card ">
					<Dialog
						header="Agent Password Change"
						visible={userPasswordDialog}
						onHide={() => {
							setUserPasswordDialog(false);
							// setSpinner(false);
						}}
					>
						<div className="card modal-form-grid">
							<Formik
								onSubmit={handleChangePassword}
								initialValues={{
									newPassword: "",
									cnfNewPassword: "",
								}}
								validationSchema={validationSchema}
								enableReinitialize
							>
								{({
									values,

									handleSubmit,
								}) => (
									<Form>
										<div className="form-grid grid">
											<div className="field col-12 md:col-4">
												<Field
													header={"Password"}
													name={"newPassword"}
													component={FieldPassword}
												/>
											</div>
											<div className="field col-12 md:col-4">
												<Field
													header={"Confirm Password"}
													name={"cnfNewPassword"}
													component={FieldPassword}
												/>
											</div>
											<div className="col-12 flex align-items-end justify-content-end">
												<Button
													type="button"
													label="Save "
													icon="pi pi-save"
													onClick={handleSubmit}
													loading={loading}
												/>
											</div>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</Dialog>
				</div>
			</div>
		</>
	);
};
export default Users;
