import { Field, Form, Formik } from "formik";
import { FeildDropdown, FieldInput } from "../../../components/FieldInput";
import Config from "../../../shared/config";
import { Button } from "primereact/button";
const SearchVendor = (props) => {
	const initialValue = {
		vendorType: "",
		name: "",
		address: "",
		pan: "",
		gst: "",
		contactPersonName: "",
		contactPersonType: "",
		bankAccountType: "",
		accountNumber: "",
		bankName: "",
		bankAddress: "",
		ifscCode: "",
	};

	const handleFormSubmit = (value) => {
		const newData = { ...value };
		let data = Object.fromEntries(
			Object.entries(newData).filter(([_, v]) => v !== "")
		);

		props.search({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: data,
		});
		props.search({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};
	return (
		<div className="mb-3">
			<div className="background-secondary p-4 border-round-md my-3">
				<Formik
					initialValues={initialValue}
					onSubmit={handleFormSubmit}
				>
					{({ handleSubmit, resetForm }) => (
						<Form className="grid" onSubmit={handleSubmit}>
							<div className="field col-12 md:col-4">
								<Field
									header={"Vendor Type"}
									name={"vendorType"}
									component={FeildDropdown}
									options={Config.VENDOR_TYPE}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Name"}
									name={"name"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Address"}
									name={"address"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"PAN"}
									name={"pan"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"GST"}
									name={"gst"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Contactperson Name"}
									name={"contactPersonName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Contactperson Type"}
									name={"contactPersonType"}
									component={FeildDropdown}
									options={Config.CONTACT_PERSON_TYPE}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Bank Account Type"}
									name={"bankAccountType"}
									component={FeildDropdown}
									options={Config.BANK_ACCOUNTTYPE}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Account Number"}
									name={"accountNumber"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Bank Name"}
									name={"bankName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Bank Address"}
									name={"bankAddress"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"IFSC Code"}
									name={"ifscCode"}
									component={FieldInput}
								/>
							</div>
							<div className="col-12 flex gap-2">
								<Button
									icon={"pi pi-refresh"}
									label={"Reset"}
									className="danger-btn"
									type="button"
									onClick={() => resetForm()}
								/>
								<Button label={"Search"} type="submit" />
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default SearchVendor;
