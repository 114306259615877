import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
	Bar,
	BarChart,
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setApplicationState } from "../../../../store/reducer/AuthReducer";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { Button } from "primereact/button";
const AgentPerformance = (props) => {
	const toast = useRef(null);
	const leadDispatch = useDispatch();
	const navigate = useNavigate();
	const template2 = {
		layout: "CurrentPageReport PrevPageLink NextPageLink",
	};

	const stateTemplate = (item) => {
		switch (item?.applicationStatus) {
			case "CUSTOMER_DRAFT":
				return (
					<span className="status status-warning">
						Customer Draft
					</span>
				);

			case "DRAFT":
				return <span className="status status-warning">Draft</span>;

			case "PENDING_FOR_CUSTOMER":
				return (
					<span className="status status-primary">
						Pending For Customer
					</span>
				);
			case "CUSTOMER_APPROVED":
				return (
					<span className="status status-success">
						Customer Approved
					</span>
				);
			case "LOCK":
				return (
					<span className="status text-white  bg-orange-500">
						Lock
					</span>
				);
			case "LOCK_APPROVED_BY_FI":
				return (
					<span className="status text-white  bg-orange-500">
						Lock
					</span>
				);
			case "LOCK_DISBURSED_BY_FI":
				return (
					<span className="status text-white  bg-orange-500">
						Lock
					</span>
				);
			case "LOCK_REJECTED_BY_FI":
				return (
					<span className="status text-white  bg-orange-500">
						Lock
					</span>
				);
			case "TSRM_SUBMITTED":
				return (
					<span className="status text-white  bg-blue-500">
						Tsrm Submit
					</span>
				);
			case "REJECT":
				return (
					<span className="status text-white bg-red-800">
						Rejected
					</span>
				);
			case "REVERT_BY_OE":
				return (
					<span className="status bg-red-100 text-red-800 ">
						Revert By OE
					</span>
				);
			case "WORK_IN_PROGRESS":
				return (
					<span className="status bg-cyan-100 text-cyan-800 ">
						OE Work In Progress
					</span>
				);
			default:
				<></>;
			// return (
			// 	<span className="status text-white  bg-blue-500">
			// 		{item?.applicationStatus?.replaceAll("_", " ")}
			// 	</span>
			// );
		}
	};

	return (
		<>
			<Toast ref={toast} />
			<div className="col-12">
				<h5 className="text-center mt-1 mb-3">Product wise details</h5>
				<DataTable
					paginator
					paginatorTemplate={template2}
					rows={5}
					responsiveLayout={"scroll"}
					value={props?.productData?.listData}
				>
					<Column header={"Product Name"} field="productName" />
					<Column
						header={"Lead Number"}
						body={(elm) => (
							<Link
								to={`/vendor/lead/preview-lead/${elm?.leadId}`}
							>
								{elm?.leadNumber}
							</Link>
						)}
						// field="leadNumber"
					/>
					<Column
						header={"Application Number"}
						body={(data) => (
							<Button
								className="p-0 p-button-text"
								onClick={() => {
									if (
										!data.applicationId ||
										!data?.productAlias
									) {
										return toast.current.show({
											severity: "error",
											summary: "Error",
											detail: "Alias and Application Id not present",
											style: { color: "#000000" },
											life: 3000,
										});
									}
									leadDispatch(
										setApplicationState("preview")
									);
									if (data.productAlias === "HC") {
										navigate(
											`/vendor/hospicash/edit/${data.id}`,
											{
												state: {
													mobile: data.mobile,
													pan: "",
													leadId: null,
													subproductId:
														data.productId,
													alias: data?.productAlias,
													applicationId:
														data.applicationId,
													stateId: "",
													districtId: "",
												},
											}
										);
									} else {
										navigate("/vendor/application/create", {
											state: {
												mobile: data.mobile,
												pan: "",
												leadId: null,
												subproductId: data.productId,
												alias: data.productAlias,
												applicationId:
													data.applicationId,
												stateId: "",
												districtId: "",
											},
										});
									}
								}}
							>
								{data?.applicationNumber}
							</Button>
						)}
						// field="applicationNumber"
					/>
					<Column
						header={"Application Status"}
						body={stateTemplate}
					/>
				</DataTable>
			</div>
			<div className="col-12 c-prfile-chat" style={{ height: "440px" }}>
				<h5 className="my-1 text-center">Product wise Lead</h5>
				<ResponsiveContainer width="100%" height="100%">
					<LineChart
						data={props?.productData?.leadData}
						margin={{
							top: 5,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="title" />
						<YAxis />
						<Tooltip />

						{props?.productData?.leadData?.length > 0 &&
							Object.keys(props?.productData?.leadData[0])?.map(
								(elm, index) =>
									elm !== "title" && (
										<Line
											key={index.toString()}
											type="monotone"
											dataKey={elm}
											strokeWidth={3}
											stroke={
												"#" +
												Math.floor(
													Math.random() * 0xffffff
												)
													.toString(16)
													.padEnd(6, "0")
											}
											activeDot={{ r: 8 }}
										/>
									)
							)}
					</LineChart>
				</ResponsiveContainer>
			</div>
			<div className="col-12" style={{ height: "400px" }}>
				<h5 className="mb-1 text-center">Product wise Application</h5>
				<ResponsiveContainer
					width="100%"
					height="100%"
					className="c-prfile-chat"
				>
					<BarChart
						width={500}
						height={300}
						data={props?.productData?.applicationData}
						margin={{
							top: 20,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="title" />
						<YAxis />
						<Tooltip />
						{props?.productData?.leadData?.length > 0 &&
							Object.keys(props?.productData?.leadData[0])?.map(
								(elm, index) =>
									elm !== "title" && (
										<Bar
											key={index.toString()}
											dataKey={elm}
											stackId="a"
											fill={
												"#" +
												Math.floor(
													Math.random() * 0xffffff
												)
													.toString(16)
													.padEnd(6, "0")
											}
										/>
									)
							)}
					</BarChart>
				</ResponsiveContainer>
			</div>
		</>
	);
};

export default AgentPerformance;
