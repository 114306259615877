import { Field, Formik, Form } from "formik";
import React, { useEffect, useReducer, useRef, useState } from "react";

import Config from "../../../../../shared/config";
import { Checkbox } from "primereact/checkbox";

import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { InstitutionService } from "../../../../../services/InstitutionService";
import {
	useGetDsaInstitutionDetailsQuery,
	useGetInstitutionDetailsQuery,
} from "../../../../../services/query/queryApi";
import {
	FeildDropdown,
	FieldEditor,
	FieldInput,
	FieldMultiselect,
} from "../../../../../components/FieldInput";
import { InputNode } from "../../../../../components/InputComponent";
import { ProgramService } from "../../../../../services/ProgramService";
import { convertNullToString } from "../../../../../utils/UtilsFunction";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../../components/fetchReducer";
import { Toast } from "primereact/toast";
import waterfall from "async/waterfall";
import { ProgressSpinner } from "primereact/progressspinner";
import Loader from "../../../../../components/Loader";
import { S3 } from "aws-sdk";
import { ProgressBar } from "primereact/progressbar";
const GeneralUpdate = (props) => {
	const institutionService = new InstitutionService();
	const { data: dsaInstitute = { data: [] }, isSuccess } =
		useGetDsaInstitutionDetailsQuery({
			pageNo: 1,
			pageSize: 5000,
		});
	const { data = [] } = useGetInstitutionDetailsQuery();
	const [loader, setLoader] = useState(false);
	const [imgSpinner, setImgSpinner] = useState(false);
	const toastTL = useRef(null);
	const [processingFee, setProcessingFee] = useState(false);
	const [state, dispatch] = useReducer(dataReducer, fetchInitialState);
	const [productList, setProductList] = useState({ institutionProduct: [] });
	const programService = new ProgramService();
	const handleSubProduct = (e, setFieldValue) => {
		setFieldValue("institutionId", e.value);
		institutionService
			.getInstitutionWiseProductList(e.value)
			.then((res) => props.setProductList(res))
			.catch((e) => {});
	};
	const getProgramList = () => {
		waterfall(
			[
				function (cb) {
					dispatch({
						type: Config.FETCH_CONFIG.start,
					});

					programService
						.getFullProgramData(props.programId)
						.then((res) => {
							const data = convertNullToString(res);
							const {
								programCommissionStructureDto,
								programEligibilityCriteriaDtoList,
								programOfferDtoList,
								programCreditBureauDtoList,
								...restObj
							} = data;

							dispatch({
								type: Config.FETCH_CONFIG.success,
								payload: restObj,
							});
							dispatch({
								type: Config.FETCH_CONFIG.fetched,
							});
							cb(null, res);
						})
						.catch((er) =>
							dispatch({ type: Config.FETCH_CONFIG.stop })
						);
				},
				function (arg) {
					institutionService
						.getInstitutionWiseProductList(arg.institutionId)
						.then((data) => {
							setProductList(data);
							// const productId = data.institutionProduct.find(
							// 	(item) => item.subProductId === res.productId
							// );
							// if (productId !== undefined) {
							// 	dispatch({
							// 		type: editFieldName.productId,
							// 		payload: productId,
							// 	});
							// }
						})
						.finally(() =>
							dispatch({ type: Config.FETCH_CONFIG.stop })
						);
				},
			],
			function (err, result) {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			}
		);

		// programService
		// 	.getFullProgramData(props.programId)
		// 	.then((res) => {
		// 		const data = convertNullToString(res);

		// 		const {
		// 			programCommissionStructureDto,
		// 			programEligibilityCriteriaDtoList,
		// 			programOfferDtoList,
		// 			programCreditBureauDtoList,
		// 			alias,
		// 			...restObj
		// 		} = data;
		// 		dispatch({
		// 			type: Config.FETCH_CONFIG.success,
		// 			payload: restObj,
		// 		});
		// 		dispatch({ type: Config.FETCH_CONFIG.fetched });
		// 		institutionService
		// 			.getInstitutionWiseProductList(res.institutionId)
		// 			.then((data) => {
		// 				setProductList(data);
		// 				// const productId = data.institutionProduct.find(
		// 				// 	(item) => item.subProductId === res.productId
		// 				// );
		// 				// if (productId !== undefined) {
		// 				// 	dispatch({
		// 				// 		type: editFieldName.productId,
		// 				// 		payload: productId,
		// 				// 	});
		// 				// }
		// 			})
		// 			.catch((e) => console.log(e));
		// 	})

		// 	.catch((error) =>
		// 		toastTL.current.show({
		// 			severity: "error",
		// 			summary: "Error",
		// 			detail: "Somthing went worng",
		// 			style: { color: "#000000" },
		// 			life: 3000,
		// 		})
		// 	)
		// 	.finally(() => dispatch({ type: Config.FETCH_CONFIG.stop }));
	};
	const handleUpdate = (val) => {
		const { alias, dsaDetails, ...rest } = val;
		setLoader(true);
		programService
			.updateProgram(rest)
			.then((res) =>
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Updated successfully",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.catch((error) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoader(false);
				// setSpinner(false);
			});
	};
	useEffect(() => {
		props.programId && getProgramList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const progressTemplate = (item) => {
		return imgSpinner ? (
			<ProgressBar mode="indeterminate" style={{ height: "4px" }} />
		) : (
			<></>
		);
	};
	// const validationSchema = Yup.object().shape({
	// 	name: Yup.string()
	// 		.matches(/^(\w+\s)*\w+$/, "Please enter valid name")
	// 		.required("This Field is Required"),
	// 	productId: Yup.object()
	// 		.test({
	// 			test: (productId) => productId.id !== null,
	// 			message: "This Field is Required",
	// 		})
	// 		.required("This Field is Required"),
	// 	// shortDescription: Yup.string().required("This Field is Required"),
	// 	// longDescription: Yup.string().required("This Field is Required"),
	// 	eligibilityDetails: Yup.string().required("This Field is Required"),
	// 	documentsRequired: Yup.string().required("This Field is Required"),
	// 	institutionId: Yup.number().required("This Field is Required"),
	// 	minLoanAmount: Yup.number()
	// 		.when("productId", {
	// 			is: (productId) => productId.alias !== "CC",
	// 			then: Yup.number().required("This Field is Required"),
	// 		})
	// 		.typeError("Amount must be a number")
	// 		.min(0, "Invalid Value"),
	// 	maxLoanAmount: Yup.number().when("productId", {
	// 		is: (productId) => productId.alias !== "CC",
	// 		then: Yup.number()
	// 			.typeError("Amount must be a number")
	// 			.min(0, "Invalid Value")
	// 			.when("minLoanAmount", (minLoanAmount, value) => {
	// 				return value.test({
	// 					test: (maxLoanAmount) =>
	// 						!!minLoanAmount && maxLoanAmount > minLoanAmount,
	// 					message: "Max Loan Amount > Min Loan Amount",
	// 				});
	// 			})
	// 			.required("This Field is Required"),
	// 	}),
	// 	minLoanPeriod: Yup.number()
	// 		.when("productId", {
	// 			is: (productId) => productId.alias !== "CC",
	// 			then: Yup.number().required("This Field is Required"),
	// 		})
	// 		.integer("Invalid Value") //done
	// 		.typeError("Enter a valid number")
	// 		.min(0, "Invalid Value"),
	// 	maxLoanPeriod: Yup.number().when("productId", {
	// 		is: (productId) => productId.alias !== "CC",
	// 		then: Yup.number()
	// 			.integer("Invalid Value") //done
	// 			.typeError("Enter a valid number")
	// 			.min(0, "Invalid Value")
	// 			.when("minLoanPeriod", (minLoanPeriod, value) => {
	// 				return value.test({
	// 					test: (maxLoanPeriod) =>
	// 						!!minLoanPeriod && maxLoanPeriod > minLoanPeriod,
	// 					message: "Max Loan Period > Min Loan Period",
	// 				});
	// 			})
	// 			.required("This Field is Required"),
	// 	}),
	// 	minProcessingFees: Yup.number()
	// 		.when("productId", {
	// 			is: (productId) => productId.alias !== "CC",
	// 			then: Yup.number(),
	// 		}) //done
	// 		.typeError("Amount must be a number")
	// 		.min(0, "Invalid Value"),
	// 	maxProcessingFees: Yup.number().when("productId", {
	// 		is: (productId) => productId.alias !== "CC",
	// 		then: Yup.number()
	// 			.typeError("Amount must be a number")
	// 			.min(0, "Invalid Value"),
	// 		// .when("minProcessingFees", (minProcessingFees, value) => {
	// 		// 	return value.test({
	// 		// 		test: (maxProcessingFees) =>
	// 		// 			!!minProcessingFees &&
	// 		// 			maxProcessingFees > minProcessingFees,
	// 		// 		message: "Max Processing Fee > Min Processing Fee",
	// 		// 	});
	// 		// })
	// 	}), //done
	// 	minRateOfInterest: Yup.number()
	// 		.when("productId", {
	// 			is: (productId) => productId.alias !== "CC",
	// 			then: Yup.number().required("This Field is Required"),
	// 		}) //done
	// 		.typeError("Amount must be a number")
	// 		.min(0, "Invalid Value"),
	// 	maxRateOfInterest: Yup.number().when("productId", {
	// 		is: (productId) => productId.alias !== "CC",
	// 		then: Yup.number()
	// 			.typeError("Amount must be a number")
	// 			.min(0, "Invalid Value")
	// 			.when("minRateOfInterest", (minRateOfInterest, value) => {
	// 				return value.test({
	// 					test: (maxRateOfInterest) =>
	// 						!!minRateOfInterest &&
	// 						maxRateOfInterest > minRateOfInterest,
	// 					message: "Max Processing Fee > Min Processing Fee",
	// 				});
	// 			})
	// 			.required("This Field is Required"),
	// 	}), //done
	// 	profession: Yup.array()
	// 		.of(Yup.string())
	// 		.min(1, "Field cannot be empty")
	// 		.required("Field cannot be empty"),
	// });
	const initialValue = {
		institutionId: "",
		product: "",
		productId: {
			id: null,
		},
		// dsaDetails: "", //for dsa
		name: "",
		shortDescription: "",
		longDescription: "",
		eligibilityDetails: "",
		imageFirst: "",
		imageSecond: "",
		profession: "",
		minLoanAmount: "",
		maxLoanAmount: "",
		minProcessingFees: "",
		maxProcessingFees: "",
		minLoanPeriod: "",
		maxLoanPeriod: "",
		minRateOfInterest: "",
		maxRateOfInterest: "",
		preClosure: false,
		documentsRequired: "",

		joiningFee: false,
		annualFee: false,
		active: true,
		cashback: false,
		fuelSurchargeWaiver: false,
		maxPremiumAmount: "",
		minPremiumAmount: "",
		maxTerm: "",
		minTerm: "",
		maxAge: "",
		minHospitalisation: "",
		maternityBenefit: false,
	};
	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});
	const onUploadfirst = (e, setFieldValue) => {
		//setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.PROGRAM_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};
		setImgSpinner(true);
		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});
			}
			setFieldValue("imageFirst", newFileName, true);
			setImgSpinner(false);
		});
	};
	const onUploadSecond = (e, setFieldValue) => {
		//setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.PROGRAM_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};
		setImgSpinner(true);
		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "2nd document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});
			}
			setFieldValue("imageSecond", newFileName, true);
			setImgSpinner(false);
		});
	};
	// console.log(dsaInstitute.data);
	return (
		<>
			{loader && <Loader />}
			<Toast ref={toastTL} />
			{state.isLoading ? (
				<div className="flex align-items-center justify-content-center py-5">
					<ProgressSpinner
						style={{ height: "30px" }}
						strokeWidth="5"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				</div>
			) : (
				state.isFetched && (
					<Formik
						initialValues={{
							...initialValue,
							...(props.type === "edit" &&
								state.isFetched &&
								state.data),
						}}
						// validationSchema={validationSchema}
						onSubmit={handleUpdate}
						enableReinitialize
					>
						{({
							values,
							handleSubmit,
							errors,
							touched,
							setFieldValue,
						}) => (
							<Form
								className="form-grid grid"
								onSubmit={handleSubmit}
								autoComplete="on"
							>
								{values?.dsaId ? (
									<>
										<div className="field col-12 md:col-3">
											<Field
												disabled
												filter
												name="dsaId"
												component={FeildDropdown}
												options={dsaInstitute.data}
												optionLabel={"dsaName"}
												header={"Select DSA"}
												star={"*"}
												optionValue={"id"}
											/>
										</div>

										<div className="field col-12 md:col-3">
											<Field
												name="institutionId"
												component={FeildDropdown}
												options={
													dsaInstitute.data?.filter(
														(elm) =>
															elm.id ===
															values?.dsaId
													)[0]?.details
												}
												filter
												optionLabel={"bankName"}
												header={"Bank"}
												star={"*"}
												optionValue={"id"}
												value={values.institutionId}
												onChange={(e) =>
													handleSubProduct(
														e,
														setFieldValue
													)
												}
												disabled={
													props.type === "edit"
														? true
														: false
												}
											/>
										</div>
									</>
								) : (
									<div className="field col-12 md:col-3">
										<Field
											name="institutionId"
											component={FeildDropdown}
											options={data}
											optionValue={"id"}
											filter
											optionLabel={"name"}
											header={"Bank"}
											star={"*"}
											value={values.institutionId}
											onChange={(e) =>
												handleSubProduct(
													e,
													setFieldValue
												)
											}
											disabled={
												props.type === "edit"
													? true
													: false
											}
										/>
									</div>
								)}
								<div className="field col-12 md:col-3">
									<Field
										header={"Name of the Program"}
										component={FieldInput}
										name={"name"}
										star={"*"}
									/>
								</div>

								<div className="field col-12 md:col-3">
									<Field
										name="productId"
										optionValue={"subProductId"}
										component={FeildDropdown}
										options={productList.institutionProduct}
										optionLabel="subProduct"
										header={"Sub Product"}
										star={"*"}
										disabled={
											props.type === "edit" ? true : false
										}
									/>
								</div>

								<div className="field col-12 md:col-3">
									<Field
										name={`profession`}
										component={FieldMultiselect}
										options={Config.OCCUPATION}
										optionLabel="name"
										header={"Profession"}
										star={"*"}
									/>
								</div>

								{values.productId !== undefined && (
									<>
										{values.alias === "CC" ? (
											<>
												<div className="field col-12 md:col-3">
													<Field
														component={
															FeildDropdown
														}
														name={"joiningFee"}
														options={Config.YES_NO}
														header={"Joining Fee"}
														star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														component={
															FeildDropdown
														}
														name={"annualFee"}
														options={Config.YES_NO}
														header={"Annual Fee"}
														star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														component={
															FeildDropdown
														}
														name={"cashback"}
														options={Config.YES_NO}
														header={"Cash Back"}
														star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														component={
															FeildDropdown
														}
														name={
															"fuelSurchargeWaiver"
														}
														options={Config.YES_NO}
														header={
															"Fuel Surcharge Waiver"
														}
														star="*"
													/>
												</div>
											</>
										) : values.alias === "HC" ? (
											<>
												<div className="field col-12 md:col-3">
													<Field
														component={FieldInput}
														name={
															"maxPremiumAmount"
														}
														type={"number"}
														header={
															"Max Premium Amount"
														}
														star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														component={FieldInput}
														name={
															"minPremiumAmount"
														}
														type={"number"}
														header={
															"Min Premium Amount"
														}
														star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														component={FieldInput}
														name={"maxTerm"}
														type={"number"}
														header={"Max Term"}
														star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														component={FieldInput}
														name={"minTerm"}
														type={"number"}
														header={"Min Term"}
														star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														component={FieldInput}
														name={"maxAge"}
														type={"number"}
														header={"Max Age"}
														star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														component={FieldInput}
														name={
															"minHospitalisation"
														}
														type={"number"}
														header={
															"Min Hospitalisation"
														}
														star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														component={
															FeildDropdown
														}
														name={
															"maternityBenefit"
														}
														type={"number"}
														options={Config.YES_NO}
														header={
															"Maternity Benefit"
														}
														star="*"
													/>
												</div>
											</>
										) : (
											<>
												<div className="col-12">
													<Checkbox
														checked={processingFee}
														onChange={(e) =>
															setProcessingFee(
																e.checked
															)
														}
													/>{" "}
													Is processingFee fixed?
												</div>
												<div className="field col-12 md:col-3">
													<Field
														type="number"
														component={FieldInput}
														name={"minLoanAmount"}
														header={
															"Min Loan Amount"
														}
														star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														type="number"
														component={FieldInput}
														name={"maxLoanAmount"}
														header={
															"Max Loan Amount"
														}
														star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														type="number"
														component={FieldInput}
														name={
															"minProcessingFees"
														}
														header={`Min Processing Fees ${
															!processingFee
																? "%"
																: ""
														}`}
														// star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														type="number"
														component={FieldInput}
														name={
															"maxProcessingFees"
														}
														header={`Max Processing Fees ${
															!processingFee
																? "%"
																: ""
														}`}
														// star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														type="number"
														component={FieldInput}
														name={"minLoanPeriod"}
														header={
															"Min Loan Period (in months)"
														}
														star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														type="number"
														component={FieldInput}
														name={"maxLoanPeriod"}
														header={
															"Max Loan Period (in months)"
														}
														star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														type="number"
														component={FieldInput}
														name={
															"minRateOfInterest"
														}
														header={
															"Min Rate Of Interest(%)"
														}
														star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														type="number"
														component={FieldInput}
														name={
															"maxRateOfInterest"
														}
														header={
															"Max Rate Of Interest(%)"
														}
														star="*"
													/>
												</div>
												<div className="field col-12 md:col-3">
													<Field
														component={
															FeildDropdown
														}
														name={"preClosure"}
														options={
															Config.PRECLOSURE
														}
														header={"Pre-closure"}
														star="*"
													/>
												</div>
											</>
										)}
									</>
								)}

								<div className="col-12"> </div>
								<div className="field col-12 md:col-6">
									<Field
										name="shortDescription"
										component={FieldEditor}
										header={"Short Description"}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-6">
									<Field
										name="longDescription"
										component={FieldEditor}
										header={"Long Description"}
										star={"*"}
									/>
								</div>

								<div className="field col-12 md:col-6">
									<Field
										name="eligibilityDetails"
										component={FieldEditor}
										header={"Eligibility Details"}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-6">
									<Field
										name="documentsRequired"
										component={FieldEditor}
										header={"Documents Required"}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-6">
									<h3>Image 1</h3>
									{values.imageFirst && (
										<a
											href={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageFirst}`}
											target="_blank"
											className="flex align-items-center justify-content-start"
											rel="noreferrer"
										>
											{/* <i
												className={`text-2xl pi ${
													getFileExtension(
														img.documentFileName
													) === "pdf"
														? "pi-file-pdf text-red-400"
														: "pi-image text-primary-700"
												}`}
											/> */}

											<span className="w-full">
												<img
													className="w-6"
													src={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageFirst}`}
													alt=""
												/>
											</span>
										</a>
									)}
								</div>
								<div className="field col-12 md:col-6">
									<h3>Image 1</h3>
									{values.imageSecond && (
										<a
											href={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageSecond}`}
											target="_blank"
											className="flex align-items-center justify-content-start"
											rel="noreferrer"
										>
											{/* <i
												className={`text-2xl pi ${
													getFileExtension(
														img.documentFileName
													) === "pdf"
														? "pi-file-pdf text-red-400"
														: "pi-image text-primary-700"
												}`}
											/> */}

											<span className="w-full">
												<img
													className="w-6"
													src={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageSecond}`}
													alt=""
												/>
											</span>
										</a>
									)}
								</div>
								<div className="field col-12 md:col-6">
									<InputNode header={"Upload Image 1"}>
										<FileUpload
											name="imageFirst"
											customUpload
											progressBarTemplate={
												progressTemplate
											}
											accept=".jpg,.png,.jpeg"
											uploadHandler={(e) =>
												onUploadfirst(e, setFieldValue)
											}
											maxFileSize={1000000}
											emptyTemplate={
												<p className="m-0">
													Drag and drop files to here
													to upload.
												</p>
											}
										/>
										{errors.imageFirst &&
											touched.imageFirst && (
												<small className="p-error">
													{errors.imageFirst}
												</small>
											)}
									</InputNode>
								</div>
								<div className="field col-12 md:col-6">
									<InputNode header={"Upload Image 2"}>
										<FileUpload
											name="imageSecond"
											customUpload
											progressBarTemplate={
												progressTemplate
											}
											accept=".jpg,.png,.jpeg"
											uploadHandler={(e) =>
												onUploadSecond(e, setFieldValue)
											}
											maxFileSize={1000000}
											emptyTemplate={
												<p className="m-0">
													Drag and drop files to here
													to upload.
												</p>
											}
										/>
										{errors.imageSecond &&
											touched.imageSecond && (
												<small className="p-error">
													{errors.imageSecond}
												</small>
											)}
									</InputNode>
								</div>

								<Button
									type="submit"
									label={
										props.type === "edit"
											? "Update"
											: "Create"
									}
									style={{ borderRadius: "6px" }}
								/>
							</Form>
						)}
					</Formik>
				)
			)}
		</>
	);
};

export default GeneralUpdate;
