import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DashboardService } from "../../../../services/DashboardService";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const ActiveAgentApplicationLeadCount = (props) => {
	const selector = useSelector((state) => state.persistData.auth.user);
	const dashboardService = new DashboardService();
	const [dropdown, setDropdown] = useState("");
	const [leaddetailsstate, setLeaddetailsstate] = useState([]);
	const navigate = useNavigate();
	const getData = (userName) => {
		dashboardService
			.active_agent_application_lead({ userName: userName })
			.then((res) => {
				setLeaddetailsstate(res);
			});
	};
	useEffect(() => {
		getData(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<h5 className="text-center my-1">Active Agent</h5>
			{selector.role.position === 1030 ? null : (
				<div className="flex justify-content-between pb-2">
					<Dropdown
						className="border-round-md"
						filter
						options={props.tsrmlist.map((elm) => ({
							...elm,
							name:
								(elm?.firstName ? elm?.firstName : "") +
								" " +
								(elm?.middleName ? elm?.middleName : "") +
								" " +
								(elm?.lastName ? elm?.lastName : ""),
						}))}
						optionLabel="name"
						optionValue="userName"
						placeholder="Tsrm"
						value={dropdown}
						onChange={(e) => {
							getData(e.value);
							setDropdown(e.value);
						}}
					/>
					<Button
						icon={"pi pi-refresh"}
						onClick={() => {
							setDropdown("");
							getData(null);
						}}
						className="p-button-text"
					/>
				</div>
			)}
			<DataTable
				paginator
				paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
				rows={7}
				value={leaddetailsstate}
				scrollable
			>
				<Column
					header={"Agent Code"}
					body={(elm) => (
						<Button
							className="p-0 m-0 p-button-text text-gray-800"
							onClick={() =>
								navigate(
									`/vendor/agent-profile/${elm.agentId}`,
									{
										state: {
											profileId: elm.profileId,
										},
									}
								)
							}
							label={elm.agentCode}
						/>
					)}
				/>
				<Column header={"Name"} field="fullName" />
				<Column header={"Mobile"} field="mobileNumber" />
				<Column header={"Allocated To"} field="allocatedTo" />
				<Column header={"Total Lead"} field="leadCount" />
				<Column header={"Total Application"} field="applicationCount" />
			</DataTable>
		</>
	);
};

export default ActiveAgentApplicationLeadCount;
