import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Paginator from "../../../../components/Paginator";
import {
	searchInitialState,
	searchReducer,
} from "../../../../components/searchReducer";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import Config from "../../../../shared/config";
import { AgentService } from "../../../../services/AgentService";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Field, Form, Formik } from "formik";
import { FeildDropdown, FieldInput } from "../../../../components/FieldInput";
import { Divider } from "primereact/divider";
import * as Yup from "yup";
// import { useGetAllDIstrictQuery } from "../../../../services/query/queryApi";
import { FileUpload } from "primereact/fileupload";
import { S3 } from "aws-sdk";
import Loader from "../../../../components/Loader";
import { produce } from "immer";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import debounce from "lodash.debounce";
const AgentProfileUpdate = () => {
	const [loader, setLoader] = useState(false);
	// const { data: districtList = [] } = useGetAllDIstrictQuery();
	const { id } = useParams();
	const { state: locationData } = useLocation();
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const navigate = useNavigate();
	const agentService = new AgentService();
	const [members, setMembers] = useReducer(dataReducer, {
		data: { data: [] },
		isLoading: false,
		error: false,
		errorMessage: null,
		isFetched: false,
	});
	const toastTL = useRef(null);
	const increment = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const pagesizechange = (e) => {
		// const newPageNo = Math.ceil(
		// 	(members.data.pageSize * members.data.pageNumber) / e.target.value
		// );

		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};

	const agentPartnerList = () => {
		const requestBody = {
			agentId: Number(id),
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.searchValue !== "" && searchState.searchValue),
			...(searchState.sortByColumn && searchState.sortType
				? {
						sorts: [
							searchState.sortByColumn +
								"," +
								searchState.sortType,
						],
				  }
				: { sorts: [] }),
		};
		setMembers({ type: Config.FETCH_CONFIG.start });
		agentService
			.getPartnerList(requestBody)
			.then((res) => {
				setMembers({ type: Config.FETCH_CONFIG.success, payload: res });
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setMembers({ type: Config.FETCH_CONFIG.stop }));
	};

	useEffect(() => {
		agentPartnerList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);

	// const getStatus = (data) => {
	// 	switch (data) {
	// 		case "VERIFIED":
	// 			return <span className="status status-success">Verified</span>;
	// 		case "ON_HOLD":
	// 			return <span className="status status-warning">On Hold</span>;
	// 		case "TEMPORARY_SUSPENDED":
	// 			return (
	// 				<span className="status status-danger">
	// 					Temporary Suspended
	// 				</span>
	// 			);
	// 		case "NOT_VERIFIED":
	// 			return <span className="status status-ban">Not Verified</span>;
	// 		case "BLOCKED":
	// 			return (
	// 				<span className="status status-danger-deep">Blocked</span>
	// 			);

	// 		default:
	// 			return <span className="status status-danger">No Data</span>;
	// 	}
	// };
	const agentStatus = (status) => {
		return status === null || status.toLowerCase() === "inactive" ? (
			<span className="status status-mute">Inactive</span>
		) : status.toLowerCase() === "blocked" ? (
			<span className="status status-danger-deep">Blocked</span>
		) : (
			<span className="status status-primary">Active</span>
		);
	};
	const rejectUpdateProfile = (id) => {
		setLoader(true);
		agentService
			.rejectUpdateProfile(id)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: res?.message,
					style: { color: "#000000" },
					life: 3000,
				});
				navigate(-1);
			})

			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoader(false));
	};
	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});
	const initialValues = {
		profileType: locationData.requestProfile
			? locationData.requestProfile
			: "",
		email: "",
		companyDetails: {
			name: "",
			type: "",
			address1: "",
			address2: "",
			address3: "",
			// representativeFirstName: "",
			// representativeLastName: "",
			// representativeMobile: "",
			// pincode: "", //num
			// districtId: "", //num
			designation: "",
			fileName: "",
			fileSequence: "",
			pan: "",
			panFile: "",
			...(locationData?.companyDetails
				? locationData?.companyDetails
				: {}),
		},
	};

	const validationSchema = Yup.object().shape({
		profileType: Yup.string().required("This field is required"),
		companyDetails: Yup.object().when("profileType", {
			is: (profileType) => profileType === "COMPANY",
			then: Yup.object().shape({
				name: Yup.string().required("This field is required"),
				type: Yup.string().required("This field is required"),
				address1: Yup.string().required("This field is required"),

				// representativeFirstName: Yup.string().required(
				// 	"This field is required"
				// ),
				// representativeLastName: Yup.string().required(
				// 	"This field is required"
				// ),
				// representativeMobile: Yup.string().required(
				// 	"This field is required"
				// ),
				// districtId: Yup.number().required("This field is required"),
				panFile: Yup.string().required("This field is required"),
				fileName: Yup.string().when("type", {
					is: (type) => !!type && type !== "Proprietor",
					then: Yup.string().required("This field is required"),
				}),
				fileSequence: Yup.string().when("type", {
					is: (type) => !!type && type !== "Proprietor",
					then: Yup.string().required("This field is required"),
				}),
				pan: Yup.string()
					.nullable()
					.matches(
						/[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
						"Pan Number is invalid"
					)
					.required("This field is required"),
			}),
		}),
	});
	function getFileExtension(filename) {
		const extension = filename?.split(".").pop();
		return extension;
	}
	const handleFormSubmit = (value) => {
		if (locationData?.approve) {
			setLoader(true);
			agentService
				.approveUpdateProfile(locationData?.requestId)
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: res?.message,
						style: { color: "#000000" },
						life: 3000,
					});
					navigate(-1);
				})

				.catch((e) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e?.message,
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => setLoader(false));
		} else {
			setLoader(true);
			const newData = produce(value, (state) => {
				for (let item in state) {
					if (item === "companyDetails") {
						const obj = state[item];
						for (let com in obj) {
							if (obj[com] === "") {
								obj[com] = null;
							}
						}
					}
					if (state[item] === "") {
						state[item] = null;
					}
				}
			});
			const { companyDetails, ...rest } = newData;
			const requestBody = {
				agentId: locationData?.agentId,
				profileType: rest?.profileType,
				...(rest?.profileType === "COMPANY"
					? { companyDetails: companyDetails }
					: { companyDetails: null }),
			};
			agentService
				.updateProfile(requestBody)
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: res?.message,
						style: { color: "#000000" },
						life: 3000,
					});
					navigate(-1);
				})

				.catch((e) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e?.message,
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => setLoader(false));
		}
	};
	const confirm = () => {
		confirmDialog({
			message: "Are you sure you want to proceed?",
			header: "Confirmation",
			icon: "pi pi-exclamation-triangle",
			accept: () => rejectUpdateProfile(locationData?.requestId),
			reject: () => {},
		});
	};
	const onUploadImage = (e, name, setFieldValue) => {
		setLoader(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.AGENT_PROFILE_BUCKET + "/" + locationData?.profileId,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err) => {
			if (err) {
				setLoader(false);
				return toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Select Document Types",
					style: { color: "#000000" },
					life: 3000,
				});
			}
			setLoader(false);
			if (name === "fileName") {
				setFieldValue("companyDetails.fileName", fileName, true);
				setFieldValue("companyDetails.fileSequence", newFileName, true);
			} else {
				setFieldValue("companyDetails.panFile", newFileName, true);
			}
		});
	};
	return (
		<>
			<ConfirmDialog />
			{loader && <Loader />}
			{members?.isLoading && <Loader />}
			<Button
				label="Back"
				className="p-button-text mb-1"
				icon="pi pi-angle-left"
				onClick={() => navigate(-1)}
			/>
			<Toast ref={toastTL} position="top-left" />
			<Card className="mb-3">
				<div className="flex justify-content-between align-items-center mb-3">
					<h2 className="m-0">
						{locationData?.profileData?.firstName
							? locationData?.profileData?.firstName
							: ""}{" "}
						{locationData?.profileData?.middleName
							? locationData?.profileData?.middleName
							: ""}{" "}
						{locationData?.profileData?.lastName
							? locationData?.profileData?.lastName
							: ""}{" "}
						{locationData?.profileData?.profileStatus ? (
							<span className="sq-status status-success">
								<i className="pi pi-verified" />
							</span>
						) : (
							<span className="sq-status status-danger">
								<i className="pi pi-exclamation-triangle" />
							</span>
						)}
					</h2>
					{/* {locationData?.profileData?.createdAt && (
						<span>
							<strong className="text-indigo-700">
								Created At :{" "}
							</strong>
							{locationData?.profileData?.createdAt
								? moment(locationData?.profileData?.createdAt).format(
										"ddd DD MMM,YYYY, hh:mm A"
								  )
								: ""}
						</span>
					)} */}
				</div>
				<div className="grid">
					{/* <div className="col-12">
						<hr />
					</div> */}
					<ul className="userLIst pl-2 mb-3 flex justify-content-between align-items-center flex-wrap flex-row">
						{locationData?.profileData?.agentCode && (
							<li>
								<strong>Agent Code : </strong>
								{locationData?.profileData?.agentCode}
							</li>
						)}

						{locationData?.profileData?.mobileNumber && (
							<li>
								<strong>Mobile : </strong>
								{locationData?.profileData?.mobileNumber}
							</li>
						)}

						{locationData?.profileData?.status && (
							<li className=" flex justify-content-start algin-items-center">
								<strong>Status : </strong>

								<p className="m-0 value stValue ml-2">
									{agentStatus(
										locationData?.profileData?.status
									)}
								</p>
							</li>
						)}
						{locationData?.profileData?.profileType && (
							<li>
								<strong>Current Profile Type : </strong>
								{locationData?.profileData?.profileType}
							</li>
						)}
					</ul>

					{/* <ul className="col-3 userLIst">
						{locationData?.profileData?.emailId && (
							<li>
								<strong>Email : </strong>
								{locationData?.profileData?.emailId}
							</li>
						)}
						{locationData?.profileData?.userName && (
							<li>
								<strong>Username : </strong>
								{locationData?.profileData?.userName}
							</li>
						)}
					</ul>

					<ul className="col-3 userLIst">
						{locationData?.profileData?.profileStatus && (
							<li className=" flex justify-content-start align-items-center">
								<strong>Profile Status : </strong>

								<p className="m-0 value ml-2">
									{getStatus(
										locationData?.profileData?.profileStatus
									)}
								</p>
							</li>
						)}

						{locationData?.profileData?.selfieVerified && (
							<li className=" flex justify-content-start align-items-center">
								<strong>Selfie Verified : </strong>

								<p className="m-0 ml-2 value">
									{locationData?.profileData?.selfieVerified ? (
										<span className="sq-status status-success">
											<i className="pi pi-verified" />
										</span>
									) : (
										<span className="sq-status status-danger">
											<i className="pi pi-exclamation-triangle" />
										</span>
									)}
								</p>
							</li>
						)}
					</ul>

					<ul className="col-3 userLIst">
						{locationData?.profileData?.pincode && (
							<li>
								<strong>Pincode : </strong>
								{locationData?.profileData?.pincode}
							</li>
						)}

						{locationData?.profileData?.districtName && (
							<li>
								<strong>District Name : </strong>
								{locationData?.profileData?.districtName}
							</li>
						)}

						{locationData?.profileData?.stateName && (
							<li>
								<strong>State Name : </strong>
								{locationData?.profileData?.stateName}
							</li>
						)}

						{locationData?.profileData?.zoneName && (
							<li>
								<strong>Zone Name : </strong>
								{locationData?.profileData?.zoneName}
							</li>
						)}

						{locationData?.profileData?.panVerified && (
							<li className=" flex justify-content-start align-items-center">
								<strong>Pan Verified : </strong>

								<p className="m-0 ml-2 value">
									{locationData?.profileData?.panVerified ? (
										<span className="sq-status status-success">
											<i className="pi pi-verified" />
										</span>
									) : (
										<span className="sq-status status-danger">
											<i className="pi pi-exclamation-triangle" />
										</span>
									)}
								</p>
							</li>
						)}
					</ul> */}
					<Divider>Choose Profile Type</Divider>
				</div>

				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={handleFormSubmit}
					enableReinitialize
				>
					{({
						values,
						handleSubmit,
						resetForm,
						setFieldValue,
						errors,
					}) => (
						<Form
							onSubmit={handleSubmit}
							className="grid form-grid"
						>
							<div className="col-12 md:col-3">
								<div className="grid form-grid align-items-center">
									<div className="field col-12 md:col-12">
										<Field
											header={"Profile Type"}
											name={"profileType"}
											component={FeildDropdown}
											options={Config.ProfileType}
											star={"*"}
										/>
									</div>
								</div>
							</div>
							{/* <div className="col-12 md:col-3">
								<div className="grid form-grid align-items-center">
									<div className="field col-12 md:col-12">
										<Field
											header={"Email"}
											name={"email"}
											component={FieldInput}
										/>
									</div>
								</div>
							</div> */}
							{values.profileType === "COMPANY" && (
								<div className="col-12 md:col-12">
									<Divider>Company Details</Divider>
									<div className="grid form-grid">
										<div className="field col-12 md:col-3">
											<Field
												name="companyDetails.name"
												header="Name"
												component={FieldInput}
												star={"*"}
											/>
										</div>
										<div className="field col-12 md:col-3">
											<Field
												name="companyDetails.type"
												header="Company Type"
												component={FeildDropdown}
												star={"*"}
												value={
													values.companyDetails.type
												}
												onChange={(e) => {
													setFieldValue(
														"companyDetails.type",
														e.value,
														true
													);
													setFieldValue(
														"companyDetails.designation",
														e.value?.toUpperCase(),
														true
													);
												}}
												options={[
													// {
													// 	label: "Proprietorship",
													// 	value: "PROPRIETORSHIP",
													// },
													// {
													// 	label: "Partnership",
													// 	value: "PARTNERSHIP",
													// },
													// {
													// 	label: "Pvt Ltd",
													// 	value: "PVT_LTD",
													// },
													{
														label: "PROPRIETOR",
														value: "Proprietor",
													},
													{
														label: "PARTNERSHIP",
														value: "Partner",
													},
													{
														label: "PVT LTD.",
														value: "Director",
													},
												]}
											/>
										</div>
										{/* <div className="field col-12 md:col-3">
											<Field
												name="companyDetails.designation"
												header="Designation"
												component={FieldInput}
												disabled
											/>
										</div> */}
										<div className="field col-12 md:col-3">
											<Field
												name="companyDetails.address1"
												header="Address1"
												component={FieldInput}
												star={"*"}
											/>
										</div>
										<div className="field col-12 md:col-3">
											<Field
												name="companyDetails.address2"
												header="Address2"
												component={FieldInput}
											/>
										</div>
										<div className="field col-12 md:col-3">
											<Field
												name="companyDetails.address3"
												header="Address3"
												component={FieldInput}
											/>
										</div>
										{/* <div className="field col-12 md:col-3">
											<Field
												name="companyDetails.representativeFirstName"
												header="Representative First Name"
												component={FieldInput}
												star={"*"}
											/>
										</div>
										<div className="field col-12 md:col-3">
											<Field
												name="companyDetails.representativeLastName"
												header="Representative Last Name"
												component={FieldInput}
												star={"*"}
											/>
										</div> */}
										{/* <div className="field col-12 md:col-3">
											<Field
												name="companyDetails.representativeMobile"
												header="Representative Mobile"
												component={FieldInput}
												star={"*"}
											/>
										</div> */}
										{/* <div className="field col-12 md:col-3">
											<Field
												star={"*"}
												filter
												name="companyDetails.districtId"
												header="District"
												component={FeildDropdown}
												options={districtList}
												optionLabel={"name"}
												optionValue={"id"}
												optionGroupLabel="name"
												optionGroupChildren="districtList"
											/>
										</div> */}
										{/* <div className="field col-12 md:col-3">
											<Field
												star={"*"}
												name="companyDetails.pincode"
												header="Pincode"
												component={FieldInput}
												type={"number"}
											/>
										</div> */}
										<div className="field col-12 md:col-3">
											<Field
												star={"*"}
												name="companyDetails.pan"
												header="PAN"
												component={FieldInput}
											/>
										</div>
										<div className="col-12 grid">
											{values.companyDetails.type !==
												"Proprietor" && (
												<div className="field col-12 md:col-6">
													{Boolean(
														values?.companyDetails
															?.fileSequence
													) && (
														<a
															href={
																Config.AGENT_BUCKET_ACCESS_URL +
																"/agent-profile/" +
																locationData?.profileId +
																"/" +
																values
																	?.companyDetails
																	?.fileSequence
															}
															target={"_blank"}
															rel="noopener noreferrer"
														>
															{getFileExtension(
																values
																	?.companyDetails
																	?.fileSequence
															) === "pdf" ? (
																<i
																	className={`text-5xl pi pi-file-pdf text-red-400`}
																/>
															) : (
																<span className="c-list-box-it">
																	<img
																		style={{
																			width: "40px",
																		}}
																		src={
																			Config.AGENT_BUCKET_ACCESS_URL +
																			"/agent-profile/" +
																			locationData?.profileId +
																			"/" +
																			values
																				?.companyDetails
																				?.fileSequence
																		}
																		alt={
																			values
																				?.companyDetails
																				?.fileSequence
																		}
																	/>
																</span>
															)}
														</a>
													)}
													<h4>Document Upload</h4>
													<FileUpload
														customUpload
														accept=".jpg,.png,.jpeg, .pdf"
														uploadHandler={(e) =>
															onUploadImage(
																e,
																"fileName",
																setFieldValue
															)
														}
														maxFileSize={1000000}
														emptyTemplate={
															<p className="m-0">
																Drag and drop
																files to here to
																upload.
															</p>
														}
													/>
													{Boolean(
														errors?.companyDetails
															?.fileName
													) && (
														<small className="text-red-600">
															{
																errors
																	?.companyDetails
																	?.fileName
															}
														</small>
													)}
												</div>
											)}

											<div className="field col-12 md:col-6">
												{Boolean(
													values?.companyDetails
														?.panFile
												) && (
													<a
														href={
															Config.AGENT_BUCKET_ACCESS_URL +
															"/agent-profile/" +
															locationData?.profileId +
															"/" +
															values
																?.companyDetails
																?.panFile
														}
														target={"_blank"}
														rel="noopener noreferrer"
													>
														{getFileExtension(
															values
																?.companyDetails
																?.panFile
														) === "pdf" ? (
															<i
																className={`text-5xl pi pi-file-pdf text-red-400`}
															/>
														) : (
															<span className="c-list-box-it">
																<img
																	style={{
																		width: "40px",
																	}}
																	src={
																		Config.AGENT_BUCKET_ACCESS_URL +
																		"/agent-profile/" +
																		locationData?.profileId +
																		"/" +
																		values
																			?.companyDetails
																			?.panFile
																	}
																	alt={
																		values
																			?.companyDetails
																			?.panFile
																	}
																/>
															</span>
														)}
													</a>
												)}
												<h4>PAN Upload</h4>
												<FileUpload
													customUpload
													accept=".jpg,.png,.jpeg, .pdf"
													uploadHandler={(e) =>
														onUploadImage(
															e,
															"panFile",
															setFieldValue
														)
													}
													maxFileSize={1000000}
													emptyTemplate={
														<p className="m-0">
															Drag and drop files
															to here to upload.
														</p>
													}
												/>
												{Boolean(
													errors?.companyDetails
														?.panFile
												) && (
													<small className="text-red-600">
														{
															errors
																?.companyDetails
																?.panFile
														}
													</small>
												)}
											</div>
										</div>
									</div>
								</div>
							)}
							<div className=" flex gap-2 col-12">
								<Button
									className=" danger-btn"
									label="Reset"
									icon={"pi pi-refresh"}
									type="button"
									onClick={() => resetForm()}
								/>
								<Button
									className=""
									label="Submit"
									type="submit"
									disabled={members?.data?.data?.length !== 0}
								/>

								{locationData?.requestId && (
									<Button
										className="danger-btn"
										label="Reject"
										icon={"pi pi-times"}
										type="button"
										onClick={() => {
											confirm();
										}}
									/>
								)}
							</div>
						</Form>
					)}
				</Formik>
			</Card>
			{locationData?.profileData?.profileType !== "INDIVIDUAL" && (
				<>
					{members.data.data?.length > 0 && (
						<div className="w-full">
							<h4 className="text-center mb-2">Partner List</h4>
							<DataTable
								value={
									members.data.data &&
									members.data.data.map((item, index) => ({
										...item,
										index:
											members.data.pageSize *
												members.data.pageNo -
											members.data.pageSize +
											1 +
											index,
									}))
								}
								responsiveLayout="scroll"
								breakpoint="960px"
							>
								<Column field="index" />
								<Column header={"Name"} field="fullName" />
								<Column field="status" header={"Status"} />
								<Column
									body={(item) =>
										item?.registered ? (
											<span className="text-green-800 bg-green-100 px-3 py-1">
												Registered
											</span>
										) : (
											<span className="text-red-800 bg-red-100 px-3 py-1">
												Not Registered
											</span>
										)
									}
									header={"Registered"}
								/>
								<Column
									field="mobileNumber"
									header={"Mobile"}
								/>
							</DataTable>

							<Paginator
								pageSize={searchState.pageSize}
								firstPage={members.data.firstPage}
								lastPage={members.data.lastPage}
								decrement={decrement}
								increment={increment}
								pagesizechange={pagesizechange}
								pageNo={members.data.pageNo}
								totalPages={members.data.totalPages}
								totalElements={members.data.totalElements}
							/>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default AgentProfileUpdate;
