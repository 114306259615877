import React, { useState, useRef, useReducer } from "react";
import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import * as Yup from "yup";
import { UsersService } from "../../../services/UsersService";
import {
	FeildDropdown,
	FieldInput,
	FieldMultiselect,
	FieldPassword,
	FieldRadio,
} from "../../../components/FieldInput";
import { Toast } from "primereact/toast";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import Config from "../../../shared/config";
import {
	convertNullToString,
	convertObjNull,
} from "../../../utils/UtilsFunction";
// import { Chip } from "primereact/chip";
import debounce from "lodash.debounce";
const AddUserDialog = (props) => {
	const userService = new UsersService();
	const toast = useRef(null);
	const [loading1, setLoading1] = useState(false);
	const [userAreaList, setUserAreaList] = useState([]);
	const [userState, userDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const initialValues = {
		employeeId: "",
		role: { name: null, position: null },
		userName: "",
		firstName: "",
		lastName: "",
		middleName: "",
		emailId: "",
		gender: "Male",
		mobileNumber: "",
		salutation: "Mr",
		password: "",
		areaList: "",
		areaType: "",
		user: { id: null },
		parentId: "",
	};

	const validationSchema = Yup.object().shape({
		firstName: Yup.string()
			.required("First name cannot be empty.")
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid first name"
			),
		lastName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid last name"
			)
			.required("Last name cannot be empty."),
		middleName: Yup.string().matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid middle name"
		),
		emailId: Yup.string()
			.required("Email id cannot be empty.")
			.email("Invalid email id."),
		mobileNumber: Yup.string()
			.min(10, "Mobile No. should be 10 digit")
			.max(10, "Mobile No. should not be more than 10 digit")
			.matches(/^[0]?[6789]\d{9}$/, "Invalid Mobile No.")
			.required("Mobile no cannot be empty."),
		employeeId: Yup.string()

			.required("Employee Id cannot be empty.")
			.matches(/^\S+$/, "Invalid Id"),
		role: Yup.object().test({
			test: (role) => role.name !== null,
			message: "Role is required",
		}),
		user: Yup.object().when("role", {
			is: (role) =>
				role.position !== 1 &&
				role.position !== 50 &&
				role.position !== 100 &&
				role.position !== 5000,
			then: Yup.object().test({
				test: (role) => role.id !== null,
				message: "Role is required",
			}),
		}),
		areaList: Yup.array()
			.of(Yup.number())
			.when("role", {
				is: (role) =>
					role.position !== 50 &&
					role.position !== 100 &&
					role.position !== 5000,
				then: Yup.array()
					.of(Yup.number())
					.min(1, "Field cannot be empty")
					.required("Field cannot be empty"),
			}),

		// user: Yup.object()

		// 	.when("role", {
		// is: (role) => role.name === "Area Sales Manager",
		// then: Yup.object().required("Zonal  Manager cannot be empty."),
		// 	})
		// 	.when("role", {
		// 		is: (role) => role.name === "Regional Manager",
		// 		then: Yup.object().required(
		// 			"Area Sales Manager cannot be empty."
		// 		),
		// 	})
		// 	.when("role", {
		// 		is: (role) => role.name === "Sales Executive",
		// 		then: Yup.object().required(
		// 			"Regional Manager cannot be empty."
		// 		),
		// 	}),
	});

	const handleSubmitForm = (data) => {
		const { role, user, ...rest } = data;

		const body = {
			...rest,
			roleName: role.name,
			roleId: role.id,
			areaType: role.areaType,
			parentId:
				role.position === 1 && userState.data.length > 0
					? ""
					: user.userName,
		};

		const requestBody = convertObjNull(body);

		setLoading1(true);
		userService
			.createUser(requestBody)
			.then(() => {
				toast.current.show({
					severity: "success",
					summary: "Successful",
					detail: "User added Successfully",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoading1(false))
			.finally(
				debounce(() => {
					props.setMemberDialog(false);
					props.userDataTableValue();
				}, 1000)
			);
	};
	const onRoleChanges = (e, setFieldValue) => {
		setFieldValue("user", initialValues.user, true);
		setFieldValue("areaList", initialValues.areaList, true);
		setUserAreaList([]);
		setFieldValue("role", e.value, true);
		if (e.value.position !== 50 && e.value.position !== 100) {
			let position = e.value.position - 1;
			if (position === 0) {
				userDispatch({ type: Config.FETCH_CONFIG.start });
				userService
					.getZones()
					.then((res) => {
						const data = res.map((e) => ({ ...e, value: e.id }));
						userDispatch({
							type: Config.FETCH_CONFIG.success,
							payload: data,
						});
					})
					.finally(() =>
						userDispatch({ type: Config.FETCH_CONFIG.stop })
					);
			} else {
				userDispatch({ type: Config.FETCH_CONFIG.start });
				userService
					.getUserByRolePosition(position)
					.then((res) => {
						let users = res.roleUserList.map((item) => {
							const strObj = convertNullToString(item);
							return {
								...strObj,
								name: `${strObj.firstName} ${strObj.middleName} ${strObj.lastName}`,
							};
						});

						userDispatch({
							type: Config.FETCH_CONFIG.success,
							payload: { ...res, roleUserList: users },
						});
					})
					.catch((e) =>
						userDispatch({
							type: Config.FETCH_CONFIG.error,
							payload: e,
						})
					)
					.finally(() =>
						userDispatch({ type: Config.FETCH_CONFIG.stop })
					);
			}
		}
	};

	const onUserChanges = (val, setFieldValue) => {
		setFieldValue("user", val, true);
		setFieldValue("areaList", initialValues.areaList, true);
		setUserAreaList([]);
		userService.getUsersAreaList(val.userName).then((res) => {
			setUserAreaList(res);
		});
	};

	const countryOptionTemplate = (item) => {
		return (
			<>
				<h4 className="my-0">{item.firstName}</h4>
				<div
					style={{
						width: "100%",
						maxWidth: "450px",
						display: "flex",
						flexWrap: "wrap",
					}}
				>
					{item.ownAreaList.map((e) => (
						// <Chip
						// 	className="m-1 bg-indigo-200 text-indigo-700 capitalize"
						// 	label={e.name}
						// 	key={e.id}
						// />
						<span className="status status-primary m-1" key={e.id}>
							{e.name}
						</span>
					))}
				</div>
			</>
		);
	};

	return (
		<>
			<Toast ref={toast} position="top-left" />
			<div className="col-12 p-0">
				<div className="card modal-form-grid">
					<Formik
						initialValues={initialValues}
						onSubmit={handleSubmitForm}
						validationSchema={validationSchema}
					>
						{({
							handleSubmit,
							setFieldValue,
							values,
							resetForm,
						}) => (
							<Form className="grid" onSubmit={handleSubmit}>
								<>
									<div className="field col-12">
										<Field
											name="salutation"
											component={FieldRadio}
											radiolist={[
												{
													id: "Mr",
													name: "Mr",
													value: "Mr",
												},
												{
													id: "Mrs",
													name: "Mrs",
													value: "Mrs",
												},
											]}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"First Name"}
											name={"firstName"}
											component={FieldInput}
											star={"*"}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Middle Name"}
											name={"middleName"}
											component={FieldInput}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Last Name"}
											name={"lastName"}
											component={FieldInput}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-6">
										<Field
											header={"Email Id"}
											name={"emailId"}
											component={FieldInput}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-6">
										<Field
											header={"Mobile No"}
											name={"mobileNumber"}
											component={FieldInput}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-6">
										<Field
											name="gender"
											header={"Gender"}
											component={FieldRadio}
											radiolist={[
												{
													id: "Male",
													name: "Male",
													value: "Male",
												},
												{
													id: "Female",
													name: "Female",
													value: "Female",
												},
											]}
										/>
									</div>
									<div className="field col-12 md:col-6">
										<Field
											header={"Employee Id"}
											name={"employeeId"}
											component={FieldInput}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-6">
										<Field
											header={"User Name"}
											name={"userName"}
											component={FieldInput}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-6">
										<Field
											header={"Password"}
											name={"password"}
											component={FieldPassword}
											star={"*"}
										/>
									</div>
								</>

								<div className="field col-12 md:col-6">
									<Field
										header={"Select Role"}
										name={"role"}
										component={FeildDropdown}
										filter
										star={"*"}
										onChange={(e) =>
											onRoleChanges(e, setFieldValue)
										}
										options={props.roles}
										optionLabel="name"
									/>
								</div>

								{values?.role?.position === 1 ? (
									<>
										{userState.isLoading ? (
											<div className="field flex align-items-end justify-conetent-end flex-column col-12 md:col-6">
												<Skeleton className="w-full flex-1 mt-4 " />
											</div>
										) : (
											<div className="field col-12 md:col-6">
												<Field
													header={`Select ${values.role.areaType.toLowerCase()}`}
													name={"areaList"}
													component={FieldMultiselect}
													star={"*"}
													filter
													options={userState.data}
													optionLabel="name"
												/>
											</div>
										)}
									</>
								) : (
									values?.role?.position !== 50 &&
									values?.role?.position !== 100 &&
									values?.role?.position !== 5000 && (
										<>
											{userState?.isLoading ? (
												<div className="field flex align-items-end justify-conetent-end flex-column col-12 md:col-6">
													<Skeleton className="w-full flex-1 mt-4 " />
												</div>
											) : (
												userState?.data
													?.roleUserList && (
													// userState.data.roleUserList
													// 			.length > 0 &&
													<>
														<div className="field col-12 md:col-6">
															<Field
																header={`Select ${userState.data?.role}`}
																name={"user"}
																component={
																	FeildDropdown
																}
																filter
																star={"*"}
																options={
																	userState
																		.data
																		?.roleUserList
																}
																optionLabel="name"
																// itemTemplate={
																// 	countryOptionTemplate
																// }
																value={
																	values.user
																}
																onChange={(
																	e
																) => {
																	onUserChanges(
																		e.value,
																		setFieldValue
																	);
																	// setFieldValue(
																	// 	"areaList",
																	// 	initialValues.areaList,
																	// 	true
																	// );
																	// setFieldValue(
																	// 	"user",
																	// 	e.value,
																	// 	true
																	// );
																}}
															/>
														</div>

														{userAreaList && (
															// userAreaList.length > 0 &&
															<div className="field col-12 md:col-6">
																<Field
																	header={`Select ${values.role?.areaType?.toLowerCase()}`}
																	name={
																		"areaList"
																	}
																	filter
																	component={
																		FieldMultiselect
																	}
																	star={"*"}
																	options={userAreaList?.map(
																		(
																			item
																		) => ({
																			...item,
																			value: item.id,
																		})
																	)}
																	optionLabel="name"
																/>
															</div>
														)}
													</>
												)
											)}
										</>
									)
								)}

								<div className="col-12 pb-0">
									<div className="flex justify-content-end align-items-center w-full">
										<Button
											type="button"
											label="Cancel"
											className="danger-btn mr-2"
											icon="pi pi-times"
											onClick={() => {
												resetForm();
												userDispatch({
													type: Config.FETCH_CONFIG
														.ini,
												});
												props.memberDialogClose(false);
											}}
										/>
										<Button
											loading={loading1}
											type="submit"
											label="Save"
											className="primary-btn"
											icon="pi pi-save"
										/>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</>
	);
};

export default AddUserDialog;
