import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Panel } from "primereact/panel";
import React, { useState, useRef, useEffect } from "react";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
	FieldMultiselect,
	FieldRadio,
	FieldTextArea,
} from "../components/FieldInput";
import Config from "../shared/config";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { convertNullToString, convertObjNull } from "../utils/UtilsFunction";
import ExternalService from "./ExtService";
import ExtDocumentUpload from "./DocumentUploadExternal";
import * as Yup from "yup";
import { Divider } from "primereact/divider";
import { ConnectedFocusError } from "focus-formik-error";

export const validationSchema = Yup.object().shape({
	otherReason: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid reason name"
		)
		.nullable(),
	mobile: Yup.string()
		.matches(/^[1-9]\d{9}$/gi, "Mobile number should be 10 digit number")
		.nullable(),
	pan: Yup.string()
		.matches(
			/([A-Z]{5}[0-9]{4}[A-Z]{1})/,
			"Please enter valid pancard number"
		)
		.nullable(),
	state: Yup.string().nullable(),
	pincode: Yup.string()
		.matches(/^\d{6}$/, "Please enter valid pincode")
		.nullable(),
	gender: Yup.string().nullable(),
});

const LifeInsuranceLoan = ({ id, data, getData, setSpinner }) => {
	const [checkboxState, setCheckBox] = useState(false);
	const convertData = convertNullToString(data);
	const loanService = new ExternalService();
	const toastTL = useRef(null);
	const [acceptance, setAcceptance] = useState(null);
	const [district, setDistrict] = useState([]);
	const [reason, setReason] = useState([]);
	const [allDisease, setAllDisease] = useState([]);

	const dateValidation = moment(new Date()).subtract(18, "years").toDate();

	const getReason = () => {
		loanService
			.getReson(
				data?.program ? data?.program?.productId?.id : data.productId
			)
			.then((res) => {
				setReason(
					res.map((e) => {
						return { ...e, value: e.id };
					})
				);
			})
			.catch((e) => console.log(e))
			.finally(() => {});
	};

	const getDisease = () => {
		loanService
			.getDisease()
			.then((res) => {
				setAllDisease(
					res.map((e) => {
						return { ...e, value: e.id };
					})
				);
			})
			.catch((e) => console.log(e))
			.finally(() => {});
	};

	const handleDistrict = (e, setFieldValue) => {
		getDistrictList(e);
		setFieldValue("state", e, true);
	};

	const getDistrictList = (e) => {
		loanService
			.districtList(e)
			.then((res) => {
				setDistrict({
					payload: res.map((item) => ({ ...item, value: item.id })),
				});
			})
			.catch(() => {});
	};

	useEffect(() => {
		Promise.all([
			getReason(),
			getDistrictList(convertData.state),
			getDisease(),
		]);
	}, []);

	const handleFormSubmit = (value, acton) => {
		setSpinner(true);
		const reqBody = {
			...value,

			dob:
				value.dob !== ""
					? moment(value.dob).format("YYYY-MM-DD")
					: null,
			proposerDob:
				value.proposerDob !== ""
					? moment(value.proposerDob).format("YYYY-MM-DD")
					: null,
			nomineeDob:
				value.nomineeDob !== ""
					? moment(value.nomineeDob).format("YYYY-MM-DD")
					: null,
		};
		const submitData = convertObjNull(reqBody);

		if (
			data.alias === "ULIP" ||
			data.alias === "TRADI" ||
			data.alias === "CIP" ||
			data.alias === "CEP" ||
			data.alias === "TIP" ||
			data.alias === "RIP"

			// data.program.productId.alias === "ULIP" ||
			// data.program.productId.alias === "TRADI" ||
			// data.program.productId.alias === "CIP" ||
			// data.program.productId.alias === "CEP" ||
			// data.program.productId.alias === "TIP" ||
			// data.program.productId.alias === "RIP"
		) {
			loanService
				.insuranceSaveDraft(submitData)
				.then((res) => {
					if (res) {
						toastTL.current.show({
							severity: "success",
							summary: "Success",
							detail: "Draft Save Successfully",
							life: 3000,
						});
					}
				})
				.catch((e) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						life: 3000,
					})
				)
				.finally(() => setSpinner(false));
		} else {
			loanService
				.draftSave(submitData)
				.then((res) => {
					if (res) {
						toastTL.current.show({
							severity: "success",
							summary: "Success",
							detail: "Draft Save Successfully",
							life: 3000,
						});
					}
				})
				.catch((e) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						life: 3000,
					})
				)
				.finally(() => setSpinner(false));
		}
	};

	const handleSubmit = () => {
		setSpinner(true);
		loanService
			.finalSubmit(id)
			.then((res) => {
				if (res) {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: `${convertData.program.name} Application Submit Successfully`,
						life: 3000,
					});
					getData();
				}
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			})
			.finally(() => setSpinner(false));
	};

	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			<h3 className="text-center">
				{convertData.program.name} Application
			</h3>
			<div className="px-5 py-3" style={{ background: "#f3f5f7" }}>
				<p className={"font-semibold"}>
					Rainbow's Application Number (RAN) :&nbsp;&nbsp;{" "}
					{data.number} | Date:&nbsp;&nbsp;
					{moment(data.application_date).format("Do MMM, YYYY")}
				</p>
				<Formik
					onSubmit={handleFormSubmit}
					initialValues={{
						...convertData,
						dob:
							convertData.dob !== ""
								? new Date(convertData.dob)
								: "",
						proposerDob:
							convertData.proposerDob !== ""
								? new Date(convertData.proposerDob)
								: "",
						nomineeDob:
							convertData.nomineeDob !== ""
								? new Date(convertData.nomineeDob)
								: "",
					}}
					validationSchema={validationSchema}
					// enableReinitialize
				>
					{({
						values,
						handleChange,
						handleSubmit,
						setFieldValue,
					}) => (
						<Form onSubmit={handleSubmit} autoComplete="off">
							<ConnectedFocusError />

							<Panel
								header={<h3 className="m-0">Basic Details</h3>}
								className={"my-2"}
								toggleable
							>
								<div className="form-grid grid">
									<div className="field col-12 md:col-4">
										<Field
											header={"Program name"}
											name={"programName"}
											value={values.program.name}
											component={FieldInput}
											disabled={true}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"PAN"}
											name={"pan"}
											value={values.pan}
											component={FieldInput}
											disabled={true}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Application number"}
											name={"applicationNumber"}
											value={values.number}
											component={FieldInput}
											disabled={true}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Product name"}
											name={"productName"}
											value={values.productName}
											component={FieldInput}
											disabled={true}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Purpose of Insurance"}
											name={"reasonId"}
											filter
											value={values.reasonId}
											component={FeildDropdown}
											options={[
												...reason,
												{
													reason: "Other",
													value: 0,
												},
											]}
											optionLabel="reason"
										/>
									</div>

									{values.reasonId === 0 && (
										<div className="field col-12 md:col-4">
											<Field
												header={"Other Reason"}
												name={"otherReason"}
												value={values.otherReason}
												component={FieldInput}
											/>
										</div>
									)}
									<div className="col-12 flex align-items-end justify-content-end">
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									</div>
								</div>
							</Panel>

							<Panel
								header={
									<h3 className="m-0">Insurer Details</h3>
								}
								className={"my-2"}
								toggleable
							>
								<div className="form-grid grid">
									<div className="field col-12 md:col-4">
										<div className="grid">
											<div className="field col-12 md:col-4">
												<Field
													header={"Salutation"}
													name={"salutation"}
													component={FeildDropdown}
													options={Config.SALUTATION}
												/>
											</div>
											<div className="field col-12 md:col-8">
												<Field
													header={"First Name"}
													name={"firstName"}
													component={FieldInput}
												/>
											</div>
										</div>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Middle Name"}
											name={"middleName"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Last Name"}
											name={"lastName"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Mobile Number"}
											name={"mobile"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"AlternativeMobile No"}
											name={"alternativeMobile"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Email"}
											name={"personalMail"}
											component={FieldInput}
											type={"email"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"PAN"}
											name={"pan"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<div className="grid">
											<div className="field col-12 md:col-6">
												<Field
													header={"Date Of Birth"}
													name={"dob"}
													component={FeildCalender}
												/>
											</div>
											<div className="field col-12 md:col-6">
												<Field
													header={"Gender"}
													name={"gender"}
													component={FeildDropdown}
													options={Config.GENDERS}
													optionLabel={"name"}
												/>
											</div>
										</div>
									</div>

									<div className="field col-12 md:col-4">
										<div className="grid">
											<div className="field col-12 md:col-4 flex flex-row align-items-center">
												<label
													className={
														"block text-sm mr-2 mb-0	"
													}
												>
													Smoke
												</label>
												<Checkbox
													name="isSmoke"
													onChange={handleChange}
													checked={values.isSmoke}
													value={values.isSmoke}
												/>
											</div>
											<div className="field col-12 md:col-4 flex flex-row align-items-center">
												<label
													className={
														"block text-sm mr-2 mb-0	"
													}
												>
													Chew Tabacco
												</label>
												<Checkbox
													name="isChewTobacco"
													onChange={handleChange}
													checked={
														values.isChewTobacco
													}
													value={values.isChewTobacco}
												/>
											</div>
											<div className="field col-12 md:col-4 flex flex-row align-items-center">
												<label
													className={
														"block text-sm mr-2 mb-0	"
													}
												>
													Alcohol
												</label>
												<Checkbox
													name="isAlcohol"
													onChange={handleChange}
													checked={values.isAlcohol}
													value={values.isAlcohol}
												/>
											</div>
										</div>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Marital Status"}
											name={"maritalStatus"}
											component={FeildDropdown}
											options={Config.MARITAL_STATUS}
										/>
									</div>

									{values.maritalStatus === "Married" && (
										<div className="field col-12 md:col-4">
											<Field
												header={"Spouse’s Full Name"}
												name={"spouseName"}
												component={FieldInput}
											/>
										</div>
									)}

									<div className="field col-12 md:col-4">
										<Field
											header={"Father’s Name"}
											name={"fatherName"}
											component={FieldInput}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Mother’s Name"}
											name={"motherMaidenName"}
											component={FieldInput}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Height (Inch)"}
											name={"height"}
											component={FieldInput}
											type={"number"}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Weight (Kg)"}
											name={"weight"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Address Line 1"}
											name={"addressLine1"}
											component={FieldTextArea}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"State"}
											name={"state"}
											filter
											onChange={(e) => {
												handleDistrict(
													e.value,
													setFieldValue
												);
											}}
											component={FeildDropdown}
											options={Config.stateData}
											optionLabel={"name"}
											star={"*"}
										/>
									</div>
									{/* {values.state && (
										<div className="field col-12 md:col-4">
											<Field
												header={"District"}
												name={"district"}
												filter
												component={FeildDropdown}
												// options={
												// 	districtListState.districtList
												// }
												options={getDistrictData(
													values.state
												)}
												optionLabel={"name"}
											/>
										</div>
									)} */}
									<div className="field col-12 md:col-4">
										<Field
											header={"District"}
											name={"district"}
											filter
											component={FeildDropdown}
											options={district.payload}
											optionLabel={"name"}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											name={"pincode"}
											header={"pincode"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Occupation Type"}
											name={"occupationType"}
											component={FeildDropdown}
											options={Config.OCCUPATION}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Highest Qualification"}
											name={"highestQualification"}
											component={FeildDropdown}
											options={Config.QUALIFICATION}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Company Type"}
											name={"companyType"}
											component={FeildDropdown}
											options={Config.ORGANIZATION}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Designation"}
											name={"designation"}
											component={FieldInput}
											// options={Config.DESIGNATION}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											name={"annualIncome"}
											header={"Annual income"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											name={"workingPeriod"}
											header={"Working period"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											name={"policyDetails"}
											header={"Existing Policy details"}
											component={FieldInput}
										/>
									</div>
									<h4 className="col-12">
										Preferred Mailing Address
									</h4>
									<div className="flex flex-wrap gap-3">
										<Field
											name={"preferredMailingAddress"}
											component={FieldRadio}
											radiolist={[
												{
													id: "Present Address",
													name: "Present Address",
													value: "Present Address",
												},
												{
													id: "Permanent Address",
													name: "Permanent Address",
													value: "Permanent Address",
												},
											]}
										/>
									</div>

									<div className="col-12 flex align-items-end justify-content-end">
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									</div>
								</div>
							</Panel>

							<Panel
								header={
									<h3 className="m-0">Proposer Details</h3>
								}
								className={"my-2"}
								toggleable
							>
								<div className="form-grid grid">
									<div className="col-12">
										<Checkbox
											onChange={(e) => {
												setCheckBox(e.checked);
												if (e.checked) {
													setFieldValue(
														"proposerMaritalStatus",

														values.maritalStatus,
														true
													);
													setFieldValue(
														"proposerDob",
														values.dob
															? new Date(
																	values.dob
															  )
															: "",
														true
													);
													setFieldValue(
														"proposerGender",
														values.gender,
														true
													);
													setFieldValue(
														"proposerOccupation",
														values.occupationType,
														true
													);
													setFieldValue(
														"proposerQualification",
														values.highestQualification,
														true
													);
													setFieldValue(
														"proposerCompanyType",
														values.companyType,
														true
													);

													setFieldValue(
														"proposerMobile",
														values.mobile,
														true
													);

													setFieldValue(
														"proposerEmail",
														values.personalMail,
														true
													);

													setFieldValue(
														"proposerFirstName",
														values.firstName,
														true
													);

													setFieldValue(
														"proposerMiddleName",
														values.middleName,
														true
													);

													setFieldValue(
														"proposerLastName",
														values.lastName,
														true
													);

													setFieldValue(
														"proposerSpouseName",
														values.spouseName,
														true
													);

													setFieldValue(
														"proposerMotherMaidenName",
														values.motherMaidenName,
														true
													);

													setFieldValue(
														"proposerAnnualIncome",
														values.annualIncome,
														true
													);

													setFieldValue(
														"proposerWorkingPeriod",
														values.workingPeriod,
														true
													);

													setFieldValue(
														"proposerHeight",
														values.height,
														true
													);

													setFieldValue(
														"proposerWeight",
														values.weight,
														true
													);

													setFieldValue(
														"proposerDesignation",
														values.designation,
														true
													);
												} else {
													setFieldValue(
														"proposerMaritalStatus",

														"",
														true
													);
													setFieldValue(
														"proposerDob",
														"",
														true
													);
													setFieldValue(
														"proposerGender",
														"",
														true
													);
													setFieldValue(
														"proposerOccupation",
														"",
														true
													);
													setFieldValue(
														"proposerQualification",
														"",
														true
													);
													setFieldValue(
														"proposerCompanyType",
														"",
														true
													);

													setFieldValue(
														"proposerMobile",
														"",
														true
													);

													setFieldValue(
														"proposerEmail",
														"",
														true
													);

													setFieldValue(
														"proposerFirstName",
														"",
														true
													);

													setFieldValue(
														"proposerMiddleName",
														"",
														true
													);

													setFieldValue(
														"proposerLastName",
														"",
														true
													);

													setFieldValue(
														"proposerSpouseName",
														"",
														true
													);

													setFieldValue(
														"proposerMotherMaidenName",
														"",
														true
													);

													setFieldValue(
														"proposerAnnualIncome",
														"",
														true
													);

													setFieldValue(
														"proposerWorkingPeriod",
														"",
														true
													);

													setFieldValue(
														"proposerHeight",
														"",
														true
													);

													setFieldValue(
														"proposerWeight",
														"",
														true
													);

													setFieldValue(
														"proposerDesignation",
														"",
														true
													);
												}
											}}
											checked={checkboxState}
											className={"ml-2 "}
										/>{" "}
										Same as Insurer Details
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"First Name"}
											name={"proposerFirstName"}
											component={FieldInput}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Middle Name"}
											name={"proposerMiddleName"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Last Name"}
											name={"proposerLastName"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Mobile Number"}
											name={"proposerMobile"}
											component={FieldInput}
											type={"number"}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Email"}
											name={"proposerEmail"}
											component={FieldInput}
											type={"email"}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<div className="grid">
											<div className="field col-12 md:col-6">
												<Field
													header={"Date Of Birth"}
													name={"proposerDob"}
													component={FeildCalender}
												/>
											</div>
											<div className="field col-12 md:col-6">
												<Field
													header={"Gender"}
													name={"proposerGender"}
													component={FeildDropdown}
													options={Config.GENDERS}
													optionLabel={"name"}
												/>
											</div>
										</div>
									</div>
									<div className="field col-12 md:col-4">
										<div className="grid">
											<div className="field col-12 md:col-4 flex flex-row align-items-center">
												<label
													className={
														"block text-sm mr-2 mb-0	"
													}
												>
													Smoke
												</label>
												<Checkbox
													name="isProposerSmoke"
													onChange={handleChange}
													checked={
														values.isProposerSmoke
													}
													value={
														values.isProposerSmoke
													}
												/>
											</div>
											<div className="field col-12 md:col-4 flex flex-row align-items-center">
												<label
													className={
														"block text-sm mr-2 mb-0	"
													}
												>
													Chew Tabacco
												</label>
												<Checkbox
													name="isProposerChewTobacco"
													onChange={handleChange}
													checked={
														values.isProposerChewTobacco
													}
													value={
														values.isProposerChewTobacco
													}
												/>
											</div>
										</div>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Marital Status"}
											name={"proposerMaritalStatus"}
											component={FeildDropdown}
											options={Config.MARITAL_STATUS}
										/>
									</div>

									{values.proposerMaritalStatus ===
										"Married" && (
										<div className="field col-12 md:col-4">
											<Field
												header={"Spouse’s Full Name"}
												name={"proposerSpouseName"}
												component={FieldInput}
											/>
										</div>
									)}

									<div className="field col-12 md:col-4">
										<Field
											header={"Mother’s Name"}
											name={"proposerMotherMaidenName"}
											component={FieldInput}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Height (Inch)"}
											name={"proposerHeight"}
											component={FieldInput}
											type={"number"}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Weight (Kg)"}
											name={"proposerWeight"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Landmark"}
											name={"proposerLandMark"}
											component={FieldTextArea}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Occupation Type"}
											name={"proposerOccupation"}
											component={FeildDropdown}
											options={Config.OCCUPATION}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Highest Qualification"}
											name={"proposerQualification"}
											component={FeildDropdown}
											options={Config.QUALIFICATION}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Company Type"}
											name={"proposerCompanyType"}
											component={FeildDropdown}
											options={Config.ORGANIZATION}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Designation"}
											name={"proposerDesignation"}
											component={FieldInput}
											// options={Config.DESIGNATION}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											name={"proposerAnnualIncome"}
											header={"Annual income"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											name={"proposerWorkingPeriod"}
											header={"Working period"}
											component={FieldInput}
											type={"number"}
										/>
									</div>

									<div className="col-12 flex align-items-end justify-content-end">
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									</div>
								</div>
							</Panel>

							<Panel
								header={
									<h3 className="m-0">Nominee Details</h3>
								}
								className={"my-2"}
								toggleable
							>
								<div className="form-grid grid">
									<div className="field col-12 md:col-4">
										<Field
											header={"Name"}
											name={"nomineeName"}
											component={FieldInput}
											// options={Config.OCCUPATION}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<div className="grid">
											<div className="field col-12 md:col-6">
												<Field
													header={"Date Of Birth"}
													name={"nomineeDob"}
													component={FeildCalender}
												/>
											</div>
											<div className="field col-12 md:col-6">
												<Field
													header={"Gender"}
													name={"nomineeGender"}
													component={FeildDropdown}
													options={Config.GENDERS}
													optionLabel={"name"}
												/>
											</div>
										</div>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={
												"Select Relationship with Insured"
											}
											name={
												"relationshipWithPrimaryInsured"
											}
											component={FeildDropdown}
											options={Config.RELATIONSHIP}
											optionLabel={"label"}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Appointee Name"}
											name={"appointeeName"}
											component={FieldInput}
											// options={Config.OCCUPATION}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={
												"Select Relationship with Nominee"
											}
											name={"relationshipWithNominee"}
											component={FeildDropdown}
											options={Config.RELATIONSHIP}
											optionLabel={"label"}
										/>
									</div>

									<div className="col-12 flex align-items-end justify-content-end">
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									</div>
								</div>
							</Panel>

							<Panel
								header={<h3 className="m-0">Family History</h3>}
								className={"my-4"}
								toggleable
							>
								<div className="form-grid grid">
									<div className="field col-12 md:col-4">
										<Field
											header={"Father"}
											name={"familyFather"}
											component={FieldTextArea}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Mother"}
											name={"familyMother"}
											component={FieldTextArea}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Spouse"}
											name={"familySpouse"}
											component={FieldTextArea}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Son"}
											name={"familySon"}
											component={FieldTextArea}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Daughter"}
											name={"familyDaughter"}
											component={FieldTextArea}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"age"}
											name={"familyAge"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Status"}
											name={"familyStatus"}
											component={FieldInput}
										/>
									</div>
									<div className="col-12 flex align-items-end justify-content-end">
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									</div>
								</div>
							</Panel>

							<Panel
								header={
									<h3 className="m-0">Payment Details</h3>
								}
								className={"my-2"}
								toggleable
							>
								<div className="form-grid grid">
									<div className="field col-12 md:col-4">
										<Field
											header={"Payment Frequency"}
											name={"paymentFrequency"}
											component={FeildDropdown}
											options={Config.PAYMENYTYPE}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Premium"}
											name={"premium"}
											component={FieldInput}
											type={"number"}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Premium Paying Term"}
											name={"premiumPayingTerm"}
											component={FieldInput}
											type={"number"}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Policy Term"}
											name={"policyTerm"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Disease"}
											name={"diseaseList"}
											filter
											value={values.diseaseList}
											component={FieldMultiselect}
											options={allDisease}
											optionLabel="disease"
											optionValue="id"
										/>
									</div>

									<div className="col-12 flex align-items-end justify-content-end">
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									</div>
								</div>
							</Panel>

							{/* <div className="col-12 flex align-items-end justify-content-end">
								<Button
									type="button"
									label="Save as draft"
									onClick={handleSubmit}
								/>
							</div> */}
						</Form>
					)}
				</Formik>
				<Divider />
				<Panel
					header={"Document Required"}
					className={"my-4"}
					toggleable
				>
					<ExtDocumentUpload
						id={id}
						subproductId={
							data?.program
								? data?.program?.productId?.id
								: data.productId
						}
					/>
				</Panel>
				<div className="col-12">
					<Checkbox
						onChange={(e) => setAcceptance(e.checked)}
						checked={acceptance}
						className={"ml-2 "}
					/>{" "}
					I do hereby declare that all the above information given by
					me are true to the best of my knowledge and belief
				</div>
				<div className="flex justify-content-end align-items-center w-full mt-4">
					<Button
						type="cancel"
						label="Cancel"
						className="p-button-danger mr-2"
						style={{
							borderRadius: "6px",
						}}
					/>
					<Button
						type="submit"
						label="Apply Loan"
						disabled={!acceptance}
						onClick={() => handleSubmit()}
					/>
				</div>
			</div>
		</>
	);
};

export default LifeInsuranceLoan;
