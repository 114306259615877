import React from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { Button } from "primereact/button";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
} from "../../../../components/FieldInput";
import { Divider } from "primereact/divider";
import * as Yup from "yup";
const EmployeeCommission = (props) => {
	const initialValue = {
		userType: "",
		effectiveStartDate: "",
		effectiveEndDate: "",
		commissionType: "",
		agentCommissionDetails: [
			{
				minSaleAmount: "",
				maxSaleAmount: "",
				amount: "",
			},
		],
	};
	const validationSchema = Yup.object().shape({
		userType: Yup.string().required("This field is required"),
		commissionType: Yup.string().required("This field is required"),
		effectiveStartDate: Yup.date().required("This field is required"),
		effectiveEndDate: Yup.date()
			.when("effectiveStartDate", (effectiveStartDate, value) => {
				return value.test({
					test: (effectiveEndDate) =>
						!!new Date(effectiveStartDate) &&
						new Date(effectiveEndDate) >
							new Date(effectiveStartDate),
					message: "Invalid date",
				});
			})
			.required("This field is required"),
		agentCommissionDetails: Yup.array().of(
			Yup.object().shape({
				minSaleAmount: Yup.number()
					.typeError("Value must be a number")
					.min(0, "Invalid Amount")
					.required("This field is required"),
				maxSaleAmount: Yup.number()
					.typeError("Value must be a number")
					.min(0, "Invalid Amount")
					.when("minSaleAmount", (minSaleAmount, value) => {
						return value.test({
							test: (maxSaleAmount) =>
								!!minSaleAmount &&
								maxSaleAmount > minSaleAmount,
							message: "Max sell amount > Min sell amount",
						});
					})

					.required("This field is required"),
				amount: Yup.number().required("This field is required"),
			})
		),
	});
	return (
		<>
			<Formik
				initialValues={{
					...initialValue,
					...(props.initialValue !== undefined && {
						...props.initialValue,
						effectiveStartDate: new Date(
							props.initialValue.effectiveStartDate
						),
						effectiveEndDate: new Date(
							props.initialValue.effectiveEndDate
						),
					}),
				}}
				onSubmit={props.handleFormSubmit}
				validationSchema={validationSchema}
				enableReinitialize
			>
				{({ values, handleSubmit }) => (
					<Form className="modal-form-grid" onSubmit={handleSubmit}>
						<div className="form-grid grid ">
							<div className="field col-12 md:col-3">
								<Field
									name={`userType`}
									component={FeildDropdown}
									header={"User Type"}
									options={[
										{
											label: "Agent",
											value: "AGENT",
										},
										{
											label: "Employee",
											value: "EMPLOYEE",
										},
									]}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									name={`effectiveStartDate`}
									component={FeildCalender}
									header={"Effective Start Date"}
									minDate={new Date()}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									name={`effectiveEndDate`}
									component={FeildCalender}
									header={"Effective End Date"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									name={`commissionType`}
									component={FeildDropdown}
									header={"Commission Type"}
									options={[
										{
											label: "Fixed",
											value: "fixed",
										},
										{
											label: "Percentage",
											value: "percentage",
										},
									]}
									star={"*"}
								/>
							</div>
						</div>
						<Divider align="left" type="solid">
							<b>Commission Slabs</b>
						</Divider>
						<FieldArray
							name="agentCommissionDetails"
							render={(arrayHelper) => (
								<>
									{values.agentCommissionDetails &&
										values.agentCommissionDetails.length >
											0 &&
										values.agentCommissionDetails.map(
											(item, index) => (
												<div
													className="form-grid grid"
													key={
														index.toString() +
														"mapValueCommision"
													}
												>
													<div className="field col-12 md:col-4">
														<Field
															name={`agentCommissionDetails.${index}.minSaleAmount`}
															component={
																FieldInput
															}
															header={
																"Min Sell Amount"
															}
															star={"*"}
															type={"number"}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															name={`agentCommissionDetails.${index}.maxSaleAmount`}
															component={
																FieldInput
															}
															header={
																"Max Sell Amount"
															}
															star={"*"}
															type={"number"}
														/>
													</div>

													<div className="field col-12 md:col-3">
														<Field
															header={`Commission:(${
																values.commissionType ===
																"FIXED"
																	? "Fixed Amount"
																	: "Percentage"
															}) `}
															name={`agentCommissionDetails.${index}.amount`}
															component={
																FieldInput
															}
															type={"number"}
															star={"*"}
														/>
													</div>
													{index !== 0 && (
														<div className="col-1">
															<Button
																type="button"
																onClick={() =>
																	arrayHelper.remove(
																		index
																	)
																}
																icon="pi pi-trash"
																className="p-button-danger mt-4 p-button-outlined"
																aria-label="Cancel"
															/>
														</div>
													)}
												</div>
											)
										)}
									<div className="add-more-btn flex ">
										<Button
											type={"button"}
											onClick={() =>
												arrayHelper.push({
													minSaleAmount: "",
													maxSaleAmount: "",
													amount: "",
												})
											}
											className="p-button-text py-0 flex-1 align-items-center justify-content-center"
										>
											<i className="pi pi-plus"></i>
											<span className="ml-2">
												Commission Slabs
											</span>
										</Button>
									</div>
								</>
							)}
						/>
						<div className="flex justify-content-end align-items-center w-full mt-4">
							<Button
								type="submit"
								label="Save"
								icon="pi pi-save"
								style={{ borderRadius: "6px" }}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default EmployeeCommission;
