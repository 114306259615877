import Config from "../shared/config";
import { produce } from "immer";
export const searchInitialState = {
	searchValue: "",
	pageNo: 1,
	pageSize: 50,
	sortByColumn: null,
	sortType: null,
};
// export const searchReducer = (state, action) => {
//     switch (action.type) {
// 		case Config.SEARCH_CONFIG.searchRequest:
// 			return {
// 				...state,
// 				searchValue: action.payload,
// 			};
// 		case Config.SEARCH_CONFIG.increment:
// 			return {
// 				...state,
// 				pageNo: state.pageNo + 1,
// 			};
// 		case Config.SEARCH_CONFIG.decrement:
// 			return {
// 				...state,
// 				pageNo: state.pageNo - 1,
// 			};
// 		case Config.SEARCH_CONFIG.pageSize:
// 			return {
// 				...state,
// 				pageSize: action.payload,
// 			};
// 		case Config.SEARCH_CONFIG.sortByColumn:
// 			return {
// 				...state,
// 				sortByColumn: action.payload,
// 			};

//         default:
//             return state;
// 	}
// };

export const searchReducer = produce((draft = searchInitialState, action) => {
	switch (action.type) {
		case Config.SEARCH_CONFIG.searchRequest:
			draft.searchValue = action.payload;
			break;
		case Config.SEARCH_CONFIG.increment:
			draft.pageNo = draft.pageNo + 1;
			break;
		case Config.SEARCH_CONFIG.decrement:
			draft.pageNo = draft.pageNo - 1;
			break;
		case Config.SEARCH_CONFIG.pageSize:
			draft.pageSize = action.payload;
			break;
		case Config.SEARCH_CONFIG.sortByColumn:
			draft.sortByColumn = action.payload;
			break;
		case Config.SEARCH_CONFIG.sortType:
			draft.sortType = action.payload;
			break;
		case Config.SEARCH_CONFIG.pageNo:
			draft.pageNo = action.payload;
			break;
		case Config.SEARCH_CONFIG.reset:
			draft.searchValue = "";
			draft.pageNo = 1;
			draft.pageSize = 50;
			draft.sortByColumn = null;
			draft.sortType = null;
			break;
		default:
			return draft;
	}
});
