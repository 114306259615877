import { Fragment, useEffect, useReducer, useRef, useState } from "react";
import { produce } from "immer";
import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import { Panel } from "primereact/panel";
import { InputSwitch } from "primereact/inputswitch";
import { Divider } from "primereact/divider";
import { LeadService } from "../../../../services/LeadService";
import Loader from "../../../../components/Loader";
import {
	useGetAllDIstrictQuery,
	useGetProductDetailsQuery,
	// useGetStateListQuery,
} from "../../../../services/query/queryApi";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
	FieldRadio,
	FieldTextArea,
} from "../../../../components/FieldInput";
import { ProgressSpinner } from "primereact/progressspinner";
import Config from "../../../../shared/config";
// import moment from "moment";

import selectState from "../../../../components/stateList";
import { Checkbox } from "primereact/checkbox";
// import { LoanApplicationService } from "../../../../services/LoanApplication";
import {
	convertNullToString,
	convertObjNull,
} from "../../../../utils/UtilsFunction";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { classNames } from "primereact/utils";
import { ConnectedFocusError } from "focus-formik-error";
import { Skeleton } from "primereact/skeleton";
import Header from "../../../partners/institutions/header";
import DocumentUpload from "./DocumentUpload";

const SubmitNewLeadDialog = (props) => {
	const initialState = {
		stage: [
			{
				sequence: 1,
				title: "Lead",
				status: "active",
			},
			{
				sequence: 2,
				title: "Document Upload",
				status: "inactive",
			},
		],
		active: 1,
		leadId: null,
	};
	const stageReducer = produce((draft, action) => {
		switch (action.type) {
			case "stage":
				const newData = draft.stage.map((item) =>
					item.sequence === action.payload.sequence
						? { ...item, status: action.payload.status }
						: item
				);
				draft.stage = newData;
				break;
			case "active":
				draft.active = action.active;
				break;
			case "id":
				draft.leadId = action.id;
				break;
			default:
				return draft;
		}
	});
	const [stages, setStages] = useReducer(stageReducer, initialState);
	const navigate = useNavigate();
	const toastTL = useRef(null);
	// const loanApplicationService = new LoanApplicationService();
	// const { data: stateData = [] } = useGetStateListQuery();
	const {
		data: allDistrictData = [
			{ districtList: [{ id: null, name: "" }], id: null, name: "" },
		],
		isLoading,
	} = useGetAllDIstrictQuery();
	const createLead = new LeadService();
	const [loader, setLoader] = useState(false);
	const { isSuccess, data = {} } = useGetProductDetailsQuery({
		searchRequest: [
			{
				operation: "EQUAL",
				column: "lineOfBusiness",
				value: props?.location?.state?.lob,
			},
		],
		pageNo: 1,
		pageSize: 330,
		sorts: [],
	});

	const validationSchema = Yup.object().shape({
		mobile: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.required("This field is required"),
		whatsappNumber: Yup.string().matches(
			/^[1-9]\d{9}$/gi,
			"Mobile number should be 10 digit number"
		),
		pan: Yup.string().matches(
			/[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
			"Pan Number is invalid"
		),
		personalMail: Yup.string()
			.trim()

			.email("Invalid email"),

		pincode: Yup.string().matches(/^\d{6}$/, "Please enter valid pincode"),
		firstName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid first name"
			)
			.required("This field is required"),
		lastName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid last name"
			)
			.required("This field is required"),
		middleName: Yup.string().matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid middle name"
		),
		spouseName: Yup.string().matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid spouse name"
		),
		fatherName: Yup.string().matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid father name"
		),
		motherMaidenName: Yup.string().matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid mother maiden name"
		),
		city: Yup.string().matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid city"
		),
		perCity: Yup.string().matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid city"
		),
		perPincode: Yup.string().matches(
			/^\d{6}$/,
			"Please enter valid pincode"
		),
		officeTelephoneNumber: Yup.string().matches(
			/^[1-9]\d{9}$/gi,
			"Mobile number should be 10 digit number"
		),
		officeCity: Yup.string().matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid city"
		),
		officePincode: Yup.string().matches(
			/^\d{6}$/,
			"Please enter valid pincode"
		),
		state: Yup.number().required("This field is required"),
		districtId: Yup.number().required("This field is required"),
		totalYearExperienceInMonths: Yup.number()
			.positive("Invalid value")
			.integer("Invalid value"),
		totalYearExperienceInMonthsCurrentCompany: Yup.number()
			.positive("Invalid value")
			.integer("Invalid value"),

		// gender: Yup.string().required("This field is required"),
	});
	const reducer = produce((state, action) => {
		const checkId = state.productList.find(
			(item) => item === action.payload
		);
		const newArr = state.productList.filter(
			(item) => item !== action.payload
		);
		if (checkId === undefined) {
			state.productList = [...state.productList, action.payload];
		} else {
			state.productList = newArr;
		}
	});
	const [state, dispatch] = useReducer(reducer, { productList: [] });
	// const [districtListState, setDistrict] = useReducer(
	// 	(state, action) => {
	// 		return { ...state, districtList: action.payload };
	// 	},
	// 	{ districtList: [] }
	// );
	const [leaddetailsstate, leadDetailsDIspatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const getLeadEditData = () => {
		if (
			(props.type === "edit" || props.type === "preview") &&
			props.leadId.id
		) {
			leadDetailsDIspatch({
				type: Config.FETCH_CONFIG.start,
			});
			createLead
				.getLeadDetails(props.leadId.id)
				.then((res) => {
					const data = convertNullToString(res);
					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.success,
						payload: data,
					});
				})
				.catch((e) => {
					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.error,
						payload: e,
					});
				})
				.finally(() =>
					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.stop,
					})
				);
		}
	};
	const initialValues =
		props.type === "create"
			? {
					leadType: props?.location?.state?.productType,
					firstName: "",
					middleName: "",
					lastName: "",
					mobile: "",
					pan: "",
					salutation: "Mr",
					whatsappNumber: "",
					whatsappNumberSame: false,
					permanentAddressSame: false,
					personalMail: "",
					dob: null,
					age: "",
					gender: "",
					category: "",
					religion: "",
					maritalStatus: "",
					spouseName: "",
					fatherName: "",
					motherMaidenName: "",
					residentialStatus: "",
					nationality: "",
					residenceType: "",
					residingSince: null,
					buildingName: "",
					addressLine1: "",
					addressLine2: "",
					addressLine3: "",
					landmark: "",
					city: "",
					state: "",
					pincode: "",
					perResidenceType: "",
					perResidingSince: null,
					perBuildingName: "",
					perAddressLine1: "",
					perAddressLine2: "",
					perAddressLine3: "",
					perLandmark: "",
					perCity: "",
					perState: "",
					perPincode: "",
					preferredMailingAddress: "",
					highestQualification: "",
					occupationType: "",
					companyType: "",
					companyName: "",
					designation: "",
					officialMail: "",
					officeTelephoneNumber: "",
					extension: "",
					industryType: "",
					totalYearExperienceInMonths: "",
					totalYearExperienceInMonthsCurrentCompany: "",
					totalYearExperience: "",
					netMonthlyIncome: "",
					annualTurnover: "",
					officeAddressLine1: "",
					officeLandmark: "",
					officeCity: "",
					officeState: "",
					officePincode: "",
					loanAmount: "",
					tenure: "",
					emi: "",
					districtId: "",
			  }
			: props.type === "edit" || props.type === "preview"
			? {
					leadType: props?.location?.state?.productType,
					...leaddetailsstate.data,

					dob:
						leaddetailsstate.data.dob &&
						leaddetailsstate.data.dob !== ""
							? new Date(leaddetailsstate.data.dob)
							: null,
					residingSince:
						leaddetailsstate.data.residingSince &&
						leaddetailsstate.data.residingSince !== ""
							? new Date(leaddetailsstate.data.residingSince)
							: null,
					perResidingSince:
						leaddetailsstate.data.perResidingSince &&
						leaddetailsstate.data.perResidingSince !== ""
							? new Date(leaddetailsstate.data.perResidingSince)
							: null,
			  }
			: {};

	// const handleDistrict = (e, setFieldValue) => {
	// 	loanApplicationService
	// 		.getDistrictByStateID(e)
	// 		.then((res) => {
	// 			setDistrict({
	// 				payload: res.map((item) => ({ ...item, value: item.id })),
	// 			});
	// 		})
	// 		.catch(() => {});
	// 	setFieldValue("state", e, true);
	// };
	const getDistrictData = (e) => {
		const stateData = allDistrictData.find((item) => item.id === e);
		if (stateData !== undefined) {
			const districtData = stateData.districtList.map((e) => ({
				...e,
				value: e.id,
			}));
			return districtData;
		} else {
			return [];
		}
	};

	const handleFormSubmit = (data, action) => {
		const { dob, residingSince, perResidingSince, ...rest } = data;
		let tempDate =
			residingSince && residingSince !== ""
				? moment(residingSince).format("YYYY-MM-DD")
				: "";
		let tempDate2 =
			perResidingSince && perResidingSince !== ""
				? moment(perResidingSince).format("YYYY-MM-DD")
				: "";

		const newData = {
			...rest,
			products: state.productList,
			dob: dob && dob !== "" ? moment(dob).format("YYYY-MM-DD") : "",
			residingSince: tempDate,
			perResidingSince: tempDate2,
			age:
				dob && dob !== ""
					? new Date().getFullYear() - new Date(dob).getFullYear()
					: "",
		};
		const body = convertObjNull(newData);

		if (props.type === "create" && state.productList.length === 0) {
			return toastTL.current.show({
				severity: "error",
				summary: "Validation Error",
				detail: "No Product selected",
				style: { color: "#000000" },
				life: 3000,
			});
		}
		setLoader(true);
		if (props.type === "create") {
			createLead
				.createLead(body)
				.then((e) => {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});
					action.resetForm();
					setStages({
						type: "id",
						id: e.id,
					});
					setStages({
						type: "stage",
						payload: {
							sequence: 2,
							status: "active",
						},
					});
					setStages({
						type: "stage",
						payload: {
							sequence: 1,
							status: "success",
						},
					});
					setStages({ type: "active", active: 2 });
				})
				.catch((e) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => {
					setLoader(false);
				});
		} else {
			createLead
				.updateLead(body)
				.then((e) => {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});

					setStages({
						type: "stage",
						payload: {
							sequence: 2,
							status: "active",
						},
					});
					setStages({
						type: "stage",
						payload: {
							sequence: 1,
							status: "success",
						},
					});
					setStages({ type: "active", active: 2 });
				})
				.catch((e) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => {
					setLoader(false);
					getLeadEditData();
				});
		}
	};
	const handleNext = () => {
		setStages({
			type: "stage",
			payload: {
				sequence: 2,
				status: "active",
			},
		});
		setStages({
			type: "stage",
			payload: {
				sequence: 1,
				status: "success",
			},
		});
		setStages({ type: "active", active: 2 });
	};
	const handleProductChange = (subId) => {
		dispatch({ payload: subId });
	};
	useEffect(() => {
		let ignore = false;
		if (!ignore) {
			getLeadEditData();
		}
		return () => {
			ignore = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="col-12">
			{loader && <Loader />}
			{leaddetailsstate.isLoading && <Loader />}
			<Toast ref={toastTL} position="top-left" />

			<div className="flex align-items-center justify-content-end pb-3">
				<Button
					label="Back"
					icon="pi pi-arrow-left"
					onClick={() => navigate(-1)}
				/>
			</div>
			<Header
				stages={stages.stage}
				title="Lead"
				stageName={props?.location?.state?.productName}
			/>

			{(function (elm) {
				switch (elm) {
					case 1:
						return (
							<div className="card  pt-5 pb-2 px-2 ">
								<Formik
									enableReinitialize
									initialValues={initialValues}
									onSubmit={handleFormSubmit}
									validationSchema={validationSchema}
								>
									{({
										values,
										handleSubmit,
										handleChange,
										resetForm,
									}) => (
										<Form
											className={
												(classNames({
													"form-disable":
														props.type ===
														"preview",
												}),
												"grid")
											}
											onSubmit={handleSubmit}
										>
											<ConnectedFocusError />
											<Panel
												header="Lead Details"
												className="w-full mb-3"
												toggleable
											>
												<div className="grid form-grid">
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Mobile Number"
															}
															name={"mobile"}
															component={
																FieldInput
															}
															type={"number"}
															star={"*"}
															disabled={
																props.type ===
																	"edit" ||
																props.type ===
																	"preview"
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<label className="text-sm">
															Whatsapp No (Same as
															Mobile no?)
														</label>
														<div className="p-inputgroup">
															<span className="p-inputgroup-addon p-1">
																<Checkbox
																	name="whatsappNumberSame"
																	onChange={
																		handleChange
																	}
																	checked={
																		values.whatsappNumberSame
																	}
																	value={
																		values.whatsappNumberSame
																	}
																/>
															</span>
															<Field
																name={
																	"whatsappNumber"
																}
																type={"number"}
																value={
																	values.whatsappNumberSame
																		? (values.whatsappNumber =
																				values.mobile)
																		: values.whatsappNumber
																}
																component={
																	FieldInput
																}
																disabled={
																	values.whatsappNumberSame
																		? true
																		: false
																}
															/>
														</div>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"PAN"}
															name={"pan"}
															component={
																FieldInput
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Loan Amount"
															}
															name={"loanAmount"}
															component={
																FieldInput
															}
															type={"number"}
														/>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={"Tenure"}
															name={"tenure"}
															component={
																FieldInput
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"EMI"}
															name={"emi"}
															type={"number"}
															component={
																FieldInput
															}
														/>
													</div>
												</div>
											</Panel>
											<Panel
												header="Personal Details"
												className="w-full mb-3"
												toggleable
											>
												<div className="grid form-grid">
													<div className="field col-12 md:col-4">
														<Field
															header={"Email"}
															name={
																"personalMail"
															}
															component={
																FieldInput
															}
															type={"email"}
															star={"*"}
														/>
													</div>

													<div className="field col-12 md:col-4">
														<div className="grid">
															<div className="field col-12 md:col-3">
																<Field
																	header={
																		"Salutaion"
																	}
																	name={
																		"salutation"
																	}
																	component={
																		FeildDropdown
																	}
																	value={
																		values.salutation
																	}
																	options={
																		Config.SALUTATION
																	}
																/>
															</div>
															<div className="field col-12 md:col-9">
																<Field
																	header={
																		"First Name"
																	}
																	name={
																		"firstName"
																	}
																	component={
																		FieldInput
																	}
																	star={"*"}
																/>
															</div>
														</div>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Middle Name"
															}
															name={"middleName"}
															component={
																FieldInput
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"Last Name"}
															name={"lastName"}
															component={
																FieldInput
															}
															star={"*"}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<div className="grid">
															<div className="field col-12 md:col-6">
																<Field
																	header={
																		"Date Of Birth"
																	}
																	name={"dob"}
																	component={
																		FeildCalender
																	}
																	maxDate={
																		new Date(
																			moment()
																				.subtract(
																					18,
																					"year"
																				)
																				.toDate()
																		)
																	}
																	viewDate={
																		new Date(
																			moment()
																				.subtract(
																					18,
																					"year"
																				)
																				.toDate()
																		)
																	}
																	// star={"*"}
																/>
															</div>
															<div className="field col-12 md:col-2 pl-0">
																<Field
																	header={
																		"Age"
																	}
																	name={"age"}
																	component={
																		FieldInput
																	}
																	value={
																		values.dob
																			? new Date()
																					.getFullYear()
																					.toString() -
																			  new Date(
																					values.dob
																			  ).getFullYear()
																			: ""
																	}
																	disabled
																	type={
																		"number"
																	}
																/>
															</div>

															<div className="field col-12 md:col-4 pl-0">
																<Field
																	header={
																		"Gender"
																	}
																	name={
																		"gender"
																	}
																	component={
																		FeildDropdown
																	}
																	options={
																		Config.GENDERS
																	}
																	optionLabel={
																		"name"
																	}
																/>
															</div>
														</div>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={
																"Marital Status"
															}
															name={
																"maritalStatus"
															}
															component={
																FeildDropdown
															}
															options={
																Config.MARITAL_STATUS
															}
														/>
													</div>
													{values.maritalStatus ===
														"Married" && (
														<div className="field col-12 md:col-4">
															<Field
																header={
																	"Spouse’s Full Name"
																}
																name={
																	"spouseName"
																}
																component={
																	FieldInput
																}
															/>
														</div>
													)}

													<div className="field col-12 md:col-4">
														<Field
															header={
																"Father’s Name"
															}
															name={"fatherName"}
															component={
																FieldInput
															}
														/>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={
																"Mother’s Maiden Name"
															}
															name={
																"motherMaidenName"
															}
															component={
																FieldInput
															}
														/>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={
																"Residential Status"
															}
															name={
																"residentialStatus"
															}
															component={
																FeildDropdown
															}
															options={
																Config.RESIDENTIALSTATUS
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<div className="grid">
															<div className="field col-12 md:col-6">
																<Field
																	header={
																		"Religion"
																	}
																	name={
																		"religion"
																	}
																	component={
																		FeildDropdown
																	}
																	options={
																		Config.RELIGION
																	}
																	filter
																/>
															</div>
															<div className="field col-12 md:col-6">
																<Field
																	header={
																		"Caste"
																	}
																	name={
																		"category"
																	}
																	component={
																		FeildDropdown
																	}
																	options={
																		Config.CATEGORY
																	}
																/>
															</div>
														</div>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={
																"Nationality"
															}
															name={"nationality"}
															component={
																FeildDropdown
															}
															options={
																Config.NATIONALITY
															}
														/>
													</div>
												</div>
											</Panel>
											<Panel
												header="Address"
												className="w-full mb-3"
												toggleable
											>
												<div className="form-grid grid">
													<h4 className="col-12 ">
														Present Address
													</h4>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Residence Type"
															}
															name={
																"residenceType"
															}
															component={
																FeildDropdown
															}
															options={
																Config.RESIDENTTYPE
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Residing Since"
															}
															name={
																"residingSince"
															}
															component={
																FeildCalender
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Building Name/Flat No."
															}
															name={
																"buildingName"
															}
															component={
																FieldInput
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Address Line 1"
															}
															name={
																"addressLine1"
															}
															component={
																FieldTextArea
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Address Line 2"
															}
															name={
																"addressLine2"
															}
															component={
																FieldTextArea
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Address Line 3"
															}
															name={
																"addressLine3"
															}
															component={
																FieldTextArea
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"Landmark"}
															name={"landmark"}
															component={
																FieldInput
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"City"}
															name={"city"}
															component={
																FieldInput
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														{isLoading ? (
															<Skeleton className="w-full flex-1 h-3rem mt-4 " />
														) : (
															<Field
																header={"State"}
																name={"state"}
																filter
																// onChange={(e) => {
																// 	handleDistrict(e.value, setFieldValue);
																// }}
																component={
																	FeildDropdown
																}
																// options={selectState(stateData)}
																options={selectState(
																	allDistrictData
																)}
																optionLabel={
																	"name"
																}
																star={"*"}
																disabled={
																	props.type ===
																		"edit" ||
																	props.type ===
																		"preview"
																}
															/>
														)}
													</div>
													<div className="field col-12 md:col-4">
														{isLoading ? (
															<Skeleton className="w-full flex-1 h-3rem mt-4 " />
														) : (
															<Field
																header={
																	"District"
																}
																name={
																	"districtId"
																}
																filter
																component={
																	FeildDropdown
																}
																// options={districtListState.districtList}
																options={getDistrictData(
																	values.state
																)}
																optionLabel={
																	"name"
																}
																star={"*"}
																disabled={
																	props.type ===
																		"edit" ||
																	props.type ===
																		"preview"
																}
															/>
														)}
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"Pincode"}
															name={"pincode"}
															component={
																FieldInput
															}
															type={"number"}
														/>
													</div>
													<h4 className="col-12 ">
														Permanent Address
													</h4>
													<div className="col-12">
														<Checkbox
															name="permanentAddressSame"
															onChange={
																handleChange
															}
															checked={
																values.permanentAddressSame
															}
															value={
																values.permanentAddressSame
															}
															className={"ml-2 "}
														/>{" "}
														Same as Personal Address
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={
																"Residence Type"
															}
															name={
																"perResidenceType"
															}
															component={
																FeildDropdown
															}
															options={
																Config.RESIDENTTYPE
															}
															value={
																values.permanentAddressSame
																	? (values.perResidenceType =
																			values.residenceType)
																	: values.perResidenceType
															}
															disabled={
																values.permanentAddressSame
																	? true
																	: false
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Residing Since"
															}
															name={
																"perResidingSince"
															}
															component={
																FeildCalender
															}
															value={
																values.permanentAddressSame
																	? (values.perResidingSince =
																			values.residingSince)
																	: values.perResidingSince
															}
															disabled={
																values.permanentAddressSame
																	? true
																	: false
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Building Name"
															}
															name={
																"perBuildingName"
															}
															component={
																FieldInput
															}
															value={
																values.permanentAddressSame
																	? (values.perBuildingName =
																			values.buildingName)
																	: values.perBuildingName
															}
															disabled={
																values.permanentAddressSame
																	? true
																	: false
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Address Line 1"
															}
															name={
																"perAddressLine1"
															}
															value={
																values.permanentAddressSame
																	? (values.perAddressLine1 =
																			values.addressLine1)
																	: values.perAddressLine1
															}
															component={
																FieldTextArea
															}
															disabled={
																values.permanentAddressSame
																	? true
																	: false
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Address Line 2"
															}
															name={
																"perAddressLine2"
															}
															value={
																values.permanentAddressSame
																	? (values.perAddressLine2 =
																			values.addressLine2)
																	: values.perAddressLine2
															}
															component={
																FieldTextArea
															}
															disabled={
																values.permanentAddressSame
																	? true
																	: false
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Address Line 3"
															}
															name={
																"perAddressLine3"
															}
															value={
																values.permanentAddressSame
																	? (values.perAddressLine3 =
																			values.addressLine3)
																	: values.perAddressLine3
															}
															component={
																FieldTextArea
															}
															disabled={
																values.permanentAddressSame
																	? true
																	: false
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"Landmark"}
															name={"perLandmark"}
															value={
																values.permanentAddressSame
																	? (values.perLandmark =
																			values.landmark)
																	: values.perLandmark
															}
															component={
																FieldInput
															}
															disabled={
																values.permanentAddressSame
																	? true
																	: false
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"City"}
															name={"perCity"}
															value={
																values.permanentAddressSame
																	? (values.perCity =
																			values.city)
																	: values.perCity
															}
															component={
																FieldInput
															}
															disabled={
																values.permanentAddressSame
																	? true
																	: false
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														{isLoading ? (
															<Skeleton className="w-full flex-1 h-3rem mt-4 " />
														) : (
															<Field
																header={"State"}
																name={
																	"perState"
																}
																value={
																	values.permanentAddressSame
																		? (values.perState =
																				values.state)
																		: values.perState
																}
																component={
																	FeildDropdown
																}
																// options={selectState(stateData)}
																options={selectState(
																	allDistrictData
																)}
																filter
																optionLabel={
																	"name"
																}
																disabled={
																	values.permanentAddressSame
																		? true
																		: false
																}
															/>
														)}
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"Pincode"}
															name={"perPincode"}
															value={
																values.permanentAddressSame
																	? (values.perPincode =
																			values.pincode)
																	: values.perPincode
															}
															component={
																FieldInput
															}
															disabled={
																values.permanentAddressSame
																	? true
																	: false
															}
														/>
													</div>
													<h5 className="col-12">
														Preferred Mailing
														Address
													</h5>
													<div className="flex flex-wrap gap-3">
														<Field
															name={
																"preferredMailingAddress"
															}
															component={
																FieldRadio
															}
															radiolist={
																Config.PREFRREDMAILING_ADDRESS
															}
														/>
													</div>
												</div>
											</Panel>
											<Panel
												className="w-full"
												header="Occupation Details"
											>
												<div className="form-grid grid">
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Highest Qualification"
															}
															name={
																"highestQualification"
															}
															component={
																FeildDropdown
															}
															options={
																Config.QUALIFICATION
															}
														/>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={
																"Occupation Type"
															}
															name={
																"occupationType"
															}
															component={
																FeildDropdown
															}
															options={
																Config.OCCUPATION
															}
														/>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={
																"Company Type"
															}
															name={"companyType"}
															component={
																FeildDropdown
															}
															options={
																Config.ORGANIZATION
															}
														/>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={
																"Company Name"
															}
															name={"companyName"}
															component={
																FieldInput
															}
														/>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={
																"Designation"
															}
															name={"designation"}
															component={
																FieldInput
															}
															// options={Config.DESIGNATION}
														/>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={
																"Official Email Id"
															}
															name={
																"officialMail"
															}
															component={
																FieldInput
															}
															type={"email"}
														/>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={
																"Office Phone Number"
															}
															name={
																"officeTelephoneNumber"
															}
															component={
																FieldInput
															}
															type={"number"}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"Extension"}
															name={"extension"}
															component={
																FieldInput
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Industry Type"
															}
															name={
																"industryType"
															}
															component={
																FeildDropdown
															}
															options={
																Config.INDUSTRY
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Experience In Months In Current Company"
															}
															name={
																"totalYearExperienceInMonthsCurrentCompany"
															}
															component={
																FieldInput
															}
															type={"number"}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Total Year Experience In Months"
															}
															name={
																"totalYearExperienceInMonths"
															}
															component={
																FieldInput
															}
															type={"number"}
														/>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={
																"Total Work Experience (in Yrs)"
															}
															name={
																"totalYearExperience"
															}
															component={
																FieldInput
															}
															value={(
																values.totalYearExperienceInMonths /
																12
															).toFixed(1)}
															type={"number"}
															disabled
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Net Monthly Income/Salary"
															}
															name={
																"netMonthlyIncome"
															}
															component={
																FieldInput
															}
															type={"number"}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Annual Turnover"
															}
															name={
																"annualTurnover"
															}
															component={
																FieldInput
															}
															type={"number"}
														/>
													</div>

													<div className="col-12">
														<h4 className="m-0">
															Work / Office
															address:
														</h4>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={" Address"}
															name={
																"officeAddressLine1"
															}
															component={
																FieldTextArea
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"Landmark"}
															name={
																"officeLandmark"
															}
															component={
																FieldInput
															}
														/>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={"City"}
															name={"officeCity"}
															component={
																FieldInput
															}
														/>
													</div>

													<div className="field col-12 md:col-4">
														{isLoading ? (
															<Skeleton className="w-full flex-1 h-3rem mt-4 " />
														) : (
															<Field
																header={"State"}
																name={
																	"officeState"
																}
																component={
																	FeildDropdown
																}
																// options={selectState(stateData)}
																options={selectState(
																	allDistrictData
																)}
																optionLabel={
																	"name"
																}
																filter
															/>
														)}
													</div>
													{values.officeState && (
														<div className="field col-12 md:col-4">
															{isLoading ? (
																<Skeleton className="w-full flex-1 h-3rem mt-4 " />
															) : (
																<Field
																	header={
																		"District"
																	}
																	name={
																		"officeDistrict"
																	}
																	filter
																	component={
																		FeildDropdown
																	}
																	// options={
																	// 	districtListState.districtList
																	// }
																	options={getDistrictData(
																		values.officeState
																	)}
																	optionLabel={
																		"name"
																	}
																/>
															)}
														</div>
													)}

													<div className="field col-12 md:col-4">
														<Field
															header={"Pincode"}
															name={
																"officePincode"
															}
															component={
																FieldInput
															}
															type={"number"}
														/>
													</div>
												</div>
											</Panel>
											<Divider />
											{props.type === "create" && (
												<>
													{isSuccess ? (
														<>
															{data.data.map(
																(
																	item,
																	index
																) => (
																	<Fragment
																		key={
																			index
																		}
																	>
																		{item.isActive && (
																			<div className="col-12 lg:col-6">
																				<Panel
																					header={
																						item.title
																					}
																					toggleable
																					collapsed
																				>
																					{item.subProducts.map(
																						(
																							element,
																							j
																						) => (
																							<Fragment
																								key={
																									j.toString() +
																									"keyFor"
																								}
																							>
																								{element.isActive && (
																									<div className=" flex justify-content-between align-items-center mt-0 mb-0">
																										<h5 className="m-0">
																											{
																												element.title
																											}
																										</h5>
																										<InputSwitch
																											name="products[]"
																											checked={
																												state.productList &&
																												state.productList.includes(
																													element.id
																												)
																											}
																											onChange={() =>
																												handleProductChange(
																													element.id
																												)
																											}
																											className="switch_toggle"
																										/>
																									</div>
																								)}
																								{element.isActive &&
																									item
																										.subProducts
																										.length -
																										1 !==
																										j && (
																										<Divider />
																									)}
																							</Fragment>
																						)
																					)}
																				</Panel>
																			</div>
																		)}
																	</Fragment>
																)
															)}
														</>
													) : (
														<div className="col-12 flex justify-content-center align-items-center">
															<ProgressSpinner
																style={{
																	width: "50px",
																	height: "50px",
																}}
																strokeWidth="4"
																animationDuration=".5s"
															/>
														</div>
													)}
												</>
											)}
											<div className="flex justify-content-end align-items-center pt-3 w-full">
												{props.type !== "preview" && (
													<>
														<Button
															type="button"
															label="Reset"
															onClick={() => {
																resetForm();
															}}
															className="danger-btn mr-2"
															icon="pi pi-times"
														/>
														<Button
															type="submit"
															label="Save & Next"
															icon="pi pi-save"
														/>
													</>
												)}
												{props.type !== "create" && (
													<Button
														type="button"
														label="Next"
														className="ml-2"
														onClick={handleNext}
													/>
												)}
											</div>
										</Form>
									)}
								</Formik>
							</div>
						);
					case 2:
						return (
							<DocumentUpload
								leadId={
									props.type === "create"
										? stages.leadId
										: leaddetailsstate.data?.id
								}
								setStages={setStages}
								stages={stages}
								type={props.type}
							/>
						);

					default:
						break;
				}
			})(stages.active)}
		</div>
	);
};

export default SubmitNewLeadDialog;
