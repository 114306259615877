import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";
import { getIn } from "formik";

import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import ReactQuill from "react-quill";
import { Chips } from "primereact/chips";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";
export const FieldInput = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors, handleReset, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.

	...props
}) => (
	<>
		{props.header && (
			<label
				className={
					// classNames(
					// {
					// 	"text-red-600":
					// 		Boolean(getIn(errors,field.name)) &&
					// 		getIn(touched,field.name),
					// },
					" w-full block text-sm"
				}
			>
				{props.header}
				<span className="text-red-500 font-bold">{props.star}</span>
			</label>
		)}

		{/* {console.log(Boolean(getIn(errors,field.name)))} */}
		<InputText
			className={classNames(
				{
					"p-invalid":
						Boolean(getIn(errors, field.name)) &&
						getIn(touched, field.name),
				},
				"block w-full "
			)}
			{...field}
			{...props}
			// placeholder={props.header}
			// type={props.type ? props.type : "text"}
		/>

		{Boolean(getIn(errors, field.name)) && getIn(touched, field.name) && (
			<small className="p-error block">{getIn(errors, field.name)}</small>
		)}
	</>
);
export const FieldPassword = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors, handleReset, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<>
		{props.header && (
			<label className={" w-full block text-sm"}>
				{props.header}
				<span className="text-red-500 font-bold">{props.star}</span>
			</label>
		)}

		{/* {console.log(Boolean(getIn(errors,field.name)))} */}
		<Password
			className={classNames(
				{
					"p-invalid":
						Boolean(getIn(errors, field.name)) &&
						getIn(touched, field.name),
				},
				"block w-full"
			)}
			{...field}
			{...props}
			inputClassName="w-full"
			toggleMask
			// placeholder={props.header}
			// type={props.type ? props.type : "text"}
		/>
		{Boolean(getIn(errors, field.name)) && getIn(touched, field.name) && (
			<small className="p-error">{getIn(errors, field.name)}</small>
		)}
	</>
);
export const FieldCheckBox = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors, handleReset, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<>
		{props.header && (
			<label className={" w-full block text-sm"}>
				{props.header}
				<span className="text-red-500 font-bold">{props.star}</span>
			</label>
		)}

		{/* {console.log(Boolean(getIn(errors,field.name)))} */}
		<Checkbox
			className={classNames(
				{
					"p-invalid":
						Boolean(getIn(errors, field.name)) &&
						getIn(touched, field.name),
				},
				"block w-full"
			)}
			{...field}
			{...props}
			checked={field.value}
			// placeholder={props.header}
			// type={props.type ? props.type : "text"}
		/>
		{Boolean(getIn(errors, field.name)) && getIn(touched, field.name) && (
			<small className="p-error">{getIn(errors, field.name)}</small>
		)}
	</>
);

export const FeildDropdown = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<>
		{props.header && (
			<label className={" w-full block text-sm"}>
				{props.header}
				<span className="text-red-500 font-bold">{props.star}</span>
			</label>
		)}
		<Dropdown
			draggable
			className={classNames(
				{
					"border-red-600":
						Boolean(getIn(errors, field.name)) &&
						getIn(touched, field.name),
				},
				"border-round-md w-full"
			)}
			{...field}
			{...props}
			// options={props.options}
			// optionLabel={props.optionLabel}
			// placeholder={props.header}
		/>
		{Boolean(getIn(errors, field.name)) && getIn(touched, field.name) && (
			<small className="p-error">{getIn(errors, field.name)}</small>
		)}
	</>
);
export const FeildCalender = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors, setFieldValue, handleCgange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<>
		<label className={" w-full block text-sm"}>
			{props.header}
			<span className="text-red-500 font-bold">{props.star}</span>
		</label>
		<Calendar
			className={classNames(
				{
					"p-invalid":
						Boolean(getIn(errors, field.name)) &&
						getIn(touched, field.name),
				},
				"border-round-md w-full"
			)}
			{...field}
			{...props}
			showIcon
			dateFormat="dd-mm-yy"
			// value={new Date(field.value)}

			// onChange={(e) => setFieldValue(field.name, e.value)}
		/>
		{Boolean(getIn(errors, field.name)) && getIn(touched, field.name) && (
			<small className="p-error">{getIn(errors, field.name)}</small>
		)}
	</>
);

export const FieldTextArea = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<>
		<label className={" w-full block text-sm"}>
			{props.header}
			<span className="text-red-500 font-bold">{props.star}</span>
		</label>
		<InputTextarea
			className={classNames(
				{
					"border-red-600":
						Boolean(getIn(errors, field.name)) &&
						getIn(touched, field.name),
				},
				"border-round-md w-full"
			)}
			{...field}
			{...props}
			// placeholder={props.header}
		/>
		{Boolean(getIn(errors, field.name)) && getIn(touched, field.name) && (
			<small className="p-error">{getIn(errors, field.name)}</small>
		)}
	</>
);
export const FieldEditor = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors, setFieldValue, handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<>
		<label className={" w-full block text-sm"}>
			{props.header}
			<span className="text-red-500 font-bold">{props.star}</span>
		</label>
		{/* <Editor
			style={{ height: "220px" }}
			className={classNames(
				{
					"border-red-600":
						Boolean(getIn(errors,field.name)) && getIn(touched,field.name),
				},
				"border-round-md w-full"
			)}
			{...field}
			{...props}
			value={field.value}
			onTextChange={(e) => setFieldValue(field.name, e.htmlValue, true)}
			// placeholder={props.header}
		/> */}
		{/* <CKEditor
			{...field}
			{...props}
			style={{ minHeight: "400px" }}
			className={classNames(
				{
					"border-red-600":
						Boolean(getIn(errors,field.name)) && getIn(touched,field.name),
				},
				"border-round-md w-full"
			)}
			editor={ClassicEditor}
			data={field.value}
			onChange={(event, editor) => {
				const data = editor.getData();
				setFieldValue(field.name, data, true);
			}}
		/> */}
		<ReactQuill
			// {...field}
			defaultValue={""}
			{...props}
			theme="snow"
			style={{ background: "#fff" }}
			value={field.value}
			onChange={field.onChange(field.name)}
		/>
		{Boolean(getIn(errors, field.name)) && getIn(touched, field.name) && (
			<small className="p-error">{getIn(errors, field.name)}</small>
		)}
	</>
);

export const FieldFile = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<>
		<label className="block">
			{props.header}
			<span className="text-red-500 font-bold">{props.star}</span>
		</label>
		<FileUpload
			customUpload
			accept=".jpg,.jpeg,.png"
			className="border-round-md w-full"
			{...field}
			name={field.name}
			{...props}
			maxFileSize={1000000}
			uploadHandler={(e) => props.onUpload(e, setFieldValue)}
			emptyTemplate={
				<p className="m-0">Drag and drop files to here to upload.</p>
			}
		/>
		{getIn(touched, field.name) && Boolean(getIn(errors, field.name)) && (
			<div className="error">{getIn(errors, field.name)}</div>
		)}
	</>
);

export const FieldRadio = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<>
		<label className="block text-sm">
			{props.header}
			<span className="text-red-500 font-bold">{props.star}</span>
		</label>
		<div className="flex">
			{props.radiolist.map((btn, i) => {
				return (
					<div key={i} className="flex align-items-center mr-3 ">
						<RadioButton
							{...field}
							{...props}
							{...btn}
							checked={field.value === btn.value}
							onChange={(e) => {
								setFieldValue(field.name, e.value, true);
							}}
						/>

						<label className="ml-1">{btn.value}</label>
					</div>
				);
			})}
		</div>
		{getIn(touched, field.name) && Boolean(getIn(errors, field.name)) && (
			<div className="p-error">{getIn(errors, field.name)}</div>
		)}
	</>
);
export const FieldMultiselect = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<>
		{props.header && (
			<label className={" w-full block text-sm"}>
				{props.header}
				<span className="text-red-500 font-bold">{props.star}</span>
			</label>
		)}

		<MultiSelect
			className={classNames(
				{
					"border-red-600":
						Boolean(getIn(errors, field.name)) &&
						getIn(touched, field.name),
				},
				"border-round-md w-full"
			)}
			display="chip"
			{...field}
			{...props}
		/>
		{Boolean(getIn(errors, field.name)) && getIn(touched, field.name) && (
			<small className="p-error">{getIn(errors, field.name)}</small>
		)}
	</>
);

// export const multiCheckBox = ({
// 	field, // { name, value, onChange, onBlur }
// 	form: { touched, errors, handleReset, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
// 	...props
// }) => {
// 	const [selectedCategories,setSelectedCategories]=useState([])
// 	const handleProfession = (e, setFieldValue) => {
// 		let _selectedCategories = [...selectedCategories];

// 		if (e.checked) _selectedCategories.push(e.target.value);
// 		else
// 			_selectedCategories = _selectedCategories.filter(
// 				(category) => category !== e.target.value
// 			);

// 		setSelectedCategories(_selectedCategories);
// 		setFieldValue(field.nam, _selectedCategories);
// 	};
// 	return (
// 		<>
// 			{props.checkBoxData.map((category) => {
// 				return (
// 					<div
// 						key={category.value}
// 						className="flex align-items-start"
// 					>
// 						<Checkbox
// 							inputId={category.value}

// 							value={category}
// 							onChange={(e) => {
// 								handleProfession(e, setFieldValue);
// 							}}
// 							checked={field.value.profession.some(
// 								(item) => item === category
// 							)}
// 						/>
// 						<label htmlFor={category.value} className="ml-2">
// 							{category.name}
// 						</label>
// 					</div>
// 				);
// 			})}
// 		</>
// 	);
// };

export const FieldMuiliCheckBox = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors, handleReset, setFieldValue, setValues }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<>
		{/* {console.log(Boolean(getIn(errors,field.name)))} */}

		<Checkbox
			className={classNames({
				"p-invalid":
					Boolean(getIn(errors, field.name)) &&
					getIn(touched, field.name),
			})}
			{...field}
			{...props}
			defaultChecked={false}
		/>

		{props.header && (
			<label className={"ml-2 text-sm font-semibold"}>
				{props.header}
				{/* <span className="text-red-500 font-bold">{props.star}</span> */}
			</label>
		)}
	</>
);

export const FieldChips = ({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	...props
}) => (
	<>
		{props.header && (
			<label className={" w-full block text-sm"}>
				{props.header}
				<span className="text-red-500 font-bold">{props.star}</span>
			</label>
		)}

		<Chips
			className={classNames(
				{
					"border-red-600":
						Boolean(getIn(errors, field.name)) &&
						getIn(touched, field.name),
				},
				"border-round-md w-full"
			)}
			{...field}
			{...props}
		/>
		{Boolean(getIn(errors, field.name)) && getIn(touched, field.name) && (
			<small className="p-error">{getIn(errors, field.name)}</small>
		)}
	</>
);
