import React from "react";

const Paginator = (props) => {
	return (
		<div className="w-full mt-3 mb-2 paginator flex  justify-content-between align-items-center">
			{props.totalElements && (
				<span>
					Showing {props.pageNo * props.pageSize - props.pageSize + 1}{" "}
					to{" "}
					{props.totalElements < props.pageSize * props.pageNo
						? props.totalElements
						: props.pageNo * props.pageSize}{" "}
					from {props.totalElements}
				</span>
			)}
			<div className="flex gap-3 ">
				<div className="mr-3">
					Items per page:{" "}
					<select
						onChange={props.pagesizechange}
						value={props.pageSize}
					>
						<option value="25">25</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</select>
				</div>
				<div>
					<button
						className="mr-2 pagination-btn"
						disabled={props.firstPage}
						onClick={props.decrement}
					>
						<span className="pi pi-angle-left"></span>
					</button>
					<span>
						{props.pageNo}/{props.totalPages}
					</span>
					<button
						className="ml-2 pagination-btn"
						disabled={props.lastPage}
						onClick={props.increment}
					>
						<span className="pi pi-angle-right"></span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default Paginator;
