import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { ProgressSpinner } from "primereact/progressspinner";
import { ProductService } from "../../../services/ProductService";

const ProductStatus = ({ id }) => {
	const message = useRef(null);
	const [statusForm, setFrom] = useState([{ id: "", status: "" }]);
	const [isLoading, setLoading] = useState(false);
	const productSrvice = new ProductService();

	const getProductsStatus = () => {
		productSrvice
			.getProductsStatus(id)
			.then((res) => {
				let tempStatusArr = res.map((item) => {
					return {
						id: item.id,
						status: item.name,
					};
				});
				tempStatusArr.push({ id: "", status: "" });
				setFrom(tempStatusArr);
				setLoading(false);
			})
			.catch((e) => {
				setLoading(false);
				message.current.show([
					{
						severity: "error",
						summary: "",
						detail: e.message,
						life: 2000,
					},
				]);
			});
	};

	useEffect(() => {
		setLoading(true);
		getProductsStatus(); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handelAddStatus = (event) => {
		event.preventDefault();
		const inputState = {
			id: "",
			status: "",
		};
		setFrom((prv) => [...prv, inputState]);
	};
	const handelStatusRemove = (index, event) => {
		event.preventDefault();
		setFrom((prev) => prev.filter((item) => item !== prev[index]));
	};
	const handelInputChange = (index, event) => {
		event.preventDefault();
		setFrom((prv) => {
			return prv.map((item, i) => {
				if (i !== index) {
					return item;
				}
				return {
					...item,
					[event.target.name]: event.target.value,
				};
			});
		});
	};

	const saveStatus = () => {
		setLoading(true);
		let status = statusForm.filter(
			(item) => item.id === "" && item.status !== ""
		);

		if (status && status.length > 0) {
			const requestBody = {
				id: id,
				name: [],
			};
			status.forEach((item) => {
				requestBody.name.push(item.status);
			});
			productSrvice
				.addProductStatus(requestBody)
				.then((res) => {
					if (res) {
						getProductsStatus();
					}
				})
				.catch((e) => {
					setLoading(false);
					message.current.show([
						{
							severity: "error",
							detail: "Status Can't be empty",
							life: 2000,
						},
					]);
				});
		} else {
			setLoading(false);
			message.current.show([
				{
					severity: "error",
					detail: "Status Can't be empty",
					life: 2000,
				},
			]);
		}
	};
	const updateStatus = (index, statusId) => {
		setLoading(true);
		const requestBody = {
			id: statusId,
			name: statusForm[index].status,
		};
		productSrvice
			.updateProductStatus(requestBody)
			.then((res) => {
				if (res) {
					getProductsStatus();
				}
			})
			.catch((e) => {
				setLoading(false);
				message.current.show([
					{
						severity: "error",
						summary: "",
						detail: e.message,
						life: 2000,
					},
				]);
			});
	};
	const deleteProductStatus = (statusId) => {
		setLoading(true);

		productSrvice
			.deleteProductStatus(statusId)
			.then((res) => {
				if (res !== "Can't be deleted") {
					getProductsStatus();
				} else {
					setLoading(false);
					message.current.show([
						{
							severity: "error",
							summary: "",
							detail: "Can't be deleted",
							life: 2000,
						},
					]);
				}
			})
			.catch((e) => {
				setLoading(false);
				message.current.show([
					{
						severity: "error",
						summary: "",
						detail: e.message,
						life: 2000,
					},
				]);
			})
			.finally(() => setLoading(false));
	};
	return (
		<div className="card modal-form-grid">
			<Messages className="w-full" ref={message} />
			{isLoading ? (
				<div className="flex align-items-center justify-content-center py-5">
					<ProgressSpinner
						style={{
							width: "40px",
							height: "40px",
						}}
						strokeWidth="4"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				</div>
			) : (
				<div className="col-12">
					{statusForm.map((item, index) => (
						<div
							className="flex w-full mb-2"
							key={`status-${index}`}
						>
							<InputText
								type="text"
								name="status"
								placeholder="Status"
								value={item.status}
								onChange={(e) => handelInputChange(index, e)}
								className="w-full mr-2"
							/>

							{!item.id ? (
								index === statusForm.length - 1 ? (
									<Button
										icon="pi pi-plus"
										className="success-btn p-button-sm"
										onClick={handelAddStatus}
										tooltip="Add Status"
										tooltipOptions={{ position: "bottom" }}
									/>
								) : (
									<Button
										icon="pi pi-times"
										className="danger-btn p-button-sm"
										onClick={(e) =>
											handelStatusRemove(index, e)
										}
										tooltip="Remove Status"
										tooltipOptions={{ position: "bottom" }}
									/>
								)
							) : (
								<>
									<Button
										icon="pi pi-pencil"
										className="p-button p-button-outlined primary-btn mr-1"
										tooltip="Update Status"
										tooltipOptions={{ position: "bottom" }}
										onClick={(e) =>
											updateStatus(index, item.id)
										}
									></Button>
									<Button
										icon="pi pi-trash"
										className="p-button-danger p-button-outlined mr-l"
										tooltip="Delete Status"
										tooltipOptions={{ position: "bottom" }}
										onClick={(e) =>
											deleteProductStatus(item.id)
										}
									></Button>
								</>
							)}
						</div>
					))}
					<Button
						label="Save"
						icon="pi pi-save"
						className="p-button primary-btn"
						onClick={saveStatus}
						tooltip="Save Status"
						tooltipOptions={{ position: "bottom" }}
					/>
				</div>
			)}
		</div>
	);
};

export default ProductStatus;
