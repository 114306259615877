import { useState } from "react";
import { Form, Formik, Field } from "formik";
import Config from "../../../../shared/config";
import { Button } from "primereact/button";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
} from "../../../../components/FieldInput";
import { useGetProductDetailsQuery } from "../../../../services/query/queryApi";

const SearchBusinessList = (props) => {
	const { data: productList = { data: [] } } = useGetProductDetailsQuery({
		searchRequest: [],
		pageNo: 1,
		pageSize: 1000,
		sorts: [],
	});
	const [show, setShow] = useState(false);
	const initialValue = {
		fullName: "",
		agentUserName: "",
		agentCode: "",
		mobileNumber: "",
		productId: "",
		districtName: "",
		fromDate: "",
		toDate: "",
	};

	// const getAllLoanData = (value) => {
	// 	props.loader(true);
	// 	const body = {
	// 		pageNo: 1,
	// 		pageSize: 1000000,
	// 	};

	// 	loanListService
	// 		.getLoanApplicationList(body)
	// 		.then((res) => {
	// 			const data = newData(res.data);
	// 			exportExcel(data, "All application list");
	// 		})
	// 		.catch((e) => {
	// 			toast.current.show({
	// 				severity: "error",
	// 				summary: e.name,
	// 				detail: e.message,
	// 				style: { color: "#000000" },
	// 				life: 3000,
	// 			});
	// 		})
	// 		.finally(() => props.loader(false));
	// };
	// const newData = (data) => {
	// 	props.loader(true);
	// 	const arr = [...data];
	// 	const body = arr.map((item) => ({
	// 		...(item.firstName === null &&
	// 		item.middleName === null &&
	// 		item.lastName === null
	// 			? {
	// 					Name: item.applicantName,
	// 			  }
	// 			: {
	// 					Name:
	// 						(item.firstName ? item.firstName : "") +
	// 						" " +
	// 						(item.middleName ? item.middleName : "") +
	// 						" " +
	// 						(item.lastName ? item.lastName : ""),
	// 			  }),
	// 		LOB: item.alias,
	// 		Zone: item.zone,
	// 		State: item.state,
	// 		District: item.district,

	// 		"Mobile No": item.mobile,
	// 		"RF Application No": item.number,
	// 		"RFA Status": item.status?.replaceAll("_", " ").toLowerCase(),
	// 		"Agent Code": item.agentCode,
	// 		"Program Name": item.programName,

	// 		Amount: item.loanAmount,

	// 		Gender: item.gender,
	// 		"Date Of Birth": item.dob
	// 			? moment(item.dob).format("Do MMM, YYYY")
	// 			: "",
	// 		"Personal Mail": item.personalMail,

	// 		"Created On": item.createdOn
	// 			? moment(item.createdOn).format("Do MMM, YYYY")
	// 			: "",
	// 		"FI Status": item.applicationStatus,
	// 		// "subProductId":item.subProductId,

	// 		"FI Application Number": item.bankApplicationNumber,
	// 	}));
	// 	return body;
	// };
	const handleForm = (val, action) => {
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: val,
		});
	};

	// const items = [
	// 	{
	// 		label: "Export",
	// 		icon: "pi pi-file-excel",
	// 		command: () => {
	// 			exportExcel(props.newData, "applicationList");
	// 		},
	// 	},
	// 	{
	// 		label: "Export All",
	// 		icon: "pi pi-file-excel",
	// 		command: () => {
	// 			getAllLoanData();
	// 		},
	// 	},
	// 	{
	// 		label: "Export Hospicash",
	// 		icon: "pi pi-file-excel",
	// 		command: () => {
	// 			getData();
	// 		},
	// 	},
	// ];

	return (
		<>
			<div className="col-12 w-full mb-3 flex align-items-end justify-content-between">
				<h3>Business Details</h3>
				<div className="flex gap-2">
					<Button
						icon={show ? "pi pi-times" : "pi pi-search"}
						className={
							show
								? "p-button-danger p-button-outlined"
								: "p-button-outlined"
						}
						label={show ? "Close" : "Search"}
						onClick={() => {
							setShow((show) => !show);
						}}
					/>
					<Button
						className=" p-button-outlined"
						onClick={() =>
							props.searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							})
						}
					>
						<i className="pi pi-refresh mr-1" />
						Reload
					</Button>
				</div>
			</div>
			{show && (
				<Formik initialValues={initialValue} onSubmit={handleForm}>
					{({ handleSubmit, resetForm }) => (
						<Form
							className="background-secondary c-search-form-box border-round-md form-grid grid mb-3"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 md:col-3">
								<Field
									header={"From Date"}
									name={"fromDate"}
									component={FeildCalender}
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									header={"To Date"}
									name={"toDate"}
									component={FeildCalender}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Product"}
									name={"productId"}
									component={FeildDropdown}
									options={productList?.data?.filter(
										(item) => item.active
									)}
									filter
									optionValue={"id"}
									optionLabel="title"
									optionGroupLabel="title"
									optionGroupChildren="subProducts"
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									header={"Name"}
									name={"fullName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Mobile No."}
									name={"mobileNumber"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Agent Code"}
									name={"agentCode"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Agent Username"}
									name={"agentUserName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"District"}
									name={"districtName"}
									component={FieldInput}
								/>
							</div>

							<div className="flex flex-1 justify-content-end align-items-center">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Search" />
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default SearchBusinessList;
