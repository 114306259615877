import React, { useState, useEffect, useRef, useReducer } from "react";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import {
	MdModeEditOutline,
	MdLockOutline,
	MdOutlinePreview,
	MdOutlineCheckCircle,
} from "react-icons/md";
import { LoanApplicationService } from "../../../services/LoanApplication";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import { Button } from "primereact/button";
import SearchLoanApplication from "./components/SearchLoanApplication";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	setname,
	setType,
	setAlias,
	setStatus,
	setApplicationId,
	setSubproductId,
} from "../../../store/reducer/LeadReducer";
import Config from "../../../shared/config";
import { LoanService } from "../../../services/LoanService";
import { ConfirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { confirmDialog } from "primereact/confirmdialog"; // To use confirmDialog method
import Loader from "../../../components/Loader";
import { Dialog } from "primereact/dialog";
import { produce } from "immer";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { Field, Form, Formik } from "formik";
import {
	FeildDropdown,
	FieldInput,
	FieldTextArea,
} from "../../../components/FieldInput";
import TableNoData from "../../../components/TableNoData";
import Paginator from "../../../components/Paginator";
import {
	searchInitialState,
	searchReducer,
} from "../../../components/searchReducer";
import {
	setApplicationState,
	setTypeName,
} from "../../../store/reducer/AuthReducer";
import MenuComponent from "../../../components/MenuComponent";
import StatusBulkUpload from "./payments/components/StatusBulkUpload/StatusBulkUpload";
import { CallCenterService } from "../../../services/callCenterService";
import { ProgramService } from "../../../services/ProgramService";
import { ProgressSpinner } from "primereact/progressspinner";
import * as Yup from "yup";
const VendorLoanApplicationList = () => {
	const urlState = useParams();
	const leadDispatch = useDispatch();
	const loanListService = new LoanApplicationService();
	const ccService = new CallCenterService();
	const loanService = new LoanService();
	const toast = useRef(null);
	const navigate = useNavigate();
	const [assignLead, setAssignLead] = useState({
		state: false,
		id: null,
	});
	const selector = useSelector((state) => state.persistData.auth.user.role);
	const programService = new ProgramService();
	const [dialog, setDialog] = useState(false);
	const [programUpdatedialog, setProgramUpdateDialog] = useState(false);
	const [application, setApplication] = useState(null);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [selectedUpdateProgram, setSelectedUpdateProgram] = useState("");
	const [institutionState, institutionDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const validationSchema = Yup.object().shape({
		productStatusId: Yup.object().required("This field is required"),
		// sanctionAmount: Yup.number().required("This field is required"),
		remarks: Yup.string().required("This field is required"),
	});
	const [allOEList, setAllOEList] = useState([]);
	const [state, dispatch] = useReducer(dataReducer, fetchInitialState);

	const [spinner, setSpinner] = useState(false);
	// const [sortState, sortDispatch] = useReducer(sortReducer, sortInitialState);
	const [searchState, searchDispatch] = useReducer(searchReducer, {
		searchValue: {
			status: [
				"CUSTOMER_DRAFT",
				"DRAFT",
				"LOCK_APPROVED_BY_FI",
				"LOCK_DISBURSED_BY_FI",
				"LOCK_REJECTED_BY_FI",
				"LOCK",
				"CUSTOMER_APPROVED",
				"PENDING_FOR_CUSTOMER",
				"REVERT_BY_OE",
				"TSRM_SUBMITTED",
				"WORK_IN_PROGRESS",
			],
		},
		pageNo: 1,
		pageSize: 50,
		sortByColumn: null,
		sortType: null,
	});
	const [loading1, setLoading1] = useState(false);
	const [statusDialoge, setStatusDialoge] = useState(false);
	const [noteDetailDialog, setNoteDetailDialog] = useState({
		state: false,
		id: null,
		note: "",
	});
	const [rejectDialog, setRejectDialog] = useState({
		state: false,
		id: null,
		note: "",
	});
	const [revertDialog, setRevertDialog] = useState({
		state: false,
		id: null,
		note: "",
	});
	const [noteList, setnoteList] = useState([]);
	const [statusState, statusDispatch] = useReducer(
		produce((state, action) => {
			switch (action.type) {
				case "dialog":
					state.dialog = action.payload;
					break;
				case "id":
					state.applicationId = action.payload;
					break;
				case "setData":
					state.setData = action.payload;
					break;
				case "getHistory":
					state.getHistory = action.payload;
					break;
				case "isLoading":
					state.isLoading = action.payload;
					break;
				case "type":
					state.type = action.payload;
					break;
				case "sanctionAmount":
					state.sanctionAmount = action.payload;
					break;
				case "fetched":
					state.fetched = action.payload;
					break;
				default:
					return state;
			}
		}),
		{
			dialog: false,
			setData: {},
			applicationId: null,
			getHistory: [],
			isLoading: false,
			type: "",
			sanctionAmount: "",
			fetched: false,
		}
	);
	const [statusList, setStatusList] = useReducer(
		dataReducer,
		fetchInitialState
	);

	const [lodarTblArray] = useState(Array.from({ length: 10 }));
	const getLoanData = (value) => {
		dispatch({ type: Config.FETCH_CONFIG.start });

		const body = {
			applicationType: ["LOAN", "CREDIT_CARD"],
			...searchState.searchValue,
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.sortByColumn &&
				searchState.sortType && {
					sorts: [
						searchState.sortByColumn + "," + searchState.sortType,
					],
				}),
			...(urlState.leadId && { leadId: urlState.leadId }),
		};

		// if (item.state !== null) {
		// 	body.leadId = item.state;
		// }

		const demo = Object.fromEntries(
			Object.entries(body).filter(([_, v]) => v !== "")
		);

		ccService
			.getVendorApplicationList(demo)
			.then((res) =>
				dispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				})
			)
			.catch((e) => {
				dispatch({ type: Config.FETCH_CONFIG.error, payload: e });
				toast.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => dispatch({ type: Config.FETCH_CONFIG.stop }));
	};
	const handleLoack = (e) => {
		setSpinner(true);
		loanService
			.applicationLock(e)
			.then(() => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "Status updated successfully",
					style: { color: "#000000" },
					life: 3000,
				});
				getLoanData();
			})
			.catch((er) =>
				toast.current.show({
					severity: "error",
					summary: "error",
					detail: "Error",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setSpinner(false);
			});
	};
	const handleWorkInProgress = (e) => {
		setSpinner(true);
		ccService
			.set_work_in_progress({
				applicationId: e,
				notes: null,
			})
			.then(() => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "Status updated successfully",
					style: { color: "#000000" },
					life: 3000,
				});
				getLoanData();
			})
			.catch((er) =>
				toast.current.show({
					severity: "error",
					summary: "error",
					detail: "Error",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setSpinner(false);
			});
	};
	const skeletonBody = () => {
		return <Skeleton height="1rem"></Skeleton>;
	};
	// function tblSort(columnName, sortType) {
	// 	searchDispatch({
	// 		type: Config.SEARCH_CONFIG.sortByColumn,
	// 		payload: columnName,
	// 	});
	// 	searchDispatch({
	// 		type: Config.SEARCH_CONFIG.sortType,
	// 		payload: sortType,
	// 	});
	// }
	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	// function getSortableColumn(name, columnName) {
	// 	return (
	// 		<span className="flex align-items-center">
	// 			{name}
	// 			<span className="flex flex-column text-xs ml-2">
	// 				<span
	// 					className={
	// 						searchState.sortByColumn === columnName &&
	// 						searchState.sortType === "asc"
	// 							? "sort-icon asc active"
	// 							: "sort-icon asc"
	// 					}
	// 					onClick={() => tblSort(columnName, "asc")}
	// 				></span>
	// 				<span
	// 					className={
	// 						searchState.sortByColumn === columnName &&
	// 						searchState.sortType === "desc"
	// 							? "sort-icon desc active"
	// 							: "sort-icon desc"
	// 					}
	// 					onClick={() => tblSort(columnName, "desc")}
	// 				></span>
	// 			</span>
	// 		</span>
	// 	);
	// }
	function tblSort(columnName, sortType) {
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}
	const getName = (item) => {
		let fname = item.firstName ? item.firstName : "";
		let mname = item.middleName ? item.middleName : "";
		let lname = item.lastName ? item.lastName : "";
		let name = `${fname} ${mname} ${lname}`;
		if (!item.firstName && !item.middleName && !item.lastName) {
			return item.applicantName;
		} else {
			return name;
		}
	};
	// const colGroup = [
	// 	{ header: "Rainbow Application No", field: "number" },
	// 	{ header: "Bank Application No", field: "bankApplicationNumber" },
	// 	{ header: "Alias", field: "alias" },
	// 	{ header: "Program Name", field: "programName" },
	// 	{ header: "Firstname", field: "firstName" },
	// 	{ header: "Middlename", field: "middleName" },
	// 	{ header: "Lastname", field: "lastName" },
	// 	{ header: "Mobile", field: "mobile" },

	// 	{ header: "Application State", ield: "status" },
	// 	{ header: "Application Status", field: "applicationStatus" },

	// 	// { header: "Action" },
	// 	// <Column  />
	// 	// 				<Column  />
	// 	// 				<Column field="alias" />
	// 	// 				<Column field="programName" />
	// 	// 				<Column body={getName} />
	// 	// 				<Column field="mobile" />
	// 	// 				<Column body={stateTemplate} />
	// 	// 				<Column body={statusTemplate} />
	// 	// 				<Column body={actionTemplate} />
	// ];
	// const exportColumns = colGroup.map((col) => ({
	// 	title: col.header,
	// 	dataKey: col.field,
	// }));
	const handleStatus = (e) => {
		setStatusList({ type: Config.FETCH_CONFIG.start });
		loanListService
			.getStatusByProgramId(e)
			.then((res) =>
				setStatusList({
					type: Config.FETCH_CONFIG.success,
					payload: res.map((item) => ({
						...item,
						// value: item.id,
						statusName: `${item.productStatusName} (${item.name})`,
					})),
				})
			)

			.catch((e) => console.log(e))
			.finally(() => setStatusList({ type: Config.FETCH_CONFIG.stop }));
	};

	// function getSortableColumn(name, columnName) {
	// 	return (
	// 		<span className="flex align-items-center">
	// 			{name}
	// 			<span className="flex flex-column text-xs ml-2">
	// 				<span
	// 					className={
	// 						searchState.sortByColumn === columnName &&
	// 						searchState.sortType === "asc"
	// 							? "sort-icon asc active"
	// 							: "sort-icon asc"
	// 					}
	// 					onClick={() => tblSort(columnName, "asc")}
	// 				></span>
	// 				<span
	// 					className={
	// 						searchState.sortByColumn === columnName &&
	// 						searchState.sortType === "desc"
	// 							? "sort-icon desc active"
	// 							: "sort-icon desc"
	// 					}
	// 					onClick={() => tblSort(columnName, "desc")}
	// 				></span>
	// 			</span>
	// 		</span>
	// 	);
	// }
	const dateTemplate = ({ createdOn }) => {
		return createdOn
			? moment(createdOn).format("Do MMM, YYYY HH:mm A")
			: createdOn;
	};
	const dateMTemplate = ({ modifiedOn }) => {
		return modifiedOn
			? moment(modifiedOn).format("Do MMM, YYYY HH:mm A")
			: modifiedOn;
	};
	const allocatedToTemplate = (item) => {
		let fname = item.allocatedToFirstName ? item.allocatedToFirstName : "";
		let mname = item.allocatedToMiddleName
			? item.allocatedToMiddleName
			: "";
		let lname = item.allocatedToLastName ? item.allocatedToLastName : "";
		let userName = item.allocatedToAgentCode
			? `(${item.allocatedToAgentCode})`
			: `${item.allocatedTo ? `(${item.allocatedTo})` : ""}`;
		let mobile = item.agentMobileNumber ? item.agentMobileNumber : "";
		let name = `${fname} ${mname} ${lname} ${userName} ${mobile}`;

		return name;
	};
	// const newData =
	// 	state.data.data &&
	// 	state.data.data.map((item) => ({
	// 		...(item.firstName === null &&
	// 		item.middleName === null &&
	// 		item.lastName === null
	// 			? {
	// 					Name: item.applicantName,
	// 			  }
	// 			: {
	// 					Name:
	// 						(item.firstName ? item.firstName : "") +
	// 						" " +
	// 						(item.middleName ? item.middleName : "") +
	// 						" " +
	// 						(item.lastName ? item.lastName : ""),
	// 			  }),
	// 		"Mobile No": item.mobile,
	// 		Number: item.number,
	// 		Zone: item.zone,
	// 		State: item.state,
	// 		District: item.district,
	// 		Status: item.status.replaceAll("_", " ").toLowerCase(),
	// 		"Agent Code": item.agentCode,
	// 		"Program Name": item.programName,

	// 		"Loan Amount": item.loanAmount,

	// 		Gender: item.gender,
	// 		"Date Of Birth": moment(item.dob).format("Do MMM, YYYY"),
	// 		"Personal Mail": item.personalMail,

	// 		"Created On": moment(item.createdOn).format("Do MMM, YYYY"),
	// 		"Application Status": item.applicationStatus,
	// 		// "subProductId":item.subProductId,

	// 		"Bank Application Number": item.bankApplicationNumber,
	// 	}));
	// const exportPdf = () => {
	// 	import("jspdf").then((jsPDF) => {
	// 		import("jspdf-autotable").then(() => {
	// 			const doc = new jsPDF.default(0, 0);

	// 			doc.autoTable(exportColumns, state.data.data);
	// 			doc.save(`applicationList${Date.now()}.pdf`);
	// 		});
	// 	});
	// };

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />
				<Column
					header={getSortableColumn("RF Application No.", "number")}
				/>
				<Column
					header={getSortableColumn(
						"FI Application No",
						"bankApplicationNumber"
					)}
				/>
				<Column header={"Amount"} />
				<Column header={"LOB"} />
				<Column
					header={getSortableColumn(
						"Program/Product Name",
						"programName"
					)}
				/>
				<Column header={getSortableColumn("Name", "firstName")} />

				<Column header={"Mobile"} />
				<Column header={getSortableColumn("Created By", "createdBy")} />
				<Column header={getSortableColumn("Created On", "createdOn")} />

				<Column header={"State"} />
				<Column header={"District"} />
				<Column
					header={getSortableColumn("Updated On", "modifiedOn")}
				/>
				<Column
					header={getSortableColumn("Allocated To", "allocatedTo")}
				/>
				<Column header={"OE"} />
				<Column header={getSortableColumn("RFA Status", "status")} />
				<Column
					header={getSortableColumn("FI Status", "applicationStatus")}
				/>
				<Column header="Note" />
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const applicationStatusUpdate = (data, type) => {
		statusDispatch({
			type: "dialog",
			payload: true,
		});
		statusDispatch({
			type: "type",
			payload: type,
		});
		statusDispatch({
			type: "id",
			payload: data.id,
		});
		if (type === "status") {
			statusDispatch({ type: "isLoading", payload: true });
			handleStatus(data.programId);

			loanListService
				.getApplicationStatus(data.id)
				.then((res) => {
					statusDispatch({ type: "getHistory", payload: res });
					statusDispatch({
						type: "sanctionAmount",
						payload: res[0]?.sanctionAmount
							? res[0]?.sanctionAmount
							: null,
					});
				})
				.catch(() => {
					toast.current.show({
						severity: "error",
						summary: "error",
						detail: "Unable to get Status history",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => {
					statusDispatch({ type: "isLoading", payload: false });
					statusDispatch({ type: "fetched", payload: true });
				});
		}
	};

	const confirm = (e) => {
		confirmDialog({
			message: "Are you sure you want to proceed?",
			header: "Confirmation",
			icon: "pi pi-exclamation-triangle",
			accept: () => handleLoack(e),
			reject: () => {},
		});
	};
	const confirmWorkInProgress = (e) => {
		confirmDialog({
			message: "Are you sure you want to proceed?",
			header: "Confirmation",
			icon: "pi pi-exclamation-triangle",
			accept: () => handleWorkInProgress(e),
			reject: () => {},
		});
	};
	const getDataById = (e) => {
		institutionDispatch({ type: Config.FETCH_CONFIG.start });
		programService
			.getProgramBySubId({ subProductId: e })
			.then((res) => {
				const data = res
					.filter((item) => item.activeStatus === true)
					?.map((elm) => ({
						...elm,
						loanAmount: elm.minLoanAmount + "-" + elm.maxLoanAmount,
						processingFee:
							elm.minProcessingFees + "-" + elm.maxProcessingFees,
						loanPeriod: elm.minLoanPeriod + "-" + elm.maxLoanPeriod,
						RoI:
							elm.minRateOfInterest + "-" + elm.maxRateOfInterest,
					}));
				institutionDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: data,
				});
			})
			.catch((e) =>
				institutionDispatch({
					type: Config.FETCH_CONFIG.error,
					payload: e,
				})
			)
			.finally(() =>
				institutionDispatch({ type: Config.FETCH_CONFIG.stop })
			);
	};
	const actionTemplate = (data) => {
		const items = [
			...(data.status !== "LOCK"
				? [
						...(data.status !== "CUSTOMER_APPROVED"
							? [
									{
										label: "Edit",
										icon: "pi pi-pencil",
										command: () => {
											leadDispatch(
												setApplicationId(data.id)
											);
											leadDispatch(setStatus("draft"));
											data.status === "CUSTOMER_DRAFT" &&
												leadDispatch(
													setStatus("CUSTOMER_DRAFT")
												);
											leadDispatch(
												setname("applicationList")
											);
											leadDispatch(setType("credit"));
											leadDispatch(setAlias(data?.alias));
											leadDispatch(
												setSubproductId(
													data.subProductId
												)
											);
											leadDispatch(setTypeName("edit"));
											if (data.alias === "HC") {
												navigate(
													`/vendor/hospicash/edit/${data.id}`
												);
											} else {
												navigate(
													"/vendor/application/create"
												);
											}
										},
									},
							  ]
							: [
									{
										label: "Lock",
										icon: "pi pi-lock",
										command: () => {
											confirm(data.id);
										},
									},
									{
										label: "Preview",
										icon: "pi pi-info-circle",
										command: () => {
											leadDispatch(
												setname("applicationList")
											);
											leadDispatch(setStatus("lock"));
											leadDispatch(setType("credit"));
											leadDispatch(
												setApplicationId(data.id)
											);
											leadDispatch(
												setSubproductId(
													data.subProductId
												)
											);
											leadDispatch(setAlias(data.alias));
											leadDispatch(setTypeName("edit"));
											if (data.alias === "HC") {
												navigate(
													`/vendor/hospicash/preview/${data.id}`
												);
											} else {
												navigate(
													"/vendor/application/create"
												);
											}
										},
									},
							  ]),
				  ]
				: [
						{
							label: "Preview",
							icon: "pi pi-info-circle",
							command: () => {
								leadDispatch(setname("applicationList"));
								leadDispatch(setStatus("lock"));
								leadDispatch(setType("credit"));
								leadDispatch(setApplicationId(data.id));
								leadDispatch(
									setSubproductId(data.subProductId)
								);
								leadDispatch(setAlias(data.alias));
								leadDispatch(setTypeName("edit"));
								if (data.alias === "HC") {
									navigate(
										`/vendor/hospicash/preview/${data.id}`
									);
								} else {
									navigate("/vendor/application/create");
								}
							},
						},
						{
							label: "Update Status",
							icon: "pi pi-check",
							command: () => {
								applicationStatusUpdate(data, "status");
							},
						},
						{
							label: "Bank Application number",
							icon: "pi pi-verified",
							command: () => {
								applicationStatusUpdate(data, "bank");
							},
						},
				  ]),
			{
				label: "Assign OE",
				icon: "pi pi-tag",
				command: () => {
					setAssignLead((prev) => ({
						...prev,
						id: data.id,
						state: true,
					}));
				},
			},
		];
		const menuItems = [
			{
				label: "Preview",
				icon: "pi pi-info-circle",
				command: () => {
					leadDispatch(setApplicationState("preview"));
					if (data.alias === "HC") {
						navigate(`/vendor/hospicash/edit/${data.id}`, {
							state: {
								mobile: data.mobile,
								pan: "",
								leadId: null,
								subproductId: data.subProductId,
								alias: data?.alias,
								applicationId: data.id,
								stateId: "",
								districtId: "",
							},
						});
					} else {
						navigate("/vendor/application/create", {
							state: {
								mobile: data.mobile,
								pan: "",
								leadId: null,
								subproductId: data.subProductId,
								alias: data?.alias,
								applicationId: data.id,
								stateId: "",
								districtId: "",
							},
						});
					}
				},
			},
		];
		if (data.status !== "REJECT") {
			menuItems.push({
				label: "Reject",
				icon: "pi pi-times",
				command: () => {
					setRejectDialog((prev) => ({
						...prev,
						id: data.id,
						state: true,
					}));
				},
			});
		}
		if (
			data.status !== "LOCK" &&
			data.status !== "LOCK_REJECTED_BY_FI" &&
			data.status !== "LOCK_DISBURSED_BY_FI" &&
			data.status !== "LOCK_APPROVED_BY_FI" &&
			data.status !== "REJECT"
		) {
			menuItems.push({
				label: "Edit",
				icon: "pi pi-pencil",
				command: () => {
					leadDispatch(setApplicationState("edit"));
					if (data.alias === "HC") {
						navigate(`/vendor/hospicash/edit/${data.id}`, {
							state: {
								mobile: data.mobile,
								pan: "",
								leadId: null,
								subproductId: data.subProductId,
								alias: data?.alias,
								applicationId: data.id,
								stateId: "",
								districtId: "",
							},
						});
					} else {
						navigate("/vendor/application/create", {
							state: {
								mobile: data.mobile,
								pan: "",
								leadId: null,
								subproductId: data.subProductId,
								alias: data?.alias,
								applicationId: data.id,
								stateId: "",
								districtId: "",
							},
						});
					}
				},
			});
		}
		if (
			data.status !== "LOCK" &&
			data.status !== "LOCK_REJECTED_BY_FI" &&
			data.status !== "LOCK_DISBURSED_BY_FI" &&
			data.status !== "LOCK_APPROVED_BY_FI" &&
			data.status !== "REVERT_BY_OE" &&
			data.status !== "REJECT" &&
			(selector?.position === 810 || selector?.position === 2000)
		) {
			menuItems.push({
				label: "Revert",
				icon: "pi pi-reply",
				command: () => {
					setRevertDialog((prev) => ({
						...prev,
						id: data.id,
						state: true,
					}));
				},
			});
		}
		switch (true) {
			case data.status === "LOCK" ||
				data.status === "LOCK_REJECTED_BY_FI" ||
				data.status === "LOCK_DISBURSED_BY_FI" ||
				data.status === "LOCK_APPROVED_BY_FI":
				menuItems.push(
					...[
						{
							label: "Update Status",
							icon: "pi pi-check",
							command: () => {
								applicationStatusUpdate(data, "status");
							},
						},
						{
							label: "Bank Application number",
							icon: "pi pi-verified",
							command: () => {
								applicationStatusUpdate(data, "bank");
							},
						},
					]
				);
				break;
			case data.status === "TSRM_SUBMITTED":
				if (selector?.position !== 810) {
					menuItems.push(
						...[
							{
								label: "Assign OE",
								icon: "pi pi-tag",
								command: () => {
									setAssignLead((prev) => ({
										...prev,
										id: data.id,
										state: true,
									}));
								},
							},
						]
					);
				}
				break;
			default:
				break;
		}
		// if (data.status !== "LOCK") {
		// 	menuItems.push({
		// 		label: "Edit",
		// 		icon: "pi pi-pencil",
		// 		command: () => {
		// 			leadDispatch(setApplicationState("edit"));
		// 			if (data.alias === "HC") {
		// 				navigate(`/vendor/hospicash/edit/${data.id}`, {
		// 					state: {
		// 						mobile: data.mobile,
		// 						pan: "",
		// 						leadId: null,
		// 						subproductId: data.subProductId,
		// 						alias: data?.alias,
		// 						applicationId: data.id,
		// 						stateId: "",
		// 						districtId: "",
		// 					},
		// 				});
		// 			} else {
		// 				navigate("/vendor/application/create", {
		// 					state: {
		// 						mobile: data.mobile,
		// 						pan: "",
		// 						leadId: null,
		// 						subproductId: data.subProductId,
		// 						alias: data?.alias,
		// 						applicationId: data.id,
		// 						stateId: "",
		// 						districtId: "",
		// 					},
		// 				});
		// 			}
		// 		},
		// 	});
		// }
		if (
			data.status !== "LOCK" &&
			data.status !== "LOCK_REJECTED_BY_FI" &&
			data.status !== "LOCK_DISBURSED_BY_FI" &&
			data.status !== "LOCK_APPROVED_BY_FI" &&
			data.status !== "REJECT" &&
			(selector?.position === 810 || selector?.position === 2000)
		) {
			if (data?.programId) {
				menuItems.push(
					...[
						{
							label: "Lock",
							icon: "pi pi-lock",
							command: () => {
								confirm(data.id);
							},
						},
						{
							label: "Change Program",
							icon: "pi pi-check",
							command: () => {
								setProgramUpdateDialog(true);
								setApplication(data.id);
								getDataById(data.subProductId);
							},
						},
					]
				);
			}
			// if (!data?.programId) {
			// 	menuItems.push(
			// 		...[
			// 			{
			// 				label: "Add Program",
			// 				icon: "pi pi-plus",
			// 				command: () => {
			// 					setDialog(true);
			// 					setApplication(data.id);
			// 					getDataById(data.subProductId);
			// 				},
			// 			},
			// 		]
			// 	);
			// }
		}
		if (selector?.position === 810 || selector?.position === 2000) {
			menuItems.push(
				...[
					{
						label: "Add Program",
						icon: "pi pi-plus",
						command: () => {
							setDialog(true);
							setApplication(data.id);
							getDataById(data.subProductId);
						},
					},
				]
			);
		}
		if (
			(selector?.position === 810 || selector?.position === 2000) &&
			data.status === "TSRM_SUBMITTED"
		) {
			menuItems.push(
				...[
					{
						label: "Work In Progress",
						icon: "pi pi-clock",
						command: () => {
							confirmWorkInProgress(data.id);
						},
					},
				]
			);
		}
		// if (data.status === "LOCK") {
		// 	menuItems.push(
		// 		...[
		// 			{
		// 				label: "Preview",
		// 				icon: "pi pi-info-circle",
		// 				command: () => {
		// 					leadDispatch(setname("applicationList"));
		// 					leadDispatch(setStatus("lock"));
		// 					leadDispatch(setType("credit"));
		// 					leadDispatch(setApplicationId(data.id));
		// 					leadDispatch(setSubproductId(data.subProductId));
		// 					leadDispatch(setAlias(data.alias));
		// 					leadDispatch(setTypeName("edit"));
		// 					if (data.alias === "HC") {
		// 						navigate(
		// 							`/vendor/hospicash/preview/${data.id}`
		// 						);
		// 					} else {
		// 						navigate("/vendor/application/create");
		// 					}
		// 				},
		// 			},
		// 			{
		// 				label: "Update Status",
		// 				icon: "pi pi-check",
		// 				command: () => {
		// 					applicationStatusUpdate(data, "status");
		// 				},
		// 			},
		// 			{
		// 				label: "Bank Application number",
		// 				icon: "pi pi-verified",
		// 				command: () => {
		// 					applicationStatusUpdate(data, "bank");
		// 				},
		// 			},
		// 		]
		// 	);
		// } else {
		// 	if (data.status === "DRAFT" || data.status === "CUSTOMER_DRAFT") {
		// 		menuItems.push(
		// 			...[
		// 				{
		// 					label: "Edit",
		// 					icon: "pi pi-pencil",
		// 					command: () => {
		// 						leadDispatch(setApplicationId(data.id));
		// 						leadDispatch(setStatus("draft"));
		// 						data.status === "CUSTOMER_DRAFT" &&
		// 							leadDispatch(setStatus("CUSTOMER_DRAFT"));
		// 						leadDispatch(setname("applicationList"));
		// 						leadDispatch(setType("credit"));
		// 						leadDispatch(setAlias(data?.alias));
		// 						leadDispatch(
		// 							setSubproductId(data.subProductId)
		// 						);
		// 						leadDispatch(setTypeName("edit"));
		// 						if (data.alias === "HC") {
		// 							navigate(
		// 								`/vendor/hospicash/edit/${data.id}`
		// 							);
		// 						} else {
		// 							navigate("/vendor/application/create");
		// 						}
		// 					},
		// 				},
		// 				{
		// 					label: "Assign OE",
		// 					icon: "pi pi-tag",
		// 					command: () => {
		// 						setAssignLead((prev) => ({
		// 							...prev,
		// 							id: data.id,
		// 							state: true,
		// 						}));
		// 					},
		// 				},
		// 			]
		// 		);
		// 	} else {
		// 		menuItems.push(
		// 			...[
		// 				{
		// 					label: "Preview",
		// 					icon: "pi pi-info-circle",
		// 					command: () => {
		// 						leadDispatch(setname("applicationList"));
		// 						leadDispatch(setStatus("lock"));
		// 						leadDispatch(setType("credit"));
		// 						leadDispatch(setApplicationId(data.id));
		// 						leadDispatch(
		// 							setSubproductId(data.subProductId)
		// 						);
		// 						leadDispatch(setAlias(data.alias));
		// 						leadDispatch(setTypeName("edit"));
		// 						if (data.alias === "HC") {
		// 							navigate(
		// 								`/vendor/hospicash/preview/${data.id}`
		// 							);
		// 						} else {
		// 							navigate("/vendor/application/create");
		// 						}
		// 					},
		// 				},
		// 				{
		// 					label: "Assign OE",
		// 					icon: "pi pi-tag",
		// 					command: () => {
		// 						setAssignLead((prev) => ({
		// 							...prev,
		// 							id: data.id,
		// 							state: true,
		// 						}));
		// 					},
		// 				},
		// 			]
		// 		);
		// 	}
		// 	if (data?.programId) {
		// 		menuItems.push({
		// 			label: "Lock",
		// 			icon: "pi pi-lock",
		// 			command: () => {
		// 				confirm(data.id);
		// 			},
		// 		});
		// 	}
		// 	if (!data?.programId) {
		// 		menuItems.push({
		// 			label: "Add Program",
		// 			icon: "pi pi-plus",
		// 			command: () => {
		// 				setDialog(true);
		// 				setApplication(data.id);
		// 				getDataById(data.subProductId);
		// 			},
		// 		});
		// 	}
		// }

		return <MenuComponent items={menuItems} />;
	};

	useEffect(() => {
		getLoanData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);
	const handleStatusSave = (value, action) => {
		setLoading1(true);
		const body = {
			applicationId: statusState.applicationId,
			productStatusId: value.productStatusId?.id,
			sanctionAmount: value.sanctionAmount,
			remarks: value.remarks,
		};

		// console.log(body);
		loanListService
			.saveStatus(body)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					style: { color: "#000000" },
				});
				statusDispatch({ type: "dialog", payload: false });
				getLoanData();
			})
			.catch((err) =>
				toast.current.show({
					severity: "error",
					summary: "error",
					detail: err?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoading1(false);
			});
	};
	const stateTemplate = (item) => {
		switch (item?.status) {
			case "CUSTOMER_DRAFT":
				return (
					<span className="status status-warning">
						Customer Draft
					</span>
				);

			case "DRAFT":
				return <span className="status status-warning">Draft</span>;

			case "PENDING_FOR_CUSTOMER":
				return (
					<span className="status status-primary">
						Pending For Customer
					</span>
				);
			case "CUSTOMER_APPROVED":
				return (
					<span className="status status-success">
						Customer Approved
					</span>
				);
			case "LOCK":
				return (
					<span className="status text-white  bg-orange-500">
						Lock
					</span>
				);
			case "TSRM_SUBMITTED":
				return (
					<span className="status text-white  bg-blue-500">
						Tsrm Submit
					</span>
				);
			case "REJECT":
				return (
					<span className="status text-white bg-red-800">
						Rejected
					</span>
				);
			case "REVERT_BY_OE":
				return (
					<span className="status bg-red-100 text-red-800 ">
						Revert By OE
					</span>
				);
			case "WORK_IN_PROGRESS":
				return (
					<span className="status bg-cyan-100 text-cyan-800 ">
						OE Work In Progress
					</span>
				);
			case "LOCK_APPROVED_BY_FI":
				return (
					<span className="status text-white  bg-orange-500">
						Lock
					</span>
				);
			case "LOCK_DISBURSED_BY_FI":
				return (
					<span className="status text-white  bg-orange-500">
						Lock
					</span>
				);
			case "LOCK_REJECTED_BY_FI":
				return (
					<span className="status text-white  bg-orange-500">
						Lock
					</span>
				);
			default:
				return (
					<span className="status text-white  bg-blue-500">
						{item?.status?.replaceAll("_", " ")}
					</span>
				);
		}
	};
	const statusTemplate = (item) => {
		return (
			<>
				{item.applicationStatus && (
					<span className="status status-primary">
						{item.applicationStatus}
					</span>
				)}
			</>
		);
	};
	const statusHistoryTemplate = (item) => {
		return (
			<>
				{item.productStatusName && (
					<span className="status status-primary p-1">
						{item.productStatusName}
					</span>
				)}
			</>
		);
	};
	const handleBankUpdate = (value) => {
		statusDispatch({ type: "isLoading", payload: true });
		loanListService
			.saveBankApplication(value)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "error",
					detail: "Unable to update",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				statusDispatch({ type: "isLoading", payload: false });
				statusDispatch({
					type: "dialog",
					payload: false,
				});
				// searchDispatch({ type: Config.SEARCH_CONFIG.reset });
				getLoanData();
			});
	};

	const handleStatusUpload = () => {
		setStatusDialoge(true);
	};

	const handleStatusDialogeClose = () => {
		setStatusDialoge(false);
	};
	const createdByTemplate = (item) => {
		let fname = item.createdByFirstName ? item.createdByFirstName : "";
		let mname = item.createdByMiddleName ? item.createdByMiddleName : "";
		let lname = item.createdByLastName ? item.createdByLastName : "";
		// const userName = item.createdBy ? `(${item.createdBy})` : "";

		const userName =
			item.agentCode !== null
				? item.agentCode
					? `(${item.agentCode})`
					: ""
				: item.createdBy
				? `(${item.createdBy})`
				: "";

		let name = `${fname} ${mname} ${lname} ${userName}`;
		return name;
	};
	const gLoader = (e) => {
		setSpinner(e);
	};
	useEffect(() => {
		ccService.allOEList().then((res) => {
			const data = res.map((item) => ({
				...item,
				name:
					(item.firstName ? item.firstName : "") +
					" " +
					(item.middleName ? item.middleName : "") +
					" " +
					(item.lastName ? item.lastName : ""),
			}));
			setAllOEList(data);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleOe = (val) => {
		setSpinner(true);
		ccService
			.assignApplicationToOE({
				applicationId: assignLead.id,
				oeUserName: val.oeUserName,
			})
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res?.message,
					style: { color: "#000000" },
					life: 3000,
				});

				setAssignLead((prev) => ({
					...prev,
					id: null,
					state: false,
				}));
				getLoanData();
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setSpinner(false));
	};
	const handleProgramAdd = () => {
		setSpinner(true);
		ccService
			.addProgramToApplication({
				applicationId: application,
				programIds: selectedProducts.map((elm) => elm.programId),
			})
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res?.message,
					style: { color: "#000000" },
					life: 3000,
				});
				setDialog(false);
				getLoanData();
			})
			.finally(() => {
				setSpinner(false);
			});
	};
	const handleProgramUpdate = () => {
		setSpinner(true);
		ccService
			.updateProgramToApplication({
				applicationId: application,
				programIds: [selectedUpdateProgram?.programId],
			})
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res?.message,
					style: { color: "#000000" },
					life: 3000,
				});
				setProgramUpdateDialog(false);
				getLoanData();
			})
			.finally(() => {
				setSpinner(false);
			});
	};
	const handleNoteSubmit = (val) => {
		setSpinner(true);
		ccService
			.saveApplicationNote({
				applicationId: noteDetailDialog.id,
				notes: val.notes,
			})
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});

				getLoanData();
				setNoteDetailDialog((prev) => ({
					...prev,
					state: false,
					id: null,
				}));
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setSpinner(false));
	};

	const handleRejectApplication = (val) => {
		setSpinner(true);
		const newVal =
			val.noteOptions === "Other" ? val.notes : val.noteOptions;
		ccService
			.rejectApplication({
				applicationId: rejectDialog.id,
				notes: newVal,
			})
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res?.message,
					style: { color: "#000000" },
					life: 3000,
				});

				getLoanData();
				setRejectDialog((prev) => ({
					...prev,
					state: false,
					id: null,
				}));
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setSpinner(false));
	};

	const handleRevertApplication = (val) => {
		setSpinner(true);
		ccService
			.revertApplication({
				applicationId: revertDialog.id,
				notes: val.notes,
			})
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res?.message,
					style: { color: "#000000" },
					life: 3000,
				});

				getLoanData();
				setRevertDialog((prev) => ({
					...prev,
					state: false,
					id: null,
				}));
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setSpinner(false));
	};

	return (
		<>
			{spinner && <Loader />}
			<SearchLoanApplication
				// exportPdf={exportPdf}
				searchDispatch={searchDispatch}
				// newData={newData}
				handleStatusUpload={handleStatusUpload}
				loader={gLoader}
			/>
			<Dialog
				visible={dialog}
				onHide={() => {
					setDialog(false);
					setSelectedProducts([]);
				}}
				style={{ minWidth: "70%" }}
				header="Select program"
			>
				<div className="card modal-form-grid">
					{institutionState.isLoading ? (
						<div className="flex align-items-center justify-content-center">
							<ProgressSpinner
								style={{ width: "30px", height: "30px" }}
								strokeWidth="4"
								fill="var(--surface-ground)"
							/>
						</div>
					) : (
						<DataTable
							value={institutionState.data}
							responsiveLayout="scroll"
							dataKey="programId"
							breakpoint="960px"
							selection={selectedProducts}
							onSelectionChange={(e) =>
								setSelectedProducts(e.value)
							}
						>
							<Column
								selectionMode="multiple"
								headerStyle={{ width: "3rem" }}
							/>
							<Column field="name" header="ProgramName" />
							<Column
								field="institutionName"
								header="Institution Name"
							/>
							<Column
								field="lineOfBusiness"
								header="Line Of Business"
							/>
							<Column field="loanAmount" header="Loan Amount" />
							<Column field="loanPeriod" header="Loan Period" />

							<Column
								field="processingFee"
								header="Processing Fee"
							/>
							<Column field="RoI" header="Rate Of Interest" />
						</DataTable>
					)}
					<div className="flex align-items-center justify-content-end pt-2">
						<Button
							label="Add"
							disabled={selectedProducts?.length === 0}
							onClick={handleProgramAdd}
						/>
					</div>
				</div>
			</Dialog>
			<Dialog
				visible={programUpdatedialog}
				onHide={() => {
					setProgramUpdateDialog(false);
					setSelectedUpdateProgram("");
				}}
				style={{ minWidth: "70%" }}
				header="Change program"
			>
				<div className="card modal-form-grid">
					{institutionState.isLoading ? (
						<div className="flex align-items-center justify-content-center">
							<ProgressSpinner
								style={{ width: "30px", height: "30px" }}
								strokeWidth="4"
								fill="var(--surface-ground)"
							/>
						</div>
					) : (
						<DataTable
							value={institutionState.data}
							responsiveLayout="scroll"
							dataKey="programId"
							breakpoint="960px"
							selection={selectedUpdateProgram}
							onSelectionChange={(e) =>
								setSelectedUpdateProgram(e.value)
							}
						>
							<Column
								selectionMode={"single"}
								headerStyle={{ width: "3rem" }}
							/>
							<Column field="name" header="ProgramName" />
							<Column
								field="institutionName"
								header="Institution Name"
							/>
							<Column
								field="lineOfBusiness"
								header="Line Of Business"
							/>
							<Column field="loanAmount" header="Loan Amount" />
							<Column field="loanPeriod" header="Loan Period" />

							<Column
								field="processingFee"
								header="Processing Fee"
							/>
							<Column field="RoI" header="Rate Of Interest" />
						</DataTable>
					)}
					<div className="flex align-items-center justify-content-end pt-2">
						<Button
							label="Update"
							disabled={selectedProducts ? false : true}
							onClick={handleProgramUpdate}
						/>
					</div>
				</div>
			</Dialog>
			<ConfirmDialog />
			<Dialog
				header={"Note"}
				style={{ width: "70%" }}
				visible={noteDetailDialog.state}
				onHide={() =>
					setNoteDetailDialog((prev) => ({
						...prev,
						state: false,

						id: null,
					}))
				}
			>
				<Formik
					initialValues={{ notes: noteDetailDialog.note }}
					onSubmit={handleNoteSubmit}
				>
					{({ handleSubmit, resetForm }) => (
						<Form
							className="modal-form-grid border-round-md col-12 mt-2 p-4 form-grid grid"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 ">
								<Field
									required
									header={"Note"}
									name={"notes"}
									component={FieldTextArea}
								/>
							</div>
							<div className="col-12 flex align-items-end justify-content-end">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Save" />
							</div>
						</Form>
					)}
				</Formik>
				<DataTable className="mt-2" value={noteList}>
					<Column
						header={"Created By"}
						body={(item) =>
							(item?.createdByFirstName
								? item?.createdByFirstName
								: "") +
							" " +
							(item?.createdByLastName
								? item?.createdByLastName
								: "") +
							" ( " +
							(item?.createdByRole ? item?.createdByRole : "") +
							" )"
						}
					/>
					<Column
						header={"Application No."}
						field="applicationNumber"
					/>
					{/* <Column header={"Created By"} field="createdBy" /> */}
					<Column header={"Notes"} field="notes" />
					<Column
						header={"Created At"}
						body={(elm) =>
							elm?.createdAt
								? moment(elm?.createdAt).format(
										"Do MMM, YYYY HH:mm"
								  )
								: ""
						}
					/>
				</DataTable>
			</Dialog>
			<Dialog
				header={"Application Reject"}
				style={{ width: "40%" }}
				visible={rejectDialog.state}
				onHide={() => {
					setRejectDialog((prev) => ({
						...prev,
						state: false,
						id: null,
					}));
					setnoteList([]);
				}}
			>
				<Formik
					initialValues={{
						notes: rejectDialog.note,
						noteOptions: "",
					}}
					onSubmit={handleRejectApplication}
				>
					{({ handleSubmit, resetForm, values }) => (
						<Form
							className="modal-form-grid border-round-md col-12 mt-2 p-4 form-grid grid"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 ">
								<Field
									required
									header={"Note"}
									name={"noteOptions"}
									component={FeildDropdown}
									options={[
										{
											label: "Region not supported",
											value: "Region not supported",
										},
										{
											label: "Income too low",
											value: "Income too low",
										},
										{
											label: "Application Loan amount Low",
											value: "Application Loan amount Low",
										},
										{
											label: "BL file Without Registration proof",
											value: "BL file Without Registration proof",
										},
										{
											label: "Credit score low",
											value: "Credit score low",
										},
										{ label: "Other", value: "Other" },
									]}
								/>
							</div>
							{values.noteOptions === "Other" && (
								<div className="field col-12 ">
									<Field
										required
										header={"Note"}
										name={"notes"}
										component={FieldTextArea}
									/>
								</div>
							)}
							<div className="col-12 flex align-items-end justify-content-end">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Save" />
							</div>
						</Form>
					)}
				</Formik>
			</Dialog>
			<Dialog
				header={"Application Revert"}
				style={{ width: "40%" }}
				visible={revertDialog.state}
				onHide={() =>
					setRevertDialog((prev) => ({
						...prev,
						state: false,
						id: null,
					}))
				}
			>
				<Formik
					initialValues={{ notes: revertDialog.note }}
					onSubmit={handleRevertApplication}
				>
					{({ handleSubmit, resetForm }) => (
						<Form
							className="modal-form-grid border-round-md col-12 mt-2 p-4 form-grid grid"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 ">
								<Field
									required
									header={"Note"}
									name={"notes"}
									component={FieldTextArea}
								/>
							</div>
							<div className="col-12 flex align-items-end justify-content-end">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Save" />
							</div>
						</Form>
					)}
				</Formik>
			</Dialog>
			<Dialog
				visible={statusDialoge}
				header="Bank Application Bulk Status Upload"
				onHide={() => setStatusDialoge(false)}
				style={{ width: "60%" }}
			>
				<StatusBulkUpload
					handleStatusDialogeClose={handleStatusDialogeClose}
				/>
			</Dialog>
			<Dialog
				visible={statusState.dialog}
				style={{ width: "80%" }}
				header={(function (e) {
					switch (e) {
						case "status":
							return "Application Status Update";
						case "bank":
							return "Bank Status Update";

						default:
							return "";
					}
				})(statusState.type)}
				onHide={() =>
					statusDispatch({ type: "dialog", payload: false })
				}
			>
				{(function (e) {
					switch (e) {
						case "status":
							return (
								<div className=" modal-form-grid">
									<div className="py-4">
										{statusList.isLoading ? (
											<Skeleton className="w-5  block h-3rem" />
										) : (
											<Formik
												initialValues={{
													productStatusId: "",
													sanctionAmount:
														statusState.sanctionAmount,
													remarks: "",
												}}
												onSubmit={handleStatusSave}
												validationSchema={
													validationSchema
												}
											>
												{({
													handleSubmit,
													resetForm,
												}) => (
													<Form
														onSubmit={handleSubmit}
														className="grid form-grid col-12"
													>
														<div className="field col-12 md:col-3">
															<Field
																header={
																	"Select Status"
																}
																name={
																	"productStatusId"
																}
																component={
																	FeildDropdown
																}
																options={
																	statusList.data
																}
																optionLabel="statusName"
															/>
														</div>
														<div className="field col-12 md:col-3">
															<Field
																type="number"
																header={
																	"Sanction Amount"
																}
																name={
																	"sanctionAmount"
																}
																component={
																	FieldInput
																}
															/>
														</div>
														<div className="field col-12 md:col-3">
															<Field
																header={
																	"Remarks"
																}
																name={"remarks"}
																component={
																	FieldInput
																}
															/>
														</div>
														{/* <Dropdown
															value={
																statusState.setData
															}
															onChange={(e) =>
																statusDispatch({
																	type: "setData",
																	payload:
																		e.value,
																})
															}
															className="w-5 border-round-md"
															options={
																statusList.data
															}
															optionLabel="statusName"
															placeholder="Select Status"
														/> */}
														{/* <InputText
															type="number"
															value={
																statusState.sanctionAmount
															}
															onChange={(e) =>
																statusDispatch({
																	type: "sanctionAmount",
																	payload:
																		e.value,
																})
															}
															placeholder="Sanction Amount"
														/>
														<InputText
															value={
																statusState.sanctionAmount
															}
															required
															onChange={(e) =>
																statusDispatch({
																	type: "sanctionAmount",
																	payload:
																		e.value,
																})
															}
															placeholder="Remark"
														/> */}
														<div className="flex justify-content-end align-items-center col-12 md:col-3">
															<Button
																type="button"
																label="Reset"
																className="p-button-danger mr-2"
																onClick={
																	resetForm
																}
															/>
															<Button
																label="Save"
																type="submit"
																icon="pi pi-save"
																loading={
																	loading1
																}
															/>
														</div>
													</Form>
												)}
											</Formik>
										)}
									</div>

									{statusState.isLoading ? (
										<DataTable
											value={statusState.getHistory}
											responsiveLayout={"scroll"}
										>
											<Column
												body={skeletonBody}
												header="Application Number"
											/>
											<Column
												body={skeletonBody}
												header={"Status History"}
											/>
											<Column
												body={skeletonBody}
												header={"Remarks"}
											/>

											<Column
												body={skeletonBody}
												header="Created On"
											/>
										</DataTable>
									) : (
										statusState.fetched && (
											<>
												<DataTable
													paginator
													rows={10}
													value={
														statusState.getHistory
													}
													responsiveLayout={"scroll"}
												>
													<Column
														field="applicationNumber"
														header="Application Number"
													/>
													<Column
														body={
															statusHistoryTemplate
														}
														header={
															"Status History"
														}
													/>
													<Column
														field="remarks"
														header={"Remarks"}
													/>

													<Column
														header="Created On"
														body={(item) =>
															moment(
																item.createdOn
															).format(
																"Do MMM, HH:mm A"
															)
														}
													/>
												</DataTable>
											</>
										)
									)}
								</div>
							);
						case "bank":
							return (
								<>
									<Formik
										initialValues={{
											applicationId:
												statusState.applicationId,
											bankApplicationNumber: "",
										}}
										onSubmit={handleBankUpdate}
										enableReinitialize
									>
										{({ values, handleSubmit }) => (
											<Form
												className=" py-4 modal-form-grid gap-2 flex "
												onSubmit={handleSubmit}
											>
												<Field
													name="bankApplicationNumber"
													placeholder="Bank Application Number"
													component={FieldInput}
												/>

												<Button
													loading={
														statusState.isLoading
													}
													disabled={
														values.bankApplicationNumber ===
														""
															? true
															: false
													}
													label="Save"
													icon="pi pi-save"
													type={"submit"}
												/>
											</Form>
										)}
									</Formik>
								</>
							);

						default:
							return <></>;
					}
				})(statusState.type)}
			</Dialog>

			<Dialog
				visible={assignLead.state}
				onHide={() =>
					setAssignLead((prev) => ({
						...prev,
						id: null,
						state: false,
					}))
				}
				style={{ width: "30%" }}
				header={"Assign Operation Executive"}
			>
				<Formik onSubmit={handleOe} initialValues={{ oeUserName: "" }}>
					{({ handleSubmit, values }) => (
						<Form onSubmit={handleSubmit} className="pt-2">
							<Field
								name={"oeUserName"}
								component={FeildDropdown}
								options={allOEList}
								optionLabel={"name"}
								optionValue={"userName"}
							/>
							<Button
								type={"submit"}
								label="Save"
								disabled={values.oeUserName ? false : true}
								className="w-full mt-2"
							/>
						</Form>
					)}
				</Formik>
			</Dialog>
			<Toast ref={toast} />
			{/* <div className="col-12 flex align-items-center justify-content-between ">
					<h3 className="m-0">Loan Application List</h3>
					<Button label="reset" onClick={() => getLoanData()} />
				</div> */}

			{state.isLoading ? (
				<DataTable
					headerColumnGroup={headerGroup}
					value={lodarTblArray}
					responsiveLayout="scroll"
					breakpoint="960px"
				>
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
				</DataTable>
			) : state.data.data && state.data.data.length > 0 ? (
				<>
					<Paginator
						pageSize={state.data.pageSize}
						firstPage={state.data.firstPage}
						lastPage={state.data.lastPage}
						decrement={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.decrement,
							})
						}
						increment={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.increment,
							})
						}
						totalElements={state.data.totalElements}
						pagesizechange={(e) => {
							// let newPageNo = Math.ceil(
							// 	(state.data.pageSize * state.data.pageNo) /
							// 		e.target.value
							// );
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageSize,
								payload: e.target.value,
							});
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageNo,
								payload: 1,
							});
						}}
						pageNo={state.data.pageNo}
						totalPages={state.data.totalPages}
					/>
					<DataTable
						headerColumnGroup={headerGroup}
						value={
							state.data.data &&
							state.data.data.map((item, index) => ({
								...item,
								index:
									state.data.pageSize * state.data.pageNo -
									state.data.pageSize +
									1 +
									index,
							}))
						}
						responsiveLayout="scroll"
						breakpoint="960px"
					>
						<Column field="index" />
						<Column field="number" />
						<Column field={"bankApplicationNumber"} />
						<Column
							field={"loanAmount"}
							body={(item) =>
								item.loanAmount ? item.loanAmount : ""
							}
						/>
						<Column field="alias" />
						<Column
							body={(item) =>
								item?.programName
									? item?.programName
									: item?.productName
									? item?.productName
									: ""
							}
						/>
						<Column body={getName} />
						<Column field="mobile" />
						<Column body={createdByTemplate} />
						<Column body={dateTemplate} />

						<Column field="state" />
						<Column field="district" />
						<Column body={dateMTemplate} />
						<Column body={allocatedToTemplate} />
						<Column field="oeName" />
						<Column body={stateTemplate} />
						<Column body={statusTemplate} />
						<Column
							body={(item) => (
								<Button
									onClick={() => {
										setNoteDetailDialog((prev) => ({
											...prev,
											state: true,
											id: [item.id],
											note: item.notes,
										}));
										ccService
											.getApplicationNoteList(item.id)
											.then((res) => setnoteList(res));
									}}
									className={`icon-btn p-button-text ${
										item?.notes
											? "p-button-success"
											: "p-button-danger"
									}`}
									icon={"pi pi-info-circle"}
									tooltip={
										item?.notes ? item?.notes : "No Note"
									}
									tooltipOptions={{
										position: "left",
									}}
								/>
							)}
						/>
						<Column body={actionTemplate} />
					</DataTable>
					<Paginator
						pageSize={state.data.pageSize}
						firstPage={state.data.firstPage}
						lastPage={state.data.lastPage}
						decrement={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.decrement,
							})
						}
						increment={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.increment,
							})
						}
						totalElements={state.data.totalElements}
						pagesizechange={(e) => {
							// let newPageNo = Math.ceil(
							// 	(state.data.pageSize * state.data.pageNo) /
							// 		e.target.value
							// );
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageSize,
								payload: e.target.value,
							});
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageNo,
								payload: 1,
							});
						}}
						pageNo={state.data.pageNo}
						totalPages={state.data.totalPages}
					/>
				</>
			) : (
				<TableNoData headerGroup={headerGroup} />
			)}
		</>
	);
};

export default VendorLoanApplicationList;
