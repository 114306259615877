const TableDesign = (props) => {
	// console.log("props.data======", props.data);

	return (
		<div className="c-table-design-w">
			<div className="c-table-design-in">
				<div className="c-table-design-heading">
					<ul>
						{props?.data?.length > 0 &&
							Object.keys(props?.data[0])?.map(
								(item, index) =>
									item !== "id" &&
									item !== "assignedTo" && (
										<li
											style={{
												textTransform: "capitalize",
											}}
											key={index.toString()}
										>
											{item}
										</li>
									)
							)}
					</ul>
				</div>
				<div className="c-table-design-list flex-1">
					{props?.data?.map((item, index) => (
						<div
							key={index.toString()}
							className="c-table-design-list-box flex-1"
						>
							<ul>
								{Object.values(item)?.map(
									(elm, i) =>
										elm !== null && (
											<li key={i.toString()}>{elm}</li>
										)
								)}
							</ul>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default TableDesign;
