import { Divider } from "primereact/divider";
import moment from "moment";
import { useGetAllDIstrictQuery } from "../../../../services/query/queryApi";
import { getDistrict, getState } from "../../../../components/stateList";
import Config from "../../../../shared/config";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "primereact/button";
import Logo from "../../../../assets/images/logo_main.png";
const PreviewPdf = ({ pdfState }) => {
	// const { state: pdfState } = useLocation();
	const { data = [] } = useGetAllDIstrictQuery();
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});
	const getPageMargins = () => {
		return `
		@page { margin: ${40} ${40} ${40} ${40} !important; 
	}
		
		
		
		
		`;
	};
	console.log(pdfState);
	return (
		<>
			<Button
				label="Print this out"
				icon={"pi pi-print"}
				onClick={handlePrint}
			/>
			<div
				ref={componentRef}
				className="grid w-full p-4 align-items-center"
			>
				<style>{getPageMargins()}</style>
				<div className="col-6">
					<h2 className="mb-2 mt-0">
						{pdfState?.salutation +
							" " +
							pdfState?.firstName +
							" " +
							pdfState?.middleName +
							" " +
							pdfState?.lastName}
					</h2>
					<h4 className="mb-2 mt-0">{pdfState?.mobile}</h4>
					<small className="block font-bold mb-1">
						{pdfState?.designation}
					</small>
					<small className="block">{pdfState?.companyName}</small>
				</div>
				<div className="col-6">
					<img
						src={Logo}
						alt="logo"
						style={{
							width: "150px",
							marginLeft: "auto",
							display: "block",
						}}
					/>
				</div>

				<div className="col-12 grid">
					<Divider align={"left"}>
						<h3 className="col-12 m-0">Application Details</h3>
					</Divider>
					<div className="col-3">
						<h5 className="mb-0 ">Application Number</h5>
						<small>{pdfState?.number}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Application Type</h5>
						<small>{pdfState?.applicationType}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Application Date</h5>
						<small>
							{pdfState?.application_date !== ""
								? moment(pdfState?.application_date).format(
										"Do MMM, YYYY"
								  )
								: ""}
						</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0 ">Amount</h5>
						<small>{pdfState?.loanAmount}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Tenure</h5>
						<small>{pdfState?.loanTenure}</small>
					</div>
					<div className="col-3"></div>
					<div className="col-3"></div>
				</div>
				<div className="col-12 grid">
					<Divider>
						<h3 className="col-12 m-0">Personal Details</h3>
					</Divider>
					<div className="col-3">
						<h5 className="mb-0">Father’s Name</h5>
						<small>{pdfState?.fatherName}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Mother's Maiden Name</h5>
						<small>{pdfState?.motherMaidenName}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Marital Status</h5>
						<small>{pdfState?.maritalStatus}</small>
						{pdfState?.maritalStatus === "Married" && (
							<>
								<h5 className="mb-0">Spouse Name</h5>
								<small>{pdfState?.spouseName}</small>
							</>
						)}
					</div>
					<div className="col-3">
						<h5 className="mb-0">Caste</h5>
						<small>{pdfState?.category}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Whatsapp Number</h5>
						<small>{pdfState?.whatsappNumber}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">PAN</h5>
						<small>{pdfState?.pan}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Gender</h5>
						<small>{pdfState?.gender}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">D.O.B</h5>
						<small>
							{pdfState?.dob !== ""
								? moment(pdfState?.dob).format("Do MMM, YYYY")
								: ""}
						</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Age</h5>
						<small>{pdfState?.age}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Religion</h5>
						<small>{pdfState?.religion}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Personal Mail</h5>
						<small>{pdfState?.personalMail}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Driving Licence</h5>
						<small>{pdfState?.drivingLicence}</small>
					</div>
				</div>
				<div className="col-12 grid pageBrake">
					<Divider>
						<h3 className="col-12 m-0">Present Address</h3>
					</Divider>
					<div className="col-3">
						<h5 className="mb-0 ">Address Line 1</h5>
						<small>{pdfState?.addressLine1}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Address Line 2</h5>
						<small>{pdfState?.addressLine2}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Address Line 3</h5>
						<small>{pdfState?.addressLine3}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">State</h5>
						{data && (
							<small>
								{pdfState?.state &&
									getState(data, pdfState?.state)()?.name}
							</small>
						)}
					</div>
					<div className="col-3">
						<h5 className="mb-0 ">Building Name</h5>
						<small>{pdfState?.buildingName}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Landmark</h5>
						<small>{pdfState?.landmark}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">City</h5>
						<small>{pdfState?.city}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">District</h5>
						{data && (
							<small>
								{pdfState?.districtId &&
									getState(data, pdfState?.state)() &&
									getDistrict(
										getState(data, pdfState?.state)()
											?.districtList,
										pdfState?.districtId
									)()?.name}
							</small>
						)}
					</div>
				</div>
				<div className="col-12 grid">
					<Divider>
						<h3 className="col-12 m-0">Permanent Address</h3>
					</Divider>
					<div className="col-3">
						<h5 className="mb-0 ">Address Line 1</h5>
						<small>{pdfState?.addressLine1}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Address Line 2</h5>
						<small>{pdfState?.addressLine2}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Address Line 3</h5>
						<small>{pdfState?.addressLine3}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0 ">Building Name</h5>
						<small>{pdfState?.buildingName}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Landmark</h5>
						<small>{pdfState?.landmark}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">City</h5>
						<small>{pdfState?.city}</small>
					</div>
				</div>
				<div className="col-12 grid pageBrake">
					<Divider>
						<h3 className="col-12 m-0">Occupation Details</h3>
					</Divider>
					<div className="col-3">
						<h5 className="mb-0 ">Highest Qualification</h5>
						<small>
							{pdfState?.industryType !== ""
								? Config?.QUALIFICATION?.find(
										(item) =>
											item?.value ===
											pdfState?.highestQualification
								  )?.label
								: ""}
						</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Profession Type</h5>
						<small>
							{pdfState?.industryType !== ""
								? Config?.OCCUPATION?.find(
										(item) =>
											item?.value ===
											pdfState?.occupationType
								  )?.label
								: ""}
						</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Company Type</h5>
						<small>
							{pdfState?.industryType !== ""
								? Config?.ORGANIZATION?.find(
										(item) =>
											item?.value ===
											pdfState?.companyType
								  )?.label
								: ""}
						</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Official Mail</h5>
						<small>{pdfState?.officialMail}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Extension</h5>
						<small>{pdfState?.extension}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0 ">
							Company Registered Office Address
						</h5>
						<small>
							{pdfState?.companyRegisteredOfficeAddress}
						</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Company Name</h5>
						<small>{pdfState?.companyName}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Designation</h5>
						<small>{pdfState?.designation}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Office Telephone Number</h5>
						<small>{pdfState?.officeTelephoneNumber}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Industry Type</h5>
						<small>
							{pdfState?.industryType !== ""
								? Config?.INDUSTRY?.find(
										(item) =>
											item?.value ===
											pdfState?.industryType
								  )?.label
								: ""}
						</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Company Website</h5>
						<small>{pdfState?.companyWebsite}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">
							Experience In Months In Current Company
						</h5>
						<small>
							{
								pdfState?.totalYearExperienceInMonthsCurrentCompany
							}
						</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">
							Total Year Experience In Months
						</h5>
						<small>{pdfState?.totalYearExperienceInMonths}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Total Year Experience</h5>
						<small>{pdfState?.totalYearExperience}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Net Monthly Income/Salary</h5>
						<small>{pdfState?.netMonthlyIncome}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Annual Turnover</h5>
						<small>{pdfState?.annualTurnover}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">office Address </h5>
						<small>
							{pdfState?.officeAddressLine1 +
								pdfState?.officeAddressLine2 +
								pdfState?.officeAddressLine3}
						</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Landmark</h5>
						<small>{pdfState?.officeLandmark}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">City</h5>
						<small>{pdfState?.officeCity}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">State</h5>
						<small>
							{pdfState?.officeState &&
								getState(data, pdfState?.officeState)()?.name}
						</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Pincode</h5>
						<small>{pdfState?.officePincode}</small>
					</div>
				</div>
				<div className="col-12 grid pageBrake">
					<Divider>
						<h3 className="col-12 m-0">Reference Details</h3>
					</Divider>
					<h4 className="col-12 mb-0">Reference 1</h4>
					<div className="col-3">
						<h5 className="mb-0 ">Name</h5>
						<small>{pdfState?.reference1Name}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Relationship</h5>
						<small>{pdfState?.reference1Relation}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Mobile</h5>
						<small>{pdfState?.reference1Mobile}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Address</h5>
						<small>{pdfState?.reference1Address}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">City</h5>
						<small>{pdfState?.referenceCity}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">State</h5>
						<small>
							{pdfState?.reference1State &&
								getState(data, pdfState?.reference1State)()
									?.name}
						</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Pincode</h5>
						<small>{pdfState?.referencePincode}</small>
					</div>
					<h4 className="col-12 mb-0">Reference 2</h4>
					<div className="col-3">
						<h5 className="mb-0 ">Name</h5>
						<small>{pdfState?.reference2Name}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Relationship</h5>
						<small>{pdfState?.reference2Relation}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Mobile</h5>
						<small>{pdfState?.reference2Mobile}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Address</h5>
						<small>{pdfState?.reference2Address}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">City</h5>
						<small>{pdfState?.reference2City}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">State</h5>
						<small>
							{pdfState?.reference2State &&
								getState(data, pdfState?.reference2State)()
									?.name}
						</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Pincode</h5>
						<small>{pdfState?.reference2Pincode}</small>
					</div>
				</div>
				<div className="col-12 grid">
					<Divider>
						<h3 className="col-12 m-0">Bank Details</h3>
					</Divider>
					<div className="col-3">
						<h5 className="mb-0 ">Account Holder Name</h5>
						<small>{pdfState?.bankAccountHolderName}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Account Type</h5>
						<small>{pdfState?.accountType}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Account Number</h5>
						<small>{pdfState?.bankAccountNumber}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Bank Name</h5>
						<small>{pdfState?.bankName}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Bank Ifsc Code</h5>
						<small>{pdfState?.bankIfscCode}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Branch</h5>
						<small>{pdfState?.bankBranch}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Bank Account Opened In</h5>
						<small>
							{pdfState?.bankAccountOpenedIn !== ""
								? moment(pdfState?.bankAccountOpenedIn)?.format(
										"Do MMM, YYYY"
								  )
								: ""}
						</small>
					</div>
					<h4 className="col-12 mb-0">Existing Loan Details</h4>
					<div className="col-3">
						<h5 className="mb-0 ">Account Number</h5>
						<small>{pdfState?.existingLoanAccountNumber}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Bank Name</h5>
						<small>{pdfState?.existingLoanBankName}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Loan Tenure</h5>
						<small>{pdfState?.existingLoanTenure}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">EMI</h5>
						<small>{pdfState?.emi}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">EMI Start Date</h5>
						<small>{pdfState?.emiStartDate}</small>
					</div>
				</div>
			</div>
			<Button
				label="Print this out"
				icon={"pi pi-print"}
				onClick={handlePrint}
			/>
		</>
	);
};

export default PreviewPdf;
