import React, { useRef, useState } from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
} from "../../../../../components/FieldInput";
import * as Yup from "yup";
import { ProgramService } from "../../../../../services/ProgramService";
import { useDispatch, useSelector } from "react-redux";
import { setEnable } from "../../../../../store/reducer/ProgramReducer";
import moment from "moment";
import Loader from "../../../../../components/Loader";

const CommisionStructure = (props) => {
	const locationData = props?.locationData;

	const toastTL = useRef(null);
	const programService = new ProgramService();
	const programId = useSelector((state) => state.program.programId);
	const dispatch = useDispatch();
	const [spinner, setSpinner] = useState(false);
	const initialValue = {
		commissionType: "percentage",
		startDate: "",
		endDate: "",
		programCommissionDetailsDtos: [
			{
				minAmount: "",
				maxAmount: "",
				amount: "",
			},
		],
	};
	// console.log(state);
	// const validationSchema = Yup.object().shape({
	// 	commissionType: Yup.string().required("This field is required"),
	// 	startDate: Yup.date().required("This field is required"),
	// 	endDate: Yup.date()
	// 		.when("startDate", (startDate, value) => {
	// 			return value.test({
	// 				test: (endDate) =>
	// 					!!new Date(startDate) &&
	// 					new Date(endDate) > new Date(startDate),
	// 				message: "Invalid date",
	// 			});
	// 		})
	// 		.required("This field is required"),
	// 	programCommissionDetailsDtos: Yup.array().of(
	// 		Yup.object().shape({
	// 			minAmount: Yup.number()
	// 				.typeError("Value must be a number")
	// 				.min(0, "Invalid Amount")
	// 				.required("This field is required"),
	// 			maxAmount: Yup.number()
	// 				.typeError("Value must be a number")
	// 				.min(0, "Invalid Amount")
	// 				.when("minAmount", (minAmount, value) => {
	// 					return value.test({
	// 						test: (maxAmount) =>
	// 							!!minAmount && maxAmount > minAmount,
	// 						message: "Max sell amount > Min sell amount",
	// 					});
	// 				})

	// 				.required("This field is required"),
	// 			amount: Yup.number().required("This field is required"),
	// 		})
	// 	),
	// });
	const validationSchema = Yup.object().shape({
		commissionType: Yup.string().required("This field is required"),
		startDate: Yup.date().required("This field is required"),
		endDate: Yup.date()
			.when("startDate", (startDate, value) => {
				return value.test({
					test: (endDate) =>
						!!new Date(startDate) &&
						new Date(endDate) > new Date(startDate),
					message: "Invalid date",
				});
			})
			.required("This field is required"),
		programCommissionDetailsDtos: Yup.array().of(
			Yup.object().shape({
				minAmount: Yup.number()
					.min(0, "Invalid Amount")
					.required("This field is required"),
				maxAmount: Yup.number()
					.min(0, "Invalid Amount")
					.when(["minAmount"], (min, value) => {
						return value.test({
							test: (max) =>
								(!!min && max > min) || !Boolean(max),
							message: "Max amount > Min amount",
						});
					})
					.nullable(),
				// amount: Yup.number()
				// 	.when(["minAmount", "maxAmount"], (min, max, value) => {
				// 		return value.test({
				// 			test: (def) => {
				// 				return (
				// 					!!min && !!max && max >= def && def >= min
				// 				);
				// 			},
				// 			message: "Max > Def > Min ",
				// 		});
				// 	})
				// 	.required("This field is required"),
				amount: Yup.number().required("This field is required"),
			})
		),
	});
	const handleFormSubmit = (value, onSubmitProps) => {
		setSpinner(true);
		const { startDate, endDate, commissionType } = value;
		const body = {
			programId:
				locationData?.type === "edit"
					? locationData?.programId
					: programId,
			commissionType: commissionType,
			startDate: moment(startDate).format("YYYY-MM-DD"),
			endDate: moment(endDate).format("YYYY-MM-DD"),
			programCommissionDetailsDtos: value.programCommissionDetailsDtos,
		};
		if (locationData?.type === "edit" && locationData?.programId) {
		}
		programService
			.addCommission(body)
			.then(() => {
				dispatch(setEnable(2));
			})

			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				onSubmitProps.resetForm();
				setSpinner(false);
			});
	};

	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			{spinner && <Loader />}
			<Formik
				initialValues={initialValue}
				onSubmit={handleFormSubmit}
				validationSchema={validationSchema}
				enableReinitialize
			>
				{({ values, handleSubmit }) => (
					<Form className="" onSubmit={handleSubmit}>
						<div className="form-grid grid">
							<div className="field col-12 md:col-4">
								<Field
									name={`startDate`}
									component={FeildCalender}
									header={"Effective Start Date"}
									minDate={new Date()}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									name={`endDate`}
									component={FeildCalender}
									header={"Effective End Date"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									name={`commissionType`}
									component={FeildDropdown}
									header={"Commission Type"}
									options={[
										{
											label: "Fixed",
											value: "fixed",
										},
										{
											label: "Percentage",
											value: "percentage",
										},
									]}
									star={"*"}
								/>
							</div>
						</div>
						<FieldArray
							name="programCommissionDetailsDtos"
							render={(arrayHelper) => (
								<>
									{values.programCommissionDetailsDtos &&
									values.programCommissionDetailsDtos.length >
										0
										? values.programCommissionDetailsDtos.map(
												(item, index) => (
													<div
														className="form-grid grid"
														key={
															index.toString() +
															"mapValueCommision"
														}
													>
														<div className="field col-12 md:col-4">
															<Field
																name={`programCommissionDetailsDtos.${index}.minAmount`}
																component={
																	FieldInput
																}
																header={
																	"Min Sell Amount"
																}
																star={"*"}
															/>
														</div>
														<div className="field col-12 md:col-3">
															<Field
																name={`programCommissionDetailsDtos.${index}.maxAmount`}
																component={
																	FieldInput
																}
																header={
																	"Max Sell Amount"
																}
																star={"*"}
															/>
														</div>

														<div className="field col-12 md:col-4">
															<Field
																header={`Commission:(${
																	values.type ===
																	"fixed"
																		? "Fixed Amount"
																		: "Percentage"
																}) `}
																name={`programCommissionDetailsDtos.${index}.amount`}
																component={
																	FieldInput
																}
															/>
														</div>

														<div className="col-1 pt-5">
															{values
																.programCommissionDetailsDtos
																.length ===
															index + 1 ? (
																<Button
																	type="button"
																	icon="pi pi-plus"
																	className="p-button-success"
																	onClick={() =>
																		arrayHelper.push(
																			{
																				minAmount:
																					"",
																				maxAmount:
																					"",
																				amount: "",
																			}
																		)
																	}
																/>
															) : (
																<Button
																	type="button"
																	onClick={() =>
																		arrayHelper.remove(
																			index
																		)
																	}
																	icon="pi pi-minus"
																	className="p-button-danger p-button-sm md:col-5 "
																	aria-label="Cancel"
																/>
															)}
														</div>
													</div>
												)
										  )
										: null}
								</>
							)}
						/>
						<div className="flex justify-content-end align-items-center w-full mt-4">
							<Button
								type="cancel"
								label="Cancel"
								className="p-button-danger mr-2"
								style={{
									borderRadius: "6px",
									width: "10rem",
								}}
							/>
							<Button
								type="submit"
								label="Save"
								style={{ borderRadius: "6px" }}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default CommisionStructure;
