import { useEffect, useReducer, useRef, useState } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import TableLoader from "../../../../components/TableLoader";
import Loader from "../../../../components/Loader";

import Paginator from "../../../../components/Paginator";
import { DataTable } from "primereact/datatable";
import {
	searchInitialState,
	searchReducer,
} from "../../../../components/searchReducer";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";
import Config from "../../../../shared/config";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Button } from "primereact/button";
import SearchTransaction from "./components/SearchPayment";
import { PaymentService } from "../../../../services/paymentService";
import moment from "moment";
import { convertNullToString } from "../../../../utils/UtilsFunction";
import { Column } from "primereact/column";
const PaymentDetails = () => {
	const toast = useRef(null);
	const paymentService = new PaymentService();
	const [paymentState, paymentDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const [spinner, setSpinner] = useState(false);
	const itaration = {
		institutionId: "",
		productId: "",
		subproductId: "",
		name: "",
		status: "",
		action: "",
	};
	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	function tblSort(columnName, sortType) {
		// setSortByColumn(columnName);
		// setSortType(sortType);

		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="Application Number" />
				<Column header="Transaction Id" />
				<Column header="Amount" />
				<Column header="Txn Date" />
				<Column header="Debit" />
				<Column header="Credit" />
				<Column header="Bank Name" />
				<Column header="Payment Status" />
			</Row>
		</ColumnGroup>
	);
	const approvedPayment = (e) => {
		paymentService
			.paymentApproved(e)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "Payment Approved Successfully",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setSpinner(false);
				getPaymentData();
			});
	};
	const reject = () => {
		toast.current.show({
			severity: "warn",
			summary: "Rejected",
			detail: "Rejected",
			life: 3000,
		});
	};

	const showConfirm = (id) => {
		confirmDialog({
			message: "Are you sure you want to proceed?",
			header: "Confirmation",
			icon: "pi pi-exclamation-triangle",
			accept: () => approvedPayment(id),
			reject: () => reject(),
		});
	};
	const actionTemplate = (item) => {
		return (
			<>
				{/* {item.isApproved === true ? (
					// <Button
					// 	icon="pi pi-verified"
					// 	style={{ height: "20px", backgroundColor: "#50C878" }}
					// />
					<Button
						className="icon-btn success-btn"
						tooltip="Payment Approved Successfully"
						tooltipOptions={{ position: "bottom" }}
					>
						<i className="pi pi-verified"></i>
					</Button>
				) : (
					<Button
						className="icon-btn primary-btn"
						tooltip="Payment Status Change"
						tooltipOptions={{ position: "bottom" }}
						onClick={() => {
							showConfirm(item.id);
						}}
					>
						<AiOutlineFileAdd />
					</Button>
					// <Button
					// 	icon="pi pi-pencil"
					// 	style={{ height: "20px", backgroundColor: "#FF5733" }}
					// 	onClick={() => {
					// 		showConfirm(item.id);
					// 	}}
					// />
				)} */}

				{item.credit && (
					<Button
						className="icon-btn success-btn"
						tooltip="Payment Approved Successfully"
						tooltipOptions={{ position: "bottom" }}
					>
						<i className="pi pi-verified"></i>
					</Button>
				)}
			</>
		);
	};
	const getPaymentData = () => {
		setSpinner(true);
		const body = {
			...searchState.searchValue,
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.sortByColumn &&
				searchState.sortType && {
					sorts: [
						searchState.sortByColumn + "," + searchState.sortType,
					],
				}),
		};
		const demo = Object.fromEntries(
			Object.entries(body).filter(([_, v]) => v !== "")
		);
		// paymentState({ type: Config.FETCH_CONFIG.start });
		paymentService
			.getPaymentList(demo)
			.then((res) => {
				const newData = convertNullToString(res);
				paymentDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: newData,
				});
			})
			.finally(() => setSpinner(false));
	};
	const fromTxnDate = (item) => {
		return (
			<>{item.txnDate && moment(item.txnDate).format("Do MMM, YYYY")}</>
		);
	};
	const toTxnDate = (item) => {
		return <>{moment(item.toTxnDate).format("Do MMM, YYYY")}</>;
	};
	useEffect(() => {
		getPaymentData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);
	return (
		<>
			<Toast ref={toast} />
			<SearchTransaction searchDispatch={searchDispatch} />
			<ConfirmDialog />
			{paymentState.isLoading ? (
				<TableLoader itaration={itaration} headerGroup={headerGroup} />
			) : (
				<>
					{spinner && <Loader />}
					<Paginator
						pageSize={paymentState.data.pageSize}
						firstPage={paymentState.data.firstPage}
						lastPage={paymentState.data.lastPage}
						decrement={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.decrement,
							})
						}
						increment={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.increment,
							})
						}
						pagesizechange={(e) => {
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageSize,
								payload: e.target.value,
							});
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageNo,
								payload: 1,
							});
						}}
						totalElements={paymentState.data.totalElements}
						pageNo={paymentState.data.pageNo}
						totalPages={paymentState.data.totalPages}
					/>

					<DataTable
						// value={elem.programListDtoList}
						headerColumnGroup={headerGroup}
						value={paymentState.data.data}
						responsiveLayout="scroll"
					>
						<Column field={"applicationNumber"} />
						<Column field={"transactionId"} />
						<Column field={"amount"} />
						<Column body={fromTxnDate} />
						<Column field={"debit"} />
						<Column field={"credit"} />
						<Column field={"bankName"} />
						<Column body={actionTemplate} />
					</DataTable>
					<Paginator
						pageSize={paymentState.data.pageSize}
						firstPage={paymentState.data.firstPage}
						lastPage={paymentState.data.lastPage}
						decrement={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.decrement,
							})
						}
						increment={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.increment,
							})
						}
						pagesizechange={(e) => {
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageSize,
								payload: e.target.value,
							});
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageNo,
								payload: 1,
							});
						}}
						totalElements={paymentState.data.totalElements}
						pageNo={paymentState.data.pageNo}
						totalPages={paymentState.data.totalPages}
					/>
				</>
			)}
		</>
	);
};

export default PaymentDetails;
