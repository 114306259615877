import { useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { FeildDropdown, FieldInput } from "../../../../components/FieldInput";
import { Button } from "primereact/button";
import Config from "../../../../shared/config";
import * as Yup from "yup";
import { SplitButton } from "primereact/splitbutton";
import { exportExcel } from "../../../../components/XlsxExport";
import { UsersService } from "../../../../services/UsersService";
import { Toast } from "primereact/toast";
import Loader from "../../../../components/Loader";

import { useSelector } from "react-redux";
const SearchComponent = (props) => {
	const selector = useSelector((state) => state.persistData.auth.user.role);
	const toastTL = useRef(null);
	const userService = new UsersService();
	const [loader, setLoader] = useState(false);
	const [open, setOpen] = useState(false);
	const initialValues = {
		userName: "",
		firstName: "",
		lastName: "",
		middleName: "",
		emailId: "",
		roleId: "", //number
		gender: "",
		mobileNumber: "",
	};
	const validationSchema = Yup.object().shape({
		emailId: Yup.string().trim().email("Invalid email").nullable(),
		mobileNumber: Yup.string()
			.matches(
				/^[6-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable(),
	});
	const handleSearch = (value) => {
		const { userName, firstName, lastName, middleName, ...rest } = value;
		const newData = {
			...rest,
			userName: userName.trim(),
			firstName: firstName.trim(),
			lastName: lastName.trim(),
			middleName: middleName.trim(),
		};
		let data = Object.fromEntries(
			Object.entries(newData).filter(([_, v]) => v !== "")
		);

		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: data,
		});
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};
	const getAllLoanData = () => {
		const requestBody = {
			pageNo: 1,
			pageSize: 100000,
		};

		setLoader(true);
		userService
			.getUser(requestBody)
			.then((res) => {
				const newData =
					res.data &&
					res.data.map((elm) => ({
						Id: elm.id,
						"Employee Id": elm.employeeId,
						"Role Name": elm.roleName,
						// "Role Id": elm.roleId,
						Position: elm.position,
						Username: elm.userName,
						"User Type": elm.userType,
						Name:
							(elm.firstName ? elm.firstName : "") +
							" " +
							(elm.middleName ? elm.middleName : "") +
							" " +
							(elm.lastName ? elm.lastName : ""),

						"Email Id": elm.emailId,
						"Created On": elm.createdOn,
						Gender: elm.gender,
						"Mobile Number": elm.mobileNumber,
						"Parent Id": elm.parentId,
						"User Status": elm.isActive ? "Active" : "Disable",
					}));
				exportExcel(newData, "All_Users_List");
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoader(false));
	};
	const items = [
		{
			label: "Export",
			icon: "pi pi-file-excel",
			command: () => {
				exportExcel(props.newData, "User_List");
			},
		},
		{
			label: "Export All",
			icon: "pi pi-file-excel",
			command: () => {
				getAllLoanData();
			},
		},
	];
	return (
		<>
			{loader && <Loader />}
			<Toast ref={toastTL} />
			<div className="w-full py-3 flex align-items-center justify-content-between">
				<h3 className="m-0">All Users List</h3>
				<div className="flex gap-2">
					<Button
						label={open ? "Close" : "Search"}
						icon={open ? "pi pi-times" : "pi pi-search"}
						className={
							open
								? "p-button-outlined p-button-danger"
								: "p-button-outlined"
						}
						aria-label="Cancel"
						onClick={() => setOpen((prev) => !prev)}
					/>
					<Button
						label="Reload"
						icon="pi pi-refresh"
						className="p-button-outlined"
						onClick={() => {
							props.searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							});
						}}
					/>
					{selector?.position === null &&
						selector?.position !== 5000 && (
							<Button
								label="Add Users"
								icon="pi pi-plus"
								className="primary-btn"
								onClick={() => {
									props.addMemberClick();
								}}
							/>
						)}
					<SplitButton
						icon={"pi pi-file-export"}
						label="Export"
						model={items}
						// loading={spinner}
					/>
				</div>
			</div>

			{open && (
				<Formik
					initialValues={initialValues}
					enableReinitialize
					onSubmit={handleSearch}
					validationSchema={validationSchema}
				>
					{({ handleSubmit, resetForm }) => (
						<Form
							className="background-secondary p-4 border-round-md my-3 form-grid grid"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 md:col-3">
								<Field
									header={"Username"}
									name={"userName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"First Name"}
									name={"firstName"}
									component={FieldInput}
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									header={"Middle Name"}
									name={"middleName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Last Name"}
									name={"lastName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Email Id"}
									name={"emailId"}
									component={FieldInput}
									type={"email"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Role ID"}
									name={"roleId"}
									component={FeildDropdown}
									options={props.role.map((item) => ({
										label: item.name,
										name: item.name,
										value: item.id,
									}))}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Gender"}
									name={"gender"}
									component={FeildDropdown}
									options={Config.GENDERS}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"User Status"}
									name={"isActive"}
									component={FeildDropdown}
									options={[
										{ label: "Active", value: true },
										{ label: "Disable", value: false },
									]}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Mobile Number"}
									name={"mobileNumber"}
									component={FieldInput}
								/>
							</div>
							<div className="col-12 flex align-items-end justify-content-end">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Search" />
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default SearchComponent;
