import React, { useEffect, useReducer, useRef, useState } from "react";
import Header from "../../../partners/institutions/header";
import { produce } from "immer";
import CampaignDetails from "./CampaignDetails";
import DemograpicFilter from "./DemograpicFilter";
import ProductHistory from "./ProductHistory";
import { useParams } from "react-router-dom";
import parallel from "async/parallel";
import { CampaignService } from "../../../../services/CampaignService";
import {
	useGetAllDIstrictQuery,
	useGetProductDetailsQuery,
} from "../../../../services/query/queryApi";
import { Toast } from "primereact/toast";
import Loader from "../../../../components/Loader";
const AddCampaign = () => {
	const { id } = useParams();
	const toast = useRef(null);
	const { data = { data: [] }, isSuccess } = useGetProductDetailsQuery({
		searchRequest: [],
		pageNo: 1,
		pageSize: 10000,
		sorts: [],
	});
	const { data: stateList = [], isSuccess: stateSuccess } =
		useGetAllDIstrictQuery();
	const [programList, setProgramList] = useState([]);
	const campaignService = new CampaignService();
	const initialState = {
		stage: [
			{
				sequence: 1,
				title: "Campaign details",
				status: "active",
			},
			{
				sequence: 2,
				title: "Demographic filtration criteria",
				status: "inactive",
			},
			{
				sequence: 3,
				title: "Product/Subproduct purchase history",
				status: "inactive",
			},
		],
		active: 1,
		campaignId: null,
	};

	const stageReducer = produce((draft, action) => {
		switch (action.type) {
			case "stage":
				const newData = draft.stage.map((item) =>
					item.sequence === action.payload.sequence
						? { ...item, status: action.payload.status }
						: item
				);
				draft.stage = newData;
				break;
			case "active":
				draft.active = action.active;
				break;
			case "id":
				draft.campaignId = action.id;
				break;
			default:
				return draft;
		}
	});
	const reducer = produce((draft, action) => {
		switch (action.type) {
			case "details":
				draft.details = action.details;
				break;
			case "demo":
				draft.demo = action.demo;
				break;
			case "history":
				draft.history = action.history;
				break;
			case "loader":
				draft.loader = action.loader;
				break;
			case "fetched":
				draft.fetched = true;
				break;
			default:
				return draft;
		}
	});
	const [stages, setStages] = useReducer(stageReducer, initialState);
	const [dataState, dataDispatch] = useReducer(reducer, {
		details: {},
		demo: {},
		history: {},
		loader: false,
		fetched: false,
	});
	const getData = (e) => {
		dataDispatch({ type: "loader", loader: true });
		parallel([
			function (cb) {
				campaignService
					.getCampaign(e)
					.then((res) => {
						cb(null, res);
						// 	const subproducts =
						// 		res.campaignSubProductDetailsDtos.map(
						// 			(x) => x.originId
						// 		);
						// campaignService.getProgramList(subproducts).then((xx) => {
						// 	const newProgram = xx.flatMap((yy) => yy.programs);

						// });
					})
					.catch((er) => cb(er));
			},
			function (cb) {
				campaignService
					.getDemographicData(e)
					.then((res) => cb(null, res))
					.catch((er) => cb(er));
			},
			function (cb) {
				campaignService
					.getPurchaseHistory(e)
					.then((res) => cb(null, res))
					.catch((er) => cb(er));
			},
		])
			.then((result) => {
				dataDispatch({ type: "fetched" });
				const {
					startDate,
					endDate,
					campaignModeDetailsDtoList,
					campaignProductDetailsDtos,
					campaignSubProductDetailsDtos,
					campaignProgramDetailsDtos,
					frequency,
					...rest
				} = result[0];
				//part 1
				const campaignModeDetailsDtoList1 =
					campaignModeDetailsDtoList.map((x) => ({
						campaignMode: x.campaignMode,
					}));
				const campaignProductDetailsDtos1 = data.data.filter((item) =>
					campaignProductDetailsDtos.some(
						(elm) => elm.originId === item.id
					)
				);
				const campaignSubProductDetailsDtos1 =
					campaignProductDetailsDtos1
						.flatMap((elm) => elm.subProducts)
						.filter((xx) =>
							campaignSubProductDetailsDtos.some(
								(elm) => elm.originId === xx.id
							)
						);
				const campaignProgramDetailsDtos1 =
					campaignProgramDetailsDtos.map((elm) => elm.programId);
				const newDetails = {
					...rest,
					frequency: frequency === null ? [] : frequency,
					startDate: startDate ? new Date(startDate) : "",
					endDate: endDate ? new Date(endDate) : "",
					campaignModeDetailsDtoList: campaignModeDetailsDtoList1,
					campaignProductDetailsDtos: campaignProductDetailsDtos1,
					campaignSubProductDetailsDtos:
						campaignSubProductDetailsDtos1,
					campaignProgramDetailsDtos: campaignProgramDetailsDtos1,
				};
				// part -2
				const {
					id,
					residenceType,
					state,
					district,
					pincode,
					minAge,
					maxAge,
					maritalStatus,
					gender,
					minMonthlyIncome,
					maxMonthlyIncome,
					qualifications,
					professions,
					typeOfCompany,
				} = result[1];
				const newState = stateList.filter((xx) =>
					state.some((yy) => yy.stateId === xx.id)
				);

				const newData2 = {
					id: id,
					state: newState,
					district: district.map((x) => x.districtId),
					pincode: pincode.map((elm) => elm.pincode),
					age: [minAge ? minAge : 18, maxAge ? maxAge : 60],
					maritalStatus: maritalStatus.map((x) => x.maritalStatus),
					qualifications: qualifications.map(
						(y) => y.qualificationStatus
					),
					gender: gender.map((z) => z.gender),
					monthlyIncome: [
						minMonthlyIncome ? minMonthlyIncome / 1000 : 10,
						maxMonthlyIncome ? maxMonthlyIncome / 1000 : 50,
					],
					professions: professions.map((x) => x.profession),
					typeOfCompany: typeOfCompany.map((y) => y.companyType),
					residenceType: residenceType.map((z) => z.residence),
				};

				//part 3
				const {
					includeProductList,
					excludeProductList,
					excludeSubProductList,
					includeSubProductList,
					includeBuyType,
					excludeBuyType,
					...restData
				} = result[2];
				const includeProduct = data.data.filter((item) =>
					includeProductList.some((elm) => elm.originId === item.id)
				);
				const includeSubProduct = includeProduct
					.flatMap((elm) => elm.subProducts)
					.filter((xx) =>
						includeSubProductList.some(
							(elm) => elm.originId === xx.id
						)
					);
				const excludeProduct = data.data.filter((item) =>
					excludeProductList.some((elm) => elm.originId === item.id)
				);
				const excludeSubProduct = excludeProduct
					.flatMap((elm) => elm.subProducts)
					.filter((xx) =>
						excludeSubProductList.some(
							(elm) => elm.originId === xx.id
						)
					);

				const newData = {
					...restData,
					...(restData.includeTypeSelect === "BOUGHT"
						? { includeBuyType1: includeBuyType }
						: { includeBuyType2: includeBuyType }),
					...(restData.excludeTypeSelect === "BOUGHT"
						? { excludeBuyType1: excludeBuyType }
						: { excludeBuyType2: excludeBuyType }),
					...(restData.includeTypeSelect === "BOUGHT"
						? { includeProductList1: includeProduct }
						: { includeProductList2: includeProduct }),
					...(restData.excludeTypeSelect === "BOUGHT"
						? { excludeProductList1: excludeProduct }
						: { excludeProductList2: excludeProduct }),
					...(restData.includeTypeSelect === "BOUGHT"
						? { includeSubProductList1: includeSubProduct }
						: { includeSubProductList2: includeSubProduct }),
					...(restData.excludeTypeSelect === "BOUGHT"
						? { excludeSubProductList1: excludeSubProduct }
						: { excludeSubProductList2: excludeSubProduct }),
				};

				dataDispatch({ type: "details", details: newDetails });
				dataDispatch({ type: "demo", demo: newData2 });
				dataDispatch({ type: "history", history: newData });
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => dataDispatch({ type: "loader", loader: false }));
	};

	useEffect(() => {
		id && dataDispatch({ type: "loader", loader: true });
		isSuccess && stateSuccess && id && getData(id);
		// dataDispatch({ type: "loader", loader: false });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess, stateSuccess]);

	return (
		<>
			<Toast ref={toast} />
			{dataState.loader && <Loader />}
			<Header stages={stages.stage} title="Campaign" stageName={""} />
			<div className="p-5 generalbgbox mt-3">
				{(function (elm) {
					switch (elm) {
						case 1:
							return (
								<CampaignDetails
									stages={stages}
									setStages={setStages}
									dataState={dataState}
									id={id}
								/>
							);
						case 2:
							return (
								<DemograpicFilter
									stages={stages}
									setStages={setStages}
									data={dataState.demo}
									id={id}
								/>
							);
						case 3:
							return (
								<ProductHistory
									stages={stages}
									setStages={setStages}
									dataState={dataState}
									id={id}
								/>
							);
						default:
							<></>;
					}
				})(stages.active)}
			</div>
		</>
	);
};

export default AddCampaign;
