import axios from "axios";
import { apiPath } from "../shared/constant";

export class SupportService {
	constructor() {
		this.token = sessionStorage.getItem("token");
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}

	getFAQCategory = async () => {
		try {
			const response = await axios.get(`${apiPath}/faq/faq-type/all`, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getFAQCategoryListById = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/faq/search`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	editFAQCategory = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/faq/faq-type/update`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	createFAQCategory = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/faq/faq-type/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	createFAQ = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/faq/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	createFAQChild = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/faq/create-faq-child`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getFAQTutorialCate = async () => {
		try {
			const response = await axios.get(`${apiPath}/tutorial-type/all`, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getFAQTutorialDetails = async (e) => {
		try {
			const response = await axios.get(`${apiPath}/tutorial-type/${e}`, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	editFAQTutorialCategory = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/tutorial-type/update`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	createFAQTutorialCategory = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/tutorial-type/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	createTutorialList = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/tutorial/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	updateTutorialList = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/tutorial/update`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getTutorialListById = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/tutorial/search`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	updateFAQ = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/faq/update`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
}
