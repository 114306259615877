import React, { useEffect, useReducer, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import {
	FeildDropdown,
	FieldInput,
	FieldMultiselect,
} from "../../../components/FieldInput";
import { Skeleton } from "primereact/skeleton";
import FaqTopic from "./components/FaqTopic";
import { Dialog } from "primereact/dialog";
import { SupportService } from "../../../services/SupportService";
import SearchFAQ from "./components/SearchFAQ";
import { searchReducer } from "../../../components/searchReducer";
import Paginator from "../../../components/Paginator";
import { dataReducer } from "../../../components/fetchReducer";
import Config from "../../../shared/config";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { Panel } from "primereact/panel";
import Loader from "../../../components/Loader";

const AddDialoge = ({ value, role }) => {
	const toastTL = useRef(null);
	const supportService = new SupportService();

	const [addDialog, setAddDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState("add");
	const [editData, setEditData] = useState({});
	const [selectedCategoryList, setselectedCategoryList] = useReducer(
		dataReducer,
		{ data: { data: [] }, isLoading: false, isFetched: false }
	);

	const validationSchema = Yup.object().shape({
		roleId: Yup.array()
			.of(Yup.number())
			.nullable()
			.required("Atleast select one role"),
		description: Yup.string().nullable().required("This field is required"),
	});
	const [searchState, searchDispatch] = useReducer(searchReducer, {
		searchValue: "",
		pageNo: 1,
		pageSize: 10,
	});
	const getCategoryData = (e) => {
		if (e) {
			const reqBody = {
				pageNo: searchState.pageNo,
				pageSize: searchState.pageSize,
				typeId: e.id,
				...(searchState.searchValue !== "" && searchState.searchValue),
			};
			setselectedCategoryList({ type: Config.FETCH_CONFIG.start });
			supportService
				.getFAQCategoryListById(reqBody)
				.then((res) => {
					setselectedCategoryList({
						type: Config.FETCH_CONFIG.success,
						payload: res,
					});
				})
				.finally(() => {
					setselectedCategoryList({
						type: Config.FETCH_CONFIG.fetched,
					});
					setselectedCategoryList({ type: Config.FETCH_CONFIG.stop });
				});
		}
	};
	useEffect(() => {
		getCategoryData(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, searchState]);

	const addTopic = (e) => {
		setType(e);
		setAddDialog(true);
	};

	const handleFormSubmit = (e) => {
		setLoading(true);
		if (type === "add") {
			let requestBody = {
				description: e.description,
				parentId: null,
				typeId: value.id,
				answer: "True",
				roleId: e.roleId,
			};
			supportService
				.createFAQ(requestBody)
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Successfull",
						detail: res,
						style: { color: "#000000" },
						life: 3000,
					});
					setLoading(false);
					setAddDialog(false);
				})
				.catch((e) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => {
					setLoading(false);
					// setAddDialog(false);
					getCategoryData(value);
				});
		} else {
			supportService
				.updateFAQ(e)
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Successfull",
						detail: res,
						style: { color: "#000000" },
						life: 3000,
					});
					setLoading(false);
					setAddDialog(false);
				})
				.catch((e) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => {
					setLoading(false);
					// setAddDialog(false);
					getCategoryData(value);
				});
		}
	};
	const handleRefresh = () => {
		getCategoryData(value);
	};
	const increment = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.decrement });
	};

	const pagesizechange = (e) => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};

	const handleEdit = (e) => {
		setType("edit");
		setEditData(e);
		setAddDialog(true);
	};

	// console.log(editData);
	return (
		<>
			<div>
				<Toast ref={toastTL} position="top-left" />

				<SearchFAQ
					addMemberClick={addTopic}
					searchDispatch={searchDispatch}
					header={value.name}
					role={role}
				/>
			</div>
			<div className="w-full h-full card">
				{selectedCategoryList.isLoading ? (
					<div className="py-5 px-5">
						<Skeleton width="100%" className="mb-2" height="2rem" />
						<Skeleton width="100%" className="mb-2" height="2rem" />
						<Skeleton width="100%" className="mb-2" height="2rem" />
						<Skeleton width="100%" height="2rem" />
					</div>
				) : (
					selectedCategoryList.data.data && (
						<>
							{selectedCategoryList.data.data.length > 0
								? selectedCategoryList.data.data.map(
										(item, index) => {
											return (
												<Panel
													key={index}
													collapsed
													toggleable
													className="mb-2"
													header={
														<div className="flex align-items-center gap-2">
															<Button
																className="icon-btn p-button-text"
																icon={
																	"pi pi-pencil"
																}
																onClick={() => {
																	handleEdit(
																		item
																	);
																}}
															/>
															<span className="vertical-align-middle">
																{
																	item.description
																}
															</span>
														</div>
													}
												>
													{item && (
														<FaqTopic
															item={item}
															handleRefresh={
																handleRefresh
															}
														/>
													)}
												</Panel>
											);
										}
								  )
								: selectedCategoryList.isFetched && (
										<div className="flex align-items-center justify-content-center py-5 bg-indigo-50 m-4 border-dashed border-1 border-indigo-500">
											<p>No data</p>
										</div>
								  )}
							{selectedCategoryList.data?.data.length > 0 && (
								<Paginator
									pageSize={searchState.pageSize}
									firstPage={
										selectedCategoryList.data.firstPage
									}
									lastPage={
										selectedCategoryList.data.lastPage
									}
									decrement={decrement}
									increment={increment}
									pagesizechange={pagesizechange}
									pageNo={selectedCategoryList.data.pageNo}
									totalElements={
										selectedCategoryList.data.totalElements
									}
									totalPages={
										selectedCategoryList.data.totalPages
									}
								/>
							)}
						</>
					)
				)}
			</div>
			<Dialog
				header="Topic"
				visible={addDialog}
				onHide={() => setAddDialog(false)}
				breakpoints={{
					"960px": "95vw",
				}}
				style={{ width: "50%" }}
			>
				<div className="card modal-form-grid">
					{loading && <Loader />}
					<Formik
						initialValues={
							type === "add"
								? { roleId: "", description: "" }
								: { ...editData }
						}
						onSubmit={handleFormSubmit}
						validationSchema={validationSchema}
						enableReinitialize
					>
						{({
							handleSubmit,

							resetForm,
						}) => (
							<Form onSubmit={handleSubmit} className="mt-5">
								<div className="grid form-grid">
									{/* {role ? (
										<>
											<Skeleton className="w-full h-3rem mt-4 " />
											<Skeleton className="w-full h-3rem mt-2 " />
										</>
									) : (
										<> */}
									<div className="field col-12">
										{/* <Field
											header={"Select Role"}
											component={FeildDropdown}
											name={`roleId`}
											options={role}
											optionLabel={"name"}
											optionValue={"id"}
										/> */}
										<Field
											header={"Select Role"}
											component={FieldMultiselect}
											name={`roleId`}
											options={role}
											optionLabel={"name"}
											optionValue={"id"}
										/>
									</div>
									<div className="field col-12">
										<Field
											header={"Description"}
											component={FieldInput}
											name={`description`}
										/>
									</div>
									<div className="flex justify-content-end align-items-center w-full mt-4">
										<Button
											type="button"
											label="Close"
											icon="pi pi-times"
											className="danger-btn mr-2"
											onClick={() => setAddDialog(false)}
											style={{
												borderRadius: "6px",
											}}
										/>
										<Button
											type="submit"
											label="Save"
											icon="pi pi-save"
											style={{
												borderRadius: "6px",
											}}
											loading={loading}
										/>
									</div>
									{/* </>
									)} */}
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Dialog>
		</>
	);
};

export default AddDialoge;
