import axios from "axios";
import { apiPath } from "../shared/constant";

export class CampaignService {
	constructor() {
		this.token = sessionStorage.getItem("token");
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}
	campaignCreate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/campaign-details/create`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getCampaign = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/campaign-details/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getDemographicData = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/demographic-filtration/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	campaignUpdate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/campaign-details/update`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	purchaseHistoryCreate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/campaign-details/create-purchase-history`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getPurchaseHistory = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/campaign-details/purchase-history/${payload}`,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	purchaseHistoryUpdate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/campaign-details/update-purchase-history`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	getCampaignList = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/campaign-details/search`,
				payload,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	campaignDetails = async (id) => {
		try {
			const res = await axios.post(
				`${apiPath}/campaign-details/${id}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	demographicCreate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/demographic-filtration/create`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	demographicUpdate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/demographic-filtration/update`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getProgramList = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/all-subproducts-with-programs`,
				payload,
				{ headers: this.headers }
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getDemographic = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/demographic-filtration/demographic-filtration/${payload}`,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getLeadList = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/campaign-details/fetch-lead-list`,
				payload,
				{ headers: this.headers }
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	changeCampIgnStatus = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/campaign-details/status/${payload}`,

				{ headers: this.headers }
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
}
