import axios from "axios";
import { apiPath } from "../shared/constant";

export class InstitutionService {
	constructor() {
		this.token = sessionStorage.getItem("token");
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}
	//Get Institution
	//@method: POST
	getInstitution = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/institution/svc/search`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getInstitutionDSA = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/dsa/svc/search`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	//Add Institution General Details
	//@method: POST
	addGeneralInstitution = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/institution/svc/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	addGeneralDSAInstitution = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/dsa/svc/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	addExtraGeneralDSAInstitution = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/dsa/svc/institution-add`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	//Add Institution Contact Details multiple
	//@method: POST
	addInstitutionContact = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/institution/svc/contact/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	addDSAInstitutionContact = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/dsa/svc/contact/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	//Add Institution Contract Details
	//@method: POST
	addInstitutionContract = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/institution/svc/contract/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	addDSAInstitutionContract = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/dsa/svc/contract/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	//Get Institution By ID
	//method: GET
	getInstitutionById = async (id) => {
		try {
			const response = await axios.get(
				`${apiPath}/institution/svc/${id}`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getDSAInstitutionContactById = async (id) => {
		try {
			const response = await axios.get(`${apiPath}/dsa/svc/${id}`, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getInstitutionWiseProductList = async (id) => {
		try {
			const response = await axios.get(
				`${apiPath}/institution/svc/productList/${id}`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	//Update Institution Basic Details
	//@method: POST
	updateInstitutionBasicDetails = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/institution/svc/update`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	updateDSAInstitutionBasicDetails = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/dsa/svc/update`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	//Institution Product add
	//@method: POST
	institutionProductAdd = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/institution/svc/product/add`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	// Add DSA
	addDSAproduct = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/institution/svc/product/dsa`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	updateInstitutionContact = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/institution/svc/contact/update`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	updateDSAInstitutionContact = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/dsa/svc/contact/update`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	updateDSAInstitutionContract = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/dsa/svc/contract/update`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getInstitutionProductDetails = async (id) => {
		try {
			const response = await axios.get(
				`${apiPath}/institution/svc/productdetail/${id}`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getInstitutionName = async (type) => {
		try {
			const response = await axios.get(
				`${apiPath}/bank/banktype/${type}`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	updateInstitutionProductContact = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/institution/svc/product/contact/update`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	createInstitutionProductContact = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/institution/svc/product/contact/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	creatInstitutionProductContract = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/institution/svc/product/contract/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	institutionStatusMaping = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/institution/svc/statusmapping`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	institutionStatusUpdate = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/institution/svc/save`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	// contracted update

	updateInstitutionContracted = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/institution/svc/contract/update`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
}
