import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import * as Yup from "yup";

import { useGetAllDIstrictQuery } from "../../../../services/query/queryApi";

import selectState, { selectDistrict } from "../../../../components/stateList";

const ProductContactDialog = ({
	id,
	handelContactAdd,
	data,
	type,
	loadingss,
	contactDialog,
}) => {
	const { data: stateList = [{ id: "", districtList: [{ id: "" }] }] } =
		useGetAllDIstrictQuery();

	const formik = useFormik({
		initialValues:
			type === "create"
				? {
						institutionProductId: id,
						email: "",
						name: "",
						mobile: "",
						stateId: "",
						districtId: "",
						designation: "",
				  }
				: { ...data },

		validationSchema: Yup.object().shape({
			name: Yup.string()
				.required("Name can't be empty.")
				.matches(/^[a-zA-Z ]*$/, "Use only alphabetical characters")
				.max(32, "First name not greater than 32 characters"),
			email: Yup.string()
				.required("Email id can't be empty.")
				.email("Invalid email id."),
			mobile: Yup.string()
				.matches(
					/^[6-9]\d{9}$/gi,
					"Mobile number should be 10 digit number"
				)
				.required("Mobile no. is required"),
			stateId: Yup.string().required("State is required"),
			districtId: Yup.string().required("District is required"),
			designation: Yup.string().required("Designation is required"),
		}),
		enableReinitialize: true,
		onSubmit: (data) => {
			handelContactAdd(data);
			formik.resetForm();
		},
	});

	const isFormFieldValid = (name) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};

	return (
		<div className="card modal-form-grid">
			<form onSubmit={formik.handleSubmit} className="p-fluid grid">
				<FocusError formik={formik} />
				<div className="field col-12 md:col-6">
					<label htmlFor="name">Name*</label>
					<InputText
						id="name"
						name="name"
						value={formik.values.name}
						onChange={formik.handleChange}
						autoFocus
						className={classNames({
							"p-invalid": isFormFieldValid("name"),
						})}
					/>
					{getFormErrorMessage("name")}
				</div>
				<div className="field col-12 md:col-6">
					<label htmlFor="email">Email Id*</label>
					<InputText
						id="email"
						name="email"
						value={formik.values.email}
						onChange={formik.handleChange}
						className={classNames({
							"p-invalid": isFormFieldValid("email"),
						})}
					/>
					{getFormErrorMessage("email")}
				</div>
				<div className="field col-12 md:col-6">
					<label htmlFor="lastName">Mobile No*</label>
					<InputText
						id="mobile"
						name="mobile"
						value={formik.values.mobile}
						onChange={formik.handleChange}
						placeholder="Mobile No."
						className={classNames({
							"p-invalid": isFormFieldValid("mobile"),
						})}
					/>
					{getFormErrorMessage("mobile")}
				</div>
				<div className="field col-12 md:col-6">
					<label htmlFor="lastName">Designation*</label>
					<InputText
						id="designation"
						name="designation"
						value={formik.values.designation}
						onChange={formik.handleChange}
						placeholder="Designation"
						className={classNames({
							"p-invalid": isFormFieldValid("designation"),
						})}
					/>
					{getFormErrorMessage("designation")}
				</div>

				<div className="field col-12 md:col-6">
					<label htmlFor="state">State</label>
					<Dropdown
						id="stateId"
						name="stateId"
						filter
						value={formik.values.stateId}
						onChange={formik.handleChange}
						options={selectState(stateList)}
						placeholder="Select state"
						optionLabel="name"
						className={classNames(
							{
								"p-invalid": isFormFieldValid("stateId"),
							},
							"border-round-md"
						)}
					/>
					{getFormErrorMessage("stateId")}
				</div>
				{formik.values.stateId !== "" && (
					<div className="field col-12 md:col-6">
						<label htmlFor="district">District</label>

						<Dropdown
							id="districtId"
							name="districtId"
							filter
							value={formik.values.districtId}
							onChange={formik.handleChange}
							options={selectDistrict(
								selectState(stateList),
								formik.values.stateId
							)}
							placeholder="Select district"
							optionLabel="name"
							className={classNames(
								{
									"p-invalid": isFormFieldValid("stateId"),
								},
								"border-round-md"
							)}
						/>
						{getFormErrorMessage("districtId")}
					</div>
				)}

				<div className="flex justify-content-end align-items-center w-full">
					<div className="col-12 md:col-4 lg:col-4 mt-3 flex">
						<Button
							type="button"
							label="Cancel"
							icon="pi pi-times"
							className="p-button-danger mr-2"
							onClick={() => contactDialog(false)}
						/>
						<Button
							type="submit"
							icon="pi pi-save"
							label="Save"
							loading={loadingss}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ProductContactDialog;
