import React, { useEffect, useReducer, useRef, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import GeneralUpdate from "./components/GeneralUpdate";
import { dataReducer } from "../../../../components/fetchReducer";
import { ProgramService } from "../../../../services/ProgramService";
import Config from "../../../../shared/config";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import Loader from "../../../../components/Loader";
import CommisionUpdate from "./components/CommisionUpdate";
import { convertNullToString } from "../../../../utils/UtilsFunction";
import OfferUpdate from "./components/OfferUpdate";
import moment from "moment";

const ProgramCopy = ({ programId }) => {
	const toastTL = useRef(null);
	const [newId, setProgramId] = useState(null);
	const [loader, setLoader] = useState(false);
	const programService = new ProgramService();
	const [activeIndex1, setActiveIndex1] = useState(0);
	const [state, dispatch] = useReducer(dataReducer, {
		data: {},
		isLoading: false,
		error: false,
		errorMessage: null,
		isFetched: false,
	});
	const getData = () => {
		dispatch({ type: Config.FETCH_CONFIG.start });
		programService
			.getFullProgram(programId)
			.then((res) => {
				dispatch({ type: Config.FETCH_CONFIG.success, payload: res });
				dispatch({ type: Config.FETCH_CONFIG.fetched });
			})
			.catch((err) => {})
			.finally(() => {
				dispatch({ type: Config.FETCH_CONFIG.stop });
			});
	};
	useEffect(() => {
		!id && getData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const {
		programCommissionStructureDto,
		programCreditBureauDtoList,
		programEligibilityCriteriaDtoList,
		programOfferDtoList,
		programCommissionDto,

		id,
		name,
		...restObj
	} = state.data;
	const handleGeneral = (val) => {
		setLoader(true);
		const { alias, dsaDetails, ...rest } = val;
		programService
			.createProgram(rest)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Program created successfully",
					style: { color: "#000000" },
					life: 3000,
				});
				setProgramId(res.id);
				setActiveIndex1(1);
			})
			.catch((er) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setLoader(false);
			});
	};
	const handleCommsissionSubmit = (val) => {
		const { id, ...restobjk } = val;
		setLoader(true);
		programService
			.commissionCreate(restobjk)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Commission created successfully",
					style: { color: "#000000" },
					life: 3000,
				});
				setActiveIndex1(2);
			})
			.catch((er) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setLoader(false);
			});
	};
	const handleOfferSubmit = (val) => {
		const { id, endDate, startDate, ...restObj } = val;
		setLoader(true);
		programService
			.offerCreate({
				...restObj,
				startDate: startDate
					? moment(startDate).format("YYYY-MM-DD")
					: null,
				endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
			})
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Offer created successfully",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((er) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setLoader(false);
			});
	};
	return (
		<>
			<Toast ref={toastTL} />
			{loader && <Loader />}
			{state.isLoading ? (
				<div className="flex align-items-center justify-content-center py-5">
					<ProgressSpinner
						style={{ height: "30px" }}
						strokeWidth="5"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				</div>
			) : (
				state.isFetched && (
					<TabView
						activeIndex={activeIndex1}
						onTabChange={(e) => setActiveIndex1(e.index)}
					>
						<TabPanel header="General">
							<GeneralUpdate
								programId={newId}
								data={convertNullToString(restObj)}
								handleGeneral={handleGeneral}
							/>
						</TabPanel>
						<TabPanel header="Commission Structure">
							<CommisionUpdate
								data={state.data.programCommissionDto}
								programId={newId}
								handleCommsissionSubmit={
									handleCommsissionSubmit
								}
							/>
						</TabPanel>
						<TabPanel header="Offer">
							<OfferUpdate
								programId={newId}
								initialValue={programOfferDtoList}
								handleFormSubmit={handleOfferSubmit}
								alias={state.data.alias}
							/>
						</TabPanel>
					</TabView>
				)
			)}
		</>
	);
};

export default ProgramCopy;
