import React, { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import authBG from "../../assets/images/login_bg.png";
import logo from "../../assets/images/logo_main.png";
import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { FieldInput, FieldPassword } from "../../components/FieldInput";
import OTPInput from "react-otp-input";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";

const ForgetPassword = () => {
	const toast = useRef(null);
	const [otp, setOtp] = useState("");

	const validationSchema = Yup.object().shape({
		mobile: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.required("Mobile number is required")
			.nullable(),
		password: Yup.string()
			.required("Please enter a password")
			.min(8, "Password must have at least 8 characters")
			.matches(
				/[0-9]/,
				"Your password must have at least 1 digit character"
			)
			.matches(
				/[a-z]/,
				"Your password must have at least 1 lowercase character"
			)
			.matches(
				/[A-Z]/,
				"Your password must have at least 1 uppercase character"
			),
		cnfPassword: Yup.string()
			.required("Please re-type your password")
			.oneOf([Yup.ref("password")], "Passwords does not match"),
	});
	const initValaue = {
		mobile: "",
		password: "",
		cnfPassword: "",
	};
	const handleFormSubmit = (e) => {
		const requestBody = {
			...e,
			otp: otp,
		};
		console.log(requestBody);
	};
	return (
		<>
			<div className="app-wrapper h-screen secondary-bg">
				<Toast ref={toast} position="top-left" />
				<div className="auth-bg flex">
					<img className="w-full" src={authBG} alt="Authbg" />
				</div>
				<div className="conatiner">
					<div className="grid">
						<div className="col-12">
							<div className="auth-logo-grid">
								<img className="img-fluid" src={logo} alt="" />
							</div>
						</div>
						<div className="col-12">
							<div className="auth-wrapper">
								<div className="card auth-card">
									<h2>Forget Password</h2>
									<div>
										<Formik
											enableReinitialize
											initialValues={initValaue}
											validationSchema={validationSchema}
											onSubmit={handleFormSubmit}
										>
											{({
												values,
												handleChange,
												handleSubmit,
												errors,
												touched,
											}) => (
												<Form onSubmit={handleSubmit}>
													<div className="auth-field position-relative mb-4">
														<Field
															name={"mobile"}
															placeholder={
																"Enter Mobile Number"
															}
															component={
																FieldInput
															}
															type={"number"}
														/>
													</div>
													<div className="auth-field position-relative mb-4">
														<Button>
															Send OTP
														</Button>
													</div>
													<div className="auth-field position-relative mb-4">
														<small className="font-bold block mb-1">
															Enter the OTP sent
															to -{" "}
														</small>
														<OTPInput
															value={otp}
															onChange={setOtp}
															numInputs={6}
															renderSeparator={
																<span>
																	&nbsp;-&nbsp;{" "}
																</span>
															}
															renderInput={(
																props
															) => (
																<InputText
																	{...props}
																	type="text"
																	autoComplete="off"
																	className="w-3rem  mt-3 mb-3"
																/>
															)}
														/>
													</div>
													<div className="auth-field position-relative mb-4">
														<Field
															name={"password"}
															placeholder={
																"Enter Password"
															}
															component={
																FieldPassword
															}
														/>
													</div>
													<div className="auth-field position-relative mb-4">
														<Field
															name={"cnfPassword"}
															placeholder={
																"Confirm Password"
															}
															component={
																FieldPassword
															}
														/>
													</div>
													<div className="d-grid gap-2">
														<Button
															type="submit"
															label="Submit"
															className="primary-btn w-full border-round-md"
															// loading={loading}
														/>
													</div>
													<div className="text-right mt-1">
														<Link to="/">
															Login Here
														</Link>
													</div>
												</Form>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ForgetPassword;
