import React from "react";

const NoDataBtn = (props) => {
	return (
		<div className="grid c-no-product-w ">
			<div className="col-6 c-no-product-in">
				<h3>{props.header}</h3>
				<button
					type="button"
					className="c-no-product-btn"
					onClick={props.handleClick}
				>
					<i className="pi pi-plus"></i>
				</button>
			</div>
		</div>
	);
};

export { NoDataBtn };
