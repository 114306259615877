import axios from "axios";
import { useSelector } from "react-redux";
import { agent_end_point_url, apiPath } from "../shared/constant";
export class VendorService {
	selector = useSelector((state) => state.persistData.auth);
	constructor() {
		// this.token = sessionStorage.getItem("token");
		this.token = this.selector.token;
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}

	createVendor = async (requestBody) => {
		const url = `${agent_end_point_url}/vendor/create`;
		try {
			const response = await axios.post(url, requestBody, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	updateVendor = async (requestBody) => {
		const url = `${agent_end_point_url}/vendor/update`;
		try {
			const response = await axios.post(url, requestBody, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getVendorList = async (requestBody) => {
		const url = `${agent_end_point_url}/vendor/search`;
		try {
			const response = await axios.post(url, requestBody, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	deleteVendorList = async (requestBody) => {
		const url = `${agent_end_point_url}/vendor/delete/${requestBody}`;
		try {
			const response = await axios.post(
				url,
				{},
				{
					headers: this.headers,
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	createHub = async (requestBody) => {
		const url = `${agent_end_point_url}/vendor/hub/create`;
		try {
			const response = await axios.post(url, requestBody, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getHubList = async (requestBody) => {
		const url = `${agent_end_point_url}/vendor/hub/search`;
		try {
			const response = await axios.post(url, requestBody, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	updateHub = async (requestBody) => {
		const url = `${agent_end_point_url}/vendor/hub/update`;
		try {
			const response = await axios.post(url, requestBody, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	deleteHub = async (requestBody) => {
		const url = `${agent_end_point_url}/vendor/hub/delete/${requestBody}`;
		try {
			const response = await axios.post(
				url,
				{},
				{
					headers: this.headers,
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	createspoke = async (requestBody) => {
		const url = `${agent_end_point_url}/vendor/spoke/create`;
		try {
			const response = await axios.post(url, requestBody, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getspokeList = async (requestBody) => {
		const url = `${agent_end_point_url}/vendor/spoke/search`;
		try {
			const response = await axios.post(url, requestBody, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	updatespoke = async (requestBody) => {
		const url = `${agent_end_point_url}/vendor/spoke/update`;
		try {
			const response = await axios.post(url, requestBody, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	deletespoke = async (requestBody) => {
		const url = `${agent_end_point_url}/vendor/spoke/delete/${requestBody}`;
		try {
			const response = await axios.post(
				url,
				{},
				{
					headers: this.headers,
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	productWiseLeadApplication = async (profileId) => {
		const url = `${agent_end_point_url}/profile/product-lead-application-diagram/${profileId}`;
		try {
			const response = await axios.get(
				url,

				{
					headers: this.headers,
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	productWiseLead = async (profileId) => {
		const url = `${agent_end_point_url}/profile/profile-lead-count/${profileId}`;
		try {
			const response = await axios.get(
				url,

				{
					headers: this.headers,
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	productWiseApplication = async (profileId) => {
		const url = `${agent_end_point_url}/profile/profile-application-count/${profileId}`;
		try {
			const response = await axios.get(
				url,

				{
					headers: this.headers,
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agentActivity = async (profileId) => {
		const url = `${agent_end_point_url}/profile/profile-activity-history/${profileId}`;
		try {
			const response = await axios.get(
				url,

				{
					headers: this.headers,
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agentStatusUpdateFromProfile = async (body) => {
		const url = `${agent_end_point_url}/profile/save/agentEngagement`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agentDetailsSaveQualification = async (body) => {
		const url = `${agent_end_point_url}/agent/save-profile-details`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agentActivityLofList = async (agentId) => {
		const url = `${agent_end_point_url}/profile/login-activity-list/${agentId}`;
		try {
			const response = await axios.get(url, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getVendorApplicationDailyTsrmReport = async (payload) => {
		try {
			const res = await fetch(
				`${apiPath}/report/tsrm-wise-daily-application-report-vendor/${payload}`,
				{
					headers: this.headers,
				}
			);
			return res;
		} catch (error) {
			throw error.response.data;
		}
	};
	getOEVendorAdminReport = async (payload) => {
		try {
			const res = await fetch(
				`${apiPath}/report/oe-admin-report/${payload}`,
				{
					headers: this.headers,
				}
			);
			return res;
		} catch (error) {
			throw error.response.data;
		}
	};
	getTSRMDailyCallReport = async (payload) => {
		try {
			const res = await fetch(
				`${agent_end_point_url}/report/tsrm-daily-call-report/${payload}`,
				{
					headers: this.headers,
				}
			);
			return res;
		} catch (error) {
			throw error.response.data;
		}
	};
}
