import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { institutionData, masterData } from "../services/query/queryApi";
import ProgramReducer from "./reducer/ProgramReducer";
import AuthReducer from "./reducer/AuthReducer";
import {
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import LeadReducer from "./reducer/LeadReducer";
import LoanApplication from "./reducer/LoanApplication";
const persistConfig = {
	key: "root",
	version: 1,
	storage,
	blacklist: [institutionData.reducerPath, masterData.reducerPath],
};
const persistedReducer = persistReducer(persistConfig, AuthReducer);

export const store = configureStore({
	reducer: {
		[institutionData.reducerPath]: institutionData.reducer,
		[masterData.reducerPath]: masterData.reducer,
		program: ProgramReducer,
		persistData: persistedReducer,
		lead: LeadReducer,
		loanApplication: LoanApplication,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
				],
			},
		})
			.concat(institutionData.middleware)
			.concat(masterData.middleware),
});

setupListeners(store.dispatch);
