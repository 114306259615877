import { useLocation, useParams } from "react-router-dom";
import SubmitNewLeadDialog from "./SubmitNewLeadDialog";
import SubmitNewInsuranceLeadDialog from "./SubmitInsuranceLead";
import Config from "../../../../shared/config";

const EditDialog = () => {
	const locaiton = useLocation();
	const params = useParams();
	return locaiton?.state?.productType === Config.PRODUCT_LEAD_TYPE.LOAN ? (
		<SubmitNewLeadDialog
			location={locaiton}
			type={"edit"}
			leadId={params}
		/>
	) : (
		<SubmitNewInsuranceLeadDialog
			location={locaiton}
			type={"edit"}
			leadId={params}
		/>
	);
};

export default EditDialog;
