import { useEffect, useRef, useState } from "react";
import { Form, Formik, Field } from "formik";
import {
	FeildDropdown,
	FieldCheckBox,
	FieldInput,
	FieldMultiselect,
} from "../../../../components/FieldInput";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import Config from "../../../../shared/config";
import { exportExcel } from "../../../../components/XlsxExport";
import SearchFieldGroup from "../../../../components/SearchFieldGroup";
import { SplitButton } from "primereact/splitbutton";
import { LoanService } from "../../../../services/LoanService";
import { apiPath } from "../../../../shared/constant";
import { LoanApplicationService } from "../../../../services/LoanApplication";
import { Toast } from "primereact/toast";
import moment from "moment";
import { VendorService } from "../../../../services/vendorService";
import { useDispatch, useSelector } from "react-redux";
import { setLeadId } from "../../../../store/reducer/LeadReducer";
import { produce } from "immer";
import {
	setApplicationState,
	setCountryApplicationId,
} from "../../../../store/reducer/AuthReducer";
import {
	useGetAppProgramQuery,
	useGetInstitutionDetailsQuery,
	useGetProductDetailsQuery,
} from "../../../../services/query/queryApi";
const SearchLoanApplication = (props) => {
	const { data: productList = { data: [] } } = useGetProductDetailsQuery({
		searchRequest: [],
		pageNo: 1,
		pageSize: 330,
		sorts: [],
	});
	const { data: programData = [] } = useGetAppProgramQuery();
	const { data: institutionList = [] } = useGetInstitutionDetailsQuery();
	const selector = useSelector(
		(state) => state?.persistData?.auth?.user?.role
	);
	const dispatch = useDispatch();
	const toast = useRef(null);
	const [show, setShow] = useState(false);
	const loanService = new LoanService();
	const [VendorList, setVendorList] = useState([]);
	const ccService = new VendorService();
	const navigate = useNavigate();
	const loanListService = new LoanApplicationService();
	const initialValue = {
		//"id":[1459]
		number: "",
		status: [],
		// agentCode: "",
		// "program":
		// {
		//     "id":2067
		// }
		vendorId: "",
		bankApplicationNumber: "",
		loanAmount: "",
		programId: "",
		institutionId: "",
		productId: "",
		applicantName: "",
		// "gender":"Male"
		// "dob":"1998-03-16"
		// "personalMail":"abc@gmail.com"
		mobile: "",
		// "customerId":"2091"
		uniqueApplication: false,
		isApplicationNumber: false,
		applicationStatus: [],
	};

	const getAllLoanData = (value) => {
		props.loader(true);
		const body = {
			pageNo: 1,
			pageSize: 1000000,
		};

		loanListService
			.getLoanApplicationList(body)
			.then((res) => {
				const data = newData(res.data);
				exportExcel(data, "All application list");
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => props.loader(false));
	};
	const newData = (data) => {
		props.loader(true);
		const arr = [...data];
		const body = arr.map((item) => ({
			...(item.firstName === null &&
			item.middleName === null &&
			item.lastName === null
				? {
						Name: item.applicantName,
				  }
				: {
						Name:
							(item.firstName ? item.firstName : "") +
							" " +
							(item.middleName ? item.middleName : "") +
							" " +
							(item.lastName ? item.lastName : ""),
				  }),
			LOB: item.alias,
			Zone: item.zone,
			State: item.state,
			District: item.district,

			"Mobile No": item.mobile,
			"RF Application No": item.number,
			"RFA Status": item.status?.replaceAll("_", " ").toLowerCase(),
			"Agent Code": item.agentCode,
			"Program Name": item.programName,

			Amount: item.loanAmount,

			Gender: item.gender,
			"Date Of Birth": item.dob
				? moment(item.dob).format("Do MMM, YYYY")
				: "",
			"Personal Mail": item.personalMail,

			"Created On": item.createdOn
				? moment(item.createdOn).format("Do MMM, YYYY")
				: "",
			"FI Status": item.applicationStatus,
			// "subProductId":item.subProductId,

			"FI Application Number": item.bankApplicationNumber,
		}));
		return body;
	};
	const handleForm = (val, action) => {
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
		let newValue = Object.values(val);
		let newKeys = Object.keys(val);
		const newObj = produce({}, (state) => {
			for (let index = 0; index < newValue.length; index++) {
				const element = newValue[index];
				if (
					(!Array.isArray(element) && Boolean(element)) ||
					(Array.isArray(element) && element.length)
				) {
					state[newKeys[index]] = element;
				}
			}
		});
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: newObj,
		});
	};
	const getData = () => {
		props.loader(true);
		loanService
			.getAllHospicashList()
			.then((res) => {
				const a = document.createElement("a");
				a.href = `${apiPath}/application/hospicash-all`;
				a.download = "ApplicationList";
				a.click();

				// saveAs(
				// 	"http://192.168.0.147:8083/rainbowmaster/application/hospicash-all",
				// 	"HospicashList"
				// );
			})
			.catch((er) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Error",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => props.loader(false));
		// .catch((err) => {
		// 	props.loader(false);
		// });
		// .finally(() => props.loader(false));

		// FileSaver.saveAs(
		// 	`${apiPath}/application/hospicash-all`,
		// 	"applicationList"
		// );
	};
	const items = [
		{
			label: "Export",
			icon: "pi pi-file-excel",
			command: () => {
				exportExcel(props.newData, "applicationList");
			},
		},
		{
			label: "Export All",
			icon: "pi pi-file-excel",
			command: () => {
				getAllLoanData();
			},
		},
		{
			label: "Export Hospicash",
			icon: "pi pi-file-excel",
			command: () => {
				getData();
			},
		},
	];
	useEffect(() => {
		ccService
			.getVendorList({
				pageNo: 1,
				pageSize: 1000,
			})
			.then((res) => {
				const data = res?.data?.map((elm) => ({
					...elm,
					name: elm.vendorType + "(" + elm.name + ")",
				}));
				setVendorList(data);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<div className="col-12 w-full flex align-items-end justify-content-between">
				<Toast ref={toast} />
				<h3>Application List</h3>
				<div className="flex gap-2">
					<Button
						icon={show ? "pi pi-times" : "pi pi-search"}
						className={
							show
								? "p-button-danger p-button-outlined"
								: "p-button-outlined"
						}
						label={show ? "Close" : "Search"}
						onClick={() => {
							setShow((show) => !show);
						}}
					/>
					<Button
						className=" p-button-outlined"
						onClick={() =>
							props.searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							})
						}
					>
						<i className="pi pi-refresh mr-1" />
						Reload
					</Button>
					{selector?.position !== 50 && (
						<Button
							className=""
							onClick={props.handleStatusUpload}
							icon="pi pi-upload"
							label="Bulk Status Upload"
						/>
					)}
					{selector?.position !== 50 && (
						<Button
							className=""
							onClick={() => {
								dispatch(setApplicationState("edit"));
								dispatch(setCountryApplicationId(null));
								navigate("/masters/application-create");
							}}
							icon="pi pi-plus"
							label="Application"
						/>
					)}
					{/* {true && ( */}
					{/* <Button
						label="Application Report"
						icon={"pi pi-plus"}
						className=""
						onClick={() => {
							navigate("/masters/application-report");
						}}
					/> */}
					{/* )} */}
					<SplitButton
						icon={"pi pi-file-export"}
						label="Export"
						model={items}
					/>
				</div>
			</div>
			{show && (
				<Formik initialValues={initialValue} onSubmit={handleForm}>
					{({ handleSubmit, resetForm, values, setFieldValue }) => (
						<Form
							onSubmit={handleSubmit}
							className="background-secondary p-2 border-round-md my-3"
						>
							<div className="form-grid grid justify-content-end">
								<SearchFieldGroup
									values={values}
									setFieldValue={setFieldValue}
									zoneField={"zoneId"}
									stateField={"stateId"}
									districtField={"districtId"}
									fromDate={"fromDate"}
									toDate={"toDate"}
								/>
								{selector?.position !== 50 && (
									<div className="field col-12 md:col-3">
										<Field
											header={"Status"}
											name={"status"}
											filter
											component={FieldMultiselect}
											options={[
												{
													label: "Customer Draft",
													value: "CUSTOMER_DRAFT",
												},
												{
													label: "Draft",
													value: "DRAFT",
												},
												// {
												// 	label: "Submitted",
												// 	value: "SUBMITED",
												// },
												{
													label: "Lock",
													value: "LOCK",
												},
												{
													label: "Customer Approved",
													value: "CUSTOMER_APPROVED",
												},
												{
													label: "Pending For Customer",
													value: "PENDING_FOR_CUSTOMER",
												},
											]}
										/>
									</div>
								)}
								<div className="field col-12 md:col-3">
									<Field
										header={"FI Application number"}
										name={"bankApplicationNumber"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Loan Amount"}
										name={"loanAmount"}
										type={"number"}
										component={FieldInput}
									/>
								</div>

								<div className="field col-12 md:col-3">
									<Field
										header={"Application Number"}
										name={"number"}
										component={FieldInput}
									/>
								</div>
								{/* <div className="field col-12 md:col-3">
									<Field
										header={"Agent Code"}
										name={"agentCode"}
										type={"number"}
										component={FieldInput}
									/>
								</div> */}

								{/* <div className="field col-12 md:col-3">
									<Field
										header={"FI Application number"}
										name={"bankApplicationNumber"}
										component={FieldInput}
									/>
								</div> */}
								<div className="field col-12 md:col-3">
									<Field
										header={"Program"}
										name={"programId"}
										component={FeildDropdown}
										filter
										options={programData}
										optionLabel={"name"}
										optionValue={"programId"}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Product"}
										name={"productId"}
										filter
										component={FeildDropdown}
										options={productList.data?.filter(
											(elm) => elm.isActive
										)}
										optionLabel="title"
										optionGroupLabel="title"
										optionGroupChildren="subProducts"
										optionValue={"id"}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										name="institutionId"
										component={FeildDropdown}
										options={institutionList}
										filter
										optionLabel={"name"}
										optionValue={"id"}
										header={"Institution"}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"FI Status"}
										name={"applicationStatus"}
										component={FeildDropdown}
										options={[
											{
												label: "Pending",
												value: "Pending",
											},
											{
												label: "Approve",
												value: "Approve",
											},

											{
												label: "Disbursed",
												value: "Disbursed",
											},
											{
												label: "Rejected",
												value: "Rejected",
											},
										]}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Applicant Name"}
										name={"applicantName"}
										component={FieldInput}
									/>
								</div>

								<div className="field col-12 md:col-3">
									<Field
										header={"Mobile"}
										name={"mobile"}
										component={FieldInput}
									/>
								</div>
								{selector?.position === null && (
									<div className="field col-12 md:col-3">
										<Field
											filter
											header={"Vendor"}
											name={"vendorId"}
											component={FeildDropdown}
											options={VendorList}
											optionLabel="name"
											optionValue={"id"}
										/>
									</div>
								)}

								{/* <div className="field col-12 md:col-3">
									<Field
										header={"Gender"}
										name={"gender"}
										component={FieldRadio}
										radiolist={radioBtns}
									/>
								</div> */}

								<div className="field col-12 md:col-3">
									<Field
										header={"Unique Application"}
										name={"uniqueApplication"}
										component={FieldCheckBox}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={
											"RF Applications(which has RF Application No.)"
										}
										name={"isApplicationNumber"}
										component={FieldCheckBox}
									/>
								</div>
								<div className="md:col-3 md-offset-3 col-12 flex align-items-center justify-content-end">
									<Button
										type="button"
										label="Reset"
										className="p-button-danger mr-2"
										onClick={resetForm}
									/>
									<Button type="submit" label="Search" />
								</div>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default SearchLoanApplication;
