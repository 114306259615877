import { Fragment, useEffect, useReducer, useRef, useState } from "react";
// import { Image } from "primereact/image";
import Config from "../../../../shared/config";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { S3 } from "aws-sdk";
import { LoanApplicationService } from "../../../../services/LoanApplication";
import { ProductService } from "../../../../services/ProductService";
import groupBy from "lodash/groupBy";
import { Toast } from "primereact/toast";
// import { Link } from "react-router-dom";
import FileSaver, { saveAs } from "file-saver";
import JSZip from "jszip";
import { Button } from "primereact/button";
import isEmpty from "lodash/isEmpty";
import { ProgressBar } from "primereact/progressbar";
import map from "async/map";

const DocumentUpload = (props) => {
	// const selector = useSelector((state) => state.lead);
	const loanApplicationService = new LoanApplicationService();
	const productService = new ProductService();
	const [loader, setLoader] = useState(false);
	const toast = useRef(null);
	const [spinner, setSpinner] = useState(false);
	const [documentPreview, setDocumentPreview] = useState([]);
	const [productDocumentState, productDocumentDispatch] = useReducer(
		(state, action) => {
			return { ...state, productList: action.payload };
		},
		{ productList: {} }
	);
	const [dropdownState, dropdownDocumentDispatch] = useReducer(
		(a, b) => {
			return { ...a, value: b };
		},
		{
			value: { id: "", documentTypeId: { id: "", type: "" } },
		}
	);

	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});
	//get  doucment preview list
	const getDocumentList = () => {
		props?.applicationId &&
			loanApplicationService
				.documentList(props?.applicationId)
				.then((e) => {
					setDocumentPreview(e);
					const dataTypeProof = groupBy(e, "documentTypeId.type");
					props.setImageCompareData(dataTypeProof);
				})
				.catch((e) => {});
	};
	// const getLeadDocumentList = (e) => {
	// 	e &&
	// 		leadService
	// 			.getLeadDocument(e)
	// 			.then((res) => {
	// 				const params = {
	// 					Bucket: `${Config.APPLICATION_ATTACHMENT_BUCKET}/${props?.applicationId}`,
	// 					Key: res[0]?.documentFileName,
	// 				};
	// 				s3Clint.getObject(params, (err, data) => {
	// 					if (err) {
	// 						if (err.code === "NoSuchKey") {
	// 							const imageData = documentPreview.map(
	// 								(item) => ({
	// 									url: `${Config.AGENT_BUCKET_ACCESS_URL}/lead/${folder_path}/${props.leadId}/${item.documentFileName}`,
	// 									key: item.documentFileName,
	// 								})
	// 							);
	// 							const copyFile = function (file, callback) {
	// 								let params = {
	// 									Bucket: `${Config.APPLICATION_ATTACHMENT_BUCKET}/${props?.applicationId}`,
	// 									CopySource: `${Config.LEAD_ATTACHMENT_BUCKET}/${folder_path}/${props.leadId}/${file.key}`,
	// 									Key: file.key,
	// 								};
	// 								s3Clint.copyObject(
	// 									params,
	// 									function (err, data) {
	// 										if (err)
	// 											console.log(err, err.stack);
	// 										else console.log(data); // successful response
	// 									}
	// 								);
	// 							};
	// 							eachSeries(imageData, copyFile)
	// 								.then(() => {
	// 									console.log(
	// 										"All files have been deleted successfully"
	// 									);
	// 								})
	// 								.catch((err) => {
	// 									console.log(err);
	// 								});
	// 						} else {
	// 							console.log(
	// 								"Error Occurred While getObject():"
	// 							);
	// 						}
	// 					} else {
	// 						console.log(data);
	// 					}
	// 				});
	// 			})
	// 			.catch((e) => {
	// 				console.log(e);
	// 			});
	// };

	const onUploadImage = (e) => {
		//setLoading(true);
		setLoader(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket:
				Config.APPLICATION_ATTACHMENT_BUCKET +
				"/" +
				props?.applicationId,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err) => {
			if (err || !dropdownState.value.documentTypeId.id) {
				//setLoading(false);
				setLoader(false);
				return toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Select Document Types",
					style: { color: "#000000" },
					life: 3000,
				});
			}
			if (!props?.applicationId) {
				setLoader(false);
				return toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Save as draft before upload",
					style: { color: "#000000" },
					life: 3000,
				});
			}

			//setLoading(false);

			const data = {
				applicationId: props?.applicationId,
				documentFileName: newFileName,
				documentTypeId: dropdownState.value.documentTypeId.id,
			};

			loanApplicationService
				.documentUpload(data)
				.then(() => {
					toast.current.show({
						severity: "success",
						summary: "Successfull",
						detail: "Document uploaded",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.catch((e) => {
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => {
					setLoader(false);
					getDocumentList();
				});
		});
	};

	//get required document

	const getDocumentType = (e) => {
		props?.subproductId &&
			productService
				.getexistingProductDocument(e)
				.then((res) => {
					const data = groupBy(res, "documentTypeId.type");
					productDocumentDispatch({ payload: data });
					props.setImageData(data);
				})
				.catch((e) => console.log(e));
	};

	const dataTypeProof = groupBy(documentPreview, "documentTypeId.type");

	useEffect(() => {
		Promise.allSettled([
			getDocumentType(props?.subproductId),

			getDocumentList(),
		]);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.subproductId, props?.applicationId]);
	// useEffect(() => {
	// 	getLeadDocumentList(props?.leadId);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [props?.leadId]);
	function getFileExtension(filename) {
		const extension = filename.split(".").pop();
		return extension;
	}

	const download = (e) => {
		FileSaver.saveAs(
			`${Config.AGENT_BUCKET_ACCESS_URL}/applications/${props?.applicationId}/${e}`,
			e
		);
	};
	const progressBar = () => {
		return loader ? (
			<ProgressBar mode="indeterminate" style={{ height: "4px" }} />
		) : null;
	};
	const generateZipFromCloud = async () => {
		var zip = new JSZip();
		var img = zip.folder("images");
		const imageData = documentPreview.map(
			(item) =>
				`${Config.AGENT_BUCKET_ACCESS_URL}/applications/${props?.applicationId}/${item.documentFileName}`
		);
		let filename = `Application_File_${props?.applicationId}`;
		map(imageData, async function (file, cb) {
			const imageBlob = await fetch(file).then((response) =>
				response.blob()
			);
			const imgData = new File([imageBlob], "filename.jpg");
			img.file(`${file.substring(file.lastIndexOf("/"))} `, imgData, {
				base64: true,
			});
		}).then((res) => {
			zip.generateAsync({ type: "blob" }).then(function (content) {
				saveAs(content, filename);
			});
		});
	};

	const deleteFile = (fileId) => {
		setSpinner(true);
		const params = {
			Bucket:
				Config.APPLICATION_ATTACHMENT_BUCKET +
				"/" +
				props?.applicationId,
			Key: fileId?.documentFileName,
		};
		s3Clint.deleteObject(params, (err, data) => {
			if (err) {
				setSpinner(false);
				//setLoading(false);
				setLoader(false);
				return toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Failed to delete",
					style: { color: "#000000" },
					life: 3000,
				});
			}
			loanApplicationService
				.applicationDocsDelete(fileId?.id)
				.then((res) =>
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: "Deleted successfully",
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.then(() => getDocumentList())
				.catch((er) => {
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: "Failed to delete",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => {
					setSpinner(false);
				});
		});
	};
	return (
		<>
			<Toast ref={toast} position="top-left" />
			<div className="grid">
				{Object.entries(productDocumentState.productList).map(
					(item, index) => {
						const obj =
							!isEmpty(dataTypeProof) &&
							Object.entries(dataTypeProof).find(
								(elm) => elm[0] === item[0]
							);
						return (
							<div key={`${index}`} className="md:col-6 ">
								<div className="border-1 border-300 border-round-md p-3">
									<h4 className="col-12 capitalize">
										{item[0]
											.replace(/_/g, " ")
											.toLocaleLowerCase()}{" "}
										<span className="text-red-500 font-bold">
											*
										</span>
									</h4>

									<Dropdown
										className="border-round-md w-full mb-3"
										value={dropdownState.value}
										onChange={(e) =>
											dropdownDocumentDispatch(e.value)
										}
										placeholder="Select one "
										options={item[1].map((item) => {
											return {
												label: item.documentTypeId.name,
												value: item,
											};
										})}
									/>

									{dropdownState.value.documentTypeId.type ===
										item[0] && (
										<FileUpload
											progressBarTemplate={progressBar}
											customUpload
											accept=".jpg,.png,.jpeg, .pdf"
											uploadHandler={(e) =>
												onUploadImage(e)
											}
											maxFileSize={1000000}
											emptyTemplate={
												<p className="m-0">
													Drag and drop files to here
													to upload.
												</p>
											}
										/>
									)}

									{obj && (
										<>
											{spinner && (
												<ProgressBar
													mode="indeterminate"
													style={{ height: "4px" }}
												/>
											)}
											{obj[1].map((it, index) => (
												<Fragment key={it.id}>
													<div className="list-box-grid c-list-box-w">
														<div className="w-full flex justify-content-between align-iteam-center list-box-container">
															<a
																href={`${Config.AGENT_BUCKET_ACCESS_URL}/applications/${props?.applicationId}/${it.documentFileName}`}
																target="_blank"
																className="flex align-items-center justify-content-start"
																rel="noreferrer"
															>
																{getFileExtension(
																	it.documentFileName
																) === "pdf" ? (
																	<i
																		className={`text-2xl pi pi-file-pdf text-red-400`}
																	/>
																) : (
																	<span className="c-list-box-it">
																		<img
																			width={
																				50
																			}
																			src={`${Config.AGENT_BUCKET_ACCESS_URL}/applications/${props?.applicationId}/${it.documentFileName}`}
																			alt=""
																		/>
																	</span>
																)}

																<div className="pl-4">
																	<h5 className="m-0">
																		{
																			it.documentFileName
																		}
																	</h5>
																	<small
																		style={{
																			textTransform:
																				"uppercase",
																		}}
																	>
																		{
																			it
																				.documentTypeId
																				.name
																		}
																	</small>
																</div>
															</a>

															<div className="fles gap-2">
																<Button
																	icon={
																		"pi pi-download"
																	}
																	onClick={() =>
																		download(
																			it.documentFileName
																		)
																	}
																	className="p-button-rounded"
																/>
																<Button
																	icon={
																		"pi pi-trash"
																	}
																	onClick={() =>
																		deleteFile(
																			it
																		)
																	}
																	className="p-button-rounded"
																/>
															</div>
														</div>
													</div>
												</Fragment>
											))}
										</>
									)}
								</div>
							</div>
						);
					}
				)}
				<div className="col-12">
					<Button
						onClick={generateZipFromCloud}
						disabled={isEmpty(dataTypeProof)}
						label="All Download"
					/>
				</div>
			</div>
		</>
	);
};

export default DocumentUpload;
