import React, { useEffect, useReducer } from "react";
import { LeadService } from "../../../../services/LeadService";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import Config from "../../../../shared/config";
import { TabView, TabPanel } from "primereact/tabview";
import { AgentService } from "../../../../services/AgentService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { Button } from "primereact/button";
// import { Dialog } from "primereact/dialog";

// import { ListBox } from "primereact/listbox";

// import TableLoader, { skeletonBody } from "../../../../components/TableLoader";
import { ProgressSpinner } from "primereact/progressspinner";

const RmDetailsComponent = (props) => {
	const leadService = new LeadService();
	const agentService = new AgentService();
	const [agentState, agentdispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const [seState, sedispatch] = useReducer(dataReducer, fetchInitialState);

	const getList = (e) => {
		agentdispatch({
			type: Config.FETCH_CONFIG.start,
		});
		// agentService.getAgentDetails(e).then((res) => {
		// 	sedispatch({ type: Config.FETCH_CONFIG.start });
		// });
		Promise.all([
			leadService.getAgent(e).then((res) => {
				agentdispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			}),
			agentService.getAgentDetails(e).then((res) => {
				sedispatch({ type: Config.FETCH_CONFIG.success, payload: res });
			}),
		]).finally(() => {
			agentdispatch({
				type: Config.FETCH_CONFIG.stop,
			});

			sedispatch({ type: Config.FETCH_CONFIG.stop });
		});
	};

	useEffect(() => {
		getList(props.userName);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const nameTemplate = (item) => {
		return (
			<>{`${item.firstName ? item.firstName : ""} ${
				item.middleName ? item.middleName : ""
			} ${item.lastName ? item.lastName : ""}`}</>
		);
	};

	return (
		<div className="p-2">
			<TabView activeIndex={1}>
				<TabPanel header="Sales Executive">
					{seState.isLoading ? (
						<div className="flex align-items-center justify-content-center py-5">
							<ProgressSpinner
								style={{
									width: "40px",
									height: "40px",
								}}
								strokeWidth="4"
								fill="var(--surface-ground)"
								animationDuration=".5s"
							/>
						</div>
					) : (
						<DataTable value={seState.data}>
							<Column body={nameTemplate} header={"Name"} />
							<Column field="userName" header={"Username"} />
						</DataTable>
					)}
				</TabPanel>
				<TabPanel header="Agents">
					{agentState.isLoading ? (
						<div className="flex align-items-center justify-content-center py-5">
							<ProgressSpinner
								style={{
									width: "40px",
									height: "40px",
								}}
								strokeWidth="4"
								fill="var(--surface-ground)"
								animationDuration=".5s"
							/>
						</div>
					) : (
						<DataTable value={agentState.data}>
							<Column body={nameTemplate} header={"Name"} />
							<Column
								field="mobileNumber"
								header={"Mobile Number"}
							/>
							<Column field="emailId" header={"Email Id"} />
							<Column field="userName" header={"Username"} />
							<Column
								field="profileType"
								header={"Profiletype"}
							/>

							{/* <Column body={nameTemplate} header={"Name"} />
						<Column field="mobileNumber" header={"Mobile Number"} />
						<Column field="emailId" header={"Email Id"} />
						<Column field="userName" header={"Username"} />
						<Column field="profileType" header={"Profiletype"} /> */}
						</DataTable>
					)}
				</TabPanel>
			</TabView>
		</div>
	);
};

export default RmDetailsComponent;
