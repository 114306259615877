import { useState, useRef, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Config from "../../../../shared/config";
import Header from "../../../partners/institutions/header";
import moment from "moment";
import { LoanService } from "../../../../services/LoanService";

import { LoanApplicationService } from "../../../../services/LoanApplication";
import { useDispatch, useSelector } from "react-redux";
import {
	convertNullToString,
	convertObjNull,
} from "../../../../utils/UtilsFunction";
import Loader from "../../../../components/Loader";
import { useGetAllDIstrictQuery } from "../../../../services/query/queryApi";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import { Dialog } from "primereact/dialog";
import DocumentUpload from "./DocumentUpload";
import * as Yup from "yup";
import { Divider } from "primereact/divider";
import PersonalLoan from "./FormComponent/PersonalLoan";
import HomeLoan from "./FormComponent/HomeLoan";
import CreditCard from "./FormComponent/CreditCard";
import VehicleLoan from "./FormComponent/VehicleLoan";

import PreviewPdf from "./PreviewApplication";
import BusinessLoan from "./FormComponent/BusinessLoan";
import { setCountryApplicationId } from "../../../../store/reducer/AuthReducer";

const ApplicationForm = (props) => {
	const navigate = useNavigate();
	const persistSelector = useSelector((state) => state.persistData);
	const [printDialog, setPrintDialog] = useState(false);
	window.onbeforeunload = function () {
		return "Are you sure";
	};
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const {
		data: getAllDistrict = [
			{ districtList: [{ id: null, name: "" }], id: null, name: "" },
		],
	} = useGetAllDIstrictQuery();
	const loanService = new LoanService();
	const toastTL = useRef(null);
	const loanApplicationService = new LoanApplicationService();
	const [reason, setReason] = useState([]);

	const [leaddetailsstate, leadDetailsDIspatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const [formState, formDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	//edit data
	const getData = (id) => {
		formDispatch({ type: Config.FETCH_CONFIG.start });
		loanApplicationService
			.getLoanApplicationEditList(id)
			.then((res) => {
				const data = convertNullToString(res);

				formDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: data,
				});
			})

			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				formDispatch({ type: Config.FETCH_CONFIG.stop });
			});
	};

	const getReason = () => {
		props.locationData.subproductId &&
			loanApplicationService
				.getReason(props.locationData.subproductId)
				.then((res) => {
					const data = res.map((e) => {
						return { ...e, value: e.id };
					});
					setReason([...data, { reason: "other", value: 0 }]);
				})
				.catch((e) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					})
				);
	};

	// const getDisease = () => {
	// 	loanApplicationService
	// 		.getDisease()
	// 		.then((res) => {
	// 			setAllDisease(res);
	// 		})
	// 		.catch((e) =>
	// 			toastTL.current.show({
	// 				severity: "error",
	// 				summary: "Error",
	// 				detail: e.message,
	// 				style: { color: "#000000" },
	// 				life: 3000,
	// 			})
	// 		);
	// };

	const getLoanApplicationCreateFullList = (mobileNumber) => {
		leadDetailsDIspatch({
			type: Config.FETCH_CONFIG.start,
		});

		if (
			props.locationData.alias !== "ULIP" ||
			props.locationData.alias !== "TRADI" ||
			props.locationData.alias !== "CIP" ||
			props.locationData.alias !== "CEP" ||
			props.locationData.alias !== "TIP" ||
			props.locationData.alias !== "RIP"
		) {
			loanApplicationService
				.getLoanApplicationCreateFullList(mobileNumber)
				.then((res) => {
					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.success,
						payload: convertNullToString(res),
					});
				})
				.catch((e) => {
					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.error,
						payload: e,
					});
				})
				.finally(() =>
					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.stop,
					})
				);
		} else {
			loanApplicationService
				.getInsuranceLoanApplicationCreateFullList(mobileNumber)
				.then((res) => {
					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.success,
						payload: convertNullToString(res),
					});
				})
				.catch((e) => {
					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.error,
						payload: e,
					});
				})
				.finally(() =>
					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.stop,
					})
				);
		}
	};

	const getDistrictData = (e) => {
		const stateData = getAllDistrict.find((item) => item.id === e);
		if (stateData !== undefined) {
			const districtData = stateData.districtList.map((e) => ({
				...e,
				value: e.id,
			}));
			return districtData;
		} else {
			return [];
		}
	};

	useEffect(() => {
		let ignore = false;
		if (!ignore) {
			Promise.all([
				getReason(),
				props.locationData?.applicationId
					? getData(props.locationData?.applicationId)
					: persistSelector.countryApplicationId &&
					  getData(persistSelector.countryApplicationId),

				!persistSelector.countryApplicationId &&
					!props.locationData?.applicationId &&
					props.locationData?.mobileNumber &&
					getLoanApplicationCreateFullList(
						props.locationData?.mobileNumber
					),
			]);
		}
		return () => {
			ignore = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initialValue = {
		totalYearExperienceInMonths: "",
		totalYearExperienceInMonthsCurrentCompany: "",
		totalYearExperience: "",
		officeContactPersonName: "",
		officeContactPersonMobile: "",
		reference1Name: "",
		reference1Mobile: "",
		reference1Relation: "",
		reference1Address: "",
		referenceCity: "",
		reference1State: "",
		referencePincode: "",
		reference2Name: "",
		reference2Address: "",
		reference2City: "",
		reference2Pincode: "",
		reference2State: "",
		reference2Mobile: "",
		reference2Relation: "",
		category: "",

		idProof: "",
		addressProof: "",
		gstNumber: "",
		uploadStatementFile: "",
		grossMonthlyIncome: "",
		bankName: "",
		otherLoanAmount: "",
		loanDisbursedDate: "",
		emi: "",
		lastEmiDate: "",
		customerId: "",

		createdBy: "",
		createdOn: "",
		modifiedBy: "",
		modifiedOn: "",

		bankAccountNumber: "",
		bankAccountHolderName: "",
		bankBranch: "",
		bankIfscCode: "",
		accountType: "",
		existingLoanBankName: "",
		existingLoanAccountNumber: "",
		existingLoanTenure: "",
		loanTenure: "",
		emiStartDate: "",
		loanType: "",
		drivingLicence: "",
		rcBook: "",
		gstState: "",
		typeOfProperty: "",
		companyRegisteredOfficeAddress: "",
		coFirstName: "",
		coMiddleName: "",
		coLastName: "",
		coMobile: "",
		coPersonalMail: "",
		coGender: "",
		businessRunBy: "",
		natureOfBusiness: "",
		businessType: "",
		companyCurrentAddress: "",
		profession: "",
		// agentCode: "",
		otherReason: "",
		// age: "",
		reasonId: "",
		loanAmount: "",

		...leaddetailsstate.data,
		leadId: props.locationData?.leadId,
		coApplicantAllowed: leaddetailsstate.coApplicantAllowed
			? leaddetailsstate.coApplicantAllowed
			: false,
		coWhatsappNumberSame: leaddetailsstate.coWhatsappNumberSame
			? leaddetailsstate.coWhatsappNumberSame
			: false,
		isSubmit: leaddetailsstate.isSubmit ? leaddetailsstate.isSubmit : false,
		isDelete: leaddetailsstate.isDelete ? leaddetailsstate.isDelete : false,
		mobile: props.locationData?.mobileNumber,
		pan: props.locationData?.panNumber,
		state: props.locationData?.state,
		districtId: props.locationData?.district,
		application_date: moment().format("YYYY-MM-DD"),

		dob:
			leaddetailsstate.data.dob !== ""
				? new Date(
						moment(leaddetailsstate.data.dob).format("YYYY-MM-DD")
				  )
				: "",
		residingSince:
			leaddetailsstate.data.residingSince !== ""
				? new Date(
						moment(leaddetailsstate.data.residingSince).format(
							"YYYY-MM-DD"
						)
				  )
				: "",
		perResidingSince:
			leaddetailsstate.data.perResidingSince !== ""
				? new Date(
						moment(leaddetailsstate.data.perResidingSince).format(
							"YYYY-MM-DD"
						)
				  )
				: "",

		applicationType:
			props.locationData?.alias === "CC" ? "CREDIT_CARD" : "LOAN", //done

		bankAccountOpenedIn:
			leaddetailsstate.data.bankAccountOpenedIn !== ""
				? new Date(
						moment(
							leaddetailsstate.data.bankAccountOpenedIn
						).format("YYYY-MM-DD")
				  )
				: "",
		...((props.locationData?.applicationId ||
			persistSelector.countryApplicationId) && {
			...formState.data,
			dob:
				formState.data.dob !== ""
					? new Date(moment(formState.data.dob).format("YYYY-MM-DD"))
					: "",
			// coDob: new Date(
			// 	moment(formState.data.coDob).format("YYYY-MM-DD")
			// ),
			residingSince:
				formState.data.residingSince !== ""
					? new Date(
							moment(formState.data.residingSince).format(
								"YYYY-MM-DD"
							)
					  )
					: "",
			perResidingSince:
				formState.data.perResidingSince !== ""
					? new Date(
							moment(formState.data.perResidingSince).format(
								"YYYY-MM-DD"
							)
					  )
					: "",
			bankAccountOpenedIn:
				formState.data.bankAccountOpenedIn !== ""
					? new Date(
							moment(formState.data.bankAccountOpenedIn).format(
								"YYYY-MM-DD"
							)
					  )
					: "",
			gstState: formState.data.gstState
				? Number(formState.data.gstState)
				: "",
		}),
	};

	// handle form submit

	const handleFormSubmit = (value, acton) => {
		const reqBody = {
			...value,

			dob:
				value.dob !== ""
					? moment(value.dob).format("YYYY-MM-DD")
					: null,
			coDob:
				value.coDob && value.coDob !== ""
					? moment(value.coDob).format("YYYY-MM-DD")
					: null,
			residingSince:
				value.residingSince !== ""
					? moment(value.residingSince).format("YYYY-MM-DD")
					: null,
			perResidingSince:
				value.perResidingSince !== ""
					? moment(value.perResidingSince).format("YYYY-MM-DD")
					: null,
			bankAccountOpenedIn:
				value.bankAccountOpenedIn !== ""
					? moment(value.bankAccountOpenedIn).format("YYYY-MM-DD")
					: null,
			age:
				value.dob !== ""
					? new Date().getFullYear() -
					  new Date(value.dob).getFullYear()
					: "",
			otherReason: value.reasonId !== 0 ? null : value.otherReason,
		};

		const param = props.locationData?.applicationId
			? reqBody
			: {
					...reqBody,
					program: { id: props.locationData?.programId },
					...(persistSelector.countryApplicationId && {
						id: persistSelector.countryApplicationId,
					}),
			  };

		const data = convertObjNull(param);
		setLoader(true);
		loanService
			.loanSaveDraft(data)
			.then((res) => {
				!props.locationData?.applicationId &&
					!persistSelector.countryApplicationId &&
					dispatch(setCountryApplicationId(res.id));
				props.locationData?.applicationId
					? getData(props.locationData?.applicationId)
					: res.id && getData(res.id);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail:
						props.locationData?.applicationId || res.id
							? "Application updated"
							: "Application saved",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoader(false);
			});
	};

	const finalSubmit = (applicationId) => {
		setLoader(true);
		loanService
			.applicationSubmit(applicationId)
			.then((res) =>
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				})
			)

			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				navigate("/masters/loan-application-list");
				setLoader(false);
			});
	};

	const validationSchema = Yup.object().shape({
		loanAmount: Yup.number().when({
			is: () =>
				props.locationData?.alias === "PL" ||
				props.locationData?.alias === "HL" ||
				props.locationData?.alias === "LAP" ||
				props.locationData?.alias === "BL",
			then: Yup.number()
				.min(0, "Invalid")
				.required("This field is required"),
		}),
		aadhaarNo: Yup.string().min(12).nullable(),
		otherReason: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid reason name"
			)
			.nullable(),
		mobile: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable(),
		whatsappNumber: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable(),
		personalMail: Yup.string().trim().email("Invalid email").nullable(),
		pan: Yup.string()
			.matches(
				/([A-Z]{5}[0-9]{4}[A-Z]{1})/,
				"Please enter valid pancard number"
			)
			.nullable(),
		firstName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid first name"
			)
			.nullable(),
		lastName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid last name"
			)
			.nullable(),
		middleName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid middle name"
			)
			.nullable(),
		spouseName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid spouse name"
			)
			.nullable(),
		fatherName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid father name"
			)
			.nullable(),

		motherMaidenName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid mother maiden name"
			)
			.nullable(),
		coFirstName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid co-first name"
			)
			.nullable(),
		coMiddleName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid co-middle name"
			)
			.nullable(),
		coLastName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid co-last name"
			)
			.nullable(),
		coMobile: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable(),
		coPersonalMail: Yup.string().trim().email("Invalid email").nullable(),
		city: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid city"
			)
			.nullable(),
		state: Yup.string().nullable(),
		pincode: Yup.string()
			.matches(/^\d{6}$/, "Please enter valid pincode")
			// .test({
			// 	test: debounce(
			// 		(pin) =>
			// 			pin.length > 0 &&
			// 			pinValidation.varifyPin(pin).then((res) => {
			// 				if (res[0].Status === "Error") {
			// 					return false;
			// 				} else {
			// 					return true;
			// 				}
			// 			}),
			// 		1000
			// 	),
			// 	message: "Invalid Pincode",
			// })
			.nullable(),
		perCity: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid city"
			)
			.nullable(),
		perState: Yup.string().nullable(),
		perPincode: Yup.string()
			.matches(/^\d{6}$/, "Please enter valid pincode")
			.nullable(),
		officialMail: Yup.string().trim().email("Invalid email").nullable(),
		officeTelephoneNumber: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable(),
		officeCity: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid city"
			)
			.nullable(),
		officePincode: Yup.string()
			.matches(/^\d{6}$/, "Please enter valid pincode")
			.nullable(),
		bankAccountHolderName: Yup.string()
			.matches(
				/^(?=.{1,100}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid  name"
			)
			.nullable(),
		bankIfscCode: Yup.string().nullable(),
		bankAccountNumber: Yup.string().nullable(),
		bankName: Yup.string().nullable(),
		reference1Name: Yup.string()
			.matches(
				/^(?=.{1,100}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid reference name"
			)
			.nullable(),
		reference1Relation: Yup.string()
			.matches(
				/^(?=.{1,100}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid reference relation"
			)
			.nullable(),
		reference1Mobile: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable(),
		referenceCity: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid city"
			)
			.nullable(),
		referencePincode: Yup.string()
			.matches(/^\d{6}$/, "Please enter valid pincode")
			.nullable(),
		reference2Name: Yup.string()
			.matches(
				/^(?=.{1,100}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid reference name"
			)
			.nullable(),
		reference2Mobile: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable(),
		reference2Relation: Yup.string()
			.matches(
				/^(?=.{1,100}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid referene relation"
			)
			.nullable(),
		reference2City: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid city"
			)
			.nullable(),
		reference2Pincode: Yup.string()
			.matches(/^\d{6}$/, "Please enter valid pincode")
			.nullable(),
		gender: Yup.string().nullable(),
	});

	const [stages] = useState([
		{
			sequence: 1,
			title: "Select a Program",
			status: "success",
		},
		{
			sequence: 2,
			title: "Add Contact",
			status: "success",
		},

		{
			sequence: 3,
			title: "Save Application",
			status: "active",
		},
	]);

	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			<Dialog
				visible={printDialog}
				style={{ width: "80%" }}
				onHide={() => setPrintDialog(false)}
			>
				{formState.data?.state && (
					<PreviewPdf pdfState={formState.data} />
				)}
			</Dialog>
			<div className="flex align-items-center pb-4 gap-2 justify-content-end">
				{props.locationData?.applicationId && (
					<Button
						label="Export"
						className="p-button-danger"
						icon="pi pi-file-pdf"
						onClick={() => setPrintDialog(true)}
					/>
				)}

				<Button
					icon="pi pi-arrow-left"
					label="Back"
					onClick={() => {
						navigate(-1);
						// switch (persistSelector.application.name) {
						// 	case "create":
						// 		navigate("/masters/application-create");
						// 		break;
						// 	case "lead":
						// 		navigate("/masters/lead/all");
						// 		break;
						// 	case "edit":
						// 		navigate("/masters/loan-application-list");
						// 		break;
						// 	default:
						// 		break;
						// }
					}}
				/>
			</div>
			<Header
				stages={props.locationData?.applicationId ? [] : stages}
				title="Application"
				stageName={(function () {
					switch (props.locationData?.alias) {
						case "PL":
							return "Personal Loan Application";
						case "CC":
							return "Credit Card Application";
						case "HL":
							return "Home Loan Application";
						case "LAP":
							return "Loan Against Property ";
						case "BL":
							return "Business Loan Application";
						case "VL":
							return "Vehicle Loan Application";

						default:
							return "Application";
					}
				})()}
			/>
			{leaddetailsstate.isLoading && <Loader />}
			{loader && <Loader />}
			<>
				{props.locationData?.applicationId &&
					formState.data?.number && (
						<h3 className={"font-semibold mt-6 mb-0"}>
							Rainbow's Application Number (RAN) :&nbsp;&nbsp;{" "}
							{formState.data?.number}
						</h3>
					)}
				{(function (e) {
					switch (e) {
						case "PL":
							return (
								<PersonalLoan
									handleFormSubmit={handleFormSubmit}
									initialValue={initialValue}
									validationSchema={validationSchema}
									reason={reason}
									getAllDistrict={getAllDistrict}
									getDistrictData={getDistrictData}
								/>
							);
						case "CC":
							return (
								<CreditCard
									handleFormSubmit={handleFormSubmit}
									initialValue={initialValue}
									validationSchema={validationSchema}
									reason={reason}
									getAllDistrict={getAllDistrict}
									getDistrictData={getDistrictData}
								/>
							);
						case "HL":
							return (
								<HomeLoan
									handleFormSubmit={handleFormSubmit}
									initialValue={initialValue}
									validationSchema={validationSchema}
									reason={reason}
									getAllDistrict={getAllDistrict}
									getDistrictData={getDistrictData}
								/>
							);
						case "LAP":
							return (
								<HomeLoan
									handleFormSubmit={handleFormSubmit}
									initialValue={initialValue}
									validationSchema={validationSchema}
									reason={reason}
									getAllDistrict={getAllDistrict}
									getDistrictData={getDistrictData}
								/>
							);
						case "VL":
							return (
								<VehicleLoan
									handleFormSubmit={handleFormSubmit}
									initialValue={initialValue}
									validationSchema={validationSchema}
									reason={reason}
									getAllDistrict={getAllDistrict}
									getDistrictData={getDistrictData}
								/>
							);
						case "BL":
							return (
								<BusinessLoan
									handleFormSubmit={handleFormSubmit}
									initialValue={initialValue}
									validationSchema={validationSchema}
									reason={reason}
									getAllDistrict={getAllDistrict}
									getDistrictData={getDistrictData}
								/>
							);
						default:
							return <></>;
					}
				})(props.locationData?.alias)}

				<Divider />
				{(props.locationData?.applicationId ||
					persistSelector.countryApplicationId) && (
					<DocumentUpload
						applicationId={props?.locationData?.applicationId}
						subproductId={props?.locationData?.subproductId}
					/>
				)}

				{persistSelector?.applicationState !== "preview" && (
					<>
						{(props.locationData?.applicationId ||
							persistSelector.countryApplicationId) && (
							<div className="flex justify-content-end align-items-center w-full mt-4">
								<Button
									type="button"
									label="Submit"
									icon="pi pi-save"
									onClick={() => {
										finalSubmit(
											props.locationData?.applicationId
												? props.locationData
														?.applicationId
												: persistSelector.countryApplicationId
										);
									}}
								/>
							</div>
						)}
					</>
				)}
			</>
		</>
	);
};

export default ApplicationForm;
