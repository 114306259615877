import { useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { FeildDropdown, FieldInput } from "../../../components/FieldInput";
import Config from "../../../shared/config";
import { Button } from "primereact/button";
import { VendorService } from "../../../services/vendorService";
import * as Yup from "yup";
import Loader from "../../../components/Loader";
import { Toast } from "primereact/toast";
import debounce from "lodash.debounce";
import { convertObjNull } from "../../../utils/UtilsFunction";
import {
	useGetAllHubQuery,
	useGetCityListQuery,
} from "../../../services/query/queryApi";
const CreateSpoke = (props) => {
	const { data: cityList = [] } = useGetCityListQuery();
	const { data: HubList = [] } = useGetAllHubQuery();
	const toastTL = useRef(null);
	const [loader, setLoader] = useState(false);
	const vendorService = new VendorService();
	const initialValue = {
		name: "",
		cityId: "",
		hubId: "",
	};
	const validationSchema = Yup.object().shape({
		name: Yup.string().required("This field is required"),
		cityId: Yup.number().required("This field is required"),
	});
	const handleFormSubmit = (value) => {
		if (props.dialogState?.type === "create") {
			setLoader(true);
			const data = convertObjNull(value);
			vendorService
				.createspoke(data)
				.then(() => {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: "Spoke created successfully",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.then(
					debounce(() => {
						props.setDialog({ dialog: false });
						props.userDataTableValue();
					}, 1000)
				)
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e?.message,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => setLoader(false));
		} else {
			setLoader(true);
			vendorService
				.updatespoke(value)
				.then(() => {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: "Spoke Updated successfully",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.then(
					debounce(() => {
						props.setDialog({ dialog: false });
						props.userDataTableValue();
					}, 1000)
				)
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e?.message,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => setLoader(false));
		}
	};

	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			{loader && <Loader />}
			<Formik
				initialValues={{
					...initialValue,
					...(props.dialogState?.type === "edit" &&
						props.dialogState?.data),
				}}
				enableReinitialize
				onSubmit={handleFormSubmit}
				validationSchema={validationSchema}
			>
				{({ handleSubmit, resetForm }) => (
					<Form
						className="grid modal-form-grid mt-2"
						onSubmit={handleSubmit}
					>
						<div className="field col-12 md:col-4">
							<Field
								header={"Name"}
								name={"name"}
								component={FieldInput}
							/>
						</div>

						<div className="field col-12 md:col-4">
							<Field
								filter
								header={"HUb"}
								name={"hubId"}
								type={"number"}
								component={FeildDropdown}
								options={HubList}
								optionLabel={"name"}
								optionValue={"id"}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								filter
								header={"City"}
								name={"cityId"}
								type={"number"}
								component={FeildDropdown}
								options={cityList}
								optionLabel={"name"}
								optionValue={"id"}
							/>
						</div>
						<div className="col-12 flex gap-2">
							<Button
								icon={"pi pi-refresh"}
								label={"Reset"}
								className="danger-btn"
								type="button"
								onClick={() => resetForm()}
							/>
							<Button
								label={
									props.dialogState?.type === "create"
										? "Save"
										: "Update"
								}
								type="submit"
							/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default CreateSpoke;
