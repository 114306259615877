import { useState } from "react";
import { Button } from "primereact/button";
import Config from "../../../../shared/config";
import { Field, Form, Formik } from "formik";
import {
	FeildCalender,
	FeildDropdown,
} from "../../../../components/FieldInput";
import { useGetAllCCUserQuery } from "../../../../services/query/queryApi";
import { exportExcel } from "../../../../components/XlsxExport";
import moment from "moment";

const ActivitySearch = (props) => {
	const { data = [] } = useGetAllCCUserQuery();
	const [open, setOpen] = useState(false);
	const initialValues = {
		parentEnum: "",
		childEnum: "",
		createdBy: "",
		description: "",
		fromDate: "",
		toDate: "",
	};
	const handleSearch = (value) => {
		let data = Object.fromEntries(
			Object.entries(value).filter(([_, v]) => v !== "")
		);

		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: data,
		});
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};
	const newData = props?.newData
		? props?.newData?.map((item) => ({
				Page: item?.parentEnum?.replaceAll("_", " "),
				Topic: item?.childEnum?.replaceAll("_", " "),
				Description: item?.description,
				"Created On": item?.createdOn
					? moment(item.createdOn).format("Do MMM,YYYY HH:mm A")
					: "",
				"Created By":
					(item?.fullName ? item?.fullName : "") +
					"(" +
					(item?.createdBy ? item?.createdBy : "") +
					")",
		  }))
		: [];
	return (
		<>
			<div className="w-full py-3 flex align-items-center justify-content-between">
				<h3 className="m-0">User Activity</h3>
				<div className="flex gap-2">
					<Button
						label={open ? "Close" : "Search"}
						icon={open ? "pi pi-times" : "pi pi-search"}
						className={
							open
								? "p-button-outlined p-button-danger"
								: "p-button-outlined"
						}
						aria-label="Cancel"
						onClick={() => setOpen((prev) => !prev)}
					/>
					<Button
						label="Reload"
						icon="pi pi-refresh"
						className="p-button-outlined"
						onClick={() => {
							props.searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							});
						}}
					/>
					<Button
						type="button"
						icon="pi pi-file-excel"
						className="p-button-success "
						onClick={() => exportExcel(newData, "Activity_list")}
						tooltip="Export Excel"
						tooltipOptions={{ position: "left" }}
					/>
				</div>
			</div>

			{open && (
				<Formik
					initialValues={initialValues}
					enableReinitialize
					onSubmit={handleSearch}
				>
					{({ handleSubmit, resetForm }) => (
						<Form
							className="background-secondary mt-2 c-search-form-box border-round-md form-grid grid mb-3"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 md:col-3">
								<Field
									header={"From Date"}
									name={"fromDate"}
									component={FeildCalender}
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									header={"To Date"}
									name={"toDate"}
									component={FeildCalender}
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									header={"Page"}
									name={"parentEnum"}
									component={FeildDropdown}
									options={Config.PARENT_USER_ACTIVITY}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Topic"}
									name={"childEnum"}
									component={FeildDropdown}
									filter
									options={Config.CHILD_USER_ACTIVITY}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Created By"}
									name={"createdBy"}
									filter
									component={FeildDropdown}
									options={data?.map((elm) => ({
										...elm,
										fullName:
											elm.fullName +
											"(" +
											elm.userName +
											")",
									}))}
									optionLabel="fullName"
									optionValue="userName"
								/>
							</div>

							<div className="col-3 ml-auto flex align-items-end justify-content-end">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Search" />
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default ActivitySearch;
