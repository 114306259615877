import { useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
// import * as Yup from "yup";
// import { ProgramService } from "../../../../../services/ProgramService";
// import { S3 } from "aws-sdk";
// import moment from "moment";

import { FileUpload } from "primereact/fileupload";
import { S3 } from "aws-sdk";
import { Toast } from "primereact/toast";
import moment from "moment";
import * as Yup from "yup";
import { ProgramService } from "../../../../../../services/ProgramService";
import Config from "../../../../../../shared/config";
import Loader from "../../../../../../components/Loader";
import {
	FeildCalender,
	FeildDropdown,
	FieldEditor,
	FieldInput,
} from "../../../../../../components/FieldInput";
import { InputNode } from "../../../../../../components/InputComponent";
import { useNavigate } from "react-router-dom";
const OfferUpdate = (props) => {
	const navigate = useNavigate();
	const programService = new ProgramService();
	const [spinner, setSpinner] = useState(false);
	const initialValue = {
		programId: props.programId,
		name: "",
		startDate: "",
		endDate: "",
		details: "",
		image: "",
		joiningFee: false,
		annualFee: "",
		preClosureCharge: false, //NPA
		processingFees: false, //isProcessingFeess
		rateOfInterest: "", //rateOfInterest
	};
	const toastTL = useRef(null);
	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});
	const onUploadImage = (e, setFieldValue) => {
		//setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.INSTITUTION_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Something went wrong",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successful",
					detail: "Contract document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});

				setFieldValue("image", newFileName);
			}
		});
	};
	const handleFormSubmit = (val, action) => {
		if (props?.typeOption === "edit") {
			if (props.type && props.type === "edit") {
				const { startDate, endDate, alias, ...rest } = val;
				const newData = {
					...rest,
					programId: props.programId,
					startDate: startDate
						? moment(startDate).format("YYYY-MM-DD")
						: "",
					endDate: endDate
						? moment(endDate).format("YYYY-MM-DD")
						: "",
				};
				setSpinner(true);
				programService
					.offerUpdate(newData)
					.then(() =>
						toastTL.current.show({
							severity: "success",
							summary: "Successful",
							detail: "Updated",
							style: { color: "#000000" },
							life: 3000,
						})
					)
					.catch(() =>
						toastTL.current.show({
							severity: "error",
							summary: "Error",
							detail: "Something went wrong",
							style: { color: "#000000" },
							life: 3000,
						})
					)
					.finally(() => setSpinner(false));
			} else {
				setSpinner(true);
				const { startDate, endDate, alias, ...rest } = val;
				const newData = {
					...rest,
					programId: props.programId,
					startDate: moment(startDate).format("YYYY-MM-DD"),
					endDate: moment(endDate).format("YYYY-MM-DD"),
				};
				programService
					.offerCreate([newData])
					.then((res) => {
						toastTL.current.show({
							severity: "success",
							summary: "Successful",
							detail: "Offer Created",
							style: { color: "#000000" },
							life: 3000,
						});
						action.resetForm();
					})
					.catch(() =>
						toastTL.current.show({
							severity: "error",
							summary: "Error",
							detail: "Something went wrong",
							style: { color: "#000000" },
							life: 3000,
						})
					)
					.finally(() => {
						setSpinner(false);
						props.getData();
					});
			}
		}
		if (props?.typeOption === "copy") {
			if (props.type && props.type === "edit") {
				const { startDate, endDate, alias, ...rest } = val;
				const newData = {
					...rest,
					programId: props.programId,
					startDate: startDate
						? moment(startDate).format("YYYY-MM-DD")
						: "",
					endDate: endDate
						? moment(endDate).format("YYYY-MM-DD")
						: "",
				};
				setSpinner(true);
				programService
					.offerUpdate(newData)
					.then(() =>
						toastTL.current.show({
							severity: "success",
							summary: "Successful",
							detail: "Updated",
							style: { color: "#000000" },
							life: 3000,
						})
					)
					.catch(() =>
						toastTL.current.show({
							severity: "error",
							summary: "Error",
							detail: "Something went wrong",
							style: { color: "#000000" },
							life: 3000,
						})
					)
					.finally(() => setSpinner(false));
			} else {
				setSpinner(true);
				const { startDate, endDate, alias, ...rest } = val;
				const newData = {
					...rest,
					programId: props.programId,
					startDate: moment(startDate).format("YYYY-MM-DD"),
					endDate: moment(endDate).format("YYYY-MM-DD"),
				};
				programService
					.offerCreate([newData])
					.then((res) => {
						toastTL.current.show({
							severity: "success",
							summary: "Successful",
							detail: "Offer Created",
							style: { color: "#000000" },
							life: 3000,
						});
						action.resetForm();
					})
					.catch(() =>
						toastTL.current.show({
							severity: "error",
							summary: "Error",
							detail: "Something went wrong",
							style: { color: "#000000" },
							life: 3000,
						})
					)
					.finally(() => {
						setSpinner(false);
						props.getData();
					});
			}
		}
		if (props?.typeOption === "create") {
			setSpinner(true);
			const { startDate, endDate, alias, ...rest } = val;
			const newData = {
				...rest,
				programId: props.programId,
				startDate: moment(startDate).format("YYYY-MM-DD"),
				endDate: moment(endDate).format("YYYY-MM-DD"),
			};
			programService
				.offerCreate([newData])
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Successful",
						detail: "Offer Created",
						style: { color: "#000000" },
						life: 3000,
					});
					navigate(-1);
				})
				.catch(() =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: "Something went wrong",
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => {
					setSpinner(false);
					props.getData();
				});
		}

		// if (props.type && props.type === "edit") {
		// 	const { startDate, endDate, alias, ...rest } = val;
		// 	const newData = {
		// 		...rest,
		// 		programId: props.programId,
		// 		startDate: startDate
		// 			? moment(startDate).format("YYYY-MM-DD")
		// 			: "",
		// 		endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
		// 	};
		// 	setSpinner(true);
		// 	programService
		// 		.offerUpdate(newData)
		// 		.then(() =>
		// 			toastTL.current.show({
		// 				severity: "success",
		// 				summary: "Successfull",
		// 				detail: "Updated",
		// 				style: { color: "#000000" },
		// 				life: 3000,
		// 			})
		// 		)
		// 		.catch(() =>
		// 			toastTL.current.show({
		// 				severity: "error",
		// 				summary: "Error",
		// 				detail: "Somthing went worng",
		// 				style: { color: "#000000" },
		// 				life: 3000,
		// 			})
		// 		)
		// 		.finally(() => setSpinner(false));
		// } else {
		// 	setSpinner(true);
		// 	const { startDate, endDate, alias, ...rest } = val;
		// 	const newData = {
		// 		...rest,
		// 		programId: props.programId,
		// 		startDate: moment(startDate).format("YYYY-MM-DD"),
		// 		endDate: moment(endDate).format("YYYY-MM-DD"),
		// 	};
		// 	programService
		// 		.offerCreate([newData])
		// 		.then((res) =>
		// 			toastTL.current.show({
		// 				severity: "success",
		// 				summary: "Successfull",
		// 				detail: "Offer Created",
		// 				style: { color: "#000000" },
		// 				life: 3000,
		// 			})
		// 		)
		// 		.catch(() =>
		// 			toastTL.current.show({
		// 				severity: "error",
		// 				summary: "Error",
		// 				detail: "Somthing went worng",
		// 				style: { color: "#000000" },
		// 				life: 3000,
		// 			})
		// 		)
		// 		.finally(() => {
		// 			setSpinner(false);
		// 			props.getData();
		// 		});
		// }
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("This Field is required"),
		startDate: Yup.date().required("This Field is required"),
		endDate: Yup.date()
			.when("startDate", (startDate, value) => {
				return value.test({
					test: (endDate) =>
						!!startDate && new Date(endDate) > new Date(startDate),
					message: "Invalid date",
				});
			})
			.required("This Field is required"),
		details: Yup.string().required("This Field is required"),
		rateOfInterest: Yup.number()
			.typeError("Enter a valid number")
			.min(0, "Invalid number")
			.required("This Field is required"),
	});
	return (
		<>
			{spinner && <Loader />}
			<Toast ref={toastTL} />
			<Formik
				initialValues={{
					...initialValue,
					...(props.item && {
						...props.item,
						startDate: props.item.startDate
							? new Date(props.item.startDate)
							: "",
						endDate: props.item.endDate
							? new Date(props.item.endDate)
							: "",
					}),
				}}
				onSubmit={handleFormSubmit}
				enableReinitialize
				validationSchema={validationSchema}
			>
				{({ handleSubmit, setFieldValue, values }) => (
					<Form
						className="form-grid grid mb-3"
						onSubmit={handleSubmit}
					>
						<div className="field col-12 md:col-6">
							<Field
								component={FieldInput}
								name="name"
								header={"Offer Name"}
								star={"*"}
							/>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								component={FeildCalender}
								name="startDate"
								header={"Start Date"}
								star={"*"}
							/>
						</div>
						<div className="field col-12  md:col-3">
							<Field
								component={FeildCalender}
								name="endDate"
								header={"End Date"}
								star={"*"}
							/>
						</div>

						<div className="field col-12 md:col-6">
							<Field
								component={FieldEditor}
								name="details"
								header={"Offer Details"}
								star={"*"}
							/>
						</div>

						<div className="field col-12 md:col-6">
							{values.image && (
								<a
									href={`${Config.AGENT_BUCKET_ACCESS_URL}/institution-docs/${values.image}`}
									target="_blank"
									className="flex align-items-center justify-content-start cr-imgBx"
									rel="noreferrer"
								>
									{/* <i
												className={`text-2xl pi ${
													getFileExtension(
														img.documentFileName
													) === "pdf"
														? "pi-file-pdf text-red-400"
														: "pi-image text-primary-700"
												}`}
											/> */}

									<span className="">
										<img
											className="w-10"
											src={`${Config.AGENT_BUCKET_ACCESS_URL}/institution-docs/${values.image}`}
											alt=""
										/>
									</span>
								</a>
							)}
							<InputNode header={"Upload Image "}>
								<FileUpload
									name="image"
									customUpload
									accept=".png,.jpeg,.jpg"
									uploadHandler={(e) =>
										onUploadImage(e, setFieldValue)
									}
									maxFileSize={1000000}
									emptyTemplate={
										<p className="m-0">
											Drag and drop files to here to
											upload.
										</p>
									}
								/>
							</InputNode>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								component={FeildDropdown}
								name={"processingFees"}
								options={Config.YES_NO}
								header={"Processing Fee"}
								star="*"
							/>
						</div>

						<div className="field col-12 md:col-3">
							<Field
								type="number"
								component={FieldInput}
								name={"rateOfInterest"}
								header={"Interest Rate"}
								star="*"
							/>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								component={FeildDropdown}
								// name={"preClosure"}
								name={"preClosureCharge"}
								options={Config.YES_NO}
								header={"Pre-closure"}
								star="*"
							/>
						</div>

						{props.alias !== undefined && props.alias === "CC" && (
							<>
								<div className="field col-12 md:col-3">
									<Field
										component={FeildDropdown}
										name={"annualFee"}
										options={Config.YES_NO}
										header={"Annual Fee"}
										star="*"
									/>
								</div>

								<div className="field col-12 md:col-3">
									<Field
										component={FeildDropdown}
										name={"joiningFee"}
										options={Config.YES_NO}
										header={"Joining Fee"}
										star="*"
									/>
								</div>
							</>
						)}

						<div className="col-12 flex align-item-center gap-2 justify-content-end">
							<Button
								type="reset"
								label="Reset"
								className="danger-btn"
								icon="pi pi-refresh"
							/>
							<Button
								type="submit"
								icon="pi pi-save"
								label={props.type === "edit" ? "Update" : "Add"}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default OfferUpdate;
