import { Field } from "formik";
import React, { useState } from "react";
import { FieldMultiselect } from "../../../../components/FieldInput";
import { useGetProductDetailsQuery } from "../../../../services/query/queryApi";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const ProductPurchaseHistory = (props) => {
	const { data = { data: [] } } = useGetProductDetailsQuery({
		searchRequest: [],
		pageNo: 1,
		pageSize: 10000,
		sorts: [],
	});

	const [selectProduct, setSelectProduct] = useState(false);

	// const productData = data.data?.map((elm) => ({
	// 	key: elm.id,
	// 	label: elm.title,
	// 	data: elm,

	// 	children: elm.subProducts.map((em) => ({
	// 		key: em.id,
	// 		label: em.title,
	// 		data: em,
	// 	})),
	// }));
	const handleProductHange = (e, values, setFieldValue) => {
		setFieldValue(props.includeProductList, e.value);
		const newData = props.values[props.includeSubProductList].filter(
			(elm) => e.value.some((item) => item.id === elm.parentId)
		);
		setFieldValue(props.includeSubProductList, newData);
	};

	return (
		props.values && (
			<div className="c-include-box-in">
				<div className="flex gap-3 align-items-center py-3 flex-wrap ">
					<p className="m-0">Those who </p>
					<Field
						// className={"w-10rem"}
						name={`${props.includeBuyType}`}
						as="select"
						placeholder={`Select ${props.initialValues}`}
					>
						{props.dropdownoptions.map((elm, index) => (
							<option value={elm.value} key={index}>
								{elm.label}
							</option>
						))}
					</Field>
					{/* {console.log(props.values.product)} */}
					{/* <Field
							className={"w-14rem border-round-md h-2rem"}
							placeholder={`Select ${
								Object.keys(props.initialValues)[0]
							}`}
							name={`${Object.keys(props.initialValues)[0]}`}
							component={FeildDropdown}
							options={props.dropdownoptions}
						/> */}
					<p className="m-0">these products</p>
					<div className="c-products-list">
						{props.values[props.includeProductList] &&
							props.values[props.includeProductList].map(
								(elm) => (
									<span
										key={elm.id}
										className="bg-indigo-100 px-3 text-sm font-bold py-2 text-indigo-700 border-round-3xl"
									>
										{elm.title}
									</span>
								)
							)}
						<Button
							icon="pi pi-plus"
							className="p-button-rounded p-button-text p-button-sm"
							aria-label="Product"
							style={{ height: 30 }}
							label="Products"
							type="button"
							onClick={() => setSelectProduct(true)}
						/>
					</div>
					{/* <Field
							className={"w-14rem"}
							placeholder={"Product"}
							name={"product"}
							component={FieldMultiselect}
							optionLabel={"title"}
							options={data.data}
						/> */}
					<Dialog
						visible={selectProduct}
						header={"Select"}
						onHide={() => setSelectProduct(false)}
						style={{ width: "60%" }}
					>
						{/* <TreeTable
								value={productData}
								selectionMode="checkbox"
								selectionKeys={selectedNodeKeys3}
								onSelectionChange={(e) =>
									handleProductChange(e.value)
								}
								paginator
								// paginatorTemplate={template2}
								rows={5}
							>
								<Column
									headerStyle={{
										paddingTop: "17px",
										paddingBottom: "17px",
									}}
									field="lineOfBusiness"
									header="Line Of Business"
									expander
									bodyStyle={{
										whiteSpace: "nowrap",
										textOverflow: "ellipsis",
										overflow: "hidden",
									}}
								></Column>
								<Column
									field="title"
									header="Product Name"
								></Column>
								
							</TreeTable> */}
						<div className="modal-form-grid">
							<div className="flex gap-3 py-3">
								<Field
									className={"w-14rem"}
									placeholder={"Product"}
									name={props.includeProductList}
									component={FieldMultiselect}
									optionLabel={"title"}
									options={data.data}
									onChange={(e) => {
										handleProductHange(
											e,
											props.values,
											props.setFieldValue
										);
									}}
								/>
								{props.values[props.includeProductList] && (
									<Field
										className={"w-14rem"}
										placeholder={"Sub Product"}
										name={props.includeSubProductList}
										component={FieldMultiselect}
										optionLabel={"title"}
										options={
											props.values[
												props.includeProductList
											]
										}
										optionGroupLabel="title"
										optionGroupChildren="subProducts"
									/>
								)}
								{/* <h3>Subproducts</h3> */}

								<Button
									label="Save"
									className=""
									onClick={() => {
										setSelectProduct(false);
									}}
								/>
							</div>
							<div className="grid ">
								{props.values[props.includeProductList].length >
									0 && (
									<div className="col-12 md:col-6">
										<h3>Products</h3>
										<ul>
											{props.values[
												props.includeProductList
											].map((elm) => (
												<li key={elm.id}>
													<p className="bg-indigo-100 px-3 text-sm font-bold py-2 text-indigo-700 border-round-3xl">
														{elm.title}
													</p>
												</li>
											))}
										</ul>
									</div>
								)}
								{props.values[props.includeProductList].length >
									0 && (
									<div className="col-12 md:col-6">
										<h3>Subproducts</h3>
										<ul>
											{props.values[
												props.includeSubProductList
											].map((elm) => (
												<li key={elm.id}>
													<p className="bg-indigo-100 px-3 text-sm font-bold py-2 text-indigo-700 border-round-3xl">
														{elm.title}
													</p>
												</li>
											))}
										</ul>
									</div>
								)}
							</div>
						</div>
					</Dialog>

					<p className="m-0">and these sub products </p>
					<div className="c-products-list">
						{props.values[props.includeSubProductList] &&
							props.values[props.includeSubProductList].map(
								(elm) => (
									<span
										key={elm.id}
										className="bg-indigo-100 px-3 text-sm font-bold py-2 text-indigo-700 border-round-3xl"
									>
										{elm.title}
									</span>
								)
							)}
						<Button
							icon="pi pi-plus"
							className="p-button-rounded p-button-text p-button-sm"
							aria-label="Sub product"
							style={{ height: 30 }}
							label="Sub product"
							type="button"
							onClick={() => setSelectProduct(true)}
						/>
					</div>
					{props.months && (
						<>
							<p className="m-0">in last</p>

							<Field
								placeholder={"months"}
								name={props.monthName}
							/>
							<p className="m-0">months.</p>
						</>
					)}
				</div>
			</div>
		)
	);
};

export default ProductPurchaseHistory;
