import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
	FeildCalender,
	// FeildCalender,
	FeildDropdown,
	FieldCheckBox,
	FieldInput,
} from "../../../../components/FieldInput";
import Loader from "../../../../components/Loader";
import { exportExcel } from "../../../../components/XlsxExport";
import { AgentService } from "../../../../services/AgentService";
import { LoanApplicationService } from "../../../../services/LoanApplication";
import { CallCenterService } from "../../../../services/callCenterService";
import { useGetStateListQuery } from "../../../../services/query/queryApi";
import Config from "../../../../shared/config";

function SearchCCAgent(props) {
	const { data: stateData = [] } = useGetStateListQuery();
	const [district, setDistrict] = useState([]);
	const [userList, setUserList] = useState([]);
	const [rmuserList, setrmUserList] = useState([]);
	const [show, setShow] = useState(false);
	const loanApplicationService = new LoanApplicationService();
	const toastTL = useRef(null);
	const userService = new AgentService();
	const [loader, setLoader] = useState(false);
	const selector = useSelector(
		(state) => state.persistData?.auth?.user?.role?.position
	);
	const callcenterService = new CallCenterService();

	const getTsrmBYHubSpoke = (val) => {
		callcenterService.getTsrmByHubIdspokeId(val).then((res) => {
			setUserList(res?.data);
		});
	};

	const getrm = (val) => {
		callcenterService.getRm(val).then((res) => {
			setrmUserList(res?.data);
		});
	};
	useEffect(() => {
		selector === 1000 &&
			getTsrmBYHubSpoke({
				pageNo: 1,
				pageSize: 1000000,
				hubId: null,
				spokeId: null,
			});

		getrm({
			pageNo: 1,
			pageSize: 1000000,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selector]);

	const radioBtns = [
		{
			id: "Male",
			name: "Male",
			value: "Male",
		},
		{
			id: "Female",
			name: "Female",
			value: "Female",
		},
		{
			id: "Others",
			name: "Others",
			value: "Others",
		},
	];
	const initialValue = {
		name: "",
		gender: "",
		emailId: "",
		mobileNumber: "",
		userName: "",
		status: "",
		profileType: "",
		profileStatus: "",
		zoneId: "",
		stateId: "",
		districtId: "",
		salesUser: "",
		rmUser: "",
		agentCode: "",
		submissionDateFrom: "",
		submissionDateTo: "",
		agentEngagement: "",
		lastCalledFrom: "",
		lastCalledTo: "",

		// commissionStatus: "",
	};
	const handleForm = (value) => {
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: value,
		});
	};
	const getAllLoanData = () => {
		const requestBody = {
			pageNo: 1,
			pageSize: 100000,
		};

		setLoader(true);
		userService
			.getAgentsProfiles(requestBody)
			.then((res) => {
				const newData =
					res.data &&
					res.data.map((elm) => ({
						"Agent Id": elm.agentId,
						"Agent Code": elm.agentCode,
						Name:
							(elm.firstName ? elm.firstName : "") +
							" " +
							(elm.middleName ? elm.middleName : "") +
							" " +
							(elm.lastName ? elm.lastName : ""),

						"Email Id": elm.emailId,
						"Mobile Number": elm.mobileNumber,
						"Zone Name": elm.zoneName,
						"State Name": elm.stateName,
						"District Name": elm.districtName,

						Gender: elm.gender,
						"User Name": elm.userName,

						Status: elm.status,
						"Profile Id": elm.profileId,
						"Profile Type": elm.profileType,
						"Profile Status": elm.profileStatus,
						"Is Default": elm.isDefault,
						Pincode: elm.pincode,
						Remarks: elm.remarks,

						"Created At": elm.createdAt,
						"Sales User": elm.salesUser,
						"Rm User": elm.rmUser,
						"Selfie Verified": elm.selfieVerified,
						"Pan Verified": elm.panVerified,
						"aadhaar Verified": elm.aadhaarVerified,
					}));

				exportExcel(newData, "All_Agent_List");
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoader(false));
	};
	const items = [
		{
			label: "Export",
			icon: "pi pi-file-excel",
			command: () => {
				exportExcel(props.newData, "AgentList");
			},
		},
		{
			label: "Export All",
			icon: "pi pi-file-excel",
			command: () => {
				getAllLoanData();
			},
		},
	];
	const itemAllocate = [
		{
			label: "Allocate TSRM",
			icon: "pi pi-arrow-down",
			command: () => {
				props.setAllocateAgentDialog((e) => ({
					...e,
					state: true,
					id: props.selectedProducts.map((item) => item.agentId),
				}));
			},
		},
		{
			label: "Set Reminder",
			icon: "pi pi-history",
			command: () => {
				props.setReminderDetailDialog((prev) => ({
					...prev,
					state: true,
					id: props.selectedProducts.map((item) => item.agentId),
				}));
			},
		},
	];
	const handleDistrict = (e, setFieldValue) => {
		setFieldValue("districtId", "", true);
		loanApplicationService
			.getDistrictByStateID(e)
			.then((res) => {
				setDistrict(res);
			})
			.catch(() => {});
		setFieldValue("stateId", e, true);
	};

	return (
		<>
			{loader && <Loader />}
			<Toast ref={toastTL} />
			<div className="col-12 w-full flex align-items-end justify-content-between">
				<h3>All Agent List</h3>
				<div className="flex gap-2">
					<Button
						icon={show ? "pi pi-times" : "pi pi-search"}
						className={
							show
								? "p-button-danger p-button-outlined"
								: "p-button-outlined"
						}
						label={show ? "Close" : "Search"}
						onClick={() => {
							setShow((show) => !show);
						}}
					/>
					<Button
						className=" p-button-outlined"
						onClick={() =>
							props.searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							})
						}
					>
						<i className="pi pi-refresh mr-1" />
						Reload
					</Button>
					{props.selectedProducts.length > 0 && (
						<SplitButton
							icon={"pi pi-bars"}
							label="Action"
							model={itemAllocate}
							// loading={spinner}
						/>
					)}
					{/* <SplitButton
						icon={"pi pi-file-export"}
						label="Export"
						model={items}
						// loading={spinner}
					/> */}
				</div>
			</div>
			{show && (
				<Formik initialValues={initialValue} onSubmit={handleForm}>
					{({ values, handleSubmit, setFieldValue, resetForm }) => (
						<Form
							onSubmit={handleSubmit}
							className="background-secondary mt-2 c-search-form-box border-round-md form-grid grid"
						>
							<div className="field col-12 md:col-3">
								<Field
									header={"From Date"}
									name={"submissionDateFrom"}
									component={FeildCalender}
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									header={"To Date"}
									name={"submissionDateTo"}
									component={FeildCalender}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Profile Status"}
									name={"profileStatus"}
									component={FeildDropdown}
									options={Config.ProfileStatus}
								/>
							</div>
							{selector === 1000 && (
								<div className="field col-12 md:col-3">
									<Field
										header={"Assign to (TSRM)"}
										name={"assignedTo"}
										filter
										component={FeildDropdown}
										options={userList.map((elm) => ({
											...elm,
											name:
												(elm?.firstName
													? elm?.firstName
													: "") +
												" " +
												(elm?.middleName
													? elm?.middleName
													: "") +
												" " +
												(elm?.lastName
													? elm?.lastName
													: "") +
												" (" +
												elm?.userName +
												")",
										}))}
										optionLabel="name"
										optionValue={"userName"}
									/>
								</div>
							)}
							<div className="field col-12 md:col-3">
								<Field
									header={"Name"}
									name={"name"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Mobile"}
									name={"mobileNumber"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Agent Code"}
									name={"agentCode"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Username"}
									name={"userName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Profile Type"}
									name={"profileType"}
									component={FeildDropdown}
									options={Config.ProfileTypeSearch}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"State"}
									name={"stateId"}
									component={FeildDropdown}
									options={stateData}
									filter
									onChange={(e) => {
										handleDistrict(e.value, setFieldValue);
									}}
									optionLabel={"name"}
									optionValue={"id"}
									type={"number"}
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									header={"District"}
									name={"districtId"}
									component={FeildDropdown}
									filter
									options={district}
									optionLabel={"name"}
									optionValue={"id"}
									type={"number"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Agent Engagement"}
									name={"agentEngagement"}
									component={FeildDropdown}
									options={[
										{
											label: "Featured",
											value: "FEATURED",
										},
										{ label: "Engaged", value: "ENGAGED" },
										{ label: "Dormant", value: "DORMANT" },
										{
											label: "Follow Up",
											value: "FOLLOW_UP",
										},
									]}
								/>
							</div>
							{/* <div className="field col-12 md:col-3">
								<Field
									header={"Email"}
									name={"emailId"}
									component={FieldInput}
								/>
							</div> */}

							{/* <div className="field col-12 md:col-3">
								<Field
									header={"RM/ASM username"}
									name={"rmUser"}
									component={FieldInput}
								/>
							</div> */}
							{/* <div className="field col-12 md:col-3">
								<Field
									header={"RM/ASM"}
									name={"rmUser"}
									filter
									component={FeildDropdown}
									options={rmuserList.map((elm) => ({
										...elm,
										name:
											(elm?.firstName
												? elm?.firstName
												: "") +
											" " +
											(elm?.middleName
												? elm?.middleName
												: "") +
											" " +
											(elm?.lastName
												? elm?.lastName
												: "") +
											" (" +
											elm?.userName +
											")",
									}))}
									optionLabel="name"
									optionValue={"userName"}
								/>
							</div> */}

							{/* <div className="field col-12 md:col-3">
								<Field
									header={"TSE username"}
									name={"salesUser"}
									component={FieldInput}
								/>
							</div> */}

							{/* <div className="field col-12 md:col-3">
								<Field
									header={"Gender"}
									name={"gender"}
									component={FieldRadio}
									radiolist={radioBtns}
								/>
							</div> */}
							<div className="field col-12 md:col-3">
								<Field
									header={"Last Call From Date"}
									name={"lastCalledFrom"}
									component={FeildCalender}
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									header={"Last Call From To Date"}
									name={"lastCalledTo"}
									component={FeildCalender}
								/>
							</div>
							<div className="field col-12 md:col-3">
								{selector === 1000 && (
									<Field
										header={"Unassign TSRM"}
										name={"unassignTsrm"}
										component={FieldCheckBox}
									/>
								)}
							</div>

							<div className="field flex pt-4  ml-auto gap-2 justify-content-end ">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger "
									onClick={() => resetForm()}
								/>
								<Button type="submit" label="Search" />
							</div>
							{/* <div className="field col-12 md:col-3">
								<Field
									header={"Commission Status"}
									name={"commissionStatus"}
									component={FeildDropdown}
									options={Config.ProfileStatus}
								/>
							</div> */}
						</Form>
					)}
				</Formik>
			)}
		</>
	);
}

export default SearchCCAgent;
