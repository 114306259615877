import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import { useRef, useState } from "react";
import {
	// FeildCalender,
	FeildDropdown,
	FieldInput,
	FieldRadio,
} from "../../../../components/FieldInput";
import Config from "../../../../shared/config";
import SearchFieldGroup from "../../../../components/SearchFieldGroup";
import { SplitButton } from "primereact/splitbutton";
import { exportExcel } from "../../../../components/XlsxExport";
import Loader from "../../../../components/Loader";
import { Toast } from "primereact/toast";
import { AgentService } from "../../../../services/AgentService";
import { useNavigate } from "react-router-dom";

function SearchAgent(props) {
	const [show, setShow] = useState(false);
	const navigate = useNavigate();
	const toastTL = useRef(null);
	const userService = new AgentService();
	const [loader, setLoader] = useState(false);
	const radioBtns = [
		{
			id: "Male",
			name: "Male",
			value: "Male",
		},
		{
			id: "Female",
			name: "Female",
			value: "Female",
		},
		{
			id: "Others",
			name: "Others",
			value: "Others",
		},
	];
	const initialValue = {
		name: "",
		gender: "",
		emailId: "",
		mobileNumber: "",
		userName: "",
		status: "",
		profileType: "",
		profileStatus: "",
		zoneId: "",
		stateId: "",
		districtId: "",
		salesUser: "",
		rmUser: "",
		agentCode: "",
		// commissionStatus: "",
	};
	const handleForm = (value) => {
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: value,
		});
	};
	const getAllLoanData = () => {
		const requestBody = {
			pageNo: 1,
			pageSize: 100000,
		};

		setLoader(true);
		userService
			.getAgentsProfiles(requestBody)
			.then((res) => {
				const newData =
					res.data &&
					res.data.map((elm) => ({
						"Agent Id": elm.agentId,
						"Agent Code": elm.agentCode,
						Name:
							(elm.firstName ? elm.firstName : "") +
							" " +
							(elm.middleName ? elm.middleName : "") +
							" " +
							(elm.lastName ? elm.lastName : ""),

						"Email Id": elm.emailId,
						"Mobile Number": elm.mobileNumber,
						"Zone Name": elm.zoneName,
						"State Name": elm.stateName,
						"District Name": elm.districtName,

						Gender: elm.gender,
						"User Name": elm.userName,

						Status: elm.status,
						"Profile Id": elm.profileId,
						"Profile Type": elm.profileType,
						"Profile Status": elm.profileStatus,
						"Is Default": elm.isDefault,
						Pincode: elm.pincode,
						Remarks: elm.remarks,

						"Created At": elm.createdAt,
						"Sales User": elm.salesUser,
						"Rm User": elm.rmUser,
						"Selfie Verified": elm.selfieVerified,
						"Pan Verified": elm.panVerified,
						"aadhaar Verified": elm.aadhaarVerified,
					}));

				exportExcel(newData, "All_Agent_List");
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoader(false));
	};
	const items = [
		{
			label: "Export",
			icon: "pi pi-file-excel",
			command: () => {
				exportExcel(props.newData, "AgentList");
			},
		},
		{
			label: "Export All",
			icon: "pi pi-file-excel",
			command: () => {
				getAllLoanData();
			},
		},
	];
	return (
		<>
			{loader && <Loader />}
			<Toast ref={toastTL} />
			<div className="col-12 w-full flex align-items-end justify-content-between">
				<h3>All Agent List</h3>
				<div className="flex gap-2">
					<Button
						icon={show ? "pi pi-times" : "pi pi-search"}
						className={
							show
								? "p-button-danger p-button-outlined"
								: "p-button-outlined"
						}
						label={show ? "Close" : "Search"}
						onClick={() => {
							setShow((show) => !show);
						}}
					/>
					<Button
						className=" p-button-outlined"
						onClick={() =>
							props.searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							})
						}
					>
						<i className="pi pi-refresh mr-1" />
						Reload
					</Button>
					<Button
						className=""
						onClick={() =>
							navigate("/members/agent-profile-update-request")
						}
					>
						<i className="pi pi-user mr-1" />
						Profile Update
					</Button>
					<SplitButton
						icon={"pi pi-file-export"}
						label="Export"
						model={items}
						// loading={spinner}
					/>
				</div>
			</div>
			{show && (
				<Formik initialValues={initialValue} onSubmit={handleForm}>
					{({ values, handleSubmit, setFieldValue, resetForm }) => (
						<Form
							onSubmit={handleSubmit}
							className="form-grid grid background-secondary p-4 border-round-md my-3"
						>
							<SearchFieldGroup
								values={values}
								setFieldValue={setFieldValue}
								zoneField={"zoneId"}
								stateField={"stateId"}
								districtField={"districtId"}
								// fromDate={"fromDate"}
								// toDate={"toDate"}
							/>
							<div className="field col-12 md:col-3">
								<Field
									header={"Agent Name"}
									name={"name"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Agent Code"}
									name={"agentCode"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Profile Type"}
									name={"profileType"}
									component={FeildDropdown}
									options={Config.ProfileTypeSearch}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Email"}
									name={"emailId"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Mobile"}
									name={"mobileNumber"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Username"}
									name={"userName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Gender"}
									name={"gender"}
									component={FieldRadio}
									radiolist={radioBtns}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"RM/ASM username"}
									name={"rmUser"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"TSE username"}
									name={"salesUser"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Profile Status"}
									name={"profileStatus"}
									component={FeildDropdown}
									options={Config.ProfileStatus}
								/>
							</div>
							{/* <div className="field col-12 md:col-3">
								<Field
									header={"Commission Status"}
									name={"commissionStatus"}
									component={FeildDropdown}
									options={Config.ProfileStatus}
								/>
							</div> */}

							<div className="field flex align-items-center  justify-content-end col-12">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={() => resetForm()}
								/>
								<Button type="submit" label="Search" />
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
}

export default SearchAgent;
