import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Panel } from "primereact/panel";
import React, { useState, useRef, useEffect } from "react";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
	FieldRadio,
	FieldTextArea,
} from "../components/FieldInput";
import Config from "../shared/config";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { convertNullToString, convertObjNull } from "../utils/UtilsFunction";
import ExternalService from "./ExtService";
import ExtDocumentUpload from "./DocumentUploadExternal";
import * as Yup from "yup";
import { Divider } from "primereact/divider";
import { ConnectedFocusError } from "focus-formik-error";

export const validationSchema = Yup.object().shape({
	otherReason: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid reason name"
		)
		.nullable(),
	mobile: Yup.string()
		.matches(/^[1-9]\d{9}$/gi, "Mobile number should be 10 digit number")
		.nullable(),
	whatsappNumber: Yup.string()
		.matches(/^[1-9]\d{9}$/gi, "Mobile number should be 10 digit number")
		.nullable(),
	personalMail: Yup.string().trim().email("Invalid email").nullable(),
	pan: Yup.string()
		.matches(
			/([A-Z]{5}[0-9]{4}[A-Z]{1})/,
			"Please enter valid pancard number"
		)
		.nullable(),
	firstName: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid first name"
		)
		.nullable(),
	lastName: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid last name"
		)
		.nullable(),
	middleName: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid middle name"
		)
		.nullable(),
	spouseName: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid spouse name"
		)
		.nullable(),
	fatherName: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid father name"
		)
		.nullable(),
	motherMaidenName: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid mother maiden name"
		)
		.nullable(),
	coFirstName: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid co-first name"
		)
		.nullable(),
	coMiddleName: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid co-middle name"
		)
		.nullable(),
	coLastName: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid co-last name"
		)
		.nullable(),
	coMobile: Yup.string()
		.matches(/^[1-9]\d{9}$/gi, "Mobile number should be 10 digit number")
		.nullable(),
	coPersonalMail: Yup.string().trim().email("Invalid email").nullable(),
	city: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid city"
		)
		.nullable(),
	state: Yup.string().nullable(),
	pincode: Yup.string()
		.matches(/^\d{6}$/, "Please enter valid pincode")
		.nullable(),
	perCity: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid city"
		)
		.nullable(),
	perState: Yup.string().nullable(),
	perPincode: Yup.string()
		.matches(/^\d{6}$/, "Please enter valid pincode")
		.nullable(),
	officialMail: Yup.string().trim().email("Invalid email").nullable(),
	officeTelephoneNumber: Yup.string()
		.matches(/^[1-9]\d{9}$/gi, "Mobile number should be 10 digit number")
		.nullable(),
	officeCity: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid city"
		)
		.nullable(),
	officePincode: Yup.string()
		.matches(/^\d{6}$/, "Please enter valid pincode")
		.nullable(),
	bankAccountHolderName: Yup.string()
		.matches(
			/^(?=.{1,100}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid  name"
		)
		.nullable(),
	bankIfscCode: Yup.string().nullable(),
	bankAccountNumber: Yup.string().nullable(),
	bankName: Yup.string().nullable(),
	reference1Name: Yup.string()
		.matches(
			/^(?=.{1,100}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid reference name"
		)
		.nullable(),
	reference1Relation: Yup.string()
		.matches(
			/^(?=.{1,100}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid reference relation"
		)
		.nullable(),
	reference1Mobile: Yup.string()
		.matches(/^[1-9]\d{9}$/gi, "Mobile number should be 10 digit number")
		.nullable(),
	referenceCity: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid city"
		)
		.nullable(),
	referencePincode: Yup.string()
		.matches(/^\d{6}$/, "Please enter valid pincode")
		.nullable(),
	reference2Name: Yup.string()
		.matches(
			/^(?=.{1,100}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid reference name"
		)
		.nullable(),
	reference2Mobile: Yup.string()
		.matches(/^[1-9]\d{9}$/gi, "Mobile number should be 10 digit number")
		.nullable(),
	reference2Relation: Yup.string()
		.matches(
			/^(?=.{1,100}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid referene relation"
		)
		.nullable(),
	reference2City: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid city"
		)
		.nullable(),
	reference2Pincode: Yup.string()
		.matches(/^\d{6}$/, "Please enter valid pincode")
		.nullable(),
	gender: Yup.string().nullable(),
});

const PersonalLoan = ({ id, data, getData }) => {
	const convertData = convertNullToString(data);
	const loanService = new ExternalService();
	const toastTL = useRef(null);
	const [acceptance, setAcceptance] = useState(null);
	const [district, setDistrict] = useState([]);
	const [reason, setReason] = useState([]);

	const dateValidation = moment(new Date()).subtract(18, "years").toDate();

	const getReason = () => {
		loanService
			.getReson(
				data?.program ? data?.program?.productId?.id : data.productId
			)
			.then((res) => {
				setReason(
					res.map((e) => {
						return { ...e, value: e.id };
					})
				);
			})
			.catch((e) => console.log(e))
			.finally(() => {});
	};

	const handleDistrict = (e, setFieldValue) => {
		getDistrictList(e);
		setFieldValue("state", e, true);
	};
	const getDistrictList = (e) => {
		loanService
			.districtList(e)
			.then((res) => {
				setDistrict({
					payload: res.map((item) => ({ ...item, value: item.id })),
				});
			})
			.catch(() => {});
	};
	useEffect(() => {
		Promise.all([getReason(), getDistrictList(convertData.state)]);
		// getReason();
		// loanApplicationService
		// 	.getDistrictByStateID(convertData.state)
		// 	.then((res) => {
		// 		setDistrict({
		// 			payload: res.map((item) => ({ ...item, value: item.id })),
		// 		});
		// 	});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFormSubmit = (value, acton) => {
		const reqBody = {
			...value,

			dob:
				value.dob !== ""
					? moment(value.dob).format("YYYY-MM-DD")
					: null,
			// coDob:
			// 	value.coDob !== ""
			// 		? moment(value.coDob).format("YYYY-MM-DD")
			// 		: null,
			residingSince:
				value.residingSince !== ""
					? moment(value.residingSince).format("YYYY-MM-DD")
					: null,
			perResidingSince:
				value.perResidingSince !== ""
					? moment(value.perResidingSince).format("YYYY-MM-DD")
					: null,
			bankAccountOpenedIn:
				value.bankAccountOpenedIn !== ""
					? moment(value.bankAccountOpenedIn).format("YYYY-MM-DD")
					: null,
		};
		const submitData = convertObjNull(reqBody);

		loanService
			.draftSave(submitData)
			.then((res) => {
				if (res) {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: "Draft Save Successfully",
						life: 3000,
					});
				}
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				})
			);
	};

	const handleSubmit = () => {
		loanService
			.finalSubmit(id)
			.then((res) => {
				if (res) {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: `${convertData.program.name} Application Submit Successfully`,
						life: 3000,
					});
					getData();
				}
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			});
	};

	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			<h3 className="text-center">
				{convertData.program.name} Application
			</h3>
			<div className="px-5 py-3" style={{ background: "#f3f5f7" }}>
				<p className={"font-semibold"}>
					Rainbow's Application Number (RAN) :&nbsp;&nbsp;{" "}
					{data.number} | Date:&nbsp;&nbsp;
					{moment(data.application_date).format("Do MMM, YYYY")}
				</p>
				<Formik
					onSubmit={handleFormSubmit}
					initialValues={{
						...convertData,
						dob:
							convertData.dob !== ""
								? new Date(convertData.dob)
								: "",
						residingSince:
							convertData.residingSince !== ""
								? new Date(convertData.residingSince)
								: "",
						perResidingSince:
							convertData.perResidingSince !== ""
								? new Date(convertData.perResidingSince)
								: "",
						bankAccountOpenedIn:
							convertData.bankAccountOpenedIn !== ""
								? new Date(convertData.bankAccountOpenedIn)
								: "",
						// coDob:
						// 	convertData.coDob !== ""
						// 		? new Date(convertData.coDob)
						// 		: "",
					}}
					validationSchema={validationSchema}
					// enableReinitialize
				>
					{({
						values,
						handleChange,
						handleSubmit,
						setFieldValue,
					}) => (
						<Form onSubmit={handleSubmit} autoComplete="off">
							<ConnectedFocusError />
							<Panel
								header={"Loan Details"}
								className={"my-2"}
								toggleable
							>
								<div className="form-grid grid">
									<div className="field col-12 md:col-4">
										<Field
											header={"Reason for Loan"}
											name={"reasonId"}
											filter
											component={FeildDropdown}
											value={values.reasonId}
											options={[
												...reason,
												{
													reason: "Other",
													value: 0,
												},
											]}
											optionLabel="reason"
										/>
									</div>

									{values.reasonId === 0 && (
										<div className="field col-12 md:col-4">
											<Field
												header={"Other Reason"}
												name={"otherReason"}
												component={FieldInput}
											/>
										</div>
									)}
									<div className="field col-12 md:col-4">
										<Field
											header={"Loan Amount"}
											name={"loanAmount"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Tenure(In Months)"}
											name={"loanTenure"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="col-12 flex align-items-end justify-content-end">
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									</div>
								</div>
							</Panel>
							<Panel
								header={
									<h3 className="m-0">Personal Details</h3>
								}
								className={"my-2"}
								toggleable
							>
								<div className="form-grid grid">
									<div className="field col-12 md:col-3">
										<Field
											header={"Mobile Number"}
											name={"mobile"}
											component={FieldInput}
											type={"number"}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<label className="text-sm">
											Whatsapp No (Same as Mobile no?)
										</label>
										<div className="p-inputgroup">
											<span className="p-inputgroup-addon">
												<Checkbox
													name="whatsappNumberSame"
													onChange={handleChange}
													checked={
														values.whatsappNumberSame
													}
													value={
														values.whatsappNumberSame
													}
												/>
											</span>
											<Field
												name={"whatsappNumber"}
												value={
													values.whatsappNumberSame
														? (values.whatsappNumber =
																values.mobile)
														: values.whatsappNumber
												}
												component={FieldInput}
												disabled={
													values.whatsappNumberSame
														? true
														: false
												}
											/>
										</div>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Email"}
											name={"personalMail"}
											component={FieldInput}
											type={"email"}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"PAN"}
											name={"pan"}
											component={FieldInput}
											star={"*"}
										/>
									</div>

									<div className="field col-12 md:col-2">
										<Field
											header={"Salutation"}
											name={"salutation"}
											component={FeildDropdown}
											options={Config.SALUTATION}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"First Name"}
											name={"firstName"}
											component={FieldInput}
											star={"*"}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Middle Name"}
											name={"middleName"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Last Name"}
											name={"lastName"}
											component={FieldInput}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-2">
										<Field
											header={"Date Of Birth"}
											name={"dob"}
											component={FeildCalender}
											maxDate={dateValidation}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-2">
										<Field
											header={"Age"}
											name={"age"}
											component={FieldInput}
											value={
												Math.floor(
													moment(new Date()).diff(
														moment(
															values.dob,
															"YYYY-MM-DD"
														),
														"years",
														true
													)
												) || ""
											}
											disabled
										/>
									</div>

									<div className="field col-12 md:col-2">
										<Field
											header={"Gender"}
											name={"gender"}
											component={FeildDropdown}
											options={Config.GENDERS}
											optionLabel={"name"}
											star={"*"}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Marital Status"}
											name={"maritalStatus"}
											component={FeildDropdown}
											options={Config.MARITAL_STATUS}
										/>
									</div>

									{values.maritalStatus === "Married" && (
										<div className="field col-12 md:col-3">
											<Field
												header={"Spouse’s Full Name"}
												name={"spouseName"}
												component={FieldInput}
											/>
										</div>
									)}

									<div className="field col-12 md:col-3">
										<Field
											header={"Religion"}
											name={"religion"}
											component={FeildDropdown}
											options={Config.RELIGION}
											filter
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Cast"}
											name={"category"}
											component={FeildDropdown}
											options={Config.CATEGORY}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Father’s Name"}
											name={"fatherName"}
											component={FieldInput}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Mother’s Maiden Name"}
											name={"motherMaidenName"}
											component={FieldInput}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Residential Status"}
											name={"residentialStatus"}
											component={FeildDropdown}
											options={Config.RESIDENTIALSTATUS}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Nationality"}
											name={"nationality"}
											component={FeildDropdown}
											options={Config.NATIONALITY}
										/>
									</div>
									<div className="col-12 flex align-items-end justify-content-end">
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									</div>
								</div>
							</Panel>
							<Panel
								header={
									<h3 className="m-0">Address Details</h3>
								}
								className={"my-2"}
								toggleable
							>
								<div className="form-grid grid">
									<h4 className="col-12 ">Present Address</h4>
									<div className="field col-12 md:col-4">
										<Field
											header={"Residence Type"}
											name={"residenceType"}
											component={FeildDropdown}
											options={Config.RESIDENTTYPE}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Residing Since"}
											name={"residingSince"}
											component={FeildCalender}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Building Name/Flat No."}
											name={"buildingName"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Address Line 1"}
											name={"addressLine1"}
											component={FieldTextArea}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Address Line 2"}
											name={"addressLine2"}
											component={FieldTextArea}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Address Line 3"}
											name={"addressLine3"}
											component={FieldTextArea}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Landmark"}
											name={"landmark"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"City"}
											name={"city"}
											component={FieldInput}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"State"}
											name={"state"}
											filter
											onChange={(e) => {
												handleDistrict(
													e.value,
													setFieldValue
												);
											}}
											component={FeildDropdown}
											options={Config.stateData}
											optionLabel={"name"}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"District"}
											name={"districtId"}
											filter
											component={FeildDropdown}
											options={district.payload}
											optionLabel={"name"}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											name={"pincode"}
											header={"Pincode"}
											component={FieldInput}
											type={"number"}
											star={"*"}
										/>
									</div>

									<h4 className="col-12 ">
										Permanent Address
									</h4>
									<div className="col-12">
										<Checkbox
											name="permanentAddressSame"
											onChange={handleChange}
											checked={
												values.permanentAddressSame
											}
											value={values.permanentAddressSame}
											className={"ml-2 "}
										/>{" "}
										Same as Present Address
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Residence Type"}
											name={"perResidenceType"}
											component={FeildDropdown}
											options={Config.RESIDENTTYPE}
											value={
												values.permanentAddressSame
													? (values.perResidenceType =
															values.residenceType)
													: values.perResidenceType
											}
											disabled={
												values.permanentAddressSame
													? true
													: false
											}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Residing Since"}
											name={"perResidingSince"}
											component={FeildCalender}
											value={
												values.permanentAddressSame
													? (values.perResidingSince =
															values.residingSince)
													: values.perResidingSince
											}
											disabled={
												values.permanentAddressSame
													? true
													: false
											}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Building Name"}
											name={"perBuildingName"}
											component={FieldInput}
											value={
												values.permanentAddressSame
													? (values.perBuildingName =
															values.buildingName)
													: values.perBuildingName
											}
											disabled={
												values.permanentAddressSame
													? true
													: false
											}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Address Line 1"}
											name={"perAddressLine1"}
											value={
												values.permanentAddressSame
													? (values.perAddressLine1 =
															values.addressLine1)
													: values.perAddressLine1
											}
											component={FieldTextArea}
											disabled={
												values.permanentAddressSame
													? true
													: false
											}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Address Line 2"}
											name={"perAddressLine2"}
											value={
												values.permanentAddressSame
													? (values.perAddressLine2 =
															values.addressLine2)
													: values.perAddressLine2
											}
											component={FieldTextArea}
											disabled={
												values.permanentAddressSame
													? true
													: false
											}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Address Line 3"}
											name={"perAddressLine3"}
											value={
												values.permanentAddressSame
													? (values.perAddressLine3 =
															values.addressLine3)
													: values.perAddressLine3
											}
											component={FieldTextArea}
											disabled={
												values.permanentAddressSame
													? true
													: false
											}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Landmark"}
											name={"perLandmark"}
											value={
												values.permanentAddressSame
													? (values.perLandmark =
															values.landmark)
													: values.perLandmark
											}
											component={FieldInput}
											disabled={
												values.permanentAddressSame
													? true
													: false
											}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"City"}
											name={"perCity"}
											value={
												values.permanentAddressSame
													? (values.perCity =
															values.city)
													: values.perCity
											}
											component={FieldInput}
											disabled={
												values.permanentAddressSame
													? true
													: false
											}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"State"}
											name={"perState"}
											value={
												values.permanentAddressSame
													? (values.perState =
															values.state)
													: values.perState
											}
											component={FeildDropdown}
											options={Config.stateData}
											filter
											optionLabel={"name"}
											star={"*"}
											disabled={
												values.permanentAddressSame
													? true
													: false
											}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Pincode"}
											value={
												values.permanentAddressSame
													? (values.perPincode =
															values.pincode)
													: values.perPincode
											}
											disabled={
												values.permanentAddressSame
													? true
													: false
											}
											component={FieldInput}
											name={"perPincode"}
											type={"number"}
											star={"*"}
										/>
									</div>
									<h4 className="col-12">
										Preferred Mailing Address
									</h4>
									<div className="flex flex-wrap gap-3">
										<Field
											name={"preferredMailingAddress"}
											component={FieldRadio}
											radiolist={[
												{
													id: "Present Address",
													name: "Present Address",
													value: "Present Address",
												},
												{
													id: "Permanent Address",
													name: "Permanent Address",
													value: "Permanent Address",
												},
											]}
										/>
									</div>
									<div className="col-12 flex align-items-end justify-content-end">
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									</div>
								</div>
							</Panel>
							<Panel
								header={
									<h3 className="m-0">Occupation Details</h3>
								}
								className={"my-2"}
								toggleable
							>
								<div className="form-grid grid">
									<div className="field col-12 md:col-3">
										<Field
											header={"Highest Qualification"}
											name={"highestQualification"}
											component={FeildDropdown}
											options={Config.QUALIFICATION}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Occupation Type"}
											name={"occupationType"}
											component={FeildDropdown}
											options={Config.OCCUPATION}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Company Type"}
											name={"companyType"}
											component={FeildDropdown}
											options={Config.ORGANIZATION}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Company Name"}
											name={"companyName"}
											component={FieldInput}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Designation"}
											name={"designation"}
											component={FieldInput}
											// options={Config.DESIGNATION}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Official Email Id"}
											name={"officialMail"}
											component={FieldInput}
											type={"email"}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Office Phone Number"}
											name={"officeTelephoneNumber"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Extension"}
											name={"extension"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Industry Type"}
											name={"industryType"}
											component={FeildDropdown}
											options={Config.INDUSTRY}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={
												"Total Year Experience In Months"
											}
											name={"totalYearExperienceInMonths"}
											component={FieldInput}
											type={"number"}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={
												"Total Work Experience (in Yrs)"
											}
											name={"totalYearExperience"}
											component={FieldInput}
											value={(
												values.totalYearExperienceInMonths /
												12
											).toFixed(1)}
											type={"number"}
											disabled
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={
												"Experience In Months In Current Company"
											}
											name={
												"totalYearExperienceInMonthsCurrentCompany"
											}
											component={FieldInput}
											type={"number"}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={
												"Total Work Experience in current company (in Yrs)"
											}
											name={
												"totalYearExperienceCurrentCompany"
											}
											component={FieldInput}
											value={(
												values.totalYearExperienceInMonthsCurrentCompany /
												12
											).toFixed(1)}
											type={"number"}
											disabled
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Net Monthly Income/Salary"}
											name={"netMonthlyIncome"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Annual Turnover"}
											name={"annualTurnover"}
											component={FieldInput}
											type={"number"}
										/>
									</div>

									{/* <div className="field col-12 md:col-3">
										<Field
											header={
												"Gross Monthly Income/Salary"
											}
											name={"grossMonthlyIncome"}
											component={FieldInput}
											type={"number"}
										/>
									</div> */}

									<div className="col-12">
										<h4 className="m-0">
											Work / Office address:
										</h4>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={" Address"}
											name={"officeAddressLine1"}
											component={FieldTextArea}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Landmark"}
											name={"officeLandmark"}
											component={FieldInput}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"City"}
											name={"officeCity"}
											component={FieldInput}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"State"}
											name={"officeState"}
											component={FeildDropdown}
											options={Config.stateData}
											optionLabel={"name"}
											filter
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Pincode"}
											name={"officePincode"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="col-12 flex align-items-end justify-content-end">
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									</div>
								</div>
							</Panel>
							<Panel
								header={"Bank Details"}
								className={"my-4"}
								toggleable
							>
								<div className="form-grid grid">
									<div className="field col-12 md:col-3">
										<Field
											header={"Bank Name"}
											name={"bankName"}
											component={FieldInput}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={" Account Holder Name"}
											name={"bankAccountHolderName"}
											component={FieldInput}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Account Number"}
											name={"bankAccountNumber"}
											component={FieldInput}
											type={"number"}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Branch"}
											name={"bankBranch"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"IFSC Code"}
											name={"bankIfscCode"}
											component={FieldInput}
											star={"*"}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Account Type"}
											name={"accountType"}
											component={FeildDropdown}
											options={Config.ACCOUNTTYPE}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Opened In"}
											name={"bankAccountOpenedIn"}
											component={FeildCalender}
										/>
									</div>
								</div>
								<div className="col-12 flex align-items-end justify-content-end">
									<Button
										type="button"
										label="Save as draft"
										onClick={handleSubmit}
									/>
								</div>
							</Panel>

							{/* .....Existing Loan Details..... */}
							<Panel
								header={"Existing Loan Details"}
								className={"my-4"}
								toggleable
							>
								<div className="form-grid grid">
									<div className="field col-12 md:col-3">
										<Field
											header={"Bank Name"}
											name={"existingLoanBankName"}
											component={FieldInput}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Loan Account Number"}
											name={"existingLoanAccountNumber"}
											component={FieldInput}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"EMI"}
											name={"emi"}
											component={FieldInput}
											type={"number"}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Tenure(In Months)"}
											name={"existingLoanTenure"}
											component={FieldInput}
											type={"number"}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Loan Amount"}
											name={"otherLoanAmount"}
											component={FieldInput}
										/>
									</div>
									<div className="col-12 flex align-items-end justify-content-end">
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									</div>
								</div>
							</Panel>

							<Panel
								header={<h3 className="m-0">Other Details</h3>}
								className={"my-2"}
								toggleable
							>
								<div className="form-grid grid">
									<h4 className="col-12">
										Reference Details
									</h4>
									<h5 className="col-12 m-0">Reference 1</h5>
									<div className="field col-12 md:col-3">
										<Field
											header={"Name"}
											name={"reference1Name"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={" Mobile"}
											name={"reference1Mobile"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Relation With Borrower"}
											name={"reference1Relation"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Address"}
											name={"reference1Address"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"City"}
											name={"referenceCity"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"State"}
											name={"reference1State"}
											component={FeildDropdown}
											options={Config.stateData}
											filter
											optionLabel={"name"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Pincode"}
											name={"referencePincode"}
											component={FieldInput}
											type={"number"}
										/>
									</div>

									<h5 className="col-12 m-0">Reference 2</h5>
									<div className="field col-12 md:col-3">
										<Field
											header={"Name"}
											name={"reference2Name"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Mobile"}
											name={"reference2Mobile"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Relation With Borrower"}
											name={"reference2Relation"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Address"}
											name={"reference2Address"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"City"}
											name={"reference2City"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"State"}
											name={"reference2State"}
											component={FeildDropdown}
											options={Config.stateData}
											filter
											optionLabel={"name"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Pincode"}
											name={"reference2Pincode"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
								</div>
							</Panel>

							<div className="col-12 flex align-items-end justify-content-end">
								<Button
									type="button"
									label="Save as draft"
									onClick={handleSubmit}
								/>
							</div>
						</Form>
					)}
				</Formik>
				<Divider />
				<Panel
					header={"Document Required"}
					className={"my-4"}
					toggleable
				>
					<ExtDocumentUpload
						id={id}
						subproductId={
							data?.program
								? data?.program?.productId?.id
								: data.productId
						}
					/>
				</Panel>
				<div className="col-12">
					<Checkbox
						onChange={(e) => setAcceptance(e.checked)}
						checked={acceptance}
						className={"ml-2 "}
					/>{" "}
					I do hereby declare that all the above information given by
					me are true to the best of my knowledge and belief
				</div>
				<div className="flex justify-content-end align-items-center w-full mt-4">
					<Button
						type="cancel"
						label="Cancel"
						className="p-button-danger mr-2"
						style={{
							borderRadius: "6px",
						}}
					/>
					<Button
						type="submit"
						label="Apply Loan"
						disabled={!acceptance}
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</>
	);
};

export default PersonalLoan;
