import { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Divider } from "primereact/divider";
import { Skeleton } from "primereact/skeleton";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { InstitutionService } from "../../../services/InstitutionService";
import ProductContractDetails from "./components/ProductContractDetails";
import ProductContactDialog from "./components/ProductContactDialog";
import { ProductService } from "../../../services/ProductService";
import { FeildDropdown, FieldInput } from "../../../components/FieldInput";
import Config from "../../../shared/config";
import moment from "moment";

import InstituteImg from "../../../assets/images/institute.png";
import mainInstituteImg from "../../../assets/images/bank.png";
import Loader from "../../../components/Loader";

const validationSchema = Yup.object().shape({
	statusList: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required("Institution status is required."),
			status: Yup.string()
				.nullable()
				.required("Product status is required"),
		})
	),
});

const AddinstitutionProductsDsa = () => {
	const navigation = useNavigate();
	const toast = useRef(null);
	const { id } = useParams();
	const { state: location } = useLocation();
	const [isLoading, setisLoading] = useState(false);
	const [details, setdetails] = useState([]);
	const [products, setProducts] = useState([]);
	const [productDialog, setproductDialog] = useState(false);
	const [productLoader, setproductLoader] = useState(false);
	const [productDetails, setproductDetails] = useState(null);
	const [contractDialog, setcontractDialog] = useState(false);
	const [selectedProductIndex, setselectedproductIndex] = useState(0);
	const [selectedProduct, setselectedProduct] = useState(null);
	const [contactLodar, setContactLodar] = useState(false);
	const [contractLodar, setcontractLodar] = useState(false);
	const [statusLodar, setstatusLodar] = useState(false);
	const [contactDialog, setContactDialog] = useState(false);
	const [productStatus, setProductStatus] = useState(null);
	const [statusInitialValues, setstatusInitialValues] = useState([]);
	const [productDsaModal, setProductDsaModal] = useState(false);
	const [dsavalue, setDsavalue] = useState({});
	const institutionService = new InstitutionService();
	const productService = new ProductService();
	const tableLoaderData = Array.from({ length: 5 });
	const [formData, setFormData] = useState({});
	const [type, setType] = useState("create");
	const bodyTemplate = () => {
		return <Skeleton></Skeleton>;
	};

	useEffect(() => {
		getInstitutionDetails(id); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (productDetails) {
			let addedProductIds = productDetails.map((item) => {
				return item.productId;
			});
			const requestBody = {
				searchRequest: [],
				pageNo: 1,
				pageSize: 300,
				sorts: [],
			};
			productService.getProducts(requestBody).then((res) => {
				const products = res.data.map((item) => {
					let subProducts = item.subProducts.map((elm) => {
						if (elm.isActive) {
							return {
								subProduct: elm.title,
								id: elm.id,
								added: addedProductIds.includes(elm.id),
							};
						}
					});
					let tempSub = subProducts.filter(function (element) {
						return element !== undefined;
					});
					if (item.isActive) {
						return {
							product: item.title,
							subProducts: tempSub,
						};
					}
				});
				let tempProd = products.filter(function (element) {
					return element !== undefined;
				});

				setProducts(tempProd);
			});
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productDetails]);

	useEffect(() => {
		if (selectedProduct) {
			productService
				.getProductsStatus(selectedProduct.productId)
				.then((res) => {
					const statusArray = res.map((item) => {
						return {
							label: item.name,
							value: item.id,
						};
					});
					setProductStatus(statusArray);
				});
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedProduct]);

	useEffect(() => {
		let statusInitialValues = [];
		if (productStatus) {
			if (
				selectedProduct.institutionProductStatusList &&
				selectedProduct.institutionProductStatusList.length > 0
			) {
				selectedProduct.institutionProductStatusList.forEach((item) => {
					let index = productStatus.findIndex(
						(elm) => elm.value === item.productStatusId
					);
					if (index > -1) {
						statusInitialValues.push({
							id: item.id,
							name: item.name,
							status: productStatus[index].value,
							completeStatus: item.completeStatus,
						});
					}
				});
			}

			setstatusInitialValues(statusInitialValues);
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productStatus]);

	const getInstitutionDetails = (id) => {
		setisLoading(true);
		institutionService
			.getInstitutionProductDetails(id)
			.then((res) => {
				// console.log(res);
				if (res) {
					const products = res.institutionProductDtos.sort(
						(a, b) => a.id - b.id
					);
					const institution = {
						...res,
						institutionProductDtos: products,
					};
					setdetails(institution.institutionDetail);
					setproductDetails(institution.institutionProductDtos);
					setselectedProduct(
						institution.institutionProductDtos[selectedProductIndex]
					);
					setisLoading(false);
					setContactLodar(false);
					setstatusLodar(false);
					setproductLoader(false);
					setcontractLodar(false);
				}
			})
			.catch((e) => {
				setisLoading(false);
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			});
	};
	const addProduct = (productId) => {
		setproductLoader(true);
		const requestBody = {
			institutionId: id,
			productId: productId,
		};
		institutionService.institutionProductAdd(requestBody).then((res) => {
			if (res) {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "One Product Added",
					life: 3000,
				});
				getInstitutionDetails(id);
			}
		});
	};
	const handelProductSelect = (index) => {
		setselectedProduct(productDetails[index]);
		setselectedproductIndex(index);
	};

	const contactAdd = (requestBody) => {
		setContactLodar(true);
		if (type === "create") {
			return institutionService
				.createInstitutionProductContact(requestBody)
				.then((res) => {
					if (res) {
						getInstitutionDetails(id);
						setContactDialog(false);
						toast.current.show({
							severity: "success",
							summary: "Success",
							detail: "Contact Added",
							life: 3000,
						});
					}
				})
				.catch((e) => {
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						life: 3000,
					});
					setContactDialog(false);
					setContactLodar(false);
				});
		}
		if (type === "edit") {
			return institutionService
				.updateInstitutionProductContact(requestBody)
				.then((res) => {
					if (res) {
						getInstitutionDetails(id);
						setContactDialog(false);
						toast.current.show({
							severity: "success",
							summary: "Success",
							detail: "Contact Updated",
							life: 3000,
						});
					}
				})
				.catch((e) => {
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						life: 3000,
					});
					setContactDialog(false);
					setContactLodar(false);
				});
		}
	};

	const contractAdd = (requestBody) => {
		setcontractLodar(true);
		institutionService
			.creatInstitutionProductContract(requestBody)
			.then((res) => {
				if (res) {
					getInstitutionDetails(id);
					setcontractDialog(false);
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: "Contract Added",
						life: 3000,
					});
				}
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
				setcontractDialog(false);
				setcontractLodar(false);
			});
	};

	// const onContactRowEditComplete = (e) => {
	// 	setContactLodar(true);
	// 	const { newData } = e;
	// 	const requestBody = {
	// 		id: newData.id,
	// 		institutionProductId: newData.institutionProductId,
	// 		email: newData.email,
	// 		mobile: newData.mobile,
	// 		name: newData.name,
	// 	};
	// 	institutionService
	// 		.updateInstitutionProductContact(requestBody)
	// 		.then((res) => {
	// 			if (res) {
	// 				getInstitutionDetails(id);
	// 				toast.current.show({
	// 					severity: "success",
	// 					summary: "Success",
	// 					detail: "Contact Updated",
	// 					life: 3000,
	// 				});
	// 			}
	// 		})
	// 		.catch((e) => {
	// 			toast.current.show({
	// 				severity: "error",
	// 				summary: "Error",
	// 				detail: e.message,
	// 				life: 3000,
	// 			});
	// 			setContactLodar(false);
	// 		});
	// };

	const saveStatus = (values, onSubmitProps) => {
		setstatusLodar(true);
		const requestBody = {
			institutionProductId: selectedProduct.id,
			statusList: [],
		};

		requestBody.statusList = values.statusList.map((item) => {
			return {
				...item,
				name: item.name,
				productStatusId: item.status,
			};
		});
		institutionService
			.institutionStatusMaping(requestBody)
			.then((res) => {
				if (res) {
					getInstitutionDetails(id);
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: "Status maped successfully",
						life: 3000,
					});
				}
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
				setstatusLodar(false);
			});
	};

	//Table editors

	const startdateTemplate = (item) => {
		return moment(item.contractStartDate).format("Do MMM, YYYY");
	};
	const enddateTemplate = (item) => {
		return moment(item.contractEndDate).format("Do MMM, YYYY");
	};
	const tableDocumentTemplate = (item) => {
		return (
			<a
				href={`${Config.AGENT_BUCKET_ACCESS_URL}${Config.INSTITUTION_DOCS_FOLDER}/${item.contractDocName}`}
				target="_blank"
				rel="noreferrer"
			>
				View Document
			</a>
		);
	};
	const tabHeaderITemplate = (options) => {
		return (
			<Button
				onClick={options.onClick}
				className={`p-button p-component p-button-rounded p-button-outlined ${
					options.selected && "activeBtn"
				}`}
				label={options.titleElement}
			/>
		);
	};
	const actionTemplate = (item) => {
		return (
			<Button
				onClick={() => {
					setType("edit");
					setFormData(item);
					setContactDialog(true);
				}}
				icon={"pi pi-pencil"}
				className="p-button-rounded p-0"
			/>
		);
	};
	let dsaInitialValue = {
		id: "",
		rainbowDsaCode: "",
	};
	const handelProductDsaCode = (item) => {
		setProductDsaModal(true);
		setDsavalue({
			id: item.id,
			rainbowDsaCode: item.rainbowDsaCode ? item.rainbowDsaCode : "",
		});
	};

	const contactDialogClose = (e) => {
		setContactDialog(e);
	};
	const contractDialogClose = (e) => {
		setcontractDialog(e);
	};

	const handleStatus = (e, setFieldValue, index, id, list) => {
		setFieldValue(`statusList.${index}.completeStatus`, e.value, true);
		const idx = list.findIndex((elm) => elm.completeStatus === e.value);
		if (idx > -1) {
			setFieldValue(`statusList.${idx}.completeStatus`, "", true);
		}
		let payload = {
			id: id,
			completeStatus: e.value,
		};

		institutionService
			.institutionStatusUpdate(payload)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					life: 3000,
				});
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			});
	};

	// console.log({
	// 	statusList: statusInitialValues,
	// });

	// useEffect(() => {
	// 	if (statusDisable) {
	// 		institutionService
	// 			.institutionStatusUpdate(statusDisable)
	// 			.then((res) => {
	// 				console.log(res);
	// 			});
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [statusDisable.id]);
	return (
		<>
			<Toast ref={toast} position="top-left" />
			{productLoader && <Loader />}
			{isLoading && !productDetails && <Loader />}
			<div className="grid">
				<div className="col-12">
					<div className="flex justify-content-between align-items-center w-full">
						<h3 className="m-0">Add Institution's product</h3>
						<div>
							<Button
								type="button"
								icon="pi pi-plus"
								label="Product"
								className="mr-3"
								onClick={() => setproductDialog(true)}
							/>
							<Button
								type="button"
								icon="pi pi-chevron-left"
								label="Back"
								onClick={() =>
									navigation("/financial-institution/dsa")
								}
							/>
						</div>
					</div>
				</div>
				<div className="col-12">
					{productDetails && productDetails.length === 0 ? (
						<div className="grid c-no-product-w ">
							<div className="col-6 c-no-product-in">
								<h3>No Product Added yet.</h3>
								<button
									type="button"
									className="c-no-product-btn"
									onClick={() => setproductDialog(true)}
								>
									<i className="pi pi-plus"></i>
								</button>
							</div>
						</div>
					) : (
						<div className="grid">
							<div className="col-12">
								<div className="w-full customDetilsBox">
									<Card>
										<div className="flex justify-content-start align-items-center">
											<div className="avtarIcon">
												<img
													src={mainInstituteImg}
													alt="Institute Img"
												/>
											</div>
											<div className="detailsBox">
												<h2>
													{location?.dsaName}
													{/* <strong className="bg-white text-blue-800">
														({details.alias})
													</strong> */}
												</h2>
												<p>
													<strong className="bg-white text-blue-800 mr-2">
														DSA Code :
													</strong>
													{location?.dsaCode}
													<strong className="bg-white text-blue-800 ml-2 mr-2">
														Rainbow DSA Code :
													</strong>
													{location?.rainbowDsaCode}
												</p>
												<p>
													<strong className="bg-white text-blue-800 mr-2">
														Address :
													</strong>
													{location?.address +
														" , " +
														location?.address2}
													<strong className="bg-white text-blue-800 ml-2 mr-2">
														City :
													</strong>
													{location?.city}
													<strong className="bg-white text-blue-800 ml-2 mr-2">
														Pincode :
													</strong>
													{location?.pincode}
													<strong className="bg-white text-blue-800 ml-2 mr-2">
														State :
													</strong>
													{location?.stateName}
												</p>
											</div>
										</div>
										<Divider />
										<div className="flex justify-content-start align-items-center ml-5 cr-bankBox">
											<div className="avtarIcon">
												<img
													src={InstituteImg}
													alt="Institute Img"
												/>
											</div>
											<div className="detailsBox">
												<h2 className="text-blue-800">
													{details.bankName}
													{/* <strong className="bg-white text-blue-800">
														({details.alias})
													</strong> */}
												</h2>
												<p>
													<strong className="bg-white text-blue-800 mr-2">
														Address :
													</strong>
													{details.addressLine1}
													<strong className="bg-white text-blue-800 ml-2 mr-2">
														City :
													</strong>
													{details.city}
													<strong className="bg-white text-blue-800 ml-2 mr-2">
														Pincode :
													</strong>
													{details.pincode}
													<strong className="bg-white text-blue-800 ml-2 mr-2">
														State :
													</strong>
													{details.stateName}
												</p>
												<p>
													<strong className="bg-white text-blue-800 ">
														Rainbow DSA Code
													</strong>
													: {details.rainbowDsaCode}
												</p>
											</div>
										</div>
									</Card>
								</div>
							</div>

							<div className="col-12">
								<div className="grid cr-tabView">
									{/* <TabView scrollable>
										{[
											{
												title: `Tab 1`,
												content: `Tab 1 Content`,
											},
										].map((tab, index) => {
											return (
												<TabPanel
													className="cr-buttonCard c-tab-btn-w"
													key={index}
													header={tab.title}
													headerTemplate={
														tabHeaderITemplate
													}
												>
													<div className="grid"></div>
												</TabPanel>
											);
										})}
									</TabView> */}
									<div className="col-3">
										<div className="w-full product-scroll-wraper">
											{productDetails &&
												productDetails.map(
													(item, index) => (
														<Card
															className={
																selectedProduct.id ===
																item.id
																	? `product-card mb-3 active`
																	: `product-card mb-3`
															}
															title={
																item.product
																	.title
															}
															subTitle={`${item.product.parentId.title}(${item.product.lineOfBusiness})`}
															key={`product-${index}`}
															onClick={() =>
																handelProductSelect(
																	index
																)
															}
														>
															{item.rainbowDsaCode ? (
																<p className="m-0 p-0 text-primary">
																	{
																		item.rainbowDsaCode
																	}{" "}
																	<span
																		onClick={() =>
																			handelProductDsaCode(
																				item
																			)
																		}
																	>
																		<i className="pi pi-pencil"></i>
																	</span>
																</p>
															) : (
																<p
																	className="m-0 p-0 text-primary"
																	onClick={() =>
																		handelProductDsaCode(
																			item
																		)
																	}
																>
																	<i className="pi pi-plus"></i>{" "}
																	Add DSA Code
																</p>
															)}
														</Card>
													)
												)}
										</div>
									</div>
									<div className="col-9">
										{selectedProduct && (
											<div className="w-full h-full">
												<Panel
													header="Contact Details"
													toggleable
													className="mb-2"
												>
													<div className="grid">
														<div className="col-12">
															<div className="flex justify-content-end">
																<Button
																	type="button"
																	icon="pi pi-plus"
																	label="Contact"
																	className="mb-3"
																	onClick={() => {
																		setType(
																			"create"
																		);
																		setContactDialog(
																			true
																		);
																	}}
																/>
															</div>
															<Dialog
																header="Add Contact"
																visible={
																	contactDialog
																}
																onHide={() =>
																	setContactDialog(
																		false
																	)
																}
																breakpoints={{
																	"960px":
																		"95vw",
																}}
																style={{
																	width: "50%",
																}}
															>
																<ProductContactDialog
																	id={
																		selectedProduct.id
																	}
																	handelContactAdd={
																		contactAdd
																	}
																	data={
																		formData
																	}
																	type={type}
																	loadingss={
																		contactLodar
																	}
																	contactDialog={
																		contactDialogClose
																	}
																/>
															</Dialog>
															{contactLodar ? (
																<DataTable
																	value={
																		tableLoaderData
																	}
																	className="p-datatable-striped"
																>
																	<Column
																		field="name"
																		header="Name"
																		style={{
																			width: "20%",
																		}}
																		body={
																			bodyTemplate
																		}
																	></Column>
																	<Column
																		field="mobile"
																		header="Mobile"
																		style={{
																			width: "20%",
																		}}
																		body={
																			bodyTemplate
																		}
																	></Column>
																	<Column
																		field="email"
																		header="Email"
																		style={{
																			width: "20%",
																		}}
																		body={
																			bodyTemplate
																		}
																	></Column>
																	<Column
																		field="designation"
																		header="Designation"
																		style={{
																			width: "20%",
																		}}
																		body={
																			bodyTemplate
																		}
																	></Column>
																	<Column
																		field="districtName"
																		header="District"
																		style={{
																			width: "20%",
																		}}
																		body={
																			bodyTemplate
																		}
																	></Column>
																	<Column
																		field="stateName"
																		header="State"
																		style={{
																			width: "20%",
																		}}
																		body={
																			bodyTemplate
																		}
																	></Column>

																	<Column
																		field="action"
																		header="Action"
																		style={{
																			width: "10%",
																		}}
																		body={
																			bodyTemplate
																		}
																	></Column>
																</DataTable>
															) : (
																<DataTable
																	value={
																		selectedProduct.institutionProductContactList1
																	}
																	editMode="row"
																	dataKey="id"
																	// onRowEditComplete={
																	// 	onContactRowEditComplete
																	// }
																	responsiveLayout="scroll"
																>
																	<Column
																		field="name"
																		header="Name"
																		style={{
																			width: "20%",
																		}}
																	></Column>
																	<Column
																		field="mobile"
																		header="Mobile"
																		style={{
																			width: "20%",
																		}}
																	></Column>
																	<Column
																		field="email"
																		header="Email"
																		style={{
																			width: "20%",
																		}}
																	></Column>
																	<Column
																		field="designation"
																		header="Designation"
																		style={{
																			width: "20%",
																		}}
																	></Column>
																	<Column
																		field="districtName"
																		header="District"
																		style={{
																			width: "20%",
																		}}
																	></Column>
																	<Column
																		field="stateName"
																		header="State"
																		style={{
																			width: "20%",
																		}}
																	></Column>
																	<Column
																		body={
																			actionTemplate
																		}
																		header="Action"
																		headerStyle={{
																			width: "10%",
																			minWidth:
																				"8rem",
																		}}
																	></Column>
																</DataTable>
															)}
														</div>
													</div>
												</Panel>
												<Panel
													header="Contract Details"
													toggleable
													collapsed="false"
													className="mb-2"
												>
													<div className="grid">
														<div className="col-12">
															<div className="flex justify-content-end">
																<Button
																	type="button"
																	icon="pi pi-plus"
																	label="Contract"
																	className="mb-3"
																	onClick={() =>
																		setcontractDialog(
																			true
																		)
																	}
																/>
															</div>
															<Dialog
																header="Add Contract"
																visible={
																	contractDialog
																}
																onHide={() =>
																	setcontractDialog(
																		false
																	)
																}
																breakpoints={{
																	"960px":
																		"75vw",
																}}
																style={{
																	width: "50vw",
																}}
															>
																<ProductContractDetails
																	id={
																		selectedProduct.id
																	}
																	handelContractCreate={
																		contractAdd
																	}
																	contractDialog={
																		contractDialogClose
																	}
																/>
															</Dialog>
															{contractLodar ? (
																<DataTable
																	value={
																		tableLoaderData
																	}
																	className="p-datatable-striped"
																>
																	<Column
																		field="startDate"
																		header="Start Date"
																		style={{
																			width: "20%",
																		}}
																		body={
																			bodyTemplate
																		}
																	></Column>
																	<Column
																		field="endDate"
																		header="End Date"
																		style={{
																			width: "20%",
																		}}
																		body={
																			bodyTemplate
																		}
																	></Column>
																	<Column
																		field="document"
																		header="Document"
																		style={{
																			width: "20%",
																		}}
																		body={
																			bodyTemplate
																		}
																	></Column>
																</DataTable>
															) : (
																<DataTable
																	value={
																		selectedProduct.institutionProductContractList
																	}
																	dataKey="id"
																	responsiveLayout="scroll"
																>
																	<Column
																		body={
																			startdateTemplate
																		}
																		header="Start Date"
																		style={{
																			width: "20%",
																		}}
																	></Column>
																	<Column
																		body={
																			enddateTemplate
																		}
																		header="End Date"
																		style={{
																			width: "20%",
																		}}
																	></Column>
																	<Column
																		body={
																			tableDocumentTemplate
																		}
																		field="contractDocName"
																		header="Document"
																		style={{
																			width: "20%",
																		}}
																	></Column>
																</DataTable>
															)}
														</div>
													</div>
												</Panel>
												<Panel
													header="Institution Product Status Mapping"
													toggleable
													collapsed="false"
													className="mb-2"
												>
													<div className="grid">
														{statusLodar ? (
															<>
																<div className="col-6">
																	<Skeleton
																		height="2rem"
																		className="mb-2"
																	></Skeleton>
																</div>
																<div className="col-6">
																	<Skeleton
																		height="2rem"
																		className="mb-2"
																	></Skeleton>
																</div>
																<div className="col-6">
																	<Skeleton
																		height="2rem"
																		className="mb-2"
																	></Skeleton>
																</div>
																<div className="col-6">
																	<Skeleton
																		height="2rem"
																		className="mb-2"
																	></Skeleton>
																</div>
															</>
														) : (
															<div className="col-12">
																<Formik
																	initialValues={{
																		statusList:
																			statusInitialValues,
																	}}
																	validationSchema={
																		validationSchema
																	}
																	onSubmit={(
																		values,
																		onSubmitProps
																	) =>
																		saveStatus(
																			values,
																			onSubmitProps
																		)
																	}
																	enableReinitialize
																>
																	{({
																		values,
																		setFieldValue,
																	}) => (
																		<Form>
																			<FieldArray name="statusList">
																				{(
																					arrayHelpers
																				) => (
																					<div>
																						{values.statusList &&
																							values
																								.statusList
																								.length >
																								0 &&
																							values.statusList.map(
																								(
																									status,
																									index
																								) => (
																									<div
																										className="grid"
																										key={
																											index
																										}
																									>
																										<div className="field col-12 md:col-6 lg:col-3">
																											<Field
																												name={`statusList.${index}.status`}
																												component={
																													FeildDropdown
																												}
																												options={
																													productStatus
																												}
																												header={
																													"Rainbow's Product Status"
																												}
																											/>
																										</div>

																										<div className="field col-12 md:col-6 lg:col-3">
																											<Field
																												name={`statusList.${index}.name`}
																												component={
																													FieldInput
																												}
																												header={
																													"Institution's Product Status"
																												}
																											/>
																										</div>
																										{values
																											.statusList[
																											index
																										]
																											.id && (
																											<div className="field col-12 md:col-6 lg:col-4">
																												<Field
																													name={`statusList.${index}.completeStatus`}
																													component={
																														FeildDropdown
																													}
																													options={[
																														{
																															label: "APPROVED",
																															value: "APPROVED",
																														},
																														{
																															label: "DISBURSED",
																															value: "DISBURSED",
																														},
																														{
																															label: "REJECT",
																															value: "REJECT",
																														},
																													]}
																													header={
																														"Rainbow's Product Payment Status"
																													}
																													onChange={(
																														e
																													) =>
																														handleStatus(
																															e,
																															setFieldValue,
																															index,
																															values
																																.statusList[
																																index
																															]
																																.id,
																															values.statusList
																														)
																													}
																													value={
																														values
																															.statusList[
																															index
																														]
																															.completeStatus
																													}
																												/>
																											</div>
																										)}
																									</div>
																								)
																							)}
																						<Button
																							type="button"
																							icon="pi pi-plus"
																							label="Status"
																							onClick={() =>
																								arrayHelpers.push(
																									{
																										name: "",
																										status: null,
																									}
																								)
																							}
																						/>
																					</div>
																				)}
																			</FieldArray>
																			<div className="flex justify-content-end align-items-center w-full">
																				<Button
																					type="submit"
																					label="Save Maping"
																				/>
																			</div>
																		</Form>
																	)}
																</Formik>
															</div>
														)}
													</div>
												</Panel>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<Dialog
				header="Product DSA Code"
				visible={productDsaModal}
				onHide={() => setProductDsaModal(false)}
				breakpoints={{
					"960px": "95vw",
				}}
				style={{ width: "30vw" }}
			>
				<div className="card modal-form-grid">
					<Formik
						initialValues={{ ...dsaInitialValue, ...dsavalue }}
						onSubmit={(value) => {
							setisLoading(true);
							institutionService
								.addDSAproduct(value)
								.then((res) => {
									if (res) {
										toast.current.show({
											severity: "success",
											summary: "Success",
											detail: "DSA code added",
											life: 3000,
										});
										getInstitutionDetails(id);
										setisLoading(false);
										setProductDsaModal(false);
										setDsavalue({});
										setisLoading(false);
									}
								})
								.catch((e) => {
									toast.current.show({
										severity: "error",
										summary: "Error",
										detail: "Somthing went wrong.",
										life: 3000,
									});
									setisLoading(false);
									setProductDsaModal(false);
									setisLoading(false);
									setDsavalue({});
								});
						}}
						enableReinitialize
					>
						{({
							handleSubmit,
							setFieldValue,
							values,
							resetForm,
						}) => (
							<Form
								className="grid mt-2 "
								onSubmit={handleSubmit}
							>
								<div className="field col-12">
									<Field
										header={"Product DSA Code"}
										name={"rainbowDsaCode"}
										component={FieldInput}
										star={"*"}
										required
									/>
								</div>
								<div className="col-12 pb-0">
									<div className="flex justify-content-end align-items-center w-full">
										<Button
											type="button"
											label="Close"
											icon="pi pi-times"
											className="danger-btn mr-2"
											onClick={() =>
												setProductDsaModal(false)
											}
											style={{
												borderRadius: "6px",
											}}
										/>
										<Button
											type="submit"
											loading={isLoading}
											icon="pi pi-save"
											label="Save"
											className="primary-btn"
										/>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Dialog>
			<Dialog
				header="Add Products"
				visible={productDialog}
				onHide={() => setproductDialog(false)}
				breakpoints={{
					"960px": "95vw",
				}}
				style={{ width: "50vw" }}
			>
				<div className="card modal-form-grid">
					<div className="grid">
						{products?.length > 0 &&
							products?.map((item, index) => (
								<div
									className="col-12 md:col-6"
									key={`product-${index}`}
								>
									<h4 className="m-0">{item.product}</h4>
									<Divider />
									{item.subProducts.map((elm, j) => (
										<button
											className={
												elm.added
													? `product-list active`
													: `product-list`
											}
											key={`sub-products-${j}`}
											onClick={
												elm.added
													? undefined
													: () => addProduct(elm.id)
											}
										>
											{elm.subProduct}
											<i
												className={
													elm.added
														? `pi pi-check`
														: `pi pi-plus`
												}
											></i>
										</button>
									))}
								</div>
							))}
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default AddinstitutionProductsDsa;
