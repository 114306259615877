import { Field, Form, Formik } from "formik";
import React from "react";
import {
	FeildCalender,
	FieldInput,
	FieldMultiselect,
} from "../../components/FieldInput";
import {
	useGetAllOEListQuery,
	useGetAllTSRMQuery,
	useGetAllVendorQuery,
	useGetInstitutionDetailsQuery,
} from "../../services/query/queryApi";
import { Button } from "primereact/button";
import Config from "../../shared/config";

const SearchParams = ({ urlId, handleFormSubmit, setOpen, handleReset }) => {
	const { data: VendorList = { data: [] } } = useGetAllVendorQuery();
	const { data: TSRMList = { data: [] } } = useGetAllTSRMQuery();
	const { data: Institution = [] } = useGetInstitutionDetailsQuery();
	const { data: OEList = [] } = useGetAllOEListQuery();
	const ReportSearchForm = {
		"tsrm-daily-call-report": {
			date: true,
			fields: [
				{
					heading: "Vendor Name",
					Component: FieldMultiselect,
					name: "vendorName",
					option: VendorList.data?.map((elm) => ({
						label: elm.name,
						value: elm.name,
					})),
				},
				{
					heading: "Vendor Type",
					Component: FieldMultiselect,
					name: "vendorType",
					option: Config.VENDOR_TYPE,
				},
				{
					heading: "TSRM",
					Component: FieldMultiselect,
					name: "tsrm",
					option: TSRMList.data?.map((elm) => ({
						label:
							(elm.firstName ?? "") +
							" " +
							(elm.middleName ?? "") +
							" " +
							(elm.lastName ?? "") +
							"(" +
							(elm.userName ?? "") +
							")",
						value: elm.userName,
					})),
				},
			],
		},
		"finance-agent-wise-report-monthly": {
			date: true,
			fields: [
				{
					heading: "Agent Code",
					Component: FieldInput,

					name: "agentCode",
				},
				{
					heading: "Mobile Number",
					Component: FieldInput,
					name: "mobileNumber",
				},
			],
		},
		"agent-lead-pass-to-tsrm-monthly-report": {
			date: false,
			fields: [
				{
					heading: "Month",
					Component: FieldInput,
					name: "month",
				},
				{
					heading: "TSRM",
					Component: FieldMultiselect,
					name: "tsrm",
					option: TSRMList.data?.map((elm) => ({
						label:
							(elm.firstName ?? "") +
							" " +
							(elm.middleName ?? "") +
							" " +
							(elm.lastName ?? "") +
							"(" +
							(elm.userName ?? "") +
							")",
						value: elm.userName,
					})),
				},
				{
					heading: "Vendor Name",
					Component: FieldMultiselect,
					name: "vendorName",
					option: VendorList.data?.map((elm) => ({
						label: elm.name,
						value: elm.name,
					})),
				},
				{
					heading: "Vendor Type",
					Component: FieldMultiselect,
					name: "vendorType",
					option: Config.VENDOR_TYPE,
				},
			],
		},
		"agent-lead-daily-status-report-vendor": {
			date: true,
			fields: [
				{
					heading: "Month",
					Component: FieldInput,
					name: "month",
				},
				{
					heading: "TSRM",
					Component: FieldMultiselect,
					name: "tsrm",
					option: TSRMList.data?.map((elm) => ({
						label:
							(elm.firstName ?? "") +
							" " +
							(elm.middleName ?? "") +
							" " +
							(elm.lastName ?? "") +
							"(" +
							(elm.userName ?? "") +
							")",
						value: elm.userName,
					})),
				},

				{
					heading: "Agent Code",
					Component: FieldInput,
					name: "agentCode",
				},
			],
		},
		"application-daily-wise-report": {
			date: true,
			fields: [
				{
					heading: "Institution",
					Component: FieldMultiselect,
					name: "institute",
					option: Institution?.map((elm) => ({
						label: elm?.name,
						value: elm.name,
					})),
				},
				{
					heading: "Status",
					Component: FieldMultiselect,
					name: "status",
					option: Config.LOAN_APPLICATION_STATUS,
				},
				{
					heading: "RF Application No",
					Component: FieldInput,
					name: "rfApplicationNo",
				},
				{
					heading: "FI Application No",
					Component: FieldInput,
					name: "fiApplicationNo",
				},
				{
					heading: "Agent Code",
					Component: FieldInput,
					name: "agentCode",
				},
				{
					heading: "Product Type",
					Component: FieldInput,
					name: "productTypeName",
				},
			],
		},
		"insurance-application-daily-wise-report": {
			date: true,
			fields: [
				{
					heading: "Institution",
					Component: FieldMultiselect,
					name: "institute",
					option: Institution?.map((elm) => ({
						label: elm?.name,
						value: elm.name,
					})),
				},
				{
					heading: "Status",
					Component: FieldMultiselect,
					name: "status",
					option: Config.LOAN_APPLICATION_STATUS,
				},
				{
					heading: "RF Application No",
					Component: FieldInput,
					name: "rfApplicationNo",
				},
				{
					heading: "FI Application No",
					Component: FieldInput,
					name: "fiApplicationNo",
				},
				{
					heading: "Agent Code",
					Component: FieldInput,
					name: "agentCode",
				},
				{
					heading: "Product Type",
					Component: FieldInput,
					name: "productTypeName",
				},
			],
		},
		"credit-card-application-daily-wise-report": {
			date: true,
			fields: [
				{
					heading: "Institution",
					Component: FieldMultiselect,
					name: "institute",
					option: Institution?.map((elm) => ({
						label: elm?.name,
						value: elm.name,
					})),
				},
				{
					heading: "Status",
					Component: FieldMultiselect,
					name: "status",
					option: Config.LOAN_APPLICATION_STATUS,
				},
				{
					heading: "RF Application No",
					Component: FieldInput,
					name: "rfApplicationNo",
				},
				{
					heading: "FI Application No",
					Component: FieldInput,
					name: "fiApplicationNo",
				},
				{
					heading: "Agent Code",
					Component: FieldInput,
					name: "agentCode",
				},
				{
					heading: "Product Type",
					Component: FieldInput,
					name: "productTypeName",
				},
			],
		},
		"application-monthly-wise-under-tsrm-report": {
			date: false,
			fields: [
				{
					heading: "Month",
					Component: FieldInput,
					name: "month",
				},
				{
					heading: "TSRM",
					Component: FieldMultiselect,
					name: "tsrm",
					option: TSRMList.data?.map((elm) => ({
						label:
							(elm.firstName ?? "") +
							" " +
							(elm.middleName ?? "") +
							" " +
							(elm.lastName ?? "") +
							"(" +
							(elm.userName ?? "") +
							")",
						value: elm.userName,
					})),
				},
				{
					heading: "Vendor Name",
					Component: FieldMultiselect,
					name: "vendorName",
					option: VendorList.data?.map((elm) => ({
						label: elm.name,
						value: elm.name,
					})),
				},
			],
		},
		"insurance-monthly-wise-under-tsrm-report": {
			date: false,
			fields: [
				{
					heading: "Month",
					Component: FieldInput,
					name: "month",
				},
				{
					heading: "TSRM",
					Component: FieldMultiselect,
					name: "tsrm",
					option: TSRMList.data?.map((elm) => ({
						label:
							(elm.firstName ?? "") +
							" " +
							(elm.middleName ?? "") +
							" " +
							(elm.lastName ?? "") +
							"(" +
							(elm.userName ?? "") +
							")",
						value: elm.userName,
					})),
				},
				{
					heading: "Vendor Name",
					Component: FieldMultiselect,
					name: "vendorName",
					option: VendorList.data?.map((elm) => ({
						label: elm.name,
						value: elm.name,
					})),
				},
			],
		},
		"credit-card-monthly-wise-under-tsrm-report": {
			date: false,
			fields: [
				{
					heading: "Month",
					Component: FieldInput,
					name: "month",
				},
				{
					heading: "TSRM",
					Component: FieldMultiselect,
					name: "tsrm",
					option: TSRMList.data?.map((elm) => ({
						label:
							(elm.firstName ?? "") +
							" " +
							(elm.middleName ?? "") +
							" " +
							(elm.lastName ?? "") +
							"(" +
							(elm.userName ?? "") +
							")",
						value: elm.userName,
					})),
				},
				{
					heading: "Vendor Name",
					Component: FieldMultiselect,
					name: "vendorName",
					option: VendorList.data?.map((elm) => ({
						label: elm.name,
						value: elm.name,
					})),
				},
			],
		},
		"oe-admin-report": {
			date: false,
			fields: [
				{
					heading: "Month",
					Component: FieldInput,
					name: "month",
				},
				{
					heading: "Operational Executive",
					Component: FieldMultiselect,
					name: "oeCode",
					option: OEList?.map((elm) => ({
						label:
							(elm.firstName ?? "") +
							" " +
							(elm.lastName ?? "") +
							"(" +
							(elm.userName ?? "") +
							")",
						value: elm.userName,
					})),
				},
			],
		},
		"finance-depart-application-report": {
			date: true,
			fields: [
				{
					heading: "Month",
					Component: FieldInput,
					name: "month",
				},
				{
					heading: "Institution",
					Component: FieldMultiselect,
					name: "institute",
					option: Institution?.map((elm) => ({
						label: elm?.name,
						value: elm.name,
					})),
				},
				{
					heading: "Status",
					Component: FieldMultiselect,
					name: "status",
					option: Config.LOAN_APPLICATION_STATUS,
				},
			],
		},
		"tsrm-wise-daily-application-report-vendor": {
			date: true,
			fields: [
				{
					heading: "Month",
					Component: FieldInput,
					name: "month",
				},
				{
					heading: "TSRM",
					Component: FieldMultiselect,
					name: "tsrm",
					option: TSRMList.data?.map((elm) => ({
						label:
							(elm.firstName ?? "") +
							" " +
							(elm.middleName ?? "") +
							" " +
							(elm.lastName ?? "") +
							"(" +
							(elm.userName ?? "") +
							")",
						value: elm.userName,
					})),
				},
			],
		},
		"tsrm-wise-daily-insurance-application-report-vendor": {
			date: true,
			fields: [
				{
					heading: "Month",
					Component: FieldInput,
					name: "month",
				},
				{
					heading: "TSRM",
					Component: FieldMultiselect,
					name: "tsrm",
					option: TSRMList.data?.map((elm) => ({
						label:
							(elm.firstName ?? "") +
							" " +
							(elm.middleName ?? "") +
							" " +
							(elm.lastName ?? "") +
							"(" +
							(elm.userName ?? "") +
							")",
						value: elm.userName,
					})),
				},
			],
		},
		"tsrm-wise-daily-credit-card-application-report-vendor": {
			date: true,
			fields: [
				{
					heading: "Month",
					Component: FieldInput,
					name: "month",
				},
				{
					heading: "TSRM",
					Component: FieldMultiselect,
					name: "tsrm",
					option: TSRMList.data?.map((elm) => ({
						label:
							(elm.firstName ?? "") +
							" " +
							(elm.middleName ?? "") +
							" " +
							(elm.lastName ?? "") +
							"(" +
							(elm.userName ?? "") +
							")",
						value: elm.userName,
					})),
				},
			],
		},
		"oe-report-daily-wise": {
			date: true,
			fields: [
				{
					heading: "Operational Executive",
					Component: FieldMultiselect,
					name: "oeName",
					option: OEList?.map((elm) => ({
						label:
							(elm.firstName ?? "") +
							" " +
							(elm.lastName ?? "") +
							" (" +
							(elm.userName ?? "") +
							")",
						value:
							(elm.firstName ?? "") +
							" " +
							(elm.lastName ?? "") +
							" (" +
							(elm.userName ?? "") +
							")",
					})),
				},
				{
					heading: "Institution",
					Component: FieldMultiselect,
					name: "institute",
					option: Institution?.map((elm) => ({
						label: elm?.name,
						value: elm.name,
					})),
				},
				{
					heading: "Status",
					Component: FieldMultiselect,
					name: "status",
					option: Config.LOAN_APPLICATION_STATUS,
				},
				{
					heading: "Vendor Type",
					Component: FieldMultiselect,
					name: "vendorType",
					option: Config.VENDOR_TYPE,
				},
				{
					heading: "F.I Application No.",
					Component: FieldInput,
					name: "fiApplicationNo",
				},
				{
					heading: "Product Type",
					Component: FieldInput,
					name: "productTypeName",
				},
			],
		},
		"oe-report-daily-wise-for-admin": {
			date: true,
			fields: [
				{
					heading: "Month",
					Component: FieldInput,
					name: "month",
				},
				{
					heading: "Product Type",
					Component: FieldInput,
					name: "productTypeName",
				},
				{
					heading: "Operational Executive",
					Component: FieldMultiselect,
					name: "oeCode",
					option: OEList?.map((elm) => ({
						label:
							(elm.firstName ?? "") +
							" " +
							(elm.lastName ?? "") +
							"(" +
							(elm.userName ?? "") +
							")",
						value: elm.userName,
					})),
				},
				{
					heading: "Institution",
					Component: FieldMultiselect,
					name: "institute",
					option: Institution?.map((elm) => ({
						label: elm?.name,
						value: elm.name,
					})),
				},
				{
					heading: "Status",
					Component: FieldMultiselect,
					name: "status",
					option: Config.LOAN_APPLICATION_STATUS,
				},
				{
					heading: "Vendor Type",
					Component: FieldMultiselect,
					name: "vendorType",
					option: Config.VENDOR_TYPE,
				},
				{
					heading: "RF Application No",
					Component: FieldInput,
					name: "rfApplicationNo",
				},
				{
					heading: "FI Application No",
					Component: FieldInput,
					name: "fiApplicationNo",
				},
				{
					heading: "Agent Name",
					Component: FieldInput,
					name: "agentName",
				},
				{
					heading: "Line Of Business",
					Component: FieldInput,
					name: "lob",
				},
			],
		},
		"agent-lead-daily-status-report-admin": {
			date: true,
			fields: [
				{
					heading: "Month",
					Component: FieldInput,
					name: "month",
				},
			],
		},
		mh_agent_lead_daily_report: {
			date: true,
			fields: [
				{
					heading: "Month",
					Component: FieldInput,
					name: "month",
				},
				{
					heading: "Agent Code",
					Component: FieldInput,
					name: "agentCode",
				},
				{
					heading: "Lead Number",
					Component: FieldInput,
					name: "leadNumber",
				},
				{
					heading: "Agent Mobile No.",
					Component: FieldInput,
					name: "agentMobile",
				},
			],
		},
		mh_agent_lead_daily_report_insurance: {
			date: true,
			fields: [
				{
					heading: "Lead Number",
					Component: FieldInput,
					name: "leadNumber",
				},
				{
					heading: "Agent Mobile No.",
					Component: FieldInput,
					name: "agentMobile",
				},
			],
		},
		mh_agent_monthly_performance_report_with_earning: {
			date: false,
			fields: [
				{
					heading: "Agent Mobile No.",
					Component: FieldInput,
					name: "agentMobile",
				},
				{
					heading: "Agent Code",
					Component: FieldInput,
					name: "agentCode",
				},
			],
		},
		"principal-house-daily-report-pl": {
			date: true,
			fields: [
				{
					heading: "Institution",
					Component: FieldMultiselect,
					name: "institute",
					option: Institution?.map((elm) => ({
						label: elm?.name,
						value: elm.name,
					})),
				},
			],
		},
		"principal-house-daily-report-insurance": {
			date: true,
			fields: [
				{
					heading: "Institution",
					Component: FieldMultiselect,
					name: "institute",
					option: Institution?.map((elm) => ({
						label: elm?.name,
						value: elm.name,
					})),
				},
			],
		},
		"today-tsrm-agent-onboarding-report-vendor": {
			date: true,
			fields: [
				{
					heading: "Vendor Name",
					Component: FieldMultiselect,
					name: "vendorName",
					option: VendorList.data?.map((elm) => ({
						label: elm.name,
						value: elm.name,
					})),
				},
				{
					heading: "Vendor Type",
					Component: FieldMultiselect,
					name: "vendorType",
					option: Config.VENDOR_TYPE?.map((elm) => ({
						...elm,
						value: elm.value?.replace("_", " "),
					})),
				},
			],
		},
		onboarding_monthly_report: {
			date: false,
			fields: [
				{
					heading: "Month",
					Component: FieldInput,
					name: "month",
				},
				{
					heading: "Vendor Name",
					Component: FieldMultiselect,
					name: "vendorName",
					option: VendorList.data?.map((elm) => ({
						label: elm.name,
						value: elm.name,
					})),
				},
				{
					heading: "Vendor Type",
					Component: FieldMultiselect,
					name: "vendorType",
					option: Config.VENDOR_TYPE?.map((elm) => ({
						...elm,
						value: elm.value?.replace("_", " "),
					})),
				},
				{
					heading: "TSRM",
					Component: FieldMultiselect,
					name: "tsrm",
					option: TSRMList.data?.map((elm) => ({
						label:
							(elm.firstName ?? "") +
							" " +
							(elm.middleName ?? "") +
							" " +
							(elm.lastName ?? "") +
							" (" +
							(elm.userName ?? "") +
							")",
						value:
							(elm.firstName ?? "") +
							" " +
							(elm.lastName ?? "") +
							" (" +
							(elm.userName ?? "") +
							")",
					})),
				},
			],
		},
		onboarding_daily_report: {
			date: true,
			fields: [
				{
					heading: "Vendor Name",
					Component: FieldMultiselect,
					name: "vendorName",
					option: VendorList.data?.map((elm) => ({
						label: elm.name,
						value: elm.name,
					})),
				},
				{
					heading: "Vendor Type",
					Component: FieldMultiselect,
					name: "vendorType",
					option: Config.VENDOR_TYPE?.map((elm) => ({
						...elm,
						value: elm.value?.replace("_", " "),
					})),
				},
				{
					heading: "TSRM",
					Component: FieldMultiselect,
					name: "tsrm",
					option: TSRMList.data?.map((elm) => ({
						label:
							(elm.firstName ?? "") +
							" " +
							(elm.middleName ?? "") +
							" " +
							(elm.lastName ?? "") +
							" (" +
							(elm.userName ?? "") +
							")",
						value:
							(elm.firstName ?? "") +
							" " +
							(elm.lastName ?? "") +
							" (" +
							(elm.userName ?? "") +
							")",
					})),
				},
			],
		},
	};
	return (
		<Formik
			initialValues={{}}
			onSubmit={handleFormSubmit}
			enableReinitialize
		>
			{({ handleSubmit, resetForm }) => (
				<Form
					className="grid form-grid modal-form-grid"
					onSubmit={handleSubmit}
				>
					<>
						{ReportSearchForm[urlId]?.date && (
							<>
								<div className="field col-12 md:col-3">
									<Field
										header={"From Date"}
										component={FeildCalender}
										name={"fromDate"}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"To Date"}
										component={FeildCalender}
										name={"toDate"}
									/>
								</div>
							</>
						)}
						{ReportSearchForm[urlId]?.fields?.map((item, index) => (
							<div key={index} className="field col-12 md:col-3">
								<Field
									header={item.heading}
									{...(item.Component && {
										component: item.Component,
									})}
									name={item.name}
									{...(item?.option && {
										options: item.option,
										filter: true,
									})}
								/>
							</div>
						))}
					</>
					<div className="col-12 flex align-items-center justify-content-end">
						<Button
							type="button"
							label="Reset"
							className="p-button-danger mr-2"
							onClick={() => {
								resetForm();
								setOpen(false);
								handleReset();
							}}
						/>
						<Button type="submit" label="Search" />
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default SearchParams;
