import moment from "moment";
import { jsPDF } from "jspdf";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	setApplicationState,
	setCountryApplicationId,
} from "../../../../store/reducer/AuthReducer";
import { useRef } from "react";
const PaymentPreview = (props) => {
	const pdfRef = useRef(null);
	const leadDispatch = useDispatch();
	const navigate = useNavigate();
	const createPDF = () => {
		const pdf = new jsPDF("l", "pt", [1088, 940]);
		const data = pdfRef.current;
		pdf.html(data, { margin: 40 }).then(() => {
			pdf.save(props?.paymentData?.invoiceNumber + ".pdf");
		});
	};
	return (
		// <>
		// 	<p>Bill to-{props?.paymentData?.billTo}</p>
		// 	<p>invoiceDate - {props?.paymentData?.invoiceDate}</p>
		// 	<p>invoiceNumber - {paymentData?.invoiceNumber}</p>
		// 	<p>rmUser - {paymentData?.rmUser}</p>
		// 	<p>address-{paymentData?.address}</p>
		// 	<p>address - {paymentData?.address}</p>
		// 	<DataTable value={paymentData?.commissionDetails}>
		// 		<Column
		// 			body={(item) =>
		// 				item?.date ? (
		// 					moment(item?.date).format("DD-MM-YYYY")
		// 				) : (
		// 					<></>
		// 				)
		// 			}
		// 		/>
		// 		<Column field="leadNumber" header={"Lead Number"} />
		// 		<Column
		// 			field="rfApplicationNumber"
		// 			header={"RF Application Number"}
		// 		/>
		// 		<Column
		// 			field="fiApplicationNumber"
		// 			header={"FI Application Number"}
		// 		/>
		// 		<Column field="fullName" header={"Name"} />
		// 		<Column field="sanctionAmount" header={"Sanction Amount"} />
		// 		<Column field="commissionAmount" header={"Commission Amount"} />
		// 		<Column field="mobileNumber" header={"Mobile Number"} />
		// 		<Column field="bankName" header={"Bank Name"} />
		// 		<Column field="district" header={"District"} />
		// 		<Column field="state" header={"State"} />
		// 	</DataTable>
		// </>
		<>
			<div className="flex align-items-center justify-content-end">
				<Button
					label="Download"
					onClick={() => createPDF()}
					icon={"pi pi-download"}
				/>
			</div>
			<table
				ref={pdfRef}
				style={{
					width: "1000px",
					fontFamily: "Arial, Helvetica, sans-serif",
					borderCollapse: "collapse",
					padding: 0,
					margin: "0 auto",
					marginTop: 10,
					border: "1px solid #000",
				}}
			>
				<tbody>
					<tr>
						<td>
							<table
								style={{
									width: "100%",
									fontFamily: "Arial, Helvetica, sans-serif",
									borderCollapse: "collapse",
									padding: 0,
									margin: 0,
								}}
							>
								<tbody>
									<tr>
										<td
											style={{
												width: "60%",
												padding: 10,
												borderBottom: "1px solid #000",
											}}
										>
											<h2
												style={{
													margin: 0,
													lineHeight: "normal",
													marginBottom: 10,
													fontFamily:
														"Arial, Helvetica, sans-serif",
													fontSize: 18,
													fontWeight: "bold",
												}}
											>
												CONNECTEOR NAME -{" "}
												{props?.paymentData?.billTo}
											</h2>
											<h3
												style={{
													margin: 0,
													lineHeight: "normal",
													fontFamily:
														"Arial, Helvetica, sans-serif",
													fontSize: 15,
													fontWeight: "bold",
												}}
											>
												ADDRESS-
												{props?.paymentData?.address}
											</h3>
										</td>
										<td
											style={{
												width: "40%",
												padding: 10,
												borderBottom: "1px solid #000",
											}}
										>
											<h2
												style={{
													margin: 0,
													lineHeight: "normal",
													marginBottom: 10,
													textAlign: "center",
													fontFamily:
														"Arial, Helvetica, sans-serif",
													fontSize: 18,
													fontWeight: "bold",
												}}
											>
												INVOICE -{" "}
												{
													props?.paymentData
														?.invoiceNumber
												}
											</h2>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr>
						<td>
							<table
								style={{
									width: "100%",
									fontFamily: "Arial, Helvetica, sans-serif",
									borderCollapse: "collapse",
									padding: 0,
									margin: 0,
								}}
							>
								<tbody>
									<tr>
										<td
											style={{
												width: "50%",
												padding: 10,
											}}
										>
											<h3
												style={{
													margin: 0,
													lineHeight: "normal",
													marginBottom: 10,
													fontFamily:
														"Arial, Helvetica, sans-serif",
													fontSize: 13,
													fontWeight: "bold",
												}}
											>
												BILL TO:- Rainbow Financial
												Services
											</h3>
											<h3
												style={{
													margin: 0,
													lineHeight: "normal",
													fontFamily:
														"Arial, Helvetica, sans-serif",
													fontSize: 13,
													fontWeight: "bold",
												}}
											>
												M/S:- 43/3 Hazra Main Road, 2nd
												Floor, Asha Building.
												Kolkata-700019,West Bengal.
											</h3>
										</td>
										<td
											style={{
												width: "50%",
												padding: 10,
											}}
										>
											<table
												style={{
													width: "100%",
													fontFamily:
														"Arial, Helvetica, sans-serif",
													borderCollapse: "collapse",
													padding: 0,
													margin: 0,
													border: "1px solid #000",
												}}
											>
												<tbody>
													<tr>
														<td
															style={{
																border: "1px solid #000",
																fontWeight:
																	"bold",
																padding: 5,
																fontSize: 12,
																width: "50%",
															}}
														>
															INVOICE DATE
														</td>
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																width: "50%",
															}}
														>
															{moment(
																props
																	?.paymentData
																	?.invoiceDate
															).format(
																"DD-MM-YYYY"
															)}
														</td>
													</tr>
													<tr>
														<td
															style={{
																border: "1px solid #000",
																fontWeight:
																	"bold",
																padding: 5,
																fontSize: 12,
																width: "50%",
															}}
														>
															INVOICE NO.{" "}
														</td>
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																width: "50%",
															}}
														>
															{
																props
																	?.paymentData
																	?.invoiceNumber
															}
														</td>
													</tr>
													<tr>
														<td
															style={{
																border: "1px solid #000",
																fontWeight:
																	"bold",
																padding: 5,
																fontSize: 12,
																width: "50%",
															}}
														>
															REGIONAL MANAGER
														</td>
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																width: "50%",
															}}
														>
															{
																props
																	?.paymentData
																	?.rmUser
															}
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr>
						<td style={{ padding: 10 }}>
							<table
								style={{
									width: "100%",
									fontFamily: "Arial, Helvetica, sans-serif",
									borderCollapse: "collapse",
									padding: 0,
									margin: 0,
									marginTop: 20,
								}}
							>
								<tbody>
									{/* <tr>
										<td
											style={{
												fontFamily:
													"Arial, Helvetica, sans-serif",
												fontSize: 14,
												marginBottom: 10,
												fontWeight: "bold",
											}}
										>
											*Invoice generated on{" "}
											{props?.paymentData?.invoiceDate
												? moment(
														props?.paymentData
															?.invoiceDate
												  ).format("MMM, YYYY")
												: null}
										</td>
									</tr> */}
									<tr>
										<td style={{ paddingTop: 10 }}>
											<table
												style={{
													width: "100%",
													fontFamily:
														"Arial, Helvetica, sans-serif",
													borderCollapse: "collapse",
													padding: 0,
													margin: 0,
													border: "1px solid #000",
													textAlign: "center",
												}}
											>
												<tbody>
													<tr>
														<th
															style={{
																background:
																	"#eee",
																border: "1px solid #000",
																padding: 5,
																fontSize: 12,
															}}
														>
															SR. NO
														</th>
														<th
															style={{
																background:
																	"#eee",
																border: "1px solid #000",
																padding: 5,
																fontSize: 12,
															}}
														>
															MONTH &amp;YEAR
														</th>
														<th
															style={{
																background:
																	"#eee",
																border: "1px solid #000",
																padding: 5,
																fontSize: 12,
															}}
														>
															DISTRICT
														</th>
														<th
															style={{
																background:
																	"#eee",
																border: "1px solid #000",
																padding: 5,
																fontSize: 12,
															}}
														>
															STATE
														</th>
														<th
															style={{
																background:
																	"#eee",
																border: "1px solid #000",
																padding: 5,
																fontSize: 12,
															}}
														>
															CUSTOMER NAME
														</th>
														<th
															style={{
																background:
																	"#eee",
																border: "1px solid #000",
																padding: 5,
																fontSize: 12,
															}}
														>
															BANK NAME
														</th>
														<th
															style={{
																background:
																	"#eee",
																border: "1px solid #000",
																padding: 5,
																fontSize: 12,
															}}
														>
															PRODUCT
														</th>

														<th
															style={{
																background:
																	"#eee",
																border: "1px solid #000",
																padding: 5,
																fontSize: 12,
															}}
														>
															RF APPLICATION NO.
														</th>
														<th
															style={{
																background:
																	"#eee",
																border: "1px solid #000",
																padding: 5,
																fontSize: 12,
															}}
														>
															FI APPLICATION NO.
														</th>
														<th
															style={{
																background:
																	"#eee",
																border: "1px solid #000",
																padding: 5,
																fontSize: 12,
															}}
														>
															SANCTION AMOUNT
														</th>
														<th
															style={{
																background:
																	"#eee",
																border: "1px solid #000",
																padding: 5,
																fontSize: 12,
															}}
														>
															COMMISSION AMOUNT
														</th>
													</tr>
													{props?.paymentData?.commissionDetails?.map(
														(item, index) => (
															<tr key={index}>
																<td
																	style={{
																		border: "1px solid #000",
																		padding: 5,
																		fontSize: 12,
																	}}
																>
																	{index + 1}
																</td>
																<td
																	style={{
																		border: "1px solid #000",
																		padding: 5,
																		fontSize: 12,
																		whiteSpace:
																			"nowrap",
																	}}
																>
																	{item?.date ? (
																		moment(
																			item?.date
																		).format(
																			"DD-MM-YYYY"
																		)
																	) : (
																		<></>
																	)}
																</td>
																<td
																	style={{
																		border: "1px solid #000",
																		padding: 5,
																		fontSize: 12,
																	}}
																>
																	{
																		item?.district
																	}
																</td>
																<td
																	style={{
																		border: "1px solid #000",
																		padding: 5,
																		fontSize: 12,
																	}}
																>
																	{
																		item?.state
																	}
																</td>
																<td
																	style={{
																		border: "1px solid #000",
																		padding: 5,
																		fontSize: 12,
																	}}
																>
																	{
																		item?.fullName
																	}
																</td>
																<td
																	style={{
																		border: "1px solid #000",
																		padding: 5,
																		fontSize: 12,
																	}}
																>
																	{
																		item?.bankName
																	}
																</td>
																<td
																	style={{
																		border: "1px solid #000",
																		padding: 5,
																		fontSize: 12,
																	}}
																>
																	{
																		props?.productName
																	}
																</td>

																<td
																	onClick={() => {
																		leadDispatch(
																			setCountryApplicationId(
																				null
																			)
																		);
																		leadDispatch(
																			setApplicationState(
																				"preview"
																			)
																		);
																		navigate(
																			"/masters/applicationcreate",
																			{
																				state: {
																					alias: item?.subProductAlias,
																					applicationId:
																						item?.applicationId,
																					subproductId:
																						item?.subProductId,
																				},
																			}
																		);
																	}}
																	style={{
																		border: "1px solid #000",
																		padding: 5,
																		fontSize: 12,
																		color: "#253d98",
																		fontWeight: 600,
																		cursor: "pointer",
																	}}
																>
																	{
																		item?.rfApplicationNumber
																	}
																</td>
																<td
																	style={{
																		border: "1px solid #000",
																		padding: 5,
																		fontSize: 12,
																	}}
																>
																	{
																		item?.fiApplicationNumber
																	}
																</td>
																<td
																	style={{
																		border: "1px solid #000",
																		padding: 5,
																		fontSize: 12,
																	}}
																>
																	{
																		item?.sanctionAmount
																	}
																</td>
																<td
																	style={{
																		border: "1px solid #000",
																		padding: 5,
																		fontSize: 12,
																	}}
																>
																	{`${item?.commissionAmount} (${item?.commissionPercentage})%`}
																</td>
															</tr>
														)
													)}
													<tr>
														<td colSpan={9} />
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																fontSize: 13,
																fontWeight:
																	"bold",
															}}
														>
															TOTAL AMOUNT
														</td>
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																fontSize: 13,
																fontWeight:
																	"bold",
															}}
														>
															{
																props
																	?.paymentData
																	?.totalAmount
															}
														</td>
													</tr>
													<tr>
														<td colSpan={9} />
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																fontSize: 13,
																fontWeight:
																	"bold",
															}}
														>
															TDS AMOUNT
														</td>
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																fontSize: 13,
																fontWeight:
																	"bold",
															}}
														>
															{
																props
																	?.paymentData
																	?.tdsAmount
															}
														</td>
													</tr>
													<tr>
														<td colSpan={9} />
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																fontSize: 13,
																fontWeight:
																	"bold",
															}}
														>
															GST AMOUNT
														</td>
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																fontSize: 13,
																fontWeight:
																	"bold",
															}}
														>
															{
																props
																	?.paymentData
																	?.gstAmount
															}
														</td>
													</tr>
													<tr>
														<td colSpan={9} />
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																fontSize: 13,
																fontWeight:
																	"bold",
															}}
														>
															NET AMOUNT
														</td>
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																fontSize: 13,
																fontWeight:
																	"bold",
															}}
														>
															{
																props
																	?.paymentData
																	?.netAmount
															}
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr>
						<td style={{ padding: 10, width: "45%" }}>
							<table
								style={{
									width: "100%",
									fontFamily: "Arial, Helvetica, sans-serif",
									borderCollapse: "collapse",
									padding: 0,
									margin: 0,
									marginTop: 20,
								}}
							>
								<tbody>
									<tr>
										<td>
											<table
												style={{
													width: "100%",
													fontFamily:
														"Arial, Helvetica, sans-serif",
													borderCollapse: "collapse",
													padding: 0,
													margin: 0,
													border: "1px solid #000",
												}}
											>
												<tbody>
													<tr>
														<td
															style={{
																border: "1px solid #000",
																fontWeight:
																	"bold",
																padding: 5,
																fontSize: 12,
																width: "50%",
															}}
														>
															PAN CARD NO:
														</td>
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																width: "50%",
															}}
														>
															{
																props
																	?.paymentData
																	?.pan
															}
														</td>
													</tr>
													<tr>
														<td
															style={{
																border: "1px solid #000",
																fontWeight:
																	"bold",
																padding: 5,
																fontSize: 12,
																width: "50%",
															}}
														>
															ACCOUNT NO:
														</td>
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																width: "50%",
															}}
														>
															{
																props
																	?.paymentData
																	?.accountNumber
															}
														</td>
													</tr>
													<tr>
														<td
															style={{
																border: "1px solid #000",
																fontWeight:
																	"bold",
																padding: 5,
																fontSize: 12,
																width: "50%",
															}}
														>
															IFSC CODE:
														</td>
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																width: "50%",
															}}
														>
															{
																props
																	?.paymentData
																	?.ifsc
															}
														</td>
													</tr>
													<tr>
														<td
															style={{
																border: "1px solid #000",
																fontWeight:
																	"bold",
																padding: 5,
																fontSize: 12,
																width: "50%",
															}}
														>
															BANK NAME:
														</td>
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																width: "50%",
															}}
														>
															{
																props
																	?.paymentData
																	?.bankName
															}
														</td>
													</tr>
													<tr>
														<td
															style={{
																border: "1px solid #000",
																fontWeight:
																	"bold",
																padding: 5,
																fontSize: 12,
																width: "50%",
															}}
														>
															GST NO.
														</td>
														<td
															style={{
																border: "1px solid #000",
																padding: 5,
																width: "50%",
															}}
														>
															{
																props
																	?.paymentData
																	?.gstNumber
															}
														</td>
													</tr>
												</tbody>
											</table>
										</td>
										<td style={{ width: "15%" }} />
										<td
											style={{
												width: "40%",
												verticalAlign: "bottom",
											}}
										>
											<h2
												style={{
													paddingTop: 10,
													borderTop: "1px solid #000",
													fontWeight: "bold",
													fontSize: 13,
													textAlign: "center",
												}}
											>
												Authorised Sign.
											</h2>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
};

export default PaymentPreview;
