import React from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import AppLayout from "./pages/Layouts/AppLayout";
import Users from "./pages/members/users/Users";
import AgentsProfile from "./pages/members/users/AgentsProfile";
import ErrorPage from "./pages/ErrorPage";
import MyAgents from "./pages/members/allocation/MyAgents";
import UnallocatedAgents from "./pages/members/allocation/UnallocatedAgents";
import Products from "./pages/masters/products/Products";
import FinancialInstitutions from "./pages/partners/institutions/FinancialInstitutions";
import AddFinancialInstitutions from "./pages/partners/institutions/AddFinancialInstitutions";
import AddinstitutionProducts from "./pages/partners/institutions/AddinstitutionProducts";
// import Program from "./pages/partners/programsetup/Program";
import LoanApplication from "./pages/masters/loanApplication/LoanApplication";
// import EditProgram from "./pages/partners/programsetup/edit/EditProgram";
import LoanApplicationList from "./pages/masters/loanApplication/LoanApplicationList";
import PageCall from "./ApplicationForm/PageCall";
import AllocateLeadDialog from "./pages/masters/lead/component/AllocateLeadDialog";
import AllLead from "./pages/masters/lead/AllLead";
import Own from "./pages/masters/lead/Own";
import EditDialog from "./pages/masters/lead/component/EditDialog";
import CreateLead from "./pages/masters/lead/component/CreateLead";
// import CopyProgram from "./pages/partners/programsetup/edit/CopyProgram";
import CreateApplication from "./pages/masters/loanApplication/components/CreateApplication";
import UnallocateApplication from "./pages/masters/loanApplication/UnallocateApplication";
import PreviewLead from "./pages/masters/lead/component/PreviewLead";
import ProgramList from "./pages/partners/programsetup/ProgramList";
import Insurance from "./pages/masters/loanApplication/components/Insurance";
// import EditProgram2 from "./pages/partners/programsetup/edit/EditProgram2";
import HospicasEdit from "./pages/masters/loanApplication/components/HospicasEdit";
import PreviewHospicash from "./pages/masters/loanApplication/components/PreviewHospicash";
import ForgetPassword from "./pages/login/ForgetPassword";
import Program from "./pages/partners/programsetup/Program";
import EditEmployeeCommission from "./pages/partners/programsetup/components/EditEmployeeCommission";
import PaymentDetails from "./pages/masters/loanApplication/payments/PaymentDetails";
import AgentReport from "./pages/reports/AgentReport";
import CustomerList from "./pages/reports/CustomerList";
import PreviewPdf from "./pages/masters/loanApplication/components/PreviewApplication";
import Faq from "./pages/masters/support/Faq";
import Tutorial from "./pages/masters/support/Tutorial";
import CommissionList from "./pages/masters/products/CommissionList";
import EmployeeCommissionList from "./pages/masters/products/EmployeeCommissionList";
import Campaign from "./pages/masters/campaign/Campaign";
import AddCampaign from "./pages/masters/campaign/component/AddCampaign";
import AgentCommission from "./pages/members/users/components/AgentCommission";
import LeadList from "./pages/masters/campaign/component/LeadList";
// import LeadCenter from "./pages/masters/lead/component/LeadCenter";
import Vendor from "./pages/vendor";
import HubList from "./pages/vendor/HubList";
import SpokeList from "./pages/vendor/Spoke";
import CallCenterUsers from "./pages/vendor/call_center/CallcenterUser";
// import { menu_path } from "./shared/constant";
import MenuCreate from "./pages/Menu";
import MyVendorAgents from "./pages/vendor/agents/MyVendorAgent";
import CCAllocateAgentLead from "./pages/vendor/agents/CCAllocateAgentLead";
import Dashboard from "./pages/dashboard/Dashboard";
import VendorLoanApplicationList from "./pages/vendor/loanApplication/LoanApplicationList";
import CreateVendorApplication from "./pages/vendor/loanApplication/components/CreateApplication";
import VendorLoanApplication from "./pages/vendor/loanApplication/LoanApplication";
import VendorAllLead from "./pages/vendor/lead/AllLead";
import VendorCreateLead from "./pages/vendor/lead/component/VendorCreateLead";
import VendorEditDialog from "./pages/vendor/lead/component/VendorEditDialog";
import VendorPreviewLead from "./pages/vendor/lead/component/VendorPreviewLead";
import VendorHospicasEdit from "./pages/vendor/loanApplication/components/HospicasEdit";
import AgentProfile from "./pages/vendor/agents/AgentProfile";
import UserActivity from "./pages/vendor/call_center/UserActivity";
import CountryDashboard from "./pages/dashboard/countryAdmin";
import EnterApplication from "./pages/masters/loanApplication/components/EnterApplication";
import "react-big-calendar/lib/css/react-big-calendar.css";
import BusinessDetailsList from "./pages/members/finance/BusinessDetailsList";
import InvoiceDetailsList from "./pages/members/finance/InvoiceDetails";
import DSAFinancialInstitutions from "./pages/partners/dsa/DSAFinancialInstitutions";
import AddFinancialInstitutionsDsa from "./pages/partners/dsa/AddFinancialInstitutionsDsa";
import AddinstitutionProductsDsa from "./pages/partners/dsa/AddinstitutionProductsDsa";
import ProgramPage from "./pages/partners/programsetup/setUpDialog/ProgramPage";
import AgentProfileUpdate from "./pages/members/users/components/AgentProfileUpdate";
import AgentProfileUpdateList from "./pages/members/users/components/AgentProfileUpdateList";
import InsuranceApplicationList from "./pages/masters/loanApplication/InsuranceApplicationList";
import VendorLoanApplicationInsuranceList from "./pages/vendor/loanApplication/InsuranceApplicationList";
// import AgentLeadDailyStatus from "./pages/masters/lead/component/AgentLeadDailyStatus";
// import ApplicationReport from "./pages/masters/loanApplication/components/ApplicationReport";
import Ratings from "./pages/ratings/Ratings";
import ApplicationReport from "./pages/applicationReport/ApplicationReport";
import SubproductQuestions from "./pages/masters/products/components/SubproductQuestions";
// import ApplicationVendorReport from "./pages/vendor/loanApplication/components/ApplicationReport";
// import OEApplicationVendorReport from "./pages/vendor/loanApplication/components/OeApplicationReport";
const App = () => {
	return (
		<Routes>
			{/* <Route path="/login" element={<Signup />} /> */}
			<Route path="/forget-password" element={<ForgetPassword />} />
			<Route path="/" element={<AppLayout />}>
				<Route index element={<Dashboard />} />
				<Route path="/dashboard-call-center" element={<Dashboard />} />
				<Route path="/masters/products" element={<Products />} />
				<Route
					path="/masters/products/commission/:productId"
					element={<CommissionList />}
				/>
				<Route
					path="/masters/products/questions/:productId"
					element={<SubproductQuestions />}
				/>
				<Route
					path="/masters/products/employee-commission/:productId"
					element={<EmployeeCommissionList />}
				/>

				<Route path="/masters/lead/all" element={<AllLead />} />
				<Route path="/masters/lead/own" element={<Own />} />
				<Route
					path="/masters/loan_application"
					element={<LoanApplication />}
				/>
				<Route
					path="/masters/applicationcreate"
					element={<EnterApplication />}
				/>

				<Route path="/masters/lead/new-lead" element={<CreateLead />} />
				<Route
					path="/masters/lead/edit-lead/:id"
					element={<EditDialog />}
				/>
				<Route
					path="/masters/lead/preview-lead/:id"
					element={<PreviewLead />}
				/>
				{/* <Route
					path="/masters/lead/agent-lead-daily-status"
					element={<AgentLeadDailyStatus />}
				/> */}

				<Route
					path="/masters/lead/lead-allocation"
					element={<AllocateLeadDialog />}
				/>
				{/* <Route path="/agent-lead" element={<LeadCenter />} /> */}
				<Route path="/agent-lead" element={<CCAllocateAgentLead />} />
				<Route path="/masters/lead/insu" element={<Insurance />} />
				<Route
					path="/masters/loan-application-list"
					element={<LoanApplicationList />}
				/>
				<Route
					path="/masters/insurance-application-list"
					element={<InsuranceApplicationList />}
				/>
				{/* <Route
					path="/masters/application-report"
					element={<ApplicationReport />}
				/> */}
				{/* <Route
					path="/reports/application-report"
					element={<ApplicationReport />}
				/> */}
				{/* report */}
				<Route
					path="/reports/report/:url/report/:id"
					element={<ApplicationReport />}
				/>
				{/* <Route
					path="/reports/application-report/call/:id"
					element={<ApplicationReport />}
				/>
				<Route
					path="/reports/application-report/lead/:id"
					element={<ApplicationReport />}
				/>
				<Route
					path="/reports/application-report/finance/:id"
					element={<ApplicationReport />}
				/>
				<Route
					path="/reports/application-report/operation/:id"
					element={<ApplicationReport />}
				/> */}
				<Route
					path="/masters/application-pdf"
					element={<PreviewPdf />}
				/>
				<Route
					path="/masters/application-create"
					element={<CreateApplication />}
				/>
				<Route
					path="/masters/unallocated-application"
					element={<UnallocateApplication />}
				/>
				<Route
					path="/masters/hospicash/edit/:id"
					element={<HospicasEdit />}
				/>
				<Route
					path="/masters/hospicash/preview/:id"
					element={<PreviewHospicash />}
				/>
				<Route
					path="/masters/hospicash/payments"
					element={<PaymentDetails />}
				/>
				<Route
					path="/masters/finance/business-details"
					element={<BusinessDetailsList />}
				/>
				<Route
					path="/masters/finance/invoice-details"
					element={<InvoiceDetailsList />}
				/>
				<Route path="/members/users" element={<Users />} />
				<Route path="/members/agents" element={<AgentsProfile />} />
				<Route path="/members/my-agents" element={<MyAgents />} />
				<Route
					path="/members/agent-commission"
					element={<AgentCommission />}
				/>
				<Route
					path="/members/agent-profile-update-request"
					element={<AgentProfileUpdateList />}
				/>
				<Route
					path="/members/agent-profile-update/:id"
					element={<AgentProfileUpdate />}
				/>
				<Route
					path="/members/unallocated-agents"
					element={<UnallocatedAgents />}
				/>
				<Route
					path="/partners/financial-institution/list"
					element={<FinancialInstitutions />}
				/>
				<Route
					path="/partners/financial-institution/add"
					element={<AddFinancialInstitutions />}
				/>
				<Route
					path="/partners/dsa-financial-institution/add"
					element={<AddFinancialInstitutionsDsa />}
				/>
				<Route
					path="/partners/financial-institution/product-maping/:id"
					element={<AddinstitutionProducts />}
				/>
				<Route
					path="/partners/financial-institution-dsa/product-maping/:id"
					element={<AddinstitutionProductsDsa />}
				/>
				<Route
					path="/financial-institution/dsa"
					element={<DSAFinancialInstitutions />}
				/>
				<Route
					path="/partners/program-management"
					element={<Program />}
				/>
				<Route
					path="/partners/program-setup-management"
					element={<ProgramList />}
				/>
				<Route
					path="/partners/program-management-create"
					element={<ProgramPage />}
				/>
				<Route
					path="/partners/program-setup-management/commission/:programId"
					element={<EditEmployeeCommission />}
				/>

				{/* <Route
					path="/partners/program-list"
					element={<ProgramList />}
				/> */}
				{/* <Route
					path="/partners/program-setup-management/edit/:id"
					element={<EditProgram />}
				/> */}
				{/* <Route
					path="/partners/program-setup-management/edit/:id"
					element={<EditProgram2 />}
				/>
				<Route
					path="/partners/program-setup-management/copy/:id"
					element={<CopyProgram />}
				/> */}
				<Route path="/reports/agent" element={<AgentReport />} />
				<Route
					path="/reports/customer-list"
					element={<CustomerList />}
				/>
				<Route path="/support/faq" element={<Faq />} />
				<Route path="/support/tutorial" element={<Tutorial />} />
				<Route path="/masters/campaign" element={<Campaign />} />
				<Route path="/masters/campaign/add" element={<AddCampaign />} />
				<Route
					path="/masters/campaign/edit/:id"
					element={<AddCampaign />}
				/>
				<Route
					path="/masters/campaign/lead-by-campaign/:campaignId"
					element={<LeadList />}
				/>
				{/* <Route path="/call-support/user" element={<CallCenterUser />} /> */}
				<Route
					path="/vendor/call-support/user"
					element={<CallCenterUsers />}
				/>
				<Route
					path="/vendor/call-support/user-activity"
					element={<UserActivity />}
				/>
				{/* <Route
					path="/call-support/agent"
					element={<CallCenterTreeview />}
				/> */}
				<Route path="/vendor" element={<Vendor />} />
				<Route path="/hub" element={<HubList />} />
				<Route path="/spoke" element={<SpokeList />} />
				<Route
					path="/vendor/agents/my-agent-lead"
					element={<CCAllocateAgentLead />}
				/>
				<Route
					path="/vendor/call-support/user-details"
					element={<MyVendorAgents />}
				/>
				<Route
					path="/vendor/application-list"
					element={<VendorLoanApplicationList />}
				/>
				<Route
					path="/vendor/insurance-application-list"
					element={<VendorLoanApplicationInsuranceList />}
				/>
				{/* <Route
					path="/vendor/application-report"
					element={<ApplicationVendorReport />}
				/> */}
				{/* <Route
					path="/vendor/application-report-oe"
					element={<OEApplicationVendorReport />}
				/> */}
				<Route
					path="/vendor/application-list-by-leadId/:leadId"
					element={<VendorLoanApplicationList />}
				/>
				<Route
					path="/vendor/application-create"
					element={<CreateVendorApplication />}
				/>
				<Route
					path="/vendor/application/create"
					element={<VendorLoanApplication />}
				/>
				<Route path="/vendor/lead/all" element={<VendorAllLead />} />
				<Route path={`/create-menu`} element={<MenuCreate />} />
				<Route
					path="/vendor/lead/new-lead"
					element={<VendorCreateLead />}
				/>
				<Route
					path="/vendor/agent-profile/:id"
					element={<AgentProfile />}
				/>
				<Route
					path="/vendor/lead/edit-lead/:id"
					element={<VendorEditDialog />}
				/>
				<Route
					path="/vendor/lead/preview-lead/:id"
					element={<VendorPreviewLead />}
				/>
				<Route
					path="/vendor/hospicash/edit/:id"
					element={<VendorHospicasEdit />}
				/>
				<Route path="/rating/application" element={<Ratings />} />
				<Route path="/admin-dashboard" element={<CountryDashboard />} />
			</Route>
			{/* <Route path=":id" element={<PageCall />} /> */}

			<Route path="*" element={<ErrorPage />} />
		</Routes>
	);
};

export default App;
