const Completion = () => {
	return (
		<div className="mt-6">
			<p className="text-center">
				<i
					className="pi pi-verified"
					style={{ fontSize: "30px", color: "green" }}
				/>
			</p>

			<h3 className="text-center">Status updated successfully</h3>
		</div>
	);
};

export default Completion;
