import axios from "axios";
import { agent_end_point_url, apiPath } from "../shared/constant";
import { useSelector } from "react-redux";
export class CallCenterService {
	selector = useSelector((state) => state.persistData.auth);
	constructor() {
		// this.token = sessionStorage.getItem("token");
		this.token = this.selector.token;
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}

	createCallCenterUser = async (payload) => {
		const url = `${agent_end_point_url}/vendor/vendor-user/create`;
		try {
			const response = await axios.post(url, payload, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	updateCallCenterUser = async (payload) => {
		const url = `${agent_end_point_url}/vendor/vendor-user/update`;
		try {
			const response = await axios.post(url, payload, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	deleteCCUserList = async (requestBody) => {
		const url = `${agent_end_point_url}/vendor/vendor-user/delete/${requestBody}`;
		try {
			const response = await axios.post(
				url,
				{},
				{
					headers: this.headers,
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	callCenterUserList = async (payload) => {
		const url = `${agent_end_point_url}/vendor/vendor-user/search`;
		try {
			const response = await axios.post(url, payload, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	callCenterUserActivityList = async (payload) => {
		const url = `${agent_end_point_url}/activity-history/search`;
		try {
			const response = await axios.post(url, payload, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getCallCenterRoleById = async (userName) => {
		const url = `${agent_end_point_url}/vendor/role-list/${userName}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAllHub = async (userName) => {
		const url = `${agent_end_point_url}/vendor/hub/all`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getSpokeListById = async (userId) => {
		const url = `${agent_end_point_url}/vendor/spoke-list/${userId}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getCallCenterAgentByusername = async (userName) => {
		const url = `${agent_end_point_url}/vendor/role-list/${userName}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getTsrmByHubIdspokeId = async (body) => {
		const url = `${agent_end_point_url}/vendor/tsrm-list/search`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAgentList = async (body) => {
		const url = `${agent_end_point_url}/vendor/agent/all-vendor-agents`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAllTsrmByHubIdspokeIdByCountryAdmin = async (body) => {
		const url = `${agent_end_point_url}/vendor/all-tsrm-list/search`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getCCAgentLead = async (body) => {
		const url = `${agent_end_point_url}/vendor/agent-lead/search`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	archiveAgent = async (body) => {
		const url = `${agent_end_point_url}/vendor/agent-lead/hidden`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getUSerWiseSpokeList = async () => {
		const url = `${agent_end_point_url}/vendor/userwise-spoke-list`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	allocateLead = async (body) => {
		const url = `${agent_end_point_url}/vendor/allocate/agent`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agentLeadStatusUpdate = async (body) => {
		const url = `${agent_end_point_url}/vendor/agent-lead-status`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agentLeadNoteSave = async (body) => {
		const url = `${agent_end_point_url}/vendor/save-notes`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	sendMessage = async (body) => {
		const url = `${agent_end_point_url}/social/agent-lead/share`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agentLeadReminder = async (body) => {
		const url = `${agent_end_point_url}/vendor/remainder`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agentReminder = async (body) => {
		const url = `${agent_end_point_url}/vendor/agent/remainder`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAllagentReminder = async (body) => {
		const url = `${agent_end_point_url}/vendor/agent/remainders-list-monthly`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	deleteReminderById = async (id) => {
		const url = `${agent_end_point_url}/vendor/agent/remainder/delete/${id}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getUserByHubId = async (id) => {
		const url = `${agent_end_point_url}/vendor/tasm-lists/${id}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	bulkAgentLeadCreation = async (payload) => {
		try {
			const res = await axios.post(
				`${agent_end_point_url}/social/bulkAgentLead-preview-create`,
				payload,
				{
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${this.token}`,
					},
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	bulkAgentLeadSAve = async (payload) => {
		try {
			const res = await axios.post(
				`${agent_end_point_url}/social/bulk/leads/create`,
				payload,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getStatusById = async (id) => {
		const url = `${agent_end_point_url}/social/agent-lead-status/${id}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAgent = async (body) => {
		const url = `${agent_end_point_url}/vendor/agent/search`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	sendCall = async (body) => {
		const url = `${agent_end_point_url}/social/agent-lead/call/${body}`;
		try {
			const response = await axios.get(url, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getVendorApplicationList = async (body) => {
		const url = `${apiPath}/vendor-application/search`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getVendorLeadList = async (body) => {
		const url = `${agent_end_point_url}/vendor/lead/search`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	assignLeadToOE = async (body) => {
		const url = `${agent_end_point_url}/vendor/lead/assign/oe`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	assignApplicationToOE = async (body) => {
		const url = `${apiPath}/vendor-application/assign/oe`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	allOEList = async () => {
		const url = `${agent_end_point_url}/vendor/lead/user/all-oe`;
		try {
			const response = await axios.get(url, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	saveLeadNote = async (data) => {
		const url = `${agent_end_point_url}/vendor/lead/save-notes`;
		try {
			const response = await axios.post(url, data, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	saveApplicationNote = async (data) => {
		const url = `${apiPath}/vendor-application/save-notes`;
		try {
			const response = await axios.post(url, data, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	saveAgentNote = async (data) => {
		const url = `${agent_end_point_url}/vendor/agent/save-notes`;
		try {
			const response = await axios.post(url, data, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	sendLeadCall = async (body) => {
		const url = `${agent_end_point_url}/vendor/lead/call/${body}`;
		try {
			const response = await axios.get(url, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	sendAgentCall = async (body) => {
		const url = `${agent_end_point_url}/vendor/agent/call/${body}`;
		try {
			const response = await axios.get(url, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	allocateAgentTsrm = async (data) => {
		const url = `${agent_end_point_url}/vendor/allocate/tsrm`;
		try {
			const response = await axios.post(url, data, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	addProgramToApplication = async (data) => {
		const url = `${apiPath}/vendor-application/create`;
		try {
			const response = await axios.post(url, data, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	updateProgramToApplication = async (data) => {
		const url = `${apiPath}/vendor-application/program/update`;
		try {
			const response = await axios.post(url, data, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	rejectApplication = async (data) => {
		const url = `${apiPath}/vendor-application/status-reject`;
		try {
			const response = await axios.post(url, data, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	revertApplication = async (data) => {
		const url = `${apiPath}/vendor-application/revert-by-oe`;
		try {
			const response = await axios.post(url, data, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getRm = async (data) => {
		const url = `${agent_end_point_url}/vendor/agent/all-rm-asm-list`;
		try {
			const response = await axios.post(url, data, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAgentLeadCallLog = async (data) => {
		const url = `${agent_end_point_url}/agent-lead-call-history`;
		try {
			const response = await axios.post(url, data, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAgentCallLog = async (data) => {
		const url = `${agent_end_point_url}/vendor/agent/vendor-agent-call-history`;
		try {
			const response = await axios.post(url, data, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getApplicationNoteList = async (data) => {
		const url = `${apiPath}/vendor-application/notes/application/${data}`;
		try {
			const response = await axios.get(url, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getStateWiseDistrict = async (data) => {
		const url = `${agent_end_point_url}/state-wise-district`;
		try {
			const response = await axios.post(url, data, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	set_work_in_progress = async (data) => {
		const url = `${apiPath}/vendor-application/oe-work-in-progress`;
		try {
			const response = await axios.post(url, data, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAllReminderListByAgentId = async (data) => {
		const url = `${agent_end_point_url}/vendor/agent/remainder-list/${data}`;
		try {
			const response = await axios.get(url, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	deleteListByParentId = async (data) => {
		const url = `${agent_end_point_url}/vendor/agent/remainder/delete-series/${data}`;
		try {
			const response = await axios.get(url, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
}
