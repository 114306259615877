import React, { useState, useEffect, useRef, useReducer } from "react";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";

import {
	searchInitialState,
	searchReducer,
} from "../../../components/searchReducer";
import Config from "../../../shared/config";
import Paginator from "../../../components/Paginator";
import CreateCallCenterUser from "./components/CreateCallCenterUser";
import { CallCenterService } from "../../../services/callCenterService";
import SearchCallCenterComponent from "./components/SearchCallCenter";
import Loader from "../../../components/Loader";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Field, Form, Formik } from "formik";
import { FieldPassword } from "../../../components/FieldInput";
import { AgentService } from "../../../services/AgentService";
import { confirmDialog } from "primereact/confirmdialog"; // To use confirmDialog method
import { UsersService } from "../../../services/UsersService";

const CallCenterUsers = () => {
	const userData = useSelector((state) => state.persistData.auth.user.role);
	const callcenterService = new CallCenterService();
	const toastTL = useRef(null);
	const [loader, setLoader] = useState(false);
	const [members, setMembers] = useReducer(dataReducer, fetchInitialState);
	// const [visible, setVisible] = useState({ confirm: false, id: null });
	const [role, setRole] = useState([{ id: "" }]);
	const [memberDialog, setMemberDialog] = useState(false);
	const [memberData, setMemberData] = useState(null);
	const [type, setType] = useState("create");
	const handelUserPasswordDialoge = (e) => {
		setUserName(e.userName);
		setUserPasswordDialog(true);
	};

	const userService = new UsersService();
	const [userPasswordDialog, setUserPasswordDialog] = useState(false);
	const agentService = new AgentService();
	const [userName, setUserName] = useState("");
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const lodarTblArray = Array.from({ length: 10 });
	const skeletonBody = () => {
		return <Skeleton height="1rem"></Skeleton>;
	};

	// const accept = () => {
	// 	setLoader(true);
	// 	callcenterService
	// 		.deleteCCUserList(visible.id)
	// 		.then((res) => {
	// 			toastTL.current.show({
	// 				severity: "success",
	// 				summary: "Success",
	// 				detail: res,
	// 				style: { color: "#000000" },
	// 				life: 3000,
	// 			});
	// 			userDataTableValue();
	// 		})
	// 		.catch((e) =>
	// 			toastTL.current.show({
	// 				severity: "error",
	// 				summary: "Error",
	// 				detail: e?.message,
	// 				style: { color: "#000000" },
	// 				life: 3000,
	// 			})
	// 		)
	// 		.finally(() => setLoader(false));
	// };

	// const reject = () => {
	// 	setVisible((e) => ({
	// 		...e,
	// 		confirm: true,
	// 		id: null,
	// 	}));
	// };
	useEffect(() => {
		let ignore = false;
		if (!ignore && userData?.id) {
			callcenterService
				.getCallCenterRoleById(userData.id)
				.then((res) => {
					setRole(res);
				})
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: e.name,
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});
				});
		}
		return () => {
			ignore = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	const userDataTableValue = () => {
		const requestBody = {
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.searchValue !== "" && searchState.searchValue),
			...(searchState.sortByColumn && searchState.sortType
				? {
						sorts: [
							searchState.sortByColumn +
								"," +
								searchState.sortType,
						],
				  }
				: { sorts: [] }),
		};

		// if (searchValue !== "" && operation) {
		// 	requestBody.searchRequest = ;
		// }
		// if (searchState.sortByColumn && searchState.sortType) {
		// 	requestBody.sorts = [
		// 		,
		// 	];
		// }
		setMembers({ type: Config.FETCH_CONFIG.start });
		callcenterService
			.callCenterUserList(requestBody)
			.then((res) => {
				setMembers({ type: Config.FETCH_CONFIG.success, payload: res });
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setMembers({ type: Config.FETCH_CONFIG.stop });
				setMembers({ type: Config.FETCH_CONFIG.fetched });
			});
	};

	useEffect(() => {
		userDataTableValue();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);

	/**
	 * Table Sort Function
	 */
	const addMemberClick = (item) => {
		setMemberDialog(true);
		setMemberData(item);
	};
	const validationSchema = Yup.object().shape({
		newPassword: Yup.string()
			.required("Password is required")
			.min(8, "Password must have at least 8 characters")
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
			),
		cnfNewPassword: Yup.string().oneOf(
			[Yup.ref("newPassword"), null],
			"Passwords must match"
		),
	});
	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	function tblSort(columnName, sortType) {
		// setSortByColumn(columnName);
		// setSortType(sortType);

		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}

	const increment = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const pagesizechange = (e) => {
		// const newPageNo = Math.ceil(
		// 	(members.data.pageSize * members.data.pageNumber) / e.target.value
		// );

		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};
	const handleDisable = (id) => {
		setLoader(true);
		userService
			.disableUser(id)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: "User disabled Successfully",
					style: { color: "#000000" },
					life: 3000,
				});
				setLoader(false);
				userDataTableValue();
			})
			.catch((e) => {
				setLoader(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				});
			});
	};
	const handleEnable = (id) => {
		setLoader(true);
		userService
			.enableUser(id)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: "User Enabled Successfully",
					style: { color: "#000000" },
					life: 3000,
				});
				setLoader(false);
				userDataTableValue();
			})
			.catch((e) => {
				setLoader(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				});
			});
	};
	/**
	 *  Table Templates
	 */
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />
				<Column header={getSortableColumn("Name", "firstName")} />
				<Column header={"User Status"} />
				<Column header={"Mobile"} />
				<Column header={getSortableColumn("User Name", "userName")} />
				<Column header={"Parent username"} />
				<Column header={"Hub"} />
				{userData?.position !== 2000 && <Column header={"Spoke"} />}
				<Column header={"Role"} />
				{/* <Column header={getSortableColumn("Gender", "gender")} />
				
				<Column header="Employee Id" /> */}
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const actionTemplate = (item) => {
		return (
			<div className="flex gap-2">
				<Button
					icon={"pi pi-pencil"}
					className="icon-btn p-button-text"
					tooltip="Edit User"
					tooltipOptions={{
						position: "bottom",
					}}
					onClick={() => {
						setType("edit");
						setMemberDialog(true);
						const data = {
							...item,
							spokeId:
								item?.spokeId === "null"
									? []
									: item?.spokeId
											?.split(",")
											.map((item) => Number(item)),
						};

						setMemberData(data);
					}}
				/>

				{(userData?.position === 1000 ||
					userData?.position === null) && (
					<Button
						className="icon-btn success-btn"
						tooltip="User Password Change"
						tooltipOptions={{ position: "bottom" }}
						onClick={() => handelUserPasswordDialoge(item)}
					>
						<i className="pi pi-pencil"></i>
					</Button>
				)}
				{item?.isActive ? (
					<Button
						className="icon-btn primary-btn"
						tooltip="Disable User"
						tooltipOptions={{
							position: "bottom",
						}}
						onClick={() =>
							confirmDialog({
								message: "Are you sure you want to proceed?",
								header: "Confirmation",
								icon: "pi pi-exclamation-triangle",
								accept: () => handleDisable(item.userName),
								reject: () => {},
							})
						}
						icon={"pi pi-trash"}
					/>
				) : (
					<Button
						className="icon-btn primary-btn"
						tooltip="Enable User"
						tooltipOptions={{
							position: "left",
						}}
						onClick={() =>
							confirmDialog({
								message: "Are you sure you want to proceed?",
								header: "Confirmation",
								icon: "pi pi-exclamation-triangle",
								accept: () => handleEnable(item.userName),
								reject: () => {},
							})
						}
						icon={"pi pi-user"}
					/>
				)}
				{/* <Button
					icon={"pi pi-trash"}
					className="icon-btn p-button-text p-button-danger"
					tooltip="Delete User"
					tooltipOptions={{
						position: "bottom",
					}}
					onClick={() => {
						setVisible((e) => ({
							...e,
							confirm: true,
							id: item.id,
						}));
					}}
				/> */}
			</div>
		);
	};

	const roleColor = (item) => {
		switch (item.roleId) {
			case 18:
				return <span className="status status-warning">TSRM</span>;

			case 19:
				return <span className="status status-danger">TASM</span>;
			case 23:
				return <span className="status status-danger">OE</span>;

			// case 2:
			// 	return (
			// 		<span className="status status-primary">
			// 			{item.roleName}
			// 		</span>
			// 	);

			// case 4:
			// 	return (
			// 		<span className="status status-danger-deep">
			// 			{item.roleName}
			// 		</span>
			// 	);

			// case 5:
			// 	return (
			// 		<span className="status status-success">
			// 			{item.roleName}
			// 		</span>
			// 	);
			// case 50:
			// 	return (
			// 		<span className="status status-success">
			// 			{item.roleName}
			// 		</span>
			// 	);

			default:
				return "";
		}
	};

	const getName = (item) => {
		let fname = item.firstName ? item.firstName : "";
		let mname = item.middleName ? item.middleName : "";
		let lname = item.lastName ? item.lastName : "";
		let name = `${fname} ${mname} ${lname}`;
		return name;
	};

	const memberDialogClose = (e) => {
		setMemberDialog(e);
	};
	const newData =
		members.data.data &&
		members.data.data.map((elm) => ({
			Id: elm.id,
			"Employee Id": elm.employeeId,
			"Role Name": elm.roleName,
			// "Role Id": elm.roleId,
			Position: elm.position,
			Username: elm.userName,
			"User Type": elm.userType,
			Name:
				(elm.firstName ? elm.firstName : "") +
				" " +
				(elm.middleName ? elm.middleName : "") +
				" " +
				(elm.lastName ? elm.lastName : ""),
			"Email Id": elm.emailId,
			"Created On": elm.createdOn,
			Gender: elm.gender,
			"Mobile Number": elm.mobileNumber,
			"Parent Id": elm.parentId,
		}));

	// const getParentName = (item) => {
	// 	let fname = item.parentIdFirstName ? item.parentIdFirstName : "";
	// 	let mname = item.parentIdMiddleName ? item.parentIdMiddleName : "";
	// 	let lname = item.parentIdLastName ? item.parentIdLastName : "";
	// 	let userName = item.parentId ? `(${item.parentId})` : "";
	// 	let parentName = `${fname} ${mname} ${lname} ${userName}`;
	// 	return parentName;
	// };
	// const createdOnTemplate = (item) => {
	// 	return item.createdOn
	// 		? moment(item.createdOn).format("Do MMM, YYYY")
	// 		: "";
	// };
	const handleChangePassword = (e) => {
		setLoader(true);
		let requestBody = {
			newPassword: e.newPassword,
			userName: userName,
		};
		agentService
			.agentPasswordChange(requestBody)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: "Password Changed Successfully",
					style: { color: "#000000" },
					life: 3000,
				});
				setUserPasswordDialog(false);
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setLoader(false);
			});
	};
	return (
		<>
			<ConfirmDialog />
			<Dialog
				header="Agent Password Change"
				visible={userPasswordDialog}
				onHide={() => {
					setUserPasswordDialog(false);
					// setSpinner(false);
				}}
			>
				<div className="card modal-form-grid">
					<Formik
						onSubmit={handleChangePassword}
						initialValues={{
							newPassword: "",
							cnfNewPassword: "",
						}}
						validationSchema={validationSchema}
						enableReinitialize
					>
						{({ handleSubmit }) => (
							<Form>
								<div className="form-grid grid">
									<div className="field col-12 md:col-4">
										<Field
											header={"Password"}
											name={"newPassword"}
											component={FieldPassword}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Confirm Password"}
											name={"cnfNewPassword"}
											component={FieldPassword}
										/>
									</div>
									<div className="col-12 flex align-items-end justify-content-end">
										<Button
											type="button"
											label="Save "
											icon="pi pi-save"
											onClick={handleSubmit}
										/>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Dialog>
			{loader && <Loader />}
			{/* <ConfirmDialog
				visible={visible.confirm}
				onHide={() =>
					setVisible((e) => ({
						...e,
						confirm: false,
						id: null,
					}))
				}
				message="Are you sure you want to proceed?"
				header="Confirmation"
				icon="pi pi-exclamation-triangle"
				accept={accept}
				reject={reject}
			/> */}
			<Toast ref={toastTL} position="top-left" />
			<SearchCallCenterComponent
				addMemberClick={addMemberClick}
				searchDispatch={searchDispatch}
				role={role}
				newData={newData}
				setType={setType}
				type={type}
			/>
			<div className="grid">
				{members.isLoading ? (
					<div className="col-12">
						<DataTable
							headerColumnGroup={headerGroup}
							value={lodarTblArray}
							responsiveLayout="stack"
							breakpoint="960px"
						>
							{/* <Column body={skeletonBody} /> */}
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
							<Column body={skeletonBody} />
						</DataTable>
					</div>
				) : (
					<div className="col-12">
						{members.data?.data?.length > 20 && (
							<Paginator
								pageSize={searchState.pageSize}
								firstPage={members.data.firstPage}
								lastPage={members.data.lastPage}
								decrement={decrement}
								increment={increment}
								pagesizechange={pagesizechange}
								pageNo={members.data.pageNo}
								totalPages={members.data.totalPages}
								totalElements={members.data.totalElements}
							/>
						)}
						{members?.isFetched && (
							<DataTable
								headerColumnGroup={headerGroup}
								value={members.data?.data?.map(
									(item, index) => ({
										...item,
										index:
											members.data.pageSize *
												members.data.pageNo -
											members.data.pageSize +
											1 +
											index,
									})
								)}
								responsiveLayout="scroll"
								breakpoint="960px"
							>
								<Column field="index" />
								<Column body={getName} />
								<Column
									body={(item) =>
										item?.isActive ? (
											<span className="px-2 py-1 border-round text-green-700 bg-green-100">
												Active
											</span>
										) : (
											<span className="px-2 py-1 border-round text-red-700 bg-red-100">
												Disable
											</span>
										)
									}
								/>
								<Column field="mobileNumber" />
								<Column field="userName" />
								<Column field="parentUserName" />
								<Column field="hubName" />
								{userData?.position !== 2000 && (
									<Column field="spokeName" />
								)}
								<Column body={roleColor} />
								{/* <Column field="gender" />
							<Column body={getParentName} />
							<Column field="employeeId" /> */}
								<Column body={actionTemplate} />
							</DataTable>
						)}

						{members.data?.data?.length > 0 && (
							<Paginator
								pageSize={searchState.pageSize}
								firstPage={members.data.firstPage}
								lastPage={members.data.lastPage}
								decrement={decrement}
								increment={increment}
								pagesizechange={pagesizechange}
								pageNo={members.data.pageNo}
								totalPages={members.data.totalPages}
								totalElements={members.data.totalElements}
							/>
						)}
					</div>
				)}

				<Dialog
					header="Add Users"
					visible={memberDialog}
					style={{ width: "70vw" }}
					breakpoints={{ "960px": "75vw", "640px": "95vw" }}
					onHide={() => setMemberDialog(false)}
				>
					<CreateCallCenterUser
						data={memberData}
						roles={role}
						type={type}
						setMemberDialog={setMemberDialog}
						userDataTableValue={userDataTableValue}
						memberDialogClose={memberDialogClose}
					/>
				</Dialog>
			</div>
		</>
	);
};
export default CallCenterUsers;
