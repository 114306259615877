import { Divider } from "primereact/divider";
import { SelectButton } from "primereact/selectbutton";
import React, { useEffect, useReducer, useState } from "react";
import AgentPerformance from "./Performance";
import { VendorService } from "../../../../services/vendorService";
import { produce } from "immer";
import BankDetails from "./BankDetails";
import VerificationDetails from "./VerificationDetails";
import { Field, Form, Formik } from "formik";
import { FieldTextArea } from "../../../../components/FieldInput";
import { Button } from "primereact/button";
const ProfileIndex = (props) => {
	const [value2, setValue2] = useState(1);
	const vendorService = new VendorService();
	const initialState = {
		listData: [],
		leadData: [],
		applicationData: [],
	};
	const reducer = produce((state, action) => {
		switch (action.type) {
			case "list":
				state.listData = action.list;
				break;
			case "lead":
				state.leadData = action.lead;
				break;
			case "application":
				state.applicationData = action.application;
				break;
			default:
				return state;
		}
	});
	const [productData, setProductData] = useReducer(reducer, initialState);
	const paymentOptions = [
		{ name: "Performence", value: 1 },
		{ name: "Verification Details", value: 2 },
		{ name: "Bank Details", value: 3 },
		{ name: "Notes", value: 4 },
	];
	if (props?.agent?.profile?.profileType === "COMPANY") {
		paymentOptions.push({
			name: "Company Details",
			value: 5,
		});
	}
	const getData = (profileId) => {
		Promise.all([
			vendorService.productWiseLeadApplication(profileId),
			vendorService.productWiseLead(profileId),
			vendorService.productWiseApplication(profileId),
		]).then((res) => {
			setProductData({
				type: "list",
				list: res[0],
			});
			setProductData({
				type: "lead",
				lead: res[1],
			});
			setProductData({
				type: "application",
				application: res[2],
			});
		});
	};
	useEffect(() => {
		getData(props?.agentData?.profileId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="col-12 c-agent-info-profile">
				<Divider align="left" type="dashed">
					<div className="inline-flex align-items-center">
						<b>Profile</b>
					</div>
				</Divider>
				<SelectButton
					value={value2}
					options={paymentOptions}
					onChange={(e) => setValue2(e.value)}
					optionLabel="name"
				/>
			</div>
			{value2 === 1 && <AgentPerformance productData={productData} />}
			{value2 === 2 && <VerificationDetails agent={props.agent} />}
			{value2 === 3 && <BankDetails agent={props.agent} />}
			{value2 === 4 && (
				<Formik
					initialValues={{
						notes: props?.agent?.profile?.agent?.notes
							? props?.agent?.profile?.agent?.notes
							: "",
					}}
					onSubmit={props.handleNoteSubmit}
				>
					{({ handleSubmit, resetForm }) => (
						<Form
							className="modal-form-grid border-round-md col-12 mt-2 p-4 form-grid grid"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 ">
								<Field
									required
									header={"Note"}
									name={"notes"}
									component={FieldTextArea}
								/>
							</div>
							<div className="col-12 flex align-items-end justify-content-end">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Save" />
							</div>
						</Form>
					)}
				</Formik>
			)}
			{value2 === 5 && (
				<div>
					{props.agent.profile.company && (
						<div className="grid">
							<div className="col-12 lg:col-6">
								<div className="text-key-val-grid">
									<p className="m-0 lable">Company Name:</p>
									<p className="m-0 value">
										{props.agent.profile.company.name}
									</p>
								</div>
								<div className="text-key-val-grid gap-5">
									<p className="m-0 lable font-600">
										Company Address:
									</p>
									<p className="m-0 value text-right">
										{props.agent.profile.company.address1 &&
											`${props.agent.profile.company.address1}, `}
										{props.agent.profile.company.address2 &&
											`${props.agent.profile.company.address2}, `}
										{props.agent.profile.company.address3 &&
											`${props.agent.profile.company.address3} `}
									</p>
								</div>
								<div className="text-key-val-grid">
									<p className="m-0 lable">Pin:</p>
									<p className="m-0 value">
										{props.agent.profile.company.pincode}
									</p>
								</div>
								<div className="text-key-val-grid">
									<p className="m-0 lable">Representative:</p>
									<p className="m-0 value">
										{`${props.agent.profile.company.representativeFirstName} ${props.agent.profile.company.representativeLastName}`}
									</p>
								</div>
								<div className="text-key-val-grid">
									<p className="m-0 lable">Designation :</p>
									<p className="m-0 value">
										{
											props.agent.profile.company
												.designation
										}
									</p>
								</div>
								<div className="text-key-val-grid">
									<p className="m-0 lable">
										Designation type:
									</p>
									<p className="m-0 value">
										{props.agent.profile.company.type}
									</p>
								</div>
								<div className="text-key-val-grid">
									<p className="m-0 lable">Mobile no:</p>
									<p className="m-0 value">
										{
											props.agent?.profile?.company
												?.representativeMobile
										}
									</p>
								</div>
								<div className="text-key-val-grid">
									<p className="m-0 lable">Pan :</p>
									<p className="m-0 value">
										{props.agent?.profile.company?.pan}
									</p>
								</div>
							</div>
							{console.log(props.agent.profile)}
							<div className="col-12 lg:col-6">
								{props.agent.profile.company.fileSequence && (
									<>
										<p style={{ fontWeight: 700 }}>
											Document
										</p>
										<img
											src={`https://rainbow-india.s3.ap-south-1.amazonaws.com/agent-profile/${props.agent.profile?.id}/${props.agent.profile.company.fileSequence}`}
											style={{ width: "50%" }}
											alt={
												props.agent.profile.company
													.fileSequence
											}
											className="border-solid border-white-alpha-90"
										/>
									</>
								)}
								{props.agent.profile.company.panFile && (
									<>
										<p style={{ fontWeight: 700 }}>Pan</p>
										<img
											src={`https://rainbow-india.s3.ap-south-1.amazonaws.com/agent-profile/${props.agent.profile?.id}/${props.agent.profile.company.panFile}`}
											style={{ width: "50%" }}
											alt={
												props.agent.profile.company
													.fileSequence
											}
											className="border-solid border-white-alpha-90"
										/>
									</>
								)}
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default ProfileIndex;
