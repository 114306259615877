import React, { useState, useRef } from "react";
import ProgramDialog from "./setUpDialog/ProgramDialog";
import { Accordion, AccordionTab } from "primereact/accordion";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MdDelete, MdContentCopy } from "react-icons/md";
import { MdModeEditOutline } from "react-icons/md";
import { Toast } from "primereact/toast";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { ProgramService } from "../../../services/ProgramService";
import { useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import { FeildDropdown, FieldInput } from "../../../components/FieldInput";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { useGetProductDetailsQuery } from "../../../services/query/queryApi";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import TableLoader from "../../../components/TableLoader";

import { ProgressBar } from "primereact/progressbar";
import Config from "../../../shared/config";

const Program = () => {
	const [showDialog, setShowDialog] = useState(false);
	const [expandedRows, setExpandedRows] = useState(null);
	const navigate = useNavigate();
	const toast = useRef(null);

	const programService = new ProgramService();
	const { data = { data: [] }, isLoading } = useGetProductDetailsQuery({
		searchRequest: [],
		pageNo: 1,
		pageSize: 300,
		sorts: [],
	});

	const reducer = (state, action) => {
		switch (action.type) {
			case "SPINNER":
				return { ...state, spinner: action.payload };
			case "SUBID":
				return { ...state, subProductId: action.payload };
			case "INS":
				return { ...state, institutionWiseListData: action.payload };

			case "DIALOG":
				return { ...state, dialog: action.payload };
			default:
				return state;
		}
	};

	const [state, dispatch] = React.useReducer(reducer, {
		spinner: false,
		subProductId: "",
		institutionWiseListData: [],
		dialog: false,
	});

	const setDaialog = (e) => setShowDialog(e);
	const getDataById = (e) => {
		const requestData = { subProductId: e };
		dispatch({ type: "SPINNER", payload: true });
		programService
			.getInstitionWiseList(requestData)
			.then((res) => {
				dispatch({ type: "INS", payload: res });
			})
			.catch((e) => console.log(e))
			.finally(() => {
				dispatch({ type: "SPINNER", payload: false });
			});

		// programService
		// 	.getInstitionWiseList(requestData)
		// 	.then((res) => {
		// 		const newArray = { data: res, subProductId: e };
		// 		dispatch({ payload: newArray });
		// 		//console.log(newArray);
		// 	})
		// 	.catch((e) => console.log(e));
	};

	const deleteProgram = (e) => {
		programService
			.deleteProgram(e)
			.then((res) =>
				toast.current.show({
					severity: "info",
					summary: "Info Message",
					detail: "Program Deleted",
					life: 3000,
				})
			)
			.catch((error) =>
				toast.current.show({
					severity: "error",
					summary: "Error Message",
					detail: "Unable to delete program",
					life: 3000,
				})
			);
	};
	const reject = () => {
		toast.current.show({
			severity: "warn",
			summary: "Rejected",
			detail: "You have rejected",
			life: 3000,
		});
	};

	const showConfirm = (data) => {
		confirmDialog({
			message: "Are you sure you want to proceed?",
			header: "Confirmation",
			icon: "pi pi-exclamation-triangle",
			accept: () => deleteProgram(data),
			reject: () => reject(),
		});
	};

	const actionTemplate = (item) => {
		return (
			<div className="flex">
				<Button
					className="icon-btn primary-btn mr-2"
					onClick={() =>
						navigate(
							`/partners/program-setup-management/edit/${item.programId}`
						)
					}
					tooltip="Edit"
					tooltipOptions={{ position: "bottom" }}
				>
					<MdModeEditOutline />
				</Button>
				<Button
					className="icon-btn primary-btn mr-2"
					onClick={() =>
						navigate(
							`/partners/program-setup-management/copy/${item.programId}`
						)
					}
					tooltip="Copy Program"
					tooltipOptions={{ position: "bottom" }}
				>
					<MdContentCopy />
				</Button>
				<Button
					onClick={() => showConfirm(item.programId)}
					className="icon-btn bg-red-800 text-yellow-50 mr-2"
					tooltip="Delete"
					tooltipOptions={{ position: "bottom" }}
				>
					{" "}
					<MdDelete />
				</Button>
			</div>
		);
	};
	const handleformSubmit = (value, action) => {
		const val = Object.fromEntries(
			Object.entries(value).filter(([_, v]) => v !== "")
		);
		dispatch({ type: "SPINNER", payload: true });
		programService
			.getInstitionWiseList(val)
			.then((res) => dispatch({ type: "INS", payload: res }))
			.catch((e) => console.log(e))
			.finally(() => {
				action.resetForm();
				dispatch({ type: "SPINNER", payload: false });
			});
	};
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column style={{ width: "3em" }} />
				<Column header={"Line Of Business"} />
				<Column header={"Product Name"} />

				{/* <Column header="Active/Inactive"></Column> */}
			</Row>
		</ColumnGroup>
	);
	const actionDialog = (item) => {
		return (
			<Button
				className="icon-btn primary-btn mr-2"
				onClick={() => {
					dispatch({ type: "DIALOG", payload: true });
					dispatch({ type: "SUBID", payload: item.id });
					getDataById(item.id);
				}}
				tooltip="Select Program"
				tooltipOptions={{ position: "bottom" }}
			>
				<MdModeEditOutline />
			</Button>
		);
	};
	const rowExpansionTemplate = (data) => {
		return (
			<div className="w-full">
				<h5>Sub Products for {data.title}</h5>
				<DataTable
					value={data.subProducts.filter((e) => e.isActive === true)}
					responsiveLayout="scroll"
				>
					<Column field="title" header="Sub Product" />

					<Column header="Select" body={actionDialog} />
				</DataTable>
			</div>
		);
	};
	const allowExpansion = (rowData) => {
		return rowData.subProducts.length > 0;
	};
	const itaration = {
		title: "",
		firstName: "",
		hi: "",
	};

	return (
		<>
			<div className="col">
				<div className="w-full flex flex-wrap justify-content-between align-items-center">
					<h3 className="m-0">Program List</h3>
					<div className="flex align-items-center">
						<Button
							icon="pi pi-plus"
							className="p-button-rounded p-button-primary p-button-sm mr-2"
							onClick={() => setShowDialog(true)}
						/>
						<h4>Add New Program</h4>
					</div>
				</div>
			</div>

			<Dialog
				header="Program Setup"
				visible={showDialog}
				onHide={() => setShowDialog(false)}
				style={{ width: "80%" }}
			>
				<ProgramDialog dialog={setDaialog} />
			</Dialog>
			<Toast ref={toast} />
			<ConfirmDialog />
			<>
				{isLoading ? (
					<TableLoader
						headerGroup={headerGroup}
						itaration={itaration}
					/>
				) : (
					<DataTable
						headerColumnGroup={headerGroup}
						value={data.data.filter(
							(item) => item.isActive === true
						)}
						expandedRows={expandedRows}
						onRowToggle={(e) => setExpandedRows(e.data)}
						rowExpansionTemplate={rowExpansionTemplate}
						responsiveLayout="scroll"
						dataKey="id"
						breakpoint="960px"
					>
						<Column
							expander={allowExpansion}
							style={{ width: "3em" }}
						/>
						<Column field="lineOfBusiness" />
						<Column field="title" />
						{/* <Column body={statusTemplate} /> */}
					</DataTable>
				)}

				<Dialog
					visible={state.dialog}
					onHide={() => dispatch({ type: "DIALOG", payload: false })}
					style={{ width: "80%" }}
					header={"Select Program"}
				>
					<>
						<Formik
							initialValues={{
								// searchRequest: "",
								processingFee: "",
								rateOfInterest: "",
								tenure: "",
							}}
							onSubmit={handleformSubmit}
						>
							{({ values, handleSubmit }) => (
								<Form onSubmit={handleSubmit}>
									<div className="form-grid grid">
										<div className="field col-12 md:col-3"></div>
										<div className="field col-12 md:col-3">
											<Field
												component={FeildDropdown}
												options={Config.YES_NO}
												header={"Processing Fee"}
												name={"processingFee"}
											/>
										</div>
										<div className="field col-12 md:col-3">
											<Field
												type={"number"}
												header={"Rate Of Interest"}
												name={"rateOfInterest"}
												component={FieldInput}
											/>
										</div>
										<div className="field col-12 md:col-2">
											<Field
												type={"number"}
												header={"Tenure"}
												name={"tenure"}
												component={FieldInput}
											/>
										</div>
										<div className="field flex  justify-content-end flex-column col-12 md:col-1">
											<Button
												type="submit"
												label="Search"
											/>
										</div>
									</div>
								</Form>
							)}
						</Formik>

						{state.spinner ? (
							<ProgressBar
								mode="indeterminate"
								style={{
									height: "5px",
									margin: "20px 0 0 30px",
								}}
							/>
						) : (
							<Accordion>
								{state.institutionWiseListData.map(
									(elem, index) => (
										<AccordionTab
											key={
												index.toString() +
												"programDataLoanData"
											}
											header={
												<div className="flex align-items-center">
													<BsFillArrowRightCircleFill
														size={25}
														color="#f5e312"
														className="mr-2"
													/>
													<span>
														{elem.institutionName}
													</span>
												</div>
											}
										>
											<DataTable
												value={elem.programListDtoList}
												responsiveLayout="scroll"
											>
												<Column
													field={"name"}
													header={"Name"}
												/>
												<Column
													field={"minLoanAmount"}
													header={"Min Loan Amount"}
												/>
												<Column
													field={"maxLoanAmount"}
													header={"Max Loan Amount"}
												/>
												<Column
													field={"minProcessingFees"}
													header={
														"Min Processing Fees"
													}
												/>
												<Column
													field={"maxProcessingFees"}
													header={
														"Max Processing Fees"
													}
												/>
												<Column
													field={"minLoanPeriod"}
													header={"Min Loan Period"}
												/>
												<Column
													field={"maxLoanPeriod"}
													header={"Max Loan Period"}
												/>
												<Column
													field={"minRateOfInterest"}
													header={
														"Min Rate Of Interest"
													}
												/>
												<Column
													field={"maxRateOfInterest"}
													header={
														"Max Rate Of Interest"
													}
												/>
												{/* <Column
																body={
																	boolTemplate
																}
																header={
																	"Active"
																}
															/>  */}
												<Column
													body={actionTemplate}
													header={"Actions"}
												/>
											</DataTable>
										</AccordionTab>
									)
								)}
							</Accordion>
						)}
					</>
				</Dialog>
			</>
			{/* <div>
				{state.productDetails &&
					state.productDetails.subProducts.map(
						(e, i) =>
							e.isActive && (
								<Panel
									key={i.toString() + "programDataLoan"}
									header={e.title}
									toggleable
									collapsed
									onExpand={() => getDataById(e.id)}
									className="mb-2"
								></Panel>
							)
					)}
			</div> */}
		</>
	);
};

export default Program;
