import axios from "axios";
import { agent_end_point_url, apiPath } from "../shared/constant";
import { useSelector } from "react-redux";

export class RatingService {
	selector = useSelector((state) => state.persistData.auth);
	constructor() {
		this.token = this.selector.token;
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}
	getAppRatingList = async (payload) => {
		try {
			const res = await axios.post(
				`${agent_end_point_url}/rating/search`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	saveQuestiont = async (payload) => {
		try {
			const res = await axios.post(
				`${agent_end_point_url}/rating/question/save`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	updateQuestiont = async (payload) => {
		try {
			const res = await axios.post(
				`${agent_end_point_url}/rating/question/update`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	getQuestionList = async () => {
		try {
			const res = await axios.get(
				`${agent_end_point_url}/rating/question/list`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	deleteQuestion = async (id) => {
		try {
			const res = await axios.get(
				`${agent_end_point_url}/rating/question/delete/${id}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw error.response.data;
		}
	};
}
