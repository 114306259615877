import React from "react";
import { FiCheck, FiX } from "react-icons/fi";
const ParentAgentDetail = ({ agent }) => {
	const getStatus = (data) => {
		switch (data.status) {
			case "ACTIVE":
				return <span className="status status-success">Active</span>;
			case "INACTIVE":
				return <span className="status status-danger">Inactive</span>;
			case "BLOCKED":
				return <span className="status status-ban">Blocked</span>;

			default:
				return <span className="status status-danger">No Status</span>;
		}
	};
	return (
		<div>
			{agent && (
				<div className="grid">
					<div className="col-12 lg:col-6">
						<div className="text-key-val-grid">
							<p className="m-0 lable">Name:</p>
							<p className="m-0 value">
								{`${
									agent.salutation && agent.salutation + "."
								} ${agent.firstName} ${agent.middleName} ${
									agent.lastName
								}`}
							</p>
						</div>
						<div className="text-key-val-grid">
							<p className="m-0 lable">Username:</p>
							<p className="m-0 value">{agent.userName}</p>
						</div>
						<div className="text-key-val-grid gap-5">
							<p className="m-0 lable font-600">Mobile Number</p>
							<p className="m-0 value text-right">
								{agent.mobileNumber}
							</p>
						</div>

						<div className="text-key-val-grid">
							<p className="m-0 lable">email Id:</p>
							<p className="m-0 value">{agent.emailId}</p>
						</div>
						<div className="text-key-val-grid">
							<p className="m-0 lable">Gender :</p>
							<p className="m-0 value">{agent.gender}</p>
						</div>
					</div>
					<div className="col-12 lg:col-6">
						<div className="text-key-val-grid">
							<p className="m-0 lable">Status :</p>
							<p className="m-0 value">{getStatus(agent)}</p>
						</div>
						<div className="text-key-val-grid">
							<p className="m-0 lable">KYC Verified :</p>
							<p className="m-0 value">
								{agent.kycVerified ? (
									<span className="sq-status status-success">
										<FiCheck />
									</span>
								) : (
									<span className="sq-status status-danger">
										<FiX />
									</span>
								)}
							</p>
						</div>
						<div className="text-key-val-grid">
							<p className="m-0 lable">Registered :</p>
							<p className="m-0 value">
								{agent.registered ? (
									<span className="sq-status status-success">
										<FiCheck />
									</span>
								) : (
									<span className="sq-status status-danger">
										<FiX />
									</span>
								)}
							</p>
						</div>
						<div className="text-key-val-grid">
							<p className="m-0 lable">Whatsapp Update :</p>
							<p className="m-0 value">
								{agent.whatsappUpdate ? (
									<span className="sq-status status-success">
										<FiCheck />
									</span>
								) : (
									<span className="sq-status status-danger">
										<FiX />
									</span>
								)}
							</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ParentAgentDetail;
