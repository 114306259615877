// import { useReducer, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import { LeadService } from "../../../../services/LeadService";
// import {
// 	dataReducer,
// 	fetchInitialState,
// } from "../../../../components/fetchReducer";
// import Config from "../../../../shared/config";
// import { useGetAllDIstrictQuery } from "../../../../services/query/queryApi";
// import { convertNullToString } from "../../../../utils/UtilsFunction";
// import { Divider } from "primereact/divider";
// import moment from "moment";
// const PreviewLead = () => {
// 	const { id } = useParams();
// 	const { data = [] } = useGetAllDIstrictQuery();
// 	const [previewState, previewDispatch] = useReducer(
// 		dataReducer,
// 		fetchInitialState
// 	);
// 	// console.log(data);
// 	const leadService = new LeadService();
// 	const getLeadData = () => {
// 		previewDispatch({ type: Config.FETCH_CONFIG.start });
// 		leadService
// 			.getLeadDetails(id)
// 			.then((res) => {
// 				const data = convertNullToString(res);
// 				previewDispatch({
// 					type: Config.FETCH_CONFIG.success,
// 					payload: data,
// 				});
// 			})
// 			.catch((e) => {
// 				previewDispatch({
// 					type: Config.FETCH_CONFIG.error,
// 					payload: e,
// 				});
// 			})
// 			.finally(() => previewDispatch({ type: Config.FETCH_CONFIG.stop }));
// 	};

// 	useEffect(() => {
// 		let ignore = false;
// 		if (!ignore) {
// 			getLeadData();
// 		}
// 		return () => {
// 			ignore = true;
// 		};
// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, []);
// 	return (
// 		<>
// 			<div className="grid w-full">
// 				<div className="col-12">
// 					<h2 className="mb-2">
// 						{previewState.data.salutation +
// 							" " +
// 							previewState.data.firstName +
// 							" " +
// 							previewState.data.middleName +
// 							" " +
// 							previewState.data.lastName}
// 					</h2>
// 					<h4 className="mb-2 mt-0">{previewState.data.mobile}</h4>
// 					<small className="block font-bold mb-1">
// 						{previewState.data.designation}
// 					</small>
// 					<small className="block">
// 						{previewState.data.companyName}
// 					</small>
// 				</div>
// 				<Divider />
// 				<div className="col-12 grid sm:col-5">
// 					<h3 className="col-12 mb-0">About</h3>

// 					<div className="col-6">
// 						<h5 className="mb-0">Father’s Name</h5>
// 						<small>{previewState.data.fatherName}</small>
// 						<h5 className="mb-0">Mother's Maiden Name</h5>
// 						<small>{previewState.data.motherMaidenName}</small>
// 						<h5 className="mb-0">Marital Status</h5>
// 						<small>{previewState.data.maritalStatus}</small>
// 						{previewState.data.maritalStatus === "Married" && (
// 							<>
// 								<h5 className="mb-0">Spouse Name</h5>
// 								<small>{previewState.data.spouseName}</small>
// 							</>
// 						)}

// 						<h5 className="mb-0">Caste</h5>
// 						<small>{previewState.data.caste}</small>
// 						<h5 className="mb-0">Whatsapp Number</h5>
// 						<small>{previewState.data.whatsappNumber}</small>
// 					</div>
// 					<div className="col-6">
// 						<h5 className="mb-0">Gender</h5>
// 						<small>{previewState.data.gender}</small>
// 						<h5 className="mb-0">D.O.B</h5>
// 						<small>
// 							{moment(previewState.data.dob).format("YYYY-MM-DD")}
// 						</small>
// 						<h5 className="mb-0">Age</h5>
// 						<small>{previewState.data.age}</small>

// 						<h5 className="mb-0">Religion</h5>
// 						<small>{previewState.data.religion}</small>
// 						<h5 className="mb-0">Personal Mail</h5>
// 						<small>{previewState.data.personalMail}</small>
// 					</div>
// 				</div>
// 				<div className="col-12 grid sm:col-4">
// 					<h3 className="col-12 mb-0">Address</h3>
// 					<div className="col-6">
// 						<h5 className="mb-0 ">Address Line 1</h5>
// 						<small>{previewState.data.addressLine1}</small>
// 						<h5 className="mb-0">Address Line 2</h5>
// 						<small>{previewState.data.addressLine2}</small>
// 						<h5 className="mb-0">Address Line 3</h5>
// 						<small>{previewState.data.addressLine3}</small>
// 						<h5 className="mb-0">State</h5>

// 						{data && (
// 							<small>
// 								{previewState.data.state &&
// 									getState(data, previewState.data.state)()
// 										.name}
// 							</small>
// 						)}
// 					</div>
// 					<div className="col-6">
// 						<h5 className="mb-0 ">Building Name</h5>
// 						<small>{previewState.data.buildingName}</small>
// 						<h5 className="mb-0">Landmark</h5>
// 						<small>{previewState.data.landmark}</small>
// 						<h5 className="mb-0">City</h5>
// 						<small>{previewState.data.city}</small>
// 						<h5 className="mb-0">District</h5>

// 						{data && (
// 							<small>
// 								{previewState.data.districtId &&
// 									getState(data, previewState.data.state)() &&
// 									getDistrict(
// 										getState(
// 											data,
// 											previewState.data.state
// 										)().districtList,
// 										previewState.data.districtId
// 									)().name}
// 							</small>
// 						)}
// 					</div>
// 				</div>
// 				<div className="col-12 grid sm:col-4">
// 					<h3 className="col-12 mb-0">Other Details</h3>
// 					<div className="col-6">
// 						<h5 className="mb-0 ">Address Line 1</h5>
// 						<small>{previewState.data.addressLine1}</small>
// 						<h5 className="mb-0">Address Line 2</h5>
// 						<small>{previewState.data.addressLine2}</small>
// 						<h5 className="mb-0">Address Line 3</h5>
// 						<small>{previewState.data.addressLine3}</small>
// 					</div>
// 					<div className="col-6">
// 						<h5 className="mb-0 ">Building Name</h5>
// 						<small>{previewState.data.buildingName}</small>
// 						<h5 className="mb-0">Landmark</h5>
// 						<small>{previewState.data.landmark}</small>
// 						<h5 className="mb-0">City</h5>
// 						<small>{previewState.data.city}</small>
// 					</div>
// 				</div>
// 			</div>
// 		</>
// 	);
// };

// export default PreviewLead;

import React from "react";
import { useLocation, useParams } from "react-router-dom";
import SubmitNewLeadDialog from "./SubmitNewLeadDialog";
import SubmitNewInsuranceLeadDialog from "./SubmitInsuranceLead";
import Config from "../../../../shared/config";
const PreviewLead = () => {
	const locaiton = useLocation();
	const params = useParams();
	return locaiton?.state?.productType === Config.PRODUCT_LEAD_TYPE.LOAN ? (
		<SubmitNewLeadDialog
			location={locaiton}
			type={"preview"}
			leadId={params}
		/>
	) : (
		<SubmitNewInsuranceLeadDialog
			location={locaiton}
			type={"preview"}
			leadId={params}
		/>
	);
};

export default PreviewLead;
