import React, { useEffect, useReducer, useRef, useState } from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
} from "../../../components/FieldInput";
import { ProductService } from "../../../services/ProductService";
import { ProgressSpinner } from "primereact/progressspinner";
import { useGetProductCategoryQuery } from "../../../services/query/queryApi";
import { Divider } from "primereact/divider";
import Config from "../../../shared/config";
import moment from "moment";
import debounce from "lodash.debounce";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import Loader from "../../../components/Loader";

const ProductCommissionStructure = (props) => {
	const {
		data = [],
		isLoading: isSpinning,
		isSuccess,
	} = useGetProductCategoryQuery();
	const [loading, setLoading] = useState(false);
	const productService = new ProductService();
	const toastTL = useRef(null);
	const initialValue = {
		effectiveStartDate: "",
		effectiveEndDate: "",
		commissionType: "",
		profileType: "",
		productAgentCommissionDetailsDtos: [],
	};
	const validationSchema = Yup.object().shape({
		commissionType: Yup.string().required("This field is required"),
		effectiveStartDate: Yup.date()

			// .when(
			// 	"profileType",

			// 	(profileType, value) => {
			// 		return value.test({
			// 			test: (effectiveStartDate) => {
			// 				return new Promise((resolve, reject) => {
			// 					// return true;
			// 					!!profileType &&
			// 						productService
			// 							.getLastCommissionDate({
			// 								productId: props.id,
			// 								profileType: profileType,
			// 							})
			// 							.then((res) => {
			// 								resolve(
			// 									!!new Date(
			// 										effectiveStartDate
			// 									) &&
			// 										new Date(
			// 											effectiveStartDate
			// 										).getTime() >
			// 											new Date(
			// 												res?.effectiveEndDate
			// 											).getTime()
			// 								);
			// 							});
			// 				});
			// 			},
			// 			message: () => !!profileType && "Invalid Date",
			// 		});
			// 	}
			// )
			// .test("profileType", "Invalid Date", (val) => {
			// 	productService
			// 		.getLastCommissionDate({
			// 			productId: props.id,
			// 			profileType: val,
			// 		})
			// 		.then((res) => {});
			// })
			.required("This field is required"),
		profileType: Yup.string().required("This field is required"),
		effectiveEndDate: Yup.string()
			.when("effectiveStartDate", (effectiveStartDate, value) => {
				return value.test({
					test: (effectiveEndDate) =>
						!!new Date(effectiveStartDate) &&
						new Date(effectiveEndDate) >
							new Date(effectiveStartDate),
					message: "Invalid date",
				});
			})
			.required("This field is required"),
		productAgentCommissionDetailsDtos: Yup.array().when("commissionType", {
			is: (data) => data === "percentage",
			then: Yup.array().of(
				Yup.object().shape({
					minAmount: Yup.number()
						.min(0, "Invalid Amount")
						.max(100, "Maximum 100")
						.required("This field is required"),
					maxAmount: Yup.number()
						.min(0, "Invalid Amount")
						.max(100, "Maximum 100")
						.when(["minAmount"], (min, value) => {
							return value.test({
								test: (max) => !!min && max > min,
								message: "Max amount > Min amount",
							});
						})
						.required("This field is required"),
					defaultAmount: Yup.number()
						.when(["minAmount", "maxAmount"], (min, max, value) => {
							return value.test({
								test: (def) => {
									return (
										!!min &&
										!!max &&
										max >= def &&
										def >= min
									);
								},
								message: "Max > Def > Min ",
							});
						})
						.required("This field is required"),
					// defaultNbfcAmount: Yup.number()
					// 	.when(["minAmount", "maxAmount"], (min, max, value) => {
					// 		return value.test({
					// 			test: (def) => {
					// 				return (
					// 					!!min &&
					// 					!!max &&
					// 					max >= def &&
					// 					def >= min
					// 				);
					// 			},
					// 			message: "Max > Def > Min ",
					// 		});
					// 	})
					// 	.required("This field is required"),
				})
			),
			otherwise: Yup.array().of(
				Yup.object().shape({
					minAmount: Yup.number()
						.min(0, "Invalid Amount")
						.required("This field is required"),
					maxAmount: Yup.number()
						.min(0, "Invalid Amount")
						.when(["minAmount"], (min, value) => {
							return value.test({
								test: (max) => !!min && max > min,
								message: "Max amount > Min amount",
							});
						})
						.required("This field is required"),
					defaultAmount: Yup.number()
						.when(["minAmount", "maxAmount"], (min, max, value) => {
							return value.test({
								test: (def) => {
									return (
										!!min &&
										!!max &&
										max >= def &&
										def >= min
									);
								},
								message: "Max > Def > Min ",
							});
						})
						.required("This field is required"),
					// defaultNbfcAmount: Yup.number()
					// 	.when(["minAmount", "maxAmount"], (min, max, value) => {
					// 		return value.test({
					// 			test: (def) => {
					// 				return (
					// 					!!min &&
					// 					!!max &&
					// 					max >= def &&
					// 					def >= min
					// 				);
					// 			},
					// 			message: "Max > Def > Min ",
					// 		});
					// 	})
					// 	.required("This field is required"),
				})
			),
		}),
	});
	const [lastDate, setLastDate] = useReducer(dataReducer, {
		isLoading: false,
		data: {},
	});
	const getLatestDate = () => {
		// setLastDate({ type: Config.FETCH_CONFIG.start });
		productService
			.getLastCommissionDate({
				productId: 2133,
				profileType: "INDIVIDUAL",
			})
			.then(
				(res) => {}
				// setLastDate({ type: Config.FETCH_CONFIG.success, payload: res })
			);
		// .finally(() => setLastDate({ type: Config.FETCH_CONFIG.stop }));
	};
	// useEffect(() => {
	// 	if (props.type === "create") {
	// 		getLatestDate();
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const handleFormSubmit = (value, onSubmitProps) => {
		if (props.type === "create") {
			const submitAgentCommissionStructure = {
				productId: Number(props.id),
				...value,
				effectiveStartDate: value.effectiveStartDate
					? moment(value.effectiveStartDate).format("YYYY-MM-DD")
					: null,
				effectiveEndDate: value.effectiveEndDate
					? moment(value.effectiveEndDate).format("YYYY-MM-DD")
					: null,
			};
			setLoading(true);
			productService
				.createAgentCommissionStructure(submitAgentCommissionStructure)
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: res.message,
						life: 3000,
					});

					props.dialogDispatch({ type: "data", data: {} });
				})
				.then(() => props.searchDispatch())
				.then(
					debounce(() => {
						props.dialogDispatch({ type: "dialog", dialog: false });
					}, 1000)
				)
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						life: 3000,
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			const { effectiveStartDate, effectiveEndDate, ...rest } = value;
			setLoading(true);
			productService
				.updateAgentCommissionStructure({
					...rest,
					effectiveStartDate: effectiveStartDate
						? moment(effectiveStartDate).format("YYYY-MM-DD")
						: null,
					effectiveEndDate: effectiveEndDate
						? moment(effectiveEndDate).format("YYYY-MM-DD")
						: null,
				})
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: res.message,
						life: 3000,
					});

					props.searchDispatch();
					props.dialogDispatch({ type: "data", data: {} });
				})
				.then(
					debounce(() => {
						props.dialogDispatch({ type: "dialog", dialog: false });
					}, 1000)
				)
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						life: 3000,
					});
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	return (
		<div className="card modal-form-grid">
			{loading && <Loader />}
			<Toast ref={toastTL} position="top-left" />
			{props.isLoading ? (
				<div className="flex align-items-center justify-content-center py-5">
					<ProgressSpinner
						style={{
							width: "40px",
							height: "40px",
						}}
						strokeWidth="4"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				</div>
			) : (
				<Formik
					initialValues={
						props.type === "create"
							? {
									...initialValue,

									...(!isSpinning &&
										data.length > 0 && {
											productAgentCommissionDetailsDtos:
												data.map((item) => ({
													categoryId: item.id,
													minAmount: "",
													defaultAmount: "",
													maxAmount: "",
												})),
										}),
							  }
							: {
									...initialValue,
									...(props.initialValue && {
										...props.initialValue,
										effectiveStartDate: props.initialValue
											.effectiveStartDate
											? new Date(
													props.initialValue.effectiveStartDate
											  )
											: "",
										effectiveEndDate: props.initialValue
											.effectiveEndDate
											? new Date(
													props.initialValue.effectiveEndDate
											  )
											: "",
									}),
							  }
					}
					onSubmit={handleFormSubmit}
					validationSchema={validationSchema}
					enableReinitialize
				>
					{({ values, handleSubmit, resetForm }) => (
						<Form className="" onSubmit={handleSubmit}>
							<div className="form-grid grid">
								<div className="field col-12 md:col-3">
									<Field
										name={`effectiveStartDate`}
										component={FeildCalender}
										header={"Effective Start Date"}
										// minDate={
										// 	lastDate.data.effectiveEndDate
										// 		? new Date(
										// 				lastDate.data.effectiveEndDate
										// 		  )
										// 		: null
										// }
										// viewDate={
										// 	lastDate.data.effectiveEndDate
										// 		? new Date("2023-10-06")
										// 		: null
										// }
									/>
								</div>

								<div className="field col-12 md:col-3">
									<Field
										name={`effectiveEndDate`}
										component={FeildCalender}
										header={"Effective End Date"}
										viewDate={
											values.effectiveStartDate
												? new Date(
														values.effectiveStartDate
												  )
												: null
										}
										minDate={
											values.effectiveStartDate
												? new Date(
														values.effectiveStartDate
												  )
												: null
										}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										name={`commissionType`}
										component={FeildDropdown}
										header={"Commission Type"}
										options={[
											{
												label: "Fixed",
												value: "fixed",
											},
											{
												label: "Percentage",
												value: "percentage",
											},
										]}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										name={`profileType`}
										component={FeildDropdown}
										header={"Profile Type"}
										options={Config.ProfileType}
										star={"*"}
									/>
								</div>
							</div>

							{!isSpinning && data.length && (
								<FieldArray
									name="category"
									render={(arrayHelper) => (
										<>
											{
												isSuccess &&
													values.productAgentCommissionDetailsDtos.map(
														(item, index) => (
															<div
																className="form-grid grid"
																key={
																	index.toString() +
																	"mapValueCommision"
																}
															>
																<div className="field col-12 md:col-2 flex align-items-center">
																	<h3 className="m-0">
																		{props.type ===
																		"create"
																			? data[
																					index
																			  ]
																					.name
																			: item.categoryName}
																	</h3>
																</div>

																<div className="field col-12 md:col-2">
																	<Field
																		name={`productAgentCommissionDetailsDtos.${index}.minAmount`}
																		component={
																			FieldInput
																		}
																		header={
																			"Min Commission"
																		}
																		type={
																			"number"
																		}
																		star={
																			"*"
																		}
																	/>
																</div>
																<div className="field col-12 md:col-3">
																	<Field
																		name={`productAgentCommissionDetailsDtos.${index}.defaultAmount`}
																		component={
																			FieldInput
																		}
																		header={
																			"Default Bank Commission"
																		}
																		type={
																			"number"
																		}
																		star={
																			"*"
																		}
																	/>
																</div>
																{props?.state
																	?.alias !==
																	"CC" && (
																	<div className="field col-12 md:col-3">
																		<Field
																			name={`productAgentCommissionDetailsDtos.${index}.defaultNbfcAmount`}
																			component={
																				FieldInput
																			}
																			header={
																				"Default NBFC Commission"
																			}
																			type={
																				"number"
																			}
																			star={
																				"*"
																			}
																		/>
																	</div>
																)}
																<div className="field col-12 md:col-2">
																	<Field
																		name={`productAgentCommissionDetailsDtos.${index}.maxAmount`}
																		component={
																			FieldInput
																		}
																		header={
																			"Max Commission"
																		}
																		type={
																			"number"
																		}
																		star={
																			"*"
																		}
																	/>
																</div>
																{/* <div className="field col-12 md:col-2">
																<Field
																	header={`Commission(${
																		values.type ===
																		"fixed"
																			? "₹"
																			: "%"
																	})`}
																	name={`productAgentCommissionDetailsDtos.${index}.percentage`}
																	component={
																		FieldInput
																	}
																	type={
																		"number"
																	}
																/> 
																</div>
																*/}
																{/* <div className="field col-12 md:col-2">
																	<Field
																		name={`productAgentCommissionDetailsDtos.${index}.agentCommissionStatus`}
																		component={
																			FeildDropdown
																		}
																		header={
																			"Status"
																		}
																		disabled={
																			props.type !==
																			"create"
																		}
																		options={[
																			{
																				label: "Active",
																				value: "ACTIVE",
																			},
																			{
																				label: "Inactive",
																				value: "INACTIVE",
																			},
																			{
																				label: "Reject",
																				value: "REJECT",
																			},
																		]}
																		star={
																			"*"
																		}
																	/>
																</div> */}
																{/* <div className="field col-12 md:col-2">
																	<Field
																		name={`productAgentCommissionDetailsDtos.${index}.remarks`}
																		component={
																			FieldInput
																		}
																		header={
																			"Remarks"
																		}
																		star={
																			"*"
																		}
																		disabled={
																			props.type !==
																			"create"
																		}
																	/>
																</div> */}
																{/* <Button
																	type="button"
																	onClick={() =>
																		arrayHelper.remove(
																			index
																		)
																	}
																	style={{
																		marginTop:
																			"1.5rem",
																	}}
																	icon="pi pi-trash"
																	className="p-button-outlined p-button-danger p-button-sm"
																	aria-label="Cancel"
																/> */}

																{/* <div className="col-1 pt-5">
															
														</div> */}
																<Divider />
															</div>
														)
													)
												// : null
											}
											{/* <Button
										type="button"
										label="Add Commission Slabs"
										icon="pi pi-plus"
										className=""
										onClick={() =>
											arrayHelper.push({
												minSellAmount: "",
												maxSellAmount: "",
												percentage: "",
											})
										}
									/> */}
											{/* <div
											className="add-more-btn flex align-items-center justify-content-center"
											onClick={() =>
												arrayHelper.push({
													minSellAmount: "",
													maxSellAmount: "",
													percentage: "",
												})
											}
										>
											<i className="pi pi-plus"></i>
											<span className="ml-2">
												Commission Slabs
											</span>
										</div> */}
										</>
									)}
								/>
							)}
							<div className="flex justify-content-end align-items-center w-full mt-4">
								<Button
									type="button"
									label="Reset"
									icon="pi pi-times"
									className="danger-btn mr-2"
									onClick={() => {
										resetForm();
									}}
									style={{
										borderRadius: "6px",
									}}
								/>
								<Button
									type="submit"
									label="Save"
									icon="pi pi-save"
									style={{ borderRadius: "6px" }}
									// loading={loading}
								/>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</div>
	);
};

export default ProductCommissionStructure;
