import { createSelector } from "@reduxjs/toolkit";
export default function selectState(val) {
	const data = createSelector(() => {
		const list = val.map((item) => ({ ...item, value: item.id }));
		return list;
	});
	return data();
}

export function selectDistrict(
	val = [{ id: "", districtList: [{ id: "" }] }],
	e
) {
	const data = val.find((item) => item.id === e);
	if (data !== undefined) {
		return data.districtList.map((elm) => ({
			...elm,
			value: elm.id,
		}));
	} else {
		return [];
	}
}

export const getState = (
	stateList = [{ id: "", name: "", districtList: [{ id: "" }] }],
	value
) =>
	createSelector(() => {
		const data = stateList.find((item) => item.id === value);
		if (data !== undefined) {
			return data;
		} else {
			return { id: "", name: "", districtList: [{ id: "" }] };
		}
	});

export const getDistrict = (
	districtList = [{ id: "", name: "", stateId: "" }],
	value
) =>
	createSelector(() => {
		const data = districtList.find((item) => item.id === value);
		if (data !== undefined) {
			return data;
		} else {
			return { id: "", name: "", stateId: "" };
		}
	});
