import React from "react";
import { TabPanel, TabView } from "primereact/tabview";
import Listview from "./components/Listview";
import Treeview from "./components/Treeview";
import { useSelector } from "react-redux";
const MyAgents = () => {
	const selector = useSelector((state) => state.persistData.auth.user);
	return (
		<div className="grid">
			<div className="col">
				<div className="w-full flex flex-wrap justify-content-between align-items-center">
					<h3 className="m-0">My Agents</h3>
				</div>
			</div>
			<div className="col-12">
				{selector.role.name && (
					<TabView className="table-tab" activeIndex={1}>
						{selector.role.position !== 5 &&
							selector.role.position !== 4 && (
								<TabPanel header="Tree List" className="p-0">
									<Treeview />
								</TabPanel>
							)}
						<TabPanel header="Agents List" className="p-0">
							<Listview />
						</TabPanel>
					</TabView>
				)}
			</div>
		</div>
	);
};

export default MyAgents;
