import axios from "axios";
import { agent_end_point_url, apiPath } from "../shared/constant";
import { useSelector } from "react-redux";
export class PaymentService {
	selector = useSelector((state) => state.persistData.auth);
	constructor() {
		this.token = sessionStorage.getItem("token");
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}
	getPaymentList = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/application/payment/search`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getBusinessDetailsList = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/agent-category-commission/business-detail`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getInvoiceList = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/agent-category-commission/Invoice-detail-list`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	paymentGenerateFinance = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/agent-category-commission/commission-pay`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	paymentApproved = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/application/payment-approve/${payload}`,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	financeAgentInvoice = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/agent-category-commission/agent-invoice`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getTranasctionListByAgent = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/agent-category-commission/Invoice-detail/${payload}`,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
}
