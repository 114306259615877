import { useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { FeildDropdown, FieldInput } from "../../../components/FieldInput";
import Config from "../../../shared/config";
import { Button } from "primereact/button";
import { VendorService } from "../../../services/vendorService";
import * as Yup from "yup";
import Loader from "../../../components/Loader";
import { Toast } from "primereact/toast";
import debounce from "lodash.debounce";
import { convertObjNull } from "../../../utils/UtilsFunction";
import {
	useGetCityListQuery,
	useGetStateListQuery,
} from "../../../services/query/queryApi";
const CreateHub = (props) => {
	const toastTL = useRef(null);
	const [loader, setLoader] = useState(false);
	const vendorService = new VendorService();
	const { data: cityList = [] } = useGetCityListQuery();
	const { data: StateList } = useGetStateListQuery();
	const initialValue = {
		name: "",
		locationType: "STATE",
		stateId: "",
		cityId: "",
	};
	const validationSchema = Yup.object().shape({
		name: Yup.string().required("This field is required"),
		locationType: Yup.string().required("This field is required"),
		stateId: Yup.number(),
		cityId: Yup.number(),
	});
	const handleFormSubmit = (value) => {
		if (props.dialogState?.type === "create") {
			setLoader(true);
			const data = convertObjNull(value);
			vendorService
				.createHub(data)
				.then(() => {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: "Hub created successfully",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.then(
					debounce(() => {
						props.setDialog({ dialog: false });
						props.userDataTableValue();
					}, 1000)
				)
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e?.message,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => setLoader(false));
		} else {
			setLoader(true);
			vendorService
				.updateVendor(value)
				.then(() => {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: "HUb Updated successfully",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.then(
					debounce(() => {
						props.setDialog({ dialog: false });
						props.userDataTableValue();
					}, 1000)
				)
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e?.message,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => setLoader(false));
		}
	};
	const handleLocation = (val, setFieldValue) => {
		setFieldValue("stateId", "", true);
		setFieldValue("cityId", "", true);
		setFieldValue("locationType", val, true);
	};
	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			{loader && <Loader />}
			<Formik
				initialValues={{
					...initialValue,
					...(props.dialogState?.type === "edit" &&
						props.dialogState?.data),
				}}
				enableReinitialize
				onSubmit={handleFormSubmit}
				validationSchema={validationSchema}
			>
				{({ handleSubmit, values, resetForm, setFieldValue }) => (
					<Form
						className="grid modal-form-grid mt-2"
						onSubmit={handleSubmit}
					>
						<div className="field col-12 md:col-4">
							<Field
								value={values.locationType}
								header={"Location Type"}
								name={"locationType"}
								component={FeildDropdown}
								onChange={(e) => {
									handleLocation(e.value, setFieldValue);
								}}
								options={Config.HUB_LOCATION_TYPE}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Name"}
								name={"name"}
								component={FieldInput}
							/>
						</div>

						{values?.locationType === "STATE" && (
							<div className="field col-12 md:col-4">
								<Field
									header={"State"}
									name={"stateId"}
									type={"number"}
									component={FeildDropdown}
									options={StateList}
									optionLabel={"name"}
									optionValue={"id"}
									filter
								/>
							</div>
						)}
						{values?.locationType === "CITY" && (
							<div className="field col-12 md:col-4">
								<Field
									filter
									header={"City"}
									name={"cityId"}
									type={"number"}
									component={FeildDropdown}
									options={cityList}
									optionLabel={"name"}
									optionValue={"id"}
								/>
							</div>
						)}
						<div className="col-12 flex gap-2">
							<Button
								icon={"pi pi-refresh"}
								label={"Reset"}
								className="danger-btn"
								type="button"
								onClick={() => resetForm()}
							/>
							<Button
								label={
									props.dialogState?.type === "create"
										? "Save"
										: "Update"
								}
								type="submit"
							/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default CreateHub;
