import { Field, FieldArray, Form, Formik } from "formik";
import { Button } from "primereact/button";

import * as Yup from "yup";
import { Divider } from "primereact/divider";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
} from "../../../../../components/FieldInput";
import { ProgramService } from "../../../../../services/ProgramService";
import moment from "moment";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import Loader from "../../../../../components/Loader";

const CommisionUpdate = (props) => {
	const programService = new ProgramService();
	const toast = useRef(null);
	const [spinner, setSpinner] = useState(false);
	const validationSchema = Yup.object().shape({
		commissionType: Yup.string().required("This field is required"),
		startDate: Yup.date().required("This field is required"),
		endDate: Yup.date()
			.when("startDate", (startDate, value) => {
				return value.test({
					test: (endDate) =>
						!!new Date(startDate) &&
						new Date(endDate) > new Date(startDate),
					message: "Invalid date",
				});
			})
			.required("This field is required"),
		programCommissionDetailsDtos: Yup.array().of(
			Yup.object().shape({
				minAmount: Yup.number()
					.min(0, "Invalid Amount")
					.required("This field is required"),
				maxAmount: Yup.number()
					// .min(0, "Invalid Amount")
					.when(["minAmount"], (min, value) => {
						return value.test({
							test: (max) =>
								(!!min && max > min) || !Boolean(max),
							message: "Max amount > Min amount",
						});
					})
					.nullable(),
				// amount: Yup.number()
				// 	.when(["minAmount", "maxAmount"], (min, max, value) => {
				// 		return value.test({
				// 			test: (def) => {
				// 				return (
				// 					!!min && !!max && max >= def && def >= min
				// 				);
				// 			},
				// 			message: "Max > Def > Min ",
				// 		});
				// 	})
				// 	.required("This field is required"),
				amount: Yup.number().required("This field is required"),
			})
		),
	});
	const handleSubmit = (val, action) => {
		const { startDate, endDate, ...rest } = val;

		if (props.type === "edit") {
			setSpinner(true);
			programService
				.updateCommission({
					...rest,
					startDate: moment(startDate).format("YYYY-MM-DD"),
					endDate: moment(endDate).format("YYYY-MM-DD"),
				})
				.then((res) => {
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: res,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => {
					setSpinner(false);
				});
		} else {
			setSpinner(true);
			programService
				.addCommission({
					...rest,
					startDate: moment(startDate).format("YYYY-MM-DD"),
					endDate: moment(endDate).format("YYYY-MM-DD"),
				})
				.then((res) => {
					props.getData();
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: res,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.catch((e) =>
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => {
					setSpinner(false);
					action.resetForm();
				});
		}
	};

	const initialValue = {
		programId: props.programId,
		commissionType: "percentage",
		startDate: "",
		endDate: "",
		programCommissionDetailsDtos: [
			{
				minAmount: "",
				maxAmount: "",
				amount: "",
			},
		],
	};
	return (
		<>
			{spinner && <Loader />}
			<Toast ref={toast} />
			<Formik
				initialValues={{
					...initialValue,
					...(props.item && {
						...props.item,
						startDate: props.item.startDate
							? new Date(props.item.startDate)
							: "",
						endDate: props.item.endDate
							? new Date(props.item.endDate)
							: "",
					}),
				}}
				enableReinitialize
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
			>
				{({ values, handleSubmit }) => (
					<Form
						className=" p-3 my-3 border-1 border-400 border-round-md"
						onSubmit={handleSubmit}
					>
						<div className="form-grid grid">
							<>
								<div className="field col-12 md:col-4">
									<Field
										name={`startDate`}
										component={FeildCalender}
										header={"Effective Start Date"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										name={`endDate`}
										component={FeildCalender}
										header={"Effective End Date"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										name={`commissionType`}
										component={FeildDropdown}
										header={"Commission Type"}
										options={[
											{
												label: "Fixed",
												value: "fixed",
											},
											{
												label: "Percentage",
												value: "percentage",
											},
										]}
									/>
								</div>
							</>
						</div>
						<Divider />
						<FieldArray
							name="programCommissionDetailsDtos"
							render={(arrayHelper) => (
								<>
									{values.programCommissionDetailsDtos &&
										values.programCommissionDetailsDtos
											.length >= 0 &&
										values.programCommissionDetailsDtos.map(
											(item, index) => (
												<div
													className="form-grid grid"
													key={
														index.toString() +
														"mapValueCommision"
													}
												>
													<div className="field col-12 md:col-4">
														<Field
															name={`programCommissionDetailsDtos.${index}.minAmount`}
															component={
																FieldInput
															}
															header={
																"Min Sell Amount"
															}
															type={"number"}
														/>
													</div>
													<div className="field col-12 md:col-3">
														<Field
															name={`programCommissionDetailsDtos.${index}.maxAmount`}
															component={
																FieldInput
															}
															header={
																"Max Sell Amount"
															}
															type={"number"}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															name={`programCommissionDetailsDtos.${index}.amount`}
															component={
																FieldInput
															}
															header={`Commission:(${
																values.commissionType ===
																"fixed"
																	? "Fixed Amount"
																	: "Percentage"
															}) `}
															type={"number"}
														/>
													</div>
													<div className="col-1 pt-5">
														<Button
															type="button"
															onClick={() =>
																arrayHelper.remove(
																	index
																)
															}
															icon="pi pi-minus"
															className="p-button-danger p-button-sm p-button-outlined md:col-5 "
															aria-label="Cancel"
														/>
													</div>
												</div>
											)
										)}
									<div className="col-12 px-0 ">
										<Button
											type="button"
											icon="pi pi-plus"
											className="p-button-text w-full bg-primary-100 border-dashed border-1 border-indigo-600"
											onClick={() =>
												arrayHelper.push({
													minAmount: "",
													maxAmount: "",
													amount: "",
													// ...(props.type ===
													// 	"edit" && {
													// 	type: values.type,
													// 	programId:
													// 		props
													// 			.initialValue
													// 			.programId,
													// 	effectiveEndDate:
													// 		values.effectiveEndDate,
													// 	startDate:
													// 		values.startDate,
													// }),
												})
											}
										/>
									</div>
								</>
							)}
						/>
						<div className=" flex align-item-center gap-2 justify-content-end">
							<Button
								type="reset"
								label="Reset"
								className="danger-btn"
								icon="pi pi-refresh"
							/>
							<Button
								type="submit"
								icon="pi pi-save"
								label={
									props.type === "edit" ? "Update" : "Create"
								}
								disabled={
									props.disable !== undefined
										? props.disable.disable.comm
										: false
								}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default CommisionUpdate;
