import { useState, useRef } from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { InstitutionService } from "../../../services/InstitutionService";
import { Toast } from "primereact/toast";
import Loader from "../../../components/Loader";
import Config from "../../../shared/config";

import { useGetStateListQuery } from "../../../services/query/queryApi";
import {
	FeildDropdown,
	FieldInput,
	FieldTextArea,
} from "../../../components/FieldInput";
import { Divider } from "primereact/divider";

const BasicDetails = (props) => {
	const toastTL = useRef(null);
	const institutionService = new InstitutionService();
	// const [institition, setInstitition] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const { data = [] } = useGetStateListQuery();
	const validationSchema = Yup.object().shape({
		dsaName: Yup.string().required("This field is required."),
		dsaCode: Yup.string().required("This field is required."),
		rainbowDsaCode: Yup.string().required("This field is required."),
		address: Yup.string().required("This field is required."),
		city: Yup.string().required("This field is required"),
		pincode: Yup.number().required("This field is required"),
		stateId: Yup.string().required("This field is required"),
		details: Yup.array().of(
			Yup.object().shape({
				bankType: Yup.string().required("Type is required."),
				bankId: Yup.string().required("Name is required."),
				addressLine1: Yup.string().required(
					"Address Line 1 is required"
				),
				stateId: Yup.string().required("State is required"),
				city: Yup.string().required("City is required"),
				pincode: Yup.number().required("Pincode is required"),
			})
		),
	});

	const handleChange = (e, setFieldValue, index) => {
		setFieldValue(`details.${index}.bankType`, e.value, true);
		institutionService
			.getInstitutionName(e.value)
			.then((res) => {
				setFieldValue(`details.${index}.allInstitute`, res, true);
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			);
	};
	const createInstitution = (value) => {
		setLoading(true);
		const { details, ...rest } = value;
		const newDetails = details?.map((item) => ({
			bankType: item.bankType,
			bankId: item.bankId,
			alias: item.alias,
			addressLine1: item.addressLine1,
			addressLine2: item.addressLine2,
			addressLine3: item.addressLine3,
			city: item.city,
			stateId: item.stateId,
			pincode: item.pincode,
		}));
		institutionService
			.addGeneralDSAInstitution({
				...rest,
				details: newDetails,
			})
			.then((res) => {
				props.handelStages();
				props.getId(res?.dsaId);
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoading(false));
	};

	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			{isLoading && <Loader />}
			<div className="card form-grid p-4 mt-3 generalbgbox border-round-md">
				<Formik
					initialValues={{
						dsaName: "",
						dsaCode: "",
						rainbowDsaCode: "",
						address: "",
						address2: "",
						city: "",
						pincode: "",
						stateId: "",
						details: [
							{
								bankType: "",
								bankId: "",
								alias: "",
								allInstitute: [],
								addressLine1: "",
								addressLine2: "",
								addressLine3: "",
								city: "",
								stateId: "",
								pincode: "",
							},
						],
					}}
					validationSchema={validationSchema}
					onSubmit={createInstitution}
					enableReinitialize
				>
					{({ handleSubmit, setFieldValue, resetForm, values }) => (
						<Form
							onSubmit={handleSubmit}
							className="form-grid grid cr-finInsFrm "
						>
							<Divider>
								<h4 className="m-0">DSA details</h4>
							</Divider>
							<div className="field col-12 md:col-3">
								<Field
									header={"Name"}
									component={FieldInput}
									name="dsaName"
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"DSA Code"}
									component={FieldInput}
									name="dsaCode"
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Rainbow DSA Code"}
									component={FieldInput}
									name="rainbowDsaCode"
									star={"*"}
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									header={"Address1"}
									component={FieldInput}
									name="address"
									star={"*"}
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									header={"Address2"}
									component={FieldInput}
									name="address2"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"City"}
									component={FieldInput}
									name="city"
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Pin"}
									component={FieldInput}
									name="pincode"
									star={"*"}
									type={"number"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									name={"stateId"}
									component={FeildDropdown}
									filter
									header={"State"}
									star={"*"}
									options={data}
									optionValue={"id"}
									optionLabel="name"
								/>
							</div>
							<Divider>
								<h4 className="m-0">
									Financial Institution details
								</h4>
							</Divider>
							<FieldArray name="details">
								{({ remove, push }) => (
									<>
										{values?.details?.map((item, index) => (
											<div
												className="col-12 cr-finalIns"
												key={index}
											>
												<div className="contact-grid-card grid form-grid">
													<Button
														type="button"
														className="floating-icon-btn p-button-text"
														onClick={() =>
															remove(index)
														}
														icon={
															"pi pi-times-circle"
														}
													/>
													<div className="field col-12 md:col-4">
														<Field
															name={`details.${index}.bankType`}
															component={
																FeildDropdown
															}
															filter
															options={
																Config.INSTITUTION_TYPE
															}
															optionLabel="name"
															header={"Type"}
															star={"*"}
															onChange={(e) =>
																handleChange(
																	e,
																	setFieldValue,
																	index
																)
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															name={`details.${index}.bankId`}
															component={
																FeildDropdown
															}
															filter
															header={"Name"}
															star={"*"}
															options={
																values.details[
																	index
																]?.allInstitute
															}
															optionValue={"id"}
															optionLabel="bankName"
														/>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={"Alias"}
															component={
																FieldInput
															}
															name={`details.${index}.alias`}
														/>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={
																"Address Line 1"
															}
															component={
																FieldTextArea
															}
															name={`details.${index}.addressLine1`}
															star={"*"}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Address Line 2"
															}
															component={
																FieldTextArea
															}
															name={`details.${index}.addressLine2`}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Address Line 3"
															}
															component={
																FieldTextArea
															}
															name={`details.${index}.addressLine3`}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															name={`details.${index}.stateId`}
															component={
																FeildDropdown
															}
															filter
															header={"State"}
															star={"*"}
															options={data}
															optionValue={"id"}
															optionLabel="name"
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"City"}
															component={
																FieldInput
															}
															name={`details.${index}.city`}
															star={"*"}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"Pincode"}
															component={
																FieldInput
															}
															type="number"
															name={`details.${index}.pincode`}
															star={"*"}
														/>
													</div>
												</div>
											</div>
										))}
										<button
											className="add-more-btn w-full mb-3"
											type="button"
											onClick={() =>
												push({
													bankType: "",
													bankId: "",
													rainbowDsaCode: "",
													alias: "",
													addressLine1: "",
													addressLine2: "",
													addressLine3: "",
													allInstitute: [],
													city: "",
													stateId: "",
													pincode: "",
												})
											}
										>
											<i className="pi pi-plus"></i>
										</button>
									</>
								)}
							</FieldArray>

							<div className="flex justify-content-end align-items-center w-full md:col-3 mt-2">
								<Button
									type="button"
									label="Reset"
									onClick={() => resetForm()}
									className="p-button-danger mr-2"
									icon="pi pi-times"
								/>
								<Button
									type="submit"
									label="Save & next"
									icon="pi pi-save"
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
};

export default BasicDetails;
