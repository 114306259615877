import { Fragment, useEffect, useReducer, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { S3 } from "aws-sdk";

import groupBy from "lodash/groupBy";
import { Toast } from "primereact/toast";
// import { Link } from "react-router-dom";
import FileSaver, { saveAs } from "file-saver";
import JSZip from "jszip";
import { Button } from "primereact/button";
import isEmpty from "lodash/isEmpty";
import { ProgressBar } from "primereact/progressbar";
import { ProductService } from "../../../../services/ProductService";
import Config from "../../../../shared/config";
import { LeadService } from "../../../../services/LeadService";
import { folder_path } from "../../../../shared/constant";
import map from "async/map";
const DocumentUpload = (props) => {
	const loanApplicationService = new LeadService();
	const productService = new ProductService();
	const leadService = new LeadService();
	const [loader, setLoader] = useState(false);
	const [spinner, setSpinner] = useState(false);
	const toast = useRef(null);
	const [documentPreview, setDocumentPreview] = useState([]);
	const [productDocumentState, productDocumentDispatch] = useReducer(
		(state, action) => {
			return { ...state, productList: action.payload };
		},
		{ productList: {} }
	);
	const [dropdownState, dropdownDocumentDispatch] = useReducer(
		(a, b) => {
			return { ...a, value: b };
		},
		{
			value: { id: "", documentTypeId: { id: "", type: "" } },
		}
	);

	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});
	//get  doucment preview list
	const getDocumentList = () => {
		// selector.name === "applicationList" &&
		props.leadId &&
			loanApplicationService
				.getLeadDocument(props.leadId)
				.then((e) => setDocumentPreview(e))
				.catch((e) => console.log(e));
	};

	const onUploadImage = (e) => {
		//setLoading(true);
		setLoader(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket:
				Config.LEAD_ATTACHMENT_BUCKET +
				"/" +
				folder_path +
				"/" +
				props.leadId,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err) => {
			if (err || !dropdownState.value.id) {
				//setLoading(false);
				setLoader(false);
				return toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Select Document Types",
					style: { color: "#000000" },
					life: 3000,
				});
			}

			//setLoading(false);

			const data = {
				leadId: props.leadId,
				documentFileName: newFileName,
				documentTypeId: dropdownState.value.id,
			};

			loanApplicationService
				.documentUpload(data)
				.then(() => {
					toast.current.show({
						severity: "success",
						summary: "Successfull",
						detail: "Document uploaded",
						style: { color: "#000000" },
						life: 3000,
					});
					e.options.clear();
				})
				.catch((e) => {
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => {
					setLoader(false);
					getDocumentList();
				});
		});
	};
	const deleteFile = (fileId) => {
		setSpinner(true);
		const params = {
			Bucket:
				Config.LEAD_ATTACHMENT_BUCKET +
				"/" +
				folder_path +
				"/" +
				props.leadId,
			Key: fileId?.documentFileName,
		};
		s3Clint.deleteObject(params, (err, data) => {
			if (err) {
				// setLoading(false);
				setSpinner(false);
				return toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Failed to delete",
					style: { color: "#000000" },
					life: 3000,
				});
			}
			leadService
				.leadDocsDelete(fileId?.id)
				.then((res) =>
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: "Deleted successfully",
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.then(() => getDocumentList())
				.catch((er) => {
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: "Failed to delete",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => {
					setSpinner(false);
				});
		});
	};

	const handleBack = () => {
		props.setStages({
			type: "stage",
			payload: {
				sequence: 1,
				status: "active",
			},
		});
		props.setStages({
			type: "stage",
			payload: {
				sequence: 2,
				status: "success",
			},
		});
		props.setStages({ type: "active", active: 1 });
	};
	//get required document
	const getDocumentType = () => {
		productService
			.getProductDocument()
			.then((res) => {
				const data = groupBy(res, "type");
				productDocumentDispatch({ payload: data });
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			);
	};

	const dataTypeProof = groupBy(documentPreview, "documentTypeId.type");

	useEffect(() => {
		Promise.allSettled([getDocumentType(), getDocumentList()]);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.leadId]);

	function getFileExtension(filename) {
		const extension = filename.split(".").pop();
		return extension;
	}

	const download = (e) => {
		FileSaver.saveAs(
			`${Config.AGENT_BUCKET_ACCESS_URL}/lead/${folder_path}/${props.leadId}/${e}`,
			e
		);
	};
	const progressBar = () => {
		return (
			loader && (
				<ProgressBar mode="indeterminate" style={{ height: "4px" }} />
			)
		);
	};

	const generateZipFromCloud = async () => {
		var zip = new JSZip();
		var img = zip.folder("images");
		const imageData = documentPreview.map(
			(item) =>
				`${Config.AGENT_BUCKET_ACCESS_URL}/lead/${folder_path}/${props.leadId}/${item.documentFileName}`
		);
		let filename = `File_${props.leadId}`;
		map(imageData, async function (file, cb) {
			const imageBlob = await fetch(file).then((response) =>
				response.blob()
			);
			const imgData = new File([imageBlob], "filename.jpg");
			img.file(`${file.substring(file.lastIndexOf("/"))} `, imgData, {
				base64: true,
			});
		}).then((res) => {
			zip.generateAsync({ type: "blob" }).then(function (content) {
				saveAs(content, filename);
			});
		});
	};
	// console.log(Object.entries(productDocumentState.productList));
	// console.log(Object.entries(dataTypeProof));
	return (
		<>
			<Toast ref={toast} position="top-left" />
			<div className="grid pt-5">
				{Object.entries(productDocumentState.productList).map(
					(item, index) => {
						const obj =
							!isEmpty(dataTypeProof) &&
							Object.entries(dataTypeProof).find(
								(elm) => elm[0] === item[0]
							);
						return (
							<div key={`${index}`} className="md:col-6 ">
								<div className="border-1 border-300 border-round-md p-3">
									<h4 className="col-12 capitalize">
										{item[0]
											.replace(/_/g, " ")
											.toLocaleLowerCase()}
									</h4>

									{props.type !== "preview" && (
										<>
											<Dropdown
												className="border-round-md w-full mb-3"
												value={dropdownState.value}
												onChange={(e) =>
													dropdownDocumentDispatch(
														e.value
													)
												}
												placeholder="Select one"
												optionLabel="name"
												options={item[1]}
											/>

											{dropdownState.value.type ===
												item[0] && (
												<FileUpload
													progressBarTemplate={
														progressBar
													}
													customUpload
													accept=".jpg,.png,.jpeg, .pdf"
													uploadHandler={(e) =>
														onUploadImage(e)
													}
													maxFileSize={1000000}
													emptyTemplate={
														<p className="m-0">
															Drag and drop files
															to here to upload.
														</p>
													}
												/>
											)}
										</>
									)}

									{obj && (
										<>
											{spinner && (
												<ProgressBar
													mode="indeterminate"
													style={{ height: "4px" }}
												/>
											)}
											{obj[1].map((it, index) => (
												<Fragment key={it.id}>
													<div className="list-box-grid c-list-box-w">
														<div className="w-full flex justify-content-between align-iteam-center list-box-container">
															<a
																href={`${Config.AGENT_BUCKET_ACCESS_URL}/lead/${folder_path}/${props.leadId}/${it.documentFileName}`}
																target="_blank"
																className="flex align-items-center justify-content-start"
																rel="noreferrer"
															>
																{getFileExtension(
																	it.documentFileName
																) === "pdf" ? (
																	<i
																		className={`text-2xl pi pi-file-pdf text-red-400`}
																	/>
																) : (
																	<span className="c-list-box-it">
																		<img
																			width={
																				50
																			}
																			src={`${Config.AGENT_BUCKET_ACCESS_URL}/lead/${folder_path}/${props.leadId}/${it.documentFileName}`}
																			alt=""
																		/>
																	</span>
																)}

																<div className="pl-4">
																	<h5 className="m-0">
																		{
																			it.documentFileName
																		}
																	</h5>
																	<small
																		style={{
																			textTransform:
																				"uppercase",
																		}}
																	>
																		{
																			it
																				.documentTypeId
																				.name
																		}
																	</small>
																</div>
															</a>

															<Button
																icon={
																	"pi pi-download"
																}
																onClick={() =>
																	download(
																		it.documentFileName
																	)
																}
																className="p-button-rounded"
															/>
															<Button
																icon={
																	"pi pi-trash"
																}
																onClick={() =>
																	deleteFile(
																		it
																	)
																}
																className="p-button-rounded"
															/>
														</div>
													</div>
												</Fragment>
											))}
										</>
									)}
								</div>
							</div>
						);
					}
				)}
				<div className="col-12">
					<Button
						onClick={generateZipFromCloud}
						disabled={isEmpty(dataTypeProof)}
						label="All Download"
					/>
					{!props.stages.leadId && (
						<Button
							onClick={handleBack}
							className="ml-2 danger-btn"
							label="Back"
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default DocumentUpload;
