import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import Config from "../../../../../shared/config";
// import * as Yup from "yup";
// import { ProgramService } from "../../../../../services/ProgramService";
// import { S3 } from "aws-sdk";
// import moment from "moment";

import { InputNode } from "../../../../../components/InputComponent";

import { FileUpload } from "primereact/fileupload";
import {
	FeildCalender,
	FeildDropdown,
	FieldEditor,
	FieldInput,
} from "../../../../../components/FieldInput";
import { Divider } from "primereact/divider";
import { useState } from "react";

const OfferUpdate = (props) => {
	const [open, setOpen] = useState(false);
	// const validationSchema = Yup.object().shape({
	// 	name: Yup.string().required("This Field is required"),
	// 	startDate: Yup.date().required("This Field is required"),
	// 	endDate: Yup.date()
	// 		.when("startDate", (startDate, value) => {
	// 			return value.test({
	// 				test: (endDate) =>
	// 					!!startDate && new Date(endDate) > new Date(startDate),
	// 				message: "Invalid date",
	// 			});
	// 		})
	// 		.required("This Field is required"),
	// 	details: Yup.string().required("This Field is required"),
	// 	rateOfInterest: Yup.number()
	// 		.typeError("Enter a valid number")
	// 		.min(0, "Invalid number")
	// 		.required("This Field is required"),
	// });

	const initialValue = {
		programId: props.programId,
		name: "",
		startDate: "",
		endDate: "",
		details: "",
		image: "",
		joiningFee: false,
		annualFee: "",
		preClosureCharge: false, //NPA
		processingFees: false, //isProcessingFeess
		rateOfInterest: "", //rateOfInterest
	};

	return (
		<>
			<div className="w-full flex align-items-center justify-content-end">
				<Button
					icon={open ? "pi pi-times" : "pi pi-plus"}
					label={open ? "" : "Add"}
					className={open ? "p-button-danger p-button-text" : ""}
					onClick={() => setOpen((p) => !p)}
				/>
			</div>
			{open && (
				<Formik
					initialValues={initialValue}
					onSubmit={props.handleFormSubmit}
					enableReinitialize
					// validationSchema={validationSchema}
				>
					{({ handleSubmit, setFieldValue, values }) => (
						<Form
							className="form-grid grid"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 md:col-6">
								<Field
									component={FieldInput}
									name="name"
									header={"Offer Name"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									component={FeildCalender}
									name="startDate"
									header={"Start Date"}
									star={"*"}
								/>
							</div>
							<div className="field col-12  md:col-3">
								<Field
									component={FeildCalender}
									name="endDate"
									header={"End Date"}
									star={"*"}
								/>
							</div>

							<div className="field col-12 md:col-6">
								<Field
									component={FieldEditor}
									name="details"
									header={"Offer Details"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-6">
								<InputNode header={"Upload Image "}>
									<FileUpload
										name="image"
										customUpload
										accept=".png,.jpeg,.jpg"
										uploadHandler={(e) =>
											props.onUploadImage(
												e,
												setFieldValue
											)
										}
										maxFileSize={1000000}
										emptyTemplate={
											<p className="m-0">
												Drag and drop files to here to
												upload.
											</p>
										}
									/>
								</InputNode>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									component={FeildDropdown}
									name={"processingFees"}
									options={Config.YES_NO}
									header={"Processing Fee"}
									star="*"
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									type="number"
									component={FieldInput}
									name={"rateOfInterest"}
									header={"Interest Rate"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									component={FeildDropdown}
									// name={"preClosure"}
									name={"preClosureCharge"}
									options={Config.YES_NO}
									header={"Pre-closure"}
									star="*"
								/>
							</div>

							{props.alias !== undefined &&
								props.alias === "CC" && (
									<>
										<div className="field col-12 md:col-3">
											<Field
												component={FeildDropdown}
												name={"annualFee"}
												options={Config.YES_NO}
												header={"Annual Fee"}
												star="*"
											/>
										</div>

										<div className="field col-12 md:col-3">
											<Field
												component={FeildDropdown}
												name={"joiningFee"}
												options={Config.YES_NO}
												header={"Joining Fee"}
												star="*"
											/>
										</div>
									</>
								)}

							<div className="col-12 flex align-item-center gap-2 justify-content-end">
								<Button
									type={"reset"}
									className="p-button-danger"
									icon="pi pi-times"
									label={"Reset"}
								/>
								<Button
									type="submit"
									icon="pi pi-save"
									label={"Create"}
									disabled={props.programId ? false : true}
								/>
							</div>
						</Form>
					)}
				</Formik>
			)}
			<Divider align="left">Offer List</Divider>
			{props.initialValue &&
				props.initialValue.map((item) => (
					<Formik
						key={item.id}
						initialValues={{
							...initialValue,
							...(item && {
								...item,
								startDate:
									item.startDate === ""
										? ""
										: new Date(item.startDate),
								endDate:
									item.endDate === ""
										? ""
										: new Date(item.endDate),
							}),
						}}
						onSubmit={props.handleFormSubmit}
						enableReinitialize
						// validationSchema={validationSchema}
					>
						{({ handleSubmit, setFieldValue, values }) => (
							<Form
								className="form-grid mb-2 grid"
								onSubmit={handleSubmit}
							>
								<div className="field col-12 md:col-6">
									<Field
										component={FieldInput}
										name="name"
										header={"Offer Name"}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										component={FeildCalender}
										name="startDate"
										header={"Start Date"}
										star={"*"}
									/>
								</div>
								<div className="field col-12  md:col-3">
									<Field
										component={FeildCalender}
										name="endDate"
										header={"End Date"}
										star={"*"}
									/>
								</div>

								<div className="field col-12 md:col-6">
									<Field
										component={FieldEditor}
										name="details"
										header={"Offer Details"}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-6">
									<InputNode header={"Upload Image "}>
										<FileUpload
											name="image"
											customUpload
											accept=".png,.jpeg,.jpg"
											uploadHandler={(e) =>
												props.onUploadImage(
													e,
													setFieldValue
												)
											}
											maxFileSize={1000000}
											emptyTemplate={
												<p className="m-0">
													Drag and drop files to here
													to upload.
												</p>
											}
										/>
									</InputNode>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										component={FeildDropdown}
										name={"processingFees"}
										options={Config.YES_NO}
										header={"Processing Fee"}
										star="*"
									/>
								</div>

								<div className="field col-12 md:col-3">
									<Field
										type="number"
										component={FieldInput}
										name={"rateOfInterest"}
										header={"Interest Rate"}
										star="*"
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										component={FeildDropdown}
										// name={"preClosure"}
										name={"preClosureCharge"}
										options={Config.YES_NO}
										header={"Pre-closure"}
										star="*"
									/>
								</div>

								{props.alias !== undefined &&
									props.alias === "CC" && (
										<>
											<div className="field col-12 md:col-3">
												<Field
													component={FeildDropdown}
													name={"annualFee"}
													options={Config.YES_NO}
													header={"Annual Fee"}
													star="*"
												/>
											</div>

											<div className="field col-12 md:col-3">
												<Field
													component={FeildDropdown}
													name={"joiningFee"}
													options={Config.YES_NO}
													header={"Joining Fee"}
													star="*"
												/>
											</div>
										</>
									)}

								<div className="col-12 flex align-item-center gap-2 justify-content-end">
									<Button
										type={"reset"}
										className="danger-btn"
										icon="pi pi-refresh"
										label={"Reset"}
									/>
									<Button
										type="submit"
										icon="pi pi-save"
										label={"Create"}
										disabled={
											props.programId ? false : true
										}
									/>
								</div>
							</Form>
						)}
					</Formik>
				))}
		</>
	);
};

export default OfferUpdate;
