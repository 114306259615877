import { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import General from "./components/General";
import Offers from "./components/Offers";
// import Eligibility from "./components/Eligibility";
// import CreditBureau from "./components/CreditBureau";
import CommisionStructure from "./components/CommisionStructure";
import { useSelector } from "react-redux";

const ProgramDialog = (props) => {
	const [activeIndex, setActiveIndex] = useState(0);

	const changeIndex = (value) => {
		setActiveIndex(value);
	};

	const tabIndex = useSelector((state) => state.program.tabIndex);
	return (
		<>
			<TabView
				activeIndex={activeIndex}
				onTabChange={(e) => setActiveIndex(e.index)}
			>
				<TabPanel header="General Info" disabled={tabIndex[0].disable}>
					<General
						changeIndex={changeIndex}
						dialog={props.dialog}
						getAllProgramList={props.getAllProgramList}
					/>
				</TabPanel>
				{/* <TabPanel
					header="Credit Bureau"
					// disabled={state.tabIndex[1].disable}
				>
					<CreditBureau changeIndex={changeIndex} />
				</TabPanel> */}
				<TabPanel
					header="Commission Structure"
					disabled={tabIndex[1].disable}
				>
					<CommisionStructure
						changeIndex={changeIndex}
						dialog={props.dialog}
					/>
				</TabPanel>
				{/* <TabPanel
					header="Eligiblity Criteria"
					// disabled={state.tabIndex[3].disable}
				>
					<Eligibility changeIndex={changeIndex} />
				</TabPanel> */}

				<TabPanel header="Offers" disabled={tabIndex[2].disable}>
					<Offers
						dialog={props.dialog}
						changeIndex={changeIndex}
						getAllProgramList={props.getAllProgramList}
					/>
				</TabPanel>
			</TabView>
		</>
	);
};

export default ProgramDialog;
