import { Field, Form, Formik } from "formik";
import { useEffect, useReducer, useRef, useState } from "react";
import {
	FeildDropdown,
	FieldInput,
	FieldMultiselect,
} from "../../../components/FieldInput";
import { Button } from "primereact/button";
import Config from "../../../shared/config";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import { UsersService } from "../../../services/UsersService";
import { convertNullToString } from "../../../utils/UtilsFunction";
import { Skeleton } from "primereact/skeleton";

import { useSelector } from "react-redux";
const EditUser = ({ editData, editFormData, roles, setUserEditDialog }) => {
	const userService = new UsersService();

	const selector = useSelector((state) => state.persistData.auth.user.role);
	const toastTL = useRef(null);
	const [userAreaList, setUserAreaList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [userState, userDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);

	const initialValue = {
		rolePosition: "",
		areaList: [],
		...editData,
		role: { ...roles.find((item) => item.id === editData.roleId) },
		user:
			userState.data.roleUserList &&
			userState.data.roleUserList.find(
				(item) => item.userName === editData.parentUserName
			),
	};

	useEffect(() => {
		userService.getUsersAreaList(editData.parentUserName).then((res) => {
			setUserAreaList(res);
		});
	}, []);

	const handleFormSubmit = (value, acton) => {
		setLoading(true);
		const reqBody = {
			...value,
			areaList: value.areaList.map((e) => e.id),
			parentId: value.parentUserName,
		};

		setLoading(true);
		editFormData(reqBody);
	};

	const validationSchema = Yup.object().shape({
		firstName: Yup.string()
			.required("First name cannot be empty.")
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid first name"
			),
		lastName: Yup.string()
			.required("Last name cannot be empty.")
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid Last name"
			),
		middleName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid middle name"
			)

			.nullable(),
		emailId: Yup.string()
			.required("Email id cannot be empty.")
			.email("Invalid email id."),
		mobileNumber: Yup.string()
			.min(10, "Mobile No. should be 10 digit")
			.max(10, "Mobile No. should not be more than 10 digit")
			.matches(/^[0]?[0-9]\d{9}$/, "Invalid Mobile No.")
			.required("Mobile no cannot be empty."),
		// areaList: Yup.array().of(Yup.object()).min(1, "Field cannot be empty"),
	});

	const getAreaListByRole = () => {
		let position = editData.rolePosition - 1;
		if (position === 0 && position !== 49 && position !== 99) {
			userDispatch({ type: Config.FETCH_CONFIG.start });
			userService
				.getZones()
				.then((res) => {
					userDispatch({
						type: Config.FETCH_CONFIG.success,
						payload: res,
					});
				})
				.finally(() =>
					userDispatch({ type: Config.FETCH_CONFIG.stop })
				);
		} else {
			userDispatch({ type: Config.FETCH_CONFIG.start });
			userService
				.getUserByRolePosition(position)
				.then((res) => {
					let users = res.roleUserList.map((item) => {
						const strObj = convertNullToString(item);
						return {
							...strObj,
							name: `${strObj.firstName} ${strObj.middleName} ${strObj.lastName}`,
						};
					});

					userDispatch({
						type: Config.FETCH_CONFIG.success,
						payload: { ...res, roleUserList: users },
					});
				})
				.catch((e) =>
					userDispatch({
						type: Config.FETCH_CONFIG.error,
						payload: e,
					})
				)
				.finally(() =>
					userDispatch({ type: Config.FETCH_CONFIG.stop })
				);
		}
	};
	useEffect(() => {
		getAreaListByRole();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onRoleChanges = (e, setFieldValue) => {
		setFieldValue("role", e.value, true);
		let position = e.value.position - 1;
		if (position === 0) {
			userDispatch({ type: Config.FETCH_CONFIG.start });
			userService
				.getZones()
				.then((res) => {
					const data = res.map((e) => ({ ...e, value: e.id }));
					userDispatch({
						type: Config.FETCH_CONFIG.success,
						payload: data,
					});
				})
				.finally(() =>
					userDispatch({ type: Config.FETCH_CONFIG.stop })
				);
		} else {
			userDispatch({ type: Config.FETCH_CONFIG.start });
			userService
				.getUserByRolePosition(position)
				.then((res) => {
					let users = res.roleUserList.map((item) => {
						const strObj = convertNullToString(item);
						return {
							...strObj,
							name: `${strObj.firstName} ${strObj.middleName} ${strObj.lastName}`,
						};
					});

					userDispatch({
						type: Config.FETCH_CONFIG.success,
						payload: { ...res, roleUserList: users },
					});
				})
				.catch((e) =>
					userDispatch({
						type: Config.FETCH_CONFIG.error,
						payload: e,
					})
				)
				.finally(() =>
					userDispatch({ type: Config.FETCH_CONFIG.stop })
				);
		}
	};
	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			<div className="col-12 p-0">
				<Formik
					onSubmit={handleFormSubmit}
					initialValues={initialValue}
					validationSchema={validationSchema}
					enableReinitialize
				>
					{({ values, handleSubmit, setFieldValue }) => (
						<Form className="grid" onSubmit={handleSubmit}>
							<>
								<div className="field col-12 md:col-4">
									<Field
										header={"UserName"}
										name={"userName"}
										component={FieldInput}
										disabled
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Email"}
										name={"emailId"}
										component={FieldInput}
										type={"email"}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Employee ID"}
										name={"employeeId"}
										component={FieldInput}
										disabled
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"First Name"}
										name={"firstName"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Middle Name"}
										name={"middleName"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Last Name"}
										name={"lastName"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Gender"}
										name={"gender"}
										filter
										component={FeildDropdown}
										options={Config.GENDERS}
										optionLabel={"name"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Mobile Number"}
										name={"mobileNumber"}
										component={FieldInput}
									/>
								</div>
								{/* {selector?.position === null && ( */}
								<>
									<div className="field col-12 md:col-6">
										<Field
											header={"Select Role"}
											name={"role"}
											component={FeildDropdown}
											filter
											star={"*"}
											onChange={(e) =>
												onRoleChanges(e, setFieldValue)
											}
											options={roles}
											disabled
											optionLabel="name"
										/>
									</div>

									{editData &&
									editData?.rolePosition === 1 ? (
										<>
											{userState.isLoading ? (
												<div className="field flex align-items-end justify-conetent-end flex-column col-12 md:col-6">
													<Skeleton className="w-full flex-1 mt-4 " />
												</div>
											) : (
												<div className="field col-12 md:col-6">
													<Field
														header={`Select Zone`}
														name={"areaList"}
														component={
															FieldMultiselect
														}
														filter
														star={"*"}
														options={userState.data}
														optionLabel="name"
													/>
												</div>
											)}
										</>
									) : (
										values.role?.position !== 50 &&
										values.role?.position !== 100 &&
										values.role?.position !== 5000 && (
											<>
												{userState.isLoading ? (
													<div className="field flex align-items-end justify-conetent-end flex-column col-12 md:col-6">
														<Skeleton className="w-full flex-1 mt-4 " />
													</div>
												) : (
													<div className="field col-12 md:col-6">
														<Field
															header={`Select ${userState.data.role}`}
															name={"user"}
															component={
																FeildDropdown
															}
															filter
															star={"*"}
															options={
																userState.data
																	?.roleUserList
															}
															optionLabel="name"
															disabled
														/>
													</div>
												)}

												{userAreaList && (
													// userAreaList.length > 0 &&
													<div className="field col-12 md:col-6">
														<Field
															header={`Select Area`}
															name={"areaList"}
															component={
																FieldMultiselect
															}
															filter
															star={"*"}
															options={
																userAreaList
															}
															optionLabel="name"
															display="comma"
															optionDisabled={(
																item
															) =>
																editData.areaList &&
																editData.areaList?.some(
																	(elm) =>
																		elm.id ===
																		item.id
																)
															}
														/>
													</div>
												)}
											</>
										)
									)}
								</>
								{/* )} */}
								<div className="col-12 pb-0">
									<div className="flex justify-content-end align-items-center w-full">
										<Button
											type="button"
											label="Cancel"
											className="danger-btn mr-2"
											icon="pi pi-times"
											onClick={() =>
												setUserEditDialog(false)
											}
										/>
										<Button
											type="submit"
											label="Update"
											icon="pi pi-save"
											loading={loading}
										/>
									</div>
								</div>
							</>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
};

export default EditUser;
