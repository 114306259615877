import { Field, Form, Formik } from "formik";
import { FeildDropdown, FieldInput } from "../../../../components/FieldInput";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { useEffect, useRef, useState } from "react";
import { InstitutionService } from "../../../../services/InstitutionService";
import Loader from "../../../../components/Loader";
import { Toast } from "primereact/toast";
import ContactDetails from "./ContactDetails";
import ContractDetails from "./ContractDetails";
import { useGetStateListQuery } from "../../../../services/query/queryApi";

const DsaUpdate = (props) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const toastTL = useRef(null);
	const institutionService = new InstitutionService();
	const { data = [] } = useGetStateListQuery();
	const [loader, setLoader] = useState(false);
	const [contactData, setContactData] = useState(null);
	const initialValue = {
		id: "",
		dsaName: "",
		dsaCode: "",
		rainbowDsaCode: "",
		address: "",
	};
	const getDataById = (id) => {
		institutionService
			.getDSAInstitutionContactById(id)
			.then((res) => setContactData(res));
	};
	const handleDsaFormSubmit = (val) => {
		setLoader(true);
		institutionService
			.updateDSAInstitutionBasicDetails(val)
			.then((e) => {
				toastTL.current.show({
					severity: "success",
					summary: e?.name,
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				});
				props.getInstitution();
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: e?.name,
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoader(false));
	};

	useEffect(() => {
		props?.data?.id && getDataById(props?.data?.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.data]);
	const handleContactFormSubmit = (value) => {
		setLoader(true);
		institutionService
			.updateDSAInstitutionContact({ ...value, dsaId: props?.data?.id })
			.then((res) => {
				props.getInstitution();
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: "Contact Updated",
					life: 3000,
				});
				props?.data?.id && getDataById(props?.data?.id);
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			})
			.finally(() => setLoader(false));
	};
	const handleContract = (value, action) => {
		if (value?.id) {
			setLoader(true);
			institutionService
				.updateDSAInstitutionContract({
					...value,
					dsaId: props?.data?.id,
				})
				.then((res) => {
					props.getInstitution();
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: "Contact Updated",
						life: 3000,
					});
					action.resetForm();
					props?.data?.id && getDataById(props?.data?.id);
				})
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						life: 3000,
					});
				})
				.finally(() => setLoader(false));
		} else {
			setLoader(true);
			institutionService
				.addDSAInstitutionContract({ ...value, dsaId: props?.data?.id })
				.then((res) => {
					props.getInstitution();
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: "Contact Updated",
						life: 3000,
					});
					props?.data?.id && getDataById(props?.data?.id);
					action.resetForm();
				})
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						life: 3000,
					});
				})
				.finally(() => setLoader(false));
		}
	};
	return (
		<>
			{loader && <Loader />}
			<Toast ref={toastTL} />
			<TabView
				activeIndex={activeIndex}
				onTabChange={(e) => setActiveIndex(e.index)}
			>
				<TabPanel header="Basic Details">
					<Formik
						initialValues={{ ...initialValue, ...props?.data }}
						onSubmit={handleDsaFormSubmit}
						enableReinitialize
					>
						{({ handleSubmit, resetForm }) => (
							<Form
								className="grid form-grid"
								onSubmit={handleSubmit}
							>
								<div className="field col-12 md:col-6">
									<Field
										header={"DSA Name"}
										name={"dsaName"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-6">
									<Field
										header={"DSA Code"}
										name={"dsaCode"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-6">
									<Field
										header={"Rainbow DSA Code"}
										name={"rainbowDsaCode"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-6">
									<Field
										header={"Address"}
										name={"address"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-6">
									<Field
										header={"Address2"}
										component={FieldInput}
										name="address2"
									/>
								</div>
								<div className="field col-12 md:col-6">
									<Field
										header={"City"}
										component={FieldInput}
										name="city"
									/>
								</div>
								<div className="field col-12 md:col-6">
									<Field
										header={"Pin"}
										component={FieldInput}
										name="pincode"
										type={"number"}
									/>
								</div>
								<div className="field col-12 md:col-6">
									<Field
										name={"stateId"}
										component={FeildDropdown}
										filter
										header={"State"}
										options={data}
										optionValue={"id"}
										optionLabel="name"
									/>
								</div>
								<div className="flex justify-content-end gap-2 align-items-center col-12">
									<Button
										type="button"
										label="Reset"
										icon="pi pi-times"
										className="danger-btn"
										onClick={() => resetForm()}
									/>
									<Button
										type="submit"
										icon="pi pi-save"
										label="Update"
									/>
								</div>
							</Form>
						)}
					</Formik>
				</TabPanel>
				<TabPanel header="Contacts">
					<ContactDetails
						data={contactData?.dsaContact}
						handleFormSubmit={handleContactFormSubmit}
					/>
				</TabPanel>
				<TabPanel header="Contracts">
					<ContractDetails
						data={contactData?.dsaContract}
						handleContract={handleContract}
					/>
				</TabPanel>
			</TabView>
		</>
	);
};

export default DsaUpdate;
