import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

import { Button } from "primereact/button";
import { useLocation, useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import Loader from "../../../../components/Loader";
import { ProductService } from "../../../../services/ProductService";
import { Dropdown } from "primereact/dropdown";
const SubproductQuestions = () => {
	const { productId } = useParams();
	const [inputTypeList, setInputTypeList] = useState([
		{ label: "dropdown", value: "dropdown" },
		{ label: "calender", value: "calender" },
	]);
	const [keyBoardtTypeList, setKeyTypeList] = useState([
		{ label: "numeric", value: "numeric" },
		{
			label: "sentences",
			value: "sentences",
		},
	]);
	const locationData = useLocation();
	const productSErvice = new ProductService();
	const toast = useRef(null);
	const [dialog, setDialog] = useState(false);
	const [spinner, setSpinner] = useState(false);
	const [questionData, setQuestionData] = useState({
		questionDtos: [],
	});
	const validationSchema = Yup.object().shape({
		question: Yup.string().required("This field is required"),
		parameter: Yup.string().required("This field is required"),
		stepNumber: Yup.number().required("This field is required"), //num
		placeHolder: Yup.string().required("This field is required"),
		inputType: Yup.string().when("parameter", {
			is: (val) => {
				switch (val) {
					case "profession":
						return true;
					case "gender":
						return true;
					case "dob":
						return true;
					default:
						return false;
				}
			},
			then: Yup.string().required("This field is required"),
		}),
		keyboardType: Yup.string().when("parameter", {
			is: (val) => {
				switch (val) {
					case "profession":
						return false;
					case "gender":
						return false;
					case "dob":
						return false;
					default:
						return true;
				}
			},
			then: Yup.string().required("This field is required"),
		}),
	});

	const handleFormSubmit = (value) => {
		// if (value?.id) {
		setSpinner(true);
		const reqBody = {
			...value,
			subProductId: productId,
		};
		productSErvice
			.createQuestion(reqBody)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "Created Successfully",
					style: { color: "#000000" },
					life: 3000,
				});
				formik.resetForm();
				setDialog(false);
				getQuestionList(productId);
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setSpinner(false));
		// 	ratingService
		// 		.updateQuestiont(value)

		// } else {
		// 	setSpinner(true);
		// 	ratingService
		// 		.saveQuestiont(value)
		// 		.then((res) => {
		// 			setDialog((prev) => ({ ...prev, data: res }));
		// 			formik.resetForm();
		// 		})
		// 		.catch((e) =>
		// 			toast.current.show({
		// 				severity: "error",
		// 				summary: "Error",
		// 				detail: e?.message,
		// 				style: { color: "#000000" },
		// 				life: 3000,
		// 			})
		// 		)
		// 		.finally(() => setSpinner(false));
		// }
	};
	const formik = useFormik({
		initialValues: {
			question: "",
			parameter: "",
			stepNumber: "", //num
			keyboardType: "",
			placeHolder: "",
			inputType: "",
		},
		onSubmit: handleFormSubmit,
		validationSchema: validationSchema,
	});
	const getQuestionList = (id) => {
		setSpinner(true);
		productSErvice
			.getAllQuestionBySubProduct(id)
			.then((res) => setQuestionData(res))
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setSpinner(false));
	};

	const onDelete = (id) => {
		setSpinner(true);
		productSErvice
			.deleteQuestion(id)
			.then((res) => {
				getQuestionList(productId);
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "Deleted Successfully",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setSpinner(false));
	};
	useEffect(() => {
		getQuestionList(productId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productId]);

	const actionTemplate = (item) => {
		return (
			<div className="flex gap-2">
				{/* <Button
					className="icon-btn p-button-text"
					icon={"pi pi-pencil"}
					onClick={() => {
						setDialog(true);
						formik.setValues(
							{
								question: item?.question,
								parameter: item?.parameter,
								stepNumber: item?.stepNumber, //num
								keyboardType: item?.keyboardType,
								placeHolder: item?.placeHolder,
								inputType: item?.inputType,
							},
							true
						);
					}}
				/> */}
				<Button
					className="icon-btn p-button-text p-button-danger"
					icon={"pi pi-trash"}
					onClick={() => onDelete(item.id)}
				/>
			</div>
		);
	};
	return (
		<>
			{spinner && <Loader />}
			<Dialog
				header={"Questions"}
				visible={dialog}
				onHide={() => {
					setDialog(false);
					formik.resetForm();
				}}
				style={{ width: "60%" }}
			>
				<form className="grid mt-3 form-grid">
					<div className="field col-12">
						<InputText
							name="question"
							placeholder="Question"
							className="w-full"
							value={formik.values.question}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						{Boolean(formik.errors["question"]) &&
							formik.touched["question"] && (
								<small className="text-red-600">
									{formik.errors["question"]}
								</small>
							)}
					</div>

					<div className="field col-12 md:col-6">
						<Dropdown
							name="stepNumber"
							placeholder="Step Number"
							className="w-full"
							style={{ borderRadius: "6px" }}
							options={[
								{ label: 25, value: 25 },
								{ label: 30, value: 30 },
								{ label: 3, value: 3 },
								{ label: 1, value: 1 },
								{ label: 2, value: 2 },
								{ label: 5, value: 5 },
								{ label: 10, value: 10 },
								{ label: 15, value: 15 },
								{ label: 20, value: 20 },
							]}
							value={formik.values.stepNumber}
							onChange={formik.handleChange}
						/>
						{formik.errors["stepNumber"] && (
							<small className="text-red-600">
								{formik.errors["stepNumber"]}
							</small>
						)}
					</div>

					<div className="field col-12 md:col-6">
						<Dropdown
							name="parameter"
							placeholder="Parameter"
							className="w-full"
							style={{ borderRadius: "6px" }}
							options={[
								{
									label: "sumCoverAmount",
									value: "sumCoverAmount",
								},
								{ label: "loanTenure", value: "loanTenure" },
								{ label: "loanAmount", value: "loanAmount" },
								{ label: "profession", value: "profession" },
								{ label: "dob", value: "dob" },
								{ label: "gender", value: "gender" },
								{
									label: "premiumPayingTerm",
									value: "premiumPayingTerm",
								},
								{
									label: "prosumCoverAmountfession",
									value: "prosumCoverAmountfession",
								},
							]}
							value={formik.values.parameter}
							onChange={(e) => {
								formik.setFieldValue(
									"parameter",
									e.target.value,
									true
								);
								formik.setFieldValue("inputType", "", true);
								formik.setFieldValue("keyboardType", "", true);
								switch (e.target.value) {
									case "profession":
										setInputTypeList([
											{
												label: "dropdown",
												value: "dropdown",
											},
										]);

										break;
									case "gender":
										setInputTypeList([
											{
												label: "dropdown",
												value: "dropdown",
											},
										]);
										break;
									case "dob":
										setInputTypeList([
											{
												label: "calender",
												value: "calender",
											},
										]);
										break;
									case "prosumCoverAmountfession":
										setKeyTypeList([
											{
												label: "sentences",
												value: "sentences",
											},
										]);
										break;
									default:
										setKeyTypeList([
											{
												label: "numeric",
												value: "numeric",
											},
										]);
										break;
								}
							}}
						/>
						{formik.errors["parameter"] && (
							<small className="text-red-600">
								{formik.errors["parameter"]}
							</small>
						)}
					</div>
					<div className="field col-12 md:col-6">
						<InputText
							name="placeHolder"
							placeholder="PlaceHolder"
							className="w-full"
							style={{ borderRadius: "6px" }}
							value={formik.values.placeHolder}
							onChange={formik.handleChange}
						/>
						{formik.errors["placeHolder"] && (
							<small className="text-red-600">
								{formik.errors["placeHolder"]}
							</small>
						)}
					</div>

					{formik.values.parameter &&
					(formik.values.parameter === "profession" ||
						formik.values.parameter === "dob" ||
						formik.values.parameter === "gender") ? (
						<div className="field col-12 md:col-6">
							<Dropdown
								name="inputType"
								placeholder="InputType"
								className="w-full"
								style={{ borderRadius: "6px" }}
								options={inputTypeList}
								value={formik.values.inputType}
								onChange={(e) => {
									formik.setFieldValue(
										"inputType",
										e.value,
										true
									);
									if (e.value === "dropdown") {
										formik.setFieldValue(
											"keyboardType",
											"",
											true
										);
									}
								}}
							/>
							{formik.errors["inputType"] && (
								<small className="text-red-600">
									{formik.errors["inputType"]}
								</small>
							)}
						</div>
					) : (
						<div className="field col-12 md:col-6">
							<Dropdown
								name="keyboardType"
								placeholder="Keyboard Type"
								className="w-full"
								style={{ borderRadius: "6px" }}
								options={keyBoardtTypeList}
								value={formik.values.keyboardType}
								onChange={formik.handleChange}
							/>
							{formik.errors["keyboardType"] && (
								<small className="text-red-600">
									{formik.errors["keyboardType"]}
								</small>
							)}
						</div>
					)}
					<div className="field col-12 flex gap-2 md:col-6 align-items-center justify-content-end">
						<Button
							type="button"
							label={"Save"}
							onClick={() => formik.handleSubmit()}
						/>
						<Button
							type="button"
							label="Reset"
							className="p-button-danger"
							onClick={() => formik.resetForm()}
						/>
					</div>
				</form>
			</Dialog>
			<Toast ref={toast} />
			<div className="col-12 flex align-items-center justify-content-between ">
				<h3 className="m-0">Questions</h3>
				<h4 className="m-0">{locationData?.state}</h4>
				<Button
					label={"Add"}
					onClick={() => {
						setDialog(true);
					}}
				/>
			</div>
			<DataTable
				value={questionData?.questionDtos}
				responsiveLayout="scroll"
				breakpoint="960px"
			>
				<Column field="question" header={"Question"} />
				<Column field="parameter" header={"Parameter"} />
				<Column body={actionTemplate} header={"Action"} />
			</DataTable>
		</>
	);
};

export default SubproductQuestions;
