import React, { useEffect, useReducer, useRef, useState } from "react";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import Config from "../../../../shared/config";
import { AgentService } from "../../../../services/AgentService";

import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { useGetAllRoleQuery } from "../../../../services/query/queryApi";

const AgentAssignDialoge = ({
	profileId,
	agentAssignDialogeClose,
	getMyAgents,
}) => {
	const toast = useRef(null);
	const loginUserDetail = useSelector((state) => state.persistData.auth.user);
	const [selectedAgent, setSelectedAgent] = useState(null);
	const [loading, setLoading] = useState(false);
	const agentService = new AgentService();
	const { data: roleList = [{ position: "", name: "" }], isLoading } =
		useGetAllRoleQuery();
	// console.log(loginUserDetail);
	const [agentDetail, setAgentDetail] = useReducer(
		dataReducer,
		fetchInitialState
	);

	const agentDetailData = () => {
		setAgentDetail({ type: Config.FETCH_CONFIG.start });
		agentService
			.getAgentDetails(loginUserDetail.userName)
			.then((res) => {
				setAgentDetail({
					type: Config.FETCH_CONFIG.success,
					payload: res.map((item) => ({
						...item,
						name: `${item.firstName} ${
							item.middleName ? item.middleName : ""
						} ${item.lastName}`,
					})),
				});
			})
			.catch((e) =>
				setAgentDetail({ type: Config.FETCH_CONFIG.error, payload: e })
			)
			.finally(() => setAgentDetail({ type: Config.FETCH_CONFIG.stop }));
	};

	const handelSubmit = () => {
		setLoading(true);
		const payload = {
			profileId: profileId,
			seUserName: selectedAgent.userName,
		};
		agentService
			.submitGetAgentDetails(payload)
			.then((res) => {
				agentAssignDialogeClose(false);
			})
			.catch((e) => {
				Toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			})
			.finally(() => {
				setLoading(false);
				getMyAgents();
			});
	};

	useEffect(() => {
		agentDetailData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="card modal-form-grid">
			<Toast ref={toast} />
			{agentDetail.isLoading ? (
				<div className="flex justify-content-center">
					<ProgressSpinner
						style={{ width: "50px", height: "50px" }}
						strokeWidth="4"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				</div>
			) : agentDetail.data.length > 0 ? (
				<>
					<Dropdown
						value={selectedAgent}
						onChange={(e) => setSelectedAgent(e.value)}
						options={agentDetail.data}
						optionLabel="name"
						placeholder="Select Tele Sales Executive"
						className="w-full border-round-md"
					/>
					<Button
						className="mt-3 w-full"
						label="Assign"
						onClick={handelSubmit}
						loading={loading}
					/>
				</>
			) : (
				<div className="mt-6">
					<p className="text-center">
						<i
							className="pi pi-info-circle"
							style={{ fontSize: "30px", color: "red" }}
						></i>
					</p>
					<h3 className="text-center">
						{/* Sorry! There is no Tele Salse Executive found. */}
						{`Sorry! There is no ${
							isLoading
								? "____"
								: roleList.find((item) => item.position === 5)
										.name
						} found.`}
					</h3>
				</div>
			)}
		</div>
	);
};

export default AgentAssignDialoge;
