import { useEffect, useRef, useState } from "react";
import Config from "../../../shared/config";
import { Button } from "primereact/button";
import { Field, Form, Formik } from "formik";
import { FeildDropdown, FieldInput } from "../../../components/FieldInput";
import { SplitButton } from "primereact/splitbutton";
import { ReportService } from "../../../services/ReportService";
import { exportExcel } from "../../../components/XlsxExport";
import { Toast } from "primereact/toast";
import moment from "moment";
import { ZoneService } from "../../../services/ZoneService";

const SearchReport = (props) => {
	const reportService = new ReportService();
	const zoneService = new ZoneService();
	const toast = useRef(null);
	const [open, setOpen] = useState(false);
	const [loader, setLoader] = useState(false);
	const [zoneId, setZoneId] = useState([]);
	const [stateValue, setStateValue] = useState([]);
	const [districtValue, setDistrictValue] = useState([]);

	const getZone = () => {
		zoneService.getZone().then((res) => {
			const custmZone = res.map((item) => ({ ...item, value: item.id }));
			setZoneId(custmZone);
		});
	};

	const handleZone = (e, setFieldValue) => {
		getState(e);
		setFieldValue("zone", e, true);
		setFieldValue("district", "", true);
	};

	const getState = (e) => {
		zoneService.getStateListByZone(e).then((res) => {
			const custmState = res.map((item) => ({ ...item, value: item.id }));
			setStateValue(custmState);
		});
	};

	const handleState = (e, setFieldValue) => {
		getDistrict(e);
		setFieldValue("state", e, true);
	};

	const getDistrict = (e) => {
		zoneService.getDistricByState(e).then((res) => {
			const custmDistrict = res.map((item) => ({
				...item,
				value: item.id,
			}));
			setDistrictValue(custmDistrict);
		});
	};

	// const handelDistrict = (e, setFieldValue) => {
	// 	console.log(e);
	// };
	useEffect(() => {
		getZone();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFormSubmit = (value) => {
		// const { state, district } = value;
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});

		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: {
				...value,
				// ...(state && { state: state.id }),
				// ...(district && { district: district.id }),
			},
		});
	};
	const exportData = (data) => {
		const newData = data.map((item) => ({
			"Agent Name":
				item.firstName + " " + item.middleName + " " + item.lastName,

			Username: item.userName,
			Mobile: item.mobileNumber,
			District: item.districtName,
			Status: item.status,
			Email: item.emailId,
			"Created On": moment(item.createdOn).format("Do MMM YYYY"),
			"RM username": item.rmUserName,
			"TSE username": item.seUserName,
		}));
		return newData;
	};
	let items = [
		{
			label: "Export",
			icon: "pi pi-fw pi-file-excel",
			command: () => {
				if (props.data) {
					const data = exportData(props.data);
					exportExcel(data, "agent_list");
				}
			},
		},
		{
			label: "Export All",
			icon: "pi pi-fw pi-file-excel",
			command: () => {
				const body = {
					pageNo: 1,
					pageSize: 10000000,
				};
				setLoader(true);
				reportService
					.getAgentReportList(body)
					.then((res) => {
						const data = exportData(res.data);
						exportExcel(data, "All agent list");
					})
					.catch((e) => {
						toast.current.show({
							severity: "error",
							summary: "Error",
							detail: e.message,
							life: 3000,
						});
					})
					.finally(() => setLoader(false));
			},
		},
	];

	return (
		<>
			<Toast ref={toast} />
			<div className="w-full  py-3 flex align-items-end justify-content-between">
				<h3 className="m-0">Agent Onboarding </h3>
				<div className="flex gap-2">
					<Button
						icon={open ? "pi pi-times" : "pi pi-search"}
						className={
							open
								? "p-button-danger p-button-outlined"
								: "p-button-outlined"
						}
						label={open ? "Close" : "Search"}
						aria-label="Cancel"
						onClick={() => setOpen((prev) => !prev)}
					/>
					<Button
						label="Reload"
						icon="pi pi-refresh"
						className="primary-btn p-button-outlined"
						onClick={() =>
							props.searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							})
						}
					/>

					<SplitButton
						loading={loader}
						label="Export"
						icon="pi pi-file-excel"
						model={items}
					/>
				</div>
			</div>
			{open && (
				<Formik
					initialValues={{
						// agentName: "",
						// email: "",
						// mobile: "",
						// rmUserName: "",
						// seUserName: "",
						// district: "",
						// state: "",
						// userName: "",
						// status: "",
						agentName: "",
						emailId: "",
						mobileNumber: "",
						rmUserName: "",
						seUserName: "",
						district: "",
						state: "",
						zone: "",
						userName: "",
						status: "",
						// fromDate: "",
						// toDate: "",
					}}
					onSubmit={handleFormSubmit}
				>
					{({ values, handleSubmit, setFieldValue, resetForm }) => (
						<Form
							onSubmit={handleSubmit}
							className="form-grid grid background-secondary p-4 border-round-md my-3"
						>
							<div className="field col-12 md:col-3">
								<Field
									header={"Agent Name"}
									name={"agentName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Email"}
									name={"emailId"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Mobile"}
									name={"mobileNumber"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Username"}
									name={"userName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"RM/ASM"}
									name={"rmUserName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"TSE"}
									name={"seUserName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Status"}
									name={"status"}
									component={FeildDropdown}
									options={Config.AGENT_STATUS}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									filter
									header={"Zone"}
									name={"zone"}
									component={FeildDropdown}
									options={zoneId}
									optionLabel={"name"}
									onChange={(e) => {
										handleZone(e.value, setFieldValue);
									}}
								/>
							</div>
							{values.zone && (
								<div className="field col-12 md:col-3">
									<Field
										filter
										header={"State"}
										name={"state"}
										component={FeildDropdown}
										options={stateValue}
										onChange={(e) => {
											handleState(e.value, setFieldValue);
										}}
										optionLabel={"name"}
									/>
								</div>
							)}

							{values.state && (
								<div className="field col-12 md:col-3">
									<Field
										filter
										header={"District"}
										name={"district"}
										component={FeildDropdown}
										options={districtValue}
										// onChange={(e) => {
										// 	handelDistrict(
										// 		e.value,
										// 		setFieldValue
										// 	);
										// }}
										optionLabel={"name"}
									/>
								</div>
							)}
							{/* <div className="field col-12 md:col-3">
								<Field
									header={"From Date"}
									name={"fromDate"}
									component={FeildCalender}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"To Date"}
									name={"toDate"}
									component={FeildCalender}
								/>
							</div> */}

							<div className="field flex align-items-center  justify-content-end col-12">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={() => resetForm()}
								/>
								<Button type="submit" label="Search" />
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default SearchReport;
