import { Field, FieldArray, Form, Formik } from "formik";
import { Button } from "primereact/button";

import * as Yup from "yup";
import { Divider } from "primereact/divider";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
} from "../../../../../components/FieldInput";

const CommisionUpdate = (props) => {
	const validationSchema = Yup.object().shape({
		commissionType: Yup.string().required("This field is required"),
		startDate: Yup.date().required("This field is required"),
		endDate: Yup.date()
			.when("startDate", (startDate, value) => {
				return value.test({
					test: (endDate) =>
						!!new Date(startDate) &&
						new Date(endDate) > new Date(startDate),
					message: "Invalid date",
				});
			})
			.required("This field is required"),
		programCommissionDetailsDtos: Yup.array().of(
			Yup.object().shape({
				minAmount: Yup.number()
					.min(0, "Invalid Amount")
					.required("This field is required"),
				maxAmount: Yup.number()
					.min(0, "Invalid Amount")
					.when(["minAmount"], (min, value) => {
						return value.test({
							test: (max) =>
								(!!min && max > min) || !Boolean(max),
							message: "Max amount > Min amount",
						});
					})
					.nullable(),
				// amount: Yup.number()
				// 	.when(["minAmount", "maxAmount"], (min, max, value) => {
				// 		return value.test({
				// 			test: (def) => {
				// 				return (
				// 					!!min && !!max && max >= def && def >= min
				// 				);
				// 			},
				// 			message: "Max > Def > Min ",
				// 		});
				// 	})
				// 	.required("This field is required"),
				amount: Yup.number().required("This field is required"),
			})
		),
	});
	const initialValue = {
		commissionType: "percentage",
		startDate: "",
		endDate: "",
		programCommissionDetailsDtos: [
			{
				minAmount: "",
				maxAmount: "",
				amount: "",
			},
		],
	};
	return (
		<>
			<Formik
				initialValues={{
					...initialValue,
					...(props.data && {
						...props.data,
						programId: props.programId,
						startDate: props.data.startDate
							? new Date(props.data.startDate)
							: "",
						endDate: props.data.endDate
							? new Date(props.data.endDate)
							: "",
					}),
				}}
				enableReinitialize
				onSubmit={props.handleCommsissionSubmit}
				validationSchema={validationSchema}
			>
				{({ values, handleSubmit }) => (
					<Form
						className=" p-3 my-3 border-1 border-400 border-round-md"
						onSubmit={handleSubmit}
					>
						<div className="form-grid grid">
							<>
								<div className="field col-12 md:col-4">
									<Field
										name={`startDate`}
										component={FeildCalender}
										header={"Effective Start Date"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										name={`endDate`}
										component={FeildCalender}
										header={"Effective End Date"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										name={`commissionType`}
										component={FeildDropdown}
										header={"Commission Type"}
										options={[
											{
												label: "Fixed",
												value: "fixed",
											},
											{
												label: "Percentage",
												value: "percentage",
											},
										]}
									/>
								</div>
							</>
						</div>
						<Divider />
						<FieldArray
							name="programCommissionDetailsDtos"
							render={(arrayHelper) => (
								<>
									{values.programCommissionDetailsDtos &&
										values.programCommissionDetailsDtos
											.length >= 0 &&
										values.programCommissionDetailsDtos.map(
											(item, index) => (
												<div
													className="form-grid grid"
													key={
														index.toString() +
														"mapValueCommision"
													}
												>
													<div className="field col-12 md:col-4">
														<Field
															name={`programCommissionDetailsDtos.${index}.minAmount`}
															component={
																FieldInput
															}
															header={
																"Min Sell Amount"
															}
															type={"number"}
														/>
													</div>
													<div className="field col-12 md:col-3">
														<Field
															name={`programCommissionDetailsDtos.${index}.maxAmount`}
															component={
																FieldInput
															}
															header={
																"Max Sell Amount"
															}
															type={"number"}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															name={`programCommissionDetailsDtos.${index}.amount`}
															component={
																FieldInput
															}
															header={`Commission:(${
																values.commissionType ===
																"fixed"
																	? "Fixed Amount"
																	: "Percentage"
															}) `}
															type={"number"}
														/>
													</div>
													<div className="col-1 pt-5">
														<Button
															type="button"
															onClick={() =>
																arrayHelper.remove(
																	index
																)
															}
															icon="pi pi-minus"
															className="p-button-danger p-button-sm p-button-outlined md:col-5 "
															aria-label="Cancel"
														/>
													</div>
												</div>
											)
										)}
									<div className="col-12 px-0 ">
										<Button
											type="button"
											icon="pi pi-plus"
											className="p-button-text w-full bg-primary-100 border-dashed border-1 border-indigo-600"
											onClick={() =>
												arrayHelper.push({
													minAmount: "",
													maxAmount: "",
													amount: "",
												})
											}
										/>
									</div>
								</>
							)}
						/>
						<div className=" flex align-item-center gap-2 justify-content-end">
							<Button
								type={"reset"}
								className="danger-btn"
								icon="pi pi-refresh"
								label={"Reset"}
							/>
							<Button
								type="submit"
								icon="pi pi-save"
								label={"Create"}
								disabled={props.programId ? false : true}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default CommisionUpdate;
