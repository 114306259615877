import React, { useEffect, useReducer, useRef, useState } from "react";
import { Button } from "primereact/button";
import { SupportService } from "../../../services/SupportService";
import { Dialog } from "primereact/dialog";
import EditFAQDialoge from "./EditFAQDialoge";
import CreateFAQDialoge from "./CreateFAQDialoge";
import Config from "../../../shared/config";
import { produce } from "immer";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import { ListBox } from "primereact/listbox";
import AddDialoge from "./AddDialoge";
import CreateTutorialCat from "./components/CreateTutorialCat";
import TutorialList from "./components/TutorialList";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import Loader from "../../../components/Loader";
import { NoDataBtn } from "../../../components/NoDataBtn";
const Tutorial = () => {
	const supportService = new SupportService();
	const [categoryList, setCategoryList] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const toast = useRef(null);
	const initialState = {
		value: {},
		dialog: false,
		type: "Create",
	};
	const [spinner, setSpinner] = useState(false);
	const reducer = produce((state, action) => {
		switch (action.type) {
			case "value":
				state.value = action.payload;
				break;
			case "dialog":
				state.dialog = action.payload;
				break;
			case "type":
				state.type = action.payload;
				break;
			default:
				return state;
		}
	});
	const detailsReducer = produce((state, action) => {
		state.data = action.data;
	});
	const [detailsState, detailsDispatch] = useReducer(detailsReducer, {
		data: {},
	});
	useEffect(() => {
		let subscribed = true;
		if (subscribed) {
			getAllTutorialCategory();
		}
		return () => {
			subscribed = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [tutorialState, tutorialDispatch] = useReducer(reducer, initialState);
	const handleDialog = (type, value) => {
		tutorialDispatch({ type: "dialog", payload: true });
		if (type === "create") {
			tutorialDispatch({ type: "type", payload: "Create" });
			tutorialDispatch({ type: "value", payload: initialState.value });
		} else {
			tutorialDispatch({ type: "type", payload: "Update" });
			tutorialDispatch({ type: "value", payload: value });
		}
	};
	const getAllTutorialCategory = () => {
		setCategoryList({
			type: Config.FETCH_CONFIG.start,
		});
		supportService
			.getFAQTutorialCate()
			.then((res) => {
				setCategoryList({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
				detailsDispatch({ data: res[0] });
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				})
			)
			.finally(() =>
				setCategoryList({
					type: Config.FETCH_CONFIG.stop,
				})
			);
	};

	const handleCategorySelect = (e) => {
		detailsState.data.id &&
			detailsState.data.id !== e.id &&
			detailsDispatch({ data: e });
	};

	const itemTemplate = (item) => {
		return (
			<div className="flex justify-content-between align-items-center">
				<div className="flex align-items-center gap-2">
					<img
						src={`${Config.AGENT_BUCKET_ACCESS_URL}/faq-docs/${item.imageAttachment}`}
						alt={item.name}
						className="border-circle w-2rem h-2rem"
						style={{ objectFit: "cover" }}
					/>

					<h5>{item.name}</h5>
				</div>

				<Button
					// label="Edit"

					icon={"pi pi-pencil"}
					className="p-button-text"
					onClick={() => {
						handleDialog("update", item);
					}}
				/>
			</div>
		);
	};
	const handleFormSubmit = (value) => {
		if (tutorialState.type === "Create") {
			setSpinner(true);
			supportService
				.createFAQTutorialCategory(value)
				.then((res) => {
					toast.current.show({
						severity: "success",
						summary: "Successfull",
						detail: "Successfull",
						style: { color: "#000000" },
						life: 3000,
					});
					tutorialDispatch({ type: "dialog", payload: false });
				})
				.catch((err) =>
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: "Error",
						life: 3000,
					})
				)
				.finally(() => {
					setSpinner(false);
					getAllTutorialCategory();
				});
		} else {
			setSpinner(true);
			supportService
				.editFAQTutorialCategory(value)
				.then((res) => {
					toast.current.show({
						severity: "success",
						summary: "Successfull",
						detail: "Successfull",
						style: { color: "#000000" },
						life: 3000,
					});
					tutorialDispatch({ type: "dialog", payload: false });
				})
				.catch((err) =>
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: "Error",
						life: 3000,
					})
				)
				.finally(() => {
					setSpinner(false);
					getAllTutorialCategory();
				});
		}
	};
	const handleClick = () => {
		handleDialog("create");
	};

	return (
		<div className="grid">
			<Toast ref={toast} />
			{spinner && <Loader />}
			<h3 className="col-12">Tutorial</h3>
			<div className="col-12 md:col-3">
				{categoryList.isLoading ? (
					<>
						<Skeleton width="100%" className="mb-2" height="5rem" />
						<Skeleton width="100%" className="mb-2" height="5rem" />
						<Skeleton width="100%" className="mb-2" height="5rem" />
						<Skeleton width="100%" height="5rem" />
					</>
				) : (
					<ListBox
						options={categoryList.data}
						onChange={(e) => {
							handleCategorySelect(e.value);
						}}
						value={detailsState.data}
						filter
						optionLabel="name"
						itemTemplate={itemTemplate}
						listStyle={{ maxHeight: "60vh" }}
					/>
				)}
				<Button
					className="w-full my-2"
					icon={"pi pi-plus"}
					label="Add Tutorial Category"
					onClick={() => {
						handleDialog("create");
					}}
				/>
			</div>
			<div className="col-12 md:col-9">
				{detailsState.data ? (
					<TutorialList value={detailsState.data} />
				) : (
					<NoDataBtn
						header={"Add Category"}
						handleClick={handleClick}
					/>
				)}
			</div>
			<Dialog
				header={tutorialState.type}
				visible={tutorialState.dialog}
				onHide={() => {
					tutorialDispatch({ type: "dialog", payload: false });
				}}
				breakpoints={{
					"960px": "95vw",
				}}
				style={{ width: "50%" }}
			>
				<CreateTutorialCat
					value={tutorialState.value}
					handleFormSubmit={handleFormSubmit}
				/>
			</Dialog>
		</div>
	);
};

export default Tutorial;
