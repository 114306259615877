import React, { useState } from "react";
import Header from "../../../../partners/institutions/header";
import UploadFile from "./UploadFile";
import Preview from "./Preview";
import Completion from "./Completion";

const BulkUpload = ({ handleStatusDialogeClose }) => {
	const [num, setNum] = useState(1);
	const [data, setData] = useState([]);
	const [finalData, setFinalData] = useState([]);

	const [stages, setstages] = useState([
		{
			sequence: 1,
			title: "Upload File",
			status: "active",
		},
		{
			sequence: 2,
			title: "Preview",
			status: "inactive",
		},
		{
			sequence: 3,
			title: "Completion",
			status: "inactive",
		},
	]);

	const [id, setId] = useState(null);
	const [stageName, setstageName] = useState("Upload File");
	const getId = (item) => {
		setId(item);
	};

	const handleBack = () => {
		setNum(num - 1);
	};

	const handelStages = () => {
		if (num < stages.length) {
			let newStage = [...stages];
			let sequence = num + 1;
			let index = newStage.findIndex(
				(item) => item.sequence === sequence
			);
			newStage[index].status = "active";
			newStage[index - 1].status = "success";
			setstageName(newStage[index].title);
			setstages(newStage);
			setNum(sequence);
		}
	};

	const excelData = (e) => {
		setData(e);
	};
	const submitData = (e) => {
		setFinalData(e);
	};

	return (
		<div>
			<Header
				stages={stages}
				title="Upload Leads in Bulk"
				stageName={stageName}
			/>
			{num === 1 ? (
				<UploadFile
					getId={getId}
					handelStages={handelStages}
					excelData={excelData}
					handleStatusDialogeClose={handleStatusDialogeClose}
				/>
			) : num === 2 ? (
				<Preview
					id={id}
					handelStages={handelStages}
					handleBack={handleBack}
					data={data}
					submitData={submitData}
				/>
			) : num === 3 ? (
				<Completion
					id={id}
					handelStages={handelStages}
					finalData={finalData}
					handleStatusDialogeClose={handleStatusDialogeClose}
				/>
			) : null}
		</div>
	);
};

export default BulkUpload;
