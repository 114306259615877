import { Field, FieldArray, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { FieldInput } from "../../../../components/FieldInput";
import { Button } from "primereact/button";
import { SupportService } from "../../../../services/SupportService";
import { Toast } from "primereact/toast";
import * as Yup from "yup";

const FaqTopic = ({ item, handleRefresh }) => {
	const toastTL = useRef(null);
	const supportService = new SupportService();
	const [loading, setLoading] = useState(false);

	const validationSchema = Yup.object().shape({
		child: Yup.array().of(
			Yup.object().shape({
				description: Yup.string()
					.nullable()
					.required("This field is required"),
				answer: Yup.string()
					.nullable()
					.required("This field is required"),
			})
		),
	});
	const initialValues = {
		child: [],
	};

	const handleFormSubmit = (e) => {
		setLoading(true);
		supportService
			.createFAQChild(e)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoading(false);
				handleRefresh();
			});
	};
	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			<Formik
				initialValues={{ ...initialValues, ...item }}
				onSubmit={handleFormSubmit}
				validationSchema={validationSchema}
				enableReinitialize
			>
				{({ handleSubmit, values, resetForm }) => (
					<Form onSubmit={handleSubmit} className="mt-5">
						<FieldArray
							name="child"
							render={(arrayHelper) => (
								<>
									{values.child && values.child.length > 0
										? values.child.map((item, index) => (
												<div
													className="grid form-grid"
													key={index}
												>
													<div className="field col-12">
														<Field
															header={`${
																index + 1
															}. Question`}
															component={
																FieldInput
															}
															name={`child.${index}.description`}
														/>
													</div>

													<div className="field col-11">
														<Field
															header={"Answer"}
															component={
																FieldInput
															}
															name={`child.${index}.answer`}
														/>
													</div>
													<div className="field col-1">
														<Button
															type="button"
															onClick={() =>
																arrayHelper.remove(
																	index
																)
															}
															style={{
																marginTop:
																	"1.5rem",
															}}
															icon="pi pi-trash"
															className="p-button-outlined p-button-danger p-button-sm"
															aria-label="Cancel"
														/>
													</div>
												</div>
										  ))
										: null}

									<div
										className="add-more-btn flex align-items-center justify-content-center"
										onClick={() =>
											arrayHelper.push({
												description: "",
												answer: "",
											})
										}
									>
										<i className="pi pi-plus"></i>
										<span className="ml-2">
											Add Question
										</span>
									</div>
								</>
							)}
						/>
						<div className="flex justify-content-end align-items-center w-full mt-4">
							<Button
								type="button"
								label="Clear"
								icon="pi pi-times"
								className="danger-btn mr-2"
								onClick={() => resetForm()}
								style={{
									borderRadius: "6px",
								}}
							/>
							<Button
								type="submit"
								loading={loading}
								label="Save"
								icon="pi pi-save"
								style={{
									borderRadius: "6px",
								}}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default FaqTopic;
