import { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConnectedFocusError } from "focus-formik-error";
import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
} from "../../../../../components/FieldInput";
import Config from "../../../../../shared/config";
import moment from "moment";
import selectState from "../../../../../components/stateList";
import { useGetAllDIstrictQuery } from "../../../../../services/query/queryApi";
import { LoanApplicationService } from "../../../../../services/LoanApplication";
import { dataReducer } from "../../../../../components/fetchReducer";
import { LoanService } from "../../../../../services/LoanService";
import { Divider } from "primereact/divider";
import DocumentUpload from "../DocumentUpload";
import {
	convertNullToString,
	convertObjNull,
} from "../../../../../utils/UtilsFunction";
import Loader from "../../../../../components/Loader";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import PreviewPdf from "../PreviewApplication";
import { setCountryApplicationId } from "../../../../../store/reducer/AuthReducer";
import Header from "../../../../partners/institutions/header";
import { FileUpload } from "primereact/fileupload";

const MotorInsuance = (props) => {
	const navigate = useNavigate();
	const [leaddetailsstate, leadDetailsDIspatch] = useReducer(dataReducer, {
		data: {},
		isLoading: false,
		error: false,
		errorMessage: null,
		isFetched: false,
	});
	const selector = useSelector((state) => state.persistData);

	const [loader, setLoader] = useState(true);
	const [printDialog, setPrintDialog] = useState(false);
	const dispatch = useDispatch();
	const {
		data: getAllDistrict = [
			{ districtList: [{ id: null, name: "" }], id: null, name: "" },
		],
	} = useGetAllDIstrictQuery();
	const toastTL = useRef(null);
	const [formState, formDispatch] = useReducer(dataReducer, {
		data: {},
		isLoading: false,
		error: false,
		errorMessage: null,
		isFetched: false,
	});
	const loanApplicationService = new LoanApplicationService();
	const loanService = new LoanService();
	const getApplicationByMobile = () => {
		setLoader(true);
		loanApplicationService
			.getLoanApplicationCreateFullList(props?.locationData?.mobileNumber)
			.then((res) => {
				leadDetailsDIspatch({
					type: Config.FETCH_CONFIG.success,
					payload: convertNullToString(res),
				});
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoader(false));
	};
	const onUploadImage = (e) => {
		//setLoading(true);
		setLoader(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket:
				Config.APPLICATION_ATTACHMENT_BUCKET +
				"/" +
				selector.applicationId,
			Key: newFileName,
			ContentType: file.type,
		};

		// s3Clint.upload(params, (err) => {
		// 	if (err || !dropdownState.value.documentTypeId.id) {
		// 		//setLoading(false);
		// 		setLoader(false);
		// 		return toast.current.show({
		// 			severity: "error",
		// 			summary: "Error",
		// 			detail: "Select Document Types",
		// 			style: { color: "#000000" },
		// 			life: 3000,
		// 		});
		// 	}
		// 	if (!selector.applicationId && selector.name === "lead") {
		// 		setLoader(false);
		// 		return toast.current.show({
		// 			severity: "error",
		// 			summary: "Error",
		// 			detail: "Save as draft before upload",
		// 			style: { color: "#000000" },
		// 			life: 3000,
		// 		});
		// 	}

		//setLoading(false);

		// });
	};

	useEffect(() => {
		Promise.all([
			// loanApplicationService.getReason(props.locationData.subproductId),
			props?.locationData?.mobileNumber && getApplicationByMobile(),
			// loanApplicationService.getDisease(),
			(props.locationData.applicationId ||
				selector.countryApplicationId) &&
				getData(
					props.locationData.applicationId
						? props.locationData.applicationId
						: selector.countryApplicationId
				),
		])
			// .then((response) => {
			// 				response[2] &&
			// 		formDispatch({
			// 			type: Config.FETCH_CONFIG.success,
			// 			payload: response[2],
			// 		});
			// })
			.catch((error) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoader(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const insuranceInitialValue = {
	// 	insuarerDetails: [],
	// 	pan: props?.locationData?.panNumber,
	// 	firstName: "",
	// 	middleName: "",
	// 	lastName: "",
	// 	mobile: props?.locationData?.mobileNumber,
	// 	alternativeMobile: "",
	// 	dob: "",
	// 	isSmoke: false,
	// 	isChewTobacco: false,
	// 	isAlcohol: false,
	// 	gender: "",
	// 	maritalStatus: "",
	// 	spouseName: "",
	// 	fatherName: "",
	// 	motherMaidenName: "",
	// 	height: "",
	// 	weight: "",
	// 	addressLine1: "",
	// 	pincode: "",
	// 	state: props?.locationData?.state,
	// 	district: props?.locationData?.district,
	// 	preferredMailingAddress: "",
	// 	highestQualification: "",
	// 	occupationType: "",
	// 	designation: "",
	// 	personalMail: "",
	// 	companyType: "",
	// 	annualIncome: "",
	// 	workingPeriod: "",
	// 	policyDetails: "",
	// 	otherReason: "",

	// 	proposerFirstName: "",
	// 	proposerMiddleName: "",
	// 	proposerLastName: "",
	// 	proposerMobile: "",
	// 	proposerDob: "",
	// 	isProposerSmoke: false,
	// 	isProposerChewTobacco: false,
	// 	proposerGender: "",
	// 	proposerMaritalStatus: "",
	// 	proposerSpouseName: "",
	// 	proposerMotherMaidenName: "",
	// 	proposerHeight: "",
	// 	proposerWeight: "",
	// 	proposerLandMark: "",
	// 	proposerQualification: "",
	// 	proposerOccupation: "",
	// 	proposerDesignation: "",
	// 	proposerEmail: "",
	// 	proposerCompanyType: "",
	// 	proposerAnnualIncome: "",
	// 	proposerWorkingPeriod: "",

	// 	nomineeName: "",
	// 	nomineeDob: "",
	// 	nomineeGender: "",
	// 	relationshipWithPrimaryInsured: "",
	// 	appointeeName: "",
	// 	relationshipWithNominee: "",

	// 	familyFather: "",
	// 	familyMother: "",
	// 	familySpouse: "",
	// 	familySon: "",
	// 	familyDaughter: "",
	// 	familyAge: "",
	// 	familyStatus: "",

	// 	paymentFrequency: "",
	// 	premium: "",
	// 	premiumPayingTerm: "",
	// 	policyTerm: "",
	// 	diseaseList: [],
	// 	leadId: props?.locationData?.leadId ? props?.locationData?.leadId : "",
	// 	...(!props.locationData?.applicationId && {
	// 		...leaddetailsstate.data,
	// 		dob: Boolean(leaddetailsstate.data?.dob)
	// 			? new Date(leaddetailsstate.data?.dob)
	// 			: "",
	// 		proposerDob: Boolean(leaddetailsstate.data?.proposerDob)
	// 			? new Date(leaddetailsstate.data?.proposerDob)
	// 			: "",
	// 		nomineeDob: Boolean(leaddetailsstate.data?.nomineeDob)
	// 			? new Date(leaddetailsstate.data?.nomineeDob)
	// 			: "",
	// 	}),
	// 	...((props.locationData?.applicationId ||
	// 		selector.countryApplicationId) && {
	// 		...formState?.data,
	// 		dob: Boolean(formState.data?.dob)
	// 			? new Date(moment(formState.data?.dob).format())
	// 			: "",
	// 		proposerDob: Boolean(formState.data?.proposerDob)
	// 			? new Date(moment(formState.data?.proposerDob).format())
	// 			: "",
	// 		nomineeDob: Boolean(formState.data?.nomineeDob)
	// 			? new Date(moment(formState.data?.nomineeDob).format())
	// 			: "",
	// 	}),
	// };
	const insuranceInitialValue = {
		salutation: "",
		firstName: "",
		middleName: "",
		lastName: "",
		mobileNumber: "",
		pincode: "",
		pan: "",
		state: "",
		district: "",
		email: "",
		generateQuote: "",
		commercialType: "",
		registrationNumber: "",
		registrationDate: "",
		policyIssueDate: "",
		policyEndDate: "",
		model: "",
		manufacturerYear: "",
		fuelType: "",
		varient: "",
		typeOfPolicy: "",
		// policyPaper: "",
		aadhaar: "",
		paymentFrequency: "",
		premium: "",
		modeOfPayment: "",
		// productId: 2173,
	};
	const getDistrictData = (e) => {
		const stateData = getAllDistrict.find((item) => item.id === e);
		if (stateData !== undefined) {
			const districtData = stateData.districtList.map((e) => ({
				...e,
				value: e.id,
			}));
			return districtData;
		} else {
			return [];
		}
	};
	const getData = (applicationId) => {
		loanApplicationService
			.getMotorInsuranceLoanApplicationEditList(applicationId)
			.then((res) => {
				const data = convertNullToString(res);
				console.log(data);
				formDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: data,
				});
			})

			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				formDispatch({ type: Config.FETCH_CONFIG.stop });
			});
	};

	const handleFormSubmit = (value) => {
		const reqBody = {
			...value,
			// applicationType: "LOAN",
			registrationDate: Boolean(value.registrationDate)
				? moment(value.registrationDate).format("YYYY-MM-DD")
				: null,
			policyIssueDate: Boolean(value?.policyIssueDate)
				? new Date(moment(value?.policyIssueDate).format("YYYY-MM-DD"))
				: null,
			policyEndDate: Boolean(value?.policyEndDate)
				? new Date(moment(value?.policyEndDate).format("YYYY-MM-DD"))
				: null,
		};

		const param = props.locationData.applicationId
			? reqBody
			: {
					...reqBody,
					program: { id: props.locationData?.programId },
					...(selector.countryApplicationId && {
						id: selector.countryApplicationId,
					}),
			  };

		const data = convertObjNull(param);
		setLoader(true);
		loanService
			.motorInsuranceSaveDraft(data)
			.then((res) => {
				!props.locationData?.applicationId &&
					!selector.countryApplicationId &&
					dispatch(setCountryApplicationId(res.id));
				props.locationData?.applicationId
					? getData(props.locationData?.applicationId)
					: selector.countryApplicationId
					? getData(selector.countryApplicationId)
					: getData(res.id);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail:
						props.locationData?.applicationId ||
						selector.countryApplicationId
							? "Application updated"
							: "Application saved",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoader(false);
			});
	};

	const finalSubmit = (applicationId) => {
		setLoader(true);

		loanService
			.insuranceApplicationSubmit(applicationId)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});

				navigate("/masters/insurance-application-list");
			})

			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoader(false);
			});
	};
	const validationSchema = Yup.object().shape({
		// daughterCount: Yup.number().max(10, "Invalid amount").nullable(),
		// sonCount: Yup.number().max(10, "Invalid amount").nullable(),
		mobileNumber: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable()
			.required("This field is required"),
		firstName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid first name"
			)
			.nullable()
			.required("This field is required"),
		state: Yup.number().nullable().required("State is required"),
		district: Yup.number().nullable().required("District is required"),
		lastName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid last name"
			)
			.nullable()
			.required("This field is required"),
		registrationNumber: Yup.string()
			.nullable()
			.required("This field is required"),
		registrationDate: Yup.date()
			.nullable()
			.required("This field is required"),
		policyIssueDate: Yup.date()
			.nullable()
			.required("This field is required"),
		policyEndDate: Yup.date().nullable().required("This field is required"),
		pan: Yup.string()
			.matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Pan Number is invalid")
			.nullable()
			.required("This field is required"),
		aadhaar: Yup.number("Invalid")
			// .matches(
			// 	/^ [2-9] {1} [0-9] {3}\s [0-9] {4}\s [0-9] {4}$/,
			// 	"Aadhaar Number is invalid"
			// )
			.nullable()
			.required("This field is required"),
	});
	return (
		<>
			{loader && <Loader />}
			<Toast ref={toastTL} position="top-left" />
			<Dialog
				visible={printDialog}
				style={{ width: "80%" }}
				onHide={() => setPrintDialog(false)}
			>
				{formState.data?.salutation && (
					<PreviewPdf pdfState={formState.data} />
				)}
			</Dialog>
			<Button
				label="back"
				className="mb-2"
				icon={"pi pi-arrow-left"}
				onClick={() => navigate("/masters/insurance-application-list")}
			/>
			<Header
				stages={[]}
				title="Application"
				stageName={
					props?.locationData?.programName
						? props?.locationData?.programName
						: formState?.data?.program?.name
				}
			/>

			<Formik
				onSubmit={handleFormSubmit}
				initialValues={{
					...insuranceInitialValue,
					...(!props.locationData?.applicationId && {
						...leaddetailsstate.data,
						registrationDate: Boolean(
							leaddetailsstate.data?.registrationDate
						)
							? new Date(leaddetailsstate.data?.registrationDate)
							: "",
						policyIssueDate: Boolean(
							leaddetailsstate.data?.policyIssueDate
						)
							? new Date(leaddetailsstate.data?.policyIssueDate)
							: "",
						policyEndDate: Boolean(
							leaddetailsstate.data?.policyEndDate
						)
							? new Date(leaddetailsstate.data?.policyEndDate)
							: "",
					}),
					pan: props?.locationData?.panNumber,
					state: props?.locationData?.state,
					district: props?.locationData?.district,
					mobileNumber: props?.locationData?.mobileNumber,
					leadId: props?.locationData?.leadId
						? props?.locationData?.leadId
						: "",
					...((props.locationData?.applicationId ||
						selector.countryApplicationId) && {
						...formState?.data,

						registrationDate: Boolean(
							formState.data?.registrationDate
						)
							? new Date(formState.data?.registrationDate)
							: "",
						policyIssueDate: Boolean(
							formState.data?.policyIssueDate
						)
							? new Date(formState.data?.policyIssueDate)
							: "",
						policyEndDate: Boolean(formState.data?.policyEndDate)
							? new Date(formState.data?.policyEndDate)
							: "",
					}),
				}}
				validationSchema={validationSchema}
				enableReinitialize
			>
				{({ values, handleChange, handleSubmit, setFieldValue }) => (
					<Form
						className="py-5"
						onSubmit={handleSubmit}
						autoComplete="off"
					>
						<ConnectedFocusError />
						<Panel
							header={<h3 className="m-0">Proposer Details</h3>}
							className={"my-2"}
							toggleable
						>
							<div className="grid form-grid">
								<div className="field col-12 md:col-2">
									<Field
										header={"Salutation"}
										name={"salutation"}
										component={FeildDropdown}
										options={Config.SALUTATION}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"First Name"}
										name={"firstName"}
										component={FieldInput}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-2">
									<Field
										header={"Middle Name"}
										name={"middleName"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-2">
									<Field
										header={"Last Name"}
										name={"lastName"}
										star={"*"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Pan"}
										name={"pan"}
										star={"*"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Aadhaar"}
										name={"aadhaar"}
										component={FieldInput}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Mobile Number"}
										name={"mobileNumber"}
										component={FieldInput}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Email Id"}
										name={"email"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"State"}
										name={"state"}
										filter
										component={FeildDropdown}
										options={selectState(getAllDistrict)}
										optionLabel={"name"}
										star={"*"}
									/>
								</div>
								{values.state && (
									<div className="field col-12 md:col-4">
										<Field
											header={"District"}
											name={"district"}
											filter
											component={FeildDropdown}
											options={getDistrictData(
												values.state
											)}
											optionLabel={"name"}
											star={"*"}
										/>
									</div>
								)}
								<div className="field col-12 md:col-4">
									<Field
										name={"pincode"}
										header={"pincode"}
										component={FieldInput}
										type={"number"}
									/>
								</div>

								{/* <div className="field col-12 md:col-4">
									<Field
										name={"monthlyIncome"}
										header={"Monthly income"}
										component={FieldInput}
										type={"number"}
									/>
								</div> */}
							</div>
						</Panel>

						<Panel
							header={<h3 className="m-0">Other Details</h3>}
							className={"my-2"}
							toggleable
						>
							<div className="form-grid grid">
								<div className="field col-12 md:col-4">
									<Field
										header={"Commercial Type"}
										name={"commercialType"}
										component={FeildDropdown}
										options={[
											{ label: "Autos", value: "AUTOS" },
											{ label: "Taxis", value: "TAXIS" },
											{
												label: "Goods Carrying Vehicles",
												value: "GOODS_CARRYING_VEHICLES",
											},
											{
												label: "Buses and coaches",
												value: "BUSES_AND_COACHES",
											},
										]}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Quote For Renewal"}
										name={"generateQuote"}
										component={FieldInput}
									/>
								</div>
								{/* <div className="field col-12 md:col-4">
									<Field
										header={"Registration Date"}
										name={"registrationDate"}
										component={FeildCalender}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Policy Issue Date"}
										name={"policyIssueDate"}
										component={FeildCalender}
									/>
								</div> */}
								{/* <div className="field col-12 md:col-4">
									<Field
										header={"Policy End Date"}
										name={"policyEndDate"}
										component={FeildCalender}
									/>
								</div>
								 */}
								<div className="field col-12 md:col-4">
									<Field
										header={"Registration Date"}
										name={"registrationDate"}
										component={FeildCalender}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Registration Number"}
										name={"registrationNumber"}
										component={FieldInput}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Policy Issue Date"}
										name={"policyIssueDate"}
										component={FeildCalender}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Policy End Date"}
										name={"policyEndDate"}
										component={FeildCalender}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Model"}
										name={"model"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Manufacturer Year"}
										name={"manufacturerYear"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Fuel Type"}
										name={"fuelType"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Varient"}
										name={"varient"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Type Of Policy"}
										name={"typeOfPolicy"}
										component={FeildDropdown}
										options={[
											{
												label: "Comprehensive",
												value: "COMPREHENSIVE",
											},
											{
												label: "Third Party",
												value: "THIRD_PARTY",
											},
											{
												label: "On damage",
												value: "ONDAMAGE",
											},
										]}
									/>
								</div>
								{/* <div>
									<FileUpload
										customUpload
										accept=".jpg,.png,.jpeg, .pdf"
										uploadHandler={(e) => onUploadImage(e)}
										maxFileSize={1000000}
										emptyTemplate={
											<p className="m-0">
												Drag and drop files to here to
												upload.
											</p>
										}
									/>
								</div> */}
								<div className="col-12 flex align-items-end justify-content-end">
									{selector.applicationState !==
										"preview" && (
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									)}
								</div>
							</div>
						</Panel>

						<Panel
							header={<h3 className="m-0">Payment Details</h3>}
							className={"my-2"}
							toggleable
						>
							<div className="form-grid grid">
								<div className="field col-12 md:col-4">
									<Field
										header={"Payment Frequency"}
										name={"paymentFrequency"}
										component={FeildDropdown}
										options={Config.PAYMENYTYPE}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Premium"}
										name={"premium"}
										component={FieldInput}
										type={"number"}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Payment Mode"}
										name={"modeOfPayment"}
										component={FieldInput}
									/>
								</div>

								<div className="col-12 flex align-items-end justify-content-end">
									{selector.applicationState !==
										"preview" && (
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									)}
								</div>
							</div>
						</Panel>
					</Form>
				)}
			</Formik>
			<Divider />
			<DocumentUpload
				applicationId={props?.locationData?.applicationId}
				subproductId={props?.locationData?.subproductId}
			/>
			{(props.locationData?.applicationId ||
				selector.countryApplicationId) && (
				<div className="flex justify-content-end align-items-center w-full mt-4">
					<Button
						type="button"
						label="Submit"
						icon="pi pi-save"
						onClick={() => {
							finalSubmit(
								props.locationData?.applicationId
									? props.locationData?.applicationId
									: selector.countryApplicationId
							);
						}}
					/>
				</div>
			)}
		</>
	);
};

export default MotorInsuance;
