export const apiPath = process.env.REACT_APP_MASTER_API_PATH;
// export const apiPath = "http://192.168.0.141:8082/rainbowmaster";

export const agent_end_point_url = process.env.REACT_APP_BACKEND_API_PATH;
// export const agent_end_point_url = "http://192.168.0.141:8081/rainbowbackend";

export const superadminApiPath = "https://myhero.co.in/rainbowsuperadmin";
export const pageNo = 10;
export const folder_path = process.env.REACT_APP_PATH;
// export const roles = {
// 	ZM: "Zonal Sales Manager",
// 	ASM: "Area Sales Manager",
// 	RM: "Relationship Manager",
// 	SE: "Tele Sales Support Executive",
// };

export const ReportConfig = {
	url: process.env.REACT_APP_BASE_URL,
	// url: "http://192.168.0.141:8084",
};
