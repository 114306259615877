import React, { Fragment, useEffect, useReducer } from "react";

import { UsersService } from "../../../../services/UsersService";

import { useSelector } from "react-redux";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import Config from "../../../../shared/config";
import { Panel } from "primereact/panel";
import RmDetailsComponent from "./RmDetailsComponent";
import AsmDetails from "./AsmDetails";
import { Skeleton } from "primereact/skeleton";

// import { Chip } from "primereact/chip";
import RsmDetails from "./RsmDetails";

const Treeview = () => {
	const userservice = new UsersService();
	const userDetails = useSelector((state) => state.persistData.auth.user);

	const [userState, userDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);

	const getData = () => {
		userDispatch({ type: Config.FETCH_CONFIG.start });
		userservice
			.getAgentProfile()
			.then((res) => {
				userDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((e) => console.log(e))
			.finally(() => userDispatch({ type: Config.FETCH_CONFIG.stop }));
	};
	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="py-5">
			{userState.isLoading ? (
				<>
					<Skeleton width="100%" className="mb-2" height="2rem" />
					<Skeleton width="100%" className="mb-2" height="2rem" />
					<Skeleton width="100%" className="mb-2" height="2rem" />
					<Skeleton width="100%" height="2rem" />
				</>
			) : (
				<>
					{" "}
					{(function (e) {
						switch (e) {
							case 1:
								return userState.data.map((item) => (
									<Panel
										key={item.id}
										header={`${
											item.firstName ? item.firstName : ""
										} ${
											item.middleName
												? item.middleName
												: ""
										} ${
											item.lastName ? item.lastName : ""
										}-(${
											item.areaList &&
											item.areaList.map((e, i) => {
												return e.name + " ";
											})
										})`}
										toggleable
										collapsed
									>
										<RsmDetails userName={item.userName} />
									</Panel>
								));
							case 2:
								return userState.data.map((item) => (
									<Panel
										key={item.id}
										header={`${
											item.firstName ? item.firstName : ""
										} ${
											item.middleName
												? item.middleName
												: ""
										} ${
											item.lastName ? item.lastName : ""
										}-(${
											item.stateName ? item.stateName : ""
										})`}
										toggleable
										collapsed
									>
										<AsmDetails userName={item.userName} />
									</Panel>
								));
							case 3:
								return userState.data.map((item) => (
									<Panel
										key={item.id}
										header={`${
											item.firstName ? item.firstName : ""
										} ${
											item.middleName
												? item.middleName
												: ""
										} ${
											item.lastName ? item.lastName : ""
										} - (${
											item.areaList &&
											item.areaList.map((e) => {
												return e.name + " ";
											})
										})`}
										toggleable
										collapsed
									>
										<RmDetailsComponent
											userName={item.userName}
										/>
									</Panel>
								));
							// case 4:
							// 	return userState.data.map((item) => (
							// 		<Panel
							// 			key={item.id}
							// 			header={`${
							// 				item.firstName ? item.firstName : ""
							// 			} ${
							// 				item.middleName
							// 					? item.middleName
							// 					: ""
							// 			} ${
							// 				item.lastName ? item.lastName : ""
							// 			}-(${
							// 				item.stateName ? item.stateName : ""
							// 			})`}
							// 			toggleable
							// 			collapsed
							// 		>
							// 			<AsmDetails userName={item.userName} />
							// 		</Panel>
							// 	));

							default:
								return <></>;
						}
					})(userDetails.role.position)}
				</>
			)}
		</div>
	);
};

export default Treeview;
