import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import Header from "../../institutions/header";
import { useState } from "react";
import { Divider } from "primereact/divider";
import FormRender from "./components/FormRender";
import CommisionStructure from "./components/CommisionStructure";
import { useSelector } from "react-redux";
import Offers from "./components/Offers";
import HandleCommission from "./components/HandleCommission";
import HandleOffer from "./components/HandleOffer";

const ProgramPage = () => {
	const { state: locationData } = useLocation();
	const navigate = useNavigate();
	const selector = useSelector((state) => state.program);

	const [stages, setStages] = useState([
		{
			sequence: 1,
			title: "Select Institute",
			status: "success",
		},
		{
			sequence: 2,
			title: "Enter Details",
			status: "active",
		},

		{
			sequence: 3,
			title: "Save Commissions",
		},
		{
			sequence: 4,
			title: "Save Offers",
		},
	]);
	return (
		<>
			<Button
				className="p-button-text "
				label="Back"
				icon={"pi pi-angle-left"}
				onClick={() => navigate(-1)}
			/>
			<Header
				stages={locationData?.programId ? [] : []}
				title="Program"
				stageName={(function () {
					switch (locationData?.programData?.productId?.alias) {
						case "PL":
							return "Personal Loan Program";
						case "CC":
							return "Credit Card Program";
						case "HL":
							return "Home Loan Program";
						case "LAP":
							return "Loan Against Property ";
						case "BL":
							return "Business Loan Program";
						case "VL":
							return "Vehicle Loan Program";
						case "HC":
							return "Hospicash Program";
						case "TIP":
							return "Term Insurance";
						case "RIP":
							return "Retirement Plan";
						case "INDIV":
							return "Indivisual Insurance";
						default:
							return "Program";
					}
				})()}
			/>
			<Divider className="mt-6" />
			{selector?.tabIndex === 0 ? (
				<FormRender locationData={locationData} setStages={setStages} />
			) : selector?.tabIndex === 1 ? (
				<HandleCommission
					programId={locationData?.programId}
					type={locationData?.type}
				/>
			) : selector?.tabIndex === 2 ? (
				<HandleOffer
					type={locationData?.type}
					programId={locationData?.programId}
				/>
			) : (
				<></>
			)}
		</>
	);
};

export default ProgramPage;
