import { Field } from "formik";
import { useReducer } from "react";
import { FeildCalender, FeildDropdown } from "./FieldInput";
import { produce } from "immer";
import { ZoneService } from "../services/ZoneService";
import { useGetZoneQuery } from "../services/query/queryApi";
const SearchFieldGroup = ({
	values,
	setFieldValue,
	zoneField,
	stateField,
	districtField,
	fromDate,
	toDate,
}) => {
	const zoneService = new ZoneService();
	const { data: zone = [], isLoading } = useGetZoneQuery();
	const dataReducer = produce((state, action) => {
		switch (action.type) {
			// case "zone":
			// 	state.zone = action.payload;
			// 	break;
			case "state":
				state.state = action.payload;
				break;
			case "district":
				state.district = action.payload;
				break;
			default:
				return state;
		}
	});
	const initialValue = { state: [], district: [] };
	const [searchData, setsearchData] = useReducer(dataReducer, initialValue);
	// const getZone = () => {
	// 	zoneService
	// 		.getZone()
	// 		.then((res) => setsearchData({ type: "zone", payload: res }))
	// 		.catch((er) => {});
	// };
	// useEffect(() => {
	// 	let subscribed = true;
	// 	if (subscribed) {
	// 		getZone();
	// 	}
	// 	return () => {
	// 		subscribed = false;
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);
	const handleZoneChange = (e, setFieldValue) => {
		setFieldValue(zoneField, e.value, true);
		setFieldValue(stateField, "", true);
		zoneService
			.getStateListByZone(e.value)
			.then((res) => setsearchData({ type: "state", payload: res }))
			.catch((er) => {});
	};
	const handleStateChange = (e, setFieldValue) => {
		setFieldValue(stateField, e.value, true);
		setFieldValue(districtField, "", true);
		zoneService
			.getDistricByState(e.value)
			.then((res) => setsearchData({ type: "district", payload: res }))
			.catch((er) => {});
	};
	return (
		<>
			{fromDate && (
				<div className="field col-12 md:col-3">
					<Field
						header={"From date"}
						name={fromDate}
						component={FeildCalender}
					/>
				</div>
			)}
			{toDate && (
				<div className="field col-12 md:col-3">
					<Field
						header={"To date"}
						name={toDate}
						component={FeildCalender}
						minDate={
							values[fromDate] ? values[fromDate] : new Date(null)
						}
					/>
				</div>
			)}
			{!isLoading && (
				<div className="field col-12 md:col-3">
					<Field
						type={"number"}
						component={FeildDropdown}
						header={"Zone"}
						value={values[zoneField]}
						onChange={(e) => handleZoneChange(e, setFieldValue)}
						name={zoneField}
						optionLabel="name"
						options={zone}
						optionValue={"id"}
					/>
				</div>
			)}
			{/* {values[zoneField] && values[zoneField] && ( */}
			<div className="field col-12 md:col-3">
				<Field
					type={"number"}
					filter
					header={"State"}
					name={stateField}
					value={values[stateField]}
					onChange={(e) => handleStateChange(e, setFieldValue)}
					component={FeildDropdown}
					options={searchData.state}
					optionLabel="name"
					optionValue={"id"}
				/>
			</div>
			{/* )} */}
			{/* {values[stateField] && values[stateField] && ( */}
			<div className="field col-12 md:col-3">
				<Field
					type={"number"}
					header={"District"}
					filter
					name={districtField}
					component={FeildDropdown}
					options={searchData.district}
					optionLabel="name"
					optionValue={"id"}
				/>
			</div>
			{/* )} */}
		</>
	);
};

export default SearchFieldGroup;
