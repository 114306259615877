import React, { useEffect, useReducer, useRef, useState } from "react";
import CampaignSearch from "./component/CampaignSearch";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import moment from "moment";
import { Button } from "primereact/button";
import {
	searchInitialState,
	searchReducer,
} from "../../../components/searchReducer";
import Config from "../../../shared/config";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import { CampaignService } from "../../../services/CampaignService";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import Paginator from "../../../components/Paginator";
import TableLoader from "../../../components/TableLoader";
import { Dialog } from "primereact/dialog";
import MenuComponent from "../../../components/MenuComponent";
import { useGetProductDetailsQuery } from "../../../services/query/queryApi";
const Campaign = () => {
	const toast = useRef(null);
	const [loader, setLoader] = useState(false);
	const navigate = useNavigate();
	const { data = { data: [] }, isSuccess } = useGetProductDetailsQuery({
		searchRequest: [],
		pageNo: 1,
		pageSize: 10000,
		sorts: [],
	});
	// console.log(data);
	const [dialog, setDialog] = useState(false);
	const [id, setid] = useState({ id: null, status: null });
	const campaignService = new CampaignService();
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const [state, dispatch] = useReducer(dataReducer, {
		data: {},
		isLoading: false,
		error: false,
		errorMessage: null,
		isFetched: false,
	});
	const getLoanData = () => {
		dispatch({ type: Config.FETCH_CONFIG.start });

		const body = {
			...searchState.searchValue,

			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.sortByColumn &&
				searchState.sortType && {
					sorts: [
						searchState.sortByColumn + "," + searchState.sortType,
					],
				}),
		};
		const demo = Object.fromEntries(
			Object.entries(body).filter(([_, v]) => v !== "")
		);

		campaignService
			.getCampaignList(demo)
			.then((res) => {
				// console.log(res.data);
				dispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
				dispatch({
					type: Config.FETCH_CONFIG.fetched,
				});
			})
			.catch((e) => {
				dispatch({ type: Config.FETCH_CONFIG.error, payload: e });
				toast.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => dispatch({ type: Config.FETCH_CONFIG.stop }));
	};

	useEffect(() => {
		getLoanData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="Campaign Name" />
				<Column header="Frequency" />
				<Column header="Start Date" />
				<Column header="End Date" />
				<Column header="Status" />
				<Column header="Short Description" />
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const statusTemplate = (item) => {
		switch (item.status) {
			case "No":
				return (
					<span className="py-1 px-3 border-round-md bg-red-200 text-red-800">
						No
					</span>
				);

			case "Generated":
				return (
					<span className="py-1 px-3 border-round-md bg-green-200 text-green-800">
						Generated
					</span>
				);
			case "In_Process":
				return (
					<span className="py-1 px-3 border-round-md bg-yellow-200 text-yellow-800">
						In Process
					</span>
				);
			default:
				return <></>;
		}
	};
	const startDateFormat = (item) =>
		moment(item.startDate).format("DD MMM, YYYY");
	const endDateFormat = (item) => moment(item.endDate).format("DD MMM, YYYY");
	const handleStatus = () => {
		setLoader(true);
		campaignService
			.changeCampIgnStatus(id.id)
			.then(() => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "Saved",
					style: { color: "#000000" },
					life: 3000,
				});
				getLoanData();
			})
			.catch((err) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Update failed",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoader(false);
				setDialog(false);
			});
	};
	const editTemplate = (item) => {
		const items = [
			{
				label: "Edit",
				icon: "pi pi-pencil",
				command: () => {
					navigate(`/masters/campaign/edit/${item.id}`);
				},
			},
			{
				label: "Lead",
				icon: "pi pi-caret-right",
				command: () => {
					if (item.status !== "Generated") {
						setid({ id: item.id, status: item.status });
						setDialog(true);
					} else {
						navigate(
							`/masters/campaign/lead-by-campaign/${item.id}`
						);
					}
				},
			},
		];
		return <MenuComponent items={items} />;
	};
	const increment = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const pagesizechange = (e) => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};
	const itaration = {
		a: 1,
		b: 1,
		c: 3,
		d: 4,
		e: 5,
		f: 6,
		j: 7,
	};

	return (
		<>
			<Toast ref={toast} />
			<Dialog
				header={"Genereate Lead"}
				visible={dialog}
				onHide={() => setDialog(false)}
				style={{ width: "30%" }}
			>
				<div className="modal-form-grid flex align-items-center justify-content-center pt-5">
					{id.status === "In_Process" ? (
						<p>In Progress</p>
					) : (
						<Button
							loading={loader}
							label="Genereate Lead"
							onClick={() => handleStatus()}
						/>
					)}
				</div>
			</Dialog>
			<div className="grid">
				<div className="col-12">
					<CampaignSearch searchDispatch={searchDispatch} />
				</div>
				<div className="col-12">
					{state.isLoading ? (
						<TableLoader
							itaration={itaration}
							headerGroup={headerGroup}
						/>
					) : (
						state.isFetched && (
							<>
								{state.data?.data.length > 0 && (
									<Paginator
										pageSize={state.data?.pageSize}
										firstPage={state.data?.firstPage}
										lastPage={state.data?.lastPage}
										decrement={decrement}
										increment={increment}
										pagesizechange={pagesizechange}
										pageNo={state.data?.pageNo}
										totalPages={state.data?.totalPages}
										totalElements={
											state.data?.totalElements
										}
									/>
								)}
								<DataTable
									headerColumnGroup={headerGroup}
									value={state.data?.data}
									responsiveLayout="scroll"
								>
									<Column field={"name"} />
									<Column field={"frequency"} />
									<Column body={startDateFormat} />
									<Column body={endDateFormat} />
									<Column body={statusTemplate} />
									<Column field={"shortDescription"} />
									<Column body={editTemplate} />
								</DataTable>
								{state.data?.data.length > 10 && (
									<Paginator
										pageSize={state.data?.pageSize}
										firstPage={state.data?.firstPage}
										lastPage={state.data?.lastPage}
										decrement={decrement}
										increment={increment}
										pagesizechange={pagesizechange}
										pageNo={state.data?.pageNo}
										totalPages={state.data?.totalPages}
										totalElements={
											state.data?.totalElements
										}
									/>
								)}
							</>
						)
					)}
				</div>
			</div>
		</>
	);
};

export default Campaign;
