import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Logo from "../../../../assets/images/logo_main.png";
import moment from "moment";
import { useGetAllDIstrictQuery } from "../../../../services/query/queryApi";
import { getDistrict, getState } from "../../../../components/stateList";
const PrintHospicash = ({ data, planDetails }) => {
	// console.log(props);
	const { data: stateList = [], isLoading } = useGetAllDIstrictQuery();
	const plan = planDetails.find((item) => item.id === data.planId);
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});
	const getPageMargins = () => {
		return `
		@page { margin: ${40} ${40} ${40} ${40} !important; 
	}
	
		`;
	};
	return (
		<>
			<Button
				label="Print this out"
				icon={"pi pi-print"}
				onClick={handlePrint}
			/>
			<div
				ref={componentRef}
				className="grid w-full p-4 align-items-center"
			>
				<style>{getPageMargins()}</style>
				<div className="col-6">
					<h2 className="mb-2 mt-0">{data.applicantName}</h2>
					<h5 className="mb-2 mt-0">{data.mobileNumber}</h5>

					<small className="block font-bold mb-1">
						{data.program.productId.title}
					</small>
				</div>
				<div className="col-6">
					<img
						src={Logo}
						alt="logo"
						style={{
							width: "150px",
							marginLeft: "auto",
							display: "block",
						}}
					/>
				</div>
				<div className="col-12 grid">
					<Divider align={"left"}>
						<h3 className="col-12 mb-0 mt-0">Plan Details</h3>
					</Divider>
					<div className="col-6">
						{plan !== undefined && (
							<>
								<h4 className="mb-0">{plan.name}</h4>
								<ul className="p-0 listed">
									<li>
										First Year Adult: ₹{plan.firstYearAdult}
									</li>
									<li>
										First Year Kid: ₹{plan.firstYearKid}
									</li>
									<li>
										Second Year Adult: ₹
										{plan.secondYearAdult}
									</li>
									<li>
										Second Year Kid: ₹{plan.secondYearKid}
									</li>
								</ul>
							</>
						)}
					</div>
					<div className="col-6">
						<h5 className="mb-0">Select Policy Terms Years</h5>
						<small>{data.policyTerm}</small>
						<h5 className="mb-0">GSC Sum Insured</h5>
						<small>
							₹{" "}
							{plan
								? plan.insuredValue *
								  (1 +
										data.coApplicant.length +
										data.kidApplicantList.length)
								: "0"}
						</small>
						<h5 className="mb-0">Premium Amount</h5>
						<small>
							₹{" "}
							{data.policyTerm && plan
								? data.policyTerm === 2
									? plan.secondYearAdult *
											(1 + data.coApplicant.length) +
									  plan.secondYearKid *
											data.kidApplicantList.length
									: plan.firstYearAdult *
											(1 + data.coApplicant.length) +
									  plan.firstYearKid *
											data.kidApplicantList.length
								: "0"}
						</small>
					</div>
				</div>
				<div className="col-12 grid">
					<Divider align={"left"}>
						<h3 className="col-12 mb-0 mt-0">
							Application Details
						</h3>
					</Divider>
					<div className="col-3">
						<h5 className="mb-0 ">Branch</h5>
						<small>{data.branch}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Unique Id</h5>
						<small>{data.uniqueId}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Unique Identification Number</h5>
						<small>{data.uniqueIdentificationNumber}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0 ">Enrollment Date</h5>
						<small>
							{data.enrollmentDate !== ""
								? moment(data.enrollmentDate).format(
										"Do MMM, YYYY"
								  )
								: ""}
						</small>
					</div>

					<div className="col-3">
						<h5 className="mb-0">Gender</h5>
						<small>{data.gender}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">DOB</h5>
						<small>
							{data.dob !== ""
								? moment(data.dob).format("Do MMM, YYYY")
								: ""}
						</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">PAN</h5>
						<small>{data.pan}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Profession</h5>
						<small>{data.profession}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">
							Relationship With Primary Insured
						</h5>
						<small>{data.relationshipWithPrimaryInsured}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Address</h5>
						<small>{data.address}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">City</h5>
						<small>{data.city}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">State</h5>
						{!isLoading && (
							<small>
								{data.state &&
									stateList &&
									getState(stateList, data.state)().name}
							</small>
						)}
					</div>
					<div className="col-3">
						<h5 className="mb-0">District</h5>
						{!isLoading && (
							<small>
								{data.district &&
									stateList &&
									getState(stateList, data.state)() &&
									getDistrict(
										getState(stateList, data.state)()
											.districtList,
										data.district
									)().name}
							</small>
						)}
					</div>
					<div className="col-3">
						<h5 className="mb-0">Pincode</h5>
						<small>{data.pincode}</small>
					</div>

					<div className="col-3">
						<h5 className="mb-0">Email</h5>
						<small>{data.emailId}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Kid Nominee Name</h5>
						<small>{data.kidNomineeName}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Kids Gender</h5>
						<small>{data.kidsGender}</small>
					</div>
					<div className="col-3">
						<h5 className="mb-0">Relationship With Kid</h5>
						<small>{data.relationshipWithKid}</small>
					</div>
				</div>
				<div className="col-12 grid">
					<Divider align={"left"}>
						<h3 className="col-12 mb-0 mt-0">
							Co-Applicant Details
						</h3>
					</Divider>
					{data.coApplicant.map((item, id) => (
						<div className="col-12 grid" key={id.toString()}>
							<h4 className="col-12 mb-0 mt-0">
								Co-Applicant {id + 1}
							</h4>
							<div className="col-3">
								<h5 className="mb-0">Co-Applicant Name</h5>
								<small>{item.coApplicantName}</small>
							</div>
							<div className="col-3">
								<h5 className="mb-0">Co-Applicant DOB</h5>
								<small>
									{item.coApplicantDob !== ""
										? moment(item.coApplicantDob).format(
												"Do MMM, YYYY"
										  )
										: ""}
								</small>
							</div>
							<div className="col-3">
								<h5 className="mb-0">
									Relationship With Applicant
								</h5>
								<small>
									{item.relationshipWithApplicant.toLowerCase()}
								</small>
							</div>
							<div className="col-3">
								<h5 className="mb-0">Mobile Number</h5>
								<small>{item.mobileNumber}</small>
							</div>
							<div className="col-3">
								<h5 className="mb-0">Email</h5>
								<small>{item.emailId}</small>
							</div>
							<div className="col-3">
								<h5 className="mb-0">
									Co-Applicant Nominee Name
								</h5>
								<small>{item.coApplicantNomineeName}</small>
							</div>
							<div className="col-3">
								<h5 className="mb-0">
									Co-Applicant Nominee Gender
								</h5>
								<small>{item.coApplicantNomineeGender}</small>
							</div>
							<div className="col-3">
								<h5 className="mb-0">
									Co-Applicant Nominee DOB
								</h5>
								<small>
									{item.coApplicantNomineeDob !== ""
										? moment(
												item.coApplicantNomineeDob
										  ).format("Do MMM, YYYY")
										: ""}
								</small>
							</div>
							<div className="col-3">
								<h5 className="mb-0">
									Co-Applicant Nominee Relationship
								</h5>
								<small>
									{item.coApplicantNomineeRelationship.toLowerCase()}
								</small>
							</div>
						</div>
					))}
				</div>
				<div className="col-12 grid">
					<Divider align={"left"}>
						<h3 className="col-12 mb-0 mt-0">
							Kid Application Details
						</h3>
					</Divider>
					{data.kidApplicantList.map((item, id) => (
						<div className="col-12 grid" key={id.toString()}>
							<h4 className="col-12 mb-0 mt-0">
								Details {id + 1}
							</h4>
							<div className="col-3">
								<h5 className="mb-0">Name</h5>
								<small>{item.kidName}</small>
							</div>
							<div className="col-3">
								<h5 className="mb-0">DOB</h5>
								<small>
									{item.dob !== ""
										? moment(item.dob).format(
												"Do MMM, YYYY"
										  )
										: ""}
								</small>
							</div>
							<div className="col-3">
								<h5 className="mb-0">
									Relationship With Applicant
								</h5>
								<small>{item.relationshipWithApplicant}</small>
							</div>
							<div className="col-3">
								<h5 className="mb-0">Mobile Number</h5>
								<small>{item.mobileNumber}</small>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default PrintHospicash;
