import AgentLeadList from "./components/AgentLeadList";
import DailyPerformanceStacked from "./components/DailyPerformanceStacked";
import LeadFunnel from "./components/LeadFunnel";
import AgentLeadDetails from "./components/AgentLeadDetails";
import ApplicationTab from "./components/ApplicationTab";
import ActiveAgentApplicationLeadCount from "./components/ActiveAgentApplicationLeadCount";
import TableDesign from "./components/TableDesign";
import { useEffect, useState } from "react";
import { CallCenterService } from "../../../services/callCenterService";

const CountryDashboard = () => {
	const [tsrmlist, setTsrmlist] = useState([]);
	const ccservice = new CallCenterService();

	useEffect(() => {
		ccservice
			.getTsrmByHubIdspokeId({
				pageNo: 1,
				pageSize: 1000000,
				hubId: null,
				spokeId: null,
			})
			.then((res) => setTsrmlist(res?.data));
	}, []);
	return (
		<>
			<AgentLeadDetails tsrmlist={tsrmlist} />
			<LeadFunnel tsrmlist={tsrmlist} />
			<div className=" grid mt-3">
				<div className="lg:col-8 md:col-12 col-12 ">
					<div className="c-agent-box-1">
						<h5 className="mt-0 text-center">
							TSRM Daily Performance on Agent Lead
						</h5>
						<DailyPerformanceStacked tsrmlist={tsrmlist} />
					</div>
				</div>
				<div className="lg:col-4 md:col-12 col-12">
					<div className="c-agent-box-1">
						<h5 className="mt-0 text-center">New Agent Lead</h5>
						<AgentLeadList tsrmlist={tsrmlist} />
					</div>
				</div>
			</div>
			<div className="grid">
				<div className="col-12 mt-3">
					<div className="c-agent-box-1 c-agent-lead-w">
						<ApplicationTab tsrmlist={tsrmlist} />
					</div>
				</div>
			</div>
			<div className="grid">
				<div className="col-12 mt-3">
					<div className="c-agent-box-1 c-agent-lead-w">
						<ActiveAgentApplicationLeadCount tsrmlist={tsrmlist} />
					</div>
				</div>
			</div>

			<TableDesign />
		</>
	);
};

export default CountryDashboard;
