import React from "react";
import { FieldMuiliCheckBox } from "./FieldInput";
import { Field, FieldArray } from "formik";
import { Button } from "primereact/button";

const MultiCheckBox = (props) => {
	return (
		<div className="col-12 grid w-full">
			<FieldArray
				name={props.name}
				render={(arrayHelpers) =>
					props.checkboxData.map((item, index) => (
						<div key={index} className="col-3 md:col-3 ">
							<Field
								header={item.label}
								component={FieldMuiliCheckBox}
								value={item.value}
								checked={props.values[props.name].includes(
									item.value
								)}
								onChange={(e) => {
									if (e.target.checked) {
										arrayHelpers.push(item.value);
									} else {
										const idx = props.values[
											props.name
										].indexOf(item.value);
										arrayHelpers.remove(idx);
									}
								}}
								name={`${props.name}.${index}`}
							/>
						</div>
					))
				}
			/>
		</div>
	);
};

export default MultiCheckBox;
