import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import React, { useEffect, useState } from "react";

const Preview = (props) => {
	const [tableData, setTableData] = useState([]);

	useEffect(() => {
		let modifyData =
			props.data &&
			props.data.map((item, index) => ({
				...item,
				index: 1 + index,
			}));
		props.submitData(modifyData);
		setTableData(modifyData);
	}, []);
	const handleUpload = () => {
		props.handelStages();
	};
	const handleBack = () => {
		props.handleBack();
	};
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />

				<Column header="Name" />
				<Column header="Mobile No." />

				<Column header="State" />
				<Column header="City" />

				<Column header="Email" />
				<Column header="Source" />
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const deleteApplication = (e) => {
		const filterData = tableData.filter((item) => item.index !== e.index);
		setTableData(filterData);
		props.submitData(filterData);
	};
	const actionTemplate = (item) => {
		return (
			<>
				<Button
					type="button"
					onClick={() => deleteApplication(item)}
					// style={{
					// 	marginTop: "1.5rem",
					// }}
					icon="pi pi-trash"
					className="p-button-text p-button-danger icon-btn"
					aria-label="Cancel"
				/>
			</>
		);
	};
	const getName = (item) => {
		let fname = item.firstName ? item.firstName : "";
		let mname = item.middleName ? item.middleName : "";
		let lname = item.lastName ? item.lastName : "";
		let name = `${fname} ${mname} ${lname}`;
		if (!item.firstName && !item.middleName && !item.lastName) {
			return item.applicantName;
		} else {
			return name;
		}
	};

	return (
		<>
			<div>
				<h4 className="text-center mt-6">
					Only the first 50 rows are displayed below
				</h4>
				<DataTable
					headerColumnGroup={headerGroup}
					value={tableData}
					responsiveLayout="scroll"
					breakpoint="960px"
				>
					<Column field="index" />

					<Column body={getName} />
					<Column field="mobileNumber" />
					<Column field="state" />

					<Column field="city" />
					<Column field="email" />
					<Column field="source" />
					<Column body={actionTemplate} />
				</DataTable>
			</div>
			<div className="flex justify-content-end gap-2 pt-3">
				<Button
					icon="pi pi-angle-left"
					label="Back"
					onClick={handleBack}
				/>
				<Button
					icon="pi pi-angle-right"
					label="Save"
					onClick={handleUpload}
				/>
			</div>
		</>
	);
};

export default Preview;
