import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import React, { useRef, useState } from "react";

// import FileSaver from "file-saver";

import { Toast } from "primereact/toast";
import Loader from "../../../../../components/Loader";
// import { LoanApplicationService } from "../../../../../services/LoanApplication";
import { LeadService } from "../../../../../services/LeadService";
import { agent_end_point_url } from "../../../../../shared/constant";

const UploadFile = (props) => {
	const toast = useRef(null);
	const [fileData, setFileData] = useState(null);
	const leadService = new LeadService();
	const [loading, setLoading] = useState(false);

	const onUploadImage = (e) => {
		setLoading(true);
		let excelFile = e.files[0];
		setFileData(excelFile);
		const formData = new FormData();
		formData.append("file", excelFile);
		leadService
			.bulkLeadCreation(formData)
			.then((res) => {
				console.log(res);
				toast.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});
				props.excelData(res);
				setLoading(false);
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
				setLoading(false);
			});
	};

	const handleUpload = () => {
		props.handelStages();
	};

	const templateDownload = () => {
		leadService.downloadLeadExcel().then((res) => {
			console.log(res);
			if (res) {
				const a = document.createElement("a");
				a.href = `${agent_end_point_url}/lead/generate-excel`;
				a.download = "LeadList";
				a.click();
			}
		});
		// FileSaver.saveAs(`${apiPath}/lead/generate-excel`);
	};
	return (
		<>
			<div>
				{loading && <Loader />}
				<Toast ref={toast} position="top-left" />
				<div className="c-download-pw">
					<h4>Steps:</h4>
					<ol>
						<li>
							Download the template shown below and save it to
							your computer
						</li>
						<li>
							Enter the data in the template (do not change any
							column heading)
						</li>
						<li>Upload the file from your computer and save</li>
					</ol>
					<div className="c-download-btn">
						<span className="c-download-text">
							Download Lead Template
						</span>{" "}
						{/* <Button icon="pi pi-file-excel" /> */}
						{/* <Button icon="pi pi-download" className="ml-2" /> */}
						<div className="c-excel-icon">
							<i className="pi pi-file-excel"></i>
						</div>
						<Button
							icon="pi pi-download"
							className="p-button-rounded p-button-info p-button-text"
							onClick={() => templateDownload()}
						/>
					</div>
				</div>
				<FileUpload
					customUpload
					// accept=".xlsx,.docx,"
					uploadHandler={(e) => onUploadImage(e)}
					maxFileSize={1000000}
					emptyTemplate={
						<p className="m-0">
							Drag and drop files to here to upload.
						</p>
					}
				/>
			</div>
			<div className="flex justify-content-end mt-2">
				<Button
					label="Upload"
					onClick={handleUpload}
					disabled={fileData && fileData ? false : true}
				/>
				<Button
					label="Cancel"
					className="p-button-danger ml-2"
					onClick={() => props.handleStatusDialogeClose()}
				/>
			</div>
		</>
	);
};

export default UploadFile;
