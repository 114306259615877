import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import { useRef, useState } from "react";
import { LeadService } from "../../../../../services/LeadService";
import Loader from "../../../../../components/Loader";
import { Toast } from "primereact/toast";
import Config from "../../../../../shared/config";

const Preview = (props) => {
	const toast = useRef(null);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [loader, setLoader] = useState(false);
	const leadService = new LeadService();
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column selectionMode={"multiple"} />
				<Column header="Name" />
				<Column header={"Status"} />
				<Column header="State" />
				<Column header="District" />
				<Column header="Mobile No." />
			</Row>
		</ColumnGroup>
	);

	const getName = (item) => {
		let fname = item.firstName ? item.firstName : "";
		let mname = item.middleName ? item.middleName : "";
		let lname = item.lastName ? item.lastName : "";
		let name = `${fname} ${mname} ${lname}`;
		if (!item.firstName && !item.middleName && !item.lastName) {
			return item.applicantName;
		} else {
			return name;
		}
	};
	const statusTemplate = (item) => {
		switch (item.status) {
			case "FOLLOW_UP":
				return <span className="status status-warning">Follow Up</span>;

			case "NOT_INTERESTED":
				return (
					<span className="status status-danger">Not Interested</span>
				);

			case "NEW":
				return <span className="status status-primary">New</span>;

			case "NOT_ELIGIBLE":
				return (
					<span className="status status-danger-deep">
						Not Eligible
					</span>
				);

			case "CONVERTED":
				return <span className="status status-success">Converted</span>;

			default:
				return "";
		}
	};
	const handleStatus = () => {
		setLoader(true);
		const data = selectedProducts.map((item) => ({
			id: item.id,
			status: item.status,
			errorStatus: false,
			errorMessages: [],
		}));
		leadService
			.bulkLeadStatusUpdate({
				errors: null,
				statusCode: 200,
				bulkUploadLeadStatusRequestList: data,
			})
			.then(() => {
				props.handelStages();
				props.searchDispatch();
			})
			.catch((err) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: err.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoader(false));
	};

	return (
		<>
			<Toast ref={toast} />
			{loader && <Loader />}
			<p>
				<strong>
					<span className="text-red-400">* </span>Select leads to
					process status update (check header checkbox to select all)
				</strong>
			</p>
			<div>
				<DataTable
					scrollable
					scrollHeight="300px"
					headerColumnGroup={headerGroup}
					value={props.listData}
					responsiveLayout="scroll"
					breakpoint="960px"
					selectionMode={"checkbox"}
					selection={selectedProducts}
					onSelectionChange={(e) => setSelectedProducts(e.value)}
					dataKey="id"
				>
					<Column selectionMode="multiple" />
					<Column body={getName} />
					<Column body={statusTemplate} />
					<Column field="stateName" />
					<Column field="districtName" />
					<Column field="mobileNumber" />
				</DataTable>
			</div>
			<div className="pt-2 flex align-items-center justify-content-end">
				<Button
					disabled={selectedProducts.length < 1}
					onClick={() => handleStatus()}
					label="Update"
				/>
			</div>
		</>
	);
};

export default Preview;
