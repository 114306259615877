import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	programId: null,
	tabIndex: 0,
	product: {},
	productType: null,
};

const ProgramSlice = createSlice({
	name: "programslice",
	initialState: initialState,
	reducers: {
		setId: (state, action) => {
			state.programId = action.payload;
		},
		setEnable: (state, action) => {
			state.tabIndex = action.payload;
		},
		setProduct: (state, action) => {
			state.product = action.payload;
		},
		setProductType: (state, action) => {
			state.productType = action.payload;
		},
		reset: (state) => {
			state.programId = null;
			state.product = {};
			state.productType = null;
			state.tabIndex = 0;
		},
	},
});
export const { setId, setEnable, setProduct, setProductType, reset } =
	ProgramSlice.actions;
export default ProgramSlice.reducer;
