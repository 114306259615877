import { useEffect, useState, useRef } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { InstitutionService } from "../../../services/InstitutionService";
import BasicDetails from "./components/BasicDetails";
const InstitutionDetails = ({ refressList, id }) => {
	const toast = useRef(null);
	const institutionService = new InstitutionService();
	const [data, setData] = useState(null);
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		getInstitution(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const getInstitution = (id) => {
		institutionService
			.getInstitutionById(id)
			.then((res) => {
				setLoading(false);
				setData(res);
			})
			.catch((e) => {
				setLoading(false);
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			});
	};
	const updateBasicDetails = (req) => {
		let payload = {
			searchRequest: [],
			pageNo: 1,
			pageSize: 10,
			sorts: [],
		};
		setLoading(true);
		institutionService
			.updateInstitutionBasicDetails(req)
			.then((res) => {
				if (res) {
					getInstitution(id);
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: "Details Updated",
						life: 3000,
					});
					refressList(payload);
				}
			})
			.catch((e) => {
				setLoading(false);
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			});
	};

	return (
		<>
			<Toast ref={toast} />
			<div className="flex card modal-form-grid">
				{isLoading && !data ? (
					<ProgressSpinner
						style={{
							width: "50px",
							height: "50px",
							marginTop: "1rem",
							textAlign: "center",
						}}
						strokeWidth="4"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				) : (
					data && (
						<BasicDetails
							data={data.institutionDetail}
							id={id}
							handelBasicDetails={updateBasicDetails}
							isLoading={isLoading}
						/>
					)
				)}
			</div>
		</>
	);
};

export default InstitutionDetails;
