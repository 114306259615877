import React, {
	useEffect,
	useReducer,
	useRef,
	useState,
	Suspense,
	Fragment,
} from "react";
import { Checkbox } from "primereact/checkbox";
import { produce } from "immer";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { useGetProductDetailsQuery } from "../../../../services/query/queryApi";
import { AgentService } from "../../../../services/AgentService";
import Loader from "../../../../components/Loader";
const EditProduct = ({ agentEdit }) => {
	const toast = useRef(null);
	const [spinner, setSpinner] = useState(false);
	const agentService = new AgentService();
	const { data: productList = { data: [] } } = useGetProductDetailsQuery({
		searchRequest: [],
		pageNo: 1,
		pageSize: 330,
		sorts: [],
	});
	const productReducer = produce((state, action) => {
		switch (action.type) {
			case "ini":
				state.checkedData = action.data;
				break;
			case "edit":
				const isPresent = state.checkedData.find(
					(id) => id === action.id
				);
				if (isPresent === undefined) {
					state.checkedData.push(action.id);
				} else {
					state.checkedData = state.checkedData.filter(
						(id) => id !== action.id
					);
				}
				break;
			default:
				return state;
		}
	});
	const [productState, productDispatch] = useReducer(productReducer, {
		checkedData: [],
	});
	const onCategoryChange = (e) => {
		productDispatch({ type: "edit", id: e.value });
	};
	const reHydrateData = (e) => {
		const newArray = e.flatMap((item) =>
			item.subProducts.map((id) => id.id)
		);
		return newArray;
	};
	useEffect(() => {
		let subscribe = true;
		if (agentEdit.products && subscribe) {
			const data = reHydrateData(agentEdit.products);
			productDispatch({ type: "ini", data: data });
		}
		return () => {
			subscribe = false;
		};
	}, [agentEdit]);
	const updateData = () => {
		const data = {
			profileId: agentEdit.profile.id,
			productIds: productState.checkedData,
			profileType: agentEdit.profile.profileType,
			workOnGivenLead: true,
		};
		setSpinner(true);
		agentService
			.agentDetailsUpdate(data)
			.then(() => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "Updated Successfully",
					life: 3000,
				});
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			})
			.finally(() => setSpinner(false));
	};

	return (
		<>
			{spinner && <Loader />}
			<Toast ref={toast} position="top-left" />
			<Suspense
				fallback={
					<div className="flex align-items-center justify-content-center">
						<ProgressSpinner
							style={{ width: "30px", height: "30px" }}
							strokeWidth="5"
							fill="var(--surface-ground)"
							animationDuration=".5s"
						/>
					</div>
				}
			>
				<div className="grid p-fluid">
					{productList.data.map(
						(item, index) =>
							item.isActive && (
								<div
									key={`${index}`}
									className="col-12 md:col-6"
								>
									<Divider align={"left"}>
										<h4 className="m-0">{item.title}</h4>
									</Divider>
									{item.subProducts.map(
										(category) =>
											category.isActive && (
												<div
													key={category.id}
													className="p-inputgroup py-1"
												>
													<span className="p-inputgroup-addon">
														<Checkbox
															value={category.id}
															onChange={
																onCategoryChange
															}
															checked={productState.checkedData.some(
																(item) =>
																	item ===
																	category.id
															)}
														/>
													</span>
													<InputText
														value={category.title}
														readOnly
													/>
												</div>
											)
									)}
								</div>
							)
					)}
					<Divider />
					<div className="flex">
						<Button
							label="Save"
							icon="pi pi-save"
							className="primary-btn"
							onClick={() => updateData()}
						/>
					</div>
				</div>
			</Suspense>
		</>
	);
};

export default EditProduct;
