import { ConnectedFocusError } from "focus-formik-error";
import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";

import { Button } from "primereact/button";

import axios from "axios";
import { Toast } from "primereact/toast";
import { AgentService } from "../../../../services/AgentService";
import { FeildDropdown, FieldInput } from "../../../../components/FieldInput";
import Config from "../../../../shared/config";

const EditBankDetails = ({ agentEdit, dialogeClose }) => {
	const agentService = new AgentService();
	const toastTL = useRef(null);
	const [loading, setLoading] = useState(false);

	const ifscCheck = (e, setFieldValue) => {
		setFieldValue("ifsc", e, true);
		if (e.length === 11) {
			axios.get(`https://ifsc.razorpay.com/${e}`).then((res) => {
				setFieldValue("bankName", res.data.BANK, true);
				setFieldValue("micr", res.data.MICR, true);
				setFieldValue("ifsc", res.data.IFSC, true);
				setFieldValue("bankAddress", res.data.ADDRESS, true);
			});
		}
	};

	const handleFormSubmit = (e) => {
		setLoading(true);
		let requestBody = {
			profileId: agentEdit.profile.id,
			accountNumber: e.accountNumber,
			accountType: e.accountType,
			bankAddress: e.bankAddress,
			bankName: e.bankName,
			name: e.name,
			ifsc: e.ifsc,
			micr: e.micr,
			chequeId: agentEdit.bankDetails.chequeId,
		};

		agentService
			.bankDetailsUpdate(requestBody)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: "Agent Bank Details Update Successfully",
					life: 3000,
				});
				setLoading(false);
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			});
	};
	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			<Formik
				onSubmit={handleFormSubmit}
				initialValues={{ ...agentEdit.bankDetails }}
				enableReinitialize
			>
				{({ values, handleSubmit, setFieldValue }) => (
					<Form onSubmit={handleSubmit}>
						<ConnectedFocusError />

						<div className="form-grid grid">
							<div className="field col-12 md:col-4">
								<Field
									header={" Account Holder Name"}
									name={"name"}
									component={FieldInput}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Account Type"}
									name={"accountType"}
									component={FeildDropdown}
									options={Config.BANK_ACCOUNTTYPE}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Account Number"}
									name={"accountNumber"}
									component={FieldInput}
									type={"number"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"IFSC Code"}
									name={"ifsc"}
									component={FieldInput}
									star={"*"}
									// onChange={ifscCheck}
									onChange={(e) =>
										ifscCheck(e.target.value, setFieldValue)
									}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Bank Name"}
									name={"bankName"}
									component={FieldInput}
									star={"*"}
								/>
							</div>

							<div className="field col-12 md:col-4">
								<Field
									header={"Bank Address"}
									name={"bankAddress"}
									component={FieldInput}
								/>
							</div>

							<div className="field col-12 md:col-4">
								<Field
									header={"MICR"}
									name={"micr"}
									component={FieldInput}
								/>
							</div>
						</div>
						<div className="flex justify-content-end align-items-center w-full">
							<div className="col-12 md:col-3 lg:col-3 mt-3 flex">
								<Button
									type="button"
									label="Cancel"
									icon="pi pi-times"
									className="p-button-danger mr-2"
									onClick={() => dialogeClose(false)}
								/>
								<Button
									type="submit"
									icon="pi pi-save"
									label="Save"
									loading={loading}
								/>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default EditBankDetails;
