import { Suspense, useEffect, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import S3 from "aws-sdk/clients/s3";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { ProgramService } from "../../../../../../services/ProgramService";
import Config from "../../../../../../shared/config";
import Loader from "../../../../../../components/Loader";
import {
	FeildDropdown,
	FieldCheckBox,
	FieldEditor,
	FieldInput,
	FieldMultiselect,
} from "../../../../../../components/FieldInput";
import { InputNode } from "../../../../../../components/InputComponent";
import { useDispatch } from "react-redux";
import {
	setEnable,
	setId,
} from "../../../../../../store/reducer/ProgramReducer";
import { convertObjNull } from "../../../../../../utils/UtilsFunction";
const LoanProgram = (props) => {
	const dispatch = useDispatch();
	const pType = props?.locationData;
	console.log(pType);
	const programService = new ProgramService();
	const toastTL = useRef(null);
	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});
	const [programData, setProgramData] = useState(null);
	const [spinner, setSpinner] = useState(false);
	const getProgram = (id) => {
		if (pType.type === "edit" || pType?.type === "copy") {
			setSpinner(true);
			programService
				.getFullProgramData(id)
				.then((res) => {
					setProgramData(res);
				})
				.catch((e) => {})
				.finally(() => setSpinner(false));
		}
	};
	useEffect(() => {
		pType?.programId && getProgram(pType.programId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const programCreate = (payload) => {
		setSpinner(true);
		programService
			.createProgram(payload)
			.then((res) => {
				dispatch(setId(res?.id));
				dispatch(setEnable(1));
			})
			.catch((error) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setSpinner(false);
			});
	};
	const initialValue = {
		name:
			pType?.programData?.institutionId?.name +
			" " +
			pType?.programData?.productId?.subProduct,
		shortDescription: "",
		longDescription: "",
		eligibilityDetails: "",
		imageFirst: "",
		imageSecond: "",
		profession: "",
		minLoanAmount: "",
		maxLoanAmount: "",
		minProcessingFees: "",
		maxProcessingFees: "",
		minLoanPeriod: "",
		maxLoanPeriod: "",
		minRateOfInterest: "",
		maxRateOfInterest: "",
		isPreClosureCharge: false,
		documentsRequired: "",
		isProcessingFees: false,
	};
	const validationSchema = Yup.object().shape({
		name: Yup.string().required("This Field is Required"),
		eligibilityDetails: Yup.string().required("This Field is Required"),
		documentsRequired: Yup.string().required("This Field is Required"),
		minLoanAmount: Yup.number()
			.typeError("Amount must be a number")
			.min(0, "Invalid Value")
			.required("This Field is Required"),
		maxLoanAmount: Yup.number()
			.typeError("Amount must be a number")
			.min(0, "Invalid Value")
			.when("minLoanAmount", (minLoanAmount, value) => {
				return value.test({
					test: (maxLoanAmount) =>
						!!minLoanAmount && maxLoanAmount > minLoanAmount,
					message: "Max Loan Amount > Min Loan Amount",
				});
			})
			.required("This Field is Required"),
		minLoanPeriod: Yup.number()
			.integer("Invalid Value") //done
			.typeError("Enter a valid number")
			.min(0, "Invalid Value")
			.required("This Field is Required"),
		maxLoanPeriod: Yup.number()
			.integer("Invalid Value") //done
			.typeError("Enter a valid number")
			.min(0, "Invalid Value")
			.when("minLoanPeriod", (minLoanPeriod, value) => {
				return value.test({
					test: (maxLoanPeriod) =>
						!!minLoanPeriod && maxLoanPeriod > minLoanPeriod,
					message: "Max Loan Period > Min Loan Period",
				});
			})
			.required("This Field is Required"),
		minProcessingFees: Yup.number()
			.typeError("Amount must be a number")
			.min(0, "Invalid Value")
			.required("This Field is Required"),
		maxProcessingFees: Yup.number()
			.typeError("Amount must be a number")
			.min(0, "Invalid Value"),
		minRateOfInterest: Yup.number()
			.typeError("Amount must be a number")
			.min(0, "Invalid Value")
			.required("This Field is Required"),
		maxRateOfInterest: Yup.number()
			.typeError("Amount must be a number")
			.min(0, "Invalid Value")
			.when("minRateOfInterest", (minRateOfInterest, value) => {
				return value.test({
					test: (maxRateOfInterest) =>
						!!minRateOfInterest &&
						maxRateOfInterest > minRateOfInterest,
					message: "Max Rate of interest  > Min Rate of interest",
				});
			})
			.required("This Field is Required"),
		profession: Yup.array()
			.of(Yup.string())
			.min(1, "Field cannot be empty")
			.required("Field cannot be empty"),
	});
	const handleFormSubmit = (value, onSubmitProps) => {
		const urlData = pType?.programData;

		const body = convertObjNull({
			...value,
			productId: urlData?.productId?.subProductId,
			institutionId: urlData?.institutionId.id,
			type: urlData?.productId?.alias === "CC" ? "CREDIT_CARD" : "LOANS",
		});
		if (pType?.programId && pType.type === "edit") {
			setSpinner(true);
			programService
				.updateProgram(body)
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Successfull",
						detail: "Updated successfully",
						style: { color: "#000000" },
						life: 3000,
					});
					pType?.programId && getProgram(pType.programId);
				})
				.catch((error) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: "Somthing went worng",
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => {
					setSpinner(false);
				});
		} else if (pType?.programId && pType.type === "copy") {
			const { id, ...rest } = body;
			setSpinner(true);
			programService
				.createProgram(rest)
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Successfull",
						detail: "Program created successfully",
						style: { color: "#000000" },
						life: 3000,
					});
					dispatch(setId(res.id));
					dispatch(setEnable(1));
				})
				.catch((er) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: "Somthing went worng",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => {
					setSpinner(false);
				});
		} else {
			programCreate(body);
		}
	};
	const onUploadfirst = (e, setFieldValue) => {
		//setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.PROGRAM_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Contract document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});

				setFieldValue("imageFirst", newFileName);
			}
		});
	};
	const onUploadSecond = (e, setFieldValue) => {
		//setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.PROGRAM_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "2nd document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});

				setFieldValue("imageSecond", newFileName);
			}
		});
	};
	return (
		<>
			{spinner && <Loader />}
			<Toast ref={toastTL} position="top-left" />
			<Suspense fallback={null}>
				<Formik
					initialValues={{
						...initialValue,
						...(programData && programData),
					}}
					validationSchema={validationSchema}
					onSubmit={handleFormSubmit}
					enableReinitialize
				>
					{({
						handleSubmit,
						setFieldValue,
						touched,
						errors,
						resetForm,
						values,
					}) => (
						<Form
							className="form-grid grid"
							onSubmit={handleSubmit}
							autoComplete="on"
						>
							<div className="field col-12 md:col-3">
								<Field
									header={"Name of the Program"}
									component={FieldInput}
									name={"name"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									name={`profession`}
									component={FieldMultiselect}
									options={Config.OCCUPATION}
									optionLabel="name"
									header={"Profession"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									name={"isProcessingFees"}
									header={"Is Processing Fee Fixed"}
									component={FieldCheckBox}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									type="number"
									component={FieldInput}
									name={"minLoanAmount"}
									header={"Min Loan Amount"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									type="number"
									component={FieldInput}
									name={"maxLoanAmount"}
									header={"Max Loan Amount"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									type="number"
									component={FieldInput}
									name={"minProcessingFees"}
									header={`Min Processing Fees ${
										values.isProcessingFees ? "" : "%"
									}`}
									// star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									type="number"
									component={FieldInput}
									name={"maxProcessingFees"}
									header={`Max Processing Fees ${
										values.isProcessingFees ? "" : "%"
									}`}
									// star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									type="number"
									component={FieldInput}
									name={"minLoanPeriod"}
									header={"Min Loan Period (in months)"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									type="number"
									component={FieldInput}
									name={"maxLoanPeriod"}
									header={"Max Loan Period (in months)"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									type="number"
									component={FieldInput}
									name={"minRateOfInterest"}
									header={"Min Rate Of Interest(%)"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									type="number"
									component={FieldInput}
									name={"maxRateOfInterest"}
									header={"Max Rate Of Interest(%)"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									component={FeildDropdown}
									name={"isPreClosureCharge"}
									options={Config.PRECLOSURE}
									header={"Is Pre-closure"}
									star="*"
								/>
							</div>

							<div className="col-12"> </div>
							<div className="field col-12 md:col-6">
								<Field
									name="shortDescription"
									component={FieldEditor}
									header={"Short Description"}
								/>
							</div>
							<div className="field col-12 md:col-6">
								<Field
									name="longDescription"
									component={FieldEditor}
									header={"Long Description"}
								/>
							</div>

							<div className="field col-12 md:col-6">
								<Field
									name="eligibilityDetails"
									component={FieldEditor}
									header={"Eligibility Details"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-6">
								<Field
									name="documentsRequired"
									component={FieldEditor}
									header={"Documents Required"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-6">
								<h3>Image 1</h3>
								{values.imageFirst && (
									<a
										href={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageFirst}`}
										target="_blank"
										className="flex align-items-center justify-content-start"
										rel="noreferrer"
									>
										{/* <i
												className={`text-2xl pi ${
													getFileExtension(
														img.documentFileName
													) === "pdf"
														? "pi-file-pdf text-red-400"
														: "pi-image text-primary-700"
												}`}
											/> */}

										<span className="">
											<img
												style={{ height: "80px" }}
												src={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageFirst}`}
												alt=""
											/>
										</span>
									</a>
								)}
							</div>
							<div className="field col-12 md:col-6">
								<h3>Image 2</h3>
								{values.imageSecond && (
									<a
										href={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageSecond}`}
										target="_blank"
										className="flex align-items-center justify-content-start"
										rel="noreferrer"
									>
										{/* <i
												className={`text-2xl pi ${
													getFileExtension(
														img.documentFileName
													) === "pdf"
														? "pi-file-pdf text-red-400"
														: "pi-image text-primary-700"
												}`}
											/> */}

										<span className="">
											<img
												style={{ height: "80px" }}
												src={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageSecond}`}
												alt=""
											/>
										</span>
									</a>
								)}
							</div>
							<div className="field col-12 md:col-6">
								<InputNode header={"Upload Logo"}>
									<FileUpload
										name="imageFirst"
										customUpload
										accept=".jpg,.png,.jpeg"
										uploadHandler={(e) =>
											onUploadfirst(e, setFieldValue)
										}
										maxFileSize={1000000}
										emptyTemplate={
											<p className="m-0">
												Drag and drop files to here to
												upload.
											</p>
										}
									/>
									{errors.imageFirst &&
										touched.imageFirst && (
											<small className="p-error">
												{errors.imageFirst}
											</small>
										)}
								</InputNode>
							</div>

							<div className="field col-12 md:col-6">
								<InputNode header={"Upload Image"}>
									<FileUpload
										name="imageSecond"
										customUpload
										accept=".jpg,.png,.jpeg"
										uploadHandler={(e) =>
											onUploadSecond(e, setFieldValue)
										}
										maxFileSize={1000000}
										emptyTemplate={
											<p className="m-0">
												Drag and drop files to here to
												upload.
											</p>
										}
									/>
									{errors.imageSecond &&
										touched.imageSecond && (
											<small className="p-error">
												{errors.imageSecond}
											</small>
										)}
								</InputNode>
							</div>
							<div className="flex justify-content-end align-items-center col-12 gap-2 mt-4">
								<Button
									type="button"
									label="Reset"
									onClick={() => resetForm()}
									icon={"pi pi-refresh"}
									className="danger-btn"
								/>
								<Button
									type="submit"
									label={
										pType?.programId &&
										pType?.type === "edit"
											? "Update"
											: "Save & next"
									}
								/>
								{pType?.programId && pType?.type === "edit" && (
									<Button
										type="button"
										onClick={() => {
											dispatch(setEnable(1));
										}}
										icon={"pi pi-arrow-right"}
									/>
								)}
							</div>
						</Form>
					)}
				</Formik>
			</Suspense>
		</>
	);
};

export default LoanProgram;
