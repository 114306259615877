import React, { useState, useEffect, useReducer, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { BiEdit, BiLink } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { InstitutionService } from "../../../services/InstitutionService";
import Paginator from "../../../components/Paginator";
import InstitutionDetails from "./InstitutionDetails";
import TableLoader from "../../../components/TableLoader";
import {
	dataReducer,
	fetchInitialState,
	// sortReducer,
} from "../../../components/fetchReducer";
import Config from "../../../shared/config";
import {
	searchInitialState,
	searchReducer,
} from "../../../components/searchReducer";
import { Field, Form, Formik } from "formik";
import { FeildDropdown, FieldInput } from "../../../components/FieldInput";
import { useGetStateListQuery } from "../../../services/query/queryApi";
import selectState from "../../../components/stateList";
import { SplitButton } from "primereact/splitbutton";
import { exportExcel } from "../../../components/XlsxExport";
import Loader from "../../../components/Loader";
import { Toast } from "primereact/toast";

const FinancialInstitutions = () => {
	const [show, setShow] = React.useState(false);
	const toastTL = useRef(null);
	const [loader, setLoader] = useState(false);
	let navigate = useNavigate();
	const [institution, setInstitution] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const { data: stateList = [], isLoading } = useGetStateListQuery();
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	// const [columnName] = useState("name");
	// const [operation] = useState("LIKE");
	const [selectedId, setSelectedId] = useState(null);
	const [updateDialog, setUpdateDialog] = useState(false);
	const inistitutionService = new InstitutionService();
	// const sortInitialState = {
	// 	searchValue: {},
	// };
	// const [sortState, sortDispatch] = useReducer(sortReducer, sortInitialState);

	const itaration = {
		type2: "",
		type: "",
		name: "",
		alise: "",
		address: "",
		city: "",
		action: "",
	};

	const routeChange = () => {
		let path = `/partners/financial-institution/add`;
		navigate(path);
	};

	useEffect(() => {
		let ignore = false;
		if (!ignore) {
			getInstitution();
		}
		return () => {
			ignore = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);

	function getInstitution() {
		let body = {
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.sortByColumn && searchState.sortType
				? {
						sorts: [
							searchState.sortByColumn +
								"," +
								searchState.sortType,
						],
				  }
				: { sorts: [] }),
			...(searchState.searchValue !== "" && searchState.searchValue),
		};
		setInstitution({ type: Config.FETCH_CONFIG.start });
		inistitutionService
			.getInstitution(body)
			.then((res) => {
				setInstitution({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((error) => {
				setInstitution({
					type: Config.FETCH_CONFIG.error,
					payload: error,
				});
			})
			.finally(() =>
				setInstitution({
					type: Config.FETCH_CONFIG.stop,
				})
			);
	}

	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	function tblSort(columnName, sortType) {
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}

	const increment = () => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.increment,
		});
	};
	const decrement = () => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.decrement,
		});
	};
	const pagesizechange = (e) => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};

	const redirectLink = (val) => {
		navigate(`/partners/financial-institution/product-maping/${val}`);
	};

	const getInstitutionDetails = (id) => {
		setSelectedId(id);
		setUpdateDialog(true);
	};

	//Table Template
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />
				<Column header={getSortableColumn("Type", "bankType")} />
				<Column header={getSortableColumn("Name", "bank.bankName")} />
				<Column header={getSortableColumn("Alias", "alias")} />
				<Column header="Address" />
				<Column header={getSortableColumn("City", "city")} />
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const actionTemplate = (item) => {
		return (
			<>
				<div className="flex">
					<Button
						className="icon-btn primary-btn mr-2"
						onClick={() => getInstitutionDetails(item.id)}
						tooltip="Edit Financial Institutions"
						tooltipOptions={{
							position: "left",
						}}
					>
						<BiEdit />
					</Button>
					<Button
						className="icon-btn status-warning"
						tooltip="Add Institutions Product"
						tooltipOptions={{
							position: "left",
						}}
						onClick={() => redirectLink(item.id)}
					>
						<BiLink />
					</Button>
				</div>
			</>
		);
	};
	const addressTemplate = (item) => {
		return `${item.addressLine1} ${item.addressLine2} ${item.addressLine3}`;
	};

	const initialValue = {
		type: "",
		name: "",
		alias: "",
		stateId: "",
		city: "",
		pincode: "",
	};

	const handleForm = (val, action) => {
		let data = Object.fromEntries(
			Object.entries(val).filter(([_, v]) => v !== "")
		);
		// console.log({ ...data, pageNo: 1, pageSize: 10 });

		searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: data,
		});
		searchDispatch({ type: Config.SEARCH_CONFIG.pageNo, payload: 1 });
		// action.resetForm();
	};

	const handleReset = () => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.reset,
		});
	};

	const typeTemplate = (item) => {
		return item.bankType.replaceAll("_", " ");
	};
	const newData = (e) => {
		const data = [...e];
		const anotherData = data.map((elm) => ({
			id: elm.id,
			alias: elm.alias,
			addressLine1: elm.addressLine1,
			addressLine2: elm.addressLine2,
			addressLine3: elm.addressLine3,
			city: elm.city,
			bankId: elm.bankId,
			bankName: elm.bankName,
			bankType: elm.bankType,
			lastModifiedBy: elm.lastModifiedBy,
			lastModifiedOn: elm.lastModifiedOn,
			rainbowDsaCode: elm.rainbowDsaCode,
			pincode: elm.pincode,
			stateId: elm.stateId,
			stateName: elm.stateName,
		}));
		return anotherData;
	};
	const getAllLoanData = () => {
		const requestBody = {
			pageNo: 1,
			pageSize: 100000,
		};

		setLoader(true);
		inistitutionService
			.getInstitution(requestBody)
			.then((res) => {
				const newData =
					res.data &&
					res.data.map((elm) => ({
						Alias: elm.alias,
						AddressLine1: elm.addressLine1,
						AddressLine2: elm.addressLine2,
						AddressLine3: elm.addressLine3,
						City: elm.city,
						BankId: elm.bankId,
						BankName: elm.bankName,
						BankType: elm.bankType,
						LastModifiedBy: elm.lastModifiedBy,
						LastModifiedOn: elm.lastModifiedOn,
						"Rainbow DSA Code": elm.rainbowDsaCode,
						Pincode: elm.pincode,
						"State Id": elm.stateId,
						"State Name": elm.stateName,
					}));

				exportExcel(newData, "All_FI_List");
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoader(false));
	};
	const items = [
		{
			label: "Export",
			icon: "pi pi-file-excel",
			command: () => {
				exportExcel(newData(institution.data.data), "FI_List");
			},
		},
		{
			label: "Export All",
			icon: "pi pi-file-excel",
			command: () => {
				getAllLoanData();
			},
		},
	];
	return (
		<>
			{loader && <Loader />}
			<Toast ref={toastTL} />
			<Dialog
				header="Update Institution"
				visible={updateDialog}
				style={{ width: "65vw" }}
				breakpoints={{ "960px": "90vw" }}
				onHide={() => setUpdateDialog(false)}
			>
				<InstitutionDetails
					refressList={getInstitution}
					id={selectedId}
				/>
			</Dialog>
			<div className="grid">
				<div className="col-12 flex flex-wrap justify-content-between align-items-center">
					<h3 className="m-0">Financial Institutions</h3>
					<div className="flex align-items-center gap-1">
						{/* <Button
							label="Filter"
							icon="pi pi-filter-fill"
							iconPos="left"
							className="disabled-btn"
						/> */}
						<span className="p-input-icon-left">
							<Button
								label={show ? "Close" : "Search"}
								icon={show ? "pi pi-times" : "pi pi-search"}
								className={
									show
										? "p-button-outlined p-button-danger"
										: "p-button-outlined"
								}
								aria-label="Cancel"
								onClick={() => {
									setShow((show) => !show);
								}}
							/>
						</span>
						<Button
							label="Reload"
							icon="pi pi-refresh"
							iconPos="right"
							className="p-button-outlined primary-btn"
							onClick={handleReset}
						/>
						<Button
							label="Add New"
							icon="pi pi-plus"
							iconPos="right"
							className="primary-btn"
							onClick={routeChange}
						/>
						<SplitButton
							icon={"pi pi-file-export"}
							label="Export"
							model={items}
							// loading={spinner}
						/>
					</div>
				</div>
				{show && (
					<Formik initialValues={initialValue} onSubmit={handleForm}>
						{({ handleSubmit, resetForm }) => (
							<Form
								onSubmit={handleSubmit}
								className="background-secondary p-4 border-round-md my-3"
							>
								<div className="form-grid grid">
									<div className="field col-12 md:col-3">
										<Field
											header={"Type"}
											name={"type"}
											component={FeildDropdown}
											options={Config.INSTITUTION_TYPE}
											optionLabel="name"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Name"}
											name={"name"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Alias"}
											name={"alias"}
											component={FieldInput}
										/>
									</div>
									{!isLoading && (
										<div className="field col-12 md:col-3">
											<Field
												header={"State"}
												name={"stateId"}
												options={selectState(stateList)}
												component={FeildDropdown}
												optionLabel={"name"}
												filter
											/>
										</div>
									)}
									<div className="field col-12 md:col-3">
										<Field
											header={"City"}
											name={"city"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Pincode"}
											name={"pincode"}
											component={FieldInput}
											type={"number"}
										/>
									</div>

									<div className="col-12 flex align-items-end justify-content-end">
										<Button
											type="button"
											label="Reset"
											className="p-button-danger mr-2"
											onClick={resetForm}
										/>
										<Button type="submit" label="Search" />
									</div>
								</div>
							</Form>
						)}
					</Formik>
				)}
				{institution.isLoading ? (
					<div className="col-12">
						<TableLoader
							headerGroup={headerGroup}
							itaration={itaration}
						/>
					</div>
				) : (
					institution.data && (
						<div className="col-12">
							{institution.data.totalPages > 0 && (
								<Paginator
									pageSize={institution.data.pageSize}
									firstPage={institution.data.firstPage}
									lastPage={institution.data.lastPage}
									decrement={decrement}
									increment={increment}
									pagesizechange={pagesizechange}
									pageNo={institution.data.pageNo}
									totalPages={institution.data.totalPages}
									totalElements={
										institution.data.totalElements
									}
								/>
							)}
							<DataTable
								headerColumnGroup={headerGroup}
								value={
									institution.data.data &&
									institution.data.data.map(
										(item, index) => ({
											...item,
											index:
												institution.data.pageSize *
													institution.data.pageNo -
												institution.data.pageSize +
												1 +
												index,
										})
									)
								}
								responsiveLayout="stack"
								breakpoint="960px"
							>
								<Column field="index" />
								<Column field="bankType" body={typeTemplate} />
								<Column field="bankName" />
								<Column field="alias" />
								<Column body={addressTemplate} />
								<Column field="city" />
								<Column body={actionTemplate} />
							</DataTable>

							{institution.data.totalPages > 0 && (
								<Paginator
									pageSize={institution.data.pageSize}
									firstPage={institution.data.firstPage}
									lastPage={institution.data.lastPage}
									decrement={decrement}
									increment={increment}
									pagesizechange={pagesizechange}
									pageNo={institution.data.pageNo}
									totalPages={institution.data.totalPages}
									totalElements={
										institution.data.totalElements
									}
								/>
							)}
						</div>
					)
				)}
			</div>
		</>
	);
};

export default FinancialInstitutions;
