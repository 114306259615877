import React, { useRef, useState } from "react";

import { RadioButton } from "primereact/radiobutton";
import ProductPurchaseHistory from "./ProductPurchaseHistory";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import Config from "../../../../shared/config";
import { Form, Formik } from "formik";
import { CampaignService } from "../../../../services/CampaignService";
import { Toast } from "primereact/toast";
import { produce } from "immer";
import Loader from "../../../../components/Loader";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
const ProductHistory = (props) => {
	const toast = useRef(null);
	const campaignService = new CampaignService();
	const [spinner, setSpinner] = useState(false);
	const navigate = useNavigate();
	// const [campaignState, campaignDispatch] = useReducer(dataReducer, {
	// 	data: [],
	// 	isLoading: false,
	// 	isFetched: false,
	// });
	// const { data = { data: [] }, isSuccess } = useGetProductDetailsQuery({
	// 	searchRequest: [],
	// 	pageNo: 1,
	// 	pageSize: 10000,
	// 	sorts: [],
	// });
	const initialValue = {
		id: props.id ? props.id : props.stages.campaignId,
		includeType: "CUSTOMER",
		includeBuyType1: "",
		includeBuyType2: "",
		includeMonth: "",
		includeProduct: true,
		includeProductList1: [],
		includeSubProductList1: [],
		includeProductList2: [],
		includeSubProductList2: [],
		excludeType: "CUSTOMER",
		excludeBuyType1: "",
		excludeBuyType2: "",
		excludeMonth: "",
		excludeProduct: true,
		excludeProductList1: [],
		excludeSubProductList1: [],
		excludeProductList2: [],
		excludeSubProductList2: [],
		includeTypeSelect: "BOUGHT",
		excludeTypeSelect: "BOUGHT",
	};
	const handleFormSubmit = (value) => {
		setSpinner(true);
		const newVal = produce(value, (state) => {
			Object.keys(state).forEach((xx) => {
				if (state[xx] === "") {
					state[xx] = null;
				}
			});
		});
		const {
			includeProductList1,
			includeSubProductList1,
			includeProductList2,
			includeSubProductList2,
			includeBuyType1,
			includeBuyType2,
			excludeBuyType1,
			excludeBuyType2,
			excludeProductList1,
			excludeSubProductList1,
			excludeProductList2,
			excludeSubProductList2,
			...rest
		} = newVal;

		const includeProductList = (
			rest.includeTypeSelect === "BOUGHT"
				? includeProductList1
				: includeProductList2
		).map((elm) => ({ originId: elm.id }));
		const includeSubProductList = (
			rest.includeTypeSelect === "BOUGHT"
				? includeSubProductList1
				: includeSubProductList2
		).map((elm) => ({ originId: elm.id }));

		const includeBuyType =
			rest.includeTypeSelect === "BOUGHT"
				? includeBuyType1
				: includeBuyType2;
		const excludeProductList = (
			rest.excludeTypeSelect === "BOUGHT"
				? excludeProductList1
				: excludeProductList2
		).map((elm) => ({ originId: elm.id }));
		const excludeSubProductList = (
			rest.excludeTypeSelect === "BOUGHT"
				? excludeSubProductList1
				: excludeSubProductList2
		).map((elm) => ({ originId: elm.id }));

		const excludeBuyType =
			rest.excludeTypeSelect === "BOUGHT"
				? excludeBuyType1
				: excludeBuyType2;

		const newData = {
			...rest,
			includeBuyType,
			includeProductList,
			includeSubProductList,
			excludeBuyType,
			excludeProductList,
			excludeSubProductList,
		};
		if (props.id) {
			campaignService
				.purchaseHistoryUpdate(newData)
				.then((res) =>
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: "Saved",
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.catch((e) =>
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => setSpinner(false));
		} else {
			campaignService
				.purchaseHistoryCreate(newData)
				.then((res) => {
					setSpinner(false);
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: "Saved",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.then(
					debounce(() => {
						navigate("/masters/campaign");
					}, 500)
				)
				.catch((e) => {
					setSpinner(false);
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});
				});
		}
	};
	// const getData = () => {
	// 	campaignDispatch({
	// 		type: Config.FETCH_CONFIG.start,
	// 	});
	// 	campaignService
	// 		.getPurchaseHistory(2007)
	// 		.then((res) => {
	// 			const {
	// 				includeProductList,
	// 				excludeProductList,
	// 				excludeSubProductList,
	// 				includeSubProductList,
	// 				...restData
	// 			} = res;
	// 			const includeProduct = data.data.filter((item) =>
	// 				includeProductList.some((elm) => elm.originId === item.id)
	// 			);
	// 			const includeSubProduct = includeProduct
	// 				.flatMap((elm) => elm.subProducts)
	// 				.filter((xx) =>
	// 					includeSubProductList.some(
	// 						(elm) => elm.originId === xx.id
	// 					)
	// 				);
	// 			const excludeProduct = data.data.filter((item) =>
	// 				excludeProductList.some((elm) => elm.originId === item.id)
	// 			);
	// 			const excludeSubProduct = excludeProduct
	// 				.flatMap((elm) => elm.subProducts)
	// 				.filter((xx) =>
	// 					excludeSubProductList.some(
	// 						(elm) => elm.originId === xx.id
	// 					)
	// 				);
	// 			const newData = {
	// 				...restData,
	// 				...(restData.includeTypeSelect === "BOUGHT"
	// 					? { includeProductList1: includeProduct }
	// 					: { includeProductList2: includeProduct }),
	// 				...(restData.excludeTypeSelect === "BOUGHT"
	// 					? { excludeProductList1: excludeProduct }
	// 					: { excludeProductList2: excludeProduct }),
	// 				...(restData.includeTypeSelect === "BOUGHT"
	// 					? { includeSubProductList1: includeSubProduct }
	// 					: { includeSubProductList2: includeSubProduct }),
	// 				...(restData.includeTypeSelect === "BOUGHT"
	// 					? { excludeSubProductList1: excludeSubProduct }
	// 					: { excludeSubProductList2: excludeSubProduct }),
	// 			};

	// 			campaignDispatch({
	// 				type: Config.FETCH_CONFIG.success,
	// 				payload: newData,
	// 			});
	// 			campaignDispatch({
	// 				type: Config.FETCH_CONFIG.fetched,
	// 			});
	// 		})
	// 		.catch((er) => {})
	// 		.finally(() =>
	// 			campaignDispatch({
	// 				type: Config.FETCH_CONFIG.stop,
	// 			})
	// 		);
	// };
	// useEffect(() => {
	// 	isSuccess && getData();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [isSuccess]);

	return (
		<>
			<Toast ref={toast} />
			{/* {campaignState.isLoading ? (
				<div className="flex align-items-center justify-content-center py-5">
					<ProgressSpinner
						style={{ height: "30px" }}
						strokeWidth="5"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				</div>
			) : ( */}
			{spinner && <Loader />}
			<Formik
				enableReinitialize
				initialValues={{
					...initialValue,
					...(props.dataState.fetched && props.dataState.history),
				}}
				onSubmit={handleFormSubmit}
			>
				{({ handleChange, values, setFieldValue, handleSubmit }) => (
					<Form onSubmit={handleSubmit}>
						<div>
							<div className="flex gap-2 align-items-center ">
								{" "}
								<h3 className="mr-4">Include all </h3>
								<RadioButton
									name="includeType"
									value="CUSTOMER"
									onChange={handleChange}
									checked={values.includeType === "CUSTOMER"}
								/>
								<label htmlFor="includeType">Customers</label>
								<RadioButton
									name="includeType"
									value="LEAD"
									onChange={handleChange}
									checked={values.includeType === "LEAD"}
								/>
								<label htmlFor="includeType">Leads</label>
							</div>

							<div className="card flex justify-content-center">
								<div className="flex flex-wrap gap-3">
									<div className="flex align-items-center c-include-box">
										<RadioButton
											name="includeTypeSelect"
											value="BOUGHT"
											onChange={handleChange}
											checked={
												values.includeTypeSelect ===
												"BOUGHT"
											}
										/>
										<ProductPurchaseHistory
											setFieldValue={setFieldValue}
											includeProductList={
												"includeProductList1"
											}
											includeBuyType={"includeBuyType1"}
											includeSubProductList={
												"includeSubProductList1"
											}
											dropdownoptions={
												Config.CampaignBuyTypeBought
											}
											values={values}
											monthName={"includeMonth"}
											months
										/>
									</div>
									<h4 className="w-full m-0 text-center">
										OR
									</h4>
									<div className="flex align-items-center c-include-box">
										<RadioButton
											name="includeTypeSelect"
											value="HAVE"
											onChange={handleChange}
											checked={
												values.includeTypeSelect ===
												"HAVE"
											}
										/>
										<ProductPurchaseHistory
											setFieldValue={setFieldValue}
											includeProductList={
												"includeProductList2"
											}
											includeBuyType={"includeBuyType2"}
											includeSubProductList={
												"includeSubProductList2"
											}
											months={false}
											values={values}
											dropdownoptions={
												Config.CampaignBuyTypeHave
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<Divider />
						<div>
							<div className="flex gap-2 align-items-center ">
								{" "}
								<h3 className="mr-4">Exclude all </h3>
								<RadioButton
									name="excludeType"
									value="CUSTOMER"
									onChange={handleChange}
									checked={values.excludeType === "CUSTOMER"}
								/>
								<label htmlFor="excludeType">Customers</label>
								<RadioButton
									name="excludeType"
									value="LEAD"
									onChange={handleChange}
									checked={values.excludeType === "LEAD"}
								/>
								<label htmlFor="excludeType">Leads</label>
							</div>

							<div className="card flex justify-content-center">
								<div className="flex flex-wrap gap-3">
									<div className="flex align-items-center c-include-box">
										<RadioButton
											name="excludeTypeSelect"
											value="BOUGHT"
											onChange={handleChange}
											checked={
												values.excludeTypeSelect ===
												"BOUGHT"
											}
										/>
										<ProductPurchaseHistory
											setFieldValue={setFieldValue}
											includeProductList={
												"excludeProductList1"
											}
											includeBuyType={"excludeBuyType1"}
											includeSubProductList={
												"excludeSubProductList1"
											}
											dropdownoptions={
												Config.CampaignBuyTypeBought
											}
											values={values}
											monthName={"excludeMonth"}
											months
										/>
									</div>
									<h4 className="w-full m-0 text-center">
										OR
									</h4>
									<div className="flex align-items-center c-include-box">
										<RadioButton
											name="excludeTypeSelect"
											value="HAVE"
											onChange={handleChange}
											checked={
												values.excludeTypeSelect ===
												"HAVE"
											}
										/>
										<ProductPurchaseHistory
											setFieldValue={setFieldValue}
											includeProductList={
												"excludeProductList2"
											}
											includeBuyType={"excludeBuyType2"}
											includeSubProductList={
												"excludeSubProductList2"
											}
											months={false}
											values={values}
											dropdownoptions={
												Config.CampaignBuyTypeHave
											}
										/>
									</div>
								</div>
							</div>
							<div className="flex col-12 justify-content-end align-items-center w-full gap-2 mt-4">
								<Button
									type="button"
									label="Back"
									icon="pi pi-angle-left"
									className="danger-btn"
									onClick={() => {
										props.setStages({
											type: "active",
											active: 2,
										});
										props.setStages({
											type: "stage",
											payload: {
												sequence: 2,
												status: "active",
											},
										});
										props.setStages({
											type: "stage",
											payload: {
												sequence: 3,
												status: "inactive",
											},
										});
									}}
								/>
								<Button
									label="Save"
									type={"submit"}
									icon={"pi pi-save"}
								/>
							</div>
						</div>
					</Form>
				)}
			</Formik>
			{/* )} */}
		</>
	);
};

export default ProductHistory;
