import moment from "moment/moment";
import React, { useRef, useState } from "react";
import { FiCheck, FiX } from "react-icons/fi";
import { Image } from "primereact/image";
import Config from "../../../../shared/config";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { UsersService } from "../../../../services/UsersService";
import { Toast } from "primereact/toast";
import S3 from "aws-sdk/clients/s3";
import { ProgressBar } from "primereact/progressbar";
import Loader from "../../../../components/Loader";
const KyDetail = (props) => {
	const userService = new UsersService();
	const [loader, setLoader] = useState(false);
	const [spinner, setSpinner] = useState(false);
	const [files, setFiles] = useState(0);
	const toast = useRef(null);
	const fileref = useRef(null);
	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});
	const handleFormSubmit = (value) => {
		const newData = {
			...value,
			aadhaar: {
				...value.aadhaar,
				dateOfBirth: value.aadhaar.dateOfBirth
					? moment(value.aadhaar.dateOfBirth).format("YYYY-MM-DD")
					: null,
			},
			pan: {
				...value.pan,
				dateOfBirth: value.pan.dateOfBirth
					? moment(value.pan.dateOfBirth).format("YYYY-MM-DD")
					: null,
			},
		};
		// console.log(newData);
		setLoader(true);
		userService
			.kycVerification(newData)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Successful",
					detail: res.message,
					style: { color: "#000000" },
					life: 3000,
				});
				props.handleCall();
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoader(false));
	};
	const formik = useFormik({
		initialValues: {
			profileId: props.agent.profile.id,
			// status: "",
			// panVerified: props.agent.profile.panVerified,
			// aadhaarVerified: props.agent.profile.aadhaarVerified,
			// selfieVerified: props.agent.profile.selfieVerified,
			selfieFileName: props.agent.profile.selfieFileName
				? props.agent.profile.selfieFileName
				: "",
			aadhaar: {
				fullName: props.agent.profile.aadharFullName
					? props.agent.profile.aadharFullName
					: "",
				dateOfBirth: props.agent.profile.aadharDateOfBirth
					? new Date(props.agent.profile.aadharDateOfBirth)
					: "",
				address: props.agent.profile.aadharAddress
					? props.agent.profile.aadharAddress
					: "",
			},
			pan: {
				idNumber: props.agent.profile.panNumber
					? props.agent.profile.panNumber
					: "",
				fullName: props.agent.profile.panFullName
					? props.agent.profile.panFullName
					: "",
				dateOfBirth: props.agent.profile.panDateOfBirth
					? new Date(props.agent.profile.panDateOfBirth)
					: "",
			},
			// faceMatch: {
			// 	faceMatchPOA: props.agent.profile.faceMatchPOA
			// 		? props.agent.profile.faceMatchPOA
			// 		: false,
			// 	faceMatchPOI: props.agent.profile.faceMatchPOI
			// 		? props.agent.profile.faceMatchPOI
			// 		: false,
			// },
		},
		enableReinitialize: true,
		onSubmit: handleFormSubmit,
	});
	const onUploadfirst = (e) => {
		//setLoading(true);
		setSpinner(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.AGENT_PROFILE_BUCKET + "/" + props.agent.profile.id,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
				fileref.current.clear();
				setSpinner(false);
			} else {
				//setLoading(false);

				toast.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});
				formik.setFieldValue("selfieFileName", newFileName);
				fileref.current.clear();
				setSpinner(false);
			}
		});
	};
	// console.log(fileref.current);
	const Progress = () => {
		return spinner ? (
			<ProgressBar mode="indeterminate" style={{ height: "6px" }} />
		) : null;
	};
	const headerTemplate = (options) => {
		const { className, chooseButton, uploadButton, cancelButton } = options;

		return (
			<div
				className={className}
				style={{
					backgroundColor: "transparent",
					display: "flex",
					alignItems: "center",
				}}
			>
				{chooseButton}
				{uploadButton}
				{cancelButton}
			</div>
		);
	};
	return (
		<>
			<Toast ref={toast} />
			{loader && <Loader />}
			<div className="grid ">
				<div className="col-12 lg:col-6">
					<div className="card p-3 border-1 border-400 border-round-md">
						<div className="w-full flex align-items-center justify-content-between">
							<h3 className="mt-0">AADHAAR</h3>
							{props.agent.profile.aadhaarVerified ? (
								<span className="sq-status status-success">
									<FiCheck />
								</span>
							) : (
								<span className="sq-status status-danger">
									<FiX />
								</span>
							)}
						</div>

						<div className="mb-2">
							<h5 className="m-0">Name</h5>

							{props.agent.profile.aadhaarVerified &&
							props.agent.profile.aadharFullName ? (
								<p className="m-0">
									{props.agent.profile.aadharFullName}
								</p>
							) : (
								<InputText
									name="aadhaar.fullName"
									value={formik.values.aadhaar.fullName}
									onChange={formik.handleChange}
								/>
							)}
						</div>

						<div className="mb-2">
							<h5 className="m-0">Date Of Birth</h5>
							{props.agent.profile.aadhaarVerified &&
							props.agent.profile.aadharDateOfBirth ? (
								<p className="m-0">
									{moment(
										props.agent.profile.aadharDateOfBirth
									).format("Do MMM, YYYY")}
								</p>
							) : (
								<Calendar
									name="aadhaar.dateOfBirth"
									value={formik.values.aadhaar.dateOfBirth}
									onChange={formik.handleChange}
								/>
							)}
						</div>

						<div className="mb-2">
							<h5 className="m-0">Address</h5>
							{props.agent.profile.aadhaarVerified &&
							props.agent.profile.aadharAddress ? (
								<p className="m-0">
									{props.agent.profile.aadharAddress}
								</p>
							) : (
								<InputTextarea
									name="aadhaar.address"
									value={formik.values.aadhaar.address}
									onChange={formik.handleChange}
								/>
							)}
						</div>
					</div>
					<div className="card mt-3 p-3 border-1 border-400 border-round-md">
						<div className="w-full flex align-items-center justify-content-between">
							<h3 className="mt-0">PAN</h3>
							{props.agent.profile.panVerified ? (
								<span className="sq-status status-success">
									<FiCheck />
								</span>
							) : (
								<span className="sq-status status-danger">
									<FiX />
								</span>
							)}
						</div>

						<div className="mb-2">
							<h5 className="m-0">PAN No:</h5>
							{props.agent.profile.panVerified &&
							props.agent.profile.panNumber ? (
								<p className="m-0">
									{props.agent.profile.panNumber}
								</p>
							) : (
								<InputText
									name="pan.idNumber"
									value={formik.values.pan.idNumber}
									onChange={formik.handleChange}
								/>
							)}
						</div>

						<div className="mb-2">
							<h5 className="m-0">Name:</h5>
							{props.agent.profile.panVerified &&
							props.agent.profile.panFullName ? (
								<p className="m-0">
									{props.agent.profile.panFullName}
								</p>
							) : (
								<InputText
									name="pan.fullName"
									value={formik.values.pan.fullName}
									onChange={formik.handleChange}
								/>
							)}
						</div>

						<div className="mb-2">
							<h5 className="m-0">Date Of Birth:</h5>
							{props.agent.profile.panVerified &&
							props.agent.profile.panDateOfBirth ? (
								<p className="m-0">
									{moment(
										props.agent.profile.panDateOfBirth
									).format("Do MMM, YYYY")}
								</p>
							) : (
								<Calendar
									name="pan.dateOfBirth"
									value={formik.values.pan.dateOfBirth}
									onChange={formik.handleChange}
								/>
							)}
						</div>
					</div>
				</div>
				<div className="col-12 lg:col-6">
					<div className="card p-3 border-1 border-400 border-round-md">
						<h3 className="mt-0">Face Match</h3>

						<div className="grid">
							{formik.values.selfieFileName && (
								<div className="col-4">
									<Image
										src={`${
											Config.AGENT_BUCKET_ACCESS_URL
										}/agent-profile/${
											props.agent.profile.id
										}/${
											formik.values.selfieFileName
												? formik.values.selfieFileName
												: props.agent.profile
														.selfieFileName
										}`}
										alt="Image"
										width="100%"
										imageStyle={{ maxHeight: "136px" }}
										preview
									/>
								</div>
							)}

							<div className="col-8">
								<div className="card px-2">
									<div className="w-full flex align-items-center justify-content-between">
										<h3>Match with PAN</h3>
										{props.agent.profile.faceMatchPOI ? (
											<span className="sq-status status-success">
												<FiCheck />
											</span>
										) : (
											<span className="sq-status status-danger">
												<FiX />
											</span>
										)}
									</div>
									<div className="w-full flex align-items-center justify-content-between">
										<h3>Match with AADHAAR</h3>
										{props.agent.profile.faceMatchPOA ? (
											<span className="sq-status status-success">
												<FiCheck />
											</span>
										) : (
											<span className="sq-status status-danger">
												<FiX />
											</span>
										)}
									</div>
								</div>
							</div>
						</div>

						{(!props.agent.profile.faceMatchPOA ||
							!props.agent.profile.faceMatchPOI) && (
							<>
								<FileUpload
									mode="basic"
									ref={fileref}
									name="selfieFileName"
									accept=".jpg,.png,.jpeg"
									maxFileSize={1000000}
									customUpload
									uploadHandler={(e) => onUploadfirst(e)}
									auto
									chooseLabel="Browse"
								/>
								{/* <FileUpload
									mode="basic"
									name="selfieFileName"
									accept=".jpg,.png,.jpeg"
									maxFileSize={1000000}
									customUpload
									uploadHandler={(e) => onUploadfirst(e)}
									auto
									// multiple

									chooseLabel="Browse"
								/> */}
								{/* <FileUpload
									progressBarTemplate={Progress}
									headerTemplate={headerTemplate}
									multiple
									customUpload
									maxFileSize={1000000}
									emptyTemplate={
										<p className="m-0">
											Drag and drop files to here to
											upload.
										</p>
									}
								/> */}
							</>
						)}
					</div>
					{(!props.agent.profile.faceMatchPOA ||
						!props.agent.profile.faceMatchPOI ||
						!props.agent.profile.aadhaarVerified ||
						!props.agent.profile.panVerified) && (
						<div className="col-12 flex align-items-center justify-content-end">
							<Button
								className="mt-2"
								type={"button"}
								label="Update"
								onClick={formik.handleSubmit}
							/>
						</div>
					)}
					{props?.agent?.company && (
						<div className="card p-3 mt-2 border-1 border-400 border-round-md">
							<h3 className="mt-0">Company KYC details</h3>

							<div className="grid">
								<div className="col-8">
									<div className="card px-2">
										{props?.agent?.profile?.companyCin &&
											props?.agent?.profile
												?.companyAddress &&
											props?.agent?.profile
												?.companyName && (
												<div className="w-full flex align-items-center justify-content-between">
													<h3>Company PAN details</h3>
													<span className="sq-status status-success">
														<FiCheck />
													</span>
												</div>
											)}
										{props?.agent?.profile
											?.gstCompanyAddress &&
											props?.agent?.profile
												?.gstCompanyName && (
												<div className="w-full flex align-items-center justify-content-between">
													<h3>Company GST</h3>
													<span className="sq-status status-success">
														<FiCheck />
													</span>
												</div>
											)}
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default KyDetail;
