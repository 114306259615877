import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { FieldInput } from "../../../components/FieldInput";
import { Button } from "primereact/button";
import Config from "../../../shared/config";
import { S3 } from "aws-sdk";
import { Toast } from "primereact/toast";
import { InputNode } from "../../../components/InputComponent";
import { FileUpload } from "primereact/fileupload";
import { SupportService } from "../../../services/SupportService";
import * as Yup from "yup";
import Loader from "../../../components/Loader";

const EditFAQDialoge = ({ editData, editDialogClose }) => {
	const supportService = new SupportService();
	const [loading, setLoading] = useState(false);
	const toastTL = useRef(null);
	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});
	const validationSchema = Yup.object().shape({
		name: Yup.string().required("This field is required"),
		logoUrl: Yup.string().required("Upload logo image required"),
	});
	const onUploadImage = (e, setFieldValue) => {
		//setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.FAQ_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Logo uploaded",
					style: { color: "#000000" },
					life: 3000,
				});

				setFieldValue("logoUrl", newFileName);
			}
		});
	};

	const handleFormSubmit = (e) => {
		setLoading(true);
		supportService
			.editFAQCategory(e)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});
				setLoading(false);
				editDialogClose(false);
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setLoading(false);
				// editDialogClose(false);
			});
	};
	return (
		<div className="card modal-form-grid">
			<Toast ref={toastTL} position="top-left" />
			{loading && <Loader />}
			<Formik
				initialValues={{
					...editData,
				}}
				onSubmit={handleFormSubmit}
				validationSchema={validationSchema}
				enableReinitialize
			>
				{({
					handleSubmit,
					values,
					resetForm,
					setFieldValue,
					touched,
					errors,
				}) => (
					<Form onSubmit={handleSubmit} className="mt-5">
						<div className="grid form-grid">
							<div className="field col-12">
								<Field
									header={"Name"}
									component={FieldInput}
									name={`name`}
								/>
							</div>
							<div className="field col-12 md:col-12">
								<InputNode header={"Upload Logo"}>
									<FileUpload
										name="logoUrl"
										customUpload
										accept=".jpg,.png,.jpeg"
										uploadHandler={(e) =>
											onUploadImage(e, setFieldValue)
										}
										maxFileSize={1000000}
										emptyTemplate={
											<p className="m-0">
												Drag and drop files to here to
												upload.
											</p>
										}
									/>
									{errors.logoUrl && touched.logoUrl && (
										<small className="p-error">
											{errors.logoUrl}
										</small>
									)}
								</InputNode>
							</div>
							<div className="flex justify-content-end align-items-center w-full mt-4">
								<Button
									type="button"
									label="Reset"
									icon="pi pi-refresh"
									className="danger-btn mr-2"
									onClick={() => {
										resetForm();
									}}
									style={{
										borderRadius: "6px",
									}}
								/>
								<Button
									type="submit"
									label="Save"
									icon="pi pi-save"
									style={{ borderRadius: "6px" }}
									loading={loading}
								/>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default EditFAQDialoge;
