import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import React, { useEffect, useRef, useState } from "react";
import { Messages } from "primereact/messages";

const PreviewFile = (props) => {
	const msgs = useRef(null);
	const [tableData, setTableData] = useState([]);

	useEffect(() => {
		let modifyData =
			props.data &&
			props.data.map((item, index) => ({
				...item,
				index: 1 + index,
			}));
		props.submitData(modifyData);
		msgs.current.show({
			severity: "error",
			summary: "",
			detail: (
				<ul>
					{modifyData?.map(
						(item, index) =>
							item.errorMessage && (
								<li className="m-0" key={index}>
									{item.errorMessage}
								</li>
							)
					)}
				</ul>
			),
			sticky: true,
		});
		setTableData(modifyData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleUpload = () => {
		props.handelStages();
	};
	const handleBack = () => {
		props.handleBack();
	};
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />
				<Column header="Bank Application Number" />
				<Column header="Status" />
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);

	const deleteApplication = (e) => {
		const filterData = tableData.filter((item) => item.index !== e.index);
		setTableData(filterData);
		props.submitData(filterData);
		console.log(filterData);
	};

	const actionTemplate = (item) => {
		return (
			<>
				<Button
					type="button"
					onClick={() => deleteApplication(item)}
					// style={{
					// 	marginTop: "1.5rem",
					// }}
					icon="pi pi-trash"
					className="p-button-outlined p-button-danger p-button-sm"
					aria-label="Cancel"
				/>
			</>
		);
	};
	return (
		<>
			<div>
				<Messages ref={msgs} />
				{/* <h4 className="text-center mt-6">
					Only the first 50 rows are displayed below
				</h4> */}
				<DataTable
					headerColumnGroup={headerGroup}
					value={tableData}
					responsiveLayout="scroll"
					breakpoint="960px"
				>
					<Column field="index" />
					<Column field="bankApplicationNumber" />
					<Column field="applicationStatus" />

					<Column body={actionTemplate} />
				</DataTable>
			</div>
			<div className="flex justify-content-end">
				<Button
					icon="pi pi-angle-left"
					label="Back"
					className="mr-2"
					onClick={handleBack}
				/>
				<Button
					icon="pi pi-angle-right"
					label="Next"
					className="mr-2"
					onClick={handleUpload}
				/>
			</div>
		</>
	);
};

export default PreviewFile;
