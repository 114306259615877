import axios from "axios";
import { useSelector } from "react-redux";
import { agent_end_point_url } from "../shared/constant";

export default class ChangePassword {
	selector = useSelector((state) => state.persistData.auth);
	constructor() {
		this.token = this.selector.token;
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}
	changePassword = async ({ oldPassword, newPassword }) => {
		let payload = {
			oldPassword: oldPassword,
			newPassword: newPassword,
		};
		try {
			const response = await axios.post(
				agent_end_point_url + "/customer/password/change",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
}
