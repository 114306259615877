import Config from "../../../../../shared/config";
import CreditCard from "./formcomponents/CreditCard";
import HospicashProgram from "./formcomponents/Hospicash";
import InsuranceProgram from "./formcomponents/InsuranceProgram";
import LoanProgram from "./formcomponents/LoanProgram";
import MedicalInsuranceProgram from "./formcomponents/MedicalInsurance";
import MotorInsuranceProgram from "./formcomponents/MotorInsurance";
import RetirementPlan from "./formcomponents/RetirementPlan";

const FormRender = (props) => {
	const locationData = props?.locationData;

	switch (locationData?.programData?.productId?.alias) {
		case "PL":
			return <LoanProgram locationData={locationData} />;
		case "VL":
			return <LoanProgram locationData={locationData} />;
		case "HL":
			return <LoanProgram locationData={locationData} />;
		case "LAP":
			return <LoanProgram locationData={locationData} />;
		case "BL":
			return <LoanProgram locationData={locationData} />;
		case "CC":
			return <CreditCard locationData={locationData} />;
		case "HC":
			return <HospicashProgram locationData={locationData} />;
		case "TIP":
			return <InsuranceProgram locationData={locationData} />;
		case "TRADI":
			return <InsuranceProgram locationData={locationData} />;
		case Config.PRODUCT_ALIAS.ULIP:
			return <InsuranceProgram locationData={locationData} />;
		case Config.PRODUCT_ALIAS.CHILD_INSURANCE_PLAN:
			return <InsuranceProgram locationData={locationData} />;
		case Config.PRODUCT_ALIAS.CHILD_EDUCATION_PLAN:
			return <InsuranceProgram locationData={locationData} />;
		case Config.PRODUCT_ALIAS.INVESTMENT_LIFE_INSURANCE:
			return <InsuranceProgram locationData={locationData} />;
		case Config.PRODUCT_ALIAS.GROUP_INSURANCE_PLAN:
			return <InsuranceProgram locationData={locationData} />;
		case "RIP":
			return <RetirementPlan locationData={locationData} />;

		case "INDIV":
			return <MedicalInsuranceProgram locationData={locationData} />;
		case Config.PRODUCT_ALIAS.FAMILY_FLOATER:
			return <MedicalInsuranceProgram locationData={locationData} />;
		case Config.PRODUCT_ALIAS.GROUP:
			return <MedicalInsuranceProgram locationData={locationData} />;
		case Config.PRODUCT_ALIAS.CRITICAL_ILLNESS:
			return <MedicalInsuranceProgram locationData={locationData} />;
		case "ACCI":
			return <MedicalInsuranceProgram locationData={locationData} />;
		case Config.PRODUCT_ALIAS.MOTOR_INSURANCE_PRIVET_CAR:
			return <MotorInsuranceProgram locationData={locationData} />;
		case Config.PRODUCT_ALIAS.MOTOR_INSURANCE_TWO_WHEELER:
			return <MotorInsuranceProgram locationData={locationData} />;
		default:
			return <></>;
	}
};
export default FormRender;
