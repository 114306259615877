import { useLocation, useParams } from "react-router-dom";
import SubmitNewLeadDialog from "./SubmitNewLeadDialog";
import Config from "../../../../shared/config";
import SubmitNewInsuranceLead from "./SubmitInsuranceLead";

const VendorEditDialog = () => {
	const params = useParams();
	const location = useLocation();
	return location?.state?.productType === Config.PRODUCT_LEAD_TYPE.LOAN ? (
		<SubmitNewLeadDialog
			location={location}
			type={"edit"}
			leadId={params}
		/>
	) : (
		<SubmitNewInsuranceLead
			location={location}
			type={"edit"}
			leadId={params}
		/>
	);
};

export default VendorEditDialog;
