import CreateVendor from "./components/CreateVendor";
import { Dialog } from "primereact/dialog";
import { useEffect, useReducer, useRef, useState } from "react";
import { Button } from "primereact/button";
import {
	searchInitialState,
	searchReducer,
} from "../../components/searchReducer";
import { dataReducer, fetchInitialState } from "../../components/fetchReducer";
import Config from "../../shared/config";
import { Toast } from "primereact/toast";
import { VendorService } from "../../services/vendorService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { ConfirmDialog } from "primereact/confirmdialog"; // To use confirmDialog method
import Loader from "../../components/Loader";
import TableLoader from "../../components/TableLoader";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import Paginator from "../../components/Paginator";
import SearchVendor from "./components/SearchVendor";

const Vendor = () => {
	const [loader, setLoader] = useState(false);
	const [visible, setVisible] = useState({ confirm: false, id: null });
	const [open, setOpen] = useState(false);
	const vendorService = new VendorService();
	const reducer = (state, payload) => {
		return { ...state, ...payload };
	};
	const [dialogState, setDialog] = useReducer(reducer, {
		dialog: false,
		type: "create",
		data: {},
	});
	const toastTL = useRef(null);
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const [members, setMembers] = useReducer(dataReducer, fetchInitialState);
	const itaration = {
		title: "",
		firstName: "",
		hi: "",
		mobilenumber: "",
		city: "",
		state: "",
		stated: "",
	};
	function tblSort(columnName, sortType) {
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}

	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column
					header={getSortableColumn("Vendor Type", "vendorType")}
				/>

				<Column header={getSortableColumn("Vendor Name", "name")} />
				<Column header={getSortableColumn("Username", "userName")} />
				<Column header={getSortableColumn("Email", "email")} />
				<Column
					header={getSortableColumn(
						"Contactperson Name",
						"contactPersonName"
					)}
				/>
				<Column
					header={getSortableColumn(
						"Contactperson Type",
						"contactPersonType"
					)}
				/>
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const userDataTableValue = () => {
		const requestBody = {
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.searchValue !== "" && searchState.searchValue),
			...(searchState.sortByColumn && searchState.sortType
				? {
						sorts: [
							searchState.sortByColumn +
								"," +
								searchState.sortType,
						],
				  }
				: { sorts: [] }),
		};

		// if (searchValue !== "" && operation) {
		// 	requestBody.searchRequest = ;
		// }
		// if (searchState.sortByColumn && searchState.sortType) {
		// 	requestBody.sorts = [
		// 		,
		// 	];
		// }
		setMembers({ type: Config.FETCH_CONFIG.start });
		vendorService
			.getVendorList(requestBody)
			.then((res) => {
				setMembers({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
				setMembers({
					type: Config.FETCH_CONFIG.fetched,
				});
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setMembers({ type: Config.FETCH_CONFIG.stop }));
	};
	useEffect(() => {
		userDataTableValue();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);

	const accept = () => {
		setLoader(true);
		vendorService
			.deleteVendorList(visible.id)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});
				userDataTableValue();
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoader(false));
	};

	const reject = () => {
		setVisible((e) => ({
			...e,
			confirm: true,
			id: null,
		}));
	};
	const actionTemplate = (item) => {
		return (
			<div className="flex gap-1">
				<Button
					className="icon-btn p-button-text"
					tooltip="Edit"
					icon={"pi pi-pencil"}
					tooltipOptions={{
						position: "bottom",
					}}
					onClick={() =>
						setDialog({
							dialog: true,
							type: "edit",
							data: item,
						})
					}
				/>

				<Button
					className="icon-btn p-button-danger p-button-text"
					icon={"pi pi-trash"}
					tooltip="Delete"
					tooltipOptions={{ position: "bottom" }}
					onClick={() =>
						setVisible((e) => ({
							...e,
							confirm: true,
							id: item.id,
						}))
					}
				/>
			</div>
		);
	};
	const increment = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const pagesizechange = (e) => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};

	return (
		<>
			{loader && <Loader />}
			<ConfirmDialog
				visible={visible.confirm}
				onHide={() =>
					setVisible((e) => ({
						...e,
						confirm: false,
						id: null,
					}))
				}
				message="Are you sure you want to proceed?"
				header="Confirmation"
				icon="pi pi-exclamation-triangle"
				accept={accept}
				reject={reject}
			/>
			<Toast ref={toastTL} position="top-left" />
			<div className="flex align-items-center justify-content-between">
				<h3>Vendor</h3>
				<div className="flex gap-2">
					<Button
						icon={open ? "pi pi-times" : "pi pi-search"}
						className={
							open
								? "p-button-danger p-button-outlined"
								: "p-button-outlined"
						}
						label={open ? "Close" : "Search"}
						onClick={() => setOpen((prev) => !prev)}
					/>
					<Button
						label="Reload"
						icon={"pi pi-refresh "}
						className=" p-button-outlined"
						onClick={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							})
						}
					/>
					<Button
						onClick={() => {
							setDialog({
								dialog: true,
								type: "create",
								data: {},
							});
						}}
						label="Add vendor"
						icon={"pi pi-plus"}
					/>
				</div>
			</div>
			{open && <SearchVendor search={searchDispatch} />}
			<Dialog
				style={{ width: "60%" }}
				visible={dialogState.dialog}
				onHide={() => setDialog({ dialog: false, data: {} })}
				header={
					dialogState.type === "create"
						? "Create vendor"
						: "Update vendor"
				}
			>
				<CreateVendor
					dialogState={dialogState}
					userDataTableValue={userDataTableValue}
					setDialog={setDialog}
				/>
			</Dialog>
			<div className="col-12">
				{members.isLoading ? (
					<TableLoader
						headerGroup={headerGroup}
						itaration={itaration}
					/>
				) : (
					members.isFetched && (
						<>
							<DataTable
								value={members.data?.data}
								responsiveLayout="scroll"
								breakpoint="960px"
								headerColumnGroup={headerGroup}
							>
								<Column
									body={(item) =>
										item?.vendorType.replaceAll("_", " ")
									}
								/>
								<Column field="name" />
								<Column field="userName" />
								<Column field="email" />

								<Column field="contactPersonName" />
								<Column
									body={(item) =>
										item?.contactPersonType.replaceAll(
											"_",
											" "
										)
									}
								/>

								<Column field={actionTemplate} />
							</DataTable>
							{members.data?.data?.length > 0 && (
								<Paginator
									pageSize={members.data?.pageSize}
									firstPage={members.data?.firstPage}
									lastPage={members.data?.lastPage}
									decrement={decrement}
									increment={increment}
									pagesizechange={pagesizechange}
									pageNo={members.data?.pageNo}
									totalPages={members.data?.totalPages}
									totalElements={members.data?.totalElements}
								/>
							)}
						</>
					)
				)}
			</div>

			{/* {members.data?.data?.map((item) => (
				<Panel
					key={item.id}
					toggleable
					header={
						<span className="flex gap-2 align-items-center">
							<Button
								className="p-button-text icon-btn"
								icon={"pi pi-pencil"}
								onClick={() =>
									setDialog({
										dialog: true,
										type: "edit",
										data: item,
									})
								}
							/>
							{}
						</span>
					}
					className="mb-2"
				></Panel>
			))} */}
		</>
	);
};

export default Vendor;
