import axios from "axios";
import { agent_end_point_url } from "../shared/constant";
import { useSelector } from "react-redux";

export class ZoneService {
	selector = useSelector((state) => state.persistData.auth);
	constructor() {
		this.token = this.selector.token;
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}
	getRole = async () => {
		try {
			const res = await axios.get(
				`${agent_end_point_url}/all-role`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getZone = async () => {
		try {
			const res = await axios.get(
				`${agent_end_point_url}/zone`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getStateListByZone = async (zoneId) => {
		try {
			const response = await axios.get(
				`${agent_end_point_url}/state/${zoneId}`,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getDistricByState = async (stateID) => {
		try {
			const response = await axios.get(
				`${agent_end_point_url}/district/${stateID}`,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
}
