import React from "react";
import SubmitNewLeadDialog from "./SubmitNewLeadDialog";
import { useLocation } from "react-router-dom";
import SubmitNewInsuranceLeadDialog from "./SubmitInsuranceLead";
import Config from "../../../../shared/config";

const VendorCreateLead = () => {
	const location = useLocation();
	return location?.state?.productType === Config.PRODUCT_LEAD_TYPE.LOAN ? (
		<SubmitNewLeadDialog location={location} type={"create"} />
	) : (
		<SubmitNewInsuranceLeadDialog location={location} type={"create"} />
	);
};

export default VendorCreateLead;
