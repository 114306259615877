import React, { useState, useEffect, useRef, useReducer } from "react";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
// import { ProgressSpinner } from "primereact/progressspinner";
import { MdModeEditOutline, MdInfoOutline } from "react-icons/md";
// Internal Imports
import { ProductService } from "../../../services/ProductService";
import Paginator from "../../../components/Paginator";
import MasterProducts from "./MasterProducts";
import Loader from "../../../components/Loader";
import ProductStatus from "./ProductStatus";
import ReasonCreate from "./ReasonCreate";
import ProductDocumentDialoge from "./ProductDocumentDialoge";
import ProductCommissionStructure from "./ProductCommissionStructure";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import Config from "../../../shared/config";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
const Products = () => {
	const toastTL = useRef(null);
	const toastBC = useRef(null);
	const navigation = useNavigate();
	// const menu = useRef(null);
	const [products, setProducts] = useState(null);
	const [expandedRows, setExpandedRows] = useState(null);
	const [searchValue, setSearchValue] = useState("");
	const [columnName] = useState("title");
	const [operation] = useState("LIKE");
	const [sortByColumn, setSortByColumn] = useState(null);
	const [sortType, setSortType] = useState(null);
	const [pageNo, setpageNo] = useState(1);
	const [pageSize, setpageSize] = useState(300);

	const [productStatusDialog, setProductStatusDialog] = useState(false);
	const [productReasonStatusDialog, setProductReasonStatusDialog] =
		useState(false);
	const [productDocumentDialog, setProductDocumentDialog] = useState(false);
	const [commissionStructureDialog, setCommissionStructureDialog] =
		useState(false);
	const [addedProducts, setInsertedProducts] = useState(null);
	const [selectedProducts, setselectedProducts] = useState(null);
	const [subProductId, setSubProductId] = useState(null);
	const [subProductReasonId, setSubProductReasonId] = useState(null);
	const [subProductDocument, setSubProductDocument] = useState(null);
	const [commissionStructure, setCommissionStructure] = useState(null);
	// const [commissionStructureData, setCommissionStructureData] = useReducer(
	// 	dataReducer,
	// 	fetchInitialState
	// );
	const productService = new ProductService();
	const [spinner, setSpinner] = useState(false);
	const [loader, setLoader] = useState(false);

	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							sortByColumn === columnName && sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							sortByColumn === columnName && sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}

	const lodarTblArray = Array.from({ length: 10 });
	const skeletonBody = () => {
		return <Skeleton height="1rem"></Skeleton>;
	};

	function tblSort(columnName, sortType) {
		setSortByColumn(columnName);
		setSortType(sortType);
	}

	useEffect(() => {
		let payload = {
			searchRequest: [],
			pageNo: pageNo,
			pageSize: pageSize,
			sorts: [],
		};
		if (searchValue !== "" && columnName && operation) {
			payload.searchRequest = [
				{
					column: "parentId",
					value: searchValue,
					operation: operation,
				},
				{
					column: columnName,
					value: searchValue,
					operation: operation,
				},
			];
		}
		if (sortByColumn && sortType) {
			payload.sorts = [sortByColumn + "," + sortType];
		}
		getProducts(payload);
		return () => {
			setSpinner(false);
		}; // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		columnName,
		searchValue,
		operation,
		sortByColumn,
		sortType,
		pageNo,
		pageSize,
	]);

	const getProducts = (payload) => {
		productService
			.getProducts(payload)
			.then((res) => {
				let products = res;
				setProducts(products);
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			});
	};

	const increment = () => {
		setpageNo(pageNo + 1);
	};
	const decrement = () => {
		setpageNo(pageNo - 1);
	};
	const pagesizechange = (e) => {
		setpageSize(e.target.value);
	};

	const handelImportProduct = (items, value) => {
		setInsertedProducts(items);
		setselectedProducts(value);
	};

	const onEditClick = (item) => {
		let requestBody = {
			id: item.id,
			title: item.title,
			code: item.code,
			parentId: item.parentId,
			lineOfBusiness: item.lineOfBusiness,
			active: !item.active,
			alias: item?.alias,
		};
		productService
			.updateProduct(requestBody)
			.then((res) => {
				if (res) {
					toastTL.current.show({
						severity: "success",
						summary: "Successfull",
						detail: "Product update successful",
						style: { color: "#000000" },
						life: 3000,
					});
					let payload = {
						searchRequest: [],
						pageNo: pageNo,
						pageSize: pageSize,
						sorts: [],
					};
					getProducts(payload);
					setSpinner(false);
				}
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
				setSpinner(false);
			});
	};

	const showConfirm = () => {
		toastBC.current.show({
			severity: "warn",
			summary: "Warning Message",
			content: (
				<div className="flex flex-column" style={{ flex: "1" }}>
					<div className="text-center">
						<i
							className="pi pi-exclamation-triangle"
							style={{ fontSize: "3rem" }}
						></i>
						<h4>Are you sure?</h4>
						<p>Want to add this product to your country.</p>
					</div>
					<div className="grid p-fluid">
						<div className="col-6">
							<Button
								type="button"
								label="Yes"
								className="p-button-success"
								onClick={onConfirmYes}
							/>
						</div>
						<div className="col-6">
							<Button
								type="button"
								label="No"
								className="p-button-secondary"
								onClick={onConfirmNo}
							/>
						</div>
					</div>
				</div>
			),
			life: 3000,
		});
	};

	const onConfirmYes = () => {
		setLoader(true);
		let payload = {
			searchRequest: [],
			pageNo: pageNo,
			pageSize: pageSize,
			sorts: [],
		};
		productService
			.addProducts(addedProducts)
			.then((res) => {
				if (typeof res === "object") {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: "Product added to country",
						style: { color: "#000000" },
						life: 3000,
					});
					getProducts(payload);
					setInsertedProducts(null);
					setselectedProducts(null);

					toastBC.current.clear();
				}
				setLoader(false);
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
				getProducts(payload);
				setInsertedProducts(null);
				setselectedProducts(null);

				setLoader(false);
				toastBC.current.clear();
			});
	};

	const onConfirmNo = () => {
		setInsertedProducts(null);
		setselectedProducts(null);
		toastBC.current.clear();
	};

	const getProductStatus = (item) => {
		setSubProductId(item.id);
		setProductStatusDialog(true);
	};

	const getProductReasonStatus = (item) => {
		setSubProductReasonId(item.id);
		setProductReasonStatusDialog(true);
	};

	const getProductDocument = (item) => {
		setSubProductDocument(item.id);
		setProductDocumentDialog(true);
	};

	// const getCommissionStructure = (item) => {
	// 	setCommissionStructure(item.id);
	// 	setCommissionStructureDialog(true);
	// 	// setCommissionStructureData({
	// 	// 	type: Config.FETCH_CONFIG.start,
	// 	// });
	// 	// productService
	// 	// 	.getAgentCommissionStructure(item.id)
	// 	// 	.then((res) => {
	// 	// 		setCommissionStructureData({
	// 	// 			type: Config.FETCH_CONFIG.success,
	// 	// 			payload: res,
	// 	// 		});
	// 	// 	})
	// 	// 	.catch((e) => {
	// 	// 		setCommissionStructureData({
	// 	// 			type: Config.FETCH_CONFIG.error,
	// 	// 			payload: e,
	// 	// 		});
	// 	// 	})
	// 	// 	.finally(() => {
	// 	// 		setCommissionStructureData({
	// 	// 			type: Config.FETCH_CONFIG.stop,
	// 	// 		});
	// 	// 	});
	// };

	const agentCommissionDialogeClose = (e) => {
		setCommissionStructureDialog(false);
	};

	const productDocumentClose = (e) => {
		setProductDocumentDialog(e);
	};

	// Table template
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column style={{ width: "3em" }}></Column>
				<Column header="#"></Column>
				<Column
					header={getSortableColumn(
						"Line Of Business",
						"lineOfBusiness"
					)}
				></Column>
				<Column header={getSortableColumn("Product", "title")}></Column>
				<Column header="Status"></Column>
			</Row>
		</ColumnGroup>
	);

	const statusUpdateTemplate = (item) => {
		return (
			<InputSwitch
				checked={item.active}
				onChange={() => {
					onEditClick(item);
					setSpinner(true);
				}}
				className="switch_toggle"
				tooltip="Change Status"
				tooltipOptions={{ position: "bottom" }}
			/>
		);
	};

	const actionTemplate = (item) => {
		return (
			<div className="flex gap-2">
				<Button
					className="icon-btn primary-btn"
					onClick={() => getProductStatus(item, "edit")}
					tooltip="Edit Product Status"
					tooltipOptions={{ position: "bottom" }}
				>
					<MdModeEditOutline />
				</Button>
				<Button
					className="icon-btn  p-button-info"
					tooltip="View Reason"
					onClick={() => getProductReasonStatus(item, "info")}
					tooltipOptions={{ position: "bottom" }}
				>
					<MdInfoOutline />
				</Button>
				<Button
					className="icon-btn  p-button-help"
					tooltip="Product Document"
					onClick={() => getProductDocument(item)}
					tooltipOptions={{ position: "bottom" }}
				>
					<i className="pi pi-paperclip" />
				</Button>
				<Button
					className="icon-btn  p-button-success"
					tooltip="Agent Commission Structure"
					onClick={() =>
						navigation(`/masters/products/commission/${item.id}`, {
							state: {
								title: item.title,
								alias: item?.alias,
							},
						})
					}
					tooltipOptions={{ position: "bottom" }}
				>
					<i className="pi pi-gift" />
				</Button>
				<Button
					className="icon-btn  "
					tooltip="Employee Commission Structure"
					onClick={() =>
						navigation(
							`/masters/products/employee-commission/${item.id}`
						)
					}
					tooltipOptions={{ position: "bottom" }}
				>
					<i className="pi pi-gift" />
				</Button>
				<Button
					className="icon-btn  "
					tooltip="Add Questions"
					onClick={() =>
						navigation(`/masters/products/questions/${item.id}`, {
							state: item?.title,
						})
					}
					tooltipOptions={{ position: "bottom" }}
				>
					<i className="pi pi-list" />
				</Button>
			</div>
		);
	};

	const allowExpansion = (rowData) => {
		return rowData.subProducts.length > 0;
	};
	const rowExpansionTemplate = (data) => {
		return (
			<div className="w-full">
				<h5>Sub Products for {data.title}</h5>
				<DataTable value={data.subProducts} responsiveLayout="scroll">
					<Column
						header="#"
						headerStyle={{ width: "3em" }}
						body={(data, options) => options.rowIndex + 1}
					></Column>
					<Column field="title" header="Sub Product"></Column>
					<Column
						header="Status"
						body={statusUpdateTemplate}
					></Column>
					<Column
						header="Action"
						body={actionTemplate}
						style={{ width: "130px" }}
					></Column>
				</DataTable>
			</div>
		);
	};

	return (
		<>
			<div className="grid">
				<div className="col-7">
					<Toast ref={toastTL} position="top-left" />
					<Toast ref={toastBC} position="bottom-center" />

					<div className="w-full flex flex-wrap justify-content-between align-items-center ">
						<h3>Products</h3>
						<div className="flex align-items-center">
							<span className="p-input-icon-left">
								<i className="pi pi-search" />
								<InputText
									onChange={debounce(
										(e) => setSearchValue(e.target.value),
										1000
									)}
									placeholder="Search Products"
									className="search-field p-inputtext-sm"
								/>
							</span>
						</div>
					</div>
					{products ? (
						<div className="w-full">
							<DataTable
								headerColumnGroup={headerGroup}
								value={products.data}
								expandedRows={expandedRows}
								onRowToggle={(e) => setExpandedRows(e.data)}
								rowExpansionTemplate={rowExpansionTemplate}
								responsiveLayout="scroll"
								dataKey="id"
								breakpoint="960px"
							>
								<Column
									expander={allowExpansion}
									style={{ width: "3em" }}
								/>
								<Column
									headerStyle={{ width: "3em" }}
									body={(data, options) =>
										options.rowIndex + 1
									}
								></Column>
								<Column field="lineOfBusiness" />
								<Column field="title" />
								{/* <Column body={statusTemplate} /> */}
								<Column
									body={statusUpdateTemplate}
									style={{ width: "50px" }}
									headerClassName="text-center"
								/>
							</DataTable>
							{products.firstPage && products.lastPage ? null : (
								<Paginator
									pageSize={products.pageSize}
									firstPage={products.firstPage}
									lastPage={products.lastPage}
									decrement={decrement}
									increment={increment}
									pagesizechange={pagesizechange}
									pageNo={products.pageNo}
									totalPages={products.totalPages}
								/>
							)}
						</div>
					) : (
						<div className="w-full">
							<DataTable
								value={lodarTblArray}
								responsiveLayout="stack"
								breakpoint="960px"
							>
								<Column
									header="Line Of Business"
									body={skeletonBody}
								/>
								<Column header="Product" body={skeletonBody} />
								<Column header="Status" body={skeletonBody} />
							</DataTable>
						</div>
					)}
				</div>

				<div className="col-5">
					<div>
						<h3>Master Products</h3>
						<MasterProducts
							handelImportProduct={handelImportProduct}
							selectedProducts={selectedProducts}
						/>

						<Button
							label="Add Product"
							icon="pi pi-plus"
							iconPos="right"
							className="primary-btn"
							onClick={showConfirm}
							disabled={
								addedProducts && addedProducts.length > 0
									? false
									: true
							}
						/>
					</div>
				</div>

				{spinner && <Loader />}
				{loader && <Loader />}
				<Dialog
					header="Product Status"
					visible={productStatusDialog}
					style={{ width: "30%" }}
					breakpoints={{ "960px": "90vw" }}
					onHide={() => setProductStatusDialog(false)}
				>
					<ProductStatus id={subProductId} />
				</Dialog>

				<Dialog
					header="Product Purpose"
					visible={productReasonStatusDialog}
					style={{ width: "50%", maxWidth: "650px" }}
					breakpoints={{ "960px": "90vw" }}
					onHide={() => setProductReasonStatusDialog(false)}
				>
					<ReasonCreate id={subProductReasonId} />
				</Dialog>
				<Dialog
					header="Product Document"
					visible={productDocumentDialog}
					style={{ width: "50%" }}
					breakpoints={{ "960px": "90vw" }}
					onHide={() => setProductDocumentDialog(false)}
				>
					<ProductDocumentDialoge
						id={subProductDocument}
						productDocumentClose={productDocumentClose}
					/>
				</Dialog>
				{/* <Dialog
					header="Agent Commission Structure"
					visible={commissionStructureDialog}
					breakpoints={{ "960px": "90vw" }}
					style={{ width: "80%" }}
					onHide={() => {
						setCommissionStructureDialog(false);
						// setCommissionStructureData({
						// 	type: Config.FETCH_CONFIG.ini,
						// });
					}}
				> */}
				{/* {commissionStructureData.data &&
					commissionStructureData.isLoading ? (
						<div className="card flex justify-content-center">
							<ProgressSpinner
								style={{ width: "50px", height: "150px" }}
								strokeWidth="4"
							/>
						</div>
					) : (
						<ProductCommissionStructure
							id={commissionStructure}
							commissionStructureData={
								commissionStructureData.data
							}
							// submitAgentCommissionStructure={
							// 	submitAgentCommissionStructure
							// }
							agentCommissionDialogeClose={
								agentCommissionDialogeClose
							}
						/>
					)} */}

				{/* <ProductCommissionStructure
						id={commissionStructure}
						// commissionStructureData={commissionStructureData.data}
						agentCommissionDialogeClose={
							agentCommissionDialogeClose
						}
						// isLoading={commissionStructureData.isLoading}
					/> */}
				{/* </Dialog> */}
			</div>
		</>
	);
};

export default Products;
