import React, { useEffect, useState, useRef } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { InstitutionService } from "../../../services/InstitutionService";
import BasicDetails from "./components/BasicDetails";
import ContactDetails from "./components/ContactDetails";
import ContractDetails from "./components/ContractDetails";

const InstitutionDetails = ({ refressList, id }) => {
	const toast = useRef(null);
	const institutionService = new InstitutionService();
	const [data, setData] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);
	useEffect(() => {
		setLoading(true);
		getInstitution(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const getInstitution = (id) => {
		institutionService
			.getInstitutionById(id)
			.then((res) => {
				setLoading(false);
				setData(res);
			})
			.catch((e) => {
				setLoading(false);
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			});
	};
	const updateBasicDetails = (req) => {
		let payload = {
			searchRequest: [],
			pageNo: 1,
			pageSize: 10,
			sorts: [],
		};
		setLoading(true);
		institutionService
			.updateInstitutionBasicDetails(req)
			.then((res) => {
				if (res) {
					getInstitution(id);
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: "Details Updated",
						life: 3000,
					});
					refressList(payload);
				}
			})
			.catch((e) => {
				setLoading(false);
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			});
	};
	const updateContact = (req) => {
		setLoading(true);
		institutionService
			.updateInstitutionContact(req)
			.then((res) => {
				if (res) {
					getInstitution(id);
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: "Contact Updated",
						life: 3000,
					});
				}
			})
			.catch((e) => {
				setLoading(false);
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			});
	};
	const addContact = (req) => {
		setLoading(true);
		institutionService
			.addInstitutionContact(req)
			.then((res) => {
				if (res) {
					getInstitution(id);
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: "Contact Added",
						life: 3000,
					});
				}
			})
			.catch((e) => {
				setLoading(false);
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			});
	};
	const addContract = (req) => {
		setLoading(true);
		institutionService
			.addInstitutionContract(req)
			.then((res) => {
				if (res) {
					getInstitution(id);
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: "Contract Added",
						life: 3000,
					});
				}
			})
			.catch((e) => {
				setLoading(false);
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			});
	};

	const updateContract = (e) => {
		setLoading(true);
		institutionService
			.updateInstitutionContracted(e)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Successful",
					detail: "Contract Details Update Successfully",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoading(false));
	};
	return (
		<>
			{isLoading && !data && (
				<div className="flex card modal-form-grid">
					<ProgressSpinner
						style={{
							width: "50px",
							height: "50px",
							marginTop: "1rem",
							textAlign: "center",
						}}
						strokeWidth="4"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				</div>
			)}
			<Toast ref={toast} />
			{data ? (
				<TabView
					activeIndex={activeIndex}
					onTabChange={(e) => setActiveIndex(e.index)}
				>
					<TabPanel header="Basic Details">
						<BasicDetails
							data={data.institutionDetail}
							id={id}
							handelBasicDetails={updateBasicDetails}
							isLoading={isLoading}
						/>
					</TabPanel>
					<TabPanel header="Contacts">
						<ContactDetails
							data={data.institutionContact}
							id={id}
							handelContact={updateContact}
							handelContactAdd={addContact}
							isLoading={isLoading}
						/>
					</TabPanel>
					<TabPanel header="Contracts">
						<ContractDetails
							data={data.institutionContract}
							id={id}
							handelContractAdd={addContract}
							handelContractUpdate={updateContract}
							isLoading={isLoading}
						/>
					</TabPanel>
				</TabView>
			) : null}
		</>
	);
};

export default InstitutionDetails;
