import React, { useEffect, useReducer, useRef, useState } from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import * as Yup from "yup";

import { ProgressSpinner } from "primereact/progressspinner";
import { Divider } from "primereact/divider";

import moment from "moment";
import debounce from "lodash.debounce";
import { useGetAllRoleQuery } from "../../../../services/query/queryApi";
import { ProductService } from "../../../../services/ProductService";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
} from "../../../../components/FieldInput";
import { dataReducer } from "../../../../components/fetchReducer";
import Config from "../../../../shared/config";

const ProductEmployeeCommissionStructure = (props) => {
	const {
		data = [],
		isLoading: isSpinning,
		isSuccess,
	} = useGetAllRoleQuery();
	const [loading, setLoading] = useState(false);
	const productService = new ProductService();
	const toastTL = useRef(null);
	const initialValue = {
		effectiveStartDate: "",
		effectiveEndDate: "",
		commissionType: "percentage",
		productEmployeeCommissionDetailsDtos: [],
	};
	const validationSchema = Yup.object().shape({
		commissionType: Yup.string().required("This field is required"),
		effectiveStartDate: Yup.string().required("This field is required"),
		effectiveEndDate: Yup.string()
			.when("effectiveStartDate", (effectiveStartDate, value) => {
				return value.test({
					test: (effectiveEndDate) =>
						!!new Date(effectiveStartDate) &&
						new Date(effectiveEndDate) >
							new Date(effectiveStartDate),
					message: "Invalid date",
				});
			})
			.required("This field is required"),
		productEmployeeCommissionDetailsDtos: Yup.array().when(
			"commissionType",
			{
				is: (commissionType) => commissionType === "percentage",
				then: Yup.array().of(
					Yup.object().shape({
						minAmount: Yup.number()
							.min(0, "Invalid Amount")
							.max(100, "Maximum 100")
							.required("This field is required"),
						maxAmount: Yup.number()
							.min(0, "Invalid Amount")
							.max(100, "Maximum 100")
							.when(["minAmount"], (min, value) => {
								return value.test({
									test: (max) => !!min && max > min,
									message: "Max amount > Min amount",
								});
							})
							.required("This field is required"),
						defaultAmount: Yup.number()
							.when(
								["minAmount", "maxAmount"],
								(min, max, value) => {
									return value.test({
										test: (def) => {
											return (
												!!min &&
												!!max &&
												max >= def &&
												def >= min
											);
										},
										message: "Max > Def > Min ",
									});
								}
							)
							.required("This field is required"),
						remarks: Yup.string().required(
							"This field is required"
						),
						agentCommissionStatus: Yup.string().required(
							"This field is required"
						),
					})
				),
				otherwise: Yup.array().of(
					Yup.object().shape({
						minAmount: Yup.number()
							.min(0, "Invalid Amount")
							.required("This field is required"),
						maxAmount: Yup.number()
							.min(0, "Invalid Amount")
							.when(["minAmount"], (min, value) => {
								return value.test({
									test: (max) => !!min && max > min,
									message: "Max amount > Min amount",
								});
							})
							.required("This field is required"),
						defaultAmount: Yup.number()
							.when(
								["minAmount", "maxAmount"],
								(min, max, value) => {
									return value.test({
										test: (def) => {
											return (
												!!min &&
												!!max &&
												max >= def &&
												def >= min
											);
										},
										message: "Max > Def > Min ",
									});
								}
							)
							.required("This field is required"),
						remarks: Yup.string().required(
							"This field is required"
						),
						agentCommissionStatus: Yup.string().required(
							"This field is required"
						),
					})
				),
			}
		),
	});
	const [lastDate, setLastDate] = useReducer(dataReducer, {
		isLoading: false,
		data: {},
	});
	const getLatestDate = () => {
		setLastDate({ type: Config.FETCH_CONFIG.start });
		productService
			.getLastEmployeeCommissionDate(props.id)
			.then((res) =>
				setLastDate({ type: Config.FETCH_CONFIG.success, payload: res })
			)
			.finally(() => setLastDate({ type: Config.FETCH_CONFIG.stop }));
	};
	useEffect(() => {
		if (props.type === "create") {
			getLatestDate();
		}
	}, []);

	const handleFormSubmit = (value, onSubmitProps) => {
		if (props.type === "create") {
			const submitAgentCommissionStructure = {
				productId: Number(props.id),
				...value,
				effectiveStartDate: value.effectiveStartDate
					? moment(value.effectiveStartDate).format("YYYY-MM-DD")
					: null,
				effectiveEndDate: value.effectiveEndDate
					? moment(value.effectiveEndDate).format("YYYY-MM-DD")
					: null,
			};
			setLoading(true);
			productService
				.createEmployeeCommissionStructure(
					submitAgentCommissionStructure
				)
				.then((res) => {
					if (res) {
						toastTL.current.show({
							severity: "success",
							summary: "Success",
							detail: "Successfully",
							life: 3000,
						});
					}

					props.dialogDispatch({ type: "data", data: {} });
				})
				.then(() => props.searchDispatch())
				.then(
					debounce(() => {
						props.dialogDispatch({ type: "dialog", dialog: false });
					}, 1000)
				)
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						life: 3000,
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			const { effectiveStartDate, effectiveEndDate, ...rest } = value;
			setLoading(true);
			productService
				.updateEmployeeCommissionStructure({
					...rest,
					effectiveStartDate: effectiveStartDate
						? moment(effectiveStartDate).format("YYYY-MM-DD")
						: null,
					effectiveEndDate: effectiveEndDate
						? moment(effectiveEndDate).format("YYYY-MM-DD")
						: null,
				})
				.then((res) => {
					if (res) {
						toastTL.current.show({
							severity: "success",
							summary: "Success",
							detail: "Successfully",
							life: 3000,
						});
					}

					props.searchDispatch();
					props.dialogDispatch({ type: "data", data: {} });
				})
				.then(() => {
					props.dialogDispatch({ type: "dialog", dialog: false });
				})
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						life: 3000,
					});
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	return (
		<div className="card modal-form-grid">
			<Toast ref={toastTL} position="top-left" />
			{props.isLoading ? (
				<div className="flex align-items-center justify-content-center py-5">
					<ProgressSpinner
						style={{
							width: "40px",
							height: "40px",
						}}
						strokeWidth="4"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				</div>
			) : (
				<Formik
					initialValues={
						props.type === "create"
							? {
									...initialValue,
									...(!isSpinning &&
										data.length > 0 && {
											effectiveStartDate: "",
											productEmployeeCommissionDetailsDtos:
												data.map((item) => ({
													roleId: item.id,
													minAmount: "",
													defaultAmount: "",
													maxAmount: "",
													agentCommissionStatus: "",
													remarks: "",
												})),
										}),
							  }
							: {
									...initialValue,
									...(props.initialValue && {
										...props.initialValue,
										effectiveStartDate: props.initialValue
											.effectiveStartDate
											? new Date(
													props.initialValue.effectiveStartDate
											  )
											: "",
										effectiveEndDate: props.initialValue
											.effectiveEndDate
											? new Date(
													props.initialValue.effectiveEndDate
											  )
											: "",
									}),
							  }
					}
					onSubmit={handleFormSubmit}
					validationSchema={validationSchema}
					enableReinitialize
				>
					{({ values, handleSubmit, resetForm }) => (
						<Form className="" onSubmit={handleSubmit}>
							<div className="form-grid grid">
								<div className="field col-12 md:col-4">
									<Field
										name={`effectiveStartDate`}
										component={FeildCalender}
										header={"Effective Start Date"}
										minDate={
											lastDate.data.effectiveEndDate
												? new Date(
														lastDate.data.effectiveEndDate
												  )
												: null
										}
										viewDate={
											lastDate.data.effectiveEndDate
												? new Date("2023-10-06")
												: null
										}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										name={`effectiveEndDate`}
										component={FeildCalender}
										header={"Effective End Date"}
										viewDate={
											values.effectiveStartDate
												? new Date(
														values.effectiveStartDate
												  )
												: null
										}
										minDate={
											values.effectiveStartDate
												? new Date(
														values.effectiveStartDate
												  )
												: null
										}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										name={`commissionType`}
										component={FeildDropdown}
										header={"Commission Type"}
										options={[
											{
												label: "Fixed",
												value: "fixed",
											},
											{
												label: "Percentage",
												value: "percentage",
											},
										]}
										star={"*"}
									/>
								</div>
							</div>

							{!isSpinning && data.length && (
								<FieldArray
									name="productEmployeeCommissionDetailsDtos"
									render={(arrayHelper) => (
										<>
											{
												isSuccess &&
													values.productEmployeeCommissionDetailsDtos.map(
														(item, index) => (
															<div
																className="form-grid grid"
																key={
																	index.toString() +
																	"mapValueCommision"
																}
															>
																<div className="field col-12 md:col-2 flex align-items-center">
																	<h3 className="m-0">
																		{props.type ===
																		"create"
																			? data[
																					index
																			  ]
																					.name
																			: item.roleName}
																	</h3>
																</div>

																<div className="field col-12 md:col-2">
																	<Field
																		name={`productEmployeeCommissionDetailsDtos.${index}.minAmount`}
																		component={
																			FieldInput
																		}
																		header={
																			"Min Sell Amount"
																		}
																		type={
																			"number"
																		}
																		star={
																			"*"
																		}
																	/>
																</div>
																<div className="field col-12 md:col-2">
																	<Field
																		name={`productEmployeeCommissionDetailsDtos.${index}.defaultAmount`}
																		component={
																			FieldInput
																		}
																		header={
																			"Default Amount"
																		}
																		type={
																			"number"
																		}
																		star={
																			"*"
																		}
																	/>
																</div>
																<div className="field col-12 md:col-2">
																	<Field
																		name={`productEmployeeCommissionDetailsDtos.${index}.maxAmount`}
																		component={
																			FieldInput
																		}
																		header={
																			"Max Sell Amount"
																		}
																		type={
																			"number"
																		}
																		star={
																			"*"
																		}
																	/>
																</div>
																{/* <div className="field col-12 md:col-2">
																<Field
																	header={`Commission(${
																		values.type ===
																		"fixed"
																			? "₹"
																			: "%"
																	})`}
																	name={`productEmployeeCommissionDetailsDtos.${index}.percentage`}
																	component={
																		FieldInput
																	}
																	type={
																		"number"
																	}
																/> 
																</div>
																*/}
																<div className="field col-12 md:col-2">
																	<Field
																		name={`productEmployeeCommissionDetailsDtos.${index}.agentCommissionStatus`}
																		component={
																			FeildDropdown
																		}
																		header={
																			"Status"
																		}
																		options={[
																			{
																				label: "Active",
																				value: "ACTIVE",
																			},
																			{
																				label: "Inactive",
																				value: "INACTIVE",
																			},
																			{
																				label: "Reject",
																				value: "REJECT",
																			},
																		]}
																		star={
																			"*"
																		}
																		disabled={
																			props.type !==
																			"create"
																		}
																	/>
																</div>
																<div className="field col-12 md:col-2">
																	<Field
																		name={`productEmployeeCommissionDetailsDtos.${index}.remarks`}
																		component={
																			FieldInput
																		}
																		header={
																			"Remarks"
																		}
																		star={
																			"*"
																		}
																		disabled={
																			props.type !==
																			"create"
																		}
																	/>
																</div>
																{/* <Button
																	type="button"
																	onClick={() =>
																		arrayHelper.remove(
																			index
																		)
																	}
																	style={{
																		marginTop:
																			"1.5rem",
																	}}
																	icon="pi pi-trash"
																	className="p-button-outlined p-button-danger p-button-sm"
																	aria-label="Cancel"
																/> */}

																{/* <div className="col-1 pt-5">
															
														</div> */}
																<Divider />
															</div>
														)
													)
												// : null
											}
											{/* <Button
										type="button"
										label="Add Commission Slabs"
										icon="pi pi-plus"
										className=""
										onClick={() =>
											arrayHelper.push({
												minSellAmount: "",
												maxSellAmount: "",
												percentage: "",
											})
										}
									/> */}
											{/* <div
											className="add-more-btn flex align-items-center justify-content-center"
											onClick={() =>
												arrayHelper.push({
													minSellAmount: "",
													maxSellAmount: "",
													percentage: "",
												})
											}
										>
											<i className="pi pi-plus"></i>
											<span className="ml-2">
												Commission Slabs
											</span>
										</div> */}
										</>
									)}
								/>
							)}
							<div className="flex justify-content-end align-items-center w-full mt-4">
								<Button
									type="button"
									label="Reset"
									icon="pi pi-times"
									className="danger-btn mr-2"
									onClick={() => {
										resetForm();
									}}
									style={{
										borderRadius: "6px",
									}}
								/>
								<Button
									type="submit"
									label="Save"
									icon="pi pi-save"
									style={{ borderRadius: "6px" }}
									loading={loading}
								/>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</div>
	);
};

export default ProductEmployeeCommissionStructure;
