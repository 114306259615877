import { useEffect, useReducer, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";

import { Skeleton } from "primereact/skeleton";
import { Dialog } from "primereact/dialog";
import { SupportService } from "../../../../services/SupportService";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import { searchReducer } from "../../../../components/searchReducer";
import Config from "../../../../shared/config";
import Paginator from "../../../../components/Paginator";
import {
	FieldEditor,
	FieldInput,
	FieldMultiselect,
} from "../../../../components/FieldInput";
import SearchTutorial from "./SearchTutorial";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { produce } from "immer";
import { Toast } from "primereact/toast";
import { ZoneService } from "../../../../services/ZoneService";
import Loader from "../../../../components/Loader";
import * as Yup from "yup";
const TutorialList = ({ value = { id: null } }) => {
	const supportService = new SupportService();
	const toastTL = useRef(null);
	const roleService = new ZoneService();
	const [roleState, roleDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const [addDialog, setAddDialog] = useReducer(
		produce((draft, action) => {
			switch (action.type) {
				case "dialog":
					draft.dialog = action.value;
					break;
				case "data":
					draft.data = action.value;
					break;
				case "type":
					draft.type = action.value;
					break;
				default:
					return draft;
			}
		}),
		{ dialog: false, data: null, type: "Create" }
	);
	const [selectedCategoryList, setselectedCategoryList] = useReducer(
		dataReducer,
		{ data: { data: [] }, isLoading: false, isFetched: false }
	);
	const initialValue = {
		typeId: value.id,
		roleId: "",
		question: "",
		htmlEditor: "",
		videoAttachment: "",
	};
	const [searchState, searchDispatch] = useReducer(searchReducer, {
		searchValue: "",
		pageNo: 1,
		pageSize: 10,
	});
	const [spinner, setSpinner] = useState(false);
	const getCategoryData = (e) => {
		if (e.id) {
			const reqBody = {
				pageNo: searchState.pageNo,
				pageSize: searchState.pageSize,
				typeId: e.id,
				...(searchState.searchValue !== "" && searchState.searchValue),
			};
			setselectedCategoryList({ type: Config.FETCH_CONFIG.start });
			supportService
				.getTutorialListById(reqBody)
				.then((res) => {
					setselectedCategoryList({
						type: Config.FETCH_CONFIG.success,
						payload: res,
					});
				})
				.finally(() => {
					setselectedCategoryList({
						type: Config.FETCH_CONFIG.fetched,
					});
					setselectedCategoryList({ type: Config.FETCH_CONFIG.stop });
				});
		}
	};
	useEffect(() => {
		getCategoryData(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, searchState]);
	useEffect(() => {
		let subscribe = true;
		if (subscribe) {
			roleDispatch({ type: Config.FETCH_CONFIG.start });
			roleService
				.getRole()
				.then((res) =>
					roleDispatch({
						type: Config.FETCH_CONFIG.success,
						payload: res,
					})
				)
				.catch((err) => {})
				.finally(() =>
					roleDispatch({ type: Config.FETCH_CONFIG.stop })
				);
		}
		return () => {
			subscribe = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleTutorialDialog = (type, value) => {
		setAddDialog({ type: "dialog", value: true });
		if (type === "edit" && selectedCategoryList.data.data) {
			setAddDialog({ type: "type", value: "Edit" });
			const data = selectedCategoryList.data.data.find(
				(item) => item.id === value.id
			);
			if (data) {
				setAddDialog({ type: "data", value: data });
			}
		} else {
			setAddDialog({ type: "type", value: "Create" });
			setAddDialog({ type: "data", value: null });
		}
	};

	const handleFormSubmit = (e) => {
		if (addDialog.type === "Create") {
			setSpinner(true);
			supportService
				.createTutorialList(e)
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Successfull",
						detail: "Created successfully",
						style: { color: "#000000" },
						life: 3000,
					});
					setSpinner(false);
					setAddDialog({ type: "dialog", value: false });
				})
				.catch((err) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: "Error",
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => {
					setSpinner(false);
					getCategoryData(value);
					// setAddDialog({ type: "dialog", value: false });
				});
		} else {
			setSpinner(true);
			supportService
				.updateTutorialList(e)
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Successfull",
						detail: "Updated successfully",
						style: { color: "#000000" },
						life: 3000,
					});
					setAddDialog({ type: "dialog", value: false });
				})
				.catch(() =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: "Error",
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => {
					setSpinner(false);
					getCategoryData(value);
					// setAddDialog({ type: "dialog", value: false });
				});
		}
	};

	const increment = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const validationSchema = Yup.object().shape({
		roleId: Yup.array()
			.of(Yup.number())
			.min(1, "Select One")
			.required("This field is required"),
		question: Yup.string().required("This field is required"),
		htmlEditor: Yup.string().required("This field is required"),
		videoAttachment: Yup.string().required("This field is required"),
	});
	const pagesizechange = (e) => {
		// const newPageNo = Math.ceil(
		// 	(members.data.pageSize * members.data.pageNumber) / e.target.value
		// );

		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};
	const action = (item) => {
		return (
			<Button
				className="p-button-text icon-btn"
				icon={"pi pi-pencil"}
				onClick={() => handleTutorialDialog("edit", item)}
			/>
		);
	};
	const skeletonTemplate = (item) => {
		return <Skeleton className="flex-1" />;
	};
	return (
		<>
			<Toast ref={toastTL} />
			<Dialog
				header={addDialog.type + " Tutorial"}
				visible={addDialog.dialog}
				onHide={() => setAddDialog({ type: "dialog", value: false })}
				breakpoints={{
					"960px": "95vw",
				}}
				style={{ width: "50%" }}
			>
				{spinner && <Loader />}
				<Formik
					initialValues={{
						...initialValue,
						...(addDialog.data && { ...addDialog.data }),
					}}
					onSubmit={handleFormSubmit}
					validationSchema={validationSchema}
					enableReinitialize
				>
					{({
						handleSubmit,

						resetForm,
					}) => (
						<Form
							onSubmit={handleSubmit}
							className="card modal-form-grid"
						>
							<div className="grid form-grid">
								<div className="field col-12">
									{roleState.isLoading ? (
										<Skeleton className="w-full flex-1 h-3rem mt-4 " />
									) : (
										<Field
											header={"Select Role"}
											component={FieldMultiselect}
											name={`roleId`}
											options={roleState.data}
											optionLabel={"name"}
											optionValue={"id"}
										/>
									)}
								</div>
								<div className="field col-12">
									<Field
										header={"Question"}
										component={FieldInput}
										name={`question`}
									/>
								</div>
								<div className="field col-12">
									<Field
										header={"HTML Editor"}
										component={FieldEditor}
										name={`htmlEditor`}
									/>
								</div>
								<div className="field col-12">
									<Field
										header={"Video Link"}
										component={FieldInput}
										name={`videoAttachment`}
									/>
								</div>
								<div className="flex justify-content-end align-items-center w-full mt-4">
									<Button
										type="button"
										label="Reset"
										icon="pi pi-refresh"
										className="danger-btn mr-2"
										onClick={() => resetForm()}
										style={{
											borderRadius: "6px",
										}}
									/>
									<Button
										loading={spinner}
										type="submit"
										label="Save"
										icon="pi pi-save"
										style={{ borderRadius: "6px" }}
									/>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</Dialog>
			<SearchTutorial
				searchDispatch={searchDispatch}
				setAddDialog={handleTutorialDialog}
				role={roleState.data}
			/>

			<div className="w-full h-full card">
				{selectedCategoryList.isLoading ? (
					<>
						<DataTable
							value={Array(5)
								.fill()
								.map(() => ({ loading: true }))}
						>
							<Column
								body={skeletonTemplate}
								header={"Question"}
							/>
							<Column body={skeletonTemplate} header={"Edit"} />
						</DataTable>
					</>
				) : (
					selectedCategoryList.isFetched && (
						<>
							<DataTable value={selectedCategoryList.data.data}>
								<Column field="question" header={"Question"} />
								<Column body={action} header={"Edit"} />
							</DataTable>

							{selectedCategoryList.data.data.length > 10 && (
								<Paginator
									pageSize={searchState.pageSize}
									firstPage={
										selectedCategoryList.data.firstPage
									}
									lastPage={
										selectedCategoryList.data.lastPage
									}
									decrement={decrement}
									increment={increment}
									pagesizechange={pagesizechange}
									pageNo={selectedCategoryList.data.pageNo}
									totalPages={
										selectedCategoryList.data.totalPages
									}
								/>
							)}
						</>
					)
				)}
			</div>
		</>
	);
};

export default TutorialList;
