import { useEffect, useReducer } from "react";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import Config from "../../../../shared/config";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { UsersService } from "../../../../services/UsersService";
import AsmDetails from "./AsmDetails";
const RsmDetails = (props) => {
	const userService = new UsersService();
	const [userState, userDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);

	const getData = () => {
		userDispatch({
			type: Config.FETCH_CONFIG.start,
		});
		userService
			.getChildUser(props.userName)
			.then((res) => {
				userDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((e) => console.log(e))
			.finally(() =>
				userDispatch({
					type: Config.FETCH_CONFIG.stop,
				})
			);
	};
	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{userState.isLoading ? (
				<div className="flex align-items-center justify-content-center py-5">
					<ProgressSpinner
						style={{
							width: "40px",
							height: "40px",
						}}
						strokeWidth="4"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				</div>
			) : (
				userState.data.map((item) => (
					<Panel
						key={item.id}
						header={`${item.firstName ? item.firstName : ""} ${
							item.middleName ? item.middleName : ""
						} ${item.lastName ? item.lastName : ""} - (${
							item.areaList &&
							item.areaList.map((e) => {
								return e.name + " ";
							})
						})`}
						toggleable
						collapsed
					>
						<AsmDetails userName={item.userName} />
					</Panel>
				))
			)}
		</>
	);
};

export default RsmDetails;
