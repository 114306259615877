import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import S3 from "aws-sdk/clients/s3";
import moment from "moment/moment";
import { FiFileText } from "react-icons/fi";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";

import Config from "../../../../shared/config";

const s3Clint = new S3({
	accessKeyId: Config.S3_ACCESSKEYID,
	secretAccessKey: Config.S3_SECRETACCESSKEY,
	region: Config.S3_REGION,
	signatureVersion: "v4",
});

const ContractDetails = ({
	data,
	id,
	isLoading,
	handelContractAdd,
	handelContractUpdate,
}) => {
	const toastTL = useRef(null);
	const [contractFilterData, setContractFilterData] = useState({});
	const [submitType, setSubmitType] = useState("create");

	const formik = useFormik({
		initialValues:
			submitType === "edit"
				? {
						...contractFilterData,
						startDate: new Date(contractFilterData.startDate),
						endDate: new Date(contractFilterData.endDate),
				  }
				: { startDate: null, endDate: null, contractDocName: null },
		enableReinitialize: true,
		validate: (data) => {
			let errors = {};

			if (!data.startDate) {
				errors.startDate = "Start date is required.";
			}
			if (!data.endDate) {
				errors.endDate = "End date is required";
			} else if (moment(data.endDate).isBefore(data.startDate)) {
				errors.endDate = "End date can not be less then start date";
			}
			if (!data.contractDocName) {
				errors.contractDocName = "Contract document is required";
			}
			return errors;
		},
		onSubmit: (data) => {
			const requestBody = {
				institutionId: id,
				contractDocName: data.contractDocName,
				contractStartDate: moment(data.startDate).format("YYYY-MM-DD"),
				contractEndDate: moment(data.endDate).format("YYYY-MM-DD"),
			};

			if (submitType === "create") {
				addContract(requestBody);
			} else {
				let requestEdit = {
					...requestBody,
					id: contractFilterData.id,
					isDelete: false,
				};
				handelContractUpdate(requestEdit);
			}
		},
	});

	const addContract = (req) => {
		handelContractAdd(req);
	};

	const isFormFieldValid = (name) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};

	const onUpload = (e) => {
		// setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.INSTITUTION_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};
		if (contractFilterData) {
			s3Clint.deleteObject(
				{
					Bucket: Config.INSTITUTION_DOCS_BUCKET,
					Key: contractFilterData.contractDocName,
				},
				(err, data) => {
					console.log(err);
					console.log(data);
				}
			);
		}
		s3Clint.upload(params, (err, data) => {
			if (err) {
				// setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				// setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successful",
					detail: "Contract document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});
				formik.setFieldValue("contractDocName", newFileName);
			}
		});
	};

	const startDateTemplate = (item) => {
		return moment(item.startDate).format("Do MMM, YYYY");
	};
	const endDateTemplate = (item) => {
		return moment(item.endDate).format("Do MMM, YYYY");
	};
	const docTemplate = (item) => {
		const url = `${Config.AGENT_BUCKET_ACCESS_URL}${Config.INSTITUTION_DOCS_FOLDER}/${item.contractDocName}`;
		return (
			<a
				href={url}
				target="_blank"
				rel="noreferrer"
				style={{ fontSize: "20px" }}
			>
				<FiFileText />
			</a>
		);
	};

	const contractEdit = (e) => {
		const filterData = data.find((item) => item.id === e);
		if (filterData !== undefined) setContractFilterData(filterData);
		console.log(filterData);
	};
	const actionTemplate = (item) => {
		return (
			<>
				<Button
					label=""
					// icon="pi pi-bars"
					className="p-button-rounded p-button-text"
					icon="pi pi-pencil"
					onClick={() => {
						contractEdit(item.id);
						setSubmitType("edit");
					}}
					aria-controls="popup_menu"
					aria-haspopup
				/>
			</>
		);
	};

	return (
		<div className="grid">
			<Toast ref={toastTL} position="top-left" />
			{/* {isLoading && <Loader />} */}
			<div className="col-12">
				<DataTable value={data} responsiveLayout="scroll">
					<Column
						body={startDateTemplate}
						header="Start Date"
					></Column>
					<Column body={endDateTemplate} header="End Date"></Column>
					<Column body={docTemplate} header="Document"></Column>
					<Column body={actionTemplate} header="Document"></Column>
				</DataTable>
			</div>
			<div className="col-12">
				<form onSubmit={formik.handleSubmit} className="p-fluid grid">
					<FocusError formik={formik} />
					<div className="field col-12 md:col-6 lg:col-4">
						<label htmlFor="startDate">Start Date*</label>
						<Calendar
							className={classNames({
								"p-invalid": isFormFieldValid("startDate"),
							})}
							name="startDate"
							value={formik.values.startDate}
							onChange={formik.handleChange}
							dateFormat="dd/mm/yy"
							minDate={new Date()}
							showIcon
						/>
						{getFormErrorMessage("startDate")}
					</div>
					<div className="field col-12 md:col-6 lg:col-4">
						<label htmlFor="endDate">End Date*</label>
						<Calendar
							name="endDate"
							className={classNames({
								"p-invalid": isFormFieldValid("endDate"),
							})}
							value={formik.values.endDate}
							onChange={formik.handleChange}
							dateFormat="dd/mm/yy"
							minDate={new Date()}
							showIcon
						/>
						{getFormErrorMessage("endDate")}
					</div>
					<div className="field col-12 md:col-12 lg:col-8">
						<label htmlFor="contractDocName">
							Upload Document*
						</label>
						<FileUpload
							name="contractDocName"
							customUpload
							accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
							uploadHandler={onUpload}
							maxFileSize={2000000}
							emptyTemplate={
								<p className="m-0">
									Drag and drop files to here to upload.
								</p>
							}
						/>
						{getFormErrorMessage("contractDocName")}
						<p className="p-error">
							Notes: <br />
							1) Please upload the document first before submiting
							the form. <br /> 2) Upload document maximum size 2
							mb.
						</p>
					</div>

					<div className="col-12 md:col-12 lg:col-12 mt-3 flex justify-content-end">
						<div className="flex justify-content-end align-items-center  mt-4">
							<Button
								type="submit"
								// label="Save"
								label={
									submitType === "create" ? "Save" : "Update"
								}
								// style={{ borderRadius: "6px" }}
								icon="pi pi-save"
								loading={isLoading}
							/>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ContractDetails;
