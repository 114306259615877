import React from "react";
import Insurance from "./Insurance";
import { useParams } from "react-router-dom";

const HospicasEdit = () => {
	const { id } = useParams();
	return <Insurance propsId={id} />;
};

export default HospicasEdit;
