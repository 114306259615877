import React, { useEffect, useReducer, useState } from "react";
import { ProgramService } from "../../../../services/ProgramService";
import { Button } from "primereact/button";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import Config from "../../../../shared/config";
import { Divider } from "primereact/divider";
import { ProgressSpinner } from "primereact/progressspinner";
import OfferUpdate from "./components/OfferUpdate";

const HandleOffer = ({ programId }) => {
	const programService = new ProgramService();
	const [open, setOpen] = useState(false);
	const [offerState, offerDispatch] = useReducer(dataReducer, {
		data: {},
		isLoading: false,
		error: false,
		errorMessage: null,
		isFetched: false,
	});
	const getData = () => {
		offerDispatch({ type: Config.FETCH_CONFIG.start });
		programService
			.getFullProgramData(programId)
			.then((res) => {
				const { programOfferDtoList, alias } = res;
				offerDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: {
						programOfferDtoList: programOfferDtoList,
						alias: alias,
					},
				});
			})
			.catch((er) => {})
			.finally(() => {
				offerDispatch({ type: Config.FETCH_CONFIG.stop });
			});
	};
	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="w-full flex align-items-center justify-content-end">
				<Button
					icon={open ? "pi pi-times" : "pi pi-plus"}
					label={open ? "" : "Add"}
					className={open ? "p-button-danger p-button-text" : ""}
					onClick={() => setOpen((p) => !p)}
				/>
			</div>
			{open && (
				<OfferUpdate
					programId={programId}
					getData={getData}
					alias={offerState.data.alias}
				/>
			)}
			<Divider align={"left"}>Offer List</Divider>
			{offerState.isLoading ? (
				<div className="flex align-items-center justify-content-center py-5">
					<ProgressSpinner
						style={{ height: "30px" }}
						strokeWidth="5"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				</div>
			) : (
				offerState.data.programOfferDtoList &&
				offerState.data.programOfferDtoList.map((item, index) => (
					<div key={index}>
						<h3 className="mb-0">{index + 1}.</h3>
						<OfferUpdate type={"edit"} item={item} />
					</div>
				))
			)}
		</>
	);
};

export default HandleOffer;
