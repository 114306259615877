import React, { useRef, useState, useReducer, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { UsersService } from "../../../../services/UsersService";
import TableLoader from "../../../../components/TableLoader";

import { Skeleton } from "primereact/skeleton";

import { LeadService } from "../../../../services/LeadService";
import TableNoData from "../../../../components/TableNoData";
import { useSelector } from "react-redux";
import { produce } from "immer";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { FilterMatchMode } from "primereact/api";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import Config from "../../../../shared/config";
import isEmpty from "lodash/isEmpty";

import { InputText } from "primereact/inputtext";
import Loader from "../../../../components/Loader";
import { useGetAllRoleQuery } from "../../../../services/query/queryApi";
import { createSelector } from "reselect";
import { useNavigate } from "react-router-dom";
const AllocateLeadDialog = () => {
	const userservice = new UsersService();
	const leadService = new LeadService();
	const navigate = useNavigate();
	const toast = useRef(null);
	const [userData, setUserData] = useReducer(dataReducer, fetchInitialState);
	const [allocateDialog, setAllocateDialog] = useState(false);
	const [userType, setUserType] = useState(null);
	const selector = useSelector((state) => state.persistData.auth.user);
	const [leadType, setLeadType] = useState(
		selector.role.position === 4 ? "leadSelect" : "rmSelect"
	);
	const { data = [], isSuccess } = useGetAllRoleQuery();

	const [spinner, setSpinner] = useState(false);
	const reducer = produce((state, action) => {
		switch (action.type) {
			case "SELECTED":
				state.selected = action.payload;
				break;
			case "USER_NAME":
				state.user = action.payload;
				break;
			case "LEAD_LIST":
				state.leadList = action.payload;
				break;
			case "SPINNER":
				state.spinner = action.payload;
				break;
			default:
				return state;
		}
	});
	const [state, dispatch] = useReducer(reducer, {
		selected: [],
		user: null,
		leadList: [],
		spinner: true,
	});
	const [rmState, rmDispatch] = useReducer(reducer, {
		selected: {},
		user: null,
		leadList: [],
		spinner: true,
	});
	const handleSelect = (data) => {
		dispatch({ type: "SELECTED", payload: data });
	};
	const handleSelectRm = (data) => {
		rmDispatch({ type: "SELECTED", payload: data });
	};
	const filter = {
		name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
	};
	const itaration = {
		profileType: "",
		firstName: "",
		pincode: "",
		mobileNumber: "",
		mobileNumbere: "",
	};
	const getUser = () => {
		const param =
			selector.role.position === 4
				? selector.userName
				: rmState.selected.userName;
		userType && setUserData({ type: Config.FETCH_CONFIG.start });

		userType &&
			(userType === "salesExecutive"
				? userservice.getSeByRM(param)
				: userType === "agents" && leadService.getAgent(param)
			)
				.then((res) => {
					setUserData({
						type: Config.FETCH_CONFIG.success,
						payload: res.map((item) => ({
							...item,
							name: `${item.firstName ? item.firstName : ""} ${
								item.middleName ? item.middleName : ""
							} ${item.lastName ? item.lastName : ""}`,
							value: item.userName,
						})),
					});
				})
				.catch((e) => {
					setUserData({
						type: Config.FETCH_CONFIG.error,
						payload: e,
					});
				})
				.finally(() => setUserData({ type: Config.FETCH_CONFIG.stop }));
	};
	const getLeadList = (e) => {
		dispatch({ type: "SPINNER", payload: true });
		dispatch({ type: "SELECTED", payload: [] });

		leadService
			.getUnallocatedLead(e)
			.then((res) => {
				dispatch({ type: "LEAD_LIST", payload: res });
			})
			.catch((e) => {
				toast.current.show([
					{
						severity: "error",
						summary: "Error",
						detail: "Error",
						life: 3000,
					},
				]);
			})
			.finally(() => dispatch({ type: "SPINNER", payload: false }));
	};
	const getRmList = () => {
		rmDispatch({ type: "SPINNER", payload: true });
		rmDispatch({ type: "SELECTED", payload: {} });
		leadService
			.getRmList(selector.userName)
			.then((res) => {
				rmDispatch({ type: "LEAD_LIST", payload: res });
			})
			.catch((e) => {
				toast.current.show([
					{
						severity: "error",
						summary: "Error",
						detail: "Error",
						life: 3000,
					},
				]);
			})
			.finally(() => rmDispatch({ type: "SPINNER", payload: false }));
	};

	const getData = () => {
		selector.role.position === 4
			? getLeadList(selector.userName)
			: getRmList(selector.userName);
	};
	useEffect(() => {
		let ignore = false;
		if (!ignore) {
			getData();
		}
		return () => {
			ignore = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		let ignore = false;
		if (!ignore) {
			getUser();
		}
		return () => {
			ignore = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userType]);

	const nameTemplate = ({ firstName, middleName, lastName }) => {
		return (
			<>
				{firstName ? firstName : ""} {middleName ? middleName : ""}{" "}
				{lastName ? lastName : ""}
			</>
		);
	};

	const handleClick = () => {
		setAllocateDialog(true);
	};
	const handleRmClick = () => {
		setLeadType("leadSelect");
		getLeadList(rmState.selected.userName);
	};
	const handleAllocate = () => {
		setSpinner(true);
		const newArray = state.selected.map((e) => e.leadId);

		const payload = JSON.stringify({
			leadId: newArray,
			userName: state.user,
		});
		leadService
			.allocateLead(payload)
			.then((res) => {
				toast.current.show({
					severity: "success",

					detail: "Saved successfully",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",

					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setSpinner(false);
				setAllocateDialog(false);
				setUserType(null);
				dispatch({ type: "USER_NAME", payload: null });
				if (selector.role.position !== 4) {
					setLeadType("rmSelect");
				} else {
					getLeadList(selector.userName);
				}
			});
	};

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header={"Select"} />
				<Column header={"Interested In"} />

				<Column header={"Name"} />
				<Column header={"Mobile No"} />
				<Column header={"Action"} />
			</Row>
		</ColumnGroup>
	);
	// const onFilterChange = debounce((e) => setFilter(e), 1000);

	const roleName = createSelector(() => {
		const roleName = data.find((item) => item.position === 4);
		if (roleName !== undefined) {
			return roleName.name;
		} else {
			return ".....";
		}
	});
	const editAction = (item) => {
		return (
			<Button
				className="p-button-text icon-button p-0"
				icon="pi pi-pencil"
				tooltip="Edit"
				tooltipOptions={"left"}
				onClick={() =>
					navigate(`/masters/lead/edit-lead/${item.leadId}`)
				}
			/>
		);
	};
	return (
		<>
			<Toast ref={toast} />
			{spinner && <Loader />}
			<Dialog
				visible={allocateDialog}
				onHide={() => {
					setAllocateDialog(false);
					setUserType(null);
					dispatch({ type: "USER_NAME", payload: null });
				}}
				header={"Select User type"}
				style={{ width: "30%" }}
			>
				<div className="pb-5">
					<div className="field-radiobutton">
						<RadioButton
							value="salesExecutive"
							name="city"
							onChange={(e) => {
								setUserType(e.value);
								dispatch({ type: "USER_NAME", payload: null });
							}}
							checked={userType === "salesExecutive"}
						/>
						{isSuccess && (
							<label>
								{data.find((item) => item.position === 5).name}
							</label>
						)}
					</div>
					<div className="field-radiobutton">
						<RadioButton
							value="agents"
							name="city"
							onChange={(e) => {
								setUserType(e.value);
								dispatch({ type: "USER_NAME", payload: null });
							}}
							checked={userType === "agents"}
						/>
						<label>Agents</label>
					</div>
					{userType && (
						<>
							{userData.isLoading ? (
								<>
									<Skeleton
										borderRadius="8px"
										width="100%"
										height="30px"
									/>
									<Skeleton
										borderRadius="8px"
										width="100%"
										className="mt-3"
										height="30px"
									/>
								</>
							) : (
								<>
									<Dropdown
										className="w-full border-round-md"
										value={state.user}
										options={userData.data}
										onChange={(e) => {
											dispatch({
												type: "USER_NAME",
												payload: e.value,
											});
										}}
										filter
										placeholder={"Select User"}
										optionLabel={"name"}
									/>
									<Button
										disabled={state.user === null}
										label="Add"
										className=" mt-3 w-full"
										onClick={handleAllocate}
									/>
								</>
							)}
						</>
					)}
				</div>
			</Dialog>
			{leadType === "rmSelect" && (
				<>
					<div className="flex justify-content-between">
						<h3>Select {roleName()} to Allocate Lead</h3>
						<div>
							<span className="p-input-icon-left">
								<i className="pi pi-search" />
								<InputText
									// onChange={debounce(
									// 	(e) => setFilter(e.target.value),
									// 	1000
									// )}
									placeholder="Keyword Search"
								/>
							</span>
							<Button
								type="button"
								icon="pi pi-refresh"
								label="Reload"
								className="p-button-outlined ml-2"
								onClick={() => {
									rmDispatch({
										type: "LEAD_LIST",
										payload: [],
									});
									getRmList(selector.userName);
								}}
							/>
						</div>
					</div>
					{/* {rmState.leadList.length > 0 ? (
						<DataTable
							paginator
							rows={10}
							emptyMessage="No Regional Manager found."
							rowsPerPageOptions={[10, 20, 50]}
							value={rmState.leadList}
							selectionMode="checkbox"
							selection={rmState.selected}
							onSelectionChange={(e) => {
								handleSelectRm(e.value);
							}}
							filters={filter}
							dataKey="id"
							filterDisplay="menu"
							responsiveLayout="scroll"
							breakpoint="960px"
						>
							<Column
								header={"Select"}
								selectionMode={"single"}
								headerStyle={{ width: "3rem" }}
							/>
							<Column header={"Name"} body={nameTemplate} />
							<Column header={"Username"} field="userName" />
						</DataTable>
					) : (
						<TableLoader
							itaration={{ profileType: "", firstName: "" }}
							headerGroup={
								<ColumnGroup>
									<Row>
										<Column
											header={"Select"}
											style={{ width: "3em" }}
										/>

										<Column header={"Name"} />
									</Row>
								</ColumnGroup>
							}
						/>
					)} */}

					{rmState.spinner === true ? (
						<TableLoader
							itaration={{ profileType: "", firstName: "" }}
							headerGroup={
								<ColumnGroup>
									<Row>
										<Column
											header={"Select"}
											style={{ width: "3em" }}
										/>

										<Column header={"Name"} />
									</Row>
								</ColumnGroup>
							}
						/>
					) : (
						<DataTable
							paginator
							rows={10}
							emptyMessage="No Regional Manager found."
							rowsPerPageOptions={[10, 20, 50]}
							value={rmState.leadList}
							selectionMode="checkbox"
							selection={rmState.selected}
							onSelectionChange={(e) => {
								handleSelectRm(e.value);
							}}
							filters={filter}
							dataKey="id"
							filterDisplay="menu"
							responsiveLayout="scroll"
							breakpoint="960px"
						>
							<Column
								header={"Select"}
								selectionMode={"single"}
								headerStyle={{ width: "3rem" }}
							/>
							<Column header={"Name"} body={nameTemplate} />
							<Column header={"Username"} field="userName" />
						</DataTable>
					)}
					<div className="flex justify-content-end mt-6">
						<Button
							label="Get Lead"
							className="primary-btn"
							disabled={isEmpty(rmState.selected)}
							onClick={handleRmClick}
						/>
					</div>
				</>
			)}
			{leadType === "leadSelect" && (
				<>
					<div className="flex justify-content-between pb-2">
						<h3 className="m-0">Select Leads To Allocate</h3>
						{selector.role.position !== 4 && (
							<Button
								type="button"
								icon="pi pi-arrow-left"
								label="Back"
								onClick={() => setLeadType("rmSelect")}
							/>
						)}
					</div>

					{state.spinner ? (
						<TableLoader
							itaration={itaration}
							headerGroup={headerGroup}
						/>
					) : (
						<>
							{state.leadList.length > 0 ? (
								<DataTable
									paginator
									rows={10}
									rowsPerPageOptions={[10, 20, 50]}
									value={state.leadList}
									selectionMode="checkbox"
									selection={state.selected}
									onSelectionChange={(e) => {
										handleSelect(e.value);
									}}
									dataKey="leadId"
									responsiveLayout="scroll"
									breakpoint="960px"
									headerColumnGroup={headerGroup}
								>
									<Column
										selectionMode={"multiple"}
										headerStyle={{ width: "3em" }}
									/>
									<Column field="productName" />

									<Column body={nameTemplate} />
									<Column field="mobileNumber" />
									<Column body={editAction} />
								</DataTable>
							) : (
								<TableNoData
									itaration={itaration}
									headerGroup={headerGroup}
								/>
							)}

							<div className="flex justify-content-end mt-6">
								<Button
									label="Allocate Lead"
									className="primary-btn"
									disabled={
										state.selected.length === 0
											? true
											: false
									}
									onClick={handleClick}
								/>
							</div>
						</>
					)}
				</>
			)}
		</>
	);
};

export default AllocateLeadDialog;
