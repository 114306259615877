import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import * as Yup from "yup";
// import { states } from "../../../shared/data";
import { InstitutionService } from "../../../services/InstitutionService";
import { Toast } from "primereact/toast";
import Loader from "../../../components/Loader";
import Config from "../../../shared/config";
// import { useNavigate } from "react-router-dom";

import { useGetStateListQuery } from "../../../services/query/queryApi";

const BasicDetails = (props) => {
	const toastTL = useRef(null);
	const institutionService = new InstitutionService();
	const [institition, setInstitition] = useState([]);
	const [isLoading, setLoading] = useState(false);
	// const [pinCode, setPincode] = useState("");

	const { data = [] } = useGetStateListQuery();

	const formik = useFormik({
		initialValues: {
			type: "",
			bankId: "",
			rainbowDsaCode: "",
			alias: "",
			addressLine1: "",
			addressLine2: "",
			addressLine3: "",
			city: "",
			state: "",
			pincode: "",
		},
		validationSchema: Yup.object().shape({
			type: Yup.string().required("Type is required."),
			bankId: Yup.string().required("Name is required."),
			// rainbowDsaCode: Yup.string()

			// 	.required("DSA code is required.")
			// 	.matches(/^(\w+\s)*\w+$/, "Invalid code"),
			// alias: Yup.string()
			// 	.required("Alias is required.")
			// 	.matches(/^[a-zA-Z ]*$/, "Invalid alias"),
			addressLine1: Yup.string().required("Address Line 1 is required"),
			state: Yup.string().required("State is required"),
			city: Yup.string()
				.required("City is required")
				.min(3, "Invalid city name")
				.matches(/^[a-zA-Z ]*$/, "Invalid city name"),
			pincode: Yup.string()
				.required("Pincode is required")
				.matches(/^[0-9]{1,6}$/, "Invalid pincode"),
		}),
		onSubmit: (data) => {
			let requestBody = {
				bankId: data.bankId,
				rainbowDsaCode: data.rainbowDsaCode,
				alias: data.alias,
				addressLine1: data.addressLine1,
				addressLine2: data.addressLine2,
				addressLine3: data.addressLine3,
				city: data.city,
				stateId: data.state,
				pincode: data.pincode,
			};
			// console.log("requestBody: ", requestBody);
			createInstitution(requestBody);
		},
	});
	const handleChange = (e, setFieldValue) => {
		institutionService
			.getInstitutionName(e.value)
			.then((res) => {
				console.log(res);
				setInstitition(res);
			})
			.catch((e) => console.log(e));
		setFieldValue("type", e.value, true);
	};
	// const navigate = useNavigate();
	const createInstitution = (requestBody) => {
		setLoading(true);
		institutionService
			.addGeneralInstitution(requestBody)
			.then((res) => {
				props.handelStages();
				props.getId(res?.id);
				formik.resetForm();
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoading(false));
	};

	const isFormFieldValid = (name) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};

	return (
		<div className="col-12 px-0">
			<Toast ref={toastTL} position="top-left" />
			{isLoading && <Loader />}
			<div className="card form-grid pl-6 pr-6 pt-5 pb-5 generalbgbox">
				<form onSubmit={formik.handleSubmit} className="p-fluid grid">
					<FocusError formik={formik} />
					<div className="col-12">
						<h4 className="m-0">Financial Institution details</h4>
					</div>
					<div className="field col-12 md:col-6 lg:col-3">
						<label htmlFor="type">
							Type
							<span className="text-red-500 font-bold">*</span>
						</label>
						<Dropdown
							id="type"
							name="type"
							className={classNames(
								{
									"p-invalid": isFormFieldValid("type"),
								},
								"border-round-md"
							)}
							value={formik.values.type}
							onChange={(e) =>
								handleChange(e, formik.setFieldValue)
							}
							options={Config.INSTITUTION_TYPE}
							optionLabel="name"
						/>
						{getFormErrorMessage("type")}
					</div>
					<div className="field col-12 md:col-6 lg:col-3">
						<label htmlFor="name">
							Name
							<span className="text-red-500 font-bold">*</span>
						</label>
						<Dropdown
							id="name"
							name="bankId"
							options={institition.map((item) => ({
								...item,
								value: item.id,
							}))}
							filter
							value={formik.values.bankId}
							onChange={formik.handleChange}
							optionLabel="bankName"
							className={classNames(
								{
									"p-invalid": isFormFieldValid("name"),
								},
								"border-round-md"
							)}
						/>

						{getFormErrorMessage("name")}
					</div>
					<div className="field col-12 md:col-6 lg:col-3">
						<label htmlFor="rainbowDsaCode">
							Rainbow DSA Code
							{/* <span className="text-red-500 font-bold">*</span> */}
						</label>
						<InputText
							id="rainbowDsaCode"
							name="rainbowDsaCode"
							value={formik.values.rainbowDsaCode}
							onChange={formik.handleChange}
							className={classNames({
								"p-invalid": isFormFieldValid("rainbowDsaCode"),
							})}
						/>
						{getFormErrorMessage("rainbowDsaCode")}
					</div>
					<div className="field col-12 md:col-6 lg:col-3">
						<label htmlFor="alias">
							Alias
							{/* <span className="text-red-500 font-bold">*</span> */}
						</label>
						<InputText
							id="alias"
							name="alias"
							value={formik.values.alias}
							onChange={formik.handleChange}
							className={classNames({
								"p-invalid": isFormFieldValid("alias"),
							})}
						/>
						{getFormErrorMessage("alias")}
					</div>
					<div className="col-12">
						<h4 className="m-0">Financial Institution Address</h4>
					</div>
					<div className="field col-12 md:col-6 lg:col-4">
						<label htmlFor="addressLine1">
							Address Line 1
							<span className="text-red-500 font-bold">*</span>
						</label>
						<InputText
							id="addressLine1"
							name="addressLine1"
							value={formik.values.addressLine1}
							onChange={formik.handleChange}
							className={classNames({
								"p-invalid": isFormFieldValid("addressLine1"),
							})}
						/>
						{getFormErrorMessage("addressLine1")}
					</div>
					<div className="field col-12 md:col-6 lg:col-4">
						<label htmlFor="addressLine2">Address Line 2</label>
						<InputText
							id="addressLine2"
							name="addressLine2"
							value={formik.values.addressLine2}
							onChange={formik.handleChange}
							className={classNames({
								"p-invalid": isFormFieldValid("addressLine2"),
							})}
						/>
						{getFormErrorMessage("addressLine2")}
					</div>
					<div className="field col-12 md:col-6 lg:col-4">
						<label htmlFor="addressLine3">Address Line 3</label>
						<InputText
							id="addressLine3"
							name="addressLine3"
							value={formik.values.addressLine3}
							onChange={formik.handleChange}
							className={classNames({
								"p-invalid": isFormFieldValid("addressLine3"),
							})}
						/>
						{getFormErrorMessage("addressLine3")}
					</div>
					<div className="field col-12 md:col-6 lg:col-4">
						<label htmlFor="state">
							State
							<span className="text-red-500 font-bold">*</span>
						</label>
						<Dropdown
							id="state"
							name="state"
							value={formik.values.state}
							onChange={formik.handleChange}
							options={data.map((item) => ({
								label: item.name,
								value: item.id,
							}))}
							filter
							className={classNames(
								{
									"p-invalid": isFormFieldValid("state"),
								},
								"border-round-md"
							)}
						/>
						{getFormErrorMessage("state")}
					</div>
					<div className="field col-12 md:col-6 lg:col-4">
						<label htmlFor="city">
							City
							<span className="text-red-500 font-bold">*</span>
						</label>
						<InputText
							id="city"
							name="city"
							value={formik.values.city}
							onChange={formik.handleChange}
							className={classNames({
								"p-invalid": isFormFieldValid("city"),
							})}
						/>
						{getFormErrorMessage("city")}
					</div>

					<div className="field col-12 md:col-6 lg:col-4">
						<label htmlFor="pincode">
							Pincode
							<span className="text-red-500 font-bold">*</span>
						</label>
						<InputText
							id="pincode"
							name="pincode"
							value={formik.values.pincode}
							onChange={formik.handleChange}
							className={classNames({
								"p-invalid": isFormFieldValid("pincode"),
							})}
						/>
						{getFormErrorMessage("pincode")}
					</div>
					<div className="flex justify-content-end align-items-center w-full">
						<div className="col-12 md:col-3 lg:col-3 mt-3 flex">
							<Button
								type="button"
								label="Reset"
								onClick={() => formik.resetForm()}
								className="p-button-danger mr-2"
								icon="pi pi-times"
							/>
							<Button
								type="submit"
								label="Save & next"
								icon="pi pi-save"
							/>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default BasicDetails;
