import { useRef } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
const MenuComponent = ({ items, icon, textcolor }) => {
	const menu = useRef(null);
	return (
		<>
			<Button
				className={`icon-btn p-button-text ${textcolor && textcolor}`}
				icon={icon ? icon : "pi pi-bars"}
				onClick={(event) => menu.current.toggle(event)}
			/>
			<Menu model={items} popup ref={menu} />
		</>
	);
};

export default MenuComponent;
