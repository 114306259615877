import { useDispatch } from "react-redux";
import CreditScoreCheck from "./components/CreditScoreCheck";
import { useLocation, useNavigate } from "react-router-dom";
import { setCountryApplicationId } from "../../../store/reducer/AuthReducer";
const LoanApplication = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleFormSubmit = (val) => {
		dispatch(setCountryApplicationId(null));
		navigate("/masters/applicationcreate", {
			state: {
				leadId: location.state.leadId,
				subproductId: location.state.subproductId,
				subProductName: location.state.subProductName,
				alias: location.state.alias,
				applicationId: location.state.applicationId,
				programName: location.state.programName,
				programId: location.state.programId,
				mobileNumber: val.mobileNumber,
				state: val.state,
				district: val.district,
				panNumber: val.panNumber,
			},
		});
	};
	return (
		<CreditScoreCheck
			formData={location.state}
			handleFormSubmit={handleFormSubmit}
		/>
	);
};

export default LoanApplication;
