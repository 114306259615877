import axios from "axios";
import { agent_end_point_url, apiPath } from "../shared/constant";
import { useSelector } from "react-redux";
export class MenuService {
	selector = useSelector((state) => state.persistData.auth);
	constructor() {
		// this.token = sessionStorage.getItem("token");
		this.token = this.selector.token;
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}

	createMenu = async (payload) => {
		const url = `${apiPath}/rolemenu/svc/menu/create`;
		try {
			const response = await axios.post(url, payload, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getMenu = async () => {
		const url = `${apiPath}/rolemenu/svc/menu/list`;
		try {
			const response = await axios.get(url, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAllRole = async () => {
		const url = `${apiPath}/rolemenu/svc/role/all-list`;
		try {
			const response = await axios.get(url, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	createMenuMap = async (body) => {
		const url = `${apiPath}/rolemenu/svc/role-menu-map`;
		try {
			const response = await axios.post(url, body, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
}
