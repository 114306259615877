import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import Config from "../../../shared/config";
import { FeildDropdown, FieldInput } from "../../../components/FieldInput";
import { useGetCityListQuery } from "../../../services/query/queryApi";

const SearchSpoke = (props) => {
	const { data = [] } = useGetCityListQuery();
	const handleFormSubmit = (value) => {
		const newData = { ...value };
		let data = Object.fromEntries(
			Object.entries(newData).filter(([_, v]) => v !== "")
		);
		props.search({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: data,
		});
		props.search({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};
	const initialValue = {
		name: "",

		cityId: "",
	};

	return (
		<div className="mb-3">
			<div className="background-secondary p-4 border-round-md my-3">
				<Formik
					initialValues={initialValue}
					onSubmit={handleFormSubmit}
				>
					{({ handleSubmit, resetForm }) => (
						<Form className="grid" onSubmit={handleSubmit}>
							<div className="field col-12 md:col-4">
								<Field
									header={"Name"}
									name={"name"}
									component={FieldInput}
								/>
							</div>

							<div className="field col-12 md:col-4">
								<Field
									filter
									header={"City"}
									name={"cityId"}
									type={"number"}
									component={FeildDropdown}
									options={data}
									optionLabel={"name"}
									optionValue={"id"}
								/>
							</div>

							<div className="col-12 flex gap-3">
								<Button
									icon={"pi pi-refresh"}
									label={"Reset"}
									className="danger-btn"
									type="button"
									onClick={() => resetForm()}
								/>
								<Button label={"Search"} type="submit" />
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default SearchSpoke;
