import { Field, Form, Formik } from "formik";
import React, { useRef } from "react";
import { FieldInput } from "../../../../components/FieldInput";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { InputNode } from "../../../../components/InputComponent";
import { FileUpload } from "primereact/fileupload";
import Config from "../../../../shared/config";
import { S3 } from "aws-sdk";
import { Toast } from "primereact/toast";
const CreateTutorialCat = ({ value, handleFormSubmit }) => {
	const toastTL = useRef(null);
	const initialValue = {
		name: "",
		imageAttachment: "",
	};
	const validationSchema = Yup.object().shape({
		name: Yup.string().nullable().required("This field is required"),
		imageAttachment: Yup.string().required("Upload logo image required"),
	});
	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});

	const onUploadImage = (e, setFieldValue) => {
		//setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.FAQ_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Logo uploaded",
					style: { color: "#000000" },
					life: 3000,
				});

				setFieldValue("imageAttachment", newFileName);
			}
		});
	};
	return (
		<>
			<Toast ref={toastTL} />
			<Formik
				initialValues={{ ...initialValue, ...value }}
				validationSchema={validationSchema}
				enableReinitialize
				onSubmit={handleFormSubmit}
			>
				{({
					handleSubmit,
					errors,
					setFieldValue,
					touched,
					resetForm,
				}) => (
					<Form
						onSubmit={handleSubmit}
						className="card modal-form-grid mt-2 grid form-grid"
					>
						<div className="field col-12">
							<Field
								header={"Name"}
								component={FieldInput}
								name={`name`}
							/>
						</div>
						<div className="field col-12 md:col-12">
							<InputNode header={"Upload Logo"}>
								<FileUpload
									name="imageAttachment"
									customUpload
									accept=".jpg,.png,.jpeg"
									uploadHandler={(e) =>
										onUploadImage(e, setFieldValue)
									}
									maxFileSize={1000000}
									emptyTemplate={
										<p className="m-0">
											Drag and drop files to here to
											upload.
										</p>
									}
								/>
								{errors.imageAttachment &&
									touched.imageAttachment && (
										<small className="p-error">
											{errors.imageAttachment}
										</small>
									)}
							</InputNode>
						</div>
						<div className="flex justify-content-end align-items-center w-full mt-4 gap-2">
							<Button
								type="button"
								label="Reset"
								icon="pi pi-refresh"
								className="danger-btn"
								onClick={() => resetForm()}
							/>
							<Button
								type="submit"
								label="Save"
								icon="pi pi-save"
							/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default CreateTutorialCat;
