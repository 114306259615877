import React, { useState, useRef } from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { InstitutionService } from "../../../services/InstitutionService";
import Loader from "../../../components/Loader";
import { FeildDropdown, FieldInput } from "../../../components/FieldInput";
import { useGetAllDIstrictQuery } from "../../../services/query/queryApi";
import selectState from "../../../components/stateList";

const validationSchema = Yup.object().shape({
	contacts: Yup.array().of(
		Yup.object().shape({
			name: Yup.string()
				.required("Name is required.")
				.matches(/^[a-zA-Z ]*$/, "Invalid name.")
				.max(32, "Name not greater than 32 characters.")
				.min(2, "Name not less than 2 characters."),
			mobile: Yup.string()
				.required("Mobile no is required.")
				.min(10, "Invalid mobile no.")
				.max(10, "Invalid mobile no."),
			email: Yup.string()
				.required("Email is required.")
				.email("Invalid email id."),
			stateId: Yup.string().required("State is required."),
			districtId: Yup.string().required("District is required."),
			desgination: Yup.string().required("Desgination is required."),
		})
	),
});

const AddContact = (props) => {
	const {
		data: getAllDistrict = [
			{ districtList: [{ id: null, name: "" }], id: null, name: "" },
		],
	} = useGetAllDIstrictQuery();

	const toastTL = useRef(null);
	const institutionService = new InstitutionService();
	const [isLoading, setLoading] = useState(false);
	const initialValues = {
		contacts: [
			{
				institutionId: props.id,
				name: "",
				mobile: "",
				email: "",
				stateId: "",
				districtId: "",
				desgination: "",
			},
		],
	};

	const handleFormSubmit = (values) => {
		setLoading(true);
		const data = values.contacts.filter(
			(item) =>
				item.name !== "" || item.mobile !== "" || item.email !== ""
		);
		if (data.length !== 0) {
			institutionService
				.addInstitutionContact(data)
				.then(() => {
					setLoading(false);
					toastTL.current.show({
						severity: "success",
						summary: "Successful",
						detail: "Contact added.",
						style: { color: "#000000" },
						life: 3000,
					});
					props.handelStages();
				})
				.then((e) => {
					setLoading(false);
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});
				});
		} else {
			setLoading(false);
			toastTL.current.show({
				severity: "error",
				summary: "Error",
				detail: "Contact can't be empty",
				style: { color: "#000000" },
				life: 3000,
			});
		}
	};
	const getDistrictData = (e) => {
		const stateData = getAllDistrict.find((item) => item.id === e);
		if (stateData !== undefined) {
			const districtData = stateData.districtList.map((e) => ({
				...e,
				value: e.id,
			}));
			return districtData;
		} else {
			return [];
		}
	};
	return (
		<div className="col-12 px-0">
			<Toast ref={toastTL} position="top-left" />
			{isLoading && <Loader />}
			<div className="card form-grid  pl-2 pr-2 pt-5 pb-5 generalbgbox">
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={handleFormSubmit}
				>
					{({ values, resetForm }) => (
						<Form>
							<FieldArray name="contacts">
								{({ insert, remove, push }) => (
									<div>
										{values.contacts.length > 0 &&
											values.contacts.map(
												(item, index) => (
													<div
														className="contact-grid-card"
														key={index}
													>
														<span
															className="floating-icon-btn"
															onClick={() =>
																remove(index)
															}
														>
															<i className="pi pi-times-circle"></i>
														</span>
														<div className="p-fluid grid">
															<div className="field col-12 md:col-6 lg:col-4">
																<label
																	htmlFor={`contacts.${index}.name`}
																>
																	Full Name
																	<span className="text-red-500 font-bold">
																		*
																	</span>
																</label>

																<div className="flex-column">
																	<Field
																		name={`contacts.${index}.name`}
																		component={
																			FieldInput
																		}
																		placeholder="Name"
																		type="text"
																	/>
																</div>
															</div>
															<div className="field col-12 md:col-6 lg:col-4">
																<label
																	htmlFor={`contacts.${index}.name`}
																>
																	Mobile No.
																	<span className="text-red-500 font-bold">
																		*
																	</span>
																</label>

																<div className="flex-column">
																	<Field
																		name={`contacts.${index}.mobile`}
																		component={
																			FieldInput
																		}
																		placeholder="Mobile Number"
																		type="number"
																	/>
																</div>
															</div>
															<div className="field col-12 md:col-6 lg:col-4">
																<label
																	htmlFor={`contacts.${index}.email`}
																>
																	Email
																	<span className="text-red-500 font-bold">
																		*
																	</span>
																</label>
																<Field
																	name={`contacts.${index}.email`}
																	component={
																		FieldInput
																	}
																	placeholder="email@example.in"
																	type="email"
																/>
															</div>
															<div className="field col-12 md:col-6 lg:col-4">
																<label
																	htmlFor={`contacts.${index}.desgination`}
																>
																	Desgination
																	<span className="text-red-500 font-bold">
																		*
																	</span>
																</label>
																<Field
																	name={`contacts.${index}.desgination`}
																	component={
																		FieldInput
																	}
																	placeholder="Enter Desgination"
																/>
															</div>
															<div className="field col-12 md:col-4">
																<Field
																	header={
																		"State"
																	}
																	name={`contacts.${index}.stateId`}
																	filter
																	placeholder="Select State"
																	component={
																		FeildDropdown
																	}
																	options={selectState(
																		getAllDistrict
																	)}
																	optionLabel={
																		"name"
																	}
																	star={"*"}
																/>
															</div>
															<div className="field col-12 md:col-4">
																<Field
																	header={
																		"District"
																	}
																	name={`contacts.${index}.districtId`}
																	filter
																	component={
																		FeildDropdown
																	}
																	placeholder="Select District"
																	options={getDistrictData(
																		values
																			.contacts[
																			index
																		]
																			.stateId
																	)}
																	optionLabel={
																		"name"
																	}
																/>
															</div>
														</div>
													</div>
												)
											)}
										<div
											className="add-more-btn flex align-items-center justify-content-center mb-3"
											onClick={() =>
												push({
													institutionId: props.id,
													name: "",
													mobile: "",
													email: "",
													stateId: "",
													districtId: "",
													desgination: "",
												})
											}
										>
											<i className="pi pi-plus"></i>
											<span className="ml-2">
												Contact Details
											</span>
										</div>
									</div>
								)}
							</FieldArray>

							<div className="flex justify-content-end align-items-center w-full">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={() => resetForm()}
									icon="pi pi-times"
								/>
								<Button
									type="submit"
									label="Save & next"
									icon="pi pi-save"
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};
export default AddContact;
