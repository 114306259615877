// import { BiGridAlt, BiUser } from "react-icons/bi";
// import {
// 	AiOutlineDatabase,
// 	AiOutlineBank,
// 	AiOutlineUsergroupAdd,
// } from "react-icons/ai";
// import { BiSupport } from "react-icons/bi";
// import { TbReportSearch } from "react-icons/tb";
export const adminMenus = [
	{
		title: "Masters",
		icon: "pi-th-large",
		child: [
			{
				title: "Products",
				path: "/masters/products",
			},
			{
				title: "Lead Management",
				child: [
					// {
					// 	title: "Create lead",
					// 	path: "/masters/lead/new-lead",
					// },
					{
						title: "All",
						path: "/masters/lead/all",
					},
					{
						title: "Own",
						path: "/masters/lead/own",
					},
					{
						title: "Unallocated",
						path: "/masters/lead/lead-allocation",
					},
				],
			},
			// {
			// 	title: "Lead",
			// 	path: "/masters/lead",
			// },
			{
				title: "Application Management",
				child: [
					// {
					// 	title: "Create Application",
					// 	path: "/masters/application-create",
					// },
					{
						title: "Application List",
						path: "/masters/loan-application-list",
					},
					{
						title: "Unallocated Application",
						path: "/masters/unallocated-application",
					},
					{
						title: "Hospicash Payment",
						path: "/masters/hospicash/payments",
					},
				],
			},
		],
	},
	{
		title: "System Members",
		icon: "pi-user",
		child: [
			{
				title: "User Management",
				child: [
					{
						title: "Agents",
						path: "/members/agents",
					},
					{
						title: "Users",
						path: "/members/users",
					},
				],
			},
		],
	},
	{
		title: "Bank/ Partner Management",
		icon: "pi-building",
		child: [
			{
				title: "Financial Institutes",
				child: [
					{
						title: "Search/Listing",
						path: "/partners/financial-institution/list",
					},
				],
			},
			{
				title: "Program Setup & Management",
				path: "/partners/program-setup-management",
			},
		],
	},

	{
		title: "Campaign",
		icon: "pi-users",
		child: [{ title: "Campaign", path: "/masters/campaign" }],
	},

	{
		title: "Report",
		icon: "pi-file-edit",
		child: [
			{
				title: "Agent Onboarding",
				path: "/reports/agent",
			},
			{
				title: "Customer List",
				path: "/reports/customer-list",
			},
		],
	},
	{
		title: "Support",
		icon: "pi-phone",
		child: [
			{
				title: "FAQs",
				path: "/support/faq",
			},
			{
				title: "Tutorials",
				path: "/support/tutorial",
			},
		],
	},
	{
		title: "Agent Lead",
		icon: "pi-list",
		child: [{ title: "Agent Lead", path: "/agent-lead" }],
	},
	{
		title: "Vendor",
		icon: "pi-sitemap",
		child: [
			{
				title: "Vendor",

				child: [
					{
						title: "Vendor",
						path: "/vendor",
					},
					{ title: "Hub", path: "/hub" },
					{ title: "Spoke", path: "/spoke" },
				],
			},
			// {
			// 	title: "User",

			// 	child: [
			// 		{
			// 			title: "User",
			// 			path: "/vendor-user",
			// 		},
			// 	],
			// },
		],
	},
];

//other user permission

export const sidenavData2 = [
	{
		title: "Masters",
		icon: "pi-th-large",
		child: [
			// {
			// 	title: "Lead",
			// 	path: "/masters/lead",
			// },
			{
				title: "Application Management",
				child: [
					// {
					// 	title: "Create Application",
					// 	path: "/masters/application-create",
					// },
					{
						title: "Application List",
						path: "/masters/loan-application-list",
					},
					{
						title: "Unallocated Application",
						path: "/masters/unallocated-application",
					},
				],
			},
			{
				title: "Lead Management",
				child: [
					// {
					// 	title: "Create lead",
					// 	path: "/masters/lead/new-lead",
					// },
					{
						title: "Own",
						path: "/masters/lead/own",
					},
					{
						title: "All",
						path: "/masters/lead/all",
					},
					{
						title: "Unallocated",
						path: "/masters/lead/lead-allocation",
					},
				],
			},
		],
	},
	{
		title: "System Members",
		icon: "pi-user",
		child: [
			{
				title: "Allocation",
				child: [
					{
						title: "My Agents",
						path: "/members/my-agents",
					},
					{
						title: "Unallocated Agents",
						path: "/members/unallocated-agents",
					},
				],
			},
		],
	},
	// {
	// 	title: "Campaign",
	// 	icon: "pi-users",
	// 	child: [{ title: "Campaign", path: "/masters/campaign" }],
	// },
	{
		title: "Report",
		icon: "pi-list",
		child: [
			{
				title: "Agent Onboarding",
				path: "/reports/agent",
			},
			{
				title: "Customer List",
				path: "/reports/customer-list",
			},
		],
	},
];

export const navForRM = [
	{
		title: "Masters",
		icon: "pi-th-large",
		child: [
			// {
			// 	title: "Lead",
			// 	path: "/masters/lead",
			// },
			{
				title: "Application Management",
				child: [
					// {
					// 	title: "Create Application",
					// 	path: "/masters/application-create",
					// },
					{
						title: "Application List",
						path: "/masters/loan-application-list",
					},
					{
						title: "Unallocated Application",
						path: "/masters/unallocated-application",
					},
				],
			},
			{
				title: "Lead Management",
				child: [
					// {
					// 	title: "Create lead",
					// 	path: "/masters/lead/new-lead",
					// },
					{
						title: "Own",
						path: "/masters/lead/own",
					},
					{
						title: "All",
						path: "/masters/lead/all",
					},
					{
						title: "Unallocated",
						path: "/masters/lead/lead-allocation",
					},
				],
			},
		],
	},
	{
		title: "System Members",
		icon: "pi-user",
		child: [
			{
				title: "Agents",
				child: [
					{
						title: "My Agents",
						path: "/members/my-agents",
					},
					// {
					// 	title: "Unallocated Agents",
					// 	path: "/members/unallocated-agents",
					// },
				],
			},
		],
	},
	// {
	// 	title: "Campaign",
	// 	icon: "pi-users",
	// 	child: [{ title: "Campaign", path: "/masters/campaign" }],
	// },
	{
		title: "Report",
		icon: "pi-list",
		child: [
			{
				title: "Agent Onboarding",
				path: "/reports/agent",
			},
			{
				title: "Customer List",
				path: "/reports/customer-list",
			},
		],
	},
];

// SE Permission

export const sidenavSEData2 = [
	{
		title: "Masters",
		icon: "pi-th-large",
		child: [
			// {
			// 	title: "Lead",
			// 	path: "/masters/lead",
			// },
			{
				title: "Application Management",
				child: [
					// {
					// 	title: "Create Application",
					// 	path: "/masters/application-create",
					// },
					{
						title: "Application List",
						path: "/masters/loan-application-list",
					},
					// {
					// 	title: "Unallocated Application",
					// 	path: "/masters/unallocated-application",
					// },
				],
			},
			{
				title: "Lead Management",
				child: [
					// {
					// 	title: "Create lead",
					// 	path: "/masters/lead/new-lead",
					// },
					{
						title: "Own",
						path: "/masters/lead/own",
					},
					{
						title: "Allocated",
						path: "/masters/lead/all",
					},
					// {
					// 	title: "Unallocated",
					// 	path: "/masters/lead/lead-allocation",
					// },
				],
			},
		],
	},
	{
		title: "System Members",
		icon: "pi-user",
		child: [
			{
				title: "Agents",
				child: [
					{
						title: "My Agents",
						path: "/members/my-agents",
					},
					// {
					// 	title: "Unallocated Agents",
					// 	path: "/members/unallocated-agents",
					// },
				],
			},
		],
	},
	// {
	// 	title: "Campaign",
	// 	icon: "pi-users",
	// 	child: [{ title: "Campaign", path: "/masters/campaign" }],
	// },
	{
		title: "Report",
		icon: "pi-list",
		child: [
			{
				title: "Agent Onboarding",
				path: "/reports/agent",
			},
			{
				title: "Customer List",
				path: "/reports/customer-list",
			},
		],
	},
];

//Zonal Manager Permission

export const sidenavDataZonalManager = [
	{
		title: "Masters",
		icon: "pi-th-large",
		child: [
			{
				title: "Application Management",
				child: [
					// {
					// 	title: "Create Application",
					// 	path: "/masters/application-create",
					// },
					{
						title: "Application List",
						path: "/masters/loan-application-list",
					},
					{
						title: "Unallocated Application",
						path: "/masters/unallocated-application",
					},
				],
			},
			{
				title: "Lead Management",
				child: [
					// {
					// 	title: "Create lead",
					// 	path: "/masters/lead/new-lead",
					// },
					{
						title: "Own",
						path: "/masters/lead/own",
					},
					{
						title: "All",
						path: "/masters/lead/all",
					},
					{
						title: "Unallocated",
						path: "/masters/lead/lead-allocation",
					},
				],
			},
		],
	},
	{
		title: "System Members",
		icon: "pi-user",
		child: [
			{
				title: "Allocation",
				child: [
					{
						title: "My Agents",
						path: "/members/my-agents",
					},
				],
			},
		],
	},
	// {
	// 	title: "Campaign",
	// 	icon: "pi-users",
	// 	child: [{ title: "Campaign", path: "/masters/campaign" }],
	// },
	{
		title: "Report",
		icon: "pi-list",
		child: [
			{
				title: "Agent Onboarding",
				path: "/reports/agent",
			},
			{
				title: "Customer List",
				path: "/reports/customer-list",
			},
		],
	},
];
export const finanseMenu = [
	{
		title: "System Members",
		icon: "pi-user",
		child: [
			{
				title: "User Management",
				child: [
					{
						title: "Agents",
						path: "/members/agents",
					},
				],
			},
		],
	},
];

export const callCenterLead = [
	{
		title: "Call Center User",
		icon: "pi-user",
		child: [
			{
				title: "Call Center User Management",
				child: [
					{
						title: "Call Center User List",
						path: "/vendor/call-support/user",
					},
				],
			},
			// {
			// 	title: "Agent Management",
			// 	child: [
			// 		{
			// 			title: "Agents",
			// 			path: "/members/agents",
			// 		},
			// 	],
			// },
		],
	},
];

export const callCenterTASM = [
	{
		title: "User",
		icon: "pi-user",
		child: [
			{
				title: "User Management",
				child: [
					{
						title: "Agents",
						path: "/call-support/agent",
					},
				],
			},
		],
	},
];

//dropdoen cities for financial-institutions/add
export const states = [
	{ label: "Andhra Pradesh", value: "Andhra Pradesh" },
	{ label: "Arunchal Pradesh", value: "Arunchal Pradesh" },
	{ label: "Assam", value: "Assam" },
	{ label: "Bihar", value: "Bihar" },
	{ label: "Chhattisgarh", value: "Chhattisgarh" },
	{ label: "Goa", value: "Goa" },
	{ label: "Gujarat", value: "Gujarat" },
	{ label: "Haryana", value: "Haryana" },
	{ label: "Himachal Pradesh", value: "Himachal Pradesh" },
	{ label: "Jharkhand", value: "Jharkhand" },
	{ label: "Karnataka", value: "Karnataka" },
	{ label: "Kerala", value: "Kerala" },
	{ label: "Madhya Pradesh", value: "Madhya Pradesh" },
	{ label: "Maharashtra", value: "Maharashtra" },
	{ label: "Manipur", value: "Manipur" },
	{ label: "Meghalaya", value: "Meghalaya" },
	{ label: "Mizoram", value: "Mizoram" },
	{ label: "Nagaland", value: "Nagaland" },
	{ label: "Odisha", value: "Odisha" },
	{ label: "Punjab", value: "Punjab" },
	{ label: "Rajasthan", value: "Rajasthan" },
	{ label: "Sikkim", value: "Sikkim" },
	{ label: "Tamil Nadu", value: "Tamil Nadu" },
	{ label: "Telangana", value: "Telangana" },
	{ label: "Tripura", value: "Tripura" },
	{ label: "Uttar Pradesh", value: "Uttar Pradesh" },
	{ label: "Uttarakhand", value: "Uttarakhand" },
	{ label: "West Bangal", value: "West Bangal" },
];

//members data

export const memberStatic = {
	data: [
		{
			name: "Agradeep Rakshit",
			email: "rakshit.bwn@gmail.com",
			mobile: "7063276421",
			userName: "agradeep1234",
			role: "Area Manager",
			status: "active",
		},
		{
			name: "Rajdeep Rakshit",
			email: "rajdeep.bwn@gmail.com",
			mobile: "8927159487",
			userName: "rajdeep1234",
			role: "Zonal Manager",
			status: "inactive",
		},
		{
			name: "Pradip Rakshit",
			email: "pradip.bwn@gmail.com",
			mobile: "8347688653",
			userName: "rajdeep1234",
			role: "Regional Manager",
			status: "active",
		},
		{
			name: "Sandip Rakshit",
			email: "sandip.bwn@gmail.com",
			mobile: "9475473876",
			userName: "sandip1234",
			role: "Sales Excutive",
			status: "active",
		},
	],
	totalPages: 1,
	totalElements: 4,
	pageNo: 1,
	pageSize: 10,
	firstPage: true,
	lastPage: true,
};

export const allocationTree = [
	{
		key: "0",
		label: "Zonal manager",
		type: "ZM",
		data: {
			name: "Ujjal Kr Chatterjee",
			mobile: "7852365785",
			email: "ujjalchatterjee1178@gmail.com",
			zone: "Eastern Zone",
		},
		children: [],
	},
];

export const listData = {
	data: [
		{
			id: "1",
			name: "Ujjal Kr Chatterjee",
			mobile: "7852365785",
			email: "ujjalchatterjee1178@gmail.com",
			username: "UjjalCh1221",
			gender: "M",
			profiletype: "Employee",
		},
		{
			id: "2",
			name: "Ujjal Kr Chatterjee",
			mobile: "7852365785",
			email: "ujjalchatterjee1178@gmail.com",
			username: "UjjalCh1221",
			gender: "M",
			profiletype: "Employee",
		},
		{
			id: "3",
			name: "Ujjal Kr Chatterjee",
			mobile: "7852365785",
			email: "ujjalchatterjee1178@gmail.com",
			username: "UjjalCh1221",
			gender: "M",
			profiletype: "Employee",
		},
		{
			id: "4",
			name: "Ujjal Kr Chatterjee",
			mobile: "7852365785",
			email: "ujjalchatterjee1178@gmail.com",
			username: "UjjalCh1221",
			gender: "M",
			profiletype: "Employee",
		},
	],
	totalPages: 2,
	totalElements: 5,
	pageNo: 1,
	pageSize: 10,
	firstPage: true,
	lastPage: false,
};
export const unallocatedData = [
	{
		agentId: 144,
		emailId: "ujjal88@gmail.com",
		firstName: "Ujjal",
		gender: "Male",
		id: null,
		lastName: "Chatterjee",
		middleName: "Kr",
		mobileNumber: "7059377035",
		status: "ACTIVE",
		userName: "Ujjal200",
		active: true,
		profileList: [
			{
				aadhaarVerified: false,
				id: 2255,
				panVerified: false,
				pincode: 455556,
				profileType: "MAIN_AGENT",
				selfieVerified: false,
				status: "ON_HOLD",
			},
		],
	},
	{
		agentId: 145,
		emailId: "ujjal88@gmail.com",
		firstName: "Ujjal",
		id: null,
		gender: "Male",
		lastName: "Chatterjee",
		middleName: "Kr",
		mobileNumber: "7059377035",
		status: "INACTIVE",
		userName: "Ujjal200",
		active: false,
		profileList: [
			{
				aadhaarVerified: false,
				id: 2255,
				panVerified: false,
				pincode: 455556,
				profileType: "MAIN_AGENT",
				selfieVerified: false,
				status: "ON_HOLD",
			},
		],
	},
	{
		agentId: 154,
		emailId: "shahnawazshaukat84@gmail.com",
		firstName: "Shahnawaz",
		gender: "Male",
		id: null,
		lastName: "Shaukat",
		middleName: "",
		mobileNumber: "7604065308",
		active: true,
		profileList: [
			{
				aadhaarVerified: false,
				id: 2255,
				panVerified: false,
				pincode: 455556,
				profileType: "MAIN_AGENT",
				selfieVerified: false,
				status: "VERIFIED",
			},
		],
		status: "Active",
		userName: "Shah1984",
	},
];
export const memberRole = [
	{ name: "Zonal Manager", code: "ZM" },
	{ name: "Area Sales Manager", code: "ASM" },
	{ name: "Regional Manager ", code: "RM" },
	{ name: "Sales Executive", code: "SE" },
];
export const zoneRole = [
	{ name: "East Zone", code: "EZ" },
	{ name: "West Zone", code: "WZ" },
	{ name: "North Zone ", code: "NZ" },
	{ name: "South Zone", code: "SZ" },
];
//for asm
export const asmRole = [
	{ name: "Arunachal Pradesh", code: "EZ" },
	{ name: "Nagaland", code: "NL" },
	{ name: "Mizoram", code: "MR" },
	{ name: "Jharkhand", code: "JR" },
	{ name: "West Bangal", code: "Wb" },
];
export const zoneManagerName = [
	{ name: "Agradeep Rakshit", code: "AR" },
	{ name: "deep Rakshit", code: "DR" },
	{ name: "Sudeep Rakshit", code: "SR" },
	{ name: "sandip Rakshit", code: "SR" },
	{ name: "Paldeep Rakshit", code: "PR" },
	{ name: "Manob Rakshit", code: "MR" },
	{ name: "Modon Rakshit", code: "MR" },
];
//for rm
export const rmRole = [
	{ name: "Burdwan", code: "BW" },
	{ name: "Nodia", code: "ND" },
	{ name: "Birbhum", code: "BM" },
	{ name: "Murshidabad", code: "Md" },
];
export const areaSalesManagerName = [
	{ name: "Madhurima das", code: "MD" },
	{ name: "Chandrima das", code: "CD" },
	{ name: "Rima Das", code: "RD" },
	{ name: "Karima Das", code: "KD" },
];
//for se(singer)
export const areaRegionalManagerName = [
	{ name: "Arijit Singh", code: "AS" },
	{ name: "Honey Sing", code: "HS" },
	{ name: "Darshan Ravel", code: "DR" },
	{ name: "Kailash Kher", code: "KK" },
	{ name: "Kumar Sanu", code: "KS" },
];

//data-filter
export const LOBdata = [
	{ label: "Investment", value: "investment" },
	{ label: "Loans", value: "loans" },
	{ label: "Insurance", value: "insurance" },
	{ label: "Credit Card", value: "creditCard" },
	{ label: "Loan Against Property", value: "loanAgainstProperty" },
	{ label: "Loans", value: "loans" },
];

export const instituteDummyData = [
	{
		sl: "1",
		"interested in": "Home Loan",
		mobile: "7063276421",
		pin: "713148",
		city: "Alipurduar",
		name: "Kesab Chandra Patel",
		"more details": "Collected Data",
		status: "Not Interested",
	},
	{
		sl: "2",
		"interested in": "Car Loan",
		mobile: "7063276421",
		pin: "700156",
		city: "kolkata",
		name: "Murari Mohan Gayen",
		"more details": "Collected Data",
		status: "Converted",
	},
	{
		sl: "3",
		"interested in": "Home Loan",
		mobile: "8927159487",
		pin: "700223",
		city: "kolkata",
		name: "Ram Lal Gayen",
		"more details": "Collected Data",
		status: "Not Eligible",
	},
];

export const leadAllocationFind = [
	{
		id: "1",
		name: "Raju",
		phoneNo: "7063276421",
		altPhoneNo: "7654667043",
		place: "kolkata",
		pin: "713148",
		type: "Agent",
	},
	{
		id: "2",
		name: "Sanju",
		phoneNo: "8927159487",
		altPhoneNo: "7654321234",
		place: "delhi",
		pin: "713148",
		type: "User",
	},
];
export const InsuranceData = [
	{
		id: 1,
		title: "Loan",
		subProducts: [
			{
				id: 1.0,
				title: "Individual",
				isActive: false,
			},
			{
				id: 1.1,
				title: "Family",
				isActive: true,
			},
		],
	},
	{
		id: 2,
		title: "Insurance",
		subProducts: [
			{
				id: 2.0,
				title: "Traditional",
				isActive: true,
			},
			{
				id: 2.1,
				title: "Retirement Plan",
				isActive: false,
			},
		],
	},
];

//-------program management ------- //

export const programData = {
	loan: [
		{ id: "1", title: "Business Loan", data: [] },
		{ id: "2", title: "Home Loan", data: [] },
		{
			id: "3",
			title: "Persional Loan",
			data: [
				{
					id: "1",
					title: "State Bank Of India",
					data: [
						{
							id: "1",
							title: "Easy Fund Persional Loan 2023",
							processingFee: "1% - 2%",
							rateOfInterest: "12.00-15.50%",
							tenure: "2-5 yrs",
							active: true,
						},
						{
							id: "2",
							title: "General Persional Loan 2023",
							processingFee: "1% - 2%",
							rateOfInterest: "12.00-15.50%",
							tenure: "2-5 yrs",
							active: false,
						},
						{
							id: "3",
							title: "Quick Easy Easy Persional Loan 2023",
							processingFee: "1% - 2%",
							rateOfInterest: "12.00-15.50%",
							tenure: "2-5 yrs",
							active: true,
						},
					],
				},
				{
					id: "2",
					title: "HDFC Bank",
					data: [
						{
							id: "1",
							title: "Easy Fund Persional Loan 2023",
							processingFee: "1% - 2%",
							rateOfInterest: "12.00-15.50%",
							tenure: "2-5 yrs",
							active: true,
						},
						{
							id: "2",
							title: "General Persional Loan 2023",
							processingFee: "1% - 2%",
							rateOfInterest: "12.00-15.50%",
							tenure: "2-5 yrs",
							active: false,
						},
						{
							id: "3",
							title: "Quick Easy Easy Persional Loan 2023",
							processingFee: "1% - 2%",
							rateOfInterest: "12.00-15.50%",
							tenure: "2-5 yrs",
							active: true,
						},
					],
				},
			],
		},
	],
};

export const institutionType = [
	{ name: "Bank", code: "Bank" },
	{ name: "NBFC", code: "NBFC" },
	{ name: "Insurance Company", code: "Insurance Company" },
	{ name: "Mutual Fund House", code: "Mutual Fund House" },
	{ name: "Stock Broker", code: "Stock Broker" },
	{ name: "Micro Finance Institution", code: "Micro Finance Institution" },
];

export const dashboardCardData = [
	{
		id: 1,
		gradient: "linear-gradient(180deg, #F5B946 0%, #F67545 100%)",
		icon: "pi pi-user",
		button: "New",
		heading: "Total Agent",
		count: 30,
	},
	{
		id: 2,
		gradient: "linear-gradient(180deg, #4CB5FC 0%, #4F6EFC 100%)",
		icon: "pi pi-user",
		button: "New",
		heading: "KYC verified",
		count: 30,
	},
	{
		id: 3,
		gradient:
			"linear-gradient(180deg, #63E7FD 0%, #43B4E4 99.99%, #4F6EFC 100%)",
		icon: "pi pi-user",
		button: "New",
		heading: "KYC Pending",
		count: 30,
	},
];
// export const dashboardCardData2 = [
// 	{
// 		id: 1,
// 		gradient: "linear-gradient(180deg, #2292FC 0%, #23EDC3 100%)",
// 		icon: "pi pi-user",
// 		button: "New",
// 		heading: "Total Agent Leads",
// 		count: 30,
// 	},
// 	{
// 		id: 2,
// 		gradient: "linear-gradient(180deg, #4CB5FC 0%, #4F6EFC 100%)",
// 		icon: "pi pi-user",
// 		button: "New",
// 		heading: "Total Agent",
// 		count: 30,
// 	},
// 	{
// 		id: 3,
// 		gradient:
// 			"linear-gradient(180deg, #63E7FD 0%, #43B4E4 99.99%, #4F6EFC 100%)",
// 		icon: "pi pi-user",
// 		button: "New",
// 		heading: "Total Agent",
// 		count: 30,
// 	},
// 	{
// 		id: 4,
// 		gradient:
// 			"linear-gradient(180deg, #38FFC3 0%, #43B4E4 99.99%, #51D2AB 100%)",
// 		icon: "pi pi-user",
// 		button: "New",
// 		heading: "Total Agent",
// 		count: 30,
// 	},
// ];
// export const ActiveAgentStatus = [
// 	{
// 		name: "Verified",
// 		value: 34,
// 	},
// 	{
// 		name: "Temporary Suspended",
// 		value: 4,
// 	},
// 	{
// 		name: "On Hold",
// 		value: 13,
// 	},
// 	{
// 		name: "Blocked",
// 		value: 8,
// 	},
// 	{
// 		name: "Not Verified",
// 		value: 16,
// 	},
// ];
// export const ActiveAgentStatusColor = [
// 	"#8ff3c3",
// 	"#ffc8bc",
// 	"#ffea63",
// 	"#b12100",
// 	"#8775cc",
// ];

//  private String date;
//     private String tsrm;
//     private String agentCode;
