import React, { useReducer, useRef, useState } from "react";
import { Formik, Field, Form } from "formik";
import { FeildDropdown, FieldInput } from "../../../../components/FieldInput";
import Header from "../../../partners/institutions/header";
import { Button } from "primereact/button";
import { ProgramOtpService } from "../../../../services/ProgramOtpService";
import * as Yup from "yup";
// import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

import Loader from "../../../../components/Loader";
import { useGetStateListQuery } from "../../../../services/query/queryApi";
import selectState from "../../../../components/stateList";
import { LoanApplicationService } from "../../../../services/LoanApplication";
import produce from "immer";
import debounce from "lodash/debounce";
const CreditScoreCheck = (props) => {
	console.log(props);
	const { data: stateData = [] } = useGetStateListQuery();
	const loanApplicationService = new LoanApplicationService();
	const [customerShare, setCustomerShare] = useState(false);
	// const selector = useSelector((state) => state.lead);
	const navigate = useNavigate();

	const otpService = new ProgramOtpService();

	const initialState = {
		stage: [
			{
				sequence: 1,
				title: "Select a Program",
				status: "success",
			},
			{
				sequence: 2,
				title: "Add Contact",
				status: "active",
			},

			{
				sequence: 3,
				title: "Save Application",
				status: "inactive",
			},
		],
	};
	const stageReducer = produce((draft, action) => {
		const newData = draft.stage.map((item) =>
			item.sequence === action.payload.sequence ? action.payload : item
		);
		draft.stage = newData;
	});
	const [stages] = useReducer(stageReducer, initialState);

	const toast = useRef(null);
	const validationSchema = Yup.object().shape({
		mobileNumber: Yup.string()
			.matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Mobile No is invalid")
			.required("This field is required"),
		panNumber: Yup.string()
			.nullable()
			.matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Pan Number is invalid")
			.required("This field is required"),
		state: Yup.number().required("This field is required"),
		district: Yup.number().required("This field is required"),
	});

	const [districtListState, setDistrict] = useReducer(
		(state, action) => {
			return { ...state, districtList: action.payload };
		},
		{ districtList: [] }
	);

	const handleDistrict = (e, setFieldValue) => {
		loanApplicationService
			.getDistrictByStateID(e)
			.then((res) => {
				setDistrict({
					payload: res.map((item) => ({ ...item, value: item.id })),
				});
			})
			.catch(() => {});
		setFieldValue("state", e, true);
	};

	const shareWithCustomer = (value) => {
		const body = {
			agentCode: null,
			applicationType:
				props?.fromData?.alias === "CC" ? "CREDIT_CARD" : "LOAN",
			districtId: value.district,
			leadId: props?.fromData?.leadId,
			mobile: value.mobileNumber,
			offerId: null,
			pan: value.panNumber,
			programId: props?.fromData?.programId,
			stateId: value.state,
		};
		setCustomerShare(true);
		otpService
			.shareWithCustomer(body)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "Success",
					style: { color: "#000000" },
					life: 3000,
				});

				setCustomerShare(false);
			})
			.then(
				debounce(() => navigate("/masters/loan-application-list"), 500)
			)
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
				setCustomerShare(false);
			});
	};
	return (
		<>
			<Header
				stages={stages.stage}
				title="Application"
				stageName={"Create Application"}
			/>
			{customerShare && <Loader />}
			<Toast ref={toast} />
			<div className="px-5 py-5">
				<Formik
					initialValues={{
						mobileNumber: props.formData?.mobileNumber,
						state: "",
						district: "",
						panNumber: props.formData?.panNumber,
					}}
					onSubmit={props.handleFormSubmit}
					validationSchema={validationSchema}
					enableReinitialize
				>
					{({ handleSubmit, values, setFieldValue, isValid }) => (
						<Form onSubmit={handleSubmit}>
							<div className="flex py-4 flex-column align-items-center justify-content-center font-bold">
								<div className="field mb-3 w-28rem">
									<Field
										header={"Customer Mobile Number"}
										name={"mobileNumber"}
										component={FieldInput}
										star={"*"}
									/>
								</div>
								<div className="field mb-3 w-28rem">
									<Field
										header={"Customer PAN Number"}
										name={"panNumber"}
										component={FieldInput}
										star={"*"}
									/>
								</div>

								<div className="field mb-3 w-28rem">
									<Field
										header={"Customer State"}
										name={"state"}
										component={FeildDropdown}
										options={selectState(stateData)}
										filter
										onChange={(e) => {
											handleDistrict(
												e.value,
												setFieldValue
											);
										}}
										optionLabel={"name"}
										star={"*"}
										type={"number"}
									/>
								</div>

								<div className="field mb-3 w-28rem">
									<Field
										header={"Customer District"}
										name={"district"}
										component={FeildDropdown}
										filter
										options={districtListState.districtList}
										optionLabel={"name"}
										star={"*"}
										type={"number"}
									/>
								</div>

								<div className=" flex align-items-center justify-content-between">
									<Button
										label="Continue"
										className=" mr-2"
										type={"submit"}
									/>
									{/* {props.formData.alias !== "HC" && (
										<Button
											label="Share with customer"
											className=" ml-2"
											type="button"
											disabled={isValid ? false : true}
											onClick={(e) => {
												e.preventDefault();
												if (
													values.panNumber &&
													values.mobileNumber &&
													values.district &&
													values.state
												) {
													shareWithCustomer(values);
												} else {
													return toast.current.show({
														severity: "error",
														summary: "Error",
														detail: "Invalid Data",
														style: {
															color: "#000000",
														},
														life: 3000,
													});
												}
											}}
										/>
									)} */}
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
};

export default CreditScoreCheck;
