import { useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { FeildDropdown, FieldInput } from "../../../../components/FieldInput";
import { Button } from "primereact/button";
import Config from "../../../../shared/config";
import * as Yup from "yup";
import { SplitButton } from "primereact/splitbutton";
import { exportExcel } from "../../../../components/XlsxExport";
import { UsersService } from "../../../../services/UsersService";
import { Toast } from "primereact/toast";
import Loader from "../../../../components/Loader";
import { useGetAllHubQuery } from "../../../../services/query/queryApi";
import { CallCenterService } from "../../../../services/callCenterService";
import { useSelector } from "react-redux";
const SearchCallCenterComponent = (props) => {
	const selector = useSelector((state) => state.persistData.auth.user);
	const { data: hubList = [] } = useGetAllHubQuery();
	const toastTL = useRef(null);
	const ccService = new CallCenterService();
	const [loader, setLoader] = useState(false);
	const [spokeList, setSpokeList] = useState([]);
	const [open, setOpen] = useState(false);
	const initialValues = {
		userName: "",
		firstName: "",
		lastName: "",
		middleName: "",
		email: "",
		roleId: "", //number
		mobileNumber: "",
		hubId: "", //number
		spokeId: "", //number
	};
	// const validationSchema = Yup.object().shape({
	// 	email: Yup.string().trim().email("Invalid email").nullable(),
	// 	mobileNumber: Yup.string()
	// 		.matches(
	// 			/^[6-9]\d{9}$/gi,
	// 			"Mobile number should be 10 digit number"
	// 		)
	// 		.nullable(),
	// });
	const handleSearch = (value) => {
		let data = Object.fromEntries(
			Object.entries(value).filter(([_, v]) => v !== "")
		);

		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: data,
		});
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};

	const getSpokeList = (e) => {
		ccService.getSpokeListById(e).then((res) => setSpokeList(res));
	};
	// const items = [
	// 	{
	// 		label: "Export",
	// 		icon: "pi pi-file-excel",
	// 		command: () => {
	// 			exportExcel(props.newData, "User_List");
	// 		},
	// 	},
	// 	{
	// 		label: "Export All",
	// 		icon: "pi pi-file-excel",
	// 		command: () => {
	// 			getAllLoanData();
	// 		},
	// 	},
	// ];
	const handleSpokeList = (e, setFieldValue) => {
		setFieldValue("hubId", e.value, true);
		setFieldValue("spokeId", "", true);
		getSpokeList(e.value);
	};
	return (
		<>
			{loader && <Loader />}
			<Toast ref={toastTL} />
			<div className="w-full py-3 flex align-items-center justify-content-between">
				<h3 className="m-0">All User List</h3>
				<div>
					<Button
						label={open ? "Close" : "Search"}
						icon={open ? "pi pi-times" : "pi pi-search"}
						className={
							open
								? "p-button-outlined p-button-danger"
								: "p-button-outlined"
						}
						aria-label="Cancel"
						onClick={() => setOpen((prev) => !prev)}
					/>
					<Button
						label="Reload"
						icon="pi pi-refresh"
						className="p-button-outlined ml-2"
						onClick={() => {
							props.searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							});
						}}
					/>
					{(selector.role.position === 1000 ||
						selector.role.position === 2000 ||
						selector.role.position === 3000) && (
						<Button
							label="Add Users"
							icon="pi pi-plus"
							className="primary-btn mr-2 ml-2"
							onClick={() => {
								props.addMemberClick();
								props.setType("create");
							}}
						/>
					)}
					{/* <SplitButton
						icon={"pi pi-file-export"}
						label="Export"
						model={items}
						// loading={spinner}
					/> */}
				</div>
			</div>

			{open && (
				<Formik
					initialValues={initialValues}
					enableReinitialize
					onSubmit={handleSearch}
					// validationSchema={validationSchema}
				>
					{({ handleSubmit, resetForm, setFieldValue }) => (
						<Form
							className="background-secondary mt-2 c-search-form-box border-round-md form-grid grid mb-3"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 md:col-3">
								<Field
									header={"Username"}
									name={"userName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Name"}
									name={"firstName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Email Id"}
									name={"email"}
									component={FieldInput}
									type={"email"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Role ID"}
									name={"roleId"}
									component={FeildDropdown}
									options={props.role}
									optionLabel={"name"}
									optionValue={"id"}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Mobile Number"}
									name={"mobileNumber"}
									component={FieldInput}
								/>
							</div>
							{selector.role.position === 1000 && (
								<>
									<div className="field col-12 md:col-3">
										<Field
											header={"Hub"}
											name={"hubId"}
											component={FeildDropdown}
											options={hubList}
											onChange={(e) =>
												handleSpokeList(
													e,
													setFieldValue
												)
											}
											optionLabel={"name"}
											optionValue={"id"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Spoke"}
											name={"spokeId"}
											component={FeildDropdown}
											options={spokeList}
											optionLabel={"name"}
											optionValue={"id"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"User Status"}
											name={"isActive"}
											component={FeildDropdown}
											options={[
												{
													label: "Active",
													value: true,
												},
												{
													label: "Disable",
													value: false,
												},
											]}
										/>
									</div>
								</>
							)}
							<div className="col-3 flex align-items-end justify-content-end">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Search" />
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default SearchCallCenterComponent;
