import React, { Suspense, useRef, useState } from "react";
import Config from "../../../../../shared/config";
import { InputNode } from "../../../../../components/InputComponent";
import { ProgramService } from "../../../../../services/ProgramService";
import { Field, Form, Formik } from "formik";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import S3 from "aws-sdk/clients/s3";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import {
	FeildDropdown,
	FieldEditor,
	FieldInput,
	FieldMultiselect,
} from "../../../../../components/FieldInput";
import { Checkbox } from "primereact/checkbox";
import {
	useGetDsaInstitutionDetailsQuery,
	useGetInstitutionDetailsQuery,
} from "../../../../../services/query/queryApi";
import { useDispatch } from "react-redux";
import {
	setEnable,
	setId,
	setProductType,
} from "../../../../../store/reducer/ProgramReducer";
import { convertObjNull } from "../../../../../utils/UtilsFunction";
import Loader from "../../../../../components/Loader";
import debounce from "lodash.debounce";
import { InstitutionService } from "../../../../../services/InstitutionService";

const General = (props) => {
	const dispatch = useDispatch();
	const programService = new ProgramService();
	const toastTL = useRef(null);
	const institutionService = new InstitutionService();
	const { data: dsaInstitute = { data: [] } } =
		useGetDsaInstitutionDetailsQuery({
			pageNo: 1,
			pageSize: 5000,
		});
	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});
	const { data = [] } = useGetInstitutionDetailsQuery();
	const [processingFee, setProcessingFee] = useState(false);
	const [productList, setProductList] = useState({ institutionProduct: [] });
	const [spinner, setSpinner] = useState(false);
	const programCreate = (payload) => {
		setSpinner(true);
		programService
			.createProgram(payload)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: "Program created",
					style: { color: "#000000" },
					life: 3000,
				});
				dispatch(setId(res.id));
				props.getAllProgramList();
			})
			.then(() => {
				dispatch(setEnable({ index: 0, disable: true }));
				dispatch(setEnable({ index: 1, disable: false }));
			})
			.then(
				debounce(() => {
					props.changeIndex(1);
				}, 1000)
			)
			.catch((error) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setSpinner(false);
			});
	};
	const initialValue = {
		institutionId: "",
		product: "",
		productId: {
			id: null,
		},
		selectType: "", //for dsa
		dsaDetails: { dsaName: "", details: [] }, //for dsa
		name: "",
		shortDescription: "",
		longDescription: "",
		eligibilityDetails: "",
		imageFirst: "",
		imageSecond: "",
		profession: "",
		minLoanAmount: "",
		maxLoanAmount: "",
		minProcessingFees: "",
		maxProcessingFees: "",
		minLoanPeriod: "",
		maxLoanPeriod: "",
		minRateOfInterest: "",
		maxRateOfInterest: "",
		preClosure: false,
		documentsRequired: "",
		joiningFee: false,
		annualFee: false,
		active: true,
		cashback: false,
		fuelSurchargeWaiver: false,
		maxPremiumAmount: "",
		minPremiumAmount: "",
		maxTerm: "",
		minTerm: "",
		maxAge: "",
		minHospitalisation: "",
		maternityBenefit: false,
	};
	const handleSubProduct1 = (e, setFieldValue, dsaDetails) => {
		setFieldValue("institutionId", e.value, true);
		console.log(dsaDetails);
		setFieldValue("name", dsaDetails?.dsaName + "_" + e.value.bankName);
		setFieldValue(
			"productId",
			{
				id: null,
			},
			true
		);
		institutionService
			.getInstitutionWiseProductList(e.value?.id)
			.then((res) => setProductList(res))
			.catch((e) => {});
	};
	const handleSubProduct2 = (e, setFieldValue) => {
		setFieldValue("institutionId", e.value, true);
		setFieldValue("name", e.value.name);
		setFieldValue(
			"productId",
			{
				id: null,
			},
			true
		);
		institutionService
			.getInstitutionWiseProductList(e.value?.id)
			.then((res) => setProductList(res))
			.catch((e) => {});
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string()
			// .matches(/^(\w+\s)*\w+$/, "Please enter valid name")
			.required("This Field is Required"),
		productId: Yup.object()
			.test({
				test: (productId) => productId.id !== null,
				message: "This Field is Required",
			})
			.required("This Field is Required"),
		// shortDescription: Yup.string().required("This Field is Required"),
		// longDescription: Yup.string().required("This Field is Required"),
		eligibilityDetails: Yup.string().required("This Field is Required"),
		documentsRequired: Yup.string().required("This Field is Required"),
		institutionId: Yup.object()
			.nullable()
			.required("This Field is Required"),
		minLoanAmount: Yup.number()
			.when("productId", {
				is: (productId) =>
					productId.alias !== "CC" && productId.alias !== "HC",
				then: Yup.number().required("This Field is Required"),
			})
			.typeError("Amount must be a number")
			.min(0, "Invalid Value"),
		maxLoanAmount: Yup.number().when("productId", {
			is: (productId) =>
				productId.alias !== "CC" && productId.alias !== "HC",
			then: Yup.number()
				.typeError("Amount must be a number")
				.min(0, "Invalid Value")
				.when("minLoanAmount", (minLoanAmount, value) => {
					return value.test({
						test: (maxLoanAmount) =>
							!!minLoanAmount && maxLoanAmount > minLoanAmount,
						message: "Max Loan Amount > Min Loan Amount",
					});
				})
				.required("This Field is Required"),
		}),
		minLoanPeriod: Yup.number()
			.when("productId", {
				is: (productId) =>
					productId.alias !== "CC" && productId.alias !== "HC",
				then: Yup.number().required("This Field is Required"),
			})
			.integer("Invalid Value") //done
			.typeError("Enter a valid number")
			.min(0, "Invalid Value"),
		maxLoanPeriod: Yup.number().when("productId", {
			is: (productId) =>
				productId.alias !== "CC" && productId.alias !== "HC",
			then: Yup.number()
				.integer("Invalid Value") //done
				.typeError("Enter a valid number")
				.min(0, "Invalid Value")
				.when("minLoanPeriod", (minLoanPeriod, value) => {
					return value.test({
						test: (maxLoanPeriod) =>
							!!minLoanPeriod && maxLoanPeriod > minLoanPeriod,
						message: "Max Loan Period > Min Loan Period",
					});
				})
				.required("This Field is Required"),
		}),
		minProcessingFees: Yup.number()
			.when("productId", {
				is: (productId) =>
					productId.alias !== "CC" && productId.alias !== "HC",
				then: Yup.number(),
			}) //done
			.typeError("Amount must be a number")
			.min(0, "Invalid Value"),
		maxProcessingFees: Yup.number().when("productId", {
			is: (productId) =>
				productId.alias !== "CC" && productId.alias !== "HC",
			then: Yup.number()
				.typeError("Amount must be a number")
				.min(0, "Invalid Value"),
			// .when("minProcessingFees", (minProcessingFees, value) => {
			// 	return value.test({
			// 		test: (maxProcessingFees) =>
			// 			!!minProcessingFees &&
			// 			maxProcessingFees > minProcessingFees,
			// 		message: "Max Processing Fee > Min Processing Fee",
			// 	});
			// })
		}), //done
		minRateOfInterest: Yup.number()
			.when("productId", {
				is: (productId) =>
					productId.alias !== "CC" && productId.alias !== "HC",
				then: Yup.number().required("This Field is Required"),
			}) //done
			.typeError("Amount must be a number")
			.min(0, "Invalid Value"),
		maxRateOfInterest: Yup.number().when("productId", {
			is: (productId) =>
				productId.alias !== "CC" && productId.alias !== "HC",
			then: Yup.number()
				.typeError("Amount must be a number")
				.min(0, "Invalid Value")
				.when("minRateOfInterest", (minRateOfInterest, value) => {
					return value.test({
						test: (maxRateOfInterest) =>
							!!minRateOfInterest &&
							maxRateOfInterest > minRateOfInterest,
						message: "Max Rate of interest  > Min Rate of interest",
					});
				})
				.required("This Field is Required"),
		}), //done
		profession: Yup.array()
			.of(Yup.string())
			.min(1, "Field cannot be empty")
			.required("Field cannot be empty"),
	});
	const handleFormSubmit = (value, onSubmitProps) => {
		const {
			product,
			productId,
			dsaDetails,
			institutionId,
			selectType,
			instituteDetails,
			...ObjectRest
		} = value;
		const body = convertObjNull({
			...ObjectRest,
			productId: productId.subProductId,
			institutionId: institutionId.id,
		});
		const newData = {
			...body,
			type: productId.alias === "CC" ? "CREDIT_CARD" : "LOANS",
		};
		dispatch(setProductType(value.productId.alias));
		programCreate(newData);
	};
	const onUploadfirst = (e, setFieldValue) => {
		//setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.PROGRAM_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Contract document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});

				setFieldValue("imageFirst", newFileName);
			}
		});
	};
	const onUploadSecond = (e, setFieldValue) => {
		//setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.PROGRAM_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "2nd document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});

				setFieldValue("imageSecond", newFileName);
			}
		});
	};
	return (
		<>
			{spinner && <Loader />}
			<Toast ref={toastTL} position="top-left" />
			<Suspense fallback={null}>
				<Formik
					initialValues={initialValue}
					validationSchema={validationSchema}
					onSubmit={handleFormSubmit}
					enableReinitialize
				>
					{({
						values,

						handleSubmit,

						setFieldValue,
						touched,
						errors,
						resetForm,
					}) => (
						<Form
							className="form-grid grid"
							onSubmit={handleSubmit}
							autoComplete="on"
						>
							<div className="field col-12 md:col-3">
								<Field
									name="selectType"
									component={FeildDropdown}
									options={[
										{ label: "Institution", value: "INS" },
										{ label: "DSA", value: "DSA" },
									]}
									header={"Select Type"}
									star={"*"}
									value={values.selectType}
									onChange={(e) => {
										setFieldValue(
											"selectType",
											e.value,
											true
										);
										setFieldValue(
											"dsaDetails",
											{ details: [] },
											true
										);

										setFieldValue(
											"institutionId",
											"",
											true
										);
									}}
								/>
							</div>
							{values.selectType === "DSA" ? (
								<>
									<div className="field col-12 md:col-3">
										<Field
											filter
											name="dsaDetails"
											component={FeildDropdown}
											options={dsaInstitute.data}
											optionLabel={"dsaName"}
											header={"Select DSA"}
											star={"*"}
											value={values.dsaDetails}
											onChange={(e) => {
												setFieldValue(
													"dsaDetails",
													e.value,
													true
												);

												setFieldValue(
													"institutionId",
													"",
													true
												);
											}}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											name="institutionId"
											component={FeildDropdown}
											options={values.dsaDetails?.details}
											filter
											optionLabel={"bankName"}
											header={"Bank"}
											star={"*"}
											value={values.institutionId}
											onChange={(e) =>
												handleSubProduct1(
													e,
													setFieldValue,
													values.dsaDetails
												)
											}
										/>
									</div>
								</>
							) : (
								values.selectType === "INS" && (
									<div className="field col-12 md:col-3">
										<Field
											name="institutionId"
											component={FeildDropdown}
											options={data}
											filter
											optionLabel={"name"}
											header={"Bank"}
											star={"*"}
											value={values.institutionId}
											onChange={(e) =>
												handleSubProduct2(
													e,
													setFieldValue
												)
											}
										/>
									</div>
								)
							)}

							<div className="field col-12 md:col-3">
								<Field
									header={"Name of the Program"}
									component={FieldInput}
									name={"name"}
									star={"*"}
								/>
							</div>

							{/* <div className="field col-12 md:col-3">
							<Field
								name="product"
								component={FeildDropdown}
								options={
									productData.isSuccess
										? productData.data.data
										: []
								}
								optionLabel="title"
								header={"Product"}
								star={"*"}
							/>
						</div> */}

							<div className="field col-12 md:col-3">
								<Field
									name="productId"
									component={FeildDropdown}
									options={productList.institutionProduct}
									optionLabel="subProduct"
									header={"Sub Product"}
									star={"*"}
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									name={`profession`}
									component={FieldMultiselect}
									options={Config.OCCUPATION}
									optionLabel="name"
									header={"Profession"}
									star={"*"}
								/>
							</div>

							{values.productId.alias === "CC" ? (
								<>
									<div className="field col-12 md:col-3">
										<Field
											component={FeildDropdown}
											name={"joiningFee"}
											options={Config.YES_NO}
											header={"Joining Fee"}
											star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											component={FeildDropdown}
											name={"annualFee"}
											options={Config.YES_NO}
											header={"Annual Fee"}
											star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											component={FeildDropdown}
											name={"cashback"}
											options={Config.YES_NO}
											header={"Cash Back"}
											star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											component={FeildDropdown}
											name={"fuelSurchargeWaiver"}
											options={Config.YES_NO}
											header={"Fuel Surcharge Waiver"}
											star="*"
										/>
									</div>
								</>
							) : values.productId.alias === "HC" ? (
								<>
									<div className="field col-12 md:col-3">
										<Field
											component={FieldInput}
											name={"maxPremiumAmount"}
											type={"number"}
											header={"Max Premium Amount"}
											star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											component={FieldInput}
											name={"minPremiumAmount"}
											type={"number"}
											header={"Min Premium Amount"}
											star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											component={FieldInput}
											name={"maxTerm"}
											type={"number"}
											header={"Max Term"}
											star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											component={FieldInput}
											name={"minTerm"}
											type={"number"}
											header={"Min Term"}
											star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											component={FieldInput}
											name={"maxAge"}
											type={"number"}
											header={"Max Age"}
											star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											component={FieldInput}
											name={"minHospitalisation"}
											type={"number"}
											header={"Min Hospitalisation"}
											star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											component={FeildDropdown}
											name={"maternityBenefit"}
											type={"number"}
											options={Config.YES_NO}
											header={"Maternity Benefit"}
											star="*"
										/>
									</div>
								</>
							) : (
								<>
									<div className="col-12">
										<Checkbox
											checked={processingFee}
											onChange={(e) =>
												setProcessingFee(e.checked)
											}
										/>{" "}
										Is processingFee fixed?
									</div>
									<div className="field col-12 md:col-3">
										<Field
											type="number"
											component={FieldInput}
											name={"minLoanAmount"}
											header={"Min Loan Amount"}
											star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											type="number"
											component={FieldInput}
											name={"maxLoanAmount"}
											header={"Max Loan Amount"}
											star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											type="number"
											component={FieldInput}
											name={"minProcessingFees"}
											header={`Min Processing Fees ${
												!processingFee ? "%" : ""
											}`}
											// star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											type="number"
											component={FieldInput}
											name={"maxProcessingFees"}
											header={`Max Processing Fees ${
												!processingFee ? "%" : ""
											}`}
											// star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											type="number"
											component={FieldInput}
											name={"minLoanPeriod"}
											header={
												"Min Loan Period (in months)"
											}
											star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											type="number"
											component={FieldInput}
											name={"maxLoanPeriod"}
											header={
												"Max Loan Period (in months)"
											}
											star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											type="number"
											component={FieldInput}
											name={"minRateOfInterest"}
											header={"Min Rate Of Interest(%)"}
											star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											type="number"
											component={FieldInput}
											name={"maxRateOfInterest"}
											header={"Max Rate Of Interest(%)"}
											star="*"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											component={FeildDropdown}
											name={"preClosure"}
											options={Config.PRECLOSURE}
											header={"Pre-closure"}
											star="*"
										/>
									</div>
								</>
							)}
							<div className="col-12"> </div>
							<div className="field col-12 md:col-6">
								<Field
									name="shortDescription"
									component={FieldEditor}
									header={"Short Description"}
								/>
							</div>
							<div className="field col-12 md:col-6">
								<Field
									name="longDescription"
									component={FieldEditor}
									header={"Long Description"}
								/>
							</div>

							<div className="field col-12 md:col-6">
								<Field
									name="eligibilityDetails"
									component={FieldEditor}
									header={"Eligibility Details"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-6">
								<Field
									name="documentsRequired"
									component={FieldEditor}
									header={"Documents Required"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-6">
								<InputNode header={"Upload Image 1"}>
									<FileUpload
										name="imageFirst"
										customUpload
										accept=".jpg,.png,.jpeg"
										uploadHandler={(e) =>
											onUploadfirst(e, setFieldValue)
										}
										maxFileSize={1000000}
										emptyTemplate={
											<p className="m-0">
												Drag and drop files to here to
												upload.
											</p>
										}
									/>
									{errors.imageFirst &&
										touched.imageFirst && (
											<small className="p-error">
												{errors.imageFirst}
											</small>
										)}
								</InputNode>
							</div>
							<div className="field col-12 md:col-6">
								<InputNode header={"Upload Image 2"}>
									<FileUpload
										name="imageSecond"
										customUpload
										accept=".jpg,.png,.jpeg"
										uploadHandler={(e) =>
											onUploadSecond(e, setFieldValue)
										}
										maxFileSize={1000000}
										emptyTemplate={
											<p className="m-0">
												Drag and drop files to here to
												upload.
											</p>
										}
									/>
									{errors.imageSecond &&
										touched.imageSecond && (
											<small className="p-error">
												{errors.imageSecond}
											</small>
										)}
								</InputNode>
							</div>
							<div className="flex justify-content-end align-items-center w-full mt-4">
								<Button
									type="button"
									label="Cancel"
									// onClick={() => props.dialog(false)}
									onClick={() => resetForm()}
									className="p-button-danger mr-2"
									style={{
										borderRadius: "6px",
										width: "10rem",
									}}
								/>
								<Button
									type="submit"
									label="Save & next"
									style={{ borderRadius: "6px" }}
								/>
							</div>
						</Form>
					)}
				</Formik>
			</Suspense>
		</>
	);
};

export default General;
