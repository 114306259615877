// import React from "react";
// import { TabPanel, TabView } from "primereact/tabview";

// import { useSelector } from "react-redux";
// import VendorTreeview from "./components/VendorTreeview";
// import VendorListview from "./components/VendorListview";
import AgentsCCProfile from "./AgentCCProfile";
// import VendorListview from "./components/VendorListview";
const MyVendorAgents = () => {
	// const selector = useSelector((state) => state.persistData.auth.user);
	return (
		<div className="grid">
			<div className="col-12">
				{/* {selector.role.position === 1000 ? (
					<AgentsCCProfile />
				) : (
					<TabView className="table-tab" activeIndex={1}>
						<TabPanel header="Tree List" className="p-0">
							<VendorTreeview />
						</TabPanel>

						<TabPanel header="Agents List" className="p-0">
							<VendorListview />
						</TabPanel>
					</TabView>
				)} */}
				<AgentsCCProfile />
			</div>
		</div>
	);
};

export default MyVendorAgents;
