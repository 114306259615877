import axios from "axios";
import { agent_end_point_url, apiPath } from "../shared/constant";
export class ProgramOtpService {
	constructor() {
		this.token = sessionStorage.getItem("token");
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}

	getOtp = async (payload) => {
		try {
			const res = await axios.post(
				`${agent_end_point_url}/otp/send`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	varifyOtp = async (payload) => {
		try {
			const res = await axios.post(
				`${agent_end_point_url}/otp/verify`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	shareWithCustomer = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/application/customer-fill`,
				// `http://192.168.0.149:8000/rainbowmaster/application/customer-fill`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			console.log(error);
			throw error.response.data;
		}
	};
}
