import React, { useEffect, useReducer } from "react";
import { dataReducer, fetchInitialState } from "../../components/fetchReducer";
import {
	searchInitialState,
	searchReducer,
} from "../../components/searchReducer";
import TableLoader from "../../components/TableLoader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Paginator from "../../components/Paginator";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import SearchCustomer from "./components/SearchCustomer";
import Config from "../../shared/config";
import { ReportService } from "../../services/ReportService";
import moment from "moment";

const CustomerList = () => {
	const reportService = new ReportService();
	const [customerListState, customerListReducer] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);

	const getCustomerList = () => {
		const body = {
			...searchState.searchValue,

			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,

			...(searchState.sortByColumn &&
				searchState.sortType && {
					sorts: [
						searchState.sortByColumn + "," + searchState.sortType,
					],
				}),
		};

		const demo = Object.fromEntries(
			Object.entries(body).filter(([_, v]) => v !== "")
		);

		customerListReducer({ type: Config.FETCH_CONFIG.start });
		reportService
			.getCustomerList(demo)
			.then((res) => {
				customerListReducer({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((e) => {
				customerListReducer({
					type: Config.FETCH_CONFIG.error,
					payload: e,
				});
			})
			.finally(() => {
				customerListReducer({
					type: Config.FETCH_CONFIG.stop,
				});
			});
	};

	const getSortableColumn = (name, columnName) => {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	};
	function tblSort(columnName, sortType) {
		// setSortByColumn(columnName);
		// setSortType(sortType);

		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}

	useEffect(() => {
		getCustomerList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);
	const itaration = {
		id: "",
		name: "",
		gender: "",
		mobileNumber: "",
		email: "",
		createdOn: "",
		userName: "",
		stateName: "",
		// districtName: "",
		leadCount: "",
		applicationCount: "",
		updateOn: "",
	};
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />
				<Column header={getSortableColumn("Name", "firstName")} />
				<Column header={getSortableColumn("Gender", "gender")} />
				<Column header={"Mobile"} />
				<Column header={"Email"} />
				<Column header={getSortableColumn("UserName", "userName")} />
				<Column header={getSortableColumn("State", "preState")} />
				{/* <Column
					header={getSortableColumn("District", "districtName")}
				/> */}
				<Column header="Lead Count" />
				<Column header="Application Count" />
				<Column header="Create On" />
				<Column header="Update On" />
			</Row>
		</ColumnGroup>
	);
	const getName = (item) => {
		let fname = item.firstName ? item.firstName : "";
		let mname = item.middleName ? item.middleName : "";
		let lname = item.lastName ? item.lastName : "";
		let name = `${fname} ${mname} ${lname}`;
		if (!item.firstName && !item.middleName && !item.lastName) {
			return item.customerName;
		} else {
			return name;
		}
	};

	const createOnFormat = (item) => {
		return (
			<>
				{item.createdAt &&
					moment(item.createdAt).format("Do MMM, YYYY HH:mm A")}
			</>
		);
	};

	const updateOnFormat = (item) => {
		return (
			<>
				{item.modifiedAt &&
					moment(item.modifiedAt).format("Do MMM, YYYY HH:mm A")}
			</>
		);
	};
	return (
		<>
			<SearchCustomer
				searchDispatch={searchDispatch}
				data={customerListState.data.data}
			/>
			{customerListState.isLoading ? (
				<TableLoader itaration={itaration} headerGroup={headerGroup} />
			) : (
				<>
					<Paginator
						pageSize={customerListState.data.pageSize}
						firstPage={customerListState.data.firstPage}
						lastPage={customerListState.data.lastPage}
						decrement={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.decrement,
							})
						}
						increment={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.increment,
							})
						}
						totalElements={customerListState.data.totalElements}
						pagesizechange={(e) => {
							// let newPageNo = Math.ceil(
							// 	(customerListState.data.pageSize *
							// 		customerListState.data.pageNo) /
							// 		e.target.value
							// );
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageSize,
								payload: e.target.value,
							});
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageNo,
								payload: 1,
							});
						}}
						pageNo={customerListState.data.pageNo}
						totalPages={customerListState.data.totalPages}
					/>
					<DataTable
						headerColumnGroup={headerGroup}
						value={
							customerListState.data.data &&
							customerListState.data.data.map((item, index) => ({
								...item,
								index:
									customerListState.data.pageSize *
										customerListState.data.pageNo -
									customerListState.data.pageSize +
									1 +
									index,
							}))
						}
						responsiveLayout="scroll"
					>
						<Column field="index" />
						<Column body={getName} />
						<Column field={"gender"} />
						<Column field={"mobileNumber"} />
						<Column field={"email"} />
						<Column field={"userName"} />
						<Column field={"stateName"} />
						{/* <Column field={"districtName"} /> */}
						<Column field={"leadCount"} />
						<Column field={"applicationCount"} />
						<Column body={createOnFormat} />
						<Column body={updateOnFormat} />
					</DataTable>
					<Paginator
						pageSize={customerListState.data.pageSize}
						firstPage={customerListState.data.firstPage}
						lastPage={customerListState.data.lastPage}
						decrement={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.decrement,
							})
						}
						increment={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.increment,
							})
						}
						totalElements={customerListState.data.totalElements}
						pagesizechange={(e) => {
							// let newPageNo = Math.ceil(
							// 	(customerListState.data.pageSize *
							// 		customerListState.data.pageNo) /
							// 		e.target.value
							// );
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageSize,
								payload: e.target.value,
							});
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageNo,
								payload: 1,
							});
						}}
						pageNo={customerListState.data.pageNo}
						totalPages={customerListState.data.totalPages}
					/>
				</>
			)}
		</>
	);
};

export default CustomerList;
