import { DataTable } from "primereact/datatable";
import React, { useEffect, useReducer, useState } from "react";
import { dataReducer } from "../../components/fetchReducer";
import { Column } from "primereact/column";
import Config from "../../shared/config";
import { useSelector } from "react-redux";
import axios from "axios";
import Paginator from "../../components/Paginator";
import {
	searchInitialState,
	searchReducer,
} from "../../components/searchReducer";
import { Button } from "primereact/button";
import { Field, FieldArray, Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import { produce } from "immer";
import jsPDF from "jspdf";
import { SplitButton } from "primereact/splitbutton";
import { exportExcel } from "../../components/XlsxExport";
import { useLocation, useParams } from "react-router-dom";
import { agent_end_point_url, apiPath } from "../../shared/constant";
import Loader from "../../components/Loader";
import SearchParams from "./SearchParams";
import moment from "moment";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import "jspdf-autotable";
const ApplicationReport = () => {
	const { state } = useLocation();
	const { url, id } = useParams();
	const [loader, setLoader] = useState(false);
	const selector = useSelector((state) => state.persistData);
	const [dialog, setDialog] = useState(false);
	const [applicationReportState, applicationReportDispatch] = useReducer(
		dataReducer,
		{
			data: { data: [] },
			isLoading: false,
			error: false,
			errorMessage: null,
			isFetched: false,
		}
	);
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const [tableData, setTableData] = useState({ data: [] });
	const [open, setOpen] = useState(false);
	useEffect(() => {
		setOpen(false);

		const requestBody = {
			...(open && searchState.searchValue),
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
		};
		setLoader(true);
		axios
			.post(
				(url === "rainbowbackend" ? agent_end_point_url : apiPath) +
					"/report/" +
					id,
				requestBody,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${selector?.auth?.token}`,
					},
				}
			)
			.then((res) => {
				if (tableData?.data?.length === 0) {
					setTableData(res.data);
				}

				applicationReportDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res.data,
				});
			})
			.catch((err) => {
				applicationReportDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: { data: [] },
				});
			})
			.finally((res) => setLoader(false));
	}, [url, id, searchState]);

	const exportPdf = () => {
		var columns =
			applicationReportState?.data?.data &&
			Object.keys(applicationReportState?.data?.data[0])?.map((k) => ({
				title: k,
				dataKey: k,
			}));

		const doc = new jsPDF("l", "pt", [1227, 900]);

		doc.autoTable(columns, applicationReportState?.data?.data, {
			theme: "grid",
			headerStyles: {
				fillColor: [37, 61, 152],
				//textColor: [78, 53, 73], //Black
				textColor: [255, 255, 255], //White
			},
		});
		doc.save(state?.heading + new Date().toLocaleDateString() + ".pdf");
	};
	const exportAllPdf = () => {
		const requestBody = {
			pageNo: 1,
			pageSize: 100000,
		};
		setLoader(true);
		axios
			.post(
				(url === "rainbowbackend" ? agent_end_point_url : apiPath) +
					"/report/" +
					id,
				requestBody,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${selector?.auth?.token}`,
					},
				}
			)

			.then((res) => {
				const response = res.data;
				var columns =
					response?.data &&
					Object.keys(response?.data[0])?.map((k) => ({
						title: k,
						dataKey: k,
					}));

				const doc = new jsPDF("l", "pt", [1227, 900]);

				doc.autoTable(columns, response?.data, {
					theme: "grid",
					headerStyles: {
						fillColor: [37, 61, 152],
						//textColor: [78, 53, 73], //Black
						textColor: [255, 255, 255], //White
					},
				});
				doc.save(
					state?.heading + new Date().toLocaleDateString() + ".pdf"
				);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally((res) => setLoader(false));
	};
	const exportAllXlsx = () => {
		const requestBody = {
			pageNo: 1,
			pageSize: 100000,
		};
		setLoader(true);
		axios
			.post(
				(url === "rainbowbackend" ? agent_end_point_url : apiPath) +
					"/report/" +
					id,
				requestBody,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${selector?.auth?.token}`,
					},
				}
			)
			.then((res) => {
				const response = res.data;
				exportExcel(
					response?.data,
					id + "_" + new Date().toLocaleDateString()
				);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally((res) => setLoader(false));
	};
	const handleFormSubmit = (value) => {
		const { fromDate, toDate, ...rest } = value;
		const payload = {
			...rest,
			fromDate: Boolean(fromDate)
				? moment(fromDate).format("YYYY-MM-DD")
				: null,
			toDate: Boolean(toDate)
				? moment(toDate).format("YYYY-MM-DD")
				: null,
		};
		let newValue = Object.values(payload);
		let newKeys = Object.keys(payload);
		const newObj = produce({}, (state) => {
			for (let index = 0; index < newValue.length; index++) {
				const element = newValue[index];
				if (
					(!Array.isArray(element) && Boolean(element)) ||
					(Array.isArray(element) && element.length)
				) {
					state[newKeys[index]] = element;
				}
			}
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: newObj,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: 50,
		});
	};
	const handleReset = () => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: { pageNo: 1, pageSize: 50 },
		});
	};
	const headerColumnGroup = (
		<ColumnGroup>
			<Row>
				{tableData?.data?.length > 0 &&
					Object.keys(tableData?.data[0])?.map((elm, index) => (
						<Column
							style={{ width: "150px", textAlign: "center" }}
							key={index}
							header={elm}
						/>
					))}
			</Row>
		</ColumnGroup>
	);
	return (
		<>
			{loader && <Loader />}
			<div
				className="flex w-full align-items-center justify-content-between "
				style={{
					position: "sticky",
					top: 0,
					zIndex: 10,
					backgroundColor: "#fff7cc",
					padding: "10px 5px",
				}}
			>
				<div className="flex flex-wrap gap-2  c-application-btn">
					<h4>{state?.heading}</h4>
				</div>
				<div className="flex gap-2">
					<Button
						icon={open ? "pi pi-times" : "pi pi-search"}
						className={
							open
								? "p-button-danger p-button-outlined"
								: "p-button-outlined"
						}
						label={open ? "Close" : "Search"}
						onClick={() => setOpen((prev) => !prev)}
					/>
					<Button
						icon="pi pi-cog"
						onClick={() => setDialog(true)}
						className="p-button-outlined "
						label="Hide Column"
					/>
					<SplitButton
						label="Export"
						icon="pi pi-plus"
						// onClick={save}
						model={[
							{
								label: "Export PDF",
								icon: "pi pi-file-pdf",
								command: (e) => {
									exportPdf();
								},
							},
							{
								label: "Export XLSX",
								icon: "pi pi-file-excel",
								command: () => {
									exportExcel(
										applicationReportState?.data?.data,
										id +
											"_" +
											new Date().toLocaleDateString()
									);
								},
							},
							{
								label: "Export PDF All",
								icon: "pi pi-file-pdf",
								command: (e) => {
									exportAllPdf();
								},
							},
							{
								label: "Export XLSX All",
								icon: "pi pi-file-excel",
								command: () => {
									exportAllXlsx();
								},
							},
						]}
					></SplitButton>
				</div>
			</div>
			{open && (
				<SearchParams
					handleReset={handleReset}
					urlId={id}
					setOpen={setOpen}
					handleFormSubmit={handleFormSubmit}
				/>
			)}
			<Formik
				initialValues={{
					columns: [],
				}}
				onSubmit={(value) => {
					const data = produce(tableData, (state) => {
						state.data = state.data?.map((elem) => {
							const newObj = produce(elem, (draft) => {
								for (
									let index = 0;
									index < value.columns.length;
									index++
								) {
									const element = value.columns[index];
									delete draft[element];
								}
							});
							return newObj;
						});
					});
					applicationReportDispatch({
						type: Config.FETCH_CONFIG.success,
						payload: data,
					});
				}}
			>
				{({ resetForm, values, setFieldValue, handleChange }) => (
					<Dialog
						header={"Select checkbox to hide column"}
						style={{ width: "70%" }}
						visible={dialog}
						onHide={() => setDialog(false)}
					>
						<Form className=" grid">
							<div className="col-6">
								<div className="modal-form-grid c-resetForm-w">
									<FieldArray
										name={"names"}
										render={() => (
											<>
												<div className="flex mt-3  flex-wrap">
													{tableData?.data &&
														Object.keys(
															tableData?.data[0]
														).map((item, index) => (
															<div
																className="field-checkbox w-full"
																key={index}
															>
																<Field
																	type="checkbox"
																	value={item}
																	name={`columns`}
																	onChange={(
																		e
																	) => {
																		handleChange(
																			e
																		);
																	}}
																/>
																<label>
																	{item}
																</label>
															</div>
														))}
												</div>
											</>
										)}
									/>
								</div>
							</div>
							{/* {console.log(values.names)} */}
							<div className="col-6   flex-wrap">
								<div className="modal-form-grid c-resetForm-w c-resetForm-w-2">
									<div className="c-resetForm-w-in">
										{values.columns?.map((item, index) => (
											<div
												className="field-checkbox "
												key={index}
											>
												<label>{item}</label>
											</div>
										))}
									</div>
								</div>
							</div>

							<div className="flex align-items-center justify-content-end py-2 gap-2">
								<Button type="submit" label="Apply" />
								<Button
									type="button"
									onClick={() => {
										resetForm();
										// getData("HTML", [], id);
									}}
									label="Reset"
									className="danger-btn"
								/>
							</div>
						</Form>
					</Dialog>
				)}
			</Formik>
			<div className="mt-3"></div>
			{applicationReportState?.data?.data?.length > 0 && (
				<Paginator
					pageSize={applicationReportState.data.pageSize}
					firstPage={applicationReportState.data.firstPage}
					lastPage={applicationReportState.data.lastPage}
					decrement={() =>
						searchDispatch({
							type: Config.SEARCH_CONFIG.decrement,
						})
					}
					increment={() =>
						searchDispatch({
							type: Config.SEARCH_CONFIG.increment,
						})
					}
					totalElements={applicationReportState.data.totalElements}
					pagesizechange={(e) => {
						// let newPageNo = Math.ceil(
						// 	(applicationReportState.data.pageSize * applicationReportState.data.pageNo) /
						// 		e.target.value
						// );
						searchDispatch({
							type: Config.SEARCH_CONFIG.pageSize,
							payload: Number(e.target.value),
						});
						searchDispatch({
							type: Config.SEARCH_CONFIG.pageNo,
							payload: 1,
						});
					}}
					pageNo={applicationReportState.data.pageNo}
					totalPages={applicationReportState.data.totalPages}
				/>
			)}

			{applicationReportState?.data?.data?.length > 0 ? (
				<DataTable
					scrollable
					value={applicationReportState.data.data ?? []}
				>
					{applicationReportState?.data?.data?.length > 0 &&
						Object.keys(applicationReportState?.data?.data[0])?.map(
							(elm, index) => (
								<Column
									key={index}
									header={elm}
									body={(item) => (
										<p
											style={{
												width: "130px",
												overflowWrap: "break-word",
											}}
										>
											{item[elm]}
										</p>
									)}
									// field={elm}
								/>
							)
						)}
				</DataTable>
			) : (
				<DataTable
					headerColumnGroup={headerColumnGroup}
					value={[]}
				></DataTable>
			)}
			{applicationReportState?.data?.data?.length > 0 && (
				<Paginator
					pageSize={applicationReportState.data.pageSize}
					firstPage={applicationReportState.data.firstPage}
					lastPage={applicationReportState.data.lastPage}
					decrement={() =>
						searchDispatch({
							type: Config.SEARCH_CONFIG.decrement,
						})
					}
					increment={() =>
						searchDispatch({
							type: Config.SEARCH_CONFIG.increment,
						})
					}
					totalElements={applicationReportState.data.totalElements}
					pagesizechange={(e) => {
						// let newPageNo = Math.ceil(
						// 	(applicationReportState.data.pageSize * applicationReportState.data.pageNo) /
						// 		e.target.value
						// );
						searchDispatch({
							type: Config.SEARCH_CONFIG.pageSize,
							payload: Number(e.target.value),
						});
						searchDispatch({
							type: Config.SEARCH_CONFIG.pageNo,
							payload: 1,
						});
					}}
					pageNo={applicationReportState.data.pageNo}
					totalPages={applicationReportState.data.totalPages}
				/>
			)}
		</>
	);
};

export default ApplicationReport;
