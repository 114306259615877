import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import S3 from "aws-sdk/clients/s3";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import Config from "../../../shared/config";
import { InstitutionService } from "../../../services/InstitutionService";
import Loader from "../../../components/Loader";

const s3Clint = new S3({
	accessKeyId: Config.S3_ACCESSKEYID,
	secretAccessKey: Config.S3_SECRETACCESSKEY,
	region: Config.S3_REGION,
	signatureVersion: "v4",
});

const AddContract = (props) => {
	const navigate = useNavigate();
	const toastTL = useRef(null);
	const institutionService = new InstitutionService();
	const [isLoading, setLoading] = useState(false);
	const formik = useFormik({
		initialValues: {
			startDate: null,
			endDate: null,
			contractDocName: null,
		},
		validate: (data) => {
			let errors = {};

			if (!data.startDate) {
				errors.startDate = "Start date is required.";
			}
			if (!data.endDate) {
				errors.endDate = "End date is required";
			} else if (moment(data.endDate).isBefore(data.startDate)) {
				errors.endDate = "End date can not be less then start date";
			}
			if (!data.contractDocName) {
				errors.contractDocName = "Contract document is required";
			}
			return errors;
		},
		onSubmit: (data) => {
			setLoading(true);
			const requestBody = {
				dsaId: props.id,
				contractDocName: data.contractDocName,
				contractStartDate: moment(data.startDate).format("YYYY-MM-DD"),
				contractEndDate: moment(data.endDate).format("YYYY-MM-DD"),
			};
			institutionService
				.addDSAInstitutionContract(requestBody)
				.then((res) => {
					if (res) {
						setLoading(false);
						toastTL.current.show({
							severity: "success",
							summary: "Successful",
							detail: "Contract created.",
							style: { color: "#000000" },
							life: 3000,
						});
						navigate("/financial-institution/dsa");
					}
				})
				.catch((e) => {
					setLoading(false);
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});
				});
		},
	});

	const isFormFieldValid = (name) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};

	const onUpload = (e) => {
		setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.INSTITUTION_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successful",
					detail: "Contract document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});
				formik.setFieldValue("contractDocName", newFileName);
			}
		});
	};

	return (
		<div className="col-12 px-0">
			<Toast ref={toastTL} position="top-left" />
			<div className="grid">
				<div className="col-12 md:col-12">
					{isLoading && <Loader />}
					<div className="card form-grid pl-6 pr-6 pt-5 pb-5 generalbgbox">
						<form
							onSubmit={formik.handleSubmit}
							className="p-fluid grid"
						>
							<FocusError formik={formik} />
							<div className="field col-12 md:col-6 lg:col-4">
								<label htmlFor="startDate">Start Date*</label>
								<Calendar
									name="startDate"
									className={classNames({
										"p-invalid":
											isFormFieldValid("startDate"),
									})}
									value={formik.values.startDate}
									onChange={formik.handleChange}
									dateFormat="dd/mm/yy"
									minDate={new Date()}
									showIcon
								/>
								{getFormErrorMessage("startDate")}
							</div>
							<div className="field col-12 md:col-6 lg:col-4">
								<label htmlFor="endDate">End Date*</label>
								<Calendar
									name="endDate"
									className={classNames({
										"p-invalid":
											isFormFieldValid("startDate"),
									})}
									value={formik.values.endDate}
									onChange={formik.handleChange}
									dateFormat="dd/mm/yy"
									minDate={new Date()}
									showIcon
								/>
								{getFormErrorMessage("endDate")}
							</div>
							<div className="field col-12 md:col-12 lg:col-8">
								<label htmlFor="contractDocName">
									Upload Document*
								</label>
								<FileUpload
									name="contractDocName"
									customUpload
									accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
									uploadHandler={onUpload}
									maxFileSize={2000000}
									emptyTemplate={
										<p className="m-0">
											Drag and drop files to here to
											upload.
										</p>
									}
								/>
								{getFormErrorMessage("contractDocName")}
								<p className="p-error">
									Notes: <br />
									1) Please upload the document first before
									submiting the form. <br /> 2) Upload
									document maximum size 2 mb.
								</p>
							</div>
							<div className="flex justify-content-end align-items-center w-full">
								<div className="col-12 md:col-3 lg:col-3 mt-3 flex">
									<Button
										type="button"
										label="Reset"
										className="p-button-danger mr-2"
										icon="pi pi-times"
										onClick={() => formik.resetForm()}
									/>
									<Button
										type="submit"
										label="Save"
										icon="pi pi-save"
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};
export default AddContract;
