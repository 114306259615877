import React, { useState, useRef, useEffect, useReducer } from "react";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import {
	searchInitialState,
	searchReducer,
} from "../../../components/searchReducer";
import Config from "../../../shared/config";
import TableLoader from "../../../components/TableLoader";
import Paginator from "../../../components/Paginator";
import AllocateAgentForm from "./componentAllocation/AllocateAgentForm";
import { useSelector } from "react-redux";
import { CallCenterService } from "../../../services/callCenterService";
import SearchAgentLead from "./componentAllocation/SearchAgentLead";
import { ConfirmDialog } from "primereact/confirmdialog";
import Loader from "../../../components/Loader";
import moment from "moment";
import MenuComponent from "../../../components/MenuComponent";
import { Field, Form, Formik } from "formik";
import { FieldTextArea } from "../../../components/FieldInput";
import LeadStatusUpdate from "./components/LeadStatusUpdate";
import LeadReminder from "./components/LeadReminder";
import BulkUpload from "./BulkAgentLeadUplode/BulkUpload";

const CCAllocateAgentLead = () => {
	const toast = useRef(null);
	const ccService = new CallCenterService();
	const [bulkDialoge, setBulkDialogue] = useState(false);
	const [calllog, setCalllog] = useState([]);
	const [callLogDialog, setCallLogDialog] = useState(false);
	const [response, setResponse] = useReducer(dataReducer, fetchInitialState);
	const [profileDetailDialog, setProfileDetailDialog] = useState({
		state: false,
		data: null,
	});
	const [callDialog, setCallDialog] = useState({
		state: false,
		id: null,
		// index: null,

		// rowData: null,
	});
	const [noteDetailDialog, setNoteDetailDialog] = useState({
		state: false,
		id: null,
		note: "",
	});
	const [remarkDetailDialog, setRemarkDetailDialog] = useState({
		state: false,
		id: null,
		rowData: null,
		index: null,
		status: null,
	});
	const [statusDialog, setStatusDetailDialog] = useState({
		state: false,
		id: null,
		index: null,
		rowData: null,
	});
	const [reminderDialog, setReminderDetailDialog] = useState({
		state: false,
		id: null,
	});
	const [searchState, searchDispatch] = React.useReducer(
		searchReducer,
		searchInitialState
	);
	const [visible, setVisible] = useState({ confirm: false, id: null });
	const [visibleMessage, setVisibleMessage] = useState({
		confirm: false,
		id: null,
		type: "",
	});
	const [loader, setLoader] = useState(false);

	const [selectedProducts, setSelectedProducts] = useState([]);
	const selector = useSelector((state) => state.persistData.auth.user.role);
	/**
	 * Table Sort Function
	 */
	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	function tblSort(columnName, sortType) {
		// searchDispatch({
		// 	type: "SORT_BY_COL",
		// 	payload: columnName,

		// });
		// searchDispatch({
		// 	type: "SORT_TYPE",
		// 	payload: sortType,
		// });
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}
	const increment = () => {
		// searchDispatch({ type: "PAGEINC" });
		searchDispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		// searchDispatch({ type: "PAGEDEC" });
		searchDispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const pagesizechange = (e) => {
		// setpageSize(e.target.value);
		// searchDispatch({ type: "PAGESIZE", payload: e.target.value });
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
	};
	const handleNoteSubmit = (val) => {
		setLoader(true);
		ccService
			.agentLeadNoteSave({
				agentLeadId: noteDetailDialog.id,
				notes: val.notes,
			})
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});
				setSelectedProducts([]);
				getResponse();
				setNoteDetailDialog((prev) => ({
					...prev,
					state: false,
					id: null,
				}));
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoader(false));
	};
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column
					selectionMode={"multiple"}
					headerStyle={{ width: "3rem" }}
				/>
				<Column header={getSortableColumn("Name", "firstName")} />
				<Column header={"Mobile"} />
				<Column header={"State"} />
				<Column header={"City"} />
				<Column header={"Assigned To"} />
				<Column header={getSortableColumn("Source", "source")} />
				<Column header={getSortableColumn("Created On", "createdOn")} />
				<Column header={getSortableColumn("Updated On", "updatedOn")} />
				<Column
					header={getSortableColumn("Last Contacted", "lastCalled")}
				/>
				<Column header={getSortableColumn("Status", "currentStatus")} />
				<Column header={"Note"} />
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const nameTemplate = ({ firstName, middleName, lastName }) => {
		return (
			(firstName ? firstName : "") +
			" " +
			(middleName ? middleName : "") +
			" " +
			(lastName ? lastName : "")
		);
	};

	const assignedToTemplate = ({
		assignedTo,
		assignedToFirstName,
		assignedToLastName,
	}) => {
		return (
			(assignedToFirstName ? assignedToFirstName : "") +
			" " +
			(assignedToLastName ? assignedToLastName : "") +
			" " +
			(assignedTo ? `(${assignedTo})` : "")
		);
	};

	const itaration = {
		profileType: "",
		firstName: "",
		pincode: "",
		mobileNumber: "",
		emailId: "",
		emailId1: "",
		emailIdd1: "",
		aa: "",
		bb: "",
		s: "",
		d: "",
		dd: "",
		ff: "",
	};

	const getResponse = () => {
		const requestBody = {
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.searchValue !== "" && searchState.searchValue),
			...(searchState.sortByColumn && searchState.sortType
				? {
						sorts: [
							`${
								searchState.sortByColumn === "firstName"
									? `${searchState.sortByColumn}`
									: searchState.sortByColumn
							},${searchState.sortType}`,
						],
				  }
				: { sorts: [] }),
		};
		setResponse({ type: Config.FETCH_CONFIG.start });
		ccService
			.getCCAgentLead(requestBody)

			.then((res) => {
				setResponse({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
				setResponse({
					type: Config.FETCH_CONFIG.fetched,
				});
			})
			.catch(() => {
				toast.current.show([
					{
						severity: "error",
						summary: "Error",
						detail: "Error",
						life: 3000,
					},
				]);
			})
			.finally(() => setResponse({ type: Config.FETCH_CONFIG.stop }));
	};
	useEffect(() => {
		getResponse();
		// eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/exhaustive-deps
	}, [searchState]);
	const handleCallLog = (id) => {
		ccService
			.getAgentLeadCallLog({ id: id })
			.then((res) => setCalllog(res));
	};
	const actionTemplate = (item, option) => {
		const items = [
			{
				label: "Allocate",
				icon: "pi pi-sitemap",
				command: () => {
					setProfileDetailDialog((prev) => ({
						...prev,
						state: true,
						data: [item.id],
					}));
				},
			},

			{
				label: "Add Note",
				icon: "pi pi-file-edit",
				command: () => {
					setNoteDetailDialog((prev) => ({
						...prev,
						state: true,
						id: [item.id],
						note: item?.notes,
					}));
				},
			},
			{
				label: "Status Log",
				icon: "pi pi-history",
				command: () => {
					setStatusDetailDialog((prev) => ({
						...prev,
						state: true,
						id: [item.id],
						index: option.rowIndex,
						rowData: item,
					}));
				},
			},
			{
				label: "Set Reminder",
				icon: "pi pi-clock",
				command: () => {
					setReminderDetailDialog((prev) => ({
						...prev,
						state: true,
						id: [item.id],
					}));
				},
			},
		];

		// if (item.isCalled) {
		// 	items.push({
		// 		label: "Update Status",
		// 		icon: "pi pi-history",
		// 		command: () => {
		// 			setStatusDetailDialog((prev) => ({
		// 				...prev,
		// 				state: true,
		// 				id: [item.id],
		// 			}));
		// 		},
		// 	});
		// }
		if (!item.isHidden) {
			items.push({
				label: "Archive",
				icon: "pi pi-arrow-down",
				command: () => {
					setVisible((e) => ({
						...e,
						confirm: true,
						id: [item.id],
					}));
				},
			});
		}

		const newItem = [
			{
				label: "Allocate",
				icon: "pi pi-sitemap",
				command: () => {
					setProfileDetailDialog((prev) => ({
						...prev,
						state: true,
						data: [item.id],
					}));
				},
			},

			// {
			// 	label: "Add Note",
			// 	icon: "pi pi-file-edit",
			// 	command: () => {
			// 		setNoteDetailDialog((prev) => ({
			// 			...prev,
			// 			state: true,
			// 			id: [item.id],
			// 		}));
			// 	},
			// },
			// {
			// 	label: "Update Status",
			// 	icon: "pi pi-history",
			// 	command: () => {
			// 		setStatusDetailDialog((prev) => ({
			// 			...prev,
			// 			state: true,
			// 			id: [item.id],
			// 			index: option.rowIndex,
			// 		}));
			// 	},
			// },
			// {
			// 	label: "Update Status",
			// 	icon: "pi pi-history",
			// 	command: () => {
			// 		setStatusDetailDialog((prev) => ({
			// 			...prev,
			// 			state: true,
			// 			id: [item.id],
			// 		}));
			// 	},
			// },
			{
				label: "Set Reminder",
				icon: "pi pi-clock",
				command: () => {
					setReminderDetailDialog((prev) => ({
						...prev,
						state: true,
						id: [item.id],
					}));
				},
			},
		];
		// if (item.isCalled) {
		// 	newItem.push({
		// 		label: "Update Status",
		// 		icon: "pi pi-history",
		// 		command: () => {
		// 			setStatusDetailDialog((prev) => ({
		// 				...prev,
		// 				state: true,
		// 				id: [item.id],
		// 			}));
		// 		},
		// 	});
		// }
		const shareItem = [
			{
				label: "Whatsapp",
				icon: "pi pi-whatsapp",
				command: () => {
					setVisibleMessage((e) => ({
						...e,
						confirm: true,
						id: [item.id],
						type: "WHATSAPP",
					}));
				},
			},
			{
				label: "Email",
				icon: "pi pi-envelope",
				command: () => {
					setVisibleMessage((e) => ({
						...e,
						confirm: true,
						id: [item.id],
						type: "EMAIL",
					}));
				},
			},
		];

		return (
			// <div className="flex gap-2">
			// 	{(selector.position === 1020 || selector.position === 1000) && (
			// 	<Button
			// 		disabled={selectedProducts.length > 0 ? true : false}
			// 		icon={"pi pi-sitemap"}
			// 		tooltip="Allocate"
			// 		tooltipOptions={{ position: "left" }}
			// 		className="icon-btn primary-btn mr-2"
			// 		onClick={() => {
			// 			setProfileDetailDialog((prev) => ({
			// 				...prev,
			// 				state: true,
			// 				data: [item.id],
			// 			}));
			// 		}}
			// 	/>
			// )}
			// <Button
			// 	disabled={selectedProducts.length > 0 ? true : false}
			// 	icon={"pi pi-arrow-down"}
			// 	className="icon-btn primary-btn mr-2"
			// 	tooltip="Archive"
			// 	tooltipOptions={{ position: "left" }}
			// 	onClick={() => {
			// 		setVisible((e) => ({
			// 			...e,
			// 			confirm: true,
			// 			id: [item.id],
			// 		}));
			// 	}}
			// />
			// </div>

			selectedProducts.length === 0 ? (
				<div className="flex align-items-center justify-content-end gap-2">
					<Button
						disabled={selectedProducts.length > 0 ? true : false}
						icon={"pi pi-phone"}
						tooltip="Call"
						tooltipOptions={{ position: "left" }}
						className="icon-btn p-button-text"
						onClick={() => {
							setCallDialog((prev) => ({
								...prev,
								state: true,
								id: item.id,
							}));
						}}
					/>
					<Button
						disabled={selectedProducts.length > 0 ? true : false}
						icon={"pi pi-clone"}
						tooltip="Call Log"
						tooltipOptions={{ position: "left" }}
						className="icon-btn p-button-text"
						onClick={() => {
							setCallLogDialog(true);
							handleCallLog(item.id);
						}}
					/>

					<MenuComponent icon="pi pi-share-alt" items={shareItem} />
					<MenuComponent
						items={
							selector.position === 1020 ||
							selector.position === 1000
								? items
								: newItem
						}
					/>
				</div>
			) : (
				<></>
			)
		);
	};

	const handleSearch = (val, action) => {
		setLoader(true);
		const body = {
			agentLeadId: profileDetailDialog?.data,
			userName: val.userName,
		};
		ccService
			.allocateLead(body)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});
				setSelectedProducts([]);
				getResponse();
				action.resetForm();
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoader(false));
	};

	const accept = () => {
		setLoader(true);
		ccService
			.archiveAgent({ ids: visible.id })
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res?.message,
					style: { color: "#000000" },
					life: 3000,
				});
				setSelectedProducts([]);
				getResponse();
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoader(false));
	};
	const acceptMessage = () => {
		setLoader(true);
		ccService
			.sendMessage({ ids: visibleMessage.id, type: visibleMessage.type })
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res?.message,
					style: { color: "#000000" },
					life: 3000,
				});
				setSelectedProducts([]);
				getResponse();
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoader(false);
				setVisibleMessage((elm) => ({ ...elm, confirm: false }));
			});
	};
	const acceptCall = () => {
		setLoader(true);
		ccService
			.sendCall(callDialog.id)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res?.message,
					style: { color: "#000000" },
					life: 3000,
				});

				// onStatusEdit({
				// 	newData: "Called",
				// 	index: callDialog.index,
				// 	data: callDialog.rowData,
				// });
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoader(false);
				setCallDialog((elm) => ({ ...elm, state: false }));
			});
	};

	const reject = () => {
		setVisible((e) => ({
			...e,
			confirm: false,
			id: null,
		}));
	};
	const rejectMessage = () => {
		setVisibleMessage((e) => ({
			...e,
			confirm: false,
			id: null,
		}));
	};
	const rejectCall = () => {
		setCallDialog((e) => ({
			...e,
			state: false,
			id: null,
		}));
	};
	const handleStatusUpdate = (val, action) => {
		setLoader(true);
		ccService
			.agentLeadStatusUpdate({
				agentLeadId: remarkDetailDialog.id,
				status: remarkDetailDialog.status,
				remarks: val.remarks,
			})
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res?.message,
					style: { color: "#000000" },
					life: 3000,
				});
				onStatusEdit({
					newData: res?.status,
					index: remarkDetailDialog.index,
					data: remarkDetailDialog.rowData,
				});
				action.resetForm();
				setSelectedProducts([]);
				setRemarkDetailDialog((prev) => ({
					...prev,
					state: false,
					id: null,
					status: null,
				}));
				// setStatusDetailDialog((prev) => ({
				// 	...prev,
				// 	state: false,
				// 	id: null,
				// 	rowData: null,
				// }));

				// onStatusEdit();
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoader(false));
	};

	const handleReminder = (val, action) => {
		setLoader(true);
		ccService
			.agentLeadReminder({
				agentLeadId: reminderDialog.id,
				remainderNotes: val.remainderNotes,
				remainderNotification: val.remainderNotification,
				remainderTime: val.remainderTime ? val.remainderTime : null,
				date: val.date ? moment(val.date).format("YYYY-MM-DD") : null,
			})
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});
				action.resetForm();
				setSelectedProducts([]);
				getResponse();
				setReminderDetailDialog((prev) => ({
					...prev,
					id: null,
					state: false,
				}));
			})
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoader(false));
	};

	const stateTemplate = (item, option) => {
		const items = Config.AGENTLEADSTATUS.map((elm) => ({
			label: elm.label,
			command: () => {
				setRemarkDetailDialog((prev) => ({
					...prev,
					state: true,
					id: [item.id],
					status: elm.value,
					index: option.rowIndex,
					rowData: item,
				}));
				// handleStatusUpdate({
				// 	id: [item.id],
				// 	status: elm.value,
				// 	remarks: null,
				// 	index: option.rowIndex,
				// 	rowData: item,
				// });
			},
		}));
		// [
		// 	{
		// 		label: "New",
		// 		command: () => {
		// 			handleStatusUpdate({
		// 				id: [item.id],
		// 				status: "New",
		// 				// remarks: val.remarks,
		// 				remarks: null,
		// 				index: option.rowIndex,
		// 				rowData: item,
		// 			});
		// 		},
		// 	},
		// 	{
		// 		label: "Interested",
		// 		command: () => {
		// 			handleStatusUpdate({
		// 				id: [item.id],
		// 				status: "Interested",
		// 				// remarks: val.remarks,
		// 				remarks: null,
		// 				index: option.rowIndex,
		// 				rowData: item,
		// 			});
		// 		},
		// 	},
		// 	{
		// 		label: "Not Interested",
		// 		command: () => {
		// 			handleStatusUpdate({
		// 				id: [item.id],
		// 				status: "Not_interested",
		// 				// remarks: val.remarks,
		// 				remarks: null,
		// 				index: option.rowIndex,
		// 				rowData: item,
		// 			});
		// 		},
		// 	},
		// 	{
		// 		label: "Call Back",
		// 		command: () => {
		// 			handleStatusUpdate({
		// 				id: [item.id],
		// 				status: "Call_back",
		// 				// remarks: val.remarks,
		// 				remarks: null,
		// 				index: option.rowIndex,
		// 				rowData: item,
		// 			});
		// 		},
		// 	},
		// 	{
		// 		label: "No Answer",
		// 		command: () => {
		// 			handleStatusUpdate({
		// 				id: [item.id],
		// 				status: "No_Answer",
		// 				// remarks: val.remarks,
		// 				remarks: null,
		// 				index: option.rowIndex,
		// 				rowData: item,
		// 			});
		// 		},
		// 	},
		// 	{
		// 		label: "Will Register",
		// 		command: () => {
		// 			handleStatusUpdate({
		// 				id: [item.id],
		// 				status: "Will_register",
		// 				// remarks: val.remarks,
		// 				remarks: null,
		// 				index: option.rowIndex,
		// 				rowData: item,
		// 			});
		// 		},
		// 	},
		// ];
		// const Elm = () => {
		if (item.isHidden) {
			return (
				<span className="bg-red-800 text-red-50 border-round-sm flex gap-1 justify-content-between align-items-center py-1 px-2">
					Archive{" "}
					<MenuComponent
						textcolor={"text-red-50"}
						icon={"pi pi-chevron-down"}
						items={items}
					/>
				</span>
			);
		} else {
			switch (item.currentStatus) {
				case Config.AGENTLEADSTATUSEARCHS[0].value:
					return (
						<span className="bg-blue-800 flex gap-1 justify-content-between align-items-center text-blue-50 border-round-sm py-1 px-2">
							{Config.AGENTLEADSTATUSEARCHS[0].label}
							<MenuComponent
								textcolor={"text-blue-50"}
								icon={"pi pi-chevron-down"}
								items={items}
							/>
						</span>
					);
				case Config.AGENTLEADSTATUSEARCHS[1].value:
					return (
						<span className="bg-green-100 text-green-700 border-round-sm flex gap-1 justify-content-between align-items-center py-1 px-2">
							{Config.AGENTLEADSTATUSEARCHS[1].label}
							<MenuComponent
								textcolor={"text-green-700"}
								icon={"pi pi-chevron-down"}
								items={items}
							/>
						</span>
					);
				case Config.AGENTLEADSTATUSEARCHS[2].value:
					return (
						<span className="bg-blue-300 white-space-nowrap text-blue-50 border-round-sm flex gap-1 justify-content-between align-items-center py-1 px-2">
							{Config.AGENTLEADSTATUSEARCHS[2].label}
							<MenuComponent
								textcolor={"text-blue-50"}
								icon={"pi pi-chevron-down"}
								items={items}
							/>
						</span>
					);
				case Config.AGENTLEADSTATUSEARCHS[3].value:
					return (
						<span className="bg-yellow-100 text-yellow-800 border-round-sm flex gap-1 justify-content-between align-items-center py-1 px-2">
							{Config.AGENTLEADSTATUSEARCHS[3].label}
							<MenuComponent
								textcolor={"text-yellow-800"}
								icon={"pi pi-chevron-down"}
								items={items}
							/>
						</span>
					);
				case Config.AGENTLEADSTATUSEARCHS[4].value:
					return (
						<span className="bg-orange-100 text-orange-700 border-round-sm flex gap-1 justify-content-between align-items-center py-1 px-2 white-space-nowrap">
							{Config.AGENTLEADSTATUSEARCHS[4].label}
							<MenuComponent
								textcolor={"text-orange-700"}
								icon={"pi pi-chevron-down"}
								items={items}
							/>
						</span>
					);
				case Config.AGENTLEADSTATUSEARCHS[5].value:
					return (
						<span className="bg-teal-400 white-space-nowrap text-teal-50 border-round-sm flex gap-1 justify-content-between align-items-center py-1 px-2">
							{Config.AGENTLEADSTATUSEARCHS[5].label}

							<MenuComponent
								textcolor={"text-teal-50"}
								icon={"pi pi-chevron-down"}
								items={items}
							/>
						</span>
					);
				case Config.AGENTLEADSTATUSEARCHS[6].value:
					return (
						<span className="bg-green-800 text-green-50 border-round-sm flex gap-1 justify-content-between align-items-center py-1 px-2">
							{Config.AGENTLEADSTATUSEARCHS[6].label}
							<MenuComponent
								textcolor={"text-green-50"}
								icon={"pi pi-chevron-down"}
								items={items}
							/>
						</span>
					);
				case Config.AGENTLEADSTATUSEARCHS[7].value:
					return (
						<span className="bg-blue-100 text-blue-900 border-round-sm flex gap-1 justify-content-between align-items-center py-1 px-2">
							{Config.AGENTLEADSTATUSEARCHS[7].label}
							<MenuComponent
								textcolor={"text-blue-900"}
								icon={"pi pi-chevron-down"}
								items={items}
							/>
						</span>
					);
				case Config.AGENTLEADSTATUSEARCHS[8].value:
					return (
						<span className="bg-purple-100 text-purple-800 border-round-sm flex gap-1 justify-content-between align-items-center py-1 px-2">
							{Config.AGENTLEADSTATUSEARCHS[8].label}
							<MenuComponent
								textcolor={"text-purple-800"}
								icon={"pi pi-chevron-down"}
								items={items}
							/>
						</span>
					);
				case Config.AGENTLEADSTATUSEARCHS[9].value:
					return (
						<span className="bg-red-300 white-space-nowrap text-red-50 border-round-sm flex gap-1 justify-content-between align-items-center py-1 px-2">
							{Config.AGENTLEADSTATUSEARCHS[9].label}
							<MenuComponent
								textcolor={"text-red-50"}
								icon={"pi pi-chevron-down"}
								items={items}
							/>
						</span>
					);
				case Config.AGENTLEADSTATUSEARCHS[10].value:
					return (
						<span className="bg-red-300 white-space-nowrap text-red-50 border-round-sm flex gap-1 justify-content-between align-items-center py-1 px-2">
							{Config.AGENTLEADSTATUSEARCHS[10].label}
							<MenuComponent
								textcolor={"text-red-50"}
								icon={"pi pi-chevron-down"}
								items={items}
							/>
						</span>
					);
				// case "EmailSend":
				// 	return (
				// 		<span className="bg-blue-100 text-blue-700 border-round-sm flex gap-1 justify-content-between align-items-center py-1 px-2">
				// 			Email Send
				// 			<MenuComponent
				// 				textcolor={"text-blue-700"}
				// 				icon={"pi pi-chevron-down"}
				// 				items={items}
				// 			/>
				// 		</span>
				// 	);
				// case "ProfileCreated":
				// 	return (
				// 		<span className="bg-indigo-100 white-space-nowrap text-indigo-700 border-round-sm flex gap-1 justify-content-between align-items-center py-1 px-2">
				// 			Profile Created
				// 			<MenuComponent
				// 				textcolor={"text-indigo-700"}
				// 				icon={"pi pi-chevron-down"}
				// 				items={items}
				// 			/>
				// 		</span>
				// 	);
				default:
					<></>;
			}
		}
		// };
		// return (
		// 	<div className="flex justify-content-between">
		// 		<Elm />
		// 		<MenuComponent icon={"pi pi-chevron-down"} items={items} />
		// 	</div>
		// );
	};
	const handleStatusDialogeClose = () => {
		setBulkDialogue(false);
	};
	const onStatusEdit = (e) => {
		let _products = [...response?.data?.data];
		let { newData, index, data } = e;
		const dataObject = { ...data, currentStatus: newData };
		_products[index] = dataObject;
		const anotherData = { ...response?.data, data: _products };
		setResponse({
			type: Config.FETCH_CONFIG.success,
			payload: anotherData,
		});
		console.log(e);
	};

	return (
		<>
			{loader && <Loader />}
			<Toast ref={toast} />
			<ConfirmDialog
				visible={visible.confirm}
				onHide={() =>
					setVisible((e) => ({
						...e,
						confirm: false,
						id: null,
					}))
				}
				message="Are you sure you want to proceed?"
				header="Confirmation"
				icon="pi pi-exclamation-triangle"
				accept={accept}
				reject={reject}
			/>
			<ConfirmDialog
				visible={visibleMessage.confirm}
				onHide={() =>
					setVisibleMessage((e) => ({
						...e,
						confirm: false,
						id: null,
						type: "",
					}))
				}
				message="Are you sure you want to proceed?"
				header="Confirmation"
				icon="pi pi-exclamation-triangle"
				accept={acceptMessage}
				reject={rejectMessage}
			/>
			<ConfirmDialog
				visible={callDialog.state}
				onHide={() =>
					setCallDialog((e) => ({
						...e,
						state: false,
						id: null,
					}))
				}
				message="Are you sure you want to proceed?"
				header="Confirmation"
				icon="pi pi-exclamation-triangle"
				accept={acceptCall}
				reject={rejectCall}
			/>
			<SearchAgentLead
				searchDispatch={searchDispatch}
				selectedProducts={selectedProducts}
				setProfileDetailDialog={setProfileDetailDialog}
				setVisible={setVisible}
				setVisibleMessage={setVisibleMessage}
				setStatusDetailDialog={setStatusDetailDialog}
				setNoteDetailDialog={setNoteDetailDialog}
				setReminderDetailDialog={setReminderDetailDialog}
				setBulkDialogue={setBulkDialogue}
			/>
			<>
				{response.isLoading ? (
					<TableLoader
						itaration={itaration}
						headerGroup={headerGroup}
					/>
				) : (
					response.isFetched && (
						<>
							<Paginator
								totalElements={response.data.totalElements}
								pageSize={response.data.pageSize}
								firstPage={response.data.firstPage}
								lastPage={response.data.lastPage}
								decrement={decrement}
								increment={increment}
								pagesizechange={pagesizechange}
								pageNo={response.data.pageNo}
								totalPages={response.data.totalPages}
							/>
							<DataTable
								// editMode="cell"
								// onRowEditComplete={onStatusEdit}
								headerColumnGroup={headerGroup}
								value={response?.data?.data}
								responsiveLayout="scroll"
								dataKey="id"
								breakpoint="960px"
								selection={selectedProducts}
								selectionMode="checkbox"
								onSelectionChange={(e) =>
									setSelectedProducts(e.value)
								}
							>
								<Column
									header={"Select"}
									selectionMode={"multiple"}
									headerStyle={{ width: "3rem" }}
								/>
								<Column body={nameTemplate} />
								<Column field="mobileNumber" />
								<Column field={"stateName"} />
								<Column field={"cityName"} />
								<Column field={assignedToTemplate} />

								<Column field="source" />
								<Column
									body={(item) =>
										item?.createdOn
											? moment(item?.createdOn).format(
													"Do MMM, YYYY HH:mm A"
											  )
											: ""
									}
								/>
								<Column
									body={(item) =>
										item?.updatedOn
											? moment(item?.updatedOn).format(
													"Do MMM, YYYY HH:mm A"
											  )
											: ""
									}
								/>
								<Column
									body={(elm) =>
										elm?.lastCalled
											? moment(elm.lastCalled).format(
													"Do MMM,YYYY HH:mm A"
											  )
											: ""
									}
								/>
								<Column body={stateTemplate} />
								<Column
									body={(item) => (
										<Button
											onClick={() =>
												setNoteDetailDialog((prev) => ({
													...prev,
													state: true,
													id: [item.id],
												}))
											}
											className={`icon-btn p-button-text ${
												item?.notes
													? "p-button-success"
													: "p-button-danger"
											}`}
											icon={"pi pi-info-circle"}
											tooltip={
												item?.notes
													? item?.notes
													: "No Note"
											}
											tooltipOptions={{
												position: "left",
											}}
										/>
									)}
								/>
								<Column body={actionTemplate} />
								{/* <Column body={actionTemplate} />
								<Column body={actionTemplate} /> */}
							</DataTable>
							{response?.data?.data?.length > 0 && (
								<Paginator
									totalElements={response.data.totalElements}
									pageSize={response.data.pageSize}
									firstPage={response.data.firstPage}
									lastPage={response.data.lastPage}
									decrement={decrement}
									increment={increment}
									pagesizechange={pagesizechange}
									pageNo={response.data.pageNo}
									totalPages={response.data.totalPages}
								/>
							)}
						</>
					)
				)}
			</>
			<Dialog
				visible={bulkDialoge}
				onHide={() => setBulkDialogue(false)}
				style={{ width: "70%" }}
			>
				<BulkUpload
					handleStatusDialogeClose={handleStatusDialogeClose}
				/>
			</Dialog>
			<Dialog
				header="Agent Profile"
				visible={profileDetailDialog.state}
				onHide={() => {
					setProfileDetailDialog((prev) => ({
						...prev,
						state: false,
					}));
				}}
				breakpoints={{ "960px": "97vw" }}
				style={{ width: "65vw" }}
			>
				<AllocateAgentForm handleSearch={handleSearch} />
			</Dialog>

			<Dialog
				header={"Note"}
				style={{ width: "40%" }}
				visible={noteDetailDialog.state}
				onHide={() =>
					setNoteDetailDialog((prev) => ({
						...prev,
						state: false,

						id: null,
					}))
				}
			>
				<Formik
					initialValues={{ notes: noteDetailDialog.note }}
					onSubmit={handleNoteSubmit}
				>
					{({ handleSubmit, resetForm }) => (
						<Form
							className="modal-form-grid border-round-md col-12 mt-2 p-4 form-grid grid"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 ">
								<Field
									required
									header={"Note"}
									name={"notes"}
									component={FieldTextArea}
								/>
							</div>
							<div className="col-12 flex align-items-end justify-content-end">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Save" />
							</div>
						</Form>
					)}
				</Formik>
			</Dialog>

			<Dialog
				header={"Remark"}
				style={{ width: "40%" }}
				visible={remarkDetailDialog.state}
				onHide={() =>
					setRemarkDetailDialog((prev) => ({
						...prev,
						state: false,
						id: null,
						note: null,
						status: null,
					}))
				}
			>
				<Formik
					initialValues={{ remarks: remarkDetailDialog.note }}
					onSubmit={handleStatusUpdate}
				>
					{({ handleSubmit, resetForm }) => (
						<Form
							className="modal-form-grid border-round-md col-12 mt-2 p-4 form-grid grid"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 ">
								<Field
									required
									header={"Remark"}
									name={"remarks"}
									component={FieldTextArea}
								/>
							</div>
							<div className="col-12 flex align-items-end justify-content-end">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Save" />
							</div>
						</Form>
					)}
				</Formik>
			</Dialog>
			<Dialog
				header={"Status"}
				style={{ width: "60%" }}
				visible={statusDialog.state}
				onHide={() =>
					setStatusDetailDialog((prev) => ({
						...prev,
						state: false,
						id: null,
					}))
				}
			>
				<LeadStatusUpdate
					id={statusDialog.id}
					handleStatusUpdate={handleStatusUpdate}
				/>
			</Dialog>
			<Dialog
				header={"Set Reminder"}
				style={{ width: "40%" }}
				visible={reminderDialog.state}
				onHide={() =>
					setReminderDetailDialog((prev) => ({
						...prev,
						state: false,
						id: null,
					}))
				}
			>
				<LeadReminder handleReminder={handleReminder} />
			</Dialog>
			<Dialog
				style={{ width: "50%" }}
				header={"Call Log"}
				visible={callLogDialog}
				onHide={() => {
					setCallLogDialog(false);
					setCalllog([]);
				}}
			>
				<div className="modal-form-grid">
					{calllog?.length > 0 ? (
						<DataTable value={calllog}>
							{/* <Column header="Agent Lead Id" field="agentLeadId" /> */}
							<Column
								header={"Contacted On"}
								body={(elm) =>
									elm?.createdOn
										? moment(elm?.createdOn).format(
												"Do MMM, YYYY HH:mm A"
										  )
										: ""
								}
								field="createdOn"
							/>

							<Column
								header={"Contacted By"}
								body={(elm) =>
									(elm.createdByFirstName
										? elm.createdByFirstName
										: "") +
									" " +
									(elm.createdByLastName
										? elm.createdByLastName
										: "") +
									"(" +
									elm.createdBy +
									")"
								}
								// field="createdBy"
							/>
							{/* <Column field="createdByFirstName" />
					<Column field="createdByLastName" /> */}
						</DataTable>
					) : (
						<></>
					)}
				</div>
			</Dialog>
		</>
	);
};

export default CCAllocateAgentLead;
