import General from "../pages/members/users/components/General";
import Product from "../pages/members/users/components/Product";
import KyDetail from "../pages/members/users/components/KyDetail";
import BankDetail from "../pages/members/users/components/BankDetail";
import CompanyDetail from "../pages/members/users/components/CompanyDetail";
import ParentAgentDetail from "../pages/members/users/components/ParentAgentDetail";
import { TabView, TabPanel } from "primereact/tabview";

const AgentProfileComponent = (props) => {
	return (
		<TabView activeIndex={0}>
			{props.agent.hasOwnProperty("profile") ? (
				<TabPanel header="General">
					<General agent={props.agent} />
				</TabPanel>
			) : null}

			{props.agent.hasOwnProperty("products") ? (
				<TabPanel header="Products">
					<Product agent={props.agent} />
				</TabPanel>
			) : null}

			{props.agent.hasOwnProperty("profile") ? (
				<TabPanel header="KYC Details">
					<KyDetail
						handleCall={props.handleCall}
						agent={props.agent}
						getProfileEdit={props.getProfileEdit}
					/>
				</TabPanel>
			) : null}

			{props.agent.hasOwnProperty("bankDetails") ? (
				<TabPanel header="Bank Details">
					<BankDetail agent={props.agent} />
				</TabPanel>
			) : null}

			{props.agent.hasOwnProperty("profile") &&
			props.agent.profile.hasOwnProperty("company") ? (
				<TabPanel header="Company Details">
					<CompanyDetail agent={props.agent} />
				</TabPanel>
			) : null}

			{props.agent.hasOwnProperty("profile") &&
			props.agent.profile.hasOwnProperty("parentAgent") ? (
				<TabPanel header="Parent Agent Details">
					<ParentAgentDetail
						agent={props.agent.profile.parentAgent}
					/>
				</TabPanel>
			) : null}
		</TabView>
	);
};

export default AgentProfileComponent;
