import React from "react";
import { DataTable } from "primereact/datatable";

const TableNoData = (props) => {
	return (
		<DataTable
			headerColumnGroup={props.headerGroup}
			value={[]}
			responsiveLayout={"scroll"}
		></DataTable>
	);
};

export default TableNoData;
