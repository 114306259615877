import { Field, FieldArray, Form, Formik } from "formik";
import {
	FeildDropdown,
	FieldEditor,
	FieldInput,
} from "../../../../../../components/FieldInput";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { InputNode } from "../../../../../../components/InputComponent";
import { ProgramService } from "../../../../../../services/ProgramService";
import Config from "../../../../../../shared/config";
import { S3 } from "aws-sdk";
import Loader from "../../../../../../components/Loader";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { convertObjNull } from "../../../../../../utils/UtilsFunction";
import {
	setEnable,
	setId,
} from "../../../../../../store/reducer/ProgramReducer";
import * as Yup from "yup";

const RetirementPlan = (props) => {
	const [spinner, setSpinner] = useState(false);
	const toastTL = useRef(null);
	const [programData, setProgramData] = useState(null);
	const pType = props?.locationData;
	const dispatch = useDispatch();
	const initialValue = {
		planInfo: "",
		features: "",
		specialBenefits: "",
		maturityBenefit: "",
		eligibilityDetails: "",
		name:
			pType?.programData?.institutionId?.name +
			" " +
			pType?.programData?.productId?.subProduct,
		imageFirst: "",
		imageSecond: "",
		type: "INSURANCE",
		paymentType: "",
		ppt: "",
		minPremiumAmount: "",
		maxPremiumAmount: "",
		minPensionStartYear: "",
		maxPensionStartYear: "",
		fundType: "",
		funds: [
			{
				fundName: "",
				fiveYearRate: "",
				tenYearRate: "",
				rsiRate: "",
			},
		],
	};
	const validationSchema = Yup.object().shape({
		name: Yup.string().required("This field is required"),
		imageFirst: Yup.string().required("This field is required"),
		imageSecond: Yup.string().required("This field is required"),
		// type: "LIFE",
		// productId: 456,
		minPremiumAmount: Yup.number()
			.typeError("Amount must be a number")
			.min(0, "Invalid Value")
			.required("This Field is Required"),
		maxPremiumAmount: Yup.number()
			.typeError("Amount must be a number")
			.min(0, "Invalid Value")
			.when("minPremiumAmount", (minPremiumAmount, value) => {
				return value.test({
					test: (maxPremiumAmount) =>
						!!minPremiumAmount &&
						maxPremiumAmount > minPremiumAmount,
					message: "Max Premium Amount > Min Premium Amount",
				});
			})
			.required("This Field is Required"),
		minPensionStartYear: Yup.number()
			.typeError("Amount must be a number")
			.min(0, "Invalid Value")
			.required("This Field is Required"),
		maxPensionStartYear: Yup.number()
			.typeError("Amount must be a number")
			.min(0, "Invalid Value")
			.when("minPensionStartYear", (minPensionStartYear, value) => {
				return value.test({
					test: (maxPensionStartYear) =>
						!!minPensionStartYear &&
						maxPensionStartYear > minPensionStartYear,
					message: "Max Cover Amount > Min Cover Amount",
				});
			})
			.required("This Field is Required"),

		paymentType: Yup.string().required("This field is required"),
		ppt: Yup.number().required("This field is required"),

		planInfo: Yup.string().required("This field is required"),
		features: Yup.string().required("This field is required"),
		specialBenefits: Yup.string().required("This field is required"),

		maturityBenefit: Yup.string().required("This field is required"),
		eligibilityDetails: Yup.string().required("This field is required"),
		fundType: Yup.string().required("This field is required"),
		funds: Yup.array().when("fundType", {
			is: (fundType) => fundType === "MARKET_LINKED",
			then: Yup.array().of(
				Yup.object().shape({
					fundName: Yup.string().required("This field is required"),
					fiveYearRate: Yup.string().required(
						"This field is required"
					),
					tenYearRate: Yup.string().required(
						"This field is required"
					),
					rsiRate: Yup.string().required("This field is required"),
				})
			),
		}),
	});
	const programService = new ProgramService();
	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});
	const getProgram = (id) => {
		if (pType.type === "edit" || pType?.type === "copy") {
			setSpinner(true);
			programService
				.getFullProgramData(id)
				.then((res) => {
					setProgramData(res);
				})
				.catch((e) => {})
				.finally(() => setSpinner(false));
		}
	};
	useEffect(() => {
		pType?.programId && getProgram(pType.programId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const onUploadfirst = (e, setFieldValue) => {
		//setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.PROGRAM_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Something went wrong",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successful",
					detail: "Contract document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});

				setFieldValue("imageFirst", newFileName);
			}
		});
	};
	const onUploadSecond = (e, setFieldValue) => {
		//setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.PROGRAM_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Something went wrong",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successful",
					detail: "2nd document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});

				setFieldValue("imageSecond", newFileName);
			}
		});
	};
	const programCreate = (payload) => {
		setSpinner(true);
		programService
			.createRetirementProgram(payload)
			.then((res) => {
				dispatch(setId(res?.id));
				dispatch(setEnable(1));
			})
			.catch((error) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setSpinner(false);
			});
	};
	const handleFormSubmit = (value, onSubmitProps) => {
		const urlData = pType?.programData;

		const body = convertObjNull({
			...value,
			productId: urlData?.productId?.subProductId,
			institutionId: urlData?.institutionId.id,
		});
		if (pType?.programId && pType.type === "edit") {
			setSpinner(true);
			programService
				.updateProgram(body)
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Successfull",
						detail: "Updated successfully",
						style: { color: "#000000" },
						life: 3000,
					});
					pType?.programId && getProgram(pType.programId);
				})
				.catch((error) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: "Somthing went worng",
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => {
					setSpinner(false);
				});
		} else if (pType?.programId && pType.type === "copy") {
			const { id, ...rest } = body;
			setSpinner(true);
			programCreate(rest);
			// programService
			// 	.createProgram(rest)
			// 	.then((res) => {
			// 		toastTL.current.show({
			// 			severity: "success",
			// 			summary: "Successfull",
			// 			detail: "Program created successfully",
			// 			style: { color: "#000000" },
			// 			life: 3000,
			// 		});
			// 		dispatch(setId(res.id));
			// 		dispatch(setEnable(1));
			// 	})
			// 	.catch((er) => {
			// 		toastTL.current.show({
			// 			severity: "error",
			// 			summary: "Error",
			// 			detail: "Somthing went worng",
			// 			style: { color: "#000000" },
			// 			life: 3000,
			// 		});
			// 	})
			// 	.finally(() => {
			// 		setSpinner(false);
			// 	});
		} else {
			programCreate(body);
		}
	};
	return (
		<>
			{spinner && <Loader />}
			<Toast ref={toastTL} position="top-left" />
			<Formik
				initialValues={{
					...initialValue,
					...(programData && programData),
				}}
				onSubmit={handleFormSubmit}
				enableReinitialize
				validationSchema={validationSchema}
			>
				{({
					handleSubmit,
					values,
					resetForm,
					setFieldValue,
					errors,
					touched,
				}) => (
					<Form onSubmit={handleSubmit}>
						<div className="grid form-grid">
							<div className="field col-12 md:col-3">
								<Field
									component={FieldInput}
									name={"name"}
									header={"Program Name"}
									star="*"
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									component={FieldInput}
									name={"minPremiumAmount"}
									type={"number"}
									header={"Min Premium Amount"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									component={FieldInput}
									name={"maxPremiumAmount"}
									type={"number"}
									header={"Max Premium Amount"}
									star="*"
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									component={FieldInput}
									name={"minPensionStartYear"}
									type={"number"}
									header={"Min Pension Start Year Age"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									component={FieldInput}
									name={"maxPensionStartYear"}
									type={"number"}
									header={"Max Pension Start Year Age"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									component={FeildDropdown}
									name={"paymentType"}
									options={[
										{ label: "Monthly", value: "MONTHLY" },
										{
											label: "Quartly",
											value: "QUARTERLY",
										},
										{
											label: "Half-yearly",
											value: "HALF_YEARLY",
										},
										{ label: "Yearly", value: "YEARLY" },
									]}
									header={"Payment Type"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									component={FeildDropdown}
									name={"ppt"}
									options={[
										{ label: "5", value: 5 },
										{ label: "7", value: 7 },
										{ label: "10", value: 10 },
										{ label: "12", value: 12 },
										{ label: "15", value: 15 },
										{ label: "20", value: 20 },
									]}
									header={"PPT"}
									star="*"
								/>
							</div>

							<div className="field col-12 md:col-6">
								<h3>Image 1</h3>
								{values.imageFirst && (
									<a
										href={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageFirst}`}
										target="_blank"
										className="flex align-items-center justify-content-start"
										rel="noreferrer"
									>
										{/* <i
												className={`text-2xl pi ${
													getFileExtension(
														img.documentFileName
													) === "pdf"
														? "pi-file-pdf text-red-400"
														: "pi-image text-primary-700"
												}`}
											/> */}

										<span className="">
											<img
												style={{ height: "80px" }}
												src={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageFirst}`}
												alt=""
											/>
										</span>
									</a>
								)}
							</div>
							<div className="field col-12 md:col-6">
								<h3>Image 2</h3>
								{values.imageSecond && (
									<a
										href={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageSecond}`}
										target="_blank"
										className="flex align-items-center justify-content-start"
										rel="noreferrer"
									>
										{/* <i
												className={`text-2xl pi ${
													getFileExtension(
														img.documentFileName
													) === "pdf"
														? "pi-file-pdf text-red-400"
														: "pi-image text-primary-700"
												}`}
											/> */}

										<span className="">
											<img
												style={{ height: "80px" }}
												src={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageSecond}`}
												alt=""
											/>
										</span>
									</a>
								)}
							</div>
							<div className="field col-12 md:col-6">
								<InputNode header={"Upload Logo"}>
									<FileUpload
										name="imageFirst"
										customUpload
										accept=".jpg,.png,.jpeg"
										uploadHandler={(e) =>
											onUploadfirst(e, setFieldValue)
										}
										maxFileSize={1000000}
										emptyTemplate={
											<p className="m-0">
												Drag and drop files to here to
												upload.
											</p>
										}
									/>
									{errors.imageFirst &&
										touched.imageFirst && (
											<small className="p-error">
												{errors.imageFirst}
											</small>
										)}
								</InputNode>
							</div>

							<div className="field col-12 md:col-6">
								<InputNode header={"Upload Image 2"}>
									<FileUpload
										name="imageSecond"
										customUpload
										accept=".jpg,.png,.jpeg"
										uploadHandler={(e) =>
											onUploadSecond(e, setFieldValue)
										}
										maxFileSize={1000000}
										emptyTemplate={
											<p className="m-0">
												Drag and drop files to here to
												upload.
											</p>
										}
									/>
									{errors.imageSecond &&
										touched.imageSecond && (
											<small className="p-error">
												{errors.imageSecond}
											</small>
										)}
								</InputNode>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									component={FieldEditor}
									name={"planInfo"}
									header={"Plan Info"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									component={FieldEditor}
									name={"features"}
									header={"Features"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									component={FieldEditor}
									name={"specialBenefits"}
									header={"Special Benefits "}
									star="*"
								/>
							</div>

							<div className="field col-12 md:col-4">
								<Field
									component={FieldEditor}
									name={"maturityBenefit"}
									header={"Maturity Benefit"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									component={FieldEditor}
									name={"eligibilityDetails"}
									header={"Eligibility Details"}
									star="*"
								/>
							</div>
						</div>
						<div className="grid form-grid mt-3">
							<div className="field col-12 md:col-3">
								<Field
									component={FeildDropdown}
									name={"fundType"}
									header={"Program Fund Type"}
									options={[
										{
											label: "MARKET LINKED",
											value: "MARKET_LINKED",
										},
										{
											label: "GUARANTEED",
											value: "GUARANTEED",
										},
									]}
									star="*"
								/>
							</div>
							<div className="col-12">
								{values.fundType === "MARKET_LINKED" && (
									<FieldArray
										name="funds"
										render={(arrayHelper) => (
											<>
												{values.funds &&
												values.funds.length > 0
													? values.funds.map(
															(item, index) => (
																<div
																	className="form-grid grid"
																	key={
																		index.toString() +
																		"mapValueCommision"
																	}
																>
																	<div className="field col-12 md:col-3">
																		<Field
																			name={`funds.${index}.fundName`}
																			component={
																				FieldInput
																			}
																			header={
																				"Name"
																			}
																			star={
																				"*"
																			}
																		/>
																	</div>
																	<div className="field col">
																		<Field
																			name={`funds.${index}.fiveYearRate`}
																			component={
																				FieldInput
																			}
																			header={
																				"Five Year Rate"
																			}
																			type="number"
																			star={
																				"*"
																			}
																		/>
																	</div>
																	<div className="field col">
																		<Field
																			name={`funds.${index}.tenYearRate`}
																			component={
																				FieldInput
																			}
																			header={
																				"Ten Year Rate"
																			}
																			type="number"
																			star={
																				"*"
																			}
																		/>
																	</div>
																	<div className="field col">
																		<Field
																			name={`funds.${index}.rsiRate`}
																			component={
																				FieldInput
																			}
																			header={
																				"RSI Rate"
																			}
																			type="number"
																			star={
																				"*"
																			}
																		/>
																	</div>
																	<div className="col-1 pt-5">
																		{values
																			.funds
																			.length ===
																		index +
																			1 ? (
																			<Button
																				type="button"
																				icon="pi pi-plus"
																				className="p-button-success rounded outlined"
																				onClick={() =>
																					arrayHelper.push(
																						{
																							fundName:
																								"",
																							fiveYearRate:
																								"",
																							tenYearRate:
																								"",
																							rsiRate:
																								"",
																						}
																					)
																				}
																			/>
																		) : (
																			<Button
																				type="button"
																				onClick={() =>
																					arrayHelper.remove(
																						index
																					)
																				}
																				icon="pi pi-minus"
																				className="p-button-danger p-button-sm md:col-5 "
																				aria-label="Cancel"
																			/>
																		)}
																	</div>
																</div>
															)
													  )
													: null}
											</>
										)}
									/>
								)}
							</div>
							<div className="flex justify-content-end align-items-center col-12 gap-2 mt-4">
								<Button
									type="button"
									label="Cancel"
									onClick={() => resetForm()}
									icon={"pi pi-refresh"}
									className="danger-btn"
								/>
								<Button type="submit" label="Save & next" />
								{pType?.programId && pType?.type === "edit" && (
									<Button
										type="button"
										onClick={() => {
											dispatch(setEnable(1));
										}}
										icon={"pi pi-arrow-right"}
									/>
								)}
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default RetirementPlan;
