import { useEffect, useRef, useState } from "react";
import Config from "../../../shared/config";
import { Button } from "primereact/button";
import { Field, Form, Formik } from "formik";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
} from "../../../components/FieldInput";
import { SplitButton } from "primereact/splitbutton";
import { exportExcel } from "../../../components/XlsxExport";
import { ReportService } from "../../../services/ReportService";
import { Toast } from "primereact/toast";
import moment from "moment";
import { ZoneService } from "../../../services/ZoneService";

const SearchCustomer = (props) => {
	const reportService = new ReportService();
	const zoneService = new ZoneService();
	const toast = useRef(null);
	const [open, setOpen] = useState(false);
	const [loader, setLoader] = useState(false);
	const [zoneId, setZoneId] = useState([]);
	const [stateValue, setStateValue] = useState([]);
	const [districtValue, setDistrictValue] = useState([]);

	// const {
	// 	data: getAllDistrict = [
	// 		{ districtList: [{ id: null, name: "" }], id: null, name: "" },
	// 	],
	// } = useGetAllDIstrictQuery();

	let initialValue = {
		customerName: "",
		mobileNumber: "",
		email: "",
		userName: "",
		zone: "",
		preState: "",
		districtId: "",
		minLeadCount: "",
		maxLeadCount: "",
		minApplicationCount: "",
		maxApplicationCount: "",
		fromDate: "",
		toDate: "",
	};
	const getZone = () => {
		zoneService.getZone().then((res) => {
			const custmZone = res.map((item) => ({ ...item, value: item.id }));
			setZoneId(custmZone);
		});
	};

	const handleZone = (e, setFieldValue) => {
		getState(e);
		setFieldValue("zone", e, true);
		setFieldValue("district", "", true);
	};

	const getState = (e) => {
		zoneService.getStateListByZone(e).then((res) => {
			const custmState = res.map((item) => ({ ...item, value: item.id }));
			setStateValue(custmState);
		});
	};

	const handleState = (e, setFieldValue) => {
		getDistrict(e);
		setFieldValue("preState", e, true);
	};

	const getDistrict = (e) => {
		zoneService.getDistricByState(e).then((res) => {
			const custmDistrict = res.map((item) => ({
				...item,
				value: item.id,
			}));
			setDistrictValue(custmDistrict);
		});
	};
	useEffect(() => {
		getZone();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFormSubmit = (value) => {
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});

		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: value,
		});
	};

	const exportData = (data) => {
		const newData = data.map((item) => ({
			"Customer Name":
				(item.firstName ? item.firstName : "") +
				" " +
				(item.middleName ? item.middleName : "") +
				" " +
				(item.lastName ? item.lastName : ""),
			Username: item.userName,
			Gender: item.gender,
			Mobile: item.mobileNumber,
			Email: item.email,
			DOB: item.dob,
			Address1: item.preAddressLine1,
			Address2: item.preAddressLine2,
			State: item.stateName,
			District: item.districtName,
			LeadCount: item.leadCount,
			ApplicationCount: item.applicationCount,
			"Created By": item.createdBy,
			"Created On":
				item.createdOn &&
				moment(item.createdOn).format("Do MMM, YYYY HH:mm A"),
			isRegister: item.isRegistered,
		}));
		return newData;
	};

	let items = [
		{
			label: "Export",
			icon: "pi pi-fw pi-file-excel",
			command: () => {
				if (props.data) {
					const data = exportData(props.data);
					exportExcel(data, "customer_list");
				}
			},
		},
		{
			label: "Export All",
			icon: "pi pi-fw pi-file-excel",
			command: () => {
				const body = {
					pageNo: 1,
					pageSize: 10000000,
				};
				setLoader(true);
				reportService
					.getCustomerList(body)
					.then((res) => {
						const data = exportData(res.data);
						exportExcel(data, "All Customer List");
					})
					.catch((e) => {
						toast.current.show({
							severity: "error",
							summary: "Error",
							detail: e.message,
							life: 3000,
						});
					})
					.finally(() => setLoader(false));
			},
		},
	];
	return (
		<>
			<div className="w-full  py-3 flex align-items-end justify-content-between">
				<Toast ref={toast} />
				<h3 className="m-0">Customer List </h3>
				<div className="flex gap-2">
					<Button
						icon={open ? "pi pi-times" : "pi pi-search"}
						className={
							open
								? "p-button-danger p-button-outlined"
								: "p-button-outlined"
						}
						label={open ? "Close" : "Search"}
						aria-label="Cancel"
						onClick={() => setOpen((prev) => !prev)}
					/>
					<Button
						label="Reload"
						icon="pi pi-refresh"
						className="primary-btn p-button-outlined"
						onClick={() =>
							props.searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							})
						}
					/>
					<SplitButton
						loading={loader}
						label="Export"
						icon="pi pi-file-excel"
						model={items}
					/>
				</div>
			</div>
			{open && (
				<Formik
					initialValues={initialValue}
					onSubmit={handleFormSubmit}
				>
					{({ values, handleSubmit, resetForm, setFieldValue }) => (
						<Form onSubmit={handleSubmit}>
							<div className="form-grid grid background-secondary p-4 border-round-md my-3">
								<div className="field col-12 md:col-3">
									<Field
										header={"Name"}
										name={"customerName"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Mobile"}
										name={"mobileNumber"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Email"}
										name={"email"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"UserName"}
										name={"userName"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Zone"}
										name={"zone"}
										component={FeildDropdown}
										options={zoneId}
										optionLabel={"name"}
										onChange={(e) => {
											handleZone(e.value, setFieldValue);
										}}
									/>
								</div>
								{values.zone && (
									<div className="field col-12 md:col-3">
										<Field
											header={"State"}
											name={"preState"}
											component={FeildDropdown}
											// options={getAllDistrict}
											options={stateValue}
											optionLabel={"name"}
											onChange={(e) => {
												handleState(
													e.value,
													setFieldValue
												);
											}}
										/>
									</div>
								)}

								{values.preState && (
									<div className="field col-12 md:col-3">
										<Field
											filter
											header={"District"}
											name={"district"}
											component={FeildDropdown}
											options={districtValue}
											optionLabel={"name"}
										/>
									</div>
								)}
								<div className="field col-12 md:col-3">
									<Field
										header={"Min Lead Count"}
										name={"minLeadCount"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Max Lead Count"}
										name={"maxLeadCount"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Min Application Count"}
										name={"minApplicationCount"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Max Application Count"}
										name={"maxApplicationCount"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"From Date"}
										name={"fromDate"}
										component={FeildCalender}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"To Date"}
										name={"toDate"}
										component={FeildCalender}
									/>
								</div>

								<div className="field flex align-items-center  justify-content-end col-12">
									<Button
										type="button"
										label="Reset"
										className="p-button-danger mr-2"
										onClick={() => resetForm()}
									/>
									<Button type="submit" label="Search" />
								</div>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default SearchCustomer;
