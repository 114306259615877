import axios from "axios";
import { agent_end_point_url, apiPath } from "../shared/constant";
import { useSelector } from "react-redux";
export class LoanApplicationService {
	selector = useSelector((state) => state.persistData.auth);
	constructor() {
		this.token = this.selector.token;
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}

	getLoanApplicationList = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/application/searchapplication`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getLoanApplicationEditList = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/application/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getInsuranceLoanApplicationEditList = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/application/insurance/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getMotorInsuranceLoanApplicationEditList = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/application/insurance/motor-insurance/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getLoanApplicationCreateFullList = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/application/application-details/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getMedicalInsuranceCreateFullListById = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/application/medical-insurance/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getInsuranceLoanApplicationCreateFullList = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/application/insurance-details/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getStateList = async () => {
		try {
			const res = await axios.get(
				`${agent_end_point_url}/states/all`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getDistrictByStateID = async (id) => {
		try {
			const res = await axios.get(
				`${agent_end_point_url}/district/${id}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	createReason = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/master/svc/product/reason`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	deleteReason = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/master/svc/product/reason/delete/${payload}`,
				{},
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	updateReason = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/master/svc/product/reason/update`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getReason = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/master/svc/product/reason/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	documentUpload = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/application-document/save`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	documentList = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/application-document/by-application/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getStatusByProgramId = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/institution/svc/get-status-by-program-id/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	saveStatus = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/application/status/save`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	saveInsuranceStatus = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/application/insurance/status/save`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	addInsurancePremium = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/application/insurance/premium/add`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	saveBankApplication = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/application/bank-application/save`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getApplicationStatus = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/application/status/application/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getApplicationPremiumList = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/application/insurance/premium/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getInsurancePlan = async () => {
		try {
			const res = await axios.get(
				`${apiPath}/application/all-plan`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getHospicasData = async (id) => {
		try {
			const res = await axios.get(
				`${apiPath}/application/hospi-cash-details/${id}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	insuranceDraftSave = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/application/hospicash/draft-save`,
				payload,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	downloadStatus = async () => {
		try {
			const res = await axios.get(
				`${apiPath}/application/generate-excel`,

				{
					headers: this.headers,
				}
				// headers: {
				// 		"Content-Type": "multipart/form-data",
				// 		Authorization: `Bearer ${this.token}`,
				// 	},
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	bulkCreation = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/application/bulkcreateapplication`,
				payload,
				{
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${this.token}`,
					},
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	bulkStatusUpdate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/application/bulkcreateapplication-save`,
				payload,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getDisease = async () => {
		try {
			const res = await axios.get(
				`${apiPath}/application/disease`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	applicationDocsDelete = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/application-document/delete/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.message;
		} catch (error) {
			throw error.response.data;
		}
	};
	medicalInsuranceDraftSave = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/application/medical-insuranse/draft-save`,
				payload,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getApplicationMonthlyWiseUnderTsrmReport = async (payload) => {
		try {
			const res = await fetch(
				`${apiPath}/report/application-monthly-wise-under-tsrm-report`,
				{
					headers: this.headers,
					method: "POST",
					body: payload,
				}
			);
			return res;
		} catch (error) {
			throw error.response.data;
		}
	};
	getApplicationDailyWiseReport = async (payload) => {
		try {
			const res = await fetch(
				`${apiPath}/report/application-daily-wise-report`,
				{
					headers: this.headers,
					method: "POST",
					body: payload,
				}
			);
			return res;
		} catch (error) {
			throw error.response.data;
		}
	};
	getFinanceDepertApplicationReport = async (payload) => {
		try {
			const res = await fetch(
				`${apiPath}/report/finance-depart-application-report`,
				{
					headers: this.headers,
					method: "POST",
					body: payload,
				}
			);
			return res;
		} catch (error) {
			throw error.response.data;
		}
	};
	getFinanceAgentWiseReportMonthlyReport = async (payload) => {
		try {
			const res = await fetch(
				`${apiPath}/report/finance-agent-wise-report-monthly`,
				{
					headers: this.headers,
					method: "POST",
					body: payload,
				}
			);
			return res;
		} catch (error) {
			throw error.response.data;
		}
	};
	getTsrmWiseDailyApplicationReportVendor = async (payload) => {
		try {
			const res = await fetch(
				`${apiPath}/report/tsrm-wise-daily-application-report-vendor`,
				{
					headers: this.headers,
					method: "POST",
					body: payload,
				}
			);
			return res;
		} catch (error) {
			throw error.response.data;
		}
	};
	getOEVendorAdminReport = async (payload) => {
		try {
			const res = await fetch(`${apiPath}/report/oe-admin-report`, {
				headers: this.headers,
				method: "POST",
				body: payload,
			});
			return res;
		} catch (error) {
			throw error.response.data;
		}
	};
	getOEVendorReportDailyWise = async (payload) => {
		try {
			const res = await fetch(`${apiPath}/report/oe-report-daily-wise`, {
				headers: this.headers,
				method: "POST",
				body: payload,
			});
			return res;
		} catch (error) {
			throw error.response.data;
		}
	};
	getVendorAgentleadDailyStatusReport = async (payload) => {
		try {
			const res = await fetch(
				`${agent_end_point_url}/report/agent-lead-daily-status-report-vendor`,
				{
					headers: this.headers,
					method: "POST",
					body: payload,
				}
			);
			return res;
		} catch (error) {
			throw error.response.data;
		}
	};
	getAgentleadPassToTSRMMonthlyReport = async (payload) => {
		try {
			const res = await fetch(
				`${agent_end_point_url}/report/agent-lead-pass-to-tsrm-monthly-report`,
				{
					headers: this.headers,
					method: "POST",
					body: payload,
				}
			);
			return res;
		} catch (error) {
			throw error.response.data;
		}
	};
	getTSRMDailyCallReport = async (payload) => {
		try {
			const res = await fetch(
				`${agent_end_point_url}/report/tsrm-daily-call-report`,
				{
					headers: this.headers,
					method: "POST",
					body: payload,
				}
			);
			return res;
		} catch (error) {
			throw error.response.data;
		}
	};
}
