import { createSlice } from "@reduxjs/toolkit";
const AuthSlice = createSlice({
	name: "authSlice",
	initialState: {
		auth: { token: null, supertoken: null, user: null, menus: [] },
		application: { name: null },
		applicationId: null,
		countryApplicationId: null,
		applicationState: "edit", //"preview" || "edit"
	},
	reducers: {
		setUser: (state, action) => {
			state.auth = action.payload;
		},
		setTypeName: (state, action) => {
			state.application.name = action.payload;
		},
		setApplicationId: (state, action) => {
			state.applicationId = action.payload;
		},
		setApplicationState: (state, action) => {
			state.applicationState = action.payload;
		},
		setCountryApplicationId: (state, action) => {
			state.countryApplicationId = action.payload;
		},
	},
});
export const {
	setUser,
	setTypeName,
	setMenus,
	setApplicationId,
	setApplicationState,
	setCountryApplicationId,
} = AuthSlice.actions;
export default AuthSlice.reducer;
