import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import S3 from "aws-sdk/clients/s3";
import moment from "moment/moment";
import { FiFileText } from "react-icons/fi";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";

import Config from "../../../../shared/config";

const s3Clint = new S3({
	accessKeyId: Config.S3_ACCESSKEYID,
	secretAccessKey: Config.S3_SECRETACCESSKEY,
	region: Config.S3_REGION,
	signatureVersion: "v4",
});

const ContractDetails = ({ data, handleContract }) => {
	console.log(data);
	const toastTL = useRef(null);
	const [contractFilterData, setContractFilterData] = useState({});
	const formik = useFormik({
		initialValues: {
			contractStartDate: "",
			contractEndDate: "",
			contractDocName: "",
			...(contractFilterData ? contractFilterData : {}),
		},
		enableReinitialize: true,
		validate: (data) => {
			let errors = {};

			if (!data.contractStartDate) {
				errors.contractStartDate = "Start date is required.";
			}
			if (!data.contractEndDate) {
				errors.contractEndDate = "End date is required";
			} else if (
				moment(data.contractEndDate).isBefore(data.contractStartDate)
			) {
				errors.contractEndDate =
					"End date can not be less then start date";
			}
			if (!data.contractDocName) {
				errors.contractDocName = "Contract document is required";
			}
			return errors;
		},
		onSubmit: (data, action) => {
			handleContract(data, action);
		},
	});

	const isFormFieldValid = (name) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};

	const onUpload = (e) => {
		// setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.INSTITUTION_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};
		if (contractFilterData) {
			s3Clint.deleteObject(
				{
					Bucket: Config.INSTITUTION_DOCS_BUCKET,
					Key: contractFilterData.contractDocName,
				},
				(err, data) => {
					console.log(err);
					console.log(data);
				}
			);
		}
		s3Clint.upload(params, (err, data) => {
			if (err) {
				// setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				// setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successful",
					detail: "Contract document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});
				formik.setFieldValue("contractDocName", newFileName);
			}
		});
	};

	const startDateTemplate = (item) => {
		return item.contractStartDate ? (
			moment(item.contractStartDate).format("Do MMM, YYYY")
		) : (
			<></>
		);
	};
	const endDateTemplate = (item) => {
		return item.contractEndDate ? (
			moment(item.contractEndDate).format("Do MMM, YYYY")
		) : (
			<></>
		);
	};
	const docTemplate = (item) => {
		const url = `${Config.AGENT_BUCKET_ACCESS_URL}${Config.INSTITUTION_DOCS_FOLDER}/${item.contractDocName}`;
		return (
			<a
				href={url}
				target="_blank"
				rel="noreferrer"
				style={{ fontSize: "20px" }}
			>
				<FiFileText />
			</a>
		);
	};

	const actionTemplate = (item) => {
		return (
			<Button
				className="icon-btn p-button-text"
				icon="pi pi-pencil"
				onClick={() => {
					const payload = {
						...item,
						contractStartDate: item.contractStartDate
							? new Date(item.contractStartDate)
							: "",
						contractEndDate: item.contractEndDate
							? new Date(item.contractEndDate)
							: "",
					};
					setContractFilterData(payload);
				}}
			/>
		);
	};

	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			<DataTable value={data} responsiveLayout="scroll">
				<Column body={startDateTemplate} header="Start Date"></Column>
				<Column body={endDateTemplate} header="End Date"></Column>
				<Column body={docTemplate} header="Document"></Column>
				<Column body={actionTemplate} header="Document"></Column>
			</DataTable>
			<div className="grid">
				<form
					onSubmit={formik.handleSubmit}
					className="p-fluid grid col-12"
				>
					<FocusError formik={formik} />
					<div className="field col-12 md:col-6 lg:col-6">
						<label htmlFor="contractStartDate">Start Date*</label>
						<Calendar
							className={classNames({
								"p-invalid":
									isFormFieldValid("contractStartDate"),
							})}
							name="contractStartDate"
							value={formik.values.contractStartDate}
							onChange={formik.handleChange}
							dateFormat="dd/mm/yy"
							minDate={new Date()}
							showIcon
						/>
						{getFormErrorMessage("contractStartDate")}
					</div>
					<div className="field col-12 md:col-6 lg:col-6">
						<label htmlFor="contractEndDate">End Date*</label>
						<Calendar
							name="contractEndDate"
							className={classNames({
								"p-invalid":
									isFormFieldValid("contractEndDate"),
							})}
							value={formik.values.contractEndDate}
							onChange={formik.handleChange}
							dateFormat="dd/mm/yy"
							minDate={new Date()}
							showIcon
						/>
						{getFormErrorMessage("contractEndDate")}
					</div>
					<div className="field col-12 md:col-12 lg:col-12">
						<label htmlFor="contractDocName">
							Upload Document*
						</label>
						<FileUpload
							name="contractDocName"
							customUpload
							accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
							uploadHandler={onUpload}
							maxFileSize={2000000}
							emptyTemplate={
								<p className="m-0">
									Drag and drop files to here to upload.
								</p>
							}
						/>
						{getFormErrorMessage("contractDocName")}
						{formik?.values?.contractDocName ? (
							<p>{formik?.values?.contractDocName}</p>
						) : null}
						<p className="p-error">
							Notes: <br />
							1) Please upload the document first before submiting
							the form. <br /> 2) Upload document maximum size 2
							mb.
						</p>
					</div>

					<div className="flex justify-content-end gap-2 align-items-center col-12">
						<Button
							type="button"
							label={"reset"}
							className="danger-btn w-auto"
							icon="pi pi-times"
							onClick={() => {
								setContractFilterData(null);
								formik.resetForm();
							}}
						/>
						<Button
							type="submit"
							label={"Save"}
							icon="pi pi-save"
							className=" w-auto"
						/>
					</div>
				</form>
			</div>
		</>
	);
};

export default ContractDetails;
