import { useReducer, useState } from "react";
import { useGetProductDetailsQuery } from "../../../../services/query/queryApi";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { ProgramService } from "../../../../services/ProgramService";
import { Dialog } from "primereact/dialog";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import Config from "../../../../shared/config";
import { ProgressSpinner } from "primereact/progressspinner";
import Header from "../../../partners/institutions/header";
import { Accordion, AccordionTab } from "primereact/accordion";
import { produce } from "immer";
const CreateApplication = () => {
	const initialState = {
		subProductId: null,
		subProductName: null,
	};
	const applicationReducer = produce((state, action) => {
		switch (action.type) {
			case "subproductId":
				state.subProductId = action.payload;
				break;
			case "subproductName":
				state.subProductName = action.payload;
				break;
			default:
				return state;
		}
	});
	const [state, dispatch] = useReducer(applicationReducer, initialState);
	const programService = new ProgramService();
	const [dialog, setDialog] = useState(false);
	const [expandedRows, setExpandedRows] = useState(null);
	const navigate = useNavigate();
	const { data = { data: [] }, isLoading } = useGetProductDetailsQuery({
		searchRequest: [],
		pageNo: 1,
		pageSize: 330,
		sorts: [],
	});
	const getDataById = (e) => {
		institutionDispatch({ type: Config.FETCH_CONFIG.start });
		programService
			.getInstitionWiseList({ subProductId: e })
			.then((res) => {
				institutionDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((e) =>
				institutionDispatch({
					type: Config.FETCH_CONFIG.error,
					payload: e,
				})
			)
			.finally(() =>
				institutionDispatch({ type: Config.FETCH_CONFIG.stop })
			);
	};
	const [stages] = useState([
		{
			sequence: 1,
			title: "Select a Program",
			status: "active",
		},
		{
			sequence: 2,
			title: "Add Contact",
			status: "inactive",
		},

		{
			sequence: 3,
			title: "Save Application",
			status: "inactive",
		},
	]);

	const [institutionState, institutionDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const actionTemplate = (e) => {
		return (
			<Button
				className="py-2 px-3"
				onClick={() =>
					navigate("/masters/loan_application", {
						state: {
							leadId: null,
							subproductId: state.subProductId,
							subProductName: state.subProductName,
							alias: e.alias,
							applicationId: null,
							programName: e.name,
							programId: e.programId,
							mobileNumber: "",
						},
					})
				}
				label="Apply"
				icon="pi pi-angle-double-right"
			/>
		);
	};
	const subProductAction = (item) => {
		return (
			item.alias && (
				<Button
					className="py-2 px-3"
					onClick={() => {
						setDialog(true);
						dispatch({
							type: "subproductName",
							payload: item.title,
						});
						dispatch({
							type: "subproductId",
							payload: item.id,
						});
						getDataById(item.id);
					}}
					label="Select"
					icon="pi pi-angle-right"
				/>
			)
		);
	};
	const rowExpansionTemplate = (data) => {
		const filterData = data.programListDtoList.filter(
			(item) => item.activeStatus === true && item.activeStatus === !null
		);

		return (
			<div className="w-full">
				<h4 className="my-2">Program List</h4>

				<DataTable value={filterData} responsiveLayout="scroll">
					<Column field={"name"} header={"Name"} />
					<Column body={actionTemplate} header={"Apply"} />
				</DataTable>
			</div>
		);
	};
	const allowExpansion = (rowData) => {
		return rowData.programListDtoList.length > 0;
	};

	return (
		<>
			<Header
				stages={stages}
				title="Application"
				stageName={"Create Application"}
			/>

			<Dialog
				visible={dialog}
				onHide={() => {
					setDialog(false);
				}}
				style={{ minWidth: "40%" }}
				header="Select program"
			>
				<div className="card modal-form-grid">
					{institutionState.isLoading ? (
						<div className="flex align-items-center justify-content-center">
							<ProgressSpinner
								style={{ width: "30px", height: "30px" }}
								strokeWidth="4"
								fill="var(--surface-ground)"
							/>
						</div>
					) : (
						<DataTable
							value={institutionState.data}
							expandedRows={expandedRows}
							onRowToggle={(e) => setExpandedRows(e.data)}
							responsiveLayout="scroll"
							rowExpansionTemplate={rowExpansionTemplate}
							dataKey="institutionId"
							breakpoint="960px"
						>
							<Column
								expander={allowExpansion}
								style={{ width: "50px" }}
							/>
							<Column field="institutionName" header="Name" />
						</DataTable>
					)}
				</div>
			</Dialog>
			{isLoading ? (
				<div className="py-5 px-5">
					<Skeleton width="100%" className="mb-2" height="2rem" />
					<Skeleton width="100%" className="mb-2" height="2rem" />
					<Skeleton width="100%" className="mb-2" height="2rem" />
					<Skeleton width="100%" height="2rem" />
				</div>
			) : (
				<Accordion className="py-5 px-5">
					{data.data.map(
						(item) =>
							item.isActive === true &&
							item.isActive === !null && (
								<AccordionTab header={item.title} key={item.id}>
									<DataTable
										value={item.subProducts.filter(
											(item) => item.isActive === true
										)}
										dataKey="id"
									>
										<Column field="title" header="Name" />
										<Column
											field="lineOfBusiness"
											header="Line Of Business"
										/>
										<Column field="alias" header="Alias" />
										<Column
											body={subProductAction}
											header={"Select Program"}
										/>
									</DataTable>
								</AccordionTab>
							)
					)}
				</Accordion>
			)}
		</>
	);
};

export default CreateApplication;
