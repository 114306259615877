import React, { useEffect, useState } from "react";
import {
	Funnel,
	FunnelChart,
	LabelList,
	Legend,
	ResponsiveContainer,
	Tooltip,
} from "recharts";
import { DashboardService } from "../../../../services/DashboardService";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { CountryAdminDashboardService } from "../../../../services/CountryAdminDashboard";
const LeadFunnel = (props) => {
	const selector = useSelector((state) => state.persistData.auth.user);
	const [funnelData, setFunnelData] = useState([]);
	const [funnelData2, setFunnelData2] = useState([]);
	const [funnelData3, setFunnelData3] = useState([]);

	const [dropdown, setDropdown] = useState("");
	const colors = [
		"#6d79ad",
		"#52cd9f",
		"#df786f",
		"#4e9fa0",
		"#af7d9a",
		"#c9d45c",
	];
	const dashboardService = new CountryAdminDashboardService();
	const getData = (e) => {
		dashboardService.agent_funnel({ userName: e }).then((res) => {
			const data = Object.entries(res).map((elm, i) => ({
				value: Number(elm[1]),
				name:
					elm[0]?.replaceAll("_", " ") +
					" " +
					"(" +
					Number(elm[1]) +
					")",
				fill: colors[i] ? colors[i] : "#a4de6c",
			}));
			setFunnelData(data);
		});
	};
	const getApplicationLeadFunnel = (e) => {
		dashboardService
			.application_lead_funnel({ userName: e })
			.then((res) => {
				const data = Object.entries(res).map((elm, i) => ({
					value: Number(elm[1]),
					name:
						elm[0]?.replaceAll("_", " ") +
						" " +
						"(" +
						Number(elm[1]) +
						")",
					fill: colors[i] ? colors[i] : "#a4de6c",
				}));
				setFunnelData2(data);
			});
	};
	const getApplicationFunnel = (e) => {
		dashboardService.application_funnel({ userName: e }).then((res) => {
			const data = Object.entries(res).map((elm, i) => ({
				value: Number(elm[1]),
				name:
					elm[0]?.replaceAll("_", " ") +
					" " +
					"(" +
					Number(elm[1]) +
					")",
				fill: colors[i] ? colors[i] : "#a4de6c",
			}));
			setFunnelData3(data);
		});
	};
	const handleFunneldata = (tsrm) => {
		Promise.allSettled([
			getData(tsrm),
			getApplicationLeadFunnel(tsrm),
			getApplicationFunnel(tsrm),
		]);
	};
	useEffect(() => {
		handleFunneldata(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// console.log(funnelData);
	const renderLegend = (props) => {
		const { payload } = props;

		return (
			<ul>
				{payload.map((entry, index) => (
					<li key={`item-${index}`}>{entry.value}</li>
				))}
			</ul>
		);
	};

	return (
		<div className="  mt-3">
			<div className="c-agent-box-1">
				<div className="grid align-items-center">
					{selector.role.position === 1030 ? null : (
						<div className="flex col-12 justify-content-between pb-2 ">
							<Dropdown
								className="border-round-md"
								filter
								options={props.tsrmlist.map((elm) => ({
									...elm,
									name:
										(elm?.firstName ? elm?.firstName : "") +
										" " +
										(elm?.middleName
											? elm?.middleName
											: "") +
										" " +
										(elm?.lastName ? elm?.lastName : ""),
								}))}
								optionLabel="name"
								optionValue="userName"
								placeholder="Tsrm"
								value={dropdown}
								onChange={(e) => {
									handleFunneldata(e.value);
									setDropdown(e.value);
								}}
							/>
							<Button
								icon={"pi pi-refresh"}
								onClick={() => {
									setDropdown("");
									// getData(null);
									handleFunneldata(null);
								}}
								className="p-button-text"
							/>
						</div>
					)}

					<div className="lg:col-4 md:col-6 col-12">
						<h5 className="text-center">Agent Lead</h5>
						<div
							style={{
								height: "300px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							{funnelData[0]?.value > 0 ? (
								<ResponsiveContainer width="100%" height="100%">
									<FunnelChart layout={"vertical"}>
										<Tooltip />
										{/* <Legend content={renderLegend} /> */}
										<Funnel
											dataKey="value"
											data={funnelData}
											isAnimationActive
											lastShapeType={"rectangle"}
											labelLine={true}
										>
											{/* {funnelData.map((item, i) => ( */}
											<LabelList
												// key={i.toString()}
												position={"center"}
												fill="#000"
												stroke="none"
												dataKey="name"
											/>
											{/* ))} */}
										</Funnel>
									</FunnelChart>
								</ResponsiveContainer>
							) : (
								funnelData[0]?.value === 0 && (
									<div className="c-no-data-found">
										<i
											className="pi pi-filter-slash"
											style={{ color: "slateblue" }}
										></i>
										<h2>No result found!</h2>
									</div>
								)
							)}
						</div>
					</div>
					<div className="lg:col-4 md:col-6 col-12">
						<h5 className="text-center">Application lead</h5>
						<div
							style={{
								height: "300px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							{funnelData2[0]?.value > 0 ? (
								<ResponsiveContainer width="100%" height="100%">
									<FunnelChart layout={"vertical"}>
										<Tooltip />
										{/* <Legend content={renderLegend} /> */}
										<Funnel
											dataKey="value"
											data={funnelData2}
											isAnimationActive
											lastShapeType={"rectangle"}
											labelLine={true}
										>
											{/* {funnelData.map((item, i) => ( */}
											<LabelList
												// key={i.toString()}
												position={"center"}
												fill="#000"
												stroke="none"
												dataKey="name"
											/>
											{/* ))} */}
										</Funnel>
									</FunnelChart>
								</ResponsiveContainer>
							) : (
								funnelData2[0]?.value === 0 && (
									<div className="c-no-data-found">
										<i
											className="pi pi-filter-slash"
											style={{ color: "slateblue" }}
										></i>
										<h2>No result found!</h2>
									</div>
								)
							)}
						</div>
					</div>
					<div className="lg:col-4 md:col-6 col-12">
						<h5 className="text-center">Application</h5>
						<div
							style={{
								height: "300px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							{funnelData3[0]?.value > 0 ? (
								<ResponsiveContainer width="100%" height="100%">
									<FunnelChart layout={"vertical"}>
										<Tooltip />
										{/* <Legend content={renderLegend} /> */}
										<Funnel
											dataKey="value"
											data={funnelData3}
											isAnimationActive
											lastShapeType={"rectangle"}
											labelLine={true}
										>
											{/* {funnelData.map((item, i) => ( */}
											<LabelList
												// key={i.toString()}
												position={"center"}
												fill="#000"
												stroke="none"
												dataKey="name"
											/>
											{/* ))} */}
										</Funnel>
									</FunnelChart>
								</ResponsiveContainer>
							) : (
								funnelData3[0]?.value === 0 && (
									<div className="c-no-data-found">
										<i
											className="pi pi-filter-slash"
											style={{ color: "slateblue" }}
										></i>
										<h2>No result found!</h2>
									</div>
								)
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LeadFunnel;
