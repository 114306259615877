import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	programId: null,
	mobileNumber: null,
	name: null,
	leadId: null,
	pan: null,
	subproductId: null,
	alias: null,
	pin: null,
	applicationNumber: null,
	// type: null,
	type: "otp",
	status: null,
	applicationId: null,
	programName: null,
	productName: null,
};
const LeadSlice = createSlice({
	name: "leadSlice",
	initialState: initialState,
	reducers: {
		setLeadprogramId: (state, action) => {
			state.programId = action.payload;
		},
		setLeadMobileNumber: (state, action) => {
			state.mobileNumber = action.payload;
		},
		setname: (state, action) => {
			state.name = action.payload;
		},
		setLeadId: (state, action) => {
			state.leadId = action.payload;
		},
		setLeadPanNo: (state, action) => {
			state.pan = action.payload;
		},
		setSubproductId: (state, action) => {
			state.subproductId = action.payload;
		},
		setAlias: (state, action) => {
			state.alias = action.payload;
		},
		setPin: (state, action) => {
			state.pin = action.payload;
		},
		setType: (state, action) => {
			state.type = action.payload;
		},
		setStatus: (state, action) => {
			state.status = action.payload;
		},
		setApplicationId: (state, action) => {
			state.applicationId = action.payload;
		},
		setProgramName: (state, action) => {
			state.programName = action.payload;
		},
		setProductName: (state, action) => {
			state.productName = action.payload;
		},
		setApplicationNumber: (state, action) => {
			state.applicationNumber = action.payload;
		},
	},
});
export const {
	setLeadId,
	setLeadprogramId,
	setLeadMobileNumber,
	setname,
	setLeadPanNo,
	setSubproductId,
	setAlias,
	setPin,
	setType,
	setStatus,
	setApplicationId,
	setProgramName,
	setProductName,
	setApplicationNumber,
} = LeadSlice.actions;
export default LeadSlice.reducer;
