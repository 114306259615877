import { Button } from "primereact/button";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { FeildCalender, FieldInput } from "../../../../components/FieldInput";
import Config from "../../../../shared/config";
import moment from "moment";

const CampaignSearch = (props) => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);

	let initialValues = {
		name: "",
		startDate: "",
		endDate: "",
	};
	const handleFormSubmit = (val) => {
		// const body = {
		// 	...val,
		// 	startDate: val.startDate
		// 		? moment(val.startDate).format("YYYY-MM-DD")
		// 		: "",
		// 	endDate: val.endDate
		// 		? moment(val.endDate).format("YYYY-MM-DD")
		// 		: "",
		// };
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});

		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: val,
		});
	};
	return (
		<>
			<div className="w-full  py-3 flex align-items-end justify-content-between">
				<h3 className="m-0">Campaign</h3>
				<div>
					<Button
						icon={open ? "pi pi-times" : "pi pi-search"}
						className={
							open
								? "p-button-danger p-button-outlined"
								: "p-button-outlined"
						}
						label={open ? "Close" : "Search"}
						aria-label="Cancel"
						onClick={() => setOpen((prev) => !prev)}
					/>
					<Button
						label="Reload"
						icon="pi pi-refresh"
						className="primary-btn ml-2 p-button-outlined"
						onClick={() =>
							props.searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							})
						}
					/>
					<Button
						label="Add"
						icon="pi pi-plus"
						className="primary-btn ml-2 "
						onClick={() => navigate("/masters/campaign/add")}
					/>
				</div>
			</div>
			{open && (
				<Formik
					initialValues={initialValues}
					onSubmit={handleFormSubmit}
				>
					{({ handleSubmit, resetForm }) => (
						<Form onSubmit={handleSubmit}>
							<div className="form-grid grid background-secondary p-4 border-round-md my-3">
								<div className="field col-12 md:col-3">
									<Field
										header={"Campaign Name"}
										name={"name"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Start Date"}
										name={"startDate"}
										component={FeildCalender}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"End Date"}
										name={"endDate"}
										component={FeildCalender}
									/>
								</div>
								<div className="field flex align-items-center  justify-content-end col-12">
									<Button
										type="button"
										label="Reset"
										className="p-button-danger mr-2"
										onClick={() => resetForm()}
									/>
									<Button type="submit" label="Search" />
								</div>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default CampaignSearch;
