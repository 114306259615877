import { useState, useRef, useEffect, useReducer, Fragment } from "react";
import { Messages } from "primereact/messages";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import S3 from "aws-sdk/clients/s3";
import { saveAs } from "file-saver";
// import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import Config from "../../../../shared/config";
import Header from "../../../partners/institutions/header";
import moment from "moment";
import { LoanService } from "../../../../services/LoanService";

import { LoanApplicationService } from "../../../../services/LoanApplication";
import { useDispatch, useSelector } from "react-redux";
import {
	convertNullToString,
	convertObjNull,
} from "../../../../utils/UtilsFunction";
import Loader from "../../../../components/Loader";
import { useGetAllDIstrictQuery } from "../../../../services/query/queryApi";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import parallel from "async/parallel";
import { Dialog } from "primereact/dialog";
import DocumentUpload from "./DocumentUpload";
import scrollToElement from "scroll-to-element";
import * as Yup from "yup";
import { Divider } from "primereact/divider";
import PersonalLoan from "./FormComponent/PersonalLoan";
import HomeLoan from "./FormComponent/HomeLoan";
import CreditCard from "./FormComponent/CreditCard";
import VehicleLoan from "./FormComponent/VehicleLoan";

import PreviewPdf from "./PreviewApplication";
import BusinessLoan from "./FormComponent/BusinessLoan";
import LIinsurance from "./FormComponent/LIinsurance";
import { setApplicationId } from "../../../../store/reducer/AuthReducer";
import { LeadService } from "../../../../services/LeadService";
import { folder_path } from "../../../../shared/constant";
// import { PinValidation } from "../../../../services/pinValidationService";

const s3Clint = new S3({
	accessKeyId: Config.S3_ACCESSKEYID,
	secretAccessKey: Config.S3_SECRETACCESSKEY,
	region: Config.S3_REGION,
	signatureVersion: "v4",
});

const ApplicationForm = (props) => {
	const navigate = useNavigate();
	const msgs = useRef(null);
	const persistSelector = useSelector((state) => state.persistData);
	const [printDialog, setPrintDialog] = useState(false);
	window.onbeforeunload = function () {
		return "Are you sure";
	};
	const [imageData, setImageData] = useState({});
	const [leadDocument, setLeadDocument] = useState([]);
	const [imageCompareData, setImageCompareData] = useState({});
	const applicationDispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const {
		data: getAllDistrict = [
			{ districtList: [{ id: null, name: "" }], id: null, name: "" },
		],
	} = useGetAllDIstrictQuery();
	// const pinValidation = new PinValidation();
	const loanService = new LoanService();
	const lead = new LeadService();
	const toastTL = useRef(null);
	const loanApplicationService = new LoanApplicationService();
	const [reason, setReason] = useState([]);
	const [allDisease, setAllDisease] = useState([]);
	const [leaddetailsstate, leadDetailsDIspatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const [formState, formDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);

	function getFileExtension(filename) {
		const extension = filename.split(".").pop();
		return extension;
	}

	const getData = (e) => {
		formDispatch({ type: Config.FETCH_CONFIG.start });
		loanApplicationService
			.getLoanApplicationEditList(e)
			.then((res) => {
				const data = convertNullToString(res);
				formDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: data,
				});
			})

			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				formDispatch({ type: Config.FETCH_CONFIG.stop });
			});
	};

	const getReason = () => {
		props?.urlData?.state?.subproductId &&
			loanApplicationService
				.getReason(props?.urlData?.state?.subproductId)
				.then((res) => {
					const data = res.map((e) => {
						return { ...e, value: e.id };
					});
					setReason([...data, { reason: "other", value: 0 }]);
				})
				.catch((e) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					})
				);
	};

	const getDisease = () => {
		loanApplicationService
			.getDisease()
			.then((res) => {
				setAllDisease(res);
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			);
	};
	const getLoanApplicationCreateFullList = () => {
		if (
			props?.urlData?.state?.alias !== "ULIP" ||
			props?.urlData?.state?.alias !== "TRADI" ||
			props?.urlData?.state?.alias !== "CIP" ||
			props?.urlData?.state?.alias !== "CEP" ||
			props?.urlData?.state?.alias !== "TIP" ||
			props?.urlData?.state?.alias !== "RIP"
		) {
			leadDetailsDIspatch({
				type: Config.FETCH_CONFIG.start,
			});
			loanApplicationService
				.getLoanApplicationCreateFullList(props?.urlData?.state?.mobile)
				.then((res) => {
					// const newData = convertNullToString(res);
					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.success,
						payload: convertNullToString(res),
					});
				})
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() =>
					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.stop,
					})
				);
		} else {
			leadDetailsDIspatch({
				type: Config.FETCH_CONFIG.start,
			});
			loanApplicationService
				.getInsuranceLoanApplicationCreateFullList(
					props?.urlData?.state?.mobile
				)
				.then((res) => {
					// const newData = convertNullToString(res);

					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.success,
						payload: convertNullToString(res),
					});
				})
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() =>
					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.stop,
					})
				);
		}
	};

	const getDistrictData = (e) => {
		const stateData = getAllDistrict.find((item) => item.id === e);
		if (stateData !== undefined) {
			const districtData = stateData.districtList.map((e) => ({
				...e,
				value: e.id,
			}));
			return districtData;
		} else {
			return [];
		}
	};

	useEffect(() => {
		const leadId = props.urlData.state.leadId;
		if (leadId) {
			lead.getLeadDocument(leadId).then((res) => {
				let documentType = [];
				res.forEach((item) => {
					if (!documentType.includes(item.documentTypeId.type)) {
						documentType.push(item.documentTypeId.type);
					}
				});
				const docs = documentType.map((item) => {
					let temp = res.filter(
						(elm) => elm.documentTypeId.type === item
					);
					let files = temp.map((elm) => ({
						leadId: elm.leadId,
						documentFileName: elm.documentFileName,
						path: `${Config.AGENT_BUCKET_ACCESS_URL}/lead/${folder_path}/${elm.leadId}/${elm.documentFileName}`,
					}));
					return {
						type: item,
						files: files,
					};
				});

				setLeadDocument(docs);
			});
		}
	}, []);

	useEffect(() => {
		let ignore = false;
		if (!ignore) {
			parallel(
				[
					function (cb) {
						props?.urlData?.state?.applicationId ||
						persistSelector?.applicationId
							? getData(
									props?.urlData?.state?.applicationId ||
										persistSelector?.applicationId
							  )
							: !props?.urlData?.state?.applicationId &&
							  getLoanApplicationCreateFullList();
						cb(null, 1);
					},
					function (cb) {
						getReason();
						cb(null, 2);
					},
					function (cb) {
						getDisease();
						cb(null, 3);
					},
				],
				function (err, result) {}
			);
		}
		return () => {
			ignore = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [persistSelector.applicationId]);

	const download = async (file) => {
		s3Clint.getObject(
			{
				Bucket:
					Config.LEAD_ATTACHMENT_BUCKET +
					"/" +
					folder_path +
					"/" +
					props.urlData.state.leadId,
				Key: file,
			},
			function (error, data) {
				if (error != null) {
				} else {
					const blob = new Blob([data.Body]);
					saveAs(blob, file);
				}
			}
		);
	};

	const initialValue = {
		totalYearExperienceInMonths: "",
		totalYearExperienceInMonthsCurrentCompany: "",
		totalYearExperience: "",
		officeContactPersonName: "",
		officeContactPersonMobile: "",
		reference1Name: "",
		reference1Mobile: "",
		reference1Relation: "",
		reference1Address: "",
		referenceCity: "",
		reference1State: "",
		referencePincode: "",
		reference2Name: "",
		reference2Address: "",
		reference2City: "",
		reference2Pincode: "",
		reference2State: "",
		reference2Mobile: "",
		reference2Relation: "",
		category: "",

		idProof: "",
		addressProof: "",
		gstNumber: "",
		uploadStatementFile: "",
		grossMonthlyIncome: "",
		bankName: "",
		otherLoanAmount: "",
		loanDisbursedDate: "",
		emi: "",
		lastEmiDate: "",
		customerId: "",

		createdBy: "",
		createdOn: "",
		modifiedBy: "",
		modifiedOn: "",

		bankAccountNumber: "",
		bankAccountHolderName: "",
		bankBranch: "",
		bankIfscCode: "",
		accountType: "",
		existingLoanBankName: "",
		existingLoanAccountNumber: "",
		existingLoanTenure: "",
		loanTenure: "",
		emiStartDate: "",
		loanType: "",
		drivingLicence: "",
		rcBook: "",
		gstState: "",
		typeOfProperty: "",
		companyRegisteredOfficeAddress: "",
		coFirstName: "",
		coMiddleName: "",
		coLastName: "",
		coMobile: "",
		coPersonalMail: "",
		coGender: "",
		businessRunBy: "",
		natureOfBusiness: "",
		businessType: "",
		companyCurrentAddress: "",
		profession: "",
		// agentCode: "",
		otherReason: "",
		// age: "",
		reasonId: "",
		loanAmount: "",

		...(!props?.urlData?.state?.applicationId && {
			...leaddetailsstate.data,
			coApplicantAllowed: leaddetailsstate.coApplicantAllowed
				? leaddetailsstate.coApplicantAllowed
				: false,
			coWhatsappNumberSame: leaddetailsstate.coWhatsappNumberSame
				? leaddetailsstate.coWhatsappNumberSame
				: false,
			isSubmit: leaddetailsstate.isSubmit
				? leaddetailsstate.isSubmit
				: false,
			isDelete: leaddetailsstate.isDelete
				? leaddetailsstate.isDelete
				: false,
			dob:
				leaddetailsstate?.data?.dob !== ""
					? new Date(
							moment(leaddetailsstate?.data?.dob).format(
								"YYYY-MM-DD"
							)
					  )
					: "",
			// coDob:
			// 	leaddetailsstate.data.coDob !== ""
			// 		? new Date(leaddetailsstate.data.coDob)
			// 		: "",
			// dob: new Date(""),
			residingSince:
				leaddetailsstate?.data?.residingSince !== ""
					? new Date(
							moment(
								leaddetailsstate?.data?.residingSince
							).format("YYYY-MM-DD")
					  )
					: "",
			perResidingSince:
				leaddetailsstate?.data?.perResidingSince !== ""
					? new Date(
							moment(
								leaddetailsstate?.data?.perResidingSince
							).format("YYYY-MM-DD")
					  )
					: "",
			bankAccountOpenedIn:
				leaddetailsstate?.data?.bankAccountOpenedIn !== ""
					? new Date(
							moment(
								leaddetailsstate?.data?.bankAccountOpenedIn
							).format("YYYY-MM-DD")
					  )
					: "",
		}),
		mobile: props?.urlData?.state?.mobile,
		pan: props?.urlData?.state?.pan,
		state: props?.urlData?.state?.stateId,
		districtId: props?.urlData?.state?.districtId,
		application_date: moment().format("YYYY-MM-DD"),
		leadId: props?.urlData?.state?.leadId,

		applicationType:
			props?.urlData?.state?.alias === "CC" ? "CREDIT_CARD" : "LOAN", //done
		...((props?.urlData?.state?.applicationId ||
			persistSelector?.applicationId) && {
			...formState?.data,
			dob:
				formState?.data?.dob !== ""
					? new Date(moment(formState.data.dob).format("YYYY-MM-DD"))
					: "",
			// coDob: new Date(
			// 	moment(formState.data.coDob).format("YYYY-MM-DD")
			// ),
			residingSince:
				formState?.data?.residingSince !== ""
					? new Date(
							moment(formState.data.residingSince).format(
								"YYYY-MM-DD"
							)
					  )
					: "",
			perResidingSince:
				formState?.data?.perResidingSince !== ""
					? new Date(
							moment(formState.data.perResidingSince).format(
								"YYYY-MM-DD"
							)
					  )
					: "",
			bankAccountOpenedIn:
				formState?.data?.bankAccountOpenedIn !== ""
					? new Date(
							moment(formState.data.bankAccountOpenedIn).format(
								"YYYY-MM-DD"
							)
					  )
					: "",
			gstState: formState?.data?.gstState
				? Number(formState?.data?.gstState)
				: "",
		}),
	};
	const cloneDocument = (applicationId) => {
		const params = {
			Bucket: `${Config.APPLICATION_ATTACHMENT_BUCKET}/${applicationId}`,
			Key: leadDocument[0]?.files[0]?.documentFileName,
		};
		s3Clint.getObject(params, (err, data) => {
			if (err) {
				console.log(err);
				if (err.code === "NoSuchKey") {
					leadDocument.forEach((item) => {
						item.files.forEach((elm) => {
							let params = {
								Bucket: `${Config.APPLICATION_ATTACHMENT_BUCKET}/${applicationId}`,
								CopySource: `${Config.LEAD_ATTACHMENT_BUCKET}/${folder_path}/${elm.leadId}/${elm.documentFileName}`,
								Key: elm.documentFileName,
							};
							s3Clint.copyObject(params, function (err, data) {
								if (err) console.log(err, err.stack);
								// an error occurred
								else console.log(data); // successful response
							});
						});
					});
				}
			}
		});
	};
	const handleFormSubmit = (value, acton) => {
		const reqBody = {
			...value,

			dob:
				value.dob !== ""
					? moment(value.dob).format("YYYY-MM-DD")
					: null,
			coDob:
				value.coDob && value.coDob !== ""
					? moment(value.coDob).format("YYYY-MM-DD")
					: null,
			residingSince:
				value.residingSince !== ""
					? moment(value.residingSince).format("YYYY-MM-DD")
					: null,
			perResidingSince:
				value.perResidingSince !== ""
					? moment(value.perResidingSince).format("YYYY-MM-DD")
					: null,
			bankAccountOpenedIn:
				value.bankAccountOpenedIn !== ""
					? moment(value.bankAccountOpenedIn).format("YYYY-MM-DD")
					: null,
			age:
				value.dob !== ""
					? new Date().getFullYear() -
					  new Date(value.dob).getFullYear()
					: "",
			otherReason: value.reasonId !== 0 ? null : value.otherReason,
		};

		// const param =
		// 	selector.name === "lead"
		// 		? {
		// 				...reqBody,
		// 				productId: selector.subproductId,
		// 				...(selector.applicationId && {
		// 					id: selector.applicationId,
		// 				}),
		// 		  }
		// 		: selector.name === "applicationList"
		// 		? reqBody
		// 			: {};
		const param = props?.urlData?.state?.applicationId
			? reqBody
			: {
					...reqBody,
					...(persistSelector?.applicationId && {
						id: persistSelector?.applicationId,
					}),
					productId: props?.urlData?.state?.subproductId,
			  };
		const data = convertObjNull(param);
		setLoader(true);
		loanService
			.loanSaveDraft(data)
			.then((res) => {
				persistSelector?.applicationId === null &&
					applicationDispatch(setApplicationId(res?.id));
				getData(res?.id);
				cloneDocument(res?.id);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "saved",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoader(false);
				// selector?.name === "applicationList" && getData();
			});
	};

	const finalSubmit = () => {
		const myNewArr = [
			{
				label: "Marital Status",
				name: "maritalStatus",
				value: formState.data?.maritalStatus,
			},
			{
				label: "Mother MaidenName",
				name: "motherMaidenName",
				value: formState.data?.motherMaidenName,
			},
			{
				label: "Residence Type",
				name: "residenceType",
				value: formState.data?.residenceType,
			},
			{
				label: "Landmark",
				name: "landmark",
				value: formState.data?.landmark,
			},
			{
				label: "Company Name",
				name: "companyName",
				value: formState.data?.companyName,
			},
			{
				label: "Total Year Experience In Months",
				name: "totalYearExperienceInMonths",
				value: formState.data?.totalYearExperienceInMonths,
			},
			{
				label: "Pincode",
				name: "pincode",
				value: formState.data?.pincode,
			},
			{ label: "City", name: "city", value: formState.data?.city },
			{
				label: "Reference1 Mobile",
				name: "reference1Mobile",
				value: formState.data?.reference1Mobile,
			},

			{
				label: "Reference1 Name",
				name: "reference1Name",
				value: formState.data?.reference1Name,
			},
		];
		if (props?.urlData?.state?.alias !== "BL") {
			myNewArr.push(
				...[
					{
						label: "Net Monthly Income",
						name: "netMonthlyIncome",
						value: formState.data?.netMonthlyIncome,
					},
					{
						label: "Building Name",
						name: "buildingName",
						value: formState.data?.buildingName,
					},
				]
			);
		}
		if (props?.urlData?.state?.alias !== "VL") {
			myNewArr.push(
				...[
					{
						label: "Reference2 Name",
						name: "reference2Name",
						value: formState.data?.reference2Name,
					},
					{
						label: "Reference2 Mobile",
						name: "reference2Mobile",
						value: formState.data?.reference2Mobile,
					},
				]
			);
		}
		const newFocusData = myNewArr.filter((elm) => !Boolean(elm?.value));
		const selector = `[data-error-key="${newFocusData[0]?.name}"]`;

		const fallbackSelector = `[name="${newFocusData[0]?.name}"]`;
		const errorElement =
			document.querySelector(selector) ||
			document.querySelector(fallbackSelector);

		if (errorElement) {
			errorElement.focus();
			scrollToElement(errorElement);
		}
		const myArr = {
			"First Name": formState.data?.firstName,
			"Last Name": formState.data?.lastName,
			"Marital Status": formState.data?.maritalStatus,
			"Mother Maiden Name": formState.data?.motherMaidenName,
			"Residence Type": formState.data?.residenceType,
			Landmark: formState.data?.landmark,

			"Company Name": formState.data?.companyName,
			"Total Year Experience In Months":
				formState.data?.totalYearExperienceInMonths,
			// "Net Monthly Income": formState.data?.netMonthlyIncome,
			Pincode: formState.data?.pincode,
			City: formState.data?.city,
			"Reference1 Name": formState.data?.reference1Name,
			"Reference1 Mobile": formState.data?.reference1Mobile,
			"Reference2 Name": formState.data?.reference2Name,
			"Reference2 Mobile": formState.data?.reference2Mobile,
		};

		if (props?.urlData?.state?.alias !== "BL") {
			myArr["Net Monthly Income"] = formState.data?.netMonthlyIncome;
			myArr["Building Name"] = formState.data?.buildingName;
		}
		const myData = Object.values(myArr);
		const myKeys = Object.keys(myArr);
		const fil = myData
			.map(
				(elm, i) =>
					!(elm !== "" && elm !== null && elm !== undefined) && i
			)
			.filter((item) => item && item);

		const documentData = Object.keys(imageData);
		const documentCompData = Object.keys(imageCompareData);
		const commonData = documentData
			.map((elm) => !documentCompData.includes(elm) && elm)
			.filter((item) => item && item);

		if (fil?.length === 0 && commonData?.length === 0) {
			setLoader(true);
			loanService
				.applicationSubmit(
					props?.urlData?.state?.applicationId
						? props?.urlData?.state?.applicationId
						: persistSelector.applicationId
				)
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Successfull",
						detail: res?.message,
						style: { color: "#000000" },
						life: 3000,
					});
					navigate("/vendor/application-list");
				})

				.catch((e) =>
					toastTL.current.show({
						severity: "error",
						summary: e.name,
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => {
					setLoader(false);
				});
		} else {
			return msgs.current.show({
				severity: "error",
				summary: "Warning",
				detail: "Field Missing",
				sticky: true,
				content: (
					<div>
						<h1> These fields are missing.</h1>
						<div className="flex w-full gap-4 justify-content-between">
							<ul>
								{fil.map((item, i) => (
									<li key={i.toString()}>
										{myKeys[item]} is Required
									</li>
								))}
							</ul>
							<ul>
								{commonData.map((item, i) => (
									<li key={i.toString()}>{item} required</li>
								))}
							</ul>
						</div>
						<p>Save draft before submit</p>
					</div>
				),
			});
		}
	};

	const validationSchema = Yup.object().shape({
		loanAmount: Yup.number().when({
			is: (val) =>
				props?.urlData?.state?.alias === "PL" ||
				props?.urlData?.state?.alias === "BL" ||
				props?.urlData?.state?.alias === "LAP" ||
				props?.urlData?.state?.alias === "HL",
			then: Yup.number().required("This Field is required"),
		}),
		aadhaarNo: Yup.string().min(12).nullable(),
		otherReason: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid reason name"
			)
			.nullable(),
		mobile: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable(),
		whatsappNumber: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable(),
		personalMail: Yup.string().trim().email("Invalid email").nullable(),
		pan: Yup.string()
			.matches(
				/([A-Z]{5}[0-9]{4}[A-Z]{1})/,
				"Please enter valid pancard number"
			)
			.required("This field is requried"),
		firstName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid first name"
			)
			.nullable(),
		lastName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid last name"
			)
			.nullable(),
		middleName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid middle name"
			)
			.nullable(),
		spouseName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid spouse name"
			)
			.nullable(),
		fatherName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid father name"
			)
			.nullable(),

		motherMaidenName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid mother maiden name"
			)
			.nullable(),
		coFirstName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid co-first name"
			)
			.nullable(),
		coMiddleName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid co-middle name"
			)
			.nullable(),
		coLastName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid co-last name"
			)
			.nullable(),
		coMobile: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable(),
		coPersonalMail: Yup.string().trim().email("Invalid email").nullable(),
		city: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid city"
			)
			.nullable(),
		state: Yup.string().nullable(),
		pincode: Yup.string()
			.matches(/^\d{6}$/, "Please enter valid pincode")
			// .test({
			// 	test: debounce(
			// 		(pin) =>
			// 			pin.length > 0 &&
			// 			pinValidation.varifyPin(pin).then((res) => {
			// 				if (res[0].Status === "Error") {
			// 					return false;
			// 				} else {
			// 					return true;
			// 				}
			// 			}),
			// 		1000
			// 	),
			// 	message: "Invalid Pincode",
			// })
			.nullable(),
		perCity: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid city"
			)
			.nullable(),
		perState: Yup.string().nullable(),
		perPincode: Yup.string()
			.matches(/^\d{6}$/, "Please enter valid pincode")
			.nullable(),
		officialMail: Yup.string().trim().email("Invalid email").nullable(),
		officeTelephoneNumber: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable(),
		officeCity: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid city"
			)
			.nullable(),
		officePincode: Yup.string()
			.matches(/^\d{6}$/, "Please enter valid pincode")
			.nullable(),
		bankAccountHolderName: Yup.string()
			.matches(
				/^(?=.{1,100}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid  name"
			)
			.nullable(),
		bankIfscCode: Yup.string().nullable(),
		bankAccountNumber: Yup.string().nullable(),
		bankName: Yup.string().nullable(),
		reference1Name: Yup.string()
			.matches(
				/^(?=.{1,100}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid reference name"
			)
			.nullable(),
		reference1Relation: Yup.string()
			.matches(
				/^(?=.{1,100}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid reference relation"
			)
			.nullable(),
		reference1Mobile: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable(),
		referenceCity: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid city"
			)
			.nullable(),
		referencePincode: Yup.string()
			.matches(/^\d{6}$/, "Please enter valid pincode")
			.nullable(),
		reference2Name: Yup.string()
			.matches(
				/^(?=.{1,100}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid reference name"
			)
			.nullable(),
		reference2Mobile: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable(),
		reference2Relation: Yup.string()
			.matches(
				/^(?=.{1,100}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid referene relation"
			)
			.nullable(),
		reference2City: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid city"
			)
			.nullable(),
		reference2Pincode: Yup.string()
			.matches(/^\d{6}$/, "Please enter valid pincode")
			.nullable(),
		gender: Yup.string().nullable(),
	});

	return (
		<>
			<Toast ref={msgs} position="top-left" />
			<Toast ref={toastTL} position="top-left" />
			<Dialog
				visible={printDialog}
				style={{ width: "80%" }}
				onHide={() => setPrintDialog(false)}
			>
				{formState.data?.state && (
					<PreviewPdf pdfState={formState.data} />
				)}
			</Dialog>
			<div className="flex align-items-center pb-4 gap-2 justify-content-end">
				{(props?.urlData?.state?.applicationId ||
					persistSelector?.applicationId) && (
					<Button
						label="Export"
						className="p-button-danger"
						icon="pi pi-file-pdf"
						onClick={
							// () =>
							// navigate("/masters/application-pdf", {
							// 	state: formState.data,
							// })
							() => setPrintDialog(true)
						}
					/>
				)}
				{/* <Button
					label="Export"
					className="p-button-danger"
					icon="pi pi-file-pdf"
					onClick={
						() =>
							navigate("/masters/application-pdf", {
								state: formState.data,
							})
						// () => genrateHTML()
					}
				/> */}
				<Button
					icon="pi pi-arrow-left"
					label="Back"
					onClick={() => {
						// switch (persistSelector.application.name) {
						// 	case "create":
						// 		navigate("/vendor/application-create");
						// 		break;
						// 	case "lead":
						// 		navigate("/vendor/lead/all");
						// 		break;
						// 	case "edit":
						// 		navigate("/vendor/application-list");
						// 		break;
						// 	default:
						// 		break;
						navigate(-1);
					}}
				/>
			</div>
			<Header
				stages={[]}
				title="Application"
				stageName={(function () {
					switch (props?.urlData?.state?.alias) {
						case "PL":
							return "Personal Loan Application";
						case "CC":
							return "Credit Card Application";
						case "HL":
							return "Home Loan Application";
						case "LAP":
							return "Loan Against Property ";
						case "BL":
							return "Business Loan Application";
						case "VL":
							return "Vehicle Loan Application";
						// case "ULIP" ||
						// 	"TRADI" ||
						// 	"CIP" ||
						// 	"CEP" ||
						// 	"TIP" ||
						// 	"RIP":
						// 	return "Life Insurance Application";

						default:
							return "Application";
					}
				})()}
			/>
			{leaddetailsstate.isLoading && <Loader />}
			<>
				{(props?.urlData?.state?.applicationId ||
					persistSelector?.applicationId) &&
					formState?.data?.number && (
						<h3 className={"font-semibold mt-6 mb-0"}>
							Rainbow's Application Number (RAN) :&nbsp;&nbsp;{" "}
							{formState?.data?.number}
						</h3>
					)}
				{loader && <Loader />}
				{(function (e) {
					switch (e) {
						case "PL":
							return (
								<PersonalLoan
									handleFormSubmit={handleFormSubmit}
									initialValue={initialValue}
									validationSchema={validationSchema}
									reason={reason}
									getAllDistrict={getAllDistrict}
									getDistrictData={getDistrictData}
								/>
							);
						case "CC":
							return (
								<CreditCard
									handleFormSubmit={handleFormSubmit}
									initialValue={initialValue}
									validationSchema={validationSchema}
									reason={reason}
									getAllDistrict={getAllDistrict}
									getDistrictData={getDistrictData}
								/>
							);
						case "HL":
							return (
								<HomeLoan
									handleFormSubmit={handleFormSubmit}
									initialValue={initialValue}
									validationSchema={validationSchema}
									reason={reason}
									getAllDistrict={getAllDistrict}
									getDistrictData={getDistrictData}
								/>
							);
						case "LAP":
							return (
								<HomeLoan
									handleFormSubmit={handleFormSubmit}
									initialValue={initialValue}
									validationSchema={validationSchema}
									reason={reason}
									getAllDistrict={getAllDistrict}
									getDistrictData={getDistrictData}
								/>
							);
						case "VL":
							return (
								<VehicleLoan
									handleFormSubmit={handleFormSubmit}
									initialValue={initialValue}
									validationSchema={validationSchema}
									reason={reason}
									getAllDistrict={getAllDistrict}
									getDistrictData={getDistrictData}
								/>
							);
						case "BL":
							return (
								<BusinessLoan
									handleFormSubmit={handleFormSubmit}
									initialValue={initialValue}
									validationSchema={validationSchema}
									reason={reason}
									getAllDistrict={getAllDistrict}
									getDistrictData={getDistrictData}
								/>
							);
						// case "ULIP" ||
						// 	"TRADI" ||
						// 	"CIP" ||
						// 	"CEP" ||
						// 	"TIP" ||
						// 	"RIP":
						// 	return (
						// 		<LIinsurance
						// 			handleFormSubmit={
						// 				handleInsuranceFormSubmit
						// 			}
						// 			initialValue={insuranceInitialValue}
						// 			// validationSchema={{}}
						// 			reason={reason}
						// 			allDisease={allDisease}
						// 			getAllDistrict={getAllDistrict}
						// 			getDistrictData={getDistrictData}
						// 		/>
						// 	);
						default:
							return <></>;
					}
				})(props?.urlData?.state?.alias)}

				{leadDocument?.length ? (
					<Panel
						header="Document as per lead"
						toggleable
						className="mb-3"
						collapsed
					>
						<div className="grid">
							{leadDocument.map((item, index) => (
								<div key={`${index}`} className="md:col-6 ">
									<div className="border-1 border-300 border-round-md p-3">
										<h4 className="col-12 capitalize">
											{item.type
												.replace(/_/g, " ")
												.toLocaleLowerCase()}
										</h4>
										{item.files.map((elm, i) => (
											<Fragment key={`file-${i}`}>
												<div className="list-box-grid c-list-box-w">
													<div className="w-full flex justify-content-between align-iteam-center list-box-container">
														<a
															href={elm.path}
															target="_blank"
															className="flex align-items-center justify-content-start"
															rel="noreferrer"
														>
															{getFileExtension(
																elm.documentFileName
															) === "pdf" ? (
																<i
																	className={`text-2xl pi pi-file-pdf text-red-400`}
																/>
															) : (
																<span className="c-list-box-it">
																	<img
																		width={
																			50
																		}
																		src={
																			elm.path
																		}
																		alt=""
																	/>
																</span>
															)}

															<div className="pl-4">
																<h5 className="m-0">
																	{
																		elm.documentFileName
																	}
																</h5>
																<small
																	style={{
																		textTransform:
																			"uppercase",
																	}}
																>
																	{item.type}
																</small>
															</div>
														</a>

														<Button
															icon={
																"pi pi-download"
															}
															onClick={() =>
																download(
																	elm.documentFileName
																)
															}
															className="p-button-rounded"
														/>
													</div>
												</div>
											</Fragment>
										))}
									</div>
								</div>
							))}
						</div>
					</Panel>
				) : null}

				{(props?.urlData?.state?.applicationId ||
					persistSelector?.applicationId) && (
					<DocumentUpload
						applicationId={
							props?.urlData?.state?.applicationId ||
							persistSelector?.applicationId
						}
						subproductId={props?.urlData?.state?.subproductId}
						leadId={formState.data?.leadId}
						setImageData={setImageData}
						setImageCompareData={setImageCompareData}
					/>
				)}
				<Messages ref={msgs} />
				{(props?.urlData?.state?.applicationId ||
					persistSelector?.applicationId) && (
					<div className="flex justify-content-end align-items-center w-full mt-4">
						{persistSelector?.applicationState === "edit" && (
							<Button
								type="button"
								label="Submit"
								icon="pi pi-save"
								onClick={() => {
									finalSubmit();
								}}
							/>
						)}
					</div>
				)}
			</>
		</>
	);
};

export default ApplicationForm;
