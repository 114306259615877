// import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useReducer, useRef, useState } from "react";
// import { FieldInput } from "../../../../components/FieldInput";
import { useGetProductCategoryQuery } from "../../../../services/query/queryApi";
// import {
// 	dataReducer,
// 	fetchInitialState,
// } from "../../../../components/fetchReducer";
// import Config from "../../../../shared/config";
import { AgentService } from "../../../../services/AgentService";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { produce } from "immer";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import TableLoader from "../../../../components/TableLoader";
import { Button } from "primereact/button";
import Loader from "../../../../components/Loader";
import { Toast } from "primereact/toast";
import { Field, FieldArray, Form, Formik } from "formik";
import { FieldInput } from "../../../../components/FieldInput";
import { classNames } from "primereact/utils";
import { FiCheck, FiX } from "react-icons/fi";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { convertNullToString } from "../../../../utils/UtilsFunction";
import { Card } from "primereact/card";
import moment from "moment";
const ProductWiseCategory = ({ agentData }) => {
	const convertAgentData = convertNullToString(agentData?.state);
	// console.log(convertAgentData);
	const id = agentData?.state.agentId;

	const navigate = useNavigate();
	const user = useSelector(
		(state) => state.persistData.auth.user.role.position
	);
	const toast = useRef(null);
	const { data = [], isLoading, isSuccess } = useGetProductCategoryQuery();
	const [loader, setLoader] = useState(false);
	const agentService = new AgentService();

	const [categoryId, setcategoryId] = useState("");
	const initialValues = {
		data: [],
		isLoading: false,
		isFetched: false,
	};
	const reducer = produce((state, action) => {
		switch (action.type) {
			case "data":
				state.data = action.payload;
				break;
			case "isLoading":
				state.isLoading = action.payload;
				break;
			case "isFetched":
				state.isFetched = action.payload;
				break;
			default:
				return state;
		}
	});
	const [productListState, productListDispatch] = useReducer(
		reducer,
		initialValues
	);
	const handleList = (categoryId, agentId) => {
		productListDispatch({ type: "isLoading", payload: true });
		agentService
			.getproductwisecommissionbycategory(categoryId, agentId)
			.then((res) => {
				productListDispatch({ type: "isFetched", payload: true });
				productListDispatch({
					type: "data",
					payload: res,
				});
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() =>
				productListDispatch({ type: "isLoading", payload: false })
			);
	};
	const getCommissionData = (agentId) => {
		productListDispatch({ type: "isLoading", payload: true });
		agentService
			.getAgentCommission(agentId)
			.then((res) => {
				if (res?.categoryId) {
					const catId = res?.categoryId;
					setcategoryId(catId);
					handleList(catId, agentId);
				} else {
					setcategoryId(data[0]?.id);
					handleList(data[0]?.id, agentId);
				}
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() =>
				productListDispatch({ type: "isLoading", payload: false })
			);
	};
	useEffect(() => {
		productListDispatch({ type: "isLoading", payload: true });
		isSuccess && getCommissionData(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	const textEditor = (options) => {
		return (
			<InputText
				type="number"
				className="w-14rem"
				value={options.value}
				onChange={(e) => {
					options.editorCallback(e.target.value);
				}}
			/>
		);
	};

	const onRowEditComplete = (e) => {
		let { newData, index } = e;
		let anotherData = produce(productListState.data, (draft) => {
			draft[index] = newData;
		});
		productListDispatch({
			type: "data",
			payload: anotherData,
		});
		// const newData = produce(_products)
	};

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header={"ProductName"} />
				<Column header={"Profile Type"} />

				<Column header={"Amount"} />

				<Column header={"Limit (Min Amount - Max Amount)"} />
				<Column header={"Status"} />
				<Column header={"remarks"} />
				<Column header="" />
			</Row>
		</ColumnGroup>
	);
	const itaration = {
		min: "",
		max: "",
		min1: "",
		max1: "",
		action: "",
		masx: "",
	};
	// const categoryTemplate = (item) => {
	// 	const newData = data.find((it) => it.id === item.categoryId);
	// 	if (newData) {
	// 		return newData.name;
	// 	} else {
	// 		return item.categoryId;
	// 	}
	// };
	const handleCategory = (val) => {
		setcategoryId(val);
		handleList(val, id);
	};

	const handleFormSUbmit = (val) => {
		// console.log("sajjan", val);
		// console.log(val);
		// const newData = {
		// 	categoryId: categoryId,
		// 	// productAgentCommissionId: val[0].productAgentCommissionId,
		// 	agentId: id,
		// 	agentCategoryCommissionDtoList: val.map((item) => ({
		// 		...item,
		// 		agentId: id,
		// 		amount: item.defaultAmount,
		// 		categoryId: item.categoryId,
		// 	})),
		// };
		// console.log(newData);

		const newData = {
			categoryId: categoryId,
			agentId: parseInt(id),
			// productAgentCommissionId: findData.productAgentCommissionId,
			agentCategoryCommissionDtoList:
				val.productAgentCommissionDetailsDtos.map((item) => ({
					...item,
					categoryId: categoryId,
					agentId: parseInt(id),
				})),
		};
		// console.log(newData);
		setLoader(true);
		agentService
			.agentCategoryComission(newData)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((err) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: err.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoader(false));
	};
	const validationSchema = Yup.object().shape({
		productAgentCommissionDetailsDtos: Yup.array().when("minAmount", {
			is: () => user === 50,
			then: Yup.array().of(
				Yup.object().shape({
					amount: Yup.number()
						.nullable()
						.when(["minAmount", "maxAmount"], {
							is: (min, max) => min && max,
							then: Yup.number().when(
								["minAmount", "maxAmount"],
								(min, max, value) => {
									return value.test({
										test: (amount) => {
											return (
												!!min &&
												!!max &&
												max >= amount &&
												amount >= min
											);
										},
										message:
											"Min Amount < Amount < Max Amount ",
									});
								}
							),
						}),
				})
			),
		}),
	});
	const statusTemplate = (item) => {
		switch (item.agentCommissionStatus) {
			case "INACTIVE":
				return (
					<span className="status status-warning">
						{item.agentCommissionStatus}
					</span>
				);

			case "REJECT":
				return (
					<span className="status status-danger">
						{item.agentCommissionStatus}
					</span>
				);

			// case 2:
			// 	return (
			// 		<span className="status status-primary">
			// 			{item.agentCommissionStatus}
			// 		</span>
			// 	);

			// case 4:
			// 	return (
			// 		<span className="status status-danger-deep">
			// 			{item.agentCommissionStatus}
			// 		</span>
			// 	);

			case "ACTIVE":
				return (
					<span className="status status-success">
						{item.agentCommissionStatus}
					</span>
				);

			default:
				return "";
		}
	};

	const LimitTemplate = (item) => {
		return (
			<>
				{item.minAmount && item.maxAmount && (
					<p>
						{item.minAmount} - {item.maxAmount}
					</p>
				)}
			</>
		);
	};
	// console.log("object");
	const getStatus = (data) => {
		switch (data) {
			case "VERIFIED":
				return <span className="status status-success">Verified</span>;
			case "ON_HOLD":
				return <span className="status status-warning">On Hold</span>;
			case "TEMPORARY_SUSPENDED":
				return (
					<span className="status status-danger">
						Temporary Suspended
					</span>
				);
			case "NOT_VERIFIED":
				return <span className="status status-ban">Not Verified</span>;
			case "BLOCKED":
				return (
					<span className="status status-danger-deep">Blocked</span>
				);

			default:
				return <span className="status status-danger">No Data</span>;
		}
	};
	const agentStatus = (status) => {
		return status === null || status.toLowerCase() === "inactive" ? (
			<span className="status status-mute">Inactive</span>
		) : status.toLowerCase() === "blocked" ? (
			<span className="status status-danger-deep">Blocked</span>
		) : (
			<span className="status status-primary">Active</span>
		);
	};
	return (
		<>
			<Toast ref={toast} />
			{loader && <Loader />}
			<div></div>
			<>
				<>
					{isLoading ? (
						<></>
					) : (
						<div className="flex justify-content-between mb-3">
							<Dropdown
								value={categoryId}
								optionLabel={"name"}
								options={data}
								optionValue={"id"}
								onChange={(e) => {
									handleCategory(e.value);
								}}
								placeholder="Select Category"
								className="border-round-sm  w-3"
							/>
							<Button
								label="Back"
								icon="pi pi-angle-left"
								onClick={() => navigate(-1)}
							/>
						</div>
						// <Field
						// 	name="category"

						// 	component={FeildDropdown}

						// 	value={values.category}
						// 	placeholder={"Select Category"}

						// />
					)}
				</>
				{/* <div className="userBoxes mb-3"> */}
				<Card className="mb-3">
					<div className="grid">
						<div className="col-12 md:col-12">
							<div className="flex pb-2 w-full justify-content-between align-items-center">
								<h2 className="m-0">
									{convertAgentData.firstName
										? convertAgentData.firstName
										: ""}{" "}
									{convertAgentData.middleName
										? convertAgentData.middleName
										: ""}{" "}
									{convertAgentData.lastName
										? convertAgentData.lastName
										: ""}{" "}
									{convertAgentData.profileStatus ? (
										<span className="sq-status status-success">
											<i className="pi pi-verified" />
										</span>
									) : (
										<span className="sq-status status-danger">
											<i className="pi pi-exclamation-triangle" />
										</span>
									)}
								</h2>
								{convertAgentData.createdAt && (
									<span>
										<strong className="text-indigo-700">
											Created At :{" "}
										</strong>
										{convertAgentData.createdAt
											? moment(
													convertAgentData.createdAt
											  ).format(
													"ddd DD MMM,YYYY, hh:mm A"
											  )
											: ""}
										{/* {convertAgentData.createdAt} */}
									</span>
								)}
							</div>
						</div>
						<div className="col-12">
							<hr />
						</div>
						{/* <div className="col-12 md:col-3"> */}
						<ul className="col-3 userLIst">
							{convertAgentData.mobileNumber && (
								<li>
									<strong>Mobile : </strong>
									{convertAgentData.mobileNumber}
								</li>
							)}
							{convertAgentData.emailId && (
								<li>
									<strong>Email : </strong>
									{convertAgentData.emailId}
								</li>
							)}

							{convertAgentData.gender && (
								<li>
									<strong>Gender : </strong>
									{convertAgentData.gender}
								</li>
							)}

							{convertAgentData.userName && (
								<li>
									<strong>Username : </strong>
									{convertAgentData.userName}
								</li>
							)}
						</ul>
						{/* </div> */}
						{/* <div className="col-12 md:col-3"> */}
						<ul className="col-3 userLIst">
							{convertAgentData.agentCode && (
								<li>
									<strong>Agent Code : </strong>
									{convertAgentData.agentCode}
								</li>
							)}

							{convertAgentData.status && (
								<li className=" flex justify-content-start algin-items-center">
									<strong>Status : </strong>
									{/* {convertAgentData.status} */}
									<p className="m-0 value stValue ml-2">
										{agentStatus(convertAgentData.status)}
									</p>
								</li>
							)}

							{convertAgentData.profileType && (
								<li>
									<strong>Profile Type : </strong>
									{convertAgentData.profileType}
								</li>
							)}

							{convertAgentData.profileStatus && (
								<li className=" flex justify-content-start align-items-center">
									<strong>Profile Status : </strong>
									{/* {convertAgentData.profileStatus} */}
									<p className="m-0 value ml-2">
										{getStatus(
											convertAgentData.profileStatus
										)}
									</p>
								</li>
							)}

							{convertAgentData.selfieVerified && (
								<li className=" flex justify-content-start align-items-center">
									<strong>Selfie Verified : </strong>
									{/* {convertAgentData.selfieVerified} */}
									<p className="m-0 ml-2 value">
										{convertAgentData.selfieVerified ? (
											<span className="sq-status status-success">
												<i className="pi pi-verified" />
											</span>
										) : (
											<span className="sq-status status-danger">
												<i className="pi pi-exclamation-triangle" />
											</span>
										)}
									</p>
								</li>
							)}
						</ul>
						{/* </div> */}
						{/* <div className="col-12 md:col-3"> */}
						<ul className="col-3 userLIst">
							{convertAgentData.pincode && (
								<li>
									<strong>Pincode : </strong>
									{convertAgentData.pincode}
								</li>
							)}

							{convertAgentData.districtName && (
								<li>
									<strong>District Name : </strong>
									{convertAgentData.districtName}
								</li>
							)}

							{convertAgentData.stateName && (
								<li>
									<strong>State Name : </strong>
									{convertAgentData.stateName}
								</li>
							)}

							{convertAgentData.zoneName && (
								<li>
									<strong>Zone Name : </strong>
									{convertAgentData.zoneName}
								</li>
							)}

							{convertAgentData.panVerified && (
								<li className=" flex justify-content-start align-items-center">
									<strong>Pan Verified : </strong>
									{/* {convertAgentData.panVerified} */}
									<p className="m-0 ml-2 value">
										{convertAgentData.panVerified ? (
											<span className="sq-status status-success">
												<i className="pi pi-verified" />
											</span>
										) : (
											<span className="sq-status status-danger">
												<i className="pi pi-exclamation-triangle" />
											</span>
										)}
									</p>
								</li>
							)}
						</ul>
						{/* </div> */}
						{/* <div className="col-12 md:col-3"> */}
						<ul className="col-3 userLIst">
							<li>
								<strong>Sales Name : </strong>
								{convertAgentData.salesUserFirstName
									? convertAgentData.salesUserFirstName
									: ""}{" "}
								{convertAgentData.salesUserMiddleName
									? convertAgentData.salesUserMiddleName
									: ""}{" "}
								{convertAgentData.salesUserLastName
									? convertAgentData.salesUserLastName
									: ""}
							</li>
							{convertAgentData.salesUser && (
								<li>
									<strong>Sales User Name : </strong>
									{convertAgentData.salesUser}
								</li>
							)}

							<li>
								<strong>Rm Name : </strong>
								{convertAgentData.rmUserFirstName
									? convertAgentData.rmUserFirstName
									: ""}{" "}
								{convertAgentData.rmUserMiddleName
									? convertAgentData.rmUserMiddleName
									: ""}{" "}
								{convertAgentData.rmUserLastName
									? convertAgentData.rmUserLastName
									: ""}
							</li>
							{convertAgentData.aadhaarVerified && (
								<li className=" flex justify-content-start align-items-center">
									<strong>Aadhaar Verified : </strong>
									{/* {convertAgentData.aadhaarVerified} */}
									<p className="m-0 ml-2 value">
										{convertAgentData.aadhaarVerified ? (
											<span className="sq-status status-success">
												<i className="pi pi-verified" />
											</span>
										) : (
											<span className="sq-status status-danger">
												<i className="pi pi-exclamation-triangle" />
											</span>
										)}
									</p>
								</li>
							)}
						</ul>
					</div>
				</Card>
				{/* </div> */}
				{/* </div> */}
				{productListState.isLoading ? (
					<Loader />
				) : (
					productListState.isFetched &&
					(productListState.data.length === 0 ? (
						<div className="flex align-items-center justify-content-center py-5 bg-indigo-50 m-8 border-dashed border-1 border-indigo-500">
							<p>No data</p>
						</div>
					) : (
						<Formik
							initialValues={{
								productAgentCommissionDetailsDtos: [],
								...(!productListState.isLoading &&
									productListState.isFetched && {
										productAgentCommissionDetailsDtos:
											productListState.data,
									}),
							}}
							enableReinitialize
							onSubmit={handleFormSUbmit}
							// validationSchema={validationSchema}
						>
							{({ handleSubmit, values, setFieldValue }) => (
								<Form
									onSubmit={handleSubmit}
									// className="form-grid grid modal-form-grid mt-2"
								>
									<table className="p-datatable-table c-table-nw">
										<thead className="p-datatable-thead">
											<tr>
												<th>Product Name</th>
												<th>Profile Type</th>
												<th>
													Limit (Min Amount - Max
													Amount)
												</th>
												<th>Bank Amount</th>
												<th>NBFC Amount</th>
												{/* <th>Max Amount</th> */}
												<th>Status</th>
												<th>Remarks</th>
											</tr>
										</thead>
										<tbody className="p-datatable-tbody">
											<FieldArray
												name="productAgentCommissionDetailsDtos"
												render={(arrayHelper) => (
													<>
														{values.productAgentCommissionDetailsDtos.map(
															(item, index) => (
																<tr
																	key={
																		index.toString() +
																		"mapValueCommision"
																	}
																>
																	<td>
																		{
																			values
																				.productAgentCommissionDetailsDtos[
																				index
																			]
																				.productName
																		}
																	</td>
																	<td>
																		{
																			values
																				.productAgentCommissionDetailsDtos[
																				index
																			]
																				.profileType
																		}
																	</td>
																	<td>
																		{values
																			.productAgentCommissionDetailsDtos[
																			index
																		]
																			.minAmount &&
																			values
																				.productAgentCommissionDetailsDtos[
																				index
																			]
																				.maxAmount &&
																			`${values.productAgentCommissionDetailsDtos[index].minAmount}
																		-
																		${values.productAgentCommissionDetailsDtos[index].maxAmount}`}
																	</td>

																	<td>
																		<Field
																			name={`productAgentCommissionDetailsDtos.${index}.amount`}
																			component={
																				FieldInput
																			}
																			disabled={
																				values
																					.productAgentCommissionDetailsDtos[
																					index
																				]
																					.minAmount &&
																				values
																					.productAgentCommissionDetailsDtos[
																					index
																				]
																					.maxAmount
																					? false
																					: true
																			}
																			autoFocus
																			type={
																				"number"
																			}
																			star={
																				"*"
																			}
																		/>
																	</td>
																	<td>
																		<Field
																			name={`productAgentCommissionDetailsDtos.${index}.defaultNbfcAmount`}
																			component={
																				FieldInput
																			}
																			disabled={
																				values
																					.productAgentCommissionDetailsDtos[
																					index
																				]
																					.minAmount &&
																				values
																					.productAgentCommissionDetailsDtos[
																					index
																				]
																					.maxAmount
																					? false
																					: true
																			}
																			autoFocus
																			type={
																				"number"
																			}
																			star={
																				"*"
																			}
																		/>
																	</td>
																	{/* <td>
																		{
																			values
																				.productAgentCommissionDetailsDtos[
																				index
																			]
																				.minAmount
																		}
																	</td> */}

																	{/* <td>
																		{
																			values
																				.productAgentCommissionDetailsDtos[
																				index
																			]
																				.maxAmount
																		}
																	</td> */}
																	<td>
																		{
																			values
																				.productAgentCommissionDetailsDtos[
																				index
																			]
																				.agentCommissionStatus
																		}
																	</td>
																	<td>
																		{
																			values
																				.productAgentCommissionDetailsDtos[
																				index
																			]
																				.remarks
																		}
																	</td>
																</tr>
															)
														)}
													</>
												)}
											/>
										</tbody>
									</table>
									{categoryId && (
										<div className="flex pt-2 col-12 align-items-center justify-content-end">
											<Button
												label="Save"
												type="submit"
												icon={"pi pi-save"}
											/>
										</div>
									)}
								</Form>
							)}
						</Formik>
					))
				)}
			</>

			{/* {productListState.isLoading ? (
				<TableLoader headerGroup={headerGroup} itaration={itaration} />
			) : (
				productListState.isFetched && (
					<>
						<DataTable
							value={productListState.data}
							headerColumnGroup={headerGroup}
							editMode="row"
							dataKey="id"
							onRowEditComplete={onRowEditComplete}
						>
							<Column field={"productName"} />
							<Column field={"profileType"} />

							<Column
								key={"amount"}
								field={"amount"}
								editor={(options) => textEditor(options)}
							/>

							<Column body={LimitTemplate} />
							<Column body={statusTemplate} />
							<Column field={"remarks"} />
							<Column rowEditor />
						</DataTable>
						{productListState.data.length > 0 && (
							<div className="flex pt-2 align-items-center justify-content-end">
								<Button
									onClick={() =>
										handleFormSUbmit(productListState.data)
									}
									label="Save"
									icon={"pi pi-save"}
								/>
							</div>
						)}
					</>
				)
			)} */}
		</>
	);
};

export default ProductWiseCategory;
