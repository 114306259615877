import React, { useState, useEffect, useRef, useReducer } from "react";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import moment from "moment";
import {
	searchInitialState,
	searchReducer,
} from "../../components/searchReducer";
import { dataReducer, fetchInitialState } from "../../components/fetchReducer";
import Config from "../../shared/config";
import Loader from "../../components/Loader";
import Paginator from "../../components/Paginator";
import TableNoData from "../../components/TableNoData";
import { RatingService } from "../../services/Rating";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Formik, useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import * as Yup from "yup";
const Ratings = () => {
	const navigate = useNavigate();
	const ratingService = new RatingService();
	const toast = useRef(null);
	const [dialog, setDialog] = useState({ state: false, data: [] });
	const [spinner, setSpinner] = useState(false);
	const [state, dispatch] = useReducer(dataReducer, fetchInitialState);
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const validationSchema = Yup.object().shape({
		question: Yup.string().required("This field is required"),
		position: Yup.number().nullable().required("This field is required"),
	});
	const getQuestionList = () => {
		setSpinner(true);
		ratingService
			.getQuestionList()
			.then((res) => setDialog((prev) => ({ ...prev, data: res })))
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setSpinner(false);
			});
	};
	const handleFormSubmit = (value) => {
		if (value?.id) {
			setSpinner(true);
			ratingService
				.updateQuestiont(value)
				.then((res) => setDialog((prev) => ({ ...prev, data: res })))
				.catch((e) =>
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: e?.message,
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => setSpinner(false));
		} else {
			setSpinner(true);
			ratingService
				.saveQuestiont(value)
				.then((res) => {
					setDialog((prev) => ({ ...prev, data: res }));
					formik.resetForm();
				})
				.catch((e) =>
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: e?.message,
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => setSpinner(false));
		}
	};
	const formik = useFormik({
		initialValues: {
			question: "",
			// questionType: "",
			position: "",
		},
		onSubmit: handleFormSubmit,
		validationSchema: validationSchema,
	});
	const getLoanData = () => {
		setSpinner(true);
		const body = {
			...searchState.searchValue,
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.sortByColumn &&
				searchState.sortType && {
					sorts: [
						searchState.sortByColumn + "," + searchState.sortType,
					],
				}),
		};
		ratingService
			.getAppRatingList(body)
			.then((res) =>
				dispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				})
			)
			.catch((e) => {
				dispatch({ type: Config.FETCH_CONFIG.error, payload: e });
				toast.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setSpinner(false));
	};

	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}

	function tblSort(columnName, sortType) {
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}

	const dateTemplate = ({ createdOn }) => {
		return createdOn ? moment(createdOn).format("YYYY-MM-DD") : createdOn;
	};

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />

				<Column header={getSortableColumn("Agent Name", "agentName")} />
				<Column header={"Agent Code"} />

				<Column header={"Question"} />

				<Column header={"Description"} />
				<Column header={"Rating"} />
				<Column header={getSortableColumn("Created On", "createdOn")} />
				{/* <Column header="Action" /> */}
			</Row>
		</ColumnGroup>
	);

	const actionTemplate = (item) => {
		return (
			<div className="flex gap-2">
				<Button
					className="icon-btn p-button-text"
					icon={"pi pi-pencil"}
					onClick={() => {
						formik.setFieldValue("question", item.question, true);
						formik.setFieldValue("id", item.id);
						formik.setFieldValue("position", item.position, true);
					}}
				/>
				<Button
					className="icon-btn p-button-text p-button-danger"
					icon={"pi pi-trash"}
					onClick={() => onDelete(item.id)}
				/>
			</div>
		);
	};
	const onDelete = (id) => {
		setSpinner(true);
		ratingService
			.deleteQuestion(id)
			.then((res) => setDialog((prev) => ({ ...prev, data: res })))
			.catch((e) =>
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setSpinner(false));
	};
	useEffect(() => {
		Promise.all([getLoanData(), getQuestionList()]);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);

	const ratingTemplate = (item) => {
		return (
			item?.rating && (
				<Button
					label={item?.rating}
					className={item?.rating < 0 ? "p-button-danger" : ""}
					style={{
						width: "40px",
						height: "40px",
						borderRadius: "20px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						margin: "4px 0",
					}}
				/>
			)
		);
	};

	return (
		<>
			{spinner && <Loader />}
			<Dialog
				header={"Feedback Options"}
				visible={dialog.state}
				onHide={() => {
					setDialog((prev) => ({ ...prev, state: false }));
					formik.resetForm();
				}}
				style={{ width: "60%" }}
			>
				<form className="grid mt-3 form-grid">
					<div className="field col-12">
						<InputText
							name="question"
							placeholder="Question"
							className="w-full"
							value={formik.values.question}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						{Boolean(formik.errors["question"]) &&
							formik.touched["question"] && (
								<small className="text-red-600">
									{formik.errors["question"]}
								</small>
							)}
					</div>
					{/* <div className="field col-12 md:col-6">
						<Dropdown
							required
							placeholder="Type"
							className="w-full"
							style={{ borderRadius: "6px" }}
							options={[
								{ label: "INDIVIDUAL", value: "INDIVIDUAL" },
								{ label: "EMPLOYEE", value: "EMPLOYEE" },
								{ label: "PARTNER", value: "PARTNER" },
								{ label: "COMPANY", value: "COMPANY" },
								{ label: "BM", value: "BM" },
							]}
							value={formik.values.questionType}
							onChange={formik.handleChange}
						/>
					</div> */}

					<div className="field col-12 md:col-6">
						<InputText
							placeholder="View position (1,2,..)"
							className="w-full"
							type="number"
							name="position"
							onBlur={formik.handleBlur}
							value={formik.values.position}
							onChange={formik.handleChange}
						/>
						{Boolean(formik.errors["position"]) &&
							formik.touched["position"] && (
								<small className="text-red-600">
									{formik.errors["position"]}
								</small>
							)}
					</div>
					<div className="field col-12 flex gap-2 md:col-6 align-items-center justify-content-end">
						<Button
							type="button"
							label={formik.values?.id ? "Update" : "Add"}
							onClick={() => formik.handleSubmit()}
						/>
						<Button
							type="button"
							label="Reset"
							className="p-button-danger"
							onClick={() => formik.resetForm()}
						/>
					</div>
				</form>
				<DataTable className="w-full mt-3" value={dialog.data}>
					<Column field="position" header={"Position"} />
					<Column field="question" header={"Question"} />
					<Column body={actionTemplate} />
				</DataTable>
			</Dialog>
			<Toast ref={toast} />
			<div className="col-12 flex align-items-center justify-content-between ">
				<h3 className="m-0">Ratings</h3>
				<Button
					label={"Feedback Options"}
					onClick={() => {
						setDialog((prev) => ({ ...prev, state: true }));
					}}
				/>
			</div>

			{state.data.data && state.data.data.length > 0 ? (
				<>
					<Paginator
						pageSize={state.data.pageSize}
						firstPage={state.data.firstPage}
						lastPage={state.data.lastPage}
						decrement={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.decrement,
							})
						}
						increment={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.increment,
							})
						}
						totalElements={state.data.totalElements}
						pagesizechange={(e) => {
							// let newPageNo = Math.ceil(
							// 	(state.data.pageSize * state.data.pageNo) /
							// 		e.target.value
							// );
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageSize,
								payload: e.target.value,
							});
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageNo,
								payload: 1,
							});
						}}
						pageNo={state.data.pageNo}
						totalPages={state.data.totalPages}
					/>
					<DataTable
						headerColumnGroup={headerGroup}
						value={state?.data?.data?.map((item, index) => ({
							...item,
							index:
								state.data.pageSize * state.data.pageNo -
								state.data.pageSize +
								1 +
								index,
						}))}
						responsiveLayout="scroll"
						breakpoint="960px"
					>
						<Column field="index" />

						<Column field="agentName" />
						<Column
							body={(item) => {
								return (
									<Button
										className="p-button-text p-0 m-0"
										onClick={() =>
											navigate(
												`/vendor/agent-profile/${item.agentId}`,
												{
													state: {
														profileId:
															item.profileId,
													},
												}
											)
										}
										label={item?.agentCode}
									/>
								);
							}}
							// field="agentCode"
						/>
						<Column field="question" />

						<Column field="description" />
						<Column body={ratingTemplate} />
						<Column body={dateTemplate} />
						{/* <Column body={actionTemplate} /> */}
					</DataTable>
					<Paginator
						pageSize={state.data.pageSize}
						firstPage={state.data.firstPage}
						lastPage={state.data.lastPage}
						decrement={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.decrement,
							})
						}
						increment={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.increment,
							})
						}
						totalElements={state.data.totalElements}
						pagesizechange={(e) => {
							// let newPageNo = Math.ceil(
							// 	(state.data.pageSize * state.data.pageNo) /
							// 		e.target.value
							// );
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageSize,
								payload: e.target.value,
							});
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageNo,
								payload: 1,
							});
						}}
						pageNo={state.data.pageNo}
						totalPages={state.data.totalPages}
					/>
				</>
			) : (
				<TableNoData headerGroup={headerGroup} />
			)}
		</>
	);
};

export default Ratings;
