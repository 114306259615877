import React, { useEffect, useReducer, useState } from "react";
import CommisionUpdate from "./components/CommisionUpdate";
import { ProgramService } from "../../../../services/ProgramService";
import { Button } from "primereact/button";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import Config from "../../../../shared/config";
import Loader from "../../../../components/Loader";
import { Divider } from "primereact/divider";
import { ProgressSpinner } from "primereact/progressspinner";

const HandleCommission = ({ programId, type }) => {
	const programService = new ProgramService();
	const [open, setOpen] = useState(false);
	const [commsissionState, commissionDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const getData = () => {
		commissionDispatch({ type: Config.FETCH_CONFIG.start });
		programService
			.getAllCommission(programId)
			.then((res) => {
				commissionDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch(() => {})
			.finally(() =>
				commissionDispatch({ type: Config.FETCH_CONFIG.stop })
			);
	};

	useEffect(() => {
		programId && getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [programId]);
	return (
		<>
			<div className="w-full flex align-items-center justify-content-end">
				<Button
					icon={open ? "pi pi-times" : "pi pi-plus"}
					label={open ? "" : "Add"}
					className={open ? "p-button-danger p-button-text" : ""}
					onClick={() => setOpen((p) => !p)}
				/>
			</div>
			{open && (
				<CommisionUpdate programId={programId} getData={getData} />
			)}
			<Divider align={"left"}>Commission List</Divider>
			{commsissionState.isLoading ? (
				<div className="flex align-items-center justify-content-center py-5">
					<ProgressSpinner
						style={{ height: "30px" }}
						strokeWidth="5"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				</div>
			) : (
				commsissionState.data.map((item, index) => (
					<div key={index}>
						<h3 className="mb-0">{index + 1}.</h3>
						<CommisionUpdate type={type} item={item} />
					</div>
				))
			)}
		</>
	);
};

export default HandleCommission;
