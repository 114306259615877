import axios from "axios";
import { apiPath, superadminApiPath } from "../shared/constant";

export class ProductService {
	constructor() {
		this.token = sessionStorage.getItem("token");
		this.supertoken = sessionStorage.getItem("supertoken");
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
		this.superHeaders = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.supertoken}`,
		};
	}

	// Get Products according to payload
	// @method: POST
	getSuperProducts = async (payload) => {
		try {
			const response = await axios.post(
				`${superadminApiPath}/super/admin/svc/product/search`,
				payload,
				{ headers: this.superHeaders }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	// Get Products
	// @method: POST
	getProducts = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/master/svc/product/search`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	// Add products to country
	//@method: POST
	addProducts = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/master/svc/product/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	// Update products to country
	//@method: POST
	updateProduct = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/master/svc/product/update`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.response.data.message);
		}
	};

	//All Products that already added
	//@method: GEt
	getAllPreviousProducts = async () => {
		try {
			const response = await axios.get(
				`${apiPath}/master/svc/product/all`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.response.data.message);
		}
	};

	//Get Product Status
	//@method: GET
	getProductsStatus = async (id) => {
		try {
			const response = await axios.get(
				`${apiPath}/master/svc/product/status/${id}`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};

	//Add Products Status
	//@method: POST
	addProductStatus = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/master/svc/product/status`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};

	//Update Product Status
	//@method: POST
	updateProductStatus = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/master/svc/product/status/update`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};

	//Delete Product Status
	//@method: POST
	deleteProductStatus = async (statusId) => {
		try {
			const response = await axios.post(
				`${apiPath}/master/svc/product/status/delete/${statusId}`,
				{},
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};

	//product document get

	getProductDocument = async () => {
		try {
			const response = await axios.get(`${apiPath}/document-types/All`, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};

	//product document get

	getexistingProductDocument = async (id) => {
		try {
			const response = await axios.get(
				`${apiPath}/product-document-types/by-product/${id}`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};

	//product document submit

	productDocumentSubmit = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/product-document-types/save`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};

	getAgentCommissionStructure = async (id) => {
		try {
			const response = await axios.get(
				`${apiPath}/master/svc/commission-structure/${id}`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};

	submitAgentCommissionStructure = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/master/svc/commission-structure/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};
	productAgentCommision = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/product-agent-commission/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	getProductCommission = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/product-agent-commission/search `,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};
	getProductEmployeeCommission = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/product-employee-commission/search`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};
	getCommissionById = async (id) => {
		try {
			const response = await axios.get(
				`${apiPath}/product-agent-commission/${id}`,
				{
					headers: this.headers,
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};
	getEmployeeCommissionById = async (id) => {
		try {
			const response = await axios.get(
				`${apiPath}/product-employee-commission/${id}`,
				{
					headers: this.headers,
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};
	createAgentCommissionStructure = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/product-agent-commission/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	createEmployeeCommissionStructure = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/product-employee-commission/create`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	updateAgentCommissionStructure = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/product-agent-commission/update`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	updateEmployeeCommissionStructure = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/product-employee-commission/update`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	getLastCommissionDate = async (body) => {
		try {
			const response = await axios.post(
				`${apiPath}/product-agent-commission/latest-product-agent-commission`,
				body,
				{
					headers: this.headers,
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};
	getLastEmployeeCommissionDate = async (id) => {
		try {
			const response = await axios.get(
				`${apiPath}/product-employee-commission/latest-product-agent-commission/${id}`,
				{
					headers: this.headers,
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};
	getAllQuestionBySubProduct = async (id) => {
		try {
			const response = await axios.get(`${apiPath}/question/${id}`, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};
	createQuestion = async (body) => {
		try {
			const response = await axios.post(
				`${apiPath}/question/create`,
				body,
				{
					headers: this.headers,
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};
	deleteQuestion = async (id) => {
		try {
			const response = await axios.post(
				`${apiPath}/question/delete/${id}`,
				{},
				{
					headers: this.headers,
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};
}
