import React, { useState, useEffect, useRef, useReducer } from "react";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { LoanApplicationService } from "../../../services/LoanApplication";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import { Button } from "primereact/button";
import SearchLoanApplication from "./components/SearchLoanApplication";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Config from "../../../shared/config";
import { LoanService } from "../../../services/LoanService";
import { ConfirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { confirmDialog } from "primereact/confirmdialog"; // To use confirmDialog method
import Loader from "../../../components/Loader";
import { Dialog } from "primereact/dialog";
import { produce } from "immer";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { Field, Form, Formik } from "formik";
import { FeildDropdown, FieldInput } from "../../../components/FieldInput";
import TableNoData from "../../../components/TableNoData";
import Paginator from "../../../components/Paginator";
import {
	searchInitialState,
	searchReducer,
} from "../../../components/searchReducer";
import {
	setApplicationState,
	setCountryApplicationId,
} from "../../../store/reducer/AuthReducer";
import MenuComponent from "../../../components/MenuComponent";
import StatusBulkUpload from "./payments/components/StatusBulkUpload/StatusBulkUpload";
import * as Yup from "yup";
const LoanApplicationList = () => {
	const leadDispatch = useDispatch();
	const loanListService = new LoanApplicationService();
	const loanService = new LoanService();
	const toast = useRef(null);
	const navigate = useNavigate();

	const [state, dispatch] = useReducer(dataReducer, fetchInitialState);

	const [spinner, setSpinner] = useState(false);
	// const [sortState, sortDispatch] = useReducer(sortReducer, sortInitialState);
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const [loading1, setLoading1] = useState(false);
	const [statusDialoge, setStatusDialoge] = useState(false);

	const [statusState, statusDispatch] = useReducer(
		produce((state, action) => {
			switch (action.type) {
				case "dialog":
					state.dialog = action.payload;
					break;
				case "id":
					state.applicationId = action.payload;
					break;
				case "setData":
					state.setData = action.payload;
					break;
				case "getHistory":
					state.getHistory = action.payload;
					break;
				case "sanctionAmount":
					state.sanctionAmount = action.payload;
					break;
				case "isLoading":
					state.isLoading = action.payload;
					break;
				case "type":
					state.type = action.payload;
					break;
				default:
					return state;
			}
		}),
		{
			dialog: false,
			setData: {},
			applicationId: null,
			getHistory: [],
			isLoading: false,
			type: "",
			sanctionAmount: "",
		}
	);
	const [statusList, setStatusList] = useReducer(
		dataReducer,
		fetchInitialState
	);

	const [lodarTblArray] = useState(Array.from({ length: 10 }));
	const getLoanData = (value) => {
		dispatch({ type: Config.FETCH_CONFIG.start });

		const body = {
			applicationType: ["LOAN", "CREDIT_CARD"],
			...searchState.searchValue,
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.sortByColumn &&
				searchState.sortType && {
					sorts: [
						searchState.sortByColumn + "," + searchState.sortType,
					],
				}),
		};
		const demo = Object.fromEntries(
			Object.entries(body).filter(([_, v]) => v !== "")
		);

		loanListService
			.getLoanApplicationList(demo)
			.then((res) =>
				dispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				})
			)
			.catch((e) => {
				dispatch({ type: Config.FETCH_CONFIG.error, payload: e });
				toast.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => dispatch({ type: Config.FETCH_CONFIG.stop }));
	};
	const handleLoack = (e) => {
		setSpinner(true);
		loanService
			.applicationLock(e)
			.then(() => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "Status updated successfully",
					style: { color: "#000000" },
					life: 3000,
				});
				getLoanData();
			})
			.catch((er) =>
				toast.current.show({
					severity: "error",
					summary: "error",
					detail: "Error",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setSpinner(false);
			});
	};
	const skeletonBody = () => {
		return <Skeleton height="1rem"></Skeleton>;
	};
	// function tblSort(columnName, sortType) {
	// 	searchDispatch({
	// 		type: Config.SEARCH_CONFIG.sortByColumn,
	// 		payload: columnName,
	// 	});
	// 	searchDispatch({
	// 		type: Config.SEARCH_CONFIG.sortType,
	// 		payload: sortType,
	// 	});
	// }
	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	// function getSortableColumn(name, columnName) {
	// 	return (
	// 		<span className="flex align-items-center">
	// 			{name}
	// 			<span className="flex flex-column text-xs ml-2">
	// 				<span
	// 					className={
	// 						searchState.sortByColumn === columnName &&
	// 						searchState.sortType === "asc"
	// 							? "sort-icon asc active"
	// 							: "sort-icon asc"
	// 					}
	// 					onClick={() => tblSort(columnName, "asc")}
	// 				></span>
	// 				<span
	// 					className={
	// 						searchState.sortByColumn === columnName &&
	// 						searchState.sortType === "desc"
	// 							? "sort-icon desc active"
	// 							: "sort-icon desc"
	// 					}
	// 					onClick={() => tblSort(columnName, "desc")}
	// 				></span>
	// 			</span>
	// 		</span>
	// 	);
	// }
	function tblSort(columnName, sortType) {
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}
	const getName = (item) => {
		let fname = item.firstName ? item.firstName : "";
		let mname = item.middleName ? item.middleName : "";
		let lname = item.lastName ? item.lastName : "";
		let name = `${fname} ${mname} ${lname}`;
		if (!item.firstName && !item.middleName && !item.lastName) {
			return item.applicantName;
		} else {
			return name;
		}
	};
	// const colGroup = [
	// 	{ header: "Rainbow Application No", field: "number" },
	// 	{ header: "Bank Application No", field: "bankApplicationNumber" },
	// 	{ header: "Alias", field: "alias" },
	// 	{ header: "Program Name", field: "programName" },
	// 	{ header: "Firstname", field: "firstName" },
	// 	{ header: "Middlename", field: "middleName" },
	// 	{ header: "Lastname", field: "lastName" },
	// 	{ header: "Mobile", field: "mobile" },

	// 	{ header: "Application State", ield: "status" },
	// 	{ header: "Application Status", field: "applicationStatus" },

	// 	// { header: "Action" },
	// 	// <Column  />
	// 	// 				<Column  />
	// 	// 				<Column field="alias" />
	// 	// 				<Column field="programName" />
	// 	// 				<Column body={getName} />
	// 	// 				<Column field="mobile" />
	// 	// 				<Column body={stateTemplate} />
	// 	// 				<Column body={statusTemplate} />
	// 	// 				<Column body={actionTemplate} />
	// ];
	// const exportColumns = colGroup.map((col) => ({
	// 	title: col.header,
	// 	dataKey: col.field,
	// }));
	const handleStatus = (e) => {
		setStatusList({ type: Config.FETCH_CONFIG.start });
		loanListService
			.getStatusByProgramId(e)
			.then((res) =>
				setStatusList({
					type: Config.FETCH_CONFIG.success,
					payload: res.map((item) => ({
						...item,

						statusName: `${item.productStatusName} (${item.name})`,
					})),
				})
			)

			.catch((e) => console.log(e))
			.finally(() => setStatusList({ type: Config.FETCH_CONFIG.stop }));
	};
	const validationSchema = Yup.object().shape({
		productStatusId: Yup.object().required("This field is required"),
		// sanctionAmount: Yup.number().required("This field is required"),
		remarks: Yup.string().required("This field is required"),
	});
	// function getSortableColumn(name, columnName) {
	// 	return (
	// 		<span className="flex align-items-center">
	// 			{name}
	// 			<span className="flex flex-column text-xs ml-2">
	// 				<span
	// 					className={
	// 						searchState.sortByColumn === columnName &&
	// 						searchState.sortType === "asc"
	// 							? "sort-icon asc active"
	// 							: "sort-icon asc"
	// 					}
	// 					onClick={() => tblSort(columnName, "asc")}
	// 				></span>
	// 				<span
	// 					className={
	// 						searchState.sortByColumn === columnName &&
	// 						searchState.sortType === "desc"
	// 							? "sort-icon desc active"
	// 							: "sort-icon desc"
	// 					}
	// 					onClick={() => tblSort(columnName, "desc")}
	// 				></span>
	// 			</span>
	// 		</span>
	// 	);
	// }
	const dateTemplate = ({ createdOn }) => {
		return createdOn ? moment(createdOn).format("YYYY-MM-DD") : createdOn;
	};
	const dateMTemplate = ({ modifiedOn }) => {
		return modifiedOn
			? moment(modifiedOn).format("YYYY-MM-DD")
			: modifiedOn;
	};
	const allocatedToTemplate = (item) => {
		let fname = item.allocatedToFirstName ? item.allocatedToFirstName : "";
		let mname = item.allocatedToMiddleName
			? item.allocatedToMiddleName
			: "";
		let lname = item.allocatedToLastName ? item.allocatedToLastName : "";
		let userName =
			item.allocatedToAgentCode !== null
				? item.allocatedToAgentCode
					? `(${item.allocatedToAgentCode})`
					: ""
				: `${item.allocatedTo ? `(${item.allocatedTo})` : ""}`;
		let name = `${fname} ${mname} ${lname} ${userName}`;

		return name;
	};
	const newData =
		state.data.data &&
		state.data.data.map((item) => ({
			...(item.firstName === null &&
			item.middleName === null &&
			item.lastName === null
				? {
						Name: item.applicantName,
				  }
				: {
						Name:
							(item.firstName ? item.firstName : "") +
							" " +
							(item.middleName ? item.middleName : "") +
							" " +
							(item.lastName ? item.lastName : ""),
				  }),
			"Mobile No": item.mobile,
			Number: item.number,
			Zone: item.zone,
			State: item.state,
			District: item.district,
			Status: item.status?.replaceAll("_", " ").toLowerCase(),
			"Agent Code": item.agentCode,
			"Program Name": item.programName,

			"Loan Amount": item.loanAmount,

			Gender: item.gender,
			"Date Of Birth": moment(item.dob).format("Do MMM, YYYY"),
			"Personal Mail": item.personalMail,

			"Created On": moment(item.createdOn).format("Do MMM, YYYY"),
			"Application Status": item.applicationStatus,
			// "subProductId":item.subProductId,

			"Bank Application Number": item.bankApplicationNumber,
		}));
	// const exportPdf = () => {
	// 	import("jspdf").then((jsPDF) => {
	// 		import("jspdf-autotable").then(() => {
	// 			const doc = new jsPDF.default(0, 0);

	// 			doc.autoTable(exportColumns, state.data.data);
	// 			doc.save(`applicationList${Date.now()}.pdf`);
	// 		});
	// 	});
	// };

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />
				<Column
					header={getSortableColumn("RF Application No.", "number")}
				/>
				<Column
					header={getSortableColumn(
						"FI Application No",
						"bankApplicationNumber"
					)}
				/>
				<Column header={"Amount"} />
				<Column header={"LOB"} />
				<Column
					header={getSortableColumn("Program Name", "programName")}
				/>
				<Column header={getSortableColumn("Name", "firstName")} />

				<Column header={"Mobile"} />
				<Column header={getSortableColumn("Created By", "createdBy")} />
				<Column header={getSortableColumn("Created On", "createdOn")} />
				<Column header={getSortableColumn("Zone", "z.name")} />
				<Column header={getSortableColumn("State", "s.name")} />
				<Column header={getSortableColumn("District", "d.name")} />
				<Column
					header={getSortableColumn("Updated On", "modifiedOn")}
				/>
				<Column
					header={getSortableColumn("Allocated To", "allocatedTo")}
				/>
				{/* <Column header={getSortableColumn("Agent Code", "agentCode")} /> */}
				<Column header={getSortableColumn("RFA Status", "status")} />
				<Column
					header={getSortableColumn("FI Status", "applicationStatus")}
				/>

				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const applicationStatusUpdate = (data, type) => {
		// console.log(data);
		statusDispatch({
			type: "dialog",
			payload: true,
		});
		statusDispatch({
			type: "type",
			payload: type,
		});
		statusDispatch({
			type: "id",
			payload: data.id,
		});
		if (type === "status") {
			statusDispatch({ type: "isLoading", payload: true });
			handleStatus(data.programId);

			loanListService
				.getApplicationStatus(data.id)
				.then((res) => {
					statusDispatch({ type: "getHistory", payload: res });
					statusDispatch({
						type: "sanctionAmount",
						payload: res[0]?.sanctionAmount
							? res[0]?.sanctionAmount
							: null,
					});
				})
				.catch(() => {
					toast.current.show({
						severity: "error",
						summary: "error",
						detail: "Unable to get Status history",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => {
					statusDispatch({ type: "isLoading", payload: false });
				});
		}
	};

	const confirm = (e) => {
		confirmDialog({
			message: "Are you sure you want to proceed?",
			header: "Confirmation",
			icon: "pi pi-exclamation-triangle",
			accept: () => handleLoack(e),
			reject: () => {},
		});
	};
	const actionTemplate = (data) => {
		// const items = [
		// 	...(data.status !== "LOCK"
		// 		? [
		// 				...(data.status !== "CUSTOMER_APPROVED"
		// 					? [
		// 							{
		// 								label: "Edit",
		// 								icon: "pi pi-pencil",
		// 								command: () => {
		// 									leadDispatch(
		// 										setApplicationId(data.id)
		// 									);
		// 									leadDispatch(setStatus("draft"));
		// 									data.status === "CUSTOMER_DRAFT" &&
		// 										leadDispatch(
		// 											setStatus("CUSTOMER_DRAFT")
		// 										);
		// 									leadDispatch(
		// 										setname("applicationList")
		// 									);
		// 									leadDispatch(setType("credit"));
		// 									leadDispatch(setAlias(data.alias));
		// 									leadDispatch(
		// 										setSubproductId(
		// 											data.subProductId
		// 										)
		// 									);
		// 									leadDispatch(setTypeName("edit"));
		// 									if (data.alias === "HC") {
		// 										navigate(
		// 											`/masters/hospicash/edit/${data.id}`
		// 										);
		// 									} else {
		// 										navigate(
		// 											"/masters/loan_application"
		// 										);
		// 									}
		// 								},
		// 							},
		// 					  ]
		// 					: [
		// 							{
		// 								label: "Lock",
		// 								icon: "pi pi-lock",
		// 								command: () => {
		// 									confirm(data.id);
		// 								},
		// 							},
		// 							{
		// 								label: "Preview",
		// 								icon: "pi pi-info-circle",
		// 								command: () => {
		// 									leadDispatch(
		// 										setname("applicationList")
		// 									);
		// 									leadDispatch(setStatus("lock"));
		// 									leadDispatch(setType("credit"));
		// 									leadDispatch(
		// 										setApplicationId(data.id)
		// 									);
		// 									leadDispatch(
		// 										setSubproductId(
		// 											data.subProductId
		// 										)
		// 									);
		// 									leadDispatch(setAlias(data.alias));
		// 									leadDispatch(setTypeName("edit"));
		// 									if (data.alias === "HC") {
		// 										navigate(
		// 											`/masters/hospicash/preview/${data.id}`
		// 										);
		// 									} else {
		// 										navigate(
		// 											"/masters/loan_application"
		// 										);
		// 									}
		// 								},
		// 							},
		// 					  ]),
		// 		  ]
		// 		: [
		// 				{
		// 					label: "Preview",
		// 					icon: "pi pi-info-circle",
		// 					command: () => {
		// 						leadDispatch(setname("applicationList"));
		// 						leadDispatch(setStatus("lock"));
		// 						leadDispatch(setType("credit"));
		// 						leadDispatch(setApplicationId(data.id));
		// 						leadDispatch(
		// 							setSubproductId(data.subProductId)
		// 						);
		// 						leadDispatch(setAlias(data.alias));
		// 						leadDispatch(setTypeName("edit"));
		// 						if (data.alias === "HC") {
		// 							navigate(
		// 								`/masters/hospicash/preview/${data.id}`
		// 							);
		// 						} else {
		// 							navigate("/masters/loan_application");
		// 						}
		// 					},
		// 				},
		// 				{
		// 					label: "Update Status",
		// 					icon: "pi pi-check",
		// 					command: () => {
		// 						applicationStatusUpdate(data, "status");
		// 					},
		// 				},
		// 				{
		// 					label: "Bank Application number",
		// 					icon: "pi pi-verified",
		// 					command: () => {
		// 						applicationStatusUpdate(data, "bank");
		// 					},
		// 				},
		// 		  ]),
		// ];
		const items = [
			{
				label: "Preview",
				icon: "pi pi-info-circle",
				command: () => {
					leadDispatch(setCountryApplicationId(null));
					leadDispatch(setApplicationState("preview"));
					navigate("/masters/applicationcreate", {
						state: {
							alias: data.alias,
							applicationId: data.id,
							subproductId: data.subProductId,
						},
					});
				},
			},
		];
		if (
			data.status !== "LOCK" &&
			data.status !== "LOCK_APPROVED_BY_FI" &&
			data.status !== "LOCK_DISBURSED_BY_FI" &&
			data.status !== "LOCK_REJECTED_BY_FI"
		) {
			items.push(
				...[
					{
						label: "Edit",
						icon: "pi pi-pencil",
						command: () => {
							leadDispatch(setCountryApplicationId(null));
							leadDispatch(setApplicationState("edit"));
							navigate("/masters/applicationcreate", {
								state: {
									alias: data.alias,
									applicationId: data.id,
									subproductId: data.subProductId,
								},
							});
						},
					},
					{
						label: "Lock",
						icon: "pi pi-lock",
						command: () => {
							confirm(data.id);
						},
					},
				]
			);
		}
		// if (data.status === "CUSTOMER_APPROVED") {
		// 	items.push({
		// 		label: "Lock",
		// 		icon: "pi pi-lock",
		// 		command: () => {
		// 			confirm(data.id);
		// 		},
		// 	});
		// }
		if (data.status === "LOCK") {
			items.push(
				...[
					{
						label: "Update Status",
						icon: "pi pi-check",
						command: () => {
							applicationStatusUpdate(data, "status");
						},
					},
					{
						label: "Bank Application number",
						icon: "pi pi-verified",
						command: () => {
							applicationStatusUpdate(data, "bank");
						},
					},
				]
			);
		}
		return (
			// <div className="flex gap-1">
			// 	{data.status !== "LOCK" ? (
			// 		<>
			// 			{data.status !== "CUSTOMER_APPROVED" ? (
			// 				<Button
			// 					onClick={() => {
			// 						leadDispatch(setApplicationId(data.id));
			// 						leadDispatch(setStatus("draft"));
			// 						data.status === "CUSTOMER_DRAFT" &&
			// 							leadDispatch(
			// 								setStatus("CUSTOMER_DRAFT")
			// 							);
			// 						leadDispatch(setname("applicationList"));
			// 						leadDispatch(setType("credit"));
			// 						leadDispatch(setAlias(data.alias));
			// 						leadDispatch(
			// 							setSubproductId(data.subProductId)
			// 						);
			// 						leadDispatch(setTypeName("edit"));
			// 						if (data.alias === "HC") {
			// 							navigate(
			// 								`/masters/hospicash/edit/${data.id}`
			// 							);
			// 						} else {
			// 							navigate("/masters/loan_application");
			// 						}
			// 					}}
			// 					className="icon-btn primary-btn mr-2"
			// 					tooltip="Edit Loan Application"
			// 					tooltipOptions={{ position: "bottom" }}
			// 				>
			// 					<MdModeEditOutline />
			// 				</Button>
			// 			) : (
			// 				<>
			// 					<Button
			// 						className="icon-btn p-button-danger"
			// 						onClick={() => confirm(data.id)}
			// 						tooltip="Lock"
			// 						tooltipOptions={{ position: "bottom" }}
			// 					>
			// 						<MdLockOutline />
			// 					</Button>
			// 					<Button
			// 						className="icon-btn p-button-info"
			// 						onClick={() => {
			// 							leadDispatch(
			// 								setname("applicationList")
			// 							);
			// 							leadDispatch(setStatus("lock"));
			// 							leadDispatch(setType("credit"));
			// 							leadDispatch(setApplicationId(data.id));
			// 							leadDispatch(
			// 								setSubproductId(data.subProductId)
			// 							);
			// 							leadDispatch(setAlias(data.alias));
			// 							leadDispatch(setTypeName("edit"));
			// 							if (data.alias === "HC") {
			// 								navigate(
			// 									`/masters/hospicash/preview/${data.id}`
			// 								);
			// 							} else {
			// 								navigate(
			// 									"/masters/loan_application"
			// 								);
			// 							}
			// 						}}
			// 						tooltip="Preview"
			// 						tooltipOptions={{ position: "bottom" }}
			// 					>
			// 						<MdOutlinePreview />
			// 					</Button>
			// 				</>
			// 			)}
			// 		</>
			// 	) : (
			// 		<>
			// 			<Button
			// 				className="icon-btn p-button-info"
			// 				onClick={() => {
			// 					leadDispatch(setname("applicationList"));
			// 					leadDispatch(setStatus("lock"));
			// 					leadDispatch(setType("credit"));
			// 					leadDispatch(setApplicationId(data.id));
			// 					leadDispatch(
			// 						setSubproductId(data.subProductId)
			// 					);
			// 					leadDispatch(setAlias(data.alias));
			// 					leadDispatch(setTypeName("edit"));
			// 					if (data.alias === "HC") {
			// 						navigate(
			// 							`/masters/hospicash/preview/${data.id}`
			// 						);
			// 					} else {
			// 						navigate("/masters/loan_application");
			// 					}
			// 				}}
			// 				tooltip="Preview"
			// 				tooltipOptions={{ position: "bottom" }}
			// 			>
			// 				<MdOutlinePreview />
			// 			</Button>
			// 			<Button
			// 				className="icon-btn p-button-success"
			// 				tooltip="Update Status"
			// 				tooltipOptions={{ position: "bottom" }}
			// 				onClick={() => {
			// 					applicationStatusUpdate(data, "status");
			// 				}}
			// 			>
			// 				<MdOutlineCheckCircle />
			// 			</Button>
			// 			<Button
			// 				className="icon-btn p-button-info"
			// 				tooltip="Bank Application number"
			// 				tooltipOptions={{ position: "left" }}
			// 				onClick={() => {
			// 					applicationStatusUpdate(data, "bank");
			// 				}}
			// 			>
			// 				<i className="pi pi-verified"></i>
			// 			</Button>
			// 		</>
			// 	)}
			// </div>
			<MenuComponent items={items} />
		);
	};

	useEffect(() => {
		getLoanData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);
	const handleStatusSave = (value) => {
		setLoading1(true);
		// const body = {
		// 	applicationId: statusState.applicationId,
		// 	productStatusId: statusState.setData,
		// };
		const body = {
			applicationId: statusState.applicationId,
			productStatusId: value.productStatusId?.id,
			sanctionAmount: value.sanctionAmount,
			remarks: value.remarks,
		};
		// console.log(body);
		loanListService
			.saveStatus(body)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch(() =>
				toast.current.show({
					severity: "error",
					summary: "error",
					detail: "Error",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoading1(false);
				statusDispatch({ type: "dialog", payload: false });
				getLoanData();
			});
	};
	const stateTemplate = (item) => {
		switch (item.status) {
			case "CUSTOMER_DRAFT":
				return (
					<span className="status status-warning">
						Customer Draft
					</span>
				);

			case "DRAFT":
				return <span className="status status-warning">Draft</span>;

			case "PENDING_FOR_CUSTOMER":
				return (
					<span className="status status-primary">
						Pending For Customer
					</span>
				);
			case "CUSTOMER_APPROVED":
				return (
					<span className="status status-success">
						Customer Approved
					</span>
				);
			case "LOCK":
				return (
					<span className="status text-white  bg-orange-500">
						Lock
					</span>
				);
			case "LOCK_REJECTED_BY_FI":
				return (
					<span className="status text-white  bg-orange-500">
						Lock
					</span>
				);
			case "LOCK_DISBURSED_BY_FI":
				return (
					<span className="status text-white  bg-orange-500">
						Lock
					</span>
				);
			case "LOCK_APPROVED_BY_FI":
				return (
					<span className="status text-white  bg-orange-500">
						Lock
					</span>
				);

			default:
				break;
		}
	};
	const statusTemplate = (item) => {
		return (
			<>
				{item.applicationStatus && (
					<span className="status status-primary">
						{item.applicationStatus}
					</span>
				)}
			</>
		);
	};
	const statusHistoryTemplate = (item) => {
		return (
			<>
				{item.productStatusName && (
					<span className="status status-primary p-1">
						{item.productStatusName}
					</span>
				)}
			</>
		);
	};
	const handleBankUpdate = (value) => {
		statusDispatch({ type: "isLoading", payload: true });
		loanListService
			.saveBankApplication(value)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "error",
					detail: "Unable to update",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				statusDispatch({ type: "isLoading", payload: false });
				statusDispatch({
					type: "dialog",
					payload: false,
				});
				// searchDispatch({ type: Config.SEARCH_CONFIG.reset });
				getLoanData();
			});
	};

	const handleStatusUpload = () => {
		setStatusDialoge(true);
	};

	const handleStatusDialogeClose = () => {
		setStatusDialoge(false);
	};
	const createdByTemplate = (item) => {
		let fname = item.createdByFirstName ? item.createdByFirstName : "";
		let mname = item.createdByMiddleName ? item.createdByMiddleName : "";
		let lname = item.createdByLastName ? item.createdByLastName : "";
		// const userName = item.createdBy ? `(${item.createdBy})` : "";

		const userName =
			item.agentCode !== null
				? item.agentCode
					? `(${item.agentCode})`
					: ""
				: item.createdBy
				? `(${item.createdBy})`
				: "";

		let name = `${fname} ${mname} ${lname} ${userName}`;
		return name;
	};
	const gLoader = (e) => {
		setSpinner(e);
	};
	return (
		<>
			<SearchLoanApplication
				// exportPdf={exportPdf}
				searchDispatch={searchDispatch}
				newData={newData}
				handleStatusUpload={handleStatusUpload}
				loader={gLoader}
			/>
			<ConfirmDialog />
			<Dialog
				visible={statusDialoge}
				header="Bank Application Bulk Status Upload"
				onHide={() => setStatusDialoge(false)}
				style={{ width: "80%" }}
			>
				<StatusBulkUpload
					handleStatusDialogeClose={handleStatusDialogeClose}
				/>
			</Dialog>
			<Dialog
				visible={statusState.dialog}
				style={{ width: "80%" }}
				header={(function (e) {
					switch (e) {
						case "status":
							return "Application Status Update";
						case "bank":
							return "Bank Status Update";

						default:
							return "";
					}
				})(statusState.type)}
				onHide={() =>
					statusDispatch({ type: "dialog", payload: false })
				}
			>
				{(function (e) {
					switch (e) {
						case "status":
							return (
								<div className=" modal-form-grid">
									<div className="py-4">
										{statusList.isLoading ? (
											<Skeleton className="w-5  block h-3rem" />
										) : (
											// <>
											// 	<Dropdown
											// 		value={statusState.setData}
											// 		onChange={(e) =>
											// 			statusDispatch({
											// 				type: "setData",
											// 				payload: e.value,
											// 			})
											// 		}
											// 		className="w-5 border-round-md"
											// 		options={statusList.data}
											// 		optionLabel="statusName"
											// 		placeholder="Select Status"
											// 	/>

											// 	<Button
											// 		className="ml-2"
											// 		label="Save"
											// 		icon="pi pi-save"
											// 		// disabled={
											// 		// 	statusState.setData &&
											// 		// 	statusState.setData ===
											// 		// 		2159
											// 		// 		? true
											// 		// 		: false
											// 		// }
											// 		onClick={handleStatusSave}
											// 		loading={loading1}
											// 	/>
											// </>
											<Formik
												initialValues={{
													productStatusId: "",
													sanctionAmount:
														statusState.sanctionAmount,
													remarks: "",
												}}
												onSubmit={handleStatusSave}
												validationSchema={
													validationSchema
												}
											>
												{({
													handleSubmit,
													resetForm,
												}) => (
													<Form
														onSubmit={handleSubmit}
														className="grid form-grid col-12"
													>
														<div className="field col-12 md:col-3">
															<Field
																header={
																	"Select Status"
																}
																name={
																	"productStatusId"
																}
																component={
																	FeildDropdown
																}
																options={
																	statusList.data
																}
																optionLabel="statusName"
															/>
														</div>
														<div className="field col-12 md:col-3">
															<Field
																type="number"
																header={
																	"Sanction Amount"
																}
																name={
																	"sanctionAmount"
																}
																component={
																	FieldInput
																}
															/>
														</div>
														<div className="field col-12 md:col-3">
															<Field
																header={
																	"Remarks"
																}
																name={"remarks"}
																component={
																	FieldInput
																}
															/>
														</div>
														{/* <Dropdown
															value={
																statusState.setData
															}
															onChange={(e) =>
																statusDispatch({
																	type: "setData",
																	payload:
																		e.value,
																})
															}
															className="w-5 border-round-md"
															options={
																statusList.data
															}
															optionLabel="statusName"
															placeholder="Select Status"
														/> */}
														{/* <InputText
															type="number"
															value={
																statusState.sanctionAmount
															}
															onChange={(e) =>
																statusDispatch({
																	type: "sanctionAmount",
																	payload:
																		e.value,
																})
															}
															placeholder="Sanction Amount"
														/>
														<InputText
															value={
																statusState.sanctionAmount
															}
															required
															onChange={(e) =>
																statusDispatch({
																	type: "sanctionAmount",
																	payload:
																		e.value,
																})
															}
															placeholder="Remark"
														/> */}
														<div className="flex justify-content-end align-items-center col-12 md:col-3">
															<Button
																type="button"
																label="Reset"
																className="p-button-danger mr-2"
																onClick={
																	resetForm
																}
															/>
															<Button
																label="Save"
																type="submit"
																icon="pi pi-save"
																loading={
																	loading1
																}
															/>
														</div>
													</Form>
												)}
											</Formik>
										)}
									</div>

									{statusState.isLoading ? (
										<DataTable
											value={statusState.getHistory}
											responsiveLayout={"scroll"}
										>
											<Column
												body={skeletonBody}
												header="Application Number"
											/>
											<Column
												body={skeletonBody}
												header={"Status History"}
											/>
											<Column
												body={skeletonBody}
												header={"Remarks"}
											/>
											<Column
												body={skeletonBody}
												header="Created On"
											/>
										</DataTable>
									) : (
										<>
											<DataTable
												paginator
												rows={10}
												value={statusState.getHistory}
												responsiveLayout={"scroll"}
											>
												<Column
													field="applicationNumber"
													header="Application Number"
												/>
												<Column
													body={statusHistoryTemplate}
													header={"Status History"}
												/>

												<Column
													field="remarks"
													header={"Remarks"}
												/>
												<Column
													header="Created On"
													body={(item) =>
														moment(
															item.createdOn
														).format(
															"Do MMM, HH:mm A"
														)
													}
												/>
											</DataTable>
										</>
									)}
								</div>
							);
						case "bank":
							return (
								<>
									<Formik
										initialValues={{
											applicationId:
												statusState.applicationId,
											bankApplicationNumber: "",
										}}
										onSubmit={handleBankUpdate}
										enableReinitialize
									>
										{({ values, handleSubmit }) => (
											<Form
												className=" py-4 modal-form-grid gap-2 flex "
												onSubmit={handleSubmit}
											>
												<Field
													name="bankApplicationNumber"
													placeholder="Bank Application Number"
													component={FieldInput}
												/>

												<Button
													loading={
														statusState.isLoading
													}
													disabled={
														values.bankApplicationNumber ===
														""
															? true
															: false
													}
													label="Save"
													icon="pi pi-save"
													type={"submit"}
												/>
											</Form>
										)}
									</Formik>
								</>
							);

						default:
							return <></>;
					}
				})(statusState.type)}
			</Dialog>
			{spinner && <Loader />}

			<Toast ref={toast} />
			{/* <div className="col-12 flex align-items-center justify-content-between ">
					<h3 className="m-0">Loan Application List</h3>
					<Button label="reset" onClick={() => getLoanData()} />
				</div> */}

			{state.isLoading ? (
				<DataTable
					headerColumnGroup={headerGroup}
					value={lodarTblArray}
					responsiveLayout="scroll"
					breakpoint="960px"
				>
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
					<Column body={skeletonBody} />
				</DataTable>
			) : state.data.data && state.data.data.length > 0 ? (
				<>
					<Paginator
						pageSize={state.data.pageSize}
						firstPage={state.data.firstPage}
						lastPage={state.data.lastPage}
						decrement={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.decrement,
							})
						}
						increment={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.increment,
							})
						}
						totalElements={state.data.totalElements}
						pagesizechange={(e) => {
							// let newPageNo = Math.ceil(
							// 	(state.data.pageSize * state.data.pageNo) /
							// 		e.target.value
							// );
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageSize,
								payload: e.target.value,
							});
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageNo,
								payload: 1,
							});
						}}
						pageNo={state.data.pageNo}
						totalPages={state.data.totalPages}
					/>
					<DataTable
						headerColumnGroup={headerGroup}
						value={
							state.data.data &&
							state.data.data.map((item, index) => ({
								...item,
								index:
									state.data.pageSize * state.data.pageNo -
									state.data.pageSize +
									1 +
									index,
							}))
						}
						responsiveLayout="scroll"
						breakpoint="960px"
					>
						<Column field="index" />
						<Column field="number" />
						<Column field={"bankApplicationNumber"} />
						<Column
							field={"loanAmount"}
							body={(item) =>
								item.loanAmount ? item.loanAmount : ""
							}
						/>
						<Column field="alias" />
						<Column field="programName" />
						<Column body={getName} />
						<Column field="mobile" />
						<Column body={createdByTemplate} />
						<Column body={dateTemplate} />
						<Column field="zone" />
						<Column field="state" />
						<Column field="district" />
						<Column body={dateMTemplate} />
						<Column body={allocatedToTemplate} />
						<Column body={stateTemplate} />
						<Column body={statusTemplate} />
						<Column body={actionTemplate} />
					</DataTable>
					<Paginator
						pageSize={state.data.pageSize}
						firstPage={state.data.firstPage}
						lastPage={state.data.lastPage}
						decrement={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.decrement,
							})
						}
						increment={() =>
							searchDispatch({
								type: Config.SEARCH_CONFIG.increment,
							})
						}
						totalElements={state.data.totalElements}
						pagesizechange={(e) => {
							// let newPageNo = Math.ceil(
							// 	(state.data.pageSize * state.data.pageNo) /
							// 		e.target.value
							// );
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageSize,
								payload: e.target.value,
							});
							searchDispatch({
								type: Config.SEARCH_CONFIG.pageNo,
								payload: 1,
							});
						}}
						pageNo={state.data.pageNo}
						totalPages={state.data.totalPages}
					/>
				</>
			) : (
				<TableNoData headerGroup={headerGroup} />
			)}
		</>
	);
};

export default LoanApplicationList;
