import { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConnectedFocusError } from "focus-formik-error";
import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
	FieldMultiselect,
	FieldRadio,
	FieldTextArea,
} from "../../../../../components/FieldInput";
import Config from "../../../../../shared/config";
import moment from "moment";
import selectState from "../../../../../components/stateList";
import { useGetAllDIstrictQuery } from "../../../../../services/query/queryApi";
import { LoanApplicationService } from "../../../../../services/LoanApplication";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../../components/fetchReducer";
import { LoanService } from "../../../../../services/LoanService";
import { Divider } from "primereact/divider";
import DocumentUpload from "../DocumentUpload";
import {
	convertNullToString,
	convertObjNull,
} from "../../../../../utils/UtilsFunction";
import Loader from "../../../../../components/Loader";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import PreviewPdf from "../PreviewApplication";
import { setCountryApplicationId } from "../../../../../store/reducer/AuthReducer";
import Header from "../../../../partners/institutions/header";

const LIinsurance = (props) => {
	const navigate = useNavigate();
	const [checkboxState, setCheckBox] = useState(false);
	const [leaddetailsstate, leadDetailsDIspatch] = useReducer(dataReducer, {
		data: {},
		isLoading: false,
		error: false,
		errorMessage: null,
		isFetched: false,
	});
	const selector = useSelector((state) => state.persistData);
	const [allReason, setAllReason] = useState([]);
	const [allDisease, setAllDisease] = useState([]);
	const [loader, setLoader] = useState(true);
	const [printDialog, setPrintDialog] = useState(false);
	const dispatch = useDispatch();
	const {
		data: getAllDistrict = [
			{ districtList: [{ id: null, name: "" }], id: null, name: "" },
		],
	} = useGetAllDIstrictQuery();
	const toastTL = useRef(null);
	const [formState, formDispatch] = useReducer(dataReducer, {
		data: {},
		isLoading: false,
		error: false,
		errorMessage: null,
		isFetched: false,
	});
	const loanApplicationService = new LoanApplicationService();
	const loanService = new LoanService();
	const getApplicationByMobile = () => {
		setLoader(true);
		loanApplicationService
			.getLoanApplicationCreateFullList(props?.locationData?.mobileNumber)
			.then((res) => {
				leadDetailsDIspatch({
					type: Config.FETCH_CONFIG.success,
					payload: convertNullToString(res),
				});
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoader(false));
	};

	useEffect(() => {
		Promise.all([
			loanApplicationService.getReason(props.locationData.subproductId),
			props?.locationData?.mobileNumber && getApplicationByMobile(),
			loanApplicationService.getDisease(),
			(props.locationData.applicationId ||
				selector.countryApplicationId) &&
				loanApplicationService.getInsuranceLoanApplicationEditList(
					props.locationData.applicationId
						? props.locationData.applicationId
						: selector.countryApplicationId
				),
		])
			.then((response) => {
				const reasonResponse = response[0];
				const diseaseResponse = response[2];
				const data = reasonResponse.map((e) => {
					return { ...e, value: e.id };
				});
				setAllReason([...data, { reason: "other", value: 0 }]);
				setAllDisease(diseaseResponse);
				response[3] &&
					formDispatch({
						type: Config.FETCH_CONFIG.success,
						payload: response[3],
					});
			})
			.catch((error) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoader(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const insuranceInitialValue = {
		firstName: "",
		middleName: "",
		lastName: "",

		alternativeMobile: "",
		dob: "",
		isSmoke: false,
		isChewTobacco: false,
		isAlcohol: false,
		gender: "",
		maritalStatus: "",
		spouseName: "",
		fatherName: "",
		motherMaidenName: "",
		height: "",
		weight: "",
		addressLine1: "",
		pincode: "",

		preferredMailingAddress: "",
		highestQualification: "",
		occupationType: "",
		designation: "",
		personalMail: "",
		companyType: "",
		annualIncome: "",
		workingPeriod: "",
		policyDetails: "",
		otherReason: "",

		proposerFirstName: "",
		proposerMiddleName: "",
		proposerLastName: "",
		proposerMobile: "",
		proposerDob: "",
		isProposerSmoke: false,
		isProposerChewTobacco: false,
		proposerGender: "",
		proposerMaritalStatus: "",
		proposerSpouseName: "",
		proposerMotherMaidenName: "",
		proposerHeight: "",
		proposerWeight: "",
		proposerLandMark: "",
		proposerQualification: "",
		proposerOccupation: "",
		proposerDesignation: "",
		proposerEmail: "",
		proposerCompanyType: "",
		proposerAnnualIncome: "",
		proposerWorkingPeriod: "",

		nomineeName: "",
		nomineeDob: "",
		nomineeGender: "",
		relationshipWithPrimaryInsured: "",
		appointeeName: "",
		relationshipWithNominee: "",

		familyFather: "",
		familyMother: "",
		familySpouse: "",
		familySon: "",
		familyDaughter: "",
		familyAge: "",
		familyStatus: "",

		paymentFrequency: "",
		premium: "",
		premiumPayingTerm: "",
		policyTerm: "",
		diseaseList: [],

		...(!props.locationData?.applicationId && {
			...leaddetailsstate.data,
			dob: Boolean(leaddetailsstate.data?.dob)
				? new Date(leaddetailsstate.data?.dob)
				: "",
			proposerDob: Boolean(leaddetailsstate.data?.proposerDob)
				? new Date(leaddetailsstate.data?.proposerDob)
				: "",
			nomineeDob: Boolean(leaddetailsstate.data?.nomineeDob)
				? new Date(leaddetailsstate.data?.nomineeDob)
				: "",
		}),
		state: props?.locationData?.state,
		district: props?.locationData?.district,
		pan: props?.locationData?.panNumber,
		mobile: props?.locationData?.mobileNumber,
		leadId: props?.locationData?.leadId ? props?.locationData?.leadId : "",
		...((props.locationData?.applicationId ||
			selector.countryApplicationId) && {
			...formState?.data,
			dob: Boolean(formState.data?.dob)
				? new Date(moment(formState.data?.dob).format())
				: "",
			proposerDob: Boolean(formState.data?.proposerDob)
				? new Date(moment(formState.data?.proposerDob).format())
				: "",
			nomineeDob: Boolean(formState.data?.nomineeDob)
				? new Date(moment(formState.data?.nomineeDob).format())
				: "",
		}),
	};
	const getDistrictData = (e) => {
		const stateData = getAllDistrict.find((item) => item.id === e);
		if (stateData !== undefined) {
			const districtData = stateData.districtList.map((e) => ({
				...e,
				value: e.id,
			}));
			return districtData;
		} else {
			return [];
		}
	};
	const getData = (applicationId) => {
		loanApplicationService
			.getInsuranceLoanApplicationEditList(applicationId)
			.then((res) => {
				const data = convertNullToString(res);
				console.log(data);
				formDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: data,
				});
			})

			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				formDispatch({ type: Config.FETCH_CONFIG.stop });
			});
	};

	const handleFormSubmit = (value) => {
		const reqBody = {
			...value,
			// applicationType: "LOAN",
			dob: Boolean(value.dob)
				? moment(value.dob).format("YYYY-MM-DD")
				: null,
			proposerDob: Boolean(value?.proposerDob)
				? new Date(moment(value?.proposerDob).format("YYYY-MM-DD"))
				: null,
			nomineeDob: Boolean(value?.nomineeDob)
				? new Date(moment(value?.nomineeDob).format("YYYY-MM-DD"))
				: null,
			otherReason: value.reasonId !== 0 ? null : value.otherReason,
		};

		const param = props.locationData.applicationId
			? reqBody
			: {
					...reqBody,
					program: { id: props.locationData?.programId },
					...(selector.countryApplicationId && {
						id: selector.countryApplicationId,
					}),
			  };

		const data = convertObjNull(param);
		setLoader(true);
		loanService
			.insuranceSaveDraft(data)
			.then((res) => {
				!props.locationData?.applicationId &&
					!selector.countryApplicationId &&
					dispatch(setCountryApplicationId(res.id));
				props.locationData?.applicationId
					? getData(props.locationData?.applicationId)
					: selector.countryApplicationId
					? getData(selector.countryApplicationId)
					: getData(res.id);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail:
						props.locationData?.applicationId ||
						selector.countryApplicationId
							? "Application updated"
							: "Application saved",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoader(false);
			});
	};

	const finalSubmit = (applicationId) => {
		setLoader(true);

		loanService
			.insuranceApplicationSubmit(applicationId)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});

				navigate("/masters/insurance-application-list");
			})

			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoader(false);
			});
	};

	const validationSchema = Yup.object().shape({
		otherReason: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid reason name"
			)
			.nullable(),
		mobile: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable()
			.required("This field is required"),
		firstName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid first name"
			)
			.nullable()
			.required("This field is required"),
		state: Yup.number().nullable().required("State is required"),

		district: Yup.number().nullable().required("District is required"),
		lastName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid last name"
			)
			.nullable()
			.required("This field is required"),

		proposerMobile: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable()
			.required("This field is required"),
		proposerFirstName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid first name"
			)
			.nullable()
			.required("This field is required"),
		proposerLastName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid last name"
			)
			.nullable()
			.required("This field is required"),
	});

	return (
		<>
			{loader && <Loader />}
			<Toast ref={toastTL} position="top-left" />
			<Dialog
				visible={printDialog}
				style={{ width: "80%" }}
				onHide={() => setPrintDialog(false)}
			>
				{formState.data?.salutation && (
					<PreviewPdf pdfState={formState.data} />
				)}
			</Dialog>
			<Button
				label="back"
				className="mb-2"
				icon={"pi pi-arrow-left"}
				onClick={() => navigate("/masters/insurance-application-list")}
			/>
			<Header
				stages={[]}
				title="Application"
				stageName={
					props?.locationData?.programName
						? props?.locationData?.programName
						: formState?.data?.program?.name
				}
			/>

			<Formik
				onSubmit={handleFormSubmit}
				initialValues={insuranceInitialValue}
				validationSchema={validationSchema}
				enableReinitialize
			>
				{({ values, handleChange, handleSubmit, setFieldValue }) => (
					<Form
						className="py-5"
						onSubmit={handleSubmit}
						autoComplete="off"
					>
						<ConnectedFocusError />

						<Panel
							header={<h3 className="m-0">Basic Details</h3>}
							className={"my-2"}
							toggleable
						>
							<div className="form-grid grid">
								<div className="field col-12 md:col-4">
									<Field
										header={"PAN"}
										name={"pan"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Purpose of Insurance"}
										name={"reasonId"}
										filter
										component={FeildDropdown}
										options={allReason}
										optionLabel="reason"
									/>
								</div>
								{values.reasonId === 0 && (
									<div className="field col-12 md:col-4">
										<Field
											header={"Other Reason"}
											name={"otherReason"}
											component={FieldInput}
										/>
									</div>
								)}
								<div className="col-12 flex align-items-end justify-content-end">
									{selector.applicationState !==
										"preview" && (
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									)}
								</div>
							</div>
						</Panel>

						<Panel
							header={<h3 className="m-0">Insurer Details</h3>}
							className={"my-2"}
							toggleable
						>
							<div className="form-grid grid">
								<div className="field col-12 md:col-4">
									<div className="grid">
										<div className="field col-12 md:col-4">
											<Field
												header={"Salutation"}
												name={"salutation"}
												component={FeildDropdown}
												options={Config.SALUTATION}
											/>
										</div>
										<div className="field col-12 md:col-8">
											<Field
												header={"First Name"}
												name={"firstName"}
												component={FieldInput}
											/>
										</div>
									</div>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Middle Name"}
										name={"middleName"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Last Name"}
										name={"lastName"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Mobile Number"}
										name={"mobile"}
										component={FieldInput}
										type={"number"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"AlternativeMobile No"}
										name={"alternativeMobile"}
										component={FieldInput}
										type={"number"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Email"}
										name={"personalMail"}
										component={FieldInput}
										type={"email"}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<div className="grid">
										<div className="field col-12 md:col-6">
											<Field
												header={"Date Of Birth"}
												name={"dob"}
												component={FeildCalender}
											/>
										</div>
										<div className="field col-12 md:col-6">
											<Field
												header={"Gender"}
												name={"gender"}
												component={FeildDropdown}
												options={Config.GENDERS}
												optionLabel={"name"}
											/>
										</div>
									</div>
								</div>

								<div className="field col-12 md:col-4">
									<div className="grid">
										<div className="field col-12 md:col-4 flex flex-row align-items-center">
											<label
												className={
													"block text-sm mr-2 mb-0	"
												}
											>
												Smoke
											</label>
											<Checkbox
												name="isSmoke"
												onChange={handleChange}
												checked={values.isSmoke}
												value={values.isSmoke}
											/>
										</div>
										<div className="field col-12 md:col-4 flex flex-row align-items-center">
											<label
												className={
													"block text-sm mr-2 mb-0	"
												}
											>
												Chew Tabacco
											</label>
											<Checkbox
												name="isChewTobacco"
												onChange={handleChange}
												checked={values.isChewTobacco}
												value={values.isChewTobacco}
											/>
										</div>
										<div className="field col-12 md:col-4 flex flex-row align-items-center">
											<label
												className={
													"block text-sm mr-2 mb-0	"
												}
											>
												Alcohol
											</label>
											<Checkbox
												name="isAlcohol"
												onChange={handleChange}
												checked={values.isAlcohol}
												value={values.isAlcohol}
											/>
										</div>
									</div>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Marital Status"}
										name={"maritalStatus"}
										component={FeildDropdown}
										options={Config.MARITAL_STATUS}
									/>
								</div>

								{values.maritalStatus === "Married" && (
									<div className="field col-12 md:col-4">
										<Field
											header={"Spouse’s Full Name"}
											name={"spouseName"}
											component={FieldInput}
										/>
									</div>
								)}

								<div className="field col-12 md:col-4">
									<Field
										header={"Father’s Name"}
										name={"fatherName"}
										component={FieldInput}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Mother’s Name"}
										name={"motherMaidenName"}
										component={FieldInput}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Height (Inch)"}
										name={"height"}
										component={FieldInput}
										type={"number"}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Weight (Kg)"}
										name={"weight"}
										component={FieldInput}
										type={"number"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Address Line 1"}
										name={"addressLine1"}
										component={FieldTextArea}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"State"}
										name={"state"}
										filter
										component={FeildDropdown}
										options={selectState(getAllDistrict)}
										optionLabel={"name"}
									/>
								</div>
								{values.state && (
									<div className="field col-12 md:col-4">
										<Field
											header={"District"}
											name={"district"}
											filter
											component={FeildDropdown}
											// options={
											// 	districtListState.districtList
											// }
											options={getDistrictData(
												values.state
											)}
											optionLabel={"name"}
										/>
									</div>
								)}
								<div className="field col-12 md:col-4">
									<Field
										name={"pincode"}
										header={"pincode"}
										component={FieldInput}
										type={"number"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Occupation Type"}
										name={"occupationType"}
										component={FeildDropdown}
										options={Config.OCCUPATION}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Highest Qualification"}
										name={"highestQualification"}
										component={FeildDropdown}
										options={Config.QUALIFICATION}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Company Type"}
										name={"companyType"}
										component={FeildDropdown}
										options={Config.ORGANIZATION}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Designation"}
										name={"designation"}
										component={FieldInput}
										// options={Config.DESIGNATION}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										name={"annualIncome"}
										header={"Annual income"}
										component={FieldInput}
										type={"number"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										name={"workingPeriod"}
										header={"Working period"}
										component={FieldInput}
										type={"number"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										name={"policyDetails"}
										header={"Existing Policy details"}
										component={FieldInput}
									/>
								</div>
								<h4 className="col-12">
									Preferred Mailing Address
								</h4>
								<div className="flex flex-wrap gap-3">
									<Field
										name={"preferredMailingAddress"}
										component={FieldRadio}
										radiolist={[
											{
												id: "Present Address",
												name: "Present Address",
												value: "Present Address",
											},
											{
												id: "Permanent Address",
												name: "Permanent Address",
												value: "Permanent Address",
											},
										]}
									/>
								</div>

								<div className="col-12 flex align-items-end justify-content-end">
									{selector.applicationState !==
										"preview" && (
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									)}
								</div>
							</div>
						</Panel>

						<Panel
							header={<h3 className="m-0">Proposer Details</h3>}
							className={"my-2"}
							toggleable
						>
							<div className="form-grid grid">
								<div className="col-12">
									<Checkbox
										onChange={(e) => {
											setCheckBox(e.checked);
											if (e.checked) {
												setFieldValue(
													"proposerMaritalStatus",

													values.maritalStatus,
													true
												);
												setFieldValue(
													"proposerDob",
													values.dob
														? new Date(values.dob)
														: "",
													true
												);
												setFieldValue(
													"proposerGender",
													values.gender,
													true
												);
												setFieldValue(
													"proposerOccupation",
													values.occupationType,
													true
												);
												setFieldValue(
													"proposerQualification",
													values.highestQualification,
													true
												);
												setFieldValue(
													"proposerCompanyType",
													values.companyType,
													true
												);

												setFieldValue(
													"proposerMobile",
													values.mobile,
													true
												);

												setFieldValue(
													"proposerEmail",
													values.personalMail,
													true
												);

												setFieldValue(
													"proposerFirstName",
													values.firstName,
													true
												);

												setFieldValue(
													"proposerMiddleName",
													values.middleName,
													true
												);

												setFieldValue(
													"proposerLastName",
													values.lastName,
													true
												);

												setFieldValue(
													"proposerSpouseName",
													values.spouseName,
													true
												);

												setFieldValue(
													"proposerMotherMaidenName",
													values.motherMaidenName,
													true
												);

												setFieldValue(
													"proposerAnnualIncome",
													values.annualIncome,
													true
												);

												setFieldValue(
													"proposerWorkingPeriod",
													values.workingPeriod,
													true
												);

												setFieldValue(
													"proposerHeight",
													values.height,
													true
												);

												setFieldValue(
													"proposerWeight",
													values.weight,
													true
												);

												setFieldValue(
													"proposerDesignation",
													values.designation,
													true
												);
											} else {
												setFieldValue(
													"proposerMaritalStatus",

													"",
													true
												);
												setFieldValue(
													"proposerDob",
													"",
													true
												);
												setFieldValue(
													"proposerGender",
													"",
													true
												);
												setFieldValue(
													"proposerOccupation",
													"",
													true
												);
												setFieldValue(
													"proposerQualification",
													"",
													true
												);
												setFieldValue(
													"proposerCompanyType",
													"",
													true
												);

												setFieldValue(
													"proposerMobile",
													"",
													true
												);

												setFieldValue(
													"proposerEmail",
													"",
													true
												);

												setFieldValue(
													"proposerFirstName",
													"",
													true
												);

												setFieldValue(
													"proposerMiddleName",
													"",
													true
												);

												setFieldValue(
													"proposerLastName",
													"",
													true
												);

												setFieldValue(
													"proposerSpouseName",
													"",
													true
												);

												setFieldValue(
													"proposerMotherMaidenName",
													"",
													true
												);

												setFieldValue(
													"proposerAnnualIncome",
													"",
													true
												);

												setFieldValue(
													"proposerWorkingPeriod",
													"",
													true
												);

												setFieldValue(
													"proposerHeight",
													"",
													true
												);

												setFieldValue(
													"proposerWeight",
													"",
													true
												);

												setFieldValue(
													"proposerDesignation",
													"",
													true
												);
											}
										}}
										checked={checkboxState}
										className={"ml-2 "}
									/>{" "}
									Same as Insurer Details
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"First Name"}
										name={"proposerFirstName"}
										component={FieldInput}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Middle Name"}
										name={"proposerMiddleName"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Last Name"}
										name={"proposerLastName"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Mobile Number"}
										name={"proposerMobile"}
										component={FieldInput}
										type={"number"}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Email"}
										name={"proposerEmail"}
										component={FieldInput}
										type={"email"}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<div className="grid">
										<div className="field col-12 md:col-6">
											<Field
												header={"Date Of Birth"}
												name={"proposerDob"}
												component={FeildCalender}
											/>
										</div>
										<div className="field col-12 md:col-6">
											<Field
												header={"Gender"}
												name={"proposerGender"}
												component={FeildDropdown}
												options={Config.GENDERS}
												optionLabel={"name"}
											/>
										</div>
									</div>
								</div>
								<div className="field col-12 md:col-4">
									<div className="grid">
										<div className="field col-12 md:col-4 flex flex-row align-items-center">
											<label
												className={
													"block text-sm mr-2 mb-0	"
												}
											>
												Smoke
											</label>
											<Checkbox
												name="isProposerSmoke"
												onChange={handleChange}
												checked={values.isProposerSmoke}
												value={values.isProposerSmoke}
											/>
										</div>
										<div className="field col-12 md:col-4 flex flex-row align-items-center">
											<label
												className={
													"block text-sm mr-2 mb-0	"
												}
											>
												Chew Tabacco
											</label>
											<Checkbox
												name="isProposerChewTobacco"
												onChange={handleChange}
												checked={
													values.isProposerChewTobacco
												}
												value={
													values.isProposerChewTobacco
												}
											/>
										</div>
									</div>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Marital Status"}
										name={"proposerMaritalStatus"}
										component={FeildDropdown}
										options={Config.MARITAL_STATUS}
									/>
								</div>

								{values.proposerMaritalStatus === "Married" && (
									<div className="field col-12 md:col-4">
										<Field
											header={"Spouse’s Full Name"}
											name={"proposerSpouseName"}
											component={FieldInput}
										/>
									</div>
								)}

								<div className="field col-12 md:col-4">
									<Field
										header={"Mother’s Name"}
										name={"proposerMotherMaidenName"}
										component={FieldInput}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Height (Inch)"}
										name={"proposerHeight"}
										component={FieldInput}
										type={"number"}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Weight (Kg)"}
										name={"proposerWeight"}
										component={FieldInput}
										type={"number"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Landmark"}
										name={"proposerLandMark"}
										component={FieldTextArea}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Occupation Type"}
										name={"proposerOccupation"}
										component={FeildDropdown}
										options={Config.OCCUPATION}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Highest Qualification"}
										name={"proposerQualification"}
										component={FeildDropdown}
										options={Config.QUALIFICATION}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Company Type"}
										name={"proposerCompanyType"}
										component={FeildDropdown}
										options={Config.ORGANIZATION}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Designation"}
										name={"proposerDesignation"}
										component={FieldInput}
										// options={Config.DESIGNATION}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										name={"proposerAnnualIncome"}
										header={"Annual income"}
										component={FieldInput}
										type={"number"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										name={"proposerWorkingPeriod"}
										header={"Working period"}
										component={FieldInput}
										type={"number"}
									/>
								</div>

								<div className="col-12 flex align-items-end justify-content-end">
									{selector.applicationState !==
										"preview" && (
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									)}
								</div>
							</div>
						</Panel>

						<Panel
							header={<h3 className="m-0">Nominee Details</h3>}
							className={"my-2"}
							toggleable
						>
							<div className="form-grid grid">
								<div className="field col-12 md:col-4">
									<Field
										header={"Name"}
										name={"nomineeName"}
										component={FieldInput}
										// options={Config.OCCUPATION}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<div className="grid">
										<div className="field col-12 md:col-6">
											<Field
												header={"Date Of Birth"}
												name={"nomineeDob"}
												component={FeildCalender}
											/>
										</div>
										<div className="field col-12 md:col-6">
											<Field
												header={"Gender"}
												name={"nomineeGender"}
												component={FeildDropdown}
												options={Config.GENDERS}
												optionLabel={"name"}
											/>
										</div>
									</div>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={
											"Select Relationship with Insured"
										}
										name={"relationshipWithPrimaryInsured"}
										component={FeildDropdown}
										options={Config.RELATIONSHIP}
										optionLabel={"label"}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Appointee Name"}
										name={"appointeeName"}
										component={FieldInput}
										// options={Config.OCCUPATION}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={
											"Select Relationship with Nominee"
										}
										name={"relationshipWithNominee"}
										component={FeildDropdown}
										options={Config.RELATIONSHIP}
										optionLabel={"label"}
									/>
								</div>

								<div className="col-12 flex align-items-end justify-content-end">
									{selector.applicationState !==
										"preview" && (
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									)}
								</div>
							</div>
						</Panel>

						<Panel
							header={<h3 className="m-0">Family History</h3>}
							className={"my-4"}
							toggleable
						>
							<div className="form-grid grid">
								<div className="field col-12 md:col-4">
									<Field
										header={"Father"}
										name={"familyFather"}
										component={FieldTextArea}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Mother"}
										name={"familyMother"}
										component={FieldTextArea}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Spouse"}
										name={"familySpouse"}
										component={FieldTextArea}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Son"}
										name={"familySon"}
										component={FieldTextArea}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Daughter"}
										name={"familyDaughter"}
										component={FieldTextArea}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"age"}
										name={"familyAge"}
										component={FieldInput}
										type={"number"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Status"}
										name={"familyStatus"}
										component={FieldInput}
									/>
								</div>
								<div className="col-12 flex align-items-end justify-content-end">
									{selector.applicationState !==
										"preview" && (
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									)}
								</div>
							</div>
						</Panel>

						<Panel
							header={<h3 className="m-0">Payment Details</h3>}
							className={"my-2"}
							toggleable
						>
							<div className="form-grid grid">
								<div className="field col-12 md:col-4">
									<Field
										header={"Payment Frequency"}
										name={"paymentFrequency"}
										component={FeildDropdown}
										options={Config.PAYMENYTYPE}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Premium"}
										name={"premium"}
										component={FieldInput}
										type={"number"}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Premium Paying Term"}
										name={"premiumPayingTerm"}
										component={FieldInput}
										type={"number"}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Policy Term"}
										name={"policyTerm"}
										component={FieldInput}
										type={"number"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Disease"}
										name={"diseaseList"}
										filter
										value={values.diseaseList}
										component={FieldMultiselect}
										options={allDisease}
										optionLabel="disease"
										optionValue="id"
									/>
								</div>

								<div className="col-12 flex align-items-end justify-content-end">
									{selector.applicationState !==
										"preview" && (
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									)}
								</div>
							</div>
						</Panel>
					</Form>
				)}
			</Formik>
			<Divider />
			{(props.locationData?.applicationId ||
				selector.countryApplicationId) && (
				<DocumentUpload
					applicationId={props?.locationData?.applicationId}
					subproductId={props?.locationData?.subproductId}
				/>
			)}
			{(props.locationData?.applicationId ||
				selector.countryApplicationId) && (
				<div className="flex justify-content-end align-items-center w-full mt-4">
					<Button
						type="button"
						label="Submit"
						icon="pi pi-save"
						onClick={() => {
							finalSubmit(
								props.locationData?.applicationId
									? props.locationData?.applicationId
									: selector.countryApplicationId
							);
						}}
					/>
				</div>
			)}
		</>
	);
};

export default LIinsurance;
