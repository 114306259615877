import { useRef, useState } from "react";
import { Button } from "primereact/button";
import Config from "../../../../shared/config";
import { FeildDropdown, FieldInput } from "../../../../components/FieldInput";
import { Field, Form, Formik } from "formik";
import {
	useGetInstitutionDetailsQuery,
	useGetProductDetailsQuery,
} from "../../../../services/query/queryApi";
import { createSelector } from "reselect";
import { SplitButton } from "primereact/splitbutton";
import { exportExcel } from "../../../../components/XlsxExport";
import Loader from "../../../../components/Loader";
import { Toast } from "primereact/toast";
import { ProgramService } from "../../../../services/ProgramService";
import { useDispatch } from "react-redux";
import { setEnable, setId } from "../../../../store/reducer/ProgramReducer";

const SearchProgram = (props) => {
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const toastTL = useRef(null);
	const programService = new ProgramService();
	const [loader, setLoader] = useState(false);
	const { data = [] } = useGetInstitutionDetailsQuery();
	const { data: productList = { data: [] } } = useGetProductDetailsQuery({
		searchRequest: [],
		pageNo: 1,
		pageSize: 330,
		sorts: [],
	});
	const getSubProductList = createSelector(() => {
		let filterData = productList.data.map((item) => {
			if (item.isActive) {
				let tempSub = item.subProducts.filter(
					(elm) => elm.isActive === true
				);
				return {
					...item,
					subProducts: tempSub.map((item) => ({
						title: item.title,
						value: item.id,
					})),
				};
			}
		});
		let data = filterData.filter((item) => {
			return item !== undefined;
		});
		return data;
	});
	const handleFormSubmit = (value) => {
		const { institutionId } = value;
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});

		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: { ...value, institutionId: institutionId.id },
		});
	};
	const getAllLoanData = () => {
		const requestBody = {
			pageNo: 1,
			pageSize: 100000,
		};

		setLoader(true);
		programService
			.getAllProgramList(requestBody)
			.then((res) => {
				const newData =
					res.data &&
					res.data.map((elm) => ({
						"Program Id": elm.programId,
						"Program Name": elm.name,
						"Sub Product Name": elm.subProductName,
						"Product Name": elm.productName,
						"Line Of Business": elm.lob,
						"FI Name": elm.institutionName,

						"Active Status": elm.activeStatus,
					}));

				exportExcel(newData, "All_Program_List");
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoader(false));
	};
	const items = [
		{
			label: "Export",
			icon: "pi pi-file-excel",
			command: () => {
				exportExcel(props.newData, "ProgramList");
			},
		},
		{
			label: "Export All",
			icon: "pi pi-file-excel",
			command: () => {
				getAllLoanData();
			},
		},
	];
	return (
		<>
			{loader && <Loader />}
			<Toast ref={toastTL} />
			<div className="w-full  py-3 flex align-items-end justify-content-between">
				<h3 className="m-0">Program List</h3>
				<div className="flex  gap-2">
					<Button
						icon={open ? "pi pi-times" : "pi pi-search"}
						className={
							open
								? "p-button-danger p-button-outlined"
								: "p-button-outlined"
						}
						label={open ? "Close" : "Search"}
						aria-label="Cancel"
						onClick={() => setOpen((prev) => !prev)}
					/>
					<Button
						label="Reload"
						icon="pi pi-refresh"
						className="primary-btn  p-button-outlined"
						onClick={() =>
							props.searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							})
						}
					/>
					<Button
						label="Add Program"
						icon="pi pi-plus"
						className="primary-btn  "
						onClick={() => {
							// props.dialog(true);
							dispatch(setEnable(0));
							dispatch(setId(null));
							// dispatch(setEnable({ index: 2, disable: true }));
							props.setNewProgram(true);
						}}
					/>
					<SplitButton
						icon={"pi pi-file-export"}
						label="Export"
						model={items}
						// loading={spinner}
					/>
				</div>
			</div>
			{open && (
				<Formik
					initialValues={{
						subProductId: "",
						institutionId: "",
						name: "",
					}}
					onSubmit={handleFormSubmit}
				>
					{({ values, handleSubmit, resetForm }) => (
						<Form onSubmit={handleSubmit}>
							<div className="form-grid grid background-secondary p-4 border-round-md my-3">
								<div className="field col-12 md:col-3">
									<Field
										component={FeildDropdown}
										options={data}
										filter
										header={"Institution"}
										name={"institutionId"}
										optionLabel={"name"}
									/>
								</div>

								<div className="field col-12 md:col-3">
									<Field
										header={"Product"}
										name={"subProductId"}
										filter
										options={getSubProductList()}
										optionLabel="title"
										optionGroupLabel="title"
										optionGroupChildren="subProducts"
										component={FeildDropdown}
									/>
								</div>
								{/* <div className="field col-12 md:col-3">
									<Field
										component={FeildDropdown}
										options={Config.YES_NO}
										header={"Processing Fee"}
										name={"processingFee"}
									/>
								</div> */}
								{/* <div className="field col-12 md:col-3">
									<Field
										type={"number"}
										header={"Rate Of Interest"}
										name={"rateOfInterest"}
										component={FieldInput}
									/>
								</div> */}
								<div className="field col-12 md:col-3">
									<Field
										header={"Program Name"}
										name={"name"}
										component={FieldInput}
									/>
								</div>
								<div className="field flex align-items-center  justify-content-end col-12">
									<Button
										type="button"
										label="Reset"
										className="p-button-danger mr-2"
										onClick={() => resetForm()}
									/>
									<Button type="submit" label="Search" />
								</div>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default SearchProgram;
