import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { agent_end_point_url, apiPath } from "../../shared/constant";

//institutionData
export const institutionData = createApi({
	reducerPath: "institutionQuery",
	baseQuery: fetchBaseQuery({
		baseUrl: apiPath,
		prepareHeaders: async (headers, { getState }) => {
			const token = await getState().persistData.auth.token;
			headers.set("Content-Type", "application/json");
			headers.set("Authorization", `Bearer ${token}`);
		},
	}),
	endpoints: (builder) => ({
		getInstitutionDetails: builder.query({
			query: () => ({ url: `institution/svc/all`, method: "GET" }),
		}),
		getDsaInstitutionDetails: builder.query({
			query: (item) => ({
				url: "/dsa/svc/search",
				method: "POST",
				body: item,
			}),
		}),
		getProductDetails: builder.query({
			query: (item) => ({
				url: `/master/svc/product/search`,
				method: "POST",
				body: item,
			}),
		}),
		getInstitionWiseList: builder.query({
			query: (item) => ({
				url: `/program/list-institution-wise`,
				method: "POST",
				body: item,
			}),
		}),
		getIdWiseProgram: builder.query({
			query: (id) => ({
				url: `/program${id}`,
				method: "GET",
			}),
		}),
		getProductCategory: builder.query({
			query: () => ({
				url: `/category-type/all`,
				method: "GET",
			}),
		}),
		getAppProgram: builder.query({
			query: () => ({
				url: `/program/all-program`,
				method: "GET",
			}),
		}),
		getAllPlan: builder.query({
			query: () => ({
				url: `/application/all-plan`,
				method: "GET",
			}),
		}),
		getAllReportColumn: builder.query({
			query: () => ({
				url: "/report/all/fields",
				method: "GET",
			}),
		}),
	}),
});
export const {
	useGetInstitutionDetailsQuery,
	useGetProductDetailsQuery,
	useGetInstitionWiseListQuery,
	useGetIdWiseProgramQuery,
	useGetProductCategoryQuery,
	useGetAppProgramQuery,
	useGetAllPlanQuery,
	useGetDsaInstitutionDetailsQuery,
	useGetAllReportColumnQuery,
} = institutionData;

export const masterData = createApi({
	reducerPath: "masterdataquery",
	baseQuery: fetchBaseQuery({
		baseUrl: agent_end_point_url,
		prepareHeaders: async (headers, { getState }) => {
			const token = await getState().persistData.auth.token;
			headers.set("Content-Type", "application/json");
			headers.set("Authorization", `Bearer ${token}`);
		},
	}),
	endpoints: (builder) => ({
		getAgentProfile: builder.query({
			query: (item) => ({
				url: `/profile/search`,
				method: "POST",
				body: item,
			}),
		}),
		getStateList: builder.query({
			query: () => ({
				url: `/states/all`,
				method: "GET",
			}),
		}),
		getAllDIstrict: builder.query({
			query: () => ({
				url: "/states/all-district",
				method: "GET",
			}),
		}),
		getAllRole: builder.query({
			query: () => ({
				url: "/role",
				method: "GET",
			}),
		}),
		getCallCenterRole: builder.query({
			query: () => ({
				url: "/call-center-role/list",
				method: "GET",
			}),
		}),
		getZone: builder.query({
			query: () => ({
				url: "/zone",
				method: "GET",
			}),
		}),
		getCityList: builder.query({
			query: () => ({
				url: "/city/all",
				method: "GET",
			}),
		}),
		getAllHub: builder.query({
			query: () => ({
				url: "/vendor/hub/all",
				method: "GET",
			}),
		}),
		getAllCCUser: builder.query({
			query: () => ({
				url: "/vendor/vendor-user/all",
				method: "GET",
			}),
		}),
		getAllVendor: builder.query({
			query: () => ({
				url: `/vendor/search`,
				method: "POST",
				body: { pageNo: 1, pageSize: 50000, sorts: [] },
			}),
		}),
		getAllTSRM: builder.query({
			query: () => ({
				url: `/vendor/tsrm-list/search`,
				method: "POST",
				body: {
					pageNo: 1,
					pageSize: 1000000,
					hubId: null,
					spokeId: null,
				},
			}),
		}),
		getAllOEList: builder.query({
			query: () => ({
				url: "/vendor/lead/user/all-oe",
				method: "GET",
			}),
		}),
	}),
});
export const {
	useGetAgentProfileQuery,
	useGetStateListQuery,
	useGetAllDIstrictQuery,
	useGetAllRoleQuery,
	useGetZoneQuery,
	useGetCallCenterRoleQuery,
	useGetCityListQuery,
	useGetAllHubQuery,
	useGetAllCCUserQuery,
	useGetAllVendorQuery,
	useGetAllTSRMQuery,
	useGetAllOEListQuery,
} = masterData;
