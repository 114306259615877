import React, { useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { FiAtSign, FiLock } from "react-icons/fi";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import authBG from "../../assets/images/login_bg.png";
import logo from "../../assets/images/logo_main.png";
import { AuthService } from "../../services/AuthService";
import { setUser } from "../../store/reducer/AuthReducer";
import Config from "../../shared/config";

const Signup = () => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const auth = new AuthService();
	const toast = useRef(null);
	const initVal = {
		userId: "",
		password: "",
	};
	const validationSchema = Yup.object().shape({
		userId: Yup.string().required("User ID can't be empty."),
		password: Yup.string().required("Password can't be empty."),
	});
	const handleFormSubmit = (values) => {
		setLoading(true);
		auth.login(values)
			.then((res) => {
				if (res?.user?.role?.isAdmin) {
					auth.superLogin({
						userId: "Chandan1",
						password: "password",
					})
						.then((data) => {
							dispatch(
								setUser({
									token: res?.jwttoken,
									supertoken: data?.jwttoken,
									user: res?.user,
									menus: res?.menus,
								})
							);
							sessionStorage.setItem("token", res?.jwttoken);
							sessionStorage.setItem(
								"supertoken",
								data?.jwttoken
							);
							sessionStorage.setItem(
								"user",
								JSON.stringify(res.user)
							);
						})
						.catch(() => {
							toast.current.show({
								severity: "error",
								summary: "Error",
								detail: "error",
								style: { color: "#000000" },
								sticky: true,
							});
						});
				} else {
					dispatch(
						setUser({
							token: res?.jwttoken,
							supertoken: null,
							user: res?.user,
							menus: res?.menus,
						})
					);
					sessionStorage.setItem("token", res?.jwttoken);
					sessionStorage.setItem("supertoken", "");
					sessionStorage.setItem("user", JSON.stringify(res?.user));
				}
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					sticky: true,
				});
			})
			.finally(() => setLoading(false));
	};

	return (
		<>
			<div className="app-wrapper h-screen secondary-bg">
				<Toast ref={toast} position="top-left" />
				<div className="auth-bg flex">
					<img className="w-full" src={authBG} alt="Authbg" />
				</div>
				<div className="conatiner">
					<div className="grid">
						<div className="col-12">
							<div className="auth-logo-grid">
								<img className="img-fluid" src={logo} alt="" />
							</div>
						</div>
						<div className="col-12">
							<div className="auth-wrapper">
								<div className="card auth-card">
									<h2>Log In</h2>
									<div>
										<Formik
											enableReinitialize
											initialValues={initVal}
											validationSchema={validationSchema}
											onSubmit={handleFormSubmit}
										>
											{({
												values,
												handleChange,
												handleSubmit,
												errors,
												touched,
											}) => (
												<Form onSubmit={handleSubmit}>
													<div className="auth-field position-relative mb-4">
														<span className="p-input-icon-left w-full">
															<span className="icon">
																<FiAtSign />
															</span>
															<InputText
																name="userId"
																type={"text"}
																value={
																	values.userId
																}
																onChange={
																	handleChange
																}
																placeholder={
																	"User ID"
																}
																className="border-round-md w-full"
															/>
															{errors.userId &&
																touched.userId && (
																	<small
																		id="username1-help"
																		className="block text-danger"
																	>
																		{
																			errors.userId
																		}
																	</small>
																)}
														</span>
													</div>
													<div className="auth-field position-relative mb-4">
														<span className="p-input-icon-left border-round-md w-full">
															<span className="icon">
																<FiLock />
															</span>
															<InputText
																name="password"
																type={
																	"password"
																}
																placeholder={
																	"Password"
																}
																value={
																	values.password
																}
																onChange={
																	handleChange
																}
																className="w-full border-round-md"
															/>

															{errors.password &&
																touched.password && (
																	<small
																		id="userId1-help"
																		className="block text-danger"
																	>
																		{
																			errors.password
																		}
																	</small>
																)}
														</span>
													</div>
													<div className="d-grid gap-2">
														<Button
															type="submit"
															label="Login"
															className="primary-btn w-full border-round-md"
															loading={loading}
														/>
													</div>
													<div className="text-right mt-1">
														{/* <Link to="/forget-password"> */}
														Forget Password ?
														{/* </Link> */}
													</div>
												</Form>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Signup;
