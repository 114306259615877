import React, {
	useState,
	useEffect,
	useRef,
	useReducer,
	Suspense,
} from "react";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";

import moment from "moment";
import { useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";

import * as Yup from "yup";
import { produce } from "immer";
import { Skeleton } from "primereact/skeleton";
// import { Dropdown } from "primereact/dropdown";
import { useGetAllRoleQuery } from "../../../services/query/queryApi";
import { AgentService } from "../../../services/AgentService";
import {
	searchInitialState,
	searchReducer,
} from "../../../components/searchReducer";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import Config from "../../../shared/config";
import MenuComponent from "../../../components/MenuComponent";
import TableLoader from "../../../components/TableLoader";
import Paginator from "../../../components/Paginator";
import Loader from "../../../components/Loader";
import AgentProfileComponent from "../../../components/AgentProfile";
import {
	FeildDropdown,
	FieldPassword,
	FieldTextArea,
} from "../../../components/FieldInput";
import AgentProfileEditDialoge from "./components/VendorAgentProfileEditDialoge";
import { CallCenterService } from "../../../services/callCenterService";
import SearchCCAgent from "./components/SearchCCAgent";
import { ConfirmDialog } from "primereact/confirmdialog";
import AllocateAgentForm from "./componentAllocation/AllocateAgentForm";
import { useNavigate } from "react-router-dom";
import { VendorService } from "../../../services/vendorService";
import AgentReminder from "./components/AgentReminder";
import ReminderList from "./components/ReminderList";
// import ProductWiseCategory from "./components/ProductWiseCategory";
const AgentsCCProfile = () => {
	const vendorService = new VendorService();
	const navigate = useNavigate();
	const [isFetched, setIsFetched] = useState(false);
	const toastTL = useRef(null);
	const { isSuccess, data: roleData = [] } = useGetAllRoleQuery();
	const agentService = new AgentService();
	const ccService = new CallCenterService();
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const [reminderDialog, setReminderDetailDialog] = useState({
		state: false,
		id: null,
	});
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [agents, setAgents] = useReducer(dataReducer, fetchInitialState);
	const [agent, setagent] = useReducer(dataReducer, fetchInitialState);
	const [agentEdit, setAgentEdit] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const [noteDialog, setNoteDialog] = useState(false);
	const [activityData, setActivityData] = useState([]);
	// const [expandedRows, setExpandedRows] = useReducer(
	// 	(state, action) => {
	// 		return { ...state, data: action.payload };
	// 	},
	// 	{ data: null }
	// );
	const [reminderListDialog, setReminderListDialog] = useState({
		state: false,
		agentId: null,
	});
	const [assignState, assignDispatch] = useReducer(
		produce((state, action) => {
			switch (action.type) {
				case "assDialog":
					state.dialog = action.payload;
					break;
				case "profileId":
					state.profileId = action.payload;
					break;
				default:
					return state;
			}
		}),
		{ dialog: false, profileId: null }
	);
	const [profileDetailDialog, setprofileDetailDialog] = useState(false);
	const [noteDetailDialog, setNoteDetailDialog] = useState({
		state: false,
		id: null,
		note: "",
	});
	const [profileEditDialog, setProfileEditDialog] = useState(false);
	const [allocateAgentDialog, setAllocateAgentDialog] = useState({
		id: null,
		state: false,
	});
	const [agentPasswordDialog, setAgentPasswordDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const [userName, setUserName] = useState("");
	const [callDialog, setCallDialog] = useState({
		state: false,
		id: null,
		index: null,

		rowData: null,
	});
	const [calllog, setCalllog] = useState([]);
	const [callLogDialog, setCallLogDialog] = useState(false);
	// const [agentCommissionDialog, setAgentCommissionDialog] = useReducer(
	// 	(state, action) => {
	// 		return {
	// 			...state,
	// 			dialog: action.dialog,
	// 			agentProfileId: action.agentProfileId,
	// 		};
	// 	},
	// 	{
	// 		dialog: false,
	// 		agentProfileId: null,
	// 	}
	// );
	const handleActivity = (id) => {
		setNoteDialog(true);
		vendorService
			.agentActivityLofList(id)
			.then((res) => setActivityData(res));
	};
	const reducer = produce((state, action) => {
		switch (action.type) {
			case "DIALOG":
				state.dialog = action.payload;
				break;

			case "AGENTID":
				state.agentId = action.payload;
				break;
			case "LIST":
				state.userList = action.payload;
				break;
			case "SPINNER":
				state.spinner = action.payload;
				break;
			case "LOADER":
				state.loader = action.payload;
				break;

			default:
				return state;
		}
	});

	const [state, dispatch] = useReducer(reducer, {
		dialog: false,
		agentId: null,
		userList: [],
		spinner: false,
		loader: false,
	});
	const handleCallLog = (id) => {
		ccService.getAgentCallLog({ id: id, userName: null }).then((res) => {
			setIsFetched(true);
			setCalllog(res);
		});
	};
	const validationSchema = Yup.object().shape({
		newPassword: Yup.string()
			.required("Password is required")
			.min(8, "Password must have at least 8 characters")
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
			),
		cnfNewPassword: Yup.string().oneOf(
			[Yup.ref("newPassword"), null],
			"Passwords must match"
		),
	});
	const handleReminder = (val, action) => {
		setLoading(true);
		ccService
			.agentReminder({
				...val,
				agentId: reminderDialog.id,
				remainderDateTime: moment(val?.remainderDateTime)
					.format()
					?.split("+")[0],
				endRemainderDateTime: val.endRemainderDateTime
					? moment(val.endRemainderDateTime).format()?.split("+")[0]
					: null,
			})
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: res?.message,
					style: { color: "#000000" },
					life: 3000,
				});
				action.resetForm();
				setSelectedProducts([]);
				setReminderDetailDialog((prev) => ({
					...prev,
					id: null,
					state: false,
				}));
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoading(false);
			});
	};

	/**
	 * Table Sort Function
	 */
	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	function tblSort(columnName, sortType) {
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}
	const getAgents = () => {
		const body = {
			...searchState.searchValue,
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.sortByColumn &&
				searchState.sortType && {
					sorts: [
						searchState.sortByColumn + "," + searchState.sortType,
					],
				}),
		};
		const demo = Object.fromEntries(
			Object.entries(body).filter(([_, v]) => v !== "")
		);
		setAgents({ type: Config.FETCH_CONFIG.start });
		ccService
			.getAgent(demo)
			.then((res) => {
				// setAgents(res);
				setAgents({ type: Config.FETCH_CONFIG.success, payload: res });
			})
			.catch((e) => {
				setAgents({ type: Config.FETCH_CONFIG.error, payload: e });
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setAgents({ type: Config.FETCH_CONFIG.stop }));
	};
	useEffect(() => {
		let ignore = false;
		if (!ignore) {
			getAgents();
		}
		return () => {
			ignore = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);

	const increment = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const pagesizechange = (e) => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};

	const getProfileDetails = (id) => {
		setagent({ type: Config.FETCH_CONFIG.start });
		agentService
			.getProfileDetails(id)
			.then((res) => {
				// setagent(res);
				setagent({ type: Config.FETCH_CONFIG.success, payload: res });
				setprofileDetailDialog(true);
			})
			.catch((e) => {
				setagent({ type: Config.FETCH_CONFIG.error, payload: e });
			})
			.finally(() => setagent({ type: Config.FETCH_CONFIG.stop }));
	};

	const getProfileEdit = (e) => {
		setAgentEdit({ type: Config.FETCH_CONFIG.start });
		agentService
			.getProfileDetails(e)
			.then((res) => {
				// setAgentEdit(res);
				setAgentEdit({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
				setProfileEditDialog(true);
			})
			.catch((err) => {
				setAgentEdit({ type: Config.FETCH_CONFIG.error, payload: err });
			})
			.finally(() => setAgentEdit({ type: Config.FETCH_CONFIG.stop }));
	};

	const dialogeClose = (e) => {
		setProfileEditDialog(e);
	};
	const chengeStatus = (status) => {
		// setSpinner(true);
		setagent({ type: Config.FETCH_CONFIG.start });
		let payload = {
			agentId: agent.data.profile.agent.id,
			profileId: agent.data.profile.id,
			status: status,
		};
		agentService
			.agentProfileStatusChange(payload)
			.then(() => {
				let param = {
					searchRequest: "",
					pageNo: searchState.pageNo,
					pageSize: searchState.pageSize,
					sorts: [],
				};
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: "Profile status changed",
					style: { color: "#000000" },
					life: 3000,
				});
				setprofileDetailDialog(false);
				// setagent(null);

				getAgents(param);
				// setSpinner(false);
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});

				setprofileDetailDialog(false);
				// setagent(null);
				// setSpinner(false);
			})
			.finally(() => setagent({ type: Config.FETCH_CONFIG.ini }));
	};

	/**
	 *  Table Templates
	 */
	const handleNoteSubmit = (val) => {
		setLoading(true);
		ccService
			.saveAgentNote({
				agentId: noteDetailDialog.id,
				notes: val.notes,
			})
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});
				getAgents();
				setNoteDetailDialog((prev) => ({
					...prev,
					state: false,
					id: null,
				}));
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoading(false));
	};
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column
					selectionMode={"multiple"}
					headerStyle={{ width: "3rem" }}
				/>
				<Column header="#" />
				<Column header={getSortableColumn("Agent Code", "agentCode")} />
				<Column header={getSortableColumn("Name", "firstName")} />
				<Column
					header={getSortableColumn("Profile Type", "profileType")}
				/>
				{/* <Column
					header={getSortableColumn("District", "districtName")}
				/> */}
				<Column header="Mobile" />
				{/* <Column header={getSortableColumn("Email", "emailId")} />
				<Column header={"Gender"} /> */}
				<Column header={getSortableColumn("User Name", "userName")} />
				<Column header={getSortableColumn("Created On", "createdAt")} />
				<Column
					header={getSortableColumn(
						"Agent Engagement",
						"agentEngagement"
					)}
				/>
				<Column header={getSortableColumn("RM/ASM/TSRM", "rmUser")} />
				<Column
					header={getSortableColumn("Last Contacted", "lastCalled")}
				/>
				<Column
					header={getSortableColumn(
						"Zone",
						"p.district.stateId.zoneId"
					)}
				/>
				<Column
					header={getSortableColumn("State", "p.district.stateId")}
				/>
				<Column
					header={getSortableColumn("District", "districtName")}
				/>

				<Column header={getSortableColumn("Status", "status")} />
				<Column
					header={getSortableColumn(
						"Profile Status",
						"profileStatus"
					)}
				/>
				<Column header="Note" />
				{selectedProducts?.length === 0 && <Column header="Actions" />}
			</Row>
		</ColumnGroup>
	);

	const agentStatus = ({ status }) => {
		return status === null || status.toLowerCase() === "inactive" ? (
			<span className="status status-mute">Inactive</span>
		) : status.toLowerCase() === "blocked" ? (
			<span className="status status-danger-deep">Blocked</span>
		) : (
			<span className="status status-primary">Active</span>
		);
	};
	// const handleCommissionDialog = (e) => {
	// 	// setAgentCommissionDialog(true);
	// 	// setAgentCommissionId(e);
	// 	setAgentCommissionDialog({ dialog: true, agentProfileId: e });
	// };

	const agentChangePassword = (item) => {
		const items = [
			// {
			// 	label: "Change Password",
			// 	icon: "pi pi-lock",
			// 	command: () => {
			// 		handelAgentPasswordDialoge(item);
			// 	},
			// },
			{
				label: "My-Hero Activity",
				icon: "pi pi-user",
				command: () => {
					handleActivity(item.agentId);
				},
			},
			{
				label: "Profile",
				icon: "pi pi-user",
				command: () => {
					// console.log(item);
					navigate(`/vendor/agent-profile/${item.agentId}`, {
						state: { profileId: item.profileId },
					});
				},
			},
			{
				label: "Edit Status",
				icon: "pi pi-user",
				command: () => {
					getProfileDetails(item.profileId);
				},
			},
			{
				label: "Edit Details",
				icon: "pi pi-user-edit",
				command: () => {
					getProfileEdit(item.profileId);
				},
			},
			{
				label: "Add Note",
				icon: "pi pi-user-edit",
				command: () => {
					setNoteDetailDialog((prev) => ({
						...prev,
						state: true,
						id: [item.agentId],
					}));
				},
			},
			{
				label: "Allocate TSRM",
				icon: "pi pi-comment",
				command: () => {
					setAllocateAgentDialog((prev) => ({
						...prev,
						state: true,
						id: [item.agentId],
					}));
				},
			},
			{
				label: "Set Reminder",
				icon: "pi pi-clock",
				command: () => {
					setReminderDetailDialog((prev) => ({
						...prev,
						state: true,
						id: [item.agentId],
					}));
				},
			},
			{
				label: "Reminder List",
				icon: "pi pi-clock",
				command: () => {
					setReminderListDialog((prev) => ({
						...prev,
						state: true,
						agentId: item.agentId,
					}));
				},
			},
			// {
			// 	label: "Assign TSE",
			// 	icon: "pi pi-tag",
			// 	command: () => {
			// 		handleAssign(item);
			// 	},
			// },
			// {
			// 	label: "Re-allocate RM/ASM",
			// 	icon: "pi pi-check-circle",
			// 	command: () => {
			// 		dispatch({
			// 			type: "DIALOG",
			// 			payload: true,
			// 		});
			// 		dispatch({
			// 			type: "AGENTID",
			// 			payload: item.profileId,
			// 		});
			// 		dispatch({
			// 			type: "LIST",
			// 			payload: [],
			// 		});
			// 		// handelAgentPasswordDialoge(item);
			// 	},
			// },

			// item?.profileStatus === "VERIFIED" && {
			// 	label: "Commission",
			// 	icon: "pi pi-wallet",
			// 	command: () => {
			// 		navigate(`/members/agent-commission`, { state: item });
			// 		// handleCommissionDialog(item.agentId);
			// 		// handelAgentPasswordDialoge(item);
			// 	},
			// },
		];

		return (
			<div className="flex gap-2">
				{/* <i className="pi pi-file-edit" /> */}
				{/* <Button
					icon="pi pi-file-edit"
					severity="warning"
					aria-label="Notification"
					style={{ height: "20px" }}
					onClick={() => handelAgentPasswordDialoge(item)}
				/> */}
				{/* <Button
					className="icon-btn success-btn"
					tooltip="Agent Password Change"
					tooltipOptions={{ position: "bottom" }}
					onClick={() => handelAgentPasswordDialoge(item)}
				>
					<i className="pi pi-pencil"></i>
				</Button> */}
				<Button
					icon={"pi pi-phone"}
					tooltip="Call"
					tooltipOptions={{ position: "left" }}
					className="icon-btn p-button-text"
					onClick={() => {
						setCallDialog((prev) => ({
							...prev,
							state: true,
							id: item.agentId,
						}));
					}}
				/>
				<Button
					disabled={selectedProducts.length > 0 ? true : false}
					icon={"pi pi-clone"}
					tooltip="Call Log"
					tooltipOptions={{ position: "left" }}
					className="icon-btn p-button-text"
					onClick={() => {
						setCallLogDialog(true);
						handleCallLog(item.agentId);
						setIsFetched(false);
					}}
				/>
				<MenuComponent items={items} />
			</div>
		);
	};

	const handleChangePassword = (e) => {
		setLoading(true);
		let requestBody = {
			newPassword: e.newPassword,
			userName: userName,
		};
		agentService
			.agentPasswordChange(requestBody)
			.then(() => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: "Password Changed Successfully",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setLoading(false);
				setAgentPasswordDialog(false);
			});
	};
	// const boolTemplate = ({ active }) => {
	// 	return active ? (
	// 		<span className="text-seccess text-4xl">
	// 			<MdToggleOn />
	// 		</span>
	// 	) : (
	// 		<span className="text-danger text-4xl">
	// 			<MdToggleOff />
	// 		</span>
	// 	);
	// };

	const profileStatus = ({ profileStatus }) => {
		return profileStatus === "VERIFIED" ? (
			<span className="status status-success">Verified</span>
		) : profileStatus === "TEMPORARY_SUSPENDED" ? (
			<span className="status status-danger">Temporary Suspended</span>
		) : profileStatus === "ON_HOLD" ? (
			<span className="status status-warning">On Hold</span>
		) : profileStatus === "BLOCKED" ? (
			<span className="status status-danger-deep">Blocked</span>
		) : (
			<span className="status status-ban">Not Verified</span>
		);
	};
	const itaration = {
		title: "",
		firstName: "",
		pincode: "",
		mobilenumber: "",
		city: "",
		status: "",
		action: "",
		actiossn: "",
		firstName1: "",
		pincode1: "",
		mobilenumber1: "",
		f: "",
		status1: "",
		pincode11: "",
		mobilenumber12: "",
		city13: "",
		status14: "",
		action1: "",
	};
	const nameTemplate = (item) => {
		let fname = item.firstName ? item.firstName : "";
		let mname = item.middleName ? item.middleName : "";
		let lname = item.lastName ? item.lastName : "";
		let name = `${fname} ${mname} ${lname}`;
		return name;
	};
	const createdOnTemp = ({ createdAt }) => {
		return moment(createdAt).format("Do MMM, YYYY HH:mm A");
	};
	const handleAssign = (e) => {
		assignDispatch({ type: "assDialog", payload: true });
		assignDispatch({ type: "profileId", payload: e.profileId });
	};
	// 	const handleReallocate = (value) => {

	// }
	// const allowExpansion = (rowData) => {
	// 	return rowData.profileList.length > 0;
	// };
	const rmuserName = (item) => {
		let fname = item.rmUserFirstName ? item.rmUserFirstName : "";
		let mname = item.rmUserMiddleName ? item.rmUserMiddleName : "";
		let lname = item.rmUserLastName ? item.rmUserLastName : "";
		let userName = item.rmUser ? `(${item.rmUser})` : "";
		let rmName = `${fname} ${mname} ${lname} ${userName}`;
		return rmName;
	};
	// const rowExpansionTemplate = (data) => {
	// 	return (
	// 		<div className="w-full">
	// 			<h5>Profile for {data.firstName + " " + data.lastName}</h5>
	// 			<DataTable value={data.profileList} responsiveLayout="scroll">
	// 				<Column field="profileType" header="Profile Type" />
	// 				<Column
	// 					field="districtName"
	// 					body={districtTemp}
	// 					header="District"
	// 				/>
	// 				<Column body={profileStatus} header="Status" />
	// 				<Column
	// 					field="createdAt"
	// 					body={createdOnTemp}
	// 					header="Created On"
	// 				/>
	// 				<Column field="salesUser" body={tseuserName} header="TSE" />
	// 				<Column field="rmUser" body={rmuserName} header="RM/ASM" />
	// 				<Column
	// 					field="action"
	// 					header="Action"
	// 					body={actionTemplate}
	// 				/>
	// 			</DataTable>
	// 		</div>
	// 	);
	// };

	const agentProfileFooter = () => {
		const items = [
			{
				label: "Approve",
				command: () => {
					chengeStatus("VERIFIED");
				},
			},
			{
				label: "Temporary Suspended",
				command: () => {
					chengeStatus("TEMPORARY_SUSPENDED");
				},
			},
			{
				label: "Block",
				command: () => {
					chengeStatus("BLOCKED");
				},
			},
		];
		const verified = [
			{
				label: "Temporary Suspended",
				command: () => {
					chengeStatus("TEMPORARY_SUSPENDED");
				},
			},
			{
				label: "Block",
				command: () => {
					chengeStatus("BLOCKED");
				},
			},
		];
		return (
			<div className="flex justify-content-end">
				<Button
					icon="pi pi-times"
					label="Cancel"
					onClick={() => {
						setprofileDetailDialog(false);
					}}
					className="danger-btn mr-2"
				/>
				<SplitButton
					label="Change Status"
					icon="pi pi-save"
					className="primary-btn"
					model={
						agent.data.profile &&
						agent.data.profile.panNumber &&
						agent.data.profile.aadharFullName &&
						agent.data.profile.selfieFileName !== ""
							? items
							: verified
					}
				/>
			</div>
		);
	};

	const handleRelocateChange = (e, setFieldValue) => {
		setFieldValue("userType", e.value, true);
		setFieldValue("userData", "", true);
		dispatch({ type: "LIST", payload: [] });
		const body = {
			agentProfileId: state.agentId,
			rolePosition: e.value,
		};
		dispatch({ type: "SPINNER", payload: true });
		agentService
			.getRmAsm(body)
			.then((res) => {
				dispatch({ type: "LIST", payload: res });
			})
			.catch(() => {})
			.finally(() => dispatch({ type: "SPINNER", payload: false }));
	};
	const handleReallocateSubmit = (value) => {
		const payload = {
			profileId: [state.agentId],
			userName: value.userData,
		};
		dispatch({ type: "LOADER", payload: true });
		agentService
			.addUnallocated(payload)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					life: 3000,
				});
				searchDispatch({ type: Config.SEARCH_CONFIG.reset });
			})
			.catch(() => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Error",
					life: 3000,
				});
			})
			.finally(() => {
				dispatch({ type: "LOADER", payload: false });
				dispatch({ type: "DIALOG", payload: false });
			});
	};
	const newData =
		agents.data.data &&
		agents.data.data.map((elm) => ({
			"Agent Id": elm.agentId,
			"Agent Code": elm.agentCode,
			Name:
				(elm.firstName ? elm.firstName : "") +
				" " +
				(elm.middleName ? elm.middleName : "") +
				" " +
				(elm.lastName ? elm.lastName : ""),

			"Email Id": elm.emailId,
			"Mobile Number": elm.mobileNumber,
			"Zone Name": elm.zoneName,
			"State Name": elm.stateName,
			"District Name": elm.districtName,

			Gender: elm.gender,
			"User Name": elm.userName,

			Status: elm.status,
			"Profile Id": elm.profileId,
			"Profile Type": elm.profileType,
			"Profile Status": elm.profileStatus,
			"Is Default": elm.isDefault,
			Pincode: elm.pincode,
			Remarks: elm.remarks,

			"Created At": elm.createdAt,
			"Sales User": elm.salesUser,
			"Rm User": elm.rmUser,
			"Selfie Verified": elm.selfieVerified,
			"Pan Verified": elm.panVerified,
			"aadhaar Verified": elm.aadhaarVerified,
		}));
	const handleAgentStatus = (id, value, index, data) => {
		setLoading(true);
		vendorService
			.agentStatusUpdateFromProfile({
				profileId: id,
				agentEngagement: value,
			})
			.then(() => {
				let _products = [...agents?.data?.data];
				const dataObject = { ...data, agentEngagement: value };
				_products[index] = dataObject;
				const anotherData = { ...agents?.data, data: _products };
				setAgents({
					type: Config.FETCH_CONFIG.success,
					payload: anotherData,
				});
			})
			.catch(() => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Error",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoading(false));
	};
	const acceptCall = () => {
		setLoading(true);
		ccService
			.sendAgentCall(callDialog.id)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: res?.message,
					style: { color: "#000000" },
					life: 3000,
				});
				// getAgents();
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoading(false);
				setCallDialog((elm) => ({ ...elm, state: false }));
			});
	};
	const rejectCall = () => {
		setCallDialog((e) => ({
			...e,
			state: false,
			id: null,
		}));
	};
	const handleSearch = (val) => {
		const data = {
			agentId: allocateAgentDialog.id,
			userName: val.userName,
		};
		setLoading(true);
		ccService
			.allocateAgentTsrm(data)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: res?.message,
					style: { color: "#000000" },
					life: 3000,
				});
				setAllocateAgentDialog((elm) => ({ ...elm, state: false }));
				getAgents();
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			{/* <Dialog
				visible={agentCommissionDialog.dialog}
				onHide={() => {
					setAgentCommissionDialog({
						dialog: false,
						agentProfileId: null,
					});
					// setAgentCommissionId(null);
				}}
				style={{ width: "60%" }}
			>
				<ProductWiseCategory
					id={agentCommissionDialog.agentProfileId}
				/>
			</Dialog> */}
			{loading && <Loader />}
			<Dialog
				header="Assign TSE"
				visible={assignState.dialog}
				style={{ width: "30%" }}
				breakpoints={{ "960px": "90vw" }}
				onHide={() =>
					assignDispatch({ type: "assDialog", payload: false })
				}
			>
				{/* <AssignTse
					profileId={assignState.profileId}
					assignDialogeClose={assignDialogeClose}
					searchDispatch={searchDispatch}
				/> */}
			</Dialog>
			<SearchCCAgent
				searchDispatch={searchDispatch}
				newData={newData}
				selectedProducts={selectedProducts}
				setAllocateAgentDialog={setAllocateAgentDialog}
				setReminderDetailDialog={setReminderDetailDialog}
			/>
			<div className="grid">
				{agents.isLoading ? (
					<div className="col-12">
						<TableLoader
							itaration={itaration}
							headerGroup={headerGroup}
						/>
					</div>
				) : (
					agents.data.data && (
						<>
							{agents.data.data &&
								agents.data.data.length > 10 && (
									<Paginator
										totalElements={
											agents.data.totalElements
										}
										pageSize={agents.data.pageSize}
										firstPage={agents.data.firstPage}
										lastPage={agents.data.lastPage}
										decrement={decrement}
										increment={increment}
										pagesizechange={pagesizechange}
										pageNo={agents.data.pageNo}
										totalPages={agents.data.totalPages}
									/>
								)}
							<DataTable
								className="col-12"
								headerColumnGroup={headerGroup}
								// value={agents.data.data}
								value={
									agents.data.data &&
									agents.data.data.map((item, index) => ({
										...item,
										index:
											agents.data.pageSize *
												agents.data.pageNo -
											agents.data.pageSize +
											1 +
											index,
									}))
								}
								// expandedRows={expandedRows.data}
								// onRowToggle={(e) =>
								// 	setExpandedRows({ payload: e.data })
								// }
								// rowExpansionTemplate={rowExpansionTemplate}
								responsiveLayout="scroll"
								// dataKey="agentId"
								breakpoint="960px"
								dataKey="agentId"
								selection={selectedProducts}
								selectionMode="checkbox"
								onSelectionChange={(e) =>
									setSelectedProducts(e.value)
								}
							>
								<Column
									header={"Select"}
									selectionMode={"multiple"}
									headerStyle={{ width: "3rem" }}
								/>
								{/* <Column
									expander={allowExpansion}
									style={{ width: "3em" }}
								/> */}
								<Column field="index" />
								<Column
									body={(item) => {
										return (
											<Button
												className="p-button-text p-0 m-0"
												onClick={() =>
													navigate(
														`/vendor/agent-profile/${item.agentId}`,
														{
															state: {
																profileId:
																	item.profileId,
															},
														}
													)
												}
												label={item?.agentCode}
											/>
										);
									}}
									// field="agentCode"
								/>
								<Column body={nameTemplate} />
								<Column field="profileType" />
								{/* <Column
									field="districtName"
									body={districtTemp}
									header="District"
								/> */}
								<Column field="mobileNumber" />
								{/* <Column field="emailId" />
								<Column
									field="gender"
									bodyClassName="capitalize"
								/> */}
								<Column field="userName" />
								<Column
									field="createdAt"
									body={createdOnTemp}
								/>
								<Column
									body={(elm, option) => {
										const items = [
											{
												label: "Featured",
												value: "FEATURED",
											},
											{
												label: "Engaged",
												value: "ENGAGED",
											},
											{
												label: "Dormant",
												value: "DORMANT",
											},
											{
												label: "Follow Up",
												value: "FOLLOW_UP",
											},
										].map((x) => ({
											label: x.label,
											command: () =>
												handleAgentStatus(
													elm.profileId,
													x.value,
													option.rowIndex,
													elm
												),
										}));
										switch (elm?.agentEngagement) {
											case "FEATURED":
												return (
													<span className="text-green-800 bg-green-200 flex gap-1 justify-content-between align-items-center border-round-sm py-1 px-2">
														Featured
														<MenuComponent
															textcolor={
																"text-blue-50"
															}
															icon={
																"pi pi-chevron-down"
															}
															items={items}
														/>
													</span>
												);

											case "ENGAGED":
												return (
													<span className="text-blue-800 bg-blue-200 flex gap-1 justify-content-between align-items-center border-round-sm py-1 px-2">
														Engaged
														<MenuComponent
															textcolor={
																"text-blue-50"
															}
															icon={
																"pi pi-chevron-down"
															}
															items={items}
														/>
													</span>
												);

											case "DORMANT":
												return (
													<span className="status text-red-800 bg-red-200 flex gap-1 justify-content-between align-items-center border-round-sm py-1 px-2">
														Dormant
														<MenuComponent
															textcolor={
																"text-blue-50"
															}
															icon={
																"pi pi-chevron-down"
															}
															items={items}
														/>
													</span>
												);
											case "FOLLOW_UP":
												return (
													<span className="status text-yellow-800 bg-yellow-200 flex gap-1 justify-content-between align-items-center border-round-sm py-1 px-2">
														Follow Up
														<MenuComponent
															textcolor={
																"text-blue-50"
															}
															icon={
																"pi pi-chevron-down"
															}
															items={items}
														/>
													</span>
												);

											default:
												return (
													<span className="status text-yellow-800 bg-yellow-200 flex gap-1 justify-content-between align-items-center border-round-sm py-1 px-2">
														Select Status
														<MenuComponent
															textcolor={
																"text-blue-50"
															}
															icon={
																"pi pi-chevron-down"
															}
															items={items}
														/>
													</span>
												);
										}
									}}
									header="Tag"
								/>
								<Column
									field="rmUser"
									body={rmuserName}
									header="RM/ASM"
								/>
								<Column
									body={(elm) =>
										elm?.lastCalled
											? moment(elm.lastCalled).format(
													"Do MMM,YYYY HH:mm A"
											  )
											: ""
									}
								/>
								<Column field={"zoneName"} />
								<Column field={"stateName"} />
								<Column field={"districtName"} />

								<Column body={agentStatus} />

								<Column body={profileStatus} />
								<Column
									body={(item) => (
										<Button
											onClick={() =>
												setNoteDetailDialog((prev) => ({
													...prev,
													state: true,
													id: [item.agentId],
												}))
											}
											className={`icon-btn p-button-text ${
												item?.notes
													? "p-button-success"
													: "p-button-danger"
											}`}
											icon={"pi pi-info-circle"}
											tooltip={
												item?.notes
													? item?.notes
													: "No Note"
											}
											tooltipOptions={{
												position: "left",
											}}
										/>
									)}
								/>
								{selectedProducts?.length === 0 && (
									<Column body={agentChangePassword} />
								)}
							</DataTable>

							{agents.data.data &&
								agents.data.data.length > 10 && (
									<Paginator
										totalElements={
											agents.data.totalElements
										}
										pageSize={agents.data.pageSize}
										firstPage={agents.data.firstPage}
										lastPage={agents.data.lastPage}
										decrement={decrement}
										increment={increment}
										pagesizechange={pagesizechange}
										pageNo={agents.data.pageNo}
										totalPages={agents.data.totalPages}
									/>
								)}
						</>
					)
				)}
			</div>
			<ConfirmDialog
				visible={callDialog.state}
				onHide={() =>
					setCallDialog((e) => ({
						...e,
						state: false,
						id: null,
					}))
				}
				message="Are you sure you want to proceed?"
				header="Confirmation"
				icon="pi pi-exclamation-triangle"
				accept={acceptCall}
				reject={rejectCall}
			/>
			{/* for dialog modal popup */}
			{agent.isLoading && <Loader />}
			<Dialog
				header={"Note"}
				style={{ width: "40%" }}
				visible={noteDetailDialog.state}
				onHide={() =>
					setNoteDetailDialog((prev) => ({
						...prev,
						state: false,

						id: null,
					}))
				}
			>
				<Formik
					initialValues={{ notes: noteDetailDialog.note }}
					onSubmit={handleNoteSubmit}
				>
					{({ handleSubmit, resetForm }) => (
						<Form
							className="modal-form-grid border-round-md col-12 mt-2 p-4 form-grid grid"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 ">
								<Field
									required
									header={"Note"}
									name={"notes"}
									component={FieldTextArea}
								/>
							</div>
							<div className="col-12 flex align-items-end justify-content-end">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Save" />
							</div>
						</Form>
					)}
				</Formik>
			</Dialog>
			<div className="dialog-demo">
				<div className="card ">
					<Dialog
						header="Agent Profile Details"
						visible={profileDetailDialog}
						onHide={() => {
							setprofileDetailDialog(false);
							// setSpinner(false);
						}}
						breakpoints={{ "960px": "97vw" }}
						style={{ width: "65vw" }}
						footer={agentProfileFooter}
					>
						<div className="w-full">
							{agent.data ? (
								<AgentProfileComponent
									handleCall={getAgents}
									agent={agent.data}
									getProfileEdit={getProfileEdit}
								/>
							) : null}
						</div>
					</Dialog>
				</div>
			</div>
			<Dialog
				header={"Set Reminder"}
				style={{ width: "40%" }}
				visible={reminderDialog.state}
				onHide={() =>
					setReminderDetailDialog((prev) => ({
						...prev,
						state: false,
						id: null,
					}))
				}
			>
				<Suspense fallback={null}>
					<AgentReminder handleReminder={handleReminder} />
				</Suspense>
			</Dialog>
			{agentEdit.isLoading && <Loader />}
			<div className="dialog-demo">
				<div className="card ">
					<Dialog
						header="Agent Profile Edit"
						visible={profileEditDialog}
						onHide={() => {
							setProfileEditDialog(false);
							// setSpinner(false);
						}}
						breakpoints={{ "960px": "97vw" }}
						style={{ width: "65vw" }}
					>
						<div className="w-full">
							{agentEdit.data && (
								<AgentProfileEditDialoge
									agentEdit={agentEdit.data}
									dialogeClose={dialogeClose}
								/>
							)}
						</div>
					</Dialog>
				</div>
			</div>
			<div className="dialog-demo">
				<div className="card ">
					<Dialog
						header="Allocate TSRM"
						visible={allocateAgentDialog.state}
						onHide={() => {
							setAllocateAgentDialog((prev) => ({
								...prev,
								state: false,
								id: null,
							}));
							// setSpinner(false);
						}}
						breakpoints={{ "960px": "97vw" }}
						style={{ width: "65vw" }}
					>
						<div className="w-full">
							<AllocateAgentForm handleSearch={handleSearch} />
						</div>
					</Dialog>
				</div>
			</div>
			<div className="dialog-demo">
				<div className="card ">
					<Dialog
						header="Agent Password Change"
						visible={agentPasswordDialog}
						onHide={() => {
							setAgentPasswordDialog(false);
							// setSpinner(false);
						}}
					>
						<div className="card modal-form-grid">
							<Formik
								onSubmit={handleChangePassword}
								initialValues={{
									newPassword: "",
									cnfNewPassword: "",
								}}
								validationSchema={validationSchema}
								enableReinitialize
							>
								{({ handleSubmit }) => (
									<Form>
										<div className="form-grid grid">
											<div className="field col-12 md:col-4">
												<Field
													header={"Password"}
													name={"newPassword"}
													component={FieldPassword}
												/>
											</div>
											<div className="field col-12 md:col-4">
												<Field
													header={"Confirm Password"}
													name={"cnfNewPassword"}
													component={FieldPassword}
												/>
											</div>
											<div className="col-12 flex align-items-end justify-content-end">
												<Button
													type="button"
													label="Save "
													icon="pi pi-save"
													onClick={handleSubmit}
													loading={loading}
												/>
											</div>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</Dialog>
				</div>
			</div>
			<div className="dialog-demo">
				<div className="card ">
					<Dialog
						header="Re-allocate RM/ASM"
						visible={state.dialog}
						style={{ width: "30%" }}
						onHide={() => {
							dispatch({ type: "DIALOG", payload: false });

							// setSpinner(false);
						}}
					>
						<div className="card modal-form-grid">
							<div className="pb-5">
								<Formik
									initialValues={{
										userType: "",
										userData: "",
									}}
									onSubmit={handleReallocateSubmit}
								>
									{({
										handleSubmit,
										setFieldValue,
										values,
									}) => (
										<Form
											onSubmit={handleSubmit}
											className="form-grid grid"
										>
											{isSuccess && (
												<div className="field col-12">
													<Field
														header={"Select RM/ASM"}
														name={"userType"}
														component={
															FeildDropdown
														}
														onChange={(e) => {
															handleRelocateChange(
																e,
																setFieldValue
															);
														}}
														optionLabel={"name"}
														optionValue={"position"}
														options={roleData.filter(
															(item) =>
																item.position ===
																	3 ||
																item.position ===
																	4
														)}
													/>
												</div>
											)}
											{state.spinner ? (
												<Skeleton className="field col-12 h-3rem " />
											) : (
												state.userList.length && (
													<div className="field col-12">
														<Field
															header={
																"Select User"
															}
															name={"userData"}
															component={
																FeildDropdown
															}
															optionLabel={"name"}
															optionValue={
																"userName"
															}
															options={state.userList.map(
																(item) => ({
																	...item,
																	name: item.firstName
																		? item.firstName
																		: "" +
																		  item.middleName
																		? item.middleName
																		: "" +
																		  item.lastName
																		? item.lastName
																		: "",
																})
															)}
														/>
														<Button
															type="button"
															label="Save "
															icon="pi pi-save"
															className="w-full mt-2"
															loading={
																state.loader
															}
															onClick={
																handleSubmit
															}
															disabled={
																values.userData
																	? false
																	: true
															}
														/>
													</div>
												)
											)}
										</Form>
									)}
								</Formik>
							</div>
						</div>
					</Dialog>
				</div>
			</div>
			<Dialog
				style={{ width: "60%" }}
				visible={noteDialog}
				header={"Activity"}
				onHide={() => {
					setIsFetched(false);
					setNoteDialog(false);
				}}
			>
				<DataTable value={activityData}>
					<Column
						body={(item) => {
							switch (item?.activity) {
								case "LOGIN":
									return "Login";
								case "PAUSE":
									return "MH-Close";
								case "RESUME":
									return "MH-Open";
								case "LOGOUT":
									return "Logout";
								default:
									return item?.activity;
							}
						}}
						header={"Activity"}
					/>
					<Column
						body={(elm) =>
							elm.transactionDateTime
								? moment(elm.transactionDateTime).format(
										"DD MMM,YYYY"
								  )
								: ""
						}
						// field="transactionDateTime"
						header={"Transaction Date Time"}
					/>
					<Column
						body={(item) =>
							item?.duration
								? moment
										.utc(item?.duration * 1000)
										.format("HH:mm:ss")
								: ""
						}
						header={"Duration"}
					/>
				</DataTable>
			</Dialog>
			<Dialog
				style={{ width: "50%" }}
				header={"Call Log"}
				visible={callLogDialog}
				onHide={() => {
					setCallLogDialog(false);
					setCalllog([]);
				}}
			>
				<div className="modal-form-grid">
					{isFetched && (
						<>
							{calllog?.length > 0 ? (
								<DataTable value={calllog}>
									<Column
										header={"Contacted On"}
										body={(elm) =>
											elm?.createdOn
												? moment(elm?.createdOn).format(
														"Do MMM, YYYY HH:mm A"
												  )
												: ""
										}
										field="createdOn"
									/>

									<Column
										header={"Contacted By"}
										body={(elm) =>
											(elm.createdByFirstName
												? elm.createdByFirstName
												: "") +
											" " +
											(elm.createdByLastName
												? elm.createdByLastName
												: "") +
											"(" +
											elm.createdBy +
											")"
										}
										// field="createdBy"
									/>
								</DataTable>
							) : (
								<h5 className="text-center">No History</h5>
							)}
						</>
					)}
				</div>
			</Dialog>
			<Dialog
				visible={reminderListDialog.state}
				header={"Reminder"}
				onHide={() => {
					setReminderListDialog((prev) => ({
						...prev,
						state: false,
						agentId: null,
					}));
				}}
			>
				<ReminderList agentId={reminderListDialog.agentId} />
			</Dialog>
		</>
	);
};

export default AgentsCCProfile;
