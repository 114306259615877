import React, { useEffect, useReducer, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";
import { BiErrorCircle } from "react-icons/bi";
import Loader from "../../../../components/Loader";
import Paginator from "../../../../components/Paginator";
import { AgentService } from "../../../../services/AgentService";
import TableNoData from "../../../../components/TableNoData";
import TableLoader from "../../../../components/TableLoader";
import { Dialog } from "primereact/dialog";
import AgentProfileComponent from "../../../../components/AgentProfile";
import { useSelector } from "react-redux";
import AgentAssignDialoge from "./AgentAssignDialoge";
import moment from "moment";
import { Button } from "primereact/button";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import Config from "../../../../shared/config";
import {
	searchInitialState,
	searchReducer,
} from "../../../../components/searchReducer";
import { useNavigate } from "react-router-dom";
const Listview = () => {
	const loginUserDetail = useSelector((state) => state.persistData.auth.user);
	const agentService = new AgentService();
	// const [members, setMembers] = useState(null);
	const navigate = useNavigate();
	const [members, setMembers] = useReducer(dataReducer, fetchInitialState);
	const [profileId, setProfileId] = useState(null);
	const [agentAssigDialog, setAgentAssigDialog] = useState(false);

	const toastTL = useRef(null);
	// const initialState = {
	// 	pageNo: 1,
	// 	pageSize: 10,
	// 	sortByColumn: null,
	// 	sortType: null,
	// 	searchValue: "",
	// 	spinner: false,
	// };

	// const reducer = (state, action) => {
	// 	switch (action.type) {
	// 		case "PAGEINC":
	// 			return { ...state, pageNo: state.pageNo + 1 };
	// 		case "PAGEDEC":
	// 			return { ...state, pageNo: state.pageNo - 1 };
	// 		case "PAGESIZE":
	// 			return { ...state, pageSize: action.payload };
	// 		case "SORTCOL":
	// 			return { ...state, sortByColumn: action.payload };
	// 		case "SORTTYPE":
	// 			return { ...state, sortType: action.payload };
	// 		case "SEARCH":
	// 			return { ...state, searchValue: action.payload };
	// 		case "SPINNER":
	// 			return { ...state, spinner: action.payload };
	// 		default:
	// 			return { state };
	// 	}
	// };

	// const [state, dispatch] = useReducer(reducer, initialState);
	const [state, dispatch] = useReducer(searchReducer, searchInitialState);

	const initialAgentDetails = {
		agent: null,
		profileDetailDialog: false,
		loader: false,
	};

	const agentReducer = (state, action) => {
		switch (action.type) {
			case "AGENT":
				return { ...state, agent: action.payload };
			case "PROFILE":
				return { ...state, profileDetailDialog: action.payload };
			case "LOADER":
				return { ...state, loader: action.payload };
			default:
				return state;
		}
	};

	const [agentState, agentdispatch] = useReducer(
		agentReducer,
		initialAgentDetails
	);

	const getMyAgents = () => {
		// dispatch({ type: "SPINNER", payload: true });
		setMembers({ type: Config.FETCH_CONFIG.start });
		// const requestBody = {
		// 	pageNo: state.pageNo,
		// 	pageSize: state.pageSize,
		// 	sorts: [],
		// };
		// if (state.sortByColumn && state.sortType) {
		// 	requestBody.sorts = [state.sortByColumn + "," + state.sortType];
		// }
		const requestBody = {
			pageNo: state.pageNo,
			pageSize: state.pageSize,
			...(state.searchValue !== "" && state.searchValue),
			...(state.sortByColumn && state.sortType
				? {
						sorts: [
							`${
								state.sortByColumn === "firstName"
									? `agent.${state.sortByColumn}`
									: state.sortByColumn
							},${state.sortType}`,
						],
				  }
				: { sorts: [] }),
		};
		agentService
			.agentAllocationist(requestBody)
			.then((res) => {
				// setMembers(res);
				// dispatch({ type: "SPINNER", payload: false });
				setMembers({ type: Config.FETCH_CONFIG.success, payload: res });
			})
			.catch((e) => {
				// dispatch({ type: "SPINNER", payload: false });
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
				// setMembers({
				// 	data: [],
				// 	totalPages: 1,
				// 	totalElements: 0,
				// 	pageNo: 1,
				// 	pageSize: 1,
				// 	firstPage: true,
				// 	lastPage: true,
				// });
			})
			.finally(() =>
				// dispatch({ type: "SPINNER", payload: false })
				setMembers({ type: Config.FETCH_CONFIG.stop })
			);
	};
	useEffect(() => {
		getMyAgents();
		// eslint-disable-next-line
	}, [state.pageSize, state.sortByColumn, state.sortType, state.pageNo]);

	const getProfileDetails = (id) => {
		agentService
			.getProfileDetails(id)
			.then((res) => {
				// setagent(res);
				// setprofileDetailDialog(true);
				// setSpinner(false);
				agentdispatch({ type: "AGENT", payload: res });
				agentdispatch({ type: "PROFILE", payload: true });
				agentdispatch({ type: "LOADER", payload: false });
			})
			.catch((e) => {
				agentdispatch({ type: "LOADER", payload: false });
			});
	};

	const agentAssignDialogeClose = (e) => {
		setAgentAssigDialog(e);
		toastTL.current.show({
			severity: "success",
			summary: "Success",
			detail: "Agent Assign Successfully",
			life: 3000,
		});
	};

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column
					header={getSortableColumn("Profile type", "profileType")}
				/>
				<Column header={getSortableColumn("Name", "firstName")} />
				<Column
					header={getSortableColumn("District", "districtName")}
				/>
				<Column header="Mobile no" />
				<Column header="Email" />
				<Column header="TSE" />
				<Column header="Created On" />
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const itaration = {
		profileType: "",
		firstName: "",
		pincode: "",
		mobileNO: "",
		email: "",
		action: "",
		actions: "",
		actionss: "",
	};
	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							state.sortByColumn === columnName &&
							state.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							state.sortByColumn === columnName &&
							state.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	function tblSort(columnName, sortType) {
		// dispatch({ type: "SORTCOL", payload: columnName });
		// dispatch({ type: "SORTTYPE", payload: sortType });
		dispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		dispatch({ type: Config.SEARCH_CONFIG.sortType, payload: sortType });
	}
	// const increment = () => {
	// 	dispatch({ type: "PAGEINC" });
	// };
	// const decrement = () => {
	// 	dispatch({ type: "PAGEDEC" });
	// };
	// const pagesizechange = (e) => {
	// 	dispatch({ type: "PAGESIZE", payload: e.target.value });
	// };
	const increment = () => {
		dispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		dispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const pagesizechange = (e) => {
		// const newPageNo = Math.ceil(
		// 	(members.data.pageSize * members.data.pageNumber) / e.target.value
		// );

		dispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		dispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};

	const nameTemplate = (item) => {
		return (
			<>{`${item.firstName ? item.firstName : ""} ${
				item.middleName ? item.middleName : ""
			} ${item.lastName ? item.lastName : ""}`}</>
		);
	};
	const seNameTemplate = (item) => {
		return <>{item.seUserName !== null ? item.seUserName : ""}</>;
	};
	const createOnTemplate = (item) => {
		return <>{moment(item.createdOn).format("Do MMM, YYYY")}</>;
	};

	// console.log(loginUserDetail);
	const agentAssign = (id) => {
		setProfileId(id);

		setAgentAssigDialog(true);
	};
	const actionTemplate = (item) => {
		return (
			<div className="flex">
				<Button
					className="icon-btn primary-btn mr-2"
					onClick={() => {
						agentdispatch({ type: "LOADER", payload: true });
						getProfileDetails(item.profileId);
					}}
					tooltip="Profile details"
					tooltipOptions={{ position: "left" }}
				>
					<BiErrorCircle />
				</Button>
				{loginUserDetail.role.position === 4 && !item.seUserName && (
					<Button
						className="icon-btn primary-btn mr-2"
						onClick={() => agentAssign(item.profileId)}
						tooltip="Assign"
						tooltipOptions={{ position: "left" }}
					>
						<i className="pi pi-tag" />
					</Button>
				)}
				<Button
					className="icon-btn p-button-success mr-2"
					onClick={() => {
						navigate(`/members/agent-commission`, { state: item });
						// navigate(`/members/agent-commission/${item.agentId}`);
					}}
					tooltip="Commission"
					tooltipOptions={{ position: "left" }}
					icon={"pi pi-shopping-bag"}
				/>
			</div>
		);
	};

	return (
		<>
			<div className="grid">
				<div className="col-12">
					<Toast ref={toastTL} position="top-left" />
					{members.isLoading ? (
						<TableLoader
							itaration={itaration}
							headerGroup={headerGroup}
						/>
					) : (
						<>
							{members.data.data &&
							members.data.data.length > 0 ? (
								<>
									<DataTable
										headerColumnGroup={headerGroup}
										value={members.data.data}
										responsiveLayout={"scroll"}
									>
										<Column field="profileType" />
										<Column
											field="name"
											body={nameTemplate}
										/>
										<Column field="districtName" />
										<Column field="mobileNumber" />
										<Column field="emailId" />
										<Column body={seNameTemplate} />
										<Column body={createOnTemplate} />

										<Column body={actionTemplate} />
									</DataTable>
									<Paginator
										pageSize={members.data.pageSize}
										firstPage={members.data.firstPage}
										lastPage={members.data.lastPage}
										decrement={decrement}
										increment={increment}
										pagesizechange={pagesizechange}
										pageNo={members.data.pageNo}
										totalPages={members.data.totalPages}
									/>
								</>
							) : (
								<TableNoData headerGroup={headerGroup} />
							)}
						</>
					)}
				</div>
			</div>
			{agentState.loader && <Loader />}

			<Dialog
				header="Assign Agent"
				visible={agentAssigDialog}
				style={{ width: "30%" }}
				breakpoints={{ "960px": "90vw" }}
				onHide={() => setAgentAssigDialog(false)}
			>
				<AgentAssignDialoge
					profileId={profileId}
					agentAssignDialogeClose={agentAssignDialogeClose}
					getMyAgents={getMyAgents}
				/>
			</Dialog>
			<div className="dialog-demo">
				<div className="card ">
					<Dialog
						header="Verify Agent Profile"
						visible={agentState.profileDetailDialog}
						onHide={() => {
							// setprofileDetailDialog(false);
							// setSpinner(false);
							agentdispatch({ type: "PROFILE", payload: false });
							agentdispatch({ type: "LOADER", payload: false });
						}}
						breakpoints={{ "960px": "97vw" }}
						style={{ width: "65vw" }}
					>
						<div className="w-full">
							{agentState.agent ? (
								<AgentProfileComponent
									agent={agentState.agent}
								/>
							) : null}
						</div>
					</Dialog>
				</div>
			</div>
		</>
	);
};

export default Listview;
