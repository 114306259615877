import axios from "axios";
import { agent_end_point_url } from "../shared/constant";
import { useSelector } from "react-redux";
export class UsersService {
	selector = useSelector((state) => state.persistData.auth);
	constructor() {
		// this.token = sessionStorage.getItem("token");
		this.token = this.selector.token;
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}

	getUser = async (requestBody) => {
		const url = `${agent_end_point_url}/user/search`;
		try {
			const response = await axios.post(url, requestBody, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getCallCenterUserList = async (requestBody) => {
		const url = `${agent_end_point_url}/call-center-user/search
`;
		try {
			const response = await axios.post(url, requestBody, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	getUserUpdate = async (requestBody) => {
		const url = `${agent_end_point_url}/user/update`;
		try {
			const response = await axios.post(url, requestBody, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	getUserEdit = async (username) => {
		const url = `${agent_end_point_url}/user/${username}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getcallCnterUserEdit = async (username) => {
		const url = `${agent_end_point_url}/call-center-users/${username}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	getRoles = async () => {
		const url = `${agent_end_point_url}/role`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	getZones = async () => {
		const url = `${agent_end_point_url}/zone`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	getUserByRolePosition = async (position) => {
		const url = `${agent_end_point_url}/user/role/${position}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getcallcenterUserByRolePosition = async (position) => {
		const url = `${agent_end_point_url}/call-center-user/${position}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	createUser = async (requestBody) => {
		const url = `${agent_end_point_url}/user/create`;
		try {
			const response = await axios.post(url, requestBody, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	createCallCenterUser = async (requestBody) => {
		const url = `${agent_end_point_url}/call-center-user/create`;
		try {
			const response = await axios.post(url, requestBody, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	updateCallCenterUser = async (requestBody) => {
		const url = `${agent_end_point_url}/call-center-user/update`;
		try {
			const response = await axios.post(url, requestBody, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};

	getStateByZones = async (zoneId) => {
		const url = `${agent_end_point_url}/state/${zoneId}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	getDistrictByState = async (stateID) => {
		const url = `${agent_end_point_url}/district/${stateID}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAgentProfile = async () => {
		const url = `${agent_end_point_url}/user/parentId`;
		try {
			const response = await axios.get(url, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getSeByRM = async (username) => {
		const url = `${agent_end_point_url}/child-user/${username}`;
		try {
			const response = await axios.get(url, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAgentById = async (agentId) => {
		const url = `${agent_end_point_url}/unallocated-agent/${agentId}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getChildUser = async (userName) => {
		const url = `${agent_end_point_url}/child-user/${userName}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getUsersAreaList = async (userName) => {
		const url = `${agent_end_point_url}/user-area/${userName}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getCallCenterUsersAreaList = async (userName) => {
		const url = `${agent_end_point_url}/call-center-user-area/${userName}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	kycVerification = async (payload) => {
		const url = `${agent_end_point_url}/kyc/update`;
		try {
			const response = await axios.post(url, payload, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	callCenterStatusUpdate = async (payload) => {
		const url = `${agent_end_point_url}/call-center-user/status-update`;
		try {
			const response = await axios.post(url, payload, {
				headers: this.headers,
			});
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	getZSMList = async (userName) => {
		const url = `${agent_end_point_url}/zsm/all`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	disableUser = async (id) => {
		const url = `${agent_end_point_url}/user/disable?userName=${id}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	enableUser = async (id) => {
		const url = `${agent_end_point_url}/user/enable?userName=${id}`;
		try {
			const response = await axios.get(url, { headers: this.headers });
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
}
