import axios from "axios";
import { agent_end_point_url, apiPath } from "../shared/constant";
import { useSelector } from "react-redux";

export class ReportService {
	selector = useSelector((state) => state.persistData.auth);
	constructor() {
		this.token = this.selector.token;
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}
	getAgentReportList = async (payload) => {
		try {
			const res = await axios.post(
				`${agent_end_point_url}/my-agents/search`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getCustomerList = async (payload) => {
		try {
			const response = await axios.post(
				`${agent_end_point_url}/customer/search`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
}
