import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";

import {
	FeildCalender,
	// FeildCalender,
	FeildDropdown,
	FieldCheckBox,
	FieldInput,
} from "../../../../components/FieldInput";
import Config from "../../../../shared/config";
import {
	useGetAllHubQuery,
	useGetProductDetailsQuery,
	useGetStateListQuery,
} from "../../../../services/query/queryApi";
import { LoanApplicationService } from "../../../../services/LoanApplication";
import { useEffect, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import SearchFieldGroup from "../../../../components/SearchFieldGroup";
import { useSelector } from "react-redux";
import { CallCenterService } from "../../../../services/callCenterService";

const SearchToggleContent = (props) => {
	const selector = useSelector((state) => state.persistData.auth.user);
	const [spokeList, setSpokeList] = useState([]);
	const [userList, setUserList] = useState([]);
	const [agentList, setAgentList] = useState([]);
	const ccService = new CallCenterService();
	const loanApplicationService = new LoanApplicationService();
	const { data = [] } = useGetStateListQuery();
	const { data: hubList = [] } = useGetAllHubQuery();
	const { data: productList = { data: [] } } = useGetProductDetailsQuery({
		searchRequest: [],
		pageNo: 1,
		pageSize: 1000,
		sorts: [],
	});
	const getSubProductList = createSelector(() => {
		let filterData = productList.data.map((item) => {
			if (item.isActive) {
				let tempSub = item.subProducts.filter(
					(elm) => elm.isActive === true
				);
				return {
					...item,
					subProducts: tempSub,
				};
			}
		});
		let data = filterData.filter((item) => {
			return item !== undefined;
		});
		return data;
	});
	const productData = getSubProductList();
	const getSpokeList = (e) => {
		ccService.getSpokeListById(e).then((res) => setSpokeList(res));
	};
	const statusDropdown = [
		{ name: "New", value: "NEW" },
		{ name: "Not Interested", value: "NOT_INTERESTED" },
		{ name: "Not Eligible", value: "NOT_ELIGIBLE" },
		{ name: "Follow Up", value: "FOLLOW_UP" },
		{ name: "Converted", value: "CONVERTED" },
		{ name: "Invalid", value: "INVALID" },
	];

	const initialValues = {
		number: "",
		allocatedTo: "",
		mobileNumber: "",
		status: "",
		productId: "",
		zoneId: "",
		stateId: "",
		districtId: "",
		submissionDateFrom: "",
		submissionDateTo: "",
		firstName: "",
		createdByAgent: "",
	};

	const handleFormSubmit = (value) => {
		const { productId, ...rest } = value;
		props.search({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: {
				...rest,
				productId: productId.id,
			},
		});
		props.search({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};
	const handleSpokeList = (e, setFieldValue) => {
		setFieldValue("hubId", e.value, true);
		setFieldValue("spokeId", "", true);
		getSpokeList(e.value);
	};

	const getTsrmBYHubSpoke = (val) => {
		ccService.getTsrmByHubIdspokeId(val).then((res) => {
			setUserList(res?.data);
		});
	};

	const getAgents = (val) => {
		ccService.getAgentList(val).then((res) => {
			setAgentList(res?.data);
		});
	};

	useEffect(() => {
		selector.role.position === 1000 &&
			getTsrmBYHubSpoke({
				pageNo: 1,
				pageSize: 1000000,
				hubId: null,
				spokeId: null,
			});

		(selector.role.position === 1000 || selector.role.position === 1030) &&
			getAgents({
				pageNo: 1,
				pageSize: 1000000,
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selector]);

	return (
		<div className="mb-3">
			<Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
				{({ handleSubmit, resetForm, values, setFieldValue }) => (
					<Form
						className="background-secondary c-search-form-box border-round-md form-grid grid"
						onSubmit={handleSubmit}
					>
						<div className="field col-12 md:col-3">
							<Field
								header={"From Date"}
								name={"submissionDateFrom"}
								component={FeildCalender}
							/>
						</div>

						<div className="field col-12 md:col-3">
							<Field
								header={"To Date"}
								name={"submissionDateTo"}
								component={FeildCalender}
							/>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								header={"Status"}
								name={"status"}
								component={FeildDropdown}
								options={statusDropdown}
								optionLabel={"name"}
							/>
						</div>
						{selector.role.position === 1000 && (
							<div className="field col-12 md:col-3">
								<Field
									header={"Assign to (TSRM)"}
									name={"allocatedTo"}
									filter
									component={FeildDropdown}
									options={userList.map((elm) => ({
										...elm,
										name:
											(elm?.firstName
												? elm?.firstName
												: "") +
											" " +
											(elm?.middleName
												? elm?.middleName
												: "") +
											" " +
											(elm?.lastName
												? elm?.lastName
												: "") +
											" (" +
											elm?.userName +
											")",
									}))}
									optionLabel="name"
									optionValue={"userName"}
								/>
							</div>
						)}
						<div className="field col-12 md:col-3">
							<Field
								header={"Name"}
								name={"firstName"}
								component={FieldInput}
							/>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								header={"Mobile No."}
								name={"mobileNumber"}
								component={FieldInput}
							/>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								header={"Lead Number."}
								name={"number"}
								component={FieldInput}
							/>
						</div>
						{productData?.length > 0 && (
							<div className="field col-12 md:col-3">
								<Field
									header={"Product"}
									name={"productId"}
									component={FeildDropdown}
									filter
									options={productData}
									optionLabel="title"
									optionGroupLabel="title"
									optionGroupChildren="subProducts"
								/>
							</div>
						)}
						{(selector.role.position === 1000 ||
							selector.role.position === 1030) && (
							<div className="field col-12 md:col-3">
								<Field
									header={"Created By (Agents)"}
									name={"createdByAgent"}
									filter
									component={FeildDropdown}
									options={agentList.map((elm) => ({
										...elm,
										name:
											(elm?.firstName
												? elm?.firstName
												: "") +
											" " +
											(elm?.middleName
												? elm?.middleName
												: "") +
											" " +
											(elm?.lastName
												? elm?.lastName
												: "") +
											" (" +
											elm?.agentCode +
											")",
									}))}
									optionLabel="name"
									optionValue={"agentCode"}
								/>
							</div>
						)}
						{selector.role.position === 1000 && (
							<>
								<SearchFieldGroup
									values={values}
									setFieldValue={setFieldValue}
									zoneField={"zoneId"}
									stateField={"stateId"}
									districtField={"districtId"}
								/>
								{/* <div className="field col-12 md:col-3">
										<Field
											header={"Hub"}
											name={"hubId"}
											filter
											component={FeildDropdown}
											onChange={(e) => {
												handleSpokeList(
													e,
													setFieldValue
												);
											}}
											options={hubList}
											optionLabel="name"
											optionValue={"id"}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Spoke"}
											name={"spokeId"}
											filter
											component={FeildDropdown}
											options={spokeList}
											optionLabel={"name"}
											optionValue={"id"}
										/>
									</div> */}
							</>
						)}
						{/* <div className="field col-12 md:col-3">
								<Field
									component={FeildDropdown}
									header={"State"}
									filter
									value={values.state}
									name={"state"}
									onChange={(e) =>
										getDistrict(e, setFieldValue)
									}
									options={data.map((item) => ({
										...item,
										value: item.id,
									}))}
									optionLabel="name"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									filter
									header={"District"}
									name={"districtId"}
									component={FeildDropdown}
									options={districtList}
									optionLabel="name"
								/>
							</div> */}

						<div className="field col-12 flex justify-content-between">
							<div>
								{selector.role.position === 1000 && (
									<Field
										header={"Unassign TSRM"}
										name={"unassignTsrm"}
										component={FieldCheckBox}
									/>
								)}
							</div>
							<div className="flex justify-content-end align-items-center col-5">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Search" />
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default SearchToggleContent;
