import axios from "axios";
import { agent_end_point_url, apiPath } from "../shared/constant";

export class LeadService {
	constructor() {
		this.token = sessionStorage.getItem("token");
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}

	// Get submitted leads  according to payload
	// @method: POST
	getSubmittedLead = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/lead/submitted",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getRecivedLead = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/lead/received",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getSocialLead = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/lead-center/search",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	documentUpload = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/lead-document/save",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	createLead = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/lead/create",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	updateLead = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/lead/update",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getLeadDetails = async (payload) => {
		try {
			const response = await axios.get(
				`${agent_end_point_url}/lead/get/${payload}`,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	varifyPin = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/pincode/validate",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getStatusHistory = async (payload) => {
		try {
			const response = await axios.get(
				agent_end_point_url + `/lead/${payload}`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	updateStatus = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/lead/status",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getUnallocatedLead = async (payload) => {
		try {
			const response = await axios.get(
				agent_end_point_url + `/lead/unallocated-lead/${payload}`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getLeadDocument = async (payload) => {
		try {
			const response = await axios.get(
				agent_end_point_url + `/lead-document/by-lead/${payload}`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getRmList = async (payload) => {
		try {
			const response = await axios.get(
				agent_end_point_url + `/rm-user/${payload}`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	allocateLead = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/lead/allocate/lead",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAgent = async (userName) => {
		try {
			const response = await axios.get(
				agent_end_point_url + `/my-agent/${userName}`,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	downloadLeadExcel = async () => {
		try {
			const res = await axios.get(
				`${agent_end_point_url}/lead/generate-excel`,

				{
					headers: this.headers,
				}
				// headers: {
				// 		"Content-Type": "multipart/form-data",
				// 		Authorization: `Bearer ${this.token}`,
				// 	},
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	bulkLeadCreation = async (payload) => {
		try {
			const res = await axios.post(
				`${agent_end_point_url}/lead/bulkcreatelead`,
				payload,
				{
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${this.token}`,
					},
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	bulkLeadUpdate = async (payload) => {
		try {
			const res = await axios.post(
				`${agent_end_point_url}/lead/bulkcreatelead-save`,
				payload,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	bulkLeadStatusUpdate = async (payload) => {
		try {
			const res = await axios.post(
				`${agent_end_point_url}/lead/bulk-lead-status-save`,
				payload,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	bulkLeadStatusFileUpdate = async (payload) => {
		try {
			const res = await axios.post(
				`${agent_end_point_url}/lead/bulk-lead-status`,
				payload,

				{
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${this.token}`,
					},
				}
			);
			return res.data;
		} catch (error) {
			throw error.response.data;
		}
	};

	leadAllocates = async (payload) => {
		try {
			const res = await axios.post(
				`${agent_end_point_url}/vendor/lead/allocate`,
				payload,

				{
					headers: this.headers,
				}
			);
			return res.message;
		} catch (error) {
			throw error.response.data;
		}
	};
	leadDocsDelete = async (payload) => {
		try {
			const res = await axios.get(
				`${agent_end_point_url}/lead-document/delete/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.message;
		} catch (error) {
			throw error.response.data;
		}
	};
	// getAgentleaddailyStatus = async (payload) => {
	// 	console.log(this.token);
	// 	try {
	// 		const res = await axios.get(
	// 			`${agent_end_point_url}/report/agent-lead-daily-status-report-vendor/${payload}`,

	// 			{
	// 				method: "GET",
	// 				headers: {
	// 					"Content-Type": "multipart/form-data",
	// 					Authorization: `Bearer ${this.token}`,
	// 				},
	// 			}
	// 		);
	// 		return res.data;
	// 	} catch (error) {
	// 		throw error.response.data;
	// 	}
	// };
	getVendorAgentleadDailyStatusReport = async (payload) => {
		try {
			const res = await fetch(
				`${agent_end_point_url}/report/agent-lead-daily-status-report-vendor/${payload}`,
				{
					headers: this.headers,
				}
			);
			return res;
		} catch (error) {
			throw error.response.data;
		}
	};
	getAgentleadMonthlyReport = async (payload) => {
		try {
			const res = await fetch(
				`${agent_end_point_url}/report/agent-lead-pass-to-tsrm-monthly-report/${payload}`,
				{
					headers: this.headers,
				}
			);
			return res;
		} catch (error) {
			throw error.response.data;
		}
	};
}
