import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import {
	FieldChips,
	FieldMultiselect,
} from "../../../../components/FieldInput";
import { useGetAllDIstrictQuery } from "../../../../services/query/queryApi";

import { Slider } from "primereact/slider";
import MultiCheckBox from "../../../../components/MultiCheckBox";
import Config from "../../../../shared/config";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { CampaignService } from "../../../../services/CampaignService";
import { Toast } from "primereact/toast";
import Loader from "../../../../components/Loader";
const DemograpicFilter = (props) => {
	const campaignService = new CampaignService();
	const { data = [] } = useGetAllDIstrictQuery();
	const [spinner, setSpinner] = useState(false);
	const toast = useRef(null);
	const initialValue = {
		id: props.stages.campaignId,
		state: [],
		district: [],
		pincode: [],
		age: [18, 60],
		maritalStatus: [],
		qualifications: [],
		gender: [],
		monthlyIncome: [10, 100],
		professions: [],
		typeOfCompany: [],
		residenceType: [],
	};
	const handleNext = () => {
		props.setStages({
			type: "stage",
			payload: {
				sequence: 3,
				status: "active",
			},
		});
		props.setStages({
			type: "stage",
			payload: {
				sequence: 2,
				status: "success",
			},
		});
		props.setStages({ type: "active", active: 3 });
	};
	const filterarr = (arr, extra) => {
		const anotherArr = arr.map((item) => ({
			[extra]: item,
		}));

		return anotherArr;
	};
	const handleFormSubmit = (val) => {
		setSpinner(true);
		let requestBody = {
			id: val.id,
			minAge: val.age[0],
			maxAge: val.age[1],
			minMonthlyIncome: val.monthlyIncome[0] * 1000,
			maxMonthlyIncome: val.monthlyIncome[1] * 1000,
			residenceType: filterarr(val.residenceType, "residence"),
			maritalStatus: val.maritalStatus.map((item) => ({
				maritalStatus: item,
			})),
			gender: val.gender.map((item) => ({
				gender: item,
			})),
			qualifications: filterarr(
				val.qualifications,
				"qualificationStatus"
			),
			professions: filterarr(val.professions, "profession"),
			typeOfCompany: filterarr(val.typeOfCompany, "companyType"),
			state: val.state.map((item) => ({
				stateId: item.id,
			})),
			district: val.district.map((item) => ({
				districtId: item,
			})),
			pincode: val.pincode.map((item) => ({
				pincode: item,
			})),
		};
		// console.log(requestBody);
		if (props.id) {
			campaignService
				.demographicUpdate(requestBody)
				.then((res) => {
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: "Saved",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.catch((e) =>
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => setSpinner(false));
		} else {
			campaignService
				.demographicCreate(requestBody)
				.then((res) => {
					toast.current.show({
						severity: "success",
						summary: "Success",
						detail: "Saved",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.catch((e) =>
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => setSpinner(false));
		}
	};

	return (
		<>
			<Toast ref={toast} />
			{spinner && <Loader />}

			<Formik
				initialValues={{ ...initialValue, ...(props.id && props.data) }}
				onSubmit={handleFormSubmit}
				enableReinitialize
			>
				{({ handleSubmit, values, setFieldValue }) => (
					<Form onSubmit={handleSubmit} className="grid form-grid">
						<div className="field col-12">
							<Divider>
								<h3>Resident Type</h3>
							</Divider>
							<MultiCheckBox
								values={values}
								setFieldValue={setFieldValue}
								name={"residenceType"}
								checkboxData={Config.RESIDENTTYPE}
							/>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								filter
								header={"State"}
								name={"state"}
								component={FieldMultiselect}
								optionLabel={"name"}
								options={data}
							/>
						</div>

						{values.state && (
							<div className="field col-12 md:col-3">
								<Field
									filter
									header={"District"}
									name={"district"}
									component={FieldMultiselect}
									optionLabel={"name"}
									options={values.state}
									optionGroupLabel="name"
									optionGroupChildren="districtList"
									optionValue={"id"}
								/>
							</div>
						)}
						<div className="field col-12 md:col-3">
							<Field
								className={"w-full"}
								header={"PIN"}
								name={"pincode"}
								component={FieldChips}
							/>
						</div>

						<div className="field col-12 md:col-3 ">
							<Divider>
								<strong>Age</strong>
							</Divider>
							<div className="flex align-items-center justify-content-evenly">
								<span>{values.age[0]}</span>
								<Slider
									name="age"
									value={values.age}
									onChange={(e) =>
										setFieldValue("age", e.value)
									}
									className="w-14rem"
									range
								/>
								<span>{values.age[1]}</span>
							</div>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								header={"Martial Status"}
								name={"maritalStatus"}
								component={FieldMultiselect}
								options={Config.MARITAL_STATUS}
							/>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								header={"Gender"}
								name={"gender"}
								component={FieldMultiselect}
								options={Config.GENDERS}
							/>
						</div>
						<div className="field col-12 md:col-6">
							<Divider>
								<strong>Monthly Income</strong>
							</Divider>
							<div className="flex align-items-center justify-content-between">
								<span>
									{new Intl.NumberFormat("en-EN", {
										style: "currency",
										currency: "INR",
									}).format(values.monthlyIncome[0] * 1000)}
								</span>
								<Slider
									name="monthlyIncome"
									value={values.monthlyIncome}
									onChange={(e) =>
										setFieldValue("monthlyIncome", e.value)
									}
									className="w-25rem"
									range
								/>
								<span>
									{new Intl.NumberFormat("en-EN", {
										style: "currency",
										currency: "INR",
									}).format(values.monthlyIncome[1] * 1000)}
								</span>
							</div>
						</div>
						<div className="field col-12">
							<Divider>
								<h3>Qualification</h3>
							</Divider>
							<MultiCheckBox
								values={values}
								setFieldValue={setFieldValue}
								name={"qualifications"}
								checkboxData={Config.QUALIFICATION}
							/>
						</div>
						<div className="field col-12">
							<Divider>
								<h3>Profession</h3>
							</Divider>
							<MultiCheckBox
								values={values}
								setFieldValue={setFieldValue}
								name={"professions"}
								checkboxData={Config.OCCUPATION}
							/>
						</div>
						<div className="field col-12">
							<Divider>
								<h3>Type Of Company</h3>
							</Divider>
							<MultiCheckBox
								values={values}
								setFieldValue={setFieldValue}
								name={"typeOfCompany"}
								checkboxData={Config.ORGANIZATION}
							/>
						</div>
						<div className="flex col-12 justify-content-end align-items-center w-full gap-2 mt-4">
							{!props.stages.id && (
								<Button
									type="button"
									label="Back"
									icon="pi pi-angle-left"
									className="danger-btn"
									onClick={() => {
										props.setStages({
											type: "active",
											active: 1,
										});
										props.setStages({
											type: "stage",
											payload: {
												sequence: 1,
												status: "active",
											},
										});
										props.setStages({
											type: "stage",
											payload: {
												sequence: 2,
												status: "inactive",
											},
										});
										props.setStages({
											type: "stage",
											payload: {
												sequence: 3,
												status: "inactive",
											},
										});
									}}
								/>
							)}
							{/* <Button type="submit">
							Save & Next <i className="pi pi-angle-right"></i>
						</Button> */}
							<Button
								type="submit"
								label="Save"
								icon="pi pi-save"
							/>
							<Button
								label="Next"
								icon="pi pi-angle-right"
								onClick={handleNext}
								type="button"
							/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default DemograpicFilter;
