import { useState } from "react";
import { Button } from "primereact/button";
import { Field, Form, Formik } from "formik";
import Config from "../../../../../shared/config";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
} from "../../../../../components/FieldInput";
import moment from "moment";

const SearchTransaction = (props) => {
	const [open, setOpen] = useState(false);

	const handleFormSubmit = (value) => {
		const { fromTxnDate, toTxnDate } = value;
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: 10,
		});
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: {
				...value,
				fromTxnDate: new Date(fromTxnDate),
				toTxnDate: new Date(toTxnDate),
			},
		});
	};
	return (
		<>
			<div className="w-full  py-3 flex align-items-end justify-content-between">
				<h3 className="m-0">Hospicash Wallet</h3>
				<div>
					<Button
						icon={open ? "pi pi-times" : "pi pi-search"}
						className={
							open
								? "p-button-danger p-button-outlined"
								: "p-button-outlined"
						}
						label={open ? "Close" : "Search"}
						aria-label="Cancel"
						onClick={() => setOpen((prev) => !prev)}
					/>
					<Button
						label="Reload"
						icon="pi pi-refresh"
						className="primary-btn ml-2 p-button-outlined"
						onClick={() =>
							props.searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							})
						}
					/>
				</div>
			</div>
			{open && (
				<Formik
					initialValues={{
						fromTxnDate: "",
						toTxnDate: "",
						type: "",
					}}
					onSubmit={handleFormSubmit}
				>
					{({ handleSubmit, resetForm }) => (
						<Form onSubmit={handleSubmit}>
							<div className="form-grid grid background-secondary p-4 border-round-md my-3">
								<div className="field col-12 md:col-3">
									<Field
										header={"From Txn Date"}
										name={"fromTxnDate"}
										component={FeildCalender}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"To Txn Date"}
										name={"toTxnDate"}
										component={FeildCalender}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Type"}
										name={"type"}
										options={[
											{ label: "All", value: "ALL" },
											{ label: "Debit", value: "DEBIT" },
											{
												label: "Credit",
												value: "CREDIT",
											},
										]}
										component={FeildDropdown}
										star={"*"}
									/>
								</div>

								<div className="field flex align-items-center  justify-content-end col-12">
									<Button
										type="button"
										label="Reset"
										className="p-button-danger mr-2"
										onClick={() => resetForm()}
									/>
									<Button type="submit" label="Search" />
								</div>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default SearchTransaction;
