import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
	FeildDropdown,
	FieldInput,
	FieldTextArea,
} from "../../../../components/FieldInput";
import { Button } from "primereact/button";
import * as Yup from "yup";
import Config from "../../../../shared/config";
import { DataTable } from "primereact/datatable";
import { CallCenterService } from "../../../../services/callCenterService";
import { Column } from "primereact/column";
import moment from "moment";
const LeadStatusUpdate = (props) => {
	const [data, setData] = useState([]);
	const ccService = new CallCenterService();
	const initialValue = {
		status: "",
		remarks: "",
	};
	const validationSchema = Yup.object().shape({
		status: Yup.string().required("This field is required"),
	});
	useEffect(() => {
		props?.id?.length > 0 &&
			ccService.getStatusById(props.id[0]).then((res) => setData(res));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id]);

	const createdByTemplate = (item) => {
		let fname = item.createdByFirstName ? item.createdByFirstName : "";
		let mname = item.createdByMiddleName ? item.createdByMiddleName : "";
		let lname = item.createdByLastName ? item.createdByLastName : "";
		// const userName = item.createdBy ? `(${item.createdBy})` : "";

		const userName =
			item.agentCode !== null
				? item.agentCode
					? `(${item.agentCode})`
					: ""
				: item.createdBy
				? `(${item.createdBy})`
				: "";

		let name = item.createdBy
			? `${fname} ${mname} ${lname} ${userName} (${item.createdBy})`
			: null;
		return name;
	};

	const stateTemplate = (item) => {
		switch (item.status) {
			case "NEW":
				return (
					<span className="bg-blue-800 text-blue-50 border-round-sm py-1 px-2">
						New
					</span>
				);
			case "NOT_INTERESTED":
				return (
					<span className="bg-blue-300 text-blue-50 border-round-sm py-1 px-2">
						Not Interested
					</span>
				);
			case "FOLLOW_UP_NOTE":
				return (
					<span className="bg-teal-400 text-yellow-800 border-round-sm py-1 px-2">
						Follow Up Note
					</span>
				);
			case "INTERESTED_WILL_REGISTER":
				return (
					<span className="bg-green-800 text-green-100 border-round-sm py-1 px-2">
						Interested Will Register
					</span>
				);
			case "NOT_RESPONDING":
				return (
					<span className="bg-yellow-100 text-orange-700 border-round-sm py-1 px-2">
						Not Responding
					</span>
				);

			case "NO_PICKUP_ATTEMPT":
				return (
					<span className="bg-blue-300 text-green-50 border-round-sm py-1 px-2">
						No Pickup Attempt
					</span>
				);
			case "CALLED":
				return (
					<span className="bg-blue-100 text-blue-900 border-round-sm py-1 px-2">
						Called
					</span>
				);
			case "DOWNLOADED":
				return (
					<span className="bg-blue-100 text-purple-800 border-round-sm py-1 px-2">
						Downloaded
					</span>
				);
			case "VERIFIED":
				return (
					<span className="bg-red-300 text-black-100 border-round-sm py-1 px-2">
						Verified
					</span>
				);
			case "INVALID_NUMBER":
				return (
					<span className="bg-green-100 text-blue-700 border-round-sm py-1 px-2">
						Invalid Number
					</span>
				);
			case "REGISTERED":
				return (
					<span className="bg-purple-100 text-blue-700 border-round-sm py-1 px-2">
						Register
					</span>
				);
			case "CONTACTED":
				return (
					<span className="bg-purple-100 text-blue-700 border-round-sm py-1 px-2">
						Contacted
					</span>
				);
			default:
				<></>;
		}
	};
	return (
		<>
			{/* <Formik
				initialValues={initialValue}
				enableReinitialize
				onSubmit={props.handleStatusUpdate}
				validationSchema={validationSchema}
			>
				{({ handleSubmit, resetForm }) => (
					<Form
						className="modal-form-grid col-12 border-round-md mt-2 p-1 form-grid grid"
						onSubmit={handleSubmit}
					>
						<div className="field col-6">
							<Field
								header={"Status"}
								name={"status"}
								filter
								component={FeildDropdown}
								options={Config.AGENTLEADSTATUS}
							/>
						</div>
						<div className="field col-6">
							<Field
								header={"Remarks"}
								name={"remarks"}
								component={FieldInput}
							/>
						</div>
						<div className="col-12 flex align-items-end justify-content-end">
							<Button
								type="button"
								label="Reset"
								className="p-button-danger mr-2"
								onClick={resetForm}
							/>
							<Button type="submit" label="Save" />
						</div>
					</Form>
				)}
			</Formik> */}
			<DataTable className="col-12 py-2" value={data}>
				<Column
					body={(item) =>
						item?.createdOn
							? moment(item?.createdOn).format("YY-MM-DD HH:MM")
							: ""
					}
					header={"Created On"}
				/>
				<Column body={createdByTemplate} header={"Created By"} />
				<Column header={"Status"} body={stateTemplate} />
				<Column field="remarks" header={"Remark"} />
			</DataTable>
		</>
	);
};

export default LeadStatusUpdate;
