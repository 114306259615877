import React, { useState, useRef, useEffect, useReducer } from "react";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import DialogSelector from "./components/DialogSelector";
import { AgentService } from "../../../services/AgentService";
import { Toast } from "primereact/toast";
import Paginator from "../../../components/Paginator";
import TableLoader from "../../../components/TableLoader";
import { BiErrorCircle } from "react-icons/bi";
import AgentProfileComponent from "../../../components/AgentProfile";
import Loader from "../../../components/Loader";
import {
	searchInitialState,
	searchReducer,
} from "../../../components/searchReducer";
import Config from "../../../shared/config";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import debounce from "lodash.debounce";
const UnallocatedAgents = () => {
	const agentService = new AgentService();
	const [response, setResponse] = useReducer(dataReducer, fetchInitialState);
	const toast = useRef(null);

	// const initialState = {
	// 	pageNo: 1,
	// 	pageSize: 10,
	// 	sortType: null,
	// 	sortByColumn: null,
	// 	profileDetailDialog: false,
	// 	search: "",
	// 	spinner: false,
	// 	refresh: false,
	// };
	const [profileDetailDialog, setProfileDetailDialog] = useState(false);
	// const reducer = (state, action) => {
	// 	switch (action.type) {
	// 		case "PAGEINC":
	// 			return { ...state, pageNo: state.pageNo + 1 };
	// 		case "PAGEDEC":
	// 			return { ...state, pageNo: state.pageNo - 1 };
	// 		case "SORT_TYPE":
	// 			return { ...state, sortType: action.payload };
	// 		case "PAGESIZE":
	// 			return { ...state, pageSize: action.payload };
	// 		case "SORT_BY_COL":
	// 			return { ...state, sortByColumn: action.payload };
	// 		case "SEARCH":
	// 			return { ...state, search: action.payload };
	// 		case "PROFILE_DETAIL_DIALOG":
	// 			return { ...state, profileDetailDialog: action.payload };
	// 		case "LOADER":
	// 			return { ...state, spinner: action.payload };
	// 		case "REFRESH":
	// 			return { ...state, refresh: action.payload };
	// 		default:
	// 			return state;
	// 	}
	// };
	const [mainstate, maindispatch] = React.useReducer(
		searchReducer,
		searchInitialState
	);
	const initialAgentDetails = {
		agent: null,
		profileDetailDialog: false,
		loader: false,
	};

	const agentReducer = (state, action) => {
		switch (action.type) {
			case "AGENT":
				return { ...state, agent: action.payload };
			case "PROFILE":
				return { ...state, profileDetailDialog: action.payload };
			case "LOADER":
				return { ...state, loader: action.payload };
			default:
				return state;
		}
	};

	const [agentState, agentdispatch] = React.useReducer(
		agentReducer,
		initialAgentDetails
	);

	/**
	 * Table Sort Function
	 */
	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							mainstate.sortByColumn === columnName &&
							mainstate.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							mainstate.sortByColumn === columnName &&
							mainstate.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	function tblSort(columnName, sortType) {
		// maindispatch({
		// 	type: "SORT_BY_COL",
		// 	payload: columnName,
		// });
		// maindispatch({
		// 	type: "SORT_TYPE",
		// 	payload: sortType,
		// });
		maindispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		maindispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}
	const increment = () => {
		// maindispatch({ type: "PAGEINC" });
		maindispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		// maindispatch({ type: "PAGEDEC" });
		maindispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const pagesizechange = (e) => {
		// setpageSize(e.target.value);
		// maindispatch({ type: "PAGESIZE", payload: e.target.value });
		maindispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
		maindispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
	};
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column
					header={getSortableColumn("Profile Type", "profileType")}
				/>
				<Column header={getSortableColumn("Name", "firstName")} />
				<Column header={getSortableColumn("Pincode", "pincode")} />
				<Column header={getSortableColumn("Mobile", "mobileNumber")} />
				<Column header={getSortableColumn("Email", "emailId")} />
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const nameTemplate = ({ firstName, middleName, lastName }) => {
		return (
			(firstName ? firstName : "") +
			" " +
			(middleName ? middleName : "") +
			" " +
			(lastName ? lastName : "")
		);
	};
	const handelAgentAllocate = (requestBody) => {
		agentService
			.addUnallocated(requestBody)
			.then((res) => {
				// maindispatch({
				// 	type: "PROFILE_DETAIL_DIALOG",
				// 	payload: false,
				// });

				// maindispatch({
				// 	type: "REFRESH",
				// 	payload: true,
				// });

				// maindispatch({ type: "LOADER", payload: false });
				toast.current.show([
					{
						severity: "success",
						summary: "Successfull",
						detail: "Agent allocated successfully",
						life: 3000,
					},
				]);
			})
			.catch(() => {
				toast.current.show([
					{
						severity: "error",
						summary: "Error",
						detail: "Error",
						life: 3000,
					},
				]);
			})
			.finally(() => {
				setProfileDetailDialog(false);
				maindispatch({ type: Config.SEARCH_CONFIG.reset });
			});
	};
	const itaration = {
		profileType: "",
		firstName: "",
		pincode: "",
		mobileNumber: "",
		emailId: "",
		action: "",
	};
	const getResponse = () => {
		// const payload = {
		// 	searchRequest: mainstate.search,
		// 	pageNo: mainstate.pageNo,
		// 	pageSize: mainstate.pageSize,
		// 	sorts: [],
		// };
		// if (mainstate.sortByColumn && mainstate.sortType) {
		// 	payload.sorts = [mainstate.sortByColumn + "," + mainstate.sortType];
		// }
		const requestBody = {
			pageNo: mainstate.pageNo,
			pageSize: mainstate.pageSize,
			...(mainstate.searchValue !== "" && {
				searchRequest: mainstate.searchValue,
			}),
			...(mainstate.sortByColumn && mainstate.sortType
				? {
						sorts: [
							`${
								mainstate.sortByColumn === "firstName"
									? `agent.${mainstate.sortByColumn}`
									: mainstate.sortByColumn
							},${mainstate.sortType}`,
						],
				  }
				: { sorts: [] }),
		};
		setResponse({ type: Config.FETCH_CONFIG.start });
		agentService
			.unallocatedAgent(requestBody)
			.then((res) => {
				setResponse({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
				setResponse({ type: Config.FETCH_CONFIG.fetched });
				// maindispatch({ type: "LOADER", payload: false });
			})
			.catch(() => {
				// maindispatch({
				// 	type: "PROFILE_DETAIL_DIALOG",
				// 	payload: false,
				// });

				toast.current.show([
					{
						severity: "error",
						summary: "Error",
						detail: "Error",
						life: 3000,
					},
				]);
			})
			.finally(() => setResponse({ type: Config.FETCH_CONFIG.stop }));
	};
	useEffect(() => {
		// maindispatch({ type: "LOADER", payload: true });

		getResponse();

		// return () => {
		// 	maindispatch({ type: "LOADER", payload: false });
		// };
		// eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/exhaustive-deps
	}, [mainstate]);
	const getProfileDetails = (id) => {
		agentdispatch({ type: "LOADER", payload: true });
		agentService
			.getProfileDetails(id)
			.then((res) => {
				// setagent(res);
				// setprofileDetailDialog(true);
				// setSpinner(false);
				agentdispatch({ type: "AGENT", payload: res });
				agentdispatch({ type: "PROFILE", payload: true });
			})
			.catch((e) => {
				toast.current.show([
					{
						severity: "error",
						summary: "Error",
						detail: "Error",
						life: 3000,
					},
				]);
			})
			.finally(() => agentdispatch({ type: "LOADER", payload: false }));
	};

	const actionTemplate = (item) => {
		return (
			<div className="flex">
				<button
					className="icon-btn primary-btn mr-2"
					onClick={() => {
						getProfileDetails(item.profileId);
					}}
				>
					<BiErrorCircle />
				</button>
			</div>
		);
	};
	return (
		<>
			<Toast ref={toast} />
			<div className="col">
				<div className="w-full flex flex-wrap justify-content-between align-items-center">
					<h3 className="m-0">Unallocated agents list</h3>

					<div className="flex align-items-center gap-1">
						{/* <Button
							label="Filter"
							icon="pi pi-filter-fill"
							iconPos="left"
							className="disabled-btn"
						/> */}
						<span className="p-input-icon-left">
							<i className="pi pi-search" />
							<InputText
								onChange={debounce(
									(e) =>
										maindispatch({
											type: Config.SEARCH_CONFIG
												.searchRequest,
											payload: e.target.value,
										}),
									1000
								)}
								placeholder="Search Agents"
								className="search-field"
							/>
						</span>
						<Button
							label="Allocate agent"
							icon="pi pi-plus"
							iconPos="right"
							className="primary-btn"
							onClick={
								() => setProfileDetailDialog(true)
								// maindispatch({
								// 	type: "PROFILE_DETAIL_DIALOG",
								// 	payload: true,
								// })
							}
						/>
					</div>
				</div>
			</div>
			<div>
				{response.isLoading ? (
					<TableLoader
						itaration={itaration}
						headerGroup={headerGroup}
					/>
				) : (
					response.isFetched && (
						<>
							<DataTable
								headerColumnGroup={headerGroup}
								value={response.data?.data}
								responsiveLayout="scroll"
								dataKey="agentId"
								breakpoint="960px"
							>
								<Column field="profileType" />
								<Column body={nameTemplate} />
								<Column field="pincode" />
								<Column field="mobileNumber" />
								<Column field="emailId" />
								<Column body={actionTemplate} />
							</DataTable>
							{response.data?.data?.length > 0 && (
								<Paginator
									pageSize={response.data?.pageSize}
									firstPage={response.data?.firstPage}
									lastPage={response.data?.lastPage}
									decrement={decrement}
									increment={increment}
									pagesizechange={pagesizechange}
									pageNo={response.data?.pageNo}
									totalPages={response.data?.totalPages}
									totalElements={response.data?.totalElements}
								/>
							)}
						</>
					)
				)}
			</div>

			<div className="dialog-demo">
				<div className="card ">
					<Dialog
						header="Agent Profile"
						visible={profileDetailDialog}
						onHide={() => {
							setProfileDetailDialog(false);
						}}
						breakpoints={{ "960px": "97vw" }}
						style={{ width: "65vw" }}
					>
						<DialogSelector
							handelAgentAllocate={handelAgentAllocate}
						/>
					</Dialog>
				</div>
			</div>
			{agentState.loader && <Loader />}
			<div className="dialog-demo">
				<div className="card ">
					<Dialog
						header="Verify Agent Profile"
						visible={agentState.profileDetailDialog}
						onHide={() => {
							agentdispatch({ type: "PROFILE", payload: false });
							agentdispatch({ type: "LOADER", payload: false });
						}}
						breakpoints={{ "960px": "97vw" }}
						style={{ width: "65vw" }}
					>
						<div className="w-full">
							{agentState.agent ? (
								<AgentProfileComponent
									agent={agentState.agent}
								/>
							) : null}
						</div>
					</Dialog>
				</div>
			</div>
		</>
	);
};

export default UnallocatedAgents;
