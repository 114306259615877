import { Field, Form, Formik } from "formik";
import {
	FeildDropdown,
	FieldInput,
	FieldPassword,
} from "../../../components/FieldInput";
import Config from "../../../shared/config";
import { Button } from "primereact/button";
import { VendorService } from "../../../services/vendorService";
import * as Yup from "yup";
import { useRef, useState } from "react";
import Loader from "../../../components/Loader";
import { Toast } from "primereact/toast";
import debounce from "lodash.debounce";
const CreateVendor = (props) => {
	const toastTL = useRef(null);
	const [loader, setLoader] = useState(false);
	const vendorService = new VendorService();
	const initialValue = {
		vendorType: "",
		name: "",
		address: "",
		pan: "",
		gst: "",
		contactPersonName: "",
		contactPersonType: "",
		bankAccountType: "",
		accountNumber: "",
		bankName: "",
		bankAddress: "",
		ifscCode: "",
		firstName: "",
		middleName: "",
		lastName: "",
		mobileNumber: "",
		email: "",
		userName: "",
		password: "",
	};
	const ValidationSchema = Yup.object().shape({
		vendorType: Yup.string().required("This field is required"),
		name: Yup.string().required("This field is required"),
		// address: Yup.string()
		// 	.matches(/^\S+$/, "Invalid value")
		// 	.required("This field is required"),
		// pan: Yup.string()
		// 	.matches(
		// 		/([A-Z]{5}[0-9]{4}[A-Z]{1})/,
		// 		"Please enter valid pancard number"
		// 	)
		// 	.required("This field is required"),
		// gst: Yup.string().required("This field is required"),
		// contactPersonName: Yup.string().required("This field is required"),
		contactPersonType: Yup.string().required("This field is required"),
		bankAccountType: Yup.string().required("This field is required"),
		// accountNumber: Yup.string().required("This field is required"),
		// bankName: Yup.string().required("This field is required"),
		// bankAddress: Yup.string().required("This field is required"),
		// ifscCode: Yup.string()
		// 	.matches(/^\S+$/, "Invalid value")
		// 	.required("This field is required"),
		firstName: Yup.string()
			.matches(/^\S+$/, "Invalid value")
			.required("This field is required"),
		// middleName: Yup.string()
		// 	.matches(/^\S+$/, "Invalid value")
		// 	.required("This field is required"),
		lastName: Yup.string()
			.matches(/^\S+$/, "Invalid value")
			.required("This field is required"),
		mobileNumber: Yup.string()
			.min(10, "Mobile No. should be 10 digit")
			.max(10, "Mobile No. should be 10 digit")
			.matches(/^[0]?[6789]\d{9}$/, "Invalid Mobile No.")
			.required("This field is required"),
		email: Yup.string()
			.email("Invalid email id.")
			.required("This field is required"),
		userName: Yup.string()
			.matches(/^\S+$/, "Invalid username")
			.required("This field is required"),
		password: Yup.string().when({
			is: () => props.dialogState?.type === "create",
			then: Yup.string()
				.matches(/^\S+$/, "Invalid Id")
				.required("This field is required"),
		}),
	});
	const handleFormSubmit = (value) => {
		if (props.dialogState?.type === "create") {
			setLoader(true);
			vendorService
				.createVendor(value)
				.then(() => {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: "Vendor created successfully",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.then(
					debounce(() => {
						props.setDialog({ dialog: false });
						props.userDataTableValue();
					}, 1000)
				)
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e?.message,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => setLoader(false));
		} else {
			setLoader(true);
			vendorService
				.updateVendor(value)
				.then(() => {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: "Vendor Updated successfully",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.then(
					debounce(() => {
						props.setDialog({ dialog: false });
						props.userDataTableValue();
					}, 1000)
				)
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e?.message,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => setLoader(false));
		}
	};
	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			{loader && <Loader />}
			<Formik
				initialValues={{ ...initialValue, ...props.dialogState?.data }}
				onSubmit={handleFormSubmit}
				validationSchema={ValidationSchema}
				enableReinitialize
			>
				{({ handleSubmit }) => (
					<Form
						className="grid modal-form-grid mt-2"
						onSubmit={handleSubmit}
					>
						<div className="col-12">
							<h3>Vendor Details</h3>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Vendor Type"}
								name={"vendorType"}
								component={FeildDropdown}
								options={Config.VENDOR_TYPE}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Name"}
								name={"name"}
								component={FieldInput}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Address"}
								name={"address"}
								component={FieldInput}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"PAN"}
								name={"pan"}
								component={FieldInput}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"GST"}
								name={"gst"}
								component={FieldInput}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Contact Person Name"}
								name={"contactPersonName"}
								component={FieldInput}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Contactperson Type"}
								name={"contactPersonType"}
								component={FeildDropdown}
								options={Config.CONTACT_PERSON_TYPE}
							/>
						</div>
						<div className="col-12">
							<h3>Bank Details</h3>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Bank Account Type"}
								name={"bankAccountType"}
								component={FeildDropdown}
								options={Config.BANK_ACCOUNTTYPE}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Account Number"}
								name={"accountNumber"}
								component={FieldInput}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Bank Name"}
								name={"bankName"}
								component={FieldInput}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Bank Address"}
								name={"bankAddress"}
								component={FieldInput}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"IFSC Code"}
								name={"ifscCode"}
								component={FieldInput}
							/>
						</div>
						<div className="col-12">
							<h3>Admin</h3>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Firstname"}
								name={"firstName"}
								component={FieldInput}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Middlename"}
								name={"middleName"}
								component={FieldInput}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Lastname"}
								name={"lastName"}
								component={FieldInput}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Mobile Number"}
								name={"mobileNumber"}
								component={FieldInput}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Email"}
								name={"email"}
								component={FieldInput}
							/>
						</div>
						<div className="field col-12 md:col-4">
							<Field
								header={"Username"}
								name={"userName"}
								component={FieldInput}
							/>
						</div>
						{props.dialogState?.type === "create" && (
							<div className="field col-12 md:col-4">
								<Field
									header={"Password"}
									name={"password"}
									component={FieldPassword}
								/>
							</div>
						)}
						<div className="col-12">
							<Button
								label={
									props.dialogState?.type === "create"
										? "Save"
										: "Update"
								}
								type="submit"
							/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default CreateVendor;
