import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	data: { state: null, district: null },
};

const LoanApplicationSlice = createSlice({
	name: "loanApplicationSlice",
	initialState: initialState,
	reducers: {
		setDistrictDetails: (state, action) => {
			state.data = action.payload;
		},
	},
});
export const { setDistrictDetails } = LoanApplicationSlice.actions;
export default LoanApplicationSlice.reducer;
