import React, { useEffect, useReducer, useState } from "react";
import {
	searchInitialState,
	searchReducer,
} from "../../../components/searchReducer";
import Config from "../../../shared/config";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import CommissionSearch from "./components/CommissionSearch";
import { ProductService } from "../../../services/ProductService";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import { produce } from "immer";
import TableLoader from "../../../components/TableLoader";
import Paginator from "../../../components/Paginator";
import moment from "moment";
import ProductEmployeeCommissionStructure from "./components/EmployeeCommission";
const EmployeeCommissionList = () => {
	const { productId } = useParams();
	const productService = new ProductService();
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const [spinner, setSpinner] = useState(false);
	const reducer = produce((draft, data) => {
		switch (data.type) {
			case "dialog":
				draft.dialog = data.dialog;
				break;
			case "data":
				draft.data = data.data;
				break;
			case "type":
				draft.type = data.payload;
				break;
			case "isLoading":
				draft.isLoading = data.isLoading;
				break;
			default:
				break;
		}
	});
	const [dialogState, dialogDispatch] = useReducer(reducer, {
		dialog: false,
		data: {},
		type: "create",
		isLoading: false,
	});
	const [commissionState, commissionDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);

	const getCommission = () => {
		const body = {
			...searchState.searchValue,
			pageNo: searchState.pageNo,
			productId: Number(productId),
			pageSize: searchState.pageSize,
			...(searchState.sortByColumn &&
				searchState.sortType && {
					sorts: [
						searchState.sortByColumn + "," + searchState.sortType,
					],
				}),
		};
		const demo = Object.fromEntries(
			Object.entries(body).filter(([_, v]) => v !== "")
		);
		commissionDispatch({
			type: Config.FETCH_CONFIG.start,
		});
		productService
			.getProductEmployeeCommission(demo)
			.then((res) =>
				commissionDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				})
			)
			.catch(() => {})
			.finally(() => {
				commissionDispatch({
					type: Config.FETCH_CONFIG.stop,
				});
			});
	};
	useEffect(() => {
		getCommission();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);
	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />
				<Column
					header={getSortableColumn(
						"Commission Type",
						"commissionType"
					)}
				/>
				<Column
					header={getSortableColumn(
						"Effective Start Date",
						"effectiveStartDate"
					)}
				/>
				<Column
					header={getSortableColumn(
						"Effective End Date",
						"effectiveEndDate"
					)}
				/>
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);

	function tblSort(columnName, sortType) {
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}
	const handleCommissionDialog = (type, e) => {
		dialogDispatch({ type: "type", payload: type });
		dialogDispatch({ type: "dialog", dialog: true });
		dialogDispatch({ type: "isLoading", isLoading: true });
		productService
			.getEmployeeCommissionById(e)
			.then((res) => dialogDispatch({ type: "data", data: res }))
			.finally(() =>
				dialogDispatch({ type: "isLoading", isLoading: false })
			);
	};
	const action = (item) => {
		return (
			<Button
				className="icon-btn p-button-text"
				icon={"pi pi-pencil"}
				tooltip="Edit"
				onClick={() => {
					handleCommissionDialog("edit", item.id);
				}}
			/>
		);
	};
	const handleClick = () => {
		dialogDispatch({ type: "type", payload: "create" });
		dialogDispatch({ type: "dialog", dialog: true });
	};
	const itaration = {
		first: "",
		snd: "",
		trd: "",
		frt: "",
		fid: "",
	};
	return (
		<>
			<CommissionSearch
				handleClick={handleClick}
				searchDispatch={searchDispatch}
			/>
			{commissionState.isLoading ? (
				<TableLoader itaration={itaration} headerGroup={headerGroup} />
			) : (
				commissionState.data.data && (
					<>
						<DataTable
							value={
								commissionState.data.data &&
								commissionState.data.data.map(
									(item, index) => ({
										...item,
										index:
											commissionState.data.pageSize *
												commissionState.data.pageNo -
											commissionState.data.pageSize +
											1 +
											index,
									})
								)
							}
							className="col-12"
							responsiveLayout="scroll"
							// dataKey="agentId"
							breakpoint="960px"
							headerColumnGroup={headerGroup}
						>
							<Column field="index" />
							<Column field="commissionType" />
							<Column
								body={(item) =>
									moment(item.effectiveStartDate).format(
										"Do MMM, YYYY"
									)
								}
							/>
							<Column
								body={(item) =>
									moment(item.effectiveEndDate).format(
										"Do MMM, YYYY"
									)
								}
							/>

							<Column body={action} />
						</DataTable>
						{commissionState.data.data.length > 0 && (
							<Paginator
								pageSize={commissionState.data.pageSize}
								firstPage={commissionState.data.firstPage}
								lastPage={commissionState.data.lastPage}
								decrement={() =>
									searchDispatch({
										type: Config.SEARCH_CONFIG.decrement,
									})
								}
								increment={() =>
									searchDispatch({
										type: Config.SEARCH_CONFIG.increment,
									})
								}
								pagesizechange={(e) => {
									// let newPageNo = Math.ceil(
									// 	(commissionState.data.pageSize * commissionState.data.pageNo) /
									// 		e.target.value
									// );
									searchDispatch({
										type: Config.SEARCH_CONFIG.pageSize,
										payload: e.target.value,
									});
									searchDispatch({
										type: Config.SEARCH_CONFIG.pageNo,
										payload: 1,
									});
								}}
								totalElements={
									commissionState.data.totalElements
								}
								pageNo={commissionState.data.pageNo}
								totalPages={commissionState.data.totalPages}
							/>
						)}
					</>
				)
			)}
			<Dialog
				visible={dialogState.dialog}
				header={dialogState.type === "create" ? "Create" : "Update"}
				onHide={() => {
					dialogDispatch({ type: "dialog", dialog: false });
					dialogDispatch({ type: "data", data: {} });
				}}
				style={{ width: "70%" }}
			>
				<ProductEmployeeCommissionStructure
					isLoading={dialogState.isLoading}
					type={dialogState.type}
					id={productId}
					initialValue={dialogState.data}
					searchDispatch={getCommission}
					dialogDispatch={dialogDispatch}
				/>
			</Dialog>
		</>
	);
};

export default EmployeeCommissionList;
