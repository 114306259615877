import React, { useEffect, useReducer, useRef, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
	searchInitialState,
	searchReducer,
} from "../../../../components/searchReducer";
import Config from "../../../../shared/config";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import Paginator from "../../../../components/Paginator";
import { AgentService } from "../../../../services/AgentService";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import MenuComponent from "../../../../components/MenuComponent";
import SearchProfileUpdate from "./SearchProfileUpdate";
import Loader from "../../../../components/Loader";
const AgentProfileUpdateList = () => {
	const agentService = new AgentService();
	const navigate = useNavigate();
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const toastTL = useRef(null);
	const [members, setMembers] = useReducer(dataReducer, fetchInitialState);
	const increment = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const pagesizechange = (e) => {
		// const newPageNo = Math.ceil(
		// 	(members.data.pageSize * members.data.pageNumber) / e.target.value
		// );

		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};
	const agentPartnerList = () => {
		const requestBody = {
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.searchValue !== "" && searchState.searchValue),
			...(searchState.sortByColumn && searchState.sortType
				? {
						sorts: [
							searchState.sortByColumn +
								"," +
								searchState.sortType,
						],
				  }
				: { sorts: [] }),
		};
		setMembers({ type: Config.FETCH_CONFIG.start });
		agentService
			.getProfileUpdateList(requestBody)
			.then((res) => {
				setMembers({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setMembers({ type: Config.FETCH_CONFIG.stop }));
	};
	useEffect(() => {
		agentPartnerList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);
	const actionTempalte = (item) => {
		const items = [];
		if (item?.status === "REQUESTED") {
			items.push({
				label: "Update Profile",
				icon: "pi pi-check",
				command: () => {
					navigate(`/members/agent-profile-update/${item.agentId}`, {
						state: {
							profileData: {
								...item?.agentProfileDetails.agent,

								profileType: item?.profileType,
							},
							requestId: item?.id,
							companyDetails: item?.company,
							requestProfile: item?.profileType,
							agentId: item?.agentId,
							profileId: item?.agentProfileDetails?.id,
							approve: "approve",
						},
					});
				},
			});
		}
		if (item.agentProfileDetails?.id) {
			items.push({
				label: "Profile",
				icon: "pi pi-user",
				command: () => {
					navigate(`/vendor/agent-profile/${item.agentId}`, {
						state: {
							profileId: item.agentProfileDetails?.id,
						},
					});
				},
			});
		}
		return <MenuComponent items={items} />;
	};
	return (
		<>
			{members?.isLoading && <Loader />}
			<Toast ref={toastTL} position="top-left" />
			<SearchProfileUpdate />
			<DataTable
				className="w-full"
				value={
					members.data.data &&
					members.data.data.map((item, index) => ({
						...item,
						index:
							members.data.pageSize * members.data.pageNo -
							members.data.pageSize +
							1 +
							index,
					}))
				}
				responsiveLayout="scroll"
				breakpoint="960px"
			>
				<Column field="index" />
				<Column
					header={"Name"}
					body={(item) =>
						item?.agentProfileDetails?.agent?.fullName
							? item?.agentProfileDetails?.agent?.fullName
							: ""
					}
				/>

				<Column
					body={(item) => {
						switch (item?.status) {
							case "REQUESTED":
								return (
									<span className="border-round-sm text-blue-800 bg-blue-200 px-3 py-1">
										Requested
									</span>
								);
							case "APPROVED":
								return (
									<span className="border-round-sm text-green-800 bg-green-200 px-3 py-1">
										Approved
									</span>
								);
							case "REJECTED":
								return (
									<span className="border-round-sm text-red-800 bg-red-200 px-3 py-1">
										Rejected
									</span>
								);

							default:
								return <></>;
						}
					}}
					header={"Status"}
				/>
				<Column
					header={"Current Profile Type"}
					body={(item) =>
						item?.agentProfileDetails?.profileType
							? item?.agentProfileDetails?.profileType
							: ""
					}
				/>
				<Column field="profileType" header={"Requested Profile Type"} />
				{/* <Column
					body={(item) => item?.agentProfileDetails?.}
					header={"Requested Profile Type"}
				/> */}
				<Column header={"Action"} body={actionTempalte} />
			</DataTable>

			<Paginator
				pageSize={searchState.pageSize}
				firstPage={members.data.firstPage}
				lastPage={members.data.lastPage}
				decrement={decrement}
				increment={increment}
				pagesizechange={pagesizechange}
				pageNo={members.data.pageNo}
				totalPages={members.data.totalPages}
				totalElements={members.data.totalElements}
			/>
		</>
	);
};

export default AgentProfileUpdateList;
