export function convertObjNull(obj) {
	Object.keys(obj).forEach(function (key) {
		if (obj[key] === "") {
			obj[key] = null;
		}
	});
	return obj;
}
export function convertNullToString(obj) {
	Object.keys(obj).forEach(function (key) {
		if (obj[key] === null) {
			obj[key] = "";
		}
	});
	return obj;
}

export function ValidateEmail(mail) {
	if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
		return true;
	}
	return false;
}
export function validatePhoneNumber(phoneNo) {
	var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

	return re.test(phoneNo);
}
