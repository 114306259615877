import React, { useState, useRef } from "react";
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { addLocale } from "primereact/api";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { FileUpload } from "primereact/fileupload";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import S3 from "aws-sdk/clients/s3";
// import * as Yup from "yup";
import moment from "moment/moment";
import Config from "../../../../shared/config";

const s3Clint = new S3({
	accessKeyId: Config.S3_ACCESSKEYID,
	secretAccessKey: Config.S3_SECRETACCESSKEY,
	region: Config.S3_REGION,
	signatureVersion: "v4",
});

const ProductContractDetails = ({
	id,
	handelContractCreate,
	contractDialog,
}) => {
	const toastTL = useRef(null);
	const [isLoading, setLoading] = useState(false);
	// let today = new Date();
	// let month = today.getMonth();
	// let year = today.getFullYear();
	// let prevMonth = month === 0 ? 11 : month - 1;
	// let prevYear = prevMonth === 11 ? year - 1 : year;
	// let nextMonth = month === 11 ? 0 : month + 1;
	// let nextYear = nextMonth === 0 ? year + 1 : year;
	addLocale("es", {
		firstDayOfWeek: 1,
		dayNames: [
			"domingo",
			"lunes",
			"martes",
			"miércoles",
			"jueves",
			"viernes",
			"sábado",
		],
		dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
		dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
		monthNames: [
			"enero",
			"febrero",
			"marzo",
			"abril",
			"mayo",
			"junio",
			"julio",
			"agosto",
			"septiembre",
			"octubre",
			"noviembre",
			"diciembre",
		],
		monthNamesShort: [
			"ene",
			"feb",
			"mar",
			"abr",
			"may",
			"jun",
			"jul",
			"ago",
			"sep",
			"oct",
			"nov",
			"dic",
		],
		today: "Hoy",
		clear: "Limpiar",
	});
	const formik = useFormik({
		initialValues: {
			institutionProductId: id,
			startDate: "",
			endDate: "",
			contractDocNo: "",
			contractDocName: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.startDate) {
				errors.startDate = "Start date is required.";
			}
			if (!data.endDate) {
				errors.endDate = "End date is required";
			} else if (moment(data.endDate).isBefore(data.startDate)) {
				errors.endDate = "End date can not be less then start date";
			}
			if (!data.contractDocName) {
				errors.contractDocName = "Contract document is required";
			}
			return errors;
		},
		onSubmit: (data) => {
			const requestBody = {
				...data,
				contractStartDate: moment(data.startDate).format("YYYY-MM-DD"),
				contractEndDate: moment(data.endDate).format("YYYY-MM-DD"),
			};
			delete requestBody.endDate;
			delete requestBody.startDate;

			handelContractCreate(requestBody);
		},
	});

	const isFormFieldValid = (name) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};

	const onUpload = (e) => {
		setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.INSTITUTION_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successful",
					detail: "Contract document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});
				formik.setFieldValue("contractDocName", newFileName);
			}
		});
	};

	return (
		<div className="card modal-form-grid">
			<Toast ref={toastTL} position="top-left" />
			{isLoading ? (
				<>
					<div className="field col-12 md:col-6 lg:col-6">
						<Skeleton width="4rem" className="mb-2"></Skeleton>
						<Skeleton height="2rem" className="mb-2"></Skeleton>
					</div>
					<div className="field col-12 md:col-6 lg:col-6">
						<Skeleton width="3rem" className="mb-2"></Skeleton>
						<Skeleton height="2rem" className="mb-2"></Skeleton>
					</div>
					<div className="field col-12 ">
						<Skeleton width="5rem" className="mb-2"></Skeleton>
						<Skeleton height="7rem" className="mb-2"></Skeleton>
					</div>
					<div className="flex justify-content-end align-items-center w-full">
						<div className="col-12 md:col-3 lg:col-3 mt-3 flex">
							<Skeleton
								width="5rem"
								height="2rem"
								className="mb-2 mr-2"
							></Skeleton>
							<Skeleton
								width="5rem"
								height="2rem"
								className="mb-2"
							></Skeleton>
						</div>
					</div>
				</>
			) : (
				<form onSubmit={formik.handleSubmit} className="p-fluid grid">
					<FocusError formik={formik} />
					<div className="field col-12 md:col-6 lg:col-6">
						<label htmlFor="startDate">Start Date*</label>
						<Calendar
							name="startDate"
							className={classNames({
								"p-invalid": isFormFieldValid("startDate"),
							})}
							value={formik.values.startDate}
							onChange={formik.handleChange}
							dateFormat="dd/mm/yy"
							minDate={new Date()}
							showIcon
						/>
						{getFormErrorMessage("startDate")}
					</div>
					<div className="field col-12 md:col-6 lg:col-6">
						<label htmlFor="endDate">End Date*</label>
						<Calendar
							name="endDate"
							className={classNames({
								"p-invalid": isFormFieldValid("endDate"),
							})}
							value={formik.values.endDate}
							onChange={formik.handleChange}
							dateFormat="dd/mm/yy"
							minDate={new Date()}
							showIcon
						/>
						{getFormErrorMessage("endDate")}
					</div>
					<div className="field col-12">
						<label htmlFor="contractDocName">
							Upload Document*
						</label>
						<FileUpload
							name="contractDocName"
							customUpload
							accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
							uploadHandler={onUpload}
							maxFileSize={1000000}
							emptyTemplate={
								<p className="m-0">
									Drag and drop files to here to upload.
								</p>
							}
						/>
						{getFormErrorMessage("contractDocName")}
						<p className="p-error">
							**Notes: Please upload the document first before
							submiting the form.
						</p>
					</div>
					<div className="flex justify-content-end align-items-center w-full">
						<div className="col-12 md:col-3 lg:col-4 mt-4 flex">
							<Button
								type="button"
								label="Cancel"
								className="p-button-danger mr-2"
								icon="pi pi-times"
								onClick={() => contractDialog(false)}
							/>
							<Button
								type="submit"
								label="Save"
								icon="pi pi-save"
							/>
						</div>
					</div>
				</form>
			)}
		</div>
	);
};

export default ProductContractDetails;
