import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DashboardService } from "../../../../services/DashboardService";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TableDesign from "./TableDesign";
import { CountryAdminDashboardService } from "../../../../services/CountryAdminDashboard";

const ApplicationTab = (props) => {
	const [value1, setValue1] = useState(1);
	const selector = useSelector((state) => state.persistData.auth.user);
	const dashboardService = new CountryAdminDashboardService();
	const [dropdown, setDropdown] = useState("");
	const [agent, setAgent] = useState([]);
	const [applicationLead, setApplicationLead] = useState([]);
	const [application, setApplication] = useState([]);

	const getData = (userName) => {
		Promise.all([
			dashboardService.cumulative_graph_agent({ userName: userName }),
			dashboardService.cumulative_graph_application_lead({
				userName: userName,
			}),
			dashboardService.cumulative_graph_application({
				userName: userName,
			}),
		]).then((res) => {
			setAgent(res[0]);
			setApplicationLead(res[1]);
			setApplication(res[2]);
		});
	};
	useEffect(() => {
		getData(null);
	}, []);
	const options = [
		{ label: "Agent Lead", value: 1 },
		{ label: "Application Lead", value: 2 },
		{ label: "Application", value: 3 },
	];
	const template2 = {
		layout: "CurrentPageReport PrevPageLink NextPageLink",
	};

	return (
		<>
			<h5 className="my-1 text-center">TSRM Cumulative Data</h5>
			<div className="flex align-items-center md:pb-1  w-full justify-content-between flex-wrap">
				<SelectButton
					value={value1}
					options={options}
					onChange={(e) => setValue1(e.value)}
				/>
				{selector.role.position === 1030 ? null : (
					<div className="flex justify-content-between pb-2 c-w-full">
						<Dropdown
							className="border-round-md"
							filter
							options={props.tsrmlist.map((elm) => ({
								...elm,
								name:
									(elm?.firstName ? elm?.firstName : "") +
									" " +
									(elm?.middleName ? elm?.middleName : "") +
									" " +
									(elm?.lastName ? elm?.lastName : ""),
							}))}
							optionLabel="name"
							optionValue="userName"
							placeholder="Tsrm"
							value={dropdown}
							onChange={(e) => {
								getData(e.value);
								setDropdown(e.value);
							}}
						/>
						<Button
							icon={"pi pi-refresh"}
							onClick={() => {
								setDropdown("");
								getData(null);
							}}
							className="p-button-text c-btn-height"
						/>
					</div>
				)}
			</div>
			<div className="md:mt-3">
				{value1 === 1 ? (
					<TableDesign data={agent} />
				) : // <DataTable
				// 	value={agent}
				// 	paginator
				// 	paginatorTemplate={template2}
				// 	rows={7}
				// 	responsiveLayout={"scroll"}
				// >
				// 	<Column header="Date" field="Title" />
				// 	<Column
				// 		header={"No Pickup Attempt"}
				// 		field="No Pickup Attempt"
				// 	/>
				// 	<Column
				// 		header="Invalid Number"
				// 		field="Invalid Number"
				// 	/>
				// 	<Column
				// 		header="Not Responding"
				// 		field="Not Responding"
				// 	/>
				// 	<Column
				// 		header="Follow Up Note"
				// 		field="Follow Up Note"
				// 	/>
				// 	<Column
				// 		header="Not Interested"
				// 		field="Not Interested"
				// 	/>
				// 	<Column
				// 		header="interested Will Register"
				// 		field="interested Will Register"
				// 	/>
				// 	<Column header="Downloaded" field="Downloaded" />
				// 	<Column header="Registered" field="Registered" />
				// 	<Column header="New" field="New" />
				// 	<Column header="Verified" field="Verified" />
				// 	<Column header="Total" field="Total" />
				// </DataTable>
				value1 === 2 ? (
					<TableDesign data={applicationLead} />
				) : (
					// <DataTable
					// 	value={applicationLead}
					// 	paginator
					// 	paginatorTemplate={template2}
					// 	rows={7}
					// 	responsiveLayout={"scroll"}
					// >
					// 	<Column header="Date" field="Title" />
					// 	<Column header="New" field="NEW" />
					// 	<Column
					// 		header="Not Interested"
					// 		field="NOT INTERESTED"
					// 	/>
					// 	<Column header="Not Eligible" field="NOT ELIGIBLE" />
					// 	<Column header="Converted" field="CONVERTED" />
					// 	<Column header="Invalid" field="INVALID" />
					// 	<Column header="Follow Up" field="FOLLOW UP" />
					// 	<Column header="Total" field="Total" />
					// </DataTable>
					<TableDesign data={application} />
					// <DataTable
					// 	value={application}
					// 	paginator
					// 	paginatorTemplate={template2}
					// 	rows={7}
					// 	responsiveLayout={"scroll"}
					// >
					// 	<Column header="Date" field="Title" />
					// 	<Column header="Draft" field="DRAFT" />
					// 	<Column
					// 		header="Pending For Customer"
					// 		field="PENDING FOR CUSTOMER"
					// 	/>
					// 	<Column
					// 		header="Customer Draft"
					// 		field="CUSTOMER DRAFT"
					// 	/>
					// 	<Column
					// 		header="Customer Approved"
					// 		field="CUSTOMER APPROVED"
					// 	/>
					// 	<Column header="Lock" field="LOCK" />
					// 	<Column header="Revert By OE" field="REVERT BY OE" />
					// 	<Column header="TSRM Submit" field="TSRM SUBMITTED" />
					// 	<Column header="Rejected" field="REJECT" />
					// 	<Column header="Total" field="Total" />
					// </DataTable>
				)}
			</div>
		</>
	);
};

export default ApplicationTab;
