import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { CallCenterService } from "../../../../services/callCenterService";
import Loader from "../../../../components/Loader";

const Completion = (props) => {
	const leadService = new CallCenterService();
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState(false);

	// console.log(props.finalData);
	useEffect(() => {
		if (props.finalData) {
			setLoading(true);

			// console.log(props.finalData);
			leadService
				.bulkAgentLeadSAve(props.finalData)
				.then((res) => {
					setType(true);
					setLoading(false);
				})
				.catch((e) => {
					setType(false);
					setLoading(false);
				});
		}
	}, []);

	return (
		<>
			{loading && <Loader />}
			{!loading && (
				<div className="mt-6">
					{type ? (
						<>
							<p className="text-center">
								<i
									className="pi pi-verified"
									style={{ fontSize: "30px", color: "green" }}
								/>
							</p>

							<h3 className="text-center">
								Your data has been uploaded Successfully
							</h3>
						</>
					) : (
						<>
							<p className="text-center">
								<i
									className="pi pi-info-circle"
									style={{ fontSize: "30px", color: "red" }}
								/>
							</p>

							<h3 className="text-center">
								Sorry! There are some error in your data.
								<br /> Please recheck,edit & upload again.
							</h3>
						</>
					)}
				</div>
			)}
			<div className="flex justify-content-end">
				<Button
					className="p-button-danger"
					label="Cancel"
					onClick={() => props.handleStatusDialogeClose()}
				/>
			</div>
		</>
	);
};

export default Completion;
