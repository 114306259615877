import React, { useEffect, useReducer, useState } from "react";
import { Button } from "primereact/button";

import { Divider } from "primereact/divider";
import { ProgressSpinner } from "primereact/progressspinner";
import { ProgramService } from "../../../../../services/ProgramService";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../../components/fetchReducer";
import Config from "../../../../../shared/config";
import CommisionUpdate from "./formcomponents/CommisionUpdate";
import { useDispatch, useSelector } from "react-redux";
import { setEnable } from "../../../../../store/reducer/ProgramReducer";

const HandleCommission = ({ programId, type }) => {
	const dispatch = useDispatch();
	const programService = new ProgramService();
	const [open, setOpen] = useState(false);
	const [commsissionState, commissionDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const selector = useSelector((state) => state.program.programId);
	const getData = () => {
		if (type === "edit") {
			commissionDispatch({ type: Config.FETCH_CONFIG.start });
			programService
				.getAllCommission(programId)
				.then((res) => {
					commissionDispatch({
						type: Config.FETCH_CONFIG.success,
						payload: res,
					});
				})
				.catch(() => {})
				.finally(() =>
					commissionDispatch({ type: Config.FETCH_CONFIG.stop })
				);
		} else {
			commissionDispatch({ type: Config.FETCH_CONFIG.start });
			programService
				.getAllCommission(selector)
				.then((res) => {
					commissionDispatch({
						type: Config.FETCH_CONFIG.success,
						payload: res,
					});
				})
				.catch(() => {})
				.finally(() =>
					commissionDispatch({ type: Config.FETCH_CONFIG.stop })
				);
		}
	};

	useEffect(() => {
		programId && getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [programId]);
	return (
		<>
			<h3 className="text-center">Commission Details</h3>
			{programId ? (
				<>
					<div className="w-full flex align-items-center justify-content-end">
						<Button
							icon={open ? "pi pi-times" : "pi pi-plus"}
							label={open ? "" : "Add"}
							className={
								open ? "p-button-danger p-button-text" : ""
							}
							onClick={() => setOpen((p) => !p)}
						/>
					</div>
					{open && (
						<CommisionUpdate
							type={"create"}
							typeOption={type}
							programId={type === "edit" ? programId : selector}
							getData={getData}
						/>
					)}
					<Divider align={"left"}>Commission List</Divider>
					{commsissionState.isLoading ? (
						<div className="flex align-items-center justify-content-center py-5">
							<ProgressSpinner
								style={{ height: "30px" }}
								strokeWidth="5"
								fill="var(--surface-ground)"
								animationDuration=".5s"
							/>
						</div>
					) : (
						commsissionState.data.map((item, index) => (
							<div key={index}>
								<h3 className="mb-0">{index + 1}.</h3>
								<CommisionUpdate
									typeOption={type}
									type={"edit"}
									item={item}
								/>
							</div>
						))
					)}
				</>
			) : (
				<>
					<CommisionUpdate
						type={"create"}
						typeOption={type}
						programId={selector}
						getData={getData}
					/>
				</>
			)}

			{programId && type === "edit" && (
				<div className="flex justify-content-end align-items-center col-12 gap-2 mt-4">
					<Button
						type="button"
						onClick={() => {
							dispatch(setEnable(0));
						}}
						icon={"pi pi-arrow-left"}
					/>
					<Button
						type="button"
						onClick={() => {
							dispatch(setEnable(2));
						}}
						icon={"pi pi-arrow-right"}
					/>
				</div>
			)}
			{programId && type === "copy" && (
				<div className="flex justify-content-end align-items-center col-12 gap-2 mt-4">
					<Button
						type="button"
						onClick={() => {
							dispatch(setEnable(2));
						}}
						icon={"pi pi-arrow-right"}
					/>
				</div>
			)}
		</>
	);
};

export default HandleCommission;
