/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button } from "primereact/button";
import React from "react";
import { AiOutlineFileSearch } from "react-icons/ai";
import { Image } from "primereact/image";
import Config from "../../../../shared/config";

const BankDetail = (props) => {
	// const handleDownload = () => {
	// 	saveA
	// }

	return props.agent.bankDetails ? (
		<div className="grid">
			<div className="col-12 lg:col-6">
				<div className="text-key-val-grid">
					<p className="m-0 lable">A/C Type:</p>
					<p className="m-0 value">
						{props.agent.bankDetails.accountType}
					</p>
				</div>
				<div className="text-key-val-grid">
					<p className="m-0 lable">A/C No:</p>
					<p className="m-0 value">
						{props.agent.bankDetails.accountNumber}
					</p>
				</div>
				<div className="text-key-val-grid">
					<p className="m-0 lable">Bank Name:</p>
					<p className="m-0 value">
						{props.agent.bankDetails.bankName}
					</p>
				</div>
				<div className="text-key-val-grid">
					<p className="m-0 lable">Bank Address:</p>
					<p className="m-0 value">
						{props.agent.bankDetails.bankAddress}
					</p>
				</div>
				<div className="text-key-val-grid">
					<p className="m-0 lable">Name as in Bank Account:</p>
					<p className="m-0 value">{props.agent.bankDetails.name}</p>
				</div>
				<div className="text-key-val-grid">
					<p className="m-0 lable">IFSC:</p>
					<p className="m-0 value">{props.agent.bankDetails.ifsc}</p>
				</div>
				<div className="text-key-val-grid">
					<p className="m-0 lable">MICR:</p>
					<p className="m-0 value">{props.agent.bankDetails.micr}</p>
				</div>
			</div>
			{props.agent.bankDetails.chequeId ? (
				<div className="col-12 lg:col-6">
					<div className="mt-3 flex justify-content-around">
						<p style={{ fontWeight: 700 }}>Cancelled Cheque</p>
						<a
							href={`${Config.AGENT_BUCKET_ACCESS_URL}/agent-profile/${props.agent.profile.id}/${props.agent.bankDetails.chequeId}`}
							download={true}
							rel="noreferrer"
							target="_black"
						>
							<Button icon="pi pi-arrow-down" label="download" />
						</a>
					</div>

					{/* <img
					src={`${Config.AGENT_BUCKET_ACCESS_URL}/agent-profile/${props.agent.profile.id}/${props.agent.bankDetails.chequeId}`}
					alt="cancel-check-photo"
					style={{ width: "100%" }}
				/> */}
					{props.agent.bankDetails.chequeId &&
					props.agent.bankDetails.chequeId
						.split(/[#?]/)[0]
						.split(".")
						.pop()
						.trim() === "pdf" ? (
						<iframe
							title="cancel-check-photo"
							src={`${Config.AGENT_BUCKET_ACCESS_URL}/agent-profile/${props.agent.profile.id}/${props.agent.bankDetails.chequeId}`}
							style={{ width: "100%" }}
						/>
					) : props.agent.bankDetails.chequeId === null ? null : (
						<Image
							src={`${Config.AGENT_BUCKET_ACCESS_URL}/agent-profile/${props.agent.profile.id}/${props.agent.bankDetails.chequeId}`}
							alt="cancel-check-photo"
							preview
							imageClassName="w-full h-19rem"
							imageStyle={{ objectFit: "contain" }}
							// onError={(event) => {
							// 	event.target.src = `${Config.AGENT_BUCKET_ACCESS_URL}/agent-profile/${props.agent.bankDetails.chequeId}`;
							// 	event.onerror = null;
							// }}
						/>
					)}
				</div>
			) : (
				<div className="col-12 lg:col-6">
					<div className="mt-3">
						<p style={{ fontWeight: 700 }}>Cancelled Cheque</p>
					</div>
					<div
						className="w-full border-dashed border-2 border-400 border-round-md surface-overlay flex align-items-center justify-content-center flex-column"
						style={{ minHeight: "90%" }}
					>
						<h3 className="text-6xl m-0">
							<AiOutlineFileSearch />
						</h3>
						<p>Cancelled Cheque Not Uploaded</p>
					</div>
				</div>
			)}
		</div>
	) : (
		<div className="flex align-items-center justify-content-center py-5 bg-indigo-50 m-4 border-dashed border-1 border-indigo-500">
			<p>No data</p>
		</div>
	);
};

export default BankDetail;
