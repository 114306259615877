import { Suspense, useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConnectedFocusError } from "focus-formik-error";
import { Field, FieldArray, Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
	FeildCalender,
	FeildDropdown,
	FieldCheckBox,
	FieldInput,
	FieldMultiselect,
	FieldRadio,
	FieldTextArea,
} from "../../../../../components/FieldInput";
import Config from "../../../../../shared/config";
import moment from "moment";
import selectState from "../../../../../components/stateList";
import { useGetAllDIstrictQuery } from "../../../../../services/query/queryApi";
import { LoanApplicationService } from "../../../../../services/LoanApplication";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../../components/fetchReducer";
import { LoanService } from "../../../../../services/LoanService";
import { Divider } from "primereact/divider";
import DocumentUpload from "../DocumentUpload";
import {
	convertNullToString,
	convertObjNull,
} from "../../../../../utils/UtilsFunction";
import Loader from "../../../../../components/Loader";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import PreviewPdf from "../PreviewApplication";
import { setCountryApplicationId } from "../../../../../store/reducer/AuthReducer";
import Header from "../../../../partners/institutions/header";

const MedicalInsurance = (props) => {
	// console.log(props);
	const navigate = useNavigate();
	const [leaddetailsstate, leadDetailsDIspatch] = useReducer(dataReducer, {
		data: {},
		isLoading: false,
		error: false,
		errorMessage: null,
		isFetched: false,
	});
	const selector = useSelector((state) => state.persistData);
	const [allDisease, setAllDisease] = useState([]);
	const [loader, setLoader] = useState(true);
	const [printDialog, setPrintDialog] = useState(false);
	const dispatch = useDispatch();
	const {
		data: getAllDistrict = [
			{ districtList: [{ id: null, name: "" }], id: null, name: "" },
		],
	} = useGetAllDIstrictQuery();
	const toastTL = useRef(null);
	const [formState, formDispatch] = useReducer(dataReducer, {
		data: {},
		isLoading: false,
		error: false,
		errorMessage: null,
		isFetched: false,
	});
	const loanApplicationService = new LoanApplicationService();
	const loanService = new LoanService();
	const getApplicationByMobile = () => {
		setLoader(true);
		loanApplicationService
			.getLoanApplicationCreateFullList(props?.locationData?.mobileNumber)
			.then((res) => {
				leadDetailsDIspatch({
					type: Config.FETCH_CONFIG.success,
					payload: convertNullToString(res),
				});
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoader(false));
	};
	useEffect(() => {
		Promise.all([
			loanApplicationService.getDisease(),
			(props.locationData.applicationId ||
				selector.countryApplicationId) &&
				loanApplicationService.getMedicalInsuranceCreateFullListById(
					props.locationData.applicationId
						? props.locationData.applicationId
						: selector.countryApplicationId
				),
			props?.locationData?.mobileNumber && getApplicationByMobile(),
		])
			.then((response) => {
				const diseaseResponse = response[0];
				setAllDisease(diseaseResponse);

				response[1] &&
					formDispatch({
						type: Config.FETCH_CONFIG.success,
						payload: response[1],
					});
			})
			.catch((error) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoader(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const insuranceInitialValue = {
		// planType: "",
		// salutation: "",
		// firstName: "",
		// middleName: "",
		// lastName: "",
		// monthlyIncome: "",
		// mobileNumber: "",
		// gender: "",
		// pincode: "",
		// pan: "",
		// state: "",
		// district: "",

		// generateQuote: "",
		insuaredPerson: [
			{
				name: "SELF",
				value: false,
			},
			{
				name: "WIFE",
				value: false,
			},
			{
				name: "FATHER",
				value: false,
			},
			{
				name: "MOTHER",
				value: false,
			},
			{
				name: "SON",
				value: false,
			},
			{
				name: "DAUGHTER",
				value: false,
			},
		],
		// insurerDetails: [
		// 	// {
		// 	// 	label: "Self",
		// 	// 	salutation: "",
		// 	// 	firstName: "",
		// 	// 	middleName: "",
		// 	// 	lastName: "",
		// 	// 	gender: "",
		// 	// 	dob: "",
		// 	// 	mobileNumber: "",
		// 	// 	haveAnyDisease: [],
		// 	// },
		// ],
		// modeOfPayment: "",
		// paymentFrequency: "",
		// premium: "",
		// nomineeName: "",
		// nomineeDob: "",
		// nomineeGender: "",
		// relationshipWithInsuared: "",
		// haveAnyDisease: [],
		// nomineePan: "",
		// nomineeAadhaar: "",
		// portability: "",
		dialog: false,
		daughterDialog: false,
		sonCount: 0,
		daughterCount: 0,
		//
		//
		// id: 123456789,
		// program: {
		// 	programId: 987,
		// 	programName: "Example Program",
		// },
		// vendorId: 456,
		// leadId: 789,
		// productId: 321,
		// agentCode: "ABC123",
		salutation: "",
		firstName: "",
		middleName: "",
		lastName: "",
		annualIncome: "", //num
		mobileNumber: "",
		gender: "",
		pincode: "",
		pan: "",
		state: "", //num
		district: "", //num
		generateQuote: "",
		modeOfPayment: "",
		paymentFrequency: "",
		premium: "", //num
		nomineeName: "",
		nomineeDob: "",
		nomineeGender: "",
		relationshipWithInsured: "",
		haveAnyDisease: [],
		nomineePan: "",
		nomineeAadhaar: "",
		// portability: "",
		dob: "",
		insurerDetails: [
			// {
			// 	id: 123456789,
			// 	salutation: "Mr.",
			// 	firstName: "John",
			// 	middleName: "Doe",
			// 	lastName: "Smith",
			// 	gender: "Male",
			// 	dob: "1990-01-15",
			// 	mobileNumber: "1234567890",
			// 	haveAnyDisease: [1, 3, 5],
			// },
		],
	};
	const getDistrictData = (e) => {
		const stateData = getAllDistrict.find((item) => item.id === e);
		if (stateData !== undefined) {
			const districtData = stateData.districtList.map((e) => ({
				...e,
				value: e.id,
			}));
			return districtData;
		} else {
			return [];
		}
	};
	const getData = (applicationId) => {
		loanApplicationService
			.getMedicalInsuranceCreateFullListById(applicationId)
			.then((res) => {
				const { insurerDetails, ...rest } = res;
				const data = convertNullToString(rest);
				const insuarernewDetails = insurerDetails
					? insurerDetails?.map((item) => ({
							...item,
							dob: Boolean(item.dob) ? new Date(item.dob) : "",
					  }))
					: [];
				formDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: { ...data, insurerDetails: insuarernewDetails },
				});
			})

			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				formDispatch({ type: Config.FETCH_CONFIG.stop });
			});
	};

	const handleFormSubmit = (value) => {
		const {
			insuaredPerson,
			dialog,
			daughterDialog,
			sonCount,
			daughterCount,
			insurerDetails,
			...rest
		} = value;
		// if (
		// 	props?.locationData?.alias !== Config.PRODUCT_ALIAS.INDIVIDUAL &&
		// 	insurerDetails?.length === 0
		// ) {
		// 	toastTL.current.show({
		// 		severity: "error",
		// 		summary: "Select minimum one",
		// 		detail: "",
		// 		style: { color: "#000000" },
		// 		life: 3000,
		// 	});
		// }
		// console.log()
		const newinsurerDetails =
			props?.locationData?.alias === Config.PRODUCT_ALIAS.INDIVIDUAL
				? []
				: // 	?.map((elm) => ({
				  // 		...elm,
				  // 		label: "SELF",
				  // 		salutation: rest.salutation,
				  // 		firstName: rest.firstName,
				  // 		middleName: rest.middleName,
				  // 		lastName: rest.lastName,
				  // 		gender: rest.gender,
				  // 		dob: rest.dob
				  // 			? moment(rest.dob).format("YYYY-MM-DD")
				  // 			: null,
				  // 		mobileNumber: rest.mobileNumber,
				  // 		haveAnyDisease: rest.haveAnyDisease,
				  //   }))
				  insurerDetails?.map((elm) => ({
						...elm,

						dob: elm.dob
							? moment(elm.dob).format("YYYY-MM-DD")
							: null,
				  }));
		const reqBody = {
			...rest,
			dob: rest.dob ? moment(rest.dob).format("YYYY-MM-DD") : null,
			nomineeDob: rest.nomineeDob
				? moment(rest.nomineeDob).format("YYYY-MM-DD")
				: null,
			insurerDetails: newinsurerDetails,
		};
		// const requestBody = props.locationData.applicationId?  {
		// 	...rest,
		// 	program: {
		// 		programId: props?.locationData?.programId,
		// 	},
		// 	productId: props?.locationData?.subproductId,

		// };
		const param = props.locationData.applicationId
			? reqBody
			: {
					...reqBody,
					program: { id: props.locationData?.programId },
					...(selector.countryApplicationId && {
						id: selector.countryApplicationId,
					}),
			  };

		const data = convertObjNull(param);

		setLoader(true);
		loanApplicationService
			.medicalInsuranceDraftSave(data)
			.then((res) => {
				!props.locationData?.applicationId &&
					!selector.countryApplicationId &&
					dispatch(setCountryApplicationId(res.id));
				props.locationData?.applicationId
					? getData(props.locationData?.applicationId)
					: selector.countryApplicationId
					? getData(selector.countryApplicationId)
					: getData(res.id);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail:
						props.locationData?.applicationId ||
						selector.countryApplicationId
							? "Application updated"
							: "Application saved",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoader(false);
			});
	};

	const finalSubmit = (applicationId) => {
		setLoader(true);

		loanService
			.insuranceApplicationSubmit(applicationId)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});

				navigate("/masters/insurance-application-list");
			})

			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoader(false);
			});
	};

	const validationSchema = Yup.object().shape({
		// daughterCount: Yup.number().max(10, "Invalid amount").nullable(),
		// sonCount: Yup.number().max(10, "Invalid amount").nullable(),
		mobileNumber: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.nullable()
			.required("This field is required"),
		firstName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid first name"
			)
			.nullable()
			.required("This field is required"),
		state: Yup.number().nullable().required("State is required"),
		district: Yup.number().nullable().required("District is required"),
		lastName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid last name"
			)
			.nullable()
			.required("This field is required"),
		pan: Yup.string()
			.matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Pan Number is invalid")
			.nullable()
			.required("This field is required"),
		// nomineePan: Yup.string()
		// 	.matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Pan Number is invalid")
		// 	.nullable(),
		gender: Yup.string().nullable().required("This field is required"),
		// nomineeAadhaar: Yup.string()
		// 	.nullable()
		// 	.required("This field is required"),
		insurerDetails: Yup.array().when("firstName", {
			is: () =>
				props?.locationData?.alias !== Config.PRODUCT_ALIAS.INDIVIDUAL,
			then: Yup.array().of(
				Yup.object()
					.shape({
						firstName: Yup.string()
							.matches(
								/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
								"Please enter valid first name"
							)
							.nullable()
							.required("This field is required"),
						lastName: Yup.string()
							.matches(
								/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
								"Please enter valid last name"
							)
							.nullable()
							.required("This field is required"),
						gender: Yup.string()
							.nullable()
							.required("This field is required"),
						dob: Yup.date().required("This field is required"),
						mobileNumber: Yup.string()
							.matches(
								/^[1-9]\d{9}$/gi,
								"Mobile number should be 10 digit number"
							)
							.nullable()
							.required("This field is required"),
					})
					.required("This field is required")
			),
		}),
	});

	return (
		<>
			{loader && <Loader />}
			<Toast ref={toastTL} position="top-left" />
			<Dialog
				visible={printDialog}
				style={{ width: "80%" }}
				onHide={() => setPrintDialog(false)}
			>
				{formState.data?.salutation && (
					<PreviewPdf pdfState={formState.data} />
				)}
			</Dialog>

			<Button
				label="back"
				className="mb-2"
				icon={"pi pi-arrow-left"}
				onClick={() => navigate("/masters/insurance-application-list")}
			/>
			<Header
				stages={[]}
				title="Application"
				stageName={
					props?.locationData?.programName
						? props?.locationData?.programName
						: formState?.data?.program?.name
				}
			/>

			<Formik
				onSubmit={handleFormSubmit}
				initialValues={{
					...insuranceInitialValue,
					...(!props.locationData?.applicationId && {
						...leaddetailsstate.data,
						dob: Boolean(leaddetailsstate.data?.dob)
							? new Date(leaddetailsstate.data?.dob)
							: "",
						nomineeDob: Boolean(leaddetailsstate.data?.nomineeDob)
							? new Date(leaddetailsstate.data?.nomineeDob)
							: "",
					}),
					pan: props?.locationData?.panNumber,
					state: props?.locationData?.state,
					district: props?.locationData?.district,
					mobileNumber: props?.locationData?.mobileNumber,
					leadId: props?.locationData?.leadId
						? props?.locationData?.leadId
						: "",
					...((props.locationData?.applicationId ||
						selector.countryApplicationId) && {
						...formState?.data,
						dob: Boolean(formState.data?.dob)
							? new Date(formState.data?.dob)
							: "",
						insurerDetails: formState.data?.insurerDetails?.map(
							(item) => ({
								...item,
								dob: Boolean(item.dob)
									? new Date(item.dob)
									: "",
							})
						),
						insuaredPerson:
							insuranceInitialValue.insuaredPerson.map((elm) => {
								if (
									formState.data?.insurerDetails?.some(
										(item) => item.label.includes(elm.name)
									)
								) {
									return { ...elm, value: true };
								} else {
									return elm;
								}
							}),
						nomineeDob: Boolean(formState.data?.nomineeDob)
							? new Date(formState.data?.nomineeDob)
							: "",
					}),
				}}
				validationSchema={validationSchema}
				enableReinitialize
			>
				{({ values, handleChange, handleSubmit, setFieldValue }) => (
					<Form
						className="py-5"
						onSubmit={handleSubmit}
						autoComplete="off"
					>
						<ConnectedFocusError />
						<Panel
							header={<h3 className="m-0">Proposer Details</h3>}
							className={"my-2"}
							toggleable
						>
							<div className="grid form-grid">
								<div className="field col-12 md:col-2">
									<Field
										header={"Salutation"}
										name={"salutation"}
										component={FeildDropdown}
										options={Config.SALUTATION}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"First Name"}
										name={"firstName"}
										component={FieldInput}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-2">
									<Field
										header={"Middle Name"}
										name={"middleName"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Last Name"}
										name={"lastName"}
										component={FieldInput}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-2">
									<Field
										header={"Gender"}
										name={`gender`}
										component={FeildDropdown}
										options={Config.GENDERS}
										optionLabel={"name"}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Disease"}
										name={`haveAnyDisease`}
										filter
										component={FieldMultiselect}
										options={allDisease}
										optionLabel="disease"
										optionValue="id"
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Date Of Birth"}
										name={`dob`}
										component={FeildCalender}
										viewDate={moment(new Date())
											.subtract(18, "years")
											.toDate()}
										maxDate={moment(new Date())
											.subtract(18, "years")
											.toDate()}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Pan"}
										name={"pan"}
										component={FieldInput}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Mobile Number"}
										name={"mobileNumber"}
										component={FieldInput}
										star={"*"}
									/>
								</div>

								<div className="field col-12 md:col-3">
									<Field
										header={"State"}
										name={"state"}
										filter
										component={FeildDropdown}
										options={selectState(getAllDistrict)}
										optionLabel={"name"}
										star={"*"}
									/>
								</div>
								{values.state && (
									<div className="field col-12 md:col-3">
										<Field
											header={"District"}
											name={"district"}
											filter
											component={FeildDropdown}
											options={getDistrictData(
												values.state
											)}
											optionLabel={"name"}
											star={"*"}
										/>
									</div>
								)}
								<div className="field col-12 md:col-3">
									<Field
										name={"pincode"}
										header={"pincode"}
										component={FieldInput}
										type={"number"}
									/>
								</div>
								{/* <div className="field col-12 md:col-4">
								<Field
									header={"Occupation Type"}
									name={"occupationType"}
									component={FeildDropdown}
									options={Config.OCCUPATION}
								/>
							</div> */}
								<div className="field col-12 md:col-3">
									<Field
										name={"annualIncome"}
										header={"Annual income"}
										component={FieldInput}
										type={"number"}
									/>
								</div>
								<div className="col-12 flex align-items-end justify-content-end">
									{selector.applicationState !==
										"preview" && (
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									)}
								</div>
							</div>
						</Panel>
						{props?.locationData?.alias !==
							Config.PRODUCT_ALIAS.INDIVIDUAL && (
							<Panel
								header={
									<h3 className="m-0">Insurer Details</h3>
								}
								className={"my-2"}
								toggleable
							>
								<div className="grid align-items-center">
									{/* <div className="field col-12 md:col-4">
									<Field
										header={"Plan"}
										name={"planType"}
										component={FeildDropdown}
										options={Config.SALUTATION}
									/>
								</div> */}
									<div className="field col-12 md:col-8">
										<Suspense fallback={null}>
											<FieldArray
												name="insuaredPerson"
												render={(arrayHelper) => (
													<>
														<div class="flex mt-5">
															{values.insuaredPerson?.map(
																(
																	item,
																	index
																) => (
																	<div
																		className="field-checkbox mr-2"
																		key={
																			index
																		}
																	>
																		<Checkbox
																			onChange={(
																				e
																			) => {
																				// console.log(
																				// 	values?.insurerDetails
																				// );
																				setFieldValue(
																					`insuaredPerson.${index}.value`,
																					e.checked,
																					true
																				);
																				if (
																					item.name ===
																					"SON"
																				) {
																					if (
																						e.checked
																					) {
																						setFieldValue(
																							"dialog",
																							e.checked,
																							true
																						);
																					} else {
																						const data =
																							[
																								...values?.insurerDetails,
																							].filter(
																								(
																									item
																								) =>
																									item.label !==
																									"SON"
																							);
																						setFieldValue(
																							`insurerDetails`,
																							data,
																							true
																						);
																					}
																				} else if (
																					item.name ===
																					"DAUGHTER"
																				) {
																					if (
																						e.checked
																					) {
																						setFieldValue(
																							"daughterDialog",
																							e.checked,
																							true
																						);
																					} else {
																						const data =
																							[
																								...values?.insurerDetails,
																							].filter(
																								(
																									item
																								) =>
																									item.label !==
																									"DAUGHTER"
																							);
																						setFieldValue(
																							`insurerDetails`,
																							data,
																							true
																						);
																					}
																				} else {
																					if (
																						e.checked
																					) {
																						const data =
																							[
																								...values?.insurerDetails,
																							];
																						if (
																							item.name ===
																							"SELF"
																						) {
																							data.push(
																								{
																									label: item?.name,
																									salutation:
																										values.salutation,
																									firstName:
																										values.firstName,
																									middleName:
																										values.middleName,
																									lastName:
																										values.lastName,
																									gender: values.gender,
																									dob: values.dob,
																									mobileNumber:
																										values.mobileNumber,
																									haveAnyDisease:
																										values.haveAnyDisease,
																								}
																							);
																						} else {
																							data.push(
																								{
																									label: item?.name,
																									salutation:
																										"",
																									firstName:
																										"",
																									middleName:
																										"",
																									mobileNumber:
																										"",
																									lastName:
																										"",
																									gender: "",
																									dob: "",
																									haveAnyDisease:
																										[],
																								}
																							);
																						}

																						setFieldValue(
																							`insurerDetails`,
																							data,
																							true
																						);
																					} else {
																						const data =
																							[
																								...values?.insurerDetails,
																							].filter(
																								(
																									elm
																								) =>
																									elm.label !==
																									item?.name
																							);
																						setFieldValue(
																							`insurerDetails`,
																							data,
																							true
																						);
																					}
																				}
																			}}
																			checked={
																				values
																					.insuaredPerson[
																					index
																				]
																					.value
																			}
																		/>
																		<label htmlFor="city1">
																			{
																				item?.name
																			}
																		</label>
																	</div>
																)
															)}
														</div>
													</>
												)}
											/>
										</Suspense>
										{values.insurerDetails?.length ===
											0 && (
											<small className="font-bold text-red-400">
												* Select minimum one option
											</small>
										)}
									</div>

									<Suspense fallback={null}>
										<Dialog
											header={"SON"}
											visible={values.dialog}
											onHide={() => {
												const index =
													values.insuaredPerson.findIndex(
														(elm) =>
															elm.name === "SON"
													);
												if (index) {
													setFieldValue(
														`insuaredPerson.${index}.value`,
														false,
														true
													);
												}
												setFieldValue(
													"dialog",
													false,
													true
												);
											}}
										>
											<Field
												name={"sonCount"}
												type={"number"}
												component={FieldInput}
											/>
											<Button
												disabled={values.sonCount > 5}
												className="mt-2"
												type="button"
												label="Add"
												onClick={() => {
													const newArr = Array(
														values.sonCount
													)
														.fill()
														.map((item, index) => ({
															label: "SON",
															salutation: "",
															firstName: "",
															middleName: "",
															lastName: "",
															mobileNumber: "",
															gender: "",
															dob: "",
															haveAnyDisease: [],
														}));
													const data = [
														...values?.insurerDetails,
														...newArr,
													];

													setFieldValue(
														`insurerDetails`,
														data,
														true
													);
													setFieldValue(
														"dialog",
														false,
														true
													);
												}}
											/>
										</Dialog>
										<Dialog
											header={"Daughter"}
											visible={values.daughterDialog}
											onHide={() => {
												const index =
													values.insuaredPerson.findIndex(
														(elm) =>
															elm.name ===
															"DAUGHTER"
													);

												if (index) {
													setFieldValue(
														`insuaredPerson.${index}.value`,
														false,
														true
													);
												}
												setFieldValue(
													"daughterDialog",
													false,
													true
												);
											}}
										>
											<Field
												name={"daughterCount"}
												type={"number"}
												component={FieldInput}
											/>
											<Button
												disabled={
													values.daughterCount > 5
												}
												type="button"
												label="Add"
												onClick={() => {
													const newArr = Array(
														values.daughterCount
													)
														.fill()
														.map((item, index) => ({
															label: "DAUGHTER",
															salutation: "",
															firstName: "",
															middleName: "",
															lastName: "",
															gender: "",
															mobileNumber: "",
															dob: "",
															haveAnyDisease: [],
														}));
													const data = [
														...values?.insurerDetails,
														...newArr,
													];

													setFieldValue(
														`insurerDetails`,
														data,
														true
													);
													setFieldValue(
														"daughterDialog",
														false,
														true
													);
												}}
											/>
										</Dialog>
									</Suspense>
								</div>
								<div className="form-grid grid">
									<Suspense fallback={null}>
										<FieldArray
											name="insurerDetails"
											render={(arrayHelper) => (
												<>
													{values.insurerDetails?.map(
														(elm, index) => (
															<div
																key={index}
																className="col-12"
															>
																<div className="c-Salutation-box">
																	<h4>
																		{
																			elm?.label
																		}
																	</h4>
																	{(elm?.label ===
																		"SON" ||
																		elm?.label ===
																			"DAUGHTER") && (
																		// <Button
																		// 	type="button"
																		// 	className="p-button-text"
																		// 	onClick={() =>
																		// 		arrayHelper.remove(
																		// 			index
																		// 		)
																		// 	}
																		// 	icon="pi pi-minus"
																		// />
																		<Button
																			type="button"
																			icon="pi pi-trash"
																			className="p-button-danger p-button-rounded cr-removedBtn"
																			onClick={() =>
																				arrayHelper.remove(
																					index
																				)
																			}
																		/>
																	)}
																	<div className="form-grid grid">
																		<div className="field col-12 md:col-2">
																			<Field
																				header={
																					"Salutation"
																				}
																				name={`insurerDetails.${index}.salutation`}
																				component={
																					FeildDropdown
																				}
																				options={
																					Config.SALUTATION
																				}
																			/>
																		</div>
																		<div className="field col-12 md:col-3">
																			<Field
																				header={
																					"First Name"
																				}
																				name={`insurerDetails.${index}.firstName`}
																				component={
																					FieldInput
																				}
																			/>
																		</div>
																		<div className="field col-12 md:col-2">
																			<Field
																				header={
																					"Middle Name"
																				}
																				name={`insurerDetails.${index}.middleName`}
																				component={
																					FieldInput
																				}
																			/>
																		</div>
																		<div className="field col-12 md:col-3">
																			<Field
																				header={
																					"Last Name"
																				}
																				name={`insurerDetails.${index}.lastName`}
																				component={
																					FieldInput
																				}
																			/>
																		</div>
																		<div className="field col-12 md:col-2">
																			<Field
																				header={
																					"Gender"
																				}
																				name={`insurerDetails.${index}.gender`}
																				component={
																					FeildDropdown
																				}
																				options={
																					Config.GENDERS
																				}
																				optionLabel={
																					"name"
																				}
																			/>
																		</div>
																		<div className="field col-12 md:col-3">
																			<Field
																				header={
																					"Date Of Birth"
																				}
																				name={`insurerDetails.${index}.dob`}
																				component={
																					FeildCalender
																				}
																				viewDate={moment(
																					new Date()
																				)
																					.subtract(
																						18,
																						"years"
																					)
																					.toDate()}
																				maxDate={moment(
																					new Date()
																				)
																					.subtract(
																						18,
																						"years"
																					)
																					.toDate()}
																			/>
																		</div>

																		<div className="field col-12 md:col-3">
																			<Field
																				header={
																					"Disease"
																				}
																				name={`insurerDetails.${index}.haveAnyDisease`}
																				filter
																				component={
																					FieldMultiselect
																				}
																				options={
																					allDisease
																				}
																				optionLabel="disease"
																				optionValue="id"
																			/>
																		</div>
																		<div className="field col-12 md:col-3">
																			<Field
																				header={
																					"Mobile No."
																				}
																				name={`insurerDetails.${index}.mobileNumber`}
																				component={
																					FieldInput
																				}
																			/>
																		</div>
																	</div>
																</div>
															</div>
														)
													)}
													{}
												</>
											)}
										/>
									</Suspense>

									<div className="col-12 flex align-items-end justify-content-end">
										{selector.applicationState !==
											"preview" && (
											<Button
												type="button"
												label="Save as draft"
												onClick={handleSubmit}
											/>
										)}
									</div>
								</div>
							</Panel>
						)}

						<Panel
							header={<h3 className="m-0">Nominee Details</h3>}
							className={"my-2"}
							toggleable
						>
							<div className="form-grid grid">
								<div className="field col-12 md:col-3">
									<Field
										header={"Name"}
										name={"nomineeName"}
										component={FieldInput}
									/>
								</div>

								<div className="field col-12 md:col-3">
									<Field
										header={"Date Of Birth"}
										name={"nomineeDob"}
										component={FeildCalender}
										viewDate={moment(new Date())
											.subtract(18, "years")
											.toDate()}
										maxDate={moment(new Date())
											.subtract(18, "years")
											.toDate()}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Gender"}
										name={"nomineeGender"}
										component={FeildDropdown}
										options={Config.GENDERS}
										optionLabel={"name"}
									/>
								</div>

								<div className="field col-12 md:col-3">
									<Field
										header={
											"Select Relationship with Insured"
										}
										name={"relationshipWithInsured"}
										component={FeildDropdown}
										options={Config.RELATIONSHIP}
										optionLabel={"label"}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Pan"}
										name={"nomineePan"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Aadhaar"}
										name={"nomineeAadhaar"}
										component={FieldInput}
									/>
								</div>
								{/* <div className="field col-12 md:col-3">
									<Field
										header={"Portability"}
										name={"portability"}
										component={FieldInput}
									/>
								</div> */}
								<div className="col-12 flex align-items-end justify-content-end">
									{selector.applicationState !==
										"preview" && (
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									)}
								</div>
							</div>
						</Panel>

						<Panel
							header={<h3 className="m-0">Payment Details</h3>}
							className={"my-2"}
							toggleable
						>
							<div className="form-grid grid">
								<div className="field col-12 md:col-3">
									<Field
										header={"Payment Frequency"}
										name={"paymentFrequency"}
										component={FeildDropdown}
										options={Config.PAYMENYTYPE}
									/>
								</div>

								<div className="field col-12 md:col-3">
									<Field
										header={"Premium"}
										name={"premium"}
										component={FieldInput}
										type={"number"}
									/>
								</div>

								<div className="field col-12 md:col-3">
									<Field
										header={"Payment Mode"}
										name={"modeOfPayment"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Generate Quote"}
										name={"generateQuote"}
										component={FieldInput}
									/>
								</div>
								<div className="col-12 flex align-items-end justify-content-end">
									{selector.applicationState !==
										"preview" && (
										<Button
											type="button"
											label="Save as draft"
											onClick={handleSubmit}
										/>
									)}
								</div>
							</div>
						</Panel>
					</Form>
				)}
			</Formik>
			<Divider />
			<DocumentUpload
				applicationId={props?.locationData?.applicationId}
				subproductId={props?.locationData?.subproductId}
			/>
			{(props.locationData?.applicationId ||
				selector.countryApplicationId) && (
				<div className="flex justify-content-end align-items-center w-full mt-4">
					<Button
						type="button"
						label="Submit"
						icon="pi pi-save"
						onClick={() => {
							finalSubmit(
								props.locationData?.applicationId
									? props.locationData?.applicationId
									: selector.countryApplicationId
							);
						}}
					/>
				</div>
			)}
		</>
	);
};

export default MedicalInsurance;
