import { ConnectedFocusError } from "focus-formik-error";
import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import {
	FeildCalender,
	FeildDropdown,
	FieldCheckBox,
	FieldInput,
	FieldRadio,
	FieldTextArea,
} from "../../../../../components/FieldInput";
import Config from "../../../../../shared/config";
import { Checkbox } from "primereact/checkbox";
import moment from "moment";
import selectState from "../../../../../components/stateList";
import { useSelector } from "react-redux";

const HomeLoan = (props) => {
	const persistSelector = useSelector((state) => state.persistData);
	return (
		<Formik
			onSubmit={props.handleFormSubmit}
			initialValues={props.initialValue}
			validationSchema={props.validationSchema}
			enableReinitialize
		>
			{({ values, handleChange, handleSubmit, setFieldValue }) => (
				<Form
					className="py-5"
					onSubmit={handleSubmit}
					autoComplete="off"
				>
					<ConnectedFocusError />

					<Panel
						header={<h3 className="m-0">Loan Details</h3>}
						className={"my-2"}
						toggleable
					>
						<div className="form-grid grid">
							<div className="field col-12 md:col-4">
								<Field
									header={"Reason for Loan"}
									name={"reasonId"}
									filter
									value={values.reasonId}
									component={FeildDropdown}
									options={props.reason}
									optionLabel="reason"
								/>
							</div>

							{values.reasonId === 0 && (
								<div className="field col-12 md:col-4">
									<Field
										header={"Other Reason"}
										name={"otherReason"}
										component={FieldInput}
									/>
								</div>
							)}
							<div className="field col-12 md:col-4">
								<Field
									header={"Loan Amount"}
									name={"loanAmount"}
									component={FieldInput}
									type={"number"}
								/>
							</div>

							<div className="field col-12 md:col-4">
								<Field
									header={"Co-Applicant Allowed"}
									name={"coApplicantAllowed"}
									component={FieldCheckBox}
								/>
							</div>

							<div className="col-12 flex align-items-end justify-content-end">
								{persistSelector?.applicationState ===
									"edit" && (
									<Button
										type="button"
										label="Save as draft"
										onClick={handleSubmit}
									/>
								)}
							</div>
						</div>
					</Panel>

					<Panel
						header={<h3 className="m-0">Personal Details</h3>}
						className={"my-2"}
						toggleable
					>
						<div className="form-grid grid">
							<div className="field col-12 md:col-4">
								<div className="grid">
									<div className="field col-12 md:col-4">
										<Field
											header={"Salutation"}
											name={"salutation"}
											component={FeildDropdown}
											options={Config.SALUTATION}
										/>
									</div>
									<div className="field col-12 md:col-8">
										<Field
											header={"First Name"}
											name={"firstName"}
											component={FieldInput}
											type={"*"}
										/>
									</div>
								</div>
							</div>

							<div className="field col-12 md:col-4">
								<Field
									header={"Middle Name"}
									name={"middleName"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Last Name"}
									name={"lastName"}
									component={FieldInput}
									type={"*"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Email"}
									name={"personalMail"}
									component={FieldInput}
									type={"email"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Mobile Number"}
									name={"mobile"}
									component={FieldInput}
									type={"number"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<label className="text-sm">
									Whatsapp No (Same as Mobile no?)
								</label>
								<div className="p-inputgroup">
									<span className="p-inputgroup-addon p-1">
										<Checkbox
											name="whatsappNumberSame"
											onChange={handleChange}
											checked={values.whatsappNumberSame}
											value={values.whatsappNumberSame}
										/>
									</span>
									<Field
										name={"whatsappNumber"}
										value={
											values.whatsappNumberSame
												? (values.whatsappNumber =
														values.mobile)
												: values.whatsappNumber
										}
										component={FieldInput}
										type={"number"}
										disabled={
											values.whatsappNumberSame
												? true
												: false
										}
									/>
								</div>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"PAN"}
									name={"pan"}
									component={FieldInput}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<div className="grid">
									<div className="field col-12 md:col-4">
										<Field
											header={"Date Of Birth"}
											name={"dob"}
											component={FeildCalender}
											type={"*"}
											maxDate={
												new Date(
													moment()
														.subtract(18, "year")
														.toDate()
												)
											}
											viewDate={
												new Date(
													moment()
														.subtract(18, "year")
														.toDate()
												)
											}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Age"}
											name={"age"}
											component={FieldInput}
											value={
												Math.floor(
													moment(new Date()).diff(
														moment(
															values.dob,
															"YYYY-MM-DD"
														),
														"years",
														true
													)
												) || ""
											}
											// readonly
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Gender"}
											name={"gender"}
											component={FeildDropdown}
											options={Config.GENDERS}
											optionLabel={"name"}
											type={"*"}
										/>
									</div>
								</div>
							</div>

							<div className="field col-12 md:col-4">
								<Field
									header={"Marital Status"}
									name={"maritalStatus"}
									component={FeildDropdown}
									options={Config.MARITAL_STATUS}
									star={"*"}
								/>
							</div>

							{values.maritalStatus === "Married" && (
								<div className="field col-12 md:col-4">
									<Field
										header={"Spouse’s Full Name"}
										name={"spouseName"}
										component={FieldInput}
									/>
								</div>
							)}

							<div className="field col-12 md:col-4">
								<Field
									header={"Father’s Name"}
									name={"fatherName"}
									component={FieldInput}
								/>
							</div>

							<div className="field col-12 md:col-4">
								<Field
									header={"Mother’s Maiden Name"}
									name={"motherMaidenName"}
									component={FieldInput}
									star={"*"}
								/>
							</div>

							<div className="field col-12 md:col-4">
								<Field
									header={"Residential Status"}
									name={"residentialStatus"}
									component={FeildDropdown}
									options={Config.RESIDENTIALSTATUS}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Nationality"}
									name={"nationality"}
									component={FeildDropdown}
									options={Config.NATIONALITY}
								/>
							</div>

							{values.coApplicantAllowed && (
								<>
									<h4 className="m-0 col-12">
										{" "}
										Co-applicant's Details
									</h4>
									<div className="field col-12 md:col-4">
										<Field
											header={"First Name"}
											name={"coFirstName"}
											component={FieldInput}
										/>
									</div>

									<div className="field col-12 md:col-4">
										<Field
											header={"Middle Name"}
											name={"coMiddleName"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Last Name"}
											name={"coLastName"}
											component={FieldInput}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Gender"}
											name={"coGender"}
											component={FeildDropdown}
											options={Config.GENDERS}
											optionLabel={"name"}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Mobile Number"}
											name={"coMobile"}
											component={FieldInput}
											type={"number"}
										/>
									</div>
									<div className="field col-12 md:col-4">
										<Field
											header={"Personal Mail"}
											name={"coPersonalMail"}
											component={FieldInput}
											type={"email"}
										/>
									</div>
								</>
							)}

							{/*   */}

							{/* 

									<div className="field col-12 md:col-4">
										<Field
											header={"Preferred Mailing"}
											name={"preferredMailingAddress"}
											component={FieldInput}
										/>
									</div> */}

							<div className="col-12 flex align-items-end justify-content-end">
								{persistSelector?.applicationState ===
									"edit" && (
									<Button
										type="button"
										label="Save as draft"
										onClick={handleSubmit}
									/>
								)}
							</div>
							{/* <div className="col-12 flex align-items-end justify-content-end">
											<Button
												type="button"
												label="Save as draft"
												onClick={() => {
													handleSubmit();
												}}
											/>
										</div> */}
						</div>
					</Panel>
					<Panel
						header={<h3 className="m-0">Address Details</h3>}
						className={"my-2"}
						toggleable
					>
						<div className="form-grid grid">
							<h4 className="col-12 ">Present Address</h4>
							<div className="field col-12 md:col-4">
								<Field
									header={"Residence Type"}
									name={"residenceType"}
									component={FeildDropdown}
									options={Config.RESIDENTTYPE}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Residing Since"}
									name={"residingSince"}
									component={FeildCalender}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Building Name/Flat No."}
									name={"buildingName"}
									component={FieldInput}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Address Line 1"}
									name={"addressLine1"}
									component={FieldTextArea}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Address Line 2"}
									name={"addressLine2"}
									component={FieldTextArea}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Address Line 3"}
									name={"addressLine3"}
									component={FieldTextArea}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Landmark"}
									name={"landmark"}
									component={FieldInput}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"City"}
									name={"city"}
									component={FieldInput}
								/>
							</div>

							<div className="field col-12 md:col-4">
								<Field
									header={"State"}
									name={"state"}
									filter
									// onChange={(e) => {
									// 	handleDistrict(
									// 		e.value,
									// 		setFieldValue
									// 	);
									// }}
									component={FeildDropdown}
									options={selectState(props.getAllDistrict)}
									optionLabel={"name"}
									star={"*"}
								/>
							</div>
							{values.state && (
								<div className="field col-12 md:col-4">
									<Field
										header={"District"}
										name={"districtId"}
										filter
										component={FeildDropdown}
										// options={
										// 	districtListState.districtList
										// }
										options={props.getDistrictData(
											values.state
										)}
										optionLabel={"name"}
									/>
								</div>
							)}
							<div className="field col-12 md:col-4">
								<Field
									name={"pincode"}
									header={"Pincode"}
									component={FieldInput}
									type={"number"}
									star={"*"}
								/>
								{/* <label className="block ">
											Pincode
										</label>
										<InputText
											className="block w-full"
											onChange={(e) =>
												varifyPin(
													e,
													setFieldValue,
													setFieldError,
													"pincode"
												)
											}
											
										/> */}
							</div>

							<h4 className="col-12 ">Permanent Address</h4>
							<div className="col-12">
								<Checkbox
									name="permanentAddressSame"
									onChange={handleChange}
									checked={values.permanentAddressSame}
									value={values.permanentAddressSame}
									className={"ml-2 "}
								/>{" "}
								Same as Present Address
							</div>

							<div className="field col-12 md:col-4">
								<Field
									header={"Residence Type"}
									name={"perResidenceType"}
									component={FeildDropdown}
									options={Config.RESIDENTTYPE}
									value={
										values.permanentAddressSame
											? (values.perResidenceType =
													values.residenceType)
											: values.perResidenceType
									}
									disabled={
										values.permanentAddressSame
											? true
											: false
									}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Residing Since"}
									name={"perResidingSince"}
									component={FeildCalender}
									value={
										values.permanentAddressSame
											? (values.perResidingSince =
													values.residingSince)
											: values.perResidingSince
									}
									disabled={
										values.permanentAddressSame
											? true
											: false
									}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Building Name"}
									name={"perBuildingName"}
									component={FieldInput}
									value={
										values.permanentAddressSame
											? (values.perBuildingName =
													values.buildingName)
											: values.perBuildingName
									}
									disabled={
										values.permanentAddressSame
											? true
											: false
									}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Address Line 1"}
									name={"perAddressLine1"}
									value={
										values.permanentAddressSame
											? (values.perAddressLine1 =
													values.addressLine1)
											: values.perAddressLine1
									}
									component={FieldTextArea}
									disabled={
										values.permanentAddressSame
											? true
											: false
									}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Address Line 2"}
									name={"perAddressLine2"}
									value={
										values.permanentAddressSame
											? (values.perAddressLine2 =
													values.addressLine2)
											: values.perAddressLine2
									}
									component={FieldTextArea}
									disabled={
										values.permanentAddressSame
											? true
											: false
									}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Address Line 3"}
									name={"perAddressLine3"}
									value={
										values.permanentAddressSame
											? (values.perAddressLine3 =
													values.addressLine3)
											: values.perAddressLine3
									}
									component={FieldTextArea}
									disabled={
										values.permanentAddressSame
											? true
											: false
									}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Landmark"}
									name={"perLandmark"}
									value={
										values.permanentAddressSame
											? (values.perLandmark =
													values.landmark)
											: values.perLandmark
									}
									component={FieldInput}
									disabled={
										values.permanentAddressSame
											? true
											: false
									}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"City"}
									name={"perCity"}
									value={
										values.permanentAddressSame
											? (values.perCity = values.city)
											: values.perCity
									}
									component={FieldInput}
									disabled={
										values.permanentAddressSame
											? true
											: false
									}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"State"}
									name={"perState"}
									value={
										values.permanentAddressSame
											? (values.perState = values.state)
											: values.perState
									}
									component={FeildDropdown}
									options={selectState(props.getAllDistrict)}
									filter
									optionLabel={"name"}
									disabled={
										values.permanentAddressSame
											? true
											: false
									}
								/>
							</div>
							<div className="field col-12 md:col-4">
								{/* <InputComponent
												header={"Pin code"}
												name={"perPincode"}
												value={
													values.permanentAddressSame
														? (values.perPincode =
																values.pincode)
														: values.perPincode
												}
												handleChange={handleChange}
											/> */}
								{/* <label className="block ">
												Pincode
											</label>
											<InputText
												value={
													values.permanentAddressSame
														? (values.perPincode =
																values.pincode)
														: values.perPincode
												}
												className="block w-full"
												onChange={(e) =>
													varifyPin(
														e,
														setFieldValue,
														setFieldError,
														"perPincode"
													)
												}
												disabled={
													values.permanentAddressSame ? true : false
												}
											/> */}
								<Field
									header={"Pincode"}
									name={"perPincode"}
									value={
										values.permanentAddressSame
											? (values.perPincode =
													values.pincode)
											: values.perPincode
									}
									component={FieldInput}
									type={"number"}
									star={"*"}
									disabled={
										values.permanentAddressSame
											? true
											: false
									}
								/>
							</div>
							<h4 className="col-12">
								Preferred Mailing Address
							</h4>
							<div className="flex flex-wrap gap-3">
								<Field
									name={"preferredMailingAddress"}
									component={FieldRadio}
									radiolist={[
										{
											id: "Present Address",
											name: "Present Address",
											value: "Present Address",
										},
										{
											id: "Permanent Address",
											name: "Permanent Address",
											value: "Permanent Address",
										},
									]}
								/>
							</div>

							<div className="col-12 flex align-items-end justify-content-end">
								{persistSelector?.applicationState ===
									"edit" && (
									<Button
										type="button"
										label="Save as draft"
										onClick={handleSubmit}
									/>
								)}
							</div>
							{/* <div className="col-12 flex align-items-end justify-content-end">
											<Button
												type="button"
												label="Save as draft"
												onClick={() => {
													handleSubmit
												}
											/>
										</div> */}
						</div>
					</Panel>
					<Panel
						header={<h3 className="m-0">Occupation Details</h3>}
						className={"my-2"}
						toggleable
					>
						<div className="form-grid grid">
							<div className="field col-12 md:col-4">
								<Field
									header={"Highest Qualification"}
									name={"highestQualification"}
									component={FeildDropdown}
									options={Config.QUALIFICATION}
								/>
							</div>

							<>
								<div className="field col-12 md:col-4">
									<Field
										header={"Occupation Type"}
										name={"occupationType"}
										component={FeildDropdown}
										options={Config.OCCUPATION}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Company Type"}
										name={"companyType"}
										component={FeildDropdown}
										options={Config.ORGANIZATION}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={
											"Company Registered Office Address"
										}
										name={"companyRegisteredOfficeAddress"}
										component={FieldInput}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Company Name"}
										name={"companyName"}
										component={FieldInput}
										star={"*"}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Designation"}
										name={"designation"}
										component={FieldInput}
										// options={Config.DESIGNATION}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Official Email Id"}
										name={"officialMail"}
										component={FieldInput}
										type={"email"}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Office Phone Number"}
										name={"officeTelephoneNumber"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Extension"}
										name={"extension"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Industry Type"}
										name={"industryType"}
										component={FeildDropdown}
										options={Config.INDUSTRY}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={
											"Total Year Experience In Months"
										}
										name={"totalYearExperienceInMonths"}
										component={FieldInput}
										type={"number"}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={
											"Total Work Experience (in Yrs)"
										}
										name={"totalYearExperience"}
										component={FieldInput}
										value={(
											values.totalYearExperienceInMonths /
											12
										).toFixed(1)}
										type={"number"}
										disabled
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={
											"Experience In Months In Current Company"
										}
										name={
											"totalYearExperienceInMonthsCurrentCompany"
										}
										component={FieldInput}
										type={"number"}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"Net Monthly Income/Salary"}
										name={"netMonthlyIncome"}
										component={FieldInput}
										type={"number"}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Annual Turnover"}
										name={"annualTurnover"}
										component={FieldInput}
										type={"number"}
									/>
								</div>

								<div className="col-12">
									<h4 className="m-0">
										Work / Office address:
									</h4>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={" Address"}
										name={"officeAddressLine1"}
										component={FieldTextArea}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"Landmark"}
										name={"officeLandmark"}
										component={FieldInput}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"City"}
										name={"officeCity"}
										component={FieldInput}
									/>
								</div>

								<div className="field col-12 md:col-4">
									<Field
										header={"State"}
										name={"officeState"}
										component={FeildDropdown}
										options={selectState(
											props.getAllDistrict
										)}
										optionLabel={"name"}
										filter
									/>
								</div>
								{values.officeState && (
									<div className="field col-12 md:col-4">
										<Field
											header={"District"}
											name={"officeDistrict"}
											filter
											component={FeildDropdown}
											// options={
											// 	districtListState.districtList
											// }
											options={props.getDistrictData(
												values.officeState
											)}
											optionLabel={"name"}
										/>
									</div>
								)}
								<div className="field col-12 md:col-4">
									<Field
										header={"Pincode"}
										name={"officePincode"}
										component={FieldInput}
										type={"number"}
									/>
								</div>
							</>

							<div className="col-12 flex align-items-end justify-content-end">
								{persistSelector?.applicationState ===
									"edit" && (
									<Button
										type="button"
										label="Save as draft"
										onClick={handleSubmit}
									/>
								)}
							</div>
							{/* <div className="col-12 flex align-items-end justify-content-end">
											<Button
												type="button"
												label="Save as draft"
												onClick={() => {
													handleSubmit
												}
											/>
										</div> */}
						</div>
					</Panel>

					<Panel
						header={<h3 className="m-0">Bank Details</h3>}
						className={"my-4"}
						toggleable
					>
						<div className="form-grid grid">
							<div className="field col-12 md:col-4">
								<Field
									header={"Bank Name"}
									name={"bankName"}
									component={FieldInput}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={" Account Holder Name"}
									name={"bankAccountHolderName"}
									component={FieldInput}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Account Number"}
									name={"bankAccountNumber"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Branch"}
									name={"bankBranch"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"IFSC Code"}
									name={"bankIfscCode"}
									component={FieldInput}
								/>
							</div>

							<div className="field col-12 md:col-4">
								<Field
									header={"Account Type"}
									name={"accountType"}
									component={FeildDropdown}
									options={Config.ACCOUNTTYPE}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Opened In"}
									name={"bankAccountOpenedIn"}
									component={FeildCalender}
								/>
							</div>
							<div className="col-12 flex align-items-end justify-content-end">
								{persistSelector?.applicationState ===
									"edit" && (
									<Button
										type="button"
										label="Save as draft"
										onClick={handleSubmit}
									/>
								)}
							</div>
							{/* <div className="col-12 flex align-items-end justify-content-end">
												<Button
													type="button"
													label="Save as draft"
													onClick={() => {
														handleSubmit
													}
												/>
											</div> */}
						</div>
					</Panel>

					{/* .....Bank..... */}

					{/* .....Existing Loan Details..... */}

					<Panel
						header={<h3 className="m-0">Other Details</h3>}
						className={"my-2"}
						toggleable
					>
						<div className="form-grid grid">
							<h4 className="col-12">Reference Details</h4>
							<h5 className="col-12 m-0">Reference 1</h5>
							<div className="field col-12 md:col-4">
								<Field
									header={"Name"}
									name={"reference1Name"}
									component={FieldInput}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={" Mobile"}
									name={"reference1Mobile"}
									component={FieldInput}
									type={"number"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Relation With Borrower"}
									name={"reference1Relation"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Address"}
									name={"reference1Address"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"City"}
									name={"referenceCity"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"State"}
									name={"reference1State"}
									component={FeildDropdown}
									options={selectState(props.getAllDistrict)}
									filter
									optionLabel={"name"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Pincode"}
									name={"referencePincode"}
									component={FieldInput}
									type={"number"}
								/>
							</div>

							<h5 className="col-12 m-0">Reference 2</h5>
							<div className="field col-12 md:col-4">
								<Field
									header={"Name"}
									name={"reference2Name"}
									component={FieldInput}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Mobile"}
									name={"reference2Mobile"}
									component={FieldInput}
									type={"number"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Relation With Borrower"}
									name={"reference2Relation"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Address"}
									name={"reference2Address"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"City"}
									name={"reference2City"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"State"}
									name={"reference2State"}
									component={FeildDropdown}
									options={selectState(props.getAllDistrict)}
									filter
									optionLabel={"name"}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Pincode"}
									name={"reference2Pincode"}
									component={FieldInput}
									type={"number"}
								/>
							</div>
							{/* <div className="col-12 flex align-items-end justify-content-end">
											<Button
												type="button"
												label="Save as draft"
												onClick={() => {
													handleSubmit();
													
												}}
											/>
										</div> */}
							<div className="col-12 flex align-items-end justify-content-end">
								{persistSelector?.applicationState ===
									"edit" && (
									<Button
										type="button"
										label="Save as draft"
										onClick={handleSubmit}
									/>
								)}
							</div>
						</div>
					</Panel>
				</Form>
			)}
		</Formik>
	);
};

export default HomeLoan;
