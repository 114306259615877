import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Formik, Form, Field } from "formik";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { FeildDropdown, FieldInput } from "../../../../components/FieldInput";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import selectState from "../../../../components/stateList";
import { useGetAllDIstrictQuery } from "../../../../services/query/queryApi";

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.required("Name is required.")
		.matches(/^[a-zA-Z ]*$/, "Invalid name.")
		.max(32, "Name not greater than 32 characters.")
		.min(2, "Name not less than 2 characters.")
		.nullable(),
	mobileNumber: Yup.string()
		.required("Mobile no is required.")
		.min(10, "Invalid mobile no.")
		.max(10, "Invalid mobile no.")
		.nullable(),
	email: Yup.string()
		.required("Email is required.")
		.email("Invalid email id.")
		.nullable(),
	designation: Yup.string().required("Designation is required").nullable(),
	stateId: Yup.number().nullable().required("State is required"),
	districtId: Yup.number().nullable().required("District is required"),
});

const ContactDetails = ({
	data,
	id,
	handelContact,
	isLoading,
	handelContactAdd,
}) => {
	const {
		data: getAllDistrict = [
			{ districtList: [{ id: null, name: "" }], id: null, name: "" },
		],
	} = useGetAllDIstrictQuery();
	const [editData, setEditData] = useState({});
	const getContactDetails = (e) => {
		const clickData = data.find((item) => item.id === e);
		setEditData(clickData);
	};

	const [typeAction, setTypeAction] = useState("show");

	let editInitialValue = { ...editData };
	let addInitialValue = {
		institutionId: id,
		name: "",
		mobileNumber: "",
		email: "",
		designation: "",
		stateId: "",
		districtId: "",
		isDelete: false,
	};

	const initialValue =
		typeAction === "edit" ? editInitialValue : addInitialValue;

	const contactSubmit = (data, onSubmitProps) => {
		if (typeAction === "edit") {
			const requestBody = {
				id: data.id,
				institutionId: id,
				name: data.name,
				mobile: data.mobileNumber,
				email: data.email,
				stateId: data.stateId,
				districtId: data.districtId,
				designation: data.designation,
				isDelete: false,
			};
			handelContact(requestBody);
			onSubmitProps.resetForm();
		} else if (typeAction === "add") {
			const requestBody = [
				{
					institutionId: id,
					name: data.name,
					mobile: data.mobileNumber,
					email: data.email,
					stateId: data.stateId,
					districtId: data.districtId,
					designation: data.designation,
					isDelete: false,
				},
			];
			handelContactAdd(requestBody);
			onSubmitProps.resetForm();
		}
		setTypeAction("show");
	};

	const actionTemplate = (item) => {
		return (
			<Button
				className="p-button-text"
				icon="pi pi-pencil"
				onClick={() => {
					getContactDetails(item.id);
					setTypeAction("edit");
				}}
				// 	tooltip="Edit Contact Details"
				// 	// tooltipOptions={{
				// 	// 	position: "bottom",
				// 	// }}
			/>
		);
	};
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="Name"></Column>
				<Column header="Mobile"></Column>
				<Column header="Email"></Column>
				<Column header="Designation"></Column>
				<Column header="District"></Column>
				<Column header="State"></Column>
				<Column header="Action"></Column>
			</Row>
		</ColumnGroup>
	);

	const getDistrictData = (e) => {
		const stateData = getAllDistrict.find((item) => item.id === e);
		if (stateData !== undefined) {
			const districtData = stateData.districtList.map((e) => ({
				...e,
				value: e.id,
			}));
			return districtData;
		} else {
			return [];
		}
	};

	return (
		<div className="col-12">
			<Button
				label="Add Contact"
				icon="pi pi-plus"
				onClick={() => setTypeAction("add")}
				className="flex flex-end mb-2"
			/>
			{(typeAction === "edit" || typeAction === "add") && (
				<Formik
					onSubmit={(values, onSubmitProps) =>
						contactSubmit(values, onSubmitProps)
					}
					validationSchema={validationSchema}
					initialValues={initialValue}
					enableReinitialize
				>
					{({
						values,
						handleChange,
						handleSubmit,
						setFieldValue,
					}) => (
						<Form onSubmit={handleSubmit} autoComplete="off">
							<div className="grid">
								<div className="field col-12 md:col-6 lg:col-4">
									<label htmlFor={`name`}>
										Full Name
										<span className="text-red-500 font-bold">
											*
										</span>
									</label>

									<div className="flex-column">
										<Field
											name={`name`}
											component={FieldInput}
											placeholder="Name"
											type="text"
										/>
									</div>
								</div>
								<div className="field col-12 md:col-6 lg:col-4">
									<label htmlFor={`mobileNumber`}>
										Mobile No.
										<span className="text-red-500 font-bold">
											*
										</span>
									</label>

									<div className="flex-column">
										<Field
											name={`mobileNumber`}
											component={FieldInput}
											placeholder="Mobile Number"
											type="number"
										/>
									</div>
								</div>
								<div className="field col-12 md:col-6 lg:col-4">
									<label htmlFor={`email`}>
										Email
										<span className="text-red-500 font-bold">
											*
										</span>
									</label>
									<Field
										name={`email`}
										component={FieldInput}
										placeholder="email@example.in"
										type="email"
									/>
								</div>
								<div className="field col-12 md:col-6 lg:col-4">
									<label htmlFor={`designation`}>
										Desgination
										<span className="text-red-500 font-bold">
											*
										</span>
									</label>
									<Field
										name={`designation`}
										component={FieldInput}
										placeholder="Enter Desgination"
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"State"}
										name={`stateId`}
										filter
										placeholder="Select State"
										component={FeildDropdown}
										options={selectState(getAllDistrict)}
										optionLabel={"name"}
										star={"*"}
									/>
								</div>
								<div className="field col-12 md:col-4">
									<Field
										header={"District"}
										name={`districtId`}
										filter
										component={FeildDropdown}
										placeholder="Select District"
										options={getDistrictData(
											values.stateId
										)}
										optionLabel={"name"}
									/>
								</div>
							</div>
							<div className="flex justify-content-end">
								{typeAction === "edit" ? (
									<Button
										label="Update"
										type="submit"
										onClick={() => setTypeAction("edit")}
										loading={isLoading}
									/>
								) : (
									<Button
										label="Create"
										type="submit"
										onClick={() => setTypeAction("add")}
										loading={isLoading}
									/>
								)}
							</div>
						</Form>
					)}
				</Formik>
			)}
			<DataTable
				headerColumnGroup={headerGroup}
				value={data}
				responsiveLayout="stack"
				breakpoint="960px"
				paginator
				rows={5}
				className="mt-4"
			>
				<Column field="name" />
				<Column field="mobileNumber" />
				<Column field="email" />
				<Column field="designation" />
				<Column field="districtName" />
				<Column field="stateName" />
				<Column body={actionTemplate} />
			</DataTable>
		</div>
	);
};

export default ContactDetails;
