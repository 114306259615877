import React, { useEffect, useReducer, useState } from "react";
import { Button } from "primereact/button";

import { Divider } from "primereact/divider";
import { ProgressSpinner } from "primereact/progressspinner";
import { ProgramService } from "../../../../../services/ProgramService";
import { dataReducer } from "../../../../../components/fetchReducer";
import Config from "../../../../../shared/config";
import OfferUpdate from "./formcomponents/OfferUpdate";
import { useDispatch, useSelector } from "react-redux";
import { setEnable } from "../../../../../store/reducer/ProgramReducer";

const HandleOffer = ({ programId, type }) => {
	const dispatch = useDispatch();
	const programService = new ProgramService();
	const selector = useSelector((state) => state.program.programId);
	const [open, setOpen] = useState(false);
	const [offerState, offerDispatch] = useReducer(dataReducer, {
		data: {},
		isLoading: false,
		error: false,
		errorMessage: null,
		isFetched: false,
	});
	const getData = () => {
		if (type === "edit") {
			offerDispatch({ type: Config.FETCH_CONFIG.start });
			programService
				.getFullProgramData(programId)
				.then((res) => {
					const { programOfferDtoList, alias } = res;
					offerDispatch({
						type: Config.FETCH_CONFIG.success,
						payload: {
							programOfferDtoList: programOfferDtoList,
							alias: alias,
						},
					});
				})
				.catch((er) => {})
				.finally(() => {
					offerDispatch({ type: Config.FETCH_CONFIG.stop });
				});
		} else {
			offerDispatch({ type: Config.FETCH_CONFIG.start });
			programService
				.getFullProgramData(selector)
				.then((res) => {
					const { programOfferDtoList, alias } = res;
					offerDispatch({
						type: Config.FETCH_CONFIG.success,
						payload: {
							programOfferDtoList: programOfferDtoList,
							alias: alias,
						},
					});
				})
				.catch((er) => {})
				.finally(() => {
					offerDispatch({ type: Config.FETCH_CONFIG.stop });
				});
		}
	};
	useEffect(() => {
		programId && getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<h3 className="text-center">Offer Details</h3>
			{programId ? (
				<>
					<div className="w-full flex align-items-center justify-content-end">
						<Button
							icon={open ? "pi pi-times" : "pi pi-plus"}
							label={open ? "" : "Add"}
							className={
								open ? "p-button-danger p-button-text" : ""
							}
							onClick={() => setOpen((p) => !p)}
						/>
					</div>
					{open && (
						<OfferUpdate
							typeOption={type}
							type={"create"}
							programId={type === "edit" ? programId : selector}
							getData={getData}
							alias={offerState.data.alias}
						/>
					)}
					<Divider align={"left"}>Offer List</Divider>
					{offerState.isLoading ? (
						<div className="flex align-items-center justify-content-center py-5">
							<ProgressSpinner
								style={{ height: "30px" }}
								strokeWidth="5"
								fill="var(--surface-ground)"
								animationDuration=".5s"
							/>
						</div>
					) : (
						offerState.data.programOfferDtoList &&
						offerState.data.programOfferDtoList.map(
							(item, index) => (
								<div key={index}>
									<h3 className="mb-0">{index + 1}.</h3>
									<OfferUpdate
										typeOption={type}
										type={"edit"}
										item={item}
									/>
								</div>
							)
						)
					)}
				</>
			) : (
				<>
					<OfferUpdate
						typeOption={type}
						type={"create"}
						programId={selector}
						getData={getData}
						alias={offerState.data.alias}
					/>
				</>
			)}
			{programId && (
				<div className="flex justify-content-end align-items-center col-12 gap-2 mt-4">
					<Button
						type="button"
						onClick={() => {
							dispatch(setEnable(1));
						}}
						icon={"pi pi-arrow-left"}
					/>
				</div>
			)}
		</>
	);
};

export default HandleOffer;
