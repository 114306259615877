import axios from "axios";
import { apiPath } from "../shared/constant";
export class LoanService {
	constructor() {
		this.token = sessionStorage.getItem("token");
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}

	loanSaveDraft = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/application/draft-save`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	applicationSubmit = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/application/submit/application/${payload}`,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	insuranceApplicationSubmit = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/application/submit/insurance/${payload}`,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	applicationLock = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/application/lock/application/${payload}`,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getUnallocatedApplication = async (userName) => {
		try {
			const res = await axios.get(
				`${apiPath}/application/unallocated-application/${userName}`,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	allocateApplication = async (payload) => {
		try {
			const response = await axios.post(
				apiPath + "/application/allocate/application",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAllHospicashList = async () => {
		try {
			const res = await axios.get(
				`${apiPath}/application/hospicash-all`,
				{
					headers: {
						Authorization: `Bearer ${this.token}`,
						"Content-Type": "multipart/form-data",
					},
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	insuranceSaveDraft = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/application/insuranse/draft-save`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	motorInsuranceSaveDraft = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/application/motor-insuranse/draft-save`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
}
