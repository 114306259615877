import { useLocation } from "react-router-dom";
import Insurance from "./Insurance";
import LIinsurance from "./FormComponent/LIinsurance";
import ApplicationForm from "./ApplicationForm";
import MedicalInsurance from "./FormComponent/MedicalInsurance";
import MotorInsuance from "./FormComponent/MotorInsurance";
import Config from "../../../../shared/config";

const EnterApplication = () => {
	const location = useLocation();

	return (
		<>
			{location?.state?.alias === "HC" ? (
				<Insurance locationData={location?.state} />
			) : location?.state?.alias === "ULIP" ||
			  location?.state?.alias === "TRADI" ||
			  location?.state?.alias === "CIP" ||
			  location?.state?.alias === "CEP" ||
			  location?.state?.alias === "TIP" ||
			  location?.state?.alias === "RIP" ? (
				<LIinsurance locationData={location?.state} />
			) : location?.state?.alias ===
			  Config.PRODUCT_ALIAS.INVESTMENT_LIFE_INSURANCE ? (
				<LIinsurance locationData={location?.state} />
			) : location?.state?.alias === "INDIV" ? (
				<MedicalInsurance locationData={location?.state} />
			) : location?.state?.alias ===
			  Config.PRODUCT_ALIAS.FAMILY_FLOATER ? (
				<MedicalInsurance locationData={location?.state} />
			) : location?.state?.alias === Config.PRODUCT_ALIAS.GROUP ? (
				<MedicalInsurance locationData={location?.state} />
			) : location?.state?.alias ===
			  Config.PRODUCT_ALIAS.CRITICAL_ILLNESS ? (
				<MedicalInsurance locationData={location?.state} />
			) : location?.state?.alias === "ACCI" ? (
				<MedicalInsurance locationData={location?.state} />
			) : location?.state?.alias === "GIP" ? (
				<LIinsurance locationData={location?.state} />
			) : location?.state?.alias ===
			  Config.PRODUCT_ALIAS.MOTOR_INSURANCE_PRIVET_CAR ? (
				<MotorInsuance locationData={location?.state} />
			) : location?.state?.alias ===
			  Config.PRODUCT_ALIAS.MOTOR_INSURANCE_TWO_WHEELER ? (
				<MotorInsuance locationData={location?.state} />
			) : (
				// <MotorInsuance locationData={location?.state} />
				<ApplicationForm locationData={location?.state} />
			)}
		</>
	);
};

export default EnterApplication;
