import { InputText } from "primereact/inputtext";

// import { InputNumber } from "primereact/inputnumber";

import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
export const InputComponent = (props) => {
	return (
		<>
			<label className="block text-sm" style={props.style}>
				{props.header}
				<span className="text-red-500 font-bold">{props.star}</span>
			</label>
			<InputText
				value={props.value}
				onChange={props.handleChange}
				className={classNames(
					{
						"p-invalid": Boolean(props.errors),
					},
					"block w-full"
				)}
				name={props.name}
				placeholder={props.placeholder}
				type={props.type || "text"}
				step="0.1"
				onReset={props.handleReset}
				disabled={props.readOnly}
			/>
			{props.errors && <small className="p-error">{props.errors}</small>}
		</>
	);
};
export const InputComponentArea = (props) => {
	return (
		<>
			<label className="block text-sm" style={props.style}>
				{props.header}
				<span className="text-red-500 font-bold">{props.star}</span>
			</label>
			<InputTextarea
				value={props.value}
				onChange={props.handleChange}
				className={classNames(
					{
						"p-invalid": Boolean(props.errors),
					},
					"block w-full"
				)}
				name={props.name}
				placeholder={props.placeholder}
				type={props.type || "text"}
				step="0.1"
			/>
			{props.errors && <small className="p-error">{props.errors}</small>}
		</>
	);
};

export const InputNode = ({ children, header, star }) => {
	return (
		<>
			<label htmlFor={header} className="block text-sm">
				{header}
				<span className="text-red-500 font-bold">{star}</span>
			</label>
			{children}
		</>
	);
};
