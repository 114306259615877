import { Button } from "primereact/button";
import Config from "../../../../shared/config";
import { Image } from "primereact/image";
import { AiOutlineFileSearch } from "react-icons/ai";

const BankDetails = (props) => {
	return (
		<>
			<div className="c-user-dw c-user-dw-new">
				<div className="c-user-dw-box">
					<p className="m-0 font-semibold text-primary">A/C Type:</p>
					<p className="m-0 font-semibold ">
						{props?.agent?.bankDetails?.accountType
							? props?.agent?.bankDetails?.accountType
							: " - "}
					</p>
				</div>
				<div className="c-user-dw-box">
					<p className="m-0 font-semibold  text-primary">A/C No:</p>
					<p className="m-0 font-semibold">
						{props?.agent?.bankDetails?.accountNumber
							? props?.agent?.bankDetails?.accountNumber
							: " - "}
					</p>
				</div>
				<div className="c-user-dw-box">
					<p className="m-0 text-primary font-semibold">Bank Name:</p>
					<p className="m-0">
						{props?.agent?.bankDetails?.bankName
							? props?.agent?.bankDetails?.bankName
							: " - "}
					</p>
				</div>
				<div className="c-user-dw-box">
					<p className="m-0 text-primary font-semibold">
						Bank Address:
					</p>
					<p className="m-0">
						{props?.agent?.bankDetails?.bankAddress
							? props?.agent?.bankDetails?.bankAddress
							: " - "}
					</p>
				</div>
				<div className="c-user-dw-box">
					<p className="m-0 font-semibold text-primary">
						Name as in Bank Account:
					</p>
					<p className="m-0">
						{props?.agent?.bankDetails?.name
							? props?.agent?.bankDetails?.name
							: " - "}
					</p>
				</div>
				<div className="c-user-dw-box">
					<p className="m-0 text-primary font-semibold">IFSC:</p>
					<p className="m-0">
						{props?.agent?.bankDetails?.ifsc
							? props?.agent?.bankDetails?.ifsc
							: " - "}
					</p>
				</div>
				<div className="c-user-dw-box">
					<p className="m-0 text-primary font-semibold">MICR:</p>
					<p className="m-0">
						{props?.agent?.bankDetails?.micr
							? props?.agent?.bankDetails?.micr
							: " - "}
					</p>
				</div>
			</div>

			{props?.agent?.bankDetails?.chequeId ? (
				<>
					<div className="mt-3">
						<p style={{ fontWeight: 700 }}>Cancelled Cheque</p>
						<a
							href={`${Config?.AGENT_BUCKET_ACCESS_URL}/agent-profile/${props?.agent?.profile?.id}/${props?.agent?.bankDetails?.chequeId}`}
							download={true}
							rel="noreferrer"
						>
							<Button icon="pi pi-arrow-down" label="download" />
						</a>
					</div>
					{props?.agent?.bankDetails?.chequeId &&
					props?.agent?.bankDetails?.chequeId
						?.split(/[#?]/)[0]
						?.split(".")
						?.pop()
						?.trim() === "pdf" ? (
						<iframe
							title="cancel-check-photo"
							src={`${Config?.AGENT_BUCKET_ACCESS_URL}/agent-profile/${props?.agent?.profile?.id}/${props?.agent?.bankDetails?.chequeId}`}
							style={{ width: "100%" }}
						/>
					) : props?.agent?.bankDetails?.chequeId === null ? null : (
						<Image
							src={`${Config?.AGENT_BUCKET_ACCESS_URL}/agent-profile/${props?.agent?.profile?.id}/${props?.agent?.bankDetails?.chequeId}`}
							alt="cancel-check-photo"
							preview
							imageClassName="w-full h-19rem"
							imageStyle={{ objectFit: "contain" }}
						/>
					)}
				</>
			) : (
				<>
					<div className="mt-3">
						<p style={{ fontWeight: 700 }}>Cancelled Cheque</p>
					</div>
					<div
						className="w-full border-dashed border-2 border-400 border-round-md surface-overlay flex align-items-center justify-content-center flex-column"
						style={{ minHeight: "200px" }}
					>
						<h3 className="text-6xl m-0">
							<AiOutlineFileSearch />
						</h3>
						<p>Cancelled Cheque Not Uploaded</p>
					</div>
				</>
			)}
		</>
	);
};

export default BankDetails;
