import { useSelector } from "react-redux";
import CCDashBoard from "./ccadmin";

const Dashboard = () => {
	const selector = useSelector((state) => state.persistData.auth.user);

	return (
		// <div className="dashboard-container">
		// 	<CountryDashboard />
		// </div>
		<>
			{(selector?.role?.position === 1000 ||
				selector?.role?.position === 1030 ||
				selector?.role?.position === null) && <CCDashBoard />}
		</>
	);
};

export default Dashboard;
