import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import { useState } from "react";
import Config from "../../../../shared/config";
import {
	FeildCalender,
	FeildDropdown,
} from "../../../../components/FieldInput";
import moment from "moment";

const CommissionSearch = (props) => {
	const [show, setShow] = useState(false);
	const initialValue = {
		startFrom: "",
		startTo: "",
		endFrom: "",
		endTo: "",
		commissionType: "",
		profileType: "",
	};
	const handleForm = (value) => {
		let data = {
			...value,
			endFrom: value.endFrom
				? moment(value.endFrom).format("YYYY-MM-DD")
				: "",
			endTo: value.endTo ? moment(value.endTo).format("YYYY-MM-DD") : "",
			startFrom: value.startFrom
				? moment(value.startFrom).format("YYYY-MM-DD")
				: "",
			startTo: value.startTo
				? moment(value.startTo).format("YYYY-MM-DD")
				: "",
		};

		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: data,
		});
	};
	return (
		<>
			<div className="col-12 w-full flex align-items-end justify-content-between">
				<h3>{props?.state?.title} commission List </h3>
				<div className="flex gap-2">
					<Button
						icon={show ? "pi pi-times" : "pi pi-search"}
						className={
							show
								? "p-button-danger p-button-outlined"
								: "p-button-outlined"
						}
						label={show ? "Close" : "Search"}
						onClick={() => {
							setShow((show) => !show);
						}}
					/>
					<Button
						className=" p-button-outlined"
						onClick={() =>
							props.searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							})
						}
					>
						<i className="pi pi-refresh mr-1" />
						Reload
					</Button>
					<Button
						label="Add"
						icon={"pi pi-plus"}
						onClick={props.handleClick}
					/>
				</div>
			</div>
			{show && (
				<Formik initialValues={initialValue} onSubmit={handleForm}>
					{({ values, handleSubmit, setFieldValue, resetForm }) => (
						<Form
							onSubmit={handleSubmit}
							className="form-grid grid background-secondary p-4 border-round-md my-3"
						>
							<div className="field col-12 md:col-3">
								<Field
									header={"Effective Start Date Form"}
									name={"startFrom"}
									component={FeildCalender}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Effective Start Date To"}
									name={"startTo"}
									component={FeildCalender}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Effective End Date From"}
									name={"endFrom"}
									component={FeildCalender}
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									header={"Effective End Date To"}
									name={"endTo"}
									component={FeildCalender}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									name={`commissionType`}
									component={FeildDropdown}
									header={"Commission Type"}
									options={[
										{
											label: "Fixed",
											value: "fixed",
										},
										{
											label: "Percentage",
											value: "percentage",
										},
									]}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									name={`profileType`}
									component={FeildDropdown}
									header={"Profile Type"}
									options={Config.ProfileType}
								/>
							</div>

							<div className="field flex align-items-center  justify-content-end col-12">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={() => resetForm()}
								/>
								<Button type="submit" label="Search" />
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};
export default CommissionSearch;
