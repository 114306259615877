import { FileUpload } from "primereact/fileupload";
import { useEffect, useRef, useState } from "react";
import { InputNode } from "../../../../../../components/InputComponent";
import { Button } from "primereact/button";
import { Field, Form, Formik } from "formik";
import {
	FeildDropdown,
	FieldEditor,
	FieldInput,
	FieldMultiselect,
} from "../../../../../../components/FieldInput";
import Config from "../../../../../../shared/config";
import * as Yup from "yup";
import { ProgramService } from "../../../../../../services/ProgramService";
import { S3 } from "aws-sdk";

import Loader from "../../../../../../components/Loader";
import { useDispatch } from "react-redux";
import { convertObjNull } from "../../../../../../utils/UtilsFunction";
import {
	setEnable,
	setId,
} from "../../../../../../store/reducer/ProgramReducer";
import { Toast } from "primereact/toast";
const CreditCard = (props) => {
	const dispatch = useDispatch();
	const [programData, setProgramData] = useState(null);
	const pType = props?.locationData;
	console.log(pType);
	const programService = new ProgramService();
	const toastTL = useRef(null);
	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});
	const getProgram = (id) => {
		if (pType.type === "edit" || pType?.type === "copy") {
			setSpinner(true);
			programService
				.getFullProgramData(id)
				.then((res) => {
					setProgramData(res);
				})
				.catch((e) => {})
				.finally(() => setSpinner(false));
		}
	};
	useEffect(() => {
		pType?.programId && getProgram(pType.programId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [spinner, setSpinner] = useState(false);
	const programCreate = (payload) => {
		setSpinner(true);
		programService
			.createProgram(payload)
			.then((res) => {
				dispatch(setId(res?.id));
				dispatch(setEnable(1));
			})
			.catch((error) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setSpinner(false);
			});
	};
	const initialValue = {
		name:
			pType?.programData?.institutionId?.name +
			" " +
			pType?.programData?.productId?.subProduct,
		shortDescription: "",
		longDescription: "",
		eligibilityDetails: "",
		imageFirst: "",
		imageSecond: "",
		profession: "",
		// preClosure: false,
		documentsRequired: "",
		joiningFee: false,
		annualFee: false,
		active: true,
		cashback: false,
		fuelSurchargeWaiver: false,
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("This field is required"),
		eligibilityDetails: Yup.string().required("This field is required"),
		documentsRequired: Yup.string().required("This field is required"),
		profession: Yup.array()
			.of(Yup.string())
			.min(1, "Field cannot be empty")
			.required("Field cannot be empty"),
	});
	const handleFormSubmit = (value, onSubmitProps) => {
		const urlData = pType?.programData;

		const body = convertObjNull({
			...value,
			productId: urlData?.productId?.subProductId,
			institutionId: urlData?.institutionId.id,
			type: urlData?.productId?.alias === "CC" ? "CREDIT_CARD" : "LOANS",
		});
		if (pType?.programId && pType.type === "edit") {
			setSpinner(true);
			programService
				.updateProgram(body)
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Successfull",
						detail: "Updated successfully",
						style: { color: "#000000" },
						life: 3000,
					});
					pType?.programId && getProgram(pType.programId);
				})
				.catch((error) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: "Somthing went worng",
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => {
					setSpinner(false);
				});
		} else if (pType?.programId && pType.type === "copy") {
			const { id, ...rest } = body;
			setSpinner(true);
			programService
				.createProgram(rest)
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Successfull",
						detail: "Program created successfully",
						style: { color: "#000000" },
						life: 3000,
					});
					dispatch(setId(res.id));
					dispatch(setEnable(1));
				})
				.catch((er) => {
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: "Somthing went worng",
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => {
					setSpinner(false);
				});
		} else {
			programCreate(body);
		}
	};
	const onUploadfirst = (e, setFieldValue) => {
		setSpinner(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.PROGRAM_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				setSpinner(false);

				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				setSpinner(false);

				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Contract document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});

				setFieldValue("imageFirst", newFileName);
			}
		});
	};
	const onUploadSecond = (e, setFieldValue) => {
		//setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.PROGRAM_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "2nd document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});

				setFieldValue("imageSecond", newFileName);
			}
		});
	};
	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			{spinner && <Loader />}
			<Formik
				initialValues={{
					...initialValue,
					...(programData && programData),
				}}
				validationSchema={validationSchema}
				onSubmit={handleFormSubmit}
				enableReinitialize
			>
				{({
					values,

					handleSubmit,

					setFieldValue,
					touched,
					errors,
					resetForm,
				}) => (
					<Form
						className="form-grid grid"
						onSubmit={handleSubmit}
						autoComplete="on"
					>
						{/* <div className="field col-12 md:col-3">
							<Field
								name="selectType"
								component={FeildDropdown}
								options={[
									{ label: "Institution", value: "INS" },
									{ label: "DSA", value: "DSA" },
								]}
								header={"Select Type"}
								star={"*"}
								value={values.selectType}
								onChange={(e) => {
									setFieldValue("selectType", e.value, true);
									setFieldValue(
										"dsaDetails",
										{ details: [] },
										true
									);

									setFieldValue("institutionId", "", true);
								}}
							/>
						</div>
						{values.selectType === "DSA" ? (
							<>
								<div className="field col-12 md:col-3">
									<Field
										filter
										name="dsaDetails"
										component={FeildDropdown}
										options={dsaInstitute.data}
										optionLabel={"dsaName"}
										header={"Select DSA"}
										star={"*"}
										value={values.dsaDetails}
										onChange={(e) => {
											setFieldValue(
												"dsaDetails",
												e.value,
												true
											);

											setFieldValue(
												"institutionId",
												"",
												true
											);
										}}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										name="institutionId"
										component={FeildDropdown}
										options={values.dsaDetails?.details}
										filter
										optionLabel={"bankName"}
										header={"Bank"}
										star={"*"}
										value={values.institutionId}
										onChange={(e) =>
											handleSubProduct1(
												e,
												setFieldValue,
												values.dsaDetails
											)
										}
									/>
								</div>
							</>
						) : (
							values.selectType === "INS" && (
								<div className="field col-12 md:col-3">
									<Field
										name="institutionId"
										component={FeildDropdown}
										options={data}
										filter
										optionLabel={"name"}
										header={"Bank"}
										star={"*"}
										value={values.institutionId}
										onChange={(e) =>
											handleSubProduct2(e, setFieldValue)
										}
									/>
								</div>
							)
						)} */}

						<div className="field col-12 md:col-3">
							<Field
								header={"Name of the Program"}
								component={FieldInput}
								name={"name"}
								star={"*"}
							/>
						</div>

						<div className="field col-12 md:col-3">
							<Field
								name={`profession`}
								component={FieldMultiselect}
								options={Config.OCCUPATION}
								optionLabel="name"
								header={"Profession"}
								star={"*"}
							/>
						</div>

						<div className="field col-12 md:col-3">
							<Field
								component={FeildDropdown}
								name={"joiningFee"}
								options={Config.YES_NO}
								header={"Joining Fee"}
								star="*"
							/>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								component={FeildDropdown}
								name={"annualFee"}
								options={Config.YES_NO}
								header={"Annual Fee"}
								star="*"
							/>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								component={FeildDropdown}
								name={"cashback"}
								options={Config.YES_NO}
								header={"Cash Back"}
								star="*"
							/>
						</div>
						<div className="field col-12 md:col-3">
							<Field
								component={FeildDropdown}
								name={"fuelSurchargeWaiver"}
								options={Config.YES_NO}
								header={"Fuel Surcharge Waiver"}
								star="*"
							/>
						</div>

						<div className="col-12"> </div>
						<div className="field col-12 md:col-6">
							<Field
								name="shortDescription"
								component={FieldEditor}
								header={"Short Description"}
							/>
						</div>
						<div className="field col-12 md:col-6">
							<Field
								name="longDescription"
								component={FieldEditor}
								header={"Long Description"}
							/>
						</div>

						<div className="field col-12 md:col-6">
							<Field
								name="eligibilityDetails"
								component={FieldEditor}
								header={"Eligibility Details"}
								star={"*"}
							/>
						</div>
						<div className="field col-12 md:col-6">
							<Field
								name="documentsRequired"
								component={FieldEditor}
								header={"Documents Required"}
								star={"*"}
							/>
						</div>
						<div className="field col-12 md:col-6">
							<h3>Image 1</h3>
							{values.imageFirst && (
								<a
									href={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageFirst}`}
									target="_blank"
									className="flex align-items-center justify-content-start"
									rel="noreferrer"
								>
									{/* <i
												className={`text-2xl pi ${
													getFileExtension(
														img.documentFileName
													) === "pdf"
														? "pi-file-pdf text-red-400"
														: "pi-image text-primary-700"
												}`}
											/> */}

									<span className="">
										<img
											style={{ height: "80px" }}
											src={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageFirst}`}
											alt=""
										/>
									</span>
								</a>
							)}
						</div>
						<div className="field col-12 md:col-6">
							<h3>Image 2</h3>
							{values.imageSecond && (
								<a
									href={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageSecond}`}
									target="_blank"
									className="flex align-items-center justify-content-start"
									rel="noreferrer"
								>
									{/* <i
												className={`text-2xl pi ${
													getFileExtension(
														img.documentFileName
													) === "pdf"
														? "pi-file-pdf text-red-400"
														: "pi-image text-primary-700"
												}`}
											/> */}

									<span className="">
										<img
											style={{ height: "80px" }}
											src={`${Config.AGENT_BUCKET_ACCESS_URL}/program-docs/${values.imageSecond}`}
											alt=""
										/>
									</span>
								</a>
							)}
						</div>
						<div className="field col-12 md:col-6">
							<InputNode header={"Upload Logo"}>
								<FileUpload
									name="imageFirst"
									customUpload
									accept=".jpg,.png,.jpeg"
									uploadHandler={(e) =>
										onUploadfirst(e, setFieldValue)
									}
									maxFileSize={1000000}
									emptyTemplate={
										<p className="m-0">
											Drag and drop files to here to
											upload.
										</p>
									}
								/>
								{errors.imageFirst && touched.imageFirst && (
									<small className="p-error">
										{errors.imageFirst}
									</small>
								)}
							</InputNode>
						</div>
						<div className="field col-12 md:col-6">
							<InputNode header={"Upload Image 2"}>
								<FileUpload
									name="imageSecond"
									customUpload
									accept=".jpg,.png,.jpeg"
									uploadHandler={(e) =>
										onUploadSecond(e, setFieldValue)
									}
									maxFileSize={1000000}
									emptyTemplate={
										<p className="m-0">
											Drag and drop files to here to
											upload.
										</p>
									}
								/>
								{errors.imageSecond && touched.imageSecond && (
									<small className="p-error">
										{errors.imageSecond}
									</small>
								)}
							</InputNode>
						</div>
						<div className="flex col-12 justify-content-end gap-2 align-items-center mt-4">
							<Button
								type="button"
								label="Reset"
								onClick={() => resetForm()}
								icon={"pi pi-refresh"}
								className="danger-btn"
							/>
							<Button
								type="submit"
								label={
									pType?.programId && pType?.type === "edit"
										? "Update"
										: "Save & next"
								}
							/>
							{pType?.programId && pType?.type === "edit" && (
								<Button
									type="button"
									onClick={() => {
										dispatch(setEnable(1));
									}}
									icon={"pi pi-arrow-right"}
								/>
							)}
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default CreditCard;
