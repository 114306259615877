import React, { useReducer, useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { LeadService } from "../../../../services/LeadService";
import { ProgramService } from "../../../../services/ProgramService";
import TableLoader from "../../../../components/TableLoader";
import TableNoData from "../../../../components/TableNoData";
import Paginator from "../../../../components/Paginator";
import {
	FeildDropdown,
	FieldInput,
	FieldTextArea,
} from "../../../../components/FieldInput";
import {
	searchInitialState,
	searchReducer,
} from "../../../../components/searchReducer";
import Config from "../../../../shared/config";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import TimelineStatus from "../TimelineStatus";
import SearchToggleContent from "./SearchToggleContent";
import StatusUpdate from "./StatusUpdate";
import moment from "moment";
import BulkUpload from "./BulkUplod/BulkUpload";
import { exportExcel } from "../../../../components/XlsxExport";
import MenuComponent from "../../../../components/MenuComponent";
import { SplitButton } from "primereact/splitbutton";
import Loader from "../../../../components/Loader";
import { CampaignService } from "../../../../services/CampaignService";
import BulkStatusUpload from "./bulkstatusupload/BulkStatusUpload";
import AllocateAgentForm from "../../../members/users/components/AllocateAgentForm";
import {
	setApplicationState,
	setCountryApplicationId,
} from "../../../../store/reducer/AuthReducer";
import { Dropdown } from "primereact/dropdown";

const LeadsTable = (props) => {
	const leadServices = new LeadService();
	const programService = new ProgramService();
	const campaignService = new CampaignService();
	const [leadDialog, setLeadDialog] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const position = useSelector(
		(state) => state.persistData.auth.user.role.position
	);
	const [rmList, setrmList] = useState([]);
	const selector = useSelector((state) => state.persistData.auth.user);
	const [programData, setProgramData] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const [profileDetailDialog, setProfileDetailDialog] = useState({
		state: false,
		data: null,
	});
	const [rmAllocate, setrmAllocateDialog] = useState({
		state: false,
		data: null,
	});
	const [lob, setLob] = useState("");
	const [leadData, setLeadData] = useState({});
	const toastTL = useRef(null);
	const [toggle, setToggle] = useState(false);
	const [dialog, setDialog] = useState(false);
	const [expandedRows, setExpandedRows] = useState(null);
	const [bulkDialoge, setBulkDialogue] = useState(false);
	const [bulkStatus, setBulkStatus] = useState(false);
	const [loading, setLoading] = useState(false);
	const [timelineStatusDialoge, setTimelineStatusDialoge] = useReducer(
		(state, action) => {
			return {
				...state,
				dialogOn: action.status,
				dialogType: action.data,
				leadId: action.id,
			};
		},
		{ dialogOn: false, dialogType: "", leadId: null }
	);
	const [leadDataState, leadDataStateDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const [timeLineState, timeLineDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const getRmList = () => {
		setLoading(true);
		leadServices
			.getRmList(selector.userName)
			.then((res) => {
				setrmList(res);
			})
			.catch((e) => {
				toastTL.current.show([
					{
						severity: "error",
						summary: "Error",
						detail: "Error",
						life: 3000,
					},
				]);
			})
			.finally(() => setLoading(false));
	};
	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	function tblSort(columnName, sortType) {
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}

	const nameTemplate = ({ firstName, middleName, lastName }) => {
		if (middleName === null) {
			middleName = "";
		}
		if (lastName === null) {
			lastName = "";
		}
		if (firstName === null) {
			firstName = "";
		}
		return firstName + " " + middleName + " " + lastName;
	};
	const updateTemplate = ({ updatedAt }) => {
		return updatedAt ? moment(updatedAt).format("YYYY-MM-DD") : updatedAt;
	};
	const dateTemplate = ({ createdAt }) => {
		return createdAt ? moment(createdAt).format("YYYY-MM-DD") : createdAt;
	};
	const handleSearch = (val, action) => {
		setLoading(true);
		const body = {
			leadId: profileDetailDialog?.data,
			userName: val.userName,
		};
		leadServices
			.leadAllocates(body)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});

				action.resetForm();
				getRecevedList();
				setProfileDetailDialog((prev) => ({
					...prev,
					state: false,
					id: null,
				}));
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoading(false));
	};
	const applyApplicationTemplate = (item) => {
		return (
			item?.alias && (
				<Button
					label="Apply"
					onClick={() => {
						navigate("/masters/loan_application", {
							state: {
								leadId: leadData?.leadId,
								subproductId: leadData?.subProductId,
								subProductName: leadData?.subProductName,
								alias: item.alias,
								applicationId: null,
								programName: item.name,
								programId: item.programId,
								mobileNumber: leadData.mobileNumber,
								panNumber: leadData.pan,
							},
						});
					}}
				/>
			)
		);
	};
	const getRecevedList = () => {
		leadDataStateDispatch({ type: Config.FETCH_CONFIG.start });
		const body = {
			...searchState.searchValue,
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(props.campaignId && { campaignId: Number(props.campaignId) }),
			...(searchState.sortByColumn &&
				searchState.sortType && {
					sorts: [
						searchState.sortByColumn + "," + searchState.sortType,
					],
				}),
		};

		const CleanObject = (body) => {
			for (let propName in body) {
				if (body[propName] === "" || body[propName] === null) {
					delete body[propName];
				}
			}
			return body;
		};

		(props.type === "own"
			? leadServices.getSubmittedLead(CleanObject(body))
			: props.type === "all"
			? leadServices.getRecivedLead(CleanObject(body))
			: props.type === "campaign" && campaignService.getLeadList(body)
		)
			.then((res) => {
				leadDataStateDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
				leadDataStateDispatch({
					type: Config.FETCH_CONFIG.error,
					payload: e,
				});
			})
			.finally(() =>
				leadDataStateDispatch({
					type: Config.FETCH_CONFIG.stop,
				})
			);
	};
	const rowExpansionTemplate = (data) => {
		return (
			<DataTable
				value={data?.programListDtoList}
				responsiveLayout="scroll"
				className="w-full"
			>
				<Column field="name" header="Name" />
				<Column field="minLoanAmount" header="Min Loan Amount" />

				<Column field="maxLoanAmount" header="Max Loan Amount" />
				<Column
					field="minProcessingFees"
					header="Min Processing Fees"
				/>
				<Column
					field="maxProcessingFees"
					header="Max Processing Fees"
				/>
				<Column
					field="minRateOfInterest"
					header="Min Rate Of Interest"
				/>
				<Column
					field="maxRateOfInterest"
					header="Max Rate Of Interest"
				/>
				<Column field="minLoanPeriod" header="Min Loan Period" />
				<Column field="maxLoanPeriod" header="Max Loan Period" />
				<Column body={applyApplicationTemplate} header={"Apply"} />
			</DataTable>
		);
	};
	const allowExpansion = (rowData) => {
		return rowData.programListDtoList.length > 0;
	};
	useEffect(() => {
		getRecevedList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);

	const increment = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const pagesizechange = (e) => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />
				<Column header={getSortableColumn("Lead No.", "number")} />
				<Column header={"Interested In"} />
				<Column header={"Mobile"} />
				<Column
					header={getSortableColumn(
						"Zone",
						"districtId.stateId.zoneId.name"
					)}
				/>
				<Column
					header={getSortableColumn(
						"State",
						"districtId.stateId.name"
					)}
				/>
				<Column
					header={getSortableColumn("District", "districtId.name")}
				/>
				<Column header={getSortableColumn("City", "city")} />
				<Column header={getSortableColumn("Name", "firstName")} />
				<Column header={getSortableColumn("Created On", "createdAt")} />
				<Column header={"Updated On"} />

				<Column header={"Status"} />
				<Column header={"Allocated To"} />
				<Column header={"Agent Code"} />
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const itaration = {
		title: "",
		firstName: "",
		hi: "",
		mobilenumber: "",
		city: "",
		state: "",
		district: "",
		zone: "",
		status: "",
		isAllocated: "",
		allocatedTo: "",
		action: "",
		action2: "",
		action3: "",
		action4: "",
		// action5: "",
	};
	const handleProgramDialog = (item) => {
		dispatch(setApplicationState("edit"));
		dispatch(setCountryApplicationId(null));
		setLeadData((prev) => ({
			...prev,
			mobileNumber: item.mobileNumber,
			pan: item.pan,
			leadId: item.id,
			subProductId: item.productId,
			subProductName: item?.productTitle,
		}));
		setDialog(true);
		setProgramData({ type: Config.FETCH_CONFIG.start });
		programService
			.getInstitionWiseList({ subProductId: item.productId })
			.then((res) => {
				setProgramData({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((e) =>
				setProgramData({ type: Config.FETCH_CONFIG.error, payload: e })
			)
			.finally(() => setProgramData({ type: Config.FETCH_CONFIG.stop }));
	};

	const handleTimelineStatus = (item) => {
		timeLineDispatch({ type: Config.FETCH_CONFIG.start });
		programService
			.timelineStatus(item)
			.then((res) => {
				const arr = [...res];
				timeLineDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: arr.map((item) =>
						item.title === "CALL"
							? { ...item, icon: "pi pi-phone", color: "#5A96E3" }
							: item.title === "WHATSAPP"
							? {
									...item,
									icon: "pi pi-whatsapp",
									color: "#128C7E",
							  }
							: { ...item, icon: "pi pi-info", color: "#333" }
					),
				});
				timeLineDispatch({
					type: Config.FETCH_CONFIG.fetched,
				});
			})
			.catch((e) =>
				timeLineDispatch({
					type: Config.FETCH_CONFIG.error,
					payload: e,
				})
			)
			.finally(() =>
				timeLineDispatch({ type: Config.FETCH_CONFIG.stop })
			);
	};

	const onHideDialog = () => {
		setTimelineStatusDialoge({ status: false, data: "", id: null });
		timeLineDispatch({
			type: Config.FETCH_CONFIG.ini,
		});
	};
	const onOpenDialog = (e, value) => {
		setTimelineStatusDialoge({
			status: true,
			data: e,
			id: value,
		});
	};
	const handleRmAllocateSubmit = (val) => {
		setLoading(true);
		const payload = {
			leadId: rmAllocate.data,
			userName: val.userName,
		};
		leadServices
			.allocateLead(payload)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: "Allocated Successfully",
					style: { color: "#000000" },
					life: 3000,
				});
				setrmAllocateDialog((prev) => ({
					...prev,
					state: false,
					data: null,
				}));
				getRecevedList();
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoading(false));
	};
	const actionTemplate = (item) => {
		const items1 = [
			{
				label: "Edit",
				icon: "pi pi-pencil",
				command: () => {
					const type =
						item?.productLineOfBusiness === "Insurance"
							? Config.PRODUCT_LEAD_TYPE.INSURANCE
							: Config.PRODUCT_LEAD_TYPE.LOAN;
					navigate(`/masters/lead/edit-lead/${item.id}`, {
						state: {
							lob: item?.productLineOfBusiness,
							productType: type,
							productName: item?.productTitle,
						},
					});
				},
			},
			{
				label: "Timeline",
				icon: "pi pi-cloud-upload",
				command: () => {
					onOpenDialog("Timeline");
					handleTimelineStatus(item.id);
				},
			},
			{
				label: "Status Update",
				icon: "pi pi-check",
				command: () => {
					onOpenDialog("Update", item.id);
				},
			},
			{
				label: "Create Application",
				icon: "pi pi-plus",
				command: () => {
					handleProgramDialog(item);
				},
			},
			{
				label: "Preview",
				icon: "pi pi-info-circle",
				command: () => {
					const type =
						item?.productLineOfBusiness === "Insurance"
							? Config.PRODUCT_LEAD_TYPE.INSURANCE
							: Config.PRODUCT_LEAD_TYPE.LOAN;
					navigate(`/masters/lead/preview-lead/${item.id}`, {
						state: {
							lob: item?.productLineOfBusiness,
							productType: type,
							productName: item?.productTitle,
						},
					});
				},
			},
		];
		const item2 = [
			{
				label: "Preview",
				icon: "pi pi-info-circle",
				command: () => {
					const type =
						item?.productLineOfBusiness === "Insurance"
							? Config.PRODUCT_LEAD_TYPE.INSURANCE
							: Config.PRODUCT_LEAD_TYPE.LOAN;
					navigate(`/masters/lead/preview-lead/${item.id}`, {
						state: {
							lob: item?.productLineOfBusiness,
							productType: type,
							productName: item?.productTitle,
						},
					});
				},
			},
		];
		if (position === null) {
			items1.push(
				...[
					{
						label: "Allocate TSRM",
						icon: "pi pi-sitemap",
						// command: () => {
						// 	navigate("/vendor/lead/new-lead");
						// },
						command: () => {
							setProfileDetailDialog((prev) => ({
								...prev,
								state: true,
								data: [item.id],
							}));
						},
					},
					{
						label: "Allocate RM",
						icon: "pi pi-sitemap",
						// command: () => {
						// 	navigate("/vendor/lead/new-lead");
						// },
						command: () => {
							getRmList();
							setrmAllocateDialog((prev) => ({
								...prev,
								state: true,
								data: [item.id],
							}));
							getRmList();
						},
					},
				]
			);
			item2.push(
				...[
					{
						label: "Allocate TSRM",
						icon: "pi pi-sitemap",
						// command: () => {
						// 	navigate("/vendor/lead/new-lead");
						// },
						command: () => {
							setProfileDetailDialog((prev) => ({
								...prev,
								state: true,
								data: [item.id],
							}));
						},
					},
					{
						label: "Allocate RM",
						icon: "pi pi-sitemap",
						// command: () => {
						// 	navigate("/vendor/lead/new-lead");
						// },
						command: () => {
							setrmAllocateDialog((prev) => ({
								...prev,
								state: true,
								data: [item.id],
							}));
							getRmList();
						},
					},
				]
			);
		}
		return (
			<MenuComponent
				items={
					items1
					// props.type === "all" ? items1 : item2
				}
			/>

			// <div className="flex align-items-center gap-2">
			// 	{props.type === "all" && (
			// 		<>
			// 			<Button
			// 				className="icon-btn success-btn"
			// 				tooltip="Edit Lead"
			// 				tooltipOptions={{ position: "bottom" }}
			// 				onClick={() => {
			// 					navigate(`/masters/lead/edit-lead/${item.id}`);
			// 				}}
			// 			>
			// 				<AiOutlineEdit />
			// 			</Button>
			// 			<Button
			// 				className="icon-btn alert-btn"
			// 				onClick={() => {
			// 					onOpenDialog("Timeline");
			// 					handleTimelineStatus(item.id);
			// 				}}
			// 				tooltip="Timeline Status"
			// 				tooltipOptions={{ position: "bottom" }}
			// 			>
			// 				<i className="pi pi-cloud-upload" />
			// 			</Button>
			// 			<Button
			// 				className="icon-btn warning-btn"
			// 				tooltip="Mail"
			// 				tooltipOptions={{ position: "bottom" }}
			// 			>
			// 				<CgMail color="#333" />
			// 			</Button>
			// 			<Button
			// 				className="icon-btn alert-btn"
			// 				tooltip="Status Update"
			// 				tooltipOptions={{ position: "bottom" }}
			// 				onClick={() => {
			// 					onOpenDialog("Update", item.id);
			// 				}}
			// 			>
			// 				<AiOutlineFileAdd />
			// 			</Button>
			// 			<Button
			// 				icon="pi pi-plus"
			// 				className="icon-btn primary-btn"
			// 				onClick={() => handleProgramDialog(item)}
			// 				tooltip="Create Application"
			// 				tooltipOptions={{ position: "left" }}
			// 			/>
			// 			{/* <BsFillCursorFill /> */}
			// 		</>
			// 	)}

			// 	<Button
			// 		className="icon-btn p-button-info"
			// 		tooltip="Preview"
			// 		tooltipOptions={{ position: "bottom" }}
			// 		onClick={() => {
			// 			navigate(`/masters/lead/preview-lead/${item.id}`);
			// 		}}
			// 	>
			// 		<i className="pi pi-info-circle" />
			// 	</Button>

			// 	{/* <Button
			// 		onClick={() => onOpenDialog("Remark")}
			// 		className=" p-button-sm p-0 px-3"
			// 		label="Remark"
			// 		tooltip="Add Remark"
			// 		tooltipOptions={{ position: "bottom" }}
			// 	/> */}
			// </div>
		);
	};
	const statusTemplate = (item) => {
		switch (item.status) {
			case "FOLLOW_UP":
				return <span className="status status-warning">Follow Up</span>;

			case "NOT_INTERESTED":
				return (
					<span className="status status-danger">Not Interested</span>
				);

			case "NEW":
				return <span className="status status-primary">New</span>;

			case "NOT_ELIGIBLE":
				return (
					<span className="status status-danger-deep">
						Not Eligible
					</span>
				);

			case "CONVERTED":
				return <span className="status status-success">Converted</span>;
			case "INVALID":
				return (
					<span className="status bg-red-400 text-red-50">
						Invalid
					</span>
				);
			default:
				return item.status;
		}
	};

	// const isAllocatedTemplate = (item) => {
	// 	return item.isAllocated ? (
	// 		<span className="text-seccess bg-green-200 text-xs font-bold w-3rem py-1 px-5 border-round-sm">
	// 			{/* <MdToggleOn /> */}
	// 			Yes
	// 		</span>
	// 	) : (
	// 		<span className="text-danger text-xs font-bold bg-red-200 w-3rem py-1 px-5 border-round-sm">
	// 			{/* <MdToggleOff /> */}
	// 			No
	// 		</span>
	// 	);
	// };
	const allocatedTamplate = (item) => {
		const firstName = item.allocatedToFirstName
			? item.allocatedToFirstName
			: "";
		const middleName = item.allocatedToMiddleName
			? item.allocatedToMiddleName
			: "";
		const lastName = item.allocatedToLastName
			? item.allocatedToLastName
			: "";
		const userName = item.allocatedTo ? `(${item.allocatedTo})` : "";
		const name = `${firstName} ${middleName} ${lastName} ${userName}`;
		return name;
	};
	const newExcellData =
		leadDataState.data.data &&
		leadDataState.data.data.map((item) => ({
			"Product Name": item.productTitle,
			"Line Of Business": item.productLineOfBusiness,

			Name:
				(item.firstName ? item.firstName : "") +
				" " +
				(item.middleName ? item.middleName : "") +
				" " +
				(item.lastName ? item.lastName : ""),

			Status: item.status.replaceAll("_", " ").toLowerCase(),
			"Mobile Number": item.mobileNumber,
			Zone: item.zoneName,
			State: item.stateName,
			District: item.districtName,
			City: item.city,

			"Allocated To": allocatedTamplate(item),
			"Agent Code": item.agentCode,
		}));

	const items = [
		{
			label: "Create Lead",
			icon: "pi pi-plus",
			command: () => {
				setLeadDialog(true);
				// navigate("/masters/lead/new-lead");
			},
		},
		{
			label: "Export Lead",
			icon: "pi pi-file-excel",
			command: () => {
				exportExcel(newExcellData, "Leadlist");
			},
		},
		{
			label: "Export All Lead",
			icon: "pi pi-file-excel",
			command: () => {
				getAllLoanData();
			},
		},
		(position === null || position === 5) && {
			label: "Update bulk lead status",
			icon: "pi pi-upload",
			command: () => {
				setBulkStatus(true);
			},
		},
		{
			label: "Upload Leads in Bulk",
			icon: "pi pi-upload",
			command: () => {
				setBulkDialogue(true);
			},
		},
	];

	const getAllLoanData = () => {
		setLoading(true);
		const body = {
			pageNo: 1,
			pageSize: 100000,
		};

		const CleanObject = (body) => {
			for (let propName in body) {
				if (body[propName] === "" || body[propName] === null) {
					delete body[propName];
				}
			}
			return body;
		};

		(props.type === "own"
			? leadServices.getSubmittedLead(CleanObject(body))
			: props.type === "all" &&
			  leadServices.getRecivedLead(CleanObject(body))
		)
			.then((res) => {
				let allExcelData = res.data.map((item) => ({
					ID: 2308,
					"Product Title": item.productTitle,
					Name:
						(item.firstName ? item.firstName : "") +
						" " +
						(item.middleName ? item.middleName : "") +
						" " +
						(item.lastName ? item.lastName : ""),

					"Mobile Number": item.mobileNumber,
					Status: item.status,
					"Created By": item.createdBy,
					"Created At": item.createdAt,
					"Updated At": item.updatedAt,
					Zone: item.zoneName,
					State: item.stateName,
					District: item.districtName,
					City: item.city,
					"District Name": item.districtName,
					"Is Allocated": item.isAllocated,
					"Allocated To": allocatedTamplate(item),
					"Agent Code": item.agentCode,
				}));

				exportExcel(allExcelData, "FullLeadlist");
				setLoading(false);
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
				setLoading(false);
			});
	};

	const handleStatusDialogeClose = () => {
		setBulkDialogue(false);
	};

	return (
		<>
			<Dialog
				header={"Select Product Type"}
				visible={leadDialog}
				style={{ width: "30%" }}
				onHide={() => setLeadDialog(false)}
			>
				<Dropdown
					value={lob}
					onChange={(e) => setLob(e.target.value)}
					className="w-full border-round"
					options={[
						{
							label: "Insurance",
							value: "Insurance",
						},
						{
							label: "Secured Liability Products",
							value: "Secured Liability Products",
						},
						{
							label: "Unsecured Liability Products",
							value: "Unsecured Liability Products",
						},
					]}
				/>
				<Button
					className="w-full mt-2"
					label="Create"
					onClick={() => {
						const type =
							lob === "Insurance"
								? Config.PRODUCT_LEAD_TYPE.INSURANCE
								: Config.PRODUCT_LEAD_TYPE.LOAN;
						navigate("/masters/lead/new-lead", {
							state: {
								lob,
								productType: type,
							},
						});
					}}
					disabled={lob ? false : true}
				/>
			</Dialog>
			{loading && <Loader />}
			<Toast ref={toastTL} position="top-left" />
			<>
				<div className="w-full pb-3 flex flex-wrap justify-content-between align-items-center ">
					<h3>
						{props.type === "all"
							? "All Leads"
							: props.type === "own"
							? "Own Leads"
							: "Lead By Campaign"}
					</h3>

					<div className="flex gap-2">
						<Button
							icon={toggle ? "pi pi-times" : "pi pi-search"}
							className={
								toggle
									? "p-button-danger p-button-outlined"
									: "p-button-outlined"
							}
							label={toggle ? "Close" : "Search"}
							onClick={() => setToggle((prev) => !prev)}
						/>
						<Button
							label="Reload"
							icon={"pi pi-refresh "}
							className=" p-button-outlined"
							onClick={() =>
								searchDispatch({
									type: Config.SEARCH_CONFIG.reset,
								})
							}
						/>
						{/* {(position === null || position === 5) && (
							<>
								{props.type !== "campaign" && (
									<Button
										type="button"
										icon="pi pi-upload"
										className="p-button-danger  "
										rounded
										label="Update bulk lead status"
										onClick={() => setBulkStatus(true)}
										tooltip="Update bulk lead status"
										tooltipOptions={{ position: "bottom" }}
									/>
								)}
							</>
						)}
						{props.type !== "campaign" && (
							<Button
								label="Upload Leads in Bulk"
								icon={"pi pi-upload "}
								className=""
								onClick={() => setBulkDialogue(true)}
							/>
						)}
						{props.type !== "campaign" && (
							<Button
								label="Create Lead"
								icon={"pi pi-plus"}
								className=""
								onClick={() =>
									navigate("/masters/lead/new-lead")
								}
							/>
						)} */}
						{/* {props.type !== "campaign" && (
							<Button
								label=" Agent Lead Status"
								icon={"pi pi-plus"}
								className=""
								onClick={() => {
									navigate(
										"/masters/lead/agent-lead-daily-status"
									);
								}}
							/>
						)} */}
						{/* {props.type !== "campaign" && (
							<Button
								label="Monthly Agent Lead Status"
								icon={"pi pi-plus"}
								className=""
								onClick={() => {
									navigate(
										"/masters/lead/agent-lead-monthly-status"
									);
								}}
							/>
						)} */}
						{props.type !== "campaign" && (
							<SplitButton
								icon={"pi pi-file-export"}
								label="Action"
								model={items}
								// loading={spinner}
							/>
						)}
						{/* <Button
							type="button"
							icon="pi pi-file-excel"
							className="p-button-success "
							onClick={() =>
								exportExcel(newExcellData, "Leadlist")
							}
							tooltip="Export Excel"
							tooltipOptions={{ position: "left" }}
						/> */}
					</div>
				</div>
				<div className="w-full">
					{toggle && <SearchToggleContent search={searchDispatch} />}
				</div>
			</>
			<div className="grid">
				{leadDataState.isLoading ? (
					<div className="col-12">
						<TableLoader
							headerGroup={headerGroup}
							itaration={itaration}
						/>
					</div>
				) : leadDataState.data && !leadDataState.isLoading ? (
					<div className="col-12">
						{leadDataState.data?.data?.length > 0 && (
							<Paginator
								pageSize={leadDataState.data.pageSize}
								firstPage={leadDataState.data.firstPage}
								lastPage={leadDataState.data.lastPage}
								decrement={decrement}
								increment={increment}
								pagesizechange={pagesizechange}
								pageNo={leadDataState.data.pageNo}
								totalPages={leadDataState.data.totalPages}
								totalElements={leadDataState.data.totalElements}
							/>
						)}
						<DataTable
							headerColumnGroup={headerGroup}
							value={
								leadDataState.data.data &&
								leadDataState.data.data.map((item, index) => ({
									...item,
									index:
										leadDataState.data.pageSize *
											leadDataState.data.pageNo -
										leadDataState.data.pageSize +
										1 +
										index,
								}))
							}
							responsiveLayout="scroll"
							breakpoint="960px"
						>
							<Column field="index" />
							<Column field="number" />
							<Column field="productTitle" />
							<Column field="mobileNumber" />
							<Column field={"zoneName"} />
							<Column field={"stateName"} />
							<Column field={"districtName"} />
							<Column field="city" />
							<Column body={nameTemplate} />
							<Column body={dateTemplate} />
							<Column body={updateTemplate} />
							<Column field="status" body={statusTemplate} />
							<Column body={allocatedTamplate} />
							<Column field="agentCode" />
							<Column body={actionTemplate} />
						</DataTable>

						{leadDataState.data?.data?.length > 0 && (
							<Paginator
								pageSize={leadDataState.data.pageSize}
								firstPage={leadDataState.data.firstPage}
								lastPage={leadDataState.data.lastPage}
								decrement={decrement}
								increment={increment}
								pagesizechange={pagesizechange}
								pageNo={leadDataState.data.pageNo}
								totalPages={leadDataState.data.totalPages}
								totalElements={leadDataState.data.totalElements}
							/>
						)}
					</div>
				) : (
					leadDataState.data &&
					leadDataState.data.data === 0 && (
						<div className="col-12">
							<TableNoData headerGroup={headerGroup} />
						</div>
					)
				)}
				<Dialog
					header={timelineStatusDialoge.dialogType}
					visible={timelineStatusDialoge.dialogOn}
					onHide={onHideDialog}
					breakpoints={{ "960px": "97vw" }}
					style={{ width: "65%" }}
				>
					{(function () {
						switch (timelineStatusDialoge.dialogType) {
							case "Timeline":
								return (
									<TimelineStatus
										timelineStatusData={timeLineState}
									/>
								);
							case "Update":
								return (
									<StatusUpdate
										id={timelineStatusDialoge.leadId}
										event={getRecevedList}
									/>
								);
							case "Remark":
								return (
									<Formik
										initialValues={{
											title: "",
											description: "",
										}}
										validationSchema={Yup.object().shape({
											title: Yup.string().required(
												"This Field is required"
											),
											description: Yup.string().required(
												"This Field is required"
											),
										})}
									>
										{({ handleSubmit }) => (
											<Form
												className="form-grid grid"
												onSubmit={handleSubmit}
											>
												<div className="field col-12">
													<Field
														name={`title`}
														component={FieldInput}
														header={"Title"}
														star={"*"}
													/>
												</div>
												<div className="field col-12">
													<Field
														name={`description`}
														component={
															FieldTextArea
														}
														header={"Description"}
														star={"*"}
													/>
												</div>
												<div className="flex justify-content-end align-items-center col-12 mt-4">
													<Button
														type="cancel"
														label="Cancel"
														className="p-button-danger mr-2"
														onClick={() => {}}
													/>
													<Button
														type="submit"
														label="Save"
													/>
												</div>
											</Form>
										)}
									</Formik>
								);
							default:
								return <></>;
						}
					})()}
				</Dialog>

				<Dialog
					visible={dialog}
					onHide={() => setDialog(false)}
					style={{ width: "90%" }}
				>
					{programData.isLoading ? (
						<>
							<div className="col-12">
								<TableLoader
									headerGroup={
										<ColumnGroup>
											<Row>
												<Column header="Institution Name" />
												<Column />
											</Row>
										</ColumnGroup>
									}
									itaration={{ jj: "", kk: "" }}
								/>
							</div>
						</>
					) : programData.data.length > 0 ? (
						<DataTable
							value={programData.data}
							expandedRows={expandedRows}
							onRowToggle={(e) => setExpandedRows(e.data)}
							responsiveLayout="scroll"
							rowExpansionTemplate={rowExpansionTemplate}
							dataKey="institutionId"
						>
							<Column
								expander={allowExpansion}
								style={{ width: "3em" }}
							/>
							<Column
								field="institutionName"
								header="Institution Name"
							/>
						</DataTable>
					) : (
						programData.data?.length === 0 &&
						!programData.isLoading && (
							<div className="col-12">
								<TableNoData headerGroup={headerGroup} />
							</div>
						)
					)}
				</Dialog>
				<Dialog
					visible={bulkDialoge}
					onHide={() => setBulkDialogue(false)}
					style={{ width: "70%" }}
				>
					<BulkUpload
						handleStatusDialogeClose={handleStatusDialogeClose}
					/>
				</Dialog>
				<Dialog
					visible={bulkStatus}
					onHide={() => setBulkStatus(false)}
					style={{ width: "70%" }}
				>
					<BulkStatusUpload
						listData={leadDataState.data?.data}
						setBulkStatus={setBulkStatus}
						searchDispatch={getRecevedList}
					/>
				</Dialog>
			</div>
			<Dialog
				header="Allocate TSRM"
				visible={profileDetailDialog.state}
				onHide={() => {
					setProfileDetailDialog((prev) => ({
						...prev,
						state: false,
						data: null,
					}));
				}}
				breakpoints={{ "960px": "97vw" }}
				style={{ width: "65vw" }}
			>
				<AllocateAgentForm handleSearch={handleSearch} />
			</Dialog>
			<Dialog
				header="Allocate RM"
				visible={rmAllocate.state}
				onHide={() => {
					setrmAllocateDialog((prev) => ({
						...prev,
						state: false,
						data: null,
					}));
				}}
				breakpoints={{ "960px": "97vw" }}
				style={{ width: "65vw" }}
			>
				<Formik
					initialValues={{ userName: "" }}
					onSubmit={handleRmAllocateSubmit}
				>
					{({ handleSubmit, resetForm, values }) => (
						<Form
							onSubmit={handleSubmit}
							className="modal-form-grid border-round-md mt-2 p-4 form-grid grid"
						>
							<div className="field col-12 md:col-3">
								<Field
									header={"User"}
									name={"userName"}
									filter
									component={FeildDropdown}
									options={rmList?.map((elm) => ({
										...elm,
										name:
											(elm?.firstName
												? elm?.firstName
												: "") +
											" " +
											(elm?.middleName
												? elm?.middleName
												: "") +
											" " +
											(elm?.lastName
												? elm?.lastName
												: ""),
									}))}
									optionLabel="name"
									optionValue={"userName"}
								/>
							</div>
							<div className="col-12 flex align-items-end justify-content-end">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={() => {
										resetForm();
									}}
								/>
								<Button
									type="submit"
									disabled={!values.userName}
									label="Allocate"
								/>
							</div>
						</Form>
					)}
				</Formik>
			</Dialog>
		</>
	);
};

export default LeadsTable;
