import { useEffect, useRef, useState } from "react";
import { Form, Formik, Field } from "formik";
import {
	FeildCalender,
	FeildDropdown,
	FieldCheckBox,
	FieldInput,
	FieldMultiselect,
	FieldRadio,
} from "../../../../components/FieldInput";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import Config from "../../../../shared/config";
import { exportExcel } from "../../../../components/XlsxExport";
import SearchFieldGroup from "../../../../components/SearchFieldGroup";
import { SplitButton } from "primereact/splitbutton";
import { LoanService } from "../../../../services/LoanService";
// import FileSaver from "file-saver";
import { apiPath } from "../../../../shared/constant";
// import {
// 	dataReducer,
// 	fetchInitialState,
// } from "../../../../components/fetchReducer";
import { LoanApplicationService } from "../../../../services/LoanApplication";
import { Toast } from "primereact/toast";
import moment from "moment";
import { saveAs } from "file-saver";
import {
	useGetAllHubQuery,
	useGetAppProgramQuery,
	useGetInstitutionDetailsQuery,
	// useGetInstitutionDetailsQuery,
	useGetProductDetailsQuery,
	useGetStateListQuery,
} from "../../../../services/query/queryApi";
import { CallCenterService } from "../../../../services/callCenterService";
import { useDispatch, useSelector } from "react-redux";
import { setLeadId } from "../../../../store/reducer/LeadReducer";
import {
	setApplicationId,
	setApplicationState,
} from "../../../../store/reducer/AuthReducer";
import SearchFieldState from "../../../../components/SearchFieldState";
import { produce } from "immer";
const SearchInsuranceApplication = (props) => {
	const { data: programData = [] } = useGetAppProgramQuery();
	const { data: stateData = [] } = useGetStateListQuery();

	// const { data: institutionList = [] } = useGetInstitutionDetailsQuery();
	const toast = useRef(null);
	const [show, setShow] = useState(false);
	const loanService = new LoanService();
	const [spokeList, setSpokeList] = useState([]);
	const [allOEList, setAllOEList] = useState([]);
	const [userList, setUserList] = useState([]);
	// const [spinner, props.loader] = useState(false);
	const { data = [] } = useGetAllHubQuery();
	const callcenterService = new CallCenterService();
	const selector = useSelector((state) => state.persistData.auth.user.role);

	const dispatch = useDispatch();
	const { data: productList = { data: [] } } = useGetProductDetailsQuery({
		searchRequest: [],
		pageNo: 1,
		pageSize: 330,
		sorts: [],
	});
	const { data: institutionList = [] } = useGetInstitutionDetailsQuery();
	const [districtList, setDistrictList] = useState([]);
	const ccService = new CallCenterService();
	const navigate = useNavigate();
	const loanListService = new LoanApplicationService();
	const initialValue = {
		//"id":[1459]
		number: "",
		status: [
			"CUSTOMER_DRAFT",
			"DRAFT",
			"LOCK",
			"CUSTOMER_APPROVED",
			"PENDING_FOR_CUSTOMER",
			"REVERT_BY_OE",
			"TSRM_SUBMITTED",
			"WORK_IN_PROGRESS",
		],
		// hubId: "",
		// spokeId: "",
		assignedTo: [],
		bankApplicationNumber: "",
		loanAmount: "",
		productId: [],
		fromDate: "",
		toDate: "",
		oeName: [],
		stateId: [],
		districtId: [],
		name: "",
		// "gender":"Male"
		// "dob":"1998-03-16"
		// "personalMail":"abc@gmail.com"
		mobile: "",
		program: [],
		institutionId: [],
		// institutionId: "",
		// "customerId":"2091"
		uniqueApplication: false,
		unassignTsrm: false,
		isApplicationNumber: false,
		applicationStatus: [],
	};

	const getTsrmBYHubSpoke = (val) => {
		ccService.getTsrmByHubIdspokeId(val).then((res) => {
			setUserList(res?.data);
		});
	};
	useEffect(() => {
		selector.position === 1000 &&
			getTsrmBYHubSpoke({
				pageNo: 1,
				pageSize: 1000000,
				hubId: null,
				spokeId: null,
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selector]);

	const getAllLoanData = (value) => {
		props.loader(true);
		const body = {
			pageNo: 1,
			pageSize: 1000000,
		};

		loanListService
			.getLoanApplicationList(body)
			.then((res) => {
				const data = newData(res.data);
				exportExcel(data, "All application list");
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => props.loader(false));
	};
	const newData = (data) => {
		props.loader(true);
		const arr = [...data];
		const body = arr.map((item) => ({
			...(item.firstName === null &&
			item.middleName === null &&
			item.lastName === null
				? {
						Name: item.name,
				  }
				: {
						Name:
							(item.firstName ? item.firstName : "") +
							" " +
							(item.middleName ? item.middleName : "") +
							" " +
							(item.lastName ? item.lastName : ""),
				  }),
			LOB: item.alias,
			Zone: item.zone,
			State: item.state,
			District: item.district,

			"Mobile No": item.mobile,
			"RF Application No": item.number,
			"RFA Status": item.status.replaceAll("_", " ").toLowerCase(),
			"Agent Code": item.agentCode,
			"Program Name": item.programName,

			Amount: item.loanAmount,

			Gender: item.gender,
			"Date Of Birth": item.dob
				? moment(item.dob).format("Do MMM, YYYY")
				: "",
			"Personal Mail": item.personalMail,

			"Created On": item.createdOn
				? moment(item.createdOn).format("Do MMM, YYYY")
				: "",
			"FI Status": item.applicationStatus,
			// "subProductId":item.subProductId,

			"FI Application Number": item.bankApplicationNumber,
		}));
		return body;
	};
	const handleForm = (val, action) => {
		const { status, ...rest } = val;
		const newStatue = status?.includes("LOCK")
			? {
					...rest,
					status: [
						...status,
						"LOCK_REJECTED_BY_FI",
						"LOCK_DISBURSED_BY_FI",
						"LOCK_APPROVED_BY_FI",
					],
			  }
			: val;
		let newValue = Object.values(newStatue);
		let newKeys = Object.keys(newStatue);
		const newObj = produce({}, (state) => {
			for (let index = 0; index < newValue.length; index++) {
				const element = newValue[index];
				if (
					(!Array.isArray(element) && Boolean(element)) ||
					(Array.isArray(element) && element.length)
				) {
					state[newKeys[index]] = element;
				}
			}
		});
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: newObj,
		});
		// action.resetForm();
	};

	const radioBtns = [
		{
			id: "Male",
			name: "Male",
			value: "Male",
		},
		{
			id: "Female",
			name: "Female",
			value: "Female",
		},
		{
			id: "Others",
			name: "Others",
			value: "Others",
		},
	];
	const getData = () => {
		props.loader(true);
		loanService
			.getAllHospicashList()
			.then((res) => {
				const a = document.createElement("a");
				a.href = `${apiPath}/application/hospicash-all`;
				a.download = "ApplicationList";
				a.click();

				// saveAs(
				// 	"http://192.168.0.147:8083/rainbowmaster/application/hospicash-all",
				// 	"HospicashList"
				// );
			})
			.catch((er) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Error",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => props.loader(false));
		// .catch((err) => {
		// 	props.loader(false);
		// });
		// .finally(() => props.loader(false));

		// FileSaver.saveAs(
		// 	`${apiPath}/application/hospicash-all`,
		// 	"applicationList"
		// );
	};
	const items = [
		{
			label: "Export",
			icon: "pi pi-file-excel",
			command: () => {
				exportExcel(props.newData, "applicationList");
			},
		},
		{
			label: "Export All",
			icon: "pi pi-file-excel",
			command: () => {
				getAllLoanData();
			},
		},
		{
			label: "Export Hospicash",
			icon: "pi pi-file-excel",
			command: () => {
				getData();
			},
		},
	];
	const getSpokeList = (e) => {
		callcenterService.getSpokeListById(e).then((res) => setSpokeList(res));
	};
	useEffect(() => {
		ccService.allOEList().then((res) => {
			const data = res.map((item) => ({
				...item,
				name:
					(item.firstName ? item.firstName : "") +
					" " +
					(item.middleName ? item.middleName : "") +
					" " +
					(item.lastName ? item.lastName : ""),
			}));
			setAllOEList(data);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleSpokeList = (e, setFieldValue) => {
		setFieldValue("spokeId", "", true);
		setFieldValue("hubId", e.value, true);

		getSpokeList(e.value);
	};
	const handleDistrict = (val, setFieldValue) => {
		setFieldValue("districtId", [], true);
		setFieldValue("stateId", val, true);
		ccService
			.getStateWiseDistrict({ stateId: val })
			.then((res) => setDistrictList(res));
	};

	return (
		<>
			<div className="col-12 w-full flex align-items-end justify-content-between">
				<Toast ref={toast} />
				<h3>Application List</h3>
				<div className="flex gap-2">
					{/* <Button
						type="button"
						icon="pi pi-file-pdf"
						className="p-button-danger  "
						rounded
						onClick={props.exportPdf}
						tooltip="Export PDF"
						tooltipOptions={{ position: "bottom" }}
					/> */}
					<Button
						icon={show ? "pi pi-times" : "pi pi-search"}
						className={
							show
								? "p-button-danger p-button-outlined"
								: "p-button-outlined"
						}
						label={show ? "Close" : "Search"}
						onClick={() => {
							setShow((show) => !show);
						}}
					/>
					<Button
						className=" p-button-outlined"
						onClick={() =>
							// {props.searchDispatch({
							// 	type: Config.SEARCH_CONFIG.reset,
							// });
							{
								props.searchDispatch({
									type: Config.SEARCH_CONFIG.searchRequest,
									payload: {
										status: [
											"CUSTOMER_DRAFT",
											"DRAFT",
											"LOCK_REJECTED_BY_FI",
											"LOCK_DISBURSED_BY_FI",
											"LOCK_APPROVED_BY_FI",
											"LOCK",
											"CUSTOMER_APPROVED",
											"PENDING_FOR_CUSTOMER",
											"REVERT_BY_OE",
											"TSRM_SUBMITTED",
											"WORK_IN_PROGRESS",
										],
									},
								});
							}
						}
					>
						<i className="pi pi-refresh mr-1" />
						Reload
					</Button>
					{/* <Button
						className=""
						onClick={props.handleStatusUpload}
						icon="pi pi-upload"
						label="Bulk Status Upload"
					/> */}
					{selector?.id !== 1 && (
						<Button
							className=""
							onClick={() => {
								dispatch(setApplicationId(null));
								navigate("/vendor/application-create");
								dispatch(setApplicationState("edit"));
							}}
							icon="pi pi-plus"
							label="Application"
						/>
					)}
					{/* <SplitButton
						icon={"pi pi-file-export"}
						label="Export"
						model={items}
						// loading={spinner}
					/> */}
					{/* <Button
						type="button"
						icon="pi pi-file-excel"
						className="p-button-success "
						onClick={() =>
							exportExcel(props.newData, "applicationList")
						}
						tooltip="Export Excel"
						tooltipOptions={{ position: "left" }}
					/> */}
				</div>
			</div>
			{show && (
				<Formik initialValues={initialValue} onSubmit={handleForm}>
					{({ handleSubmit, resetForm, values, setFieldValue }) => (
						<Form
							onSubmit={handleSubmit}
							className="background-secondary mt-2 c-search-form-box border-round-md form-grid p-3"
						>
							<div className="form-grid grid">
								<div className="field col-12 md:col-3">
									<Field
										header={"From Date"}
										name={"fromDate"}
										component={FeildCalender}
									/>
								</div>

								<div className="field col-12 md:col-3">
									<Field
										header={"To Date"}
										name={"toDate"}
										component={FeildCalender}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Status"}
										name={"status"}
										component={FieldMultiselect}
										options={[
											{
												label: "Customer Draft",
												value: "CUSTOMER_DRAFT",
											},
											{
												label: "Draft",
												value: "DRAFT",
											},
											// {
											// 	label: "Submitted",
											// 	value: "SUBMITED",
											// },
											{
												label: "Lock",
												value: "LOCK",
											},
											{
												label: "Customer Approved",
												value: "CUSTOMER_APPROVED",
											},
											{
												label: "Pending For Customer",
												value: "PENDING_FOR_CUSTOMER",
											},

											{
												label: "Revert by OE",
												value: "REVERT_BY_OE",
											},
											{
												label: "TSRM Submit",
												value: "TSRM_SUBMITTED",
											},
											{
												label: "OE Work In Progress",
												value: "WORK_IN_PROGRESS",
											},
											{
												label: "Rejected",
												value: "REJECT",
											},
										]}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"FI Status"}
										name={"applicationStatus"}
										component={FeildDropdown}
										options={[
											{
												label: "Pending",
												value: "Pending",
											},
											{
												label: "Approve",
												value: "Approve",
											},

											{
												label: "Disbursed",
												value: "Disbursed",
											},
											{
												label: "Rejected",
												value: "Rejected",
											},
										]}
									/>
								</div>
								{selector.position === 1000 && (
									<div className="field col-12 md:col-3">
										<Field
											header={"Assign to (TSRM)"}
											name={"assignedTo"}
											filter
											component={FieldMultiselect}
											options={userList.map((elm) => ({
												...elm,
												name:
													(elm?.firstName
														? elm?.firstName
														: "") +
													" " +
													(elm?.middleName
														? elm?.middleName
														: "") +
													" " +
													(elm?.lastName
														? elm?.lastName
														: "") +
													" (" +
													elm?.userName +
													")",
											}))}
											optionLabel="name"
											optionValue={"userName"}
										/>
									</div>
								)}
								<div className="field col-12 md:col-3">
									<Field
										header={"Applicant Name"}
										name={"name"}
										component={FieldInput}
									/>
								</div>

								<div className="field col-12 md:col-3">
									<Field
										header={"Mobile"}
										name={"mobile"}
										component={FieldInput}
									/>
								</div>
								{/* <SearchFieldState
									values={values}
									setFieldValue={setFieldValue}
									stateField={"stateId"}
									districtField={"districtId"}
								/> */}
								<div className="field col-12 md:col-3">
									<Field
										header={"State"}
										name={"stateId"}
										component={FieldMultiselect}
										options={stateData}
										filter
										value={values.stateId}
										onChange={(e) => {
											handleDistrict(
												e.value,
												setFieldValue
											);
										}}
										optionLabel={"name"}
										optionValue={"id"}
									/>
								</div>

								{values.stateId?.length > 0 && (
									<div className="field col-12 md:col-3">
										<Field
											header={"District"}
											name={"districtId"}
											component={FieldMultiselect}
											filter
											options={districtList}
											optionLabel={"name"}
											optionValue={"id"}
											optionGroupLabel="name"
											optionGroupChildren="districtList"
										/>
									</div>
								)}
								<div className="field col-12 md:col-3">
									<Field
										header={"Application Number"}
										name={"number"}
										component={FieldInput}
									/>
								</div>
								{/* <div className="field col-12 md:col-3">
									<Field
										header={"Agent Code"}
										name={"agentCode"}
										type={"number"}
										component={FieldInput}
									/>
								</div> */}
								<div className="field col-12 md:col-3">
									<Field
										header={"FI Application number"}
										name={"bankApplicationNumber"}
										component={FieldInput}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Product"}
										name={"productId"}
										filter
										component={FieldMultiselect}
										options={productList.data?.filter(
											(elm) => elm.isActive
										)}
										optionLabel="title"
										optionGroupLabel="title"
										optionGroupChildren="subProducts"
										optionValue={"id"}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={"Program"}
										name={"program"}
										component={FieldMultiselect}
										filter
										options={programData}
										optionLabel={"name"}
										optionValue={"programId"}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										name="institutionId"
										component={FieldMultiselect}
										options={institutionList}
										filter
										optionLabel={"name"}
										optionValue={"id"}
										header={"Institution"}
									/>
								</div>
								{/* <div className="field col-12 md:col-3">
									<Field
										header={"Loan Amount"}
										name={"loanAmount"}
										type={"number"}
										component={FieldInput}
									/>
								</div> */}

								{/* {selector.position === 1000 && (
									<>
										<div className="field col-12 md:col-3">
											<Field
												header={"Hub"}
												name={"hubId"}
												filter
												component={FeildDropdown}
												onChange={(e) => {
													handleSpokeList(
														e,
														setFieldValue
													);
												}}
												options={data}
												optionLabel="name"
												optionValue={"id"}
											/>
										</div>

										<div className="field col-12 md:col-3">
											<Field
												header={"Spoke"}
												name={"spokeId"}
												filter
												component={FeildDropdown}
												options={spokeList}
												optionLabel={"name"}
												optionValue={"id"}
											/>
										</div>
									</>
								)} */}

								{/* <div className="field col-12 md:col-3">
									<Field
										header={"FI Application number"}
										name={"bankApplicationNumber"}
										component={FieldInput}
									/>
								</div> */}

								<div className="field col-12 md:col-3">
									<Field
										header={"Operation Executive"}
										name={"oeName"}
										component={FieldMultiselect}
										filter
										options={allOEList}
										optionLabel={"name"}
										optionValue={"userName"}
									/>
								</div>

								{/* <div className="field col-12 md:col-3">
									<Field
										header={"Institution"}
										name={"institutionId"}
										component={FeildDropdown}
										filter
										options={institutionList}
										optionLabel={"name"}
										optionValue={"id"}
									/>
								</div> */}
								{selector.position === 1000 && (
									<div className="field col-12 md:col-3">
										<Field
											header={"Unassign TSRM"}
											name={"unassignTsrm"}
											component={FieldCheckBox}
										/>
									</div>
								)}

								<div className="field col-12 md:col-3">
									<Field
										header={"Unique Application"}
										name={"uniqueApplication"}
										component={FieldCheckBox}
									/>
								</div>
								<div className="field col-12 md:col-3">
									<Field
										header={
											"RF Applications(which has RF Application No.)"
										}
										name={"isApplicationNumber"}
										component={FieldCheckBox}
									/>
								</div>
								<div className="col-5 flex align-items-end justify-content-end ml-auto">
									<Button
										type="button"
										label="Reset"
										className="p-button-danger mr-2"
										onClick={resetForm}
									/>
									<Button type="submit" label="Search" />
								</div>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default SearchInsuranceApplication;
