import moment from "moment";
import React from "react";
import { FiCheck, FiX } from "react-icons/fi";

const VerificationDetails = (props) => {
	return (
		<div className="grid ">
			<div className="col-12 lg:col-6">
				<div className="card p-3 border-1 border-400 border-round-md">
					<div className="w-full flex align-items-center justify-content-between">
						<h3 className="mt-0">AADHAAR</h3>
						{props.agent.profile.aadhaarVerified ? (
							<span className="sq-status status-success">
								<FiCheck />
							</span>
						) : (
							<span className="sq-status status-danger">
								<FiX />
							</span>
						)}
					</div>

					<div className="mb-2">
						<h5 className="m-0">Name</h5>

						<p className="m-0">
							{props?.agent?.profile?.aadharFullName}
						</p>
					</div>

					<div className="mb-2">
						<h5 className="m-0">Date Of Birth</h5>
						{props.agent.profile.aadhaarVerified &&
							props.agent.profile.aadharDateOfBirth && (
								<p className="m-0">
									{moment(
										props?.agent?.profile?.aadharDateOfBirth
									).format("Do MMM, YYYY")}
								</p>
							)}
					</div>

					<div className="mb-2">
						<h5 className="m-0">Address</h5>
						{props.agent.profile.aadhaarVerified &&
							props.agent.profile.aadharAddress && (
								<p className="m-0">
									{props.agent.profile.aadharAddress}
								</p>
							)}
					</div>
				</div>
				<div className="card mt-3 p-3 border-1 border-400 border-round-md">
					<div className="w-full flex align-items-center justify-content-between">
						<h3 className="mt-0">PAN</h3>
						{props.agent.profile.panVerified ? (
							<span className="sq-status status-success">
								<FiCheck />
							</span>
						) : (
							<span className="sq-status status-danger">
								<FiX />
							</span>
						)}
					</div>

					<div className="mb-2">
						<h5 className="m-0">PAN No:</h5>
						{props.agent.profile.panVerified &&
							props.agent.profile.panNumber && (
								<p className="m-0">
									{props.agent.profile.panNumber}
								</p>
							)}
					</div>

					<div className="mb-2">
						<h5 className="m-0">Name:</h5>
						{props.agent.profile.panVerified &&
							props.agent.profile.panFullName && (
								<p className="m-0">
									{props.agent.profile.panFullName}
								</p>
							)}
					</div>

					<div className="mb-2">
						<h5 className="m-0">Date Of Birth:</h5>
						{props.agent.profile.panVerified &&
							props.agent.profile.panDateOfBirth && (
								<p className="m-0">
									{moment(
										props.agent.profile.panDateOfBirth
									).format("Do MMM, YYYY")}
								</p>
							)}
					</div>
				</div>
			</div>
			<div className="col-12 lg:col-6">
				<div className="card p-3 border-1 border-400 border-round-md">
					<h3 className="mt-0">Face Match</h3>

					<div className="grid">
						<div className="col-8">
							<div className="card px-2">
								<div className="w-full flex align-items-center justify-content-between">
									<h3>Match with PAN</h3>
									{props.agent.profile.faceMatchPOI ? (
										<span className="sq-status status-success">
											<FiCheck />
										</span>
									) : (
										<span className="sq-status status-danger">
											<FiX />
										</span>
									)}
								</div>
								<div className="w-full flex align-items-center justify-content-between">
									<h3>Match with AADHAAR</h3>
									{props.agent.profile.faceMatchPOA ? (
										<span className="sq-status status-success">
											<FiCheck />
										</span>
									) : (
										<span className="sq-status status-danger">
											<FiX />
										</span>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VerificationDetails;
