import React from "react";
import Config from "../../../../shared/config";
import { S3 } from "aws-sdk";
import { FileUpload } from "primereact/fileupload";

const CompanyDetail = ({ agent }) => {
	// const s3Clint = new S3({
	// 	accessKeyId: Config.S3_ACCESSKEYID,
	// 	secretAccessKey: Config.S3_SECRETACCESSKEY,
	// 	region: Config.S3_REGION,
	// 	signatureVersion: "v4",
	// });
	// const onUploadImage = (e) => {
	// 	const file = e.files[0];
	// 	const fileName = file.name;
	// 	const params = {
	// 		Body: file,
	// 		Bucket: `${Config.AGENT_PROFILE_BUCKET}/${agent.profile?.id}`,
	// 		Key: fileName,
	// 		ContentType: file.type,
	// 	};

	// 	s3Clint.upload(params, (err) => {
	// 		if (err) {
	// 			//setLoading(false);
	// 			console.log(err);
	// 		}

	// 		//setLoading(false);
	// 	});
	// };
	return (
		<div>
			{agent.profile.company && (
				<div className="grid">
					<div className="col-12 lg:col-6">
						<div className="text-key-val-grid">
							<p className="m-0 lable">Company Name:</p>
							<p className="m-0 value">
								{agent.profile.company.name}
							</p>
						</div>
						<div className="text-key-val-grid gap-5">
							<p className="m-0 lable font-600">
								Company Address:
							</p>
							<p className="m-0 value text-right">
								{agent.profile.company.address1 &&
									`${agent.profile.company.address1}, `}
								{agent.profile.company.address2 &&
									`${agent.profile.company.address2}, `}
								{agent.profile.company.address3 &&
									`${agent.profile.company.address3} `}
							</p>
						</div>
						<div className="text-key-val-grid">
							<p className="m-0 lable">Pin:</p>
							<p className="m-0 value">
								{agent.profile.company.pincode}
							</p>
						</div>
						<div className="text-key-val-grid">
							<p className="m-0 lable">Representative:</p>
							<p className="m-0 value">
								{`${agent.profile.company.representativeFirstName} ${agent.profile.company.representativeLastName}`}
							</p>
						</div>
						<div className="text-key-val-grid">
							<p className="m-0 lable">Designation :</p>
							<p className="m-0 value">
								{agent.profile.company.designation}
							</p>
						</div>
						<div className="text-key-val-grid">
							<p className="m-0 lable">Designation type:</p>
							<p className="m-0 value">
								{agent.profile.company.type}
							</p>
						</div>
						<div className="text-key-val-grid">
							<p className="m-0 lable">Mobile no:</p>
							<p className="m-0 value">
								{agent?.profile?.company?.representativeMobile}
							</p>
						</div>
						<div className="text-key-val-grid">
							<p className="m-0 lable">Pan :</p>
							<p className="m-0 value">
								{agent?.profile.company?.pan}
							</p>
						</div>
					</div>
					<div className="col-12 lg:col-6">
						{agent.profile.company.fileSequence && (
							<>
								<p style={{ fontWeight: 700 }}>Document</p>
								<img
									src={`https://rainbow-india.s3.ap-south-1.amazonaws.com/agent-profile/${agent.profile?.id}/${agent.profile.company.fileSequence}`}
									style={{ width: "50%" }}
									alt={agent.profile.company.fileSequence}
									className="border-solid border-white-alpha-90"
									// onError={(event) => {
									// 	event.target.src = `https://rainbow-india.s3.ap-south-1.amazonaws.com/agent-profile/${agent.profile.company.fileSequence}`;
									// 	event.currentTarget.onerror = null;
									// }}
								/>
							</>
						)}
						{agent.profile.company.panFile && (
							<>
								<p style={{ fontWeight: 700 }}>Pan</p>
								<img
									src={`https://rainbow-india.s3.ap-south-1.amazonaws.com/agent-profile/${agent.profile?.id}/${agent.profile.company.panFile}`}
									style={{ width: "50%" }}
									alt={agent.profile.company.fileSequence}
									className="border-solid border-white-alpha-90"
									// onError={(event) => {
									// 	event.target.src = `https://rainbow-india.s3.ap-south-1.amazonaws.com/agent-profile/${agent.profile.company.panFile}`;
									// 	event.currentTarget.onerror = null;
									// }}
								/>
							</>
						)}
						{/* <FileUpload
							customUpload
							accept=".jpg,.png,.jpeg, .pdf"
							uploadHandler={(e) => onUploadImage(e)}
							maxFileSize={5000000}
							emptyTemplate={
								<p className="m-0">
									Drag and drop files to here to upload.
								</p>
							}
						/> */}
					</div>
				</div>
			)}
		</div>
	);
};

export default CompanyDetail;
