import moment from "moment";
import React from "react";
import { FiCheck, FiX } from "react-icons/fi";

const General = (props) => {
	const getStatus = (data) => {
		switch (data) {
			case "VERIFIED":
				return <span className="status status-success">Verified</span>;
			case "ON_HOLD":
				return <span className="status status-warning">On Hold</span>;
			case "TEMPORARY_SUSPENDED":
				return (
					<span className="status status-danger">
						Temporary Suspended
					</span>
				);
			case "NOT_VERIFIED":
				return <span className="status status-ban">Not Verified</span>;
			case "BLOCKED":
				return (
					<span className="status status-danger-deep">Blocked</span>
				);

			default:
				return <span className="status status-danger">No Data</span>;
		}
	};

	return (
		<div className="grid">
			<div className="col-12 lg:col-6">
				<div className="text-key-val-grid">
					<p className="m-0 lable">Name:</p>
					<p className="m-0 value">
						{`${
							props.agent.profile.agent.salutation &&
							props.agent.profile.agent.salutation
						} ${
							props.agent.profile.agent.firstName &&
							props.agent.profile.agent.firstName
						} ${
							props.agent.profile.agent.middleName &&
							props.agent.profile.agent.middleName
						} ${
							props.agent.profile.agent.lastName &&
							props.agent.profile.agent.lastName
						}`}
					</p>
				</div>
				<div className="text-key-val-grid">
					<p className="m-0 lable">Username:</p>
					<p className="m-0 value">
						{props.agent.profile.agent.userName}
					</p>
				</div>
				{props.agent.profile.companyEmployeeId && (
					<div className="text-key-val-grid">
						<p className="m-0 lable">Company Employee Id:</p>
						<p className="m-0 value">
							{props.agent.profile.companyEmployeeId}
						</p>
					</div>
				)}

				<div className="text-key-val-grid">
					<p className="m-0 lable">Email ID:</p>
					<p className="m-0 value">
						{props.agent.profile.agent.emailId}
					</p>
				</div>
				<div className="text-key-val-grid">
					<p className="m-0 lable">Mobile No:</p>
					<p className="m-0 value">
						{props.agent.profile.agent.mobileNumber}
					</p>
				</div>
				<div className="text-key-val-grid no-border">
					<p className="m-0 lable">Gender:</p>
					<p className="m-0 value">
						{props.agent.profile.agent.gender}
					</p>
				</div>
			</div>
			<div className="col-12 lg:col-6">
				<div className="text-key-val-grid">
					<p className="m-0 lable">Profile Type:</p>
					<p className="m-0 value">
						{props.agent.profile.profileType}
					</p>
				</div>
				<div className="text-key-val-grid">
					<p className="m-0 lable">Status:</p>
					<p className="m-0 value">
						{/* {props.agent.profile.status === "VERIFIED" ? (
							<span className="status status-success">
								{props.agent.profile.status}
							</span>
						) : props.agent.profile.status ===
						  "TEMPORARY_SUSPENDED" ? (
							<span className="status status-danger">
								{props.agent.profile.status}
							</span>
						) : props.agent.profile.status === "ON_HOLD" ? (
							<span className="status status-warning">
								On Hold
							</span>
						) : (
							<span className="status status-ban">
								Not Verified
							</span>
						)} */}
						{getStatus(props.agent.profile.status)}
					</p>
				</div>
				<div className="text-key-val-grid">
					<p className="m-0 lable">Is Default Profile:</p>
					<p className="m-0 value">
						{props.agent.profile.active ? (
							<span className="sq-status status-success">
								<FiCheck />
							</span>
						) : (
							<span className="sq-status status-danger">
								<FiX />
							</span>
						)}
					</p>
				</div>
				<div className="text-key-val-grid">
					<p className="m-0 lable">District:</p>
					<p className="m-0 value">
						{props.agent.profile.district.name}
					</p>
				</div>
				<div className="text-key-val-grid no-border">
					<p className="m-0 lable">Ctrated On:</p>
					<p className="m-0 value">
						{moment(props.agent.profile.createdAt).format(
							"Do MMM, YYYY HH:mm A"
						)}
					</p>
				</div>
			</div>
		</div>
	);
};

export default General;
