import { Fragment, useEffect, useReducer, useRef, useState } from "react";
import { produce } from "immer";
import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import { Panel } from "primereact/panel";
import { InputSwitch } from "primereact/inputswitch";
import { Divider } from "primereact/divider";
import { LeadService } from "../../../../services/LeadService";
import Loader from "../../../../components/Loader";
import {
	useGetAllDIstrictQuery,
	useGetProductDetailsQuery,
	// useGetStateListQuery,
} from "../../../../services/query/queryApi";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
} from "../../../../components/FieldInput";
import { ProgressSpinner } from "primereact/progressspinner";
import Config from "../../../../shared/config";
// import moment from "moment";

import selectState from "../../../../components/stateList";
// import { LoanApplicationService } from "../../../../services/LoanApplication";
import {
	convertNullToString,
	convertObjNull,
} from "../../../../utils/UtilsFunction";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { classNames } from "primereact/utils";
import { ConnectedFocusError } from "focus-formik-error";
import { Skeleton } from "primereact/skeleton";
import Header from "../../../partners/institutions/header";
import DocumentUpload from "./DocumentUpload";

const SubmitNewInsuranceLeadDialog = (props) => {
	const initialState = {
		stage: [
			{
				sequence: 1,
				title: "Lead",
				status: "active",
			},
			{
				sequence: 2,
				title: "Document Upload",
				status: "inactive",
			},
		],
		active: 1,
		leadId: null,
	};
	const stageReducer = produce((draft, action) => {
		switch (action.type) {
			case "stage":
				const newData = draft.stage.map((item) =>
					item.sequence === action.payload.sequence
						? { ...item, status: action.payload.status }
						: item
				);
				draft.stage = newData;
				break;
			case "active":
				draft.active = action.active;
				break;
			case "id":
				draft.leadId = action.id;
				break;
			default:
				return draft;
		}
	});
	const [stages, setStages] = useReducer(stageReducer, initialState);
	const navigate = useNavigate();
	const toastTL = useRef(null);
	// const loanApplicationService = new LoanApplicationService();
	// const { data: stateData = [] } = useGetStateListQuery();
	const {
		data: allDistrictData = [
			{ districtList: [{ id: null, name: "" }], id: null, name: "" },
		],
		isLoading,
	} = useGetAllDIstrictQuery();
	const createLead = new LeadService();
	const [loader, setLoader] = useState(false);
	const { isSuccess, data = {} } = useGetProductDetailsQuery({
		searchRequest: [
			{
				operation: "EQUAL",
				column: "lineOfBusiness",
				value: props?.location?.state?.lob,
			},
		],
		pageNo: 1,
		pageSize: 330,
		sorts: [],
	});

	const validationSchema = Yup.object().shape({
		mobile: Yup.string()
			.matches(
				/^[1-9]\d{9}$/gi,
				"Mobile number should be 10 digit number"
			)
			.required("This field is required"),

		personalMail: Yup.string().trim().email("Invalid email"),

		pincode: Yup.string()
			.matches(/^\d{6}$/, "Please enter valid pincode")
			.required("This field is required"),
		firstName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid first name"
			)
			.required("This field is required"),
		lastName: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid last name"
			)
			.required("This field is required"),
		middleName: Yup.string().matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid middle name"
		),

		city: Yup.string()
			.matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Please enter valid city"
			)
			.required("This field is required"),
		dob: Yup.date().required("This field is required"),

		state: Yup.number().required("This field is required"),
		districtId: Yup.number().required("This field is required"),
	});
	const reducer = produce((state, action) => {
		const checkId = state.productList.find(
			(item) => item === action.payload
		);
		const newArr = state.productList.filter(
			(item) => item !== action.payload
		);
		if (checkId === undefined) {
			state.productList = [...state.productList, action.payload];
		} else {
			state.productList = newArr;
		}
	});
	const [state, dispatch] = useReducer(reducer, { productList: [] });
	// const [districtListState, setDistrict] = useReducer(
	// 	(state, action) => {
	// 		return { ...state, districtList: action.payload };
	// 	},
	// 	{ districtList: [] }
	// );
	const [leaddetailsstate, leadDetailsDIspatch] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const getLeadEditData = () => {
		if (
			(props.type === "edit" || props.type === "preview") &&
			props.leadId.id
		) {
			leadDetailsDIspatch({
				type: Config.FETCH_CONFIG.start,
			});
			createLead
				.getLeadDetails(props.leadId.id)
				.then((res) => {
					const data = convertNullToString(res);
					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.success,
						payload: data,
					});
				})
				.catch((e) => {
					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.error,
						payload: e,
					});
				})
				.finally(() =>
					leadDetailsDIspatch({
						type: Config.FETCH_CONFIG.stop,
					})
				);
		}
	};
	const initialValues =
		props.type === "create"
			? {
					leadType: props?.location?.state?.productType,
					salutation: "",
					firstName: "",
					middleName: "",
					lastName: "",
					mobile: "",
					dob: "",
					personalMail: "",
					city: "",
					state: "",
					districtId: "",
					pincode: "",
					occupationType: "",
					currentInsuranceProvider: "",
					policyExpirationDate: "",
					note: "",
			  }
			: props.type === "edit" || props.type === "preview"
			? {
					leadType: props?.location?.state?.productType,
					...leaddetailsstate.data,

					dob:
						leaddetailsstate.data.dob &&
						leaddetailsstate.data.dob !== ""
							? new Date(leaddetailsstate.data.dob)
							: null,
					policyExpirationDate:
						leaddetailsstate.data.policyExpirationDate &&
						leaddetailsstate.data.policyExpirationDate !== ""
							? new Date(
									leaddetailsstate.data.policyExpirationDate
							  )
							: null,
			  }
			: {};

	// const handleDistrict = (e, setFieldValue) => {
	// 	loanApplicationService
	// 		.getDistrictByStateID(e)
	// 		.then((res) => {
	// 			setDistrict({
	// 				payload: res.map((item) => ({ ...item, value: item.id })),
	// 			});
	// 		})
	// 		.catch(() => {});
	// 	setFieldValue("state", e, true);
	// };
	const getDistrictData = (e) => {
		const stateData = allDistrictData.find((item) => item.id === e);
		if (stateData !== undefined) {
			const districtData = stateData.districtList.map((e) => ({
				...e,
				value: e.id,
			}));
			return districtData;
		} else {
			return [];
		}
	};

	const handleFormSubmit = (data, action) => {
		const { dob, policyExpirationDate, ...rest } = data;
		let tempDate =
			policyExpirationDate && policyExpirationDate !== ""
				? moment(policyExpirationDate).format("YYYY-MM-DD")
				: "";

		const newData = {
			...rest,
			products: state.productList,
			dob: dob && dob !== "" ? moment(dob).format("YYYY-MM-DD") : "",
			policyExpirationDate: tempDate,
		};
		const body = convertObjNull(newData);

		if (props.type === "create" && state.productList.length === 0) {
			return toastTL.current.show({
				severity: "error",
				summary: "Validation Error",
				detail: "No Product selected",
				style: { color: "#000000" },
				life: 3000,
			});
		}
		setLoader(true);
		if (props.type === "create") {
			createLead
				.createLead(body)
				.then((e) => {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});
					action.resetForm();
					setStages({
						type: "id",
						id: e.id,
					});
					setStages({
						type: "stage",
						payload: {
							sequence: 2,
							status: "active",
						},
					});
					setStages({
						type: "stage",
						payload: {
							sequence: 1,
							status: "success",
						},
					});
					setStages({ type: "active", active: 2 });
				})
				.catch((e) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => {
					setLoader(false);
				});
		} else {
			createLead
				.updateLead(body)
				.then((e) => {
					toastTL.current.show({
						severity: "success",
						summary: "Success",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});

					setStages({
						type: "stage",
						payload: {
							sequence: 2,
							status: "active",
						},
					});
					setStages({
						type: "stage",
						payload: {
							sequence: 1,
							status: "success",
						},
					});
					setStages({ type: "active", active: 2 });
				})
				.catch((e) =>
					toastTL.current.show({
						severity: "error",
						summary: "Error",
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					})
				)
				.finally(() => {
					setLoader(false);
					getLeadEditData();
				});
		}
	};
	const handleNext = () => {
		setStages({
			type: "stage",
			payload: {
				sequence: 2,
				status: "active",
			},
		});
		setStages({
			type: "stage",
			payload: {
				sequence: 1,
				status: "success",
			},
		});
		setStages({ type: "active", active: 2 });
	};
	const handleProductChange = (subId) => {
		dispatch({ payload: subId });
	};
	useEffect(() => {
		let ignore = false;
		if (!ignore) {
			getLeadEditData();
		}
		return () => {
			ignore = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="col-12">
			{loader && <Loader />}
			{leaddetailsstate.isLoading && <Loader />}
			<Toast ref={toastTL} position="top-left" />

			<div className="flex align-items-center justify-content-end pb-3">
				<Button
					label="Back"
					icon="pi pi-arrow-left"
					onClick={() => navigate(-1)}
				/>
			</div>
			<Header
				stages={stages.stage}
				title="Lead"
				stageName={props?.location?.state?.productName}
			/>

			{(function (elm) {
				switch (elm) {
					case 1:
						return (
							<div className="card  pt-5 pb-2 px-2 ">
								<Formik
									enableReinitialize
									initialValues={initialValues}
									onSubmit={handleFormSubmit}
									validationSchema={validationSchema}
								>
									{({
										values,
										handleSubmit,
										handleChange,
										resetForm,
									}) => (
										<Form
											className={
												(classNames({
													"form-disable":
														props.type ===
														"preview",
												}),
												"grid")
											}
											onSubmit={handleSubmit}
										>
											<ConnectedFocusError />

											<Panel
												header="Personal Details"
												className="w-full mb-3"
												toggleable
											>
												<div className="grid form-grid">
													<div className="field col-12 md:col-4">
														<div className="grid">
															<div className="field col-12 md:col-3">
																<Field
																	header={
																		"Salutaion"
																	}
																	name={
																		"salutation"
																	}
																	component={
																		FeildDropdown
																	}
																	value={
																		values.salutation
																	}
																	options={
																		Config.SALUTATION
																	}
																/>
															</div>
															<div className="field col-12 md:col-9">
																<Field
																	header={
																		"First Name"
																	}
																	name={
																		"firstName"
																	}
																	component={
																		FieldInput
																	}
																	star={"*"}
																/>
															</div>
														</div>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Middle Name"
															}
															name={"middleName"}
															component={
																FieldInput
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"Last Name"}
															name={"lastName"}
															component={
																FieldInput
															}
															star={"*"}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Date Of Birth"
															}
															name={"dob"}
															star={"*"}
															component={
																FeildCalender
															}
															maxDate={
																new Date(
																	moment()
																		.subtract(
																			18,
																			"year"
																		)
																		.toDate()
																)
															}
															viewDate={
																new Date(
																	moment()
																		.subtract(
																			18,
																			"year"
																		)
																		.toDate()
																)
															}
															// star={"*"}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Mobile Number"
															}
															name={"mobile"}
															component={
																FieldInput
															}
															type={"number"}
															star={"*"}
															disabled={
																props.type ===
																	"edit" ||
																props.type ===
																	"preview"
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"Email"}
															name={
																"personalMail"
															}
															component={
																FieldInput
															}
															type={"email"}
														/>
													</div>
												</div>
											</Panel>
											<Panel
												header="Address"
												className="w-full mb-3"
												toggleable
											>
												<div className="form-grid grid">
													<div className="field col-12 md:col-4">
														<Field
															header={"City"}
															name={"city"}
															component={
																FieldInput
															}
															star={"*"}
														/>
													</div>
													<div className="field col-12 md:col-4">
														{isLoading ? (
															<Skeleton className="w-full flex-1 h-3rem mt-4 " />
														) : (
															<Field
																header={"State"}
																name={"state"}
																filter
																// onChange={(e) => {
																// 	handleDistrict(e.value, setFieldValue);
																// }}
																component={
																	FeildDropdown
																}
																// options={selectState(stateData)}
																options={selectState(
																	allDistrictData
																)}
																optionLabel={
																	"name"
																}
																star={"*"}
																disabled={
																	props.type ===
																		"edit" ||
																	props.type ===
																		"preview"
																}
															/>
														)}
													</div>
													<div className="field col-12 md:col-4">
														{isLoading ? (
															<Skeleton className="w-full flex-1 h-3rem mt-4 " />
														) : (
															<Field
																header={
																	"District"
																}
																name={
																	"districtId"
																}
																filter
																component={
																	FeildDropdown
																}
																// options={districtListState.districtList}
																options={getDistrictData(
																	values.state
																)}
																optionLabel={
																	"name"
																}
																star={"*"}
																disabled={
																	props.type ===
																		"edit" ||
																	props.type ===
																		"preview"
																}
															/>
														)}
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"Pincode"}
															name={"pincode"}
															component={
																FieldInput
															}
															type={"number"}
															star={"*"}
														/>
													</div>
												</div>
											</Panel>
											<Panel
												toggleable
												className="w-full"
												header="Occupation Details"
											>
												<div className="form-grid grid">
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Occupation Type"
															}
															name={
																"occupationType"
															}
															component={
																FeildDropdown
															}
															options={
																Config.OCCUPATION
															}
														/>
													</div>
												</div>
											</Panel>
											<Panel
												toggleable
												className="w-full mt-3"
												header="Insurance Details"
											>
												<div className="form-grid grid">
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Current Insurance Provider"
															}
															name={
																"currentInsuranceProvider"
															}
															component={
																FieldInput
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Policy Expiration Date"
															}
															name={
																"policyExpirationDate"
															}
															component={
																FeildCalender
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"Note"}
															name={"note"}
															component={
																FieldInput
															}
														/>
													</div>
												</div>
											</Panel>
											<Divider />
											{props.type === "create" && (
												<>
													{isSuccess ? (
														<>
															{data.data.map(
																(
																	item,
																	index
																) => (
																	<Fragment
																		key={
																			index
																		}
																	>
																		{item.isActive && (
																			<div className="col-12 lg:col-6">
																				<Panel
																					header={
																						item.title
																					}
																					toggleable
																					collapsed
																				>
																					{item.subProducts.map(
																						(
																							element,
																							j
																						) => (
																							<Fragment
																								key={
																									j.toString() +
																									"keyFor"
																								}
																							>
																								{element.isActive && (
																									<div className=" flex justify-content-between align-items-center mt-0 mb-0">
																										<h5 className="m-0">
																											{
																												element.title
																											}
																										</h5>
																										<InputSwitch
																											name="products[]"
																											checked={
																												state.productList &&
																												state.productList.includes(
																													element.id
																												)
																											}
																											onChange={() =>
																												handleProductChange(
																													element.id
																												)
																											}
																											className="switch_toggle"
																										/>
																									</div>
																								)}
																								{element.isActive &&
																									item
																										.subProducts
																										.length -
																										1 !==
																										j && (
																										<Divider />
																									)}
																							</Fragment>
																						)
																					)}
																				</Panel>
																			</div>
																		)}
																	</Fragment>
																)
															)}
														</>
													) : (
														<div className="col-12 flex justify-content-center align-items-center">
															<ProgressSpinner
																style={{
																	width: "50px",
																	height: "50px",
																}}
																strokeWidth="4"
																animationDuration=".5s"
															/>
														</div>
													)}
												</>
											)}
											<div className="flex justify-content-end align-items-center pt-3 w-full">
												{props.type !== "preview" && (
													<>
														<Button
															type="button"
															label="Reset"
															onClick={() => {
																resetForm();
															}}
															className="danger-btn mr-2"
															icon="pi pi-times"
														/>
														<Button
															type="submit"
															label="Save & Next"
															icon="pi pi-save"
														/>
													</>
												)}
												{props.type !== "create" && (
													<Button
														type="button"
														label="Next"
														className="ml-2"
														onClick={handleNext}
													/>
												)}
											</div>
										</Form>
									)}
								</Formik>
							</div>
						);
					case 2:
						return (
							<DocumentUpload
								leadId={
									props.type === "create"
										? stages.leadId
										: leaddetailsstate.data?.id
								}
								setStages={setStages}
								stages={stages}
								type={props.type}
							/>
						);

					default:
						break;
				}
			})(stages.active)}
		</div>
	);
};

export default SubmitNewInsuranceLeadDialog;
