import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import React, { useReducer, useRef } from "react";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import { CallCenterService } from "../../../services/callCenterService";
import {
	searchInitialState,
	searchReducer,
} from "../../../components/searchReducer";
import Config from "../../../shared/config";
import { useEffect } from "react";
import { DataTable } from "primereact/datatable";
import Paginator from "../../../components/Paginator";
import ActivitySearch from "./components/ActivitySearch";
import moment from "moment";
import TableLoader from "../../../components/TableLoader";
// import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";

const UserActivity = () => {
	const toastTL = useRef(null);
	// const userData = useSelector((state) => state.persistData.auth.user.role);
	const [members, setMembers] = useReducer(dataReducer, fetchInitialState);
	const callcenterService = new CallCenterService();
	// const skeletonBody = () => {
	// 	return <Skeleton height="1rem"></Skeleton>;
	// };
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	// const [tsrmlist, setTsrmlist] = useState([]);
	// const ccservice = new CallCenterService();

	// useEffect(() => {
	// 	ccservice
	// 		.getTsrmByHubIdspokeId({
	// 			pageNo: 1,
	// 			pageSize: 1000000,
	// 			hubId: null,
	// 			spokeId: null,
	// 		})
	// 		.then((res) => setTsrmlist(res?.data));
	// }, []);
	const userDataTableValue = () => {
		const requestBody = {
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.searchValue !== "" && searchState.searchValue),
			...(searchState.sortByColumn && searchState.sortType
				? {
						sorts: [
							searchState.sortByColumn +
								"," +
								searchState.sortType,
						],
				  }
				: { sorts: [] }),
		};

		// if (searchValue !== "" && operation) {
		// 	requestBody.searchRequest = ;
		// }
		// if (searchState.sortByColumn && searchState.sortType) {
		// 	requestBody.sorts = [
		// 		,
		// 	];
		// }
		setMembers({ type: Config.FETCH_CONFIG.start });
		callcenterService
			.callCenterUserActivityList(requestBody)
			.then((res) => {
				setMembers({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setMembers({ type: Config.FETCH_CONFIG.stop });
				setMembers({ type: Config.FETCH_CONFIG.fetched });
			});
	};

	useEffect(() => {
		userDataTableValue();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);
	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	function tblSort(columnName, sortType) {
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}

	const increment = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const pagesizechange = (e) => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header={getSortableColumn("Page", "parentEnum")} />
				<Column header={getSortableColumn("Topic", "childEnum")} />
				<Column
					header={getSortableColumn("Description", "description")}
				/>
				<Column header={getSortableColumn("Created On", "createdOn")} />
				<Column header={getSortableColumn("Created By", "createdBy")} />
			</Row>
		</ColumnGroup>
	);
	const itaration = {
		a: "",
		b: "",
		c: "",
		d: "",
	};
	return (
		<>
			<ActivitySearch
				searchDispatch={searchDispatch}
				// tsrmlist={tsrmlist}
				newData={members?.data?.data}
			/>
			<div className="grid">
				{members.isLoading ? (
					<div className="col-12">
						<TableLoader itaration={itaration} />
					</div>
				) : (
					<div className="col-12">
						{members.data?.data?.length > 20 && (
							<Paginator
								pageSize={searchState.pageSize}
								firstPage={members.data.firstPage}
								lastPage={members.data.lastPage}
								decrement={decrement}
								increment={increment}
								pagesizechange={pagesizechange}
								pageNo={members.data.pageNo}
								totalPages={members.data.totalPages}
								totalElements={members.data.totalElements}
							/>
						)}
						{members?.isFetched && (
							<DataTable
								headerColumnGroup={headerGroup}
								value={members.data?.data}
								responsiveLayout="scroll"
								breakpoint="960px"
							>
								<Column
									body={(elm) => (
										<span
											style={{
												textTransform: "capitalize",
											}}
										>
											{elm.parentEnum?.replaceAll(
												"_",
												" "
											)}
										</span>
									)}
								/>
								<Column
									body={(elm) =>
										elm.childEnum?.replaceAll("_", " ")
									}
								/>
								<Column
									body={(elm) => (
										<>
											<div className="c-user-activity-info">
												<span>{elm.description}</span>
												<Button
													className="p-button-text p-0 m-0"
													icon="pi pi-info-circle"
													tooltip={elm.description}
													tooltipOptions={{
														position: "left",
													}}
												/>
											</div>
										</>
									)}
								/>
								<Column
									body={(elm) =>
										elm?.createdOn
											? moment(elm.createdOn).format(
													"Do MMM,YYYY HH:mm A"
											  )
											: ""
									}
								/>
								<Column
									body={(item) =>
										(item?.fullName ? item?.fullName : "") +
										"(" +
										(item?.createdBy
											? item?.createdBy
											: "") +
										")"
									}
								/>
							</DataTable>
						)}

						{members.data?.data?.length > 0 && (
							<Paginator
								pageSize={searchState.pageSize}
								firstPage={members.data.firstPage}
								lastPage={members.data.lastPage}
								decrement={decrement}
								increment={increment}
								pagesizechange={pagesizechange}
								pageNo={members.data.pageNo}
								totalPages={members.data.totalPages}
								totalElements={members.data.totalElements}
							/>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default UserActivity;
