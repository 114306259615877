import React, { useReducer, useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
// import { AiOutlineFileAdd, AiOutlineEdit } from "react-icons/ai";

// import { Menu } from "primereact/menu";

// import { CgMail } from "react-icons/cg";
import { Dialog } from "primereact/dialog";
// import { BsFillCursorFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { Field, Form, Formik } from "formik";
import { ConfirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { confirmDialog } from "primereact/confirmdialog"; // To use confirmDialog method
import { useDispatch, useSelector } from "react-redux";
import {
	setLeadId,
	setAlias,
	setLeadMobileNumber,
	setLeadprogramId,
	setname,
	setSubproductId,
	setPin,
	setType,
	setLeadPanNo,
} from "../../../../store/reducer/LeadReducer";
import { LeadService } from "../../../../services/LeadService";
import { ProgramService } from "../../../../services/ProgramService";
import TableLoader from "../../../../components/TableLoader";
import TableNoData from "../../../../components/TableNoData";
import Paginator from "../../../../components/Paginator";
import {
	FeildDropdown,
	FieldInput,
	FieldTextArea,
} from "../../../../components/FieldInput";
import {
	searchInitialState,
	searchReducer,
} from "../../../../components/searchReducer";
import Config from "../../../../shared/config";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import TimelineStatus from "../TimelineStatus";
import SearchToggleContent from "./SearchToggleContent";
import StatusUpdate from "./StatusUpdate";
import moment from "moment";
import BulkUpload from "./BulkUplod/BulkUpload";
import {
	setApplicationId,
	setApplicationState,
	setTypeName,
} from "../../../../store/reducer/AuthReducer";
import { exportExcel } from "../../../../components/XlsxExport";
import MenuComponent from "../../../../components/MenuComponent";
import { SplitButton } from "primereact/splitbutton";
import Loader from "../../../../components/Loader";
import { CampaignService } from "../../../../services/CampaignService";
import BulkStatusUpload from "./bulkstatusupload/BulkStatusUpload";
import { CallCenterService } from "../../../../services/callCenterService";
import AllocateAgentForm from "../../agents/componentAllocation/AllocateAgentForm";
import { Dropdown } from "primereact/dropdown";
const LeadsTable = (props) => {
	const leadServices = new LeadService();
	const ccService = new CallCenterService();
	const programService = new ProgramService();
	const campaignService = new CampaignService();
	const [leadDialog, setLeadDialog] = useState(false);
	const navigate = useNavigate();
	const leadDispatch = useDispatch();
	const position = useSelector(
		(state) => state.persistData.auth.user.role.position
	);
	const [response, setResponse] = useReducer(dataReducer, fetchInitialState);
	const [noteDetailDialog, setNoteDetailDialog] = useState({
		state: false,
		id: null,
		note: "",
	});
	const [programData, setProgramData] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const [callDialog, setCallDialog] = useState({
		state: false,
		id: null,
		index: null,

		rowData: null,
	});
	const [profileDetailDialog, setProfileDetailDialog] = useState({
		state: false,
		data: null,
	});
	const [selectedProducts, setSelectedProducts] = useState([]);
	const toastTL = useRef(null);
	const [toggle, setToggle] = useState(false);
	const [lob, setLob] = useState("");

	const [dialog, setDialog] = useState(false);
	const [expandedRows, setExpandedRows] = useState(null);
	const [bulkDialoge, setBulkDialogue] = useState(false);
	const [bulkStatus, setBulkStatus] = useState(false);
	const [loading, setLoading] = useState(false);
	const [assignLead, setAssignLead] = useState({
		state: false,
		id: null,
	});
	const [allOEList, setAllOEList] = useState([]);
	const [timelineStatusDialoge, setTimelineStatusDialoge] = useReducer(
		(state, action) => {
			return {
				...state,
				dialogOn: action.status,
				dialogType: action.data,
				leadId: action.id,
			};
		},
		{ dialogOn: false, dialogType: "", leadId: null }
	);

	const selectReducer = (state, action) => {
		return { ...state, selectionData: action.payload };
	};

	const [leadDataState, leadDataStateDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);

	const [selectState, selectDispatch] = useReducer(selectReducer, {
		selectionData: {},
	});
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const [timeLineState, timeLineDispatch] = useReducer(
		dataReducer,
		fetchInitialState
	);

	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	function tblSort(columnName, sortType) {
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}

	const nameTemplate = ({ firstName, middleName, lastName }) => {
		if (middleName === null) {
			middleName = "";
		}
		if (lastName === null) {
			lastName = "";
		}
		if (firstName === null) {
			firstName = "";
		}
		return firstName + " " + middleName + " " + lastName;
	};
	const createdByTemplate = ({
		createdByFirstName,
		createdByLastName,
		createdByUserName,
		agentCode,
	}) => {
		if (createdByFirstName === null) {
			createdByFirstName = "";
		}
		if (createdByLastName === null) {
			createdByLastName = "";
		}

		let modAgentCode =
			agentCode !== null
				? agentCode
				: createdByUserName !== null
				? createdByUserName
				: "";
		// if (createdByUserName === null) {
		// 	createdByUserName = "";
		// }
		// if (agentCode === null) {
		// 	agentCode = "";
		// }
		return `${createdByFirstName} ${createdByLastName} (${modAgentCode})`;
	};
	const updateTemplate = ({ updatedAt }) => {
		return updatedAt
			? moment(updatedAt).format("DD MMM,YYYY HH:mm")
			: updatedAt;
	};
	const dateTemplate = ({ createdAt }) => {
		return createdAt
			? moment(createdAt).format("DD MMM,YYYY HH:mm")
			: createdAt;
	};
	const getRecevedList = () => {
		leadDataStateDispatch({ type: Config.FETCH_CONFIG.start });
		const body = {
			...searchState.searchValue,
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(props.campaignId && { campaignId: Number(props.campaignId) }),
			...(searchState.sortByColumn &&
				searchState.sortType && {
					sorts: [
						searchState.sortByColumn + "," + searchState.sortType,
					],
				}),
		};

		// if (searchState.sortByColumn && searchState.sortType) {
		// 	body.sorts = [
		// 		searchState.sortByColumn + "," + searchState.sortType,
		// 	];
		// }
		const CleanObject = (body) => {
			for (let propName in body) {
				if (body[propName] === "" || body[propName] === null) {
					delete body[propName];
				}
			}
			return body;
		};
		// if (Object.keys(data).length > 0) {
		// 	body.leadFilter = data;
		// }

		ccService
			.getVendorLeadList(CleanObject(body))
			.then((res) => {
				leadDataStateDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
				leadDataStateDispatch({
					type: Config.FETCH_CONFIG.error,
					payload: e,
				});
			})
			.finally(() =>
				leadDataStateDispatch({
					type: Config.FETCH_CONFIG.stop,
				})
			);
	};
	const handleNoteSubmit = (val) => {
		setLoading(true);
		ccService
			.saveLeadNote({
				leadId: noteDetailDialog.id,
				notes: val.notes,
			})
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});

				getRecevedList();
				setNoteDetailDialog((prev) => ({
					...prev,
					state: false,
					id: null,
				}));
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoading(false));
	};
	const confirm = () => {
		confirmDialog({
			message: () => {
				return (
					<>
						<span>Want to apply this Program</span>
						<p className="font-bold m-0">
							{selectState.selectionData.name} ?
						</p>
					</>
				);
			},

			header: "Confirmation",
			icon: "pi pi-exclamation-triangle",
			accept: () => {
				leadDispatch(
					setLeadprogramId(selectState.selectionData.programId)
				);
				leadDispatch(setname("lead"));
				leadDispatch(setAlias(selectState.selectionData.alias));
				navigate("/vendor/application/create");
			},
			reject: () => {},
		});
	};

	const rowExpansionTemplate = (data) => {
		return (
			<div className="w-full">
				<DataTable
					value={data?.programListDtoList}
					responsiveLayout="scroll"
					onSelectionChange={(e) =>
						selectDispatch({ payload: e.value })
					}
					selection={selectState.selectionData}
					selectionMode="radio"
				>
					<Column
						selectionMode="single"
						headerStyle={{ width: "3rem" }}
					/>
					<Column field="name" header="Name" />
					<Column field="minLoanAmount" header="Min Loan Amount" />

					<Column field="maxLoanAmount" header="Max Loan Amount" />
					<Column
						field="minProcessingFees"
						header="Min Processing Fees"
					/>
					<Column
						field="maxProcessingFees"
						header="Max Processing Fees"
					/>
					<Column
						field="minRateOfInterest"
						header="Min Rate Of Interest"
					/>
					<Column
						field="maxRateOfInterest"
						header="Max Rate Of Interest"
					/>
					<Column field="minLoanPeriod" header="Min Loan Period" />
					<Column field="maxLoanPeriod" header="Max Loan Period" />
				</DataTable>
			</div>
		);
	};
	const allowExpansion = (rowData) => {
		return rowData.programListDtoList.length > 0;
	};
	// const cancelButton = () => {};
	useEffect(() => {
		// if (props.leadFilter) {
		// 	getRecevedList(props.leadFilter);
		// } else {
		getRecevedList();
		//}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);
	useEffect(() => {
		ccService.allOEList().then((res) => {
			const data = res.map((item) => ({
				...item,
				name:
					(item.firstName ? item.firstName : "") +
					" " +
					(item.middleName ? item.middleName : "") +
					" " +
					(item.lastName ? item.lastName : ""),
			}));

			setAllOEList(data);
		});
	}, []);
	const increment = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const pagesizechange = (e) => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};

	const handleSearch = (val, action) => {
		setLoading(true);
		const body = {
			leadId: profileDetailDialog?.data,
			userName: val.userName,
		};
		leadServices
			.leadAllocates(body)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});

				action.resetForm();
				getRecevedList();
				setProfileDetailDialog((prev) => ({
					...prev,
					state: false,
					id: null,
				}));
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoading(false));
	};

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column
					selectionMode={"multiple"}
					headerStyle={{ width: "3rem" }}
				/>
				{/* <Column header="#" /> */}
				<Column header={getSortableColumn("Lead No.", "number")} />
				<Column header={"Interested In"} />
				<Column header={"Mobile"} />
				<Column
					header={getSortableColumn(
						"Zone",
						"districtId.stateId.zoneId.name"
					)}
				/>
				<Column
					header={getSortableColumn(
						"State",
						"districtId.stateId.name"
					)}
				/>
				<Column
					header={getSortableColumn("District", "districtId.name")}
				/>
				{/* <Column header={getSortableColumn("City", "city")} /> */}
				<Column header={getSortableColumn("Name", "firstName")} />
				<Column header={getSortableColumn("Created On", "createdAt")} />
				<Column header={getSortableColumn("Created By", "createdBy")} />
				{/* <Column header={getSortableColumn("Updated On", "updatedAt")} /> */}
				<Column header={getSortableColumn("Updated On", "updatedAt")} />

				<Column header={"Status"} />
				{/* <Column header={"applicationStatus"} /> */}
				{/* <Column
					header={getSortableColumn("Allocated To", "allocatedTo")}
				/> */}
				<Column header={"Allocated To"} />
				{/* <Column header={"OE Username"} /> */}
				<Column header={"Note"} />
				{/* <Column header={"Agent Code"} /> */}
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const itaration = {
		title: "",
		fg: "",
		firstName: "",
		hi: "",
		mobilenumber: "",
		city: "",
		state: "",
		district: "",
		zone: "",
		status: "",
		isAllocated: "",
		allocatedTo: "",
		action: "",
		action2: "",
		action3: "",
		// action4: "",
		// action5: "",
		// dd: "",
	};

	const handleProgramDialog = (item) => {
		// con
		// leadDispatch(setLeadMobileNumber(item.mobileNumber));
		// leadDispatch(setSubproductId(item.productId));
		// leadDispatch(setLeadId(item.id));
		// leadDispatch(setPin(item.pincode));
		// leadDispatch(setType("otp"));

		// setDialog(true);

		// leadDispatch(setTypeName("lead"));
		// leadDispatch(setname("lead"));
		// leadDispatch(setAlias(item?.alias));

		// leadDispatch(setLeadPanNo(item?.pan));

		// leadDispatch(setLeadMobileNumber(value.mobileNumber));
		// leadDispatch(setType("credit"));
		// leadDispatch(setDistrictDetails(value));
		// setProgramData({ type: Config.FETCH_CONFIG.start });
		// programService
		// 	.getInstitionWiseList({ subProductId: item.productId })
		// 	.then((res) => {
		// 		setProgramData({
		// 			type: Config.FETCH_CONFIG.success,
		// 			payload: res,
		// 		});
		// 	})
		// 	.catch((e) =>
		// 		setProgramData({ type: Config.FETCH_CONFIG.error, payload: e })
		// 	)
		// 	// .finally(() => setLoader(false));
		// 	.finally(() => setProgramData({ type: Config.FETCH_CONFIG.stop }));
		// navigate("/vendor/application-create");
		leadDispatch(setTypeName("lead"));
		leadDispatch(setApplicationId(null));
		leadDispatch(setApplicationState("edit"));
		navigate("/vendor/application/create", {
			state: {
				mobile: item.mobileNumber,
				pan: item?.pan,
				leadId: item.id,
				subproductId: item.productId,
				alias: item?.alias,
				applicationId: null,
				stateId: item?.stateId,
				districtId: item?.districtId,
				applicationName: item?.productTitle,
			},
		});
	};

	const handleTimelineStatus = (item) => {
		timeLineDispatch({ type: Config.FETCH_CONFIG.start });
		programService
			.timelineStatus(item)
			.then((res) => {
				const arr = [...res];
				timeLineDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: arr.map((item) =>
						item.title === "CALL"
							? { ...item, icon: "pi pi-phone", color: "#5A96E3" }
							: item.title === "WHATSAPP"
							? {
									...item,
									icon: "pi pi-whatsapp",
									color: "#128C7E",
							  }
							: { ...item, icon: "pi pi-info", color: "#333" }
					),
				});
				timeLineDispatch({ type: Config.FETCH_CONFIG.fetched });
			})
			.catch((e) =>
				timeLineDispatch({
					type: Config.FETCH_CONFIG.error,
					payload: e,
				})
			)
			.finally(() =>
				timeLineDispatch({ type: Config.FETCH_CONFIG.stop })
			);
	};

	const onHideDialog = () => {
		setTimelineStatusDialoge({ status: false, data: "", id: null });
		timeLineDispatch({
			type: Config.FETCH_CONFIG.ini,
		});
	};
	const onOpenDialog = (e, value) => {
		setTimelineStatusDialoge({
			status: true,
			data: e,
			id: value,
		});
	};

	const actionTemplate = (item) => {
		const items1 = [
			{
				label: "Edit",
				icon: "pi pi-pencil",
				command: () => {
					const type =
						item?.productLineOfBusiness === "Insurance"
							? Config.PRODUCT_LEAD_TYPE.INSURANCE
							: Config.PRODUCT_LEAD_TYPE.LOAN;
					navigate(`/vendor/lead/edit-lead/${item.id}`, {
						state: {
							lob: item?.productLineOfBusiness,
							productType: type,
							productName: item?.productTitle,
						},
					});
				},
			},
			{
				label: "Timeline",
				icon: "pi pi-cloud-upload",
				command: () => {
					onOpenDialog("Timeline");
					handleTimelineStatus(item.id);
				},
			},
			{
				label: "Status Update",
				icon: "pi pi-check",
				command: () => {
					onOpenDialog("Update", item.id);
				},
			},
			// {
			// 	label: "Create Applications",
			// 	icon: "pi pi-plus",
			// 	command: () => {
			// 		handleProgramDialog(item);
			// 	},
			// },
			{
				label: "Show Application",
				icon: "pi pi-list",
				command: () => {
					navigate(`/vendor/application-list-by-leadId/${item.id}`);
				},
				// command: () => {
				// 	handleProgramDialog(item);
				// },
			},
			{
				label: "Preview",
				icon: "pi pi-info-circle",
				command: () => {
					const type =
						item?.productLineOfBusiness === "Insurance"
							? Config.PRODUCT_LEAD_TYPE.INSURANCE
							: Config.PRODUCT_LEAD_TYPE.LOAN;
					navigate(`/vendor/lead/preview-lead/${item.id}`, {
						state: {
							lob: item?.productLineOfBusiness,
							productType: type,
							productName: item?.productTitle,
						},
					});
				},
			},
			{
				label: "Allocate TSRM",
				icon: "pi pi-sitemap",
				// command: () => {
				// 	navigate("/vendor/lead/new-lead");
				// },
				command: () => {
					setProfileDetailDialog((prev) => ({
						...prev,
						state: true,
						data: [item.id],
					}));
				},
			},
		];
		// if (position === 1000 || position === 1020 || position === 1030) {
		// 	items1.push({
		// 		label: "Assign OE",
		// 		icon: "pi pi-tag",
		// 		command: () => {
		// 			setAssignLead((prev) => ({
		// 				...prev,
		// 				id: item.id,
		// 				state: true,
		// 			}));
		// 		},
		// 	});
		// }
		const item2 = [
			{
				label: "Preview",
				icon: "pi pi-info-circle",
				command: () => {
					const type =
						item?.productLineOfBusiness === "Insurance"
							? Config.PRODUCT_LEAD_TYPE.INSURANCE
							: Config.PRODUCT_LEAD_TYPE.LOAN;
					navigate(`/vendor/lead/preview-lead/${item.id}`, {
						state: {
							lob: item?.productLineOfBusiness,
							productType: type,
							productName: item?.productTitle,
						},
					});
				},
			},
		];
		if (position !== null) {
			items1.push({
				label: "Create Applications",
				icon: "pi pi-plus",
				command: () => {
					handleProgramDialog(item);
				},
			});
		}
		return (
			<div className="flex gap-2">
				<Button
					icon={"pi pi-phone"}
					tooltip="Call"
					tooltipOptions={{ position: "left" }}
					className="icon-btn p-button-text"
					onClick={() => {
						setCallDialog((prev) => ({
							...prev,
							state: true,
							id: item.id,
						}));
					}}
				/>
				<MenuComponent items={props.type === "all" ? items1 : item2} />
			</div>
		);
	};
	const statusTemplate = (item) => {
		switch (item.status) {
			case "FOLLOW_UP":
				return <span className="status status-warning">Follow Up</span>;

			case "NOT_INTERESTED":
				return (
					<span className="status status-danger">Not Interested</span>
				);

			case "NEW":
				return <span className="status status-primary">New</span>;

			case "NOT_ELIGIBLE":
				return (
					<span className="status status-danger-deep">
						Not Eligible
					</span>
				);

			case "CONVERTED":
				return <span className="status status-success">Converted</span>;
			case "INVALID":
				return (
					<span className="status bg-red-400 text-red-50">
						Invalid
					</span>
				);

			default:
				return "";
		}
	};

	const isAllocatedTemplate = (item) => {
		return item.isAllocated ? (
			<span className="text-seccess bg-green-200 text-xs font-bold w-3rem py-1 px-5 border-round-sm">
				{/* <MdToggleOn /> */}
				Yes
			</span>
		) : (
			<span className="text-danger text-xs font-bold bg-red-200 w-3rem py-1 px-5 border-round-sm">
				{/* <MdToggleOff /> */}
				No
			</span>
		);
	};
	const allocatedTamplate = (item) => {
		let fname = item.allocatedToFirstName ? item.allocatedToFirstName : "";
		let mname = item.allocatedToMiddleName
			? item.allocatedToMiddleName
			: "";
		let lname = item.allocatedToLastName ? item.allocatedToLastName : "";
		let userName = item.allocatedToAgentCode
			? `(${item.allocatedToAgentCode})`
			: `${item.allocatedTo ? `(${item.allocatedTo})` : ""}`;
		let name = `${fname} ${mname} ${lname} ${userName}`;

		return name;
	};
	const newExcellData =
		leadDataState.data.data &&
		leadDataState.data.data.map((item) => ({
			"Product Name": item.productTitle,
			"Line Of Business": item.productLineOfBusiness,

			Name:
				(item.firstName ? item.firstName : "") +
				" " +
				(item.middleName ? item.middleName : "") +
				" " +
				(item.lastName ? item.lastName : ""),

			Status: item.status.replaceAll("_", " ").toLowerCase(),
			"Mobile Number": item.mobileNumber,
			Zone: item.zoneName,
			State: item.stateName,
			District: item.districtName,
			City: item.city,

			"Allocated To": allocatedTamplate(item),
			"Agent Code": item.agentCode,
		}));
	// const exportPdf = () => {
	// 	import("jspdf").then((jsPDF) => {
	// 		import("jspdf-autotable").then(() => {
	// 			const doc = new jsPDF.default(0, 0);

	// 			doc.autoTable(newExcellData, newExcellData);
	// 			doc.save(`applicationList${Date.now()}.pdf`);
	// 		});
	// 	});
	// };

	const setiItems = () => {
		const items = [
			{
				label: "Allocate TSRM",
				icon: "pi pi-sitemap",
				// command: () => {
				// 	navigate("/vendor/lead/new-lead");
				// },
				command: () => {
					setProfileDetailDialog((prev) => ({
						...prev,
						state: true,
						data: selectedProducts.map((item) => item.id),
					}));
				},
			},
			// {
			// 	label: "Export Lead",
			// 	icon: "pi pi-file-excel",
			// 	command: () => {
			// 		exportExcel(newExcellData, "Leadlist");
			// 	},
			// },
			// {
			// 	label: "Export All Lead",
			// 	icon: "pi pi-file-excel",
			// 	command: () => {
			// 		getAllLoanData();
			// 	},
			// },

			// {
			// 	label: "Upload Leads in Bulk",
			// 	icon: "pi pi-upload",
			// 	command: () => {
			// 		setBulkDialogue(true);
			// 	},
			// },
		];
		// if (position === null || position === 5) {
		// 	items.push({
		// 		label: "Update bulk lead status",
		// 		icon: "pi pi-upload",
		// 		command: () => {
		// 			setBulkStatus(true);
		// 		},
		// 	});
		// }
		return items;
	};

	const getAllLoanData = () => {
		setLoading(true);
		const body = {
			pageNo: 1,
			pageSize: 100000,
		};

		// if (searchState.sortByColumn && searchState.sortType) {
		// 	body.sorts = [
		// 		searchState.sortByColumn + "," + searchState.sortType,
		// 	];
		// }
		const CleanObject = (body) => {
			for (let propName in body) {
				if (body[propName] === "" || body[propName] === null) {
					delete body[propName];
				}
			}
			return body;
		};
		// if (Object.keys(data).length > 0) {
		// 	body.leadFilter = data;
		// }

		(props.type === "own"
			? leadServices.getSubmittedLead(CleanObject(body))
			: props.type === "all" &&
			  leadServices.getRecivedLead(CleanObject(body))
		)
			.then((res) => {
				let allExcelData = res.data.map((item) => ({
					ID: 2308,
					"Product Title": item.productId?.title,
					Name:
						(item.firstName ? item.firstName : "") +
						" " +
						(item.middleName ? item.middleName : "") +
						" " +
						(item.lastName ? item.lastName : ""),

					"Mobile Number": item.mobileNumber,
					Status: item.status,
					"Created By": item.createdBy,
					"Created At": item.createdAt,
					"Updated At": item.updatedAt,
					Zone: item.zoneName,
					State: item.stateName,
					District: item.districtName,
					City: item.city,
					"District Name": item.districtName,
					"Is Allocated": item.isAllocated,
					"Allocated To": allocatedTamplate(item),
					"Agent Code": item.agentCode,
				}));

				exportExcel(allExcelData, "FullLeadlist");
				setLoading(false);
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
				setLoading(false);
			});
	};

	const handleStatusDialogeClose = () => {
		setBulkDialogue(false);
	};
	const handleOe = (val) => {
		setLoading(true);
		ccService
			.assignLeadToOE({
				leadId: assignLead.id,
				oeUserName: val.oeUserName,
			})
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});
				getRecevedList();
				setAssignLead((prev) => ({ ...prev, id: null, state: false }));
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => setLoading(false));
	};
	const acceptCall = () => {
		setLoading(true);
		ccService
			.sendLeadCall(callDialog.id)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: res?.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setLoading(false);
				setCallDialog((elm) => ({ ...elm, state: false }));
			});
	};
	const rejectCall = () => {
		setCallDialog((e) => ({
			...e,
			state: false,
			id: null,
		}));
	};
	// const createdByTemplate = (item) => {
	// 	let fname = item.createdByFirstName ? item.createdByFirstName : "";
	// 	let mname = item.createdByMiddleName ? item.createdByMiddleName : "";
	// 	let lname = item.createdByLastName ? item.createdByLastName : "";
	// 	// const userName = item.createdBy ? `(${item.createdBy})` : "";

	// 	const userName =
	// 		item.agentCode !== null
	// 			? item.agentCode
	// 				? `(${item.agentCode})`
	// 				: ""
	// 			: item.createdBy
	// 			? `(${item.createdBy})`
	// 			: "";

	// 	let name = `${fname} ${mname} ${lname} ${userName}`;
	// 	return name;
	// };
	return (
		<>
			<Dialog
				header={"Select Product Type"}
				visible={leadDialog}
				style={{ width: "30%" }}
				onHide={() => setLeadDialog(false)}
			>
				<Dropdown
					value={lob}
					onChange={(e) => setLob(e.target.value)}
					className="w-full border-round"
					options={[
						{
							label: "Insurance",
							value: "Insurance",
						},
						{
							label: "Secured Liability Products",
							value: "Secured Liability Products",
						},
						{
							label: "Unsecured Liability Products",
							value: "Unsecured Liability Products",
						},
					]}
				/>
				<Button
					className="w-full mt-2"
					label="Create"
					onClick={() => {
						const type =
							lob === "Insurance"
								? Config.PRODUCT_LEAD_TYPE.INSURANCE
								: Config.PRODUCT_LEAD_TYPE.LOAN;
						navigate("/vendor/lead/new-lead", {
							state: {
								lob,
								productType: type,
								productName: "",
							},
						});
					}}
					disabled={lob ? false : true}
				/>
			</Dialog>
			{loading && <Loader />}
			<Toast ref={toastTL} position="top-left" />
			<>
				<div className="w-full pb-3 flex flex-wrap justify-content-between align-items-center ">
					<h3>Lead</h3>

					<div className="flex gap-2">
						<Button
							icon={toggle ? "pi pi-times" : "pi pi-search"}
							className={
								toggle
									? "p-button-danger p-button-outlined"
									: "p-button-outlined"
							}
							label={toggle ? "Close" : "Search"}
							onClick={() => setToggle((prev) => !prev)}
						/>
						<Button
							label="Reload"
							icon={"pi pi-refresh "}
							className=" p-button-outlined"
							onClick={() =>
								searchDispatch({
									type: Config.SEARCH_CONFIG.reset,
								})
							}
						/>
						{position !== null && (
							<Button
								label="Create Lead"
								icon={"pi pi-plus "}
								className=" p-button-outlined"
								onClick={() => setLeadDialog(true)}
							/>
						)}
						{/* {(position === null || position === 5) && (
							<>
								{props.type !== "campaign" && (
									<Button
										type="button"
										icon="pi pi-upload"
										className="p-button-danger  "
										rounded
										label="Update bulk lead status"
										onClick={() => setBulkStatus(true)}
										tooltip="Update bulk lead status"
										tooltipOptions={{ position: "bottom" }}
									/>
								)}
							</>
						)}
						{props.type !== "campaign" && (
							<Button
								label="Upload Leads in Bulk"
								icon={"pi pi-upload "}
								className=""
								onClick={() => setBulkDialogue(true)}
							/>
						)}
						{props.type !== "campaign" && (
							<Button
								label="Create Lead"
								icon={"pi pi-plus"}
								className=""
								onClick={() =>
									navigate("/vendor/lead/new-lead")
								}
							/>
						)} */}
						{selectedProducts.length > 0 && (
							<SplitButton
								icon={"pi pi-bars"}
								label="Action"
								model={setiItems()}
								// loading={spinner}
							/>
						)}

						{/* <Button
							type="button"
							icon="pi pi-file-excel"
							className="p-button-success "
							onClick={() =>
								exportExcel(newExcellData, "Leadlist")
							}
							tooltip="Export Excel"
							tooltipOptions={{ position: "left" }}
						/> */}
					</div>
				</div>
				<div className="w-full">
					{toggle && <SearchToggleContent search={searchDispatch} />}
				</div>
			</>
			<div className="grid">
				{leadDataState.isLoading ? (
					<div className="col-12">
						<TableLoader
							headerGroup={headerGroup}
							itaration={itaration}
						/>
					</div>
				) : leadDataState.data && !leadDataState.isLoading ? (
					<div className="col-12">
						{leadDataState.data?.data?.length > 0 && (
							<Paginator
								pageSize={leadDataState.data.pageSize}
								firstPage={leadDataState.data.firstPage}
								lastPage={leadDataState.data.lastPage}
								decrement={decrement}
								increment={increment}
								pagesizechange={pagesizechange}
								pageNo={leadDataState.data.pageNo}
								totalPages={leadDataState.data.totalPages}
								totalElements={leadDataState.data.totalElements}
							/>
						)}
						<DataTable
							headerColumnGroup={headerGroup}
							value={
								leadDataState.data.data &&
								leadDataState.data.data.map((item, index) => ({
									...item,
									index:
										leadDataState.data.pageSize *
											leadDataState.data.pageNo -
										leadDataState.data.pageSize +
										1 +
										index,
								}))
							}
							responsiveLayout="scroll"
							breakpoint="960px"
							selection={selectedProducts}
							selectionMode="checkbox"
							onSelectionChange={(e) =>
								setSelectedProducts(e.value)
							}
						>
							{/* <Column field="index" /> */}
							<Column
								header={"Select"}
								selectionMode={"multiple"}
								headerStyle={{ width: "3rem" }}
							/>
							<Column field="number" />
							<Column field="productTitle" />
							<Column field="mobileNumber" />
							<Column field={"zoneName"} />
							<Column field={"stateName"} />
							<Column field={"districtName"} />
							{/* <Column field="city" /> */}
							<Column body={nameTemplate} />
							<Column body={dateTemplate} />
							<Column body={createdByTemplate} />
							<Column body={updateTemplate} />
							<Column field="status" body={statusTemplate} />
							{/* <Column
								body={(item) =>
									item?.applicationStatus ? (
										<span className="status py-1 status-success">
											{item?.applicationStatus?.replaceAll(
												"_",
												" "
											)}
										</span>
									) : (
										<></>
									)
								}
							/> */}
							<Column body={allocatedTamplate} />

							<Column
								body={(item) => (
									<Button
										onClick={() =>
											setNoteDetailDialog((prev) => ({
												...prev,
												state: true,
												id: [item.id],
											}))
										}
										className={`icon-btn p-button-text ${
											item?.notes
												? "p-button-success"
												: "p-button-danger"
										}`}
										icon={"pi pi-info-circle"}
										tooltip={
											item?.notes
												? item?.notes
												: "No Note"
										}
										tooltipOptions={{
											position: "left",
										}}
									/>
								)}
							/>
							<Column body={actionTemplate} />
						</DataTable>

						{leadDataState.data?.data?.length > 0 && (
							<Paginator
								pageSize={leadDataState.data.pageSize}
								firstPage={leadDataState.data.firstPage}
								lastPage={leadDataState.data.lastPage}
								decrement={decrement}
								increment={increment}
								pagesizechange={pagesizechange}
								pageNo={leadDataState.data.pageNo}
								totalPages={leadDataState.data.totalPages}
								totalElements={leadDataState.data.totalElements}
							/>
						)}
					</div>
				) : (
					leadDataState?.data &&
					leadDataState?.data?.data === 0 && (
						<div className="col-12">
							<TableNoData headerGroup={headerGroup} />
						</div>
					)
				)}
				<Dialog
					header={"Note"}
					style={{ width: "40%" }}
					visible={noteDetailDialog.state}
					onHide={() =>
						setNoteDetailDialog((prev) => ({
							...prev,
							state: false,

							id: null,
						}))
					}
				>
					<Formik
						initialValues={{ notes: noteDetailDialog.note }}
						onSubmit={handleNoteSubmit}
					>
						{({ handleSubmit, resetForm }) => (
							<Form
								className="modal-form-grid border-round-md col-12 mt-2 p-4 form-grid grid"
								onSubmit={handleSubmit}
							>
								<div className="field col-12 ">
									<Field
										required
										header={"Note"}
										name={"notes"}
										component={FieldTextArea}
									/>
								</div>
								<div className="col-12 flex align-items-end justify-content-end">
									<Button
										type="button"
										label="Reset"
										className="p-button-danger mr-2"
										onClick={resetForm}
									/>
									<Button type="submit" label="Save" />
								</div>
							</Form>
						)}
					</Formik>
				</Dialog>
				<ConfirmDialog
					visible={callDialog.state}
					onHide={() =>
						setCallDialog((e) => ({
							...e,
							state: false,
							id: null,
						}))
					}
					message="Are you sure you want to proceed?"
					header="Confirmation"
					icon="pi pi-exclamation-triangle"
					accept={acceptCall}
					reject={rejectCall}
				/>
				<Dialog
					header={timelineStatusDialoge.dialogType}
					visible={timelineStatusDialoge.dialogOn}
					onHide={onHideDialog}
					breakpoints={{ "960px": "97vw" }}
					style={{ width: "65%" }}
				>
					{/* {timeLineState.isLoading && (
						<ProgressSpinner
							strokeWidth="6"
							style={{ width: 30 }}
						/>
					)} */}
					{(function () {
						switch (timelineStatusDialoge.dialogType) {
							case "Timeline":
								return (
									<TimelineStatus
										timelineStatusData={timeLineState}
									/>
								);
							case "Update":
								return (
									<StatusUpdate
										id={timelineStatusDialoge.leadId}
										event={getRecevedList}
									/>
								);
							case "Remark":
								return (
									<Formik
										initialValues={{
											title: "",
											description: "",
										}}
										validationSchema={Yup.object().shape({
											title: Yup.string().required(
												"This Field is required"
											),
											description: Yup.string().required(
												"This Field is required"
											),
										})}
									>
										{({ handleSubmit }) => (
											<Form
												className="form-grid grid"
												onSubmit={handleSubmit}
											>
												<div className="field col-12">
													<Field
														name={`title`}
														component={FieldInput}
														header={"Title"}
														star={"*"}
													/>
												</div>
												<div className="field col-12">
													<Field
														name={`description`}
														component={
															FieldTextArea
														}
														header={"Description"}
														star={"*"}
													/>
												</div>
												<div className="flex justify-content-end align-items-center col-12 mt-4">
													<Button
														type="cancel"
														label="Cancel"
														className="p-button-danger mr-2"
														onClick={() => {}}
													/>
													<Button
														type="submit"
														label="Save"
													/>
												</div>
											</Form>
										)}
									</Formik>
								);
							default:
								return <></>;
						}
					})()}
				</Dialog>
				<Dialog
					visible={assignLead.state}
					onHide={() =>
						setAssignLead((prev) => ({
							...prev,
							id: null,
							state: false,
						}))
					}
					style={{ width: "30%" }}
					header={"Assign Operation Executive"}
				>
					<Formik
						onSubmit={handleOe}
						initialValues={{ oeUserName: "" }}
					>
						{({ handleSubmit, values }) => (
							<Form onSubmit={handleSubmit} className="pt-2">
								<Field
									name={"oeUserName"}
									component={FeildDropdown}
									options={allOEList}
									optionLabel={"name"}
									optionValue={"userName"}
								/>
								<Button
									type={"submit"}
									label="Save"
									disabled={values.oeUserName ? false : true}
									className="w-full mt-2"
								/>
							</Form>
						)}
					</Formik>
				</Dialog>
				<Dialog
					visible={dialog}
					onHide={() => setDialog(false)}
					style={{ width: "90%" }}
					header={"Select program"}
				>
					<ConfirmDialog />

					{programData.isLoading ? (
						<>
							<div className="col-12">
								<TableLoader
									headerGroup={
										<ColumnGroup>
											<Row>
												<Column header="Institution Name" />
												<Column />
											</Row>
										</ColumnGroup>
									}
									itaration={{ hi: "", hello: "" }}
								/>
							</div>
						</>
					) : programData.data.length > 0 ? (
						<DataTable
							value={programData.data}
							expandedRows={expandedRows}
							onRowToggle={(e) => setExpandedRows(e.data)}
							responsiveLayout="scroll"
							rowExpansionTemplate={rowExpansionTemplate}
							dataKey="institutionId"
						>
							<Column
								expander={allowExpansion}
								style={{ width: "3em" }}
							/>
							<Column
								field="institutionName"
								header="Institution Name"
							/>
						</DataTable>
					) : (
						programData.data.length === 0 &&
						!programData.isLoading && (
							<div className="col-12">
								<TableNoData headerGroup={headerGroup} />
							</div>
						)
					)}

					<div className="col-12 flex justify-content-end align-items-end ">
						<Button
							label="Add"
							className="p-button-danger"
							disabled={
								selectState.selectionData &&
								Object.keys(selectState.selectionData).length >
									0
									? false
									: true
							}
							onClick={confirm}
						/>
					</div>
				</Dialog>
				<Dialog
					visible={bulkDialoge}
					onHide={() => setBulkDialogue(false)}
					style={{ width: "70%" }}
				>
					<BulkUpload
						handleStatusDialogeClose={handleStatusDialogeClose}
					/>
				</Dialog>
				<Dialog
					visible={bulkStatus}
					onHide={() => setBulkStatus(false)}
					style={{ width: "70%" }}
				>
					<BulkStatusUpload
						listData={leadDataState.data?.data}
						setBulkStatus={setBulkStatus}
						searchDispatch={getRecevedList}
					/>
				</Dialog>

				<Dialog
					header="Allocate TSRM"
					visible={profileDetailDialog.state}
					onHide={() => {
						setProfileDetailDialog((prev) => ({
							...prev,
							state: false,
							data: null,
						}));
					}}
					breakpoints={{ "960px": "97vw" }}
					style={{ width: "65vw" }}
				>
					<AllocateAgentForm handleSearch={handleSearch} />
				</Dialog>
			</div>
		</>
	);
};

export default LeadsTable;
