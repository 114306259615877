import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import { useEffect, useReducer, useRef, useState } from "react";
import {
	searchInitialState,
	searchReducer,
} from "../../../components/searchReducer";
import Config from "../../../shared/config";
import Paginator from "../../../components/Paginator";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import { Dialog } from "primereact/dialog";
import MenuComponent from "../../../components/MenuComponent";
import { PaymentService } from "../../../services/paymentService";
import Loader from "../../../components/Loader";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import SearchBusinessList from "./components/SearchBusinessList";
import BusinessDetailsPreview from "./components/BusinessPaymentDetails";

const BusinessDetailsList = () => {
	const [paymentData, setPaymentData] = useState({ commissionDetails: [] });
	const [productName, setProductName] = useState(null);
	const navigate = useNavigate();
	const toastTL = useRef(null);
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const paymentService = new PaymentService();
	const [members, setMembers] = useReducer(dataReducer, fetchInitialState);
	const [paymentDialog, setPaymentDialog] = useState(false);
	// function getSortableColumn(name, columnName) {
	// 	return (
	// 		<span className="flex align-items-center">
	// 			{name}
	// 			<span className="flex flex-column text-xs ml-2">
	// 				<span
	// 					className={
	// 						searchState.sortByColumn === columnName &&
	// 						searchState.sortType === "asc"
	// 							? "sort-icon asc active"
	// 							: "sort-icon asc"
	// 					}
	// 					onClick={() => tblSort(columnName, "asc")}
	// 				></span>
	// 				<span
	// 					className={
	// 						searchState.sortByColumn === columnName &&
	// 						searchState.sortType === "desc"
	// 							? "sort-icon desc active"
	// 							: "sort-icon desc"
	// 					}
	// 					onClick={() => tblSort(columnName, "desc")}
	// 				></span>
	// 			</span>
	// 		</span>
	// 	);
	// }
	// function tblSort(columnName, sortType) {
	// 	// setSortByColumn(columnName);
	// 	// setSortType(sortType);

	// 	searchDispatch({
	// 		type: Config.SEARCH_CONFIG.sortByColumn,
	// 		payload: columnName,
	// 	});
	// 	searchDispatch({
	// 		type: Config.SEARCH_CONFIG.sortType,
	// 		payload: sortType,
	// 	});
	// }

	const increment = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const pagesizechange = (e) => {
		// const newPageNo = Math.ceil(
		// 	(members.data.pageSize * members.data.pageNumber) / e.target.value
		// );

		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};
	const getData = () => {
		setMembers({ type: Config.FETCH_CONFIG.start });
		const body = {
			...searchState.searchValue,
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.sortByColumn &&
				searchState.sortType && {
					sorts: [
						searchState.sortByColumn + "," + searchState.sortType,
					],
				}),
		};
		const CleanObject = (body) => {
			for (let propName in body) {
				if (body[propName] === "" || body[propName] === null) {
					delete body[propName];
				}
			}
			return body;
		};
		paymentService
			.getBusinessDetailsList(CleanObject(body))
			.then((res) => {
				setMembers({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setMembers({
					type: Config.FETCH_CONFIG.stop,
				});
				setMembers({
					type: Config.FETCH_CONFIG.fetched,
				});
			});
	};
	const getPaymentData = (payload) => {
		setMembers({ type: Config.FETCH_CONFIG.start });
		paymentService
			.financeAgentInvoice(payload)
			.then((res) => setPaymentData(res))
			.finally(() => {
				setMembers({
					type: Config.FETCH_CONFIG.stop,
				});
				setMembers({
					type: Config.FETCH_CONFIG.fetched,
				});
			});
	};
	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />
				<Column header={"Date"} />
				<Column header={"Name"} />
				<Column header={"Username"} />
				<Column header={"Agent code"} />
				<Column header={"Mobile Number"} />
				{/* <Column header="Disbursed Amount" /> */}
				<Column header={"Amount"} />
				{/* <Column header={"Commission Percentage"} /> */}
				<Column header={"Product"} />
				<Column header="District" />
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const actionTemplate = (item) => {
		const items1 = [
			{
				label: "Profile",
				icon: "pi pi-user",
				command: () => {
					navigate(`/vendor/agent-profile/${item?.agentId}`, {
						state: {
							profileId: item?.profileId,
						},
					});
				},
			},
			{
				label: "Commission",
				icon: "pi pi-credit-card",
				command: () => {
					setPaymentDialog(true);
					setProductName(item?.productName);
					getPaymentData({
						month: `${item?.month}, ${item?.year}`, //"Jan, 2024"
						productId: [item?.productId],
						agentId: item?.agentId,
					});
				},
			},
		];

		return (
			<div className="flex gap-2">
				<MenuComponent items={items1} />
			</div>
		);
	};
	return (
		<>
			{members?.isLoading && <Loader />}
			<Toast ref={toastTL} position="top-left" />
			<SearchBusinessList searchDispatch={searchDispatch} />
			<DataTable
				headerColumnGroup={headerGroup}
				value={members?.data?.data?.map((item, index) => ({
					...item,
					index:
						members?.data?.pageSize * members?.data?.pageNo -
						members?.data?.pageSize +
						1 +
						index,
				}))}
			>
				<Column field="index" />
				<Column
					body={(item) => {
						return item?.month + ", " + item?.year;
					}}
				/>
				<Column field="fullName" />
				<Column field="agentUserName" />
				<Column
					body={(item) => {
						return (
							<Button
								className="p-button-text p-0 m-0"
								onClick={() =>
									navigate(
										`/vendor/agent-profile/${item?.agentId}`,
										{
											state: {
												profileId: item?.profileId,
											},
										}
									)
								}
								label={item?.agentCode}
							/>
						);
					}}
				/>

				<Column field="mobileNumber" />
				{/* <Column field="disbursedAmount" /> */}
				<Column field="amount" />
				<Column field="productName" />
				<Column field="districtName" />
				<Column body={actionTemplate} />
			</DataTable>
			{members?.data?.data?.length > 0 && (
				<Paginator
					pageSize={searchState.pageSize}
					firstPage={members.data.firstPage}
					lastPage={members.data.lastPage}
					decrement={decrement}
					increment={increment}
					pagesizechange={pagesizechange}
					pageNo={members.data.pageNo}
					totalPages={members.data.totalPages}
					totalElements={members.data.totalElements}
				/>
			)}
			<Dialog
				visible={paymentDialog}
				onHide={() => {
					setPaymentDialog(false);
					setPaymentData({ commissionDetails: [] });
				}}
				header={"Commission Invoice Details"}
				style={{ width: "80%" }}
			>
				<BusinessDetailsPreview
					paymentData={paymentData}
					productName={productName}
				/>
			</Dialog>
		</>
	);
};

export default BusinessDetailsList;
