import { states } from "./data";

const S3_BUCKET_NAME = "rainbow-india";
const STATE_DROP_DOWN_DATA = states;
// const INSTITUTION_DROP_DOWN_DATA = institutionType;

const Config = {
	S3_ACCESSKEYID: process.env.REACT_APP_S3_ACCESSKEYID,
	S3_SECRETACCESSKEY: process.env.REACT_APP_S3_SECRETACCESSKEY,
	S3_BUCKET: process.env.REACT_APP_S3_BUCKET,
	S3_REGION: process.env.REACT_APP_S3_REGION,
	AGENT_PROFILE_BUCKET: `${S3_BUCKET_NAME}/agent-profile`,
	INSTITUTION_DOCS_BUCKET: `${S3_BUCKET_NAME}/institution-docs`,
	PROGRAM_DOCS_BUCKET: `${S3_BUCKET_NAME}/program-docs`,
	FAQ_DOCS_BUCKET: `${S3_BUCKET_NAME}/faq-docs`,
	CAMPAIGN_BUCKET: `${S3_BUCKET_NAME}/campaign-docs/campaign-details`,
	APPLICATION_ATTACHMENT_BUCKET: `${S3_BUCKET_NAME}/applications`,
	LEAD_ATTACHMENT_BUCKET: `${S3_BUCKET_NAME}/lead`,
	INVOICE_ATTACHMENT_BUCKET: `${S3_BUCKET_NAME}/invoice`,
	AGENT_BUCKET_ACCESS_URL:
		"https://rainbow-india.s3.ap-south-1.amazonaws.com",
	AGENT_PROFILE_FOLDER: "/agent-profile",
	INSTITUTION_DOCS_FOLDER: "/institution-docs",
	PROGRAM_DOCS_FOLDER: "/program-docs",
	STATES_DATA: STATE_DROP_DOWN_DATA,
	INSTITUTION_TYPE: [
		{ name: "Bank", value: "BANK" },
		{ name: "NBFC", value: "NBFC" },
		{ name: "Insurance Company", value: "INSURANCE_COMPANY" },
		{ name: "Mutual Fund House", value: "MUTUAL_FUND_HOUSE" },
		{ name: "Stock Broker", value: "STOCK_BROKER" },
		{
			name: "Micro Finance Institution",
			value: "MICRO_FINANCE_INSTITUTION",
		},
	],
	PROGRAM_TYPE: {
		loan: "LOANS",
		creditCard: "CREDIT_CARD",
		insurance: "INSURANCE",
		investment: "INVESTMENT",
	},
	OCCUPATION: [
		{
			name: "Service Private Sector",
			label: "Service Private Sector",
			value: "Service_Private_Sector",
		},
		{
			name: "Service Public Sector",
			label: "Service Public Sector",
			value: "Service_Public_Sector",
		},
		{
			label: "Student",
			name: "Student",
			value: "Student",
		},
		{
			label: "Self Employed Professional",
			name: "Self Employed Professional",
			value: "Self_Employed_Professional",
		},
		{
			label: "Salaried",
			name: "Salaried",
			value: "Salaried",
		},
		{
			name: "Self Employed",
			label: "Self Employed",
			value: "Self_Employed",
		},
		{
			name: "Business",
			label: "Business",
			value: "Business",
		},
		{
			label: "Retire",
			name: "Retire",
			value: "Retire",
		},

		{
			label: "House Wife",
			name: "House Wife",
			value: "House_Wife",
		},
		{
			label: "Others",
			name: "Others",
			value: "Others",
		},
	],
	ID_TYPE: [
		{
			label: "",
			value: "",
		},
	],
	DESIGNATION: [
		{
			name: "Managing Director",
			label: "Managing Director",
			value: "Managing_Director",
		},
		{
			name: "Chairman",
			label: "Chairman",
			value: "Chairman",
		},
		{
			label: "Vice Chairman",
			name: " Vice Chairman",
			value: " Vice_Chairman",
		},
	],
	ORGANIZATION: [
		{ value: "Education_Society", label: "Education Society" },
		{ value: "LLP", label: "LLP" },
		{ value: "Propritorship", label: "Propritorship" },
		{ value: "Partnership_Firm", label: "Partnership Firm" },
		{ value: "Pvt_Ltd", label: "Private Limited" },
		{ value: "Public_Ltd", label: "Public Limited" },
		{ value: "Public_Sector_PSU", label: "Public Sector PSU" },
		{ value: "Others", label: "Others" },
	],
	RESIDENTTYPE: [
		{
			label: "Parental",
			value: "PARENTAL",
		},
		{
			label: "Rented",
			value: "RENTED",
		},
		{
			label: "Owned",
			value: "OWNED",
		},
		{
			label: "Company provided",
			value: "COMPANY_PROVIDED",
		},
	],
	PREFRREDMAILING_ADDRESS: [
		{
			id: "Present_Address",
			name: "Present Address",
			value: "Present Address",
		},
		{
			id: "Permanent_Address",
			name: "Permanent Address",
			value: "Permanent Address",
		},
	],
	LOAN_APPLICATION_STATUS: [
		{
			label: "Draft",
			value: "DRAFT",
		},
		{
			label: "Pending for customer",
			value: "PENDING_FOR_CUSTOMER",
		},
		{
			label: "Customer draft",
			value: "CUSTOMER_DRAFT",
		},
		{
			label: "Customer approved",
			value: "CUSTOMER_APPROVED",
		},
		{
			label: "Lock",
			value: "LOCK",
		},
	],
	RELIGION: [
		{
			label: "Hindu",
			value: "HINDU",
		},
		{
			label: "Muslim",
			value: "MUSLIM",
		},
		{
			label: "Cristian",
			value: "CRISTIAN",
		},
		{
			label: "Buddhist",
			value: "BUDDHIST",
		},
		{
			label: "Sikh",
			value: "SIKH",
		},
		{
			label: "Jain",
			value: "JAIN",
		},
		{
			label: "Other",
			value: "OTHER",
		},
	],
	CATEGORY: [
		{
			label: "General",
			value: "GENERAL",
		},
		{
			label: "Obc",
			value: "OBC",
		},
		{
			label: "Sc",
			value: "SC",
		},
		{
			label: "St",
			value: "ST",
		},
	],
	BANK: [
		{ name: "SBI", code: "SBI" },
		{ name: "HDFC", code: "HDFC" },
		{ name: "AXIS", code: "AXIS" },
	],
	TYPE: [
		{ name: "Insurance", code: "INSURANCE" },
		{ name: "Loans", code: "LOANS" },
		{ name: "Credit Card", code: "CREDIT_CARD" },
		{ name: "Investment", code: "INVESTMENT" },
	],
	SUBPRODUCT: [
		{ name: "Home Loan", code: "homeLoan" },
		{ name: "Business Loan", code: "businessLoan" },
	],
	CREDITBUREAU_NAME: [
		{ name: "CIBIL", label: "CIBIL", value: "CIBIL" },
		{ name: "EXPERIAN", label: "EXPERIAN", value: "EXPERIAN" },
		{ name: "EQUIFAX", label: "EQUIFAX", value: "EQUIFAX" },
	],
	REASON: [
		{
			name: "Debt consolidation",
			label: "Debt consolidation",
			value: "Debt consolidation",
		},
		{
			name: "Alternative to payday loan",
			label: "Alternative to payday loan",
			value: "Alternative to payday loan",
		},
		{
			name: "Moving costs",
			label: "Moving costs",
			value: "Moving costs",
		},
		{
			name: "Emergency expenses",
			label: "Emergency expenses",
			value: "Emergency expenses",
		},
		{
			name: "Wedding expenses",
			label: "Wedding expenses",
			value: "Wedding expenses",
		},
		{
			name: "Large purchases",
			label: "Large purchases",
			value: "Large purchases",
		},
		{
			name: "House renovation or improvement",
			label: "House renovation or improvement",
			value: "House renovation or improvement",
		},
		{
			name: "Other",
			label: "Other",
			value: "Other",
		},
	],
	MARITAL_STATUS: [
		{
			label: "Single",
			value: "Single",
		},
		{
			label: "Married",
			value: "Married",
		},
		{
			label: "Divorce",
			value: "Divorce",
		},
	],
	SALUTATION: [
		{ label: "Mr.", value: "Mr" },
		{ label: "Mrs.", value: "Mrs" },
		{ label: "Ms.", value: "Ms" },
	],
	QUALIFICATION: [
		{
			name: "Under_Graduate",
			label: "Under Graduate",
			value: "Under_Graduate",
		},
		{
			name: "Graduate",
			label: "Graduate",
			value: "Graduate",
		},
		{
			name: "Post Graduate",
			label: "Post Graduate",
			value: "Post_Graduate",
		},
		{
			name: "Engineer",
			label: "Engineer",
			value: "Engineer",
		},
		{
			name: "CA",
			label: "CA",
			value: "CA",
		},
		{
			name: "Doctor",
			label: "Doctor",
			value: "Doctor",
		},
		{
			name: "Other",
			label: "Other",
			value: "Other",
		},
	],
	RESIDENTIALSTATUS: [
		{
			name: "Individual",
			label: "Individual",
			value: "Individual",
		},
		{
			name: "NRI",
			label: "NRI",
			value: "NRI",
		},
		{
			name: "PIO",
			label: "PIO",
			value: "PIO",
		},
		{
			name: "Foreign",
			label: "Foreign",
			value: "Foreign",
		},
		{
			name: "National",
			label: "National",
			value: "National",
		},
		{
			name: "Others",
			label: "Others",
			value: "Others",
		},
	],
	NATIONALITY: [
		{
			name: "Indian",
			label: "Indian",
			value: "INDIAN",
		},
		{
			name: "NRI",
			label: "NRI",
			value: "NRI",
		},
		{
			name: "Others",
			label: "Others",
			value: "Others",
		},
	],
	INDUSTRY: [
		{
			name: "Automobiles",
			label: "Automobiles",
			value: "Automobiles",
		},
		{
			name: "Agriculture based",
			label: "Agriculture based",
			value: "Agriculture based",
		},
		{
			name: "Banking",
			label: "Banking",
			value: "Banking",
		},
		{
			name: "BPO",
			label: "BPO",
			value: "BPO",
		},
		{
			name: "Capital goods",
			label: "Capital goods",
			value: "Capital goods",
		},
		{
			name: "Telecom",
			label: "Telecom",
			value: "Telecom",
		},
		{
			name: "IT",
			label: "IT",
			value: "IT",
		},
		{
			name: "Retail",
			label: "Retail",
			value: "Retail",
		},
		{
			name: "Real estate",
			label: "Real estate",
			value: "Real estate",
		},
		{
			name: "Consumer Durable",
			label: "Consumer Durable",
			value: "Consumer Durable",
		},
		{
			name: "FMGC",
			label: "FMGC",
			value: "FMGC",
		},
		{
			name: "NBFC",
			label: "NBFC",
			value: "NBFC",
		},
		{
			name: "Marketing/Adertisement",
			label: "Marketing/Adertisement",
			value: "Marketing/Adertisement",
		},
		{
			name: "Pharma",
			label: "Pharma",
			value: "Pharma",
		},
		{
			name: "Media",
			label: "Media",
			value: "Media",
		},
		{
			name: "Manufacturing",
			label: "Manufacturing",
			value: "Manufacturing",
		},
		{
			name: "Trading",
			label: "Trading",
			value: "Trading",
		},
		{
			name: "Export",
			label: "Export",
			value: "Export",
		},
		{
			name: "Transport",
			label: "Transport",
			value: "Transport",
		},
		{
			name: "Others",
			label: "Others",
			value: "Others",
		},
	],
	GENDERS: [
		{ label: "Male", name: "Male", value: "Male" },
		{ label: "Female", name: "Female", value: "Female" },
		{ label: "Others", name: "Others", value: "Others" },
	],
	APPLICATION_FORM_TYPE: [
		{ label: "API", name: "API", value: "API" },
		{ label: "FI's website", name: "FI's Website", value: "FI's Website" },
		{ label: "Hard copy", name: "Hard copy", value: "Hard copy" },
	],
	PRECLOSURE: [
		{ label: "Yes", value: true, name: "Yes" },
		{ label: "No", value: false, name: "No" },
	],
	YES_NO: [
		{ label: "Yes", value: true, name: "Yes" },
		{ label: "No", value: false, name: "No" },
	],
	FETCH_CONFIG: {
		ini: "INI",
		start: "FETCH_START",
		success: "FETCH_SUCCESS",
		error: "FETCH_ERROR",
		stop: "FETCH_STOP",
		fetched: "FETCH_DONE",
	},
	ACCOUNTTYPE: [
		{ label: "Saving", name: "Saving", value: "Saving" },
		{ label: "Current", name: "Current", value: "Current" },
		{ label: "Others", name: "Others", value: "Others" },
	],

	BANK_ACCOUNTTYPE: [
		{ label: "Saving", name: "Saving", value: "SAVINGS" },
		{ label: "Current", name: "Current", value: "CURRENT" },
	],
	stateData: [
		{
			id: 2018,
			name: "ANDAMAN AND NICOBAR ISLANDS",
			zoneId: 2006,
			value: 2018,
		},
		{
			id: 2019,
			name: "ANDHRA PRADESH",
			zoneId: 2006,
			value: 2019,
		},
		{
			id: 2020,
			name: "ARUNACHAL PRADESH",
			zoneId: 2002,
			value: 2020,
		},
		{
			id: 2021,
			name: "ASSAM",
			zoneId: 2002,
			value: 2021,
		},
		{
			id: 2022,
			name: "BIHAR",
			zoneId: 2004,
			value: 2022,
		},
		{
			id: 2023,
			name: "CHANDIGARH",
			zoneId: 2001,
			value: 2023,
		},
		{
			id: 2024,
			name: "CHHATTISGARH",
			zoneId: 2003,
			value: 2024,
		},
		{
			id: 2025,
			name: "DELHI",
			zoneId: 2001,
			value: 2025,
		},
		{
			id: 2026,
			name: "GOA",
			zoneId: 2005,
			value: 2026,
		},
		{
			id: 2027,
			name: "GUJARAT",
			zoneId: 2005,
			value: 2027,
		},
		{
			id: 2028,
			name: "HARYANA",
			zoneId: 2001,
			value: 2028,
		},
		{
			id: 2029,
			name: "HIMACHAL PRADESH",
			zoneId: 2001,
			value: 2029,
		},
		{
			id: 2030,
			name: "JAMMU AND KASHMIR",
			zoneId: 2001,
			value: 2030,
		},
		{
			id: 2031,
			name: "JHARKHAND",
			zoneId: 2004,
			value: 2031,
		},
		{
			id: 2032,
			name: "KARNATAKA",
			zoneId: 2006,
			value: 2032,
		},
		{
			id: 2033,
			name: "KERALA",
			zoneId: 2006,
			value: 2033,
		},
		{
			id: 2034,
			name: "LADAKH",
			zoneId: 2001,
			value: 2034,
		},
		{
			id: 2035,
			name: "LAKSHADWEEP",
			zoneId: 2006,
			value: 2035,
		},
		{
			id: 2036,
			name: "MADHYA PRADESH",
			zoneId: 2003,
			value: 2036,
		},
		{
			id: 2037,
			name: "MAHARASHTRA",
			zoneId: 2005,
			value: 2037,
		},
		{
			id: 2038,
			name: "MANIPUR",
			zoneId: 2002,
			value: 2038,
		},
		{
			id: 2039,
			name: "MEGHALAYA",
			zoneId: 2002,
			value: 2039,
		},
		{
			id: 2040,
			name: "MIZORAM",
			zoneId: 2002,
			value: 2040,
		},
		{
			id: 2041,
			name: "NAGALAND",
			zoneId: 2002,
			value: 2041,
		},
		{
			id: 2042,
			name: "ODISHA",
			zoneId: 2004,
			value: 2042,
		},
		{
			id: 2043,
			name: "PUDUCHERRY",
			zoneId: 2006,
			value: 2043,
		},
		{
			id: 2044,
			name: "PUNJAB",
			zoneId: 2001,
			value: 2044,
		},
		{
			id: 2045,
			name: "RAJASTHAN",
			zoneId: 2001,
			value: 2045,
		},
		{
			id: 2046,
			name: "SIKKIM",
			zoneId: 2002,
			value: 2046,
		},
		{
			id: 2047,
			name: "TAMIL NADU",
			zoneId: 2006,
			value: 2047,
		},
		{
			id: 2048,
			name: "TELANGANA",
			zoneId: 2006,
			value: 2048,
		},
		{
			id: 2049,
			name: "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
			zoneId: 2005,
			value: 2049,
		},
		{
			id: 2050,
			name: "TRIPURA",
			zoneId: 2002,
			value: 2050,
		},
		{
			id: 2051,
			name: "UTTAR PRADESH",
			zoneId: 2003,
			value: 2051,
		},
		{
			id: 2052,
			name: "UTTARAKHAND",
			zoneId: 2003,
			value: 2052,
		},
		{
			id: 2053,
			name: "WEST BENGAL",
			zoneId: 2004,
			value: 2053,
		},
	],
	OccupationProfession: [
		{ name: "Handicraft Artisans", value: "HANDICRAFT_ARTISANS" },
		{ name: "House Wife", value: "HOUSEWIFE" },
		{ name: "Business", value: "BUSINESS" },
		{ name: "Farmer", value: "FARMER" },
	],
	PolicyTerm: [
		{ label: "One Year", name: "One Year", value: 1 },
		{ label: "Two Years", name: "Two Years", value: 2 },
	],
	RelationshipWithApplicant: [
		{ name: "Brother", value: "BROTHER" },
		{ name: "Daughter", value: "DAUGHTER" },
		{ name: "Dependent", value: "DEPENDENT" },
		{ name: "Father", value: "FATHER" },
		{ name: "Mother", value: "MOTHER" },
		{ name: "Self", value: "SELF" },
		{ name: "Sister", value: "SISTER" },
		{ name: "Sister", value: "SISTER" },
		{ name: "Son", value: "SON" },
		{ name: "Spouse", value: "SPOUSE" },
	],
	SEARCH_CONFIG: {
		sortByColumn: "SORT_BY_COLUMN",
		sortType: "SORT_TYPE",
		searchRequest: "SEARCH_REQUEST",
		pageSize: "PAGE_SIZE",
		increment: "INCREMENT",
		decrement: "DECREMENT",
		reset: "RESET",
		pageNo: "PAGE_NO",
	},
	TBL_SORT_PARAM: {},
	AGENT_STATUS: [
		{ label: "Pending KYC", value: "KYC" },
		{ label: "Pending BANK", value: "BANK" },
		{ label: "COMPLETED", value: "COMPLETED" },
	],

	ApprovalStatus: [
		{
			name: "Approved",
			value: "APPROVED",
		},
		{ name: "Rejected", value: "REJECTED" },
	],

	// ProfileType: [
	// 	{
	// 		label: "Indivisual",
	// 		value: "INDIVIDUAL",
	// 	},
	// 	{ label: "Employee", value: "EMPLOYEE" },
	// 	{ label: "Subagent", value: "SUB_AGENT" },
	// 	{ label: "Company", value: "COMPANY" },
	// 	{ label: "mainagent", value: "MAIN_AGENT" },
	// ],
	ProfileType: [
		{
			label: "Individual",
			value: "INDIVIDUAL",
		},
		// {
		// 	label: "Employee",
		// 	value: "EMPLOYEE",
		// },
		// {
		// 	label: "Partner",
		// 	value: "PARTNER",
		// },
		{
			label: "Company",
			value: "COMPANY",
		},
		{
			label: "BM",
			value: "BM",
		},
	],
	ProfileTypeSearch: [
		{
			label: "Individual",
			value: "INDIVIDUAL",
		},
		// {
		// 	label: "Employee",
		// 	value: "EMPLOYEE",
		// },
		{
			label: "Partner",
			value: "PARTNER",
		},
		{
			label: "Company",
			value: "COMPANY",
		},
		{
			label: "BM",
			value: "BM",
		},
	],
	ProfileStatus: [
		{ label: "On-hold", value: "ON_HOLD" },
		{ label: "Not verified", value: "NOT_VERIFIED" },
		{ label: "Verified", value: "VERIFIED" },
		{ label: "Temporary suspended", value: "TEMPORARY_SUSPENDED" },
		{ label: "Blocked", value: "BLOCKED" },
	],
	CampaignBuyTypeBought: [
		{ label: "Bought", value: "BOUGHT" },
		{ label: "Not Bought", value: "NOT_BOUGHT" },
	],
	CampaignBuyTypeHave: [
		{ label: "Have", value: "HAVE" },
		{ label: "Do not have", value: "DO_NOT_HAVE" },
	],

	BUSINESS_BY: [
		{ label: "Self", value: "SELF" },
		{ label: "Parent", value: "PARENT" },
		{ label: "Spouse", value: "SPOUSE" },
		{ label: "Relative", value: "RELATIVE" },
		{ label: "Others", value: "OTHERS" },
	],

	BUSINESS_NATURE: [
		{ label: "Manufacturer", value: "MANUFACTURER" },
		{ label: "Retailer", value: "RETAILER" },
		{ label: "Distributor", value: "DISTRIBUTOR" },
		{ label: "Trader", value: "TRADER" },
		{ label: "Service provider", value: "SERVICE_PROVIDER" },
		{ label: "Importer", value: "IMPORTER" },
		{ label: "Exporter", value: "EXPORTER" },
		{ label: "Others", value: "OTHERS" },
	],

	BUSINESS_TYPE: [
		{ label: "Proprietorship", value: "PROPRIETORSHIP" },
		{ label: "Partnership", value: "PARTNERSHIP" },
		{ label: "Pvt Ltd", value: "PVT_LTD" },
		{ label: "LLP", value: "LLP" },
		{ label: "Ltd Company", value: "LTD_COMPANY" },
		{ label: "One Person Company", value: "ONE_PERSON_COMPANY" },
		{ label: "Not Registered", value: "NOT_REGISTERED" },
	],
	VENDOR_TYPE: [
		{ label: "Call Center", value: "CALL_CENTER" },
		{ label: "Digital Marketing", value: "DIGITAL_MARKETING" },
		{ label: "Operations", value: "OPERATIONS" },
	],
	CONTACT_PERSON_TYPE: [
		{ label: "OWNER", value: "OWNER" },
		{ label: "DIRECTOR", value: "DIRECTOR" },
		{ label: "PARTNER", value: "PARTNER" },
	],
	HUB_LOCATION_TYPE: [
		{ label: "City", value: "CITY" },
		{ label: "State", value: "STATE" },
	],

	RELATIONSHIP: [
		{ label: "Father", value: "FATHER" },
		{ label: "Mother", value: "MOTHER" },
		{ label: "Son", value: "SON" },
		{ label: "Daughter", value: "DAUGHTER" },
	],

	PAYMENYTYPE: [
		{ label: "Monthly", value: "MONTHLY" },
		{ label: "Quarterly", value: "QUARTERLY" },
		{ label: "Half yearly", value: "HALFYEARLY" },
		{ label: "Yearly", value: "YEARLY" },
		{ label: "One time", value: "ONETIME" },
	],
	AGENTLEADSTATUS: [
		{ label: "Invalid Number", value: "INVALID_NUMBER" },
		{ label: "No Pickup Attempt", value: "NO_PICKUP_ATTEMPT" },
		{ label: "Not Responding", value: "NOT_RESPONDING" },
		{ label: "Not Interested", value: "NOT_INTERESTED" },
		{ label: "Follow Up note", value: "FOLLOW_UP_NOTE" },
		{
			label: "Interested Will Register",
			value: "INTERESTED_WILL_REGISTER",
		},
	],
	AGENTLEADSTATUSEARCHS: [
		{ label: "New", value: "NEW" },
		{ label: "Invalid Number", value: "INVALID_NUMBER" },
		{ label: "No Pickup Attempt", value: "NO_PICKUP_ATTEMPT" },
		{ label: "Not Responding", value: "NOT_RESPONDING" },
		{ label: "Not Interested", value: "NOT_INTERESTED" },
		{ label: "Follow Up Note", value: "FOLLOW_UP_NOTE" },
		{
			label: "Interested Will Register",
			value: "INTERESTED_WILL_REGISTER",
		},
		{ label: "Downloaded", value: "DOWNLOADED" },
		{ label: "Registered", value: "REGISTERED" },
		{ label: "Verified", value: "VERIFIED" },
		{ label: "Called", value: "CALLED" },
		{ label: "Contacted", value: "CONTACTED" },
	],
	CHILD_USER_ACTIVITY: [
		{
			label: "ALLOCATE",
			value: "ALLOCATE",
		},
		{
			label: "ASSIGN OE",
			value: "ASSIGN_OE",
		},
		{
			label: "CALL",
			value: "CALL",
		},
		{
			label: "CREATE APPLICATION",
			value: "CREATE_APPLICATION",
		},
		{
			label: "CREATE APPLICATION_FROM_LEAD",
			value: "CREATE_APPLICATION_FROM_LEAD",
		},
		{
			label: "CREATE LEAD",
			value: "CREATE_LEAD",
		},
		{
			label: "EMAIL",
			value: "EMAIL",
		},
		{
			label: "NOTE",
			value: "NOTE",
		},
		{
			label: "REJECT",
			value: "REJECT",
		},
		{
			label: "SET REMAINDER",
			value: "SET_REMAINDER",
		},
		{
			label: "STATUS UPDATE",
			value: "STATUS_UPDATE",
		},
		{
			label: "UPDATE APPLICATION",
			value: "UPDATE_APPLICATION",
		},
		{
			label: "UPDATE LEAD",
			value: "UPDATE_LEAD",
		},
		{
			label: "WHATSAPP",
			value: "WHATSAPP",
		},
	],
	// 	[
	// 	{ label: "CALL", value: "CALL" },
	// 	{ label: "ALLOCATE", value: "ALLOCATE" },
	// 	{ label: "SET REMAINDER", value: "SET_REMAINDER" },
	// 	{ label: "WHATSAPP", value: "WHATSAPP" },
	// 	{ label: "EMAIL", value: "EMAIL" },
	// 	{ label: "CREATE LEAD", value: "CREATE_LEAD" },
	// 	{ label: "NOTE", value: "NOTE" },
	// 	{ label: "UPDATE LEAD", value: "UPDATE_LEAD" },
	// 	{ label: "STATUS UPDATE", value: "STATUS_UPDATE" },
	// 	{
	// 		label: "CREATE APPLICATION_FROM_LEAD",
	// 		value: "CREATE_APPLICATION_FROM_LEAD",
	// 	},
	// 	{ label: "CREATE APPLICATION", value: "CREATE_APPLICATION" },
	// 	{ label: "UPDATE APPLICATION", value: "UPDATE_APPLICATION" },
	// 	{ label: "REJECT", value: "REJECT" },
	// 	{ label: "ASSIGN OE", value: "ASSIGN_OE" },
	// ],

	PARENT_USER_ACTIVITY: [
		{ label: "AGENT", value: "AGENT" },
		{ label: "AGENT LEAD", value: "AGENT_LEAD" },
		{ label: "APPLICATION", value: "APPLICATIONS" },
		{ label: "LEAD", value: "LEAD" },
	],
	PAYMENT_TYPE_FINANCE: [
		{ label: "NEFT", value: "NEFT" },
		{ label: "RTGS", value: "RTGS" },
		{ label: "DD", value: "DD" },
		{ label: "CHEQUE", value: "CHEQUE" },
		{ label: "VOUCHER", value: "VOUCHER" },
		{ label: "IMPS", value: "IMPS" },
	],
	PRODUCT_ALIAS: {
		PERSONAL_LOAN: "PL",
		BUSINESS_LOAN: "BL",
		CARD_LOAN: "CC",
		HOME_LOAN: "HL",
		VEHICALE_LOAN: "VL",
		ULIP: "ULIP",
		TRADITIONAL: "TRADI",
		CHILD_INSURANCE_PLAN: "CIP",
		CHILD_EDUCATION_PLAN: "CEP",
		TERM_PLAN: "TIP",
		RETIRMENT_PLAN: "RIP",
		INDIVIDUAL: "INDIV",
		FAMILY_FLOATER: "FFHI",
		CRITICAL_ILLNESS: "CII",
		GROUP: "GHI",
		INSURANCE_HOSPITAL: "HC",
		LOAN_AGENST_PROPERTY: "LAP",
		MOTOR_INSURANCE_PRIVET_CAR: "PC",
		MOTOR_INSURANCE_TWO_WHEELER: "2WHEELER",
		INVESTMENT_LIFE_INSURANCE: "ILI",
		GROUP_INSURANCE_PLAN: "GIP",
	},
	PRODUCT_LEAD_TYPE: {
		INSURANCE: "INSURANCE",
		LOAN: "LOAN",
	},
};

export default Config;
