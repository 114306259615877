import axios from "axios";
import { agent_end_point_url, apiPath } from "../shared/constant";

export class DashboardService {
	constructor() {
		this.token = sessionStorage.getItem("token");
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}

	totalAgentLead = async () => {
		try {
			const response = await axios.get(
				agent_end_point_url + "/dashboard/total-agent-lead",

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	totalAgent = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/dashboard/total-agent",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	categoryWiseAgent = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/dashboard/category-wise-agent",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	profileWiseAgent = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/dashboard/profile-wise-agent",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	monthlyWiseAgent = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/dashboard/month-wise-agent",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	monthlyWiseAgentLead = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/dashboard/month-wise-agent-lead",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	agent_lead_list = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/dashboard/agent-lead-list",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agent_funnel = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/dashboard/funnel",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	application_lead_funnel = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/dashboard/lead-funnel",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	application_funnel = async (payload) => {
		try {
			const response = await axios.post(
				apiPath + "/dashboard/application-funnel",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agent_block = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/dashboard/agent-lead-blocks-data",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	consolidated_block = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/dashboard/consolidated-graph",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agent_block_2 = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/dashboard/second-top-agent-head-count",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agent_block_3 = async (payload) => {
		try {
			const response = await axios.post(
				apiPath + "/dashboard/application-count",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agent_block_4_application_lead = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/dashboard/second-top-lead-head-count",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	cumulative_graph_agent = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/dashboard/cumulative-graph",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	cumulative_graph_application_lead = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url +
					"/dashboard/application-lead-cumulative-graph",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	cumulative_graph_application = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/dashboard/application-cumulative-graph",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	active_agent_application_lead = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/dashboard/active-agent-list",
				payload,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
}
