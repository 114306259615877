// import { useState } from "react";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import * as Yup from "yup";
import { InstitutionService } from "../../../../services/InstitutionService";
import { Dropdown } from "primereact/dropdown";
import Config from "../../../../shared/config";
import { useGetStateListQuery } from "../../../../services/query/queryApi";

const BasicDetails = ({ data, id, handelBasicDetails, isLoading }) => {
	// console.log("data: ", data);
	const institutionService = new InstitutionService();
	// const [institition, setInstitition] = useState([]);
	const { data: stateData = [] } = useGetStateListQuery();

	const formik = useFormik({
		initialValues: {
			name: data.bankName,
			type: data.bankType,
			alias: data.alias,
			rainbowDsaCode: data.rainbowDsaCode ? data.rainbowDsaCode : "",
			addressLine1: data.addressLine1,
			addressLine2: data.addressLine2 ? data.addressLine2 : "",
			addressLine3: data.addressLine3 ? data.addressLine3 : "",
			city: data.city,
			stateId: data.stateId,
			pincode: data.pincode ? data.pincode : "",
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			type: Yup.string().required("Type is required."),
			name: Yup.string().required("Name is required."),
			// rainbowDsaCode: Yup.string().required("DSA code is required."),
			// alias: Yup.string()
			// 	.required("Alias is required.")
			// 	.matches(/^[a-zA-Z ]*$/, "Invalid alias"),
			addressLine1: Yup.string().required("Address Line 1 is required"),
			stateId: Yup.string().required("State is required"),
			city: Yup.string()
				.required("City is required")
				.min(3, "Invalid city name")
				.matches(/^[a-zA-Z ]*$/, "Invalid city name"),
			pincode: Yup.string()
				.required("Pincode is required")
				.matches(/^[0-9]{1,6}$/, "Invalid pincode"),
		}),
		onSubmit: (data) => {
			const requestBody = { id: id, ...data };
			handelBasicDetails(requestBody);
		},
	});
	const handleChange = (e, setFieldValue) => {
		institutionService
			.getInstitutionName(e.value)
			// .then((res) => setInstitition(res))
			.then((res) => console.log(res))
			.catch((e) => console.log(e));
		setFieldValue("type", e.value, true);
	};
	const isFormFieldValid = (name) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};

	return (
		<div className="grid">
			<form onSubmit={formik.handleSubmit} className="p-fluid grid">
				<FocusError formik={formik} />
				<div className="field col-12 md:col-6 lg:col-4">
					<label htmlFor="type">Type*</label>
					<Dropdown
						id="type"
						name="type"
						className={classNames(
							{
								"p-invalid": isFormFieldValid("type"),
							},
							"border-round-md"
						)}
						value={formik.values.type}
						onChange={(e) => handleChange(e, formik.setFieldValue)}
						options={Config.INSTITUTION_TYPE}
						optionLabel="name"
						disabled
					/>
					{getFormErrorMessage("type")}
				</div>
				<div className="field col-12 md:col-6 lg:col-4">
					<label htmlFor="name">Name*</label>
					<InputText
						id="name"
						name="name"
						value={formik.values.name}
						onChange={formik.handleChange}
						className={classNames(
							{
								"p-invalid": isFormFieldValid("name"),
							},
							"border-round-md"
						)}
						disabled
					/>
					{getFormErrorMessage("name")}
				</div>
				<div className="field col-12 md:col-6 lg:col-4">
					<label htmlFor="rainbowDsaCode">Rainbow DSA Code*</label>
					<InputText
						id="rainbowDsaCode"
						name="rainbowDsaCode"
						value={formik.values.rainbowDsaCode}
						onChange={formik.handleChange}
						className={classNames({
							"p-invalid": isFormFieldValid("rainbowDsaCode"),
						})}
					/>
					{getFormErrorMessage("rainbowDsaCode")}
				</div>
				<div className="field col-12 md:col-6 lg:col-4">
					<label htmlFor="alias">Alias*</label>
					<InputText
						id="alias"
						name="alias"
						value={formik.values.alias}
						onChange={formik.handleChange}
						className={classNames({
							"p-invalid": isFormFieldValid("alias"),
						})}
					/>
					{getFormErrorMessage("alias")}
				</div>
				<div className="field col-12 md:col-6 lg:col-4">
					<label htmlFor="addressLine1">Address Line 1*</label>
					<InputText
						id="addressLine1"
						name="addressLine1"
						value={formik.values.addressLine1}
						onChange={formik.handleChange}
						className={classNames({
							"p-invalid": isFormFieldValid("addressLine1"),
						})}
					/>
					{getFormErrorMessage("addressLine1")}
				</div>
				<div className="field col-12 md:col-6 lg:col-4">
					<label htmlFor="addressLine2">Address Line 2</label>
					<InputText
						id="addressLine2"
						name="addressLine2"
						value={formik.values.addressLine2}
						onChange={formik.handleChange}
						className={classNames({
							"p-invalid": isFormFieldValid("addressLine2"),
						})}
					/>
					{getFormErrorMessage("addressLine2")}
				</div>
				<div className="field col-12 md:col-6 lg:col-4">
					<label htmlFor="addressLine3">Address Line 3</label>
					<InputText
						id="addressLine3"
						name="addressLine3"
						value={formik.values.addressLine3}
						onChange={formik.handleChange}
						className={classNames({
							"p-invalid": isFormFieldValid("addressLine3"),
						})}
					/>
					{getFormErrorMessage("addressLine3")}
				</div>
				<div className="field col-12 md:col-6 lg:col-4">
					<label htmlFor="stateId">State*</label>
					<Dropdown
						id="stateId"
						name="stateId"
						value={formik.values.stateId}
						onChange={formik.handleChange}
						options={stateData.map((item) => ({
							label: item.name,
							value: item.id,
						}))}
						filter
						className={classNames(
							{
								"p-invalid": isFormFieldValid("stateId"),
							},
							"border-round-md"
						)}
					/>
					{getFormErrorMessage("stateId")}
				</div>
				<div className="field col-12 md:col-6 lg:col-4">
					<label htmlFor="city">City*</label>
					<InputText
						id="city"
						name="city"
						value={formik.values.city}
						onChange={formik.handleChange}
						className={classNames({
							"p-invalid": isFormFieldValid("city"),
						})}
					/>
					{getFormErrorMessage("city")}
				</div>
				<div className="field col-12 md:col-6 lg:col-4">
					<label htmlFor="pincode">Pincode*</label>
					<InputText
						id="pincode"
						name="pincode"
						value={formik.values.pincode}
						onChange={formik.handleChange}
						className={classNames({
							"p-invalid": isFormFieldValid("pincode"),
						})}
					/>
					{getFormErrorMessage("pincode")}
				</div>
				<div className="flex justify-content-end align-items-center w-full">
					<div className="col-12 md:col-3 lg:col-3 mt-3 flex">
						<Button
							type="submit"
							label="Update"
							loading={isLoading}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default BasicDetails;
