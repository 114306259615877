import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import React, { useRef, useState } from "react";

// import FileSaver from "file-saver";

import { Toast } from "primereact/toast";
import { LeadService } from "../../../../../services/LeadService";
import { exportExcel } from "../../../../../components/XlsxExport";
import { ProgressBar } from "primereact/progressbar";
import { read, utils } from "xlsx";
import { Messages } from "primereact/messages";
const UploadFile = (props) => {
	const toast = useRef(null);
	const leadService = new LeadService();
	const messages = useRef(null);
	const [loading, setLoading] = useState(false);

	const onUploadImage = (e) => {
		setLoading(true);
		let excelFile = e.files[0];
		const reader = new FileReader();

		reader.onload = (evt) => {
			const bstr = evt.target.result;

			const wb = read(bstr, { type: "binary" });
			const wsname = wb.SheetNames[0];

			const ws = wb.Sheets[wsname];
			const data = utils.sheet_to_json(ws, { header: 1 });

			const newData = data.slice(1).map((r) =>
				r.reduce((acc, x, i) => {
					acc[data[0][i]] = x;
					return acc;
				}, {})
			);
			props.excelData(newData);
		};
		reader.readAsBinaryString(excelFile);

		const formData = new FormData();
		formData.append("file", excelFile);
		leadService
			.bulkLeadStatusFileUpdate(formData)
			.then((res) => {
				if (res.errors) {
					messages.current.show({
						severity: "error",
						summary: "",
						detail: (
							<ul>
								{res?.errors.map((item, index) => (
									<li className="m-0" key={index}>
										{item}
									</li>
								))}
							</ul>
						),
						sticky: true,
					});
				} else {
					toast.current.show({
						severity: "success",
						summary: "Successfull",
						detail: "Document uploaded",
						style: { color: "#000000" },
						life: 3000,
					});

					props.handelStages();
				}

				// e.clear();
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoading(false));
	};
	const progressTemplate = () => {
		if (loading) {
			return (
				<ProgressBar mode="indeterminate" style={{ height: "4px" }} />
			);
		} else {
			return <></>;
		}
	};
	return (
		<>
			<div>
				<Toast ref={toast} position="top-left" />

				<div className="c-download-pw">
					<h4>Steps:</h4>
					<div>
						<Button
							icon="pi pi-download"
							label="Download"
							onClick={() =>
								exportExcel(props.listData, "Lead list")
							}
						/>
					</div>
					<ol>
						<li>
							Download the Lead list shown in the table and save
							it to your computer
						</li>
						<li>
							Update lead status{" "}
							<strong className="text-red-600">
								(Do not change any column heading){" "}
							</strong>
							, status options are:
							<ul className="mt-2">
								<li>"NEW"</li>
								<li>"NOT_INTERESTED"</li>
								<li>"NOT_ELIGIBLE"</li>
								<li>"FOLLOW_UP"</li>
								<li>"CONVERTED"</li>
							</ul>
						</li>
						<li>Upload the file from your computer and save</li>
					</ol>
				</div>
				<Messages ref={messages}></Messages>
				<FileUpload
					customUpload
					accept=".xlsx,.docx,"
					uploadHandler={(e) => onUploadImage(e)}
					maxFileSize={1000000}
					progressBarTemplate={progressTemplate}
					emptyTemplate={
						<p className="m-0">
							Drag and drop files to here to upload.
						</p>
					}
				/>
			</div>
		</>
	);
};

export default UploadFile;
