import React, { useState } from "react";
import { Button } from "primereact/button";
import AddContact from "./AddContact";
import AddContract from "./AddContract";
import BasicDetails from "./BasicDetails";
import Header from "./header";
import { useNavigate } from "react-router-dom";

const AddFinancialInstitutions = () => {
	let [num, setNum] = useState(1);
	const navigation = useNavigate();
	const [stages, setstages] = useState([
		{
			sequence: 1,
			title: "General Information",
			status: "active",
		},
		{
			sequence: 2,
			title: "Contact Details /RM/ASM",
			status: "inactive",
		},
		{
			sequence: 3,
			title: "Contract",
			status: "inactive",
		},
	]);

	const [id, setId] = useState(null);
	const [stageName, setstageName] = useState("General Information");
	const getId = (item) => {
		setId(item);
	};
	const handelStages = () => {
		if (num < stages.length) {
			let newStage = [...stages];
			let sequence = num + 1;
			let index = newStage.findIndex(
				(item) => item.sequence === sequence
			);
			newStage[index].status = "active";
			newStage[index - 1].status = "success";
			setstageName(newStage[index].title);
			setstages(newStage);
			setNum(sequence);
		}
	};

	return (
		<div className="">
			<div className="flex justify-content-end align-items-center w-full">
				<Button
					type="button"
					icon="pi pi-arrow-left"
					iconPos="left"
					label="Back"
					onClick={() =>
						navigation("/partners/financial-institution/list")
					}
					className="mb-3"
				/>
			</div>
			<Header
				stages={stages}
				title="Add Financial Institutions"
				stageName={stageName}
			/>
			{num === 1 ? (
				<BasicDetails getId={getId} handelStages={handelStages} />
			) : num === 2 ? (
				<AddContact id={id} handelStages={handelStages} />
			) : num === 3 ? (
				<AddContract id={id} handelStages={handelStages} />
			) : null}
		</div>
	);
};

export default AddFinancialInstitutions;
