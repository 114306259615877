import React from "react";
import SubmitNewLeadDialog from "./SubmitNewLeadDialog";
import { useLocation } from "react-router-dom";
import Config from "../../../../shared/config";
import SubmitNewInsuranceLeadDialog from "./SubmitInsuranceLead";

const CreateLead = () => {
	const locaiton = useLocation();
	return locaiton?.state?.productType === Config.PRODUCT_LEAD_TYPE.LOAN ? (
		<SubmitNewLeadDialog location={locaiton} type={"create"} />
	) : (
		<SubmitNewInsuranceLeadDialog location={locaiton} type={"create"} />
	);
};

export default CreateLead;
