import React, { useEffect, useReducer, useRef, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { produce } from "immer";
import groupBy from "lodash/groupBy";
// Internal Import
import { ProductService } from "../../../services/ProductService";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import Config from "../../../shared/config";
import parallel from "async/parallel";

const ProductDocumentDialoge = ({ id, productDocumentClose }) => {
	const [loading1, setLoading1] = useState(false);
	const toast = useRef(null);
	const productService = new ProductService();
	const [data, setData] = useReducer(dataReducer, fetchInitialState);
	const reducer = produce((state, action) => {
		switch (action.type) {
			case "ADD":
				const checkId = state.productList.find(
					(item) => item === action.payload
				);
				const newArr = state.productList.filter(
					(item) => item !== action.payload
				);
				if (checkId === undefined) {
					state.productList.push(action.payload);
				} else {
					state.productList = newArr;
				}
				break;
			case "INITIALISE":
				state.productList = action.payload;
				break;
			default:
				return state;
		}
	});

	const [state, dispatch] = useReducer(reducer, { productList: [] });
	const getproductDocument = () => {
		setData({ type: Config.FETCH_CONFIG.start });
		productService
			.getProductDocument()
			.then((res) => {
				const data = groupBy(res, "type");
				setData({ type: Config.FETCH_CONFIG.success, payload: data });
			})
			.catch((e) => {
				setData({ type: Config.FETCH_CONFIG.error, payload: e });
			})
			.finally(() => setData({ type: Config.FETCH_CONFIG.stop }));
	};

	const getProductCheck = () => {
		productService
			.getexistingProductDocument(id)
			.then((res) => {
				dispatch({
					type: "INITIALISE",
					payload: res.map((item) => item.documentTypeId.id),
				});
			})
			.catch((e) => {})
			.finally(() => {});
	};

	const onCategoryChange = (e) => {
		dispatch({ type: "ADD", payload: e.value.id });
	};

	useEffect(() => {
		// parallel([getproductDocument(), getProductCheck()]);
		let ignore = false;
		parallel(
			[
				function (cb) {
					if (!ignore) {
						getproductDocument();
					}

					cb(null, 1);
				},
				function (cb) {
					if (!ignore) {
						getProductCheck();
					}

					cb(null, 2);
				},
			],
			function (err, result) {
				err &&
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: "Error",
						life: 3000,
					});
			}
		);
		return () => {
			ignore = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handelSubmit = () => {
		setLoading1(true);
		const submitDocumentData = {
			subProductId: id,
			documentTypeId: state.productList,
		};

		productService
			.productDocumentSubmit(submitDocumentData)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "Document Selected Successfully",
					life: 3000,
				});
			})
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				});
			})
			.finally(() => {
				setLoading1(false);
			});
	};

	return (
		<div className="card modal-form-grid">
			{data.isLoading ? (
				<div className=" flex py-4 justify-content-center">
					<ProgressSpinner
						style={{ width: "30px", height: "30px" }}
						strokeWidth="4"
					/>
				</div>
			) : (
				<>
					<Toast ref={toast} />
					<Accordion
						activeIndex={0}
						expandIcon="pi pi-plus-circle"
						collapseIcon="pi pi-minus-circle"
					>
						{Object.entries(data.data).map((item, index) => (
							<AccordionTab
								header={item[0]
									.replace(/_/g, " ")
									.toLowerCase()}
								headerClassName="capitalize"
								key={`${index}`}
							>
								<>
									<div className="grid">
										{item[1].map((category) => (
											<div
												key={category.id}
												className="flex align-items-center col-12 md:col-6 lg:col-4"
											>
												<Checkbox
													value={category}
													onChange={onCategoryChange}
													checked={state.productList.some(
														(item) =>
															item === category.id
													)}
												/>
												<label className="ml-2 capitalize">
													{category.name.toLowerCase()}
												</label>
											</div>
										))}
									</div>
									<div className="grid py-0">
										<div className="col-12 flex justify-content-end py-0">
											<Button
												className="mt-2"
												icon="pi pi-save"
												label="Save"
												onClick={handelSubmit}
												loading={loading1}
											/>
										</div>
									</div>
								</>
							</AccordionTab>
							// <div key={`${index}`} className="col-12 md:col-4">
							// 	<div className="flex flex-column gap-3">
							// 		<h3>
							// 			{item[0].replace(/_/g, " ").toUpperCase()}
							// 		</h3>
							// 		{item[1].map((category) => (
							// 			<div
							// 				key={category.id}
							// 				className="flex align-items-center"
							// 			>
							// 				<Checkbox
							// 					value={category}
							// 					onChange={onCategoryChange}
							// 					checked={state.productList.some(
							// 						(item) => item === category.id
							// 					)}
							// 				/>
							// 				<label className="ml-2">
							// 					{category.name}
							// 				</label>
							// 			</div>
							// 		))}
							// 	</div>
							// </div>
						))}
					</Accordion>
				</>
			)}
		</div>
	);
};

export default ProductDocumentDialoge;
