import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useReducer, useRef, useState } from "react";
import {
	FeildCalender,
	FeildDropdown,
	FieldCheckBox,
	FieldInput,
	FieldRadio,
} from "../../../../components/FieldInput";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import Config from "../../../../shared/config";
import { LoanApplicationService } from "../../../../services/LoanApplication";
import { RadioButton } from "primereact/radiobutton";
import { useSelector } from "react-redux";
import { useGetAllDIstrictQuery } from "../../../../services/query/queryApi";
import selectState from "../../../../components/stateList";
import { Skeleton } from "primereact/skeleton";
import Header from "../../../partners/institutions/header";
import {
	convertNullToString,
	convertObjNull,
} from "../../../../utils/UtilsFunction";
import * as Yup from "yup";
import Loader from "../../../../components/Loader";
import { Toast } from "primereact/toast";
import moment from "moment";
import { LoanService } from "../../../../services/LoanService";
import { useNavigate } from "react-router-dom";
import {
	dataReducer,
	fetchInitialState,
} from "../../../../components/fetchReducer";
import series from "async/series";
import { ConnectedFocusError } from "focus-formik-error";
import { Dialog } from "primereact/dialog";
import PrintHospicash from "./PrintHospicash";

const validationSchema = Yup.object().shape({
	applicantName: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid first name"
		)
		.nullable(),
	pan: Yup.string()
		.matches(
			/([A-Z]{5}[0-9]{4}[A-Z]{1})/,
			"Please enter valid pancard number"
		)
		.nullable(),
	city: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid city"
		)
		.nullable(),
	pincode: Yup.string()
		.matches(/^\d{6}$/, "Please enter valid pincode")
		.nullable(),
	mobileNumber: Yup.string()
		.matches(/^[1-9]\d{9}$/gi, "Mobile number should be 10 digit number")
		.nullable(),
	emailId: Yup.string().trim().email("Invalid email").nullable(),
	kidNomineeName: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Please enter valid first name"
		)
		.nullable(),
	// plan: Yup.boolean().required("Select one filed required").nullable(),
	coApplicant: Yup.array().of(
		Yup.object().shape({
			coApplicantName: Yup.string()
				.required("Name is required.")
				.matches(
					/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
					"Invalid name."
				),
			coApplicantGender: Yup.string()
				.nullable()
				.required("Gender is required."),
			coApplicantDob: Yup.string().required("DOB is required."),
			coApplicantNomineeName: Yup.string().matches(
				/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
				"Invalid name."
			),
			relationshipWithApplicant: Yup.string()
				.nullable()
				.required("Relationship is required."),
			mobileNumber: Yup.string()
				.required("Mobile No. is required.")
				.min(10, "Mobile No. should be 10 digit")
				.max(10, "Mobile No. should not be more than 10 digit")
				.matches(
					/^[1-9]\d{9}$/gi,
					"Mobile number should be 10 digit number"
				),
		})
	),
	kidApplicantList: Yup.array().of(
		Yup.object().shape({
			kidName: Yup.string()
				.required("Name is required.")
				.matches(
					/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
					"Invalid name."
				),
			gender: Yup.string().nullable().required("Gender is required."),
			dob: Yup.string().required("DOB is required."),
			relationshipWithApplicant: Yup.string().required(
				"Relationship is required."
			),
			mobileNumber: Yup.string()
				.matches(
					/^[1-9]\d{9}$/gi,
					"Mobile number should be 10 digit number"
				)
				.nullable(),
		})
	),
});

const Insurance = ({ propsId = null, urlData }) => {
	const navigate = useNavigate();
	const persistSelector = useSelector((state) => state.persistData);
	// const stateSelector = useSelector((state) => state.loanApplication.data);
	const toastTL = useRef(null);
	const {
		data: getAllDistrict = [
			{ districtList: [{ id: null, name: "" }], id: null, name: "" },
		],
		isLoading,
	} = useGetAllDIstrictQuery();
	// const selector = useSelector((state) => state.lead);
	const [applicationId, setApplicationId] = useState(null);
	const loanApplicationService = new LoanApplicationService();
	const loanService = new LoanService();
	const [printState, setPrintState] = useState(false);
	const [stages] = useState([
		{
			sequence: 1,
			title: "Select a Program",
			status: "success",
		},
		{
			sequence: 2,
			title: "Add Contact",
			status: "success",
		},
		{
			sequence: 3,
			title: "Select District",
			status: "success",
		},
		{
			sequence: 4,
			title: "Save Application",
			status: "active",
		},
	]);
	const [planDetails, setPlanDetails] = useReducer(
		dataReducer,
		fetchInitialState
	);
	const [hospiState, hospiDispatch] = useReducer(dataReducer, {
		data: { coApplicant: [], kidApplicantList: [], program: {} },
		isLoading: false,
		error: false,
		errorMessage: null,
	});

	const [loader, setLoader] = useState(false);
	const initialValues = {
		customerId: null,
		status: "DRAFT",
		plan: "",
		branch: "",
		uniqueId: "",
		uniqueIdentificationNumber: "",
		enrollmentDate: "",
		applicantName: "",
		gender: "Male",
		dob: "",
		pan: urlData?.state?.pan,
		ghcSumInsured: "",
		occupationProfession: "",
		policyTerm: "",
		relationshipWithPrimaryInsured: "",
		address: "",
		city: "",
		state: urlData?.state?.state,
		district: urlData?.state?.district,
		coApplicantAllowed: false,
		pincode: "",
		mobileNumber: urlData?.state?.mobileNumber,
		emailId: "",
		kidNomineeName: "",
		kidsGender: "",
		kidsDob: "",
		relationshipWithKid: "",
		premiumAmount: "",
		agentCode: null,
		leadId: null,
		// program: {
		// 	id: urlData?.state?.programId,
		// },
		coApplicant: [],
		kidApplicantList: [],
		...(propsId !== null &&
			hospiState.data && {
				...hospiState.data,
				...(planDetails.data.length > 0 && {
					plan:
						planDetails.data.find(
							(item) => item.id === hospiState.data.planId
						) !== undefined
							? planDetails.data.find(
									(item) => item.id === hospiState.data.planId
							  ).value
							: "",
				}),

				dob:
					hospiState.data.dob && hospiState.data.dob !== ""
						? new Date(hospiState.data.dob)
						: "",

				enrollmentDate:
					hospiState.data.enrollmentDate &&
					hospiState.data.enrollmentDate !== ""
						? new Date(hospiState.data.enrollmentDate)
						: "",
				kidApplicantList:
					hospiState.data &&
					hospiState.data.kidApplicantList.map((item) => ({
						...item,
						dob:
							item.dob && item.dob !== ""
								? new Date(item.dob)
								: "",
					})),
				...(hospiState.data.coApplicant.length > 0 && {
					coApplicantAllowed: true,
				}),
				coApplicant: hospiState.data.coApplicant.map((item) => ({
					...item,
					coApplicantDob:
						item.coApplicantDob && item.coApplicantDob !== ""
							? new Date(item.coApplicantDob)
							: "",
					coApplicantNomineeDob:
						item.coApplicantNomineeDob &&
						item.coApplicantNomineeDob !== ""
							? new Date(item.coApplicantNomineeDob)
							: "",
				})),
			}),
	};

	const handleFormSubmit = (value) => {
		const { plan, coApplicantAllowed, ...rest } = value;

		const newData = convertObjNull(rest);
		const coApplicantData = newData.coApplicant.map((item) => {
			let tempCoData = convertObjNull(item);
			return {
				...tempCoData,
				coApplicantDob: item.coApplicantDob
					? moment(item.coApplicantDob).format("YYYY-MM-DD")
					: null,
				coApplicantNomineeDob: item.coApplicantNomineeDob
					? moment(item.coApplicantNomineeDob).format("YYYY-MM-DD")
					: null,
			};
		});
		const kidApplicantData = newData.kidApplicantList.map((item) => {
			let tempKidData = convertObjNull(item);
			return {
				...tempKidData,
				dob: item.dob ? moment(item.dob).format("YYYY-MM-DD") : null,
			};
		});
		const requestBody = {
			...(propsId === null && applicationId && { id: applicationId }),

			...newData,
			enrollmentDate: newData.enrollmentDate
				? moment(newData.enrollmentDate).format("YYYY-MM-DD")
				: null,
			dob: newData.dob ? moment(newData.dob).format("YYYY-MM-DD") : null,
			pincode: newData.pincode ? Number(newData.pincode) : null,

			ghcSumInsured: Number(
				plan.insuredValue *
					(1 +
						newData.coApplicant.length +
						newData.kidApplicantList.length)
			),
			premiumAmount: Number(
				newData.policyTerm === 2
					? plan.secondYearAdult * (1 + newData.coApplicant.length) +
							plan.secondYearKid * newData.kidApplicantList.length
					: plan.firstYearAdult * (1 + newData.coApplicant.length) +
							plan.firstYearKid * newData.kidApplicantList.length
			),
			coApplicant: coApplicantData,
			kidApplicantList: kidApplicantData,
			customerId: Number(newData.customerId),
			planId: plan.id,
		};
		if (!requestBody.planId) {
			return toastTL.current.show({
				severity: "error",
				summary: "Error",
				detail: "Select plan",
				style: { color: "#000000" },
				life: 3000,
			});
		} else {
			setLoader(true);
			loanApplicationService
				.insuranceDraftSave(requestBody)
				.then((res) => {
					toastTL.current.show({
						severity: "success",
						summary: "Successfull",
						detail: "Successfull",
						style: { color: "#000000" },
						life: 3000,
					});
					setApplicationId(res);
				})
				.catch((e) => {
					toastTL.current.show({
						severity: "error",
						summary: e.name,
						detail: e.message,
						style: { color: "#000000" },
						life: 3000,
					});
				})
				.finally(() => {
					setLoader(false);
					propsId && getData(propsId);
				});
		}
	};
	const finalSubmit = () => {
		setLoader(true);
		loanService
			.applicationLock(propsId ? propsId : applicationId)
			.then((res) =>
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				})
			)

			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: e.name,
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				navigate("/vendor/application-list");
				setLoader(false);
			});
	};
	const getData = (id) => {
		series([
			function (cb) {
				hospiDispatch({ type: Config.FETCH_CONFIG.start });
				if (propsId) {
					loanApplicationService
						.getHospicasData(id)
						.then((res) => cb(null, res))
						.finally(() =>
							hospiDispatch({ type: Config.FETCH_CONFIG.stop })
						);
				} else {
					cb(null, {});
				}
			},
			function (cb) {
				setPlanDetails({ type: Config.FETCH_CONFIG.start });
				loanApplicationService
					.getInsurancePlan()
					.then((res) => cb(null, res))
					.finally(() =>
						setPlanDetails({ type: Config.FETCH_CONFIG.stop })
					);
			},
		])
			.then((result) => {
				const newData1 = convertNullToString(result[0]);
				hospiDispatch({
					type: Config.FETCH_CONFIG.success,
					payload: newData1,
				});
				const newData = result[1].map((e) => ({ ...e, value: e }));
				setPlanDetails({
					type: Config.FETCH_CONFIG.success,
					payload: newData,
				});
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			);
	};

	useEffect(() => {
		// if (propsId) {
		getData(propsId);
		// } else {
		// 	setPlanDetails({ type: Config.FETCH_CONFIG.start });
		// 	loanApplicationService
		// 		.getInsurancePlan()
		// 		.then((res) => {
		// 			const newData = res.map((e) => ({ ...e, value: e }));
		// 			setPlanDetails({
		// 				type: Config.FETCH_CONFIG.success,
		// 				payload: newData,
		// 			});
		// 		})
		// 		.finally(() =>
		// 			setPlanDetails({ type: Config.FETCH_CONFIG.stop })
		// 		);
		// }
		// loanApplicationService.getInsurancePlan().then((res) => {
		// 	const newData = res.map((e) => ({ ...e, value: e }));
		// 	setPlanDetails(newData);
		// });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const getDistrictData = (e) => {
		const stateData = getAllDistrict.find((item) => item.id === e);
		if (stateData !== undefined) {
			const districtData = stateData.districtList.map((e) => ({
				...e,
				value: e.id,
			}));
			return districtData;
		} else {
			return [];
		}
	};

	return (
		<>
			{loader && <Loader />}
			<Toast ref={toastTL} />
			<Dialog
				visible={printState}
				style={{ width: "80%" }}
				onHide={() => setPrintState(false)}
			>
				{propsId !== null && hospiState.data && (
					<PrintHospicash
						data={hospiState.data}
						planDetails={planDetails.data}
					/>
				)}
			</Dialog>
			<div className="flex align-items-center gap-2 pb-4 justify-content-end">
				<Button
					label="Export"
					className="p-button-danger"
					icon="pi pi-file-pdf"
					onClick={() => setPrintState(true)}
				/>
				<Button
					icon="pi pi-arrow-left"
					label="Back"
					onClick={() => navigate("/vendor/application-list")}
				/>
			</div>
			<Header
				stages={propsId === null ? stages : []}
				title="Application"
				stageName={urlData?.state?.applicationName}
			/>

			<Formik
				initialValues={initialValues}
				onSubmit={handleFormSubmit}
				validationSchema={validationSchema}
				enableReinitialize
			>
				{({ handleSubmit, values, setFieldValue }) => (
					<Form onSubmit={handleSubmit} className="mt-5">
						<ConnectedFocusError />
						<Panel
							header={"Plan Details"}
							className={"my-2"}
							toggleable
						>
							<div className="grid form-grid">
								{planDetails.isLoading ? (
									<>
										<div className="col-3">
											<Skeleton className="h-11rem" />
										</div>
										<div className="col-3">
											<Skeleton className="h-11rem" />
										</div>
										<div className="col-3">
											<Skeleton className="h-11rem" />
										</div>
										<div className="col-3">
											<Skeleton className="h-11rem" />
										</div>
									</>
								) : (
									planDetails.data.map((item) => (
										<div
											className="col-12 md:col-3"
											key={item.id}
										>
											<div className="c-card-box-in card">
												<div className="c-card-radio">
													<RadioButton
														// {...item}
														value={item.value}
														checked={
															values.plan ===
															item.value
														}
														onChange={(e) => {
															setFieldValue(
																"plan",
																e.value
															);
														}}
													/>
													<label> {item.name}</label>
												</div>

												<div
													htmlFor={item.id}
													className="ml-2"
												>
													<ul>
														<li>
															First Year Adult: ₹
															{
																item.firstYearAdult
															}
														</li>

														<li>
															First Year Kid: ₹
															{item.firstYearKid}
														</li>
														<li>
															Second Year Adult: ₹
															{
																item.secondYearAdult
															}
														</li>
														<li>
															Second Year Kid: ₹
															{item.secondYearKid}
														</li>
													</ul>
												</div>
											</div>
										</div>
									))
								)}

								<div className="field col-12 md:col-3">
									<label className="block text-sm mt-auto mb-auto mr-2">
										Select Policy Terms Years
									</label>
									<Field
										// header={"Select Policy Terms Years"}
										name={"policyTerm"}
										component={FieldRadio}
										radiolist={[
											{
												label: "One Year",
												name: "One Year",
												value: 1,
											},
											{
												label: "Two Years",
												name: "Two Years",
												value: 2,
											},
										]}
									/>
								</div>
								<div className="field col-12">
									<div className="flex align-items-end justify-content-between">
										<div className="flex">
											<div className="c-card-box-in card">
												<div className="c-card-radio">
													<label>
														GSC Sum Insured
													</label>
												</div>
												<div className="ml-2 insure-amt">
													₹{" "}
													{values.plan
														? values.plan
																.insuredValue *
														  (1 +
																values
																	.coApplicant
																	.length +
																values
																	.kidApplicantList
																	.length)
														: "0"}
												</div>
											</div>
											<div className="c-card-box-in card ml-3">
												<div className="c-card-radio">
													<label>
														Premium Amount
													</label>
												</div>
												<div className="ml-2 insure-amt">
													₹{" "}
													{values.policyTerm &&
													values.plan
														? values.policyTerm ===
														  2
															? values.plan
																	.secondYearAdult *
																	(1 +
																		values
																			.coApplicant
																			.length) +
															  values.plan
																	.secondYearKid *
																	values
																		.kidApplicantList
																		.length
															: values.plan
																	.firstYearAdult *
																	(1 +
																		values
																			.coApplicant
																			.length) +
															  values.plan
																	.firstYearKid *
																	values
																		.kidApplicantList
																		.length
														: "0"}
												</div>
											</div>
										</div>
										{persistSelector?.applicationState ===
											"edit" && (
											<Button
												type="submit"
												label="Save as draft"
											/>
										)}
									</div>
								</div>
							</div>
						</Panel>

						<Panel
							header={"Application Details"}
							className={"my-2"}
							toggleable
						>
							<div className="card">
								<div className="grid form-grid">
									<div className="field col-12 md:col-3">
										<Field
											header={"Branch"}
											component={FeildDropdown}
											name={"branch"}
											options={[
												{
													name: "Kolkata",
													value: "KOLKATA",
												},
											]}
											optionLabel={"name"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Unique Id"}
											component={FieldInput}
											name={"uniqueId"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={
												"Unique Identification Number"
											}
											component={FieldInput}
											name={"uniqueIdentificationNumber"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Enrollment Date"}
											component={FeildCalender}
											name={"enrollmentDate"}
											star={"*"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Applicant Name"}
											component={FieldInput}
											name={"applicantName"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Gender"}
											component={FeildDropdown}
											name={"gender"}
											options={Config.GENDERS}
											optionLabel={"name"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"DOB"}
											component={FeildCalender}
											name={"dob"}
											maxDate={
												new Date(
													moment()
														.subtract(18, "year")
														.toDate()
												)
											}
											viewDate={
												new Date(
													moment()
														.subtract(18, "year")
														.toDate()
												)
											}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"PAN"}
											component={FieldInput}
											name={"pan"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Profession"}
											component={FeildDropdown}
											name={"occupationProfession"}
											options={
												Config.OccupationProfession
											}
											optionLabel={"name"}
										/>
									</div>
									{/* <div className="field col-12 md:col-3">
										<Field
											header={"Policy Term"}
											component={FeildDropdown}
											name={"policyTerm"}
											options={Config.PolicyTerm}
											optionLabel={"name"}
										/>
									</div> */}
									<div className="field col-12 md:col-3">
										<Field
											header={
												"Relationship With Primary Insured"
											}
											component={FeildDropdown}
											options={
												Config.RelationshipWithApplicant
											}
											optionLabel={"name"}
											name={
												"relationshipWithPrimaryInsured"
											}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Address"}
											component={FieldInput}
											name={"address"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"City"}
											component={FieldInput}
											name={"city"}
										/>
									</div>
									{isLoading ? (
										<Skeleton className="w-full flex-1 h-3rem mt-4 " />
									) : (
										<div className="field col-12 md:col-3">
											<Field
												header={"State"}
												component={FeildDropdown}
												name={"state"}
												options={selectState(
													getAllDistrict
												)}
												optionLabel={"name"}
												filter
											/>
										</div>
									)}
									{isLoading ? (
										<Skeleton className="w-full flex-1 h-3rem mt-4 " />
									) : (
										<>
											{values.state && (
												<div className="field col-12 md:col-3">
													<Field
														header={"District"}
														component={
															FeildDropdown
														}
														name={"district"}
														options={getDistrictData(
															values.state
														)}
														optionLabel={"name"}
													/>
												</div>
											)}
										</>
									)}

									<div className="field col-12 md:col-3">
										<Field
											header={"Pincode"}
											component={FieldInput}
											name={"pincode"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Mobile Number"}
											component={FieldInput}
											name={"mobileNumber"}
											type={"number"}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Email"}
											component={FieldInput}
											name={"emailId"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"kid Nominee Name"}
											component={FieldInput}
											name={"kidNomineeName"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Kids Gender"}
											component={FeildDropdown}
											name={"kidsGender"}
											options={Config.GENDERS}
											optionLabel={"name"}
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Relationship With Kid"}
											component={FeildDropdown}
											options={
												Config.RelationshipWithApplicant
											}
											optionLabel={"name"}
											name={"relationshipWithKid"}
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Co-Applicant Allowed"}
											name={"coApplicantAllowed"}
											component={FieldCheckBox}
											onChange={(e) => {
												setFieldValue(
													"coApplicantAllowed",
													e.checked
												);
												if (
													values.coApplicantAllowed ===
													true
												) {
													setFieldValue(
														"coApplicant",
														[],
														true
													);
												}
											}}
											checked={values.coApplicant}
										/>
									</div>
								</div>

								<div className="col-12 flex align-items-end justify-content-end">
									{persistSelector?.applicationState ===
										"edit" && (
										<Button
											type="submit"
											label="Save as draft"
										/>
									)}
								</div>
							</div>
						</Panel>
						{values.coApplicantAllowed && (
							<Panel
								header={"Co-Application Details"}
								className={"my-2"}
								toggleable
							>
								<div className="card">
									<FieldArray
										name="coApplicant"
										render={(arrayHelper) => (
											<>
												{values.coApplicant &&
												values.coApplicant.length > 0
													? values.coApplicant.map(
															(item, index) => (
																<div
																	className="grid form-grid"
																	key={index}
																>
																	<div className="field col-12 md:col-3">
																		<Field
																			header={
																				"co-Applicant Name"
																			}
																			component={
																				FieldInput
																			}
																			name={`coApplicant.${index}.coApplicantName`}
																		/>
																	</div>
																	<div className="field col-12 md:col-3">
																		<Field
																			header={
																				"co-Applicant Gender"
																			}
																			component={
																				FeildDropdown
																			}
																			name={`coApplicant.${index}.coApplicantGender`}
																			options={
																				Config.GENDERS
																			}
																			optionLabel={
																				"name"
																			}
																		/>
																	</div>
																	<div className="field col-12 md:col-3">
																		<Field
																			header={
																				"co-Applicant DOB"
																			}
																			component={
																				FeildCalender
																			}
																			name={`coApplicant.${index}.coApplicantDob`}
																			maxDate={
																				new Date(
																					moment()
																						.subtract(
																							18,
																							"year"
																						)
																						.toDate()
																				)
																			}
																			viewDate={
																				new Date(
																					moment()
																						.subtract(
																							18,
																							"year"
																						)
																						.toDate()
																				)
																			}
																		/>
																	</div>
																	<div className="field col-12 md:col-3">
																		<Field
																			header={
																				"Relationship With Applicant"
																			}
																			component={
																				FeildDropdown
																			}
																			options={
																				Config.RelationshipWithApplicant
																			}
																			optionLabel={
																				"name"
																			}
																			name={`coApplicant.${index}.relationshipWithApplicant`}
																		/>
																	</div>
																	<div className="field col-12 md:col-3">
																		<Field
																			header={
																				"Mobile Number"
																			}
																			component={
																				FieldInput
																			}
																			name={`coApplicant.${index}.mobileNumber`}
																		/>
																	</div>
																	<div className="field col-12 md:col-3">
																		<Field
																			header={
																				"Email"
																			}
																			component={
																				FieldInput
																			}
																			name={`coApplicant.${index}.emailId`}
																		/>
																	</div>
																	<div className="field col-12 md:col-3">
																		<Field
																			header={
																				"co-Applicant Nominee Name"
																			}
																			component={
																				FieldInput
																			}
																			name={`coApplicant.${index}.coApplicantNomineeName`}
																		/>
																	</div>
																	<div className="field col-12 md:col-3">
																		<Field
																			header={
																				"co-Applicant Nominee Gender"
																			}
																			component={
																				FeildDropdown
																			}
																			name={`coApplicant.${index}.coApplicantNomineeGender`}
																			options={
																				Config.GENDERS
																			}
																			optionLabel={
																				"name"
																			}
																		/>
																	</div>
																	<div className="field col-12 md:col-3">
																		<Field
																			header={
																				"co-Applicant Nominee DOB"
																			}
																			component={
																				FeildCalender
																			}
																			name={`coApplicant.${index}.coApplicantNomineeDob`}
																			maxDate={
																				new Date(
																					moment()
																						.subtract(
																							18,
																							"year"
																						)
																						.toDate()
																				)
																			}
																			viewDate={
																				new Date(
																					moment()
																						.subtract(
																							18,
																							"year"
																						)
																						.toDate()
																				)
																			}
																		/>
																	</div>
																	<div className="field col-12 md:col-3">
																		<Field
																			header={
																				"co-Applicant Nominee Relationship"
																			}
																			component={
																				FeildDropdown
																			}
																			options={
																				Config.RelationshipWithApplicant
																			}
																			optionLabel={
																				"name"
																			}
																			name={`coApplicant.${index}.coApplicantNomineeRelationship`}
																		/>
																	</div>
																	<div className="col-12 flex align-items-end justify-content-end">
																		{persistSelector?.applicationState ===
																			"edit" && (
																			<Button
																				type="submit"
																				label="Save as draft"
																			/>
																		)}
																	</div>
																	{/* <Divider className="m-3" /> */}
																</div>
															)
													  )
													: null}
												{values.coApplicant.length ===
													0 && (
													<div
														className="add-more-btn flex align-items-center justify-content-center"
														onClick={() =>
															arrayHelper.push({
																coApplicantName:
																	"",
																coApplicantGender:
																	"",
																coApplicantDob:
																	"",
																relationshipWithApplicant:
																	"",
																mobileNumber:
																	"",
																emailId: "",
																coApplicantNomineeName:
																	"",
																coApplicantNomineeGender:
																	"",
																coApplicantNomineeDob:
																	"",
																coApplicantNomineeRelationship:
																	"",
															})
														}
													>
														<i className="pi pi-plus"></i>
														<span className="ml-2">
															Add Co-Applicant
														</span>
													</div>
												)}
											</>
										)}
									/>
								</div>
							</Panel>
						)}

						<Panel
							header={"Kid Application Details"}
							className={"my-2"}
							toggleable
						>
							<div className="card">
								<FieldArray
									name="kidApplicantList"
									render={(arrayHelper) => (
										<>
											{values.kidApplicantList &&
											values.kidApplicantList.length > 0
												? values.kidApplicantList.map(
														(item, index) => (
															<div
																className="grid form-grid"
																key={index}
															>
																<div className="field col-12 md:col-6 lg:col-3">
																	<Field
																		header={
																			"Kids Name"
																		}
																		component={
																			FieldInput
																		}
																		name={`kidApplicantList.${index}.kidName`}
																	/>
																</div>
																<div className="col-12 md:col-6 lg:col-3">
																	<div className="grid">
																		<div className="field col-12 md:col-5 pl-0">
																			<Field
																				header={
																					"Gender"
																				}
																				component={
																					FeildDropdown
																				}
																				name={`kidApplicantList.${index}.gender`}
																				options={
																					Config.GENDERS
																				}
																				optionLabel={
																					"name"
																				}
																			/>
																		</div>
																		<div className="field col-12 md:col-7 pl-0">
																			<Field
																				header={
																					"DOB"
																				}
																				component={
																					FeildCalender
																				}
																				name={`kidApplicantList.${index}.dob`}
																				minDate={moment()
																					.subtract(
																						25,
																						"year"
																					)
																					.toDate()}
																				maxDate={moment()
																					.subtract(
																						5,
																						"year"
																					)
																					.toDate()}
																				viewDate={moment()
																					.subtract(
																						5,
																						"year"
																					)
																					.toDate()}
																			/>
																		</div>
																	</div>
																</div>
																<div className="field col-12 md:col-6 lg:col-2 pl-0">
																	<Field
																		header={
																			"Relationship With Applicant"
																		}
																		component={
																			FeildDropdown
																		}
																		options={
																			Config.RelationshipWithApplicant
																		}
																		optionLabel={
																			"name"
																		}
																		name={`kidApplicantList.${index}.relationshipWithApplicant`}
																	/>
																</div>
																<div className="field col-12 md:col-6 lg:col-4 pl-0">
																	<div className="flex">
																		<div className="field flex-1">
																			<Field
																				header={
																					"Mobile Number"
																				}
																				component={
																					FieldInput
																				}
																				name={`kidApplicantList.${index}.mobileNumber`}
																			/>
																		</div>
																		<Button
																			type="button"
																			onClick={() =>
																				arrayHelper.remove(
																					index
																				)
																			}
																			style={{
																				marginTop:
																					"1.5rem",
																				marginLeft:
																					".5rem",
																			}}
																			icon="pi pi-trash"
																			className="p-button-outlined p-button-danger p-button-sm"
																			aria-label="Cancel"
																		/>
																	</div>
																</div>
																{/* <div className="field col-12 md:col-6 lg:col-1 pl-0">
																	
																</div> */}
															</div>
														)
												  )
												: null}
											{values.kidApplicantList.length <=
											3 ? (
												<div
													className="add-more-btn flex align-items-center justify-content-center"
													onClick={() =>
														arrayHelper.push({
															kidName: "",
															gender: "",
															dob: "",
															relationshipWithApplicant:
																"",
															mobileNumber: "",
														})
													}
												>
													<i className="pi pi-plus"></i>
													<span className="ml-2">
														Add Kid
													</span>
												</div>
											) : null}
										</>
									)}
								/>
								<div className="col-12 flex align-items-end justify-content-end pr-0">
									{persistSelector?.applicationState ===
										"edit" && (
										<Button
											type="submit"
											label="Save as draft"
										/>
									)}
								</div>
							</div>
						</Panel>
						<div className="grid">
							<div className="field col-12">
								<div className="flex align-items-end justify-content-between">
									<div className="flex">
										<div className="c-card-box-in card">
											<div className="c-card-radio">
												<label>GSC Sum Insured</label>
											</div>
											<div className="ml-2 insure-amt">
												₹{" "}
												{values.plan
													? values.plan.insuredValue *
													  (1 +
															values.coApplicant
																.length +
															values
																.kidApplicantList
																.length)
													: "0"}
											</div>
										</div>
										<div className="c-card-box-in card ml-3">
											<div className="c-card-radio">
												<label>Premium Amount</label>
											</div>
											<div className="ml-2 insure-amt">
												₹{" "}
												{values.policyTerm &&
												values.plan
													? values.policyTerm === 2
														? values.plan
																.secondYearAdult *
																(1 +
																	values
																		.coApplicant
																		.length) +
														  values.plan
																.secondYearKid *
																values
																	.kidApplicantList
																	.length
														: values.plan
																.firstYearAdult *
																(1 +
																	values
																		.coApplicant
																		.length) +
														  values.plan
																.firstYearKid *
																values
																	.kidApplicantList
																	.length
													: "0"}
											</div>
										</div>
									</div>
									{(applicationId || propsId) &&
										persistSelector?.applicationState ===
											"edit" && (
											<Button
												type="button"
												label="Save & Lock"
												icon="pi pi-save"
												onClick={() => finalSubmit()}
											/>
										)}
								</div>
							</div>
						</div>
						<div className="flex justify-content-between align-items-center w-full mt-4">
							{/* <div>
								<p className="font-bold">GSC Sum Insured</p>
								{values.plan.insuredValue *
									(1 +
										values.coApplicant.length +
										values.kidApplicantList.length)}
								<p className="font-bold">Premium Amount</p>
								{values.policyTerm === 2
									? values.plan.secondYearAdult *
											(1 + values.coApplicant.length) +
									  values.plan.secondYearKid *
											values.kidApplicantList.length
									: values.plan.firstYearAdult *
											(1 + values.coApplicant.length) +
									  values.plan.firstYearKid *
											values.kidApplicantList.length}
							</div> */}
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default Insurance;
