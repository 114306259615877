import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import Config from "../../../shared/config";
import { FeildDropdown, FieldInput } from "../../../components/FieldInput";
import { useGetCityListQuery } from "../../../services/query/queryApi";

const SearchHub = (props) => {
	const { data: cityList = [] } = useGetCityListQuery();
	const handleFormSubmit = (value) => {
		const newData = { ...value };
		let data = Object.fromEntries(
			Object.entries(newData).filter(([_, v]) => v !== "")
		);
		props.search({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: data,
		});
		props.search({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};
	const initialValue = {
		name: "",
		locationType: "",
		stateId: "",
		cityId: "",
	};
	const handleLocation = (val, setFieldValue) => {
		setFieldValue("stateId", "", true);
		setFieldValue("cityId", "", true);
		setFieldValue("locationType", val, true);
	};
	return (
		<div className="mb-3">
			<div className="background-secondary p-4 border-round-md my-3">
				<Formik
					initialValues={initialValue}
					onSubmit={handleFormSubmit}
				>
					{({ handleSubmit, values, resetForm, setFieldValue }) => (
						<Form className="grid" onSubmit={handleSubmit}>
							<div className="field col-12 md:col-4">
								<Field
									value={values.locationType}
									header={"Location Type"}
									name={"locationType"}
									component={FeildDropdown}
									onChange={(e) => {
										handleLocation(e.value, setFieldValue);
									}}
									options={Config.HUB_LOCATION_TYPE}
								/>
							</div>
							<div className="field col-12 md:col-4">
								<Field
									header={"Name"}
									name={"name"}
									component={FieldInput}
								/>
							</div>

							{values?.locationType === "STATE" && (
								<div className="field col-12 md:col-4">
									<Field
										header={"State"}
										name={"stateId"}
										component={FeildDropdown}
										options={props.stateList}
										optionLabel={"name"}
										optionValue={"id"}
										filter
									/>
								</div>
							)}
							{values?.locationType === "CITY" && (
								<div className="field col-12 md:col-4">
									<Field
										filter
										header={"City"}
										name={"cityId"}
										component={FeildDropdown}
										options={cityList}
										optionLabel={"name"}
										optionValue={"id"}
									/>
								</div>
							)}
							<div className="col-12 flex gap-3">
								<Button
									icon={"pi pi-refresh"}
									label={"Reset"}
									className="danger-btn"
									type="button"
									onClick={() => resetForm()}
								/>
								<Button label={"Search"} type="submit" />
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default SearchHub;
