import { Formik, Form, Field, FieldArray } from "formik";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { FeildDropdown, FieldInput } from "../../../../components/FieldInput";
import { useGetAllDIstrictQuery } from "../../../../services/query/queryApi";

const ContactDetails = (props) => {
	const validationSchema = Yup.object().shape({
		contacts: Yup.array().of(
			Yup.object().shape({
				name: Yup.string()
					.required("Name is required.")
					.matches(/^[a-zA-Z ]*$/, "Invalid name."),
				// .max(32, "Name not greater than 32 characters.")
				// .min(2, "Name not less than 2 characters."),
				mobile: Yup.string()
					.required("Mobile no is required.")
					.min(10, "Invalid mobile no.")
					.max(10, "Invalid mobile no."),
				email: Yup.string()
					.required("Email is required.")
					.email("Invalid email id."),
				stateId: Yup.number().required("State is required."),
				districtId: Yup.number().required("District is required."),
				designation: Yup.string().required("Desgination is required."),
			})
		),
	});

	const {
		data: getAllDistrict = [
			{ districtList: [{ id: null, name: "" }], id: null, name: "" },
		],
	} = useGetAllDIstrictQuery();

	const initialValues = {
		contacts: [
			{
				name: "",
				mobile: "",
				email: "",
				stateId: "",
				districtId: "",
				designation: "",
			},
		],
	};
	const getDistrictData = (e) => {
		const stateData = getAllDistrict.find((item) => item.id === e);

		if (stateData) {
			return stateData?.districtList;
		} else {
			return [];
		}
	};

	return (
		<>
			<Formik
				initialValues={{
					...initialValues,
					...(props?.data && { contacts: props?.data }),
				}}
				validationSchema={validationSchema}
				onSubmit={props.handleFormSubmit}
				enableReinitialize
			>
				{({ values, resetForm }) => (
					<Form>
						<FieldArray name="contacts">
							{({ insert, remove, push }) => (
								<div>
									{values.contacts.length > 0 &&
										values.contacts.map((item, index) => (
											<div
												className="contact-grid-card"
												key={index}
											>
												<span
													className="floating-icon-btn"
													onClick={() =>
														remove(index)
													}
												>
													<i className="pi pi-times-circle"></i>
												</span>
												<div className="p-fluid grid">
													<div className="field col-12 md:col-6 lg:col-4">
														<label
															htmlFor={`contacts.${index}.name`}
														>
															Full Name
															<span className="text-red-500 font-bold">
																*
															</span>
														</label>

														<div className="flex-column">
															<Field
																name={`contacts.${index}.name`}
																component={
																	FieldInput
																}
																type="text"
															/>
														</div>
													</div>
													<div className="field col-12 md:col-6 lg:col-4">
														<label
															htmlFor={`contacts.${index}.mobile`}
														>
															Mobile No.
															<span className="text-red-500 font-bold">
																*
															</span>
														</label>

														<div className="flex-column">
															<Field
																name={`contacts.${index}.mobile`}
																component={
																	FieldInput
																}
															/>
														</div>
													</div>
													<div className="field col-12 md:col-6 lg:col-4">
														<label
															htmlFor={`contacts.${index}.email`}
														>
															Email
															<span className="text-red-500 font-bold">
																*
															</span>
														</label>
														<Field
															name={`contacts.${index}.email`}
															component={
																FieldInput
															}
															type="email"
														/>
													</div>
													<div className="field col-12 md:col-6 lg:col-4">
														<label
															htmlFor={`contacts.${index}.designation`}
														>
															Designation
															<span className="text-red-500 font-bold">
																*
															</span>
														</label>
														<Field
															name={`contacts.${index}.designation`}
															component={
																FieldInput
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"State"}
															name={`contacts.${index}.stateId`}
															filter
															component={
																FeildDropdown
															}
															options={
																getAllDistrict
															}
															optionLabel={"name"}
															optionValue={"id"}
															star={"*"}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"District"}
															name={`contacts.${index}.districtId`}
															filter
															component={
																FeildDropdown
															}
															options={getDistrictData(
																values.contacts[
																	index
																].stateId
															)}
															optionValue={"id"}
															optionLabel={"name"}
														/>
													</div>
												</div>
											</div>
										))}
									<button
										type="button"
										className="add-more-btn flex align-items-center justify-content-center mb-3"
										onClick={() =>
											push({
												name: "",
												mobile: "",
												email: "",
												stateId: "",
												districtId: "",
												designation: "",
											})
										}
									>
										<i className="pi pi-plus"></i>
										<span className="ml-2">
											Contact Details
										</span>
									</button>
								</div>
							)}
						</FieldArray>

						<div className="flex justify-content-end align-items-center w-full">
							<Button
								type="button"
								label="Reset"
								className="p-button-danger mr-2"
								onClick={() => resetForm()}
								icon="pi pi-times"
							/>
							<Button
								type="submit"
								label="Save & next"
								icon="pi pi-save"
							/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default ContactDetails;
