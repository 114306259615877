import React, {
	useState,
	useEffect,
	useReducer,
	useRef,
	Suspense,
} from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { BiEdit, BiLink } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { InstitutionService } from "../../../services/InstitutionService";
import Paginator from "../../../components/Paginator";
import InstitutionDetails from "./InstitutionDetails";
import TableLoader from "../../../components/TableLoader";
import {
	dataReducer,
	fetchInitialState,
	// sortReducer,
} from "../../../components/fetchReducer";
import Config from "../../../shared/config";
import {
	searchInitialState,
	searchReducer,
} from "../../../components/searchReducer";
import { Field, FieldArray, Form, Formik } from "formik";
import {
	FeildDropdown,
	FieldInput,
	FieldTextArea,
} from "../../../components/FieldInput";
import { useGetStateListQuery } from "../../../services/query/queryApi";
// import { exportExcel } from "../../../components/XlsxExport";
import Loader from "../../../components/Loader";
import { Toast } from "primereact/toast";
import moment from "moment";
import DsaUpdate from "./components/DsaUpdate";
import * as Yup from "yup";
const DSAFinancialInstitutions = () => {
	const [show, setShow] = React.useState(false);
	const toastTL = useRef(null);
	const [loader, setLoader] = useState(false);
	const [expandedRows, setExpandedRows] = useState([]);
	let navigate = useNavigate();
	const [institution, setInstitution] = useReducer(
		dataReducer,
		fetchInitialState
	);
	// console.log(expandedRows);
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const [dsaDialog, setDsaDialog] = useState({ state: false, data: null });
	const [dsaDialogIns, setDsaDialogIns] = useState({
		state: false,
		data: null,
	});
	const { data = [] } = useGetStateListQuery();
	// const [columnName] = useState("name");
	// const [operation] = useState("LIKE");
	const [selectedId, setSelectedId] = useState(null);
	const [updateDialog, setUpdateDialog] = useState(false);
	const inistitutionService = new InstitutionService();
	// const sortInitialState = {
	// 	searchValue: {},
	// };
	// const [sortState, sortDispatch] = useReducer(sortReducer, sortInitialState);

	const itaration = {
		type2: "",
		type: "",
		name: "",
		alise: "",
		address: "",
		city: "",
		action: "",
	};
	useEffect(() => {
		let ignore = false;
		if (!ignore) {
			getInstitution();
		}
		return () => {
			ignore = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);

	function getInstitution() {
		let body = {
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.sortByColumn && searchState.sortType
				? {
						sorts: [
							searchState.sortByColumn +
								"," +
								searchState.sortType,
						],
				  }
				: { sorts: [] }),
			...(searchState.searchValue !== "" && searchState.searchValue),
		};
		setInstitution({ type: Config.FETCH_CONFIG.start });
		inistitutionService
			.getInstitutionDSA(body)
			.then((res) => {
				setInstitution({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((error) => {
				setInstitution({
					type: Config.FETCH_CONFIG.error,
					payload: error,
				});
			})
			.finally(() =>
				setInstitution({
					type: Config.FETCH_CONFIG.stop,
				})
			);
	}
	const createInstitution = (value) => {
		setLoader(true);
		const { details } = value;
		const newDetails = details?.map((item) => ({
			bankType: item.bankType,
			bankId: item.bankId,
			alias: item.alias,
			addressLine1: item.addressLine1,
			addressLine2: item.addressLine2,
			addressLine3: item.addressLine3,
			city: item.city,
			stateId: item.stateId,
			pincode: item.pincode,
		}));
		inistitutionService
			.addExtraGeneralDSAInstitution({
				details: newDetails,
				id: dsaDialogIns?.data?.id,
			})
			.then((res) => {
				getInstitution();
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoader(false));
	};
	function getSortableColumn(name, columnName) {
		return (
			<span className="flex align-items-center">
				{name}
				<span className="flex flex-column text-xs ml-2">
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "asc"
								? "sort-icon asc active"
								: "sort-icon asc"
						}
						onClick={() => tblSort(columnName, "asc")}
					></span>
					<span
						className={
							searchState.sortByColumn === columnName &&
							searchState.sortType === "desc"
								? "sort-icon desc active"
								: "sort-icon desc"
						}
						onClick={() => tblSort(columnName, "desc")}
					></span>
				</span>
			</span>
		);
	}
	function tblSort(columnName, sortType) {
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortByColumn,
			payload: columnName,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.sortType,
			payload: sortType,
		});
	}

	const increment = () => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.increment,
		});
	};
	const decrement = () => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.decrement,
		});
	};
	const pagesizechange = (e) => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};

	const getInstitutionDetails = (id) => {
		setSelectedId(id);
		setUpdateDialog(true);
	};

	//Table Template
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />
				<Column header={getSortableColumn("Type", "bankType")} />
				<Column header={getSortableColumn("Name", "bank.bankName")} />
				<Column header={getSortableColumn("Alias", "alias")} />
				<Column header="Address" />
				<Column header={getSortableColumn("City", "city")} />
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const actionTemplate = (item) => {
		return (
			<>
				<div className="flex">
					<Button
						className="icon-btn primary-btn mr-2"
						onClick={() => getInstitutionDetails(item.id)}
						tooltip="Edit Financial Institutions"
						tooltipOptions={{
							position: "left",
						}}
					>
						<BiEdit />
					</Button>
					<Button
						className="icon-btn status-warning"
						tooltip="Add Institutions Product"
						tooltipOptions={{
							position: "left",
						}}
						onClick={() =>
							navigate(
								`/partners/financial-institution-dsa/product-maping/${item.id}`,
								{
									state: expandedRows[0],
								}
							)
						}
					>
						<BiLink />
					</Button>
				</div>
			</>
		);
	};
	const addressTemplate = (item) => {
		return `${item.addressLine1 ? item.addressLine1 : ""} ${
			item.addressLine2 ? item.addressLine2 : ""
		} ${item.addressLine3 ? item.addressLine3 : ""}`;
	};

	const initialValue = {
		dsaName: "",
		dsaCode: "",
		rainbowDsaCode: "",
		address: "",
	};

	const handleForm = (val, action) => {
		let data = Object.fromEntries(
			Object.entries(val).filter(([_, v]) => v !== "")
		);
		// console.log({ ...data, pageNo: 1, pageSize: 10 });

		searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: data,
		});
		searchDispatch({ type: Config.SEARCH_CONFIG.pageNo, payload: 1 });
		// action.resetForm();
	};

	const handleReset = () => {
		searchDispatch({
			type: Config.SEARCH_CONFIG.reset,
		});
	};

	const typeTemplate = (item) => {
		return item?.bankType?.replaceAll("_", " ");
	};
	// const newData = (e) => {
	// 	const data = [...e];
	// 	const anotherData = data.map((elm) => ({
	// 		id: elm.id,
	// 		alias: elm.alias,
	// 		addressLine1: elm.addressLine1,
	// 		addressLine2: elm.addressLine2,
	// 		addressLine3: elm.addressLine3,
	// 		city: elm.city,
	// 		bankId: elm.bankId,
	// 		bankName: elm.bankName,
	// 		bankType: elm.bankType,
	// 		lastModifiedBy: elm.lastModifiedBy,
	// 		lastModifiedOn: elm.lastModifiedOn,
	// 		rainbowDsaCode: elm.rainbowDsaCode,
	// 		pincode: elm.pincode,
	// 		stateId: elm.stateId,
	// 		stateName: elm.stateName,
	// 	}));
	// 	return anotherData;
	// };
	// const getAllLoanData = () => {
	// 	const requestBody = {
	// 		pageNo: 1,
	// 		pageSize: 100000,
	// 	};

	// 	setLoader(true);
	// 	inistitutionService
	// 		.getInstitution(requestBody)
	// 		.then((res) => {
	// 			const newData =
	// 				res.data &&
	// 				res.data.map((elm) => ({
	// 					Alias: elm.alias,
	// 					AddressLine1: elm.addressLine1,
	// 					AddressLine2: elm.addressLine2,
	// 					AddressLine3: elm.addressLine3,
	// 					City: elm.city,
	// 					BankId: elm.bankId,
	// 					BankName: elm.bankName,
	// 					BankType: elm.bankType,
	// 					LastModifiedBy: elm.lastModifiedBy,
	// 					LastModifiedOn: elm.lastModifiedOn,
	// 					"Rainbow DSA Code": elm.rainbowDsaCode,
	// 					Pincode: elm.pincode,
	// 					"State Id": elm.stateId,
	// 					"State Name": elm.stateName,
	// 				}));

	// 			exportExcel(newData, "All_FI_List");
	// 		})
	// 		.catch((e) => {
	// 			toastTL.current.show({
	// 				severity: "error",
	// 				summary: e.name,
	// 				detail: e.message,
	// 				style: { color: "#000000" },
	// 				life: 3000,
	// 			});
	// 		})
	// 		.finally(() => setLoader(false));
	// };
	// const items = [
	// 	{
	// 		label: "Export",
	// 		icon: "pi pi-file-excel",
	// 		command: () => {
	// 			exportExcel(newData(institution.data.data), "FI_List");
	// 		},
	// 	},
	// 	{
	// 		label: "Export All",
	// 		icon: "pi pi-file-excel",
	// 		command: () => {
	// 			getAllLoanData();
	// 		},
	// 	},
	// ];
	const rowExpansionTemplate = (data) => {
		return (
			<DataTable value={data.details} responsiveLayout="scroll">
				<Column body={typeTemplate} header="Type" />
				<Column field="bankName" header="Institution Name" />
				<Column body={addressTemplate} header="Address" />
				<Column field="city" header="City" />
				<Column body={actionTemplate} header="Action" />
			</DataTable>
		);
	};
	const allowExpansion = (rowData) => {
		return rowData?.details?.length > 0;
	};
	const handleChange = (e, setFieldValue, index) => {
		setFieldValue(`details.${index}.bankType`, e.value, true);
		inistitutionService
			.getInstitutionName(e.value)
			.then((res) => {
				setFieldValue(`details.${index}.allInstitute`, res, true);
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			);
	};

	const validationSchema = Yup.object().shape({
		details: Yup.array().of(
			Yup.object().shape({
				bankType: Yup.string().required("Type is required."),
				bankId: Yup.string().required("Name is required."),
				addressLine1: Yup.string().required(
					"Address Line 1 is required"
				),
				stateId: Yup.string().required("State is required"),
				city: Yup.string().required("City is required"),
				pincode: Yup.number().required("Pincode is required"),
			})
		),
	});
	return (
		<>
			{loader && <Loader />}
			<Toast ref={toastTL} />
			<Dialog
				header="Update Institution"
				visible={updateDialog}
				style={{ width: "65vw" }}
				breakpoints={{ "960px": "90vw" }}
				onHide={() => setUpdateDialog(false)}
			>
				<InstitutionDetails
					refressList={getInstitution}
					id={selectedId}
				/>
			</Dialog>
			<Dialog
				style={{ width: "60%" }}
				header={"Update DSA"}
				visible={dsaDialog.state}
				onHide={() =>
					setDsaDialog((prev) => ({
						...prev,
						state: false,
						data: null,
					}))
				}
			>
				<Suspense fallback={null}>
					<DsaUpdate
						data={dsaDialog.data}
						getInstitution={getInstitution}
					/>
				</Suspense>
			</Dialog>
			<Dialog
				style={{ width: "60%" }}
				header={"Add Institution"}
				visible={dsaDialogIns.state}
				onHide={() =>
					setDsaDialogIns((prev) => ({
						...prev,
						state: false,
						data: null,
					}))
				}
			>
				<Formik
					initialValues={{
						details: [
							{
								bankType: "",
								bankId: "",
								alias: "",
								allInstitute: [],
								addressLine1: "",
								addressLine2: "",
								addressLine3: "",
								city: "",
								stateId: "",
								pincode: "",
							},
						],
					}}
					validationSchema={validationSchema}
					onSubmit={createInstitution}
				>
					{({ values, setFieldValue, handleSubmit, resetForm }) => (
						<Form
							onSubmit={handleSubmit}
							className="modal-form-grid mt-2"
						>
							<FieldArray name="details">
								{({ remove, push }) => (
									<>
										{values?.details?.map((item, index) => (
											<div
												className="col-12 cr-finalIns"
												key={index}
											>
												<div className="contact-grid-card grid form-grid">
													<Button
														type="button"
														className="floating-icon-btn p-button-text"
														onClick={() =>
															remove(index)
														}
														icon={
															"pi pi-times-circle"
														}
													/>
													<div className="field col-12 md:col-4">
														<Field
															name={`details.${index}.bankType`}
															component={
																FeildDropdown
															}
															filter
															options={
																Config.INSTITUTION_TYPE
															}
															optionLabel="name"
															header={"Type"}
															star={"*"}
															onChange={(e) =>
																handleChange(
																	e,
																	setFieldValue,
																	index
																)
															}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															name={`details.${index}.bankId`}
															component={
																FeildDropdown
															}
															filter
															header={"Name"}
															star={"*"}
															options={
																values.details[
																	index
																]?.allInstitute
															}
															optionValue={"id"}
															optionLabel="bankName"
														/>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={"Alias"}
															component={
																FieldInput
															}
															name={`details.${index}.alias`}
														/>
													</div>

													<div className="field col-12 md:col-4">
														<Field
															header={
																"Address Line 1"
															}
															component={
																FieldTextArea
															}
															name={`details.${index}.addressLine1`}
															star={"*"}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Address Line 2"
															}
															component={
																FieldTextArea
															}
															name={`details.${index}.addressLine2`}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={
																"Address Line 3"
															}
															component={
																FieldTextArea
															}
															name={`details.${index}.addressLine3`}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															name={`details.${index}.stateId`}
															component={
																FeildDropdown
															}
															filter
															header={"State"}
															star={"*"}
															options={data}
															optionValue={"id"}
															optionLabel="name"
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"City"}
															component={
																FieldInput
															}
															name={`details.${index}.city`}
															star={"*"}
														/>
													</div>
													<div className="field col-12 md:col-4">
														<Field
															header={"Pincode"}
															component={
																FieldInput
															}
															type="number"
															name={`details.${index}.pincode`}
															star={"*"}
														/>
													</div>
												</div>
											</div>
										))}
										<button
											className="add-more-btn w-full mb-3"
											type="button"
											onClick={() =>
												push({
													bankType: "",
													bankId: "",
													rainbowDsaCode: "",
													alias: "",
													addressLine1: "",
													addressLine2: "",
													addressLine3: "",
													allInstitute: [],
													city: "",
													stateId: "",
													pincode: "",
												})
											}
										>
											<i className="pi pi-plus"></i>
										</button>
									</>
								)}
							</FieldArray>
							<div className="flex justify-content-end align-items-center w-full md:col-3 mt-2">
								<Button
									type="button"
									label="Reset"
									onClick={() => resetForm()}
									className="p-button-danger mr-2"
									icon="pi pi-times"
								/>
								<Button
									type="submit"
									label="Save"
									icon="pi pi-save"
								/>
							</div>
						</Form>
					)}
				</Formik>
			</Dialog>
			<div className="grid">
				<div className="col-12 flex flex-wrap justify-content-between align-items-center">
					<h3 className="m-0">DSA Financial Institutions</h3>
					<div className="flex align-items-center gap-1">
						{/* <Button
							label="Filter"
							icon="pi pi-filter-fill"
							iconPos="left"
							className="disabled-btn"
						/> */}
						<span className="p-input-icon-left">
							<Button
								label={show ? "Close" : "Search"}
								icon={show ? "pi pi-times" : "pi pi-search"}
								className={
									show
										? "p-button-outlined p-button-danger"
										: "p-button-outlined"
								}
								aria-label="Cancel"
								onClick={() => {
									setShow((show) => !show);
								}}
							/>
						</span>
						<Button
							label="Reload"
							icon="pi pi-refresh"
							iconPos="right"
							className="p-button-outlined primary-btn"
							onClick={handleReset}
						/>
						<Button
							label="Add New"
							icon="pi pi-plus"
							iconPos="right"
							className="primary-btn"
							onClick={() =>
								navigate(
									"/partners/dsa-financial-institution/add"
								)
							}
						/>
						{/* <SplitButton
							icon={"pi pi-file-export"}
							label="Export"
							model={items}
							// loading={spinner}
						/> */}
					</div>
				</div>
				{show && (
					<Formik initialValues={initialValue} onSubmit={handleForm}>
						{({ handleSubmit, resetForm }) => (
							<Form
								onSubmit={handleSubmit}
								className="background-secondary p-4 border-round-md w-full my-3"
							>
								<div className="form-grid grid">
									<div className="field col-12 md:col-3">
										<Field
											header={"Name"}
											component={FieldInput}
											name="dsaName"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"DSA Code"}
											component={FieldInput}
											name="dsaCode"
										/>
									</div>
									<div className="field col-12 md:col-3">
										<Field
											header={"Rainbow DSA Code"}
											component={FieldInput}
											name="rainbowDsaCode"
										/>
									</div>

									<div className="field col-12 md:col-3">
										<Field
											header={"Address"}
											component={FieldInput}
											name="address"
										/>
									</div>

									<div className="col-12 flex align-items-end justify-content-end">
										<Button
											type="button"
											label="Reset"
											className="p-button-danger mr-2"
											onClick={resetForm}
										/>
										<Button type="submit" label="Search" />
									</div>
								</div>
							</Form>
						)}
					</Formik>
				)}
				{institution.isLoading ? (
					<div className="col-12">
						<TableLoader
							headerGroup={headerGroup}
							itaration={itaration}
						/>
					</div>
				) : (
					institution.data && (
						<div className="col-12">
							{institution.data.totalPages > 0 && (
								<Paginator
									pageSize={institution.data.pageSize}
									firstPage={institution.data.firstPage}
									lastPage={institution.data.lastPage}
									decrement={decrement}
									increment={increment}
									pagesizechange={pagesizechange}
									pageNo={institution.data.pageNo}
									totalPages={institution.data.totalPages}
									totalElements={
										institution.data.totalElements
									}
								/>
							)}
							<DataTable
								value={
									institution.data.data &&
									institution.data.data.map(
										(item, index) => ({
											...item,
											index:
												institution.data.pageSize *
													institution.data.pageNo -
												institution.data.pageSize +
												1 +
												index,
										})
									)
								}
								breakpoint="960px"
								expandedRows={expandedRows}
								onRowToggle={(e) => setExpandedRows(e.data)}
								responsiveLayout="scroll"
								rowExpansionTemplate={rowExpansionTemplate}
							>
								<Column
									expander={allowExpansion}
									style={{ width: "3em" }}
								/>
								<Column field="index" header={"#"} />
								<Column field="dsaName" header={"Name"} />
								<Column field="dsaCode" header={"DSA Code"} />
								<Column
									field="rainbowDsaCode"
									header={"Rainbow Dsa Code"}
								/>
								<Column field="address" header={"Address"} />
								<Column
									body={(item) =>
										item.createdOn ? (
											moment(item.createdOn).format(
												"Do MMM YYYY"
											)
										) : (
											<></>
										)
									}
									header={"Created On"}
								/>
								<Column
									body={(item) =>
										item.lastModifiedOn ? (
											moment(item.lastModifiedOn).format(
												"Do MMM YYYY"
											)
										) : (
											<></>
										)
									}
									header={"Updated On"}
								/>
								<Column
									header={"Action"}
									body={({ details, ...rest }) => (
										<div className="flex gap-2">
											<Button
												className="icon-btn p-button-text"
												onClick={() =>
													setDsaDialog((prev) => ({
														...prev,
														state: true,
														data: rest,
													}))
												}
												tooltip="Edit DSA"
												tooltipOptions={{
													position: "left",
												}}
												icon={"pi pi-pencil"}
											/>
											<Button
												className="icon-btn p-button-text"
												onClick={() =>
													setDsaDialogIns((prev) => ({
														...prev,
														state: true,
														data: rest,
													}))
												}
												tooltip="Add Institution"
												tooltipOptions={{
													position: "left",
												}}
												icon={"pi pi-plus"}
											/>
										</div>
									)}
								/>
							</DataTable>

							{institution.data.totalPages > 0 && (
								<Paginator
									pageSize={institution.data.pageSize}
									firstPage={institution.data.firstPage}
									lastPage={institution.data.lastPage}
									decrement={decrement}
									increment={increment}
									pagesizechange={pagesizechange}
									pageNo={institution.data.pageNo}
									totalPages={institution.data.totalPages}
									totalElements={
										institution.data.totalElements
									}
								/>
							)}
						</div>
					)
				)}
			</div>
		</>
	);
};

export default DSAFinancialInstitutions;
