import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import Sidenav from "../../components/Sidenav";
import { useSelector } from "react-redux";
import Signup from "../login/Signup";

const AppLayout = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const onOpenerClick = () => setMenuOpen(!menuOpen);
	// const navigate = useNavigate();
	// const authToken = sessionStorage.getItem("token") || null;
	const authToken = useSelector((state) => state.persistData.auth.token);
	// useEffect(() => {
	// 	if (authToken === null) {
	// 		navigate("/login");
	// 	}

	// }, []);

	return (
		<>
			{authToken !== null ? (
				<div className="app-wrapper">
					<Sidenav menuOpen={menuOpen} />
					<div className="app-grid">
						<Header
							menuOpener={onOpenerClick}
							menuOpen={menuOpen}
						/>
						<div className="app-content">
							<Outlet />
						</div>
					</div>
				</div>
			) : (
				<Signup />
			)}
		</>
	);
};

export default AppLayout;
