import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";

import {
	// FeildCalender,
	FeildDropdown,
	FieldInput,
} from "../../../../components/FieldInput";
import Config from "../../../../shared/config";
import { useGetProductDetailsQuery } from "../../../../services/query/queryApi";
import { useEffect, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import SearchFieldGroup from "../../../../components/SearchFieldGroup";
import moment from "moment";
import { VendorService } from "../../../../services/vendorService";
import { useSelector } from "react-redux";

const SearchToggleContent = (props) => {
	const selector = useSelector(
		(state) => state?.persistData?.auth?.user?.role
	);
	const { data: productList = { data: [] } } = useGetProductDetailsQuery({
		searchRequest: [],
		pageNo: 1,
		pageSize: 1000,
		sorts: [],
	});
	const ccService = new VendorService();
	const [VendorList, setVendorList] = useState([]);
	const getSubProductList = createSelector(() => {
		let filterData = productList.data.map((item) => {
			if (item.isActive) {
				let tempSub = item.subProducts.filter(
					(elm) => elm.isActive === true
				);
				return {
					...item,
					subProducts: tempSub,
				};
			}
		});
		let data = filterData.filter((item) => {
			return item !== undefined;
		});
		return data;
	});
	const productData = getSubProductList();

	const statusDropdown = [
		{ name: "New", value: "NEW" },
		{ name: "Not Interested", value: "NOT_INTERESTED" },
		{ name: "Not Eligible", value: "NOT_ELIGIBLE" },
		{ name: "Follow Up", value: "FOLLOW_UP" },
		{ name: "Converted", value: "CONVERTED" },
		{ name: "Invalid", value: "INVALID" },
	];

	const initialValues = {
		number: "",
		districtId: "",
		mobileNumber: "",
		status: "",
		productId: "",
		stateId: "",
		zoneId: "",
		vendorId: "",
		submissionDateFrom: "",
		submissionDateTo: "",
	};

	const handleFormSubmit = (value) => {
		const { productId, submissionDateFrom, submissionDateTo, ...rest } =
			value;

		props.search({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: {
				...rest,
				productId: productId.id,
				submissionDateFrom: submissionDateFrom
					? moment(submissionDateFrom).format("YYYY-MM-DD")
					: null,
				submissionDateTo: submissionDateTo
					? moment(submissionDateTo).format("YYYY-MM-DD")
					: null,
			},
		});
		props.search({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};
	useEffect(() => {
		ccService
			.getVendorList({
				pageNo: 1,
				pageSize: 1000,
			})
			.then((res) => {
				const data = res?.data?.map((elm) => ({
					...elm,
					name: elm.vendorType + "(" + elm.name + ")",
				}));
				setVendorList(data);
			});
	}, []);
	return (
		<div className="mb-3">
			<div className="background-secondary p-4 border-round-md my-3">
				<Formik
					initialValues={initialValues}
					onSubmit={handleFormSubmit}
				>
					{({ handleSubmit, resetForm, values, setFieldValue }) => (
						<Form
							className="grid form-grid"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 md:col-3">
								<Field
									header={"Lead Number."}
									name={"number"}
									component={FieldInput}
								/>
							</div>
							<SearchFieldGroup
								values={values}
								setFieldValue={setFieldValue}
								zoneField={"zoneId"}
								stateField={"stateId"}
								districtField={"districtId"}
								fromDate={"submissionDateFrom"}
								toDate={"submissionDateTo"}
							/>
							{/* <div className="field col-12 md:col-3">
								<Field
									component={FeildDropdown}
									header={"State"}
									filter
									value={values.state}
									name={"state"}
									onChange={(e) =>
										getDistrict(e, setFieldValue)
									}
									options={data.map((item) => ({
										...item,
										value: item.id,
									}))}
									optionLabel="name"
								/>
							</div>*/}

							<div className="field col-12 md:col-3">
								<Field
									header={"Mobile No."}
									name={"mobileNumber"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									header={"Status"}
									name={"status"}
									component={FeildDropdown}
									options={statusDropdown}
									optionLabel={"name"}
								/>
							</div>
							{productData.length > 0 ? (
								<div className="field col-12 md:col-3">
									<Field
										header={"Product"}
										name={"productId"}
										component={FeildDropdown}
										filter
										options={productData}
										optionLabel="title"
										optionGroupLabel="title"
										optionGroupChildren="subProducts"
									/>
								</div>
							) : null}
							{selector?.position === null && (
								<div className="field col-12 md:col-3">
									<Field
										filter
										header={"Vendor"}
										name={"vendorId"}
										component={FeildDropdown}
										options={VendorList}
										optionLabel="name"
										optionValue={"id"}
									/>
								</div>
							)}
							<div className="flex justify-content-end align-items-center w-full">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Search" />
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default SearchToggleContent;
