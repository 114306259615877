import { Suspense, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import S3 from "aws-sdk/clients/s3";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { ProgramService } from "../../../../../../services/ProgramService";
import Config from "../../../../../../shared/config";
import Loader from "../../../../../../components/Loader";
import {
	FeildDropdown,
	FieldEditor,
	FieldInput,
	FieldMultiselect,
} from "../../../../../../components/FieldInput";
import { InputNode } from "../../../../../../components/InputComponent";
const HospicashProgram = (props) => {
	const programService = new ProgramService();
	const toastTL = useRef(null);
	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});
	const [spinner, setSpinner] = useState(false);
	const programCreate = (payload) => {
		setSpinner(true);
		programService
			.createProgram(payload)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: "Program created",
					style: { color: "#000000" },
					life: 3000,
				});

				props.getAllProgramList();
			})
			.catch((error) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				})
			)
			.finally(() => {
				setSpinner(false);
			});
	};
	const initialValue = {
		name: "",
		shortDescription: "",
		longDescription: "",
		eligibilityDetails: "",
		imageFirst: "",
		imageSecond: "",
		profession: "",
		documentsRequired: "",
		active: true,
		maxPremiumAmount: "",
		minPremiumAmount: "",
		maxTerm: "",
		minTerm: "",
		maxAge: "",
		minHospitalisation: "",
		maternityBenefit: false,
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("This Field is Required"),
		eligibilityDetails: Yup.string().required("This Field is Required"),
		documentsRequired: Yup.string().required("This Field is Required"),
		profession: Yup.array()
			.of(Yup.string())
			.min(1, "Field cannot be empty")
			.required("Field cannot be empty"),
		minPremiumAmount: Yup.number()
			.typeError("Amount must be a number")
			.min(0, "Invalid Value")
			.required("This Field is Required"),
		maxPremiumAmount: Yup.number()
			.typeError("Amount must be a number")
			.min(0, "Invalid Value")
			.when("minPremiumAmount", (minPremiumAmount, value) => {
				return value.test({
					test: (maxPremiumAmount) =>
						!!minPremiumAmount &&
						maxPremiumAmount > minPremiumAmount,
					message: "Max Premium Amount  > Min Premium Amount",
				});
			})
			.required("This Field is Required"),
		minTerm: Yup.number()
			.typeError("Term must be a number")
			.min(0, "Invalid Value")
			.required("This Field is Required"),
		maxTerm: Yup.number()
			.typeError("Term must be a number")
			.min(0, "Invalid Value")
			.when("minTerm", (minTerm, value) => {
				return value.test({
					test: (maxTerm) => !!minTerm && maxTerm > minTerm,
					message: "Max Term  > Min Term",
				});
			})
			.required("This Field is Required"),
		maxAge: Yup.number()
			.typeError("Age must be a number")
			.min(0, "Invalid Value")
			.required("This Field is Required"),
		minHospitalisation: Yup.number()
			.typeError("Data must be a number")
			.min(0, "Invalid Value")
			.required("This Field is Required"),
	});
	const handleFormSubmit = (value, onSubmitProps) => {
		const newData = {};
		// programCreate(newData);
	};
	const onUploadfirst = (e, setFieldValue) => {
		//setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.PROGRAM_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "Contract document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});

				setFieldValue("imageFirst", newFileName);
			}
		});
	};
	const onUploadSecond = (e, setFieldValue) => {
		//setLoading(true);
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.PROGRAM_DOCS_BUCKET,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err, data) => {
			if (err) {
				//setLoading(false);
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Somthing went worng",
					style: { color: "#000000" },
					life: 3000,
				});
			} else {
				//setLoading(false);
				toastTL.current.show({
					severity: "success",
					summary: "Successfull",
					detail: "2nd document uploaded",
					style: { color: "#000000" },
					life: 3000,
				});

				setFieldValue("imageSecond", newFileName);
			}
		});
	};
	return (
		<>
			{spinner && <Loader />}
			<Toast ref={toastTL} position="top-left" />
			<Suspense fallback={null}>
				<Formik
					initialValues={initialValue}
					validationSchema={validationSchema}
					onSubmit={handleFormSubmit}
					enableReinitialize
				>
					{({
						values,

						handleSubmit,

						setFieldValue,
						touched,
						errors,
						resetForm,
					}) => (
						<Form
							className="form-grid grid"
							onSubmit={handleSubmit}
							autoComplete="on"
						>
							<div className="field col-12 md:col-3">
								<Field
									header={"Name of the Program"}
									component={FieldInput}
									name={"name"}
									star={"*"}
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									name={`profession`}
									component={FieldMultiselect}
									options={Config.OCCUPATION}
									optionLabel="name"
									header={"Profession"}
									star={"*"}
								/>
							</div>

							<div className="field col-12 md:col-3">
								<Field
									component={FieldInput}
									name={"maxPremiumAmount"}
									type={"number"}
									header={"Max Premium Amount"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									component={FieldInput}
									name={"minPremiumAmount"}
									type={"number"}
									header={"Min Premium Amount"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									component={FieldInput}
									name={"maxTerm"}
									type={"number"}
									header={"Max Term"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									component={FieldInput}
									name={"minTerm"}
									type={"number"}
									header={"Min Term"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									component={FieldInput}
									name={"maxAge"}
									type={"number"}
									header={"Max Age"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									component={FieldInput}
									name={"minHospitalisation"}
									type={"number"}
									header={"Min Hospitalisation"}
									star="*"
								/>
							</div>
							<div className="field col-12 md:col-3">
								<Field
									component={FeildDropdown}
									name={"maternityBenefit"}
									type={"number"}
									options={Config.YES_NO}
									header={"Maternity Benefit"}
									star="*"
								/>
							</div>

							<div className="col-12"> </div>
							<div className="field col-12 md:col-6">
								<Field
									name="shortDescription"
									component={FieldEditor}
									header={"Short Description"}
								/>
							</div>
							<div className="field col-12 md:col-6">
								<Field
									name="longDescription"
									component={FieldEditor}
									header={"Long Description"}
								/>
							</div>

							<div className="field col-12 md:col-6">
								<Field
									name="eligibilityDetails"
									component={FieldEditor}
									header={"Eligibility Details"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-6">
								<Field
									name="documentsRequired"
									component={FieldEditor}
									header={"Documents Required"}
									star={"*"}
								/>
							</div>
							<div className="field col-12 md:col-6">
								<InputNode header={"Upload Image 1"}>
									<FileUpload
										name="imageFirst"
										customUpload
										accept=".jpg,.png,.jpeg"
										uploadHandler={(e) =>
											onUploadfirst(e, setFieldValue)
										}
										maxFileSize={1000000}
										emptyTemplate={
											<p className="m-0">
												Drag and drop files to here to
												upload.
											</p>
										}
									/>
									{errors.imageFirst &&
										touched.imageFirst && (
											<small className="p-error">
												{errors.imageFirst}
											</small>
										)}
								</InputNode>
							</div>
							<div className="field col-12 md:col-6">
								<InputNode header={"Upload Image 2"}>
									<FileUpload
										name="imageSecond"
										customUpload
										accept=".jpg,.png,.jpeg"
										uploadHandler={(e) =>
											onUploadSecond(e, setFieldValue)
										}
										maxFileSize={1000000}
										emptyTemplate={
											<p className="m-0">
												Drag and drop files to here to
												upload.
											</p>
										}
									/>
									{errors.imageSecond &&
										touched.imageSecond && (
											<small className="p-error">
												{errors.imageSecond}
											</small>
										)}
								</InputNode>
							</div>
							<div className="flex justify-content-end gap-2 align-items-center col-12 mt-4">
								<Button
									type="button"
									label="Reset"
									onClick={() => resetForm()}
									icon={"pi pi-refresh"}
									className="danger-btn"
								/>
								<Button type="submit" label="Save & next" />
							</div>
						</Form>
					)}
				</Formik>
			</Suspense>
		</>
	);
};

export default HospicashProgram;
