import axios from "axios";
import { agent_end_point_url, apiPath } from "../shared/constant";

export class AgentService {
	constructor() {
		this.token = sessionStorage.getItem("token");
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}

	// Get Agents & Profile according to payload
	// @method: POST
	getAgentsProfiles = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/profile/search",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	getProfileDetails = async (id) => {
		try {
			const response = await axios.get(
				agent_end_point_url + "/profile/" + id,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};

	agentProfileStatusChange = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/profile/verification",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	addUnallocated = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/allocate/agent",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agentAllocationist = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/agentallocation/list",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	};
	unallocatedAgent = async (payload, val) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/unallocated/list",
				payload,
				{ headers: this.headers, signal: val }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	validatePincode = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/pincode/validate",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	getAgentDetails = async (id) => {
		try {
			const response = await axios.get(
				`${agent_end_point_url}/child-user/${id}`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	submitGetAgentDetails = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/tag/se",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	getIFSCDetails = async (payLoad) => {
		try {
			const response = await axios.get(
				`https://ifsc.razorpay.com/${payLoad}`
				// { headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	bankDetailsUpdate = async (payLoad) => {
		try {
			const response = await axios.post(
				`${agent_end_point_url}/profile/bankdetails/add`,
				payLoad,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agentDetailsUpdate = async (payLoad) => {
		try {
			const response = await axios.post(
				`${agent_end_point_url}/profile/products/add`,
				payLoad,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	agentPasswordChange = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/user/password/update",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	getRmAsm = async (payload) => {
		try {
			const response = await axios.post(
				agent_end_point_url + "/user/all-by-role",
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	getAllTse = async (id) => {
		try {
			const response = await axios.get(
				`${agent_end_point_url}/user/all-tse`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getproductwisecommissionbycategory = async (categoryId, agentId) => {
		try {
			const response = await axios.get(
				`${apiPath}/product-agent-commission/by-category/${categoryId}/${agentId}`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	agentCategoryComission = async (payload) => {
		try {
			const response = await axios.post(
				`${apiPath}/agent-category-commission/save`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};

	getAgentCommission = async (id) => {
		try {
			const response = await axios.get(
				`${apiPath}/agent-category-commission/get-product-wise-commission-by-agent/${id}`,

				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	getPartnerList = async (payload) => {
		try {
			const response = await axios.post(
				`${agent_end_point_url}/partner/all-partner`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	getProfileUpdateList = async (payload) => {
		try {
			const response = await axios.post(
				`${agent_end_point_url}/profile/change/request/list`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	updateProfile = async (payload) => {
		try {
			const response = await axios.post(
				`${agent_end_point_url}/profile/update-request`,
				payload,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	rejectUpdateProfile = async (payload) => {
		try {
			const response = await axios.get(
				`${agent_end_point_url}/profile/change/request/reject/${payload}`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
	approveUpdateProfile = async (payload) => {
		try {
			const response = await axios.get(
				`${agent_end_point_url}/profile/change/request/approve/${payload}`,
				{ headers: this.headers }
			);
			return response.data;
		} catch (error) {
			throw error.response.data;
		}
	};
}
