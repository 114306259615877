import { Field, Form, Formik } from "formik";
import React, { memo } from "react";
import {
	FeildCalender,
	FeildDropdown,
	FieldTextArea,
} from "../../../../components/FieldInput";
import { Button } from "primereact/button";
import * as Yup from "yup";
const AgentReminder = (props) => {
	const initialValue = {
		remainderNotes: "",
		remainderNotification: "",
		remainderDateTime: "",
		type: "",
		endRemainderDateTime: "",
	};
	const validationSchema = Yup.object().shape({
		remainderNotes: Yup.string().required("This field is required"),
		remainderNotification: Yup.number().required("This field is required"),
		remainderDateTime: Yup.date().required("This field is required"),
		type: Yup.string().required("This field is required"),
		endRemainderDateTime: Yup.date().when("type", {
			is: (type) => type === "WEEKLY" || type === "DAILY",
			then: Yup.date().required("This field is required"),
		}),
	});
	return (
		<Formik
			initialValues={initialValue}
			enableReinitialize
			onSubmit={props.handleReminder}
			validationSchema={validationSchema}
		>
			{({ handleSubmit, resetForm, values }) => (
				<Form
					className="modal-form-grid col-12 border-round-md mt-2 p-4 form-grid grid"
					onSubmit={handleSubmit}
				>
					<div className="field col-12">
						<Field
							header={"Note "}
							name={"remainderNotes"}
							component={FieldTextArea}
						/>
					</div>
					<div className="field col-12">
						<Field
							header={"Notification Interval"}
							name={"remainderNotification"}
							component={FeildDropdown}
							options={[
								{ label: "5 min", value: 5 },
								{ label: "30 min", value: 30 },
								{ label: "1 H", value: 60 },
							]}
						/>
					</div>
					<div className="field col-12">
						<Field
							header={"Reminder Time"}
							name={"remainderDateTime"}
							component={FeildCalender}
							showTime
							hourFormat="12"
						/>
					</div>
					<div className="field col-12">
						<Field
							header={"Reminder Frequency"}
							name={"type"}
							component={FeildDropdown}
							options={[
								{ label: "Once", value: "ONCE" },
								{ label: "Daily", value: "DAILY" },
								{ label: "Weekly", value: "WEEKLY" },
							]}
						/>
					</div>
					{(values.type === "DAILY" || values.type === "WEEKLY") && (
						<div className="field col-12">
							<Field
								header={"Reminder Frequency"}
								name={"endRemainderDateTime"}
								component={FeildCalender}
							/>
						</div>
					)}
					<div className="col-12 flex align-items-end justify-content-end">
						<Button
							type="button"
							label="Reset"
							className="p-button-danger mr-2"
							onClick={resetForm}
						/>
						<Button type="submit" label="Save" />
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default memo(AgentReminder);
