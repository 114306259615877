import React, { useState } from "react";
import {
	adminMenus,
	callCenterLead,
	callCenterTASM,
	finanseMenu,
	navForRM,
	sidenavDataZonalManager,
	sidenavSEData2,
} from "../shared/data";
import { sidenavData2 } from "../shared/data";
import { NavLink } from "react-router-dom";
// import { roles } from "../shared/constant";
import { useSelector } from "react-redux";

const Subnav = ({ item }) => {
	const [subMenu, setsubMenu] = useState(false);
	const showSubMenu = () => setsubMenu(!subMenu);

	return item?.child?.length > 0 ? (
		<li className="nav-sub-item" onClick={item.child && showSubMenu}>
			<div className="flex justify-content-between align-items-center">
				<span className="menu-name">{item.name}</span>
				{subMenu ? (
					<span className="pi pi-angle-down"></span>
				) : (
					<span className="pi pi-angle-right"></span>
				)}
			</div>
			{subMenu && (
				<ul className="menu-list">
					{item.child.map((el, k) => {
						return (
							<li
								className="menu-list-item"
								key={"sub-sub-nav-" + k}
							>
								<NavLink
									to={el.targetUrl}
									state={{ heading: el.name }}
								>
									{el.name}
								</NavLink>
							</li>
						);
					})}
				</ul>
			)}
		</li>
	) : (
		<li className="nav-sub-item">
			<NavLink className="menu-name" to={item.targetUrl}>
				{item.name}
			</NavLink>
		</li>
	);
};

const Sidenav = ({ menuOpen }) => {
	const selector = useSelector((state) => state.persistData);

	const getRole = () => {
		switch (selector.auth.user.role.position) {
			case null:
				return adminMenus;
			case 1:
				return sidenavDataZonalManager;
			case 2:
				return sidenavDataZonalManager;
			case 3:
				return sidenavData2;
			case 4:
				return navForRM;
			case 5:
				return sidenavSEData2;
			case 50:
				return finanseMenu;
			case 1000:
				return callCenterLead;
			case 1020:
				return callCenterTASM;
			default:
				return [];
		}
		// return adminMenus;
	};
	return (
		<div className={menuOpen ? "sidenav-wrapper opend" : "sidenav-wrapper"}>
			<ul className="nav-list">
				{selector.auth.menus?.map((items, i) => {
					return items.child ? (
						<li className="nav-item" key={"nav-" + i}>
							<span className="nav-item-content">
								<i className={`pi ${items.icon}`} />
							</span>
							<div className="sub-nav-list">
								<h3 className="nav-title">{items.name}</h3>
								<ul className="child-nav-list">
									{items.child.map((elm, j) => {
										return <Subnav item={elm} key={j} />;
									})}
								</ul>
							</div>
						</li>
					) : (
						<li className="nav-item" key={"nav-" + i}>
							<NavLink
								to={items.targetUrl}
								className="nav-item-content"
							>
								{items.icon}
							</NavLink>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default Sidenav;
