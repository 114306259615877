import ApplicationForm from "./components/ApplicationForm";
import { useLocation } from "react-router-dom";
import Insurance from "./components/Insurance";
import LIinsurance from "./components/FormComponent/LIinsurance";
import Config from "../../../shared/config";
import MedicalInsurance from "./components/FormComponent/MedicalInsurance";
import MotorInsuance from "./components/FormComponent/MotorInsurance";
const VendorLoanApplication = () => {
	const urlData = useLocation();
	return (
		<>
			{urlData?.state?.alias === "HC" ? (
				<Insurance urlData={urlData} />
			) : urlData?.state?.alias === "ULIP" ||
			  urlData?.state?.alias === "TRADI" ||
			  urlData?.state?.alias === "CIP" ||
			  urlData?.state?.alias === "CEP" ||
			  urlData?.state?.alias === "TIP" ||
			  urlData?.state?.alias === "RIP" ? (
				<LIinsurance locationData={urlData.state} />
			) : urlData?.state?.alias ===
			  Config.PRODUCT_ALIAS.INVESTMENT_LIFE_INSURANCE ? (
				<LIinsurance locationData={urlData.state} />
			) : urlData?.state?.alias ===
			  Config.PRODUCT_ALIAS.GROUP_INSURANCE_PLAN ? (
				<LIinsurance locationData={urlData.state} />
			) : urlData?.state?.alias === Config.PRODUCT_ALIAS.INDIVIDUAL ? (
				<MedicalInsurance locationData={urlData.state} />
			) : urlData?.state?.alias ===
			  Config.PRODUCT_ALIAS.FAMILY_FLOATER ? (
				<MedicalInsurance locationData={urlData.state} />
			) : urlData?.state?.alias ===
			  Config.PRODUCT_ALIAS.CRITICAL_ILLNESS ? (
				<MedicalInsurance locationData={urlData.state} />
			) : urlData?.state?.alias === Config.PRODUCT_ALIAS.GROUP ? (
				<MedicalInsurance locationData={urlData.state} />
			) : urlData?.state?.alias === "ACCI" ? (
				<MedicalInsurance locationData={urlData.state} />
			) : urlData?.state?.alias ===
			  Config.PRODUCT_ALIAS.MOTOR_INSURANCE_PRIVET_CAR ? (
				<MotorInsuance locationData={urlData.state} />
			) : urlData?.state?.alias ===
			  Config.PRODUCT_ALIAS.MOTOR_INSURANCE_TWO_WHEELER ? (
				<MotorInsuance locationData={urlData.state} />
			) : (
				<ApplicationForm urlData={urlData} />
			)}
		</>
	);
};

export default VendorLoanApplication;
