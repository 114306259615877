import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
	FeildDropdown,
	FieldInput,
	FieldMultiselect,
} from "../components/FieldInput";
import { Button } from "primereact/button";
import { MenuService } from "../services/MenuService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { convertObjNull } from "../utils/UtilsFunction";
import { Dialog } from "primereact/dialog";

const MenuCreate = () => {
	const menuSErvice = new MenuService();
	const [open, setOpen] = useState(false);
	const [menuList, setMenuList] = useState([]);
	const [roleList, setRoleList] = useState([]);
	const initialValue = {
		name: "",
		targetUrl: "",
		parentId: "",
		position: "",
		icon: "",
		text: "",
	};
	const getMenu = () => {
		menuSErvice
			.getMenu()
			.then((res) => setMenuList(res))
			.then(() =>
				menuSErvice.getAllRole().then((res) => setRoleList(res))
			);
	};
	const handleFormSubmit = (value, action) => {
		const newData = convertObjNull(value);
		menuSErvice.createMenu(newData).then((res) => {
			getMenu();
			action.resetForm();
		});
	};
	const handleFormSubmit2 = (value, action) => {
		const newData = convertObjNull(value);
		menuSErvice.createMenuMap(newData).then((res) => {
			getMenu();
			action.resetForm();
		});
	};
	useEffect(() => {
		getMenu();
	}, []);
	return (
		<>
			<Button
				label="open"
				onClick={() => {
					setOpen(true);
				}}
			/>
			<Formik initialValues={initialValue} onSubmit={handleFormSubmit}>
				{({ values, handleSubmit }) => (
					<Form onSubmit={handleSubmit}>
						<Field
							placeholder={"Title"}
							name={"name"}
							component={FieldInput}
						/>
						<Field
							placeholder={"Path"}
							name={"targetUrl"}
							component={FieldInput}
						/>
						<Field
							placeholder={"prent id"}
							name={"parentId"}
							options={menuList.map((elm) => ({
								...elm,
								label: elm.name + elm.text,
							}))}
							component={FeildDropdown}
							filter
							optionValue={"id"}
						/>
						<Field
							placeholder={"position"}
							name={"position"}
							type={"number"}
							// options={roleList}
							component={FieldInput}
							// optionLabel={"name"}
							// optionValue={"position"}
						/>
						<Field
							placeholder={"text"}
							name={"text"}
							// options={roleList}
							component={FieldInput}
							// optionLabel={"name"}
							// optionValue={"position"}
						/>
						<Field
							placeholder={"icon"}
							name={"icon"}
							component={FieldInput}
						/>
						<Button type={"submit"} label="Save" />
					</Form>
				)}
			</Formik>
			<DataTable
				className="mt-3"
				value={menuList.map((item, index) => ({
					...item,
					index: index + 1,
				}))}
			>
				<Column field="index" header={"index"} />
				<Column field="name" header={"Name"} />
				<Column field="targetUrl" header={"TargetUrl"} />
				<Column field="position" header={"Position"} />
				<Column field="parentId" header={"Parent Id"} />
			</DataTable>
			<Dialog
				style={{ width: "80%" }}
				visible={open}
				onHide={() => setOpen(false)}
			>
				<Formik
					initialValues={{ menuId: [], roleId: "" }}
					onSubmit={handleFormSubmit2}
				>
					{({ values, handleSubmit }) => (
						<Form onSubmit={handleSubmit}>
							<Field
								placeholder={"Menu list"}
								name={"menuId"}
								options={menuList.map((elm) => ({
									...elm,
									label: elm.name + elm.text,
								}))}
								type={"number"}
								component={FieldMultiselect}
								filter
								optionValue={"id"}
							/>
							<Field
								placeholder={"Role"}
								name={"roleId"}
								type={"number"}
								options={roleList}
								component={FeildDropdown}
								optionLabel={"name"}
								optionValue={"id"}
							/>

							<Button type={"submit"} label="Save" />
						</Form>
					)}
				</Formik>
			</Dialog>
		</>
	);
};

export default MenuCreate;
