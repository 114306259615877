import { useCallback, useEffect, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { CallCenterService } from "../../../../services/callCenterService";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

const MyCalenderComponent = () => {
	const toastTL = useRef(null);
	const localizer = momentLocalizer(moment);
	const ccService = new CallCenterService();
	const [events, setEvents] = useState([]);
	const [loader, setLoader] = useState(false);
	const [eventState, setEventState] = useState({
		events: {},
		dialog: false,
	});
	const [dateRange, setDateRange] = useState({
		start: moment().startOf("month").format(),
		end: moment().endOf("month").format(),
	});
	const getData = (payload) => {
		ccService.getAllagentReminder(payload).then((res) => {
			const newData = res?.map((item) => ({
				...item,
				start: new Date(item?.start),
				end: new Date(item?.end),
			}));
			setEvents(newData);
		});
	};
	const handleSelectEvent = useCallback(
		(event) => {
			setEventState((prev) => ({
				...prev,
				events: event,
				dialog: true,
			}));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[eventState.events]
	);

	useEffect(() => {
		getData(dateRange);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleDateRange = useCallback(
		(e) => {
			if (typeof e === "object" && e?.start) {
				setDateRange((prev) => ({
					...prev,
					start: new Date(e?.start),
					end: new Date(e?.end),
				}));
				getData({
					start: new Date(e?.start),
					end: new Date(e?.end),
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dateRange]
	);
	const footerContent = (
		<>
			<p className="text-red-800" style={{ textAlign: "left" }}>
				* Delete All will delete all reminders at same time.
			</p>

			<div className="flex gap-2">
				<Button
					label="Delete"
					icon="pi pi-trash"
					loading={loader}
					onClick={() => {
						setLoader(true);
						eventState.events?.id &&
							ccService
								.deleteReminderById(eventState.events?.id)
								.then((res) => {
									getData(dateRange);
									setEventState((prev) => ({
										...prev,
										dialog: false,
										events: {},
									}));
								})
								.catch((err) => {
									toastTL.current.show({
										severity: "error",
										summary: "Error",
										detail: err?.message,
										style: { color: "#000000" },
										life: 3000,
									});
								})
								.finally(() => {
									setLoader(false);
								});
					}}
					className="p-button-danger"
				/>

				<Button
					label="Delete All"
					icon="pi pi-trash"
					loading={loader}
					onClick={() => {
						setLoader(true);
						eventState.events?.id &&
							ccService
								.deleteListByParentId(eventState.events?.id)
								.then((res) => {
									getData(dateRange);
									setEventState((prev) => ({
										...prev,
										dialog: false,
										events: {},
									}));
								})
								.catch((err) => {
									toastTL.current.show({
										severity: "error",
										summary: "Error",
										detail: err?.message,
										style: { color: "#000000" },
										life: 3000,
									});
								})
								.finally(() => {
									setLoader(false);
								});
					}}
					className="p-button-danger"
				/>
			</div>
		</>
	);
	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			<div className="c-agent-box-1 c-agent-lead-w w-full">
				<h5 className="my-1 text-center">Reminders</h5>
				<Calendar
					localizer={localizer}
					startAccessor="start"
					events={events}
					// endAccessor="end"
					style={{ height: 500 }}
					onRangeChange={handleDateRange}
					onSelectEvent={handleSelectEvent}
				/>
				<Dialog
					visible={eventState.dialog}
					onHide={() =>
						setEventState((prev) => ({
							...prev,
							dialog: false,
							events: {},
						}))
					}
					style={{ width: "35%" }}
					className="cr-dialog"
					footer={footerContent}
					header="Reminder"
				>
					<div className="flex justify-content-end align-items-center">
						<div className="cr-reminder status text-yellow-800 bg-yellow-200 flex gap-1 justify-content-between align-items-center border-round-sm py-1 px-2 mb-2">
							<p>{eventState.events?.title}</p>
							<p className="flex align-items-center py-1 gap-2">
								<i className="pi pi-clock"></i>
								{moment(eventState.events?.start).format(
									"Do MMM YYYY, hh:mm A"
								)}
							</p>
						</div>
					</div>
					<div className="cr-agentDialog">
						<h5 className="mt-0">
							Name :{" "}
							<span className="font-normal">
								{eventState.events?.resource?.fullName}
							</span>
						</h5>
						<h5 className="mt-0">
							Mobile :{" "}
							<span className="font-normal">
								{eventState.events?.resource?.mobileNumber}
							</span>
						</h5>
						<h5 className="my-0">
							Agent Code :{" "}
							<span className="font-normal">
								{eventState.events?.resource?.agentCode}
							</span>
						</h5>
					</div>
				</Dialog>
			</div>
		</>
	);
};

export default MyCalenderComponent;
