import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import GeneralUpdate from "./components/GeneralUpdate";
import HandleCommission from "./HandleCommission";
// import OfferUpdate from "./components/OfferUpdate";
import HandleOffer from "./HandleOffer";

const ProgramEdit = ({ programId, type }) => {
	return (
		<TabView>
			<TabPanel header="General">
				<GeneralUpdate programId={programId} type={type} />
			</TabPanel>
			<TabPanel header="Commission Structure">
				<HandleCommission programId={programId} type={"edit"} />
			</TabPanel>
			<TabPanel header="Offer">
				<HandleOffer programId={programId} />
			</TabPanel>
		</TabView>
	);
};

export default ProgramEdit;
