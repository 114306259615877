import moment from "moment/moment";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import PrimeReact from "primereact/api";
// import { Ripple } from "primereact/ripple";
// import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { FiLogOut, FiCalendar, FiEdit } from "react-icons/fi";
import logo from "../assets/images/logo_main.png";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../store/reducer/AuthReducer";
import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";
// import { Password } from "primereact/password";
// import { InputText } from "primereact/inputtext";
import { Field, Form, Formik } from "formik";
import { FieldInput, FieldPassword } from "./FieldInput";
import ChangePassword from "../services/ChangePasswordService";
import * as Yup from "yup";
import { Toast } from "primereact/toast";

const Header = (props) => {
	const toastTL = useRef(null);
	const authService = new ChangePassword();
	const user = useSelector((state) => state.persistData.auth.user);
	const dispatch = useDispatch();
	PrimeReact.ripple = true;
	const menu = useRef(null);
	// const [innerWidth, setInnerWidth] = useState(window.innerWidth);
	const navigate = useNavigate();
	//const user = JSON.parse(sessionStorage.getItem("user")) || null;

	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChangePassword = () => {
		setVisible(true);
	};
	const getInfo = (item, option) => {
		return (
			<div className="in-mob-info">
				{user ? (
					<p className="m-0 font-medium text-lg">
						{user.firstName} {user.lastName}
					</p>
				) : null}

				{/* <span className="mb-1">Super Admin</span> */}
				<span>
					<FiCalendar /> {moment().format("Do MMM, YYYY")}
				</span>
			</div>
		);
	};
	const items = [
		{
			label: "User Info",
			template: (item, option) => getInfo(item, option),
		},
		{
			label: "User Setting",
			items: [
				{
					label: "Change Password",
					icon: <FiEdit />,
					command: () => handleChangePassword(),
				},
				{
					label: "Logout",
					icon: <FiLogOut />,
					command: () => logout(),
				},
			],
		},
	];

	// useEffect(() => {
	// 	setInnerWidth(window.innerWidth);
	// }, []);

	const logout = () => {
		dispatch(setUser({ token: null, supertoken: null, user: null }));
		localStorage.clear();
		sessionStorage.clear();
		navigate("/");
	};

	const validationSchema = Yup.object().shape({
		newPassword: Yup.string()
			.required("Password is required")
			.min(8, "Password must have at least 8 characters")

			// .matches(/[0-9]/)
			// .matches(/[a-z]/)
			// .matches(/[A-Z]/)
			// .matches(/!@#\$%\^&\*/),
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
			),
		newPasswordCNF: Yup.string().oneOf(
			[Yup.ref("newPassword"), null],
			"Passwords must match"
		),
	});
	const handleFormSubmit = (values) => {
		setLoading(true);
		authService
			.changePassword(values)
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: res.message,
					life: 3000,
				});
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					life: 3000,
				})
			)
			.finally(() => {
				setLoading(false);
				setVisible(false);
			});
	};

	return (
		<div className="header-wrapper flex align-items-center">
			<Toast ref={toastTL} position="top-left" />
			<div className="left-header">
				{window.innerWidth <= 600 ? (
					props.menuOpen ? (
						<Button
							icon="pi pi-times"
							className="p-button-rounded p-button-secondary p-button-text mr-3"
							onClick={props.menuOpener}
							aria-label="menu-toggle"
						/>
					) : (
						<Button
							icon="pi pi-bars"
							className="p-button-rounded p-button-secondary p-button-text mr-3"
							onClick={props.menuOpener}
							aria-label="menu-toggle"
						/>
					)
				) : null}

				{/* <Link to={"/"}> */}
				<img className="app-logo" src={logo} alt="logo" />
				{/* </Link> */}
			</div>
			<div className="right-header">
				{/* <div className="notification mr-5 text-3xl p-ripple p-overlay-badge">
					<Badge
						severity="danger"
						style={{ top: "13px", right: "19px" }}
					></Badge>
					<FiBell />
					<Ripple />
				</div> */}
				<Menu
					model={items}
					popup
					ref={menu}
					id="popup_menu"
					className="p-0"
				/>
				<div
					className="user-info"
					onClick={(event) => menu.current.toggle(event)}
					aria-controls="popup_menu"
					aria-haspopup
				>
					<div className="info">
						<p>{user && `${user.firstName} ${user.lastName}`}</p>

						{/* <span>Today: {moment().format("Do MMM, YYYY")}</span> */}
						<span>{user && user.role.name}</span>
					</div>
					<Avatar
						icon="pi pi-user"
						className="mr-2"
						size="large"
						shape="circle"
					/>
					{/* <div className="avater">
						<img
							src="https://xsgames.co/randomusers/avatar.php?g=male"
							alt="user avater"
						/>
					</div> */}
				</div>
			</div>
			<Dialog
				header="Change Password"
				visible={visible}
				onHide={() => setVisible(false)}
			>
				<div className="card modal-form-grid">
					<Formik
						enableReinitialize
						initialValues={{
							oldPassword: "",
							newPassword: "",
							newPasswordCNF: "",
						}}
						onSubmit={handleFormSubmit}
						validationSchema={validationSchema}
					>
						{({ handleSubmit }) => (
							<Form
								onSubmit={handleSubmit}
								className="w-20rem grid form-grid"
							>
								<div className="field col-12 ">
									<Field
										component={FieldInput}
										name="oldPassword"
										header={"Old Password"}
									/>
								</div>
								<div className="field col-12 ">
									<Field
										component={FieldPassword}
										name="newPassword"
										header={"New Password"}
									/>
								</div>
								<div className="field col-12 ">
									<Field
										component={FieldPassword}
										name="newPasswordCNF"
										header={"Confirm New Password"}
									/>
								</div>
								<div className="field col-12 ">
									<Button
										type="submit"
										label="Submit"
										className="w-full"
										loading={loading}
									/>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Dialog>
		</div>
	);
};

export default Header;
