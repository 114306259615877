import React from "react";
import ProductWiseCategory from "./ProductWiseCategory";
import { useLocation } from "react-router-dom";

const AgentCommission = () => {
	const item = useLocation();

	return (
		<div>
			<ProductWiseCategory agentData={item} />
		</div>
	);
};

export default AgentCommission;
