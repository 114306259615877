import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Divider } from "primereact/divider";
import { Chip } from "primereact/chip";
import { Tag } from "primereact/tag";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ProfileIndex from "./agentprofilecomponent";
import { AgentService } from "../../../services/AgentService";
import { Dialog } from "primereact/dialog";
import Config from "../../../shared/config";
import Loader from "../../../components/Loader";
import { VendorService } from "../../../services/vendorService";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Field, Form, Formik } from "formik";
import { FieldInput } from "../../../components/FieldInput";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CallCenterService } from "../../../services/callCenterService";

const AgentProfile = () => {
	const toastTL = useRef(null);
	const [profileStatus, setProfileStatus] = useState("");
	const agentId = useParams();
	const navigate = useNavigate();
	const [profileData, setProfileData] = useState({});
	const [productDialog, setProductDialog] = useState(false);
	const [noteDialog, setNoteDialog] = useState(false);
	const [activityData, setActivityData] = useState([]);
	const [activity, setActivity] = useState({});
	const [loader, setLoader] = useState(false);

	const agentData = useLocation();
	const agentService = new AgentService();
	const vendorService = new VendorService();
	const ccService = new CallCenterService();
	const getData = (agentId, profileId) => {
		setLoader(true);
		Promise.all([
			agentService.getProfileDetails(profileId),
			vendorService.agentActivity(agentId),
		])
			.then((res) => {
				setProfileData(res[0]);
				setActivity(res[1]);
				setProfileStatus(
					res[0]?.profile?.agentEngagement
						? res[0]?.profile?.agentEngagement
						: ""
				);
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => setLoader(false));
	};
	const handleAgentStatus = (val) => {
		setProfileStatus(val);
		vendorService
			.agentStatusUpdateFromProfile({
				profileId: agentData?.state?.profileId,
				agentEngagement: val,
			})
			.then((res) => {
				getData(agentId?.id, agentData?.state?.profileId);
			});
	};
	useEffect(() => {
		getData(agentId?.id, agentData?.state?.profileId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleActivity = (id) => {
		setNoteDialog(true);
		vendorService
			.agentActivityLofList(id)
			.then((res) => setActivityData(res));
	};
	const handleQualification = (val) => {
		vendorService
			.agentDetailsSaveQualification(val)
			.then((res) => {
				getData(agentId?.id, agentData?.state?.profileId);
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: "Success",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				})
			);
	};
	const handleNoteSubmit = (val) => {
		ccService
			.saveAgentNote({
				agentId: [agentId?.id],
				notes: val.notes,
			})
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					summary: "Success",
					detail: res,
					style: { color: "#000000" },
					life: 3000,
				});
				getData(agentId?.id, agentData?.state?.profileId);
			})
			.catch((e) =>
				toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				})
			);
	};
	return (
		<>
			<Toast ref={toastTL} position="top-left" />
			{loader && <Loader />}{" "}
			<div className="grid">
				<div className="col-12 flex align-items-center justify-content-between c-agent-profile-hw">
					<div className="flex align-items-center mb-3 ">
						<Button
							icon="pi pi-arrow-left"
							className="p-button-rounded p-button-text"
							aria-label="Back"
							onClick={() => navigate(-1)}
						/>
						<h3 className="m-0">Agent Profile</h3>
					</div>
					<Dropdown
						style={{ borderRadius: "8px", width: "180px" }}
						placeholder="Select status"
						value={profileStatus}
						options={[
							{ label: "Featured", value: "FEATURED" },
							{ label: "Engaged", value: "ENGAGED" },
							{ label: "Dormant", value: "DORMANT" },
							{ label: "Follow Up", value: "FOLLOW_UP" },
							{ label: "Reset", value: null },
						]}
						onChange={(e) => handleAgentStatus(e.value)}
					/>
				</div>
				<div className="md:col-3">
					<Image
						className="c-agent-image"
						src={`${Config.AGENT_BUCKET_ACCESS_URL}/agent-profile/${profileData?.profile?.id}/${profileData?.profile?.selfieFileName}`}
						alt="Image"
						width="100%"
						imageStyle={{ width: "100%", maxheight: "230px" }}
						preview
					/>

					<Divider align="left" type="dashed">
						<div className="inline-flex align-items-center">
							<b>Verification Details</b>
						</div>
					</Divider>
					<div className="flex align-items-center justify-content-between mb-3">
						<p className="m-0 font-semibold">KYC Verified:</p>
						<Tag
							className="mr-0"
							severity={
								profileData?.profile?.aadhaarVerified &&
								profileData?.profile?.panVerified
									? "success"
									: "danger"
							}
							value={
								profileData?.profile?.aadhaarVerified &&
								profileData?.profile?.panVerified
									? "VERIFIED"
									: "NOT VERIFIED"
							}
						></Tag>
					</div>
					{profileData?.profile?.aadhaarVerified &&
					profileData?.profile?.panVerified ? (
						<>
							<div className="flex align-items-center justify-content-between mb-3">
								{/* <p className="m-0 font-semibold">
									Verified by:
								</p>
								<p className="m-0 font-semibold text-primary">
									Hyperbarz
								</p> */}
							</div>
							<div className="flex align-items-center justify-content-between">
								{/* <p className="m-0 font-semibold">
									Verified On:
								</p>
								<p className="m-0 font-semibold text-primary">
									30th Nov 23, 12:45pm
								</p> */}
								<p className="m-0 font-semibold">Created On:</p>
								<p className="m-0 font-semibold text-primary">
									{profileData?.profile?.agent?.createdAt
										? moment(
												profileData?.profile?.agent
													?.createdAt
										  ).format("dddd, MMMM Do YYYY")
										: " - "}
								</p>
							</div>
						</>
					) : (
						<></>
					)}
					{/* <Divider align="left" type="dashed">
						<div className="inline-flex align-items-center">
							<b>Bank Details</b>
						</div>
					</Divider>

					<BankDetails agent={profileData} /> */}
					<Formik
						initialValues={{
							id: profileData?.profile?.agent?.id,
							workExperienceAsFinance:
								profileData?.profile?.agent
									?.workExperienceAsFinance,
							qualification:
								profileData?.profile?.agent?.qualification,
							previousWorkExperience:
								profileData?.profile?.agent
									?.previousWorkExperience,
							productWorkEarlier:
								profileData?.profile?.agent?.productWorkEarlier,
						}}
						enableReinitialize
						onSubmit={handleQualification}
					>
						{({ handleSubmit }) => (
							<Form
								onSubmit={handleSubmit}
								className="grid form-grid c-agent-form"
							>
								<div className="field col-12 md:col-12">
									<Field
										header={"Work Experience As Finance"}
										component={FieldInput}
										name={"workExperienceAsFinance"}
									/>
								</div>
								<div className="field col-12 md:col-12">
									<Field
										header={"Qualification"}
										component={FieldInput}
										name={"qualification"}
									/>
								</div>
								<div className="field col-12 md:col-12">
									<Field
										header={"Previous Work Experience"}
										component={FieldInput}
										name={"previousWorkExperience"}
									/>
								</div>
								<div className="field col-12 md:col-12">
									<Field
										header={"Product Work Earlier"}
										component={FieldInput}
										name={"productWorkEarlier"}
									/>
								</div>
								<Button
									className="mt-2"
									type="submit"
									label="Save"
								/>
							</Form>
						)}
					</Formik>
				</div>
				<div className="md:col-9 pl-5 c-agent-info-new">
					<div className="flex align-items-end  c-agent-info-new-name">
						<h1 className="m-0 line-height-1">
							{profileData?.profile?.agent?.fullName}
						</h1>
						<small className="ml-2 font-semibold">
							<i className="pi pi-map-marker"></i>
							{profileData?.profile?.district?.name}
							{", "}
							{profileData?.profile?.district?.stateId?.name}
						</small>
					</div>
					<div className="my-2 font-semibold">
						<small>
							Agent Code:{" "}
							<span className="text-primary">
								{profileData?.profile?.agent?.agentCode
									? profileData?.profile?.agent?.agentCode
									: " - "}
							</span>
						</small>{" "}
						{profileData?.profile?.aadharDateOfBirth && (
							<small>
								Age:{" "}
								<span className="text-primary">
									{new Date().getFullYear() -
										new Date(
											profileData?.profile?.aadharDateOfBirth
										).getFullYear()}
								</span>{" "}
								Years
							</small>
						)}
					</div>
					<div className="my-2 font-semibold">
						<small>
							Username :{" "}
							<span className="text-primary">
								{profileData?.profile?.agent?.userName}
							</span>
						</small>{" "}
						<small>
							Profile Type :{" "}
							<span className="text-primary">
								{profileData?.profile?.profileType}
							</span>
						</small>
					</div>
					<div className="flex justify-content-between align-items-center">
						<p className="my-2 font-semibold">
							{/* <small>
								Experience as financial advisor:{" "}
								<span className="text-primary">36 months</span>
							</small> */}
						</p>
						<div className="c-socialIcon flex gap-3">
							<Link>
								<i className="pi pi-linkedin"></i>
							</Link>
							<Link>
								<i className="pi pi-facebook"></i>
							</Link>
							<Link>
								<i className="pi pi-twitter"></i>
							</Link>
							<a
								href={`https://wa.me/${profileData?.profile?.agent?.mobileNumber}`}
								target="_blank"
								rel="noreferrer"
							>
								<i className="pi pi-whatsapp"></i>
							</a>
						</div>
					</div>
					<div className="col-12">
						<div className="grid">
							<div className="md:col-7">
								<Divider align="left" type="dashed">
									<div className="inline-flex align-items-center">
										<b>Contact</b>
									</div>
								</Divider>

								<p>
									<i className="pi pi-phone mr-2"></i>{" "}
									<a
										href={`tel:${profileData?.profile?.agent?.mobileNumber}`}
										className="text-primary"
									>
										{
											profileData?.profile?.agent
												?.mobileNumber
										}
									</a>
								</p>
								<p>
									<i className="pi pi-envelope mr-2"></i>{" "}
									<a
										href={`mailto:${profileData?.profile?.agent?.emailId}`}
										className="text-primary"
									>
										{profileData?.profile?.agent?.emailId}
									</a>
								</p>
								<Divider align="left" type="dashed">
									<div className="inline-flex align-items-center">
										<b>Products</b>
									</div>
								</Divider>
								{profileData?.products
									?.flatMap((elm) => elm?.subProducts)
									?.slice(0, 4)
									.map((item) => (
										<Chip
											key={item.id}
											label={item.title}
											icon="pi pi-check"
											className="mb-2 mr-2 bg-green-100 text-green-800"
										/>
									))}

								{profileData?.products && (
									<Button
										className="p-button-text text-gray-700"
										label="... more"
										onClick={() => setProductDialog(true)}
									/>
								)}
							</div>
							<div className="md:col-5">
								<Divider align="left" type="dashed">
									<div className="inline-flex align-items-center ">
										<b>Activity History</b>
									</div>
								</Divider>

								<p>
									Last login:{" "}
									<span className="text-primary">
										{activity?.lastLogin !== "0"
											? moment(
													activity?.lastLogin
											  ).format("DD MMM,YYYY HH:mm")
											: "-"}
									</span>
								</p>
								<p className="font-semibold mb-3">
									Time spent on MyHero app:{" "}
								</p>
								<div className="w-100 flex">
									<div className="flex-1 border-1 border-300 border-round-left p-2 text-center">
										<span className="text-500">
											Maximum
										</span>
										<h3>
											{moment
												.utc(activity?.maxTime * 1000)
												.format("HH:mm:ss")}
										</h3>
									</div>
									<div className="flex-1 border-y-1 border-300 p-2 text-center">
										<span className="text-500">
											Average
										</span>
										<h3>
											{moment
												.utc(
													activity?.averageTime * 1000
												)
												.format("HH:mm:ss")}
										</h3>
									</div>
									<div className="flex-1 border-1 border-300 border-round-right p-2 text-center">
										<span className="text-500">
											Minimum
										</span>
										<h3>
											{moment
												.utc(activity?.minTime * 1000)
												.format("HH:mm:ss")}
										</h3>
									</div>
								</div>
								<div className="flex align-items-center mt-2 justify-content-end">
									<Button
										label="Activity History"
										className="p-button-text p-0"
										onClick={() =>
											handleActivity(
												profileData?.profile?.agent?.id
											)
										}
									/>
								</div>
							</div>
						</div>
					</div>
					<ProfileIndex
						agentData={agentData?.state}
						agent={profileData}
						handleNoteSubmit={handleNoteSubmit}
					/>
				</div>
			</div>
			<Dialog
				style={{ width: "60%" }}
				visible={productDialog}
				header={"All Products"}
				onHide={() => setProductDialog(false)}
			>
				<div className="modal-form-grid">
					<ul className="flex justify-content-between align-items-center flex-wrap m-0 p-0">
						{profileData?.products
							?.flatMap((elm) => elm.subProducts)
							.map((item) => (
								<li
									key={item.id}
									style={{ width: "50%", listStyle: "none" }}
								>
									<Chip
										label={item.title}
										icon="pi pi-check"
										className="mb-2 mr-2 bg-green-100 text-green-800"
									/>
								</li>
							))}
					</ul>
				</div>
			</Dialog>
			<Dialog
				style={{ width: "60%" }}
				visible={noteDialog}
				header={"Activity"}
				onHide={() => setNoteDialog(false)}
			>
				<DataTable value={activityData}>
					<Column
						body={(item) => {
							switch (item?.activity) {
								case "LOGIN":
									return "Login";
								case "PAUSE":
									return "MH-Close";
								case "RESUME":
									return "MH-Open";
								case "LOGOUT":
									return "Logout";
								default:
									return item?.activity;
							}
						}}
						header={"Activity"}
					/>
					<Column
						body={(elm) =>
							elm.transactionDateTime
								? moment(elm.transactionDateTime).format(
										"DD MMM,YYYY"
								  )
								: ""
						}
						header={"Transaction Date Time"}
					/>
					<Column
						body={(item) =>
							item?.duration
								? moment
										.utc(item?.duration * 1000)
										.format("HH:mm:ss")
								: ""
						}
						header={"Duration"}
					/>
				</DataTable>
			</Dialog>
		</>
	);
};

export default AgentProfile;
