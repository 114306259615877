import axios from "axios";
import { agent_end_point_url, apiPath } from "../shared/constant";

export class ProgramService {
	constructor() {
		this.token = sessionStorage.getItem("token");
		this.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${this.token}`,
		};
	}

	timelineStatus = async (id) => {
		try {
			const res = await axios.get(
				`${agent_end_point_url}/leadlog/lead-log/getAll/${id}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	createProgram = async (payload) => {
		try {
			const res = await axios.post(`${apiPath}/program/create`, payload, {
				headers: this.headers,
			});
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	createInsuranceProgram = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/create/insurance-program`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	createRetirementProgram = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/create/retirement-program`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	updateProgram = async (payload) => {
		try {
			const res = await axios.post(`${apiPath}/program/update`, payload, {
				headers: this.headers,
			});
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	creditBureauCreate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/credit-bureau/create`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	creditBureauUpdate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/credit-bureau/update`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	commissionCreate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/commission-structure/create`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	commissionUpdate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/commission-structure/update`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	eligibilityCreate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/eligibility-criteria/create`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	eligibilityUpdate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/eligibility-criteria/update`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getInstitution = async () => {
		try {
			const res = await axios.get(
				`${apiPath}/institution/svc/all`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	offerCreate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/offer/create`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getInstitionWiseList = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/list-institution-wise`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getProgramBySubId = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/program-institution-wise`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAllProgramList = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/searchProgram`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getFullProgramData = async (id) => {
		try {
			const res = await axios.get(
				`${apiPath}/program/${id}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	deleteProgram = async (id) => {
		try {
			const reqBody = {};
			const res = await axios.post(
				`${apiPath}/program/delete/${id}`,
				reqBody,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	offerUpdate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/offer/update`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	statusUpdate = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/update/active-status`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAllCommissionById = async (id) => {
		try {
			const res = await axios.get(
				`${agent_end_point_url}/agent/${id}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	updateEmployeeCommission = async (payload) => {
		try {
			const res = await axios.post(
				`${agent_end_point_url}/agent/update`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};

	addCommissionAgent = async (payload) => {
		try {
			const res = await axios.post(
				`${agent_end_point_url}/agent/add`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	addCommission = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/add/program-commission`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	updateCommission = async (payload) => {
		try {
			const res = await axios.post(
				`${apiPath}/program/update/program-commission`,
				payload,
				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getAllCommission = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/program/all/program-commission/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
	getFullProgram = async (payload) => {
		try {
			const res = await axios.get(
				`${apiPath}/program/copy-program/${payload}`,

				{
					headers: this.headers,
				}
			);
			return res.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
}
