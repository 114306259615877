import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import { useEffect, useReducer, useRef, useState } from "react";
import {
	searchInitialState,
	searchReducer,
} from "../../../components/searchReducer";
import Config from "../../../shared/config";
import Paginator from "../../../components/Paginator";
import {
	dataReducer,
	fetchInitialState,
} from "../../../components/fetchReducer";
import { Dialog } from "primereact/dialog";
import MenuComponent from "../../../components/MenuComponent";
import { PaymentService } from "../../../services/paymentService";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import SearchInvoice from "./components/SearchInvoice";
import Loader from "../../../components/Loader";
import { Field, Form, Formik } from "formik";
import {
	FeildCalender,
	FeildDropdown,
	FieldInput,
	FieldTextArea,
} from "../../../components/FieldInput";
import * as Yup from "yup";
import TranstionList from "./components/TranstionList";
import { FileUpload } from "primereact/fileupload";
import { S3 } from "aws-sdk";
import { folder_path } from "../../../shared/constant";
import PaymentPreview from "./components/PaymentPreview";
const InvoiceDetailsList = () => {
	const navigate = useNavigate();
	const toastTL = useRef(null);
	const [searchState, searchDispatch] = useReducer(
		searchReducer,
		searchInitialState
	);
	const [productName, setProductName] = useState(null);
	const [paymentData, setPaymentData] = useState({ commissionDetails: [] });
	const s3Clint = new S3({
		accessKeyId: Config.S3_ACCESSKEYID,
		secretAccessKey: Config.S3_SECRETACCESSKEY,
		region: Config.S3_REGION,
		signatureVersion: "v4",
	});

	const paymentService = new PaymentService();
	const [members, setMembers] = useReducer(dataReducer, fetchInitialState);
	const [paymentDialog, setPaymentDialog] = useState({
		id: null,
		state: false,
		netAmount: 0,
	});
	const [paymentPreviewDialog, setPaymentPreviewDialog] = useState(false);
	const [invoiceDialog, setInvoiceDialog] = useState({
		details: null,
		state: false,
	});
	const [paymentDialogList, setPaymentDialogList] = useState(false);
	const [transactionList, setTransactionList] = useState([]);
	// function getSortableColumn(name, columnName) {
	// 	return (
	// 		<span className="flex align-items-center">
	// 			{name}
	// 			<span className="flex flex-column text-xs ml-2">
	// 				<span
	// 					className={
	// 						searchState.sortByColumn === columnName &&
	// 						searchState.sortType === "asc"
	// 							? "sort-icon asc active"
	// 							: "sort-icon asc"
	// 					}
	// 					onClick={() => tblSort(columnName, "asc")}
	// 				></span>
	// 				<span
	// 					className={
	// 						searchState.sortByColumn === columnName &&
	// 						searchState.sortType === "desc"
	// 							? "sort-icon desc active"
	// 							: "sort-icon desc"
	// 					}
	// 					onClick={() => tblSort(columnName, "desc")}
	// 				></span>
	// 			</span>
	// 		</span>
	// 	);
	// }
	const validationSchema = Yup.object().shape({
		txnId: Yup.string().required("This field is required"),
		amount: Yup.number()
			.nullable()
			.test({
				test: (amount) =>
					!!amount && amount <= Number(paymentDialog.netAmount),
				message: "Invalid amount",
			})
			.required("This field is required"), //number
		// description: Yup.string().required("This field is required"),
		type: Yup.string().required("This field is required"),
		txnDate: Yup.date().required("This field is required"),
	});
	// function tblSort(columnName, sortType) {
	// 	// setSortByColumn(columnName);
	// 	// setSortType(sortType);

	// 	searchDispatch({
	// 		type: Config.SEARCH_CONFIG.sortByColumn,
	// 		payload: columnName,
	// 	});
	// 	searchDispatch({
	// 		type: Config.SEARCH_CONFIG.sortType,
	// 		payload: sortType,
	// 	});
	// }

	const increment = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.increment });
	};
	const decrement = () => {
		searchDispatch({ type: Config.SEARCH_CONFIG.decrement });
	};
	const pagesizechange = (e) => {
		// const newPageNo = Math.ceil(
		// 	(members.data.pageSize * members.data.pageNumber) / e.target.value
		// );

		searchDispatch({
			type: Config.SEARCH_CONFIG.pageSize,
			payload: e.target.value,
		});
		searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};
	const getPaymentData = (payload) => {
		setMembers({ type: Config.FETCH_CONFIG.start });
		paymentService
			.financeAgentInvoice(payload)
			.then((res) => setPaymentData(res))
			.finally(() => {
				setMembers({
					type: Config.FETCH_CONFIG.stop,
				});
				setMembers({
					type: Config.FETCH_CONFIG.fetched,
				});
			});
	};
	const getData = () => {
		setMembers({ type: Config.FETCH_CONFIG.start });
		const body = {
			...searchState.searchValue,
			pageNo: searchState.pageNo,
			pageSize: searchState.pageSize,
			...(searchState.sortByColumn &&
				searchState.sortType && {
					sorts: [
						searchState.sortByColumn + "," + searchState.sortType,
					],
				}),
		};
		const CleanObject = (body) => {
			for (let propName in body) {
				if (body[propName] === "" || body[propName] === null) {
					delete body[propName];
				}
			}
			return body;
		};
		paymentService
			.getInvoiceList(CleanObject(body))
			.then((res) => {
				setMembers({
					type: Config.FETCH_CONFIG.success,
					payload: res,
				});
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setMembers({
					type: Config.FETCH_CONFIG.stop,
				});
				setMembers({
					type: Config.FETCH_CONFIG.fetched,
				});
			});
	};
	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchState]);
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />
				<Column header={"Invoice Date"} />
				<Column header={"Name"} />
				<Column header={"Invoice Number"} />
				<Column header={"Transaction Date"} />
				<Column header={"Status"} />
				<Column header={"Total Amount"} />
				<Column header={"Net Amount"} />
				<Column header={"Agent code"} />
				<Column header={"Mobile Number"} />
				<Column header={"Product"} />
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);

	const handlePaymentGenerate = (value) => {
		setMembers({
			type: Config.FETCH_CONFIG.start,
		});
		paymentService
			.paymentGenerateFinance({
				agentInvoiceId: paymentDialog.id,
				txnId: value.txnId,
				amount: value.amount,
				description: value.description,
				type: value.type,
				txnDate: value.txnDate,
				document: value.document,
			})
			.then((res) => {
				toastTL.current.show({
					severity: "success",
					detail: res?.message,
					style: { color: "#000000" },
					life: 3000,
				});
				getData();
				setPaymentDialog((prev) => ({
					...prev,
					state: false,
					id: null,
					netAmount: null,
				}));
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setMembers({
					type: Config.FETCH_CONFIG.stop,
				});
			});
	};
	const getTransactionList = (value) => {
		setMembers({
			type: Config.FETCH_CONFIG.start,
		});
		paymentService
			.getTranasctionListByAgent(value)
			.then((res) => {
				// toastTL.current.show({
				// 	severity: "success",
				// 	detail: res?.message,
				// 	style: { color: "#000000" },
				// 	life: 3000,
				// });
				setTransactionList(res);
			})
			.catch((e) => {
				toastTL.current.show({
					severity: "error",
					detail: e?.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setMembers({
					type: Config.FETCH_CONFIG.stop,
				});
			});
	};
	const actionTemplate = (item) => {
		const items1 = [
			{
				label: "Profile",
				icon: "pi pi-user",
				command: () => {
					navigate(`/vendor/agent-profile/${item?.agentId}`, {
						state: {
							profileId: item?.profileId,
						},
					});
				},
			},
			{
				label: "Invoice Details",
				icon: "pi pi-credit-card",
				command: () => {
					setPaymentPreviewDialog(true);
					setProductName(item?.productName);
					getPaymentData({
						month: item?.month, //"Jan, 2024"
						productId: [item?.productId],
						agentId: item?.agentId,
					});
				},
			},
		];
		if (item?.isPaid) {
			items1.push({
				label: "Payment Details",
				icon: "pi pi-credit-card",
				command: () => {
					setPaymentDialogList(true);
					getTransactionList(item?.id);
				},
			});
			// items1.push({
			// 	label: "Payment details",
			// 	icon: "pi pi-credit-card",
			// 	command: () => {
			// 		setInvoiceDialog((prev) => ({
			// 			...prev,
			// 			state: true,
			// 			details: item,
			// 		}));
			// 	},
			// });
		} else {
			items1.push({
				label: "Pay",
				icon: "pi pi-credit-card",
				command: () => {
					setPaymentDialog((prev) => ({
						...prev,
						state: true,
						id: item?.id,
						netAmount: item?.netAmount,
					}));
					getTransactionList(item?.agentId);
				},
			});
		}
		return (
			<div className="flex gap-2">
				<MenuComponent items={items1} />
			</div>
		);
	};
	const onUploadImage = (e, setFieldValue) => {
		const file = e.files[0];
		const fileName = file.name;
		const fileNameArray = fileName.split(".");
		const fileExtension = fileNameArray[fileNameArray.length - 1];
		const newFileName = `${new Date().getTime()}.${fileExtension}`;
		const params = {
			Body: file,
			Bucket: Config.INVOICE_ATTACHMENT_BUCKET + "/" + folder_path,
			Key: newFileName,
			ContentType: file.type,
		};

		s3Clint.upload(params, (err) => {
			if (err) {
				return toastTL.current.show({
					severity: "error",
					summary: "Error",
					detail: "Select Document Types",
					style: { color: "#000000" },
					life: 3000,
				});
			}
			setFieldValue("document", newFileName, true);
			toastTL.current.show({
				severity: "success",
				summary: "Successfull",
				detail: "Document uploaded",
				style: { color: "#000000" },
				life: 3000,
			});
		});
	};
	return (
		<>
			{members?.isLoading && <Loader />}
			<Toast ref={toastTL} position="top-left" />
			<SearchInvoice searchDispatch={searchDispatch} />
			<DataTable
				headerColumnGroup={headerGroup}
				value={members?.data?.data?.map((item, index) => ({
					...item,
					index:
						members?.data?.pageSize * members?.data?.pageNo -
						members?.data?.pageSize +
						1 +
						index,
				}))}
			>
				<Column field="index" />
				<Column
					body={(item) => {
						return item?.month;
					}}
				/>
				<Column field="fullName" />
				<Column field="invoiceNumber" />
				<Column
					body={(item) =>
						item?.txnDate ? (
							moment(item?.txnDate).format("Do MMM YYYY hh:MM a")
						) : (
							<></>
						)
					}
				/>
				<Column
					body={(item) =>
						item?.isPaid ? (
							<span className="px-2 py-2 white-space-nowrap bg-green-100 text-green-800 rounded-md">
								Invoiced & Paid
							</span>
						) : (
							<span className="px-2 py-2 white-space-nowrap bg-orange-100 text-orange-800 rounded-md">
								Invoiced
							</span>
						)
					}
				/>
				<Column field="totalAmount" />
				<Column field="netAmount" />
				<Column
					body={(item) => {
						return (
							<Button
								className="p-button-text p-0 m-0"
								onClick={() =>
									navigate(
										`/vendor/agent-profile/${item?.agentId}`,
										{
											state: {
												profileId: item?.profileId,
											},
										}
									)
								}
								label={item?.agentCode}
							/>
						);
					}}
				/>
				<Column field="mobileNumber" />
				<Column field="productName" />
				{/* <Column field="districtName" /> */}
				<Column body={actionTemplate} />
			</DataTable>
			{members?.data?.data?.length > 0 && (
				<Paginator
					pageSize={searchState.pageSize}
					firstPage={members.data.firstPage}
					lastPage={members.data.lastPage}
					decrement={decrement}
					increment={increment}
					pagesizechange={pagesizechange}
					pageNo={members.data.pageNo}
					totalPages={members.data.totalPages}
					totalElements={members.data.totalElements}
				/>
			)}
			<Dialog
				visible={invoiceDialog.state}
				onHide={() =>
					setInvoiceDialog((prev) => ({
						...prev,
						state: false,
						details: null,
					}))
				}
				header={"Payemnt Info"}
				style={{ width: "80%" }}
			>
				<div className="cr-invoice grid-modal-from">
					{/* <table
						id="genpdf"
						style={{
							width: "100%",
							fontFamily: "Arial, Helvetica, sans-serif",
							borderCollapse: "collapse",
							padding: 0,
							margin: "0 auto",
							marginTop: 10,
							border: "1px solid #000",
						}}
					>
						<tbody>
							<tr>
								<td>
									<table
										style={{
											width: "100%",
											fontFamily:
												"Arial, Helvetica, sans-serif",
											borderCollapse: "collapse",
											padding: 0,
											margin: 0,
										}}
									>
										<tbody>
											<tr>
												<td
													style={{
														width: "60%",
														padding: 10,
														borderBottom:
															"1px solid #000",
													}}
												>
													<h2
														style={{
															margin: 0,
															lineHeight:
																"normal",
															marginBottom: 10,
															fontFamily:
																"Arial, Helvetica, sans-serif",
															fontSize: 18,
															fontWeight: "bold",
														}}
													>
														CONNECTEOR NAME-
														{
															invoiceDialog
																?.details
																?.fullName
														}
													</h2>
												</td>
												<td
													style={{
														width: "40%",
														padding: 10,
														borderBottom:
															"1px solid #000",
													}}
												>
													<h2
														style={{
															margin: 0,
															lineHeight:
																"normal",
															marginBottom: 10,
															textAlign: "center",
															fontFamily:
																"Arial, Helvetica, sans-serif",
															fontSize: 18,
															fontWeight: "bold",
														}}
													>
														INVOICE-
														{
															invoiceDialog
																?.details
																?.invoiceNumber
														}
													</h2>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
							<tr>
								<td>
									<table
										style={{
											width: "100%",
											fontFamily:
												"Arial, Helvetica, sans-serif",
											borderCollapse: "collapse",
											padding: 0,
											margin: 0,
										}}
									>
										<tbody>
											<tr>
												<td
													style={{
														width: "100%",
														padding: 10,
													}}
												>
													<table
														style={{
															width: "100%",
															fontFamily:
																"Arial, Helvetica, sans-serif",
															borderCollapse:
																"collapse",
															padding: 0,
															margin: 0,
															border: "1px solid #000",
														}}
													>
														<tbody>
															<tr>
																<td
																	style={{
																		border: "1px solid #000",
																		fontWeight:
																			"bold",
																		padding: 5,
																		fontSize: 12,
																		width: "50%",
																	}}
																>
																	TOTAL AMOUNT
																</td>
																<td
																	style={{
																		border: "1px solid #000",
																		padding: 5,
																		width: "50%",
																	}}
																>
																	{
																		invoiceDialog
																			?.details
																			?.totalAmount
																	}
																</td>
															</tr>
															<tr>
																<td
																	style={{
																		border: "1px solid #000",
																		fontWeight:
																			"bold",
																		padding: 5,
																		fontSize: 12,
																		width: "50%",
																	}}
																>
																	PAYABLE
																	AMOUNT
																</td>
																<td
																	style={{
																		border: "1px solid #000",
																		padding: 5,
																		width: "50%",
																	}}
																>
																	{
																		invoiceDialog
																			?.details
																			?.netAmount
																	}
																</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table> */}
					<div className="cr-details">
						<div className="flex justify-content-between align-items-top">
							<h2>{invoiceDialog?.details?.fullName}</h2>
							<p className="m-0">
								<strong>Invoice No. :</strong>
								{invoiceDialog?.details?.invoiceNumber}
							</p>
						</div>
						<div className="flex justify-content-start align-items-center flex-wrap gap-2">
							<p>
								<strong>Mobile :</strong>
								{invoiceDialog?.details?.mobileNumber}
							</p>

							<p>
								<strong>Agent Code :</strong>
								{invoiceDialog?.details?.agentCode}
							</p>

							<p>
								<strong>TXN Date :</strong>
								{invoiceDialog?.details?.txnDate
									? moment(
											invoiceDialog?.details?.txnDate
									  ).format("Do MMM YYYY hh:mm A")
									: ""}
							</p>
							<p>
								<strong>Product :</strong>
								{invoiceDialog?.details?.productName}
							</p>

							<p>
								<strong>Total Amount :</strong>
								{invoiceDialog?.details?.totalAmount}
							</p>
							<p>
								<strong>Payable Amount :</strong>
								{invoiceDialog?.details?.netAmount}
							</p>
							<p>
								<strong>Paid :</strong>
								{invoiceDialog?.details?.isPaid ? (
									<span className="px-2 py-2 white-space-nowrap bg-green-100 text-green-800 rounded-md">
										Invoiced & Paid
									</span>
								) : (
									<span className="px-2 py-2 white-space-nowrap bg-orange-100 text-orange-800 rounded-md">
										Invoiced
									</span>
								)}
							</p>
							{/* <p>
								<strong>Status :</strong>
								{invoiceDialog?.details?.isPaid}
							</p> */}
						</div>
						<table
							style={{
								width: "100%",
								fontFamily: "Arial, Helvetica, sans-serif",
								borderCollapse: "collapse",
								padding: 0,
								margin: 0,
								marginTop: 20,
								border: "1px solid #eee",
							}}
						>
							<tbody>
								<tr>
									<td
										style={{
											border: "1px solid #eee",
											fontWeight: "bold",
											padding: 5,
											fontSize: 12,
											width: "50%",
										}}
									>
										TOTAL AMOUNT
									</td>
									<td
										style={{
											border: "1px solid #eee",
											padding: 5,
											width: "50%",
										}}
									>
										{invoiceDialog?.details?.totalAmount}
									</td>
								</tr>
								<tr>
									<td
										style={{
											border: "1px solid #eee",
											fontWeight: "bold",
											padding: 5,
											fontSize: 12,
											width: "50%",
										}}
									>
										PAYABLE AMOUNT
									</td>
									<td
										style={{
											border: "1px solid #eee",
											padding: 5,
											width: "50%",
										}}
									>
										{invoiceDialog?.details?.netAmount}
									</td>
								</tr>
							</tbody>
						</table>
						{/* {invoiceDialog?.details?.productId} */}
					</div>
				</div>
			</Dialog>
			<Dialog
				visible={paymentDialog.state}
				onHide={() => {
					setPaymentDialog((prev) => ({
						...prev,
						state: false,
						id: null,
						netAmount: null,
					}));
				}}
				header={"Transaction"}
				style={{ width: "50%" }}
			>
				<Formik
					initialValues={{
						txnId: "",
						amount: paymentDialog.netAmount, //number
						description: "",
						type: "",
						txnDate: "",
						document: "",
					}}
					onSubmit={handlePaymentGenerate}
					enableReinitialize
					validationSchema={validationSchema}
				>
					{({ handleSubmit, resetForm, setFieldValue }) => (
						<Form
							onSubmit={handleSubmit}
							className="modal-form-grid c-search-form-box border-round-md form-grid grid mt-3"
						>
							<div className="field col-12 md:col-6">
								<Field
									header={"Transaction Date"}
									name={"txnDate"}
									component={FeildCalender}
								/>
							</div>
							<div className="field col-12 md:col-6">
								<Field
									header={"Transaction Id"}
									name={"txnId"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-6">
								<Field
									header={"Transaction Type"}
									name={"type"}
									options={Config.PAYMENT_TYPE_FINANCE}
									component={FeildDropdown}
								/>
							</div>
							<div className="field col-12 md:col-6">
								<Field
									header={"Transaction Amount"}
									name={"amount"}
									component={FieldInput}
									type={"number"}
								/>
							</div>
							<div className="field col-12">
								<Field
									header={"Description"}
									name={"description"}
									component={FieldTextArea}
								/>
							</div>
							<div className="field col-12">
								<FileUpload
									// progressBarTemplate={progressBar}
									customUpload
									accept=".jpg,.png,.jpeg, .pdf"
									uploadHandler={(e) =>
										onUploadImage(e, setFieldValue)
									}
									maxFileSize={1000000}
									emptyTemplate={
										<p className="m-0">
											Drag and drop files to here to
											upload.
										</p>
									}
								/>
							</div>
							<div className="flex col-12 justify-content-end align-items-center">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Save" />
							</div>
						</Form>
					)}
				</Formik>
				{/* <TranstionList transactionData={transactionList} /> */}
			</Dialog>
			<Dialog
				visible={paymentDialogList}
				onHide={() => {
					setPaymentDialogList(false);
					setTransactionList([]);
				}}
				header={"Payment Details"}
				style={{ width: "50%" }}
			>
				<TranstionList transactionData={transactionList} />
			</Dialog>
			<Dialog
				visible={paymentPreviewDialog}
				onHide={() => {
					setPaymentPreviewDialog(false);
					setPaymentData({ commissionDetails: [] });
				}}
				header={"Commission Invoice Details"}
				style={{ width: "80%" }}
			>
				<PaymentPreview
					paymentData={paymentData}
					productName={productName}
				/>
			</Dialog>
		</>
	);
};

export default InvoiceDetailsList;
