import { useRef, useState } from "react";
import {
	useGetProductDetailsQuery,
	useGetStateListQuery,
} from "../../../../services/query/queryApi";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

import { Skeleton } from "primereact/skeleton";
import { useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import * as Yup from "yup";
import Header from "../../../partners/institutions/header";
import { Accordion, AccordionTab } from "primereact/accordion";
import {
	setApplicationId,
	setTypeName,
} from "../../../../store/reducer/AuthReducer";
import { Field, Form, Formik } from "formik";
import { FeildDropdown, FieldInput } from "../../../../components/FieldInput";
import selectState from "../../../../components/stateList";
import { LoanApplicationService } from "../../../../services/LoanApplication";
import { useReducer } from "react";
import { ProgramOtpService } from "../../../../services/ProgramOtpService";
import { Toast } from "primereact/toast";
import debounce from "lodash/debounce";
import Loader from "../../../../components/Loader";
const CreateVendorApplication = () => {
	const globalDispatch = useDispatch();
	const toast = useRef(null);
	const { data: stateData = [] } = useGetStateListQuery();
	const [dialog, setDialog] = useState(false);
	const [productAlias, setProductAlias] = useState({
		alias: null,
		subproductId: null,
		productName: null,
	});
	const navigate = useNavigate();
	const [customerShare, setCustomerShare] = useState(false);
	const otpService = new ProgramOtpService();
	const loanApplicationService = new LoanApplicationService();
	const { data = { data: [] }, isLoading } = useGetProductDetailsQuery({
		searchRequest: [],
		pageNo: 1,
		pageSize: 330,
		sorts: [],
	});
	const [districtListState, setDistrict] = useReducer(
		(state, action) => {
			return { ...state, districtList: action.payload };
		},
		{ districtList: [] }
	);

	// const [stages] = useState([
	// 	{
	// 		sequence: 1,
	// 		title: "Select a Product",
	// 		status: "active",
	// 	},
	// 	{
	// 		sequence: 2,
	// 		title: "Add Basic Details",
	// 		status: "inactive",
	// 	},

	// 	{
	// 		sequence: 3,
	// 		title: "Application Details",
	// 		status: "inactive",
	// 	},
	// ]);
	const handleFormSubmit = (value, action) => {
		globalDispatch(setTypeName("create"));
		globalDispatch(setApplicationId(null));
		navigate("/vendor/application/create", {
			state: {
				mobile: value.mobileNumber,
				pan: value.panNumber,
				leadId: null,
				subproductId: productAlias.subproductId,
				alias: productAlias.alias,
				applicationId: null,
				stateId: value.state,
				districtId: value.district,
				applicationName: productAlias?.productName,
			},
		});
	};
	const subProductAction = (item) => {
		return (
			item?.alias && (
				<Button
					className="py-2 px-3"
					onClick={() => {
						setDialog(true);
						globalDispatch(setApplicationId(null));
						setProductAlias((prev) => ({
							...prev,
							alias: item?.alias,
							subproductId: item?.id,
							productName: item?.title,
						}));
					}}
					label="Apply"
					icon="pi pi-angle-right"
				/>
			)
		);
	};
	const validationSchema = Yup.object().shape({
		mobileNumber: Yup.string()
			.matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Mobile No is invalid")
			.required("This field is required"),
		panNumber: Yup.string()
			.matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Pan Number is invalid")
			.required("This field is required"),
		state: Yup.number().required("This field is required"),
		district: Yup.number().required("This field is required"),
	});

	const handleDistrict = (e, setFieldValue) => {
		loanApplicationService
			.getDistrictByStateID(e)
			.then((res) => {
				setDistrict({
					payload: res.map((item) => ({ ...item, value: item.id })),
				});
			})
			.catch(() => {});
		setFieldValue("state", e, true);
	};
	const shareWithCustomer = (value) => {
		const body = {
			agentCode: null,
			applicationType: productAlias === "CC" ? "CREDIT_CARD" : "LOAN",
			districtId: value.district,
			leadId: null,
			mobile: value.mobileNumber,
			offerId: null,
			pan: value.pan,
			stateId: value.state,
			productId: productAlias.subproductId,
		};
		setCustomerShare(true);
		otpService
			.shareWithCustomer(body)
			.then((res) => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "Success",
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.then(debounce(() => navigate("/vendor/application-list"), 500))
			.catch((e) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: e.message,
					style: { color: "#000000" },
					life: 3000,
				});
			})
			.finally(() => {
				setCustomerShare(false);
			});
	};
	return (
		<>
			{customerShare && <Loader />}
			<Toast ref={toast} />
			<Header
				stages={[]}
				title="Application"
				stageName={"Create Application"}
			/>

			<Dialog
				visible={dialog}
				onHide={() => {
					setDialog(false);
				}}
				style={{ minWidth: "40%" }}
				header="Basic Details"
			>
				<div className="px-5 py-5">
					<Formik
						initialValues={{
							mobileNumber: "",
							state: "",
							district: "",
							panNumber: "",
						}}
						onSubmit={handleFormSubmit}
						validationSchema={validationSchema}
						enableReinitialize
					>
						{({ handleSubmit, values, setFieldValue, isValid }) => (
							<Form onSubmit={handleSubmit}>
								<div className="flex py-4 flex-column align-items-center justify-content-center font-bold">
									<div className="field mb-3 w-28rem">
										<Field
											header={"Customer Mobile Number"}
											name={"mobileNumber"}
											component={FieldInput}
											star={"*"}
										/>
									</div>
									<div className="field mb-3 w-28rem">
										<Field
											header={"Customer PAN Number"}
											name={"panNumber"}
											component={FieldInput}
											star={"*"}
										/>
									</div>

									<div className="field mb-3 w-28rem">
										<Field
											header={"Customer State"}
											name={"state"}
											component={FeildDropdown}
											options={selectState(stateData)}
											filter
											onChange={(e) => {
												handleDistrict(
													e.value,
													setFieldValue
												);
											}}
											optionLabel={"name"}
											star={"*"}
											type={"number"}
										/>
									</div>

									<div className="field mb-3 w-28rem">
										<Field
											header={"Customer District"}
											name={"district"}
											component={FeildDropdown}
											filter
											options={
												districtListState.districtList
											}
											optionLabel={"name"}
											star={"*"}
											type={"number"}
										/>
									</div>

									<div className=" flex align-items-center justify-content-between">
										<Button
											label="Continue"
											className=" mr-2"
											disabled={
												isValid && values.mobileNumber
													? false
													: true
											}
											type={"submit"}
											// 	// type="button"
											// 	onClick={
											// 		handleSubmit
											// 		// (e) => {
											// 		// e.preventDefault();

											// 		// customerSubmit(values);
											// 	}
											// // }
										/>
										{productAlias !== "HC" && (
											<Button
												label="Share with customer"
												className=" ml-2"
												type="button"
												disabled={
													isValid &&
													values.mobileNumber
														? false
														: true
												}
												onClick={(e) => {
													e.preventDefault();
													shareWithCustomer(values);
												}}
											/>
										)}
									</div>
									{/* {console.log(
														values.district
													)} */}
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Dialog>
			{isLoading ? (
				<div className="py-5 px-5">
					<Skeleton width="100%" className="mb-2" height="2rem" />
					<Skeleton width="100%" className="mb-2" height="2rem" />
					<Skeleton width="100%" className="mb-2" height="2rem" />
					<Skeleton width="100%" height="2rem" />
				</div>
			) : (
				<Accordion className="py-5 px-5">
					{data.data.map(
						(item) =>
							item.isActive === true &&
							item.isActive === !null && (
								<AccordionTab header={item.title} key={item.id}>
									<DataTable
										value={item.subProducts.filter(
											(item) => item.isActive === true
										)}
										dataKey="id"
									>
										<Column field="title" header="Name" />
										<Column
											field="lineOfBusiness"
											header="Line Of Business"
										/>
										<Column field="alias" header="Alias" />
										<Column
											body={subProductAction}
											header={"Select Product"}
										/>
									</DataTable>
								</AccordionTab>
							)
					)}
				</Accordion>
			)}
		</>
	);
};

export default CreateVendorApplication;
