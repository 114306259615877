import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Config from "../../../../shared/config";
import { folder_path } from "../../../../shared/constant";

const TranstionList = (props) => {
	function getFileExtension(filename) {
		const extension = filename?.split(".").pop();
		return extension;
	}
	const paymentData = props?.transactionData[0];
	return (
		// <DataTable value={props?.transactionData}>
		// 	<Column
		// 		body={(item) =>
		// 			item?.txnDate ? (
		// 				moment(item?.txnDate).format("Do MMM YYYY hh:MM A")
		// 			) : (
		// 				<></>
		// 			)
		// 		}
		// 		header={"Transaction Date"}
		// 	/>
		// 	<Column field="txnId" header={"Transaction id"} />
		// 	<Column field="type" header={"Transaction Type"} />
		// 	<Column field="amount" header={"Transaction Amount"} />
		// 	<Column field="description" header={"Description"} />
		// 	<Column
		// 		body={(item) => {
		// 			return item?.document ? (
		// 				<a
		// 					href={
		// 						Config.AGENT_BUCKET_ACCESS_URL +
		// 						"/invoice" +
		// 						"/" +
		// 						folder_path +
		// 						"/" +
		// 						item?.document
		// 					}
		// 					target={"_blank"}
		// 					rel="noopener noreferrer"
		// 				>
		// 					{getFileExtension(item.document) === "pdf" ? (
		// 						<i
		// 							className={`text-2xl pi pi-file-pdf text-red-400`}
		// 						/>
		// 					) : (
		// 						<span className="c-list-box-it">
		// 							<img
		// 								style={{ width: "15px" }}
		// 								src={
		// 									Config.AGENT_BUCKET_ACCESS_URL +
		// 									"/invoice" +
		// 									"/" +
		// 									folder_path +
		// 									"/" +
		// 									item?.document
		// 								}
		// 								alt={item?.document}
		// 							/>
		// 						</span>
		// 					)}
		// 				</a>
		// 			) : (
		// 				<></>
		// 			);
		// 		}}
		// 		header={"Document"}
		// 	/>
		// </DataTable>
		<>
			<div className="modal-form-grid">
				<div className="cr-invoice">
					<div className="grid">
						<div className="md:col-8 col-12">
							<div className="cr-details">
								<p className="m-0">
									<strong>Transaction ID :</strong>
									{paymentData?.txnId}
								</p>
								<p className="m-0">
									<strong>Transaction Date :</strong>
									{paymentData?.txnDate
										? moment(paymentData?.txnDate).format(
												"Do MMM YYYY hh:mm A"
										  )
										: null}
								</p>
								<p className="m-0">
									<strong>Amount :</strong>
									{paymentData?.amount}
								</p>
								<p className="m-0">
									<strong>Type :</strong>
									{paymentData?.type}
								</p>
								<p className="m-0">
									<strong>Description :</strong>
									{paymentData?.description}
								</p>
							</div>
						</div>
						<div className="md:col-4 col-12">
							<div className="cr-docBx">
								{paymentData?.document && (
									<a
										href={
											Config.AGENT_BUCKET_ACCESS_URL +
											"/invoice" +
											"/" +
											folder_path +
											"/" +
											paymentData?.document
										}
										target={"_blank"}
										rel="noopener noreferrer"
									>
										{getFileExtension(
											paymentData?.document
										) === "pdf" ? (
											<i
												className={`text-5xl pi pi-file-pdf text-red-400`}
											/>
										) : (
											<span className="c-list-box-it">
												<img
													style={{
														width: "100%",
														height: "100%",
													}}
													src={
														Config.AGENT_BUCKET_ACCESS_URL +
														"/invoice" +
														"/" +
														folder_path +
														"/" +
														paymentData?.document
													}
													alt={paymentData?.document}
												/>
											</span>
										)}
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TranstionList;
