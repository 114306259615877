import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { DashboardService } from "../../../../services/DashboardService";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { CountryAdminDashboardService } from "../../../../services/CountryAdminDashboard";

const AgentLeadList = (props) => {
	const selector = useSelector((state) => state.persistData.auth.user);
	const dashboardService = new CountryAdminDashboardService();
	const [dropdown, setDropdown] = useState("");
	const [leaddetailsstate, setLeaddetailsstate] = useState([]);
	const getData = (userName) => {
		dashboardService.agent_lead_list({ userName: userName }).then((res) => {
			const data = res?.map((elm) => ({
				...elm,
				name:
					(elm.firstName ? elm.firstName : "") +
					" " +
					(elm.lastName ? elm.lastName : ""),
			}));
			setLeaddetailsstate(data);
		});
	};
	useEffect(() => {
		getData(null);
	}, []);
	const template2 = {
		layout: "CurrentPageReport PrevPageLink NextPageLink",
	};

	return (
		<>
			{selector.role.position === 1030 ? null : (
				<div className="flex justify-content-between pb-2">
					<Dropdown
						className="border-round-md"
						filter
						options={props.tsrmlist.map((elm) => ({
							...elm,
							name:
								(elm?.firstName ? elm?.firstName : "") +
								" " +
								(elm?.middleName ? elm?.middleName : "") +
								" " +
								(elm?.lastName ? elm?.lastName : ""),
						}))}
						optionLabel="name"
						optionValue="userName"
						placeholder="Tsrm"
						value={dropdown}
						onChange={(e) => {
							getData(e.value);
							setDropdown(e.value);
						}}
					/>
					<Button
						icon={"pi pi-refresh"}
						onClick={() => {
							setDropdown("");
							getData(null);
						}}
						className="p-button-text"
					/>
				</div>
			)}
			<DataTable
				paginator
				paginatorTemplate={template2}
				rows={7}
				value={leaddetailsstate}
				scrollable
			>
				<Column header={"Name"} field="name" />
				<Column header={"Mobile"} field="mobileNumber" />
				<Column header={"City"} field="cityName" />
				<Column header={"Assign To"} field="assignedTo" />
			</DataTable>
		</>
	);
};

export default AgentLeadList;
