import { useEffect, useState } from "react";
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { DashboardService } from "../../../../services/DashboardService";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import { CountryAdminDashboardService } from "../../../../services/CountryAdminDashboard";

const DailyPerformanceStacked = (props) => {
	const selector = useSelector((state) => state.persistData.auth.user);
	const [funnelData, setFunnelData] = useState([]);

	const [dropdown, setDropdown] = useState("");
	const colors = [
		"#6d79ad",
		"#52cd9f",
		"#df786f",
		"#4e9fa0",
		"#af7d9a",
		"#c9d45c",
	];
	const dashboardService = new CountryAdminDashboardService();
	const getData = (e) => {
		dashboardService.consolidated_block({ userName: e }).then((res) => {
			// const data = Object.entries(res).map((elm, i) => ({
			// 	value: Number(elm[1]),
			// 	name: elm[0]?.replaceAll("_", " "),
			// 	fill: colors[i] ? colors[i] : "#a4de6c",
			// }));
			setFunnelData(res);
		});
	};
	useEffect(() => {
		getData(null);
	}, []);
	const data = [
		{
			name: "MON",
			uv: 4000,
			pv: 2400,
			amt: 2400,
		},
		{
			name: "Page B",
			uv: 3000,
			pv: 1398,
			amt: 2210,
		},
		{
			name: "Page C",
			uv: 2000,
			pv: 9800,
			amt: 2290,
		},
		{
			name: "Page D",
			uv: 2780,
			pv: 3908,
			amt: 2000,
		},
		{
			name: "Page E",
			uv: 1890,
			pv: 4800,
			amt: 2181,
		},
		{
			name: "Page F",
			uv: 2390,
			pv: 3800,
			amt: 2500,
		},
		{
			name: "Page G",
			uv: 3490,
			pv: 4300,
			amt: 2100,
		},
	];

	return (
		<>
			{selector.role.position === 1030 ? null : (
				<div className="flex justify-content-between pb-2">
					<Dropdown
						className="border-round-md"
						filter
						options={props.tsrmlist.map((elm) => ({
							...elm,
							name:
								(elm?.firstName ? elm?.firstName : "") +
								" " +
								(elm?.middleName ? elm?.middleName : "") +
								" " +
								(elm?.lastName ? elm?.lastName : ""),
						}))}
						optionLabel="name"
						optionValue="userName"
						placeholder="Tsrm"
						value={dropdown}
						onChange={(e) => {
							getData(e.value);
							setDropdown(e.value);
						}}
					/>
					<Button
						icon={"pi pi-refresh"}
						onClick={() => {
							setDropdown("");
							getData(null);
						}}
						className="p-button-text"
					/>
				</div>
			)}
			<div
				style={{
					height: "440px",
					overflowX: "auto",
					overflowY: "hidden",
				}}
			>
				<ResponsiveContainer className={"c-chart-w-full"} height="100%">
					<BarChart
						data={funnelData}
						margin={{
							top: 20,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray="3" />
						<XAxis dataKey="Title" />
						<YAxis />
						<Tooltip />
						<Legend />
						<Bar
							dataKey="No Pickup Attempt"
							stackId="a"
							fill="#6d79ad"
						/>
						<Bar
							dataKey="Invalid Number"
							stackId="a"
							fill="#52cd9f"
						/>
						<Bar
							dataKey="Not Responding"
							stackId="a"
							fill="#df786f"
						/>
						<Bar
							dataKey="Follow Up Note"
							stackId="a"
							fill="#82ca9d"
						/>

						<Bar
							dataKey="Not Interested"
							stackId="a"
							fill="#4e9fa0"
						/>
						<Bar
							dataKey="Interested Will Register"
							stackId="a"
							fill="#af7d9a"
						/>
						<Bar dataKey="Downloaded" stackId="a" fill="#c9d45c" />
						<Bar dataKey="Registered" stackId="a" fill="#82ca9d" />
					</BarChart>
				</ResponsiveContainer>
			</div>
		</>
	);
};

export default DailyPerformanceStacked;
