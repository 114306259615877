import React from "react";
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";
import moment from "moment";
import { ProgressSpinner } from "primereact/progressspinner";

const TimelineStatus = ({ timelineStatusData }) => {
	const customizedContent = (item) => {
		return (
			<Card
				title={item.title}
				subTitle={moment(item.createdOn).format("Do MMM, YYYY HH:mm")}
			>
				{/* <Button label={item.remarks} className="p-button-text"></Button> */}
			</Card>
		);
	};

	const customizedMarker = (item) => {
		return (
			<span
				className="custom-marker shadow-1"
				style={{ backgroundColor: "#f7f7f7" }}
			>
				<i
					className={item.icon}
					style={{ fontSize: 17, color: item.color }}
				></i>
			</span>
		);
	};
	return (
		<div className="timeline-demo modal-form-grid">
			{/* <div className="card">
				{events1.length > 0 ? (
					<Timeline
						value={events1}
						marker={customizedMarker}
						content={customizedContent}
						align="alternate"
					/>
				) : (
					<h1 className="text-center"> Data Not Found</h1>
				)}
			</div> */}
			{timelineStatusData.isLoading ? (
				<div className="p-5 flex display-align-center justify-content-center">
					<ProgressSpinner
						style={{ width: "30px", height: "30px" }}
						strokeWidth="5"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				</div>
			) : timelineStatusData.isFetched &&
			  timelineStatusData.data?.length > 0 ? (
				<Timeline
					value={timelineStatusData.data}
					align="alternate"
					className="customized-timeline"
					marker={customizedMarker}
					content={customizedContent}
				/>
			) : (
				<p className="text-center">No Timeline</p>
			)}
		</div>
	);
};

export default TimelineStatus;
