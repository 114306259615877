import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import React, { useEffect, useState } from "react";
import {
	useGetAllDIstrictQuery,
	useGetProductDetailsQuery,
} from "../../../../../services/query/queryApi";
import Loader from "../../../../../components/Loader";

const Preview = (props) => {
	const [tableData, setTableData] = useState([]);
	const { data = [{ data: [] }] } = useGetProductDetailsQuery({
		searchRequest: [],
		pageNo: 1,
		pageSize: 330,
		sorts: [],
	});

	const {
		data: allDistrictData = [
			{ districtList: [{ id: null, name: "" }], id: null, name: "" },
		],
		isLoading,
	} = useGetAllDIstrictQuery();
	useEffect(() => {
		let modifyData =
			props.data &&
			props.data.map((item, index) => ({
				...item,
				index: 1 + index,
			}));
		props.submitData(modifyData);
		setTableData(modifyData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleUpload = () => {
		props.handelStages();
	};
	const handleBack = () => {
		props.handleBack();
	};
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="#" />
				<Column header="Product" />
				<Column header="Name" />
				<Column header="Mobile No." />
				<Column header="Pan" />
				<Column header="State" />
				<Column header="District" />
				<Column header="Pincode" />
				<Column header="Occupation Type" />
				<Column header="Monthly Salary" />
				<Column header="Action" />
			</Row>
		</ColumnGroup>
	);
	const deleteApplication = (e) => {
		const filterData = tableData.filter((item) => item.index !== e.index);
		setTableData(filterData);
		props.submitData(filterData);
	};
	const actionTemplate = (item) => {
		return (
			<>
				<Button
					type="button"
					onClick={() => deleteApplication(item)}
					// style={{
					// 	marginTop: "1.5rem",
					// }}
					icon="pi pi-trash"
					className="p-button-outlined p-button-danger p-button-sm"
					aria-label="Cancel"
				/>
			</>
		);
	};
	const getName = (item) => {
		let fname = item.firstName ? item.firstName : "";
		let mname = item.middleName ? item.middleName : "";
		let lname = item.lastName ? item.lastName : "";
		let name = `${fname} ${mname} ${lname}`;
		if (!item.firstName && !item.middleName && !item.lastName) {
			return item.applicantName;
		} else {
			return name;
		}
	};

	const productTemplate = (item) => {
		const newData =
			data.data && data.data.flatMap((elm) => elm.subProducts);
		// const element = newData && newData.find((e) => e.id === item.products);
		// console.log(element);
		// return element ? element.title : "";
		let filterData = [];
		item.products &&
			item.products.forEach((e) => {
				newData &&
					newData.forEach((res) => {
						if (e === res.id) {
							filterData.push(res.title);
						}
					});
			});
		return <>{filterData && filterData.map((item) => <ol>{item}</ol>)}</>;
	};

	const getStateName = (item) => {
		const newData =
			allDistrictData &&
			allDistrictData.find((elm) => elm.id === item.state);
		return newData ? newData.name : "";
	};
	const getDistrictName = (item) => {
		const newData =
			allDistrictData &&
			allDistrictData.find((elm) => elm.id === item.state);

		const districtName =
			newData &&
			newData.districtList.find((res) => res.id === item.districtId);
		return districtName ? districtName.name : "";
	};
	return (
		<>
			{isLoading && <Loader />}
			<div>
				<h4 className="text-center mt-6">
					Only the first 50 rows are displayed below
				</h4>
				<DataTable
					headerColumnGroup={headerGroup}
					value={tableData}
					responsiveLayout="scroll"
					breakpoint="960px"
				>
					<Column field="index" />
					<Column body={productTemplate} />
					<Column body={getName} />
					<Column field="mobile" />
					<Column field="pan" />
					<Column body={getStateName} />
					<Column field="districtId" body={getDistrictName} />
					<Column field="pincode" />
					<Column field="occupationType" />
					<Column field="netMonthlyIncome" />
					<Column body={actionTemplate} />
				</DataTable>
			</div>
			<div className="flex justify-content-end">
				<Button
					icon="pi pi-angle-left"
					label="Back"
					className="mr-2"
					onClick={handleBack}
				/>
				<Button
					icon="pi pi-angle-right"
					label="Next"
					className="mr-2"
					onClick={handleUpload}
				/>
			</div>
		</>
	);
};

export default Preview;
