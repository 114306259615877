import { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { CallCenterService } from "../../../../services/callCenterService";
import moment from "moment";
import Loader from "../../../../components/Loader";
import MenuComponent from "../../../../components/MenuComponent";

const ReminderList = (props) => {
	const ccService = new CallCenterService();
	const [loader, setLoader] = useState(false);
	const [reminderList, setreminderList] = useState([]);
	const getData = (id) => {
		ccService
			.getAllReminderListByAgentId(id)
			.then((res) => setreminderList(res));
	};
	const template2 = {
		layout: "CurrentPageReport PrevPageLink NextPageLink",
	};
	useEffect(() => {
		props?.agentId && getData(props.agentId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const deleteReminder = (item) => {
		const items1 = [
			{
				label: "Delete",
				icon: "pi pi-trash",
				command: () => {
					setLoader(true);
					ccService
						.deleteReminderById(item?.id)
						.then((res) => {
							getData(props.agentId);
						})
						.finally(() => {
							setLoader(false);
						});
				},
			},
			{
				label: "Delete All",
				icon: "pi pi-trash",
				command: () => {
					setLoader(true);
					ccService
						.deleteListByParentId(item?.id)
						.then((res) => {
							getData(props.agentId);
						})
						.finally(() => {
							setLoader(false);
						});
				},
			},
		];

		return <MenuComponent items={items1} />;
	};

	return (
		<>
			{loader && <Loader />}
			<DataTable
				value={reminderList}
				paginator
				paginatorTemplate={template2}
				rows={5}
				responsiveLayout={"scroll"}
			>
				<Column
					body={(item) =>
						item?.remainderDateTime ? (
							moment(item?.remainderDateTime).format(
								"Do MMM YYYY hh:MM a"
							)
						) : (
							<></>
						)
					}
					field="remainderDateTime"
					header={"Reminder"}
				/>
				<Column field="createdBy" header={"Created By"} />
				<Column field="remainderNotes" header={"Remainder Notes"} />
				<Column field="type" header={"Type"} />
				<Column header={"Action"} body={deleteReminder} />
			</DataTable>
		</>
	);
};

export default ReminderList;
