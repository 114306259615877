import { TabPanel, TabView } from "primereact/tabview";
import React from "react";
import EditProduct from "../../users/components/EditAgent/EditProduct";
import EditBankDetails from "../../users/components/EditAgent/EditBankDetails";

const AgentProfileEditDialoge = ({ agentEdit, dialogeClose }) => {
	// console.log(agentEdit);
	return (
		<div>
			<TabView activeIndex={0}>
				{/* {agentEdit.hasOwnProperty("profile") ? (
					<TabPanel header="General"></TabPanel>
				) : null} */}

				{agentEdit.hasOwnProperty("products") ? (
					<TabPanel header="Products">
						<EditProduct
							agentEdit={agentEdit}
							dialogeClose={dialogeClose}
						/>
					</TabPanel>
				) : null}

				{/* {agentEdit.hasOwnProperty("profile") ? (
					<TabPanel header="KYC Details"></TabPanel>
				) : null} */}

				{agentEdit.hasOwnProperty("bankDetails") ? (
					<TabPanel header="Bank Details">
						<EditBankDetails
							agentEdit={agentEdit}
							dialogeClose={dialogeClose}
						/>
					</TabPanel>
				) : null}
				{/* {agentEdit.hasOwnProperty("profile") &&
				agentEdit.profile.hasOwnProperty("company") ? (
					<TabPanel header="Company Details"></TabPanel>
				) : null}
				{agentEdit.hasOwnProperty("profile") &&
				agentEdit.profile.hasOwnProperty("parentAgent") ? (
					<TabPanel header="Parent Agent Details"></TabPanel>
				) : null} */}
			</TabView>
		</div>
	);
};

export default AgentProfileEditDialoge;
