import { useState } from "react";
import { Field, Form, Formik } from "formik";
import { Button } from "primereact/button";
import Config from "../../../../shared/config";
import {
	FeildDropdown,
	FieldInput,
	FieldMultiselect,
} from "../../../../components/FieldInput";
import { Skeleton } from "primereact/skeleton";
const SearchFAQ = (props) => {
	const [open, setOpen] = useState(false);
	const initialValues = {
		description: "",
		roleId: "",
	};

	const handleSearch = (value) => {
		let data = Object.fromEntries(
			Object.entries(value).filter(([_, v]) => v !== "")
		);

		props.searchDispatch({
			type: Config.SEARCH_CONFIG.searchRequest,
			payload: data,
		});
		props.searchDispatch({
			type: Config.SEARCH_CONFIG.pageNo,
			payload: 1,
		});
	};
	return (
		<>
			<div className="w-full py-3 flex align-items-center justify-content-between">
				<h3 className="m-0">{props.header}</h3>
				<div>
					<Button
						label={open ? "Close" : "Search"}
						icon={open ? "pi pi-times" : "pi pi-search"}
						className={
							open
								? "p-button-outlined p-button-danger"
								: "p-button-outlined"
						}
						aria-label="Cancel"
						onClick={() => setOpen((prev) => !prev)}
					/>
					<Button
						label="Reload"
						icon="pi pi-refresh"
						className="p-button-outlined ml-2"
						onClick={() => {
							props.searchDispatch({
								type: Config.SEARCH_CONFIG.reset,
							});
						}}
					/>
					<Button
						label="Add"
						icon="pi pi-plus"
						className="primary-btn ml-2"
						onClick={() => {
							props.addMemberClick("add");
						}}
					/>
				</div>
			</div>

			{open && (
				<Formik
					initialValues={initialValues}
					enableReinitialize
					onSubmit={handleSearch}
					// validationSchema={validationSchema}
				>
					{({ handleSubmit, resetForm }) => (
						<Form
							className="background-secondary p-4 border-round-md my-3 form-grid grid"
							onSubmit={handleSubmit}
						>
							<div className="field col-12 md:col-3">
								<Field
									header={"Description"}
									name={"description"}
									component={FieldInput}
								/>
							</div>
							<div className="field col-12 md:col-3">
								{props.role ? (
									<Field
										header={"Select Role"}
										component={FeildDropdown}
										name={`roleId`}
										options={props.role}
										optionLabel={"name"}
										optionValue={"id"}
									/>
								) : (
									<Skeleton className="w-full flex-1 h-3rem mt-4 " />
								)}

								{/* <Field
									header={"Select Role"}
									component={FieldMultiselect}
									name={`roleId`}
									options={props.role}
									optionLabel={"name"}
									optionValue={"id"}
								/> */}
							</div>
							<div className="col-12 flex align-items-end justify-content-end">
								<Button
									type="button"
									label="Reset"
									className="p-button-danger mr-2"
									onClick={resetForm}
								/>
								<Button type="submit" label="Search" />
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default SearchFAQ;
